import React, { Fragment } from "react";
import Component from "source/rootComponent";
import { css } from "@convertly/css";
import _ from "lodash";

// Style
import { BlogPostPreviewStyles } from "./BlogPostPreviewStyles";

// Util
import renderChildren from "../util/renderChildren";

export class BlogPostPreview extends Component {
  render() {
    const { data, inEditor, global, blogData } = this.props,
          { clickToRedirect, gridColumnsCount } = data || {};

    if (!blogData || !_.isArray(blogData) || blogData.length === 0) {
      return null;
    }



    let numOfColumns = 1;

    if (_.isInteger(+gridColumnsCount)) {
      numOfColumns = +gridColumnsCount;
    } else if (_.isInteger(+_.get(this.props, "page.blogSettings.gridColumnsCount"))) {
      numOfColumns = +_.get(this.props, "page.blogSettings.gridColumnsCount");
    } else if (_.isInteger(+_.get(this.props, "site.blogSettings.gridColumnsCount"))) {
      numOfColumns = +_.get(this.props, "site.blogSettings.gridColumnsCount");
    }

    this.styles = BlogPostPreviewStyles({ global, data, inEditor, numOfColumns });

    const { blogPostPreview, blogPostPreviewWrapper } = this.styles;
    const inlineStyle = this.generateStyleAttribute();

    return (
      <section
        className={css(blogPostPreviewWrapper)}
        {...inlineStyle}
        {...this.generateDataEditorAttribute()}
        {...this.generateCustomAttributes()}
      >
        {clickToRedirect
          ? blogData.map((el, idx) => {
              let slug = _.get(el, "slug");

              return (
                <a
                  href={inEditor ? "#" : slug ? slug : "/"}
                  className={`${css(blogPostPreview)}${idx === 0 ? " active" : ""}`}
                  key={`blogpostpreview-${idx}`}
                >
                  {renderChildren(this.props, {
                    blogData: { ...el, clickToRedirect },
                    inEditor
                  })}
                </a>
              );
            })
          : blogData.map((el, idx) => {
              return (
                <div
                  className={`${css(blogPostPreview)}${idx === 0 ? " active" : ""}`}
                  key={`blogpostpreview-${idx}`}
                >
                  {renderChildren(this.props, {
                    blogData: { ...el, clickToRedirect },
                    inEditor
                  })}
                </div>
              );
            })}
      </section>
    );
  }
}
