import { StyleSheet } from '@convertly/css';

const footerStyles = ( global, data ) => {

    return StyleSheet.create({
        mainFooter: {
            textAlign:'center',
            display: 'flex',
            justifyContent: 'space-between',
            fontFamily: global.fontFamily.primaryFont,
            margin: "0 auto",
            justifyContent: "space-between",
            width: "1140px",
            maxWidth: "95%",
            padding: "40px 0",
            '@media (max-width: 768px)': {
                flexDirection: 'column',
                alignItems: 'center',
            }
        },
        logo: {
            maxWidth:'50%',
            display:'inline-block'
        },
        footerText: {
            margin:'20px',
            color:global.colors.dark.color3
        },
        copyRightText: {
            display:'inline-block',
            borderColor:global.colors.dark.color3,
            color:global.colors.dark.color1,
            paddingRight:'16px',
            fontFamily: global.fontFamily.primaryFont,
            fontSize:'12px',
            '@media( max-width: 768px)': {
                border:'none'
            }
        },
        footerNavLinks: {
            display:'inline-block',
            listStyleType:'none'
        },
        copyRightLinks: {
            marginLeft:'5px',
            display:'inline-block',
            color:global.colors.light.color3,
            fontFamily: global.fontFamily.primaryFont,
            fontSize:'12px',
            ":before": {
                content: '" | "'
            },
            ':first-child': {
                ":before": {
                    content: '""'
                },
            },
        }


    })
};


export default footerStyles;
