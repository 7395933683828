/* global $, ga, _paq, fbq, window */

import { include } from '@convertly/thor';

const Script = () => {

	const Dispatcher = include( '../utils/dispatcher', 'Dispatcher' );
	const Cookie = include( '../utils/cookie', 'Cookie' );

	return ( id, siteId ) => {

        const hasLocalStorage = $('html').hasClass('localstorage');
		
		const dispatch = Dispatcher();
        const cookie = new Cookie();
		let staySignedIn = false;

		const handleError = function( data ) {

            let $emailErrorMessage = $('input[data-input="email"]').next();
            let $passwordErrorMessage = $('input[data-input="password"]').next();

            switch (data.errorCode) {
                case "001": 
                case "005": {
                    dispatch.send('snack_bar_alert', {
                        alert: "Looks like you already have an account associated with that email",
                        type: "error",
                        timeout: 5000
                    });
                    dispatch.update('authError', {
                        message: "This email already has an account",
                        errorType: "signup"
                    });

                    break;
                }
                
                case "003": {
                    dispatch.update('authError',
                        {
                            message: "Invalid Email or Password, please try again",
                            errorType: "signin"
                        });
                    break;
                }
                case "004": {
                    dispatch.send('snack_bar_alert', {
                        alert: "No Accounts found with this email",
                        type: "error",
                        timeout: 5000
                    });
                    //dispatch.update('authError', "No Accounts found with this email, signup to make an account");

                    // $emailErrorMessage.text("No Accounts found with this email.")
                    //     .addClass('show-form-error-message');
                    break;
                }
                case "006": {
                    dispatch.send('snack_bar_alert', {
                        alert: "No Accounts found with this email, signup to make an account",
                        type: "error",
                        timeout: 5000
                    });
                    dispatch.update('authError', {
                        message: "No Accounts found with this email",
                        errorType: "signin"
                    });

                    break;
                }
                case "007": {
                    dispatch.send('snack_bar_alert', {
                        alert: "Something went wrong with Facebook Sign in, please try again",
                        type: "error",
                        timeout: 5000
                    });
                    break;
                }
                case "008": {
                    dispatch.send('snack_bar_alert', {
                        alert: "Something went wrong with Google Sign in, please try again",
                        type: "error",
                        timeout: 5000
                    });
                    break;
                }
                case "012": {
                    dispatch.send('snack_bar_alert', {
                        alert: "No Accounts found with this email, signup to make an account",
                        type: "error",
                        timeout: 5000
                    });
                    dispatch.update('authError',
                        {
                            message: "No Accounts found with this email",
                            errorType: "signin"
                        });
                    break;
                }
                default:
                    dispatch.send('snack_bar_alert', {
                        alert: "Something went wrong, please try again.",
                        type: "error",
                        timeout: 5000
                    });
                    break;
            }

        };

		const b2cAuthSignIn = function( userObj ) {

			let id = siteId || 11;

			const postData = {};

			postData.siteId = id;
			postData.provider = userObj.provider;
			postData.action = userObj.action;

            if (userObj.credentials) {
                if (userObj.credentials.token) {
                    postData.tokenId = userObj.credentials.token;
                } else {
                    postData.username = userObj.credentials.username;
                    postData.password = userObj.credentials.password;
				}
			}

            let convertlyToken;

			const url = 'https://zfk9ugr1s5.execute-api.us-east-1.amazonaws.com/v2/auth';
			// const url = "https://r2zxui6ae4.execute-api.us-east-1.amazonaws.com/v1/auth";

            $.post( url, JSON.stringify(postData), function (data) {

                if (data.error) {
                    handleError( data );
                    return false;
                }
                else {

                    convertlyToken = data.token;



                    saveToCookie(convertlyToken);

                    dispatch.update('accessToken', convertlyToken);
                }
            })
        
		};

		const saveToCookie = function(token) {
                cookie.create({
                    name:"authToken",
                    value:token,
                    expires: staySignedIn ? 30 : false
                })



        };

        const initDispatchers = function() {
            dispatch.watch('authToken', (obj) => {
                b2cAuthSignIn(obj);
            });


            dispatch.watch('stay_signed_in', (staySignedInValue) => {
                staySignedIn = staySignedInValue
            });
        };

        initDispatchers()

	};

};

export default Script;
