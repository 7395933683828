import {StyleSheet} from "@convertly/css";
import ModuleGlobal from '../../../elements/style-functions/module-global-styles';
import HeaderGlobal from '../header-style-functions/header-styles-global';
import FixedHeaderFix from '../header-style-functions/fixed-header-fix';
import {generatePseudoMediaQuery} from '../../../elements/style-functions/pseudo-media-queries';
import UserOverrides from '../../../elements/style-functions/user-overrides';


const Styles = ( global, data, headerPosition, inEditor ) => {

    const userOverrides = data.userOverrides ? new UserOverrides(global, data, inEditor) : {};
    const moduleGlobal = new ModuleGlobal(global, data);
    const headerGlobal = new HeaderGlobal(global, data, headerPosition);
    const fixedHeaderFix = new FixedHeaderFix(data, "50px");

    let headerOffset = {
        marginTop: '111px',
        ...generatePseudoMediaQuery("@media(max-width:767px)", ['smallTablet', 'mobile'], {
            marginTop: "79px",
        }), inEditor
    };

    if (inEditor) {
        headerOffset = {}
    }

    let headerStyles = {};

    if (headerPosition === "overlay") {
        headerStyles = {
            position: 'absolute',
            color: global.textColors.light.white,
            stroke: global.backgroundColors.light.white,
            background: "transparent"
        }
    }
    else if (headerPosition === "overlayFixed") {
        headerStyles = {
            '.absolute': {
                position: 'absolute',
                color: global.textColors.light.white,
                stroke: global.backgroundColors.light.white,
                background: "transparent"
            },
        }
    }
    else if (headerPosition === "fixed") {

        headerStyles = {
            '* body': {
                ...headerOffset
            }
        }
    }
    else {
        headerStyles = {}
    }


    return StyleSheet.create({

        div_AxMv: {
            boxSizing: "border-box",
            color: "#5C6976",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            margin: "0",
            padding: "0"
        },
        header: {
            boxSizing: "border-box",
            color: "#5C6976",
            display: "block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            margin: "0",
            padding: "0",
            backgroundColor: global.colors.light.white,
            zIndex: '2',
            width: '100%',
            position: "fixed",
            borderBottom: `3px solid ${global.colors.light.color3}`,
            ...headerGlobal,
            ...moduleGlobal,
            ...headerStyles,
            ...userOverrides
        },
        headerTop: {
            boxSizing: "border-box",
            color: "#5C6976",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            margin: "0 auto",
            padding: "0"
        },
        mobileMenu: {
            height: '100vh',
            display: 'block',
            transition: '.15s all ease',
            width: "290px",
            backgroundColor: global.colors.light.white,
            position: 'fixed',
            left: "-290px",
            zIndex: '21',
            '.open': {
                left: "0px",
                ...generatePseudoMediaQuery("@media (min-width: 768px)", ['largeTablet', 'smallTablet', 'desktop'], {
                    display: "none",
                }, inEditor),
            }
        },
        mobileMenu2: {
            backgroundColor: "rgba(0,0,0,0.3)",
            display: "none",
            position: 'fixed',
            right: "0",
            height: "100vh",
            width: "100vw",
            zIndex: 1,
            ".open": {
                display: "block"
            }
        },
        mobileNavContent: {
            marginTop: '105px',
            height: "100%"
        },
        span_owyc: {
            boxSizing: "border-box",
            color: "#5C6976",
            cursor: "pointer",
            font: "inherit",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            margin: "0",
            padding: "0",
            textTransform: "none"
        },
        mbl_logo: {
            backgroundColor: "transparent",
            boxSizing: "border-box",
            color: "#000",
            display: "none",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            margin: "0",
            outline: "0 none !important",
            padding: "0",
            textDecoration: "none",
            transition: "all ease-in-out .3s"
        },
        img_JKQz: {
            border: "0",
            boxSizing: "border-box",
            color: "#5C6976",
            display: "inline-block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            height: "auto",
            lineHeight: "16px",
            margin: "0",
            maxWidth: "50%",
            padding: "0",
            verticalAlign: "middle",
            paddingLeft: '10px',
            ...generatePseudoMediaQuery("@media (max-width: 992px)", ['largeTablet'], {
                paddingLeft: '0px',
            }, inEditor),
            ...generatePseudoMediaQuery("@media (max-width: 768px)", ['smallTablet', 'mobile'], {
                paddingLeft: '0px',
            }, inEditor)
        },
        header_top: {
            boxSizing: "border-box",
            color: "#5C6976",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            display: 'flex',
            justifyContent: 'space-between',
            margin: '0px 4%',
            padding: '6px 0px',
            borderBottom: `2px solid ${global.colors.dark.color1}`,
            ...generatePseudoMediaQuery("@media (max-width: 768px)", ['smallTablet', 'mobile'], {
                display: "none",
            }, inEditor),
            ...userOverrides
        },
        header_top_lft: {
            boxSizing: "border-box",
            color: "#5C6976",
            float: "none",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            margin: "0",
            padding: "0",
            display: 'flex',
            paddingTop: "5px",
        },
        socialIconContainer: {
            height: '20px',
            width: '20px',
            backgroundColor: global.colors.dark.color3,
            fill: global.colors.light.white,
            borderRadius: '50%',
            backgroundSize: '30px',
            ...generatePseudoMediaQuery("@media (max-width: 768px)", ['smallTablet', 'mobile'], {
                marginTop: "20px",
                marginLeft: "10px"
            }, inEditor),
        },
        connect_us: {
            boxSizing: "border-box",
            color: "#5C6976",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            margin: "0",
            padding: "0",
        },
        socialIcons: {
            paddingTop: "5px"
        },
        li_pQxY: {
            boxSizing: "border-box",
            color: "#5C6976",
            display: "inline-block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0 5px 0 0",
            padding: "0"
        },
        a_cDUe: {
            background: "#b2b2b2",
            borderRadius: "100%",
            boxSizing: "border-box",
            color: "#000",
            display: "block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            height: "21px",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0",
            outline: "0 none !important",
            padding: "0",
            textAlign: "center",
            textDecoration: "none",
            transition: "all ease-in-out .3s",
            width: "21px"
        },
        fa_faFacebook: {
            boxSizing: "border-box",
            color: "#ffffff",
            display: "inline-block",
            font: "normal normal normal 14px/1 FontAwesome",
            fontSize: "12px",
            lineHeight: "22px",
            listStyle: "none",
            margin: "0",
            padding: "0",
            textAlign: "center",
            textRendering: "auto"
        },
        li_INZT: {
            boxSizing: "border-box",
            color: "#5C6976",
            display: "inline-block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0 5px 0 0",
            padding: "0"
        },
        a_JdfC: {
            background: "#b2b2b2",
            borderRadius: "100%",
            boxSizing: "border-box",
            color: "#000",
            display: "block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            height: "21px",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0",
            outline: "0 none !important",
            padding: "0",
            textAlign: "center",
            textDecoration: "none",
            transition: "all ease-in-out .3s",
            width: "21px"
        },
        fa_faInstagram: {
            boxSizing: "border-box",
            color: "#ffffff",
            display: "inline-block",
            font: "normal normal normal 14px/1 FontAwesome",
            fontSize: "12px",
            lineHeight: "22px",
            listStyle: "none",
            margin: "0",
            padding: "0",
            textAlign: "center",
            textRendering: "auto"
        },
        li_ywxG: {
            boxSizing: "border-box",
            color: "#5C6976",
            display: "inline-block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0 5px 0 0",
            padding: "0"
        },
        a_mbZi: {
            background: "#b2b2b2",
            borderRadius: "100%",
            boxSizing: "border-box",
            color: "#000",
            display: "block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            height: "21px",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0",
            outline: "0 none !important",
            padding: "0",
            textAlign: "center",
            textDecoration: "none",
            transition: "all ease-in-out .3s",
            width: "21px"
        },
        fa_faTwitter: {
            boxSizing: "border-box",
            color: "#ffffff",
            display: "inline-block",
            font: "normal normal normal 14px/1 FontAwesome",
            fontSize: "12px",
            lineHeight: "22px",
            listStyle: "none",
            margin: "0",
            padding: "0",
            textAlign: "center",
            textRendering: "auto"
        },
        span_ymLn: {
            boxSizing: "border-box",
            color: global.colors.light.color3,
            display: "inline-block",
            float: "none",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "10px",
            lineHeight: "12px",
            margin: "0"

        },
        clearfix: {
            boxSizing: "border-box",
            color: "#5C6976",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            margin: "0",
            padding: "0"
        },
        account: {
            boxSizing: "border-box",
            color: "#5C6976",
            display: "inline-block",
            float: "none",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            margin: "0",
            padding: "5px 5px 0 0"
        },
        ul_LLxc: {
            boxSizing: "border-box",
            color: "#5C6976",
            display: "inline-block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0",
            padding: "0",
            width: "100%"
        },
        last: {
            boxSizing: "border-box",
            color: "#5C6976",
            display: "inline-block",
            float: "none",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0 !important",
            padding: "0"
        },
        a_eDVo: {
            backgroundColor: "transparent",
            boxSizing: "border-box",
            color: "#8191A2",
            display: "block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "12px",
            lineHeight: "12px",
            listStyle: "none",
            margin: "0",
            outline: "0 none !important",
            padding: "0",
            textDecoration: "none",
            transition: "all ease-in-out .3s"
        },
        li_vrDV: {
            boxSizing: "border-box",
            color: "#5C6976",
            display: "inline-block",
            float: "none",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0 0 0 23px",
            padding: "0"
        },
        a_eSFH: {
            backgroundColor: "transparent",
            boxSizing: "border-box",
            color: "#8191A2",
            display: "block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "12px",
            lineHeight: "12px",
            listStyle: "none",
            margin: "0",
            outline: "0 none !important",
            padding: "0",
            textDecoration: "none",
            transition: "all ease-in-out .3s"
        },

        li_zZyt: {
            boxSizing: "border-box",
            color: "#5C6976",
            display: "inline-block",
            float: "none",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0 0 0 23px",
            padding: "0"
        },

        a_xcTu: {
            backgroundColor: "transparent",
            boxSizing: "border-box",
            display: "block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "12px",
            lineHeight: "12px",
            listStyle: "none",
            margin: "0",
            outline: "0 none !important",
            padding: "0",
            textDecoration: "none",
            transition: "all ease-in-out .3s"
        },

        mainHeader: {
            boxSizing: "border-box",
            margin: '0 4%',
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px 0',
            ...generatePseudoMediaQuery("@media (max-width: 768px)", ['smallTablet', 'mobile'], {
                padding: "20px 10px",
                textAlign: "center"
            }, inEditor),
        },

        navRight: {
            display: 'inline-block',
            float: 'right',
            ...generatePseudoMediaQuery("@media (max-width: 768px)", ['smallTablet', 'mobile'], {
                paddingTop: '10px'
            }, inEditor),
        },

        mobileHide: {
            ...generatePseudoMediaQuery("@media(max-width:767px)", ['smallTablet', 'mobile'], {
                display: 'none'
            }, inEditor),
        },
        navItemContainer: {
            float: 'right',
            paddingTop: '2px'
        },
        logo: {
            backgroundColor: "transparent",
            boxSizing: "border-box",
            display: "inline-block",
            float: "none",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            margin: "0 0 0 1px",
            outline: "0 none !important",
            textDecoration: "none",
            zIndex: "5",
            transition: "all ease-in-out .3s"
        },
        logoImg: {
            width:data.logoSize || "auto",
            maxWidth: "200px",
            margin: "16px auto",
            ...generatePseudoMediaQuery("@media(max-width:767px)", ['smallTablet', 'mobile'], {
                width: "auto",
                maxHeight: "45px"
            }, inEditor),
            ...generatePseudoMediaQuery(
              "@media(max-width: 768px)",
              ['smallTablet', 'mobile'],
              {
                  "margin": "0 auto"
              },
              inEditor
            )

        },

        img_sPua: {
            border: "0",
            boxSizing: "border-box",
            color: "#5C6976",
            display: "inline-block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            height: "auto",
            lineHeight: "16px",
            margin: "0",
            maxWidth: "100%",
            padding: "0",
            verticalAlign: "middle"
        },

        nav_KbjO: {
            boxSizing: "border-box",
            color: "#5C6976",
            display: "inline-block",
            float: "none",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            margin: "0",
            paddingRight: '40px',
            ...generatePseudoMediaQuery("@media(max-width:767px)", ['smallTablet', 'mobile'], {
                display: "none"
            }, inEditor),
        },
        ul_XIWg: {
            boxSizing: "border-box",
            color: "#5C6976",
            display: "inline-block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0",
            padding: "0",
            width: "100%"
        },

        last: {
            boxSizing: "border-box",
            color: "#5C6976",
            display: "inline-block",
            float: "none",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0 !important",
            padding: "0",
            position: "relative"
        },
        a_IlyQ: {
            backgroundColor: "transparent",
            boxSizing: "border-box",
            color: "#A1B1C1",
            display: "block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            fontWeight: "900",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0",
            outline: "0 none !important",
            padding: "0",
            textDecoration: "none",
            transition: "all ease-in-out .3s"
        },
        span_CruX: {
            boxSizing: "border-box",
            color: global.colors.dark.color1,
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            fontWeight: "900",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0",
            padding: "0 0 0 10px",
            position: "relative"
        },
        ul_Vdkv: {
            background: "#ffffff",
            boxSizing: "border-box",
            color: "#5C6976",
            display: "none",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            left: "0",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0",
            padding: "15px 10px 0",
            position: "absolute",
            top: "100%",
            width: "100px",
            zIndex: "9"
        },

        li_XOzO: {
            boxSizing: "border-box",
            color: "#5C6976",
            display: "block",
            float: "none",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0 0 17px 0",
            padding: "0",
            position: "relative"
        },
        a_PlyC: {
            backgroundColor: "transparent",
            boxSizing: "border-box",
            color: "#A1B1C1",
            display: "block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "11px",
            fontWeight: "bold",
            lineHeight: "11px",
            listStyle: "none",
            margin: "0",
            outline: "0 none !important",
            padding: "0",
            textDecoration: "none",
            transition: "all ease-in-out .3s"
        },
        li_lDnt: {
            boxSizing: "border-box",
            color: "#5C6976",
            display: "block",
            float: "none",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0 0 17px 0",
            padding: "0",
            position: "relative"
        },
        a_hYad: {
            backgroundColor: "transparent",
            boxSizing: "border-box",
            color: "#A1B1C1",
            display: "block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "11px",
            fontWeight: "bold",
            lineHeight: "11px",
            listStyle: "none",
            margin: "0",
            outline: "0 none !important",
            padding: "0",
            textDecoration: "none",
            transition: "all ease-in-out .3s"
        },
        li_LyAw: {
            boxSizing: "border-box",
            color: "#5C6976",
            display: "block",
            float: "none",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0 0 17px 0",
            padding: "0",
            position: "relative"
        },
        a_NPEq: {
            backgroundColor: "transparent",
            boxSizing: "border-box",
            color: "#A1B1C1",
            display: "block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "11px",
            fontWeight: "bold",
            lineHeight: "11px",
            listStyle: "none",
            margin: "0",
            outline: "0 none !important",
            padding: "0",
            textDecoration: "none",
            transition: "all ease-in-out .3s"
        },
        li_FnMp: {
            boxSizing: "border-box",
            color: "#5C6976",
            display: "block",
            float: "none",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0 0 17px 0",
            padding: "0",
            position: "relative"
        },
        a_LNeR: {
            backgroundColor: "transparent",
            boxSizing: "border-box",
            color: "#A1B1C1",
            display: "block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "11px",
            fontWeight: "bold",
            lineHeight: "11px",
            listStyle: "none",
            margin: "0",
            outline: "0 none !important",
            padding: "0",
            textDecoration: "none",
            transition: "all ease-in-out .3s"
        },
        li_rzXF: {
            boxSizing: "border-box",
            color: "#5C6976",
            display: "inline-block",
            float: "none",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0 0 0 28px",
            padding: "0",
            position: "relative"
        },
        a_WoTd: {
            backgroundColor: "transparent",
            boxSizing: "border-box",
            color: "#A1B1C1",
            display: "block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            fontWeight: "900",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0",
            outline: "0 none !important",
            padding: "0",
            textDecoration: "none",
            transition: "all ease-in-out .3s"
        },

        li_XFbw: {
            boxSizing: "border-box",
            color: "#5C6976",
            display: "inline-block",
            float: "none",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0 0 0 28px",
            padding: "0",
            position: "relative"
        },
        a_rrrl: {
            backgroundColor: "transparent",
            boxSizing: "border-box",
            color: "#A1B1C1",
            display: "block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            fontWeight: "900",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0",
            outline: "0 none !important",
            padding: "0",
            textDecoration: "none",
            transition: "all ease-in-out .3s"
        },
        socialIcon: {
            height: '100%',
            width: '100%',
            fill: global.colors.light.white,
            padding: '3px',
        },

        fixedHeaderFix: {
            ...fixedHeaderFix,
            "margin": "0"
        }
    });

};

export default Styles;
