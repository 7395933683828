import findElementObject from "./findElementObject";
export const getAncestorId = (pageElements, objectBeingEdited, activeModuleId, generation) => {
  let parentObject;
  if (generation < 0) {
    console.log("negative generation not allowed");
    return;
  }
  
  // Check for object being edited existance
  if (generation === 0 && objectBeingEdited) return objectBeingEdited.id;
  if (generation === 1 && objectBeingEdited) return objectBeingEdited.parentId;
  else {
    pageElements.forEach(element => {
      if (element.id === activeModuleId) {
        parentObject = findElementObject(element, objectBeingEdited.parentId);
      }
    });
    return getAncestorId(pageElements, parentObject, activeModuleId, generation - 1);
  }
};
export default getAncestorId;
