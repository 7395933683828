import { connect } from "react-redux";
import { EditorMenu } from "../menus/EditorMenu";
import { store } from "app/app";

import { setMainMenu } from "containers/App/actions";

import {
  setNavigation,
  userClickedSaveButton,
  setCurrentModal,
  setOverlayStyles,
  setElements,
  setEditorViewportWidth,
  setEditor,
  deletePage,
  saveTheme,
  changeEditor,
  clearEditorPanel,
} from "containers/Editor/actions/editorActions";

import CONSTANTS from "containers/Editor/constants";

const mapStateToProps = state => {
  const Editor = state.get("editor").editorReducer;
  const Auth = state.get("auth");
  const PageSlug = Editor.getIn(["page", "slug"]);

  return {
    isMenuOpen: state.getIn(["global", "isMenuOpen"]),
    savingTheme: Editor.get("savingTheme"),
    showNavigationMenu: Editor.get("showNavigationMenu"),
    isUsingNewEditor: Editor.get("isUsingNewEditor"),
    pageElements: Editor.get("pageElements"),
    saving: Editor.get("saving"),
    page: Editor.get("page"),
    currentSite: Editor.get("currentSite"),
    sites: Auth.get("sites"),
    user: Auth.get("user"),
    preview: `http://${Editor.getIn(["currentSite", "previewDomain"])}.convertly.io${PageSlug}`,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    editDesign: async () => await dispatch(setEditor({ editorType: { type: "ThemeSwitcher" } })),
    saveDesign: async () => await dispatch(saveTheme()),
    handleViewport: async (e, width) => await dispatch(setEditorViewportWidth(width)),
    savePage: async (publish = false) => await dispatch(userClickedSaveButton({ publish })),
    deletePage: async page => await dispatch(deletePage(page)),
    toggleEditor: async param => {
      dispatch(changeEditor(param));
    },
    clearEditorPanel: async () => {
      dispatch(clearEditorPanel());
    },
    popSiteSettings: e => {
      dispatch(setCurrentModal(CONSTANTS.MODALS.SITE_SETTINGS));
      dispatch(setOverlayStyles({ zIndex: 2700, backgroundColor: "#F5F8FA", opacity: "1" }));
    },
    openMenu: () => {
      const showNavigationMenu = store
        .getState()
        .get("editor")
        .editorReducer.get("showNavigationMenu");
      dispatch(setMainMenu(false));
      dispatch(setNavigation(!showNavigationMenu));
    },
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorMenu);

export default Container;
