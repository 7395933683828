import {
  setImageGalleryCallback,
  setCurrentModal,
  setOverlayStyles,
} from "containers/Editor/actions/editorActions";
import CONSTANTS from "containers/Editor/constants";
import { noop } from "./noop";
import {setImageModalSource} from "../containers/Editor/actions/editorActions";

export const openImageGallery = (cb = noop) => async dispatch => {
  return new Promise(async resolve => {
    const imageCallBack = async src => {
      resolve(src)
      return await Promise.all([dispatch(setCurrentModal("")), cb(src)(dispatch)]);
    };

    await Promise.all([
      dispatch(setImageGalleryCallback(imageCallBack)),
      dispatch(setCurrentModal(CONSTANTS.MODALS.IMAGE_GALLERY)),
      dispatch(setOverlayStyles({ zIndex: 2700 })),
    ]);
  });
};

export const openImageGallery2 = args => async dispatch => {
  const {cb, src} = args;

  await dispatch(setImageGalleryCallback(cb));
  await dispatch(setImageModalSource(src));
  await dispatch(setCurrentModal(CONSTANTS.MODALS.IMAGE_GALLERY));
  await dispatch(setOverlayStyles({zIndex: 2700}));
};
