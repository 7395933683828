import { generateGlobalObject } from '../../default-themes/generate-global-object';


let defaultGlobal = {
    colors: {
        dark: {
            color1: '#2d343d',
            color2: '#4a5663',
            color3: '#6f7e90',
            black: '#000'
        },
        accent: {
            color1: '#39618f',
            color2: '#2978d4',
            color3: '#2979ff'
        },
        light: {
            color1: '#fbfcff',
            color2: '#b4bbc3',
            color3: '#98a0a9',
            white: "#fff"
        }        
    },
    textColors: {
        light: {},
        dark: {},
        accent: {}
    },
    backgroundColors: {
        light: {},
        dark: {},
        accent: {}
    },
     // Module Default wrapper
    moduleDefault: {
        margin:'50px 0',
        padding:'50px 20px',
    },
        themeBorder: {
        border: '3px solid',
    },
     /** Misc Styles */
    border: '2px solid',
    figCaption: {
        position:'absolute',
        bottom:'5px',
        background:'rgba(0, 0, 0, .5)',
        width:'100%',
        padding:'30px 20px',
        color:'#fff',
        "@media (max-width: 479px)": {
            padding:'10px'
        }
    },
    bodyBackgroundColor: '',
    /** Typography **/
    fontFamily: {
        primaryFont: " 'Montserrat', sans-serif",
        secondaryFont: " 'Montserrat', sans-serif"
    },
    fonts: [ 'Montserrat', 'Montserrat' ],
    h1: {
        fontSize: "65px",
        margin:'20px 0',
        lineHeight: "75px",
        color:'red',
        "@media (max-width: 767px)": {
            fontSize: "55px",
            lineHeight: "65px"
        },
        "@media (max-width: 479px)": {
            fontSize: "45px",
            lineHeight: "55px"
        }
    },
    h2: {
        margin:'20px 0',
        fontSize: '36px',
        lineHeight: '46px',
        color:"green"
    },
    h3: {

        lineHeigth:"34px",
        color:'#4a5663',
    },
    h4: {

        fontSize: '16px',
        lineHeight: '26px',
    },
    h5: {

        fontSize: '16px',
        lineHeight:'26px',
    },
    h6: {

        fontSize:'12px',
        lineHeight:'22px',
    },
    p: {

        margin: '10px 0',
        fontSize: '16px',
        lineHeight: '26px',
    },
    list: {

        marginBottom: '20px',
        paddingLeft: '30px'
    },
    listItem: {
        marginBottom:'10px'
    },
    // Buttons
    button1: {
        display: 'inline-block',
        marginBottom: '15px',
        padding: '10px 25px',
        borderRadius: '3px',
        border:'none',
        fontSize:'14px'
    },
    button2: {
        display: 'inline-block',
        marginBottom: '15px',
        padding: '10px 25px',
        border:'3px solid',
        borderRadius: '3px',
        fontSize:'14px'
    },
    button3: {
        display: 'inline-block',
        marginBottom: '15px',
        padding: '20px 50px',
        borderRadius: '3px',
        fontSize:'14px'
    },
    // Form
    form: {
        height: 'auto',
        padding: "30px",
        width: '100%'
    },
    formErrorMessage: {
        color: "red",
        fontSize:'12px'
    },
    input: {
        height: "38px",
        width: '100%',
        "::placeholder": {},
        fontSize: '16px',
        lineHeight: '16px',
        border: "none",
        borderRadius: '2px',
        padding: "8px 12px",
        marginBottom: '10px'
    },
    selectInput: {
        borderRadius: '2px',
        width: '100%',
        height: "40px",
        opactiy: "0",
        appearance: 'none',
        padding: "10px"
    },
    svgDiv: {
        height: '40px'
    },
    textArea: {
        width: '100%',
        "::placeholder": {},
        fontSize: '16px',
        lineHeight: '16px',
        border: "none",
        borderRadius: '2px',
        padding: "8px 12px",
        marginBottom: '10px'
    },
    checkboxInput:{
        marginRight: "5px",
        marginLeft: "5px",
    },
    checkboxLabel:{},
    radioInput:{
       marginRight: "5px",
       marginLeft: "5px",
    },
    radioLabel:{},
    submitButton:{
        fontWeight: "700",
        border: "none",
        padding: "10px 25px"
    },
    label: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "5px"
    }
};

export default function theme ( global ) {

    global = global ? global : defaultGlobal;

    global.bodyBackgroundColor = global.colors.light.color1;

    generateGlobalObject( global );

    return global;

}
