import { StyleSheet } from "@convertly/css";
import ModuleGlobal from '../../../elements/style-functions/module-global-styles';
import HeaderGlobal from '../header-style-functions/header-styles-global';

const headerStyles6 = ( global, data ) => {


    const moduleGlobal = new ModuleGlobal( global, data );
    const headerGlobal = new HeaderGlobal( global, data );

    let headerStyles = {};

    if( data.position === "overlay" ) {
        headerStyles = {
            position: 'absolute',
            color: global.colors.light.white,
            stroke: global.backgroundColors.light.white,
            background: "transparent"
        }
    }
    if( data.position === "overlayFixed" ) {
        headerStyles = {
            '.absolute': {
                position:'absolute',
                color: global.textColors.light.color,
                stroke: global.backgroundColors.light.white,
                background: "transparent"
            },
        }
    }
    else {
        headerStyles = {}
    }


    return StyleSheet.create({

        header: {
            color: global.textColors.light.color1,
            fontFamily:global.fontFamily.primaryFont,
            lineHeight: '16px',
            stroke: global.backgroundColors.dark.color1,
            transition:'all .25s ease',
            background: '',
            ...headerGlobal,
            ...moduleGlobal,
            ...headerStyles,
        },

    })

};
export default headerStyles6;
