import _ from "lodash";
import { createAction } from "redux-act";
import { store } from "app/app";
import { fetchTemplate } from "../common";
import {
  setProcessing,
  setLeavePanelHook,
  changePanel,
  setActiveNavState,
} from "../../../actions/navBarActions";
import { getBlogPanelState, setTempPageElements } from "../actions";
import { setEditingBlogLayout, setPageElements } from "containers/Editor/actions/editorActions";

import { red, yellow, blue } from "utils";

import { PANELS } from "../../constants";

const Editor = () => store.getState().get("editor").editorReducer;
const Blog = () => getBlogPanelState(store.getState());

export const setTemplate = createAction("BLOGS PANEL: Set layout template");
export const setActiveLayoutType = createAction("BLOGS PANEL: Set layout item type being edited");
export const setActiveLayoutEditingPanel = createAction(
  "BLOGS PANEL: Set the active layout editing panel"
);

export const toggleAddElementPanel = () => async dispatch => {
  await dispatch(changePanel(PANELS.ADD_ELEMENT));
  await dispatch(setActiveNavState(PANELS.ADD_ELEMENT));
};

export const cancel = () => async dispatch => {
  console.log("EditLayout cancel()");
  await dispatch(setProcessing(true));
  await dispatch(resetEditor());
  await Promise.all([dispatch(changePanel(PANELS.BLOG)), dispatch(setProcessing(false))]);
};

export const resetEditor = () => async dispatch => {
  const elements = Blog()
    .get("tempPageElements")
    .toJS();

  await Promise.all([
    dispatch(setPageElements({ elements })),
    dispatch(setActiveLayoutType(null)),
    dispatch(setActiveLayoutEditingPanel(null)),
    dispatch(setLeavePanelHook(null)),
    dispatch(setEditingBlogLayout(false)),
    dispatch(setTemplate(null)),
  ]);
};

export const resetEditorIfLeaveEditLayout = async (activePanel, nextPanel) => {
  const activeLayoutEditingPanel = Blog().get("activeLayoutEditingPanel");

  if (activeLayoutEditingPanel && activeLayoutEditingPanel === activePanel) {
    red(`hook resetEditorIfLeaveEditLayout cancelling()`);
    red(
      `activePanel: ${activePanel}, nextPanel: ${nextPanel}, activeLayoutEditingPanel: ${activeLayoutEditingPanel}`
    );
    await store.dispatch(cancel());
  }
};

export const init = itemType => async dispatch => {
  const editingBlogLayout = Editor().get("editingBlogLayout");
  const pageElements = Editor()
    .get("pageElements")
    .toJS();
  const state = Blog();

  if (editingBlogLayout) {
    blue(`already editing a layout. exiting out of <EditLayout /> init()`);
    return;
  }

  let templateId, activePanel;

  if (itemType === "blogPost") {
    templateId = state.get("blogPostDefaultTemplate");
    activePanel = PANELS.EDIT_LAYOUT_POST_PAGE;
  }

  if (itemType === "blogMaster") {
    templateId = state.get("blogLandingDefaultTemplate");
    activePanel = PANELS.EDIT_LAYOUT_BLOG_MASTER;
  }

  if (itemType === "blogCategory") {
    templateId = state.get("blogCategoryDefaultTemplate");
    activePanel = PANELS.EDIT_LAYOUT_BLOG_CATEGORY;
  }

  const [template] = await Promise.all([
    fetchTemplate(templateId),
    dispatch(setProcessing(true)),
    dispatch(setTempPageElements(pageElements)),
  ]);

  await Promise.all([
    dispatch(setTemplate(template)),
    dispatch(setActiveLayoutType(itemType)),
    dispatch(setPageElements({ elements: template.elements })),
    dispatch(setLeavePanelHook(resetEditorIfLeaveEditLayout)),
    dispatch(setActiveLayoutEditingPanel(activePanel)),
    dispatch(setEditingBlogLayout(true)),
  ]);

  await dispatch(setProcessing(false));
};
