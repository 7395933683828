export function assign(obj, prop, value, removeProp = false) {
  if (typeof prop === "string") prop = prop.split(".");

  if (prop.length > 1) {
    var e = prop.shift();
    assign(
      (obj[e] = Object.prototype.toString.call(obj[e]) === "[object Object]" ? obj[e] : {}),
      prop,
      value
    );
  } else if (removeProp) {
    delete obj[props[0]];
  } else {
    obj[prop[0]] = value;
  }
}

export function removeProp(obj, prop) {
  assign(obj, prop, null, true);
}

export default assign;
