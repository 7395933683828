import { StyleSheet } from "@convertly/css";
import userOverrides from "../../elements/style-functions/user-overrides";
import removeNestedUserOverrides from "../../site-header/site-header/utils/removeNestedUserOverrides";

export const BlogDetailWrapperStyles = ({ global, data, inEditor }) => {
  const blogDetailWrapper = removeNestedUserOverrides({ data });

  return StyleSheet.create({
    blogDetailWrapper: {
      ...userOverrides(global, { userOverrides: blogDetailWrapper }, inEditor)
    }
  });
};
