import { StyleSheet } from "@convertly/css";
import UserOverrides from "../../elements/style-functions/user-overrides";
import removeNestedUserOverrides from "../../site-header/site-header/utils/removeNestedUserOverrides";

export const BlogPreviewMediaStyles = ({ global, data, inEditor }) => {
  const { 
    blogPreviewMediaWrapper, 
    blogPreviewMedia, 
    blogPreviewMediaOverlay 
  } = data.userOverrides || {};

 
  return StyleSheet.create({
    blogPreviewMediaWrapper: {
      // Overlay is position absolute. This prevents overlay from covering everything
      position: data.displayOverlay ? "relative" : "unset",
      ...UserOverrides(global, blogPreviewMediaWrapper, inEditor)
    },

    blogPreviewMedia: {
      backgroundColor: global.colors.accent.color1,
      ...UserOverrides(global, blogPreviewMedia, inEditor)
    },

    blogPreviewMediaOverlay: {
      position: "absolute",
      top: "0",
      left: "0",
      height: "100%",
      width: "100%",
      background: "linear-gradient(0deg, rgba(0, 0, 0, .84), rgba(36, 36, 36, .02))",
      ...UserOverrides(global, blogPreviewMediaOverlay, inEditor)
    }
  });
};