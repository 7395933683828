import { StyleSheet } from '@convertly/css';
import { generatePseudoMediaQuery } from '../../../elements/style-functions/pseudo-media-queries';
import UserOverrides from '../../../elements/style-functions/user-overrides';
import _ from 'lodash';

const navStylesTopLeft = (global, data, inEditor) => {
  let imgLogoUseroverrides = {};

  if(_.get(data, 'logoOverrides')) {
    imgLogoUseroverrides = UserOverrides(global, data.logoOverrides, inEditor);
  }

  return StyleSheet.create({
    mainNavLeft: {
      width: '40%',
      display: 'inline-block',
      paddingTop: '5px',
      paddingLeft: '10px',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
          order: '1',
          width: '70%',
          paddingTop: '9px',
          paddingLeft: '0'
        },
        inEditor
      ),
      ...generatePseudoMediaQuery(
        '@media(max-width: 475px)', ['mobile'], {
          order: '1',
          width: '70%',
          paddingTop: '4px',
          paddingLeft: '0'
        },
        inEditor
      ),
    },
    dot: {
      height: '6px',
      width: '6px',
      backgroundColor: '#F36457',
      borderRadius: '50%',
      display: 'inline-block'
    },
    textLogo: {
      fontFamily: 'rubik',
      fontStyle: 'normal',
      fontSize: '8vw',
      color: global.colors.light.color3,
      letterSpacing: '0.13vw',
      display: 'inline-block',
      lineHeight: '36px',
      ...generatePseudoMediaQuery(
        '@media(min-width: 400px)', ['mobile', 'smallTablet', 'largeTablet'], {
          fontSize: '30px'
        },
        inEditor
      ),
    },
    imgLogo: {
      display: 'inline-block',
      top: '16px',
      ...generatePseudoMediaQuery(
        '@media(max-width: 300px)', ['mobile'], {
          width: inEditor ? '137.5px' : '50vw'
        },
        inEditor
      ),
      ...imgLogoUseroverrides
    },
    logo: {
      display: 'inline-block',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
          display: 'flex',
          justifyContent: 'center',
          flexGrow: '1'
        },
        inEditor
      ),
    }
  })
}

export default navStylesTopLeft;
