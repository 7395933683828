let hasLocalStorage = false;

const test = "test";

const noop = () => {};

try {
  localStorage.setItem(test, test);
  localStorage.removeItem(test);
  hasLocalStorage = true;
} catch (e) {
  hasLocalStorage = false;
}

const setItem = (key, value) => localStorage.setItem(key, value);
const removeItem = key => localStorage.removeItem(key);
const getItem = key => localStorage.getItem(key);
const clear = () => localStorage.clear();

export const LocalStorage = {
  setItem: hasLocalStorage ? setItem : noop,
  removeItem: hasLocalStorage ? removeItem : noop,
  getItem: hasLocalStorage ? getItem : noop,
  clear: hasLocalStorage ? clear : noop
};

export default LocalStorage;
