/*global Inferno, $ */
/* eslint-disable */
import {include} from '@convertly/thor';
import CurbsidePickupWidget from "../../../ecommerce-common-components/CurbsidePickupWidget/CurbsidePickupWidget";

const Script = () => {

        /** Imports **/
        const Graph = include('../../../utils/graph', 'Graph');
        const ConvertToCurrency = include('../../../utils/ecomm-utils/convert-to-currency-ii', 'ConvertToCurrency');
        const Dispatcher = include('../../../utils/dispatcher', 'Dispatcher');
        const Cookie = include('../../../utils/cookie', 'Cookie');

        const FormValidation = include('../../../utils/form-validation', 'FormValidation');
        const AccountsPageNavigationInferno = include('./components/accounts-page-navigation', "AccountsPageNavigationInferno");
        const OrderHistoryInferno = include('./components/order-history', "OrderHistoryInferno");
        const OrderInferno = include('./components/order', "OrderInferno");
        const AccountPreferencesInferno = include('./components/billing-and-shipping', "AccountPreferencesInferno");
        const EditProfileInferno = include('./components/edit-profile', "EditProfileInferno");
        const ShippingAddressInferno = include('./components/shipping-address', "ShippingAddressInferno");
        const GoogleButtonInferno = include( '../../../sign-in-up/google-button/GoogleButton', 'GoogleButtonInferno' );
        const FacebookButtonInferno = include( '../../../sign-in-up/facebook-button/FacebookButton', 'FacebookButtonInferno' );
        const UPButtonInferno = include( '../../../sign-in-up/sign-in-up-form/upSigninButton', 'UPButtonInferno' );
        const SignInScreenInferno = include('./components/sign-in', "SignInScreenInferno");
        const CreateAccountInferno = include('../../../ecommerce-common-components/create-account/create-account', 'CreateAccountInferno');
        const SpinnerSVGInferno = include('../../../svg/spinner/spinner', 'SpinnerSVGInferno');
        const StaySignedInInferno = include('../../../sign-in-up/sign-in-up-form/stay-signed-in/stay-signed-in', 'StaySignedInInferno');
        const CurbsidePickupWidgetInferno = include("../../../ecommerce-common-components/CurbsidePickupWidget/CurbsidePickupWidget", "CurbsidePickupWidgetInferno");
        const OrderStatusInferno = include("../../../ecommerce-common-components/OrderStatus/OrderStatus", "OrderStatusInferno");
        const OrderNotificationsInferno = include( "../../../ecommerce-common-components/OrderNotifications/OrderNotifications", "OrderNotificationsInferno")
        const EditNotificationsModalInferno = include( '../../../ecommerce-common-components/EditNotificationsModal/EditNotificationsModal', "EditNotificationsModalInferno" )
        const GenericModalInferno = include( "../../../ecommerce-common-components/GenericModal/GenericModal", "GenericModalInferno")

        return (id, clientImageUrl, siteLink, siteId) => {

            const root = document.getElementById(id);

            const hasLocalStorage = $('html').hasClass('localstorage');

            /** Init Imports **/

            //Convertly Utils
            const graph = Graph();
            const dispatch = new Dispatcher(id);
            const validateForm = new FormValidation();
            const convertToCurrency = new ConvertToCurrency();
            const SpinnerSvg = new SpinnerSVGInferno();
            const cookie = new Cookie();


            //Components
            const OrderNotifications = OrderNotificationsInferno();
            const GenericModal = GenericModalInferno()
            const StaySignedIn = new StaySignedInInferno( dispatch );
            const GoogleButton = GoogleButtonInferno( dispatch );
            const FacebookButton = FacebookButtonInferno( dispatch );
            const UPButton = UPButtonInferno( dispatch, null, validateForm, SpinnerSvg, StaySignedIn );
            const ShippingAddress = ShippingAddressInferno(dispatch, validateForm);
            const OrderStatus = OrderStatusInferno(dispatch)
            const EditNotificationsModal = EditNotificationsModalInferno(dispatch, graph, GenericModal, SpinnerSvg);
            const CurbsidePickupWidget = CurbsidePickupWidgetInferno(dispatch, graph, OrderStatus, OrderNotifications, EditNotificationsModal)
            const Order = OrderInferno(dispatch, convertToCurrency, clientImageUrl, siteLink, CurbsidePickupWidget, OrderStatus, OrderNotifications, EditNotificationsModal);
            const AccountsPageNavigation = AccountsPageNavigationInferno(dispatch, siteLink, cookie);
            const OrderHistory = OrderHistoryInferno(dispatch, graph, convertToCurrency, clientImageUrl, siteLink, Order);
            const ShippingAndBilling = AccountPreferencesInferno(dispatch, graph, ShippingAddress, SpinnerSvg);
            const EditProfile = EditProfileInferno(dispatch, graph, validateForm, cookie);
            const SignInScreen = SignInScreenInferno(dispatch, siteId, validateForm, GoogleButton, FacebookButton, UPButton );



            //update renderState dispatch event

            const CheckoutWrapper = Inferno.createClass({


                getInitialState: function () {

                    return {

                        renderStage: 1,
                        accountInfo: null,
                        isSignedIn: false,
                        loginToken: null,
                        showOverlay: false,
                        displaySignUp: false,
                        signedOut: false

                    };

                },

                /** gets account info from db **/
                getAccountInformation: function () {

                    const variables = {
                        accessToken: this.state.loginToken

                    };

                    const query = `query ($accessToken: ID!) {
                                      Account(accessToken:$accessToken) { id provider profileData { firstName lastName emailAddress }
                                        shippingAddresses { id defaultAddress firstName lastName address1 address2 city state zip country email }
                                        billingAddresses { id defaultAddress firstName lastName address1 address2 city state zip country email }
                                        orders { orderId date publicId orderTotalCost invoicePDFLink orderStatus
                                                shipping {
                                                    object_id
                                                }
                                            itemsOrdered { id title brand sku image quantity price salePrice }
                                            smsAlert {
                                                enabled, sms
                                              }
                                              messages {
                                                id, timestamp, message, callBackNumber, from
                                              }
                                        }
                                      }
                                    }`;


                    const callback = (error, response) => {

                        if (error) {

                            return false
                        }

                        let account = response.Account;

                        account.orders = account.orders.reverse();

                        console.log(account.orders[0])

                        this.setState({accountInfo: account});


                    };

                    graph({query: query, variables: variables, cb: callback});

                },

                /** updates render state **/
                changeRenderState: function () {
                    dispatch.on('logged_out', () => {
                        this.setState({

                            signedOut: true,
                            receivedAccountInfo: false,
                            token: ''
                        });
                        window.location.href = `${siteLink}/`
                    });

                    dispatch.on('update_render', (stage) => {
                        this.setState({renderStage: stage})
                    })
                },

                /** checks for login token **/
                checkLogIn: function () {
                    let loginToken = cookie.read({name:'authToken'});

                        if (loginToken) {
                            this.setState({
                                loginToken: loginToken,
                                isSignedIn: true
                            });
                        }
                },

                /** toggles sign up screen **/
                toggleSignUpScreen: function () {
                    let newState = !this.state.displaySignUp;
                    this.setState({displaySignUp: newState})
                },

                componentDidUpdate() {
                    if (!this.state.receivedAccountInfo && this.state.isSignedIn) {

                        this.getAccountInformation();
                        this.setState({receivedAccountInfo: true})
                    }
                    dispatch.on('account_updated', () => {
                        this.getAccountInformation();
                    });
                    dispatch.on('account_deleted', () => {
                        this.setState({accountDeleted: true})
                    })
                },
                componentWillMount() {
                    this.checkLogIn();
                    if (!this.state.isSignedIn) {
                        dispatch.watch('accessToken', (token) => {
                            this.setState({
                                loginToken: token,
                                isSignedIn: true
                            });
                            this.getAccountInformation();
                            this.forceUpdate();
                        })
                    }
                    else {
                        this.getAccountInformation();
                        this.setState({receivedAccountInfo: true})
                    }
                },

                handleOverlay: function () {
                    dispatch.on('show_overlay', () => {
                        this.setState({showOverlay: true})
                    });

                    dispatch.on('hide_overlay', () => {
                        this.setState({showOverlay: false})
                    });
                },

                componentDidMount () {
                    this.changeRenderState();
                    this.handleOverlay();
                },


                render: function () {

                    let activeBodyState = null;
                    if (this.state.accountInfo) {
                        if (parseInt(this.state.renderStage) === 3) {
                            activeBodyState =
                                <EditProfile profileData={ this.state.accountInfo.profileData }
                                             provider={ this.state.accountInfo.provider }
                                             rootDiv={ root }
                                             accessToken={ this.state.loginToken }/>
                        }

                        else if (parseInt(this.state.renderStage) === 2) {
                            activeBodyState =
                                <ShippingAndBilling shippingAddresses={ this.state.accountInfo.shippingAddresses }
                                                    accessToken={ this.state.loginToken }
                                                    rootDiv={ root }/>
                        }
                        else {
                            activeBodyState = <OrderHistory orders={this.state.accountInfo.orders} rootDiv={ root }/>
                        }
                    }
                    /** if not logged in show log in screen **/
                    if (!this.state.isSignedIn) {
                        return (

                            <div className="col-sm-10 col-sm-offset-1 col-lg-8 col-lg-offset-2 col-xs-12 col-xs-offset-0">
                                <div className={ `${root.className} login-screen-container` }>
                                    <div
                                        className={ `${root.className} sign-in-container ${ this.state.displaySignUp ? '' : 'active' }` }>

                                        <SignInScreen rootDiv={ root } action={ 'login' } />
                                        <div className={ `${root.className} wrapper` }>
                                            <p className={ `${root.className} login-screen-link` }
                                               onClick={ this.toggleSignUpScreen }>Sign up for an account</p>
                                        </div>
                                    </div>
                                    <div
                                        className={ `${root.className} sign-up-container ${ this.state.displaySignUp ? 'active' : '' }` }>

                                            <SignInScreen rootDiv={ root } action={ 'signup' }  />

                                        <div className={ `${root.className} wrapper` }>
                                            <p className={ `${root.className} login-screen-link` }
                                               onClick={ this.toggleSignUpScreen }>Already have an account</p>
                                        </div>
                                    </div>

                                </div>

                            </div>


                        )
                    }
                    else if (this.state.accountDeleted) {
                        return (
                            <div>
                                <h2 className={ `${root.className} deleted-title` }>Your account has been deleted</h2>
                                <p className={ `${root.className} deleted-text` }>Sorry to see you go. To continue shopping, go <a className={ `${root.className} deleted-link` } href={ `${siteLink}/` }>back home</a></p>
                            </div>
                        )
                    }
                    else if (this.state.signedOut) {
                        return ( <div/> );
                    }
                    /** render accounts page **/
                    else {
                        return (

                            <div>
                                { /** Accounts page Nav **/}
                                <AccountsPageNavigation rootDiv={ root }
                                                        renderStage={ this.state.renderStage }/>

                                { /** Active Body State **/ }
                                { activeBodyState }
                                <div
                                    className={ `${ root.className } overlay ${ this.state.showOverlay ? " active" : '' }` }>
                                </div>
                            </div>
                        )
                    }
                }

            });

            Inferno.render(<CheckoutWrapper />, root);

        };

    }
;

export default Script;
