import React from 'react';
import PropTypes from 'prop-types';
import { css } from "@convertly/css";
import Component from '../../../../rootComponent';
import Types from '../../../../attribute-types';

import { id } from '@convertly/thor';


//Styles

//Scripts

//Components
import Elements from '../../../../elements/index';

//Default Props
import defaultGlobal from '../../../../themes/global.js';


//Component
export default class
EditorModal extends Component {
    constructor ( props ) {
        super( props );
    }


    render() {

        const inlineStyle = this.generateStyleAttribute( );

        let openClass = this.props.data.editorModalOpen ? ' open' : ''; 

        function renderChildren(props) {
            return React.Children.map(props.children, child => {
                return React.cloneElement(child, {
                    moduleId: props.moduleId,
                    moduleSort: props.moduleSort,
                    styles: props.styles,
                    site: props.site,
                    sliderSettings: props.sliderSettings ? props.sliderSettings : null
                });
            })
        }

        

        
        return (


                <Elements.Container>
                    <Elements.Row>
                        <Elements.Column>

                            <div className={ css( this.props.styles.modal ) + openClass } id={"modalWidget_"}
                                { ...this.generateDataEditorAttribute() }
                                onClick={( e ) => {
                                    this.launchElementalEditor( 'modalEditor', e );
                                }}
                                onMouseOver={ (e) => {this.addEditableOutline(e)} }
                                onMouseOut={ (e) => {this.removeEditableOutline(e)} }
                                {...inlineStyle} >

                                    <div className={ css( this.props.styles.modalContentArea ) }
                                        { ...this.generateDataEditorAttribute() }
                                        {...inlineStyle}>

                                        <a className={ css(this.props.styles.closeButton) } id={'closeButton_'} onClick={ this.closeModal }>&times;</a>

                                        {
                                            renderChildren(this.props)
                                        }

                                    
                                    </div>
                                </div>
                            </Elements.Column>
                        </Elements.Row>
                    </Elements.Container>


        )
    }
}

EditorModal.defaultProps = {
    data: {},
    global: defaultGlobal
};
EditorModal.contextTypes = {
    store: PropTypes.object
}
