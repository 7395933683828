import React, { useState, useEffect, useCallback, Fragment, useMemo } from "react";
import { pure } from "recompose";

import {
  Wrapper,
  ActiveSite,
  SitesDropDown,
  Sites,
  Site,
  Thumbnail,
  SiteDetails,
  Link,
  Bottom,
  Divider,
  SearchBarWrapper,
  Chevron,
  ActiveSiteText,
} from "./components";
import { S3_IMAGE_DOMAIN } from "config";
import { SearchBar, SitePlaceHolder } from "ui-components";
import { findObjectByValue } from "utils/helpers";
import { routeToPartnerPage, routeToDashboard } from "router";
import { useOnClickOutside } from "hooks";
import { Underlay } from "components/Underlay";

function sortSites(s) {
  if (!s.size || s.size === 0) return s;

  return s
    .sortBy(s => {
      return s.get("updatedAt");
    })
    .reverse();
}

export const SiteSelector = pure(({ sites, activeSite, activateSite }) => {
  const name = activeSite.get("name");
  const [open, toggleOpen] = useState(false);
  const [sitesArr, updateSitesArr] = useState(sortSites(sites));
  const [searchQuery, updateSearchQuery] = useState("");
  const [updatingActiveSite, setUpdatingActiveSite] = useState(false);

  // handle closing the menu if click outside
  const handleClickOutside = useCallback(() => open && toggleOpen(false), [open]);

  const outsideRef = useOnClickOutside(handleClickOutside);

  //Handles search
  useEffect(() => searchSites(), [searchQuery]);

  //Watches sites props and updates local state
  useEffect(() => updateSitesArr(sortSites(sites)), [sites]);

  //Searches sites based on search query
  const toggleDropdown = useCallback(() => toggleOpen(!open), [open]);

  //finds sites either by domain or name
  const searchSites = useCallback(() => {
    if (searchQuery.length > 0) {
      let newSitesArr = sites.filter(s => {
        const siteNameLower = s.get("name") ? s.get("name").toLowerCase() : "";
        const domainLower = s.get("domain") ? s.get("domain").toLowerCase() : "";
        const lowerCaseQuery = searchQuery.toLowerCase();
        if (siteNameLower.includes(lowerCaseQuery) || domainLower.includes(lowerCaseQuery))
          return s;
      });
      updateSitesArr(sortSites(newSitesArr));
    }
  }, [searchQuery, sites]);

  // Updates site on link click
  // Updated this function to event delegation
  // onClick handler is attached on the <Sites> component
  const updateActiveSite = useCallback(
    e => {
      //pull site id from event target
      const { siteId } = e.target.dataset;

      if (!siteId) return;

      if (siteId === activeSite.get("siteId")) return;

      //find site
      let site = findObjectByValue(sites, "id", siteId);
      //active it and route to dashboard
      updateSearchQuery("");
      toggleOpen(false);
      setUpdatingActiveSite(true);
      activateSite({ site })
        .then(routeToDashboard)
        .then(() => setUpdatingActiveSite(false));
    },
    [sites, activeSite, updatingActiveSite]
  );

  //updates search query
  const updateInput = useCallback(e => updateSearchQuery(e.target.value), []);

  return (
    <>
      <Wrapper ref={outsideRef}>
        {/**Text in header/ launhcer**/}
        <ActiveSite>
          <ActiveSiteText onClick={toggleDropdown}>
            <p>{name}</p>
            <Chevron open={open} onClick={toggleDropdown} />
          </ActiveSiteText>
        </ActiveSite>
        {/**Dropdown**/}
        <SitesDropDown open={open} sites={sitesArr}>
          {/**Searchbar**/}
          <SearchBarWrapper>
            <SearchBar placeholder={"Search By Site Name or Web Address"} cb={updateInput} />
          </SearchBarWrapper>
          {/**Sites Selector**/}
          <Sites onClick={updateActiveSite}>
            {sitesArr.size > 0 ? (
              sitesArr.map((site, i) => {
                const siteName = site.get("name");
                const siteId = site.get("id");
                const thumbnail = site.get("screenshot");
                return (
                  <Fragment key={siteId}>
                    <Site active={siteId === activeSite.get("siteId")}>
                      {thumbnail ? (
                        <Thumbnail src={`${S3_IMAGE_DOMAIN}/${siteId}/${thumbnail}`} />
                      ) : (
                        <Thumbnail as="div">
                          <SitePlaceHolder />
                        </Thumbnail>
                      )}

                      <SiteDetails>
                        <div>{siteName}</div>
                        <Link data-site-id={siteId}>View Site Dashboard</Link>
                      </SiteDetails>
                    </Site>
                    {i !== sites.size - 1 && <Divider />}
                  </Fragment>
                );
              })
            ) : (
              <p>No results found</p>
            )}
          </Sites>
          {/**Footer**/}
          <Bottom>
            <Link onClick={routeToPartnerPage}>Manage Websites</Link>
          </Bottom>
        </SitesDropDown>
      </Wrapper>
      {(open || updatingActiveSite) && <Underlay />}
    </>
  );
});
