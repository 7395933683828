import { StyleSheet } from "@convertly/css";
import UserOverrides from "../../elements/style-functions/user-overrides";
import removeNestedUserOverrides from "../../site-header/site-header/utils/removeNestedUserOverrides";

export const CategoryListStyles = ({ global, data, inEditor }) => {
  const { userOverrides } = data || {},
        categoryList = userOverrides ? removeNestedUserOverrides({ data }) : {},
        { categoryButton, categoryButtonText } = userOverrides || {};

  return StyleSheet.create({
    categoryListStyle: {
      ...UserOverrides(global, { userOverrides: categoryList }, inEditor)
    },
    categoryButton: {
      ...global.button1A,
      ...UserOverrides(global, categoryButton, inEditor)
    },
    categoryButtonText: {
      ...UserOverrides(global, categoryButtonText, inEditor)
    }
  });
};

export default CategoryListStyles;
