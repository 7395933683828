import  colorAssign  from '../colorAssign';

let defaultGlobal = {
    colors: {
        light: {
            color1: '#F0F0F0',
            color2: '#A7A7A7',
            color3: '#4A4A4A',
            white: "#fff"
        },
        dark: {
            color1: '#222222',
            color2: '#1E2234',
            color3: '#a6a9b5',
            black: '#000'
        },
        accent: {
            color1: '#D71819',
            color2: '#005AAC',
            color3: '#667785'
        },
    },
    textColors: {
        light: {},
        dark: {},
        accent: {}
    },
    backgroundColors: {
        light: {},
        dark: {},
        accent: {}
    },

    "moduleDefault": {
        margin:'25px 0',
        padding:'25px 20px',
        fontFamily: "'Roboto Slab', serif"
    },
        themeBorder: {
        border: '3px solid #002550',
    },
    /** Typography **/
    fontFamily: {
        primaryFont: " 'Roboto Slab', serif",
        secondaryFont: " 'Roboto', sans-serif"
    },
    fonts: [ 'Roboto Slab', 'Roboto' ],
    h1: {
        color: "#000",
        fontSize: "65px",
        margin:'20px 0',
        lineHeight: "75px",
        "@media (max-width: 767px)": {
            fontSize: "55px",
            lineHeight: "65px"
        },
        "@media (max-width: 479px)": {
            fontSize: "45px",
            lineHeight: "55px"
        }
    },
    h2: {
        color: "#000",
        margin:'20px 0',
        fontSize: '30px',
        lineHeight: '40px',
        fontFamily: "'Roboto', sans-serif"
    },
    h3: {
        fontSize: '24px',
        lineHeight: '34px',
        color:'#000'
    },
    h4: {
        color: '#A7A7A7',
        fontSize: '24px',
        lineHeight: '34px',
    },
    h5: {
        fontSize: '16px',
        lineHeight:'26px',
    },
    h6: {
        color: '#6f7e90',
        fontSize:'12px',
        lineHeight:'22px',
    },
    p: {
        margin: '10px 0',
        color: '#4A4A4A',
        fontSize: '14px',
        lineHeight: '24px',
    },
    list: {
        marginBottom: '20px',
        paddingLeft: '30px',
        color:'4a4a4a',
    },
    listItem: {
        marginBottom:'10px'
    },
    textLink: {
        color:"#f44336"
    },
    /** Misc Styles */
    border: '2px solid #B7B7B7',
    figCaption: {
        position:'absolute',
        bottom:'5px',
        background:'rgba(0, 0, 0, .5)',
        width:'100%',
        padding:'30px 20px',
        color:'#fff',
        "@media (max-width: 479px)": {
            padding:'10px'
        }
    },

    /** Buttons **/
    button1: {
        display:'inline-block',
        padding: '10px 30px',
        borderRadius: '0px',
        backgroundColor: "#d32f2f",
        fontSize: "16px"
    },
    button2: {
        display: 'inline-block',
        marginBottom: '10px',
        padding: '10px 25px',
        borderRadius: '50px',
        fontSize:'14px'
    },
    button3: {
        display: 'inline-block',
        marginBottom: '10px',
        padding: '10px 25px',
        borderRadius: '3px',
        fontSize:'14px'
    },
    // Form
    form: {
        height: 'auto',
        padding: "30px",
        width: '100%'
    },
    formErrorMessage: {
        color: "red",
        fontSize:'12px'
    },
    input: {
        height: "38px",
        width: '100%',

        "::placeholder": {},
        fontSize: '16px',
        lineHeight: '16px',
        border: "1px solid",
        borderRadius: '2px',
        padding: "8px 12px"
    },
    selectInput: {
        width: '100%',
        height: "40px",
        appearance: 'none',
        padding: "10px",

    },
    svgDiv: {
        height: '40px'
    },
    checkboxInput:{
        marginRight: "5px",
        marginLeft: "5px",
    },
    checkboxLabel: {},

    radioInput:{
        marginRight: "5px",
        marginLeft: "5px",
    },
    radioLabel:{},

    submitButton:{
        fontWeight: "700",
        border: "none",
        padding: "10px 25px"
    },
    label: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "5px"
    },
    bodyBackgroundColor: ''
};

export default function theme ( global ) {

    global = global ? global : defaultGlobal;

    colorAssign( global );

    //Body Background Color
    global.bodyBackgroundColor = global.backgroundColors.light.color1;
    //Button Styles

    const defaultButtonStyles = {
        background: global.backgroundColors.accent.color1,
        color: global.textColors.light.white,
        borderColor: global.backgroundColors.accent.color1,
        fontFamily: global.fontFamily.primaryFont
    };
    const alternateButtonStyles = {
        background: global.backgroundColors.dark.black,
        textColor: global.textColors.light.white,
        fontFamily: global.fontFamily.primaryFont,
        borderColor: global.backgroundColors.dark.black
    };

    // Module Default Wrapper Styles
    global.moduleDefault.fontFamily = global.fontFamily.primaryFont;

    // Theme Border
    global.themeBorder.borderColor = global.backgroundColors.light.color3;

    // Form Styles
    global.form.backgroundColor = global.backgroundColors.light.color2;

    global.input.backgroundColor = global.backgroundColors.light.white;
    global.input.borderColor = global.backgroundColors.dark.color3;
    global.input.color = global.textColors.dark.black;
    global.input.fontFamily = global.fontFamily.primaryFont;
    global.input["::placeholder"].color = global.textColors.dark.color3;
    global.input["::placeholder"].fontFamily = global.fontFamily.secondaryFont;

    global.selectInput.backgroundColor = global.backgroundColors.light.white;
    global.selectInput.borderColor = global.backgroundColors.dark.color3;
    global.selectInput.color = global.textColors.dark.black;
    global.selectInput.fontFamily = global.fontFamily.primaryFont;

    global.svgDiv.backgroundColor = global.backgroundColors.light.white;

    global.checkboxLabel.color = global.textColors.dark.color2;

    global.radioLabel.color = global.textColors.dark.color2;

    global.submitButton.color = global.textColors.light.white;
    global.submitButton.backgroundColor = global.backgroundColors.accent.color2;
    global.submitButton.fontFamily = global.fontFamily.secondaryFont;

    global.label.color = global.textColors.dark.color2;
    global.label.fontFamily = global.fontFamily.secondaryFont;


// typography Styles
    global.h1.color = global.textColors.dark.black;
    global.h2.color = global.textColors.dark.black;
    global.h3.color = global.textColors.dark.black;
    global.h4.color = global.textColors.light.color3;
    global.h5.color = global.textColors.dark.black;
    global.h6.color = global.textColors.dark.black;
    global.p.color = global.textColors.dark.color2;
    global.list.color = global.textColors.dark.color2;

    global.h1.fontFamily = global.fontFamily.primaryFont;
    global.h2.fontFamily = global.fontFamily.secondaryFont;
    global.h3.fontFamily = global.fontFamily.primaryFont;
    global.h4.fontFamily = global.fontFamily.primaryFont;
    global.h5.fontFamily = global.fontFamily.secondaryFont;
    global.h6.fontFamily = global.fontFamily.primaryFont;
    global.p.fontFamily = global.fontFamily.primaryFont;
    global.list.fontFamily = global.fontFamily.secondaryFont;

    global.button1 = {
        ...global.button1,
        ...defaultButtonStyles
    };

    global.button1A = {
        ...global.button1,
        ...alternateButtonStyles
    };

    global.button2 = {
        ...global.button2,
        ...defaultButtonStyles
    };

    global.button2A = {
        ...global.button2,
        ...alternateButtonStyles
    };

    global.button3 = {
        ...global.button3,
        ...defaultButtonStyles
    };

    global.button3A = {
        ...global.button3,
        ...alternateButtonStyles
    };

    return global;


}
