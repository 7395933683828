import {generatePseudoMediaQuery} from "../../../elements/style-functions/pseudo-media-queries";
import {generateMSStyleSheet} from "../../../utils/browserSpecificStyles";

/**
 * Alt styles for the bubble widget in
 * product grid when this displays in the image overlay
 * @param global
 * @param data
 * @returns style object
 */
export default function StyleObject(global, data, inEditor) {
	
	
	const sliderController = {
		height: '20px',
		width: '20px',
		borderRadius: '50%',
		background: global.colors.accent.color1,
	};
	
	return {
		".product-filter-modal-wrapper": {
			display: 'inline-block',
			width: '18%',
			marginRight: '15px',
			...generatePseudoMediaQuery(
				'@media(max-width: 991px)',
				['largeTablet', 'smallTablet', 'mobile'],
				{
					position: 'fixed',
					transition: 'all .3s ease',
					top: '-1500px',
					width: 'fit-content',
					height: '100vh',
					margin: '0 auto',
					zIndex: '45',
					left: '5%',
					'.active': {
						top: '0px'
					}
				},
				inEditor
			),
		},
		".product-filter-container": {
			display: 'inline-block',
			width: '100%',
			
			color: global.colors.dark.color1,
			backgroundColor: global.colors.light.white,
			padding: '20px 10px 10px',
			...generatePseudoMediaQuery(
				'@media(max-width: 991px)',
				['largeTablet', 'smallTablet', 'mobile'],
				{
					position: 'relative',
					width: '90vw',
					height: '100%',
					zIndex: '1',
					overflow: 'scroll'
				},
				inEditor
			),
		},
		
		'.product-filter-mobile-header': {
			display: 'none',
			...generatePseudoMediaQuery(
				'@media(max-width: 991px)',
				['largeTablet', 'smallTablet', 'mobile'],
				{
					display: 'block'
				},
				inEditor
			),
		},
		
		'.product-filter-mobile-header .search-bar-container': {
			display: 'none',
			height: '58px',
			backgroundColor: global.colors.light.color1,
			padding: '10px',
			margin: '15px 0px',
			...generatePseudoMediaQuery(
				'@media(max-width: 991px)', ['largeTablet', 'smallTablet', 'mobile'], {
					display: 'block'
				},
				inEditor
			),
		},
		
		'.product-filter-mobile-header .search-bar-wrapper': {
			display: 'flex',
			border: `1px solid ${ global.colors.light.color3 }`,
			width: '100%',
			'align-items': 'center',
			height: '100%',
		},
		
		'.product-filter-mobile-header-text': {
			display: 'inline-block',
			fontSize: '24px',
			lineHeight: '30px',
		},
		
		'.product-filter-mobile-header .search-bar': {
			width: '100%',
			'.left': {
				order: '2'
			},
			'.right': {
				order: '1',
				width: '97%',
			},
			'.with-x-icon': {
				width: '95%',
				'.left': {
					width: '83%'
				}
			},
		},
		
		'.overlay': {
			display: 'none',
			...generatePseudoMediaQuery(
				'@media(max-width: 991px)',
				['largetTablet', 'smallTablet', 'mobile'],
				{
					display: 'block',
					opacity: '0',
					position: 'fixed',
					top: '-1000px',
					backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5))',
					zIndex: '0',
					transition: "opacity .3s ease",
					'.active': {
						top: 0,
						bottom: 0,
						right: 0,
						left: 0,
						opacity: '1'
					}
					
				},
				inEditor
			),
		},
		
		".product-filter-mobile-row": {
			display: 'block',
			width: '100%'
		},
		
		'.product-filter-refine-text': {
			fontSize: '16px',
			textTransform: 'uppercase',
			fontWeight: 'bold',
			marginBottom: '10px',
			...generatePseudoMediaQuery(
				'@media(max-width: 991px)',
				['largeTablet', 'smallTablet', 'mobile'],
				{
					fontSize: '12px',
					fontWeight: 'normal',
					textAlign: 'center',
					color: global.colors.dark.color3,
					border: `1px solid ${ global.colors.dark.color3 }`,
					padding: '7px',
					width: '54%',
					cursor: 'pointer'
				},
				inEditor
			),
			...generatePseudoMediaQuery(
				'@media(max-width: 767px)',
				['smallTablet', 'mobile'],
				{
					display: 'none'
				},
				inEditor
			),
		},
		'.active .product-filter-refine-text': {
			...generatePseudoMediaQuery(
				'@media(max-width: 991px)',
				['largeTablet', 'smallTablet', 'mobile'],
				{
					fontSize: '16px',
					fontWeight: 'bold',
					textAlign: 'left',
					color: global.colors.light.color3,
					border: `0px solid ${ global.colors.dark.color3 }`,
					cursor: 'none',
					width: '50%',
					backgroundColor: global.colors.light.white,
					padding: '10px',
					paddingLeft: '20px',
					marginBottom: '0px',
					color: global.colors.dark.black,
					display: 'inline-block',
					height: '42px',
					paddingTop: '9px'
				},
				inEditor
			),
		},
		'.product-filter-close-button': {
			display: 'none',
			...generatePseudoMediaQuery(
				'@media(max-width: 991px)',
				['largeTablet', 'smallTablet', 'mobile'],
				{
					display: 'inline-block',
					textDecoration: 'underline',
					width: '50%',
					cursor: 'pointer',
					textAlign: 'right',
					float: 'right'
				},
				inEditor
			),
		},
		'.your-selection-container': {
			background: `${global.colors.light.color2}55`,
			padding: '20px 10px',
			marginBottom: '15px',
		},
		'.your-selection-title': {
			marginTop: '0px',
			color: '#000',
			fontSize: '16px',
			textTransform: 'uppercase'
		},
		".divider": {
			width: '100%',
			height: '1px',
			margin: '7px 0'
		},
		".your-selection-options": {
			display: 'block',
			cursor: 'pointer',
			marginBottom: '5px',
			paddingLeft: '20px',
			color: global.colors.dark.color2,
			":hover ": {
				".remove-filter-icon": {
					opacity: 1
				}
			}
			
		},
		".remove-filter-icon": {
			height: '10px',
			width: '10px',
			fill: global.colors.dark.color3,
			marginLeft: '10px',
			opacity: 0,
			transition: 'opacity .5s ease'
		},
		".filtration-container": {
			width: '100%',
			paddingTop: '20px',
			paddingBottom: '20px',
			borderTop: `1px solid ${ global.colors.dark.color3 }`,
			...generatePseudoMediaQuery(
				'@media(max-width: 991px)',
				['largeTablet', 'smallTablet', 'mobile'],
				{
					display: 'none'
				},
				inEditor
			),
		},
		'.active .filtration-container': {
			...generatePseudoMediaQuery(
				'@media(max-width: 991px)',
				['largeTablet', 'smallTablet', 'mobile'],
				{
					display: 'block',
					paddingLeft: '20px',
					paddingRight: '20px',
				},
				inEditor
			),
		},
		'.filtration-header': {
			cursor: 'pointer',
		},
		'.apply-filter-button-container': {
			height: '17%',
			padding: '15px 10px',
			display: 'none',
		},
		'.apply-filter-button': {
			padding: '9px 15px',
			backgroundColor: global.colors.accent.color3,
			color: global.colors.light.white,
			width: '112px',
			...generatePseudoMediaQuery(
				'@media(max-width: 767px)',
				['smallTablet', 'mobile'],
				{
					width: '100%',
					textAlign: 'center',
				},
				inEditor
			),
		},
		'.active .apply-filter-button-container': {
			...generatePseudoMediaQuery(
				'@media(max-width: 991px)',
				['largeTablet', 'smallTablet', 'mobile'],
				{
					display: 'block'
				},
				inEditor
			),
		},
		".filtration-content": {
			maxHeight: '0px',
			overflow: 'hidden',
			transition: 'max-height .2s ease',
			".active": {
				marginTop: '10px',
				maxHeight: '1000px',
				transition: 'max-height .5s ease',
			}
		},
		
		'.on-sale-checkbox-wrapper': {
			fontStyle: global.fontFamily.secondaryFont,
			marginTop: '36px',
			fontSize: '12px',
			display: 'flex',
			alignItems: 'center'
		},
		
		'.on-sale-checkbox': {
			marginRight: '3px'
		},
		
		".arrow-container": {
			float: 'right',
			".active": {
				transform: 'rotateX(180deg)'
			}
		},
		".filtration-title": {
			display: 'inline',
			fontSize: '13px',
			textTransform: 'uppercase'
		},
		".arrow-down": {
			height: '10px',
			width: '10px',
			fill: global.colors.dark.color1,
		},
		".filtration-option-text": {
			padding: '10px 20px',
			color: global.colors.dark.color3,
			fontSize: '12px',
			cursor: 'pointer',
		},
		//Pricing Slider
		'.price-slider': {
      position: 'relative',
			//height: '61px',
		},
		
		'.price-slider-price': {
      display: 'inline-block',
			'.min': {},
			'.max': {}
		},
		".p-s-c": {
			position: 'relative',
			width: '100%'
		},
		".min-price": {
			height: '5px',
			background: global.colors.accent.color1,
			appearance: 'none',
			position: 'absolute',
			bottom: '1px',
			width: '100%',
			"::-moz-range-track": {
				background: global.colors.accent.color1,
				height: '0px',
				border: '0px',
				padding: '0px',
				pointerEvents:'none'
			},
			
			"::-moz-range-thumb": {
				...sliderController,
				cursor: 'pointer',
				zIndex: '9999999999',
				
			},
			"::-webkit-slider-thumb": {
				appearance: 'none',
				...sliderController,
				cursor: 'pointer',
				position: 'relative',
				bottom: '0px',
				zIndex: '9999999999',
			},
		},
		".max-price": {
			background: "transparent",
			"appearance": 'none',
			position: 'absolute',
			bottom: '4px',
			width: '100%',
			height:'0px',
			...generateMSStyleSheet({
				height: '10px'
			}),
			"::-moz-range-track": {
				background: global.colors.accent.color1,
				pointerEvents:'none',
				height: '0px'
			},
			"::-moz-range-thumb": {
				...sliderController,
				cursor: 'pointer',
				zIndex: '0'
			},
			"::-webkit-slider-thumb": {
				"appearance": 'none',
				...sliderController,
				cursor: 'pointer',
				position: 'relative',
				bottom: '0px'
			}
			
		},
		
		'.price-slider-clear-button': {
			display: 'inline-block',
			marginLeft: '3px',
			fontSize: '10px',
			cursor: 'pointer',
      color: global.colors.light.color3,
      visibility: 'hidden',
      opacity: '0',
      transition: 'visibility 0.7s, opacity 0.7s linear',
      position: 'absolute',
      right: '0',
      top: '4px',
      '.active': {
        visibility: 'visible',
        opacity: '1',
      }
		},
		".prices": {
      marginBottom:'20px',
      display: 'inline-block',
		},
		".price-slider-container": {
			width: '100%',
			height: '5px',
			border: `2px solid ${global.colors.light.color3}`,
			marginTop: '25px',
			position: 'relative',
			background: global.colors.light.color3
		},
		".slider-progress-bar": {
			height: '5px',
			background: global.colors.accent.color1,
			position: 'absolute',
			left: '0',
			right: '0',
			bottom: '-2px'
		},
		".slider-controller": {
			height: '23px',
			width: '23px',
			borderRadius: '50%',
			background: global.colors.accent.color1,
			position: 'relative',
			".start": {
				float: 'left',
				bottom: '9px'
			},
			".end": {
				float: 'right',
				bottom: '9px'
			}
			
		},
		
		'.your-selection-text': {
			fontWeight: 'bold',
			textTransform: 'uppercase'
		}
		
	}
}
