import { fromJS } from "immutable";
import { createReducer } from "redux-act";

import {
  setFilter,
  setElementCategories,
  setSearch,
  setActiveElement,
  setCustomModules,
  setLoaded,
  setPublicModules
} from "./actions";

const initialParams = {
  search: "",
  filter: "ALL",
  elementCategories: [],
  customModules: [],
  activeElement: "ALL",
  loaded: false,
};

const initialState = fromJS(initialParams);

const reducers = {
  [setLoaded]: (state, loaded) => state.merge({ loaded }),

  [setFilter]: (state, filter) => state.merge({ filter }),

  [setCustomModules]: (state, customModules) => state.merge({ customModules }),

  [setElementCategories]: (state, elementCategories) => state.merge({ elementCategories }),

  [setPublicModules]: (state, publicModules) => state.merge({ publicModules }),

  [setSearch]: (state, search) => state.merge({ search }),

  [setActiveElement]: (state, activeElement) => state.merge({ activeElement }),
};

export const AddElementPanelReducer = createReducer(reducers, initialState);
