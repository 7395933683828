import {StyleSheet} from "@convertly/css";
import UserOverrides from "../../elements/style-functions/user-overrides";
import {generatePseudoMediaQuery} from '../../elements/style-functions/pseudo-media-queries';
import {addValueToUserOverrides, createNewCssRules} from '../../utils/edit-userOverrides';
import {generateHeaderOffsetMediaQueries} from '../header-style-functions/fixed-header-media-queries';
import _ from 'lodash';

const headerStyles4 = (global, data, headerPosition, inEditor) => {
	
	let positionFixed = data.position === "fixed";
	const displayHeaderTop = _.get(data, 'headerTopperSettings.display')
	let headerUserOverrides = _.get(data, 'userOverrides') ?
		UserOverrides(global, data, inEditor) :
		{};
	let bodyPaddingMediaQueries = {};
	let headerHeight = _.get(data, 'userOverrides.height') ?
		_.get(data, 'userOverrides.height') :
		displayHeaderTop ? '120px' : '80px';
	
	let headerStyles = {};
	if (data.userOverrides && positionFixed) {
		bodyPaddingMediaQueries = generateHeaderOffsetMediaQueries(data);
	}
	if (headerPosition === "overlay") {
		headerStyles = {
			position: 'absolute',
			color: global.colors.light.white,
			stroke: global.colors.light.white,
			background: "transparent"
		}
	}
	else if (headerPosition === "overlayFixed") {
		headerStyles = {
			'.absolute': {
				position: 'absolute',
				color: global.colors.light.white,
				stroke: global.colors.light.white,
				background: "transparent"
			}
		}
	}
	else if (headerPosition === "fixed") {
		
		headerStyles = {
			position: 'fixed',
			"* body": {
				paddingTop: headerHeight || '0',
				overflowY: 'scroll',
				minHeight: `calc(100vh - ${ headerHeight })` || '100vh',
				...bodyPaddingMediaQueries
			},
		}
	}
	else {
		headerStyles = {}
	}
	
	
	let callOutUserOverrides = _.get(data, 'customHeaderMessage.userOverrides') ?
		UserOverrides(global, data.customHeaderMessage, inEditor) : {};
	
	let navLinksOverrides = {},
		navLinkAdditionalStyles = {},
		borderBottomColor = {},
		socialIconBGOverrides = {},
		callOutTextColorOverrides = {};
	
	if (_.get(data, "menu.userOverrides")) {
		/** map through userOverrides make fill match color **/
		let editednavLinksOverrides = {
			userOverrides: addValueToUserOverrides(_.get(data, "menu.userOverrides"),
				"color",
				["fill"])
		};
		navLinksOverrides = UserOverrides(global, editednavLinksOverrides, inEditor);
		navLinkAdditionalStyles = createNewCssRules(navLinksOverrides, 'color', ['color', 'borderColor', 'fill'])
		borderBottomColor = createNewCssRules(navLinksOverrides, 'color', ['borderColor']);
		socialIconBGOverrides = createNewCssRules(navLinksOverrides, 'color', ['backgroundColor']);
		callOutTextColorOverrides = createNewCssRules(navLinksOverrides, 'color', ['color']);
	}
	
	const navListItemOverrides = (data.menu && data.menu.userOverrides)
		? new UserOverrides(global, data.menu) : {};
	
	if (_.get(data, "menu.userOverrides")) {
		/** map through userOverrides make fill match color **/
		let editednavLinksOverrides = {
			userOverrides: addValueToUserOverrides(_.get(data, "menu.userOverrides"),
				"color",
				["fill"])
		};
		navLinksOverrides = UserOverrides(global, editednavLinksOverrides, inEditor);
		borderBottomColor = createNewCssRules(navLinksOverrides, 'color', ['borderColor']);
		socialIconBGOverrides = createNewCssRules(navLinksOverrides, 'color', ['backgroundColor']);
		callOutTextColorOverrides = createNewCssRules(navLinksOverrides, 'color', ['color']);
	}
	
	return StyleSheet.create({
		headerWrapper: {
			height: 'fit-content'
		},
		header: {
			color: global.colors.dark.color1,
			fontFamily: global.fontFamily.primaryFont,
			lineHeight: '26px',
			backgroundColor: "rgba(0,0,0,0.9)",
			transition: 'all .25s ease',
			width: '100%',
			top: '0',
			zIndex: '10',
			height: displayHeaderTop ? '120px' : '80px',
			...headerStyles,
			...headerUserOverrides
		},
		headerBottom: {
			height: `calc(100% - 40px)` || '40px',
		},
		mobileCart: {
			display: 'none',
			...generatePseudoMediaQuery(
				'@media(max-width:768px)',
				['smallTablet', 'mobile'],
				{
					display: 'block'
				}, inEditor
			)
		},
		header_top: {
			fontFamily: global.fontFamily.primaryFont,
			fontSize: "16px",
			padding: '6px 20px',
			display: 'block',
			height: '40px',
			borderBottom: `1px solid ${global.colors.light.color3}`,
			...borderBottomColor
		},
		header_top_lft: {
			height: '100%',
			display: 'flex',
			paddingTop: "5px",
			alignItems: "center"
		},
		mobileSocialIcons: {
			display: 'none',
			...generatePseudoMediaQuery("@media (max-width: 768px)",
				['smallTablet', 'mobile'], {
					display: 'block',
					marginTop: '15px',
					marginLeft: '15px'
				}, inEditor),
		},
		socialIconContainer: {
			height: '20px',
			width: '20px',
			backgroundColor: global.colors.light.white,
			fill: global.colors.dark.color2,
			borderRadius: '50%',
			backgroundSize: '30px',
			...socialIconBGOverrides
		},
		socialIconWrapper: {
			float: 'left',
			...generatePseudoMediaQuery("@media (max-width: 768px)",
				['smallTablet', 'mobile'], {
					display: 'none'
				}, inEditor),
		},
		connect_us: {
			display: 'inline-flex',
			alignContent: 'center',
			float: 'left',
			color: global.colors.dark.color3,
			fontFamily: global.fontFamily.primaryFont,
			fontSize: "16px",
			lineHeight: "16px",
			margin: "0",
			padding: "0",
			...generatePseudoMediaQuery("@media (max-width: 768px)",
				['smallTablet', 'mobile'],
				{
					width: '100%',
					justifyContent: 'center',
					fontSize: '12px',
					lineHeight: '12px'
				}, inEditor),
		},
		socialIcons: {
			paddingTop: "5px"
		},
		socialIcon: {
			height: '100%',
			width: '100%',
			padding: '3px',
			fill: 'inherit',
			verticalAlign: 'sub'
		},
		customCallOut: {
			fontSize: '14px',
			color: global.colors.light.white,
			...callOutTextColorOverrides,
			...callOutUserOverrides
		},
		mobileCartContainer: {
			display: 'none',
			...generatePseudoMediaQuery(
				"@media(max-width:768px)",
				['mobile', 'smallTablet'],
				{
					display: 'block',
					position: 'absolute',
					right: '20px',
					alignSelf: 'center'
				},
				inEditor
			)
		},
		mobileCart: {
			".headerNavLink": {
				display: 'inline-block',
				cursor: 'pointer',
				padding: '0px 20px',
				fontSize: '18px',
				stroke: 'inherit',
				position: 'relative',
				fill: global.colors.light.white,
				...navListItemOverrides
			},
			".cartIcon": {
				height: '24px',
				marginTop: '-5px',
				verticalAlign: 'middle',
				fill: 'inherit'
			},
			".cartLink": {
				...navLinkAdditionalStyles
			},
			".cartLinkActive": {
				color: global.colors.accent.color1,
				fill: global.colors.accent.color1
			},
		}
		
	})
	
};
export default headerStyles4;
