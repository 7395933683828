import { deeplog } from "../../utils/deeplog";

const regeneratorRuntime = require("regenerator-runtime");
import { frontloadConnect } from "@convertly/react-frontload";
import React from "react";
import Component from "source/rootComponent";
import { css } from "@convertly/css";
import _ from "lodash";
import { emitter } from "../../emitter";

// Style
import { BlogLandingStyles } from "./BlogLandingStyles";

// Util
import { frontLoadOptions } from "../../utils/frontLoadOptions";
import { generateGraphQuery } from "../util/generate-graph-query";
import renderChildren from "../util/renderChildren";
import { doQuery } from "../../utils/doQuery";
import { getPageNumber } from "../util/getPageNumber";
import { releaseBlogKraken } from "../BlogKracken/BlogKracken";

let blogData1 = {};
let totalPostsCount1 = {};
let inEditorData;

const fetchAllBlogPosts = (props, component) => {
  return new Promise(async (resolve, reject) => {

    // Prevents refetching of blogs in the editor
    if (blogData1[props.id]) {
      const newBlogData = {};
      const newTotalPostsCount = {};
      newBlogData[props.id] = blogData1[props.id];
      newTotalPostsCount[props.id] = totalPostsCount1[props.id];

      component.setState({
        loaded: true,
        blogData: newBlogData,
        totalPostsCount: newTotalPostsCount,
      });
      return resolve();
    }

    const siteId = _.get(props, "site.siteId");
    const STORE_KEY = _.get(props, "site.STORE_KEY");
    const DISABLE_PAGINATION = _.get(props, "data.disablePagination");

    const query = generateGraphQuery(props.data.disablePagination, props.inEditor);
    const firstPageCount = props.inEditor ? 1 : await getFirstPageCount(props);

    const { limit, featuredPost, disableStartIndex, author } = props.data;

    let offset = props.data.offset;
    let blogData, totalPostsCount;
    const categories = _.get(props, "page.blogCategories");
    //get start index, default to 0
    const startIndex =
      props.data.startIndex || props.data.startIndex == 0
        ? props.data.startIndex
        : getStartIndex(offset, limit, props.page.originalUrl, firstPageCount);
    //assign variables for query
    const variables = {
      ...(limit && { limit }),
      blogQueryInput: {
        siteId,
        ...(limit && { limit:parseInt( limit ) }),
        ...(startIndex && !disableStartIndex && { startIndex }),
        ...(featuredPost && { featuredPost }),
        ...(categories && { categories }),
        ...(author && { author }),
      },
    };

    const data = await doQuery({ query, variables });
    //get the blogdata query, and scope it in an object by id
    blogData1[props.id] = _.get(data, "data.BlogPosts") || [];
    //Get count if pagination is disabled
    if (!DISABLE_PAGINATION)
      totalPostsCount1[props.id] = _.get(data, "data.BlogPostsCount") - (offset || 0);
    //write that query is loaded
    blogData1[props.id].loaded = true;

    if (props.inEditor) {
      totalPostsCount = component.state.totalPostsCount;
      if (!DISABLE_PAGINATION) {
        totalPostsCount[props.id] = _.get(data, "data.BlogPostsCount") - (offset || 0);
      }
      blogData = component.state.blogData;
      blogData[props.id] = _.get(data, "data.BlogPosts") || [];
      blogData[props.id].loaded = true;

      component.setState({ loaded: true, blogData, totalPostsCount });
    }

    resolve();
  });
};

const getFirstPageCount = async props => {
  const query = `query ($siteId:ID, $pageId:ID) {
                          BlogPageCount( siteId:$siteId pageId:$pageId ) {
                            pageCount 
                            frontPageData
                          }
                        }`;
  const variables = {
    siteId: props.site.siteId,
    pageId: props.page.id,
  };

  const data = await doQuery({ query, variables });

  return _.get(data, "data.BlogPageCount.frontPageData") || 0;
};

const getStartIndex = (offset = 0, limit, slug, frontPageOffset) => {
  let pageNumber = getPageNumber(slug);
  if (isNaN(parseInt(offset))) {
    offset = 0;
  }

  limit = parseInt(limit);
  offset = parseInt(offset);

  if (limit) {
    if (pageNumber != "1" && frontPageOffset !== 0) {
      return (pageNumber - 1) * limit - limit + offset + frontPageOffset;
    } else {
      return (pageNumber * limit) - limit + offset;
    }
  }

  return offset;
};

const calculatePostCount = (isFeatured, count, limit, disablePagination) => {
  if (isFeatured !== "EXCLUSIVE") {
    return {
      type: disablePagination ? "static" : "incrementor",
      count: parseInt(limit),
    };
  }
  return {
    type: disablePagination ? "static" : "incrementor",
    count: parseInt(count > limit ? count : limit),
  };
};

const frontload = async props => fetchAllBlogPosts(props);

class BlogLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogData: {},
      totalPostsCount: {},
    };
  }

  componentWillMount() {
    const limit = _.get(this.props, "data.limit"),
      { id } = this.props,
      { STORE_KEY, siteId } = this.props.site;
    //get blogKraken
    this.blogKraken = this.getStoreValue("blogKraken");
    if (!this.blogKraken) {
      this.updateStoreItem("blogKraken", releaseBlogKraken(STORE_KEY, siteId), "blogKraken");
      this.blogKraken = this.getStoreValue("blogKraken");
    }
    fetchAllBlogPosts(this.props, this);
    if (!blogData1[id] && !this.props.inEditor) {
      this.blogKraken.registerFrontLoad(id);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if( nextProps.data.limit !== this.props.data.limit) {
      fetchAllBlogPosts(this.props, this);
      return true;
    }


    return !!this.props.inEditor;
  }

  render() {
    const { data, global, id, inEditor } = this.props,
      { limit, disablePagination } = data;
    let blogData, totalPostsCount;
    if (inEditor) {

      blogData = this.state.blogData;
      totalPostsCount = this.state.totalPostsCount;
    } else {
      blogData = blogData1;
      totalPostsCount = totalPostsCount1;
    }

    if (!blogData[id] || !Array.isArray(blogData[id]) || blogData[id].length === 0) {
      return null;
    }

    this.styles = BlogLandingStyles({ global, data, inEditor });

    const { blogLanding } = this.styles;


    const inlineStyle = this.generateStyleAttribute();
    return (
      <section
        {...inlineStyle}
        {...this.generateDataEditorAttribute()}
        {...this.generateCustomAttributes()}
        className={css(blogLanding)}
      >
        {renderChildren(this.props, {
          inEditor,
          blogData: blogData[id],
          disablePagination,
          postCount: totalPostsCount[id],
          pageNumber: getPageNumber(this.props.page.slug),
          slug: this.props.page.slug,
          ...(limit && { limit }),
        })}
      </section>
    );
  }
}

module.exports = {
  BlogLanding: frontloadConnect(frontload, frontLoadOptions)(BlogLanding),
};
