/*global Inferno, $ */
import React from 'react';
const Script = (SortByDropdownItem) => {
	return ({
						rootClass,
						sortMeta,
						toggleSwitch
	}) => {
		
		return (
			<div
				className={ `${ rootClass } sort-by-dropdown-menu` }
				data-toggle={ 'sort-by' }>
				{
					sortMeta.map((data, idx) => {
						return (
							<SortByDropdownItem
								rootClass={ rootClass }
								data={ data }
								index={ idx }
								toggleSwitch={ toggleSwitch }
								key={ `sort-by-dropdown-item-${ idx }` }/>
						)
					})
				}
			</div>
		)
	}
}

export default Script;