import { StyleSheet } from '@convertly/css';
import { generatePseudoMediaQuery } from '../../../elements/style-functions/pseudo-media-queries';

const navStylesDropdownMenu = (global, data, inEditor) => {
	

  return StyleSheet.create({
    mainNavDropdownMenu: {
      position: 'absolute',
      display: 'none',
      width: '158px',
      height: 'auto',
      background: global.colors.light.white,
      marginTop: '17px',
      borderRadius: '2px',
      boxShadow: '0px 2px 2px 0px rgba(102, 102, 102, 0.25)',
      '.open': {
        display: 'block'
      },
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)', ['smallTablet'], {
          position: 'relative',
          width: inEditor ? '768px' : '100vw',
          background: global.colors.light.color2,
          marginTop: '0',
          '.open': {
            display: 'block'
          }
        },
        inEditor
      ),
      ...generatePseudoMediaQuery(
        '@media(max-width: 375px)', ['mobile'], {
          position: 'relative',
          width: inEditor ? '375px' : '100vw',
          background: global.colors.light.color2,
          marginTop: '0',
          '.open': {
            display: 'block'
          }
        },
        inEditor
      ),
    }
  })
}

export default navStylesDropdownMenu;
