import { releaseBlogKraken } from "../BlogKracken/BlogKracken";

const regeneratorRuntime = require("regenerator-runtime");
import { deeplog } from "../../utils/deeplog";

import React from "react";
import Component from "source/rootComponent";
import { css } from "@convertly/css";
import { frontloadConnect } from "@convertly/react-frontload";
import { doQuery } from "../../utils/doQuery";
import { singleItemQuery } from "../util/generate-graph-query";

import _ from "lodash";

// Styles
import { BlogDetailWrapperStyles } from "./BlogDetailWrapperStyles";

// Utils
import renderChildren from "../util/renderChildren";
import { frontLoadOptions } from "../../utils/frontLoadOptions";

//let blogData;
let blogData1;

const fetchBlogPost = async (props, component) => {
  const query = singleItemQuery;
  let blogData;
  const variables = {
    id: props.page.id,
    ...(!props.inEditor && { siteId: props.site.siteId }),
  };
  let res = await doQuery({ query, variables });
  blogData1 = _.get(res, "data.BlogPost");
  if (props.inEditor) {
    blogData = _.get(res, "data.BlogPost");
    component.setState({ blogData });
  }
};

const frontload = async props => fetchBlogPost(props, {});
class BlogDetailWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blogData: {},
    };
  }
  componentWillMount() {
    super.componentWillMount();
    if(!_.get(blogData1, this.props.id) || !_.get(this.state.blogData)) fetchBlogPost(this.props, this);
  }
  shouldComponentUpdate(prevProps) {
    this.deeplog(prevProps.page.id !== this.props.page.inEditor, "brick house");
    if (this.props.inEditor) {
      if(!_.get(this.state, 'blogData.slug')) fetchBlogPost(this.props, this);
      return true;
    } else {
      return false;
    }
  }
  render() {
    const { data, inEditor, global } = this.props,
      inlineStyle = this.generateStyleAttribute();
    let blogData;
    if (inEditor) {
      blogData = this.state.blogData;
    } else {
      blogData = blogData1;
    }
    
    if(_.isEmpty(blogData)) {
      return null;
    }

    this.styles = BlogDetailWrapperStyles({ global, data, inEditor });
    const { blogDetailWrapper } = this.styles;
    return (
      <section
        {...inlineStyle}
        {...this.generateDataEditorAttribute()}
        {...this.generateCustomAttributes()}
        className={css(blogDetailWrapper)}
      >
        {blogData ? (
          renderChildren(this.props, {
            inEditor,
            blogData: blogData,
          })
        ) : (
          <p> seems like we are having some issues</p>
        )}
      </section>
    );
  }
}

module.exports = {
  BlogDetailWrapper: frontloadConnect(frontload, frontLoadOptions)(BlogDetailWrapper),
};
