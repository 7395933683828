/*global $, document */
/* eslint-disable */
////import $ from 'jquery';


const PricingInfoScript = ( id ) => {

    return ( id ) => {

        let button = $(`#${id} > div > a`);
        let option = $(`#${id} > .option`);

        button.on('click', (e) => {
            if ($(e.target).hasClass('active')) {
                // do nothing
            } else {
                button.removeClass('active')
                $(e.target).addClass('active')
                option.toggleClass('active')
            }
        })

    }
};

export default PricingInfoScript;
