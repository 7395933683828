/*global $, document */
/* eslint-disable */
////import $ from 'jquery';


const PricingInfoScript = ( id ) => {

    return ( id ) => {

        let launcher = $(`#${id} > .launcher`);
        let lists    = $(`#${id} > .lists`);

        let displayText = "Close Feature List"
        
        let int = 0;

        launcher.on('click', (e) => {

            lists.toggleClass('active');

            if ( int % 2 === 0) {
                launcher[0].innerHTML = "Close Feature List"
                int++;
            } else {
                launcher[0].innerHTML = "View Full Feature List"
                int++;
            }
            

        })

    }
};

export default PricingInfoScript;
