import { StyleSheet } from "@convertly/css";
import UserOverrides from "../../elements/style-functions/user-overrides";

export const ParagraphStyles = ({ global, data, inEditor }) => {
  const userOverrides = new UserOverrides(global, data, inEditor);

  return StyleSheet.create({
    paragraph: {
      ...global.p,
      ...userOverrides
    },
    capCharacters: {
      overflow:'hidden',
      textOverflow: "ellipsis",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      "color": "red!important"
    }
  });
};
