import { connect } from "react-redux";
import { UserDetailMenu as Component } from "../menus/UserDetailMenu";
import { saveUser } from "containers/UserDetail/actions";

const mapStateToProps = state => {
  const UserState = state.get("userdetail");
  const user = UserState.get("user");

  return {
    user: user,
    dirty: !UserState.get("snapshot").equals(user),
    saving: UserState.get("saving")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    save: () => dispatch(saveUser())
  };
};

export const UserDetailMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
