import PropTypes from 'prop-types';
import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { css } from "@convertly/css";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import styles from "../../Editor/components/editor/editor-forms-styles";
import TabStyled from "../../Editor/components/editor/css-Tab-Styles";
import Toggle from "../../Editor/components/editor/toggle";
import ModalWrapper from "./ModalWrapper";
import Dropdown from "react-dropdown";
import styled, { keyframes } from "styled-components";
import Dropzone from "react-dropzone";
import SiteSettingsFooter from "../../SiteSettings/components/SiteSettingsFooter";

import {
  createPage,
  loadPageTemplates,
  setOverlayStyles,
  setCurrentModal,
  updateSiteSettings,
  setPageData,
  setSettings,
  closeModal
} from "../../Editor/actions/editorActions";

const Div = styled.div`
  width: 400px;
  .result {
    padding: 8px 0;
  }

  .Dropdown-root {
    position: relative;
  }

  .Dropdown-control {
    position: relative;
    overflow: hidden;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    color: #333;
    cursor: default;
    outline: none;
    padding: 7px 52px 6px 7px;
    transition: all 200ms ease;
  }

  .Dropdown-control:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  }

  .Dropdown-arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: " ";
    display: block;
    height: 0;
    margin-top: -ceil(2.5);
    position: absolute;
    right: 10px;
    top: 14px;
    width: 0;
  }

  .is-open .Dropdown-arrow {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
  }

  .Dropdown-menu {
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
  }

  .Dropdown-menu .Dropdown-group > .Dropdown-title {
    padding: 4px 5px;
    color: rgba(51, 51, 51, 1.2);
    font-weight: bold;
    text-transform: capitalize;
  }

  .Dropdown-option {
    box-sizing: border-box;
    color: rgba(51, 51, 51, 0.8);
    cursor: pointer;
    display: block;
    padding: 8px 10px;
  }

  .Dropdown-option:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  .Dropdown-option:hover {
    background-color: #f2f9fc;
    color: #333;
  }

  .Dropdown-option.is-selected {
    background-color: #f2f9fc;
    color: #333;
  }

  .Dropdown-noresults {
    box-sizing: border-box;
    color: #ccc;
    cursor: default;
    display: block;
    padding: 8px 10px;
  }
`;

const closeAnimation = keyframes`
  from {
    opacity: 1;
    left:50%;
    transform: translate(-50%, 0%)  scale(1);
  }

  to {
    opacity: 0;
    left:50%;
    transform: translate(-50%, 0%) scale(0.95);
  }
`;

const cssStyles = {
  siteName: {
    border: "1px solid #C6C6C6",
    width: "400px",
    borderRadius: "2px",
    height: "34px",
    padding: "7px 52px 6px 7px",
    outline: "none"
  },
  companyName: {
    border: "1px solid #C6C6C6",
    width: "770px",
    borderRadius: "2px",
    padding: "7px 52px 6px 7px",
    outline: "none",
    marginBottom: "30px"
  },
  label: {
    fontWeight: "normal",
    marginLeft: "8px"
  },
  pageDescription: {
    border: "1px solid #C6C6C6",
    width: "770px",
    borderRadius: "2px",
    padding: "7px 52px 6px 7px",
    outline: "none",
    marginBottom: "30px",
    height: "73px"
  },
  save: {
    backgroundColor: "#45A844",
    height: "35px",
    width: "118.83px",
    borderRadius: "15px",
    cursor: "pointer",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    color: "white",
    fontFamily: "Source Sans Pro",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "20px"
  },
  cancel: {
    height: "35px",
    width: "118.83px",
    borderRadius: "15px",
    border: "1px solid black",
    cursor: "pointer",
    justifyContent: "center",
    display: "flex",
    fontFamily: "Source Sans Pro",
    alignItems: "center",
    marginRight: "20px",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "20px"
  },
  buttons: {
    position: "absolute",
    bottom: 0,
    display: "flex",
    flexDirection: "row-reverse",
    padding: "20px",
    width: "100%"
  },
  browse: {
    width: "84px",
    height: "34px",
    backgroundColor: "black",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "light",
    fontFamily: "Source Sans Pro"
  },
  toggle: {
    display: "flex",
    alignItems: "center"
  },
  socialInput: {
    width: "335px",
    border: "1px solid #C6C6C6",
    borderRadius: "2px",
    padding: "7px 52px 6px 7px",
    outline: "none",
    marginBottom: "30px",
    marginLeft: "10px"
  }
};

const siteCategories = [
  "Business",
  "Online Store",
  "Event",
  "Personal",
  "Portfolio",
  "Restaurant & Food",
  "Other"
];
const timeZones = [
  "Pacific Time (US & Canada)(UTC-08:00)",
  "Mountain Time (US & Canada)(UTC-07:00)",
  "Central Time (US & Canada)(UTC-06:00)",
  "Eastern Time (US & Canada)(UTC-05:00)"
];

export default class SiteSettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Site Settings",
      selectedTime: "",
      selectedCategory: "",
      timeZone: this.props.site.timeZone || "",
      siteCategory: this.props.site.siteCategory || "",
      siteName: "" || this.props.site.siteName || "",
      siteDescription: this.props.site.siteDescription || "",
      siteTitle: this.props.site.siteTitle || "",
      siteKeywords: this.props.site.siteKeywords || "",
      facebookUrl: this.props.site.facebookUrl || "",
      twitterUrl: this.props.site.twitterUrl || "",
      instagramUrl: this.props.site.instagramUrl || "",
      googleAnalytics: this.props.site.googleAnalytics || "",
      icon: "",
      domain: this.props.site.domain,
      on: this.props.site.ssl || false,
      goToTop: this.props.site.goToTop || false
    };
    this._onSelectTime = this._onSelectTime.bind(this);
    this._onSelectCategory = this._onSelectCategory.bind(this);
    this.handleOnChangeName = this.handleOnChangeName.bind(this);
    this.handleOnChangeGoogleAnalytics = this.handleOnChangeGoogleAnalytics.bind(this);
    this.handleOnChangeFacebook = this.handleOnChangeFacebook.bind(this);
    this.handleOnChangeTwitter = this.handleOnChangeTwitter.bind(this);
    this.handleOnChangeInstagram = this.handleOnChangeInstagram.bind(this);
    this.handleOnChangeDomain = this.handleOnChangeDomain.bind(this);
    this.handleOnChangeIcon = this.handleOnChangeIcon.bind(this);
    this.handleOnChangeDescription = this.handleOnChangeDescription.bind(this);
    this.handleOnChangeTitle = this.handleOnChangeTitle.bind(this);
    this.handleOnChangeKeywords = this.handleOnChangeKeywords.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onOpenClick = this.onOpenClick.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleGoToTop = this.handleGoToTop.bind(this);
  }

  _onSelectTime(option) {
    this.setState({ selectedTime: option.label });
    this.setState({ timeZone: option.label });
  }

  _onSelectCategory(option) {
    this.setState({ selectedCategory: option.label });
    this.setState({ siteCategory: option.label });
  }

  handleOnChangeName(e) {
    this.setState({ siteName: e.target.value });
  }

  handleOnChangeFacebook(e) {
    this.setState({ facebookUrl: e.target.value });
  }

  handleOnChangeTwitter(e) {
    this.setState({ twitterUrl: e.target.value });
  }

  handleOnChangeInstagram(e) {
    this.setState({ instagramUrl: e.target.value });
  }

  handleOnChangeGoogleAnalytics(e) {
    this.setState({ googleAnalytics: e.target.value });
  }

  handleOnChangeIcon(e) {
    this.setState({ icon: e.target.value });
  }

  handleOnChangeDescription(e) {
    this.setState({ siteDescription: e.target.value });
  }

  handleOnChangeTitle(e) {
    this.setState({ pageTitle: e.target.value });
  }

  handleOnChangeDomain(e) {
    this.setState({ domain: e.target.value });
  }

  handleOnChangeKeywords(e) {
    this.setState({ pageKeywords: e.target.value });
  }

  handleSave(e) {
    const object = {};

    object.facebookUrl = this.state.facebookUrl;
    object.twitterUrl = this.state.twitterUrl;
    object.instagramUrl = this.state.instagramUrl;
    object.timeZone = this.state.timeZone;
    object.siteCategory = this.state.siteCategory;
    object.siteDescription = this.state.siteDescription;
    object.siteName = this.state.siteName;
    object.siteKeywords = this.state.pageKeywords;
    object.siteTitle = this.state.siteTitle;
    object.domain = this.state.domain;
    object.googleAnalytics = this.state.googleAnalytics;
    object.ssl = this.state.on;
    object.goToTop = this.state.goToTop;

    const payload = {
      id: this.props.currentSite.siteId,
      object: object,
      currentSite: this.props.site,
      sites: this.props.sites
    };

    this.context.store.dispatch(updateSiteSettings(payload));
    this.context.store.dispatch(this.props.closeModal());
    this.context.store.dispatch(setOverlayStyles(null));
  }

  handleCancel(e) {
    this.setState({
      transition: {
        animation: `${closeAnimation} 150ms`
      }
    });

    setTimeout(() => {
      this.context.store.dispatch(closeModal());
      this.context.store.dispatch(setOverlayStyles(null));
    }, 150);
  }

  onDrop(acceptedFiles) {
    const { siteId } = this.props;
    this.context.store.dispatch(uploadImages(acceptedFiles, siteId));
  }

  onOpenClick() {
    this.dropzone.open();
  }

  handleToggle(e) {
    this.setState({ on: !this.state.on });
  }

  handleGoToTop(e) {
    this.setState({ goToTop: !this.state.goToTop });
  }

  render() {
    const defaultTime = this.state.selectedTime || this.state.timeZone || "";
    let timeZoneOptions = timeZones.filter(time => {
      return time !== defaultTime;
    });
    const defaultCategory = this.state.selectedCategory || this.state.siteCategory || "";
    let siteCategoryOptions = siteCategories.filter(category => {
      return category !== defaultCategory;
    });
    const homePageName =
      this.props.site.pages.filter(page => {
        return page.slug === "/";
      })[0].name || "";

    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="fade150"
        transitionEnterTimeout={150}
        transitionLeaveTimeout={150}
      >
        <div className={css(styles.modalUnderlay)} onClick={this.handleCancel} />

        <div className={css(styles.pageSettingsModalWrapper)}>
          <div>
            <div className={css(styles.modalHeader)}>
              Page Settings
              <span className={css(styles.modalCloseIcon)} style={{ float: "right" }}>
                <i
                  onClick={this.handleCancel}
                  className={css(styles.modalCloseIcon) + " material-icons"}
                >
                  close
                </i>
              </span>
            </div>
          </div>

          <TabStyled>
            <Tabs>
              <TabList>
                <Tab>General</Tab>
                <Tab>Domain</Tab>
                <Tab>SEO</Tab>
                <Tab>Analytics</Tab>
                <Tab>Social</Tab>
                <Tab>Advanced</Tab>
              </TabList>

              <TabPanel>
                <div style={{ marginLeft: "15px", marginBottom: "20px" }}>
                  <br />
                  <div>Site Name</div>
                  <input
                    onChange={this.handleOnChangeName}
                    style={cssStyles.siteName}
                    value={this.state.siteName}
                    type="text"
                    placeholder="placeholder"
                  />
                </div>
                <div style={{ marginBottom: "15px", marginLeft: "15px" }}>
                  Site Category
                  <Div>
                    <Dropdown
                      options={siteCategoryOptions}
                      onChange={this._onSelectCategory}
                      value={defaultCategory}
                      placeholder="placeholder"
                    />
                  </Div>
                </div>
                <div style={{ marginLeft: "15px", marginBottom: "20px" }}>
                  <br />
                  <div>Time Zone</div>
                  <Div>
                    <Dropdown
                      options={timeZoneOptions}
                      onChange={this._onSelectTime}
                      value={defaultTime}
                      placeholder="placeholder"
                    />
                  </Div>
                </div>
                <div style={{ marginLeft: "15px", marginBottom: "20px" }}>
                  <br />
                  <div>Add Favicon</div>
                  <p style={{ fontSize: "12px" }}>
                    This icon appears next to your page title in the browser tab. Upload a 32 x 32
                    pixel ICO, PNG, GIF, or JPG{" "}
                  </p>
                  <div style={{ display: "flex", marginBottom: "20px" }}>
                    <input
                      onChange={this.handleOnChangeIcon}
                      style={cssStyles.siteName}
                      value={this.state.icon}
                      type="text"
                      placeholder=""
                    />
                    <div>
                      <Dropzone
                        style={{ margin: 0, border: "none" }}
                        ref={node => {
                          this.dropzone = node;
                        }}
                        onDrop={this.onDrop}
                      >
                        <div style={cssStyles.browse}>Browse</div>
                      </Dropzone>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <label style={{ fontWeight: "normal" }}>
                      Enable “Go To Top” link on web site
                    </label>
                    <Toggle on={this.state.goToTop} callback={this.handleGoToTop} />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div style={{ marginLeft: "15px", marginTop: "26px", marginBottom: "26px" }}>
                  Your domain name is what visitors use to visit your web site. You can set your URL
                  here.
                  <br />
                  Convertly Temporary Domain Name:
                  <a href={"http://domainprefix.convertly.com"}>
                    {"http://domainprefix.convertly.com"}
                  </a>
                  <br />
                  <br />
                  Domain
                  <br />
                  <input
                    style={cssStyles.siteName}
                    onChange={this.handleOnChangeDomain}
                    value={this.state.domain}
                    type="text"
                    placeholder="http://aaamarketing.com"
                  />
                  <br />
                  If you currently do not have a domain name for you company please use a service
                  such as
                  <a href="https://www.godaddy.com" target="_blank">
                    GoDaddy.com
                  </a>{" "}
                  to register a domain name for your new web site.
                </div>
              </TabPanel>
              <TabPanel>
                <div style={{ marginLeft: "15px", marginTop: "26px", marginBottom: "26px" }}>
                  Site Title
                  <p
                    style={{
                      fontSize: "12px",
                      marginTop: "5px"
                    }}
                  >
                    {
                      "A page’s titles are displayed by search engines and displayed at the top of the page when the site is loaded. It is important for search engines and sharing on social media sites. Keep it between 55-70 characters."
                    }
                  </p>
                  <div>
                    <input
                      style={cssStyles.companyName}
                      onChange={this.handleOnChangeTitle}
                      value={this.state.siteTitle}
                      type="text"
                      placeholder=""
                    />
                  </div>
                  Site Description
                  <p
                    style={{
                      fontSize: "12px",
                      marginTop: "5px"
                    }}
                  >
                    {
                      "Describe this page in 155 characters or less. It helps people know what this page is about as well as being displayed by search engines."
                    }
                  </p>
                  <div>
                    <textarea
                      onChange={this.handleOnChangeDescription}
                      value={this.state.siteDescription}
                      style={cssStyles.pageDescription}
                      type="text"
                      placeholder=""
                    />
                  </div>
                  Keywords
                  <p
                    style={{
                      fontSize: "12px",
                      marginTop: "5px"
                    }}
                  >
                    {
                      "These are words or terms used to describe this page. Separate each keywords or phrases with a comma."
                    }
                  </p>
                  <div>
                    <input
                      style={cssStyles.companyName}
                      onChange={this.handleOnChangeKeywords}
                      value={this.state.siteKeywords}
                      type="text"
                      placeholder=""
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div style={{ marginLeft: "15px", marginTop: "26px", marginBottom: "26px" }}>
                  Google Analytics
                  <div style={{ marginTop: "10px", marginBottom: "10px", fontSize: "12px" }}>
                    <span>{"Add account information to track visitors in Google Analytics. "}</span>
                    <a>Click here</a>
                    <span>{" to learn more."}</span>
                  </div>
                  <input
                    style={cssStyles.companyName}
                    onChange={this.handleOnChangeGoogleAnalytics}
                    value={this.state.googleAnalytics}
                    type="text"
                    placeholder=""
                  />
                </div>
              </TabPanel>
              <TabPanel>
                <div style={{ marginLeft: "20px", marginTop: "26px", marginBottom: "26px" }}>
                  <p style={{ width: "770px" }}>
                    Use the below input fields to place links to your appropriate social media
                    account. These links will be tied to icons throughout your web site.
                  </p>
                  <br />
                  <div>
                    <i
                      style={{
                        color: "white",
                        backgroundColor: "#334F8D",
                        padding: "7.96px 12.9px 7.6px 12.47px",
                        fontSize: "18px",
                        borderRadius: "35px",
                        height: "34px",
                        width: "34px"
                      }}
                      className="fa fa-facebook"
                      aria-hidden="true"
                    />
                    <input
                      style={cssStyles.socialInput}
                      onChange={this.handleOnChangeFacebook}
                      value={this.state.facebookUrl}
                      type="text"
                      placeholder=""
                    />
                  </div>

                  <div>
                    <i
                      style={{
                        color: "white",
                        backgroundColor: "#4BA3EB",
                        padding: "10.24px 9.04px 10.23px 8.5px",
                        fontSize: "18px",
                        borderRadius: "25px",
                        height: "34px",
                        width: "34px"
                      }}
                      className="fa fa-twitter"
                      aria-hidden="true"
                    />
                    <input
                      style={cssStyles.socialInput}
                      onChange={this.handleOnChangeTwitter}
                      value={this.state.twitterUrl}
                      type="text"
                      placeholder=""
                    />
                  </div>

                  <div>
                    <i
                      style={{
                        color: "white",
                        background:
                          "linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)",
                        filter:
                          "progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 )",
                        padding: "7.96px 7.94px 7.96px 8.6px",
                        fontSize: "18.5px",
                        borderRadius: "25px",
                        height: "34px",
                        width: "34px"
                      }}
                      className="fa fa-google"
                      aria-hidden="true"
                    />
                    <input
                      style={cssStyles.socialInput}
                      onChange={this.handleOnChangeInstagram}
                      value={this.state.instagramUrl}
                      type="text"
                      placeholder=""
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div style={{ marginLeft: "20px", marginTop: "26px", marginBottom: "26px" }}>
                  advanced panel contents go here.
                </div>
              </TabPanel>
            </Tabs>
          </TabStyled>

          <SiteSettingsFooter handleSave={this.handleSave} {...this.props} />

          {/*
				<div style={cssStyles.buttons}>
					<div style={cssStyles.save} onClick={this.handleSave}>Save Changes</div>
					<div onClick={this.handleCancel} style={cssStyles.cancel}>Cancel</div>
				</div>
        */}
        </div>
      </ReactCSSTransitionGroup>
    );
  }
}

SiteSettingsModal.contextTypes = {
  store: PropTypes.object
};
