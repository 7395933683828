import React from "react";
import Component from "source/rootComponent";
import { css } from "@convertly/css";
import { id } from "@convertly/thor";
import _ from "lodash";
import { prepStringForInferno} from "../../utils/prep-string-for-inferno";

// Inferno Component
import BlogPreviewSliderInfernoComponent from "./inferno-component/BlogPreviewSliderInfernoComponent";
import Elements from "../../elements";

// Style
import { BlogPreviewSliderStyles } from "./BlogPreviewSliderStyles";

// Util
import formatDate from "../util/formatDate";
import { clientImage } from "../../utils/generateImage";

export class BlogPreviewSlider extends Component {
  render() {
    const { blogData, global, data, inEditor } = this.props;

    const { maxShown } = data || {};

    if (!blogData || !Array.isArray(blogData) || blogData.length === 0) {
      return null;
    }

    this.styles = BlogPreviewSliderStyles({ global, data, inEditor });

    const { blogPreviewSliderWrapper } = this.styles;

    let formattedBlogData = JSON.parse(JSON.stringify(blogData));
    formattedBlogData.forEach( post => {
      post.shortDescription = prepStringForInferno(post.shortDescription)
    } );

    for (let obj of formattedBlogData) {
      let tempDate = obj.date;
      obj.date = formatDate(tempDate);
    }

    const rootUrl = clientImage("");
    const inlineStyle = this.generateStyleAttribute();

    if (inEditor) {
      const rootClass = css(blogPreviewSliderWrapper);
      let arrOfBlogs = [];
      let arrOfPreviews = [];
      let tmpArr = [];
      let blogDataLength = blogData.length;

      for (let i = 0; i < blogDataLength; i++) {
        const currentEl = blogData[i];
        const blogItemDataValue = `${rootUrl}-${i}`;

        const {
          postTitle,
          date,
          previewImage,
          author,
          categories,
          shortDescription,
          slug
        } = currentEl;

        tmpArr.push(
          <div
            className={`${rootClass} blog-list ${i === 0 ? "active" : ""}`}
            key={`blog-list-${i}`}
            data-blog-item={blogItemDataValue}
            onClick={this.cancelClick}
          >
            <div className={`${rootClass} blog-list-title`}>{postTitle}</div>

            <div className={`${rootClass} blog-list-subtitle`}>
              <span className={`${rootClass} blog-list-date`}>{formatDate(date)}</span> |{" "}
              <span className={`${rootClass} blog-list-author`}>{author.name}</span>
            </div>
          </div>
        );

        if ((i + 1) % maxShown === 0 || i === blogDataLength - 1) {
          arrOfBlogs.push(tmpArr);
          tmpArr = [];
        }

        arrOfPreviews.push(
          <a
            className={`${rootClass} blog-preview-link ${i === 0 ? "active" : ""}`}
            key={`blog-preview-${i}`}
            data-blog-item={blogItemDataValue}
            href={inEditor ? "#" : slug || "/"}
          >
            <div className={`${rootClass} blog-preview`}>
                {previewImage && <Elements.Image
                    data={{
                      src:previewImage.src,
                      imgParams:previewImage.imgParams,
                      alt:previewImage.alt,
                      title:previewImage.title
                    }}
                    parentClass={`${rootClass} blog-preview-media`}
                />}


              <div className={`${rootClass} blog-preview-content`}>
                <div className={`${rootClass} blog-preview-subtitle`}>
                  <span className={`${rootClass} blog-preview-date`}>{formatDate(date)}</span>
                  {author.name ? "  |  " : ""}
                  <span className={`${rootClass} blog-preview-author`}>{author.name}</span>
                  {categories.length ? "  | in " : ""}
                  {categories.map((category, idx) => {
                    let categoryLength = categories.length;
                    if (categoryLength === 1) {
                      return (
                        <span
                          key={`data-blog-category-${idx}`}
                          className={`${rootClass} blog-preview-category`}
                        >
                          {category.name}
                        </span>
                      );
                    } else if (categoryLength === 2 && idx !== categoryLength - 1) {
                      return (
                        <span
                          key={`data-blog-category-${idx}`}
                          className={`${rootClass} blog-preview-category`}
                        >
                          {category.name}{" "}
                        </span>
                      );
                    } else {
                      return idx === categoryLength - 1 ? (
                        <span
                          key={`data-blog-category-${idx}`}
                          className={`${rootClass} blog-preview-category`}
                        >
                          and {category.name}
                        </span>
                      ) : (
                        <span className={`${rootClass} blog-preview-category`}>
                          {category.name},{" "}
                        </span>
                      );
                    }
                  })}
                </div>

                <div className={`${rootClass} blog-preview-title`}>{postTitle}</div>

                <div className={`${rootClass} blog-preview-short-description`}>
                  {shortDescription}
                </div>
              </div>
            </div>
          </a>
        );
      }

      return (
        <div
          {...inlineStyle}
          {...this.generateDataEditorAttribute()}
          {...this.generateCustomAttributes()}
          className={rootClass}
        >
          <div className={`${rootClass} blog-preview-slider`}>
            <div className={`${rootClass} blog-list-wrapper`}>
              {arrOfBlogs.map((blogSet, idx) => {
                return (
                  <div
                    key={`data-blog-list-item-${idx}`}
                    className={`${rootClass} blog-list-set ${idx === 0 ? "active" : ""}`}
                    data-blog-list-page={idx + 1}
                  >
                    {blogSet.map(blog => blog)}
                  </div>
                );
              })}

              <button
                className={`${rootClass} blog-list-button blog-list-next-button`}
                onClick={this.cancelClick}
              >
                &#x25BC;
              </button>
            </div>

            <div className={`${rootClass} blog-preview-wrapper`}>{arrOfPreviews.map(el => el)}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          id={id(
            BlogPreviewSliderInfernoComponent,
            JSON.stringify(formattedBlogData),
            maxShown,
            rootUrl
          )}
          {...inlineStyle}
          {...this.generateDataEditorAttribute()}
          {...this.generateCustomAttributes()}
          className={css(blogPreviewSliderWrapper)}
        />
      );
    }
  }
}
