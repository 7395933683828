import findElementObject from "./findElementObject";
export function findObjectBeingEdited(pageElements, id, moduleId) {
  let objectBeingEdited;
  if (moduleId) {
    pageElements.forEach((element, index) => {
      if (element.id === moduleId) {
        objectBeingEdited = findElementObject(element, id);
      }
    });
  } else {
    pageElements.forEach((element, index) => {
      const elementIsBelowTargetElement = element.id <= id;
      let nextElementIsAboveTargetElement;
      if (pageElements[index + 1] === undefined) {
        if (elementIsBelowTargetElement) {
          objectBeingEdited = findElementObject(element, id);
        }
      } else {
        nextElementIsAboveTargetElement = pageElements[index + 1].id > id;
        if (elementIsBelowTargetElement && nextElementIsAboveTargetElement) {
          objectBeingEdited = findElementObject(element, id);
        }
      }
    });
  }
  return objectBeingEdited;
}

export default findObjectBeingEdited;
