import { createAction } from "redux-act";
import _ from "lodash";
import { fromJS } from "immutable";
import { store } from "app/app";
import { graph, getActiveUserId, delay } from "utils";
import { getActiveSiteId, updateSite } from "app/services/sites";
import { Alerts } from "services/Alerts";
import { PANELS } from "../constants";
import { showLoadingIcon } from "containers/App/actions";

const editorState = key => {
  const Editor = store.getState().get("editor").editorReducer;

  if (!key) {
    return Editor;
  }
  return Editor.get(key);
};

const themeState = key => {
  const theme = store.getState().get("editor").Themes;
  if (!key) return theme;
  return theme.get(key);
};

import { setSiteData } from "containers/Editor/actions/editorActions";
import { fetchThemes } from "containers/PartnersDashboard/actions";
import { setProcessing, activateNavState } from "../../actions/navBarActions";

/////////////////////////////////

export const setSnapshot = createAction("THEMES PANEL: Set snapshot");
export const setActiveStyle = createAction("THEMES PANEL: Set the active style");
export const setActiveElement = createAction("THEMES PANEL: Set active element");
export const setThemeData = createAction("THEMES PANEL: Set theme data");
export const setLivePreview = createAction("THEMES PANEL: Set Live Preview");
export const setResetThemeReducer = createAction("THEMES PANEL: Reset Theme Reducer Data");
export const setIsNewTheme = createAction("THEMES PANEL: Set state in selection save modal");
export const setThemes = createAction("THEMES PANEL: Set themes");
export const setNewThemeName = createAction("THEMES PANEL: Set new theme name");
export const setSearch = createAction("THEMES PANEL: Set current search query");
export const setThumbnail = createAction("THEMES PANEL: Set Theme");
export const setThemeName = createAction("THEMES PANEL: Set Theme");
export const setUpdateThemeData = createAction("THEMES PANEL: Update Theme Data");
export const setLoaded = createAction("THEMES PANEL: Set loaded flag");
export const resetSaveModal = createAction("THEMES PANEL: Reset SaveModal");
export const setResetState = createAction("THEMES PANEL: Reset State");

export const init = () => async dispatch => {
  await Promise.all([dispatch(setProcessing(true)), dispatch(loadThemes())]);

  if (await dispatch(getThemeData())) {
    await Promise.all([dispatch(takeSnapshot()), dispatch(setLoaded(true))]);
  }

  await dispatch(setProcessing(false));
};

export const loadThemes = () => async dispatch => {
  let themes = await dispatch(fetchThemes());

  const Editor = store.getState().get("editor").editorReducer;
  const siteId = Editor.getIn(["currentSite", "siteId"]);

  const query = `
  query GetSiteThemes ($siteId:ID!){
    getSiteThemes(siteId:$siteId){
      id, themeName, thumbnail, siteId
    }
  }
`;

  const variables = {
    siteId,
  };

  const res = await graph({ query, variables });

  const customThemes = res.getSiteThemes;

  themes = themes
    .map(theme => ({
      themeName: theme.themeName,
      isGlobal: true,
      thumbnail: theme.previewUrl,
      id: theme.id,
      siteId: theme.siteId,
    }))
    .concat(
      customThemes.map(theme => ({
        themeName: theme.themeName,
        isGlobal: false,
        thumbnail:
          theme.thumbnail ||
          "https://convertlyimguploadeast.s3.amazonaws.com/convertly/ui-components/assets/icons/theme-thumb-01.svg",
        id: theme.id,
        siteId: theme.siteId,
      }))
    );
  await dispatch(setThemes(fromJS(themes)));
};

export const takeSnapshot = () => async dispatch => {
  const themeData = themeState().get("themeData");
  const themeName = themeState().get("themeName");
  const thumbnail = themeState().get("thumbnail");

  const snapshot = {
    themeData,
    themeName,
    thumbnail,
  };

  await dispatch(setSnapshot(snapshot));
};

export function revertSnapshot() {
  return async dispatch => {
    const resetState = store
      .getState()
      .get("editor")
      .Themes.get("resetState");

    const Editor = store.getState().get("editor").editorReducer;
    const site = Editor.get("currentSite");

    const snapshot = themeState().get("snapshot");

    const livePreview = themeState().get("snapshot");

    if (livePreview) await dispatch(setSiteData(site.set("themeData", snapshot.get("themeData"))));
    else await dispatch(setLivePreview(true));

    await dispatch(setResetState(resetState + 1));

    await dispatch(setThemeData(snapshot.get("themeData")));
    await dispatch(setThemeName(snapshot.get("themeName")));
    await dispatch(setThumbnail(snapshot.get("thumbnail")));
  };
}

export const styleClick = selectedOption => async dispatch => {
  const resetState = store
    .getState()
    .get("editor")
    .Themes.get("resetState");

  const activeStyle = store
    .getState()
    .get("editor")
    .Themes.get("activeStyle");

  if (selectedOption.key !== activeStyle) {
    await dispatch(setResetState(resetState + 1));
    await dispatch(setActiveStyle(selectedOption));
  }
};

export const updateFont = font => {
  // calls updateValue and loads the font
  // const fontsToLoad = fonts.map(font => `${font}:400,700`);

  const fontToLoad = `${font}:400,700`;

  window.WebFontConfig = { google: { families: [fontToLoad] } };

  const wf = document.createElement("script");
  const s = document.scripts[0];
  wf.src = "https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js";
  wf.async = false;
  s.parentNode.insertBefore(wf, s);
};

export const setSliderThemeData = sliderValues => async dispatch => {
  const themeData = mergeStateFields({
    themeData: themeState("themeData"),
    stateFields: sliderValues,
  });

  const site = editorState("currentSite");
  await dispatch(setSiteData(site.set("themeData", themeData)));
};

const mergeStateFields = ({ themeData, stateFields }) => {
  const is = (keyValue, key) => keyValue.indexOf(key) !== -1;

  Object.keys(stateFields).forEach(keyValue => {
    if (is(keyValue, "fontSize")) {
      const element = keyValue.replace("fontSize", "");
      const value = `${stateFields[keyValue]}px`;
      themeData = themeData.setIn([element, "fontSize"], value);
    }
  });

  return themeData;
};

export const updateThemeName = (field, e, empty, undefined) => async dispatch => {
  let value = _.get(e, "target.value");
  if (value === undefined) {
    value = e;
  }

  await dispatch(setNewThemeName(value));
};

/**
 *
 * @param element - element in themeData to be updated. ex p, h1, h2, etc
 * @param property - css property to be updated in camel case
 * @param value - new css property value
 * @param extraField - used for updating color values, and shorthand side values
 * @param doNotUpdateStore - flag for updating the Themes reducer
 * @param stateFields - properties being managed by component state.
 * @returns {Function}
 */
export const updateValue = (
  element,
  property,
  value,
  extraField,
  doNotUpdateStore,
  stateFields
) => async dispatch => {
  const livePreview = themeState("livePreview");
  let themeData = themeState("themeData");
  const shouldUpdateStore = !doNotUpdateStore;

  const pixelsProps = new Set([
    "fontSize",
    "borderRadius",
    "lineHeight",
    "letterSpacing",
    "horizontalPosition",
    "verticalPosition",
    "padding",
    "marginRight",
    "marginBottom",
  ]);

  const textProps = new Set(["fontWeight", "fontStyle", "textDecoration", "textAlign"]);

  const positionValues = new Set([
    "left top",
    "center top",
    "right top",
    "left center",
    "center center",
    "right center",
    "left bottom",
    "center bottom",
    "right bottom",
  ]);

  themeData = mergeStateFields({ themeData, stateFields });

  /*
  lots of variations here because there's shorthand and no shorthand for
  different properties. Maybe in the future format themeData to be same?
  */

  if (!property) {
    // bodyBackgroundColor
    themeData = extraField
      ? themeData.setIn([element], extraField)
      : themeData.setIn([element], value);
  } else if (pixelsProps.has(property)) {
    if (element === "bodyBackgroundImage" && property === "horizontalPosition") {
      const values = themeData.getIn([element, "backgroundPosition"]).split(" ");
      values[1] = String(value) + "px";
      themeData = themeData.setIn([element, "backgroundPosition"], values.join(" "));
    } else if (element === "bodyBackgroundImage" && property === "verticalPosition") {
      const values = themeData.getIn([element, "backgroundPosition"]).split(" ");
      values[3] = String(value) + "px";
      themeData = themeData.setIn([element, "backgroundPosition"], values.join(" "));
    } else {
      if (property === "padding") {
        const values = themeData.getIn([element, "padding"]).split(" ");
        if (extraField === "spacing-height") values[0] = String(value) + "px";
        else values[1] = String(value) + "px";
        themeData = themeData.setIn([element, property], values.join(" "));
      } else {
        themeData = themeData.setIn([element, property], String(value) + "px");
      }
    }
  } else if (positionValues.has(value)) {
    // background position vertical and horizontal
    let values = themeData.getIn([element, "backgroundPosition"]).split(" ");
    let horizontal = value.split(" ")[0];
    let vertical = value.split(" ")[1];

    [values[0], values[2]] = [horizontal, vertical];
    values = values.join(" ");
    themeData = themeData.setIn([element, "backgroundPosition"], values);
  } else if (textProps.has(property)) {
    // font style, bold, italic, underline
    if (!themeData.getIn([element, property])) themeData = themeData.setIn([element, property], "");
    themeData.getIn([element, property]) === value
      ? (themeData = themeData.setIn([element, property], ""))
      : (themeData = themeData.setIn([element, property], value));
  } else if (
    property !== "color" &&
    property !== "background" &&
    property !== "borderColor" &&
    element !== "colors" &&
    element !== "bodyBackgroundImage"
  ) {
    /*
      font change for both fontFamily and fonts
      - value is the primary font
      - extraField is the secondary font
    */

    const [primaryFont, secondaryFont] = [...themeData.get("fonts")];

    if (!(element === "fonts" || element === "fontFamily")) {
      const elementFont = themeData.getIn([element, "fontFamily"]);

      const font = elementFont === "primaryFont" ? primaryFont : secondaryFont;
      await updateFont(font);
      themeData = themeData.setIn([element, "fontFamily"], value);
    } else {
      // primaryFont or secondaryFont change
      await updateFont(value);

      // fontFamily update
      themeData = themeData.setIn(["fontFamily", property], `'${value}', ${extraField}`);

      // fonts update
      if (property === "primaryFont") {
        themeData = themeData.setIn(["fonts", 0], value);
      } else {
        themeData = themeData.setIn(["fonts", 1], value);
      }
    }
  } else if (extraField) {
    // extraField is the color key
    if (element === "colors") {
      // updates all elements using preset colors
      const presetTitle = property + extraField.slice(-1);
      // for (let el in themeData) {
      themeData.keySeq().forEach(el => {
        if (themeData.getIn([el, "color"]) === presetTitle) {
          themeData = themeData.setIn([el, "color"], presetTitle);
        } else if (
          themeData.getIn([el, "background"]) === presetTitle ||
          themeData.getIn([el, "backgroundColor"]) === presetTitle
        ) {
          themeData = themeData.setIn([el, "background"], presetTitle);
          themeData = themeData.setIn([el, "backgroundColor"], presetTitle);
        } else if (themeData.getIn([el, "borderColor"]) === presetTitle) {
          themeData = themeData.setIn([el, "borderColor"], presetTitle);
        }
      });

      // now this updates the preset color
      themeData = themeData.setIn([element, property, extraField], value);
    } else {
      themeData = themeData.setIn([element, property], extraField);
      if (property === "background")
        themeData = themeData.setIn([element, "backgroundColor"], extraField);
    }
  } else {
    if (element === "bodyBackgroundImage" && property === "backgroundImage") {
      themeData = themeData.mergeIn([element], {
        backgroundImage: value,
        backgroundAttachment: "auto",
        backgroundPosition: "center 0px center 0px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      });
    } else {
      themeData = themeData.setIn([element, property], value);
      if (property === "background")
        themeData = themeData.setIn([element, "backgroundColor"], value);
    }
  }

  const updates = [];

  if (livePreview) {
    const site = editorState("currentSite");
    updates.push(dispatch(setSiteData(site.set("themeData", themeData))));
  }

  if (shouldUpdateStore) {
    updates.push(dispatch(setThemeData(themeData)));
  }

  if (shouldUpdateStore) {
    await Promise.all(updates);
  } else {
    Promise.all(updates);
  }
};

export const updateStore = (fields, e) => async dispatch => {
  const [element, property, extraField, doNotUpdateStore] = [...fields];

  let value = _.get(e, "target.value");
  if (value === undefined) {
    value = e;
  }
  await dispatch(updateValue(element, property, value, extraField, doNotUpdateStore));
};

export const changeLivePreview = livePreview => async dispatch => {
  // themes reducer data
  const themeData = themeState("themeData");
  const livePreview = themeState("livePreview");
  const site = editorState("currentSite");

  if (livePreview) {
    const snapshotThemeData = themeState().getIn(["snapshot", "themeData"]);

    await Promise.all([
      dispatch(setLivePreview(false)),
      dispatch(setSiteData(site.set("themeData", snapshotThemeData))),
    ]);
  } else {
    await Promise.all([
      dispatch(setLivePreview(true)),
      dispatch(setSiteData(site.set("themeData", themeData))),
    ]);
  }
};

export const formatFonts = themeData => {
  const [primaryFont, secondaryFont] = [...themeData.get("fonts")];

  themeData.keySeq().forEach(element => {
    if (
      themeData.hasIn([element, "fontFamily"]) &&
      (themeData.getIn([element, "fontFamily"]) !== "primaryFont" ||
        themeData.getIn([element, "fontFamily"]) !== "secondaryFont")
    ) {
      const elementFont = themeData
        .getIn([element, "fontFamily"])
        .split(",")[0]
        .replace(/'/g, "")
        .trim();

      themeData =
        elementFont === primaryFont
          ? themeData.setIn([element, "fontFamily"], "primaryFont")
          : themeData.setIn([element, "fontFamily"], "secondaryFont");
    }
  });
  return themeData;
};

export const elementClick = element => async dispatch => {
  const resetState = store
    .getState()
    .get("editor")
    .Themes.get("resetState");

  const activeElement = store
    .getState()
    .get("editor")
    .Themes.get("activeElement");

  if (activeElement !== element.key) {
    await dispatch(setResetState(resetState + 1));
    await dispatch(setActiveElement(element.key));
  }
};

/**
 * This function must return boolean for the if statement in init()
 * @returns boolean
 */
export const getThemeData = () => async dispatch => {
  let reducerThemeData = themeState("themeData");
  let themes = themeState("themes");

  // if (reducerThemeData.size) {
  //   return await dispatch(setResetThemeReducer(reducerThemeData));
  // } else {
  const Editor = store.getState().get("editor").editorReducer;

  let themeData;
  let activeTheme;
  let currentTheme;

  try {
    themeData = Editor.getIn(["currentSite", "themeData"]);
    activeTheme = Editor.getIn(["currentSite", "activeTheme"]);
    currentTheme = themes.find(theme => theme.get("id") === activeTheme) || fromJS({});
  } catch (e) {
    console.error(e);
    return;
  }

  if (!activeTheme) {
    // this is update script if site does not have active theme
    return await dispatch(updateSiteActiveTheme());
  }

  themeData = formatFonts(themeData);

  return await Promise.all([
    dispatch(setThemeData(themeData)),
    dispatch(setThumbnail(currentTheme.get("thumbnail"))),
    dispatch(setThemeName(currentTheme.get("themeName"))),
  ]);
  // }
};

/**
 * Script to save current themeData as activeTheme
 * Used if site does not have activeTheme set
 *
 * @returns {Promise<void>}
 */
export const updateSiteActiveTheme = () => async dispatch => {
  const site = store.getState().getIn(["auth", "activeSite"]);
  const themeData = JSON.stringify(site.get("themeData").toJS());
  const siteId = site.get("id");
  const themeName = "Default Theme";
  const userId = getActiveUserId();

  const answer = await Alerts.confirm(
    "We need to update your site theme. This is a one time action. The Convertly app will reload when the update process is complete. If you need to save your changes do that and come back to this panel"
  );

  await Promise.all([
    dispatch(activateNavState({ key: PANELS.THEMES })),
    dispatch(setResetState()),
  ]);

  if (!answer) {
    return false;
  }

  const [theme] = await Promise.all([
    createTheme({
      siteId,
      userId,
      themeName,
      themeData,
    }),
    dispatch(showLoadingIcon(true)),
  ]);

  await updateSite({
    siteId,
    site: {
      isGlobalTheme: false,
      activeTheme: theme.id,
    },
  });

  await updateSiteTheme(siteId, themeData);

  await delay(500);

  window.location.reload();
};

/**
 *
 * @param siteId
 * @param themeData - stringified themeData object
 * @returns {Promise<void>}
 */
export const updateSiteTheme = (siteId, themeData) => async dispatch => {
  dispatch(setProcessing(true));
  const site = store.getState().getIn(["auth", "activeSite"]);
  const reducerThemeData = themeState().get("themeData");
  const themeName = themeState().get("themeName");
  const thumbnail = themeState().get("thumbnail");

  if (!siteId && !themeData) {
    siteId = store
      .getState()
      .getIn(["auth", "activeSite"])
      .get("id");

    themeData = JSON.stringify(reducerThemeData.toJS());
  }

  const snapshot = {
    themeData: reducerThemeData,
    themeName,
    thumbnail,
  };

  const query = `
  mutation UpdateSiteTheme ($siteId:ID!, $themeData: String){
    editSiteThemeData(id:$siteId, themeData: $themeData) {
      id, siteId
    }
  }
`;

  const variables = {
    siteId,
    themeData,
  };

  const res = await graph({ query, variables });

  Promise.all([dispatch(setSnapshot(snapshot)), dispatch(setProcessing(false))]);
};

export const saveNewTheme = async () => {
  const reducerThemeData = store
    .getState()
    .get("editor")
    .Themes.get("themeData");

  const Editor = store.getState().get("editor").editorReducer;
  const site = Editor.get("currentSite");
  const siteId = getActiveSiteId();
  const userId = store.getState().getIn(["auth", "user", "id"]);

  const isGlobalTheme = Editor.get(["currentSite", "isGlobalTheme"]);

  const themeName = themeState().get("newThemeName");

  const themeData = JSON.stringify(reducerThemeData.toJS());

  const theme = await createTheme({
    siteId,
    userId,
    themeData,
    themeName,
  });

  await updateSite({
    siteId,
    site: {
      isGlobalTheme: false,
      activeTheme: theme.id,
    },
  });

  await updateSiteTheme(siteId, themeData);

  if (isGlobalTheme) await setSiteData(site.set("isGlobalTheme", false));

  await setSiteData(site.set("activeTheme", res.createSiteTheme.id));
};

/**
 *
 * @param siteId
 * @param userId
 * @param themeName
 * @param themeData - stringified themeData object
 * @returns {Promise<*|{}>}
 */
const createTheme = async ({ siteId, userId, themeName, themeData }) => {
  const query = `
  mutation CreateSiteTheme ($data:SiteThemeInput){
    createSiteTheme(inputsForSiteTheme: $data) {
      id, siteId, userId, themeName, themeData, thumbnail
    }
  }
`;

  const variables = {
    data: {
      siteId,
      userId,
      themeName,
      themeData, // Stringified object
    },
  };

  const res = await graph({ query, variables });

  return _.get(res, "createSiteTheme") || {};
};

export const updateTheme = async () => {
  const reducerThemeData = store
    .getState()
    .get("editor")
    .Themes.get("themeData");

  const themeData = JSON.stringify(reducerThemeData.toJS());

  const themeName = store
    .getState()
    .get("editor")
    .Themes.get("themeName");

  const Editor = store.getState().get("editor").editorReducer;
  const site = Editor.get("currentSite");
  const siteId = getActiveSiteId();
  const id = site.get("activeTheme");

  const query = `
  mutation UpdateSiteTheme ($data:SiteThemeInput){
    updateSiteTheme(inputsForSiteTheme: $data) {
      id, siteId, userId, themeName, themeData, thumbnail
    }
  }
`;

  const variables = {
    data: {
      id,
      themeName,
      themeData, // Stringified object
    },
  };

  const res = await graph({ query, variables });

  // might not need this?
  // await updateSite({
  //   siteId,
  //   site: {
  //     isGlobalTheme: false,
  //     activeTheme: res.createSiteTheme.id,
  //   },
  // });


  await updateSiteTheme(siteId, themeData);
  // if (isGlobalTheme) await setSiteData(site.set("isGlobalTheme", false));
  await setSiteData(site.set("activeTheme", res.updateSiteTheme.id));
  await setThumbnail(
    "https://convertlyimguploadeast.s3.amazonaws.com/convertly/ui-components/assets/icons/theme-thumb-01.svg"
  );
};

export const saveTheme = () => async dispatch => {
  await dispatch(showLoadingIcon(true));

  const Editor = store.getState().get("editor").editorReducer;
  const site = Editor.get("currentSite");

  const reducerThemeData = store
    .getState()
    .get("editor")
    .Themes.get("themeData");

  const isNewTheme = store
    .getState()
    .get("editor")
    .Themes.get("isNewTheme");

  const newThemeName = store
    .getState()
    .get("editor")
    .Themes.get("newThemeName");

  if (isNewTheme) {
    await saveNewTheme();
    await dispatch(setThemeName(newThemeName));
  } else {
    await updateTheme();
  }
  // setting thumbnail to placeholder img now whether it is a new theme or updated theme
  await dispatch(
    setThumbnail(
      "https://convertlyimguploadeast.s3.amazonaws.com/convertly/ui-components/assets/icons/theme-thumb-01.svg"
    )
  );
  await dispatch(showLoadingIcon(false));
  return;
};

export const fetchTheme = id => async dispatch => {
  // id is the siteId
  const Editor = store.getState().get("editor").editorReducer;
  const site = Editor.get("currentSite");

  const query = `
			query( $id: ID! ){
			  Site(id: $id) {
          themeData, siteName
			  }
			}
			`;

  const variables = {
    id: id,
  };

  const response = await graph({ query, variables });

  const { Site } = response;
  // set themeData in site reducer
  await dispatch(setSiteData(site.set("themeData", fromJS(JSON.parse(Site["themeData"])))));
  // set themeData in themeReducer
  await dispatch(setThemeData(fromJS(JSON.parse(Site["themeData"]))));
};

export const selectTheme = theme => async dispatch => {
  const thumbnail = theme.get("thumbnail");
  const themeName = theme.get("themeName");

  await dispatch(fetchTheme(theme.get("siteId")));
  await dispatch(setThumbnail(thumbnail));
  await dispatch(setThemeName(themeName));
};
