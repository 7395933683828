import React from "react";
import {css} from "@convertly/css";
import Component from "source/rootComponent";
import _ from "lodash";

// Styles
import {BlogPreviewMediaStyles} from "./BlogPreviewMediaStyles";

// Components
import VideoPlayer from "source/elements/video/video-player/video-player";
import Elements from 'source/elements';

// Utils
import {clientImage} from "../../utils/generateImage";

export class BlogPreviewMedia extends Component {
    render() {
        let elementToBeRendered;

        const {data, inEditor, global, blogData} = this.props;

        if(_.isEmpty(blogData)) {
            return null;
        }

        const {displayOverlay} = data || {};

        const {previewImage, previewVideo, featureVideo, featureImage, setDefaultIfNoImage} =
        blogData || {};

        if(!previewImage && !previewVideo && !featureVideo && !featureImage){
            return null;
        }

        let videoObj = {},
            imageObj = {};

        if (_.isString(_.get(previewVideo, "src"))) {
            videoObj = previewVideo;
        } else if (_.isString(_.get(featureVideo, "src"))) {
            videoObj = featureVideo;
        }

        if (!_.get(videoObj, "src")) {
            if (_.isString(_.get(previewImage, "src"))) {
                imageObj = previewImage;
            } else if (_.isString(_.get(featureImage, "src"))) {
                imageObj = featureImage;
            }
        }

        const {videoType, src: previewVideoSrc, placeHolderImage, autoPlay} = videoObj;

        const {src: previewImageSrc, alt, title, imgParams} = imageObj;

        this.styles = BlogPreviewMediaStyles({global, data, inEditor});

        const {blogPreviewMediaWrapper, blogPreviewMedia, blogPreviewMediaOverlay} = this.styles;

        if (_.isString(previewVideoSrc) && !_.isEmpty(previewVideoSrc)) {
            elementToBeRendered = (
                <div className={css(blogPreviewMedia)}>
                    <VideoPlayer data={videoObj} global={global} inEditor={inEditor}/>
                </div>
            );
        } else if (_.isString(previewImageSrc) && !_.isEmpty(previewImageSrc)) {
            elementToBeRendered = (
                <Elements.Image
                    data={{
                        src: previewImageSrc,
                        imgParams,
                        alt,
                    }}
                    parentClass={css(blogPreviewMedia)}
                />
            );
        } else {
            //return placeholder or nothing
            elementToBeRendered = data.hideMissingImages ?
                null : <div className={css(blogPreviewMedia)}/>;
        }

        const inlineStyle = this.generateStyleAttribute();

        return (
            <div
                {...inlineStyle}
                {...this.generateDataEditorAttribute()}
                {...this.generateCustomAttributes()}
                className={css(blogPreviewMediaWrapper)}
            >
                {elementToBeRendered}

                {displayOverlay === true && <div className={css(blogPreviewMediaOverlay)}/>}
            </div>
        );
    }
};
