import React, { Component } from 'react';
import { generateLink } from '../../utils/generateLink';
import ReactHTMLParser from "@convertly/react-html-parser";

//Utils
import generateAccessKey from '../../utils/generateAccessKey'
import generateAddressString from '../../utils/generateAddressString';
import { generateDataAttribute } from '../../utils/generate-attribute';

export default class
GoogleMapsLink extends Component {

    constructor ( props ) {
        super( props );

        this.cancelClick = this.cancelClick.bind( this );
    }

    cancelClick( e ) {
        e.preventDefault();
    }

    render() {

        const { className } = this.props;
        const { location, customLinkMessage, gaAction, gaEvent } = this.props.data;

        let linkText = ReactHTMLParser( generateAddressString( location ) )

        if ( customLinkMessage) {
            linkText = customLinkMessage;
        }

        let locationName = '';

        if ( location.locationName ) {
            locationName = location.locationName + ' ';
        }

        return (

            <a onClick={ this.cancelClick }
               { ...generateAccessKey( "button" ) }
               className={ className }
               { ...generateDataAttribute( 'data-action', gaAction ) }
               { ...generateDataAttribute( 'data-event', gaEvent ) }
			   data-event="view on map" data-action="click"
               href={ "http://maps.google.com/?q=" + locationName + generateAddressString( location ) }
               target="_blank">{ linkText }</a>

        );
    }
}
