export default function StyleObject(global, data) {
	
	return {
		".drop-down-container": {
			position: 'relative',
			color: '#fff',
			cursor:'pointer'
			
		},
		".drop-down-content": {
			display: 'none',
			position: 'absolute',
			background: "#000",
			height: 'max-content',
			minWidth: '125px',
			zIndex: '99',
			left: '50%',
			transform: 'translateX(-50%)',
			".active": {
				display: 'block'
			}
		},
		".drop-down-list-item": {
			textAlign:'left',
			padding: '10px',
			cursor: 'pointer',
			transition: 'all 400ms ease',
			color: '#fff',
			fontSize: '12px',
			":hover": {
				backgroundColor: "#464646"
			},
			".active": {
				backgroundColor: "#464646"
			},
			".disabled": {
				backgroundColor: "#202020",
				fontStyle: 'italic',
				cursor: "not-allowed"
			}
		}
	}
	
	
}