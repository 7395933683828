    import React from 'react';
import Component from 'source/rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';
import _ from 'lodash';

//Default Props
//import defaultData from '';
const defaultData = {};
import defaultGlobal from 'source/themes/global';

//Styles
import Styles from './sign-up-react-container-styles';

//utils
import {generateLink} from 'source/utils/generateLink';

//inferno components
import SignUpContainer from '../sign-up-inferno/sign-up-container'


export default class CheckoutStage01 extends Component {

    constructor(props) {

        super(props);

    }

    render() {

        this.styles = Styles(this.props.global, this.props.data);

        if (this.props.editor) {
            return this.editor();
        }

        const siteLink = generateLink( { url:'', linkType:'inferno' } );
        const siteId = _.get(this.props, 'site.siteId');

        return (
            <div id={ id( SignUpContainer ) } className={ css( this.styles.signUpInferno ) } />
        )
    }
}


CheckoutStage01.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
