import React from 'react';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import defaultGlobal from '../../../themes/global.js';
import ReactHTMLParser from "@convertly/react-html-parser";

// Styles
import styles from '../form-styles';


export default class SubmitButton extends Component {
	constructor(props){
		super(props);
	}

	render(){
		this.styles = styles(this.props.global, this.props.data);


	return(
		<input
			className={`${css(this.styles.formInfernoContainer)} submitButton`}
			type="submit"
			value={this.props.data.submitText}/>
		)
	}
};










