import React from "react";
import styled from "styled-components";
import { GLOBALS } from "../../styles/global-default-styles";

const buttonBase = styled.button`
  color: rgb(255, 255, 255);
  padding: 10px 40px;
  border-radius: 3px;
  transition: all 0.3s ease;
  outline: none !important;
  min-width: 85px;
  box-shadow: rgb(65, 65, 65) 0 0 2px;
  height:fit-content;
  & :enabled {
    cursor: pointer;
    & :hover {
      // interesting effect. I want to see how it would work with app before
      // enabling it.
      //box-shadow: rgba(0, 0, 0, 0.2) 0 10px 16px 0, rgba(0, 0, 0, 0.19) 0 6px 20px 0;
      opacity: 0.5;
    }
  }
  & :disabled {
    background: #ccc;
  }
  margin: 0 20px 0 0;
`;

export const MainButton = styled(buttonBase)`
  background: linear-gradient(270deg, rgb(52, 152, 134) 0%, rgb(139, 217, 178) 100%);
`;

export const CancelButton = styled(buttonBase)`
  color: ${GLOBALS.colorPrimaryDark};
  background: ${GLOBALS.colorSecondaryLightest02};
`;
