import React from 'react';
import Component from 'source/rootComponent';
import { css } from '@convertly/css';
import _ from 'lodash';

// Utils
import { generateLink } from 'source/utils/generateLink';
import { clientImage } from 'source/utils/generateImage';

// Components

// Styles
import logoStyles from './logoStyles';

// Scripts

export class Logo extends Component {

  render() {

    const { global, data, inEditor, site } = this.props;
    const {alt, title} = site || {};
    const {
      showLogo,
      logoLinkToHomePage,
      textLogo,
      useTextLogo
    } = data || {};

    if(!showLogo) {
      return null;
    }

    const imgSrc = _.get(site, 'logo.src') || '';

    this.styles = logoStyles({ global, data, inEditor });
    
    const { imgLogo, txtLogo, logo } = this.styles;

    let generatedLinkObject;

    const inlineStyle = this.generateStyleAttribute();


    let logoComponentToBeRendered = (
      <img
        src={ clientImage(imgSrc, {}) }
        data-logo
        alt={ alt }
        title={ title }
        className={ `imgLogo ${ css(imgLogo) }` }
        { ...inlineStyle }
        { ...this.generateDataEditorAttribute() }
        { ...this.generateCustomAttributes() }/>
    )

    if(useTextLogo) {
      logoComponentToBeRendered = (
        <div 
          className={ css(txtLogo) }
          { ...inlineStyle }
          { ...this.generateDataEditorAttribute() }
          { ...this.generateCustomAttributes() }>
          { textLogo.text }
        </div>
      );
    }


    if(logoLinkToHomePage) {
      let logoComponent = logoComponentToBeRendered;

      generatedLinkObject = {
        url: data.customLink ? data.customLink : '/',
        linkType: 'internal'
      };

      logoComponentToBeRendered = (
        <a
          className={ css(logo) }
          href={ inEditor ? '#' : generateLink(generatedLinkObject) }
          onClick={ this.cancelClick }
          { ...inlineStyle }
          { ...this.generateDataEditorAttribute() }
          { ...this.generateCustomAttributes() }>
          { logoComponent }
        </a>
      );
    }

    return logoComponentToBeRendered;

  }

}