import React from 'react';
import Component from 'source/rootComponent';
import { css } from "@convertly/css";
import { id } from '@convertly/thor';
import { deeplog } from "../../../../utils/deeplog";
import _ from 'lodash';

//Default Props
//import defaultData from '';
const defaultData = {};
import defaultGlobal from 'source/themes/global';

//Styles
import Styles from './product-image-gallery-styles';

//Components
import Elements from 'source/elements';

//Utils
import { clientImage } from 'source/utils/generateImage';

//Scripts
import ConvertlyImageGalleryWidget01 from '../scripts/image-gallery-widget-script-01';

export default class
ProductImageGallery extends Component {

    constructor ( props ) {
        super ( props );
    }

    render () {

        this.styles = Styles( this.props.global, this.props.data );

        if ( this.props.editor ) {
            return this.editor ();
        }

        let featuredImage = this.props.images[ 0 ];
        featuredImage = _.get( featuredImage, 'src' ) || featuredImage;

        const theClassName = css ( this.styles.productbox );
        const imageClass = css ( this.styles.imageThumbnail );

        let thumbNails = null;

        if( this.props.images.length > 1 ) {
            thumbNails = <ul id="imageGalleryList" className={ css ( this.styles.productboxList ) }>
                { this.props.images.map( ( image, index ) => {
                    let itemClassName = 0 === index ? theClassName + " active" : theClassName;
                    const imgSrc = _.get( image, 'src' ) || '';
                    return (
                        <li key={ index }
                            className={ itemClassName }
                            data-imagesrc={ clientImage ( imgSrc ) }>
                            <img className={ css( this.styles.productThumbnail ) } src={ clientImage ( imgSrc, { width: '71', height:'61', crop: 'fit' } ) } alt="" />
                        </li>
                    );
                })
                }
            </ul>
        }

        return (

           <div id={ id ( ConvertlyImageGalleryWidget01 ) } className={ css ( this.styles.productgridcontainer ) }>
                <div className={ css( this.styles.productboxcontainer ) }>
                    { thumbNails }
                </div>
                <div className={ css( this.styles.productimgcontainer ) }>
                    <div className={ css( this.styles.large_productbox ) }>
                        <img id="featuredImage" className={ css ( this.styles.featureImage ) } src={ clientImage ( featuredImage, { width: '706', height:'306', crop: 'fit'} ) }/>
                    </div>
                </div>
            </div>


        )
    }
}


ProductImageGallery.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
