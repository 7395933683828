import stampit from "stampit";
import _ from "lodash";

const noOp = () => {};

const LEVELS = {
  ALL: 9,
  VERBOSE: 8,
  DEBUG: 7,
  INFO: 6,
  NOTICE: 5,
  WARNING: 4,
  ERROR: 3,
  CRIT: 2,
  ALERT: 1,
  EMERG: 0
};

let CURRENT_LEVEL = 0;

export const Logger = stampit({
  methods: {
    log: console.log,
    set: level => {
      CURRENT_LEVEL = level;
    },
    verbose: sendToLog.bind(null, LEVELS.VERBOSE),
    debug: sendToLog.bind(null, LEVELS.DEBUG),
    info: sendToLog.bind(null, LEVELS.INFO),
    notice: sendToLog.bind(null, LEVELS.NOTICE),
    warning: sendToLog.bind(null, LEVELS.WARNING),
    error: sendToLog.bind(null, LEVELS.ERROR),
    crit: sendToLog.bind(null, LEVELS.CRIT),
    alert: sendToLog.bind(null, LEVELS.ALERT),
    emerg: sendToLog.bind(null, LEVELS.EMERG),
    auth: noOp,
    chat: noOp,
  },
  props: {
    ...LEVELS
  }
});

function sendToLog(level) {
  if (CURRENT_LEVEL >= level && process.env.NODE_ENV !== "production") {
    let args = [];
    _.mapKeys(arguments, (value, key) => {
      args.push(value);
    });
    args = args.slice(1);
    console.log.apply(console, args);
  }
}

export default Logger();
