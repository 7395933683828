import {StyleSheet} from "@convertly/css";
import UserOverrides from '../style-functions/user-overrides';

const divWithOverlayStyles = (global, data, inEditor) => {

  const userOverrides = new UserOverrides(global, data, inEditor);

  return StyleSheet.create({

    divWithOverlay: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      height: "350px",
      position: "relative",
      border: "2px solid #dbdbdb",
      margin: "0 0 30px",
      overflow: 'hidden',
      ":hover ": {
        ".divOverlay": {
          bottom: 0
        }
      },
      ...userOverrides
    },

    divOverlay: {
      position: "absolute",
      backgroundColor: global.colors.light.white,
      height: "287px",
      width: "100%",
      padding: "75px 30px 55px",
      bottom: "-100%",
      border: "1px solid #dbdbdb",
      transition: "all .3s ease",
      "@media (max-width: 768px)": {
        bottom: 0
      },
      ...data.showOverlay && inEditor && {
        ".divOverlay": {
          bottom: 0
        }
      },
    }
  })
};

export default divWithOverlayStyles;
