import  colorAssign  from '../colorAssign';

let defaultGlobal = {
    colors: {
        light: {
            color1: '#fff',
            color2: '#eeeeee',
            color3: '#b7b7b7',
            white: '#fff'
        },
        dark: {
            color1: '#010101',
            color2: '#4a4a4a',
            color3: '#5c5c5c',
            dark: '#000'

        },
        accent: {
            color1: '#ca1f2b',
            color2: '#ff5a62',
            color3: '#ff232d',

        }

    },

    textColors: {
        light:{},
        dark:{},
        accent:{}
    },
    backgroundColors: {
        light:{},
        dark:{},
        accent:{}
    },
        themeBorder: {
        border: '3px solid #002550',
    },
    fontFamily: {
        primaryFont: "'Merriweather', serif",
        secondaryFont: " 'Roboto', sans-serif"
    },
    fonts: [ 'Merriweather', 'Roboto' ],
    button1: {
        display: 'inline-block',
        padding: '15px 35px',
        fontSize: '1.5em',
        lineHeight: '18px',
        borderRadius: '4px'
    },
    button2: {
        display: 'inline-block',
        padding: '10px 15px',
        fontSize: '1.3em',
        lineHeight: '18px',
        borderRadius: '4px'
    },
    button3: {
        display: 'block',
        textAlign: 'center',
        padding: '8px 15px',
        fontSize: '16px',
        lineHeight: '18px',
        borderRadius: '4px'
    },
    bodyBackgroundColor: false
};


export default function theme ( global ) {

    global = global ? global : defaultGlobal;

    colorAssign( global );


    /*
     *******  Button Colors   ***********
     */

//Button 1

    const defaultButtonStyles = {
        background: global.backgroundColors.accent.color2,
        textColor: global.textColors.light.white,
        borderColor: global.backgroundColors.accent.color2,
        fontFamily: global.fontFamily.secondaryFont
    };
    const alternateButtonStyles = {
        background: global.backgroundColors.dark.color2,
        textColor: global.textColors.light.white,
        borderColor: global.backgroundColors.dark.color2,
        fontFamily: global.fontFamily.secondaryFont
    };

    global.button1 = {
        ...global.button1,
        ...defaultButtonStyles
    };

    global.button1A = {
        ...global.button1,
        ...alternateButtonStyles
    };

    global.button2 = {
        ...global.button2,
        ...defaultButtonStyles
    };

    global.button2A = {
        ...global.button2,
        ...alternateButtonStyles
    };

    global.button3 = {
        ...global.button3,
        ...defaultButtonStyles
    };

    global.button3A = {
        ...global.button3,
        ...alternateButtonStyles
    };

    return global;
}
