import React from 'react';
import Component from '../../rootComponent';
import Types from '../../attribute-types';
import { css } from "@convertly/css";
import defaultGlobal from '../../themes/global.js';
import ReactHTMLParser from "@convertly/react-html-parser";

//utils
import generateId from '../../utils/generate-id';

//Styles
import unorderedListStyles from './un-ordered-list-styles';

//inferno-components
import ListItem from './list-item';


export default class
UnorderedList extends Component {

    constructor ( props ) {

        super( props );


    }

    render() {

        /**
         * Calls Stylesheet function
         **/
        this.styles = unorderedListStyles( this.props.global, this.props.data );

        if( this.props.editor ) {
            return this.editor();
        }

        /**
         * Checks for default class name, if it exists, add to class
         **/

        let className = '';

        let aphroditeClass = css ( this.styles.unorderedList );

        if ( this.animatedClassName ) {
            className += " " + this.animatedClassName
        }
        className += aphroditeClass;

        let listItems = this.props.data.listItems;
        listItems = listItems || [];

        return (
            <ul className={ className }
                { ...this.generateCustomAttributes() }
                { ...generateId( this.props.elementId ) }
            >

                { listItems.map( ( item, index ) => {
                    return(
                        <ListItem key={ index }
								  data={ item }
                                  listStyle={ this.props.data.listStyle ? this.props.data.listStyle : "default" }
                                  listItemClassName={ this.props.listItemClassName }
                                  bulletPointImage={ this.props.bulletPointImage }
                                  elementDataName={ this.props.elementDataName + "[" + index + "]" }
                                  moduleId={ this.props.moduleId }
                                  moduleSort={ this.props.moduleSort }
                                  global={ this.props.global } />
                    )
                } ) }
            </ul>
        );
    }
}

UnorderedList.defaultProps = {
    data: {
        "listItems": [
            {
                text:'hello'
            },
            {
                text:'my name is'
            }
        ]
    },
    global : defaultGlobal
};


/**
 *Element Boilerplate
 <Elements.UnorderedList
 parentUnorderdListStyle={ this.styles.bulletPointList }
 listItemClassName={ this.styles.bullPointListItem }
 bulletPointImage={ this.styles.bulletPointImage }
 data={ this.props.data.bulletPoints }
 global={ this.props.global } />

 **/
