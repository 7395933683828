import { createAction } from "redux-act";
import { fromJS } from "immutable";
import { graph } from "utils/graph";
import { store } from "app/app";

import { showLoadingIcon } from "containers/App/actions";

export const resetState = createAction("ORDER DETAIL: Reset order state");
export const setLoadingState = createAction("ORDER DETAIL: Set loading state");
export const setCurrentOrder = createAction("ORDER DETAIL: Set order");
export const setSnapshot = createAction("ORDER DETAIL: Set order snapshot");
export const clearOrderDetailPage = createAction("ORDER DETAIL: Clear order store");
export const saveOrderAction = createAction("ORDER DETAIL: Save order");
export const setSaveState = createAction("ORDER DETAIL: Set saving state");
export const setUpdateInventoryModal = createAction("ORDER DETAIL: Set inventory modal");

/**
 *
 * @param item
 * @returns {Function}
 */
export const emailHistoryNote = item => async () => {
  const id = store
    .getState()
    .get("Order")
    .Order.getIn(["currentOrder", "id"]);

  const query = `
		query( $item: String, $id:ID! ) {
		  EmailOrderHistory(id:$id,item:$item)
		}`;

  const variables = {
    id,
    item: JSON.stringify(item)
  };

  await graph({ query, variables });
};

/**
 *
 * @param order
 * @param snapshot
 * @param updateInventory
 * @param confirmedUpdate
 * @returns {Function}
 */
export const saveOrder = (
  order,
  snapshot,
  updateInventory = false,
  confirmedUpdate = false
) => async dispatch => {
  if (!confirmedUpdate) {
    if (order.get("orderStatus") !== snapshot.get("orderStatus")) {
      if (order.get("orderStatus") === "Cancelled" || order.get("orderStatus") === "Returned") {
        await dispatch(setUpdateInventoryModal(true));
        return;
      }
    }
  }

  await dispatch(setUpdateInventoryModal(false));
  await dispatch(setSaveState(true));
  await dispatch(setLoadingState(true));

  let query = `
		mutation($orderId:ID!,$message:String!) {
		  addHistory( id: $orderId, message: $message ) {
			id, history {
			  title, timestamp, message
			}
		  }
		}
		`;

  let variables = {
    orderId: order.get("id"),
    message: "Order status updated to " + order.get("orderStatus")
  };

  await graph({ query, variables });

  query = `
			mutation($orderId:ID!,$order:orderInput!, $updateInventory: Boolean ) {
			  editOrder( id: $orderId, order: $order, updateInventory: $updateInventory ) {
				id
			  }
			}
			`;

  const orderToSave = {
    orderStatus: order.get("orderStatus")
  };

  variables = {
    orderId: order.get("id"),
    order: orderToSave,
    updateInventory
  };

  await graph({ query, variables });
  await dispatch(loadOrder(order.get("id")));
  await dispatch(setSaveState(false));
};

/**
 * @returns {Function}
 */
export const addTrackingDetails = () => async dispatch => {
  await dispatch(setLoadingState(true));
  await dispatch(showLoadingIcon(true));

  const order =
    store
      .getState()
      .get("Order")
      .Order.get("currentOrder") || fromJS({});

  const note = `Tracking number updated to ${order.get("trackingId")}`;

  const query = `
  mutation($orderId:ID!,$order:orderInput!) {
    editOrder( id: $orderId, order: $order ) {
    id
    }
  }
  `;

  const variables = {
    orderId: order.get("id"),
    order: {
      trackingId: order.get("trackingId")
    }
  };

  await graph({ query, variables });
  await dispatch(addNewHistoryNote(note, order.get("id")));
};

/**
 *
 * @param note
 * @param orderId
 * @returns {Function}
 */
export const addNewHistoryNote = (note, orderId) => async dispatch => {
  const query = `
		mutation($orderId:ID!,$message:String!) {
		  addHistory( id: $orderId, message: $message ) {
        id, history {
          title, timestamp, message
        }
		  }
		}
		`;

  const variables = {
    orderId,
    message: note
  };

  await dispatch(setLoadingState(true));
  await dispatch(showLoadingIcon(true));
  await graph({ query, variables });
  await dispatch(loadOrder(orderId));
  await dispatch(showLoadingIcon(false));
};

/**
 *
 * @param orderId
 * @returns {Function}
 */
export const loadOrder = orderId => async dispatch => {
  await dispatch(setLoadingState(true));

  const query = `
		 query ($orderId: ID!) {
		  Order(id: $orderId) {
        id,
        publicId,
        createdAt,
        updatedAt,
        paymentStatus,
        orderStatus,
        total,
        history { title, timestamp, message, emailed },
        cart,
        trackingId,
        shippingAddress,
        billingAddress,
        paymentType,
        paymentLast4,
        customer {
          id, firstName, lastName, email
        },
        order_summary {
          name,
          value
        },
		  }
		}
		`;

  const variables = { orderId };

  const orderResponse = await graph({ query, variables });
  const order = orderResponse.Order;

  if (!order) {
    return dispatch(setLoadingState(false));
  }

  order.cart = JSON.parse(order.cart);
  order.billingAddress = JSON.parse(order.billingAddress);
  order.shippingAddress = JSON.parse(order.shippingAddress);
  order.createdAt = parseInt(order.createdAt);
  order.updatedAt = parseInt(order.updatedAt);

  order.products = _.get(order, "cart.items") || [];

  await dispatch(setCurrentOrder(order));
  await dispatch(setSnapshot(order));
  await dispatch(setLoadingState(false));
};

/**
 *
 * @param field
 * @param value
 * @returns {Function}
 */
export const updateOrder = (field, value) => async dispatch => {
  const order =
    store
      .getState()
      .get("Order")
      .Order.get("currentOrder") || fromJS({});
  await dispatch(setCurrentOrder(order.set(field, value)));
};
