import React from "react";
import styled, { css, keyframes } from "styled-components";
import { fadeIn, fadeOut } from "react-animations";
import PropTypes from "prop-types";

const Box = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 6px;
  background-color: #1e1e1e;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
  position: absolute;
  top: -20px;
  opacity: 0;
`;

const DownArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #1e1e1e;
  position: relative;
  left: 7px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);
`;

const Text = styled.div`
  width: 26px;
  height: 26px;
  font-family: SourceSansPro;
  font-size: 12px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
`;

// const fadeInAnimation = keyframes`${fadeIn}`;
// const fadeOutAnimation = keyframes`${fadeOut}`;
//
// const fadeInAnimationRule = css`
//   ${fadeInAnimation} 250ms;
// `;
//
// const fadeOutAnimationRule = css`
//   ${fadeOutAnimation} 250ms;
// `;
//
// const fadeOutStyles = {
//   animation: `${fadeOutAnimationRule}`,
// };
//
// const fadeInStyles = {
//   opacity: 1,
//   animation: `${fadeInAnimationRule}`,
// };

export const ToolTip = ({ x, label, dragging, firstDrag }) => {
  let styles;

  let transform = {
    transform: `translateX(${x - 2}px)`,
  };

  if (!dragging && firstDrag) {
    styles = {
      ...transform,
      //...fadeOutStyles,
    };
  }

  if (dragging) {
    styles = {
      ...transform,
      //...fadeInStyles,
    };
  }

  return (
    <Box style={styles}>
      <Text>{label}</Text>
      <DownArrow />
    </Box>
  );
};

ToolTip.defaultProps = {
  x: 0,
  label: "10",
  dragging: false,
  firstDrag: false,
};

ToolTip.propTypes = {
  x: PropTypes.number,
  label: PropTypes.string,
  dragging: PropTypes.bool,
  firstDrag: PropTypes.bool, // flag so we don't trigger fadeOutAnimation when component renders
};
