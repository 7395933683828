export * from "./graph";
export * from "./purge";
export * from "./loggly";
export * from "./localStorage";
export * from "./logger";
export * from "./noop";
export * from "./eventHandlers";
export * from "./imageGallery";
export * from "./imageUrl";
export * from "./openLink";
export * from "./colors";
export * from "./users";
export * from "./alterPageElements";
export * from "./currency";

import { store } from "app/app";

export const activeSite = () => store.getState().getIn(["auth", "activeSite"]);

/**
 *
 * @param t Milliseconds to wait
 * @returns {Promise}
 */
export const delay = t => {
  return new Promise(resolve => {
    setTimeout(() => resolve(), t);
  });
};

export const parse = obj => {
  try {
    return JSON.parse(obj);
  } catch (e) {
    console.log(e.message, obj);
    return null;
  }
};

// moved logic to process variable
// so can more easily control
export const isProduction = () => process.env.STAGE === "production";

//export const isProduction = () => true;
//export const isProduction = () => window.location.hostname === APP_HOST;
