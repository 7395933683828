import React from "react";
import Component from "source/rootComponent";
import { css } from "@convertly/css";
import ReactHTMLParser from "@convertly/react-html-parser";
import Styles from "./svg-code-wrapper-styles";
import _ from "lodash";
import { id } from "@convertly/thor";

export default class SVGCodeWrapper extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const inEditor = this.inEditor();
    let svg = this.props.data.code;
    this.styles = Styles(this.props.global, this.props.data, inEditor);
    const inlineStyle = this.generateStyleAttribute();

    const { code, notSVG } = this.props.data;

    if (this.props.editor) {
      return this.editor();
    }

    if (_.get(this.props, "data.code") && notSVG) {
      svg = svg.split("<");
      svg[2] = svg[2].slice(0, 4) + " preserveAspectRatio='none' " + svg[2].slice(3);
      svg = svg.join("<");
    }
    return (
      <div
        className={css(this.styles.svgCodeWrapper)}
        {...this.generateCustomAttributes()}
        dangerouslySetInnerHTML={{ __html: svg }}
        {...this.generateDataEditorAttribute()}
        {...inlineStyle}
      />
    );
  }
}
