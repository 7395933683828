import { StyleSheet } from "@convertly/css";

export default (options = {}) => {
  return StyleSheet.create({
    modalWrapper: {
      width: options.width + "px",
      position: "fixed",
      left: "50%",
      transform: "translate(-50%, -50%)",
      height: options.height || "",
      top: "calc(50% - 320px)",
      zIndex: "9999",
      background: "#FFF",
      borderRadius: "10px 10px 0 0",
      boxShadow: "0 0 3px 0 rgba(0,0,0,0.5)"
    },
    modalHeader: {
      fontSize: 18,
      color: "#4A4A4A",
      padding: 20,
      height: 60,
      borderRadius: "10px 10px 0 0",
      background: "linear-gradient(180deg, #FFF 0%, #EEE 100%)"
    },
    modalCloseIcon: {
      fontSize: 18,
      cursor: "pointer"
    },
    modalUnderlay: {
      position: "fixed",
      top: "0",
      left: "0",
      zIndex: "3900",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0,0,0,.7)"
    }
  });
};
