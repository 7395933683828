export const GLOBALS = {
  colorPrimary: "rgba(52, 152, 134, 1)", //#349886
  colorPrimaryOpacity: "#F6FDFA",
  colorPrimaryLight: "rgba(255, 255, 255, 1)", // #FFFFFF
  colorPrimaryBackground: "#F6F6F6",
  colorPrimaryDark: "rgba(65, 65, 65, 1)", // #414141
  colorPrimaryDarkOpacity: "rgba(65, 65, 65, .6)", // #414141
  colorSecondary: "rgba(132, 173, 153, 1)",
  colorSecondaryDark: "rgba(155, 155, 155, 1)", // #b9b9b9
  colorSecondaryLight: "rgba(198, 198, 198, 1)", // #c6c6c6
  colorSecondaryLighter: "rgba(139, 217, 178, 1)",
  colorSecondaryLightest: "rgba(229, 229, 229, 1)", // #E5E5E5
  colorSecondaryLightest02: "rgba(240, 240, 240, 1)",
  colorWarning: "rgba(235, 87, 87, 1)",
  colorInputBorder: "rgba(183, 231, 207, 1)", // #B7E7CF
  colorBGHover: "rgba(246, 253, 250, 1)", // #F6FdFA
  colorPrimaryGradient: "linear-gradient(270deg, #349886 0%, #8bd9b2 100%)", // Green gradient
  buttonOpacity: 1,
  buttonHoverOpacity: 0.5,
  colorPrimaryShadow: "rgba(65, 65, 65, .4)",
  boxShadow: "0px 6px 10px rgba(65,65,65,0.25)",
  dashBoardMetricPrimary: "#F6B880",
  textLink: "#4b91cd;",
};
