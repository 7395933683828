import PropTypes from 'prop-types';
import React from "react";
import { browserHistory } from "react-router";
import { saveProduct } from "../../../containers/StoreFront/ProductDetail/actions";
import PillButton from "../../Button/pillButton";
import Options from "../../../containers/Editor/components/editor/options";
import { NoDisplayOnMobile } from "components/commonCSS/utilityCSS";
import { changePageType } from "../../../containers/Editor/actions/editorActions";

export default class ProductDetailMenu extends React.Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      showLoadIcon: false
    };
    this.save = this.save.bind(this);
    this.editProduct = this.editProduct.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.viewProduct = this.viewProduct.bind(this);
  }

  onCancel(e) {
    e.preventDefault();
    browserHistory.push("/storefront/products");
  }

  save(e) {
    const { site } = this.props;

    const product = Object.assign({}, this.props.product.toJS());

    const payload = { product, productId: product.id, site: site.toJS() };

    this.context.store.dispatch(saveProduct(payload));
  }

  fetchSiteDomain(site, slug) {
    //return domain if it has one
    if(site.get('domain') )  return "https://" + site.get('domain') + slug
    //otherwise return preview domain with the convertly.io extension
    return `http://${site.get('previewDomain')}.convertly.io${slug}`
  }

  viewProduct() {
    const { site, product } = this.props;
    const url = this.fetchSiteDomain(site, product.get("slug"));
    window.open(url);
  }

  editProduct(e) {
    return (async () => {
      await this.context.store.dispatch(changePageType(this.props.product.get("id")));
      browserHistory.push("/editor");
    })();
  }

  /**
   *
   * @returns {*}
   */
  render() {
    const { saving } = this.props;

    const inlineCss = {
      spacing: {
        marginRight: "20px"
      },
      button: {
        cursor: "pointer"
      },
      container: {
        width: "100%"
      }
    };

    const options = [
      {
        optionsItemTitle: "View",
        optionsCallback: this.viewProduct
      },
      {
        optionsItemTitle: "Edit",
        optionsCallback: this.editProduct
      }
    ];

    return (
      <div style={inlineCss.container}>
        <NoDisplayOnMobile>
          <PillButton
            label="Save"
            backgroundColor="#52AF52"
            textColor="#FFF"
            action={this.save}
            showLoadIcon={saving}
            width={70}
          />
          <PillButton label="Back" action={this.onCancel} />
          <Options options={options} button resetIndex={() => {}} />
        </NoDisplayOnMobile>
      </div>
    );
  }
}

ProductDetailMenu.contextTypes = {
  store: PropTypes.object
};
