import { StyleSheet } from "@convertly/css";
import Typography from '../../../elements/style-functions/typography';

const headerGlobalStyles = ( global, element ) => {

    const headerGlobalStyles = {};

    if(element.mobileNavBackground) {
        headerGlobalStyles.backgroundColor = element.mobileNavBackground;
    }

    return headerGlobalStyles;

};

export default headerGlobalStyles;