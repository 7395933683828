export const assignZIndex = arr => {
  let zIndex = (arr.length - 1) * 3;
  arr.forEach(o => {
    o.data = o.data || {};
    o.data.isOnBanner = true;
    o.data.userOverrides = o.data.userOverrides || {};
    o.data.userOverrides.zIndex = zIndex;
    o.data.userOverrides.position = "absolute";
    zIndex -= 3;
  });
};
