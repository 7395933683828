import { fromJS } from "immutable";
import { createReducer } from "redux-act";

import {
  setProducts,
  setLoadingState,
  resetState,
  setNewProductName,
  setSavingNewProduct,
} from "../actions";

const initialState = fromJS({
  products: [],
  loading: true,
  newProductName: "",
  savingNewProduct: false,
});

const reducer = createReducer(
  {
    [setLoadingState]: (state, loading) => state.merge({ loading }),

    [setNewProductName]: (state, newProductName) => state.merge({ newProductName }),

    [setSavingNewProduct]: (state, savingNewProduct) => state.merge({ savingNewProduct }),

    [setProducts]: (state, products) => state.merge({ products }),

    [resetState]: state => {
      return state.merge({
        loading: true,
        products: [],
      });
    },
  },
  initialState
);

export default reducer;
