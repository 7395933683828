/*global Inferno, $ */
import React from 'react';
const Script = () => {
	
	return ({rootClass, data, index, toggleSwitch}) => {
		
		const {
			title,
			leftOption,
			rightOption,
			active,
			toggle,
			value1,
			value2
		} = data;
		
		return (
			<div key={`sort-${index}`}
					 className={ `${rootClass} sort-by-dropdown-item` }>
				<div className={ `${rootClass} sort-by-dropdown-item-title` }>{ title }</div>
				<div className={ `${rootClass} sort-by-dropdown-item-options` }>
					<div className={ `${rootClass} sort-by-dropdown-item-left-option` }>{ leftOption }</div>
					<div className={ `${rootClass} sort-by-dropdown-item-right-option` }>{ rightOption }</div>
				</div>
				<div className={ `${rootClass} sort-by-dropdown-item-switch` }>
					<input
						className={
							`${rootClass} sort-by-dropdown-item-switch-checkbox`
						}
						type='checkbox'
						name='switch'
						id={ `my-onoff-switch-${ index }` }/>
					<label
						onClick={ toggleSwitch }
						data-index={ index }
						className={ `${rootClass} sort-by-dropdown-item-switch-label` }
						for={ `my-onoff-switch-${ index }` }>
						<span className={ `${rootClass} sort-by-dropdown-item-switch-inner` } data-index={ index }/>
						<span
							className={ `${rootClass} sort-by-dropdown-item-switch-switch ${ active ? ' active' : ''} ${ toggle ? ' checked' : '' }` }
							data-index={ index }/>
					</label>
				</div>
			</div>
		)
	}
};

export default Script;