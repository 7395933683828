import {StyleSheet} from "@convertly/css";
import UserOverrides from '../style-functions/user-overrides';
import _ from 'lodash';
import removeNestedUserOverrides from "../../site-header/site-header/utils/removeNestedUserOverrides";
import { generatePseudoMediaQuery } from "../style-functions/pseudo-media-queries";

const form = (global, data, inEditor) => {
    const form = removeNestedUserOverrides({ data });
    
    const submitButton = _.get(data, 'userOverrides.submitButton');

    const formUserOverrides = !_.isEmpty(form) ? UserOverrides(global, { userOverrides: form }, inEditor) : {};
    const submitButtonUserOverrides = _.get(submitButton, 'userOverrides') ? UserOverrides(global, submitButton, inEditor) : {};

    const formContainerMediaQuery = generatePseudoMediaQuery(
        "@media(max-width: 767px)",
        ["mobile", "smallTablet"],
        {
            width: "100%"
        },
        inEditor
    );

    let labelOverrides = _.get(
        data, 'labelOverrides.userOverrides') ?
        UserOverrides(global, data.labelOverrides, inEditor) :
        {};


    return StyleSheet.create({
        formTitle: {
          display:'block'
        },
        formInfernoContainer: {
            cursor:'pointer',
            ".formContainer": {
                overflow:'hidden',
                position:'relative'
            },

            ".formWrapper": {
                position:'relative',
                left:'0',
                transition:'all 500ms ease',
                overflow:'hidden',
                '.flyout': {
                    left:'-110%'
                }
            },

            ".fieldContainer": {
                marginBottom:'20px',
                verticalAlign: "middle",
                padding: "0 5px",
                display: "inline-block",
                textAlign:'left',
                ".quarter": {
                    width: "25%",
                    ...formContainerMediaQuery
                },
                ".half": {
                    width: "50%",
                    ...formContainerMediaQuery
                },
                ".third": {
                    width: "33.333%",
                    ...formContainerMediaQuery
                },
                ".full": {
                    width: "100%"
                },

            },
            /** Label **/
            ".convertly-form-builder-label": {
                textAlign:'left',
                ...global.label,
                ...labelOverrides
            },

            /** Input **/
            ".input": {
                ...global.input
            },

            /** Checkbox **/
            ".checkbox": {
                ...global.checkboxInput
            },
            ".checkboxLabel": {
                display:'flex',
                alignContent:'center',
                ...global.checkboxLabel,
                ...labelOverrides
            },
            ".textArea": {
                ...global.textArea
            },

            /** Radio **/
            ".radio": {
                ...global.radioInput
            },
            ".radioLabel": {
                ...global.radioLabel,
                ...labelOverrides
            },

            /** Select **/
            ".selectContainer": {
                position: 'relative',
                width: "100%",
                height:'40px'
            },
            ".selectInput": {
                ...global.selectInput,
                outline: 'none'
            },
            ".pullUp": {
                verticalAlign:'top',
                marginTop:'-1px'
            },
            ".caretDown": {
                position: 'absolute',
                right: '10px',
                top: '14px',
                fill: global.colors.dark.color3,
                zIndex: '400',
                height: '10px',
                width: '10px'
            },

            /** Submit Button **/
            ".submitButton": {
                ...global.submitButton,
                ".disabled": {
                    filter:'grayscale(100%)',
                    pointerEvents:'none'
                },
                ...submitButtonUserOverrides
            },

            /** Required Field Error Message **/
            ".form-error-message": {
                ...global.formErrorMessage,
                position:'absolute',
                display:'none',
            },
            ".show-form-error-message": {
                display:'block'
            },

            /** Order Completion Message **/
            '.confirmationMessageWrapper': {
                position:'absolute',
                top:'0',
                left:'110%',
                transition:'all 500ms ease',
                '.in': {
                    left:'0'
                }
            },
            '.confirmationMessageHeader': {
                fontSize:'20px',
                color:global.colors.dark.color3,
            },
            '.divider': {
                borderBottom:'3px solid',
                borderColor: global.colors.dark.color3,
                padding:'3px',
                width:'40%',
                borderRadius:0,
                margin:0
            },
            '.confirmationMessage': {
                color:global.colors.dark.color3,
                fontSize:'16px'
            },
            '.flyout': {
                left:'-110%'
            }


        },

      form: {
        width: '100%',
        overflow:'hidden',
        ...global.form,
        ...formUserOverrides
      },

    })

};

export default form;
