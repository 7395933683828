import React from 'react';
import Component from '../../rootComponent';
import {css} from "@convertly/css";
import defaultGlobal from '../../themes/global.js';
import ReactHTMLParser from "@convertly/react-html-parser";

// Styles
import overlayStyleSheet from './overlay-styles';


export default class Overlay extends Component { 
	constructor(props){
		super(props);
	}

	render() {

        const inEditor = this.inEditor();

		this.styles = overlayStyleSheet(this.props.global, this.props.data, inEditor);

		let className;
		const aphroditeClass = css(this.styles.overlay);

		className +=aphroditeClass;	

		
		

	return(

		<div className={css(this.styles.overlay)} { ...this.generateCustomAttributes() }/>
		
		
		)
	}

};

Overlay.defaultProps = {
	global: defaultGlobal,
	data: {}
}