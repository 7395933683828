import React from 'react';
import Component from 'source/rootComponent';
import { css } from '@convertly/css';
import { id } from '@convertly/thor';

// Styles
import ScrollButtonStyles from './ScrollButtonStyles';

// Components
import SVG from 'source/svg'

// Scripts
import scrollFullViewportHeight from 'source/elements/full-page-scrolling-banner/scripts/scrollFullViewportHeight';

export class ScrollButton extends Component {
  
  render() {
    const { data, global, inEditor } = this.props;
    const { textBlock } = data || {};
    const styles = ScrollButtonStyles({ global, data, inEditor});
    const { scrollButton, textBlockStyles, caret } = styles;
    
    return (
      <div
        id={ id(scrollFullViewportHeight) } 
        className={ css(scrollButton) }>
        <div className={ css(textBlock) }>
          { textBlock || '' }
        </div>
        <div className={ css(caret) }>
          <SVG.ArrowHeadOpenDown/>
        </div>
      </div>
    )
  }

}