import { StyleSheet } from '@convertly/css';
import userOverrides from '../../../elements/style-functions/user-overrides';
import _ from 'lodash';
import { generatePseudoMediaQuery } from '../../../elements/style-functions/pseudo-media-queries';
import { generateHeaderOffsetMediaQueries } from '../../header-style-functions/fixed-header-media-queries'
import { createNewCssRules } from '../../../utils/edit-userOverrides';

const header05Styles = (global, data, inEditor, headerPosition) => {
	
  
  data.position = inEditor ? 'static' : data.position;

  let headerUserOverrides = userOverrides(global, data, inEditor);
  let displayHeaderTop = _.get(data, 'headerTopperSettings.display');
  let mainHeaderPosition = data.position || 'static';
  let positionFixed = data.position === "fixed";  

  let mainHeaderPositionStyles = {};
  let bodyPaddingMediaQueries = {};
  let headerHeightObject = {};
  let headerBackgroundColorObject = {};
  let callOutUserOverrides = {};
  let headerStyles = {};

  let headerHeight = _.get(data, 'userOverrides.height') ?
                      _.get(data, 'userOverrides.height') :
                      displayHeaderTop ? '120px' : '80px';

  if (headerPosition === "fixed") {
    headerStyles = {
      position: 'fixed',
      "* body": {
        paddingTop: headerHeight || '0',
        overflowY:'scroll',
        minHeight: `calc(100vh - ${ headerHeight })` || '100vh',
        ...bodyPaddingMediaQueries
      },
    }
  }

  if (_.get(data, 'userOverrides.height') || displayHeaderTop) {
    headerHeight = '100px'
  }

  if (_.get(data, 'customHeaderMessage.userOverrides')) {
    callOutUserOverrides = userOverrides(global, data.customHeaderMessage, inEditor);
  }

  if (data.userOverrides) {
    headerHeightObject = createNewCssRules(
      data.userOverrides, 
      'height', 
      ['height']
    );

    headerBackgroundColorObject = createNewCssRules(
      data.userOverrides, 
      'backgroundColor', 
      ['backgroundColor']
    );
  }

  if (mainHeaderPosition === 'fixed' && data.userOverrides) {
    bodyPaddingMediaQueries = generateHeaderOffsetMediaQueries(data);

    mainHeaderPositionStyles = {
      "* body": {
        marginTop: positionFixed ? headerHeight : '0',
        ...generatePseudoMediaQuery(
          '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
            marginTop: positionFixed ? 
              _.get(data, 'userOverrides.@media(max-width:767px).height') : 
              '0',
            minHeight: positionFixed ? 
              `calc(100vh - ${ headerHeight })` : 
              '100vh',
          },
          inEditor
        ),
        ...bodyPaddingMediaQueries
      }
    }
  }

  return StyleSheet.create({
    header: {
      height: 'auto',
      paddingLeft: '70px',
      paddingRight: '70px',
      position: mainHeaderPosition,
      zIndex: '23',
      top: '0',
      width: '100%',
      backgroundColor: global.colors.light.white,
      ...mainHeaderPositionStyles,
      ...headerHeightObject,
      ...headerBackgroundColorObject,
      // ...generatePseudoMediaQuery(
      //   '@media(max-width: 767px)', 
      //   ['mobile', 'smallTablet'], 
      //   {
      //     paddingLeft: '0px',
      //     paddingRight: '0px',
      //     height: _.get(data, 'headerTopperSettings.display') ? '122px' : '89px',
      //   },
      //   inEditor
      // ),
      ...headerStyles,
      ...headerUserOverrides,
    },
    header_top: {
      fontFamily: global.fontFamily.primaryFont,
      fontSize: "16px",
      padding: '6px 20px',
      display: 'block',
      borderBottom: global.border,
      height: '40px',
    },
    header_top_lft: {
      height: '100%',
      display: 'flex',
      paddingTop: "5px",
      alignItems: "center"
    },
    socialIconContainer: {
      height: '20px',
      width: '20px',
      backgroundColor: global.colors.dark.color3,
      fill: global.colors.light.white,
      borderRadius: '50%',
      backgroundSize: '30px',
      ...generatePseudoMediaQuery("@media (max-width: 767px)", ['smallTablet', 'mobile'], {
        display: "none",
      }, inEditor),
    },
    socialIconWrapper: {
      float: 'left',
      ...generatePseudoMediaQuery(
        '@media (max-width: 767px)',
        ['smallTablet', 'mobile'],
        { 
          display: 'none'
        }, inEditor
      )
    },
    connect_us: {
      display: 'inline-flex',
      alignContent: 'center',
      float: 'left',
      color: global.colors.dark.color3,
      fontFamily: global.fontFamily.primaryFont,
      fontSize: "16px",
      lineHeight: "16px",
      margin: "0",
      padding: "0",
      ...generatePseudoMediaQuery("@media (max-width: 767px)", ['smallTablet', 'mobile'], {
        width: '100%',
        justifyContent: 'center',
        fontSize: '12px',
        lineHeight: '12px'
      }, inEditor),
    },
    customCallOut: {
      fontSize: '14px',
      color: global.colors.dark.color3,
      ...callOutUserOverrides
    },
    socialIcons: {
      paddingTop: "5px"
    },
    socialIcon: {
      height: '100%',
      width: '100%',
      fill: global.colors.light.white,
      padding: '3px',
    },
    fixedHeaderFix: {
        display: positionFixed ? 'block' : 'none',
        height: '4em'
    }
  });
}

export default header05Styles;
