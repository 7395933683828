import React from 'react';
import { css } from "@convertly/css";
import Component from '../../../rootComponent';
import Types from '../../../attribute-types';

import { id } from '@convertly/thor';


//Styles
import modalLauncherStyles from './modal-launcher-styles-01';

//Scripts
import ModalScript from '../scripts/modal';

//Components
import NewsLetterForm from '../../../widgets/newsletter-signup/newsletter-signup-3';
import Elements from '../../../elements/index';
import EditorModal from './editor-components/editor-modal';

//Default Props
import defaultGlobal from '../../../themes/global.js';


//Component
export default class
ModalLauncher01 extends Component {
    constructor ( props ) {
        super( props );

    }

    render() {

        function renderChildren(props) {
            return React.Children.map(props.children, child => {
                return React.cloneElement(child, {
                    moduleId: props.moduleId,
                    moduleSort: props.moduleSort,
                    styles: props.styles,
                    site: props.site,
                    sliderSettings: props.sliderSettings ? props.sliderSettings : null
                });
            })
        }

        let inEditor = !!this.props.inEditor;

        /** Generates border in editor */
        const inlineStyle = this.generateStyleAttribute( );

        /** init styles **/
        this.styles = modalLauncherStyles( this.props.global, this.props.data );

        let styles;

        if( this.props.styles ) {
            styles = this.props.styles;
        }
        else {
            styles = this.styles;
        }

        let rootID = id( ModalScript,
                         this.props.data.showOnLoad )

        /**
         * Checks if launcher type is Web Link or Button
         */

        let launcher;

        if (!this.props.data.buttonType) {

            launcher =

            <div>
                <a id={'modalLauncher_' + rootID } className={ css(this.styles.launcher) } >
                    { _.get( this.props, 'data.launcherText.text' ) || 'Click Here To Sign Up'  }
                </a>
            </div>

        } else {

            launcher =
        <div>
            <a id={'modalLauncher_' + rootID } className={ css( this.styles.buttonLauncher ) }>{ this.props.data.launcherText.text }</a>
        </div>
        }

        if ( this.props.data.hideButton ) {
            launcher = undefined;
        }

        // If inEditor is true, load the editor visual component instead of the jquery one

        let modalContentArea = !inEditor ? 

        <Elements.Container>
                    <div className={ css( this.styles.modal ) } id={"modalWidget_" + rootID}
                                { ...this.generateDataEditorAttribute() }
                                onClick={( e ) => {
                                    this.launchElementalEditor( 'modalEditor', e );
                                }}
                                onMouseOver={ (e) => {this.addEditableOutline(e)} }
                                onMouseOut={ (e) => {this.removeEditableOutline(e)} }
                                {...inlineStyle} >

                                    <div className={ css( this.styles.modalContentArea ) }
                                        { ...this.generateDataEditorAttribute() }
                                        {...inlineStyle}>

                                        <a data-fuckyou="fuckyou" className={ css(this.styles.closeButton) } id={'closeButton_' + rootID} >&times;</a>

                                        {
                                            renderChildren(this.props)
                                        }

                                    
                                    </div>
                                </div>
                    </Elements.Container> : <EditorModal { ...this.props }
                                                         styles={ this.styles }/>

                

        return (


                <div { ...this.generateDataEditorAttribute() }
                     onClick={( e ) => {
                         this.launchElementalEditor( 'modalEditor', e );
                     }}
                     onMouseOver={ (e) => {this.addEditableOutline(e)} }
                     onMouseOut={ (e) => {this.removeEditableOutline(e)} }
                     {...inlineStyle}>

                    {/* MODAL LAUNCHER */}

                    { launcher }

                    {/* MODAL CONTENT AREA */}
                    
                    { modalContentArea }

                </div>


        )
    }
}

ModalLauncher01.defaultProps = {
    data: {
        launcherText: {
            text: 'PREORDER NOW'
        },
        modalHeading: 'Preorder this product',
        modalParagraph: 'Kale chips la croix narwhal XOXO, hexagon blog PBR&B hella deep v. Tumeric occupy succulents, actually cornhole air plant vice chillwave.',
        "paragraph": {
            "text": "Get the most recent news, deals and offers delivered right to your mail box.",
            "color": false,
            "fontSize": false,
            "fontFamily": false
        },
        "newsletterFooter": {
            "text": "Keffiyeh chicharrones gluten-free everyday carry sustainable tousled listacle stumptown pop-up locavore vexillologist humblebrag vaporware.",
            "color": false,
            "fontSize": false,
            "fontFamily": false
        },
        "placeholder": {
            "text": "email@example.com",
            "color": false,
            "fontSize": false
        },
        "inputForm": {
            "color": false,
            "backgroundColor": false
        },
        "submitButton": {
            "text": "SUBSCRIBE",
            "color": false,
            "backgroundColor": false,
            "borderColor": false
        }
    },
    global: defaultGlobal
};