/*global Inferno, $ */
import React from 'react';

const Script = (dispatch, MinusIcon, PlusIcon) => {
	
	return (props) => {
		const {rootClass} = props;
		
		let addToCartClassName = `${ rootClass } add-to-cart-button`;
		addToCartClassName += props.disableAddToCartButton ? ' disabled' : '';
		
		return (
			
			<div className={ `${ rootClass } add-to-cart-qty-stepper-container` }>
				<div className={ `${ rootClass } qty-adjust-btn-container pull-right` }
						 onClick={ (e) => {
							 e.preventDefault();
							 props.decreaseQuantity()
						 } }>
					<MinusIcon nameOfClass={`${ rootClass } qty-adjust-btn`}/>
				</div>
        <div className={ `${ rootClass } outer-circle` }>
          <div className={ `${ rootClass } middle-circle` }>
            <div className={ `${ rootClass } inner-circle` }>
              <button
                onClick={ (e) => {
                  e.preventDefault();
                  props.addToCart(props.product)
                } }
                className={ addToCartClassName }>
                  Add <span className={`${ rootClass } cart-button-qty`}>{ props.currentQty }</span> to cart
              </button>
            </div>
          </div>
        </div>
				<div
					onClick={
						(e) => {
							e.preventDefault();
							props.increaseQuantity()
						}
					}
					className={ `${ rootClass } qty-adjust-btn-container pull-left` }>
					<PlusIcon nameOfClass={`${ rootClass } qty-adjust-btn`}/>
				</div>
			</div>
		)
	}
};

export default Script;
