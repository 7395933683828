import { StyleSheet } from "@convertly/css";
import Typography from '../../../../elements/style-functions/typography';

const headerNavItemStyles = ( global, data ) => {

    const navLinkTypography = new Typography( global, data );

    return StyleSheet.create({

        headerNavLink: {
            ...navLinkTypography
        },
        caretColor: {
            color: data.color || 'inherit'
        }
    })
};

export default headerNavItemStyles;