/*global Inferno, $ */
/* eslint-disable */
import { include } from '@convertly/thor';

const Script = () => {

	const Graph = include( '../../../utils/graph', 'Graph' );
	const Dispatcher = include( '../../../utils/dispatcher', 'Dispatcher' );

	return( id ) => {

		const filename = 'master-control';

		/** Init Imports **/
		const graph = Graph( );
		const dispatch = Dispatcher( id );

		const root = document.getElementById( id );

		const CheckoutMasterControl = Inferno.createClass( {


			getInitialState: function( ) {
				return {
				};
			},


			componentWillMount: function( ) {
				dispatch.on( 'cart_total', this.set.bind( this, 'cartTotal' ) );
			},


			componentDidMount: function( ) {

			},


			set: function( field, value ) {
				const params = {};
				params[ field ] = value;
				this.setState( params );
			},

			render: function( ) {
				return null;
			}

		} );

		Inferno.render( <CheckoutMasterControl />, root );

	};

};

export default Script;
