import { createAction } from "redux-act";
import _ from "lodash";
import { fromJS } from "immutable";
import { store } from "app/app";
import Logger from "utils/logger";
import { graph, purgeSiteCache } from "app/utils";

export const setBusinessInfo = createAction("Site Settings: Set business info");
export const setLoaded = createAction("Site Settings: Set business info loaded flag");

export function updateBusinessInfo(businessInfo) {
  return async dispatch => {
    await dispatch(setBusinessInfo(fromJS(businessInfo)));
  };
}

export function saveBusinessInfo() {
  return async dispatch => {
    const siteId = store.getState().getIn(["auth", "activeSite", "id"]);
    const businessInfo = store
      .getState()
      .get("siteSettings")
      .BusinessInfo.get("settings");

    const query = `
      mutation( $id:ID!, $settings:siteGeneralSettingsInput ) {
        updateSiteGeneralSettings(id:$id, settings: $settings ) {
        id
        }
      }
      `;

    const variables = {
      id: siteId,
      settings: businessInfo.toJS()
    };

    await graph({ query, variables });
  };
}

export function loadBusinessInfo() {
  return async dispatch => {
    const siteId = store.getState().getIn(["auth", "activeSite", "id"]);

    const query = `
      query ($id: ID! ) {
        Site(id: $id) {
        generalSettings {
          businessName
          address
          city
          state
          zip
          country
          phone
          fax
          email
        }
        }
      }`;

    const variables = {
      id: siteId
    };

    const response = await graph({ query, variables });
    const businessInfo = _.get(response, "Site.generalSettings") || {};

    await dispatch(setBusinessInfo(fromJS(businessInfo)));
    await dispatch(setLoaded(true));
  };
}
