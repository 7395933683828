import {StyleSheet} from "@convertly/css";
import _ from 'lodash';

import UserOverrides from '../../../elements/style-functions/user-overrides';
import {generatePseudoMediaQuery} from '../../../elements/style-functions/pseudo-media-queries';
import {addValueToUserOverrides, createNewCssRules} from '../../../utils/edit-userOverrides';
import searchBarStyleObject from '../../../ecommerce-common-components/search-bar/searchBarStyleObject';



const HeaderStyles4 = (global, data, headerPosition, inEditor) => {

    const searchBarStyles = searchBarStyleObject(global, data, inEditor);

    let dropDownBg = createNewCssRules(data.userOverrides, 'backgroundColor', ['backgroundColor']);
    if(dropDownBg.hasOwnProperty("backgroundColor")) {
        //parse the colors
        dropDownBg =  UserOverrides(global, {userOverrides:{...dropDownBg}}, inEditor, true)
    }

    let modifiedColor,
        overlayBorderStyle = {},
        navLinkOverrides = {},
        dropDownOverrides = {},
        navLinkAdditionalStyles = {},
        dropDownAdditionalStyles = {},
        iconBackground;

    const headerHeight = _.get( data, 'headerTopperSettings.display') ? 'calc(100% - 40px)' : "100%";

    let logoOverrides = _.get(data, 'logoOverrides.userOverrides') ?
        UserOverrides(global, data.logoOverrides, inEditor) :
        {};

    /** USER OVER RIDES **/
    //Create Nav Link User Overrides
    if (_.get(data, "menu.userOverrides")) {
        let editedNavLinkOverrides = {
            userOverrides: addValueToUserOverrides(
                _.get(data, "menu.userOverrides"),
                "color",
                ["fill"])
        };

        navLinkOverrides = UserOverrides(global, editedNavLinkOverrides, inEditor);
        navLinkAdditionalStyles = createNewCssRules( navLinkOverrides, 'color', ['color', 'borderColor', 'fill'] )
        iconBackground = createNewCssRules( navLinkOverrides, 'color', ['backgroundColor'] );
    }
    // Pulls background color from userOverrides
    let mobileBackgroundColor = {};
    if (data.userOverrides) {
      mobileBackgroundColor = createNewCssRules(data.userOverrides, 'background', ['background']);
      mobileBackgroundColor = createNewCssRules(data.userOverrides, 'backgroundColor', ['background']);
    }
    //Create Drop Down User Overrides
    if (_.get(data, "dropdown.userOverrides")) {
        /** map through userOverrides make fill match color **/
        let editedDropdownOverrides = {
            userOverrides: addValueToUserOverrides(_.get(data, "dropdown.userOverrides"),
                "color",
                ["fill"])
        };
        dropDownOverrides = UserOverrides(global, editedDropdownOverrides, inEditor);

        dropDownAdditionalStyles = createNewCssRules( dropDownOverrides, 'color', ['color', 'borderColor', 'fill'] )
    }

    const dropdownColor = _.get(data, "dropdown.userOverrides.color") ||
        _.get(data, "menu.userOverrides.color") ||
        global.colors.light.color1;

    /** Header Position styles **/
    if (headerPosition === "overlayFixed" || headerPosition === "overlay") {
        overlayBorderStyle = {
            '.absolute': {
                borderColor: global.colors.light.white,
                color: global.colors.light.white,
                stroke: global.colors.light.white,
                fill: global.colors.light.white
            }
        }
    }

    if (headerPosition === "overlay") {
        modifiedColor = global.colors.light.white
    }
    else {
        modifiedColor = global.colors.light.white
    }

    const caret = {
        display: "inline-block",
        height: '17px',
        width: '17px',
        cursor: 'pointer',
        boxSizing: 'content-box',
        marginTop: '-6px',
        marginLeft: '5px',
        stroke: 'inherit',
        verticalAlign: 'sub'
    };

    const navListItem = {
        display: 'inline-block',
        cursor: 'pointer',
        fontSize: '18px',
        stroke: 'inherit',
        position: 'relative',
        ...generatePseudoMediaQuery(
            "@media (max-width: 768px)",
            ['mobile', 'smallTablet'],
            {
                padding: "10px"
            },
            inEditor
        )
    };

    const dropdown = {
        listStyle: 'none',
        height: 'auto',
        width: 'fit-content',

    };



    const dropdownList = {
        display: 'none',
        position: 'absolute',
        top: '140%',
        right: '-34px',
        ...dropDownBg.hasOwnProperty("backgroundColor") ? dropDownBg : {background:'rgba(0,0,0,0.9)'},
        border: `2px solid ${global.colors.light.color3}`,
        color: global.colors.light.color1,
        boxSizing: 'content-box',
        listStyle: 'none',
        padding: '20px 20px 8px',
        zIndex: '1',
        fontSize:'14px',
        '.open': {
            display: 'block'
        },
        ...generatePseudoMediaQuery(
            "@media (max-width: 768px)",
            ['mobile', 'smallTablet'],
            {
                position: 'static',
                border: 'none',
                background: 'transparent',
                fontSize:'12px',
            },
            inEditor
        ),
        ...dropDownOverrides
    };

    const searchBarWrapperFocusStyles = {
      borderBottom: `2px solid ${ global.colors.accent.color2 }`,
      fill: global.colors.accent.color2,
      'box-shadow': `0 0 0 0 ${ global.colors.dark.color3 }`,
    }


    return StyleSheet.create({
      searchBarWrapper: {
        display: 'inline-block',
        height: '25px',
        ...searchBarStyles,
        '.spinner': {
          verticalAlign: 'middle',
          height: '30px',
          width: '30px',
        },
        '.search-bar-container': {
          width: '100%',
          ...generatePseudoMediaQuery(
            '@media(max-width: 991px)', ['largeTablet', 'smallTablet', 'mobile'], {
              display: 'block'
            },
            inEditor
          ),
        },
        '.search-bar-results': {
          position: 'absolute',
          width: '100%',
          top: '3px',
          height: '0',
        },
        '.search-bar-wrapper': {
          display: 'flex',
          borderBottom: `2px solid transparent`,
          'box-shadow': `0 1px 0 0 ${ global.colors.dark.color3 }`,
          padding: '4px 4px',
          width: '100%',
          transition: 'all 0.2s linear',
          'align-items': 'center',
          transform: 'translateY(3px)',
          ':focus-within': {
            ...searchBarWrapperFocusStyles
          },
          '.has-input': {
            ...searchBarWrapperFocusStyles
          }
        },
        '.search-bar-input': {
          border: 'none',
          padding: '0px 2px',
          height: '100%',
          width: '100%',
          color: 'inherit',
          backgroundColor: 'transparent',
          lineHeight: '22px',
          fontSize: '16px'
        },

        '.product-item': {
          display: 'block',
          padding: '4px 4px',
          border: `1px solid ${ global.colors.dark.color3 }`,
          borderTop: 'none',
          listStyle: 'none',
          cursor: 'pointer',
          backgroundColor: "rgba(0,0,0,0.9)",
          'text-overflow': 'ellipsis',
          'overflow': 'hidden',
          'white-space': 'nowrap',
          'min-height': 'fit-content',
          color: 'inherit',
          ':hover': {
            backgroundColor: global.colors.light.color2
          },
          '.no-hover': {
            ':hover': {
              backgroundColor: 'white',
              cursor: 'default'
            },
          }
        },

        '.search-bar-input-placeholder': {
          position: 'absolute',
          left: '0',
          transition: 'all 0.3s linear',
          'pointer-events': 'none',
          top: '-2px',
          '.has-input': {
            marginTop: '-15px',
            fontSize: '11px',
            color: global.colors.accent.color2,
          }
        },

        ...generatePseudoMediaQuery(
          '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
            display: 'none',
          },
          inEditor
        ),
        '.mobile': {
          marginLeft: '4px',
          display: 'none',
          marginRight: '18px',
          '.x-icon': {
            fontSize: '26px',
            cursor: 'pointer',
            marginRight: '-11px',
          },
          '.search-bar-container': {
            width: '100%',
            ...generatePseudoMediaQuery(
              '@media(max-width: 991px)', ['largeTablet', 'smallTablet', 'mobile'], {
                display: 'block'
              },
              inEditor
            ),
          },
          '.search-bar-wrapper': {
            '.static': {
              display: 'flex',
              border: `2px solid ${ global.colors.light.color3 }`,
              padding: '4px',
              width: '100%',
              'align-items': 'center',
              height: '107%',
              transition: 'all 0.3s ease',
              marginRight: '-2px',
              transform: 'translateY(0px)',
              'box-shadow': `0 0 0 0 ${ global.colors.dark.color3 }`,
              ':focus-within': {
                border: `2px solid ${ global.colors.accent.color2 }`,
                fill: global.colors.accent.color2,
                color: global.colors.accent.color2
              },
            }
          },
          '.search-bar-input': {
            border: 'none',
            padding: '0px 2px',
            height: '100%',
            width: '100%',
            backgroundColor: 'transparent',
            color: global.colors.light.white,
            lineHeight: '22px',
            fontSize: '16px'
          },
          '.search-bar-product-items-list': {
            transition: 'all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s',
            position: 'absolute',
            width: '100%',
            color: global.colors.dark.black
          },
          '.search-bar-results-wrapper': {
            position: 'relative',
            width: '100%',
            left: '-16px',
            'z-index': '22',
            top: '-3px',
            display: 'block',
            '.active': {
              display: 'none',
            }
          },
          '.search-bar-results': {
            position: 'absolute',
            width: '100%',
          },
          '.product-item': {
            display: 'block',
            padding: '4px 4px',
            border: `2px solid ${ global.colors.light.color3 }`,
            borderTop: 'none',
            listStyle: 'none',
            cursor: 'pointer',
            backgroundColor: 'rgba(0,0,0,0.9)',
            'text-overflow': 'ellipsis',
            'overflow': 'hidden',
            'white-space': 'nowrap',
            width: '100%',
            color: global.colors.light.white,
            ':hover': {
              backgroundColor: global.colors.light.color2
            },
            '.no-hover': {
              ':hover': {
                backgroundColor: 'white',
                cursor: 'default'
              },
            }
          },
          ...generatePseudoMediaQuery(
            '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
              display: 'block',
            },
            inEditor
          ),
        }
      },
        menuContainer: {
            display: 'flex',
            justifyContent: 'center',
            borderColor: modifiedColor,
            borderRadius: '1px',
            fill: modifiedColor,
            color: modifiedColor,
            height: '100%',
            ...overlayBorderStyle
        },
        logo: {
            position: "absolute",
            display: 'flex',
            alignItems: 'center',
            left: "15px",
            width: 'auto',
            height: headerHeight,
            maxWidth:'200px',
            ...generatePseudoMediaQuery(
                "@media (max-width: 768px)",
                ['mobile', 'smallTablet'],
                {
                    position: "static",
                    height:'100%',
                    order: "2"
                },
                inEditor
            ),
            ...logoOverrides
        },
        logoImg: {
            width: 'auto',
            maxHeight: "35px",
            maxWidth:'100%',
            '.absolute': {
                display: 'block'
            },
            '.default': {
                display: 'block'
            }

        },
        menuLauncherIcon: {
            display: 'none',
            order: 2,
            float: 'right',
            marginRight: "15px",
            marginTop: '8px',
            ...generatePseudoMediaQuery(
                "@media (max-width: 768px)",
                ['mobile', 'smallTablet'],
                {
                    display: "flex",
                    alignSelf:"center",
                    alignItems: 'center',
                    marginRight: '0',
                    position: "absolute",
                    left: "10px",
                    marginTop: 0,
                    zIndex:'56'
                },
                inEditor
            ),
        },
        navLaunch: {
            zIndex: 45,
            display: "none",
            paddingBottom: 0,
            overflow: "visible",
            background: "none",
            border: "none",
            outline: "none",
            ...generatePseudoMediaQuery(
                "@media (max-width: 768px)",
                ['mobile', 'smallTablet'],
                {
                    display: 'block'
                },
                inEditor
            ),
        },
        iconBar: {
            backgroundColor: modifiedColor,
            width: "20px",
            height: "3px",
            marginBottom: "3px",
            position: "relative",
            display: "block",
            zIndex: 50,
            transition: 'all 0.3s ease-in-out 0s',
            ':nth-child(3)': {
                width: "16px"
            },
            ".absolute": {
                backgroundColor: global.colors.light.white,

            },
            '.open': {
                ":nth-child(2)": {
                    transform: "rotate(45deg)",
                    top: "7px",
                },
                ":nth-child(4)": {
                    transform: "rotate(-45deg)",
                    top: "-5px"
                },
                ":nth-child(3)": {
                    opacity: 0
                }
            },
            ...iconBackground
        },
        mainMenu: {
            zIndex: 45,
            width: 'fit-content',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            '.open': {
                display: 'block',
                visibility: 'visible',
                left: '0',
            },
        },
        headerNav: {
            marginTop: "8px",
            display: 'inline-block'
        },
        navList: {
            listStyle: 'none',
            height: 'auto',
            width: 'fit-content',
            transition: 'position 4s ease',
            ...mobileBackgroundColor,
            ...generatePseudoMediaQuery(
                "@media (max-width: 768px)",
                ['mobile', 'smallTablet'],
                {
                    paddingBottom: "10px",
                    position: 'absolute',
                    top: '-640%',
                    right: '0',
                    width: '100%',
                    background: "rgba(0,0,0,0.9)"
                },
                inEditor
            ),
            '.open': {
                top: '80px',
                zIndex: 55,
                minHeight: '80px'
            }

        },
        navListItem: {
            width: "fit-content",
            ...navListItem,
            ...generatePseudoMediaQuery(
              "@media (max-width: 841px)",
              ['mobile', 'smallTablet'], {
                fontSize: '14px'
              },
              inEditor
            ),

        },
        navListItemText: {
            opacity: ".4",
            ".active": {
                opacity: "1",
                transition: "100ms ease"
            },
            ":hover": {
                "@media (hover:hover)": {
                    transition: "100ms ease",
                    opacity: "1"
                }
            },
            ...generatePseudoMediaQuery(
                "@media (max-width: 768px)",
                ['mobile', 'smallTablet'],
                {
                    display: "block"
                },
                inEditor
            ),
            ...navLinkOverrides
        },

        contactNavListItem: {
            ...navListItem,
            padding: '24px 10px 0 10px',
            ...generatePseudoMediaQuery(
                "@media (max-width: 768px)",
                ['mobile', 'smallTablet'],
                {
                    marginTop: '10px',
                    textDecoration: 'underline'
                },
                inEditor
            ),
            ...navLinkOverrides
        },
        caret: {
            ...caret,

        },
        contactCaret: {
            ...caret,
            ...generatePseudoMediaQuery(
                "@media (max-width: 768px)",
                ['mobile', 'smallTablet'],
                {
                    visibility: 'hidden'
                },
                inEditor
            ),
        },
        dropdown: {
            ...dropdown,
            ...generatePseudoMediaQuery(
                "@media (max-width: 768px)",
                ['mobile', 'smallTablet'],
                {
                    float: 'none',
                },
                inEditor
            ),
            ...navLinkOverrides

        },
        contactDropdown: {
            ...dropdown
        },
        dropdownList: {
            ...dropdownList,
        },
        contactDropdownList: {
            ...dropdownList,
            minWidth:'270px'
        },
        dropDownItem: {
            display: 'none',
            position: 'absolute',
            top: '140%',
            right: '0',
            width: "200px",
            listStyle: 'none',
            padding: '10px',
            marginBottom: '20px',
            '.open': {
                display: 'block'
            },
        },

        contactListHeader: {
            display: 'block',
            width: '50%',
            borderBottom: '1px solid',
            paddingBottom: '12px',
            color: dropdownColor,
            borderColor: dropdownColor,
            textTransform: 'uppercase',
            fontSize: '18px',
            fontWeight: 'bold',
            ...generatePseudoMediaQuery(
                "@media (max-width: 768px)",
                ['mobile', 'smallTablet'],
                {
                    display: 'none'
                },
                inEditor
            ),
            ...navLinkAdditionalStyles,
            ...dropDownAdditionalStyles
        },
        arrowUp: {
            width: 0,
            height: 0,
            position: 'absolute',
            top: '-13px',
            right: '28px',
            borderLeft: '12px solid transparent',
            borderRight: '12px solid transparent',
            borderBottom: '12px solid',
            borderBottomColor: global.colors.light.color3,
            ...generatePseudoMediaQuery(
                '@media( max-width:768px )',
                ['smallTablet', 'mobile'], {
                display: 'none'
            }, inEditor
            ),
        },
        innerArrow: {
            width: 0,
            height: 0,
            position: 'relative',
            top: '3px',
            left: '-10px',
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderBottom: '10px solid',
            borderBottomColor: global.colors.light.white,
            ...generatePseudoMediaQuery(
                '@media ( min-width:768px )',
                ['desktop', 'largeTablet'],
                {
                    borderBottomColor: _.get(data, 'dropdown.userOverrides.backgroundColor')
                        || _.get(data, 'dropdown.userOverrides.background')
                        || dropDownBg.backgroundColor
                        || 'rgba(0,0,0,0.9)',
                }, inEditor
            )
        },
        downNavItem: {
            margin: '20px 0',
            width:"max-content",
            ':hover': {
                opacity: '.8'
            },
            ...generatePseudoMediaQuery(
                '@media ( min-width:768px )',
                ['desktop', 'largeTablet'],
                {
                    margin:'5px'
                }, inEditor
            ),
            ...navLinkOverrides
        },

        dropdownIconContainer: {
            fill: dropdownColor,
            display: 'inline-block',
            width: '10%',
            verticalAlign: 'middle',
            ...navLinkAdditionalStyles,
            ...dropDownAdditionalStyles
        },
        menuIcon: {
            width: '18px',
            height: '18px',
        },
        contactLinkContainer: {
            display: 'inline-block',
            marginLeft: '5px',
            width: '85%',
            verticalAlign: 'middle'
        },
        contactDownNavText: {
            fontSize: '12px',
            lineHeight: '12px',
            padding: '10px 12px 10px 5px',

        },
        dropDownlink: {
            fontSize: '15px'
        },

        ctaLink: {
            position: "absolute",
            right: "25px"
        },

        navLink: {
            color: global.colors.light.white,
            fontFamily: global.fontFamily.primaryFont,
            ...navLinkOverrides
        },
        navItemContainer: {
            float: 'left',
            margin: '0px 20px',
            ...generatePseudoMediaQuery(
                "@media (max-width: 768px)",
                ['mobile', 'smallTablet'],
                {
                   float:'none',
                    display:'block'
                },
                inEditor
            ),

        },
        desktopCartInfernoComponent: {
            ":hover ": {
                "@media(hover:hover)": {
                    ".cartDropDown": {
                        display: 'block'
                    },
                    ".casper": {
                        display: 'block'
                    }
                }
            },
            ".casper": {
                position: 'absolute',
                top: '0',
                right: '0',
                height: '60px',
                width: '425px',
                background: 'transparent',
                display: 'none'
            },
            ".headerNavLink": {
                ...navListItem,
                opacity: ".4",
                ".active": {
                    opacity: "1",
                    transition: "100ms ease"
                },
                ":hover": {
                    "@media (hover:hover)": {
                        transition: "100ms ease",
                        opacity: "1"
                    }
                },
                ...navLinkOverrides
            },
            ".cartLink": {

                //color: global.colors.accent.color1
            },
            ".cartDropDown": {
                display: 'none',
                cursor: 'default',
                position: 'absolute',
                top: '0',
                right: '-1px',
                width: '425px',
                height: "460px",
                background: 'white',
                zIndex: '50',
                paddingRight: "0",
                border: global.border
            },
            ".cartContainer": {
                height: '350px',
                overflowY: 'auto',
                width: '100%',
                borderBottom: global.border
            },
            ".cartContainerFooter": {
                marginTop: '20px',
                textAlign: 'center'
            },
            ".checkoutLink": {
                display: 'block',
                ...global.button1
            },
            ".viewCartLink": {
                display: 'block',
                color: global.colors.dark.color1
            },
            ".checkRightPanel": {
                padding: '30px',
                alignItems: "stretch",
                backgroundColor: "transparent",
                color: global.colors.dark.color1,
                display: "flex",
                flex: "1",
                flexDirection: "row",
                fontSize: "14px",
                justifyContent: "center",
                lineHeight: "20px",
                paddingTop: "30px"
            },
            ".cart-item-container": {
                padding: '15px'
            },
            ".change-message-bg": {
                backgroundColor: '#e3f2fd'
            },
            ".change-message-container": {
                borderTop: `1px solid ${global.colors.light.color2}`,
                paddingTop: '10px',
                fontFamily: global.fontFamily.secondaryFont
            },
            ".change-message-text": {
                textTransform: 'none',
                fontSize: '14px',
                color: global.colors.dark.color2
            },
            ".change-message-action": {
                display: 'block'
            },
            ".change-message-link": {
                fontSize: '16px',
                display: 'inline-block',
                margin: "5px 10px 0 0",
                cursor: 'pointer'
            },

            ".chkRightPanelContainer": {
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "100%",
                width: "100%"
            },

            ".chkTitle1_mobileTitle1": {
                borderBottom: "2px none #667785",
                color: global.colors.dark.color1,
                display: "none",
                flex: "0 auto",
                flexDirection: "row",
                fontSize: "32px",
                fontWeight: "400",
                lineHeight: "36px",
                marginBottom: "10px",
                marginTop: "0px",
                paddingBottom: "20px",
                width: "100%"
            },

            ".mobileUnderline_titleUnderline": {
                backgroundColor: global.colors.dark.color1,
                borderRadius: "5px",
                color: global.colors.dark.color1,
                display: "none",
                fontSize: "14px",
                height: "3px",
                lineHeight: "20px",
                marginBottom: "20px",
                width: "45%"
            },

            ".chkGridheadercontainer": {
                color: global.colors.dark.color1,
                display: "flex",
                fontSize: "14px",
                justifyContent: "flex-end",
                lineHeight: "20px",
                marginBottom: "15px"
            },

            ".chkProdgridimgcontainer": {
                alignItems: "flex-start",
                color: global.colors.dark.color1,
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                justifyContent: "center",
                lineHeight: "20px",
                maxWidth: "18%",
                textAlign: "center",
                width: "20%"
            },

            ".chkGridprice_th": {
                alignItems: "flex-start",
                color: global.colors.dark.color1,
                display: "flex",
                flex: "0 auto",
                fontFamily: global.fontFamily.secondaryFont,
                fontSize: "11px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                paddingLeft: "0px",
                width: "125px"
            },

            ".chkGridqty_chkUnitprice_th": {
                alignItems: "flex-start",
                color: global.colors.dark.color1,
                display: "flex",
                flex: "1",
                fontFamily: global.fontFamily.secondaryFont,
                fontSize: "11px",
                justifyContent: "center",
                lineHeight: "20px",
                textAlign: "center"
            },

            ".chkGridsubtotal_th": {
                color: global.colors.dark.color1,
                display: "flex",
                flex: "1",
                fontFamily: global.fontFamily.secondaryFont,
                fontSize: "11px",
                justifyContent: "flex-end",
                lineHeight: "20px",
                textAlign: "center"
            },

            ".chkProdgrid": {
                color: global.colors.dark.color1,
                display: "flex",
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "20px",
                position: "relative",
            },

            ".chkProdgridimg": {
                border: "0",
                color: global.colors.dark.color1,
                display: "inline-block",
                fontSize: "14px",
                height: "60px",
                width: "60px",
                lineHeight: "20px",
                maxWidth: "100%",
                textAlign: "center",
                verticalAlign: "middle",
                marginBottom: '10px'

            },

            ".chkRemovelink": {
                backgroundColor: "transparent",
                color: "#b4bbc3",
                fontSize: "10px",
                lineHeight: "20px",
                maxWidth: "100%",
                textAlign: "center",
                paddingLeft: '7px'
            },

            ".divBlock_2": {
                color: global.colors.dark.color1,
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                lineHeight: "20px",
                width: "225px"
            },

            ".chkGridprice": {
                alignItems: "flex-start",
                color: global.colors.dark.color1,
                display: "flex",
                flex: "0 auto",
                fontSize: "12px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                paddingLeft: "0px",
                width: "225px"
            },
            ".subTitle": {
                fontSize: '10px',
                color: global.colors.dark.color2
            },
            ".chkGridprice_chkGridpricenote": {
                alignItems: "flex-start",
                color: "#118bef",
                display: "flex",
                flex: "0 auto",
                fontSize: "12px",
                justifyContent: "flex-start",
                lineHeight: "16px",
                paddingLeft: "0px",
                width: "auto"
            },

            ".chkGridqty_chkUnitprice_strikethrough": {
                alignItems: "flex-start",
                color: "#667785",
                display: "flex",
                flex: "0 auto",
                fontSize: "12px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                textAlign: "center",
                textDecoration: "line-through"
            },

            ".actualprice_chkGridqty_chkUnitprice": {
                alignItems: "flex-start",
                color: "#118bef",
                display: "flex",
                flex: "1",
                fontSize: "14px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                textAlign: "center"
            },

            ".chkGridqty": {
                alignItems: "flex-start",
                color: "#667785",
                display: "flex",
                flex: "1",
                fontSize: "14px",
                justifyContent: "flex-end",
                lineHeight: "20px",
                textAlign: "center"
            },

            ".counterIndicator": {
                backgroundColor: "transparent",
                color: "#667785",
                fontSize: "14px",
                lineHeight: "20px",
                padding: "4px 9px",
                textAlign: "center",
                textDecoration: "none"
            },

            ".counternumber": {
                border: global.border,
                color: "#667785",
                flex: "0 auto",
                fontSize: "14px",
                lineHeight: "20px",
                padding: "4px 9px 3px",
                textAlign: "center"
            },

            ".chkGridsubtotal": {
                color: global.colors.dark.color2,
                display: "flex",
                flex: "1",
                fontSize: "14px",
                lineHeight: "20px"
            },

            ".progresscontainer": {
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                paddingTop: "10px",
                position: "relative"
            },

            ".truck": {
                border: "0",
                color: global.colors.dark.color1,
                display: "inline-block",
                fontSize: "14px",
                lineHeight: "20px",
                maxWidth: "55px",
                position: "absolute",
                right: "0px",
                top: "0px",
                verticalAlign: "middle"
            },

            ".progresstext": {
                borderBottomColor: "#000",
                borderBottomWidth: "3px",
                borderStyle: "none",
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                paddingBottom: "10px",
                paddingRight: "80px"
            },

            ".progressline_titleUnderline": {
                backgroundColor: global.colors.dark.color1,
                borderRadius: "5px",
                color: global.colors.dark.color1,
                fontSize: "14px",
                height: "3px",
                lineHeight: "20px",
                marginBottom: "15px",
                width: "100%"
            },

            ".cartToggleContainer": {
                color: global.colors.dark.color1,
                display: "none",
                fontSize: "14px",
                lineHeight: "20px"
            },
            ".cartTitleSub": {
                color: global.colors.dark.color1,
                fontSize: "32px",
                fontWeight: "bold",
                lineHeight: "36px",
                marginBottom: "10px",
                marginTop: "20px"
            },

            ".toggleLinkblock_wInlineBlock": {
                backgroundColor: "transparent",
                color: global.colors.dark.color1,
                display: "inline-block",
                fontSize: "14px",
                lineHeight: "20px",
                maxWidth: "100%"
            },
            ".toggleCollapsed": {
                border: "0",
                color: global.colors.dark.color1,
                display: "inline-block",
                fontSize: "14px",
                lineHeight: "20px",
                maxWidth: "100%",
                verticalAlign: "middle"
            }
        },
        socialIconSection: {
            display:'none',
            ...generatePseudoMediaQuery(
                "@media (max-width: 768px)",
                ['mobile', 'smallTablet'],
                {
                    float:'none',
                    display:'block',
                    margin: '10px 25px'
                },
                inEditor
            ),
        },
        socialIconWrapper: {
            float: 'left',
            ...generatePseudoMediaQuery("@media (max-width: 768px)",
                ['smallTablet', 'mobile'], {
                    display:'none'
                }, inEditor),
        },
        socialIcons: {
            paddingTop: "5px"
        },
        socialIcon: {
            height: '100%',
            width: '100%',
            padding: '3px',
            fill: 'inherit'
        },

    });

};

export default HeaderStyles4
