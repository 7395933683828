import React from 'react';
import PropTypes from 'prop-types';
import Component from '../../rootComponent';
import {css} from "@convertly/css";
import defaultGlobal from '../../themes/global.js';
import ReactHTMLParser from "@convertly/react-html-parser";
import { id } from '@convertly/thor';

//Styles
import headingStyles from './zero-fill-form-styles';

//Scripts
import ContactForm from './contact-us-form';

//Components
import Elements from 'source/elements/index'

/**
 * Class controls imports style functions that allows for user to override the css in the CMS editor for their site.
 **/
class ZeroFillContactForm extends Component {

    constructor ( props ) {
        super ( props );

    }

    render () {

        this.styles = headingStyles ( this.props.global, this.props.data );
        /**
         * REPLACE ONCE FORM BUILDER IS MADE
         **/
         /** Finds site id and loads it **/
        const {site} = this.props;

        let siteId = '';

        if (site) {
            siteId = site.siteId;
        }
        const formId = this.props.data.formId || 0;
        const events = this.props.data.gaEvent || [];

         const rootId = id ( ContactForm, siteId, JSON.stringify( events ), formId ) ;
        return (
            <div  id={ rootId }  className={ css(this.styles.contactUsFormContainer) }>
                {/** Contact Form **/}
                <div/>
                <div id={ `${rootId}-wrapper` } className={ css( this.styles.formWrapper ) }>
                    <Elements.Heading3
                        elementId={ `${ rootId }-heading` }
                        parentClassName={ this.styles.contactUsFormHeading }
                        data={ this.props.data.contactUsFormHeader }
                        global={ this.props.global }
                        elementDataName="contactUsFormHeader"
                        moduleId={ this.props.id }
                        moduleSort={ this.props.sort }/>
                        <div id={`${rootId}-divider` } className={ css( this.styles.divider ) }/>
                    <form id={ `${rootId}-form` } className={ css(this.styles.contactUsForm) }>
                        <input type="text"
                               id={ `${rootId}-name` }
                               className={ css(this.styles.formInput) }
                               placeholder="Name"/>
                        <input type="text"
                               id={ `${rootId}-email` }
                               className={ css(this.styles.formInput) }
                               placeholder="Email"/>
                        <textarea className={ css(this.styles.formTextArea) }
                                  id={ `${rootId}-textArea` }
                                  rows="4"
                                  placeholder="Your message here..."/>
                        <input type="button"
                               id={ `${rootId}-submit` }
                               role="button"
                               className={ css(this.styles.submitButton) }
                               value="SUBMIT"/>
                    </form>
                </div>
            </div>
        );
    }
}


/**
 * Default Props for rendering when no data is being passed
 **/
ZeroFillContactForm.defaultProps = {
    data: {
        text: "Hello from the other side",
        bold: false,
        italic: false,
        underline: false,
        align: 'center',
        fontSize: '',
        color: ''
    },
    global: defaultGlobal
};

/**
 * Connects element to context for cms editor
 **/
ZeroFillContactForm.contextTypes = {
    store: PropTypes.object
};

export default ZeroFillContactForm

/**
 Component BoilerPlate

 <Elements.ZeroFillContactForm
 parentClassName={ this.styles.heroTitle }
 data={ this.props.data.heroTitle }
 global={ this.props.global }
 elementDataName="heroTitle"
 moduleId={ this.props.id }
 moduleSort={ this.props.sort }/>

 **/
