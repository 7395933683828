import {generateMSStyleSheet} from "../../utils/browserSpecificStyles";

export default function GenericModalStyles(global, data) {

    const ieShippingModalStyles = {
        maxWidth:'650px',

    };

    const ieShippingModal = generateMSStyleSheet( ieShippingModalStyles );

    return {
        ".generic-modal-wrapper": {
            position:'fixed',
            top:"0",
            left:"0",
            bottom:0,
            right:0,
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            zIndex:99
        },
        ".generic-modal-overlay": {
            position:'absolute',
            height:'100%',
            width:'100%',
            backgroundColor:"rgba(0, 0, 0, 0.5)"
        },
        ".generic-modal": {
            position: 'relative',
            width: '500px',
            background: "#fff",
            padding:'20px',
            border:global.border,
            ...ieShippingModal,
            "@media (max-width:767px)": {
                width: '100%',
                left: '0',
                minWidth:'unset'
            },
            "@media (max-width:767px) and (min-width: 515px)": {
                paddingTop: '20px'
            },
            "@media (max-width: 515px)": {
                padding: '17px'
            }

        },

        '.closeButton': {
            position: 'absolute',
            padding: '9px 10px 10px',
            top: '-15px',
            right: '-15px',
            height: '45px',
            width: '45px',
            border: global.border,
            borderRadius: '100%',
            background: global.colors.light.color1,
            cursor: 'pointer',
            zIndex: '2',
            "@media (max-width:767px)": {
                right: '10px',
                top: '10px',
                background: 'none',
                border: 'none'
            },

        },
        '.closeButtonIcon': {
            fill: global.colors.dark.color1,
            stroke: global.colors.dark.color1,
            "@media (max-width:767px)": {
                height: '20px',
                width: '20px'
            },
        },

        '.closeIcon': {
            height: '20px',
            width: '20px'
        },

    }

}
