import colorAssign from '../colorAssign';

let defaultGlobal = {
    colors: {
        dark: {
            color1:'#2D2D2D',
            color2:'#515151',
            color3:'#5F5F5F',
            black: '#000'
        },
        accent: {
            color1:'#599800',
            color2:'#923CC6',
            color3:'#680D9F',
        },
        light: {
            color1:'#FAFAFA',
            color2:'#E9F1DE',
            color3:'#878787',
            white: '#fff'
        }
        
    },
    textColors: {
        light:{},
        dark:{},
        accent:{}
    },
    backgroundColors: {
        light:{},
        dark:{},
        accent:{}
    },
    fontFamily: {
        primaryFont: "\'Roboto\', sans-secondaryFont",
        secondaryFont: " \'Asar\', secondaryFont "
    },
    fonts: [ 'Roboto', 'Asar' ],
    button1: {
        display: 'inline-block',
        height: '50px',
        lineHeight: '50px',
        textAlign: 'center',
        fontSize: '20px',
        padding: '0 58px',
        borderRadius: '30px',
        transition: 'all 0.3s linear 0s',
    },
    button2: {
        height: '50px',
        lineHeight: '50px',
        padding: '0 45px',
        fontSize: '20px',
        transition: 'all 0.3s linear 0s',

    },
    button3: {
        padding: '0 70px',
        fontSize: '20px',
        height: '70px',
        lineHeight: '70px',
        display: 'inline-block',
        transition: 'all 0.3s'
    },

// Form
    form: {
        backgroundColor: '#599800',
        height: 'auto',
        padding: "30px",
        width: '100%'
    },
    formErrorMessage: {
        color: "red",
        fontSize:'12px'
    },
    input: {
        height: "38px",
        width: '100%',
        color: '#000',
        "::placeholder": {
            color: "#a0a0a0"
        },
        fontSize: '16px',
        lineHeight: '16px',
        border: "1px solid #878787",
        borderRadius: '2px',
        padding: "8px 12px",
        marginBottom: '10px'   
    },
    selectInput: {
        width: '100%',
        height: "40px",
        opactiy: "0",
        appearance: 'none',
        padding: "10px"
    },
    svgDiv: {
        backgroundColor: "#fff",
        height: '40px'
    },
    checkboxInput:{
        marginRight: "5px",
        marginLeft: "5px",
    },
    checkboxLabel:{
        color: "#fff"
    },
    radioInput:{
       marginRight: "5px",
       marginLeft: "5px",
    },
    radioLabel:{
        color: "#fff"
    },
    submitButton:{
        backgroundColor: "#923CC6",
        color: "#fff",
        fontFamily: " 'Roboto', sans-serif",
        fontWeight: "700",
        border: "none",
        padding: "10px 25px"
    },
    label: {
        color: "#fff",
        fontFamily: " 'Roboto', sans-serif",
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "5px"
    }

};

export default function theme ( global ) {

    global = global ? global : defaultGlobal;


    //colorAssign( global );


//Button Styles

    const defaultButtonStyles = {
        background: global.backgroundColors.accent.color2,
        textColor: global.textColors.light.white,
        borderColor: global.backgroundColors.accent.color2,
        fontFamily: global.fontFamily.secondaryFont
    };
    const alternateButtonStyles = {
        background: global.backgroundColors.dark.black,
        textColor: global.textColors.dark.color2,
        fontFamily: global.fontFamily.secondaryFont,
        borderColor: global.backgroundColors.light.white,
        boxShadow: '0px 2px 8px 1px #ccc',

    };

    global.button1 = {
        ...global.button1,
        ...defaultButtonStyles
    };

    global.button1A = {
        ...global.button1,
        ...alternateButtonStyles
    };

    global.button2 = {
        ...global.button2,
        ...defaultButtonStyles
    };

    global.button2A = {
        ...global.button2,
        ...alternateButtonStyles
    };

    global.button3 = {
        ...global.button3,
        ...defaultButtonStyles
    };

    global.button3A = {
        ...global.button3,
        ...alternateButtonStyles
    };

    return global

}
