import {prepStringForInferno} from '../../../utils/prep-string-for-inferno';

export const handleCategories = (categories) => {
	
	categories.map((category) => {
		
		category.categoryName = prepStringForInferno(category.categoryName)
		
	});
	
	return categories;
	
	
};