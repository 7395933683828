import React, {useState} from "react";
import styled from "styled-components";

export const EllipsisWrapper = styled.div`
  z-index: ${({active}) => (active ? 100 : 90)};
  position: relative;
`;

const OptionsWrapper = styled.div`
  position: fixed;
  width: 160px;
  display: ${({active}) => (active ? "block" : "none")};
  top: ${({cords}) => cords.y }px;
  left: ${({cords}) => cords.left - 12}px;
  transform:translateX(-100%);
  background: #fff;
`;

const Option = styled.div`
  padding: 10px;
  border: 1px solid #f0f0f0;
  cursor: pointer;
  user-select: none;
  &:hover {
    background: #f0f0f0;
  }
`;

const Ellipsis = styled.svg`
  cursor: pointer;
  ${props => (props.vertical ? `transform: rotate( 90deg );` : "")}
`;

const ClickLayer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
`;

export const EllipsisMenu = ({options, vertical = false, right = false, fill = "#000"}) => {
  const [active, toggleActive] = useState(false);

  const [cords, updateCords] = useState( {x:null, y:null} );


  function handleDisplay(e) {
    //sets active to true
    toggleActive(!active);
    //sets the cords for where to display the menu
    //the menu has to be fixed to prevent it not rendering on
    //overflow hidden/scroll divs
    updateCords(e.target.getBoundingClientRect());
  }

  //creates click event that does cb and closes menu
  const generateOnClick = o => {
    return () => {
      o.onClick();
      toggleActive(!active);
    };
  };

  return (
    <>
      {active && (
        <ClickLayer
          onClick={() => {
            toggleActive(!active);
          }}
        />
      )}
      <EllipsisWrapper active={active}>
        <Ellipsis
          vertical={vertical}
          onClick={ handleDisplay }
          width="16"
          height="4"
          viewBox="0 0 16 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 2C12 3.1 12.9 4 14 4C15.1 4 16 3.1 16 2C16 0.9 15.1 -3.93403e-08 14 -8.74228e-08C12.9 -1.35505e-07 12 0.9 12 2ZM10 2C10 0.9 9.1 -3.01609e-07 8 -3.49691e-07C6.9 -3.97774e-07 6 0.9 6 2C6 3.1 6.9 4 8 4C9.1 4 10 3.1 10 2ZM4 2C4 0.899999 3.1 -5.63877e-07 2 -6.11959e-07C0.9 -6.60042e-07 -3.93402e-08 0.899999 -8.74228e-08 2C-1.35505e-07 3.1 0.899999 4 2 4C3.1 4 4 3.1 4 2Z"
            fill={fill}
          />
        </Ellipsis>

        <OptionsWrapper
          active={active}
          cords={cords}
        >
          {options.map((o, idx) => {
            //these sometime are evaluated as the boolean false
            //to  get around this I check to ensure there is a truthy value
            //with the option
            if (!!o) {
              return (
                <Option key={idx} onClick={generateOnClick(o)}>
                  {o.text}
                </Option>
              )
            }
          })
          }
        </OptionsWrapper>
      </EllipsisWrapper>
    </>
  );
};

EllipsisMenu.defaultProps = {
  options: [],
};



/**
 Example Usage:


 <EllipsisMenu
 vertical={true}
 right={true}
 options={
 [{
    text: "Change Image",
    onClick: () => initImageSelection("", idx)
  },{
      text: "Move Left",
      onClick: move("left", idx)
    },{
      text: "Move Right",
      onClick: move("right", idx)
    },{
      text: "Delete",
      onClick: () => deleteImg(idx)
    }]
 }/>


 **/
