import React from "react";
export default class TableLandscapeIcon extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <svg
        style={this.props.style}
        width="20px"
        height="15px"
        viewBox="0 0 20 15"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.8913043,-2.5 L5.10869565,-2.5 C3.66804348,-2.5 2.5,-1.380625 2.5,0 L2.5,0.625 L17.5,0.625 L17.5,0 C17.5,-1.380625 16.3319565,-2.5 14.8913043,-2.5 L14.8913043,-2.5 Z M2.5,11.875 L17.5,11.875 L17.5,1.875 L2.5,1.875 L2.5,11.875 Z M10,13.75 C10.54,13.75 10.9782609,14.17 10.9782609,14.6875 C10.9782609,15.205 10.54,15.625 10,15.625 C9.46,15.625 9.02173913,15.205 9.02173913,14.6875 C9.02173913,14.17 9.46,13.75 10,13.75 L10,13.75 Z M2.5,15 C2.5,16.380625 3.66804348,17.5 5.10869565,17.5 L14.8913043,17.5 C16.3319565,17.5 17.5,16.380625 17.5,15 L17.5,13.125 L2.5,13.125 L2.5,15 L2.5,15 Z"
          id="Fill-144-Copy"
          transform="translate(10.000000, 7.500000) rotate(-270.000000) translate(-10.000000, -7.500000) "
        />
      </svg>
    );
  }
}
