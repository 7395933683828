import {StyleSheet} from "@convertly/css";
import _ from 'lodash';

import UserOverrides from '../../../elements/style-functions/user-overrides';
import {generatePseudoMediaQuery} from '../../../elements/style-functions/pseudo-media-queries';
import {addValueToUserOverrides, createNewCssRules} from '../../../utils/edit-userOverrides';
import searchBarStyleObject from '../../../ecommerce-common-components/search-bar/searchBarStyleObject';

const HeaderStyles3 = (global, data, inEditor) => {
    const searchBarStyles = searchBarStyleObject(global, data, inEditor);

    let navLinksOverrides = {},
        navLinkAdditionalStyles = {},
        dropDownAdditionalStyles = {},
        dropdownOverrides = {},
        mobileMenuOverrides = {},
        borderBottomColor = {},
        socialIconBGOverrides = {},
        callOutTextColorOverrides = {},
        iconBackground = {};

    let callOutUserOverrides = _.get(data, 'customHeaderMessage.userOverrides') ?
        UserOverrides(global, data.customHeaderMessage, inEditor) : {};

    let mainHeaderUserOverrides = _.get(data, 'mainHeader.userOverrides') ?
        UserOverrides(global, data.mainHeader, inEditor) : {};

    if (_.get(data, "menu.userOverrides")) {
        /** map through userOverrides make fill match color **/
        let editednavLinksOverrides = {
            userOverrides: addValueToUserOverrides(_.get(data, "menu.userOverrides"),
                "color",
                ["fill"])
        };
        navLinksOverrides = UserOverrides(global, editednavLinksOverrides, inEditor);
        navLinkAdditionalStyles = createNewCssRules(navLinksOverrides, 'color', ['color', 'borderColor', 'fill'])
        iconBackground = createNewCssRules(navLinksOverrides, 'color', ['backgroundColor']);
        borderBottomColor = createNewCssRules(navLinksOverrides, 'color', ['borderColor']);
        socialIconBGOverrides = createNewCssRules(navLinksOverrides, 'color', ['backgroundColor']);
        callOutTextColorOverrides = createNewCssRules(navLinksOverrides, 'color', ['color']);
    }

    if(_.get(data, 'menu.menuLauncherOverrides')) {
        mobileMenuOverrides = UserOverrides(global, data.menu.menuLauncherOverrides, inEditor)
    }

    if (_.get(data, "dropdown.userOverrides")) {
        /** map through userOverrides make fill match color **/
        let editedDropdownOverrides = {
            userOverrides: addValueToUserOverrides(_.get(data, "dropdown.userOverrides"),
                "color",
                ["fill"])
        };
        dropdownOverrides = UserOverrides(global, editedDropdownOverrides, inEditor);
        dropDownAdditionalStyles = createNewCssRules(dropdownOverrides, 'color', ['color', 'borderColor', 'fill'])
    }

    const navListItemOverrides = (data.menu && data.menu.userOverrides)
        ? new UserOverrides(global, data.menu) : {};


    const caret = {
        display: "inline-block",
        height:'17px',
        width: '17px',
        cursor: 'pointer',
        boxSizing: 'content-box',
        marginTop: '-6px',
        marginLeft: '5px',
        stroke: 'inherit',
        verticalAlign: 'text-bottom',
        ...generatePseudoMediaQuery(
            '@media( max-width:1201px )',
            ['desktop', 'largeTablet', 'smallTablet', 'mobile'], {
                top: '34px',
                right: '-17px'
            },
            inEditor),


    };

    const navListItem = {
        display: 'inline-block',
        cursor: 'pointer',
        padding: '0px 20px',
        fontSize: '18px',
        stroke: 'inherit',
        position: 'relative',
        ".active": {
          textDecoration: 'underline',
          transition: "100ms ease",
        },
        ...generatePseudoMediaQuery(
            '@media(max-width:768px)',
            ['smallTablet', 'mobile'],
            {
                marginTop: '20px'
            },
            inEditor
        ),
        ...navLinksOverrides
    };

    const dropdown = {
        float: 'right',
        listStyle: 'none',
        height: 'auto',
        width: 'fit-content',
        //padding: "5px 0"

    };
    const dropdownList = {
        display: 'none',
        textAlign: 'left',
        position: 'absolute',
        top: '175%',
        right: '-15px',
        width: 'max-content',
        background: global.colors.light.white,
        listStyle: 'none',
        padding: '20px 10px 10px 10px',
        zIndex: '1',
        border: `3px solid ${global.colors.dark.color1}`,
        borderRadius: '3px',
        '.open': {
            display: 'block'
        },
        ...generatePseudoMediaQuery(
            '@media( max-width:768px )',
            ['smallTablet', 'mobile'], {
                padding: 0,
                position: 'static',
                border: 'none',
                background: "transparent",
                fontSize: '12px'
            },
            inEditor
        ),
        ...navLinksOverrides,
        ...dropdownOverrides
    };

    const searchBarWrapperFocusStyles = {
      borderBottom: `2px solid ${ global.colors.accent.color2 }`,
      fill: global.colors.accent.color2,
      'box-shadow': `0 0 0 0 ${ global.colors.dark.color3 }`,
    }

    let headerTopperUserOverrides = _.get(data, 'headerTopperSettings.userOverrides.headerTop.userOverrides') ?
      UserOverrides(global, data.headerTopperSettings.userOverrides.headerTop, inEditor) : {};

    let socialIconUserOverrides = _.get(data, 'headerTopperSettings.userOverrides.socialIcons.userOverrides') ?
      UserOverrides(global, data.headerTopperSettings.userOverrides.socialIcons, inEditor) : {};

    let socialIconContainerUserOverrides = _.get(data, 'headerTopperSettings.userOverrides.socialIconContainer.userOverrides') ?
      UserOverrides(global, data.headerTopperSettings.userOverrides.socialIconContainer, inEditor) : {};

    let headerTopperTopLftUserOverrides = _.get(data, 'headerTopperSettings.userOverrides.headerTopperTopLft.userOverrides') ?
      UserOverrides(global, data.headerTopperSettings.userOverrides.headerTopperTopLft, inEditor) : {};

    return StyleSheet.create({
        searchBarWrapper: {
          display: 'block',
          ...generatePseudoMediaQuery(
            '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
              display: 'none',
            },
            inEditor
          ),
          ...searchBarStyles,
          '.search-bar-container': {
            width: '100%',
            ...generatePseudoMediaQuery(
              '@media(max-width: 991px)', ['largeTablet', 'smallTablet', 'mobile'], {
                display: 'block'
              },
              inEditor
            ),
          },
          '.search-bar-results': {
            position: 'absolute',
            top: '22px',
            height: '0',
            right: '0',
            width: '50%',
            zIndex: '2',
          },
          '.search-bar-wrapper': {
            display: 'flex',
            borderBottom: `2px solid transparent`,
            'box-shadow': `0 1px 0 0 ${ global.colors.dark.color3 }`,
            padding: '4px 4px',
            width: '50%',
            transition: 'all 0.2s linear',
            'align-items': 'center',
            transform: 'translateY(-12px)',
            float: 'right',
            ':focus-within': {
              ...searchBarWrapperFocusStyles
            },
            '.has-input': {
              ...searchBarWrapperFocusStyles
            }
          },
          '.search-bar-input-placeholder': {
            position: 'absolute',
            left: '0',
            transition: 'all 0.3s linear',
            'pointer-events': 'none',
            top: '3px',
            '.has-input': {
              marginTop: '-15px',
              fontSize: '11px',
              color: global.colors.accent.color2,
            }
          },
          '.mobile': {
            marginLeft: '4px',
            display: 'none',
            '.x-icon': {
              fontSize: '26px',
              cursor: 'pointer',
            },
            '.search-bar-container': {
              width: '100%',
              ...generatePseudoMediaQuery(
                '@media(max-width: 991px)', ['largeTablet', 'smallTablet', 'mobile'], {
                  display: 'block',
                  width: '90%',
                },
                inEditor
              ),
            },
            '.search-bar-wrapper': {
              '.static': {
                display: 'flex',
                border: `2px solid ${ global.colors.light.color3 }`,
                padding: '4px',
                width: '95%',
                'align-items': 'center',
                height: '107%',
                transition: 'all 0.3s ease',
                marginRight: '-2px',
                transform: 'translateY(0px)',
                'box-shadow': `0 0 0 0 ${ global.colors.dark.color3 }`,
                ':focus-within': {
                  border: `2px solid ${ global.colors.accent.color2 }`,
                  fill: global.colors.accent.color2,
                  color: global.colors.accent.color2
                },
              }
            },
            '.search-bar-input': {
              border: 'none',
              padding: '0px 2px',
              height: '100%',
              width: '100%',
              backgroundColor: 'transparent',
              color: global.colors.dark.black,
              lineHeight: '22px',
              fontSize: '16px'
            },
            '.search-bar-product-items-list': {
              transition: 'all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s',
              position: 'absolute',
              width: '100%',
              color: global.colors.dark.black
            },
            '.search-bar-results-wrapper': {
              position: 'relative',
              left: '-16px',
              width: '274.8px',
              'z-index': '22',
              top: '4px',
              display: 'none',
              '.active': {
                display: 'block'
              }
            },
            '.search-bar-results': {
              position: 'absolute',
              width: '90%',
              right: '12px',
              top: '24px'
            },
            '.product-item': {
              display: 'block',
              padding: '4px 4px',
              border: `2px solid ${ global.colors.light.color3 }`,
              borderTop: 'none',
              listStyle: 'none',
              cursor: 'pointer',
              backgroundColor: global.colors.light.white,
              'text-overflow': 'ellipsis',
              'overflow': 'hidden',
              'white-space': 'nowrap',
              ':hover': {
                backgroundColor: global.colors.light.color2
              },
              '.no-hover': {
                ':hover': {
                  backgroundColor: 'white',
                  cursor: 'default'
                },
              }
            },
            ...generatePseudoMediaQuery(
              '@media(max-width: 991px)', ['smallTablet', 'mobile'], {
                display: 'block',
              },
              inEditor
            ),
          }
        },
        ".float-right": {
            float: 'right'
        },
        mainHeader: {
            margin: '0 4%',
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px 0',
            borderTop: `2px solid ${global.colors.dark.color1}`,
            ...borderBottomColor,
            height: _.get(data, 'headerTopperSettings.display') ? 'calc(100% - 40px)' : '100%',
            ...generatePseudoMediaQuery("@media (max-width: 768px)", ['smallTablet', 'mobile'], {
                padding: "20px 10px",
                textAlign: "center"
            }, inEditor),
            ...mainHeaderUserOverrides
        },
        headerTop: {
            borderBottom: `2px solid ${global.colors.dark.color1}`,
            display: 'block',
            height: '20px',
            ...borderBottomColor
        },
        topNavLinks: {
            display: 'flex',
        },
        leftLinks: {
            display: 'flex',
        },
        rightLinks: {
            padding: '0px 15px',
        },
        btnToggle: {
            color: 'red',
        },
        headerNav: {
            display: 'inline-block',
            ...generatePseudoMediaQuery(
                '@media(max-width:768px)',
                ['smallTablet', 'mobile'],
                {
                    marginLeft: '25px'
                },
                inEditor
            )

        },
        headerBottom: {
            display: 'flex',
            justifyContent: 'space-between',
            ...generatePseudoMediaQuery(
                '@media (max-width: 580px)',
                ['smallTablet', 'mobile'], {
                    top: '205%',
                    right: '3px'
                },
                inEditor
            )
        },
        bottomRightLinks: {
            display: 'none'
        },

        menuLauncherIcon: {
            float: 'right',
            marginTop: '20px',
            marginRight: "15px",
            ...generatePseudoMediaQuery(
              '@media( max-width: 1064px)',
              ['largeTablet', 'smallTablet', 'mobile'],
              {
                  marginTop: "19px"
              },
              inEditor
            ),
            ...generatePseudoMediaQuery(
                '@media( max-width:991px )',
                ['largeTablet', 'smallTablet', 'mobile'], {
                    marginTop: "13px"
                },
                inEditor
            )
        },
        navLaunch: {
            position: 'relative',
            paddingLeft: '10px',
            overflow: "visible",
            background: "none",
            border: "none",
            outline: "none",
            //display: 'inline-block',
            paddingTop: '10px',
            float: 'left',
            display: 'none',
            zIndex:'21',
            ...generatePseudoMediaQuery('@media (max-width: 768px)',
                ['smallTablet', 'mobile'], {
                    display: 'block',
                    ".open": {
                      display: "flow-root",
                      position: "fixed"
                    }
                },
                inEditor
            )
        },
        navListItem: {
            color: global.colors.dark.black,
            fontFamily: global.fontFamily.primaryFont,
            fontSize: '14px',
            ...navListItem
        },
        navItemContainer: {
            display: "inline-block",
            paddingTop: "3px",
            ...generatePseudoMediaQuery(
                '@media (max-width: 768px)',
                ['smallTablet', 'mobile'], {
                    display: "block"
                },
                inEditor
            )
        },
        iconBar: {
            backgroundColor: global.colors.dark.color1,
            width: "20px",
            height: "3px",
            marginBottom: "3px",
            position: "relative",
            display: "block",
            transition: 'all 0.3s ease-in-out 0s',

            ".absolute": {
                backgroundColor: global.colors.light.white
            },
            '.open': {
                ":nth-child(2)": {
                    transform: "rotate(45deg)",
                    top: "7px",
                    background: global.colors.dark.color1,

                },
                ":nth-child(4)": {
                    transform: "rotate(-45deg)",
                    top: "-5px",
                    background: global.colors.dark.color1,

                },
                ":nth-child(3)": {
                    opacity: 0
                }
            },
            ...iconBackground,
            ...mobileMenuOverrides
        },

        dropdown: {
            ...dropdown,
        },

        arrowUp: {
            width: 0,
            height: 0,
            position: 'absolute',
            top: '-13px',
            right: '28px',
            borderLeft: '12px solid transparent',
            borderRight: '12px solid transparent',
            borderBottom: '12px solid',
            borderBottomColor: global.colors.dark.color1,
            ...generatePseudoMediaQuery(
                '@media( max-width:768px )',
                ['smallTablet', 'mobile'], {
                    display: 'none'
                },
                inEditor
            ),
        },
        innerArrow: {
            width: 0,
            height: 0,
            position: 'relative',
            top: '3px',
            left: '-10px',
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderBottom: '10px solid',
            borderBottomColor: global.colors.light.white,
            ...generatePseudoMediaQuery(
                '@media ( max-width:991px )',
                ['largeTablet', 'smallTablet', 'mobile'],
                {
                    borderBottomColor: _.get(data, 'dropdown.userOverrides.backgroundColor')
                    || _.get(data, 'dropdown.userOverrides.background')
                    || global.colors.light.white,
                },
                inEditor
            )
        },
        pushRight: {
            marginRight: '10px'
        },
        dropdownList: {
            ...dropdownList,
            paddingTop: '10px'
        },
        contactDropdownList: {
            minWidth:'250px',
            ...dropdownList,
        },
        menuIcon: {
            width: '18px',
            height: '18px',
        },
        contactLinkContainer: {
            display: 'inline-block',
            marginLeft: '5px',
            width: '85%',
            maxWidth: '250px',
            margin: '5px 0',
            fontSize: '14px',
            verticalAlign: 'middle',
            lineHeight: '22px',
            ...generatePseudoMediaQuery(
                '@media(max-width:768px)',
                ['smallTablet', 'mobile'],
                {
                    fontSize: '12px'
                },
                inEditor
            )
        },
        contactDownNavText: {
            fontSize: '12px',
            lineHeight: '22px',
            padding: '10px 12px 10px 5px',
            ...generatePseudoMediaQuery(
                '@media(max-width:768px)',
                ['smallTablet', 'mobile'],
                {
                    marginTop: '20px'
                },
                inEditor
            ),
            ...navLinksOverrides

        },
        contactNavListItem: {
            ...generatePseudoMediaQuery(
                '@media(max-width:768px)',
                ['smallTablet', 'mobile'],
                {
                    fontSize: '12px'
                },
                inEditor
            ),
            ...navListItem,
            ...navLinksOverrides
        },

        contactListHeader: {
            display: 'block',
            width: '50%',
            borderBottom: '1px solid',
            paddingBottom: '12px',
            color: dropdownOverrides.color || navLinksOverrides.color || global.colors.dark.color1,
            borderColor: dropdownOverrides.color || navLinksOverrides.color || global.colors.dark.color1,
            textTransform: 'uppercase',
            fontSize: '18px',
            ...generatePseudoMediaQuery(
                '@media( max-width:768px )',
                ['smallTablet', 'mobile'], {
                    display: 'none'
                },
                inEditor
            ),
            ...navLinkAdditionalStyles,
            ...dropDownAdditionalStyles
        },

        caret: {
            ...caret,
        },
        downNavItem: {
            margin: '10px 0'
        },
        dropdownIconContainer: {
            fill: global.colors.dark.color1,
            display: 'inline-block',
            width: '10%',
            verticalAlign: 'middle',
            ...navLinkAdditionalStyles,
            ...dropDownAdditionalStyles
        },
        desktopCartInfernoComponent: {
            ":hover ": {
                "@media(min-width:768px)": {
                    ".cartDropDown": {
                        display: 'block'
                    },
                    ".casper": {
                        display: 'block'
                    }
                }
            },
            ".casper": {
                position: 'absolute',
                top: '0',
                right: '0',
                height: '60px',
                width: '425px',
                background: 'transparent',
                display: 'none'
            },

            ".headerNavLink": {
                ...navListItem,
                color: '#000',
                ...navListItemOverrides,
            },
            ".cartIcon": {
                height: '24px',
                marginTop: '-5px',
                verticalAlign: 'middle'
            },
            ".cartLink": {
                ...navLinkAdditionalStyles
            },
            ".cartLinkActive": {
                color: global.colors.accent.color1,
                fill: global.colors.accent.color1
            },
            ".cartDropDown": {
                display: 'none',
                cursor: 'default',
                position: 'absolute',
                top: '0',
                right: '-1px',
                width: '425px',
                height: "460px",
                background: 'white',
                zIndex: '50',
                paddingRight: "0",
                border: global.border
            },
            ".cartContainer": {
                height: '350px',
                overflowY: 'auto',
                width: '100%',
                borderBottom: global.border
            },
            ".cartContainerFooter": {
                marginTop: '20px',
                textAlign: 'center'
            },
            ".checkoutLink": {
                display: 'block',
                ...global.button1
            },
            ".viewCartLink": {
                display: 'block',
                color: global.colors.dark.color1
            },
            ".checkRightPanel": {
                padding: '30px',
                alignItems: "stretch",
                backgroundColor: "transparent",
                color: global.colors.dark.color1,
                display: "flex",
                flex: "1",
                flexDirection: "row",
                fontSize: "14px",
                justifyContent: "center",
                lineHeight: "20px",
                paddingTop: "30px"
            },
            ".cart-item-container": {
                padding: '15px'
            },
            ".change-message-bg": {
                backgroundColor: '#e3f2fd'
            },
            ".change-message-container": {
                borderTop: `1px solid ${global.colors.light.color2}`,
                paddingTop: '10px',
                fontFamily: global.fontFamily.secondaryFont
            },
            ".change-message-text": {
                textTransform: 'none',
                fontSize: '14px',
                color: global.colors.dark.color2
            },
            ".change-message-action": {
                display: 'block'
            },
            ".change-message-link": {
                fontSize: '16px',
                display: 'inline-block',
                margin: "5px 10px 0 0",
                cursor: 'pointer'
            },

            ".chkRightPanelContainer": {
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "100%",
                width: "100%"
            },

            ".chkTitle1_mobileTitle1": {
                borderBottom: "2px none #667785",
                color: global.colors.dark.color1,
                display: "none",
                flex: "0 auto",
                flexDirection: "row",
                fontSize: "32px",
                fontWeight: "400",
                lineHeight: "36px",
                marginBottom: "10px",
                marginTop: "0px",
                paddingBottom: "20px",
                width: "100%"
            },

            ".mobileUnderline_titleUnderline": {
                backgroundColor: global.colors.dark.color1,
                borderRadius: "5px",
                color: global.colors.dark.color1,
                display: "none",
                fontSize: "14px",
                height: "3px",
                lineHeight: "20px",
                marginBottom: "20px",
                width: "45%"
            },

            ".chkGridheadercontainer": {
                color: global.colors.dark.color1,
                display: "flex",
                fontSize: "14px",
                justifyContent: "flex-end",
                lineHeight: "20px",
                marginBottom: "15px"
            },

            ".chkProdgridimgcontainer": {
                alignItems: "flex-start",
                color: global.colors.dark.color1,
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                justifyContent: "center",
                lineHeight: "20px",
                maxWidth: "18%",
                textAlign: "center",
                width: "20%"
            },

            ".chkGridprice_th": {
                alignItems: "flex-start",
                color: global.colors.dark.color1,
                display: "flex",
                flex: "0 auto",
                fontFamily: global.fontFamily.secondaryFont,
                fontSize: "11px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                paddingLeft: "0px",
                width: "125px"
            },

            ".chkGridqty_chkUnitprice_th": {
                alignItems: "flex-start",
                color: global.colors.dark.color1,
                display: "flex",
                flex: "1",
                fontFamily: global.fontFamily.secondaryFont,
                fontSize: "11px",
                justifyContent: "center",
                lineHeight: "20px",
                textAlign: "center"
            },

            ".chkGridsubtotal_th": {
                color: global.colors.dark.color1,
                display: "flex",
                flex: "1",
                fontFamily: global.fontFamily.secondaryFont,
                fontSize: "11px",
                justifyContent: "flex-end",
                lineHeight: "20px",
                textAlign: "center"
            },

            ".chkProdgrid": {
                color: global.colors.dark.color1,
                display: "flex",
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "20px",
                position: "relative",
            },

            ".chkProdgridimg": {
                border: "0",
                color: global.colors.dark.color1,
                display: "inline-block",
                fontSize: "14px",
                height: "60px",
                width: "60px",
                lineHeight: "20px",
                maxWidth: "100%",
                textAlign: "center",
                verticalAlign: "middle",
                marginBottom: '10px'

            },

            ".chkRemovelink": {
                backgroundColor: "transparent",
                color: "#b4bbc3",
                fontSize: "10px",
                lineHeight: "20px",
                maxWidth: "100%",
                textAlign: "center",
                paddingLeft: '7px'
            },

            ".divBlock_2": {
                color: global.colors.dark.color1,
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                lineHeight: "20px",
                width: "225px"
            },

            ".chkGridprice": {
                alignItems: "flex-start",
                color: global.colors.dark.color1,
                display: "flex",
                flex: "0 auto",
                fontSize: "12px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                paddingLeft: "0px",
                width: "225px"
            },

            ".subTitle": {
                fontSize: '10px',
                color: global.colors.dark.color2
            },

            ".chkGridprice_chkGridpricenote": {
                alignItems: "flex-start",
                color: "#118bef",
                display: "flex",
                flex: "0 auto",
                fontSize: "12px",
                justifyContent: "flex-start",
                lineHeight: "16px",
                paddingLeft: "0px",
                width: "auto"
            },

            ".chkGridqty_chkUnitprice_strikethrough": {
                alignItems: "flex-start",
                color: "#667785",
                display: "flex",
                flex: "0 auto",
                fontSize: "12px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                textAlign: "center",
                textDecoration: "line-through"
            },

            ".actualprice_chkGridqty_chkUnitprice": {
                alignItems: "flex-start",
                color: "#118bef",
                display: "flex",
                flex: "1",
                fontSize: "14px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                textAlign: "center"
            },

            ".chkGridqty": {
                alignItems: "flex-start",
                color: "#667785",
                display: "flex",
                flex: "1",
                fontSize: "14px",
                justifyContent: "flex-end",
                lineHeight: "20px",
                textAlign: "center"
            },

            ".counterIndicator": {
                backgroundColor: "transparent",
                color: "#667785",
                fontSize: "14px",
                lineHeight: "20px",
                padding: "4px 9px",
                textAlign: "center",
                textDecoration: "none"
            },

            ".counternumber": {
                border: global.border,
                color: "#667785",
                flex: "0 auto",
                fontSize: "14px",
                lineHeight: "20px",
                padding: "4px 9px 3px",
                textAlign: "center"
            },

            ".chkGridsubtotal": {
                color: global.colors.dark.color2,
                display: "flex",
                flex: "1",
                fontSize: "14px",
                lineHeight: "20px"
            },

            ".progresscontainer": {
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                paddingTop: "10px",
                position: "relative"
            },

            ".truck": {
                border: "0",
                color: global.colors.dark.color1,
                display: "inline-block",
                fontSize: "14px",
                lineHeight: "20px",
                maxWidth: "55px",
                position: "absolute",
                right: "0px",
                top: "0px",
                verticalAlign: "middle"
            },

            ".progresstext": {
                borderBottomColor: "#000",
                borderBottomWidth: "3px",
                borderStyle: "none",
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                paddingBottom: "10px",
                paddingRight: "80px"
            },

            ".progressline_titleUnderline": {
                backgroundColor: global.colors.dark.color1,
                borderRadius: "5px",
                color: global.colors.dark.color1,
                fontSize: "14px",
                height: "3px",
                lineHeight: "20px",
                marginBottom: "15px",
                width: "100%"
            },

            ".cartToggleContainer": {
                color: global.colors.dark.color1,
                display: "none",
                fontSize: "14px",
                lineHeight: "20px"
            },
            ".cartTitleSub": {
                color: global.colors.dark.color1,
                fontSize: "32px",
                lineHeight: "36px",
                marginBottom: "10px",
                marginTop: "20px"
            },

            ".toggleLinkblock_wInlineBlock": {
                backgroundColor: "transparent",
                color: global.colors.dark.color1,
                display: "inline-block",
                fontSize: "14px",
                lineHeight: "20px",
                maxWidth: "100%"
            },
            ".toggleCollapsed": {
                border: "0",
                color: global.colors.dark.color1,
                display: "inline-block",
                fontSize: "14px",
                lineHeight: "20px",
                maxWidth: "100%",
                verticalAlign: "middle"
            }
        },

        desktopSignInInfernoComponent: {
            ".headerNavLink": {
                ...navListItem,
                paddingRight: '0',
                ...navListItemOverrides
            },


        },

        mobileHide: {
            ...generatePseudoMediaQuery(
                '@media(max-width:768px)',
                ['smallTablet', 'mobile'],
                {
                    display: 'none'
                },
                inEditor
            ),

        },
        mobileCart: {
            display:'none',
            ...generatePseudoMediaQuery(
                '@media(max-width:768px)',
                ['smallTablet', 'mobile'],
                {
                    display:'block'
                }, inEditor
            )
        },
        header_top: {
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            padding: '6px 20px',
            display: 'block',
            height: '40px',
            ...headerTopperUserOverrides
        },
        header_top_lft: {
            height: '100%',
            display: 'flex',
            paddingTop: "5px",
            alignItems: "center",
            ...headerTopperTopLftUserOverrides
        },
        mobileSocialIcons: {
            display:'none',
            ...generatePseudoMediaQuery("@media (max-width: 991px)",
                ['largeTablet', 'smallTablet', 'mobile'], {
                    display:'block',
                    marginTop:'15px',
                    marginLeft:'15px'
                }, inEditor),
        },
        socialIconContainer: {
            height: '20px',
            width: '20px',
            backgroundColor: global.colors.dark.color2,
            fill: global.colors.light.white,
            borderRadius: '50%',
            backgroundSize: '30px',
            ...socialIconBGOverrides,  
            ...socialIconContainerUserOverrides
        },
        socialIconWrapper: {
            float: 'left',
            ...generatePseudoMediaQuery("@media (max-width: 991px)",
                ['largeTablet', 'smallTablet', 'mobile'], {
                    display:'none'
                }, inEditor),
            ...socialIconUserOverrides
        },
        connect_us: {
            display: 'inline-flex',
            alignContent: 'center',
            float: 'left',
            color: global.colors.dark.color3,
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            margin: "0",
            padding: "0",
            ...generatePseudoMediaQuery("@media (max-width: 991px)",
                ['smallTablet', 'mobile'],
                {
                    width: '100%',
                    justifyContent: 'center',
                    fontSize: '12px',
                    lineHeight: '12px'
                }, inEditor),
        },
        socialIcons: {
            paddingTop: "5px"
        },
        socialIcon: {
            height: '100%',
            width: '100%',
            padding: '3px',
            fill:'inherit'
        },
        customCallOut: {
            fontSize: '14px',
            color: global.colors.dark.color1,
            ...callOutTextColorOverrides,
            ...callOutUserOverrides
        },
        mobileCartContainer:  {
            display:'none',
            ...generatePseudoMediaQuery(
                "@media(max-width:768px)",
                ['mobile', 'smallTablet', 'largeTablet'],
                {
                    display:'block'
                },
                inEditor
            )
        },
        mobileCart: {
            ".headerNavLink": {
                display: 'inline-block',
                cursor: 'pointer',
                padding: '0px 20px',
                fontSize: '18px',
                stroke: 'inherit',
                position: 'relative',
                ...navListItemOverrides,
                "@media(max-width:580px)": {
                    padding:'0px 5px',
                    fontSize:'12px'
                }
            },
            ".cartIcon": {
                height: '24px',
                marginTop: '-5px',
                verticalAlign: 'middle',
                "@media (max-width:580px)": {
                    height:'18px'
                }
            },
            ".cartLink": {
                ...navLinkAdditionalStyles
            },
            ".cartLinkActive": {
                color: global.colors.accent.color1,
                fill: global.colors.accent.color1
            },
        }
    });

};

export default HeaderStyles3
