import { StyleSheet } from "@convertly/css";
import UserOverrides from '../style-functions/user-overrides';

const heading1Styles = ( global, data, headingStyle, inEditor ) => {


    let titleStyle = headingStyle ? headingStyle : "h1";

    const userOverRides = new UserOverrides( global, data, inEditor );
	

    return StyleSheet.create({
        heading1: {
            ...global[titleStyle],
            ...userOverRides
        }
    })

};

export default heading1Styles;
