import React from 'react';
import Component from '../../rootComponent';
import Types from '../../attribute-types';
import { css } from '@convertly/css';
import defaultGlobal from '../../themes/global.js';

//Styles
import userOverrides from './bootstrap-user-overrides';

export default class Row extends Component {
	constructor ( props ) {
		super( props );
	}
    render(){

	    const inEditor = this.inEditor()

        this.styles = userOverrides( this.props.global, this.props.data, inEditor );

        /**
         * Adds ID and sort to elements
         * @param props
         * @returns {*}
         */

        let className = "row ";
         const aphroditeClass = css( this.styles.userOverrides );


         className += aphroditeClass;


        function renderChildren(props) {
            return React.Children.map(props.children, child => {
              if(!child) return;
                return React.cloneElement(child, {
                    moduleId: props.moduleId,
                    moduleSort: props.moduleSort,
                    styles: props.styles,
                    site: props.site,
                    sliderSettings: props.sliderSettings ? props.sliderSettings : null
                });
            })
        }


		const inlineStyle = this.generateStyleAttribute();
		const { animated } = this.props.data;
		if ( this.animatedClassName ) {
			className += " " + this.animatedClassName;
		}

        if( this.props.editable ) {
			return (
				<div className={ className }
                     { ...this.generateCustomAttributes() }
					 onMouseOver={ this.addEditableOutline }
					 { ...this.generateDataEditorAttribute() }
					 onMouseOut={ this.removeEditableOutline }
					 onClick={( e ) => {
						 this.launchElementalEditor( 'row', e );
					 }}
					 data-animated={animated}
					 {...inlineStyle }>
					{ renderChildren(this.props) }
				</div>
			)
		} else {
			return (
				<div className={ className }>
					{ renderChildren(this.props) }
				</div>
			)
		}


    }
};

Row.defaultProps = {
    global: defaultGlobal,
    data: {},
	editable: true,
};

/**
 Component BoilerPlate

{
    "el": "Elements.Row",
    "data": {
        "userOverrides": {

        }
    },
    "children": [
        {
            "el": "Elements.Column",
            "data": {
                "xsOffset": 12,
                "xsColumns" 12,
                "xsPull": 12,
                "xsPush": 12,
                "userOverrides": {

                }
            },
            "children": [

            ],
            "sort", 900
        }
    ],
    "sort", 900
}

 **/

