import { generateBlogLink } from "../util/generate-blog-link";

const regeneratorRuntime = require("regenerator-runtime");
import { frontloadConnect } from "@convertly/react-frontload";
import React from "react";
import Component from "source/rootComponent";
import { css } from "@convertly/css";
import _ from "lodash";

// Components
import Elements from "source/elements";

// Style
import CategoryListStyles from "./CategoryListStyles";

// Util
import { doQuery } from "../../utils/doQuery";
import { frontLoadOptions } from "../../utils/frontLoadOptions";
import { generateLink } from "../../utils/generateLink";

let categoryList = {};
let inEditorData;

const fetchCategoryList = (props, component) => {
  return new Promise(async (resolve, reject) => {
    // Prevents refetchiung of category lists in the editor
    const inEditor = _.get(component, "props.inEditor")
    if (
      categoryList[props.id] &&
      categoryList[props.id].loaded &&
        inEditor
    ) {
      component.setState({ loaded: true });
      return resolve();
    }

    const siteId = _.get(props, "site.siteId");

    const query = props.inEditor ?
    `query {
                BlogCategories:DummyBlogCategories {
                  name
                  id
                  url
                }
              }`
      : `
              query ( $siteId:ID! ) {
                BlogCategories( siteId:$siteId ) {
                  name
                  id
                  url
                }
              }` ;

    const variables = {
      siteId,
    };

    const data = await doQuery({ query, variables });

    categoryList[props.id] = _.get(data, "data.BlogCategories") || [];
    categoryList[props.id].loaded = true;

    // Changes state so category list renders
    inEditor && component.setState({loaded:true});

    resolve(categoryList[props.id]);
  });
};

const frontload = async props =>
  _.get(props, "blogData.categories")
    ? () => {
        return true;
      }
    : fetchCategoryList(props).then(() => CategoryList);

class CategoryList extends Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false };
  }

  componentWillMount() {
    if (!_.get(categoryList[this.props.id], "loaded") && this.props.inEditor) {
      return fetchCategoryList(this.props, this);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.inEditor) {
      return true;
    }
    return false;
  }

  componentDidMount() {

  }

  render() {
    const { data, inEditor, global } = this.props;

    const { headingText, displayHeadingText, disableLink } = data || {};
    //checks to see if data is coming from parent component
    const parentBlogData = _.get(this.props, "blogData.categories");

    //uses data from either
    const categories = parentBlogData || categoryList[this.props.id];

    if (_.isEmpty(categories)) {
      return null;
    }

    this.styles = CategoryListStyles({ global, data, inEditor });

    const { categoryListStyle, categoryButton, categoryButtonText } = this.styles;

    const inlineStyle = this.generateStyleAttribute();

    return (
      <section
        {...inlineStyle}
        {...this.generateDataEditorAttribute()}
        {...this.generateCustomAttributes()}
        className={css(categoryListStyle)}
      >
        {displayHeadingText !== false && (
          <Elements.Heading4
            data={{
              text: _.isString(headingText)
                ? headingText
                : categoryList.length > 1
                ? "Categories"
                : "Category",
              userOverrides: _.get(data, "userOverrides.headingText.userOverrides" || {}),
            }}
          />
        )}
        {categories &&
          categories.map((category, idx) => {
            if (disableLink === true) {
              return (
                <div key={`category-${idx}`} className={css(categoryButton)}>
                  <div className={css(categoryButtonText)}>{category.name}</div>
                </div>
              );
            } else {
              return (
                <a
                  key={`category-${idx}`}
                  className={css(categoryButton)}
                  href={generateBlogLink(this.props.page.blogLink, category.url, inEditor)}
                >
                  <div className={css(categoryButtonText)}>{category.name}</div>
                </a>
              );
            }
          })}
      </section>
    );
  }
}

module.exports = {
  CategoryList: frontloadConnect(frontload, frontLoadOptions)(CategoryList),
};
