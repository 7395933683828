/*global Inferno, $, React */

import {include} from '@convertly/thor';


const Script = (dispatch) => {

    const Dispatch = include('../../utils/dispatcher', "Dispatch");

    return function () {

        const dispatch = Dispatch();

        dispatch.on('toggleDropDown', (res) => {
            this.toggleActiveState(res)
        });

        dispatch.on('fix-body-position', (res) => {
            if (res) {
                document.body.style.position = 'fixed';
                document.body.style.overflow = 'hidden';
            }
            else {
                document.body.removeAttribute('style');
            }
        });


        this.toggleActiveState = (target) => {
            $(`[data-toggle=${ target }]`).toggleClass('active');
        };

        this.toggleDelayActiveState = () => {
          const $elements = $(`[data-delay-active]`);
          const timer = Number($elements.data('delay-active'));
          
          if(!Number.isNaN(timer)) {
            setTimeout(() => {
              $elements.toggleClass('active');
            }, timer);
          }
        };

        let $headerHeight = $(`[data-elementtype="header"]`).height() || 0;
        let elementToToggleOnScroll = $(`[data-active-on-scroll]`);
        let $window = $(window);

        if($headerHeight > 0) {
          zenscroll.setup(null, $headerHeight);
        }

        toggleActiveOnScroll();

        this.toggleActiveOnScroll = () => {
          toggleActiveOnScroll();
        }

        this.initDomListeners = () => {
            $(document).on('click', `[data-toggle-target]`, (e) => {
                e.preventDefault();
                this.toggleActiveState(e.currentTarget.dataset.toggleTarget);
            });

            $(document).on('scroll', e => {
              this.toggleActiveOnScroll();
            });

            this.toggleDelayActiveState();
        };

        function toggleActiveOnScroll() {
          if($window.scrollTop() > $headerHeight) {
            elementToToggleOnScroll.addClass('active');
          } else {
            elementToToggleOnScroll.removeClass('active');
          }
        }

        this.initDomListeners();

    }

};

export default Script;
