import _ from "underscore";
import lodash from "lodash";
import { get, isArray } from "lodash";
import axios from "axios";
import { setSite } from "@convertly/utils";
import { fromJS, Map } from "immutable";

import { graph } from "utils/graph";
import Logger from "utils/logger";
import LocalStorage from "utils/localStorage";

import { store } from "app/app";
import { IMAGE_UPLOAD_SERVER } from "app/config";
import { isProduction } from "../utils";

import { setFiles } from "containers/ManageFiles/actions";
import { clientImage } from "containers/Editor/components/pegasus-components/src";
import { activeSite } from "utils";
import { can } from "containers/Authentication/PermissionsCheck";

import {
  setSiteData,
  setSiteImages,
  resetState as resetEditorState,
  setActivePage,
  setCommonElements,
} from "containers/Editor/actions/editorActions";

import { setCurrentSite } from "containers/Authentication/actions";

import { resetState as resetSiteSettings } from "containers/SiteSettings/actions";

import { resetSnapshot } from "containers/Dashboard/actions";
import { setUploadingImage } from "containers/App/actions";

export const getActiveSiteId = () => store.getState().getIn(["auth", "activeSite", "id"]);

export const getActiveSite = () => store.getState().getIn(["auth", "activeSite"]);

export function loadCommonElements(site) {
  return async dispatch => {
    const { id } = site;

    const query = `
			query( $id: ID! ){
			  Site(id: $id) {
          id, siteId,
          commonPageElements {
            id, type, element, modId,
          }
			  }
			}
			`;

    const variables = {
      id: id,
    };

    const response = await graph({ query, variables });
    const { Site } = response;

    const commonElements = Site.commonPageElements.map(object => {
      object.element = JSON.parse(object.element);
      return object;
    });

    await dispatch(setCommonElements(commonElements));
  };
}

export const fetchSiteData = async ({ siteId, fields }) => {
  const query = `
			query( $id: ID! ){
			  Site(id: $id) {
          ${fields.join(",")}
			  }
			}
			`;

  const variables = {
    id: siteId,
  };

  const response = await graph({ query, variables });
  return lodash.get(response, "Site");
};

/**
 * Makes a site the 'Current' Site
 * @param site
 * @returns {function(*)}
 */
export function loadSite(site) {
  return async dispatch => {
    let { id } = site;

    if (Map.isMap(site)) {
      id = site.get("id");
    }

    if (!id) {
      LocalStorage.removeItem("active-site");

      await dispatch(resetEditorState());
      await dispatch(resetSiteSettings());
      await dispatch(setSiteData(null)); // Editor reducer
      await dispatch(setCurrentSite(null)); // Auth reducer

      if (store.getState().get("Files")) {
        await dispatch(setFiles(fromJS([])));
      }

      return;
    }

    const query = `
			query( $id: ID! ){
			  Site(id: $id) {
          id, siteId, domain, theme, timeZone, ssl, goToTop, googleAnalytics, facebookUrl,
          twitterUrl, siteName, siteDescription, siteTitle, name, siteCategory, stripeConnected
          ecommerceSettings, social, logo { src, alt, title }, alt_logo { src, alt, title }
          commonPageElements {
            id, type, element, modId,
          },
          pages( id: $id ) {
            id, name, slug, pageId
          },
          locations,
          paymentMethods, previewDomain, favicon
          facebookPixelId, mailchimp
          themeData
          primaryZorkUserId
          locked
          doNotIndex
          lazyLoadImages
          adwordsAccountId
          adwordsStatus
          isGlobalTheme
          activeTheme
          blogEnabled
			  }
			}
			`;

    const variables = {
      id: id,
    };

    const response = await graph({ query, variables });
    const { Site } = response;

    Site.commonElements = (get(Site, "commonPageElements") || []).map(object => {
      object.element = JSON.parse(object.element);
      return object;
    });

    Site.social = JSON.parse(Site.social);

    if (Site.locations) {
      Site.locations = JSON.parse(Site.locations);
    }

    delete Site.commonPageElements;

    Site.ecommerce = JSON.parse(Site.ecommerceSettings);
    Site.ecommerceSettings = JSON.parse(Site.ecommerceSettings);
    Site.paymentMethods = JSON.parse(Site.paymentMethods);
    Site.themeData = JSON.parse(Site.themeData);

    let homepageId = 0;

    get(Site, "pages").map(page => {
      if (page.slug === "/") {
        homepageId = page.id;
      }
    });

    setSite(Site);

    LocalStorage.setItem("active-site", Site.id);

    await dispatch(resetEditorState());
    await dispatch(resetSiteSettings());
    await dispatch(setSiteData(Site)); // Editor reducer
    await dispatch(setCurrentSite(Site)); // Auth reducer

    if (store.getState().get("Files")) {
      await dispatch(setFiles(fromJS([])));
    }

    //await dispatch( loadPageData ( homepageId ) );
  };
}

export function resetActivePage() {
  return async dispatch => {
    LocalStorage.removeItem("active-page");
    await dispatch(resetSnapshot());
    await dispatch(setActivePage(null));
  };
}

export function getSites(userId) {
  return new Promise(async (resolve, reject) => {
    Logger.auth("");
    Logger.auth("services/sites::getSites()");

    const query = `
			query($id:ID!){
				Account( id: $id) {
					sites { id, name, domain, sort, chatEnabled, createdBy }
				}
			}`;

    const variables = { id: userId };

    const response = await graph({ query, variables });

    if (response.error) {
      return reject(new Error("Error loading sites"));
    }

    let sites = get(response, "Account.sites");

    if (sites) {
      sites = _.sortBy(sites, "sort").reverse();
    } else {
      Logger.debug("no sites in response", response);
      sites = [];
    }

    Logger.auth("services/sites::getSites() done. sites:", sites);

    resolve(sites);
  });
}

export const getSiteImages = () => async dispatch => {
  Logger.auth("services/sites::getSiteImages()");

  const siteId = store.getState().getIn(["auth", "activeSite", "id"]);

  const query = `
		query( $siteId: ID! ){
		  SiteImages( siteId: $siteId ){
		    id, filename, updatedAt, size, Size, version
		  }
		}
		`;

  const variables = {
    siteId,
  };

  const response = await graph({ query, variables });
  let Images = get(response, "SiteImages") || [];
  const host = `https://s3.amazonaws.com/convertlyimguploadeast`;
  Images = Images.map(image => {
    const filename = image.filename;

    image.link = `https:${clientImage(filename, {}, siteId)}`;
    image.thumb_100 = `https:${clientImage(
      filename,
      { width: 100, height: 100, crop: "fit" },
      siteId
    )}`;

    if (image.filename.indexOf(".pdf") !== -1) {
      image.isPDF = true;
      image.link = `${host}/${siteId}/${filename}`;
      image.thumb_100 = `https:${clientImage(
        filename,
        { width: 100, height: 100, crop: "fit" },
        siteId
      )}`.replace(".pdf", "-preview.jpg");
    } else {
      image.isPDF = false;
    }
    return image;
  });
  await dispatch(setSiteImages(Images));
};

export const uploadImages = (files, refetchImages = true) => async dispatch => {
  const Auth = store.getState().get("auth");
  const accountId = Auth.getIn(["user", "sub"]);
  const siteId = Auth.getIn(["activeSite", "id"]);

  await dispatch(setUploadingImage(true));

  const imagesToUpload = isArray(files) ? files : [files];
  const uploads = [];

  for (let i = 0; i < imagesToUpload.length; i++) {
    uploads.push(uploadImageToServer(imagesToUpload[i], siteId, accountId));
  }

  await Promise.all(uploads);

  if (refetchImages) {
    await dispatch(getSiteImages());
  }

  await dispatch(setUploadingImage(false));
};

export const uploadImage = file => async dispatch => {
  const Auth = store.getState().get("auth");
  const accountId = Auth.getIn(["user", "sub"]);
  const siteId = Auth.getIn(["activeSite", "id"]);

  await dispatch(setUploadingImage(true));

  const filename = await uploadImageToServer(file, siteId, accountId);

  await dispatch(setUploadingImage(false));

  return filename;
};

export function uploadImageToServer(file, siteId, accountId) {
  return new Promise((resolve, reject) => {
    const data = new FormData();

    data.append("file", file);
    data.append("sub", accountId);
    data.append("siteId", siteId);
    data.append("production", isProduction());

    const config = {
      method: "post",
      url: IMAGE_UPLOAD_SERVER + "/upload",
      data,
    };

    axios(config)
      .then(response => {
        const filename = get(response, "data.filename");
        resolve(filename);
      })
      .catch(err => {
        console.log("ERROR", err.message);
        reject(err);
      });
  });
}

export const updateSite = async ({ siteId, site }) => {
  if (site.siteId) {
    delete site.siteId;
  }

  const query = `
    mutation($site:siteInput,$id:ID!){
      editSite(id:$id,site:$site){
        id
      }
    }
  `;

  const variables = {
    id: siteId,
    site,
  };

  return await graph({ query, variables });
};

export const fetchConversionPoints = async siteId => {
  let facebookStuff = "";

  if (can("view_facebook")) {
    facebookStuff = `facebookCustomEventId`;
  }

  const query = `
    query( $id: ID! ){
      Site(id: $id) {
			  ConversionPoints {
					pixelTitle
					pixelType
					targetedPages
					google
					facebook
					trackAsGoal
					adwordsConversionTrackerId
					adwordsRetargetingAudienceId
          adwordsAudienceId
          ${facebookStuff}
					id
			  }
      }
		}
  `;

  const variables = {
    id: siteId,
  };
  const response = await graph({ query, variables });

  return lodash.get(response, "Site.ConversionPoints") || [];
};

export const getSiteUrl = site => {
  let url = "";

  if (site.get("domain")) {
    url = `https://${site.get("domain")}`;
  } else {
    url = `http://${site.get("previewDomain")}.convertly.io`;
  }

  return url;
};
