import React from 'react';
import PropTypes from 'prop-types';
import Component from '../../rootComponent';
import {css} from "@convertly/css";
import defaultGlobal from '../../themes/global.js';
import ReactHTMLParser from "@convertly/react-html-parser";


//Styles
import headingStyles from './heading1-styles';

//Utils
import generateId from '../../utils/generate-id';
import {generateStyle} from 'source/utils/generate-attribute';
import { generateDataAtttribute } from '../../utils/generate-attribute';

/**
 * Class controls imports style functions that allows for user to override the css in the CMS editor for their site.
 **/
class Heading1 extends Component {

	constructor ( props ) {
		super ( props );
	}

	render () {

		const inEditor = this.inEditor();
		/**
		 * Calls Stylesheet function
		 **/
		this.styles = headingStyles ( this.props.global, this.props.data, this.props.data.headingStyle, inEditor );

		let className = '';
        let text = ReactHTMLParser( this.props.data.text );
		let aphroditeClass = css ( this.styles.heading1 );

		if ( this.animatedClassName ) {
			className += " " + this.animatedClassName
		}
		className += aphroditeClass;

		const { animated } = this.props.data;
		const inlineStyle = this.generateStyleAttribute( );
		/**
		 * Loads h1 element
		 **/

		return (
			<h1 { ...this.generateDataEditorAttribute() }
				{...generateId ( this.props.elementId )}
				onClick={( e ) => {
					this.launchElementalEditor ( 'text', e );
				}}
				{ ...this.generateCustomAttributes() }
				onMouseOver={ this.addEditableOutline }
             	onMouseOut={ this.removeEditableOutline }
				{ ...inlineStyle }
				data-animated={ animated }
				className={ className }>
				{ text }

		    </h1>
		);
	}
}

/**
 * Default Props for rendering when no data is being passed
 **/
Heading1.defaultProps = {
	data: {
		text: "",
		bold: false,
		italic: false,
		underline: false,
		align: 'center',
		fontSize: '',
		color: ''
	},
	global: defaultGlobal
};

/**
 * Connects element to context for cms editor
 **/
Heading1.contextTypes = {
	store: PropTypes.object
};

export default Heading1

/**
 Component BoilerPlate

{
    "el": "Elements.Heading1",
    "data": {
        "text": "Your text here",
        "userOverrides": {

        }
    },
    "children": [],
    "sort": 900
}

 **/
