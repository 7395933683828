import { generatePseudoMediaQuery } from "../../elements/style-functions/pseudo-media-queries";

const styleContainer = ({ inEditor }) => {
  return {
    "margin-right": "auto",
    "margin-left": "auto",
    "max-width": "970px",
    ...generatePseudoMediaQuery(
      "@media(max-width: 991px)",
      ["mobile", "smallTablet", "largeTablet"],
      {
        width: "750px"
      },
      inEditor
    ),
    ...generatePseudoMediaQuery(
      "@media(max-width: 761px)",
      ["mobile", "smallTablet", "largeTablet"],
      {
        width: "100%"
      },
      inEditor
    )
  };
};

export default styleContainer;
