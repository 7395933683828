import { StyleSheet } from "@convertly/css";

const sectionTitleStyles = ( global, data ) => {

    return StyleSheet.create({
        sectionTitleSection: {
            textAlign:'center',
            maxWidth:'100%',
            fontFamily:global.fontFamily.primaryFont,
            "@media (max-width:768px)": {
                textAlign:"left",
                fontSize:'22px'
            }
        },
        sectionTitleStyles: {
            display:'inline-block',
            fontSize: '22px',
            color:global.textColors.dark.color1
        }
    })

};

export default sectionTitleStyles;