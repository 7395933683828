import { StyleSheet } from "@convertly/css";
import Typography from '../../../../../elements/style-functions/typography';

const headerDropdownNavItemStyles = ( global, data ) => {

    const dropdownNavLinkTypography = new Typography( global, data );


    return StyleSheet.create({

        headerDropdownNavLink: {
            ...dropdownNavLinkTypography
        },

    })
};

export default headerDropdownNavItemStyles;