import React from 'react';
import {Button, ButtonMultiSelectWrapper} from './Components'
import {Label} from "app/ui-components";

export const ButtonMultiSelect = ({buttonList, onChange, activeValue}) => {

  return (
    <ButtonMultiSelectWrapper>
    {
      buttonList.map( function renderButton(button) {
        return (
          <Button onClick={ () => onChange(button) } active={activeValue === button.value}>{button.text}</Button>
        )
      } )
    }
    </ButtonMultiSelectWrapper>
  )

}
