export default function StyleObject(global, data) {
  return {
    '.products-per-page-container': {
      width: '52%',
      height: '100%',
      display: 'inline-block',
      verticalAlign: 'top',
    },

    '.products-per-page-wrapper': {
      display: 'table',
      height: '100%',
      width: '100%'
    },

    '.products-per-page': {
      display: 'table-cell',
      textAlign: 'center',
      verticalAlign: 'middle',
      fontSize: '11px'
    },

    '.products-per-page-links': {
      marginRight: '5px',
      cursor: 'pointer',
      color: global.colors.accent.color2,
      fontWeight: '600',
      '.active': {
        color: global.colors.dark.black
      }
    }

  }
}