import { StyleSheet } from "@convertly/css";
import UserOverrides from '../style-functions/user-overrides';

const listItemStyles = ( global, data, listStyleType ) => {

    const userOverRides = new UserOverrides( global, data,  );

    let listStyle = {};

    if (listStyleType === "dashed") {
        listStyle = {
            ":before": {
                content: "'\\2014'",
                position: "absolute",
                marginLeft: "-20px"
            }
        }
    }

    return StyleSheet.create({

        listItem: {
            ...global.listItem,
            ...listStyle,
            ...userOverRides
        }

    })

};

export default listItemStyles;