import React from 'react';
import styled from 'styled-components';
import { GLOBALS } from '../../styles/global-default-styles';

/**
 * default dimensions for svg buttons rendered on the site editor
 */
const SvgDimensions = styled.svg`
  height: ${(props) => props.theme.defaultHeight};
  width: ${(props) => props.theme.defaultWidth};
  fill: ${(props) => props.theme.defaultfill};
`;

SvgDimensions.defaultProps = {
  theme: {
    defaultHeight: "24px",
    defaultWidth: "24px",
    defaultfill: `${GLOBALS.colorPrimaryDark}`
  }
}

/**
 * @description the editor's placeholder the user sees while loading data.
 * @param {Object} props | necessary styles/props to load the placeholder.
 */
export const SitePlaceHolder = (props) => {
  return (
    <svg tyle={ {width:'100%', height:'100%'} } viewBox="0 0 243 199" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="243" height="15" fill="#C4C4C4"/>
      <rect x="178" y="5" width="25" height="4" fill="white"/>
      <rect x="148" y="5" width="25" height="4" fill="white"/>
      <rect x="10" y="4" width="25" height="7" fill="white"/>
      <rect y="15" width="243" height="100" fill="white"/>
      <rect y="115" width="243" height="84" fill="#E5E5E5"/>
      <rect x="208" y="5" width="25" height="4" fill="white"/>
      <rect x="58" y="26" width="127" height="9" fill="#C6C6C6"/>
      <rect opacity="0.7" x="73" y="45" width="97" height="6" fill="#C6C6C6"/>
      <rect opacity="0.4" x="81" y="61" width="81" height="6" fill="#C6C6C6"/>
      <rect x="24" y="87" width="57" height="57" fill="#ADEBFF"/>
      <rect x="96" y="87" width="57" height="57" fill="#F9C699"/>
      <rect x="168" y="87" width="57" height="57" fill="#78E9D4"/>
      <circle cx="51" cy="174" r="20" fill="white"/>
      <circle cx="123" cy="174" r="20" fill="white"/>
      <circle cx="195" cy="174" r="20" fill="white"/>
    </svg>
  )
}

/**
 * @description help icon that activates a tooltip
 */
export const HelpIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.7 4.9H6.3V3.5H7.7V4.9ZM7.7 10.5H6.3V6.3H7.7V10.5ZM7 0C6.08075 0 5.17049 0.18106 4.32122 0.532843C3.47194 0.884626 2.70026 1.40024 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85651 0.737498 10.637 2.05025 11.9497C2.70026 12.5998 3.47194 13.1154 4.32122 13.4672C5.17049 13.8189 6.08075 14 7 14C8.85651 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85651 14 7C14 6.08075 13.8189 5.17049 13.4672 4.32122C13.1154 3.47194 12.5998 2.70026 11.9497 2.05025C11.2997 1.40024 10.5281 0.884626 9.67878 0.532843C8.8295 0.18106 7.91925 0 7 0Z"
      fill="#414141" />
  </svg>
);


/**
 * @description a lock icon that usually goes next to a form's input
 * @param {String} iconClass includes logic to display tooltip
 */
export const LockIcon = ({iconClass}) => (
  <svg width="15" height="20" className={iconClass} viewBox="0 0 15 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.125 6.66667H12.1875V4.7619C12.1875 2.13333 10.0875 0 7.5 0C4.9125 0 2.8125 2.13333 2.8125 4.7619V6.66667H1.875C0.84375 6.66667 0 7.52381 0 8.57143V18.0952C0 19.1429 0.84375 20 1.875 20H13.125C14.1562 20 15 19.1429 15 18.0952V8.57143C15 7.52381 14.1562 6.66667 13.125 6.66667ZM7.5 15.2381C6.46875 15.2381 5.625 14.381 5.625 13.3333C5.625 12.2857 6.46875 11.4286 7.5 11.4286C8.53125 11.4286 9.375 12.2857 9.375 13.3333C9.375 14.381 8.53125 15.2381 7.5 15.2381ZM10.4062 6.66667H4.59375V4.7619C4.59375 3.13333 5.89687 1.80952 7.5 1.80952C9.10312 1.80952 10.4062 3.13333 10.4062 4.7619V6.66667Z" fill="#414141" />
  </svg>
);

/**
 * Convertly's logo (`The C logo`)
 * @param {String} width width of the svg
 * @param {String} height height of the svg
 * @param {String} fill color the svg will adopt
 */
export const ConvertlyLogoSvg = ({width, height, styles}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={styles}
    width={width}
    height={height}
    fill="none"
    viewBox={`0 0 ${width} ${height}`}
  >
    <path
      fill="#349886"
      d="M21.229 20.37c-1.487 2.753-4.346 4.615-7.628 4.615-4.815 0-8.718-4.009-8.718-8.952a9.06 9.06 0 012.172-5.913L5.223 8.296a8.714 8.714 0 015.144-3.508l.824 2.64a8.539 8.539 0 015.766.342l1.459-4.804a13.313 13.313 0 00-8.718-.317L8.87 0a13.58 13.58 0 00-7.177 4.782l1.853 1.845C1.26 9.197-.004 12.552 0 16.032 0 23.747 6.09 30 13.601 30c4.774 0 8.972-2.526 11.399-6.347l-3.771-3.284z"
    ></path>
  </svg>
);

/**
 * Theme icon used in the vertical menu bar of the editor (part of the editor's panels)
 * @param {String} width width of the svg
 * @param {String} height height of the svg
 * @param {String} fill color the svg will adopt 
 */
export const ThemeSideBarIcon = ({width, height, fill}) => (
  <svg  width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.81818 0H18.1818C19.1818 0 20 0.9 20 2V14C20 15.1 19.1818 16 18.1818 16H11.8182V18H13.6364V20H6.36364V18H8.18182V16H1.81818C0.818182 16 0 15.1 0 14V2C0 0.9 0.818182 0 1.81818 0ZM1.81818 14H18.1818V2H1.81818V14Z" fill={fill}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.668 12.5V8.33333H16.668V12.5H11.668Z" fill={fill}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.33398 7.5V4.16667H7.50065V7.5H3.33398Z" fill={fill}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M3.33398 12.5L3.33398 8.33333H10.834V12.5H3.33398Z" fill={fill}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.33398 7.5V4.16667H16.6673V7.5L8.33398 7.5Z" fill={fill}/>
  </svg>
);

/**
 * Pages icon used in the vertical menu bar of the editor (part of the editor's panels)
 * @param {String} width width of the svg
 * @param {String} height height of the svg
 * @param {String} fill color the svg will adopt 
 */
export const PagesSideBarIcon = ({width, height, fill}) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9 0C7.89543 0 7 0.89543 7 2L15 2C16.1046 2 17 2.89543 17 4V15C18.1046 15 19 14.1046 19 13V2C19 0.895431 18.1046 0 17 0H9Z" fill={fill}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M5 4C3.89543 4 3 4.89543 3 6L11 6C12.1046 6 13 6.89543 13 8V19C14.1046 19 15 18.1046 15 17V6C15 4.89543 14.1046 4 13 4H5Z" fill={fill}/>
    <rect x="1" y="8" width="10" height="12" rx="2" fill={fill}/>
  </svg>
);

/**
 * Add element icon used in the vertical menu bar of the editor (part of the editor's panels)
 * @param {String} width width of the svg
 * @param {String} height height of the svg
 * @param {String} fill color the svg will adopt 
 */
export const CircledPlusIcon = ({width, height, fill}) => (
  <svg
        fillOpacity="0.54" 
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width} 
    height={height} 
    viewBox={`0 0 ${width} ${height}`} 
    fill="none"
  >
    <path fillRule="evenodd" clipRule="evenodd" d="M0 10C0 4.48 4.48 0 10 0C15.52 0 20 4.48 20 10C20 15.52 15.52 20 10 20C4.48 20 0 15.52 0 10ZM11 11H15V9H11V5H9V9H5V11H9V15H11V11Z" fill={fill}/>
  </svg>
);

/**
 * Blogs icon used in the vertical menu bar of the editor (part of the editor's panels)
 * @param {String} width width of the svg
 * @param {String} height height of the svg
 * @param {String} fill color the svg will adopt 
 */
export const BlogsIcon = ({width, height, fill}) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.900024 0 0.0100098 0.899994 0.0100098 2L0 20L4 16H18C19.1 16 20 15.1 20 14V2C20 0.899994 19.1 0 18 0H2ZM2 2H18V14H3.16998L2 15.17V2ZM12 10H4V12H12V10ZM4 7H16V9H4V7ZM16 4H4V6H16V4Z" fill={fill} />
  </svg>
);

/**
 * Layers icon used in the vertical menu bar of the editor (part of the editor's panels)
 * @param {String} width width of the svg
 * @param {String} height height of the svg
 * @param {String} fill color the svg will adopt 
 */
export const LayersIcon = ({width, height, fill}) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M18.1778 8.67331L10 14.6827L1.81111 8.67331L0 7.34137L10 0L20 7.34137L18.1778 8.67331ZM1.8 11.3372L9.98889 17.3466L18.1889 11.3267L20 12.6586L10 20L0 12.6586L1.8 11.3372Z" fill={fill}/>
  </svg>
);

/**
 * Assets icon used in the vertical menu bar of the editor (part of the editor's panels)
 * @param {String} width width of the svg
 * @param {String} height height of the svg
 * @param {String} fill color the svg will adopt 
 */
export const AssetsIcon = ({width, height, fill}) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 0H2C0.9 0 0.01 1.125 0.01 2.5L0 17.5C0 18.875 0.9 20 2 20H18C19.1 20 20 18.875 20 17.5V5C20 3.625 19.1 2.5 18 2.5H10L8 0Z" fill={fill}/>
  </svg>
);

/**
 * More / Ellipsis icon used in the vertical menu bar of the editor (part of the editor's panels)
 * @param {String} width width of the svg
 * @param {String} height height of the svg
 * @param {String} fill color the svg will adopt 
 */
export const EllipsisIcon = ({width, height, fill}) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.5 7C1.125 7 0 8.125 0 9.5C0 10.875 1.125 12 2.5 12C3.875 12 5 10.875 5 9.5C5 8.125 3.875 7 2.5 7ZM17.5 7C16.125 7 15 8.125 15 9.5C15 10.875 16.125 12 17.5 12C18.875 12 20 10.875 20 9.5C20 8.125 18.875 7 17.5 7ZM10 7C8.625 7 7.5 8.125 7.5 9.5C7.5 10.875 8.625 12 10 12C11.375 12 12.5 10.875 12.5 9.5C12.5 8.125 11.375 7 10 7Z" fill={fill}/>
    <g><rect width="20" height="20" fill="none"/></g>
  </svg>
);

/**
 * Save icon used in the vertical menu bar of the editor (part of the editor's panels)
 * @param {String} width width of the svg
 * @param {String} height height of the svg
 * @param {String} fill color the svg will adopt 
 */
export const FloppyDiskIcon = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill={fill} fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.3333 6.66667H2.22222V2.22222H13.3333V6.66667ZM10 17.7778C9.11595 17.7778 8.2681 17.4266 7.64298 16.8015C7.01786 16.1763 6.66667 15.3285 6.66667 14.4444C6.66667 13.5604 7.01786 12.7125 7.64298 12.0874C8.2681 11.4623 9.11595 11.1111 10 11.1111C10.8841 11.1111 11.7319 11.4623 12.357 12.0874C12.9821 12.7125 13.3333 13.5604 13.3333 14.4444C13.3333 15.3285 12.9821 16.1763 12.357 16.8015C11.7319 17.4266 10.8841 17.7778 10 17.7778ZM15.5556 0H2.22222C0.988889 0 0 1 0 2.22222V17.7778C0 18.3671 0.234126 18.9324 0.650874 19.3491C1.06762 19.7659 1.63285 20 2.22222 20H17.7778C18.3671 20 18.9324 19.7659 19.3491 19.3491C19.7659 18.9324 20 18.3671 20 17.7778V4.44444L15.5556 0Z" fill={fill}/>
    <g mask="url(#mask0)">
      <rect width="24" height="24" fill="none"/>
    </g>
  </svg>
);

/**
 * Publish icon used in the vertical menu bar of the editor (part of the editor's panels)
 * @param {String} width width of the svg
 * @param {String} height height of the svg
 * @param {String} fill color the svg will adopt 
 */
export const CloudUploadIcon = ({ width, height, fill }) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill={fill} fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 14V8H3L10 1L17 8H13V14H7ZM3 18V16H17V18H3Z" fill="none"/>
    <g />
    <path d="M16.125 8.36665C15.5583 5.49165 13.0333 3.33331 10 3.33331C7.59167 3.33331 5.5 4.69998 4.45833 6.69998C1.95 6.96665 0 9.09165 0 11.6666C0 14.425 2.24167 16.6666 5 16.6666H15.8333C18.1333 16.6666 20 14.8 20 12.5C20 10.3 18.2917 8.51665 16.125 8.36665ZM11.6667 10.8333V14.1666H8.33333V10.8333H5.83333L10 6.66665L14.1667 10.8333H11.6667Z" fill={fill}/>
  </svg>
);

/**
 * Preview icon used in the vertical menu bar of the editor (part of the editor's panels)
 * @param {String} width width of the svg
 * @param {String} height height of the svg
 * @param {String} fill color the svg will adopt 
 */
export const RoundSphereIcon = ({width, height, fill}) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill={fill} fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM8 14.5L14 10L8 5.5V14.5Z" fill={fill}/>
    <g>
      <rect width="20" height="20" fill="none"/>
    </g>
  </svg>
);

/**
 * Left arrow icon used in the global theme tab in the theme's editor (part of the editor's panels)
 * @param {String} width width of the svg
 * @param {String} height height of the svg
 * @param {String} fill color the svg will adopt 
 */
export const LeftPointingArrowIcon = ({width, height, fill}) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="keyboard_arrow_left_24px">
      <path id="icon/hardware/keyboard_arrow_left_24px" d="M15.705 16.59L11.125 12L15.705 7.41L14.295 6L8.29498 12L14.295 18L15.705 16.59Z" fill={fill} fillOpacity="0.54"/>
    </g>
  </svg>
);

/**
 * Right arrow icon used in the global theme tab in the theme's editor (part of the editor's panels)
 * @param {String} width width of the svg
 * @param {String} height height of the svg
 * @param {String} fill color the svg will adopt 
 */
export const RightPointingArrowIcon = ({width, height, fill}) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="keyboard_arrow_right_24px">
      <path id="icon/hardware/keyboard_arrow_right_24px" d="M8.29498 16.59L12.875 12L8.29498 7.41L9.70498 6L15.705 12L9.70498 18L8.29498 16.59Z" fill={fill} fillOpacity="0.54"/>
    </g>
  </svg>
);

/**
 * Down arrow icon used in different dropdowns (part of the editor's panels)
 * @param {String} width width of the svg
 * @param {String} height height of the svg
 * @param {String} fill color the svg will adopt 
 */
export const DownPointingArrowIcon = ({width, height, fill}) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 404.308 404.309">
    <g>
      <path d="M0,101.08h404.308L202.151,303.229L0,101.08z" fillOpacity="0.54" fill={fill}/>
    </g>
  </svg>
);

/**
 * the X that denotes a closing icon used in different opened containers
 * @param {String} width width of the svg
 * @param {String} height height of the svg
 * @param {String} fill color the svg will adopt 
 */
export const ClosingIcon = ({width, height, fill}) => (
  <svg version="1.1"
    width={width} 
    height={height} 
    fill={fill}
    fillOpacity="0.54" xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 348.333 348.334">
    <g>
      <path d="M336.559,68.611L231.016,174.165l105.543,105.549c15.699,15.705,15.699,41.145,0,56.85 c-7.844,7.844-18.128,11.769-28.407,11.769c-10.296,0-20.581-3.919-28.419-11.769L174.167,231.003L68.609,336.563 c-7.843,7.844-18.128,11.769-28.416,11.769c-10.285,0-20.563-3.919-28.413-11.769c-15.699-15.698-15.699-41.139,0-56.85 l105.54-105.549L11.774,68.611c-15.699-15.699-15.699-41.145,0-56.844c15.696-15.687,41.127-15.687,56.829,0l105.563,105.554 L279.721,11.767c15.705-15.687,41.139-15.687,56.832,0C352.258,27.466,352.258,52.912,336.559,68.611z"/>
    </g> 
  </svg>
);

/**
 * Bold icon used to bold text (part of the editor's panels)
 * @param {String} width width of the svg
 * @param {String} height height of the svg
 * @param {String} fill color the svg will adopt 
 */
export const BoldIcon = ({width, height, fill}) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="format_bold_24px">
      <path id="icon/editor/format_bold_24px" fillRule="evenodd" clipRule="evenodd" d="M15.225 11.79C16.195 11.12 16.875 10.02 16.875 9C16.875 6.73999 15.125 5 12.875 5H6.625V19H13.665C15.755 19 17.375 17.3 17.375 15.21C17.375 13.69 16.515 12.39 15.225 11.79ZM9.625 7.5H12.625C13.455 7.5 14.125 8.17001 14.125 9C14.125 9.82999 13.455 10.5 12.625 10.5H9.625V7.5ZM9.625 16.5H13.125C13.955 16.5 14.625 15.83 14.625 15C14.625 14.17 13.955 13.5 13.125 13.5H9.625V16.5Z" fill={fill} fillOpacity="0.54"/>
    </g>
  </svg>
);

/**
 * Italics icon used to italicize text (part of the editor's panels)
 * @param {String} width width of the svg
 * @param {String} height height of the svg
 * @param {String} fill color the svg will adopt 
 */
export const ItalicsIcon = ({width, height, fill}) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="format_italicon/editor/format_italic_24px">
      <path id="icon/editor/format_italicon/editor/24px" d="M10 5V8H12.21L8.79 16H6V19H14V16H11.79L15.21 8H18V5H10Z" fill={fill} fillOpacity="0.54"/>
    </g>
  </svg>
);

/**
 * Underline icon used to underline text (part of the editor's panels)
 * @param {String} width width of the svg
 * @param {String} height height of the svg
 * @param {String} fill color the svg will adopt 
 */
export const UnderlineIcon = ({width, height, fill}) => (
  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13 8C13 11.31 10.31 14 7 14C3.69 14 1 11.31 1 8V0H3.5V8C3.5 9.92999 5.07001 11.5 7 11.5C8.92999 11.5 10.5 9.92999 10.5 8V0H13V8ZM0 18V16H14V18H0Z" fill={fill} fillOpacity="0.54"/>
  </svg> 
);

/**
 * Left align icon to change the content's position (part of the editor's panels)
 * @param {String} width width of the svg
 * @param {String} height height of the svg
 * @param {String} fill color the svg will adopt 
 */
export const LeftAlignIcon = ({width, height, fill}) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 2V0H18V2H0ZM12 4H0V6H12V4ZM12 12H0V14H12V12ZM18 10H0V8H18V10ZM0 18H18V16H0V18Z" fill={fill} fillOpacity="0.54"/>
  </svg>
);

/**
 * Right align icon to change the content's position (part of the editor's panels)
 * @param {String} width width of the svg
 * @param {String} height height of the svg
 * @param {String} fill color the svg will adopt 
 */
export const RightAlignIcon = ({width, height, fill}) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="format_align_right_24px">
      <path id="icon/editor/format_align_right_24px" fillRule="evenodd" clipRule="evenodd" d="M3 5V3H21V5H3ZM9 9H21V7H9V9ZM21 13H3V11H21V13ZM9 17H21V15H9V17ZM3 21H21V19H3V21Z" fill={fill} fillOpacity="0.54"/>
    </g>
  </svg>
);

/**
 * Center align icon to change the content's position (part of the editor's panels)
 * @param {String} width width of the svg
 * @param {String} height height of the svg
 * @param {String} fill color the svg will adopt 
 */
export const CenterAlignIcon = ({width, height, fill}) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 2V0H18V2H0ZM4 4V6H14V4H4ZM18 10H0V8H18V10ZM4 12V14H14V12H4ZM0 18H18V16H0V18Z" fill={fill} fillOpacity="0.54"/>
  </svg>
);

/**
 * Justified align icon to change the content's position (part of the editor's panels)
 * @param {String} width width of the svg
 * @param {String} height height of the svg
 * @param {String} fill color the svg will adopt 
 */
export const JustifiedAlignIcon = ({width, height, fill}) => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="format_align_justify_24px">
      <path id="icon/editor/format_align_justify_24px" fillRule="evenodd" clipRule="evenodd" d="M3 5V3H21V5H3ZM3 9H21V7H3V9ZM21 13H3V11H21V13ZM3 17H21V15H3V17ZM3 21H21V19H3V21Z" fill={fill} fillOpacity="0.54"/>
    </g>
  </svg>
);


export const ImgThumb = (props) => (
  <svg width="24px" {...props} height="24px" viewBox="0 0 24 24" version="1.1">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/thumb" fill="#414141">
        <path
          d="M22.5,21 C22.5,21.828 21.828,22.5 21,22.5 L17.124,22.5 L11.598,16.90125 L18,10.49925 L22.5,14.99925 L22.5,21 L22.5,21 Z M3,22.5 C2.172,22.5 1.5,21.828 1.5,21 L1.5,20.29575 L7.45875,14.95875 L15.00075,22.5 L3,22.5 L3,22.5 Z M6,3 C7.65675,3 9,4.34325 9,6 C9,7.65675 7.65675,9 6,9 C4.34325,9 3,7.65675 3,6 C3,4.34325 4.34325,3 6,3 L6,3 Z M21,0 L3,0 C1.34325,0 0,1.34325 0,3 L0,21 C0,22.65675 1.34325,24 3,24 L21,24 C22.65675,24 24,22.65675 24,21 L24,3 C24,1.34325 22.65675,0 21,0 L21,0 Z M6,7.5 C6.828,7.5 7.5,6.828 7.5,6 C7.5,5.172 6.828,4.5 6,4.5 C5.172,4.5 4.5,5.172 4.5,6 C4.5,6.828 5.172,7.5 6,7.5 L6,7.5 Z"
          id="Fill-216"/>
      </g>
    </g>
  </svg>
)


export const PlusIcon = (props) => {
  return (
    <svg {...props} fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
      <path fill-rule="evenodd"
            d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"/>
    </svg>
  );
}
