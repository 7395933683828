import React from "react";
import styled from "styled-components";
import { Input } from "../Input";
import { GLOBALS } from "../../styles/global-default-styles";

export const SearchInput = styled(Input)`
  padding: 5px 5px 5px 25px;
  border: none;
`;

export const SearchWrapper = styled.nav`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin-right: 18px;
  border: 1px solid ${GLOBALS.colorPrimary};
`;

/**
 * @description Styles of the search-bar icon.
 */
export const SearchIconWrapper = styled.button`
  margin: 0 7px 7px;
  position: absolute;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/**
 * @description svg search icon
 */
export const SearchIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7124 10.0629H11.4351L16 14.6369L14.6369 16L10.0629 11.4351V10.7124L9.81589 10.4563C8.77301 11.3528 7.4191 11.8925 5.94625 11.8925C2.66209 11.8925 0 9.23042 0 5.94625C0 2.66209 2.66209 0 5.94625 0C9.23042 0 11.8925 2.66209 11.8925 5.94625C11.8925 7.4191 11.3528 8.77301 10.4563 9.81589L10.7124 10.0629ZM1.82963 5.94626C1.82963 8.22413 3.6684 10.0629 5.94627 10.0629C8.22414 10.0629 10.0629 8.22413 10.0629 5.94626C10.0629 3.66838 8.22414 1.82962 5.94627 1.82962C3.6684 1.82962 1.82963 3.66838 1.82963 5.94626Z"
      fill="#414141"
    />
  </svg>
);
