import { StyleSheet } from "@convertly/css";
import UserOverrides from '../style-functions/user-overrides';

const heading3Styles = ( global, data, headingStyle, inEditor ) => {

    let titleStyle = headingStyle ? headingStyle : "h3";
  
    const userOverRides = new UserOverrides( global, data, inEditor );

    return StyleSheet.create({
        heading3: {
            ...global[titleStyle],
            ...userOverRides
        }
    })

};

export default heading3Styles;
