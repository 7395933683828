import React from "react";
import Component from "source/rootComponent";
import { css } from "@convertly/css";
import { id } from "@convertly/thor";
import { Components } from "../../components";
import _ from "lodash";

//Scripts
import TodoInfernoWrapper from "./todo-inferno-wrapper";

//Styles
import Styles from "./todo-styles";

export class TodoListWrapper extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  render() {
    const TodoUiWrapper = _.get(Components, `InfernoUis.${this.props.data.infernoUi}`);

    this.styles = Styles(this.props.global, this.props.data, this.props.data.infernoUi);

    return (
      <div>
        <div
          id={id(TodoInfernoWrapper, "hi", TodoUiWrapper)}
          className={css(this.styles.todoWrapper)}
        />
      </div>
    );
  }
}
