import React from 'react';
import _ from 'lodash';
import Component from 'source/rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';


//Default Props
//import defaultData from '';
const defaultData = {};
import defaultGlobal from 'source/themes/global';

//Styles
import Styles from './account-page-styles';

//Utils
import { clientImage } from '../../utils/generateImage';
import { generateLink } from '../../utils/generateLink';

//Components
import Elements from 'source/elements';

//Scripts
import AccountPageInfernoApp from './inferno/accounts-page-inferno-app';

export default class AccountPage extends Component {

    constructor(props) {

        super(props);

    }

    render() {

        this.styles = Styles(this.props.global, this.props.data);

        if (this.props.editor) {
            return this.editor();
        }
        const clientImageUrl = clientImage("", {}, this.props.site.siteId );
        const clientSiteUrl = generateLink({linkType: "inferno"});

        return (
            <div className={ css(this.styles.accountbody) }>

                <Elements.Container>
                    <Elements.Row>
                        <div id={ id(AccountPageInfernoApp, clientImageUrl, clientSiteUrl, this.props.site.siteId ) }
                             className={ css(this.styles.accountPageInfernoRoot) }/>
                    </Elements.Row>
                </Elements.Container>
            </div>
        )
    }
}


AccountPage.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
