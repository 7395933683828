import { createAction } from "redux-act";
import _ from "lodash";

import { graph } from "utils";

export const setCoupon = createAction("Coupon: Set coupon object");
export const setLoadingState = createAction("Coupon: Set loading state");
export const setSavingState = createAction("Coupon: Set saving state");
export const setValidationErrors = createAction("Coupon: Set validation errors");

export const saveCoupon = () => async dispatch => {};

export const deleteCoupon = () => async dispatch => {};

export const loadCoupon = id => async dispatch => {
  await dispatch(setLoadingState(true));

  const query = `
  query($id:ID!){
    Coupon(id:$id){
      id, active, couponType, couponName, couponCode, endDate, startDate, fixedDiscount, limitUse, limitUseNumber
    }
  }
  `;

  const variables = {
    id
  };

  const res = await graph({ query, variables });

  const coupon = _.get(res, "Coupon") || {};

  await dispatch(setCoupon(coupon));
  await dispatch(setLoadingState(false));
};
