import { fromJS } from "immutable";
import { createReducer } from "redux-act";

import {
  setActiveNavState,
  setActivePanel,
  setPrevPanel,
  setPanelTitle,
  setIsNavBarMoreOptionOpen,
  setExitPanel,
  setProcessing,
  setLeavePanelHook,
  setNavBarLocked,
  setPreviewLink,
} from "../actions/navBarActions";

const initialParams = {
  activeNavState: "",
  moreVisible: false,
  panelTitle: "", // panel title override
  activePanel: "",
  prevPanels: [],
  processing: false,
  leavePanelHook: null,
  navBarLocked: true,
  previewLink: "",
};

export const initialState = fromJS(initialParams);

const reducers = {
  [setPreviewLink]: (state, previewLink = "") => state.merge({ previewLink }),

  [setNavBarLocked]: (state, navBarLocked) => state.merge({ navBarLocked }),

  [setLeavePanelHook]: (state, leavePanelHook) => state.merge({ leavePanelHook }),

  [setProcessing]: (state, processing) => state.merge({ processing }),

  [setActiveNavState]: (state, activeNavState) => state.merge({ activeNavState }),

  [setActivePanel]: (state, activePanel) => state.merge({ activePanel }),

  [setPrevPanel]: (state, prevPanels) => state.merge({ prevPanels }),

  [setPanelTitle]: (state, panelTitle) => state.merge({ panelTitle }),

  [setIsNavBarMoreOptionOpen]: (state, moreVisible) => state.merge({ moreVisible }),

  [setExitPanel]: state =>
    state.merge({
      activeNavState: "",
      activePanel: "",
      moreVisible: false,
      panelTitle: "",
      prevPanels: fromJS([]),
      processing: false,
    }),
};

export const navBarReducer = createReducer(reducers, initialState);
