/*global Inferno, $ */
/* eslint-disable */
import { include } from '@convertly/thor';



const Script = ( id ) => {

    /** Imports **/

    const Dispatcher = include( '../../../../utils/dispatcher', 'Dispatcher' );
    const AddToCartStepperInferno = include( '../../../../ecommerce-common-components/add-to-cart-stepper/add-to-cart-qty-stepper', 'AddToCartStepperInferno' );

    return( id, product ) => {

        const hasLocalStorage = $( 'html' ).hasClass( 'localstorage' );

        const root = document.getElementById( id );

        //Convertly Utils
        const dispatch = new Dispatcher( id );

        const AddToCartStepper = AddToCartStepperInferno( dispatch );


        const TheCart = Inferno.createClass( {

            getInitialState: function( ) {

                return {
					disabled: true
                };
            },


            addCurrentItemToCart: function( ) {
                const items = {
                    id: this.state.productId,
                    price: this.state.productPrice,
                    quantity: 1
                };
                dispatch.send('add_to_cart', items);
            },

            render: function( ) {

                let parsedProduct = JSON.parse(product);
                return(
                    <AddToCartStepper rootDiv={ root }
                                      rootClass={ root.className }
                                      product={ parsedProduct }
									  disabled={ this.state.disabled }
					/>
                )
            }

        } );

        Inferno.render( <TheCart />, root );

    };

};

export default Script;
