import React from 'react';
import PropTypes from 'prop-types';
import Component from '../../rootComponent';
import {css} from "@convertly/css";
import defaultGlobal from '../../themes/global.js';
import ReactHTMLParser from "@convertly/react-html-parser";

//Styles
import paragraphStyles from './paragraph-styles';

//Utils
import generateId from '../../utils/generate-id';

/**
 * Class controls imports style functions that allows for user to override the css in the CMS editor for their site.
 **/
class Paragraph extends Component {
	
	constructor(props) {
		
		super(props);
		
	}
	
	render() {
		
		const inEditor = this.inEditor();
		/**
		 * Calls Stylesheet function
		 **/
		this.styles = paragraphStyles(this.props.global, this.props.data, inEditor);
		
		/**
		 * Handles the inline hover effect
		 **/
		let text;
		let className = '';
		
		let aphroditeClass = css(this.styles.paragraph);
		
		if (this.animatedClassName) {
			className += " " + this.animatedClassName;
		}
		className += aphroditeClass;
		
		const {animated} = this.props.data
		const inlineStyle = this.generateStyleAttribute()
		
		// remove <p> and </p> from text
		// don't know why we would be rendering a Paragraph with
		// no text attribute, but it's happened
		text = this.props.data.text;
		if (text) {
			const start = text.substring(0, 3);
			const end = text.substring(text.length - 4);
			if (start === '<p>' && end === '</p>') {
				text = text.substring(3);
				text = text.substring(0, text.length - 4);
			}
		}
		
		/**
		 * Loads paragraph element
		 **/
		text = ReactHTMLParser(text);
		
		return (
			
			
			<div { ...this.generateDataEditorAttribute() }
                 { ...this.generateCustomAttributes() }
					 {...generateId(this.props.elementId)}
					 onClick={(e) => {
						 this.launchElementalEditor('text', e);
					 }}
					 onMouseOver={ (e) => {
						 this.addEditableOutline(e)
					 } }
					 onMouseOut={ (e) => {
						 this.removeEditableOutline(e)
					 } }
					 { ...inlineStyle }
					 data-animated={animated}
					 className={ className }>{ text }</div>
		
		);
	}
}


/**
 * Default Props for rendering when no data is being passed
 **/
Paragraph.defaultProps = {
	data: {
		text: "Hello from the other side",
		bold: false,
		italic: false,
		underline: false,
		align: 'center',
		fontSize: '',
		color: '',
		width: '100%'
	},
	global: defaultGlobal
};

/**
 * Connects element to context for cms editor
 **/
Paragraph.contextTypes = {
	store: PropTypes.object
};

export default Paragraph

/**
 Component BoilerPlate
 
 {
    "el": "Elements.Paragraph",
    "data": {
        "text": "Your text here",
        "userOverrides": {

        }
    },
    "children": [],
    "sort": 900
}
 
 **/
