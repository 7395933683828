import {StyleSheet} from "@convertly/css";
import Typography from '../../../elements/style-functions/typography';
import ModuleGlobal from '../../../elements/style-functions/module-global-styles';
import FooterGlobalStyles from '../footer-global-styles';

const footerStyles = (global, data) => {

    const colors = global.colors;
    const moduleGlobal = new ModuleGlobal(global, data);
    const copyTextTypography = new Typography(global, data.copyRightText);

    const footerGlobalStyles = new FooterGlobalStyles(global, data);


    return StyleSheet.create({
        mainFooter: {
            backgroundColor: colors.color1,
            color: colors.color3,
            fontFamily: global.fontFamily.primaryFont,
            ...moduleGlobal,
            ...footerGlobalStyles,
            height: 'auto',
            '@media only screen and (max-width: 599px)': {
                padding: '2em 0'
            }
        },
        footerIn: {
            padding: 0
        },
        footerLft: {
            padding: 0
        },
        footerLinks: {
            padding: '0 .8em 0 1.2em',
        },
        footerNavListItem: {
            display: 'block',
            padding: '0 0 1.1em 0'
        },
        footerNavLink: {
            ':hover': {
                opacity: '.7'
            },
        },
        footerLinksContainer: {
            width:'35%',
            '@media(max-width: 768px)': {
                float: 'none',
                padding: '.0em 0',
                width: '60%'
            },
            '@media only screen and (max-width: 599px)': {
                width: '100%'
            }
        },
        footerNavList: {
            margin: 0,
            marginLeft:'-2.7em',
            display: 'inline-block',
            float: 'left'
        },
        footerNavTitle: {
            fontSize: '1.1em',
            fontWeight: '700',
            marginBottom: '0',
        },
        footerRgt: {
            padding: '0 .8em 0 1.2em',
            float: 'right',
            '@media(max-width: 767px)': {
                float: 'none',
                padding: '.8em 0',
                width: '60%'
            },
            '@media only screen and (max-width: 599px)': {
                width: '100%'
            }
        },
        copyRight: {
            padding: '0',
            margin: '-.2em 0 0 0',
            display: 'inline',
            '@media(max-width: 767px)': {
                margin: 0
            },
            '@media(max-width: 479px)': {
                textAlign: 'center'
            }
        },
        copyText: {
            ...copyTextTypography,
            margin: 0
        },
        socialIcon: {
            height: '1.5em',
            width: '2.5em',
            cursor: 'pointer',
            fill: global.colors.dark.color2,
            ':hover': {
                fillOpacity:'.7'
            }
        },
    })

};

module.exports = footerStyles;
