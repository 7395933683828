import { StyleSheet } from '@convertly/css';
import { generatePseudoMediaQuery } from '../../../elements/style-functions/pseudo-media-queries';

const HeaderStylesTop = (global, data, inEditor) => {
	

  return StyleSheet.create({
    mainHeaderTop: {
      verticalAlign: 'middle',
      height: '58%',
      paddingTop: '6px',
      paddingBottom: inEditor ? '12px' : '0',
      display: 'flex',
      alignItems: 'center',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
          height: '73%',
          display: 'flex',
          paddingBottom: '12'
        },
        inEditor
      ),
    }
  });
}

export default HeaderStylesTop;
