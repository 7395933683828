import React from 'react';
import Component from 'source/rootComponent';
import { css } from '@convertly/css';
import _ from 'lodash';

// Components

// Styles
import mainSiteHeaderStyles from './mainSiteHeaderStyles';

// Scripts
import getHeaderPosition from '../header-style-functions/get-header-position';
import { clientImage } from '../../utils/generateImage';

export class MainSiteHeader extends Component {

    generateStyles = () => {
        const styles = {};

        const {
            enableBackgroundImage,
            userOverrides
        } = this.props.data;

        if(enableBackgroundImage !== true) {
            return styles;
        }

        let backgroundImage = _.get( this.props.data, 'backgroundImage' ) || {};
        backgroundImage = _.isObject( backgroundImage ) ? backgroundImage : {};

        const {
            imgParams,
            src: backgroundImageSrc
        } = backgroundImage;

        const source = clientImage(backgroundImageSrc, { ..._.isObject(imgParams) ? imgParams : {} });

        let appendedBackgroundImageStyles =  _.get(userOverrides, 'backgroundImage') || '';

        if(appendedBackgroundImageStyles) {
            appendedBackgroundImageStyles += ', '
        }

        appendedBackgroundImageStyles += `url(${ source })`;

        styles.backgroundImage = appendedBackgroundImageStyles;

        return styles;
    }

    render() {
        const { global, data, children, page } = this.props;
        const inEditor = this.inEditor();

        let headerPosition = inEditor ? 'static' : getHeaderPosition(data.position, page);

        this.styles = mainSiteHeaderStyles({
            global,
            data,
            inEditor
        });

        const inlineStyle = this.generateStyleAttribute();

        const { mainSiteHeader } = this.styles;

        if (headerPosition === 'noHeader') {
            return null;
        }

        return (
            <header
                className={ css(mainSiteHeader) }
                data-elementtype="header"
                itemProp="siteHeader"
                itemScope
                itemType="http://schema.org/siteHeader"
                { ...inlineStyle }
                { ...this.generateDataEditorAttribute() }
                { ...this.generateCustomAttributes() }
                style={ this.generateStyles() }>

                { this._renderSiteHeaderChildren({ props: this.props, children, inEditor }) }

            </header>
        )
    }

    // Necessary to render the children inside of each endpoints
    _renderSiteHeaderChildren({ props, children, inEditor }) {
        return React.Children.map(children, child => {
            return React.cloneElement(child, {
                moduleId: props.moduleId,
                moduleSort: props.moduleSort,
                styles: props.styles,
                site: props.site,
                inEditor: inEditor
            })
        })
    }
}