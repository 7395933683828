import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
const styles = {
  backgroundColor: "#E4E4E4",
  borderRadius: "3px",
  width: "290px",
  height: "8px",
};
export const MarkedLine = styled.div`
  display: flex;
  pointer-events: none;
  width: 100%;
  .h {
    width: 7%;
    height: 1px;
    background-color: black;
    position: relative;
    bottom: 20px;
  }
  .v {
    height: 10px;
    width: 1px;
    background: black;
    bottom: 25px;
    position: relative;
  }
`;
const NUMBER_OF_COLUMNS_INSIDE_A_ROW = 12;
export class SliderBar extends React.Component {
  render() {
    if (this.props.twelveGridMarks) {
      var arr = [];
      for (let i = 1; i < NUMBER_OF_COLUMNS_INSIDE_A_ROW; i++) {
        arr.push(
          <div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <hr className="v" />
              <div style={{ position: "absolute", top: 20 }}>{i}</div>
            </div>
          </div>
        );
        arr.push(<hr className="h" />);
      }
      return (
        <div className="sliderBar" ref={el => (this.el = el)} style={{ ...this.props.style }}>
          <MarkedLine>{arr}</MarkedLine>
        </div>
      );
    }
    return (
      <div
        className="sliderBar"
        ref={el => (this.el = el)}
        style={{ ...styles, ...this.props.style }}
      />
    );
  }
}

SliderBar.defaultProps = {
  style: {},
};

SliderBar.propTypes = {
  style: PropTypes.object,
};
