const GlobalHeader = ( global, element, headerPosition ) => {

    const globalHeader = {
        width:'100%',
        position: element.fixedHeader ? 'fixed': 'static',
        zIndex:45,
        top: 0,
        marginTop: 0,
    };
    if( element.textColor ) {
        globalHeader.color = element.textColor;
    }

    if( headerPosition === "overlay") {
        globalHeader.backgroundColor = "transparent";
        globalHeader.position = "absolute";
    }
    if( headerPosition === "overlayFixed"  ) {
        globalHeader.backgroundColor = global.bodyBackgroundColor || global.backgroundColors.light.white;
        globalHeader.position = "fixed";
    }
    if( headerPosition === "fixed" ) {
        globalHeader.position = "fixed";
    }
    return globalHeader;
};
export default GlobalHeader;