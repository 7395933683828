import React from "react";
import styled from "styled-components";
import { GLOBALS } from "app/styles/global-default-styles";

export const Input = styled.input`
  ${({ borderColor }) =>
    borderColor
      ? `border: 1px solid ${borderColor};`
      : `border: 1px solid ${GLOBALS.colorPrimary};`}
  border-radius: 3px;
  width: 100%;
  ${({ inputWidth }) => inputWidth && `width: ${inputWidth}px;`}
  background: #fff;
  font-size: 14px;
  padding: 10px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.75);
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    padding-left: 0;
    border: 0;
    background:#cecece;
  }
`;
