import React from 'react';
import Component from '../../../../rootComponent';
import { css } from "@convertly/css";
import Types from '../../../../attribute-types';
import { id } from '@convertly/thor';

//Styles
import headerNavLinkstyles from './header-nav-link-styles-1'

//Scripts
import dropdownToggle from '../../scripts/nav-dropdown-toggle';

//Components
import DropDownNavLink1 from './dropdown-nav-link/dropdown-nav-item-1';

export default class
HeaderNavLink1 extends Component {

    constructor(props) {
        super(props);

    }

    render() {

        this.styles = headerNavLinkstyles( this.props.global, this.props.data );

        let navItem;
        if ( this.props.data.dropDownNav ) {
            navItem =
                <li className={ css( this.props.styles.navItem ) }
                    id={ id( dropdownToggle ) }
                    data-target={ "#dropDown" + this.props.idNumber }
                >
                    <a className={ css( this.props.styles.navItemLink, this.styles.headerNavLink ) }
                       id={  "navItem" + this.props.idNumber }
                       key={ this.props.idNumber }
                       target={ this.props.data.target ? "_blank" : "_self" }
                       href={ this.props.data.url }>
                        { this.props.data.text }
                    </a>
                    <ul data-category="dropdown"
                        className={ css( this.props.styles.dropDownNavList ) }
                         id={ "dropDown" + this.props.idNumber } >
                        { this.props.data
                            .dropDownNav
                            .map( ( dropDownNavItem, index ) => {
                                return (
                                    <DropDownNavLink1 key={ index }
                                                      global={ this.props.global }
                                                      data={ dropDownNavItem }
                                                      styles={ this.props.styles }/>
                                )
                            })
                        }
                    </ul>
                </li>;
        }
        else {
            navItem =
                <li id={  "navItem" + this.props.index }
                    key={this.props.idNumber}
                    className={ css( this.props.styles.navItem ) }>
                    <a className={ css( this.props.styles.navItemLink, this.styles.headerDropdownNavLink ) }
                       href={ this.props.data.url }
                       target={ this.props.data.target ? "_blank" : "_self" }>
                       { this.props.data.text }
                    </a>
                </li>;
        }
        return (
            <div className={ css( this.props.styles.navItemContainer ) }>
            { navItem }
            </div>
        )
    }
}
