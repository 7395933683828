export const ContestFormStyles = (global, data) => {


    const fileUploadSharedStyles = {
        border: "1px solid #cccccc",
        backgroundColor: global.colors.light.color1,
        padding: "8px 12px",
        display: 'flex',
        width: 'fit-content',
        fontSize: "14px",
        marginTop: "0px"
    }

    return {
        "< .contest-form": {
            ...global.form
        },
        "< .contest-form-label": {
            ...global.label
        },
        "< .contest-form-input": {
            ...global.input
        },
        "< .contest-form-textarea": {
            ...global.textArea
        },

        "< .contest-file-upload-wrapper": {

            alignContent: 'space-between',
            alignItems: 'center',
            display:'none'
        },
        "< .contest-file-upload-wrapper-display": {
            display: 'flex',
        },
        "< .contest-file-upload": {
            ...fileUploadSharedStyles,
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            cursor: "pointer",

        },
        "< .contest-file-upload-size": {
            marginLeft: "20px",
            fontSize: "12px",
            fontWeight: "light"
        },
        "< .contest-file-input": {
            position: 'absolute',
            height: '100%',
            width: '100%',
            zIndex: "1",
            opacity: 0,
            cursor: "pointer"
        },
        "< .contest-form-cloud-icon": {
            height: "20px",
            width: "20px",
            marginRight: "5px",
            zIndex: 0,
            transition: 'fill 1s ease',
            fill: "#6c757d"
        },
        "< .contest-form-cloud-icon-success": {
            fill: '#28a745'
        },
        "< .contest-form-submit": {
            ...global.submitButton,
            marginTop: "20px",
            width: "100%"
        },
        "< .contest-file-handler": {
            ...fileUploadSharedStyles,
            display:'none',
            alignItems: "center",
            justifyContent: "space-between",

        },
        "< .contest-file-handler-display": {
            display:'flex!important'
        },
        "< .remove-file": {
            marginLeft: '10px',
            fontSize: '20px',
            cursor:'pointer'
        },
        "< .marketing-clause": {
            marginBottom:'12px'
        }

    }
}
