import { connect } from "react-redux";
import { AdtechMenu } from "../menus/AdtechMenu";
import { store } from "app/app";

import { setElements } from "containers/Editor/actions/editorActions";

import { updateBannerTitle } from "containers/Marketing/Banners/actions";

const mapStateToProps = state => {
  const Editor = state.get("editor").editorReducer;
  const Banners = state.get("marketing").Banners;

  return {
    isMenuOpen: state.getIn(["global", "isMenuOpen"]),
    showNavigationMenu: Editor.get("showNavigationMenu"),
    pageElements: Editor.get("pageElements"),
    isUndoDisabled: Editor.get("isUndoDisabled"),
    isRedoDisabled: Editor.get("isRedoDisabled"),
    page: Editor.get("page"),
    currentSite: Editor.get("currentSite"),
    bannerEditorIsOpen: Banners.get("bannerEditorIsOpen"),
    activeBanner: Banners.get("activeBanner"),
    saving: Banners.get("saving"),
    settings: Banners.get("settings"),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    undo: () => {
      const history = store
        .getState()
        .get("editor")
        .editorReducer.get("pageElementsHistory");
      dispatch(setElements(history));
    },
    updateBannerTitle: async () => await dispatch(updateBannerTitle()),
  };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(AdtechMenu);

export default Container;
