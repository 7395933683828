import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { GLOBALS } from "app/styles/global-default-styles";

import { noop } from "utils";
import { Label, Wrapper, Description } from "../Input/InputWithLabel";

export const TextArea = styled.textarea`
  ${({ borderColor }) =>
    borderColor
      ? `border: 1px solid ${borderColor};`
      : `border: 1px solid ${GLOBALS.colorPrimary};`}
  width: 100%;
  border-radius: 2px;
  padding: 7px 52px 6px 7px;
  outline: none;
  margin-bottom: 30px;
  height: 73px;
`;

export const TextAreaWithLabel = ({ label, description, smallLabel, ...props }) => {
  return (
    <Wrapper>
      <Label smallLabel={smallLabel} htmlFor={props.name}>
        {label}
      </Label>
      {!!description && <Description>{description}</Description>}
      <TextArea {...props} />
    </Wrapper>
  );
};

TextAreaWithLabel.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

TextAreaWithLabel.defaultProps = {
  label: "",
  description: "",
  onChange: noop,
  value: "",
};

export const TextAreaWithSmallLabel = props => <TextAreaWithLabel {...props} smallLabel={true} />;
