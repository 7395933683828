import { StyleSheet } from "@convertly/css";
import { generateSafariStyleSheet } from '../../utils/browserSpecificStyles'

const chatWidgetStyles = ( global, data ) => {

    let iosChat = generateSafariStyleSheet(
        {
            position:'static',
            paddingTop:'10px'
        }
    );

    const iconBar = {
        background: global.colors.dark.color2,
        width: '21px',
        height: '3px',
        marginBottom:"5px",
        position: "relative",
        display:"block"
    };
    const message = {

        height:'auto',
        width:'fit-content',
        maxWidth:'250px',
        display:'block',
        margin:'10px 0',
        padding:'15px',
        fontSize:'14px',
        borderRadius: '5px',
        overflowWrap:'break-word'

    };
    return StyleSheet.create({
        convertlyChatWidgetContainer:{
            position:'relative',
            zIndex:50,
            fontFamily:global.fontFamily.primaryFont

        },
        convertlyChatWidgetLaunch: {
            position:'fixed',
            top:'50vh',
            zIndex:10,
            transform:'translateY(-125px)',
            height:'125px',
            right:0,
            background:global.backgroundColors.light.color2,
            border:'3px solid',
            borderRight:'none',
            borderRadius:'5px',
            marginRight:'-10px',
            borderColor:global.backgroundColors.dark.color1,
            color:global.textColors.accent.color1,
            cursor:'pointer',
            '@media (max-width:600px)': {
                top:'auto',
                transform:'translateY(0)',
                bottom:'40px'
            }

        },
        convertlyChatWidgetLaunchText: {
            margin:'50px -25px 0 -30px',
            fontWeight:700,
            transform:"rotate(-90deg)",
            fontSize:'18px'
        },
        convertlyChatWidget: {
            position:'fixed',
            width:'400px',
            minHeight:'100vh',
            height:'100vh',
            zIndex:'50',
            background:'#edebeb',
            borderLeft:'3px solid',
            borderColor:global.backgroundColors.dark.color1,
            top:'0px',
            right:'-100%',
            transition:'all .2s linear',
            ".open": {
                right:"0px"
            },
            '@media (max-width:600px)': {
                width:'100%',
                maxWidth:'100%',
                borderLeft:'none'
            }
        },
        chatWidgetHeader: {
            borderBottom:'3px solid',
            maxHeight:'4.5em',
            display: 'flex',
            alignItems: 'center',
            padding: '20px 10px'
        },
        chatWidgetHeaderTextContainer: {
            display:'inline-block',
            color:global.textColors.dark.color2,
            padding:'0 10px',
            width:'50%',
            margin:0,
        },
        chatWidgetHeaderText: {
            fontSize:'18px',
            fontWeight:'bold'
        },
        chatWidgetClose: {
            display:'inline-block',
            width:'50%',
            margin:0
        },
        chatWidgetCloseButton: {
            float:'right',
            height: "auto",
            paddingBottom: 0,
            overflow: "visible",
            background:"none",
            border:"none",
            outline: "none",
        },
        iconBar: {
            ...iconBar,
            ":nth-child(1)":{
                transform:"rotate(45deg)",
                top:"3.1px",
            },
            ":nth-child(2)": {
                transform:"rotate(-45deg)",
                top: '-4.6px'
            },
        },
        messageBoardContainer: {
            padding: '10px 0 0 10px',
            height:'65%',
            position:'relative',
            display:'flex',
            flexDirection: 'row',
            alignItems:'flex-end',
            '@media (max-width:900px)': {
                padding:'15px'
            }

        },
        messagesBoard: {
            maxHeight:'100%',
            overflowY:'scroll',
            position:'relative',
            bottom:'0px',
            width:'100%',
            paddingRight:'10px'
        },
        systemMessage: {
            ...message,
            background:'none',
			display: 'flow-root',
            color:global.textColors.dark.color2,
			maxWidth: 'none',
			padding: '15px 0'
        },
        clientMessage: {
            ...message,
            background:global.backgroundColors.accent.color1,
            color:global.textColors.light.white,
            clear:"left",
            marginLeft:'auto',
            display:'flow-root',
            '.show': {
                display:'flow-root',
            }
        },
        agentMessage: {
            ...message,
            //marginRight:'65px',
            background:global.backgroundColors.light.white,
            color: global.textColors.dark.color1,
            marginRight:'auto',
            display:'flow-root',
            "> a" : {
                color:global.textColors.accent.color1,
                textDecoration:'underline'
            }
        },
        chatWidgetLink: {
            textDecoration:'underline',
            cursor:'pointer'
        },
        ellipsisMessage: {
            ...message,
            width:'fit-content',
            position:'relative',
            height:'35px',
            display:'none',
            background:'none',
            color: global.textColors.dark.color1,
            marginRight:'auto',
            '.active': {
                display:'flow-root',
            }
        },
        ellipsis: {
            fill:global.backgroundColors.accent.color2,
            display:'inline-block',
            position:'relative',
            bottom:'0',
            marginRight:'4px',
            height: '15px',
            width: '15px',
            opacity:'.7',
            transition:'all .15s linear',
            '.active': {
                bottom:'3px',
                opacity:'1',
            }
        },
        formContainer: {
            textAlign:'center',
            width:'100%',
            position:'absolute',
            bottom:'30px',
            padding:'padding:10px 10px 0',
            '@media (max-width:550px)': {
                bottom:'85px',
                height:'100px'
            },
            ...iosChat
        },
        messageInput: {
            width: '100%',
            border: '3px solid',
            fontSize:'16px',
            borderColor: global.backgroundColors.dark.color2,
            background: global.backgroundColors.light.white,
            color:global.textColors.dark.color1,
            padding:'5px',
            ':focus': {
                fontSize:'16px',
                outlineColor:global.backgroundColors.accent.color1,
            },
			'.disabled': {
            	background: '#aeaeae'
			},
            '@media(max-height:820px)': {
                height:'70px'
            },
            '@media (max-width:550px)': {
                display:'inline-block',
                padding:'5px',
                margin:0,
                height:'70px',
                width:'80%',
                marginRight:'-4px',
                float:'left',
                borederRight:'none',
                borderColor:global.backgroundColors.accent.color1,
                ':focus': {
                    outline:'none'
                }
            }
        },
        submitButton: {
            border:'none',
            width:'100%',
            height:'50px',
            borderRadius:'3px',
            background:global.backgroundColors.accent.color1,
            fontWeight:700,
            fontSize:'14px',
            color:global.backgroundColors.light.white,
            marginTop:'20px',
            textTransform:'uppercase',
            ':hover': {
                opacity:'.7'
            },
			'.disabled': {
            	opacity: '.7'
			},
            '@media (max-width:550px)': {
                display:'inline-block',
                padding:'0',
                margin:'0',
                height:'70px',
                marginLeft:'-1px',
                borderBottomLeftRadius:'none',
                borderTopLeftRadius:'none',
                borderLeft:'none',
                maxHeight:'70px',
                width:'20%',
                float:'left',
                ':hover': {
                    opacity:1
                }
            }
        },
        coco: {
            position:'fixed',
            bottom:'20px',
            right:'20px',
            width:'75px',
            height:'75px',
            cursor:'pointer'
        }




    })

};
export default chatWidgetStyles;
