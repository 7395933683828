import {StyleSheet} from "@convertly/css";
import UserOverrides from '../../../elements/style-functions/user-overrides';

import { generateHeaderOffsetMediaQueries } from '../header-style-functions/fixed-header-media-queries';
import _ from 'lodash';


const headerStyles5 = (global, data, headerPosition, inEditor) => {

    const headerOverrides = UserOverrides( global, data, inEditor );
    let bodyPaddingMediaQueries = {};
    let positionFixed = data.position === "fixed";
    let headerHeight = _.get( data, 'userOverrides.height' ) || '100px';

    /** Searches for height in media queries and assigns is
     to a property that can be passed to height */
    if( data.userOverrides && positionFixed ) {
        bodyPaddingMediaQueries = generateHeaderOffsetMediaQueries( data );
    }


    let headerOffset = {marginTop: headerHeight};
    let headerStyles = {};

    if (inEditor) {
        headerOffset = {}
    }

    if (headerPosition === "overlay") {
        headerStyles = {
            position: 'absolute',
            color: global.colors.light.white,
            stroke: global.colors.light.white,
            background: "transparent"
        }
    }
    else if (headerPosition === "overlayFixed") {
        headerStyles = {
            '.absolute': {
                position: 'absolute',
                color: global.colors.light.white,
                stroke: global.colors.light.white,
                background: "transparent"
            }
        }
    }
    else if (headerPosition === "fixed") {

        headerStyles = {
            position:'fixed',
            '* body': {
                ...headerOffset,
                '@media(max-width:991px)': {
                    marginTop: '70px'
                },
                ...bodyPaddingMediaQueries
            }
        }
    }
    else {
        headerStyles = {}
    }


    return StyleSheet.create({
        headerWrapper: {
            height: 'fit-content'
        },
        header: {
            width:'100%',
            color: global.colors.dark.color1,
            background: global.bodyBackgroundColor,
            fontFamily: global.fontFamily.primaryFont,
            lineHeight: '26px',
            stroke: global.colors.dark.color1,
            transition: 'all .25s ease',
            height:'100px',
            zIndex:'10',
            top:'0',
            '@media(max-width:991px)': {
                height: '70px'
            },
            ...headerStyles,
            ...headerOverrides
        }


    })

};
export default headerStyles5;
