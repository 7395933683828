import { fromJS } from "immutable";
import { createReducer } from "redux-act";

import { setActiveAsset, setActiveImageOption, setSearch } from "./actions";

const initialParams = {
  search: "",
  filter: "ALL",
  assets: [],
  activeImageOption: "ALL_IMAGES",
  activeAsset: "IMAGES",
};

const initialState = fromJS(initialParams);

const reducers = {
  [setSearch]: (state, search) => state.merge({ search }),

  [setActiveAsset]: (state, activeAsset) => state.merge({ activeAsset }),

  [setActiveImageOption]: (state, activeImageOption) => state.merge({ activeImageOption }),
};

export const AssetsPanelReducer = createReducer(reducers, initialState);
