import { StyleSheet } from "@convertly/css";


export const fadeAnimation = {
    from: {
        opacity:.2
    },
    to: {
        opacity:1
    }
};
export const fadeOutAnimation = {
    from: {
        opacity:1
    },
    to: {
        opacity:0
    }
}

const transitions = {

    fade: {
        baseStyles: {
            opacity: '0.0',
            transition: 'opacity 500ms linear',
            position: 'absolute',
            left: '0'
        },

        activeStyles: {
            opacity: '1.0',
            position: 'relative',
            transition: 'opacity 1s ease-in',

        },
        transition: 'opacity 1s ease-out'
    },
    slide: {

        baseStyles: {

            position: "absolute",
            '.left': {
                left: '-100%',
            },
            '.no-animation': {
                transition: 'all 0s'
            },
            '.right': {
                left: '100%',
            }
        },
        activeStyles: {
            position: "relative",
            left: '0px',
        },
        transition: 'all 450ms',
    },

};


const convertlyTransitions = (global, data) => {

    let globalTranstions = {
        activeTransition: '',
        activeBaseStyles: '',
        activeActiveStyles: ''
    };

    if (data.transition === 'fade') {
        globalTranstions.activeTransition = transitions.fade.transition;
        globalTranstions.activeBaseStyles = transitions.fade.baseStyles;
        globalTranstions.activeActiveStyles = transitions.fade.activeStyles;
    }
    if (data.transition === 'slide') {
        globalTranstions.activeTransition = transitions.slide.transition;
        globalTranstions.activeBaseStyles = transitions.slide.baseStyles;
        globalTranstions.activeActiveStyles = transitions.slide.activeStyles;
    }
    if (data.transition === 'slideY') {
        globalTranstions.activeTransition = transitions.slideY.transition;
        globalTranstions.activeBaseStyles = transitions.slideY.baseStyles;
        globalTranstions.activeActiveStyles = transitions.slideY.activeStyles;
    }

    return globalTranstions
};

export default convertlyTransitions;