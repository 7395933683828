import { StyleSheet } from "@convertly/css";
import UserOverrides from '../style-functions/user-overrides';

const moduleWrapperStyles =  ( global, data, inEditor ) => {

    const userOverrides = UserOverrides( global, data, inEditor );

    /** increases zIndex if there is an overlay or backgrond Image **/
    let zIndex = {};
    if(data.backgroundVideo || data.overlay) {
        if(data.backgroundVideo) {
            zIndex = {
                zIndex: 2
            }
        }
        if(data.overlay) {
            zIndex = {
                zIndex: 3
            }
        }

    }
    let overflow = {};
    if(data.backgroundVideo) {
        overflow= {
            overflow:'hidden'
        }
    }
    return StyleSheet.create({
        moduleStyles: {
            ...global.moduleDefault,
            //position:'relative',
            ...overflow,

            ...userOverrides
        },
        convertlyContentArea: {
            ...zIndex,
            position:"relative"
        },
        overlay: {
            position: "absolute",
            height:'100%',
            width:'100%',
            top:0,
            left:0,
            background:data.overlay ? data.overlay.background : '',
            zIndex:2
        }

    })

};

export default moduleWrapperStyles;