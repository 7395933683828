//import $ from 'jquery';

import {include} from '@convertly/thor';

//"use strict"
const navDropdownToggle = ( id ) => {

    const IsIOS = include('../../utils/is-ios', "IsIOS");

    return( id ) => {


        const $rootId = $( `#${id}` );
        let $body = $( 'body' );
        let $headerHeight = $( "header" ).height( );



        /** Save in memory if they are using IOS device or not **/

            let s = document.createElement("script");
            s.setAttribute('src', "https://cdnjs.cloudflare.com/ajax/libs/zenscroll/4.0.0/zenscroll-min.js");
            $body.append(s);


        /** Checks to see if you scroll past the header and renders/hides chevron **/

        $( document ).on( 'scroll', function( e ) {

            let $scroll = $(window).scrollTop( );

            if( $scroll < $headerHeight && !$rootId.hasClass( 'closed' ) ) {
                $rootId.addClass( 'closed' )


            } else if( $scroll > $headerHeight && $rootId.hasClass( 'closed' ) ) {


                $rootId.removeClass( 'closed' )
            }
        } );

        /** click handler to init scroll **/
        $rootId.on( 'click', function( ) {
            let $scroll = $body.scrollTop( );
            scrollToTop( $scroll );

        } );


        /** Scrolls to top of page **/
        function scrollToTop( i ) {

                zenscroll.toY(0, 400)

        }

    }

};


export default navDropdownToggle;
