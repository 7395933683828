import React from 'react';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import _ from 'lodash';

//Default Props
import defaultData from './social-icon-1';
import defaultGlobal from '../../../themes/global';

//Styles
import socialIconsStyles from './social-icon-list-styles-1';

//Attributes
import Types from '../../../attribute-types';

//Components
import SocialIcon01 from './social-icon-1';


export default class SocialIcons1 extends Component {

    constructor(props) {

        super(props);


    }

    render() {

        /** Calls editor */
        if( this.props.editor ) {
            return this.editor();
        }
        

        /** Generates border in editor */
        const inlineStyle = this.generateStyleAttribute( );

        /** init styles **/
        this.styles = socialIconsStyles(this.props.global, this.props.data);

        /** puts the icons in proper order **/
        function sortByKey(array, key) {
          if(Array.isArray(array)) {
            return array.sort(function(a, b) {
                var x = a[key]; var y = b[key];
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            });
          } else {
            return [];
          }
        }

        let socialIcons = _.get(this.props.site, 'social') && sortByKey(_.get(this.props.site, 'social'), "sort") ||
                          sortByKey(this.props.data.socialIcons, "sort") || [];

        return (
            <div className={ css(this.styles.socialIcons) }
                 onClick={( e ) => {
                     this.launchElementalEditor( 'socialIcon', e );
                 }}
                 onMouseOver={ (e) => {this.addEditableOutline(e)} }
                 onMouseOut={ (e) => {this.removeEditableOutline(e)} }
                 {...inlineStyle}>
                <ul className={ css(this.styles.socialIconsList) }>
                    { socialIcons.map((socialIcon, index) => {

                        if( socialIcon.active ) {
                            return (
                                <SocialIcon01 key={ index }
                                              data={ socialIcon }
                                              styles={ this.props.styles }
                                              global={ this.props.global }
                                              socialIconLinkClass={ this.props.socialIconLinkClass }/>
                            )
                        }
                    })
                    }
                </ul>
                <div className="clearfix"/>
            </div>
        )
    }
}


SocialIcons1.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
