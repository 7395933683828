import { StyleSheet } from "@convertly/css";

export const styles = StyleSheet.create({
  // Profile Link //

  // profileContainer: {
  // 	position: 'relative',
  // 	marginLeft: '10px',
  // },
  // profileSet: {
  // 	display: 'flex',
  // 	alignItems: 'center',
  // 	justifyContent: 'center',
  // 	flexWrap: 'nowrap',
  // 	height: '46px',
  // 	paddingLeft: '20px',
  // 	paddingRight: '20px',
  // 	cursor: 'pointer'
  // },
  profilePanel: {
    position: "absolute",
    top: "30",
    right: "0",
    zIndex: "4000",
    minWidth: "260px",
    background: "#FFF",
    color: "#333",
    borderRadius: "6px 0 0 6px",
    boxShadow: "0 0 3px rgba(0,0,0,0.25)"
  },
  profileDetail: {
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    padding: "20px",
    paddingRight: "40px"
  },
  profileSetting: {
    padding: "0 0 15px 20px",
    borderBottom: "1px solid #DDD"
  },
  profileAvatarSmall: {
    height: "30px",
    maxWidth: "none",
    borderRadius: "100%",
    margin: "0 10px 0 10px"
  },
  profileAvatar: {
    width: "80px",
    height: "80px",
    color: "#FFF",
    backgroundColor: "#349886",
    borderRadius: "50%",
    fontWeight: "300",
    fontSize: "28px",
    textAlign: "center",
    lineHeight: "80px",
    letterSpacing: "2px",
    marginRight: "10px"
  },
  profileNameEmail: {
    display: "inline-block"
  },
  profileRadioButton: {
    fontWeight: "400",
    marginLeft: "10px",
    cursor: "pointer",
    userSelect: "none"
  },
  profileRadioButtonSelect: {
    marginRight: "5px",
    cursor: "pointer"
  },
  statusContainer: {
    padding: "20px",
    borderBottom: "1px solid #DDD"
  },
  logoutContainer: {
    padding: "20px"
  },
  emailLink: {
    color: "#4A90E2",
    cursor: "pointer",
    userSelect: "none",
    ":hover": {
      textDecoration: "underline"
    }
  },
  panelUnderlay: {
    position: "fixed",
    top: "0",
    left: "0",
    zIndex: "3900",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,.15)"
  }
});

export default styles;
