import { StyleSheet } from "@convertly/css";

const ContactFormStyles = ( global, data ) => {
    const formField = {
        width:'100%',
        padding:'8px',
        margin:'10px 0',
        border:'2px solid',
        borderColor:global.colors.dark.color2,
        color:global.colors.dark.color2,
        fontSize:'16px',
        background:global.colors.light.white,
        ":placeholder": {
            color:global.colors.dark.color3,
        },
        ':focus': {
            outline:global.colors.accent.color1
        }
    };

    return StyleSheet.create( {

        contactUsFormContainer: {
            position:'relative',
            overflow:'hidden',
            fontFamily:global.fontFamily.primaryFont,
            backgroundColor: global.colors.accent.color2,
            width:'400px',
            padding:'15px 25px 12px',
            borderRadius: '5px',
            '@media ( max-width: 991px )': {
                margin:'40px auto',
                width:'100%'
            },
            '.confirmationMessageWrapper': {
                position:'absolute',
                top:'0',
                left:'110%',
                transition:'all 500ms ease',
                '.in': {
                    left:'0'
                }
            },
            '.confirmationMessageHeader': {
                fontSize:'20px',
                color:global.colors.dark.color3,
            },
            '.divider': {
                borderBottom:'3px solid',
                borderColor: global.colors.dark.color3,
                padding:'3px',
                width:'40%',
                borderRadius:0,
                margin:0
            },
            '.confirmationMessage': {
                color:global.colors.dark.color3,
                fontSize:'16px'
            }
        },
        formWrapper: {
            position:'relative',
            left:'0',
            transition:'all 500ms ease',
            '.flyout': {
                left:'-110%'
            }
        },
        contactUsFormHeading: {
            width:'fit-content',
            color: global.colors.dark.color2,
            borderBottom:'2px solid',
            borderColor: data.contactUsFormHeader.color || global.colors.dark.color2,
            fontSize:'20px'
        },
        contactUsForm: {
            marginTop: "10px",
        },
        formInput: {
            ...formField,
            height:'40px',
        },
        formTextArea: {
            ...formField
        },
        submitButton: {
            position:"relative",
            borderWidth:1,
            borderStyle:'solid',
            cursor: "pointer",
            width:'100%',
            ...global.button2.alternate,
            backgroundColor:global.colors.accent.color3,
            ":hover": {
                opacity:.7
            }
        },

    });

}

export default ContactFormStyles;
