const data = {

    products: [
        {
            onSale: true,
            brandName: 'Green Tea',
            productType: 'Herbalisious Green Tea',
            fullPrice: '$29.05',
            onSalePrice: '$20.05',
            productDescription: false
        }
    ],
    divider: "shadowLight"

};
export default data