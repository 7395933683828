import { StyleSheet } from "@convertly/css";
import removeNestedUserOverrides from "../../site-header/site-header/utils/removeNestedUserOverrides";
import _ from "lodash";
import { generatePseudoMediaQuery } from "../../elements/style-functions/pseudo-media-queries";

export const BlogPreviewSliderStyles = ({ global, data, inEditor }) => {
  const blogPreviewSlider = removeNestedUserOverrides({ data });

  const blogPreviewSliderUserOverrides = !_.isEmpty(blogPreviewSlider)
    ? userOverrides(global, { userOverrides: blogPreviewSlider }, inEditor)
    : {};

  return StyleSheet.create({
    blogPreviewSliderWrapper: {
      width: "100%",
      ".blog-preview-slider": {
        display: "flex",
        boxShadow: "0 3px 10px 0 rgba(0, 0, 0, .14)",
        minHeight: "428px",
        ...generatePseudoMediaQuery(
          "@media(max-width: 991px)",
          ["mobile", "smallTablet", "largeTablet"],
          {
            flexDirection: "column",
            height: "unset"
          },
          inEditor
        )
      },

      ".blog-list-wrapper": {
        width: "30%",
        position: "relative",
        // height: "fill-available",
        ...generatePseudoMediaQuery(
          "@media(max-width: 991px)",
          ["mobile", "smallTablet", "largeTablet"],
          {
            width: "100%",
            position: "relative",
            order: "2",
            minHeight: "328px"
          },
          inEditor
        )
      },

      ".blog-preview-wrapper": {
        width: "70%",
        position: "relative",
        ...generatePseudoMediaQuery(
          "@media(max-width: 991px)",
          ["mobile", "smallTablet", "largeTablet"],
          {
            width: "100%",
            height: "428px"
          },
          inEditor
        )
      },

      ".blog-preview": {
        position: "relative",
        height: "100%",
        display: "flex",
        alignItems: "flex-end",
        background: "linear-gradient(0deg, rgba(0, 0, 0, .84), rgba(36, 36, 36, .02))",
        padding: "20px",
        transition: "all 500ms ease",
        ":hover": {
          padding: "30px 30px 25px 25px"
        }
      },

      ".blog-preview-media": {
        position: "absolute",
        top: "0",
        left: "0",
        height: "100%",
        zIndex: "-1",
        width: "100%",
        objectFit: "cover"
      },

      ".blog-preview-link": {
        height: "100%",
        opacity: "0",
        position: "absolute",
        transition: "opacity 500ms ease",
        ".active": {
          opacity: "1"
        }
      },

      ".blog-preview-content": {
        color: "white"
      },

      ".blog-preview-subtitle": {
        fontSize: "11px",
        textTransform: "uppercase"
      },

      ".blog-preview-title": {
        fontSize: "16px",
        fontWeight: "bold",
        margin: "5px 0"
      },

      ".blog-list-set": {
        height: "100%",
        flexDirection: "column",
        display: "none",
        opacity: "0",
        transition: "opacity 500ms ease",
        ".active": {
          opacity: "1",
          zIndex: 1,
          display: "flex"
        }
      },

      ".blog-list": {
        flex: data.maxShown ? 1 / data.maxShown : 1,
        padding: "0px 10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        ":hover": {
          background: "#e6e6e6"
        }
      },

      ".blog-list.active": {
        background: "#e6e6e6"
      },

      ".blog-list-subtitle": {
        color: global.colors.light.color3,
        fontSize: "11px"
      },

      ".blog-list-title": {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "5px"
      },

      ".blog-list-button": {
        position: "absolute",
        left: "50%",
        borderRadius: "50%",
        background: global.colors.dark.black,
        borderColor: global.colors.dark.black,
        color: global.colors.light.white,
        height: "40px",
        width: "40px",
        transition: "all 200ms ease",
        zIndex: "2"
      },

      ".blog-list-prev-button": {
        top: "0",
        transform: "translateY(-50%) translateX(-50%)",
        paddingBottom: "5px",
        ...generatePseudoMediaQuery(
          "@media(max-width: 991px)",
          ["mobile", "smallTablet", "largeTablet"],
          {
            zIndex: "2",
            borderColor: global.colors.light.white
          },
          inEditor
        ),
        ":hover": {
          transform: "translateY(-40%) translateX(-50%)",
          borderColor: global.colors.accent.color1,
          color: global.colors.accent.color1
        }
      },

      ".blog-list-next-button": {
        bottom: "0",
        transform: "translateY(50%) translateX(-50%)",
        ":hover": {
          transform: "translateY(40%) translateX(-50%)",
          borderColor: global.colors.accent.color1,
          color: global.colors.accent.color1
        }
      }
    }
  });
};

export default BlogPreviewSliderStyles;
