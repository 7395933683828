/*global Inferno, localStorage, Fingerprint2, $, ga */
/* eslint-disable */
import {include} from '@convertly/thor';

const Script = (id) => {
	
	
	/** Imports **/
	const Graph = include('../../utils/graph', 'Graph');
	const Parser = include('../../utils/json-parse-catch', 'Parser');
	const BuildProductPricingObject = include('../../utils/ecomm-utils/inferno-utils/build-product-pricing-object-inferno', 'BuildProductPricingObject');
	const Dispatcher = include('../../utils/dispatcher', 'Dispatcher');
	const Cookie = include('../../utils/cookie', 'Cookie');
	const Generator = include('../../utils/generateRandomId', 'Generator');
	
	return (id, siteLink, validateCart) => {

		const filename = 'the-cart';
		const parser = Parser();
		const report = false;
		
		const hasLocalStorage = $('html').hasClass('localstorage');
		const noop = () => {
		};
		
		const root = document.getElementById(id);
		
		/** Init Convertly Utils **/
		const graph = Graph();
		const dispatch = new Dispatcher(id);
		const cookie = Cookie();
		const generator = Generator();
		const buildProductPricingObject = BuildProductPricingObject();
		
		const TheCart = Inferno.createClass({
			
			/**
			 * Set our initial state values
			 *
			 * @returns {{}}
			 */
			getInitialState: function () {
				
				return {
					items: [],
					subtotal: parseFloat('0.00'),
					stepper: 1,
					cartId: null,
					
					// Each edit cart mutation is assigned a random ID.
					// Before the graph call is executed, state is updated with the most
					// recent ID
					//
					// The Graph API callback checks that it's ID is the the most
					// recent ID.  If not, it aborts.
					//
					// Handles bug if you press add to cart or change quantity quickly.
					// Multiple API requests were sent and as they were resolving
					// the cart UI was updating again.
					apiId: null
				}
			},
			
			
			/**
			 * Load existing cart
			 * Initialize Dispatch Event Listeners
			 */
			componentDidMount: function () {
				
				
				dispatch.on('add_to_cart', this.addToCart.bind(this, false));
				dispatch.on('quick_buy', this.addToCart.bind(this, true));
				dispatch.on('remove_cart_item', this.removeItemFromCart);
				dispatch.on('remove_change_alert', this.removeChangeAlert);
				dispatch.on('item_quantity_change', this.updateCartItemQuantity);
				dispatch.on('item_quantity_increase', this.updateCartItemQuantity.bind(this, true));
				dispatch.on('item_quantity_decrease', this.updateCartItemQuantity.bind(this, false));
				dispatch.on('stepper_adjusted', this.changeItemQuantity);
				dispatch.on('request_cart', this.handleCartRequest);
				dispatch.on('empty_cart', this.emptyCart);
				
				this.loadCart();
				
				if (typeof ga !== 'undefined') {
					ga('require', 'ecommerce');
				} else {
					console.error('GA Ecommerce plugin not installed');
				}
				
			},
			
			
			/**
			 * Loads cart from local storage with API fallback
			 */
			loadCart: function () {
				
				
				let cart = this.loadLocalCart();
				let cartId;
				
				// Got a cart from Local Storage
				if (cart) {
					const {cartId, items, subtotal, fp} = cart;
					this.setState({cartId, fp}, () => {
						this.setCart({items, subtotal});
					});
					return;
				}
				
				// See if there's a cartId in browser cookies
				cartId = this.getLocalCartId();
				
				if (!cartId) {
					return;
				}
				
				const cb = (cart) => {
					this.setCart(cart);
					this.saveCartToLocalStorage(cart);
				};
				
				this.setState({
					cartId
				}, () => {
					this.loadRemoteCart(cb);
				});
				
			},
			
			
			/**
			 * Loads cartId from browser cookie
			 */
			getLocalCartId: function () {
				
				const cartId = cookie.read({name: 'cartId'});
				
				if (!cartId) {
					return;
				}
				
				return cartId;
				
			},
			
			
			/**
			 * Load cart from API
			 */
			loadRemoteCart: function (cb = noop) {
				
				
				const {cartId} = this.state;
				if (!cartId) {
					return;
				}
				
				const query = `query($id:ID!){Cart(id:$id){cartId,items,subtotal,fp}}`;
				const variables = {id: cartId};
				
				const graphCb = (error, {Cart}) => {
					
					if (!error) {
						Cart.items = parser(Cart.items);
						cb(Cart);
					}
				};
				
				graph({query, variables, cb: graphCb});
				
			},
			
			
			/**
			 * Loads cart from local storage
			 * Validate that cart has truthy values for items, cartId, and subtotal
			 */
			loadLocalCart: function () {
				
				let cart;
				
				try {
					cart = localStorage.getItem('cart');
					cart = parser(cart);
				} catch (e) {
					localStorage.removeItem('cart');
					return;
				}
				
				if (!cart) {
					localStorage.removeItem('cart');
					return;
				}
				
				else {
				
				
				}
				
				const {items, cartId, subtotal, fp} = cart;
				
				if (!items || !cartId || !subtotal || !fp) {
					if (report) {
						console.log(!items, items);
						console.log(!cartId, cartId);
						console.log(!subtotal, subtotal);
						console.log(!fp, fp);
					}
					
					localStorage.removeItem('cart');
					return;
				}
				//areas to check
				let cartVerified = cookie.read({name: 'cartVerified'});
				
				
				if (validateCart || cartVerified !== "cartVerified") {
					this.verifyCartValues(cart);
				}
				
				return cart;
				
			},
			
			/** Call query to check cart for changes **/
			verifyCartValues: function (cart, cb = noop) {
				let query = `query($cart:String) {
                                ValidateCart(cart:$cart) {
                                    validations
                                    error
                                }
                            }`;
				
				let cartString = JSON.stringify(cart);
				
				let variables = {
					cart: cartString
				};
				
				graph({query, variables, cb: this.validateCart.bind(this, cart, cb)})
				
			},
			
			/** Validates values in cart vs current cart **/
			validateCart: function (currentCart, cb = noop, error, response) {
				if (error) {
					console.log('error')
				}
				else {
					let cartChanges = response.ValidateCart.validations;
					cartChanges = parser(cartChanges);
					
					//map through cart items, find changes update and update change array
					
					if (cartChanges && cartChanges.length !== 0) {
						currentCart.items.map((cartItem, index) => {
							
							
							cartChanges.map((cartChange, i) => {
								if (cartItem.cartId === cartChange.id) {
									currentCart.items[index].changes = [];
									cartChange.errors.map((error) => {
										//empties array to prevent incorrect stacking of errors
										
										Object.keys(error).map((key) => {
											if (key !== "image") {
												
												currentCart.items[index][key] = error[key];
												currentCart.items[index].changes.push(key)
											}
										});
									});
									currentCart.items[index].pricing = buildProductPricingObject(currentCart.items[index]);
									currentCart.items[index] = this.getFinalPrice(currentCart.items[index]);
									
								}
								
							})
						});
						//items saving now, need to set session cookie, and set up calling it on cart/checkout
						this.saveCart(currentCart.items, cb);
					}
					
					cookie.create({
						name: "cartVerified",
						expires: false,
						value: 'cartVerified',
					});
					
				}
				
				
			},
			
			
			/**
			 * Save items and subtotal to state
			 * Calls broadcastCart
			 * Invokes optional callback
			 *
			 * @param items
			 * @param subtotal
			 * @param cb
			 */
			setCart: function ({items, subtotal}, cb = noop) {
				
				
				const params = {
					items,
					subtotal
				};
				
				this.setState(params, () => {
					this.broadcastCart(items);
					cb();
				});
				
			},
			
			
			/**
			 * Calls broadcastCart ( so page UI elements get updated immediately )
			 * Calls updateRemoteCart if cartId in state
			 * Calls createRemoteCart if no cartId in state
			 */
			saveCart: function (items, cb = noop) {
				if (!Array.isArray(items)) {
					return;
				}
				
				this.broadcastCart(items);
				
				const {cartId} = this.state;
				
				if (cartId) {
					this.updateRemoteCart({items, cartId, cb});
				} else {
					this.createRemoteCart({items, cb});
				}
				
			},
			
			
			/**
			 * Calls editCart Graph
			 * Calls saveLocalCart
			 * Calls setCart
			 *
			 * @param items
			 * @param cartId
			 * @param cb
			 */
			updateRemoteCart: function ({items, cartId, cb = noop}) {
				
				
				const query = `mutation($cart:cartInput,$id:ID!){editCart(cart:$cart,id:$id){cartId,subtotal,items}}`;
				const variables = {
					cart: {
						items: JSON.stringify(items),
					},
					id: cartId
				};
				
				const apiId = generator();
				
				const graphCb = (error, {editCart}) => {
					
					
					if (this.state.apiId !== apiId) {
						if (report) console.log('not latest API call. Aborting...', apiId);
						return;
					}
					
					if (error || !editCart) {
						if (report) console.log('editCart error', error, editCart);
						cb(new Error());
						return;
					}
					
					editCart.items = parser(editCart.items);
					
					this.saveLocalCart(editCart);
					this.setCart(editCart);
					cb();
					
				};
				
				this.setState({
					apiId: apiId
				}, () => {
					graph({query, variables, cb: graphCb});
				});
				
			},
			
			
			/**
			 * Calls createCart Graph
			 * Calls saveLocalCart
			 * Calls setCart
			 *
			 * @param items
			 * @param cb
			 */
			createRemoteCart: function ({items, cb = noop}) {
				
				
				const query = `mutation($cart:cartInput){createCart(cart:$cart){cartId,subtotal,items,fp}}`;
				const variables = {
					cart: {
						items: JSON.stringify(items)
					}
				};
				
				const graphCb = (error, {createCart}) => {
					
					
					if (error || !createCart) {
						cb(new Error());
						return;
					}
					
					createCart.items = parser(createCart.items);
					
					this.setState({
						cartId: createCart.cartId,
						fp: createCart.fp
					});
					
					this.saveLocalCart(createCart);
					this.setCart(createCart);
					cb();
					
				};
        
        window.loadFingerprint().then( hash => {
          variables.cart.fp = hash;
          graph({query, variables, cb: graphCb});
        });
				
				// new Fingerprint2().get((hash) => {
				// 	variables.cart.fp = hash;
				// 	graph({query, variables, cb: graphCb});
				// });
			},
			
			
			/**
			 * Calls saveCartToLocalStorage
			 * Calls saveCartIdToCookie
			 * @param cart
			 */
			saveLocalCart: function (cart) {
				this.saveCartToLocalStorage(cart);
				this.saveCartIdToCookie(cart.cartId);
			},
			
			
			/**
			 * Checks hasLocalStorage and saves cart
			 * @param cart
			 */
			saveCartToLocalStorage: function (cart) {
				if (hasLocalStorage) {
					localStorage.setItem('cart', JSON.stringify(cart));
				}
			},
			
			
			/**
			 * Saves Cart Id to browser cookie
			 *
			 * @param cartId
			 */
			saveCartIdToCookie: function (cartId) {
				cookie.create({
					name: 'cartId',
					value: cartId,
					expires: 7
				});
			},
			
			
			/**
			 * Dispatch handler for add_to_cart event
			 *
			 * Calls addExistingItemToCart or addNewItemToCart
			 *
			 * @param quickBuy
			 * @param cartItem
			 */
			addToCart: function (quickBuy, cartItem) {
			  
			  const {items} = this.state;
				let alreadyInCart = false;
				
				const cb = (item) => {
					
				  dispatch.send( `added_to_cart_${cartItem.id}` );
					dispatch.send( 'event', {
					  action: quickBuy ? 'Quick Buy' : 'Add to cart',
            payload: {
					    sku: cartItem.sku,
              slug: cartItem.slug,
              title: cartItem.title,
              price: cartItem.price,
              salePrice: cartItem.salePrice,
              onSale: cartItem.onSale,
              quantity: cartItem.quantity,
            }});
          
          if (quickBuy) {
            // gives the dispatcher 100ms to handle the `event` sent above
            setTimeout( () => window.location.assign('/checkout'), 100 );
          }
          
				};
				
				let currentVariant = dispatch.get(`${cartItem.id}-selected-variant`);
				
				if (items.length) {
					
				  items.forEach(item => {
						
						if (item.id === cartItem.id) {
							if (item.variantId) {
								if (item.variantId === currentVariant.id) {
									
									this.addExistingItemToCart(item, cb, quickBuy);
									alreadyInCart = true;
								}
								
							}
							else {
								this.addExistingItemToCart(item, cb, quickBuy);
								alreadyInCart = true;
							}
						}
					});
					
				}
				
				if (!alreadyInCart) {
					cartItem.cartId = `${cartItem.id}${currentVariant ? `~${currentVariant.id}` : ''}`;
					this.addNewItemToCart(cartItem, cb, quickBuy);
				}
				
			},
			
			
			/**
			 * Updates item quantity to existing item in cart
			 * Calls showSnackBar
			 * Calls saveCart
			 *
			 * @param item
			 * @param cb
			 */
			addExistingItemToCart: function (item, cb = noop, quickBuy) {
				
				const {stepper} = this.state;
				
				this.state.items.map((cartItem, index) => {
					
					if (item.cartId === cartItem.cartId) {
						cartItem.quantity += stepper;
					}
				});
				
				item.pricing = buildProductPricingObject(item);
				
				item = this.getFinalPrice(item);
				
				const {items} = this.state;
				
				this.setState({
					stepper: 1
				}, () => {
					this.saveCart(items, cb);
					if (!quickBuy) {
						this.showSnackBar(stepper);
					}
				});
				
			},
			
			getFinalPrice: function (item) {
				let newItem = item;
				
				newItem.finalPrice = newItem.pricing.onSale ? newItem.pricing.salePrice : newItem.pricing.price;
				
				return newItem
				
				
			},
			
			/**
			 * Gets Product Details from Graph
			 * Adds item to cart
			 * Calls showSnackBar
			 * Calls saveCart
			 *
			 * @param cartItem
			 * @param cb
			 */
			addNewItemToCart: function (cartItem, cb = noop, quickBuy) {
				
				const {items, stepper} = this.state;
				
				const productDetailsCb = (error, response) => {
					
					if (error) {
						return;
					}
					
					const {Product} = response;
					const Products = [Product]
					if (!Array.isArray(Products)) {
						return;
					}
					
					
					const item = Products[0];
					const selectedProductVariant = dispatch.get(`${cartItem.id}-selected-variant`);
					
					const activeVariants = dispatch.get(`${ item.id }-selected-variants`);
					
					
					item.quantity = stepper;
					item.price = selectedProductVariant && selectedProductVariant.price ? selectedProductVariant.price : item.price;
					item.onSale = selectedProductVariant && selectedProductVariant.onSale ? selectedProductVariant.onSale : item.onSale;
					item.salePrice = selectedProductVariant && selectedProductVariant.salePrice ? selectedProductVariant.salePrice : item.salePrice;
					item.pricing = selectedProductVariant && selectedProductVariant.pricing ? selectedProductVariant.pricing : buildProductPricingObject(item);
					item.image = selectedProductVariant && selectedProductVariant.img ? selectedProductVariant.img.src : item.image;
					item.finalPrice = item.pricing.salePrice ? item.pricing.salePrice : item.pricing.price;
					item.cartId = cartItem.cartId;
					if (activeVariants) {
						item.variants = [];
						activeVariants.map(variant => {
							item.variants.push(variant.value)
						});
						
					}
					
					if (selectedProductVariant) {
						item.variantId = selectedProductVariant.id;
					}
					
					items.push(item);
					
					this.setState({
						stepper: 1
					}, () => {
						this.saveCart(items, cb);
						if (!quickBuy) {
							this.showSnackBar(stepper);
						}
					});
					
					
				};
				
				this.getProductDetailsForCart(cartItem.id, productDetailsCb);
				
			},
			
			/**
			 * Clears local storage
			 * Deletes cart cookie
			 **/
			
			emptyCart: function () {
				if (hasLocalStorage) {
					localStorage.removeItem('cart');
				}
				cookie.remove({
					name: 'cartId',
					path: '/',
					domain: ''
				})
				
				
			},
			
			/**
			 * Fetch Product Details from Graph
			 *
			 * @param id - Product ID
			 * @param cb - Graph API callback
			 */
			getProductDetailsForCart: function (id, cb) {
				
				const query = `query($id:ID!){Product(id:$id){id,brand,price,onSale,salePrice,id,title,image,sku,slug}}`;
				const variables = {id: id};
				
				
				graph({query, variables, cb});
				
			},
			
			
			/**
			 * Dispatches snack_bar_alert event for adding item(s) to cart
			 *
			 * @param quantity
			 */
			showSnackBar: function (quantity) {
				/** launches snack bar **/
				dispatch.send('snack_bar_alert', {
					alert: <div>
						<p className="add-to-cart-paragraph">{`Added (${quantity}) Item${quantity > 1 ? 's' : ''} To Cart`}</p>
						<a href={`${siteLink}/cart`} className="add-to-cart-link">VIEW CART</a>
					</div>,
					type: 'success',
					timeout: '4000'
				});
			},
			
			
			/**
			 * Dispatch handler for remove_cart_item event
			 *
			 * Removes item from cart
			 * Calls saveCart
			 *
			 * @param item
			 */
			removeItemFromCart: function (item) {
				
				console.log('the-cart::removeItemFromCart', item);
				
				const items = this.state.items.filter((cartItem) => {
					return item.cartId !== cartItem.cartId;
				});
				
				this.saveCart(items);
				
			},
			
			/**
			 * Dispatch handler for remove_change_alert event
			 *
			 * Change alert from item
			 * Calls saveCart
			 *
			 * @param item
			 */
			removeChangeAlert: function (item) {
				
				console.log('the-cart::removeChangeAlert', item);
				
				let items = this.state.items;
				
				items.map((cartItem, index) => {
					if (item.cartId === cartItem.cartId) {
						delete cartItem['changes'];
						items[index] = cartItem;
					}
					
				});
				
				this.saveCart(items);
				
			},
			
			
			/**
			 * Dispatch handler for item_quantity_change event
			 *
			 * Calls saveCart
			 *
			 */
			updateCartItemQuantity: function (increment, item) {
				let items = this.state.items;
				
				items.map((cartItem, index) => {
					if (item.cartId === cartItem.cartId) {
						if (increment) {
							items[index].quantity++
						}
						else {
							items[index].quantity--
						}
					}
					
				});
				
				this.saveCart(items);
			},
			
			/**
			 * Dispatch handler for stepper_adjusted event
			 *
			 * Updates stepper value
			 */
			changeItemQuantity: function (quantity) {
				this.setState({stepper: quantity});
			},
			
			
			/**
			 * Sends out event to update cart when cart is loaded.
			 */
			broadcastCart: function (cartItems) {
				
				const {items, cartId} = this.state;
				dispatch.send('full_cart', {items, cartId});
				dispatch.send('cart', cartItems);
			},
			
			
			/**
			 * Dispatch handler
			 *
			 * Calls broadcastCart()
			 */
			handleCartRequest: function () {
				if (report) console.log('the-cart::handleCartRequest');
				this.broadcastCart(this.state.items);
			},
			
			
			/**
			 * Inferno components do not need to render anything to DOM
			 * They still do their job :)
			 *
			 * @returns null
			 */
			render: function () {
				return null;
			}
			
		});
		
		Inferno.render(<TheCart />, root);
		
	};
	
};

export default Script;


///52045bb905
