import { StyleSheet } from "@convertly/css";
import Typography from '../../elements/style-functions/typography';
import ButtonGlobal from '../../elements/buttons/button-global-styles';
import UserOverrides from '../../elements/style-functions/user-overrides';
import removeNestedUserOverrides from "../../site-header/site-header/utils/removeNestedUserOverrides";
import _ from 'lodash';

const newsletterSignupStyle = ( global, data, buttonType, buttonOverrides, inEditor ) => {

    const newsletter = removeNestedUserOverrides({ data });

    const { userOverrides } = data;

    const {
      newsletterSignupFormInput,
      newsletterSignupForm
    } = _.isObject(userOverrides) ? userOverrides : {};

    const newsletterUserOverrides = !_.isEmpty(newsletter) ? UserOverrides(global, { userOverrides: newsletter }, inEditor) : {};
    const newsletterSignupFormInputUserOverrides = _.get(newsletterSignupFormInput, 'userOverrides') ? UserOverrides(global, newsletterSignupFormInput, inEditor) : {};
    const newsletterSignupFormUserOverrides = _.get(newsletterSignupForm, 'userOverrides') ? UserOverrides(global, newsletterSignupForm, inEditor) : {};


    let ButtonOverrides = {};

    if (buttonOverrides) {
        ButtonOverrides = UserOverrides(global, { userOverrides: { ...buttonOverrides } } );
    }

    /** Hack for convertly Site */

    return StyleSheet.create({
        newsletterWrapper: {
            position: 'relative',
            margin: "0 auto",
            overflow: "hidden",
            width: "100%",
            ".ty": {
                fontSize: '1.1em',
                transition: 'all 750ms ease',
                position: 'absolute',
                top: '0%',
                left: '120vw',
                transform: 'translate( -50% )',
                overflow: "hidden",
                backgroundColor: global.colors.light.color1,
                padding: "10px 25px",
                borderRadius: "10px",
                color: global.colors.dark.color1,
                fontFamily: global.fontFamily.secondaryFont,
                "@media (max-width: 600px)": {
                    width: "90"
                },      
                ".in": {
                    left: "50%"
                }
            },
            "@media (max-width: 425px)": {
                width: "100%"
            }
        },
        newsletterSignupForm: {
            marginTop: '4px',
            position: 'relative',
            transition: 'all 750ms ease',
            left: 0,
            ".flyout": {
                left: '-900px',
                opacity: '0'
            },
            ...newsletterSignupFormUserOverrides
        },
        newsletterSignupFormInput: {
            width:'50%',
            padding:'9px',
            margin:'10px 0',
            border:global.border,
            color:global.colors.dark.color2,
            fontSize:'16px',
            background:global.colors.light.white,
            transition: "background 100ms linear",
            ":placeholder": {
                color:global.colors.dark.color3,
            },
            ':focus': {
                outline:global.colors.accent.color1
            },
            '@media (max-width:991px)': {
                width:'90%'
            },
            ".disabled ": {
                backgroundColor: "rgba( 50, 50, 50, .25)"
            },
            ...newsletterSignupFormInputUserOverrides
        },
        newsletterSignupFormSubmit: {
            position: 'relative',
            display: 'inline-block',
            fontSize: '16px',
            background: global.colors.dark.color3,
            padding: '9px 35px',
            border: '2px solid',
            borderColor: global.colors.dark.color3,
            transition: 'all 0.3s linear 0s',
            fontWeight:700,
            color: global.colors.light.color1,
            ':hover': {
              opacity:'.7'
            },
            '@media (max-width:991px)': {
              width:'90%',
              display:'block',
              margin:'0 auto'
            },
            ".disabled": {
              ":hover": {
                opacity: '1'
              }
            },
            ...ButtonOverrides,
        }
    })
};
export default newsletterSignupStyle;
