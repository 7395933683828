/**
 * This function is used in pegasus-renderer and the cms-app
 */

import {colorAssign} from './assign-colors';
import {handleTextElements} from './handle-text-elements';
import {handleFormElements} from './handle-form-elements';
import {handleButtonElements} from './handle-button-elements';

export function generateGlobalObject(global) {

    global = colorAssign(global);

    global = handleTextElements(global);

    global = handleFormElements(global);

    global = handleButtonElements(global);


    return global;

}
