/*global Inferno, $, React */
/** Box Selector Product Variant Widget **/
import React from 'react';
const Script = (convertToCurrency) => {
	
	return (props) => {

		return (
			<div className={ `${props.rootClass} price-slider` }>
				<div className={ `${props.rootClass} prices` }>
					<div className={ `${props.rootClass} price-slider-price min` }>
						<span id="minPrice">{
							props.currentMinPrice ?
								convertToCurrency(props.currentMinPrice) :
								convertToCurrency(props.minPrice) }
						</span>
						-&nbsp;</div>
					<div className={ `${props.rootClass} price-slider-price max` }>
						<span id="maxPrice">{
							props.currentMaxPrice ?
								convertToCurrency(props.currentMaxPrice) :
								convertToCurrency(props.maxPrice) }
						</span>
					</div>
				</div>
        <div 
          className={ `${props.rootClass} price-slider-clear-button ${ props.startMinPrice != props.currentMinPrice || props.startMaxPrice != props.currentMaxPrice ? 'active' : '' }` }
          onClick={ props.clearMinMaxPrice }>
          Clear</div>
				<div className={ `${props.rootClass} p-s-c`}
						 data-pricecontainer="true">
					<input type="range"
								 min={ props.startMinPrice }
								 max={ props.startMaxPrice }
								 value={ props.currentMinPrice || props.minPrice }
								 onInput={ props.updateMinPrice }
								 onChange={ props.addMinPriceFilter }
								 className={ `${props.rootClass} min-price`}/>
					<input type="range"
								 min={ props.startMinPrice }
								 max={ props.startMaxPrice }
								 value={ props.currentMaxPrice || props.maxPrice }
								 onInput={ props.updateMaxPrice }
								 onChange={ props.addMaxPriceFilter }
								 className={ `${props.rootClass} max-price`}/>
				</div>
			
			</div>
    );
	}
};

export default Script;
