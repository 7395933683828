import React from "react";
import { fromJS, List, Map } from "immutable";
import styled, { keyframes, css } from "styled-components";
import { NOTIFICATIONS } from "app/config";

const calculateBackground = type => {
  switch (type) {
    case "warning":
      return "rgba( 255, 255, 0, .80 )";

    case "danger":
      return "rgba( 255, 0, 0, .80 )";

    case "success":
      return "rgba( 0, 255, 0, .80 )";

    default:
      return "rgba( 100, 100, 100, .80)";
  }
};

/**
 * Positioned the bar just below the app header
 */
const NotificationBar = styled.div`
  position: absolute;
  top: calc(3em + 15px);
  right: 1em;
  z-index: 3500;
  width: 350px;
`;

const enter = keyframes`
	
	from {
		opacity: 0;
		transform: translateY( 50px );
	}
	
	to {
		opacity: 1;
		transform: translateY( 0 );
	}

`;

/**
 *
 * @param height comes from the height of the NotificationItem DOM element
 * @returns {string}
 */
const exit = height => keyframes`
	
	from {
		opacity: 1;
		transform: translateY( 0 ) scaleY( 1 );
		margin-bottom: 0;
	}
	
	to {
		opacity: 0;
		transform: translateY( -70px ) scaleY( 0 );
		margin-bottom: -${height}px;
	}

`;

const ItemTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
`;

const ItemMessage = styled.div`
  font-size: 14px;
`;

const IconWrapper = styled.div`
  i {
    font-size: 45px;
    color: ${({ type }) => {
      switch (type) {
        case "warning":
          return "rgba( 0, 0, 255, .25 )";
        case "danger":
          return "rgba( 0, 0, 0, .75 )";
        default:
          return "rgba( 0, 0, 0, .75 )";
      }
    }};
  }
  align-self: start;
`;

const ContentWrapper = styled.div``;

const Icon = ({ icon, spin }) => <i className={`fa ${spin && "fa-spin"} fa-${icon}`} />;

const CloseButtonIcon = styled.i`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const CloseButton = props => <CloseButtonIcon {...props} className={"fa fa-times"} />;

const NotificationItemWrapper = styled.div`
  animation: ${({ transition, height }) => {
    if (transition === "enter") {
      return css`
        ${enter} ${NOTIFICATIONS.TRANSITION}ms linear forwards
      `;
    }
    if (transition === "exit") {
      return css`
        ${exit(height)} ${NOTIFICATIONS.TRANSITION}ms linear forwards
      `;
    }
    return "none";
  }};
  width: 100%;
  transform-origin: top;
  background-color: ${props => calculateBackground(props.type)};
  padding: 10px 20px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  margin-bottom: 5px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  position: relative;
`;

class NotificationItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 0,
    };
  }

  /**
   * We use clientHeight to set the margin-bottom transition in the exit animation
   */
  componentDidMount() {
    this.setState({
      height: this.ref.clientHeight,
    });
  }

  render() {
    const { title, type, transition, icon, spin, id, clear } = this.props;

    let message = this.props.message;

    try {
      if (Array.isArray(message)) {
        message = message.join("");

        if (message === "[object Object]") {
          message = JSON.stringify(this.props.message);
        }
      }

      if (List.isList(message)) {
        message = message.toJS().join("");
      }
    } catch (e) {
      message = "";
    }

    return (
      <div ref={ref => (this.ref = ref)}>
        <NotificationItemWrapper
          height={this.state.height}
          icon={icon}
          transition={transition}
          type={type}
        >
          <CloseButton onClick={async () => await clear(id)} />
          <IconWrapper type={type}>
            <Icon icon={icon} spin={spin} />
          </IconWrapper>
          <ContentWrapper>
            <ItemTitle>{title}</ItemTitle>
            <ItemMessage>{message}</ItemMessage>
          </ContentWrapper>
        </NotificationItemWrapper>
      </div>
    );
  }
}

NotificationItem.defaultProps = {
  title: "",
  message: "",
  type: "",
  transition: "",
  icon: "",
  spin: false,
  id: "",
  clear: () => {},
};

export const Notifications = ({ notifications, clear }) => (
  <NotificationBar id={"NotificationsContainer"}>
    {notifications.map(n => (
      <NotificationItem clear={clear} {...n.toJS()} key={n.get("id")} />
    ))}
  </NotificationBar>
);

Notifications.defaultProps = {
  notifications: fromJS([]),
};

Notifications.propTypes = {};
