import { StyleSheet } from "@convertly/css";
import Typography from '../../../../elements/style-functions/typography';
import ModuleStyles from '../../../../elements/style-functions/module-global-styles';

const HeaderStyles4 = ( global, data ) => {

    let modifiedColor;
    let overlayBorderStyle = {};
    let overlayFixedNavLink = {};

        const caret = {
            display: "inline-block",
            height: '17px',
            width: '17px',
            cursor: 'pointer',
            boxSizing: 'content-box',
            marginTop: '-6px',
            marginLeft: '5px',
            stroke: 'inherit',
            position:'absolute',
            top:'27px',
            right:'-13px',
            '@media( max-width:1201px )': {
                top:'34px',
                right: '-17px'
            }

        };

        const navListItem = {
            display:'inline-block',
            cursor:'pointer',
            padding:'24px 5px 0 5px',
            fontSize: '16px',
            stroke: 'inherit',
            position:'relative',
            margin: "0px 5px",
            "@media (max-width: 991px)": {
                padding: "0px"
            }

        };
        const dropdown= {
            float:'right',
            listStyle: 'none',
            height: 'auto',
            width:'fit-content',


        };
        const dropdownList = {
            position:'absolute',
            top:'140%',
            right:'-34px',
            background:global.colors.light.white,
            boxSizing:'content-box',
            listStyle: 'none',
            padding:'20px 20px 8px',
            minWidth:'270px',
            zIndex:'1',
            '.open': {
                display:'block'
            },
            '@media( max-width:991px )': {
                top: '205%',
                right: '3px'
            },
            '@media( max-width:420px)': {
                right:'0'
            }

        };




    return StyleSheet.create({
        menuContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: "column",
            padding:'0px 0px 25px',
            backgroundColor: global.colors.dark.black,
            borderRadius:'1px',
            fill: modifiedColor,
            color:modifiedColor,
            ...overlayBorderStyle
        },
        logo: {
            float: 'left',
            maxWidth:'180px'

        },
        logoImg: {
            width: 'auto',
            maxHeight: "45px",  
            '.absolute': {
                display:'block'
            },
            '.default': {
                display:'block'
            }

        },
        menuLauncherIcon: {
            display: 'none',
            order: 2,
            float:'right',
            marginRight:"15px",
            marginTop: '17px',
            '@media( max-width:991px )': {
                display: "block"
            },
            '@media( max-width:768px )': {
                marginRight:'0'
            }


        },
        navLaunch: {
            zIndex:45,
            display: "none",
            paddingBottom: 0,
            overflow: "visible",
            background:"none",
            border:"none",
            outline: "none",
            '@media( max-width:991px )': {
                display:'block'
            }
        },
        iconBar: {
            backgroundColor:modifiedColor,
            width: "20px",
            height: "3px",
            marginBottom: "3px",
            position: "relative",
            display: "block",
            zIndex:50,
            transition: 'all 0.3s ease-in-out 0s',
            ':nth-child(3)': {
                width:"16px"
            },
            ".absolute": {
                backgroundColor: global.colors.light.white,

            },
            '.open': {
                ":nth-child(2)":{
                    transform:"rotate(45deg)",
                    top:"7px",
                    background:global.colors.dark.color1
                },
                ":nth-child(4)": {
                    transform:"rotate(-45deg)",
                    top:"-5px",
                    background:global.colors.dark.color1
                },
                ":nth-child(3)": {
                    opacity:0
                }
            },
        },

        logoImgMobile: {
            padding: "30px 0px 15px",
            fill: "white"
        },

        mainMenu: {
            zIndex: 45,
            alignSelf:'baseline',
            margin: "0 auto",
            width:'fit-content',
            '@media (max-width: 991px)': {
                marginTop:'5px',
            },
            '@media( max-width:1065px )': {
                marginTop:'-2px'
            },
            '.open': {
                display: 'block',
                visibility:'visible',
                left: '0',
            },
        },
        headerNav: {
            display:'inline-block'
        },
        navList: {
            float:'right',
            listStyle: 'none',
            height: 'auto',
            width:'fit-content',
            '@media ( max-width:991px )': {

            },
            '.open':{
                top:0,
                zIndex:2,
                minHeight:'80px',
                '@media( max-width:991px )': {
                    borderBottom: '3px solid',
                    borderColor:global.colors.dark.color1,
                }
            }
        },
        navListItem: {
            borderBottom: "3px solid transparent",
            color:global.colors.light.white,
            ...navListItem,
            ".active": {
                borderBottom: "3px solid",
                borderColor:global.colors.light.white,
                transition: "100ms ease"
            },
            ":hover": {
                "@media (min-width: 991px)": {
                    borderBottom: "3px solid",
                    transition: "100ms ease"
                }
            },
            '@media ( max-width:320px )': {
                display: "block",
                paddingTop:'4px',
                paddingBottom:'4px',
                margin: "10px auto"
            },
        },

        contactNavListItem: {
            ...navListItem,
            padding:'24px 10px 0 10px',
            '@media( max-width: 991px )': {
                marginTop:'-6px',
                textDecoration: 'underline'
            },
            '@media( max-width: 991px )': {
                marginTop:'10px',
                textDecoration: 'underline'
            },
        },
        caret: {
            ...caret,
            '@media( max-width: 991px )': {
                stroke:global.colors.dark.color1
            }
        },
        contactCaret: {
            ...caret,
            '@media( max-width: 991px )': {
                visibility:'hidden'
            }
        },
        dropdown: {
            ...dropdown,
            '@media( max-width:991px)': {
                float:'none',

            }

        },
        contactDropdown: {
            ...dropdown
        },
        dropdownList: {
            ...dropdownList,
            '@media( max-width:991px )': {
                paddingTop:'20px',
                position:'static'
            },

        },
        contactDropdownList: {
            ...dropdownList,

        },
        dropDownItem: {
            display: 'none',
            position:'absolute',
            top:'140%',
            right:'0',
            width:"200px",
            background:'#fff',
            listStyle: 'none',
            padding:'10px',
            marginBottom:'20px',
            '.open': {
                display:'block'
            }

        },
        contactListHeader: {
            display:'block',
            width:'50%',
            borderBottom:'1px solid',
            paddingBottom:'12px',
            color:global.colors.dark.color1,
            borderColor: global.colors.dark.color1,
            textTransform: 'uppercase',
            fontSize:'14px',
            fontWeight:'bold'
        },
        arrowUp: {
            width: 0,
            height: 0,
            position:'absolute',
            top:'-10px',
            right:'20px',
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderBottom: '10px solid',
            borderBottomColor: global.colors.light.white
        },
        downNavItem: {
            color: global.colors.dark.color1,
            //fill: global.colors.dark.color1,
            margin:'20px 0',
            ':hover': {
                opacity:'.8'
            },

        },
        dropdownIconContainer: {
            fill:global.colors.dark.color1,
            display:'inline-block',
            width:'10%',
            verticalAlign:'middle'
        },
        menuIcon: {
            width:'18px',
            height:'18px',


        },
        contactLinkContainer: {
            display:'inline-block',
            marginLeft:'5px',
            width:'85%',
            verticalAlign:'middle'
        },
        contactDownNavText: {
            fontSize:'12px',
            lineHeight:'12px',
            padding:'10px 12px 10px 5px',

        },
        dropDownlink: {
            fontSize: '15px'
        },

        navLinkContainer: {
            display: "inline-block"
        }

    });

};

export default HeaderStyles4
