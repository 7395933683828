import {StyleSheet} from "@convertly/css";


const customerReviewStyles = (global, data) => {

	
	
	return StyleSheet.create({
		customerReview: {
			border: "1px solid",
			borderColor: global.colors.light.color3
		},
		customerRatingDiv: { 
			borderBottom: "1px solid",
			borderColor: global.colors.light.color3,
			position: "relative",
			padding: "15px 100px 15px 10px"	
		},
		customerImg: {
			float: "left",
			display: "block",
			height: "40px",
			maxWidth: "40px",
			objectFit: "cover",
			objectPosition: "center center",
			marginRight: "15px",
			textAlign: "left"
		},
		customerName: {
			fontFamily: global.fontFamily.primaryFont,
			color: global.colors.dark.color3,
			float: "left",
			fontSize: "15px",
			lineHeight: "40px"
		},
		customerStars:{
			float: "right",
			position: "absolute", 
			right: "15px",
			top: "27px"	
		},
		clear: {
			clear: "both"
		},
		star:{
			height: "15px",
			width: "15px",
			display: "inline"
		},
		starImg: {
			height: "15px",
			width: "15px"
		},
		reviewTextDiv: {
			padding: "20px 15px"
		},
		customerText: {
			fontFamily: global.fontFamily.secondaryFont,
			color: global.colors.dark.color3,
			fontSize: "15px"
		}
	})
};

export default customerReviewStyles;
