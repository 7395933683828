import React from "react";
import { StyleSheet, css } from "@convertly/css";
import Component from "../rootComponent";
import { id } from "@convertly/thor";
import _ from "lodash";

import { generateId } from "../utils/generate-id";
import { generateStyle } from "source/utils/generate-attribute";
import { generateDataAtttribute } from "../utils/generate-attribute";
import { sortByKeys } from "../utils/sort-by-key";

import ReactHTMLParser from "@convertly/react-html-parser";

//Styles
import sliderStyles from "./convertly-slider-styles";

//Scripts
import sliderScript from "./scripts/slider-script-01";

//Component
export default class SliderContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlide: this.props.data.activeSlide || 0
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data.activeSlide !== undefined) {
      this.setState({ activeSlide: nextProps.data.activeSlide });
    }
  }

  render() {
    let inEditor = this.inEditor();

    /** Generates border in editor */
    const inlineStyle = this.generateStyleAttribute();

    /** inits stylesheet **/
    this.styles = sliderStyles(this.props.global, this.props.data);

    function renderSliderChildren(props, state) {
      return React.Children.map(props.children, child => {
        return React.cloneElement(child, {
          moduleId: props.moduleId || props.id,
          moduleSort: props.moduleSort,
          sliderSettings: props.data,
          activeSlide: state.activeSlide,
          inEditor: inEditor
        });
      });
    }
    return (
      <div
        id={id(sliderScript)}
        className={css(this.styles.convertlySlider)}
        {...this.generateDataEditorAttribute()}
        {...this.generateCustomAttributes()}
        {...inlineStyle}
      >
        {renderSliderChildren(this.props, this.state)}
      </div>
    );
  }
}
