import React from 'react';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import defaultGlobal from '../../../themes/global.js';
import ReactHTMLParser from "@convertly/react-html-parser";

// Styles
import checkboxInputStyleSheet from './checkbox-input-styles.js';


export default class CheckboxInput extends Component {
	constructor(props){
		super(props);
	}

	render(){
		this.styles = checkboxInputStyleSheet(this.props.global, this.props.data);

		let className;
		const aphroditeClass = css(this.styles.checkbox);
		className +=aphroditeClass;	

		

	return(

		<label className={css(this.styles.checkboxLabel)}>
			
			<input className={ css( this.styles.checkboxInput)} type="checkbox"/>
			{ this.props.data.label }
		</label>
		)
	}
};

CheckboxInput.defaultProps = {
	global: defaultGlobal,
	data: {}
}



