import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { fromJS } from "immutable";

import { toggleMainMenu, setMainMenu } from "containers/App/actions";
import { menuItems } from "./menuItems";
import { MenuItem } from "./components/MenuItem";

import { pkg, uiPkg } from "app";

const MenuContainer = styled.div`
  width: 160px;
  position: absolute;
  @media (max-width: 700px) {
    left: ${props => (props.isMenuOpen ? 0 : "-100vw")};
    width: 100vw;
  }
  left: ${props => (props.isMenuOpen ? 0 : "-13em")};
  top: 42px;
  z-index: 2000;
  transition: all 150ms ease;
  @media print {
    display: none;
  }
`;

const MenuWrapper = styled.ul`
  list-style: none;
  margin-top: 0 !important;
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgba(65, 65, 65, 0.25);
  padding-top: 0px;
  padding-left: 0;
  height: 100vh;
  width: 100%;
`;

const AppVersion = styled.span`
  position: absolute;
  bottom: 55px;
  left: 20px;
  color: #aaa;
  user-select: none;
`;

class MainMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // reference for index of active menu item
      activeMenu: null,
    };

    this.setActiveMenu = this.setActiveMenu.bind(this);
  }

  setActiveMenu(menu) {
    this.setState({
      activeMenu: menu,
    });
  }

  isActiveMenu(url) {
    return this.props.location === url
  }

  render() {
    if (!this.props.Auth.get("setupComplete") || this.props.bannerEditorIsOpen) return null;

    const { activeMenu } = this.state;
    const site = this.props.site ? this.props.site : fromJS({});

    return (
      <MenuContainer id={"MenuContainer"} isMenuOpen={this.props.isMenuOpen}>
        <AppVersion>
          app v{pkg.version}
          <br />
          ui v{uiPkg.version}
        </AppVersion>

        <MenuWrapper id={"MenuWrapper"}>
          {menuItems.map((item, index) => {
            const props = {
              setActiveMenu: this.setActiveMenu.bind(this, item),
              active: this.isActiveMenu(item.url),
              item: item,
              site: site,
            };
            return <MenuItem key={index} user={this.props.Auth.get("user")} {...props} />;
          })}
        </MenuWrapper>
      </MenuContainer>
    );
  }
}

MainMenu.defaultProps = {
  site: fromJS({}),
};

MainMenu.propTypes = {
  setMainMenu: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    Auth: state.get("auth"),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setMainMenu: active => dispatch(setMainMenu(active)),
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainMenu);

export default Container;
