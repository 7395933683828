import React from 'react';
import {css} from "@convertly/css";
import Component from '../../../rootComponent';
import {id} from '@convertly/thor';
import ReactHTMLParser from "@convertly/react-html-parser";
import { clientImage } from '../../../utils/generateImage';
import { generateLink } from '../../../utils/generateLink';
import Promise from 'bluebird';
import { frontloadConnect } from '@convertly/react-frontload';
import axios from 'axios';
import config from '../../../config';

//Default Props
import defaultGlobal from '../../../themes/global'
import defaultData from '../product-gallery-default-data';

import ConvertlyTabsHandler from '../scripts/tabs-handler';


//Styles
import productGalleryStyles from './product-gallery-styles-01';

const products = [];

const loadProductsAsync = ( props, component ) => {

	return new Promise( ( resolve, reject ) => {

		products.length = 0;

		const query = `
		query( $id:ID!){
		  Products( siteId: $id, showHidden: false ) {
			id, slug, pricing { price }, title,
			manufacture_details { manufacturer, model_number }, images
		  }
		}`;

		const variables = { id: props.site.siteId };

		const params = {
			url: 'https://graph.convertly.com',
			method: 'post',
			data: {
				query: query,
				variables: variables
			},
			headers: {
				Authorization: 'convertly-front-load',
			}
		};

		axios( params )
			.then( ( res ) => {

				const returnedProducts = res.data.Products;

				returnedProducts.length = 6;

				returnedProducts.map( ( obj ) => {
					products.push( obj );
					if( component ) {
						component.props.data.products.push( obj );
					}

				});
				if( component ) {
					component.forceUpdate();
				}
				resolve();
			})
			.catch( ( err ) => {
				console.log( err );
				resolve();
			});

	});
};

const frontload = ( props ) => loadProductsAsync( props );

const options = {
	noServerRender: false,
	onMount: true,
	onUpdate: true
};

class ProductGallery01 extends Component {

	constructor(props) {
		super(props);

		this.changeTab = this.changeTab.bind( this );
	}

	changeTab(e) {
	}

	componentDidMount() {
		return loadProductsAsync( this.props, this );
	}

	render() {
		this.styles = productGalleryStyles(this.props.global, this.props.data);

		return (
			<div id="filteration" className={ css(this.styles.productGalleryContainer) }>
				<div className="container">

					<div className={ css(this.styles.productGalleryHeader) } id="portfolio_links">
						<strong>
							<h3 className={ css(this.styles.productGalleryHeading) }>See our latest fashions</h3>
						</strong>
						<ul style={ { display: 'none' } } id={ id(ConvertlyTabsHandler) }
							className={ css(this.styles.productGalleryHeaderList) }>
							<li onClick={ this.changeTab }
								className={ css(this.styles.productGalleryHeaderListItem) + " active"}
								data-target="#section1"
							>Spring Collection
							</li>
							<li onClick={ this.changeTab }
								className={ css(this.styles.productGalleryHeaderListItem) }
								data-target="#section2">Black Tie
							</li>
							<li onClick={ this.changeTab }
								className={ css(this.styles.productGalleryHeaderListItem) }
								data-target="#section3">Featured Items
							</li>
						</ul>
					</div>
				</div>
				<div className="container">
					<div id="section1" className={ css(this.styles.productGalleryBody) + " active tab" }>


						{ products.map( ( product, index ) => {

							const price = product.pricing.price;
							const brand = product.manufacture_details.manufacturer;
							const { title } = product;
							const url = {
								linkType: 'internal',
								url: product.slug
							};

							return (

								<div key={ index } className={ css(this.styles.productBox3Col) }>
									<div className={ css(this.styles.productBoxWrapper) }>
										<img
											src={ clientImage( product.images[ 0 ], { width: 300, height: 300 } ) }
											alt="" className={ css(this.styles.productImg) }/>
										<div className={ css(this.styles.productDescriptionWrapper) }>
											<h6 className={ css(this.styles.productDescriptionCallOut) }>
												<a style={ { display: 'none' } } href="http://www.magicdragons.xyz/product-page">
													<strong>
														<em>
															ON SALE
														</em>
													</strong>
												</a>
											</h6>
											<div className={ css(this.styles.productDescription) }>
                                        <span
											className={ css(this.styles.productDescriptionSubTitle) }>{ brand }</span>
												<h4 className={ css(this.styles.productTitle) }>{ title }</h4>
												<h5 style={ { display: 'none' } } className={ css(this.styles.fullPrice) }>${ price }</h5>
												<strong className={ css(this.styles.salePrice) }>${ price }</strong>
											</div>
											<a href={ generateLink( url ) } className={ css(this.styles.quickBuy) }>Quick Buy</a>
										</div>
									</div>
								</div>

							);

						} ) }


					</div>
				</div>
			</div>


		)
	}
}

ProductGallery01.defaultProps = {
	global: defaultGlobal,
	data: defaultData
};

ProductGallery01.displayName = 'ProductGallery01';

export default frontloadConnect( frontload, options )( ProductGallery01 );
