import React from 'react';
import Component from 'source/rootComponent';
import {css} from '@convertly/css';
import _ from 'lodash';

// Utils
import {generateLink} from '../../../utils/generateLink';

// Components

// Styles
import navStylesTopLeft from '../styles/navStylesTopLeft';
import {clientImage} from '../../../utils/generateImage';

export class NavTopLeft extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const {global, data, site, inEditor} = this.props;
        const {showLogo, useTextLogo, logoLinkToHomePage} = data;
        const {alt, title} = site;

        const textLogoText = _.get(data, 'textLogo.text') || '';
        const imgSrc = _.get(site, 'logo.src') || '';

        this.styles = navStylesTopLeft(global, data, inEditor);

        const {
            textLogo,
            imgLogo,
            logo,
            dot,
            mainNavLeft
        } = this.styles;

        // Minor JSX components

        const logoText = (
            <div className={css(textLogo)}>
                {textLogoText.toUpperCase()}
                {/* { useTextLogo && textLogoText && <span className={ css(dot) }/> } */}
            </div>
        );

        const logoImage = (
            <img
                src={clientImage(imgSrc, {})}
                data-logo
                alt={alt}
                title={title}
                className={css(imgLogo)}/>
        );

        // Helper functions

        /**
         * @summary Generates an object for generateLink function
         * @param { String } logoType
         * @returns { Object }
         */
        const generatedLinkObject = (logoType) => {
            return {
                url: _.get(data, `${logoType}.url`) || '/',
                linkType: _.get(data, `${logoType}.linkType`) || 'internal'
            }
        };

        /**
         * @summary Generates either text or an image
         * @return JSX
         */
        const generateLogoType = () => {
            if (useTextLogo && textLogoText) {
                return logoText;
            }
            if (imgSrc) {
                return logoImage;
            }
            return null;
        };

        return (
            <div className={css(mainNavLeft)}>
                {
                    showLogo &&
                    <div className={css(logo)}>
                        {
                            logoLinkToHomePage ?
                                <a href={generateLink(generatedLinkObject())}
                                   onClick={this.cancelClick}>
                                    {generateLogoType()}
                                </a>
                                :
                                <div>
                                    {generateLogoType()}
                                </div>
                        }
                    </div>
                }
            </div>
        )
    }

}
