import React from 'react';
import {StyleSheet, css} from '@convertly/css';


const styles = StyleSheet.create({
  placeholder: {
    height: '150px',
    background: "#F6FDFA",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '18px',
    fontFamily: 'Sans Pro, sans-serif'
  }
})

export const PlaceholderContent = () => (
  <div title={"If no content is added, this message will not display on your website"} className={css(styles.placeholder)}>
    <p>Add Content Here</p>
  </div>
);
