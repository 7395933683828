import {StyleSheet} from "@convertly/css";
import UserOverrides from '../../elements/style-functions/user-overrides';

import {generateHeaderOffsetMediaQueries} from '../header-style-functions/fixed-header-media-queries';
import _ from 'lodash';
import {generatePseudoMediaQuery} from "../../elements/style-functions/pseudo-media-queries";
import {deeplog} from "../../utils/deeplog";


const headerStyles2 = (global, data, headerPosition, inEditor) => {
	
	const headerOverrides = UserOverrides(global, data, inEditor);
	let bodyPaddingMediaQueries = {};
	let positionFixed = data.position === "fixed";
	let displayHeaderTop = _.get(data, 'headerTopperSettings.display');
	let headerHeight = displayHeaderTop ? "calc(100px + 60px)" : _.get(data, 'userOverrides.height') || '100px';

	/** Searches for height in media queries and assigns is
	 to a property that can be passed to height */
	if (data.userOverrides && positionFixed) {
		bodyPaddingMediaQueries = generateHeaderOffsetMediaQueries(data);
	}
	
	
	let headerOffset = {paddingTop: headerHeight};
	let headerStyles = {};
	
	if (inEditor) {
		headerOffset = {}
	}
	
	if (headerPosition === "overlay") {
		headerStyles = {
			position: 'absolute',
			color: global.colors.light.white,
			stroke: global.colors.light.white,
			background: "transparent"
		}
	}
	else if (headerPosition === "overlayFixed") {
		headerStyles = {
			'.absolute': {
				position: 'absolute',
				color: global.colors.light.white,
				stroke: global.colors.light.white,
				background: "transparent"
			}
		}
	}
	else if (headerPosition === "fixed") {
		
		headerStyles = {
			position: 'fixed',
			zIndex: '6',
			'* body': {
				...headerOffset,
				overflowY: "scroll",
				height: '100%',
				margin: 0,
				...generatePseudoMediaQuery(
					'@media(max-width:991px)',
					['largeTablet', 'smallTablet', 'mobile'],
					{
						paddingTop: displayHeaderTop ? "calc(70px + 40px)" : '80px'
					},
					inEditor
				),
				...bodyPaddingMediaQueries
			},
		}
	}
	else {
		headerStyles = {}
	}

	return StyleSheet.create({
		headerWrapper: {
			height: 'fit-content'
		},
		header: {
			width: '100%',
			color: global.colors.dark.color1,
			background: global.bodyBackgroundColor || "white",
			fontFamily: global.fontFamily.primaryFont,
			lineHeight: '26px',
			stroke: global.colors.dark.color1,
			transition: 'all .25s ease',
			height: 'calc(100px + 40px)',
			zIndex: '4',
			top: '0',
			...generatePseudoMediaQuery(
				'@media(max-width:991px)',
				['largeTablet', 'smallTablet', 'mobile'],
				{
					height: _.get(data, 'headerTopperSettings.display') ? '110px' : '70px'
				},
				inEditor
			),
			...headerStyles,
			...headerOverrides
		}
		
		
	})
	
};
export default headerStyles2;
