/*global Inferno, $ */

import {include} from '@convertly/thor';

const Script = () => {
	
	const SearchBarInferno = include('../../../../ecommerce-common-components/search-bar/SearchBar', 'SearchBarInferno');
	const Dispatch = include('../../../../utils/dispatcher', 'Dispatch');
	const Graph = include('../../../../utils/graph', 'Graph');
	const SpinnerSVGInferno = include('../../../../svg/spinner/spinner', 'SpinnerSVGInferno');
	const ProductSearchController = include('../../../../inferno-controllers/product-search-controller', 'ProductSearchController')
	return (id, siteId) => {
		
		const root = document.getElementById(id);
		
		// Utils
		const dispatch = Dispatch(id);
		const graph = Graph();
		// Components
		const Spinner = SpinnerSVGInferno();
		const SearchBar = SearchBarInferno(dispatch, Spinner);
		const productSearchController = ProductSearchController(dispatch, graph);
		const SearchBarWrapper = Inferno.createClass({
			
			getInitialState: function () {
				this.ProductSearchController = new productSearchController(this, id, siteId);
				return {
					searchQuery: ''
				};
			},
			
			clearSearchQuery: function () {
				this.setState({searchQuery: ''});
			},
			
			componentDidMount() {
				this.ProductSearchController.initDispatchers(true)
			},
			
			render: function () {
				return (
					<SearchBar
						rootClass={ root.className }
						searchIconPosition='left'
						searchBarIsExpandable={ false }
						expandSearchBar={ false }
						showSearchIcon={ false }
						showXIcon={ true }
						loadingProducts={ this.state.loadingProducts }
						searchQuery={ this.state.searchQuery }
						liveSearchResults={ this.state.liveSearchResults }
						clearSearchQuery={ this.clearSearchQuery }
						updateSearchQuery={ this.ProductSearchController.updateSearchQuery }/>
				)
			}
		});
		
		Inferno.render(<SearchBarWrapper />, root);
		
	}
};

export default Script;
