import { StyleSheet } from '@convertly/css';

const Styles = ( global, data ) => {

    return StyleSheet.create({

        priceInfoBox: {
            width: "100%",
            height: "390px",
            background: "radial-gradient(circle farthest-corner at 49% 16%, #349886 , #0a328d )",
            display: "flex",
            flexDirection: "column",
            "@media (max-width: 480px)": {
                height: "420px"
            }
        },

        button: {
            display: "inline-block",
            color: "rgba(255,255,255,0.6)",
            fontSize: "28px",
            padding: "10px",
            cursor: "pointer",
            margin: "25px 0px 0px 0px",
            ".active": {
                color: "white"
            }
        },

        priceAnnual: {
            fontSize: "82px",
            color: "white",
            display: "none",
            '.active': {
                display: "block"
            },
            '> .yr': {
                fontSize: "30px"
            }
        },

        priceMonthly: {
            fontSize: "82px",
            color: "white",
            display: "none",
            '.active': {
                display: "block"
            },
            '> .mo': {
                fontSize: "30px"
            }
        },

        heading: {
            color: "white",
            fontSize: "32px",
            fontWeight: "bold",
            margin: "20px 0px 10px 0px"
        },

        paragraph: {
            color: "white",
            textAlign: "center",
            padding: "0px 50px"
        }


    })

};

export default Styles;