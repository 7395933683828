import { createAction } from "redux-act";
import { v1 as uuid } from "uuid";
import { store } from "app/app";
import Logger from "utils/logger";
import {
  getUnclaimedChatThreads,
  getClaimedChatThreads,
  getChatById,
  getHistoryChatThreads
} from "./apiActions";
import { getService } from "./centralActions";

let PubNub;
const stage = "production";

// used in Reducer
export const setUnclaimedChatThreads = createAction("Chat: Set unclaimed chat threads");
export const setClaimedChatThreads = createAction("Chat: Set claimed chat threads");
export const setHistoryChatThreads = createAction("Chat: Set history chat threads");
export const claimChatThreadAction = createAction("CLAIMED_CHAT_THREAD");
export const setActiveChatThreadsAction = createAction("SET_ACTIVE_CHAT_THREADS");
export const addMessageToSendQueueAction = createAction("ADD_MESSAGE_TO_SEND_QUEUE");
export const processSentMessageAction = createAction("PROCESS_SENT_MESSAGE");
export const setUnreadClaimedChats = createAction("SET_UNREAD_CLAIMED_CHATS");
export const setUnreadUnclaimedChats = createAction("SET_UNREAD_UNCLAIMED_CHATS");
export const setActiveChat = createAction("SET_ACTIVE_CHAT");

export function loadHistory() {
  return async dispatch => {
    const history = await getHistoryChatThreads();
    await dispatch(setHistoryChatThreads(history));
  };
}

export function zorkIsTyping() {
  return async dispatch => {
    const payload = {
      action: "zork_is_typing",
      b2c: {
        id: getService()
          .get("activeChat")
          .get("b2cUser")
          .get("id")
      }
    };

    sendPubNubAction(payload);
  };
}

export function sendMessage(message) {
  return async dispatch => {
    const activeChat = store
      .getState()
      .get("chatService")
      .get("activeChat");
    const payload = {
      action: "message_from_zork",
      chatId: activeChat.get("id"),
      text: message,
      b2c: {
        id: activeChat.get("b2cUser").get("id")
      }
    };
    sendPubNubAction(payload);
  };
}

export function activateChatThread(thread) {
  return async dispatch => {
    Logger.chat("activateChatThread");

    const chat = await getChatById(thread.get("id"));
    const activeChat = getService().get("activeChat");
    const zorkId = getZorkUserObject().id;
    const chatId = thread.get("id");

    await zorkReadMessage({ zorkId, chatId });

    if (activeChat.get("id") !== thread.get("id") && activeChat.get("id")) {
      await setZorkOutChatView({ zorkId, chatId: activeChat.get("id") });
    }

    await dispatch(setActiveChat(chat));
    await setZorkInChatView({ zorkId, chatId });
  };
}

export const zorkReadMessage = async ({ zorkId, chatId }) => {
  const payload = {
    action: "zork_read_message",
    zorkId,
    chatId
  };
  return await sendPubNubAction(payload);
};

export const setZorkOutChatView = async ({ chatId, zorkId }) => {
  const payload = {
    zorkId,
    chatId,
    action: "out_chat_detail_view"
  };
  return await sendPubNubAction(payload);
};

export const setZorkInChatView = async ({ chatId, zorkId }) => {
  const payload = {
    zorkId,
    chatId,
    action: "in_chat_detail_view"
  };
  return await sendPubNubAction(payload);
};

export function setPubNub(p) {
  return async dispatch => {
    PubNub = p;
  };
}

export function endChat(thread, e) {
  return async dispatch => {
    e.stopPropagation();

    const message = {
      action: "chat_thread_ended",
      chatId: thread.get("id"),
      zork: getZorkUserObject(),
      b2c: {
        id: thread.get("b2cUser").get("id")
      },
      from: "zork"
    };

    sendPubNubAction(message);

    if (isActiveChat(thread.get("id"))) {
      const activeChat = getService()
        .get("activeChat")
        .set("endedAt", new Date().getTime());
      await dispatch(setActiveChat(activeChat));
    }
  };
}

export function claimChat(thread, e) {
  e.preventDefault();
  e.stopPropagation();

  return async dispatch => {
    const message = {
      action: "claim_chat",
      chatId: thread.get("id"),
      zork: getZorkUserObject()
    };

    sendPubNubAction(message);
  };
}

export function getChatThreads() {
  return async dispatch => {
    await dispatch(getUnclaimedChatThreads());
    await dispatch(getClaimedChatThreads());
  };
}

export const sendPubNubAction = message => {
  message.messageId = uuid();
  message.stage = stage;

  Logger.chat("sending action", message.action);

  return new Promise((resolve, reject) => {
    PubNub.publish(
      {
        message,
        channel: getChannel()
      },
      ({ error, operation, statusCode, ...rest }, response) => {
        if (error) {
          console.log("Publish Error");
          console.log(operation, statusCode, rest, response);
          resolve(false);
        } else {
          resolve(true);
        }
      }
    );
  });
};

export function isActiveChat(id) {
  return (
    getService()
      .get("activeChat")
      .get("id") === id
  );
}

export const getUnclaimedChatsFromStore = () => getService().get("unclaimedChatThreads");

export const getClaimedChatsFromStore = () => getService().get("claimedChatThreads");

export const getZorkUserObject = () => {
  const user = store
    .getState()
    .get("auth")
    .get("user");

  return {
    name: user.get("first_name"),
    id: user.get("id"),
    username: user.get("username"),
    profileUrl: user.get("profileUrl") || "https://placeimg.com/75/75/any"
  };
};

export const getChannel = () => {
  const channel = "central.fb5fe470-0ac9-11e8-8e7c-4fcd83cd4ebb";

  return channel;
};
