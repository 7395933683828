import React from 'react';
import Component from '../../rootComponent';
import { css } from "@convertly/css";

//Utils
import generateId from '../../utils/generate-id';

//Styles
import wrapperDivStyles from './wrapper-div-styles';

export default class WrapperDiv extends Component {
    constructor ( props ) {
        super( props );
    }

    render() {

        const inEditor = this.inEditor();

        this.styles = wrapperDivStyles( this.props.global, this.props.data, inEditor );

        function renderChildren(props) {
            return React.Children.map(props.children, child => {
                return React.cloneElement(child, {
                    moduleId: props.moduleId,
                    moduleSort: props.moduleSort,
                    styles: props.styles
                });
            })
        }

         let className = '';

        let aphroditeClass = css ( this.styles.wrapperDiv );

        if ( this.animatedClassName ) {
            className += " " + this.animatedClassName
        }
        className += aphroditeClass;

        const { animated } = this.props.data
        const inlineStyle = this.generateStyleAttribute( )

        return (

            <div {...generateId ( this.props.elementId )}
                 { ...this.generateCustomAttributes() }
                onClick={( e ) => {
                    this.launchElementalEditor ( 'wrapperDiv', e);
                }}
                onMouseOver={ this.addEditableOutline  }
                onMouseOut={ this.removeEditableOutline }
                { ...inlineStyle }
                data-animated={animated}
                className={ className }
                 { ...this.generateDataEditorAttribute() }
                >
                { renderChildren( this.props ) }
            <div className={ css( this.styles.clearFix ) } />
            </div>

        )
    }
}

