/*global Inferno, $, React */
import React from 'react';
const Script = (SortByDropdownMenu, dispatch, ArrowDownOpen, SortByMobileModal) => {
  return ({
						rootClass,
						viewport,
						sortMeta,
            toggleSwitch,
            toggleSortByMobileModal,
            showSortByMobileModal
          }) => {
    

    function toggleDropdown(target) {
      dispatch.send('toggleDropDown', target);
    }

    if(viewport === 'mobile') {
      
      return (
          <div 
            className={ `${ rootClass } sort-by-container mobile` }>
            <div 
              className={ `${ rootClass } sort-by-button` }
              onClick={ toggleSortByMobileModal }>
              <div className={ `${ rootClass } sort-by-button-text` }>
                Sort By
                <ArrowDownOpen nameOfClass={ `${ rootClass } arrow-down` }/>
              </div>
            </div>
            <SortByMobileModal
              sortMeta={ sortMeta }
              toggleSwitch={ toggleSwitch }
              rootClass={ rootClass } 
              toggleSortByMobileModal={ toggleSortByMobileModal }
              showSortByMobileModal={ showSortByMobileModal }/>
          </div>
      )
    }
    return (
      <div 
        className={ `${ rootClass } sort-by-container` }
        onMouseEnter={ () => toggleDropdown('sort-by') }
        onMouseLeave={ () => toggleDropdown('sort-by') }>
        <div className={ `${ rootClass } sort-by-button` }>
          <div className={ `${ rootClass } sort-by-button-text` }>
            Sort By
            <ArrowDownOpen nameOfClass={ `${ rootClass } arrow-down` }/>
          </div>
        </div>
        <SortByDropdownMenu
          sortMeta={ sortMeta }
          toggleSwitch={ toggleSwitch }
          rootClass={ rootClass } />
      </div>
    )

  }
}

export default Script;