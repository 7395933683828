import React from 'react';
import {css} from "@convertly/css";
import _ from 'lodash';
import Component from '../../../rootComponent';
import Types from '../../../attribute-types';


import {id} from '@convertly/thor';
import ReactHTMLParser from "@convertly/react-html-parser";


//Styles
import hoursOfOperationStyles from './hours-of-operation-styles-03';

//Scripts
import hoursWidget from '../scripts/hours-widget';
import LocationSelectorScript from '../scripts/location-selector';
//import openStatus from '../scripts/open-status';

//Components
import Elements from '../../../elements/index';
import SVG from '../../../svg/index';
import Location  from './location';
import LocationSelector from './location-selector';
//Default Props
import defaultGlobal from '../../../themes/global.js';

//Utils 
import {generateId, generateDataAttribute} from '../../../utils/generate-attribute';


//Component
export default class HoursOfOperation03 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            storeSelectorOpen: false
        };

        this.selectStore = this.selectStore.bind(this);
        this.toggleStoreSelector = this.toggleStoreSelector.bind(this);
    }


    selectStore(index) {
        this.setState({activeIndex: index});
        this.toggleStoreSelector();
    }

    toggleStoreSelector() {
        let storeSelectorOpen = this.state.storeSelectorOpen;
        this.setState({storeSelectorOpen: !storeSelectorOpen})
    }

    render() {

        /** Calls editor */
        if (this.props.editor) {
            return this.editor();
        }

        /** Generates border in editor */
        const inlineStyle = this.generateStyleAttribute();

        const inEditor = this.inEditor();

        /** inits style sheet **/
        this.styles = hoursOfOperationStyles(this.props.global, this.props.data, inEditor);

        let rootId = id(LocationSelectorScript);

        const locations = _.get(this.props, 'site.locations') || [];

        return (

            <div className={ css(this.styles.wrapper) }
                 { ...this.generateDataEditorAttribute() }
                 { ...this.generateCustomAttributes() }
                 onClick={(e) => {
                     this.launchElementalEditor('hoursWidget', e);
                 }}
                 onMouseOver={ (e) => {
                     this.addEditableOutline(e)
                 } }
                 onMouseOut={ (e) => {
                     this.removeEditableOutline(e)
                 } }
                 {...inlineStyle}>
                <Elements.Container
                    data={ {"containerFluid": true} }>

                    <div className={ css(this.styles.container)}>

                        <div onClick={ this.toggleStoreSelector }
                             className={ css(this.styles.storeSelector) + ' storeSelector_' + rootId }>
                            <span>{ _.get(this.props, 'data.title') || 'Select your store' }</span>
                            <SVG.ArrowHeadOpenDown className={ this.styles.arrow } dataTarget={ "arrow_" + rootId }/>
                        </div>

                        <div
                            className={ `${css(this.styles.chooseLocation)} chooseLocation_${rootId} ${ this.state.storeSelectorOpen ? 'open' : '' }` }>
                            {
                                locations.map((location, index) => {

                                    let shortAddress = location.address.split(' ');
                                    shortAddress.splice(0, 1);
                                    shortAddress = shortAddress.join(' ').replace(/#\d+/g, '');


                                    return (
                                        <LocationSelector location={ location }
                                                          index={ index }
                                                          styles={ this.styles }
                                                          selectStore={ this.selectStore }
                                                          shortAddress={ shortAddress }
                                                          rootId={ rootId }/>
                                    )
                                })
                            }
                        </div>

                        <div id={'storeInfo_' + rootId}>
                            {
                                locations.map((location, index) => {

                                    return (
                                        <Location location={ location }
                                                  index={ index }
                                                  styles={ this.styles }
                                                  activeIndex={ this.state.activeIndex }
                                                  rootId={ rootId }/>
                                    )
                                })
                            }
                        </div>

                    </div>

                </Elements.Container>
            </div>

        )
    }
}

HoursOfOperation03.defaultProps = {
    data: {
        hoursOfOperation: [
            {
                dayOfWeek: "MONDAY",
                openStatus: true,
                openingTime: "9:00am",
                closingTime: "5:30pm",
                displayText: "mon"
            },
            {
                dayOfWeek: "TUESDAY",
                openStatus: true,
                openingTime: "9:00am",
                closingTime: "12:00pm",
                displayText: 'tue'
            },
            {
                dayOfWeek: "WEDNESDAY",
                openStatus: true,
                openingTime: "9:00am",
                closingTime: "5:30pm",
                displayText: "wed"

            },
            {
                dayOfWeek: "THURSDAY",
                openStatus: true,
                openingTime: "9:00am",
                closingTime: "5:30pm",
                displayText: "thur"
            },
            {
                dayOfWeek: "FRIDAY",
                openStatus: true,
                openingTime: "9:00am",
                closingTime: "4:30am",
                displayText: "fri"
            },
            {
                dayOfWeek: "SATURDAY",
                openStatus: false,
                openingTime: "9:00am",
                closingTime: "5:30pm",
                displayText: "sat"
            },
            {
                dayOfWeek: "SUNDAY",
                openStatus: false,
                openingTime: "9:00am",
                closingTime: "5:30pm",
                displayText: "sun"
            }
        ]

    },
    global: defaultGlobal
};
