/*global Inferno, $, React */

/** Todo input component for ui todo-list-02 **/

const TodoInput = () => {

	return ({todoInputValue, rootClass}) => {

		return (
			<form
				data-function="addTodo"
				className={ `${rootClass} todo-form`}>
				<input
					type="text"
					value={ todoInputValue }
                    className={ `${rootClass} todo-input`}
					data-function="updateInputValue"/>
				<input
				type="submit"
				value="submit"/>
			</form>
		);
	}
};

export default TodoInput;
