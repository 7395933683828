import {StyleSheet} from '@convertly/css';
import UserOverrides  from '../../elements/style-functions/user-overrides';
import _ from 'lodash'
import {generateFooterOffsetMediaQueries} from '../style-functions/generate-footer-offset-media-queries';
import { generatePseudoMediaQuery } from '../../elements/style-functions/pseudo-media-queries'

const footerStyles = (global, data, hideConvertlyBranding, inEditor) => {

    let footerUserOverrides = _.get(data, 'userOverrides') ?
        UserOverrides(global, data, inEditor) :
        {};
    let footerHeight = _.get(data, 'userOverrides.height') || '80px';
    let bodyPaddingMediaQueries = {};

    /** Searches for height in media queries and assigns is
     to a property that can be passed to height */
    if (data.userOverrides) {
        bodyPaddingMediaQueries = generateFooterOffsetMediaQueries(data, hideConvertlyBranding);
    }


    let bodyOffset = inEditor ?
        {} :
        {
            "* body": {
                paddingBottom: hideConvertlyBranding ? footerHeight : `calc(${footerHeight} + 20px)`,

                '@media (max-width:991px)': {
                    paddingBottom: hideConvertlyBranding ? "120px" : "140px",
                },
                '@media (max-width:420px)': {

                    paddingBottom: hideConvertlyBranding ? "140px" : "160px",
                },
                ...bodyPaddingMediaQueries
            },

        };


    return StyleSheet.create({
        mainFooter: {
            textAlign: 'center',
            display: 'flex',
            //position: inEditor ? 'static': 'absolute',
            bottom: hideConvertlyBranding ? '0' : '20px',
            margin: "0 auto",
            justifyContent: "space-between",
            width: '100%',
            padding: "10px 30px",
            color: global.colors.light.color3,
            fontFamily: global.fontFamily.primaryFont,
            fontSize: '12px',
            minHeight:'fit-content',
            //...bodyOffset,
            ...footerUserOverrides,
            ...generatePseudoMediaQuery(
                '@media (max-width: 767px)',
                ['smallTablet', 'mobile'],
                {
                    flexDirection: 'column',
                    alignItems: 'center',
                }, inEditor,
                data.userOverrides
            ),
        },
        logo: {
            maxWidth: '50%',
            display: 'inline-block'
        },
        footerText: {
            margin: '20px',
            color: global.colors.dark.color3
        },
        copyText: {
            color: 'inherit'

        },
        right: {
            display:'flex',
            height:'100%',
            alignItems:'center',
            ...generatePseudoMediaQuery(
                '@media (max-width: 768px)',
                ['smallTablet', 'mobile'],
                {
                    display:'block',
                    height:'fit-content'
                }, inEditor
            )
        },

        contentSlot2: {
            display: 'inline-flex',
            borderColor: global.colors.dark.color3,
            paddingRight: '16px',
            fontFamily: global.fontFamily.primaryFont,
            height: '100%',
            alignItems: 'flex-end',
            ...generatePseudoMediaQuery(
                '@media (max-width: 768px)',
                ['smallTablet', 'mobile'],
                {
                    display:'block',
                    height:'fit-content'
                }, inEditor
            )
        },
        contentSlot3: {
            height: '100%',
            alignItems: 'flex-end',
            display: 'inline-flex',
            ...generatePseudoMediaQuery(
                '@media (max-width: 768px)',
                ['smallTablet', 'mobile'],
                {
                    display:'block',
                    height:'fit-content'
                }, inEditor
            )
        },
        contentSlot1: {
            display: 'inline-flex',
            height: '100%',
            alignItems: 'flex-end',
            ...generatePseudoMediaQuery(
                '@media (max-width: 768px)',
                ['smallTablet', 'mobile'],
                {
                    display:'block',
                    height:'fit-content'
                }, inEditor
            )
        },
        footerNavLinks: {
            display: 'inline-block',
            listStyleType: 'none'
        },
        copyRightLinks: {
            marginLeft: '5px',
            display: 'inline-block',
            ":before": {
                content: '" | "'
            },
            ':first-child': {
                ":before": {
                    content: '""'
                },
            },
        }


    })
};


export default footerStyles;
