import { createAction } from "redux-act";
import { store } from "app/app";

import {
  setEditorViewport,
  saveModule,
  setElements,
  clearEditorPanel,
} from "containers/Editor/actions/editorActions";

import { load } from "containers/Editor/Panels/AddElement/actions";

import { getGhost } from "./EditorOptions";

import { Alerts } from "services/Alerts";

export const setClipboardVisible = createAction("EDITOR OPTIONS: Set clipboard Visible State");
export const setViewPortVisible = createAction("EDITOR OPTIONS: Set View Port Visible State");
export const setClipboardItems = createAction("EDITOR OPTIONS: Set Clipboard Items");

export const saveClipboardItem = ({ item, elementsThatCanBeAddedToPage }) => async dispatch => {
  const itemName = await Alerts.prompt("What would you like to this call?");

  if (!itemName) {
    console.log("saveModule: ", saveModule);
    return;
  }

  const nodeImage = getGhost(item.get("ghostId")).nodeImage;

  await Promise.all([
    dispatch(
      saveModule({
        module: item.set("nodeImage", nodeImage),
        moduleName: itemName,
      })
    ),
    dispatch(setViewPortVisible(false)),
    dispatch(setClipboardVisible(false)),
  ]);

  await Promise.all([dispatch(load(elementsThatCanBeAddedToPage))]);
};

export const changeActiveViewport = viewport => async dispatch => {
  await dispatch(setEditorViewport(viewport));
};

export const toggleClipboardVisible = () => async dispatch => {
  const currentState = store
    .getState()
    .get("editor")
    .EditorOptions.get("clipboardVisible");

  await Promise.all([
    dispatch(setViewPortVisible(false)),
    dispatch(setClipboardVisible(!currentState)),
  ]);
};

export const toggleViewPortVisible = () => async dispatch => {
  const currentState = store
    .getState()
    .get("editor")
    .EditorOptions.get("viewPortVisible");

  await Promise.all([
    dispatch(setClipboardVisible(false)),
    dispatch(setViewPortVisible(!currentState)),
  ]);
};

export const updateClipboardItems = clipboardItems => async dispatch => {
  await dispatch(setClipboardItems(clipboardItems));
};

export const undo = () => async dispatch => {
  const isUndoDisabled = store
    .getState()
    .get("editor")
    .editorReducer.get("isUndoDisabled");
  if (isUndoDisabled) {
    return;
  }
  await dispatch(clearEditorPanel());
  dispatch(setElements([], false, false, "undo"));
};

export const redo = () => async dispatch => {
  const isRedoDisabled = store
    .getState()
    .get("editor")
    .editorReducer.get("isRedoDisabled");
  if (isRedoDisabled) {
    return;
  }
  await dispatch(clearEditorPanel());
  dispatch(setElements([], false, false, "redo"));
};
