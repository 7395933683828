import {StyleSheet} from '@convertly/css';
import { clientImage } from '../../utils/generateImage'
import UserOverrides from '../../elements/style-functions/user-overrides';

const Styles = (global, data) => {

    const userOverrides = UserOverrides(global, data)

    return StyleSheet.create({
        listItem: {
            display: "flex",
            flexDirection: "row", 
            margin: "10px 0px",
            ...userOverrides
        },

        text: {
            color: 'white',
            paddingLeft: '10px',
            paddingTop: "4px",
            fontSize: "20px",
            fontFamily:global.fontFamily.secondaryFont,
            lineHeight: "35px",
            "@media(max-width:577px)": {
                fontSize:'16px'
            }
        },

        arrowSvg: {
            fill: 'white'
        }


    })

};

export default Styles;