import _ from "lodash";
import { graph } from "utils";

/**
 *
 * @param siteId
 * @returns {Promise<*|Array>}
 */
export const fetchAuthors = async siteId => {
  const query = `
    query BlogAuthors($siteId: ID!) {
      BlogAuthors(siteId: $siteId, includeAuthorsWithNoPosts: true) {
        id
        avatar {
          src
          alt
          title
        }
        name
      }
    }
  `;

  const variables = {
    siteId,
  };

  const res = await graph({ query, variables });
  return _.get(res, "BlogAuthors") || [];
};

export const fetchDefaultAuthorId = async siteId => {
  const query = `
    query DefaultAuthor($siteId: ID!) {
      Site(id: $siteId) {
        defaultAuthor
      }
    }
  `;

  const variables = {
    siteId,
  };

  const res = await graph({ query, variables });

  return _.get(res, "Site.defaultAuthor");
};
