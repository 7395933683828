import React, { useEffect, useCallback, useRef } from "react";

export const useOnClickOutside = (onClickOutside, disabled) => {
  const ref = useRef();

  useEffect(() => {
    if (!disabled) {
      window.addEventListener("click", checkForClickOutside);
      return () => {
        window.removeEventListener("click", checkForClickOutside);
      };
    } else {
      window.removeEventListener("click", checkForClickOutside);
    }
  }, [disabled, onClickOutside]);

  const checkForClickOutside = useCallback(
    e => {
      if (ref.current) {
        if (!ref.current.contains(e.target)) {
          onClickOutside.apply(null, [e]);
        }
      }
    },
    [onClickOutside]
  );

  return ref;
};
