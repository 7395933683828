import { fromJS } from "immutable";
import { createReducer } from "redux-act";

import {
  setPixelData,
  updateConversionPoints,
  updateCustomPixels,
  setAllPages,
  setLoadedStatus,
  setTab,
  setLoadingPixels,
  setSavingState,
  resetState,
  setSnapshot,
  setFavicon,
  setFaviconFilename,
  setFaviconPreview,
  setFaviconIsNew,
  setGeneratingPreviewDomain,
  setActiveSetting,
  setDomainFooterVisible,
  setSiteSettingsSnapshot,
  setBusinessInfoLocationSnapshot,
  setCurrentSiteSettings,
  setPixelSnapshot,
} from "../actions";

const initialState = fromJS({
  activeSetting: "GENERAL",
  domainFooterVisible: false,
  conversionPoints: [],
  customPixels: [],
  pages: [],
  loaded: false,
  loadingPixels: false,
  saving: false,
  generatingPreviewDomain: false,

  // snapshot
  // businessInfoSnapshot: {},
  businessInfoLocationSnapshot: {},
  siteSettingsSnapshot: {},
  conversionPointsSnapshot: [],
  customPixelsSnapshot: [],
});

const reducer = createReducer(
  {
    [setPixelSnapshot]: (state, { conversionPointsSnapshot, customPixelsSnapshot }) =>
      state.merge({ conversionPointsSnapshot, customPixelsSnapshot }),

    [setSiteSettingsSnapshot]: (state, siteSettingsSnapshot) =>
      state.merge({ siteSettingsSnapshot }),

    [setBusinessInfoLocationSnapshot]: (state, businessInfoLocationSnapshot) =>
      state.merge({ businessInfoLocationSnapshot }),

    [setActiveSetting]: (state, activeSetting) => state.merge({ activeSetting }),

    [setDomainFooterVisible]: (state, domainFooterVisible) => state.merge({ domainFooterVisible }),

    [setGeneratingPreviewDomain]: (state, generatingPreviewDomain) =>
      state.merge({ generatingPreviewDomain }),

    [setFavicon]: (state, favicon) => state.merge({ favicon }),

    [setFaviconFilename]: (state, faviconName) => state.merge({ faviconName }),

    [setFaviconPreview]: (state, faviconPreview) => state.merge({ faviconPreview }),

    [setFaviconIsNew]: (state, faviconIsNew) => state.merge({ faviconIsNew }),

    [setSavingState]: (state, saving) => state.merge({ saving }),

    [setSnapshot]: (state, snapshot) => state.merge({ snapshot }),

    [setLoadingPixels]: (state, loadingPixels) => state.merge({ loadingPixels }),

    [setTab]: (state, activeTab) => state.merge({ activeTab }),

    [setLoadedStatus]: (state, loaded) => state.merge({ loaded }),

    [setAllPages]: (state, pages) => state.merge({ pages }),

    [updateCustomPixels]: (state, customPixels) => state.merge({ customPixels }),

    [updateConversionPoints]: (state, conversionPoints) => state.merge({ conversionPoints }),

    [setPixelData]: (state, { conversionPoints, customPixels }) =>
      state.merge({ conversionPoints, customPixels }),

    [resetState]: state => {
      // pulled from old settings, may need to be improved
      return state.merge({
        activeSetting: "GENERAL",
        domainFooterVisible: false,
        conversionPoints: [],
        customPixels: [],
        pages: [],
        loaded: false,
        loadingPixels: false,
        saving: false,
        generatingPreviewDomain: false,

        // snapshot
        // businessInfoSnapshot: {},
        businessInfoLocationSnapshot: {},
        siteSettingsSnapshot: {},
        conversionPointsSnapshot: [],
        customPixelsSnapshot: [],
      });
    },
  },
  initialState
);

export default reducer;
