/*global Inferno, $ */
/* eslint-disable */
import { include } from '@convertly/thor';

const Scripts = ( id ) => {

    const Dispatch = include('../../../utils/dispatcher', 'Dispatch');
    const Cookie = include('../../../utils/cookie', 'Cookie');
    const ConvertToCurrency = include( '../../../utils/ecomm-utils/convert-to-currency-ii', 'ConvertToCurrency' );

    const CartItemInferno = include('./inferno-components/cart-item', 'CartItemInferno');

    return( id, siteUrl, clientImageSrc, displayCart, slug ) => {


        const hasLocalStorage = $( 'html' ).hasClass( 'localstorage' );

        const root = document.getElementById( id );
        console.log("root", id)

        //Utils
        const dispatch = Dispatch( id );
        const convertToCurrency = ConvertToCurrency( );
        const cookie = Cookie();

        const CartItem = CartItemInferno( dispatch, convertToCurrency, clientImageSrc, cookie );

        const CartHandler = Inferno.createClass( {


            getInitialState: function( ) {
                return {
                    itemsInCart: 0,

                };
            },

            handle: function( e ) {

                const { action, value } = e.detail;

                switch( action ) {
                    case 'current_cart':
                        this.setCart( value );
                        break;
                    default:
                        break;
                }
            },

            requestCart: function( items ) {
                if( $.isEmptyObject(this.state.items)) {
                    dispatch.send('request_cart', 'request');
                }
            },

            /** Renders number next to Cart icon **/
            renderCartValue: function( ) {
                dispatch.on( 'cart', ( cartItems ) => {

                    let itemsInCart = 0;



                    if(cartItems.length) {
                        cartItems.map((item) => {
                            itemsInCart += item.quantity
                        });
                    }
                    this.setState( {
                        itemsInCart: itemsInCart,
                        items: cartItems
                        } )
                } );

                dispatch.send('validate_cart', '')

            },

            /** Renders the sale price **/
            renderSalePrice( cartItem ) {
                let salePrice;
                if( cartItem.onSale ) {
                    salePrice =
                        <div>
                            <div className={ `${root.className} chkGridqty_chkUnitprice_strikethrough` }>
                                { convertToCurrency(cartItem.price) }
                            </div>

                            <div className={ `${root.className} actualprice_chkGridqty_chkUnitprice` }>
                                { convertToCurrency(cartItem.salePrice) }
                            </div>
                        </div>
                } else {
                    salePrice =
                            <div className={ `${root.className} chkGridsubtotal` }>
                                { convertToCurrency(cartItem.price) }
                            </div>
                }
                return salePrice
            },

            /** Renders call out sections **/
            renderCallOutSection( cartItemData ) {
                let callOutSection = [ ];

                if( cartItemData.productOptions ) {
                    cartItemData.productOptions.map( ( productOption ) => {
                        callOutSection.push(
                            <div className={ `${root.className} chkGridprice_chkGridpricenote` }>
                                { cartItemData.productOption.option }</div>
                        )
                    } )
                }

                return callOutSection;
            },

            /* increase quantity */
            incrementQuantity: function( e, item ) {
            	e.preventDefault();
                console.log('ITEMS:::::', this.state.items)
            	if(this.state.items) {
                    let items = this.state.items;

                    this.state.items.map((cartItem, index) => {
                        if (item.id === cartItem.id) {
                            cartItem.quantity++
                        }

                    });
                    //item.quantity++;
                    //items.push( item );
                    //this.setState( { items: items } );
                    dispatch.send('item_quantity_change', ( this.state.items ));
                }
            },

            /* decreases quantity */
            decrementQuantity: function( e, item ) {
            	e.preventDefault();
            	if(this.state.items) {
                    if (item.quantity > 1) {

                        this.state.items.map((cartItem, index) => {
                            if (item.id === cartItem.id) {
                                cartItem.quantity--
                            }

                        });
                        //this.setState( { items: items } );
                        dispatch.send('item_quantity_change', ( this.state.items ));
                    }
                    else {
                        this.removeCartItem(item)
                    }
                }
            },

            /** removes item from cart **/
            removeCartItem: function( e, item ) {
            	e.preventDefault();
                dispatch.send( 'remove_cart_item', item )
            },

            linkToCart: function() {
                location.href = `${siteUrl}/cart`
            },

            generateItemChangedMessage(product) {
                let message =
                    <div className={ `${root.className} change-message-container` }>
                        <p className={ `${root.className} change-message-text` }>This item has changed since you added it to your cart</p>
                        <div className={ `${root.className} change-message-action` }>
                            <p className={ `${root.className} change-message-link` }>Keep</p>
                            <p className={ `${root.className} change-message-link` }
                               onClick={ (e)=> { this.removeCartItem(e, product) } }>Remove</p>
                        </div>
                    </div>;

                return message
            },

            componentDidMount: function( ) {
                this.renderCartValue();

            },

            render: function( ) {

                let cartItems;

                    if (this.state.items) {
                        cartItems =
                            this.state.items.map( (item, index) => {

                                return (
                                    <CartItem rootDiv={ root } functions={ { incrementQuantity: this.incrementQuantity, decrementQuantity: this.decrementQuantity } }item={ item }/>
                                )
                            })
                    }

                let cartSection = null;

                if ( displayCart ) {

                    cartSection =
                        <div className={ `${root.className} cartDropDown` }>
                            <div className={ `${root.className} cartContainer` }>
                                { cartItems }
                            </div>
                            <div className={ `${root.className} cartContainerFooter` }>
                                <a href={ `${siteUrl}/checkout` } className={ `${root.className} checkoutLink` }>
                                    Proceed to Checkout
                                </a>
                                <a href={ `${siteUrl}/cart` } className={ `${root.className} viewCartLink` } >View Cart</a>
                            </div>
                        </div>
                }


                if( this.state.itemsInCart > 0 ) {

                    const numberOfItemsInCart = ` (${ this.state.itemsInCart })`;
                    return(
                        <div className={ `${root.className} headerNavLink sign-in` }>
                            <div className={ `${root.className} casper` } />
                            <a onClick={ this.linkToCart }
                               href={ `${siteUrl}/cart` }>
                                <p className={ `${root.className} cartLink` }>Cart{numberOfItemsInCart}</p>
                            </a>
                            { cartSection }
                        </div>

                    )
                } else {

                    return(
                        <div href={ `${siteUrl}/cart` }

                             className={ `${root.className} headerNavLink` }>
                            <a onClick={ this.linkToCart }
                               href={ `${siteUrl}/cart` }>Cart</a>
                        </div>
                        )
                }
            }
        } );

        Inferno.render( <CartHandler />, root );

    };

};

export default Scripts;
