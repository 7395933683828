import React from 'react';
import Component from '../../rootComponent';
import {css} from "@convertly/css";
import _ from "lodash";


//Get Header Position
import getHeaderPosition from '../header-style-functions/get-header-position';

//Styles
import headerStyles from './header-styles-01.js';

import { HeaderTopper } from '../components/header-topper';

//Components
import HeaderNav1 from './components/header-nav-1';

export class Header01 extends Component {

    constructor(props) {
        super(props);

    }


    render() {

        const {data, page, site, global} = this.props;

        this.styles = headerStyles({...this.props});

        const inlineStyle = this.generateStyleAttribute();

        let headerPosition = getHeaderPosition(data.position, this.props.page);
        if (this.props.editor) {
            return this.editor();
        }
        if (headerPosition === "noHeader") {
            return null
        }


        else {

            return (
                <header
                    className={ "header01 " + css(this.styles.header) }
                    data-elementType="header"
                    itemProp="siteHeader"
                    itemScope
                    itemType="http://schema.org/siteHeader"
                    onClick={(e) => {
                        this.launchElementalEditor('header', e);
                    }}
                    onMouseOver={ this.addEditableOutline }
                    onMouseOut={ this.removeEditableOutline }
                    { ...inlineStyle }
                    { ...this.generateDataEditorAttribute() }>
                    {
                        _.get(data, "headerTopperSettings.display") &&

                        <HeaderTopper
                            global={ global }
                            site={ site }
                            styles={ this.styles }
                            data={ data.headerTopperSettings }/>
                    }
                    <div className={ css(this.styles.headerBottom) }>
                        <HeaderNav1 global={ this.props.global }
                                    data={ data }
                                    id={ this.props.id }
                                    page={ page }
                                    site={ site }
                                    inEditor={ this.inEditor() }/>
                    </div>
                </header>
            )
        }
    }
}

