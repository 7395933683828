/*global $ */
/* eslint-disable */

import { include } from '@convertly/thor';

const Scripts = () => {

	// Thor import
	const Dispatcher = include( '../../utils/dispatcher', 'Dispatcher' );

	/**
	 * Common script file for elements
	 * to attach GA events
	 */
	return ( id, events ) => {

		events = events || JSON.stringify( [] );

		const root = document.getElementById ( id );
		const dispatch = Dispatcher( id );

		dispatch.attach( events );

		JSON.parse( events ).map( event => {
			root.addEventListener( event.trigger, () => {
				dispatch.trigger( event.trigger );
			});
		});

	};

};

export default Scripts;
