export function generateHeaderOffsetMediaQueries( data ) {
    let bodyPaddingMediaQueries = {};

    Object.keys( data.userOverrides ).map( ( key ) => {
        if (key.indexOf('@media') !== -1) {
            Object.keys(data.userOverrides[key]).map((mqKey) => {
                if (mqKey.indexOf('height') !== -1) {
                    bodyPaddingMediaQueries[key] = {};
                    let heightValue = data.userOverrides[key][mqKey];
                    bodyPaddingMediaQueries[key]['marginTop'] = heightValue
                    bodyPaddingMediaQueries[key]['minHeight'] = `calc(100vh - ${heightValue})`
                }
            })
        }
    })

        return bodyPaddingMediaQueries
}
