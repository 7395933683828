import React from "react";
import Component from "source/rootComponent";
import { css } from "@convertly/css";
import _ from "lodash";
import lightbox from "./scripts/lightbox";

// Style
import LightBoxImageGalleryStyles from "./LightBoxImageGalleryStyles";
import { clientImage } from "../../utils/generateImage";
import { id } from "@convertly/thor";

class LightBoxImageGallery extends Component {
  render() {
    const { data, inEditor, global } = this.props;

    if (!_.isArray(data.imageUrls)) {
      return null;
    }

    this.styles = LightBoxImageGalleryStyles({ global, data, inEditor });

    const { lightBoxImageGallery, lightBoxImage, lightBoxImageWrapper } = this.styles;

    return (
      <div
        id={id(lightbox)}
        {...this.generateDataEditorAttribute()}
        {...this.generateCustomAttributes()}
        className={css(lightBoxImageGallery)}>
        {data.imageUrls.map(imgObj => {
          let imgUrl = "";
          let wrapperUserOverrides = {};
          let imgUserOverrides = {};
          
          if(_.isString(imgObj)) {
            imgUrl = imgObj;
          } else {
            imgUrl = imgObj.imgUrl || "";
            wrapperUserOverrides = imgObj.wrapperUserOverrides || {};
            imgUserOverrides = imgObj.imgUserOverrides || {};
          }

          return (
            <div
              key={`lightbox-image-${imgUrl}`}
              className={css(lightBoxImageWrapper)}
              style={wrapperUserOverrides}>
              <img
                key={`lightbox-image-${imgUrl}`}
                className={`${css(lightBoxImage)} `}
                src={clientImage(imgUrl)}
                style={imgUserOverrides}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

export default LightBoxImageGallery;
