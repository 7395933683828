import ghost from './ghost/ghost-global';
import klay from './klay';
import magicDragons from './magic-dragons/magic-dragon-global';
import rogue1 from './rogue/rogue-1-global';
import rogue2 from './rogue/rogue-2-global';
import rogue3 from './rogue/rogue-3-global';
import rogue4 from './rogue/rogue-4-global';
import absolute01 from './absolute/absolute-1-global';
import absolute02 from './absolute/absolute-2-global';
import zeroFill01 from './zero-fill/zero-fill-theme-01';
import bolb from './bolb/bolb.js';
import bolbInc from './bolb-inc/bolb-inc.js';
import drPress from './dr-press/drPress';
import lulus from './lulus/lulus.js';
import delphi from './delphi/delphi';
import chavez from './chavez/chavez.js';
import wgpmc from './wgpmc/wgpmc';
import electricBlack from './electric-black/electric-black-theme';
import goldPlated from './gold-plated/gold-plated-theme';
import convertly from './convertly/convertly-theme.js';
import frenchPress01 from './french-press/french-press-theme-01';
import frenchPress02 from './french-press/french-press-theme-02';
import kyrish from './kyrish/kyrish';
import convertlyMain from './convertly/convertly-main';

const themes = {
	klay: klay,
	ghost: ghost,
	magicDragons: magicDragons,
	rogue1: rogue1,
    rogue2: rogue2,
    rogue3: rogue3,
    rogue4: rogue4,
	absolute01: absolute01,
    absolute02: absolute02,
	zeroFill01: zeroFill01,
    bolb: bolb,
    bolbInc: bolbInc,
    drPress: drPress,
    lulus: lulus,
    chavez: chavez,
    electricBlack: electricBlack,
    goldPlated: goldPlated,
    frenchPress01: frenchPress01,
    frenchPress02: frenchPress02,
    convertly: convertly,
    kyrish: kyrish,
    convertlyMain: convertlyMain,
    wgpmc,
    delphi
};

export default themes; 
