import React from 'react';
import {
  SearchWrapper,
  SearchIcon,
  SearchInput,
  SearchIconWrapper
} from './components';



export const SearchBar = ({placeholder="Text Here", cb, value} ) => {


  return(
    <SearchWrapper>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <SearchInput
        value={value}
        onChange={cb}
        placeholder={placeholder} />
    </SearchWrapper>
    )

}
