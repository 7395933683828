//import $ from 'jquery';

const Scripts = ( id ) => {

    return ( id ) => {
        const el = $('#' + id);
        el.on('click', function (e) {

            const closeButton = $('#closeButton');
            if (e.target.id == 'closeButton') {
                    let searchForm = '#searchform';
                    $(searchForm).removeClass('open');
                    let children = $(searchForm).children();
                    children.removeClass('open');
            }
            else {
                let target = e.target.dataset.target;
                $(target).addClass('open');
                let children = $(target).children();
                children.addClass('open');
            }
        })
    }
};


export default Scripts;
