export const PANELS = {
  ADD_ELEMENT: "ADD_ELEMENT",
  PAGES: "PAGES",
  EDIT_PAGE: "PAGE_INFO",
  BLOG: "BLOGS",
  EDIT_POST: "EDIT_POST",
  NEW_POST: "NEW_POST",
  EDIT_CATEGORY: "EDIT_CATEGORY",
  ADD_CATEGORY: "ADD_CATEGORY",
  EDIT_LAYOUT_POST_PAGE: "LAYOUT_POST_PAGE",
  EDIT_LAYOUT_BLOG_MASTER: "LAYOUT_BLOG_MASTER",
  EDIT_LAYOUT_BLOG_CATEGORY: "LAYOUT_BLOG_CATEGORY",
  SELECT_BLOG_LAYOUT: "SELECT_BLOG_LAYOUT",
  ASSETS: "ASSETS",
  THEMES: "THEMES",
};

export const POST_STATUS = {
  PUBLISHED: "PUBLISHED",
  DRAFT: "DRAFTS",
};

export const BLOG_PANEL_TAB_OPTION_KEYS = {
  POST: "POST",
  SETTINGS: "SETTINGS",
  CATEGORIES: "CATEGORIES",
  LAYOUT: "LAYOUT",
};

export const BLOG_PANEL_TAB_OPTIONS = [
  { title: "Post", key: BLOG_PANEL_TAB_OPTION_KEYS.POST },
  { title: "Settings", key: BLOG_PANEL_TAB_OPTION_KEYS.SETTINGS },
  { title: "Categories", key: BLOG_PANEL_TAB_OPTION_KEYS.CATEGORIES },
  { title: "Layout", key: BLOG_PANEL_TAB_OPTION_KEYS.LAYOUT },
];

export const PanelIconMap = {
  THEMES: "THEMES",
  GLOBAL_STYLES: "THEMES",
  PAGES: "PAGES",
  EDIT_PAGE: "PAGES",
  PAGE_INFO: "PAGES",
  ADD_ELEMENT: "ADD_ELEMENT",
  BLOG: "BLOGS",
  BLOGS: "BLOGS",
  LAYOUT_POST_PAGE: "BLOGS",
  LAYOUT_BLOG_MASTER: "BLOGS",
  LAYOUT_BLOG_CATEGORY: "BLOGS",
  LAYOUT_AUTHOR_PAGE: "BLOGS",
  SELECT_BLOG_LAYOUT: "BLOGS",
  EDIT_POST: "BLOGS",
  NEW_POST: "BLOGS",
  EDIT_CATEGORY: "BLOGS",
  ADD_CATEGORY: "BLOGS",
  AUTHORS: "BLOGS",
  EDITADDAUTHORS: "BLOGS",
  EDIT_PAGE: "BLOGS",
  LAYERS: "LAYERS",
  ASSETS: "ASSETS",
  SETTINGS: "SETTINGS",
  NEW_BRANCH: "SETTINGS",
  EDIT_BRANCH: "SETTINGS",
};
