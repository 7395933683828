import React from "react";
import PropTypes from "prop-types";
import { fromJS } from "immutable";
import { InputInlineEditing } from "components/Input/InputInlineEditing";
import { Button } from "components/Button/Button";
import { setElements, clearEditorPanel } from "containers/Editor/actions/editorActions";
import {
  saveActiveBanner,
  updateSetting,
  saveActiveTemplate
} from "containers/Marketing/Banners/actions";
import {
  MenuWrapper,
  CenterWrapper,
  ButtonsWrapper,
  UndoButtons,
  UndoButton,
  RedoButton
} from "./styledComponents";

export class AdtechMenu extends React.Component {
  constructor(props) {
    super(props);

    this.save = this.save.bind(this);
    this.undo = this.undo.bind(this);
    this.redo = this.redo.bind(this);
  }

  async save() {
    const { activeBanner } = this.props;

    if (activeBanner.get("isTemplate")) {
      await this.props.dispatch(saveActiveTemplate());
    } else {
      await this.props.dispatch(saveActiveBanner());
    }
  }

  async undo() {
    if (this.props.isUndoDisabled) {
      return;
    }
    await this.props.dispatch(clearEditorPanel());
    this.props.dispatch(setElements([], false, false, "undo"));
  }

  async redo() {
    if (this.props.isRedoDisabled) {
      return;
    }
    await this.props.dispatch(clearEditorPanel());
    this.props.dispatch(setElements([], false, false, "redo"));
  }

  /**
   *
   * @returns {*}
   */
  render() {
    const {
      currentSite,
      activeBanner,
      settings,
      dispatch,
      isUndoDisabled,
      isRedoDisabled
    } = this.props;

    if (!currentSite) {
      return null;
    }

    if (!this.props.bannerEditorIsOpen) {
      return <MenuWrapper />;
    }

    const text = !!activeBanner.get("isTemplate") ? "Template" : "Banner";

    return (
      <MenuWrapper>
        <CenterWrapper>
          <InputInlineEditing
            onChange={async value => await dispatch(updateSetting("banner-temp-title", value))}
            width={200}
            label={settings.get("banner-temp-title") || ""}
            value={settings.get("banner-temp-title") || ""}
            onSave={this.props.updateBannerTitle}
            placeholder={"Untitled"}
          />
        </CenterWrapper>

        <ButtonsWrapper>
          <UndoButtons>
            <UndoButton isUndoDisabled={isUndoDisabled}>
              <i className="material-icons" onClick={this.undo}>
                undo
              </i>
            </UndoButton>
            <RedoButton isRedoDisabled={isRedoDisabled}>
              <i className="material-icons" onClick={this.redo}>
                undo
              </i>
            </RedoButton>
          </UndoButtons>

          <Button
            no-float
            width={"auto"}
            processing={this.props.saving}
            type="submit"
            label={`Save ${text}`}
            small
            action={!this.props.saving && this.save}
          />
        </ButtonsWrapper>
      </MenuWrapper>
    );
  }
}

AdtechMenu.defaultProps = {
  activeBannerId: "",
  activeBanner: fromJS({}),
  saving: false
};

AdtechMenu.propTypes = {
  openMenu: PropTypes.func,
  activeBannerId: PropTypes.string,
  activeBanner: PropTypes.object,
  saving: PropTypes.bool
};

AdtechMenu.contextTypes = {
  store: PropTypes.object
};
