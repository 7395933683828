//need to refactor to remove the depricated type param, for now assign type to hard coded value
export function getColor(color, depricatedType, global) {
    let colorValue;
    let modifier;
    const type = "colors";

    switch (color) {
        case "dark1":
            modifier = ["dark"];
            colorValue = ["color1"];
            break;
        case "dark2":
            modifier = ["dark"];
            colorValue = ["color2"];
            break;
        case "dark3":
            modifier = ["dark"];
            colorValue = ["color3"];
            break;
        case "light1":
            modifier = ["light"];
            colorValue = ["color1"];
            break;
        case "light2":
            modifier = ["light"];
            colorValue = ["color2"];
            break;
        case "light3":
            modifier = ["light"];
            colorValue = ["color3"];
            break;
        case "accent1":
            modifier = ["accent"];
            colorValue = ["color1"];
            break;
        case "accent2":
            modifier = ["accent"];
            colorValue = ["color2"];
            break;
        case "accent3":
            modifier = ["accent"];
            colorValue = ["color3"];
            break;
        default:
            return color
    }
    return global[type][modifier][colorValue]
}