export default function StyleObject(global, data) {

    return {

        ".snack-alert-container": {
            position:'fixed',
            bottom:'0',
            zIndex:'700',
            width:'100%',
        },
        ".snack-alert-wrapper": {
            position:"absolute",
            bottom:'-250px',
            left:"50%",
            transform: "translateX(-50%)",
            transition:'all 1s ease',
			wordWrap: 'break-word',
			minWidth: '350px',
            margin:"0 auto",
            maxWidth:'100vw',
            ".active": {
                bottom:'30px'
            },
            "@media(max-width:480px)": {
                width:'100%',
                left: 0,
                transform: "translateX(0)",
            }


        },

        '.snack-alert': {
            background:global.colors.accent.color3,
            padding:'10px 30px',
            fontWeight:"bold",
            textAlign:'center',
            color:global.colors.light.white,
            fontSize:'14px',
            "> div > .add-to-cart-paragraph": {
                display:'inline',
            },
            "> div > .add-to-cart-link": {
                display:'inline',
                marginLeft:'30px',
                float:'right',
                fontWeight:'bold'
            }

        }


    }


}
