import React from 'react';
import Component from '../../rootComponent';
import { css } from "@convertly/css";


export default class
ArrowHeadLeftSvg extends Component {
    constructor ( props ) {
        super( props );
    }
    render() {
        return (

        <svg version="1.1"
             xmlns="http://www.w3.org/2000/svg"
             width="451.847px"
             height="451.847px"
             className={ css( this.props.className ) }
             viewBox="0 0 451.847 451.847"
             { ...this.props.attributes }
             data-target={ this.props.dataTarget } >
            <g>
                <path d="M97.141,225.92c0-8.095,3.091-16.192,9.259-22.366L300.689,9.27c12.359-12.359,32.397-12.359,44.751,0
                    c12.354,12.354,12.354,32.388,0,44.748L173.525,225.92l171.903,171.909c12.354,12.354,12.354,32.391,0,44.744
                    c-12.354,12.365-32.386,12.365-44.745,0l-194.29-194.281C100.226,242.115,97.141,234.018,97.141,225.92z"/>
            </g>
        </svg>
        )
    }
}
