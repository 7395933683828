import {deeplog} from "../../utils/deeplog";

const regeneratorRuntime = require("regenerator-runtime");
import React from "react";
import Component from "source/rootComponent";
import _ from "lodash";
import { css } from "@convertly/css";
import { id } from "@convertly/thor";
import { frontloadConnect } from "@convertly/react-frontload";
import { generateLink } from "../../utils/generateLink";

// Component
import ArrowHeadOpenDown from "source/svg/arrows/arrow-head-open-down.js";

// Style
import { FilterDropdownStyles } from "./FilterDropdownStyles";

// Script
import toggleDropdown from "../scripts/toggleDropdown";

// Utils
import { frontLoadOptions } from "../../utils/frontLoadOptions";
import {doQuery} from "../../utils/doQuery";
import {generateBlogLink} from "../util/generate-blog-link";

let dropdownList = {};
let loaded = false;

const fetchDropdownList = (props, component) => {
  return new Promise(async (resolve, reject) => {
    if (loaded && _.get(component, "props.inEditor")) {
      return resolve();
    }

    dropdownList = {
      authors: [
        {
          name: "Cow Man",
          id: "3dcn89h9h",
          avatar: {
            alt: false,
            imgParams: {},
            title: false,
            src: "athlete-bicycle-bike-12838-compressor.jpg"
          },
          url: "/cowmanuserpage"
        },
        {
          name: "Cow Man",
          id: "3dcn89h9h",
          avatar: {
            alt: false,
            imgParams: {},
            title: false,
            src: "athlete-bicycle-bike-12838-compressor.jpg"
          },
          url: "/cowmanuserpage"
        },
        {
          name: "Cow Man",
          id: "3dcn89h9h",
          avatar: {
            alt: false,
            imgParams: {},
            title: false,
            src: "athlete-bicycle-bike-12838-compressor.jpg"
          },
          url: "/cowmanuserpage"
        }
      ],
      categories: [
        {
          name: "Entertainment",
          url: "/Entertainment",
          id: "8u349foierjnkfe"
        },
        {
          name: "Business",
          url: "/Business",
          id: "839ofjerukfnejn"
        },
        {
          name: "Technology",
          url: "/Technology",
          id: "2ehiuwrgjlncsmlkds"
        },
        {
          name: "Finance",
          url: "/Finance",
          id: "t9i43oueghjrfkns"
        },
        {
          name: "Leadership",
          url: "/Leadership",
          id: "239irefowjlnscds"
        }
      ]
    };

    const siteId = _.get(props, "site.siteId");

    const query = `
      query($siteId:ID!) {
        BlogCategories(siteId:$siteId) {
          name
          id
          url
        } 
      }
    `;

    const variables = {
      siteId
    };


    const data = await doQuery({query, variables});
    dropdownList[props.id] = _.get( data.data, 'BlogCategories' ) || [];

    loaded = true;
    resolve();
  });
};

const frontload = async props => fetchDropdownList(props);

class FilterDropdown extends Component {
  componentWillMount() {
    if (!loaded) {
      return fetchDropdownList(this.props, this);
    }
  }

  render() {
    const { global, data, inEditor } = this.props,
          { selectionList } = data || {},
          inlineStyle = this.generateStyleAttribute(),
          toggleDropdownId = id(toggleDropdown);

    this.styles = FilterDropdownStyles({ global, data, inEditor });

    const {
      dropdown,
      list,
      listItem,
      itemLink,
      itemName,
      dropdownButton,
      filterDropdown,
      arrowHeadOpenDown
    } = this.styles;

    return (
      <div
        className={css(filterDropdown)}
        {...inlineStyle}
        {...this.generateDataEditorAttribute()}
        {...this.generateCustomAttributes()}
      >
        {dropdownList[this.props.id] && dropdownList[this.props.id].length > 0 && (
          <div className={css(dropdown)}>
            <div
              id={id(toggleDropdown)}
              data-dropdown-button="category"
              className={css(dropdownButton)}
            >
              Category <ArrowHeadOpenDown className={arrowHeadOpenDown} />
            </div>

            <ul className={css(list)} data-dropdown-button-target="category">
              {dropdownList[this.props.id].map((category, idx) => {
                return (
                  <li className={css(listItem)} key={`category-dropdown-item-${idx}`}>
                    <a className={css(itemLink)}
                       href={ generateBlogLink(this.props.page.blogLink, category.url, inEditor, this.props.site)}>
                      <div className={css(itemName)}>{category.name}</div>
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

module.exports = {
  FilterDropdown: frontloadConnect(frontload, frontLoadOptions)(FilterDropdown)
};
