import React from 'react';
import _ from 'lodash';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';

//Styles
import navStyles03 from './nav-styles-03.js';

//Scripts
import toggleMobileNav from '../../scripts/mobile-nav';
import toggleDropDown from '../../scripts/nav-dropdown-toggle';
import SearchBarWrapper from '../../scripts/inferno-components/search-bar-wrapper/SearchBarWrapper';
import cartScript from '../../scripts/cart-script';
import isSignedIn from '../../scripts/is-signed-in'
import SearchBarWrapperHeader01 from '../../scripts/inferno-components/search-bar-wrapper/SearchBarWrapperHeader01';

//Components
import Elements from 'source/elements/index';
import Widgets from 'source/widgets/index';
import NavList03 from './nav-list-03';
import {HeaderTopper} from '../../components/header-topper';


//utils
import {generateLink} from '../../../utils/generateLink';
import {clientImage} from '../../../utils/generateImage';


export default class HeaderNav03 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showHeaderMenu: false,
            toggledClass: ''
        };

        this.toggleHeaderMenu = this.toggleHeaderMenu.bind(this);
    }

    toggleHeaderMenu() {
        let showMobileMenu = this.context.store.getState().get('editor').editorReducer.get('showClientNav');

        this.context.store.dispatch(this.context.store.toggleClientNav(showMobileMenu));
    }


    render() {


        let {data, global, styles, site, page} = this.props;

        const inEditor = this.inEditor();

        let showMobileMenu = false;

        if (inEditor) {
            showMobileMenu = this.context.store.getState().get('editor').editorReducer.get('showClientNav');
        }

        this.styles = navStyles03(global, data, inEditor);

        let rootId = id(toggleMobileNav);

        const inlineStyle = this.generateStyleAttribute();

        let cartDiv = null;

        const clientImgSrc = clientImage("", {width: '60', height: '60', crop: 'fit'});
        const clientLinkUrl = generateLink({linkType: "inferno", url: ""});

        if (_.get(site, 'ecommerce.enabled')) {
            if (page && page.slug !== "/cart") {
                cartDiv =
                    <div className={css(this.styles.mobileCartContainer)}>
                        <div className={css(styles.navItemContainer)}>
                            <div className={css(this.styles.mobileCart)}
                                 id={id(cartScript, clientLinkUrl, clientImgSrc, false, "cartIcon")}/>
                        </div>
                    </div>;
            }
        }

        return (
            <Elements.Container data={{userOverrides: {height: '100%'}}}>

                <Elements.Row editable={false} data={{userOverrides: {height: '100%'}}}>
                  <Elements.Column data={ {xsColumns:12} } >

                    <div className={css(styles.headerTop, styles.fitHeight)}>
                        {data.showNavigation &&
                        <div className={`${css(styles.mobileMenu)} mobileNav_${rootId} ${showMobileMenu && 'open'}`}>
                            <div className={css(styles.mobileNavContent)}>
                                {
                                    !_.get(this.props.page, 'hideSearchBar') &&
                                    _.get(this.props, 'site.ecommerce.enabled') &&
                                    <div
                                        id={id(SearchBarWrapperHeader01, this.props.site.siteId)}
                                        className={`${ css(this.styles.searchBarWrapper) } mobile`}/>
                                }
                                <NavList03
                                    styles={this.styles}
                                    global={global}
                                    site={site}
                                    id={this.props.id}
                                    page={page}
                                    data={data.menu}/>
                                {_.get(data, 'headerTopperSettings.displaySocialIcons') &&
                                _.get(site, 'social') &&
                                <Widgets.SocialSharing01
                                    data={{
                                        socialIcons: site.social,
                                        ...data
                                    }}
                                    global={global}
                                    styles={styles}
                                    socialIconLinkClass={styles.socialIconContainer}/>
                                }
                            </div>
                        </div>
                        }

                        <div className={css(styles.mobileMenu2) + " mobileNav2_" + rootId}>
                        </div>

                        {/** TOP HEADER */}
                        {_.get(data, "headerTopperSettings.display") &&
                        <HeaderTopper
                            global={global}
                            site={site}
                            styles={this.styles}
                            data={data.headerTopperSettings}/>
                        }

                        {/** BOTTOM HEADER */}
                        <div className={css(this.styles.mainHeader)}>
                            {/** MOBILE MENU TOGGLE BUTTON*/}
                            {_.get(data, "showNavigation") ?

                            <button
                                id={rootId}
                                onClick={this.toggleHeaderMenu}
                                data-target="#menu"
                                type="button"
                                className={css(this.styles.navLaunch)}>

                                <span className="sr-only">
                                Toggle navigation
                                </span>

                                <span
                                    className={`${css(this.styles.iconBar)} icon-bar ${showMobileMenu && 'open'}`}
                                    data-target="#menu">
                                </span>

                                <span
                                    className={`${css(this.styles.iconBar)} icon-bar ${showMobileMenu && 'open'}`}
                                    data-target="#menu">
                                </span>

                                <span
                                    className={`${css(this.styles.iconBar)} icon-bar ${showMobileMenu && 'open'}`}
                                    data-target="#menu">
                                </span>

                            </button> :
                                <div className={css(this.styles.navLaunch)} />
                            }
                            {/** LOGO */}
                            {data.showLogo &&
                            <div data-pin-and-needles={true}>
                                {data.logoLinkToHomePage ?
                                    <a href={'/'}
                                       className={css(styles.logo)}
                                       onClick={(e) => {
                                           this.cancelClick(e)
                                       }}
                                    >
                                        {data.useTextLogo && _.get(data.textLogo, 'text') ?
                                            <Elements.Heading2 data={{...data.textLogo}}>Text Logo
                                                Here</Elements.Heading2>
                                            :
                                            _.get(site, 'logo.src') ?
                                                <picture>
                                                    { /** WEBP **/}
                                                    {/*<source srcSet={ clientImage(site.logo.src, {format:"webp" } ) } type="image/webp"/>*/}
                                                    { /** normal **/}
                                                    <source srcSet={ clientImage(site.logo.src, data.params ) } type="image/jpeg"/>
                                                <img src={clientImage(site.logo.src)}
                                                     alt={site.logo.alt}
                                                     data-logo
                                                     title={site.logo.title}
                                                     className={css(styles.logoImg)}/>
                                                </picture>
                                                : null
                                        }
                                    </a>
                                    :

                                    <span className={css(styles.logo)}>
                                        {data.useTextLogo && _.get(data.textLogo, 'text') ?
                                            <Elements.Heading3 editable={false} data={{...data.textLogo}}>Text Logo
                                                Here</Elements.Heading3>
                                            :
                                            _.get(site, 'logo.src') ?
                                                <picture>
                                                    { /** WEBP **/}
                                                    {/*<source srcSet={ clientImage(site.logo.src, {format:"webp" } ) } type="image/webp"/>*/}
                                                    { /** normal **/}
                                                    <source srcSet={ clientImage(site.logo.src, data.params ) } type="image/jpeg"/>
                                                <img src={clientImage(site.logo.src)}
                                                     alt={site.logo.alt}
                                                     data-logo
                                                     title={site.logo.title}
                                                     className={css(styles.logoImg)}/>
                                                </picture>
                                                     : null
                                        }
                                    </span>}

                            </div>

                            || <span className={css(styles.logo)}/>

                            }

                            {/** SHOP BUTTON + DROPDOWN MENU */}
                            <div className={css(styles.navRight)}>
                                {data.showNavigation &&
                                <div>
                                    {!_.get(this.props.page, 'hideSearchBar') &&
                                    !_.get(this.props.site, 'hideSearchBar') &&
                                    _.get(this.props, 'site.ecommerce.enabled') &&
                                    <div id={id(SearchBarWrapper, this.props.site.siteId)}
                                         className={css(this.styles.searchBarWrapper)}/>
                                    }
                                    <NavList03
                                        styles={this.styles}
                                        hideMobile={true}
                                        site={site}
                                        id={this.props.id}
                                        page={page}
                                        global={global}
                                        data={data.menu}/>
                                </div>
                                }
                                <div className={css(this.styles.mobileCart)}>
                                    {cartDiv}
                                </div>
                            </div>


                        </div>

                    </div>
                  </Elements.Column>

                </Elements.Row>

            </Elements.Container>

        )
    }
}

