//import $ from 'jquery';
//"use strict"
const absoluteToFixedHeader = (id) => {

    return (id) => {

        (function() {
            const $currentDiv = $('#' + id);
            const $header = $('#header05');
            const headerHeight = $header.height();
            const $headerNav = $('.headerNav');
            const $iconBar = $('.icon-bar');
            const $nextElement = $currentDiv.next();
            const nextElementHeight = $nextElement.height();
            //logo
            const $logo = $('.logo');
            const $logoaltsrc = $logo.data('altimg');
            const $defaultLogoSrc = $logo.attr('src');


            $header.addClass('absolute');
            $headerNav.addClass('absolute');
            $iconBar.addClass('absolute');
            $logo.attr('src', $logoaltsrc);
            //checks scrolltop of body and inverts head when it has reached the bottom of the next div
            $(window).scroll( function(){
                let bodyScrollHeight = $(window).scrollTop();
                if( bodyScrollHeight >= nextElementHeight - headerHeight && $header.hasClass('absolute') ) {
                    $header.removeClass('absolute');
                    $headerNav.removeClass('absolute');
                    $iconBar.removeClass('absolute');
                    $logo.attr('src', $defaultLogoSrc);
                    return false
                }
                if( bodyScrollHeight <= nextElementHeight - headerHeight  && !$header.hasClass('absolute') ) {
                    $header.addClass('absolute');
                    $headerNav.addClass('absolute');
                    $iconBar.addClass('absolute');
                    $logo.attr('src', $logoaltsrc);
                    return false
                }
            });


        }());

    }

};


export default absoluteToFixedHeader;
