import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import FontFaceObserver from "fontfaceobserver";
import { Frontload } from "@convertly/react-frontload";
import { Provider } from "react-redux";
import { syncHistoryWithStore } from "react-router-redux";
import { applyRouterMiddleware, Router, browserHistory } from "react-router";
import { makeSelectLocationState } from "containers/App/selectors";
import LanguageProvider from "containers/LanguageProvider";
import { useScroll } from "react-router-scroll";
import { translationMessages } from "./i18n";

/**
 * Setup logger level
 */
import Logger from "utils/logger";
import Config from "./config";
Logger.set(Config.LOGGING);

// import { LogRocket } from "./utils/logRocket";
// LogRocket.init();

console.log(
  `%cStarting cms-app with %c${process.env.GRAPH} graph %con %c${process.env.STAGE} stage`,
  "color: red; padding: 10px; background-color: yellow",
  "color: red; padding: 10px; background-color: yellow; font-weight: bold;",
  "color: red; padding: 10px; background-color: yellow",
  "color: red; padding: 10px; background-color: yellow; font-weight: bold;"
);

/**
 * Import css and static assets
 */
import "sanitize.css/sanitize.css";
//import "style-loader!css-loader!./styles/css/bootstrap.min.css";

import "./styles/css/bootstrap.min.css";
import "./styles/css/pegasus-components-reset.css";
import "./styles/css/psudo-bootstrap-grid.css";
import "../node_modules/font-awesome/css/font-awesome.css";
import "./styles/css/_datepicker.css";
import "./styles/css/react-select.css";
import "react-dates/lib/css/_datepicker.css";
import "!!style-loader!css-loader!overlayscrollbars/css/OverlayScrollbars.min.css";
import "!!style-loader!css-loader!react-tippy/dist/tippy.css";
import "./global-styles";
/* eslint-disable import/no-webpack-loader-syntax */
import "!file-loader?name=[name].[ext]!./favicon.ico";
//import "!file-loader?name=[name].[ext]!./manifest.json";
//import "./manifest.json";
import "file-loader?name=[name].[ext]!./.htaccess";
import "./styles/css/sanitize-css-overrides.css";
/* eslint-enable import/no-webpack-loader-syntax */

/**
 * Import store from app.js
 *
 * store has to be defined in app.js because it is imported
 * throughout the app from that file
 */
import { store } from "./app";

const history = syncHistoryWithStore(browserHistory, store, {
  selectLocationState: makeSelectLocationState(),
});

/**
 * Run sagas
 */
import AuthSagas from "./containers/Authentication/sagas";
import EditorSagas from "./containers/Editor/sagas";

AuthSagas.map(saga => store.runSaga(saga));
EditorSagas.map(saga => store.runSaga(saga));

/**
 * Misc stuff
 * pkg is used in mainMenu to render the version at the bottom of the page
 * bootstrap was used to try and only inject bootstrap if not in editor
 */
export const pkg = require("../package.json");
export const bootstrap = ""; //root;

export const uiPkg = require("../node_modules/@convertly/ui-components/package.json");

/**
 * Import App and routes
 * Define our render function
 */
import App from "containers/App";
import Authenticated from "containers/Authentication";
import createRoutes from "./routes";

// Set up the router, wrapping all Routes in the App component
const rootRoute = [
  {
    component: Authenticated(App),
    childRoutes: createRoutes(store),
  },
];

const root = document.getElementById("app");

const render = messages => {
  ReactDOM.render(
    <Frontload noServerRender>
      <Provider store={store}>
        <LanguageProvider messages={messages}>
          <Router
            history={history}
            routes={rootRoute}
            render={
              // Scroll to top when going to a new page, imitating default browser
              // behaviour
              applyRouterMiddleware(useScroll())
            }
          />
        </LanguageProvider>
      </Provider>
    </Frontload>,
    root
  );
};

// Hot reloadable translation json files
if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time

  //module.hot.accept();

  module.hot.addStatusHandler(status => {
    // testing how to update actions files
    // no joy yet
    if (status === "check222") {
      module.hot
        .check({
          ignoreUnaccepted: true,
          ignoreDeclined: true,
          onDeclined: info => {
            console.log("onDeclined module", info);
          },
          onUnaccepted: info => {
            console.log("onUnaccepted module", info);
          },
          onAccepted: info => {
            console.log("onAccepted module", info);
          },
          onDisposed: info => {
            console.log("onDisposed module", info);
          },
          onErrored: info => {
            console.log("onErrored module", info);
          },
        })
        .then(outdatedModules => {
          console.log("outdatedModules", outdatedModules);
        })
        .catch(error => {
          console.log("error", error.message);
        });
    }
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import("intl"));
  })
    .then(() =>
      Promise.all([import("intl/locale-data/jsonp/en.js"), import("intl/locale-data/jsonp/de.js")])
    )
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}

/**
 * Need to check if we use this font
 */
const openSansObserver = new FontFaceObserver("Open Sans", {});
openSansObserver.load().then(
  () => {
    document.body.classList.add("fontLoaded");
  },
  () => {
    document.body.classList.remove("fontLoaded");
  }
);

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === "production") {
  require("offline-plugin/runtime").install(); // eslint-disable-line global-require
}
