import { StyleSheet } from "@convertly/css";
import UserOverrides from "../../elements/style-functions/user-overrides";

export const BlogPostStyles = ({ global, data, inEditor }) => {
  return StyleSheet.create({
    blogPost: {
      ...UserOverrides(global, data, inEditor)
    }
  });
};
