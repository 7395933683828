import _ from 'lodash';

/* Removes nested userOverrides and returns the top level userOverrides

    Example:
      {
        data: {
          userOverrides: {
            display: 'inline-block'
          },
          textLogo: {
            display: 'block'
          },
          imageLogo: {
            display: 'flex'
          }
        }
      }

    Returns:
      {
        data: {
          userOverrides: {
            display: 'inline-block'
          }
        }
      }
*/

export default function removeNestedUserOverrides({ data }) {
  
  if(!_.get(data, 'userOverrides')) {
    return {};
  }

  let mainUserOverrides = JSON.parse(JSON.stringify(data.userOverrides));
  let listOfObjectKeysToBeDeleted = Object.keys(data.userOverrides)

  listOfObjectKeysToBeDeleted = listOfObjectKeysToBeDeleted.filter(key => !!mainUserOverrides[key].userOverrides);

  for(let i = 0; i < listOfObjectKeysToBeDeleted.length; i++) {
    delete mainUserOverrides[listOfObjectKeysToBeDeleted[i]];
  }

  return mainUserOverrides;
}