import { StyleSheet } from "@convertly/css";
import UserOverrides from "../../elements/style-functions/user-overrides";
import styleContainerFn from "../util/styleContainer";

export const DivWrapperStyles = ({ global, data, inEditor }) => {
  const styleContainer = styleContainerFn({ inEditor });

  const enableDefaultStyles = data.enableDefaultStyles === true ? styleContainer : {};

  return StyleSheet.create({
    divWrapper: {
      ...enableDefaultStyles,
      ...UserOverrides(global, data, inEditor)
    }
  });
};
