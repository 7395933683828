import { StyleSheet } from "@convertly/css";
import Typography from '../../../elements/style-functions/typography';
import FooterGlobalStyles from '../footer-global-styles';
import ModuleGlobalStyles from '../../../elements/style-functions/module-global-styles';


const styles = ( global, data ) => {

    const copyTextTypography = new Typography( global, data.copyRightText.text );
    const footerGlobalStyles = new FooterGlobalStyles( global, data );
    const moduleGlobalStyles = new ModuleGlobalStyles( global, data );


    return StyleSheet.create({
        mainFooter : {
            fontFamily:global.fontFamily.primaryFont,
            ...footerGlobalStyles,
            textAlign: 'center',
            ...moduleGlobalStyles
        },
        copyText : {
            color: global.colors.dark.color1,
            ...copyTextTypography
        }
    })
};


module.exports = styles;
