/*global Inferno, $, onYouTubeIframeAPIReady, YT */
/* eslint-disable */

import {include} from '@convertly/thor';

const Scripts = () => {

        const Dispatcher = include('../../../utils/dispatcher', 'Dispatcher');
        const isIOSFn = include('../../../utils/is-ios', 'isIOSFn');

        return (id, videoUrl, videoType, events) => {

            const root = document.getElementById(id);
            const dispatch = Dispatcher(id);
            const onIOS = isIOSFn()();

            dispatch.attach(events);

            const VideoPlayer = Inferno.createClass({

                    getInitialState: function () {

                        dispatch.on('youtube_api_ready', this.registerYT);

                        return {
                            loadVideo: false
                        };

                    },

                    registerYT: function () {

                        // Extraction of the video_id needs to be made
                        // more robust
                        const video_id = videoUrl.replace('https://www.youtube.com/embed/', '');

                        const player = new YT.Player(`${id}_video`, {
                            height: '100%',
                            width: '100%',
                            objectFit: 'cover',
                            videoId: video_id,
                            autoPlay: true,
                            playerVars: {
                                //controls: 0,
                                showinfo: 0,
                                autohide: 1,
                                playsinline: 1,
                                html5: 1,
                                rel: 0,
                                enablejsapi: 3,
                                mute:1,
                            },

                            events: {
                                'onReady': this.PlayerOnReady,
                                'onStateChange': function (e) {
                                    if (e.data === YT.PlayerState.ENDED) {
                                        player.playVideo();
                                    }
                                }
                            }
                        });

                        this.resizeVideo( player )

                    },

                    PlayerOnReady: function (event) {

                        event.target.playVideo();


                    },

                    resizeVideo: function ( video ) {
                        $(window).on('load resize', function() {
                            let w = $(window).width(),
                                h = $(window).height();

                            if (w / h > 16 / 9) {
                                video.setSize(w, w / 16 * 9);
                                $('.video-container').css({'left': '0px'});
                            } else {
                                video.setSize(h / 9 * 16, h);
                                $('.video-container').css({'left': -( $('.video-container').outerWidth - w) / 2});
                            }
                        })
                    },

                    onPlayerReady(e) {
                        if (e.data === YT.PlayerState.ENDED) {
                            player.playVideo();
                        }

                    },


                    injectYoutubeScript: function () {
                        let tag = document.createElement('script');
                        tag.src = "https://www.youtube.com/iframe_api";

                        let firstScriptTag = document.getElementsByTagName('script')[0];
                        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


                    },

                    render: function () {

                        /**
                         * Create Video Player
                         */

                        let videoPlayer;

                        if (videoType === "youtube") {
                            this.injectYoutubeScript();
                        }

                        return (
                            <div className="video-container" id="test">
                                <div id={ `${id}_video` }></div>
                            </div>
                        )
                    }
                }
            );


            Inferno.render(<VideoPlayer/>, root);


        }
            ;

    }
;

export default Scripts;
