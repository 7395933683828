import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MagnifyingGlass from "./magnifyingGlass.svg";
import LeftArrow from "./leftArrow.svg";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 22px;
  grid-gap: 10px;
  height: 22px;
`;

const SearchWrapper = styled.div`
  position: relative;
  border-bottom: 1px solid #c5c5c5;

  img {
    position: absolute;
    left: 0;
    top: 2px;
  }
  input {
    width: 100%;
    border: none;
    padding-left: 20px;
    :active,
    :focus {
      outline: none;
    }
  }
`;

const GoButton = styled.div`
  background: #349886;
  border-radius: 3px;
  width: 22px;
  height: 22px;
  position: relative;
  cursor: pointer;
  img {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, 0%);
  }
`;

export const SearchBar = ({ value, onChange, onSearch, placeholder }) => (
  <Wrapper>
    <SearchWrapper>
      <img src={MagnifyingGlass} />
      <input
        onKeyUp={e => {
          if (e.key === "Enter") {
            onSearch();
          }
        }}
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
      />
    </SearchWrapper>

    <GoButton onClick={onSearch}>
      <img src={LeftArrow} />
    </GoButton>
  </Wrapper>
);

SearchBar.defaultProps = {
  value: "",
  placeholder: "Search",
  onChange: () => {},
  onSearch: () => {}
};

SearchBar.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func
};
