import React from 'react';
import { css } from "@convertly/css";
import Component from '../../../rootComponent';
import Types from '../../../attribute-types';

import { id } from '@convertly/thor';


//Styles
import accordionStyles from './accordion-styles-01';

//Scripts
import toggleScript from '../scripts/toggle';

//Components
import Elements from '../../../elements/index';
import SVG from '../../../svg/index';

//Default Props
import defaultGlobal from '../../../themes/global.js';


//Component
export default class
Accordion01 extends Component {
    constructor ( props ) {
        super( props );

    }

    render() {
        /** Calls editor */
        if( this.props.editor ) {
            return this.editor();
        }

        /**
         * Calls Stylesheet
         **/
        this.styles = accordionStyles( this.props.global, this.props.data );

        /** Generates border in editor */
        const inlineStyle = this.generateStyleAttribute( );


        let rootId = id( toggleScript );

        let displayItems = this.props.data.dropdowns.map( (dropdown, index) => {

            return (
                <li className={ css( this.styles.li_rIVy ) }>
                    <a className={ css( this.styles.toggle ) + ' toggleButton_' + rootId }>
                        { dropdown.heading }
                        <SVG.ArrowHeadOpenDown className={ this.styles.arrow } />
                    </a>
                    <div className={ css( this.styles.dropdownArea ) + ' toggleArea'}>
                        <p className={ css( this.styles.p_vWqP ) }>
                            { dropdown.paragraph }
                        </p>
                    </div>
                </li>
            )

        })

        let styles;

        if( this.props.styles ) {
            styles = this.props.styles;
        }
        else {
            styles = this.styles;
        }


        return (

                <div id={ rootId }
                     { ...this.generateCustomAttributes() }
                     className={ css( this.styles.toggle_drop ) }
                     { ...this.generateDataEditorAttribute() }
                     onClick={( e ) => {
                         this.launchElementalEditor( 'Accordion', e );
                     }}
                     onMouseOver={ (e) => {this.addEditableOutline(e)} }
                     onMouseOut={ (e) => {this.removeEditableOutline(e)} }
                     {...inlineStyle}
                >
                    <ul className={ css( this.styles.ul_xipQ ) + ' accordion_' + rootId}>

                        { displayItems }

                    </ul>
                </div>

        )
    }
}

Accordion01.defaultProps = {
    data: {
        "dropdowns": [
            {
                "heading": "TEST DROPDOWN 1",
                "paragraph": "Williamsburg man braid vegan humblebrag live-edge venmo letterpress sartorial air plant tattooed. Whatever tumblr twee subway tile, church-key crucific vaporware plaid pug drinking venegar fanny pack blue bottle. Gentrify hell of DIY heirloom narwhat farm-to-table, vice cred lomo swag copper mug sustainable paleo."
            },
            {
                "heading": "TEST DROPDOWN 2",
                "paragraph": "Williamsburg man braid vegan humblebrag live-edge venmo letterpress sartorial air plant tattooed. Whatever tumblr twee subway tile, church-key crucific vaporware plaid pug drinking venegar fanny pack blue bottle. Gentrify hell of DIY heirloom narwhat farm-to-table, vice cred lomo swag copper mug sustainable paleo."
            }
        ]
    },
    global: defaultGlobal
};

