import React from 'react';
import Component from '../../rootComponent';
import Types from '../../attribute-types';
import { css } from "@convertly/css";
import ReactHTMLParser from "@convertly/react-html-parser";

//Styles
import blockquoteStyles from './blockquote-styles';


export default class
BlockQuote extends Component {

    constructor ( props ) {

        super( props );
        this.launchElementalEditor = this.launchElementalEditor.bind(this);

    }
    launchElementalEditor(type, field) {
        if( !this.props.notEditable ) {
            this.context.store.dispatch(this.context.store.setActiveComponent(this.props.sort));
            this.context.store.dispatch(this.context.store.setModules(this.props.id));
            this.context.store.dispatch(this.context.store.popEditor({type: type, field: field}));
        }
    }

    render() {

        if( this.props.editor ) {
            return this.editor();
        }
        this.styles = blockquoteStyles( this.props.global, this.props.data );

        let quoteAuthor;
        if( this.props.data.quoteAuthor.text ) {
            quoteAuthor =
                <small className={ css( this.props.parentClassName2, this.styles.author ) }  >
                    { ReactHTMLParser( this.props.data.quoteAuthor.text ) }
                </small>
        }

        return (
            <blockquote
                { ...this.generateCustomAttributes() }
                onClick={ ()=>{ this.launchElementalEditor('blockquote', this.props.elementDataName); }} className={ css( this.props.parentClassName1, this.styles.blockquote ) }>
                <q>{ ReactHTMLParser( this.props.data.blockquote.text ) }</q>
                { quoteAuthor }
            </blockquote>
        );
    }
}

