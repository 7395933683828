import React from 'react';
import Component from '../../../rootComponent';
import { css } from "@convertly/css";
import { id } from '@convertly/thor';


//scripts
import absoluteToFixed from '../scripts/absolute-to-fixed-header';

//defaultProps
import defaultGlobal from'../../../themes/global';
import defaultData from '../header-default-data';

//Styles
import headerStyles from './header-styles-06.js';

//Components
import Nav06 from './components/nav-06';
import Elements from '../../../elements/index';


export class Header06 extends Component {

    constructor( props ) {
        super( props );
    }

    render() {

        this.styles = headerStyles( this.props.global, this.props.data );

        if( this.props.editor ) {
            return this.editor();
        }
        let headerID = this.props.data.position === "overlayFixed" ? id( absoluteToFixed ) : "header";

        return (
            <div id={ headerID } >
                <header id="header06"
                        className={ css( this.styles.header ) }
                        data-elementType="header"
                        itemProp="siteHeader"
                        itemScope="?"
                        itemType="http://schema.org/siteHeader">
                    <Nav06 global={ this.props.global }
                           data={ this.props.data }
                           styles={ this.styles } />
                </header>
            </div>
        )
    }
}

Header06.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
