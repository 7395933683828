import React from 'react';
import Component from 'source/rootComponent';
import { css } from '@convertly/css';
import _ from 'lodash';

// Components
import {HeaderTop} from './components/HeaderTop';
import {HeaderBottom} from './components/HeaderBottom';
import {HeaderTopper} from '../components/header-topper';

// Styles
import headerStyles05 from './styles/headerStyles05';

// Scripts
import getHeaderPosition from '../header-style-functions/get-header-position';

export class Header05 extends Component {
  
  constructor(props) {
    super(props);
  }

  render() {
    
    const inEditor = this.inEditor();
    

    const { global, data, site, page } = this.props;

    let headerPosition = inEditor ? 'static' : getHeaderPosition(data.position, page);
    this.styles = headerStyles05(global, data, inEditor, headerPosition);
    const { header } = this.styles;

    const inlineStyle = this.generateStyleAttribute();


    if (this.props.editor) {
      return this.editor();
    }

    if(headerPosition === 'noHeader') {
      return null;
    }

    return (
      <header
        className={ css(header) } 
        data-elementType="header"
        itemProp="siteHeader"
        itemScope
        itemType="http://schema.org/siteHeader"
        onClick={(e) => {
            this.launchElementalEditor('header', e);
        }}
        onMouseOver={ this.addEditableOutline }
        onMouseOut={ this.removeEditableOutline }
        { ...inlineStyle }
        { ...this.generateDataEditorAttribute() }>
        {
          _.get(data, "headerTopperSettings.display") &&

          <HeaderTopper
            global={ global }
            site={ site }
            styles={ this.styles }
            data={ data.headerTopperSettings }
            inEditor={ inEditor }/>
        }
        <HeaderTop 
          global={ global }
          data={ data }
          site={ site }
          page={ page }
          inEditor={ inEditor }/>
        <HeaderBottom 
          global={ global }
          data={ data }
          site={ site }
          inEditor={ inEditor }
          page={ page }/>
      </header>
    )
  }
}
