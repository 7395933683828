//Container
import convertlySlider from './convertly-slider'
import sliderContainer from './slider-container/slider-container';
//Slides
import fullWidthHeroSlider from './slides/full-width-hero-slide-ghost/full-width-hero-slide-ghost';
import fullWidthHeroSliderOverlay from './slides/full-width-hero-slide-overlay/full-width-hero-slide-overlay';
import containedSlideAbsolute from './slides/contained-slide-absolute/contained-slide-absolute';
//Navs
import circularNavIcons from './slider-nav/circular-nav-icons-small/circular-nav-icons-small';
import circularNavIconsLarge from './slider-nav/circular-nav-icons-large/circular-nav-icons-large';
import titleNav from './slider-nav/title-nav/title-nav';
//Side Arrows
import middleArrowNavigation from './arrow-navigation/middle-arrow-navigation/middle-arrow-navigation';

const SliderContainer = {
    //Conatiners
    convertlySlider: convertlySlider,
    sliderContainer: sliderContainer,


    //Slides
    fullWidthHeroSlider: fullWidthHeroSlider,
    containedSlideAbsolute: containedSlideAbsolute,
    fullWidthHeroSliderOverlay: fullWidthHeroSliderOverlay,

    //Navs
    circularNavIcons: circularNavIcons,
    circularNavIconsLarge: circularNavIconsLarge,
    titleNav:titleNav,

    //Arrow Navigation
    middleArrowNavigation: middleArrowNavigation

};

export default SliderContainer;