//import $ from 'jquery';
//"use strict"
const activeNavLink = (id) => {

    return (id) => {

        let navLinks =  $('#' + id);

        navLinks.on('click', function(e) {
            navLinks.removeClass('active');
            $(e.target).addClass('active');
        });

    }

};


export default activeNavLink;
