/**
 * Alt styles for the box selector widget in
 * product grid when this displays in the image overlay
 * @param global
 * @param data
 * @returns style object
 */
export default function StyleObject(global, data) {
	
	return {
		".box-container": {
			height: '70px',
			overflowX: "auto",
			overflowY: 'hidden',
			
			backgroundColor: 'transparent',
			"-webkitBackgroundClip": 'text',
			transition: "all .8s",
			borderColor:'transparent',
			"::-webkit-scrollbar": {
				height: '8px'
			},
			"::-webkit-scrollbar-thumb": {
				backgroundColor: 'inherit',
				borderRadius: '25px',
				borderWidth:'1px',
				borderStyle:'solid',
				borderColor: `inherit`,
			},
			":hover": {
				backgroundColor: `#3337`,
				borderColor:'#9997',
			},
			
		},
		".scrollable-wrapper": {
			width: "max-content"
		},
		".box-selector-container": {
			border: `2px solid ${global.colors.light.color1}`,
			display: 'inline-block',
			padding: '12px 13px',
			margin: '10px',
			fontSize: '14px',
			color: global.colors.light.color1,
			transition: '600ms all ease',
			cursor: 'pointer',
			".active": {
				background: global.colors.light.color1,
				borderColor: '#667785',
				color: '#667785',
			},
		},
		".box-selector-container-active": {
			":hover": {
				background: global.colors.light.color1,
				borderColor: '#667785',
				color: '#667785',
			},
		},
		".box-selector-wrapper": {
			display: 'inline'
		},
		".box-selector-container-disabled": {
			borderStyle: 'solid',
			borderColor: "#333",
			backgroundColor: "#333",
			color: "#a1a1a1",
			cursor: "not-allowed"
		}
	}
	
	
}