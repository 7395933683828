import ElectricBlackAccountPage from './electric-black-account-page/account-page';
import ForgotPasswordEmail from './forgot-password/forgot-password-email/forgot-password-email';
import ResetPassword from './forgot-password/reset-password/reset-password';

const accountsPage = {

    ElectricBlackAccountPage: ElectricBlackAccountPage,
    ForgotPasswordEmail: ForgotPasswordEmail,
    ResetPassword:ResetPassword


};

export default accountsPage