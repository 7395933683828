/*global Inferno, $, React */
import React from "react";

const ContestFormScriptGenerator = () => {


    const ContestForm = ({
                             allowPhotoDescription,
                             privacyText,
                             submitFormData,
                             filename,
                             updateFileName,
                             completedSuccess = false,
                             tabSettings,
                             status,
                             customMarketingText,
                             removeFile,
                             pending
                         }) => {

        function renderTabSettings() {
            if(!tabSettings || tabSettings.length === 0) return null;
            return (
                <div>
                <label htmlFor={"tabId"} className={'contest-form-label'}>Category</label>
                <select name={"tabId"} className={"contest-form-input"}>
                    {tabSettings.map( tab => {
                        return <option value={tab.id}>{tab.name}</option>
                    } )}
                </select>
                </div>
            )
        }

        if (completedSuccess) {
            return (
                <ContestFormMessage
                    title={"Thank you!"}
                    text={"Your submission has been received! You'll get a notification once it has been reviewed."}
                />
            )
        }

        if (status === "draft") {
            return (
                <ContestFormMessage
                    title={"Coming Soon!"}
                    text={"This contest has not yet begun.  Please check back soon!"}
                />
            )
        }

        if (status === "completed") {
            return (
                <ContestFormMessage
                    title={"Sorry, this contest has closed."}
                    text={"This contest has closed. Feel free to review what was submitted. Check back some time soon."}
                />
            )
        }

        return (
            <form onSubmit={submitFormData} className={"contest-form"}>
                {/** FIRST NAME **/}
                <label htmlFor="firstName" className={'contest-form-label'}>First Name</label>
                <input className={"contest-form-input"}
                       name={"firstName"}
                       required
                       type={"text"}
                       placeholder={"First Name"}
                />
                {/** LAST NAME **/}
                <label htmlFor="lastName" className={'contest-form-label'}>Last Name</label>
                <input className={"contest-form-input"}
                       name={"lastName"}
                       required
                       type={"text"}
                       placeholder={"Last Name"}
                />
                {/** EMAIL **/}
                <label for="email" className={'contest-form-label'}>Email</label>
                <input className={"contest-form-input"}
                       name={"email"}
                       required
                       type={"text"}
                       placeholder={"Test@gmail.com"}
                />
                {/** IMAGE NAME **/}
                <label for={"imageName"} className={'contest-form-label'}>Image Name</label>
                <input className={"contest-form-input"}
                       name={"imageName"}
                       required
                       type={"text"}
                       placeholder={"Image name goes here"}
                />
                {
                    renderTabSettings()
                }
                {/** IMAGE DESCRIPTION **/}
                {
                    allowPhotoDescription &&
                    <div>

                        <label for={"imageDescription"} className={'contest-form-label'}>Image Description</label>
                        <textarea className={"contest-form-textarea"}
                                  name={"imageDescription"}
                                  rows={3}
                                  placeholder={"Awesome description goes here."}/>
                    </div>
                }
                {/** MARKETING CLAUSE **/}
                <div className={"marketing-clause"}>
                    <input type={"checkbox"}
                           defaultValue={true}
                           defaultChecked={true}
                           name={"marketingPermissionGranted"}/>
                    <label htmlFor={"marketingPermissionGranted"}
                           style={{marginLeft: "5px"}}

                           className={'contest-form-label'}>{customMarketingText || "Send me info about more contests"}</label>
                </div>
                <label className={'contest-form-label'}>Your Image</label>
                {/** FILE NAME **/}
                    <div className={ `contest-file-handler ${filename ? "contest-file-handler-display": ""}` }>
                    <p>{filename}</p>
                    <p className={'remove-file'} onClick={removeFile}>x</p>
                    </div>
                {/** FILE UPLOAD **/}
                    <div>
                        <div className={`${filename ? "" : "contest-file-upload-wrapper-display"} contest-file-upload-wrapper`}>
                            <div className={'contest-file-upload'}>
                                <CloudIcon/>
                                <p>Upload File</p>
                                <input required
                                       id="contestFileUpload"
                                       type="file"
                                       name={"image"}
                                       onChange={updateFileName}
                                       accept="image/*"
                                       className="contest-file-input"/>
                            </div>
                            <div>


                                <p className={'contest-file-upload-size'}>Maximum File Size 1<small>mb</small></p>

                            </div>

                        </div>

                    </div>
                <input className="contest-form-submit" disabled={pending} type="submit" value={ pending ? "Processing...." : "Submit" }/>
                {privacyText && <small>{privacyText}</small>}
            </form>
        )
    }

    function ContestFormMessage({title, text}) {
        return (
            <div className={"contest-form"}>
                <h2>{title}</h2>
                <p>{text}</p>
            </div>)
    }

    function CloudIcon() {
        return (
            <div
                className={`contest-form-cloud-icon`}>
                <svg id="Layer_1" viewBox="0 0 122.88 88.98"
                     style={{"enable-background": "new 0 0 122.88 88.98"}}>

                    <g>
                        <path className="st0"
                              d="M85.33,16.83c12.99-9.83,31.92,1.63,31.92,13.63c0,7.75-2.97,10.79-7.57,14.03 c23.2,12.41,12.7,39.86-7.54,44.49l-70.69,0c-33.2,0-45.48-44.99-10.13-55.89C14.69,6.66,66.5-17.2,85.33,16.83L85.33,16.83z M53.37,69.54V53.66H39.16l22.29-26.82l22.29,26.82H69.53v15.88H53.37L53.37,69.54z"/>
                    </g>
                </svg>
            </div>
        )
    }

    return ContestForm


}

export default ContestFormScriptGenerator



