import React from 'react';
import PropTypes from 'prop-types';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import {generateId} from '../../../utils/generate-attribute';
import {id} from '@convertly/thor';
import {clientImage} from '../../../utils/generateImage';
import _ from 'lodash';

//Components
import HeaderNavLink1 from './header-nav-link-1';
import Widgets from '../../../widgets';
import SearchBarWrapperHeader01 from '../../scripts/inferno-components/search-bar-wrapper/SearchBarWrapperHeader01';

//Scripts
import cartHandler from '../../scripts/cart-script';
import isSignedIn from '../../scripts/is-signed-in';


export default class HeaderNavList2 extends Component {
    constructor(props) {
        super(props);
    }

    render() {



        const {styles, data, site, displaySocial, global} = this.props;

        let className = '';
        let aphroditeClass = css(styles.headerNav);

        if (this.animatedClassName) {
            className += " " + this.animatedClassName
        }
        className += aphroditeClass;

        const {animated} = data;
        const inlineStyle = this.generateStyleAttribute();

        return (
            <div id="menu_bar" className="header-nav-list-2">
                <ul { ...this.generateDataEditorAttribute() }
                    {...generateId(this.props.elementId)}
                    onClick={(e) => {
                        this.launchElementalEditor('nav', e);
                    }}
                    onMouseOver={ this.addEditableOutline }
                    onMouseOut={ this.removeEditableOutline }
                    { ...inlineStyle }
                    data-animated={ animated }
                    className={ className }>
                    { 
                      !_.get(this.props.page, 'hideSearchBar') &&
                      _.get(this.props, 'site.ecommerce.enabled') &&
                      <div id={ id(SearchBarWrapperHeader01, this.props.site.siteId)}
                          className={ `${ css(styles.searchBarWrapper) } mobile` }/>
                    }
                    {  data.navLinks.map((navItem, index) => {
                        return (
                            <HeaderNavLink1 global={ this.props.global }
                                            key={ "headerNavLink" + index }
                                            id={index}
                                            site={ site }
                                            page={ this.props.page }
                                            data={ navItem }
                                            styles={ styles }/>
                        )
                    })}
                </ul>

                <div className={ css(styles.headerBottomLinks) }>
                    {/*Hiding until search is set up
                     <div className={ css( styles.navListItem ) }>
                     <form id="searchform1" className={ css( styles.headerSearchForm ) }>
                     <span className={ css( styles.searchIcon ) } >Search</span>
                     <input type="text"
                     name="search"
                     className={ css( styles.navSearchBar ) }
                     placeholder="Search" />
                     <input type="submit"
                     id="searchsubmit"
                     className={ css( styles.navSearchButton ) }
                     />
                     </form>
                     </div>
                     */}
                    <div className={ css(styles.navListItem) }>
                        <a className={ css(styles.navListItemLink) }
                           id={ id(cartHandler, this.props.clientLinkUrl, this.props.clientImgSrc, "true") }
                           href="/cart">Cart</a>
                    </div>
                    <div className={ css(styles.navListItem)}>
                        <a href="/account"
                           id={ id(isSignedIn, this.props.clientLinkUrl) }
                           className={ css(styles.navListItemLink) }>Sign In</a>
                    </div>
                    { displaySocial.headerTopper &&
                    displaySocial.socialIcons &&
                    _.get(site, 'social') &&
                    <div>
                        <Widgets.SocialSharing01
                            data={ {socialIcons: site.socialIcons} }
                            global={ global }
                            site={ site }
                            styles={ styles }
                            socialIconLinkClass={ styles.socialIconContainer }/>
                    </div>
                    }

                    <div className="clearfix"/>
                </div>

            </div>
        )
    }
}

/**
 * Connects element to context for cms editor
 **/
HeaderNavList2.contextTypes = {
    store: PropTypes.object
};
