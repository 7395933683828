import React from 'react';
import Component from '../../rootComponent';

export default class ContainerFluid extends Component {
    constructor ( props ) {
        super( props );
    }

    render() {
        return (
            <div className="container-fluid"
                 { ...this.generateCustomAttributes() }
            >
                {this.props.children}
            </div>
        )
    }
}
