import { createAction } from "redux-act";
import _ from "lodash";
import { fromJS } from "immutable";
import { store } from "app/app";
import Logger from "utils/logger";
import { graph, purgeSiteCache } from "app/utils";

import { toJS } from "immutable";

export const setBusinessInfo = createAction("Site Settings: Set business info");
export const setLoaded = createAction("Site Settings: Set business info loaded flag");

export const setBusinessInfoSnapshot = createAction("Site Settings: Business Info Snapshot");

export const setBusinessInfoLoaded = createAction("Site Settings: setBusinessInfoLoaded");

export function updateBusinessInfo(businessInfo) {
  return async dispatch => {
    await dispatch(setBusinessInfo(fromJS(businessInfo)));
  };
}

export function saveBusinessInfo() {
  return async dispatch => {
    const siteId = store.getState().getIn(["auth", "activeSite", "id"]);
    const businessInfo = store
      .getState()
      .get("editor")
      .BusinessInfo.get("businessInfo");

    const query = `
      mutation( $id:ID!, $settings:siteGeneralSettingsInput ) {
        updateSiteGeneralSettings(id:$id, settings: $settings ) {
        id
        }
      }
      `;

    const variables = {
      id: siteId,
      settings: businessInfo.toJS(),
    };

    await graph({ query, variables });
  };
}

export const loadBusinessInfo = () => async dispatch => {
  const siteId = store.getState().getIn(["auth", "activeSite", "id"]);

  const query = `
      query ($id: ID! ) {
        Site(id: $id) {
        generalSettings {
          businessName
          address
          city
          state
          zip
          country
          phone
          fax
          email
        }
        }
      }`;

  const variables = {
    id: siteId,
  };

  const response = await graph({ query, variables });
  const businessInfo = _.get(response, "Site.generalSettings") || {};

  await dispatch(setBusinessInfo(fromJS(businessInfo)));
  await dispatch(setBusinessInfoLoaded(true));
};

export const takeBusinessInfoSnapshot = () => async dispatch => {
  const businessInfo = store
    .getState()
    .get("editor")
    .BusinessInfo.get("businessInfo");
  await dispatch(setBusinessInfoSnapshot(businessInfo));
};

export const revertBusinessInfoSnapshot = () => async dispatch => {
  const businessInfoSnapshot = store
    .getState()
    .get("editor")
    .BusinessInfo.get("businessInfoSnapshot");
  await dispatch(setBusinessInfo(businessInfoSnapshot));
};
