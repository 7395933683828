import { StyleSheet } from "@convertly/css";
import UserOverrides from '../../../elements/style-functions/user-overrides';


const slideStyles = ( global, data, sliderSettings ) => {

    const userOverrides = new UserOverrides( global, data );

    return StyleSheet.create({

        convertlyControlNav: {
            width: '100%',
            position: 'absolute',
            top:'50px',
            padding:0,
            zIndex: 5,
            ...userOverrides

        },
        convertlyControlNavItem: {
            margin: '0 10px',
            display: 'inline-block',
            width: '40px',
            height: '40px',
            background: global.colors.light.color3,
            cursor: 'pointer',
            textIndent: '-9999px',
            borderRadius: '100%',
            ':before': {
                fontSize: '0',
                display: 'inline-block',
                color: 'rgba(0, 0, 0, 0.8)',
                textShadow: '1px 1px 0 rgba(255, 255, 255, 0.3)',
                opacity: '1'
            },
            '.active': {
                background:global.colors.light.white
            },
            ':hover': {
                background:global.colors.light.white
            },
            ':first-child': {
                marginLeft:0
            }
        }
    })
};

export default slideStyles;