/*global Inferno, $, React */

/** Todo input component for ui todo-list-02 **/

const TodoInput = () => {

    return ({todoInputValue, rootClass}) => {

        return (
            <form data-function="addTodo">
                <input 
                  type="text"
                  data-function='updateInputValue'
                  value={ todoInputValue }/>
                <input
                    type="submit"
                    value="submit"/>
            </form>
        );
    }
};

export default TodoInput;