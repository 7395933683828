import PropTypes from 'prop-types';
import React from "react";
import { saveProductAction } from "../../../containers/StoreFront/ProductDetail/actions";
import PillButton from "../../Button/pillButton";
import Options from "../../../containers/Editor/components/editor/options";
import { browserHistory } from "react-router";

export default class CustomerDetailMenu extends React.Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      showLoadIcon: false
    };

    this.save = this.save.bind(this);
    this.backToCustomers = this.backToCustomers.bind(this);
  }

  backToCustomers() {
    browserHistory.push("/storefront/customers");
  }

  save(e) {
    const { site } = this.props;

    this.setState({ showLoadIcon: true });

    setTimeout(() => {
      this.setState({ showLoadIcon: false });
    }, 1500);

    const product = Object.assign({}, this.props.product.toJS());
    product.images = product.images.map(image => {
      return image.split("/").pop();
    });

    const payload = { product, productId: product.id, site: site.toJS() };

    this.context.store.dispatch(saveProductAction(payload));
  }

  /**
   *
   * @returns {*}
   */
  render() {
    const inlineCss = {
      spacing: {
        marginRight: "20px"
      },
      button: {
        cursor: "pointer"
      },
      container: {
        width: "100%"
      }
    };

    const options = [
      {
        optionsItemTitle: "View Customer",
        optionsCallback: this.props.editOption
      },
      {
        optionsItemTitle: "Duplicate",
        optionsCallback: this.props.makePrimary
      }
    ];

    return (
      <div style={inlineCss.container}>
        <PillButton label="Back" action={this.backToCustomers} />
      </div>
    );

    return (
      <div style={inlineCss.container}>
        <span style={inlineCss.spacing}>Storefront</span>
        <PillButton
          label="Save"
          backgroundColor="#52AF52"
          textColor="#FFF"
          action={this.save}
          showLoadIcon={this.state.showLoadIcon}
          width={70}
        />
        <PillButton label="Back" action={this.save} />
        <Options options={options} button resetIndex={() => {}} />
      </div>
    );
  }
}

CustomerDetailMenu.contextTypes = {
  store: PropTypes.object
};
