import { StyleSheet } from '@convertly/css';
import userOverride from '../../elements/style-functions/user-overrides';
import removeNestedUserOverrides from '../../site-header/site-header/utils/removeNestedUserOverrides';
import _ from 'lodash';

const FooterLinkStyles = ({ global, data, inEditor }) => {

  const footerLinksWrapper = removeNestedUserOverrides({ data });

  const {
    userOverrides
  } = data || {};

  const {
    footerLink
  } = userOverrides || {};

  const footerLinksWrapperUserOverrides = userOverride(global, { userOverrides: footerLinksWrapper }, inEditor);
  const footerLinkUserOverrides = _.get(footerLink, 'userOverrides') ? userOverride(global, footerLink, inEditor) : {};

  return StyleSheet.create({

    footerLinksWrapper: {
      ...footerLinksWrapper
    },

    footerLink: {
      fontFamily: global.fontFamily.secondaryFont,
      ...footerLinkUserOverrides
    }

  })

}

export default FooterLinkStyles;