import React from "react";
import styled from "styled-components";
import {GLOBALS} from "../../styles/global-default-styles";

const rangeStyles = `
  appearance: none;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background: ${GLOBALS.colorPrimary};
  cursor: pointer;
  vertical-align:center;
  margin-top:-6px;
`

const trackStyles = `
  width: 100%;
  height: 3px;
  cursor: pointer;
  background: ${GLOBALS.colorSecondaryLightest};
  border-radius: 5px;
`

const SliderComp = styled.input`
  max-width:100%;
  appearance:inherit;
  & ::-webkit-slider-thumb {
    ${rangeStyles}
  }
  & ::-webkit-slider-runnable-track {
    ${trackStyles}
  }
  & ::-moz-range-thumb {
    ${rangeStyles}
  }
  & ::-moz-range-track {
    ${trackStyles}
  }
  & ::-ms-thumb {
    ${rangeStyles}
  }
  & ::-ms-track {
    ${trackStyles}
  }
`;

export const Slider = ({value, cb, min=0, max=100, step=1}) => {


  return(
    <SliderComp
      type="range"
      id="points"
      name="points"
      value={value}
      onChange={cb}
      min={min}
      max={max}
      step={step}/>
  )
}
