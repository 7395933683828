//import $ from 'jquery';

const toggleNavDropdown = (id) => {
  return (id) => {
    const $el = $(`#${id}`);

    $el.on('mouseover', (e) => {
      e.preventDefault();

      $elSibling = $el.next();

      
      if($elSibling.hasClass('open')) {
        $elSibling.toggleClass('open');
      } else {
        $("div[id='navDropdownMenu']").removeClass('open');
        $elSibling.toggleClass('open');
      }

      $elSibling.on('mouseleave', () => {
        $elSibling.removeClass('open');
      })
    })
    
  }
}

export default toggleNavDropdown;
