import { StyleSheet } from '@convertly/css';
import userOverrides from '../../elements/style-functions/user-overrides';
import _ from 'lodash';

const mainSiteHeaderStyles = ({ global, data, inEditor }) => {

  let mainSiteHeaderUserOverrides = userOverrides(global, data, inEditor);
  
  return StyleSheet.create({
    mainSiteHeader: {
      position: inEditor ? 'static' : (_.get(data, 'position') || 'static'),
      width: 'inherit',
      ...mainSiteHeaderUserOverrides
    }
  })

}

export default mainSiteHeaderStyles;