import React from "react";
import { fromJS, List, Map } from "immutable";
import PropTypes from "prop-types";
import styled, { keyframes, css } from "styled-components";

import { GenericModal } from "components/GenericModal";
import { setAlertConfirmation } from "../App/actions";

const enterTransition = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, 0) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translate(-50%, 0) scale(1);
  }
`;

const exitTransition = keyframes`
    from {
      opacity: 1;
      transform: translate(-50%, 0) scale(1);
    }

    to {
      opacity: 0;
      transform: translate(-50%, 0) scale(.75);
    }
  `;

export const Underlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2900;
`;

const Input = styled.input`
  display: block;
  margin-top: 10px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #c5c5c5;
  padding: 8px 16px;
  outline: none;
`;

const activeBg = `linear-gradient(90deg, rgba(139,217,178,1) 0%, rgba(69,170,145,1) 100%)`;
const disabledBg = `linear-gradient(90deg, rgba(139,217,178,.5) 0%, rgba(69,170,145,.5) 100%)`;

export const Button = styled.div`
  align-self: center;
  justify-self: center;
  padding: 10px;
`;

export const SaveButton = styled(Button)`
  color: white;
  background: ${props => (props.enabled ? activeBg : disabledBg)};
  height: 100%;
  width: 100%;
  display: grid;

  span {
    place-self: center;
  }
`;

export const CancelButton = styled(Button)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MessageBody = styled.div`
  place-self: center;
  padding: 20px;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  cursor: pointer;
  border-top: 1px solid #c5c5c5;
`;

export const ButtonWrapper = styled(ButtonsWrapper)`
  grid-template-columns: 1fr;
`;

const animation = props => css`
  ${props.transition === "enter" ? enterTransition : exitTransition} 100ms linear forwards
`;

export const ModalWrapper = styled.div`
  animation: ${animation};
  position: fixed;
  left: 50%;
  top: 75px;
  width: ${({ width }) => width}px;
  z-index: 9999;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
`;

ModalWrapper.defaultProps = {
  width: 400,
};

function runValidations(validations, value) {
  const results = [];

  validations.forEach(validation => {
    results.push(validation(value));
  });

  const reducer = (accumulator, currentValue) => {
    const current = currentValue ? 0 : 1;
    return accumulator + current;
  };

  return results.reduce(reducer, 0) === 0;
}

export const Alerts = ({ alert, dispatch, settings, updateSetting, id }) => {
  if (!alert) {
    return null;
  }

  let message = alert.get("message");
  const type = alert.get("type");

  if (List.isList(message)) {
    message = message.toJS().join(". ");
  }

  if (Map.isMap(message)) {
    message = message.toJS();
  }

  const transition = settings.get("alert-transition");

  /**
   * Yes/No confirm before continuing
   */
  if (type === "confirm") {
    return (
      <div>
        <ModalWrapper transition={transition}>
          <MessageBody>{message}</MessageBody>
          <ButtonsWrapper>
            <CancelButton onClick={async () => await dispatch(setAlertConfirmation(false))}>
              {alert.get("cancelLabel") || "Cancel"}
            </CancelButton>
            <SaveButton
              enabled={true}
              onClick={async () => await dispatch(setAlertConfirmation(true))}
            >
              <span>{alert.get("label") || "Confirm"}</span>
            </SaveButton>
          </ButtonsWrapper>
        </ModalWrapper>

        <Underlay />
      </div>
    );
  }

  /**
   * Confirm/Save with input field
   */
  if (type === "prompt") {
    // validations is array of functions
    // function( value ) {}
    // should return boolean true for valid, false for invalid

    const value = settings.get("alert-prompt-value");
    const placeholder = alert.get("placeholder") || "";
    const validations = (alert.get("validations") || fromJS([])).toJS();
    const isValid = runValidations(validations, value);

    return (
      <div>
        <ModalWrapper transition={transition}>
          <MessageBody>
            {message}
            <Input
              value={value}
              onChange={e => updateSetting("alert-prompt-value", e.target.value)}
              placeholder={placeholder}
            />
          </MessageBody>
          <ButtonsWrapper>
            <CancelButton onClick={async () => await dispatch(setAlertConfirmation(false))}>
              Cancel
            </CancelButton>
            <SaveButton
              enabled={!!value && isValid}
              onClick={
                !!value && isValid && (async () => await dispatch(setAlertConfirmation(value)))
              }
            >
              <span>{alert.get("label") || "Confirm"}</span>
            </SaveButton>
          </ButtonsWrapper>
        </ModalWrapper>

        <Underlay />
      </div>
    );
  }

  if (type === "alert") {
    return (
      <div>
        <ModalWrapper transition={transition}>
          <MessageBody>{message}</MessageBody>
          <ButtonWrapper>
            <SaveButton
              enabled={true}
              onClick={async () => await dispatch(setAlertConfirmation(true))}
            >
              <span>{alert.get("label") || "Confirm"}</span>
            </SaveButton>
          </ButtonWrapper>
        </ModalWrapper>

        <Underlay />
      </div>
    );
  }

  return (
    <GenericModal
      explicitClose={true}
      onClose={async () => await dispatch(setAlertConfirmation(false))}
      onSave={async () => await dispatch(setAlertConfirmation(true))}
      rightButtonLabel={"Confirm"}
      title={message}
      render={() => null}
      showButtons={type === "confirm"}
    />
  );
};

Alerts.defaultProps = {
  alert: fromJS({}),
  dispatch: async () => {},
};

Alerts.propTypes = {
  alert: PropTypes.object,
  dispatch: PropTypes.func,
};
