import { StyleSheet } from '@convertly/css';

import UserOverrides from '../style-functions/user-overrides';
import _ from 'lodash';
const imageStyles = ( global, data, inEditor ) => {

    const userOverRides = UserOverrides( global, data, inEditor );


    let imageOverRides
    if (data.imageOverrides) {
        imageOverRides = UserOverrides( global, data.imageOverrides, inEditor );
    }
    else {
        imageOverRides =  {}
    }

    return StyleSheet.create({

        figure: {
            maxWidth:'100%',
            width:'fit-content',
            height:'auto',
            position:'relative',
            cursor: _.get(data, 'enableLightbox') ? 'pointer' : 'default',
			...imageOverRides,
			...userOverRides,

        },
        image: {
            maxWidth:'100%',
            width:'fit-content',
            height:'auto',
            position:'relative',
            display:'none',
        },
        figCaption: {
            ...global.figCaption
        }
    });

}

export default imageStyles;
