import  colorAssign  from '../colorAssign';

let defaultGlobal = {
    //Colors 
    colors: {
        dark: {
            color1: '#002550',
            color2: '#5f5f5f',
            color3: '#64778C',
            black: '#000'
        },
        accent: {
            color1: '#7F6aa6',
            color2: '#72aad3',
            color3: '#989fe8'
        },
        light: {
            color1: '#f9f9fa',
            color2: '#fcf3e8',
            color3: '#999a9a',
            white: "#fff"
        }
    },
    
    textColors: {
        light:{},
        dark:{},
        accent:{}
    },
    backgroundColors: {
        light:{},
        dark:{},
        accent:{}
    },
    // Module Default wrapper
    moduleDefault: {
        margin:'50px 0',
        padding:'50px 20px',
    },
   /** Misc Styles */
    themeBorder: {
        border: '3px solid'
    },
    divider: {
        backgroundColor: "#2d343d",
        width: "100%",
        height: "3px",
        marginTop: "10px",
        marginBottom: "10px"
    },
    figCaption: {
        position:'absolute',
        bottom:'5px',
        background:'rgba(0, 0, 0, .5)',
        width:'100%',
        padding:'30px 20px',
        color:'#fff',
        "@media (max-width: 479px)": {
            padding:'10px'
        }
    },
    bodyBackgroundColor: '',
/** Typography **/
    fontFamily: {
        primaryFont: "'Work Sans', sans-serif",
        secondaryFont: " 'Montserrat', sans-serif"
    },
    fonts: [ 'Work Sans', 'Montserrat' ],

    h1: {
       
        fontSize: "85px",
        margin:'20px 0',
        lineHeight: "95px",
        "@media (max-width: 991px)": {
            fontSize: "70px",
            lineHeight: "80px"
        },
        "@media (max-width: 767px)": {
            fontSize: "50px",
            lineHeight: "60px"
        },
        "@media (max-width: 479px)": {
            fontSize: "40px",
            lineHeight: "50px"
        }
    },
    h2: {
      
        margin:'10px 0',
        fontSize: '35px',
        lineHeight: '45px',
    },
    h3: {
        
        fontSize: '27px',
        lineHeigth:"37px"
    },
    h4: {
     
        fontSize: '14px',
        lineHeight: '24px',
    },
    h5: {
      
        fontSize: '34px',
        lineHeight:'44px',
    },
    h6: {
       
        fontSize:'12px',
        lineHeight:'22px',
    },
    p: {
        
        margin: '10px 0',
        fontSize: '15px',
        lineHeight: '25px',
    },
    list: {
      
        marginBottom: '20px',
        paddingLeft: '30px'
    },
    listItem: {
        marginBottom:'10px'
    },
    // Buttons
    button1: {
        display: 'inline-block',
        marginBottom: '15px',
        padding: '10px 25px',
        border:'none',
        fontSize:'14px'
    },
    button2: {
        display: 'inline-block',
        marginBottom: '15px',
        padding: '20px 45px',
        border:'none',
        fontSize:'14px'
    },
    button3: {
        display: 'inline-block',
        marginBottom: '15px',
        padding: '40px 100px',
        fontSize:'18px'
    },
    // FORM
    form: {
        height: 'auto',
        padding: "30px",
        width: '100%'
    },
    formErrorMessage: {
        color: "red",
        fontSize:'12px'
    },
    input: {
        height: "38px",
        width: '100%',
        "::placeholder": {},
        fontSize: '16px',
        lineHeight: '16px',
        border: "1px solid",
        borderRadius: '2px',
        padding: "8px 12px",
        marginBottom: '10px'   
    },
    selectInput: {
        borderRadius: '2px',
        width: '100%',
        height: "40px",
        opactiy: "0",
        appearance: 'none',
        padding: "10px"
    },
    svgDiv: {
        height: '40px'
    },
    checkboxInput:{
        marginRight: "5px",
        marginLeft: "5px",
    },
    checkboxLabel:{},
    radioInput:{
       marginRight: "5px",
       marginLeft: "5px",
    },
    textArea: {
        width: "100%",
        "::placeholder": {}
    },
    radioLabel:{},
    submitButton:{
        fontWeight: "700",
        border: "none",
        padding: "10px 25px"
    },
    label: {
        fontSize: "16px",
        marginBottom: "5px"
    }
};

export default function theme ( global ) {

    global = global ? global : defaultGlobal;

    //colorAssign( global );

    //Body background styles
    global.bodyBackgroundColor = global.bodyBackgroundColor || global.backgroundColors.light.color2;
    
    // Module default wrapper styles
    global.moduleDefault.fontFamily = global.fontFamily.primaryFont;

    // Theme Border 
    global.themeBorder.borderColor = global.themeBorder.borderColor || global.backgroundColors.dark.color1;

    // Form Styles

    global.input.backgroundColor = global.backgroundColors.light.white;
    global.input.borderColor = global.backgroundColors.light.color3;
    global.input.color = global.textColors.dark.color2;
    global.input.fontFamily = global.fontFamily.primaryFont;
    global.input["::placeholder"].color = global.textColors.light.color3;
    global.input["::placeholder"].fontFamily = global.fontFamily.primaryFont;

    global.textArea.backgroundColor = global.backgroundColors.light.white;
    global.textArea.borderColor = global.backgroundColors.light.color3;
    global.textArea.color = global.textColors.dark.color2;
    global.textArea.fontFamily = global.fontFamily.primaryFont;
    global.textArea["::placeholder"].color = global.textColors.light.color3;
    global.textArea["::placeholder"].fontFamily = global.fontFamily.primaryFont;

    global.selectInput.backgroundColor = global.backgroundColors.light.white;
    global.selectInput.borderColor = global.backgroundColors.light.color3;
    global.selectInput.color = global.textColors.dark.color1;
    global.selectInput.fontFamily = global.fontFamily.primaryFont;

    global.svgDiv.backgroundColor = global.backgroundColors.light.white;

    global.checkboxLabel.color = global.textColors.dark.color2;
    global.checkboxLabel.fontFamily = global.fontFamily.primaryFont;
    
    global.radioLabel.color = global.textColors.dark.color2;
    global.radioLabel.fontFamily = global.fontFamily.primaryFont;

    global.submitButton.color = global.textColors.light.white;
    global.submitButton.backgroundColor = global.backgroundColors.dark.color1;
    global.submitButton.fontFamily = global.fontFamily.primaryFont;

    global.label.color = global.textColors.dark.color1;
    global.label.fontFamily = global.fontFamily.primaryFont;

    // Typography styles
    global.h1.color = global.textColors.dark.color1;
    global.h2.color = global.textColors.dark.color1;
    global.h3.color = global.textColors.dark.color1;
    global.h4.color = global.textColors.dark.color2;
    global.h5.color = global.textColors.dark.color1;
    global.h6.color = global.textColors.dark.color1;
    global.p.color = global.textColors.dark.color2;
    global.list.color = global.textColors.dark.color2;

    global.h1.fontFamily = global.fontFamily.primaryFont;
    global.h2.fontFamily = global.fontFamily.primaryFont;
    global.h3.fontFamily = global.fontFamily.primaryFont;
    global.h4.fontFamily = global.fontFamily.secondaryFont;
    global.h5.fontFamily = global.fontFamily.secondaryFont;
    global.h6.fontFamily = global.fontFamily.secondaryFont;
    global.p.fontFamily = global.fontFamily.primaryFont;
    global.list.fontFamily = global.fontFamily.primaryFont;

    //Button styles
    const defaultButtonStyles = {
        background: global.backgroundColors.dark.color1,
        color: global.textColors.light.white,
        borderColor: global.backgroundColors.dark.color1,
        fontFamily: global.fontFamily.primaryFont
    };
    const alternateButtonStyles = {
        background: global.backgroundColors.accent.color2,
        textColor: global.textColors.light.white,
        fontFamily: global.fontFamily.primaryFont,
        borderColor: global.backgroundColors.accent.color2
    };

    global.button1 = {
        ...global.button1,
        ...defaultButtonStyles
    };

    global.button1A = {
        ...global.button1,
        ...alternateButtonStyles
    };

    global.button2 = {
        ...global.button2,
        ...defaultButtonStyles
    };

    global.button2A = {
        ...global.button2,
        ...alternateButtonStyles
    };

    global.button3 = {
        ...global.button3,
        ...defaultButtonStyles
    };

    global.button3A = {
        ...global.button3,
        ...alternateButtonStyles
    };

    return global;

}
