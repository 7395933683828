////import $ from 'jquery';
//"use strict"
const activeNavLink = (id) => {

    return (id) => {

        const $root = $(`#${id}`);
        const root = document.getElementById(id);
        const target = root.dataset.target;

        $root.on('click', function(e) {

            $(target).toggleClass('active');

        })

    }

};


export default activeNavLink;
