const data = {
    newsletterTitle: {
        text: "Sign Up for our Newsletter",
        fontSize: '1.4em',
        color: ''
    },
    newsletterCta: {
        text: "To receive exclusive news and special offers!",
        fontSize: '1em',
        color: ''
    },
    placeholder: {
        text: "Example@example.com",
        color: '',
        fontSize:'',
    }
};

export default data;