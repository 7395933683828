import styled from 'styled-components'
import {GLOBALS} from "../../styles/global-default-styles";

export const TextLink = styled.p`
  color:${GLOBALS.colorPrimary};
  cursor:pointer;
  width:fit-content;
  & :hover {
    text-decoration:underline;
  }
`;

export const Heading3 = styled.h3`
  font-size:18px;
`
