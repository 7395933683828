/*global $ */

const tabsHandler = ( id ) => {

    return ( id ) =>  {

		const button   = document.getElementById( id );
		const dispatch = document.getElementById( 'page-app' );

		button.addEventListener( 'click', ( e ) => {

			e.preventDefault();

			const props = {
				detail : {
					action: 'increase_stepper'
				}
			};

			const event = new CustomEvent( 'cart', props );
			dispatch.dispatchEvent( event );
		});

    }
};

export default tabsHandler;
