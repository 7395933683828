import { StyleSheet } from '@convertly/css';
const FixedHeaderFix = ( element, marginBottom ) => {

    let position = element.position;

    const fixedHeaderFix = {

    };
    if( element.position === "fixed" ) {
        fixedHeaderFix.marginBottom = marginBottom;
        fixedHeaderFix.background = 'red';
    }
    return fixedHeaderFix;
};
export default FixedHeaderFix;