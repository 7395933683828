import {StyleSheet} from '@convertly/css';

const Styles = (global, data) => {

    return StyleSheet.create({

        chkFlexMiddle: {
            color: global.colors.dark.color1,
            display: "block",
            flex: "0 0 auto",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "14px",
            lineHeight: "20px",
            zIndex: '1',
            minHeight: 'calc(100vh - 150px)',
            background: '#fafafa',
            overflow:'hidden'
        },

        cartToggleContainer: {
            color: "#333",
            display: "none",
            fontSize: "14px",
            lineHeight: "20px"
        },

        chkFlexMiddleContainer: {
            color: "#333",
            display: "flex",
            flexDirection: "row",
            fontSize: "14px",
            lineHeight: "20px",
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: "0px",
            width: "100%",
            "@media(max-width:767px)": {
                display: 'block'
            }


        },
        flexMiddle_middleLeft: {
            color: "#333",
            display: "flex",
            flex: "1",
            flexDirection: "column",
            fontSize: "14px",
            lineHeight: "20px",
            paddingBottom: "30px",
            paddingRight: "20px",
            paddingTop: "30px"
        },

        chkTitle1: {
            borderBottom: "2px none #667785",
            color: global.colors.dark.color1,
            display: "block",
            flex: "0 auto",
            flexDirection: "row",
            fontSize: "32px",
            fontWeight: "400",
            lineHeight: "36px",
            marginBottom: "10px",
            marginTop: "0px",
            paddingBottom: "5px",
            width: "100%"
        },

        titleUnderline: {
            backgroundColor: global.colors.dark.color1,
            borderRadius: "5px",
            color: "#333",
            fontSize: "14px",
            height: "3px",
            lineHeight: "20px",
            marginBottom: "15px",
            width: "45%"
        },
        totalsSectionInfernoContainer: {
            ".chkLabel": {
                color: "#828588",
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "5px",
                fontFamily: global.fontFamily.secondaryFont
            },
            ".chkSubtotal": {
                color: "#667785",
                fontSize: "45px",
                lineHeight: "55px",
                margin: "10px 0"
            },
        },
        chkGuestlink_wInlineBlock: {
            backgroundColor: "transparent",
            color: global.colors.dark.color1,
            display: "flex",
            fontSize: "16px",
            lineHeight: "20px",
            maxWidth: "100%",
            paddingBottom: "23px",
            paddingTop: "20px",
            textDecoration: "underline"
        },

        chkGuestarrow: {
            border: "0",
            color: global.colors.dark.color1,
            display: "inline-block",
            fontSize: "16px",
            height: '100%',
            width: '20px',
            lineHeight: "20px",
            marginLeft: "10px",
            maxWidth: "100%",
        },

        chkPricinggrid: {
            color: "#333",
            fontSize: "14px",
            lineHeight: "20px",
        },

        chkProductcouponcontainer: {
            color: "#333",
            display: "flex",
            fontSize: "14px",
            justifyContent: "flex-start",
            lineHeight: "20px",
            marginBottom: "25px",

        },

        formBlock_wForm: {
            color: "#333",
            fontSize: "14px",
            lineHeight: "20px",
            margin: "0 0 15px",
            marginBottom: "0px"
        },
        shippingEstimateInfernoComponent: {
            ".couponInput": {
                color: "#333",
                display: "flex",
                fontSize: "14px",
                justifyContent: "flex-end",
                lineHeight: "20px"
            },
            ".couponInput_textInput_wInput": {
                backgroundColor: global.colors.light.white,
                border: "1px solid",
                borderColor: global.colors.accent.color3,
                color: global.colors.dark.color3,
                display: "flex",
                font: "inherit",
                fontSize: "16px",
                fontWeight: "300",
                height: "38px",
                justifyContent: "flex-end",
                lineHeight: "18px",
                margin: "0",
                marginBottom: "10px",
                padding: "8px 12px",
                textAlign: "left",
                verticalAlign: "middle",
                width: "100%",
                '::placeholder': {
                    color: global.colors.dark.color3
                }
            },

            ".couponApplyLink_wButton": {
                backgroundColor: "transparent",
                border: "0",
                borderRadius: "0",
                color: global.colors.dark.color1,
                cursor: "pointer",
                display: "inline-block",
                font: "inherit",
                fontSize: "12px",
                fontWeight: "300",
                lineHeight: "14px",
                margin: "0",
                padding: "9px 15px",
                paddingBottom: "11px",
                paddingRight: "0px",
                width: "200px",
                textDecoration: "underline"
            },
            ".checkBox": {
                border: '1px solid',
                cursor: 'pointer',
                verticalAlign: 'text-bottom',
                borderColor: global.colors.accent.color3,
                fill: global.colors.light.white,
                background: global.colors.light.white,
                '.open': {
                    border: 'none',
                    fill: global.colors.accent.color1,
                }
            },
            ".checkboxCheckBox": {
                visibility: 'hidden',
                height: 0,
                width: 0
            },
            ".checkboxContainer": {
                marginRight: '10px'
            },
            ".checkboxLabel": {
                display: 'block',
                color: global.colors.dark.color1,
                verticalAlign: 'middle',
                cursor: 'pointer',
                margin: '0 0 30px'
            },

            ".chkLabel": {
                color: global.colors.dark.color3,
                fontFamily: global.fontFamily.secondaryFont,
                fontSize: "12px",
                lineHeight: "20px",
                marginBottom: "5px"
            }, ".overlay": {
                position: 'fixed',
                top: 0,
                left: 0,
                height: '100vh',
                width: '100vw',
                background: 'rgba(200,200,200,.7)',
                zIndex: '2'
            },
            ".wrapper": {
                position: 'fixed',
                height: '100%',
                width: '100%',
                left: '0',
                top: '0',
                zIndex: '2'
            },
            ".shippingModal": {
                position: 'relative',
                top: '50%',
                margin: 'auto',
                transform: 'translateY(-30vh)',
                minHeight: '35vh',
                width: '525px',
                background: global.colors.light.white,
                padding: '50px 90px',
                border: global.border,

            },
            ".shippingModalHeader": {
                fontSize: '24px',
                marginBottom: '25px',
                textAlign: 'center'
            },
            ".btnContinue_wButton": {
                backgroundColor: global.colors.dark.color1,
                border: "0",
                borderRadius: "0",
                color: "white",
                cursor: "pointer",
                display: "block",
                fontSize: "14px",
                lineHeight: "inherit",
                margin: "10px 0 0",
                padding: "9px 15px",
                paddingBottom: "11px",
                paddingTop: "11px",
                textDecoration: "none",
                textTransform: "uppercase",
                width: "100%",
                textAlign: "center"
            },
            '.closeButton': {
                position: 'absolute',
                padding: '10px',
                top: '-15px',
                right: '-15px',
                height: '45px',
                width: '45px',
                border: global.border,
                borderRadius: '100%',
                background: global.colors.light.color1,
                cursor: 'pointer',

            },
            '.closeIcon': {
                height: '20px',
                width: '20px'
            },
        },
        wFormDone: {
            backgroundColor: "#dddddd",
            color: "#333",
            display: "none",
            fontSize: "14px",
            lineHeight: "20px",
            padding: "20px",
            textAlign: "center"
        },

        div_cUNn: {
            color: "#333",
            fontSize: "14px",
            lineHeight: "20px",
            textAlign: "center"
        },

        wFormFail: {
            backgroundColor: "#ffdede",
            color: "#333",
            display: "none",
            fontSize: "14px",
            lineHeight: "20px",
            marginTop: "10px",
            padding: "10px"
        },

        flexMiddle_middleRight: {
            color: "#333",
            display: "flex",
            flex: "1",
            fontSize: "14px",
            justifyContent: "flex-start",
            lineHeight: "20px",



        },
        checkoutCartReviewContainer: {
            minHeight: '100vh',
            height: '100%',
            background: global.colors.light.white,
            boxShadow: ' 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3)'
        },
        cartInfernoContainer: {
            ".flexMiddle_middleRight": {
                boxShadow: `0 2px 4px -1px rgba(0,0,0,0.3)`,
                "@media( max-width: 480px)": {
                    background: '#fff',
                    padding: '15px',
                    marginTop:'15px',
                }
            },
            ".mobile-cart-dropdown": {
                display: 'none',
                marginBottom: "20px",
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                height: '40px',
                borderBottom: global.border,
                width: '100%',
                marginTop: '20px',
                '@media( max-width:480px )': {
                    display: 'block'
                }
            },
            ".mobile-cart-dropdown-cheveron": {
                float: "right",
                height: '25px',
                width: '25px',
                fill: global.colors.dark.color1,
                cursor: 'pointer',
                transition: 'all .2s',
                ".cart-open": {
                    transform: 'rotate(180deg)'
                }
            },
            ".chkTitle1_desktopTitle1": {
                color: global.colors.dark.color1,
                display: "inline-block",
                fontSize: "16px",
                lineHeight: "26px",
                marginBottom: "10px",
                marginTop: "0px",
                paddingBottom: "5px",
                paddingLeft:'30px',
                "@media (max-width:480px)": {
                    display: 'none'
                }

            },
            ".chkTitle1_mobileTitle1": {
                color: global.colors.dark.color1,
                display: "inline-block",
                fontSize: "16px",
                lineHeight: "26px",
                marginBottom: "10px",
                marginTop: "0px",
                paddingBottom: "5px",

            },
            ".mobileCartToggle": {
                float:'right',
                fontSize:'14px',
                color:global.colors.dark.color3,
                cursor:"pointer"
            },

            ".checkRightPanel": {
                padding: '30px 0',
                alignItems: "stretch",
                color: "#333",
                display: "flex",
                flex: "1",
                flexDirection: "row",
                fontSize: "14px",
                justifyContent: "center",
                lineHeight: "20px",
                paddingTop: "30px",
                background:'#fff',
                minHeight:'100vh',
                transition: 'all .5s ease',
                overflow: 'hidden',
                "@media(max-width:767px)": {
                    //backgroundColor: "transparent",
                    minHeight:'unset',
                },
                "@media( max-width:480px )": {
                    padding: "0px",
                    maxHeight: '0',
                    height: 'auto',
                    ".cart-open": {
                        maxHeight: "2000px",
                    }
                },

            },
            ".empty-cart": {
                display: "flex",
                width: "100%",
                padding: "20px",
                justifyContent: "space-between",
                border: "2px solid",
                borderColor:global.colors.dark.color3,
                borderRadius: "3px",
                backgroundColor: "#fff",
                height:"65px",
                maxWidth:'95%'
            },
            ".empty-cart-link": {
                textDecoration:"underline"
            },
            ".chkRightPanelContainer": {
                color: "#333",
                fontSize: "14px",
                lineHeight: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "100%",
                width: "100%"
            },
            ".mobileUnderline_titleUnderline": {
                backgroundColor: global.colors.dark.color1,
                borderRadius: "5px",
                color: "#333",
                display: "none",
                fontSize: "14px",
                height: "3px",
                lineHeight: "20px",
                marginBottom: "20px",
                width: "45%"
            },
            ".chkGridheadercontainer": {
                color: "#333",
                display: "flex",
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "5px",
                position: "relative",
                padding:'0 30px'
            },

            ".chkProdgridimgcontainer": {
                alignItems: "flex-start",
                color: "#333",
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                justifyContent: "center",
                lineHeight: "20px",
                maxWidth: "18%",
                textAlign: "center",
                width: "20%",
                "@media(max-width:991px)": {
                    marginRight: '15px'
                }

            },

            ".chkGridprice_th": {
                color: "#828588",
                fontFamily: global.fontFamily.secondaryFont,
                fontSize: "11px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                paddingLeft: "0px",
                width: "180px",
                "@media(max-width:480px)": {
                    width:"28%"
                }
            },
            ".chkGridqty_chkUnitprice_th": {
                alignItems: "flex-start",
                color: "#828588",
                display: "flex",
                flex: "1",
                fontFamily: global.fontFamily.secondaryFont,
                fontSize: "11px",
                justifyContent: "center",
                lineHeight: "20px",
                textAlign: "center",
                "@media(max-width:480px)": {
                    display:'none'
                }
            },
            ".chkGridqty_chkUnitprice_th-text": {
                "@media(max-width:767px)": {
                    display: 'none'
                }
            },
            ".chkGrdQty": {
                alignItems: "flex-start",
                color: "#828588",
                display: "flex",
                flex: "1",
                fontFamily: global.fontFamily.secondaryFont,
                fontSize: "11px",
                justifyContent: "center",
                lineHeight: "20px",
                textAlign: "center",
                "@media(max-width:767px)": {
                    maxWidth: '10%',
                    justifyContent: "flex-end",
                }
            },

            ".chkGridsubtotal_th": {
                color: "#828588",
                display: "flex",
                flex: "1",
                fontFamily: global.fontFamily.secondaryFont,
                fontSize: "11px",
                justifyContent: "flex-end",
                lineHeight: "20px",
                textAlign: "center",
                maxWidth: '22.5%',

            },

            ".chkProdgrid": {
                color: "#333",
                display: "flex",
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "20px",
                position: "relative"
            },

            ".chkProdgridimg": {
                border: "0",
                color: "#333",
                display: "inline-block",
                fontSize: "14px",
                height: "70px",
                lineHeight: "20px",
                marginBottom: "21px",
                maxWidth: "100%",
                textAlign: "center",
                verticalAlign: "middle",
                width: "70px"
            },

            ".chkRemovelink": {
                backgroundColor: "transparent",
                bottom: "0px",
                color: "#b4bbc3",
                fontSize: "10px",
                lineHeight: "20px",
                maxWidth: "100%",
                position: "absolute",
                textAlign: "center",
                paddingLeft: '6px',

            },

            ".divBlock_2": {
                color: "#333",
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                lineHeight: "20px",
                width: "180px",
                "@media(max-width:480px)": {
                    width:"30%"
                }
            },

            ".chkGridprice": {
                alignItems: "flex-start",
                color: global.colors.dark.color1,
                display: "flex",
                flex: "0 auto",
                fontSize: "14px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                paddingLeft: "0px",
                width: "180px",
                "@media(max-width:767px)": {
                    fontSize: '11px'
                },
                "@media(min-width:991px)": {
                    ":hover": {
                        color:global.colors.accent.color3
                    }
                },
                "@media(max-width:480px)": {
                    width:'auto'
                }
            },

            ".chkGridprice_chkGridpricenote": {
                alignItems: "flex-start",
                color: "#118bef",
                display: "flex",
                flex: "0 auto",
                fontSize: "12px",
                justifyContent: "flex-start",
                lineHeight: "16px",
                paddingLeft: "0px",
                width: "auto"
            },

            ".divBlock_3": {
                alignItems: "center",
                color: "#333",
                display: "flex",
                flex: "1",
                flexDirection: "column",
                fontSize: "14px",
                lineHeight: "20px",
                "@media(max-width:480px)": {
                    display:'none'
                }
            },

            ".chkGridqty_chkUnitprice_strikethrough": {
                alignItems: "flex-start",
                color: "#667785",
                display: "flex",
                flex: "0 auto",
                fontSize: "14px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                textAlign: "center",
                textDecoration: "line-through",
                "@media(max-width:767px)": {
                    display: 'none'
                }

            },

            ".actualprice_chkGridqty_chkUnitprice": {
                alignItems: "flex-start",
                color: "#118bef",
                display: "flex",
                flex: "1",
                fontSize: "14px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                textAlign: "center",
                "@media(max-width:767px)": {
                    display: 'none'
                }
            },

            ".chkGridqty": {
                alignItems: "flex-start",
                color: "#667785",
                display: "flex",
                flex: "1",
                fontSize: "14px",
                justifyContent: "center",
                lineHeight: "20px",
                textAlign: "center",
                "@media(max-width:767px)": {
                    maxWidth: '13%',
                    justifyContent: "flex-end",
                }

            },

            ".counterIndicator": {
                backgroundColor: "transparent",
                color: "#667785",
                fontSize: "14px",
                lineHeight: "20px",
                padding: "4px 9px",
                textAlign: "center",
                textDecoration: "none",
                "@media( max-width:767px )": {
                    display: 'none'
                }
            },

            ".counternumber": {
                border: "2px solid #667785",
                color: "#667785",
                flex: "0 auto",
                fontSize: "14px",
                lineHeight: "20px",
                padding: "4px 9px 3px",
                textAlign: "center",

                "@media( max-width:767px )": {
                    padding: "0 5px",
                    border: 'none',
                    "::before": {
                        "content": "'x '"
                    }
                }
            },

            ".chkGridsubtotal": {
                color: "#667785",
                display: "flex",
                flex: "1",
                fontSize: "14px",
                justifyContent: "flex-end",
                lineHeight: "20px",
                textAlign: "center",
                maxWidth: '22.5%'


            },
            ".chkGridpriceing": {
                color: "#667785",
                display: "flex",
                flex: "1",
                fontSize: "14px",
                justifyContent: "flex-end",
                lineHeight: "20px",
                textAlign: "center",
                "@media(max-width:767px)": {
                    display: 'none'
                }
            },

            ".progresscontainer": {
                color: "#333",
                fontSize: "14px",
                lineHeight: "20px",
                paddingTop: "10px",
                position: "relative"
            },

            ".truck": {
                border: "0",
                color: "#333",
                display: "inline-block",
                fontSize: "14px",
                lineHeight: "20px",
                maxWidth: "55px",
                position: "absolute",
                right: "0px",
                top: "0px",
                verticalAlign: "middle"
            },

            ".progresstext": {
                borderBottomColor: "#000",
                borderBottomWidth: "3px",
                borderStyle: "none",
                color: "#333",
                fontSize: "14px",
                lineHeight: "20px",
                paddingBottom: "10px",
                paddingRight: "80px"
            },

            ".progressline_titleUnderline": {
                backgroundColor: global.colors.dark.color1,
                borderRadius: "5px",
                color: "#333",
                fontSize: "14px",
                height: "3px",
                lineHeight: "20px",
                marginBottom: "15px",
                width: "100%"
            },

            ".cartToggleContainer": {
                color: "#333",
                display: "none",
                fontSize: "14px",
                lineHeight: "20px"
            },
            ".cartTitleSub": {
                color: "#333",
                fontSize: "32px",
                fontWeight: "bold",
                lineHeight: "36px",
                marginBottom: "10px",
                marginTop: "20px"
            },

            ".toggleLinkblock_wInlineBlock": {
                backgroundColor: "transparent",
                color: "#333",
                display: "inline-block",
                fontSize: "14px",
                lineHeight: "20px",
                maxWidth: "100%"
            },
            ".toggleCollapsed": {
                border: "0",
                color: "#333",
                display: "inline-block",
                fontSize: "14px",
                lineHeight: "20px",
                maxWidth: "100%",
                verticalAlign: "middle"
            },
            ".cart-item-container": {
                padding:'15px 30px'
            },
            ".change-message-bg": {
                backgroundColor: '#e3f2fd',
            },
            ".change-message-container": {
                borderTop:`1px solid ${global.colors.light.color2}`,
                paddingTop:'10px',
                fontFamily:global.fontFamily.secondaryFont
            },
            ".change-message-text": {
                textTransform:'none',
                color:global.colors.dark.color3
            },
            ".change-message-action": {
                display:'block'
            },
            ".change-message-link": {
                fontSize:'16px',
                fontWeight:700,
                display:'inline-block',
                margin:"5px 10px 0 0",
                cursor:'pointer'
            }
        },
        textBlock: {
            color: global.colors.dark.color1,
            fontSize: "16px",
            lineHeight: "20px",
            textDecoration:'underline',
            ".chkGuestarrow": {
                border: "0",
                color: global.colors.dark.color1,
                display: "inline-block",
                fontSize: "16px",
                height: '100%',
                width: '20px',
                lineHeight: "20px",
                marginLeft: "10px",
                maxWidth: "100%",
                transition: 'margin .3s ease',
                verticalAlign:"bottom"

            },
            ':hover ': {
                '.chkGuestarrow': {
                    marginLeft: '15px'
                }
            },
        },
        quickBuyInfernoContainer: {
            ".quickCheckoutContainer": {
                height: '163px'
            },
            ".apple-pay": {
                "-webkit-appearance": "-apple-pay-button",
                "-apple-pay-button-type": "black"
            },

            ".btnLeft_placholderBtn_wButton": {
                backgroundColor: global.colors.dark.black,
                border: "0",
                borderRadius: "3px",
                clear: "right",
                color: global.colors.light.white,
                cursor: "pointer",
                display: "flex",
                flex: "1",
                flexDirection: "row",
                float: "left",
                fontSize: "14px",
                justifyContent: "center",
                lineHeight: "inherit",
                marginBottom: "15px",
                marginLeft: "0px",
                marginRight: "5px",
                padding: "9px 15px",
                paddingBottom: "10px",
                paddingTop: "10px",
                textDecoration: "none",
                width: "auto"
            },

            ".btnRight_placholderBtn_wButton": {
                backgroundColor: global.colors.dark.black,
                border: "0",
                borderRadius: "3px",
                clear: "right",
                color: global.colors.light.white,
                cursor: "pointer",
                display: "flex",
                flex: "1",
                flexDirection: "row",
                float: "left",
                fontSize: "14px",
                justifyContent: "center",
                lineHeight: "inherit",
                marginBottom: "15px",
                marginLeft: "5px",
                marginRight: "0px",
                padding: "9px 15px",
                paddingBottom: "10px",
                paddingTop: "10px",
                textDecoration: "none",
                width: "auto"
            },
        }


    });

};

export default Styles;
