import React from 'react';
import Component from '../../rootComponent';
import {css} from "@convertly/css";
import defaultGlobal from '../../themes/global.js';
import ReactHTMLParser from "@convertly/react-html-parser";

// Styles
import styles from './form-styles'


export default class TextArea extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {data} = this.props;
        this.styles = styles(this.props.global, data);

        return (
            <textarea
                className={`${css(this.styles.formInfernoContainer)} textArea`}
                rows={data.rows || 4}
                placeholder={data.placeholder}/>
        )
    }
};

TextArea.defaultProps = {
    global: defaultGlobal,
    data: {}
}








