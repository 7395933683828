////import $ from 'jquery';

const scrollFullViewportHeight = (id) => {
  return (id) => {
    const $el = $(`#${ id }`);

    $el.click(() => {
      scrollToTop(400);
    });

    function scrollToTop(scrollDuration) {
      let scrollStep = scrollDuration / 15,
          scrollInterval = setInterval(function(){
            if ( window.scrollY < $(window).height() ) {
                window.scrollBy( 0, scrollStep );
            } else {
              clearInterval(scrollInterval); 
            }
          },15);
    }
  }
}

export default scrollFullViewportHeight;
