import React from 'react';
import Component from '../../../../rootComponent';
import { css } from "@convertly/css";
import Types from '../../../../attribute-types';
import { id } from '@convertly/thor';

import HeaderNavLink1 from '../header-nav-link/header-nav-link-1';


export default class
HeaderNavList1 extends Component {

    constructor( props ) {

        super( props );

    }


    render() {
        return (
            <ul className={ css( this.props.styles.navList ) }>
                {this.props.data.navLinks.map( ( navItem, index ) => {
                    return(
                    <HeaderNavLink1 key={ "headerNavLink" + index }
                                    idNumber={ index }
                                    global={ this.props.global }
                                    data={ navItem }
                                    styles={ this.props.styles } />
                    )
                })}
            </ul>
        )
    }
}

