import { StyleSheet } from "@convertly/css";
import UserOverrides from "../../elements/style-functions/user-overrides";

export const BlogLinkStyles = ({ global, data, inEditor }) => {
  const userOverrides = new UserOverrides(global, data, inEditor);

  return StyleSheet.create({
    blogLink: {
      ...global.button1A,
      ...userOverrides
    }
  });
};
