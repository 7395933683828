import {StyleSheet} from "@convertly/css";
import { clientImage } from '../../../../utils/generateImage';

const HeaderStyles4 = (global, data, headerPosition) => {

    let modifiedColor;

    let fixed = data.position === "fixed";
    let overlay = data.position === "overlay";

    let overlayFixed = (headerPosition === 'overlayFixed') ? {
        fill: 'black'
    } : {};

    const navListItem = {
        display: 'inline-block',
        cursor: 'pointer',
        padding: '0px 5px 0 5px',
        fontSize: '18px',
        stroke: 'inherit',
        position: 'relative',
        margin: "0 10px",
        color: "white",
        "@media (max-width: 991px)": {
            padding: "0px"
        }

    };
    const dropdown = {
        float: 'right',
        listStyle: 'none',
        height: 'auto',
        width: 'fit-content'
    };

    const dropdownList = {
        display: 'none',
        position: 'absolute',
        top: '140%',
        right: '-34px',
        background: global.backgroundColors.light.white,
        boxSizing: 'content-box',
        listStyle: 'none',
        padding: '20px 20px 8px',
        minWidth: '270px',
        zIndex: '1',
        '.open': {
            display: 'block'
        },
        '@media( max-width:991px )': {
            top: '205%',
            right: '3px'
        },
        '@media( max-width:420px)': {
            right: '0'
        }
    };

    let signInButton = {
        height:'45px',
        width:'100%',
        borderRadius: "5px",
        border:'none',
        margin:'5px 0',
    };


    return StyleSheet.create({
        menuContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '25px',
            maxWidth: '1170px',
            margin: "0 auto",
            color: global.colors.dark.color1,
            '@media( max-width: 991px )': {
                padding: '0px'
            },
        },
        logo: {
            float: 'left',
            maxWidth: '180px',
            height:'fit-content',
            
            "@media ( max-width:991px )": {
                display: 'none',
                position: 'absolute',
                zIndex:2,
                top: '15px',
                left: '15px',
                '.on-homepage': {
                  display: 'block'
                }
            },
        },
        mobileLogo: {
            float: 'left',
            maxWidth: '180px',
            height:'fit-content',
            display: 'none',
            position: 'absolute',
            zIndex:2,
            top: '10px',
            left: '15px',
            "@media ( max-width:991px )": {
                display: 'block',
                '.on-homepage': {
                    display: 'none'
                }
            },
        },
        logoImg: {
            ...overlayFixed,
        },
        menuLauncherIcon: {
            display: 'none',
            order: 2,
            float: 'right',
            marginRight: "15px",
            marginTop: '22px',
            '@media( max-width:991px )': {
                display: 'block',
                position: 'absolute',
                right: "10px"
            },
            '@media( max-width:768px )': {
                marginRight: '0'
            }


        },
        navLaunch: {
            zIndex: 45,
            display: "none",
            paddingBottom: 0,
            overflow: "visible",
            background: "none",
            border: "none",
            outline: "none",
            '@media( max-width:991px )': {
                display: 'block'
            }
        },
        iconBar: {
            backgroundColor: overlay ? global.backgroundColors.dark.color1 : global.textColors.light.white,
            width: "20px",
            height: "3px",
            marginBottom: "3px",
            position: "relative",
            display: "block",
            zIndex: 50,
            transition: 'all 0.3s ease-in-out 0s',
            ':nth-child(3)': {
                width: "16px"
            },
            '.open': {
                background:global.backgroundColors.light.white,
                ":nth-child(2)": {
                    transform: "rotate(45deg)",
                    top: "7px",

                },
                ":nth-child(4)": {
                    transform: "rotate(-45deg)",
                    top: "-5px",
                    borderColor:global.backgroundColors.light.white,
                },
                ":nth-child(3)": {
                    opacity: 0
                }
            },
        },
        mainMenu: {
            zIndex: 45,
            alignSelf: 'baseline',
            width: 'fit-content',
            '@media (max-width: 991px)': {
                //marginTop:'5px',
                display: 'none'
            },
            '.open': {
                display: 'block',
                visibility: 'visible',
                left: '0',
            },
        },
        mobileMenu: {
            height: "auto",
            width: '100%',
            background: global.backgroundColors.dark.color1,
            position:'absolute',
            top:'-600px',
            transition:'all .6s ease',
            zIndex:'1',
            "@media ( min-width:991px )": {
                display:'none'
            },
            ".open": {
                top:0,
                // height: "100vh"
            }
        },
        tagLineContainer: {
            display: 'none',
            "@media( max-width:991px )": {
                display: fixed ? "block" : 'none',
                position: 'absolute',
                zIndex:'2',
                width: '100%',
                top: '5px',
                textAlign: 'center',
                color:global.textColors.dark.color2
            },
            '.open': {
                color: global.textColors.light.white
            },
        },
        tagLineSmall: {
            fontSize:'14px'
        },
        tagLineLarge: {
            fontSize:'30px',
            fontWeight:'700'
        },
        mobileLogin: {
            width:'100%',
            height: "calc(100% - 120px)",
            background:`radial-gradient(circle farthest-corner at 49% 16%, #349886 , #0a328d )`,
            padding:'30px',
            display: "flex",
            flexDirection: "column",
            justifyContent: 'center'
        },
        loginInfernoComponent: {
            ".login_container": {
                width:"100%",
                margin:"0 auto",
                overflow:'hidden',
                position:'relative',
                height:'300px'
            },

            ".login-nav": {
                width:'100%',
                textAlign:"center"
            },
            ".login-nav-link": {
                display: 'inline-block',
                color: "white",
                width: 'fit-content',
                margin: '0 auto',
                padding:'0 20px',
                cursor:'pointer',
                transition:'all .3s ease',
                ".active ": {
                    fontWeight:700,
                    ".underline-border": {
                        height:'3px'
                    }
                }
            },
            ".underline-border": {
                height:'0px',
                background:'#fff',
                width:'100%',
                transition:'all .3s ease',
                //".active": {
                  //  height:'3px'
                //}
            },

            ".signIn": {
                left:'125%'
            },
            ".signUp": {
                left:"-125%"
            },
            ".login-container": {
                width:'100%',
                transition:'all 1s ease',
                position:'absolute',
                top:'50px',
                ".active": {
                    left:'0'
                }
            },
            ".login-wrapper": {
                maxWidth:'375px',
                margin:'0 auto',
            },
            ".sign-up-form": {
                width:'100%'
            },
            ".input-container": {
                height:'60px',
                width:'100%',
            },
            ".input": {
                height:'45px',
                width:'100%',
                fontSize:'18px',
                color:global.textColors.dark.color1,
                border:'none',
                outline:'none',
                padding:"0 10px",
                borderRadius:'4px',

            },
            ".form-error-message": {
                color:'red',
                fontWeight:'bold',
                display:'none',
                fontSize:'8px'
            },
            ".show-form-error-message": {
                display:'block'
            },
            ".mobile-nav-terms-paragraph": {
                fontSize:'12px',
                lineHeight:'15px',
                color:'white'
            },
            ".google-button": {
                ...signInButton,
                background:"#4285f4",
            },
            ".facebook-button": {
                ...signInButton,
                background:"red",

            },
            ".convertly-button": {
                ...signInButton,
                background:'#132755',
                color:'#fff'
            },


        },


        headerNav: {
            display: 'inline-block',
            '@media ( max-width:991px )': {
                borderTop: '3px solid',
                marginTop: '70px',
                padding: '10px 0',
                borderColor: global.backgroundColors.dark.color2,
                width: '100%',
                display:'none',
                '.open': {
                    display:'block'
                },

            },
        },
        navList: {
            float: 'right',
            listStyle: 'none',
            paddingLeft: '15px',
            height: 'auto',
            width: 'fit-content',
            '@media ( max-width:991px )': {
                float: 'none',
                margin: '0 auto'
            },

        },
        navListItem: {
            ...navListItem,
            ".active": {
                color: global.textColors.accent.color1,
                fontWeight:'bold'
            },
            ":hover": {
                "@media (min-width: 991px)": {
                    color: global.textColors.accent.color1
                }
            },
            '@media ( max-width:991px )': {
                margin: "0 15px",
                // ".login-link": {
                //     display: 'none'
                // }
            },

        },

        contactNavListItem: {
            ...navListItem,
            padding: '24px 10px 0 10px',
            '@media( max-width: 991px )': {
                marginTop: '10px',
                textDecoration: 'underline'
            },
        },
        dropdown: {
            ...dropdown,
            '@media( max-width:991px)': {
                float: 'none',

            }

        },
        contactDropdown: {
            ...dropdown
        },
        dropdownList: {
            ...dropdownList,
            '@media( max-width:991px )': {
                paddingTop: '20px',
                position: 'static'
            },

        },
        contactDropdownList: {
            ...dropdownList,

        },
        dropDownItem: {
            display: 'none',
            position: 'absolute',
            top: '140%',
            right: '0',
            width: "200px",
            background: '#fff',
            listStyle: 'none',
            padding: '10px',
            marginBottom: '20px',
            '.open': {
                display: 'block'
            }

        },
        contactListHeader: {
            display: 'block',
            width: '50%',
            borderBottom: '1px solid',
            paddingBottom: '12px',
            color: global.textColors.dark.color1,
            borderColor: global.backgroundColors.dark.color1,
            textTransform: 'uppercase',
            fontSize: '14px',
            fontWeight: 'bold'
        },
        arrowUp: {
            width: 0,
            height: 0,
            position: 'absolute',
            top: '-10px',
            right: '20px',
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderBottom: '10px solid',
            borderBottomColor: global.backgroundColors.light.white
        },
        downNavItem: {
            color: global.textColors.dark.color1,
            //fill: global.textColors.dark.color1,
            margin: '20px 0',
            ':hover': {
                opacity: '.8'
            },

        },
        dropdownIconContainer: {
            fill: global.backgroundColors.dark.color1,
            display: 'inline-block',
            width: '10%',
            verticalAlign: 'middle'
        },
        menuIcon: {
            width: '18px',
            height: '18px',


        },
        dropDownlink: {
            fontSize: '15px'
        }

    });

};

export default HeaderStyles4
