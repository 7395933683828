import React from 'react';
import Component from 'source/rootComponent';
import { css } from '@convertly/css';
import { id } from '@convertly/thor';
import _ from 'lodash';

// Utils
import { clientImage } from '../../../utils/generateImage';
import { generateLink } from '../../../utils/generateLink';

// Components
import {NavTopRight} from './NavTopRight';
import {NavTopLeft} from './NavTopLeft';

// Styles
import headerStylesTop from '../styles/headerStylesTop';
import navStylesTopRight from '../styles/navStylesTopRight';

// Scripts

export class HeaderTop extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    
    const { global, data, site, page, inEditor } = this.props;
	  
    const slug = _.get(page, 'slug') || '/';
    
    this.styles = headerStylesTop(global, data, inEditor);
    const topRightStyles = navStylesTopRight(global, data, inEditor);

    const ecommEnabled = _.get(site, 'ecommerce.enabled') || false;
    
    const showEcomm = (slug !== '/cart' && ecommEnabled);

    return (
      <div className={ css(this.styles.mainHeaderTop) }>
        <NavTopLeft 
          global={ global }
          data={ data }
          site={ site }
          inEditor={ inEditor }/>
        {
          showEcomm &&
          <NavTopRight 
            global={ global }
            data={ data }
            site={ site }
            page={ page }
            styles={ topRightStyles }
            inEditor={ inEditor }/>
        }
      </div>
    )
  }
}

