import styled from 'styled-components';
import { GLOBALS } from "../../../styles/global-default-styles";

export const TextArea = styled.textarea`
    background: #FFFFFF;
    border: 1px solid ${GLOBALS.colorInputBorder};
    box-sizing: border-box;
    border-radius: 3px;
    width:100%;
    height: 160px;
    margin-top: 10px;
    outline:none;
    padding:10px;`;
