import { take, call, put, select, cancel, takeLatest } from "redux-saga/effects";

import {
  restoreSavedUserSession,
  loginSuccess,
  setUser,
  setAccount,
  setSites,
  setCurrentSite
} from "./actions";

import { loadSite, setSiteLoadingStatus } from "../Editor/actions/editorActions";

import { restoreUser } from "./Auth";
import { getSites } from "../../services/sites";

import _ from "underscore";

/**
 * Setup the user account, available sites, and all other user settings
 * @param user
 */
export function* setupAfterLoginSuccess(user) {
  console.log("auth setupAfterLoginSuccess start");

  user = user.payload;

  try {
    const sites = yield getSites(user.user.sub);

    yield put(setSites(sites));

    let currentSite;

    if (sites.length) {
      currentSite = sites[0];
    }

    if (localStorage.getItem("active-site")) {
      const savedActiveSite = localStorage.getItem("active-site");
      sites.map(site => {
        if (savedActiveSite === site.id) {
          currentSite = site;
        }
      });
    }

    const status = yield put(setSiteLoadingStatus(true));
    console.log(status);
    yield put(setCurrentSite(currentSite));
    yield put(loadSite({ site: currentSite }));

    yield put(setUser(user));

    console.log("auth setupAfterLoginSuccess done");
  } catch (e) {
    console.log(e.message);
  }
}

export function* refreshUserSession() {
  yield takeLatest(restoreSavedUserSession.toString(), restoreUser);
}

export function* loginSuccessSaga() {
  yield takeLatest(loginSuccess.toString(), setupAfterLoginSuccess);
}

export default [loginSuccessSaga];
