// https://xgrommx.github.io/rx-book/content/how_do_i/simple_event_emitter.html

import { Subject } from "rxjs";

const subjects = {};

function createName(name) {
  return `$ ${name}`;
}

export const Emitter = {
  on: (name, handler) => {
    const fnName = createName(name);
    subjects[fnName] || (subjects[fnName] = new Subject());
    return subjects[fnName].subscribe(handler);
  },

  emit: (name, data) => {
    const fnName = createName(name);
    subjects[fnName] || (subjects[fnName] = new Subject());
    subjects[fnName].next(data);
  },

  off: name => {
    const fnName = createName(name);
    if (subjects[fnName]) {
      subjects[fnName].dispose();
      delete subjects[fnName];
    }
  },

  listen: (name, handler) => {
    const fnName = createName(name);
    subjects[fnName] || (subjects[fnName] = new Subject());
    return subjects[fnName].subscribe(handler);
  }
};
