import React from 'react';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";

//Default Props
import defaultData from './social-icon';
import defaultGlobal from '../../../themes/global';

//Styles
import socialIconsStyles from './social-icon-list-styles';

//Attributes
import Types from '../../../attribute-types';

//Components
import SocialIcon from './social-icon';


export default class SocialIcons2 extends Component {

    constructor(props) {

        super(props);


    }

    render() {

        this.styles = socialIconsStyles(this.props.global, this.props.data);
        function sortByKey(array, key) {
            return array.sort(function(a, b) {
                var x = a[key]; var y = b[key];
                return ((x < y) ? -1 : ((x > y) ? 1 : 0));
            });
        }
        let socialIcons;
                if( this.props.site.social ) {
                    socialIcons = sortByKey(this.props.site.social, "sort");
                }
                else {
                    socialIcons = [];
                }

        return (
            <div className={ css(this.styles.socialIcons) }>
                <ul className={ css(this.styles.socialIconsList) }>
                    { socialIcons.map((socialIcon, index) => {
                            if( socialIcon.active ) {
                                return (
                                    <SocialIcon key={ index }
                                                data={ socialIcon }
                                                styles={ this.styles.socialIcon }
                                                global={ this.props.global }
                                                socialIconLinkClass={ this.styles.socialIconContainer }/>
                                )
                            }
                        })
                    }
                </ul>
                <div className="clearfix"/>
            </div>
        )
    }
}


SocialIcons2.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
