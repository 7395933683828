import {sortByKey} from '../../utils/sort-by-key';

export function sortUserOverrides(userOverrides) {
    let styleKeys = [];
    let mediaQueries = [];
    let editorQueries = [];
    let newObject = {};

    function getSort( key ) {
            if(key.indexOf('mobile') !== -1) {
                return 4;
            }
            if (key.indexOf('smallTablet') !== -1) {
                return 3;
            }
            if (key.indexOf('largeTablet') !== -1) {
                return 2
            }
                return 1;
    }

    //filter out media queries
    Object.keys(userOverrides).map((key) => {
        if (key.indexOf("@") !== -1) {
            mediaQueries.push({
                key: key,
                value: parseInt(key.replace(/\D/g, ""))
            })
        }
        else if (key.indexOf('~') !== -1) {
            editorQueries.push(
                {
                    key: key,
                    sort: getSort( key )
                }
            )
        }
        else {
            styleKeys.push(key);
        }

    });

    //sort media queries
    let sortedMediaQueries = sortByKey(mediaQueries, 'value').reverse();

    let sortedEditorQueries = sortByKey(editorQueries, 'sort');

    sortedMediaQueries.map((obj) => {
        styleKeys.push(obj.key)
    });
    sortedEditorQueries.map((obj) => {
        styleKeys.push(obj.key)
    });


     styleKeys.map((key) => {
        newObject[key] = userOverrides[key];
    });


    // Returns a new object with media queries all sorted properly
    return newObject;



}