/*global $, document */
/* eslint-disable */


//import $ from 'jquery';

import { include } from '@convertly/thor'

const ModalScript = ( id, showOnLoad ) => {

    const Cookie = include('../../../utils/cookie', 'Cookie');

    return ( id, showOnLoad ) => {

        let cookie = Cookie();

        let modal = $('#modalWidget_' + id);

        let launcher = $('#modalLauncher_' + id);

        let closebtn = $('#closeButton_' + id);

        const subscribed = cookie.read({
            name: "subscribed_to_newsletter"
        });

        const optedOut = cookie.read({
            name: "newsletter_opted_out"
        })

        if ( !optedOut && !subscribed ) {

            if (showOnLoad === 'true') {
                window.setTimeout( function()  {
                    modal.toggleClass('open');
                    $('body').css("overflow", "hidden");
                }, 4000)

            }

        }

        launcher.on('click', function() {
            modal.toggleClass('open');
            $('body').css("overflow", "hidden");
        });

        closebtn.on('click', function() {
            modal.toggleClass('open');
            $('body').css("overflow", "auto");

            if ( !subscribed ) {
                cookie.create({
                    name: "newsletter_opted_out",
                    value: "opted out",
                    expires: false
                })
            }

        });

        // If they click on the background: close the modal
        $(window).on('click', function (e) {
            if (e.target.id === 'modalWidget_' + id) {
                modal.toggleClass('open');
                $('body').css("overflow", "auto");

                if ( !subscribed ) {
                    cookie.create({
                        name: "newsletter_opted_out",
                        value: "opted out",
                        expires: false
                    })
                }
            }
        })
    }
};

export default ModalScript;
