import React from 'react';
import {StyleSheet, css} from "@convertly/css";
import Component from '../../../rootComponent';
import {id} from '@convertly/thor';


import ReactHTMLParser from "@convertly/react-html-parser";

//Styles
import sliderStyles from './circular-nav-icons-small-styles';


//Component
export default class SliderNav01 extends Component {

    constructor(props) {

        super(props);

    };

    componentWillMount () {
                /**
                 * Checks to see if we are in the cms and updates inEditor
                 **/
                if ( this.context.hasOwnProperty ( "store" ) ) {
                    if( this.context.store ) {
                        if ( !this.props.notEditable ) {
                            this.setState ( { inEditor: true } );
                        }
                    }
                }
            }

    render() {
        if (this.props.editor) {
            return this.editor();
        }

        this.styles = sliderStyles(this.props.global, this.props.data, this.state.inEditor);

        let navIcons = [];

        for( let i = 0; i < parseInt(this.props.sliderSettings.numOfSlides); i ++ ) {
        let liClassName;
            if( this.state.inEditor ) {
                    if( this.props.activeSlide == this.props.data.slideNumber ) {
                        liClassName = " active"
                    }
                }
                else {

                    if (this.props.data.slideNumber === 0) {
                        liClassName = " active";
                    }
                    else {
                        liClassName = " right";
                    }
            }
            navIcons.push(
                <li key={ i }  className={ `${css( this.styles.convertlyControlNavItem )} liClassName controlNav` }
                    data-target={ i }/>
            )
        }

        return (



            <ol className={ css(this.styles.convertlyControlNav) } id="contorlNav">
                { navIcons }
            </ol>

        )
    }
}
