import  colorAssign  from '../colorAssign';

let defaultGlobal = {
    // colors
    colors: {
        dark: {
            color1: '#101418',
            color2: '#505B66',
            color3: '#809190',
            black: '#000'
        },
        accent: {
            color1: '#349886',
            color2: '#94DFB5',
            color3: '#EACA83'
        },
        light: {
            color1: '#FAFAFA',
            color2: '#E9E9E9',
            color3: '#D6DCE2',
            white: "#fff"
        }
    },

    textColors: {
        light: {},
        dark: {},
        accent: {}
    },
    backgroundColors: {
        light: {},
        dark: {},
        accent: {}
    },
    // Module Default wrapper
    moduleDefault: {
        margin:'50px 0',
        padding:'50px 20px',
    },

    /** Misc Styles */
    themeBorder: {
        border: '3px solid',
    },
    divider: {
        backgroundColor: "#2d343d",
        width: "100%",
        height: "3px",
        marginTop: "10px",
        marginBottom: "10px"
    },
    figCaption: {
        position:'absolute',
        bottom:'5px',
        background:'rgba(0, 0, 0, .5)',
        width:'100%',
        padding:'30px 20px',
        color:'#fff',
        "@media (max-width: 479px)": {
            padding:'10px'
        }
    },
    // Body background
    bodyBackgroundColor: '',
    // Typography
    fontFamily: {
        primaryFont: " 'Rubik', sans-serif",
        secondaryFont: "'Rubik', sans-serif",
    },
    fonts: [ 'Rubik:400,900' ],
    h1: {
        "* a": {},
        margin:'20px 0 10px 0',
        fontSize: '65px',
        lineHeight: '75px',
        "@media (max-width: 767px)": {
            fontSize: "55px",
            lineHeight: "65px"
        },
        "@media (max-width: 479px)": {
            fontSize: "45px",
            lineHeight: "55px"
        }
    },
    h2: {
        "* a": {},
        fontSize: '26px',
        lineHeight:"36px"
    },
    h3: {
        "* a": {},
        fontSize: "24px",
        lineHeight: "34px"
    },
    h4: {
        "* a": {},
        fontSize: "22px",
        lineHeight: "32px"
    },
    h5: {
        "* a": {},
        fontSize: '20px',
        lineHeight:'30px'
    },
    h6: {
        "* a": {},
        fontSize:'16px',
        lineHeight:'26px'
    },
    p: {
        "* a": {},
        fontSize: '16px',
        lineHeight: '26px'
    },
    list: {
        "* a": {},
        marginBottom: '20px',
        paddingLeft: '30px'
    },
    listItem: {
        marginBottom:'5px'
    },
    // buttons
    button1: {
        height:'45px',
        width:'190px',
        display:'inline-flex',
        justifyContent:'center',
        alignItems:'center',
        borderRadius: "5px",
        background:'#132755',
        color:"#fff",
        border:'none',
        maxWidth:'90%',
        "@media(max-width:767px)": {
            width:'100%',
            display:'block',
            margin:'5px auto',
        }
    },
    button2: {
        display: 'inline-block',
        padding: '10px 15px',
        fontSize: '1.3em',
        lineHeight: '18px',
        borderRadius: '4px',
        transition: 'all 0.3s linear 0s',

    },
    button3: {
        padding: '0 70px',
        fontSize: '20px',
        height: '70px',
        lineHeight: '70px',
        display: 'inline-block',
        transition: 'all 0.3s'
    },
    // Form
    form: {
        height: 'auto',
        padding: "30px",
        width: '100%'
    },
    formErrorMessage: {
        color: "red",
        fontSize:'12px'
    },
    input: {
        height: "38px",
        width: '100%',
        "::placeholder": {},
        fontSize: '16px',
        lineHeight: '16px',
        border:'none',
        borderBottom: "3px solid",
        borderRadius: '0px',
        padding: "8px 0",
        marginBottom: '10px',
        background:'transparent',
        transition:'border 1s ease',
        ":focus": {
            outline:'none'
        }
    },
    selectInput: {
        borderRadius: '2px',
        fontFamily: "'Work Sans', sans-serif",
        width: '100%',
        height: "40px",
        opactiy: "0",
        appearance: 'none',
        padding: "10px"
    },
    svgDiv: {
        height: '40px'
    },
    checkboxInput:{
        marginRight: "5px",
        marginLeft: "5px",
    },
    checkboxLabel:{},
    radioInput:{
        marginRight: "5px",
        marginLeft: "5px",
    },
    radioLabel:{},
    submitButton:{
        fontWeight: "700",
        border: "none",
        padding: "10px 25px"
    },
    label: {
        fontSize: "16px",
        marginBottom: "5px"
    }
};

export default function theme ( global ) {

    global = global ? global : defaultGlobal;

    colorAssign( global );

    //Body Background Color
    global.bodyBackgroundColor = global.backgroundColors.light.color1;

    // Module Default Wrapper Styles
    global.moduleDefault.fontFamily = global.fontFamily.primaryFont;

    // Theme Border
    global.themeBorder.borderColor = global.backgroundColors.light.color3;

    // Form Styles
    global.form.backgroundColor = global.backgroundColors.light.white;

    global.input.borderColor = global.backgroundColors.light.white;
    global.input.color = global.textColors.dark.color1;
    global.input.fontFamily = global.fontFamily.primaryFont;
    global.input.color = global.textColors.light.white;
    global.input["::placeholder"].color = global.textColors.dark.color3;
    global.input["::placeholder"].fontFamily = global.fontFamily.primaryFont;
    global.input[":focus"].borderColor = global.backgroundColors.accent.color1;

    global.selectInput.backgroundColor = global.backgroundColors.light.white;
    global.selectInput.borderColor = global.backgroundColors.dark.color1;
    global.selectInput.color = global.textColors.dark.color1;
    global.selectInput.fontFamily = global.fontFamily.primaryFont;

    global.svgDiv.backgroundColor = global.backgroundColors.light.white;

    global.checkboxLabel.color = global.textColors.dark.color1;
    global.checkboxLabel.fontFamily = global.fontFamily.primaryFont;

    global.radioLabel.color = global.textColors.dark.color1;
    global.radioLabel.fontFamily = global.fontFamily.primaryFont;

    global.submitButton.color = global.textColors.light.white;
    global.submitButton.backgroundColor = global.backgroundColors.accent.color2;
    global.submitButton.fontFamily = global.fontFamily.primaryFont;

    global.label.color = global.textColors.dark.color1;
    global.label.fontFamily = global.fontFamily.primaryFont;

    // typography Styles
    global.h1.color = global.textColors.dark.color1;
    global.h2.color = global.textColors.dark.color1;
    global.h3.color = global.textColors.dark.color1;
    global.h4.color = global.textColors.dark.color1;
    global.h5.color = global.textColors.dark.color1;
    global.h6.color = global.textColors.dark.color1;
    global.p.color = global.textColors.dark.color2;
    global.list.color = global.textColors.dark.color2;

    global.h1.fontFamily = global.fontFamily.secondaryFont;
    global.h2.fontFamily = global.fontFamily.primaryFont;
    global.h3.fontFamily = global.fontFamily.secondaryFont;
    global.h4.fontFamily = global.fontFamily.primaryFont;
    global.h5.fontFamily = global.fontFamily.primaryFont;
    global.h6.fontFamily = global.fontFamily.primaryFont;
    global.p.fontFamily = global.fontFamily.primaryFont;
    global.list.fontFamily = global.fontFamily.primaryFont;


    //Button Styles
    const defaultButtonStyles = {
        //background: global.backgroundColors.accent.color2,
        //textColor: global.textColors.light.white,
        //borderColor: global.backgroundColors.accent.color2,
        //fontFamily: global.fontFamily.secondaryFont
    };
    const alternateButtonStyles = {
        background: global.backgroundColors.dark.color2,
        textColor: global.textColors.light.white,
        fontFamily: global.fontFamily.secondaryFont,
        borderColor: global.backgroundColors.dark.color2
    };

    global.button1 = {
        ...global.button1,
        ...defaultButtonStyles
    };

    global.button1A = {
        ...global.button1,
        ...alternateButtonStyles
    };

    global.button2 = {
        ...global.button2,
        ...defaultButtonStyles
    };

    global.button2A = {
        ...global.button2,
        ...alternateButtonStyles
    };

    global.button3 = {
        ...global.button3,
        ...defaultButtonStyles
    };

    global.button3A = {
        ...global.button3,
        ...alternateButtonStyles
    };

    return global;

}
