import _ from "lodash";
import { CONVERTLY_AUTHENTICATION } from "config";
import { fetchGraph } from "utils";
import Logger from "utils/logger";
import LocalStorage from "utils/localStorage";
import { showAppOverlay, showLoadingIcon, setAppOverlay } from "containers/App/actions";

import { loginSuccess } from "./actions";

// Private object to hold data
const UserObject = {
  isAuthenticated: false,
};

export function updateUserObject(bool) {
  UserObject.isAuthenticated = bool;
}

/**
 * Refreshes login status on page load
 *
 */
export const refreshLoginStatus = () => async dispatch => {
  Logger.auth("Authentication/Auth::refreshLoginStatus()");

  try {
    const response = await Promise.all([
      getRefreshToken(),
      dispatch(showAppOverlay(true)),
      dispatch(setAppOverlay(1)),
      dispatch(showLoadingIcon(true)),
    ]);

    const authenticationData = response[0];

    await dispatch(loginSuccess(authenticationData));

    await Promise.all([dispatch(showAppOverlay(false)), dispatch(showLoadingIcon(false))]);

    Logger.auth("Authentication/Auth::refreshLoginStatus() DONE");

    return true;
  } catch (e) {
    Logger.auth("Authentication/Auth::refreshLoginStatus() Error:", e.message);

    await dispatch(showAppOverlay(false));
    await dispatch(showLoadingIcon(false));

    Logger.auth("Authentication/Auth::refreshLoginStatus() DONE");

    throw e;
  }
};

/**
 * Returns login status
 * @returns {boolean}
 */
export function isLoggedIn() {
  Logger.auth("Authentication/Auth::isLoggedIn", UserObject.isAuthenticated);
  return UserObject.isAuthenticated;
}

/**
 * Get new Id token and Access Token
 *
 */
export async function getRefreshToken() {
  Logger.auth("Authentication/Auth::getRefreshToken()");

  const refreshToken = LocalStorage.getItem("refresh");
  const email = LocalStorage.getItem("user");

  if (!refreshToken || !email) {
    throw new Error("saved credentials not found");
  }

  const query = `
	query ($u: String!, $rt: String!) {
		RefreshToken(username: $u, refreshToken: $rt) {
			AccessToken
			IdToken
			accessPermissions {
				permissions
				permissionsList
				authenticationToken
				isSubscriber
				trialStart
				subscriptionData
				currentPlan
				user {
					firstName
					lastName
					name
					username
					email
					id
					roles
					partner
					sites {
						id, domain, chatEnabled, name, sort, screenshot, updatedAt
					}
				}
			}
			Error {
				code
				message
			}
		}
	}`;

  const variables = {
    u: email,
    rt: refreshToken,
  };

  let response = await fetchGraph({
    query,
    variables,
    url: CONVERTLY_AUTHENTICATION,
  });

  try {
    response = response.data.RefreshToken || {};

    if (_.get(response, "Error.message")) {
      throw new Error(response.Error.message);
    }

    return {
      ...response,
      RefreshToken: refreshToken,
    };
  } catch (e) {
    throw new Error(e.message);
  }
}

// Helper functions for User object
function Get() {
  return UserObject.user;
}

function Tokens() {
  return UserObject.tokens;
}

// Public facing User Object
export const User = {
  get: Get,
  tokens: Tokens,
};
