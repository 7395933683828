import React from 'react';
import Component from '../../../rootComponent';
import { css } from "@convertly/css";
import { clientImage } from '../../../utils/generateImage';

//Default Data
import defaultData from '../footer-default-data';
import defaultGlobal from '../../../themes/global';

//Styles
import footerStyles from './footer-styles-04';

//Components
import Elements from '../../../elements/index';
import SocialIcons1 from '../../../widgets/social-icons/no-background-social-icons/social-icon-list-1';


export default class
Footer04 extends Component {

    constructor ( props ) {

        super( props );

    }
    render() {

        this.styles = footerStyles( this.props.global, this.props.data );

        if( this.props.editor ) {
            return this.editor();
        }
        const inlineStyle = this.generateStyleAttribute();
        return (
            <footer id="footer04" className={css( this.styles.mainFooter ) }
                onClick={(e) => {
                                   this.launchElementalEditor('footer', e);
                              }}
                              onMouseOver={ this.addEditableOutline }
                              onMouseOut={ this.removeEditableOutline }
                              { ...inlineStyle }
                              { ...this.generateDataEditorAttribute() }
            >
                <div className="container">
                     <div className="row">
                         {/** Footer Logo **/}
                        <Elements.Image
                            parentFigureClassName={ this.styles.footerLogoFigure }
                            parentImgClassName={ this.styles.footerLogo }
                            data={ this.props.data.logo }
                            global={ this.props.global }
                            elementDataName="logo"
                            moduleId={ this.props.id }
                            moduleSort={ this.props.sort }/>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            {/** Copy Text **/}
                            <div className={ css( this.styles.copyRightText ) }>
                                <Elements.Paragraph
                                    parentClassName={ this.styles.copyText }
                                    data={ this.props.data.paragraph }
                                    global={ this.props.global }
                                    elementDataName="paragraph"
                                    moduleId={ this.props.id }
                                    moduleSort={ this.props.sort }
                                />
                            </div>
                            {/** Privacy Links **/}
                            <ul className={ css( this.styles.footerNavLinks ) } >
                                {
                                    this.props.data.copyRightLinks.map( ( item, index )  => {
                                        return (
                                            <li key={ index } className={ css( this.styles.copyRightLinks ) } >
                                                <Elements.WebLink
                                                    data={ item }
                                                    notEditable={ true }
                                                    moduleId={ this.props.id }
                                                    moduleSort={ this.props.sort }
                                                />
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }

}

Footer04.defaultProps= {
    data: defaultData,
    global: defaultGlobal
};
