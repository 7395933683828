import { fromJS } from "immutable";
import { createReducer } from "redux-act";

import { setToggled } from "./actions";

const initialParams = {
  toggledLayers: {},
};

const initialState = fromJS(initialParams);

const reducers = {
  [setToggled]: (state, toggledLayers) => state.merge({ toggledLayers }),
};

export const LayersPanelReducer = createReducer(reducers, initialState);
