import { createAction } from "redux-act";
import { fromJS } from "immutable";

import { store } from "app/app";
import { PANELS, POST_STATUS } from "../../constants";
import { delay } from "utils";

import { getBlogPanelState } from "../actions";

import {
  setProcessing,
  changePanel,
  setPreviewLink,
} from "containers/Editor/actions/navBarActions";
import { clearEditorPanel } from "containers/Editor/actions/editorActions";
import { setPost } from "../EditPost/actions";

const NUM_POSTS_TO_LOAD_PER_FETCH = 20;

export const setPosts = createAction("BLOGS PANEL: Set posts array");
export const setActivePostTypeOption = createAction("BLOGS PANEL: Set Post Type Option");
export const setNextPostLoadIndex = createAction("BLOGS PANEL: Set index for posts to load");
export const setPostsListInitialLoadComplete = createAction(
  "BLOGS PANEL: Set flag that posts have been loaded"
);
export const resetPostsList = createAction("BLOGS PANEL: Reset Posts List");

export const editPost = (post = fromJS({})) => async dispatch => {
  await dispatch(clearEditorPanel());
  await Promise.all([dispatch(setProcessing(true)), dispatch(setPost(post))]);

  // dramatic effect
  await delay(250);

  const postPanel = post.get("id") ? PANELS.EDIT_POST : PANELS.NEW_POST;

  await Promise.all([dispatch(changePanel(postPanel, PANELS.BLOG))]);
  await dispatch(setPreviewLink(post.get("slug")));
};

export const loadPosts = () => async dispatch => {
  await dispatch(setProcessing(true));

  const Blogs = getBlogPanelState(store.getState());

  const postType = Blogs.get("activePostTypeOption");

  const currentPosts = Blogs.getIn(["posts", postType]) || fromJS([]);
  const nextPostLoadIndex = Blogs.get("nextPostLoadIndex");
  const loadDrafts = postType === POST_STATUS.DRAFT;

  const posts = await fetchPosts(nextPostLoadIndex, loadDrafts);

  const newPosts = currentPosts.concat(fromJS(posts));

  await Promise.all([
    dispatch(setNextPostLoadIndex(nextPostLoadIndex + NUM_POSTS_TO_LOAD_PER_FETCH)),
    dispatch(setPosts(newPosts)),
    dispatch(setProcessing(false)),
    dispatch(setPostsListInitialLoadComplete(true)),
  ]);
};
