import { StyleSheet } from '@convertly/css';
import { generatePseudoMediaQuery } from '../../../elements/style-functions/pseudo-media-queries';

import cartScriptStyleObject from '../../scripts/cart-script-style-object';
import searchBarStyleObject from '../../../ecommerce-common-components/search-bar/searchBarStyleObject';

import {
  addValueToUserOverrides,
  createNewCssRules
} from '../../../utils/edit-userOverrides';
import _ from 'lodash';

const navStylesTopRight = (global, data, inEditor) => {
	

  const searchBarStyles = searchBarStyleObject(global, data, inEditor);
  const cartScriptStyles = cartScriptStyleObject(global, data, inEditor);
  const navLinkOverrides = _.get(data, "menu.userOverrides");

  let editedMobileCartOverrides = {};
  let editedIconBarOverrides = {};
  let editedNavLinkOverrides = {};
  let editedIconBackgroundColor = {};
  
  if(navLinkOverrides) {
    editedMobileCartOverrides = createNewCssRules(navLinkOverrides, 'color', ['fill']);
    editedIconBarOverrides = createNewCssRules(navLinkOverrides, 'color', ['backgroundColor']);
    editedIconBackgroundColor = createNewCssRules(data.userOverrides || {}, 'backgroundColor', ['backgroundColor']);
    editedNavLinkOverrides = {
      ...createNewCssRules(navLinkOverrides, 'color', ['color']),
      ...createNewCssRules(navLinkOverrides, 'fontSize', ['fontSize'])
    }
  }

  const searchBarWrapperFocusStyles = {
    borderBottom: `2px solid ${ global.colors.accent.color2 }`,
    fill: global.colors.accent.color2,
    'box-shadow': `0 0 0 0 ${ global.colors.dark.color3 }`,
  }

  return StyleSheet.create({
    searchBarWrapper: {
      display: 'inline-block',
      ...searchBarStyles,
      '.search-bar-results': {
        position: 'absolute',
        width: '100%',
        top: '-15px',
        height: '0',
        zIndex: '1'
      },
      '.search-bar-container': {
        width: '100%',
        ...generatePseudoMediaQuery(
          '@media(max-width: 991px)', ['largeTablet', 'smallTablet', 'mobile'], {
            display: 'block'
          },
          inEditor
        ),
      },
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
          display: 'none',
        },
        inEditor
      ),
      '.search-bar-wrapper': {
        display: 'flex',
        padding: '0px 4px',
        width: '100%',
        transition: 'all 0.2s linear',
        'align-items': 'center',
        borderBottom: `2px solid transparent`,
        'box-shadow': `0 1px 0 0 ${ global.colors.dark.color3 }`,
        // transform: 'translateY(26px)',
        ':focus-within': {
          ...searchBarWrapperFocusStyles
        },
        '.has-input': {
          ...searchBarWrapperFocusStyles
        }
      },
      '.search-bar-input': {
        border: 'none',
        padding: '0px 2px',
        height: '26px',
        width: '100%',
        backgroundColor: 'transparent',
        color: 'inherit',
        lineHeight: '22px',
        fontSize: 'inherit'
      },
      '.search-bar-input-placeholder': {
        position: 'absolute',
        left: '0',
        transition: 'all 0.3s linear',
        top: '3px',
        'pointer-events': 'none',
        '.has-input': {
          marginTop: '-15px',
          fontSize: '11px',
          color: global.colors.accent.color2,
        }
      },
    },
    desktopCartInfernoComponent: {
      ...cartScriptStyles['desktopCartInfernoComponent'],
      ".headerNavLink": {
        fontFamily: 'Open Sans',
        cursor: 'pointer',
        borderColor: data.moduleTextColor || "",
        display: 'flex',
        alignItems: 'left',
        height: '100%',
        fontSize: '14px',
        lineHeight: '19px',
        transition: 'all 0.3s linear 0s',
        position: 'relative',
        ...generatePseudoMediaQuery(
          '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
            display: 'none',
          },
          inEditor
        ),
        ...generatePseudoMediaQuery(
          "@media (max-width:480px)", 
          ['mobile'], 
          { padding: "0px 14px 0px" },
          inEditor
        ),
        ":hover ": {
          "@media(hover:hover)": {
            ".cartDropDown": {
              height: '460px',
              visibility: 'visible',
            },
            ".casper": {
              display: 'block'
            }
          },
        },
      },
      '.cartContainerFooter': {
        marginTop: '20px',
        textAlign: 'center',
        width: '100%',
        height: '5.1%'
      },
      color: global.colors.dark.color3,
      display: 'inline-block',
      height: '100%',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)', ['mobile', 'smallTablet'], {
          display: 'none'
        },
        inEditor
      ),
      ...editedNavLinkOverrides,
    },
    desktopCartInfernoWrapper: {
      display: 'inline-block',
      height: 'auto',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)',
        ['smallTablet', 'mobile'],
        {
          order: '2',
          width: '15%',
        },
        inEditor
      ),
      ...editedNavLinkOverrides
    },
    mainNavRightWrapper: { 
      display: 'inline-block',
      width: '60%',
      height: '100%',
      verticalAlign: 'middle',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
          width: '16%',
          paddingTop: inEditor ? '8px' : '0'
        },
        inEditor
      ),
    },
    mainNavRight: {
      color: global.colors.dark.color3,
      display: 'inline-block',
      width: '100%',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
          width: 'auto'
        },
        inEditor
      ),
      ...editedNavLinkOverrides
    },
    navRightItems: {
      display: 'inline-block',
      paddingTop: '3px',
      float: 'right'
    },
    navRightItem: {
      display: 'inline-block',
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '19px',
      align: 'left',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)', 
        ['mobile', 'smallTablet'], 
        {
          display: 'none'
        },
        inEditor
      ),
      ...editedNavLinkOverrides
    },
    verticalLines: {
      transform: 'translateY(2px)',
      height: editedNavLinkOverrides['fontSize'] || '14px',
      borderRight: `1px solid #666666`,
      margin: '0 17px',
      display: 'inline-block',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)', ['mobile', 'smallTablet'], {
          display: 'none'
        },
        inEditor
      ),
    },
    searchIcon: {
      display: 'inline-block',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)', ['mobile', 'smallTablet'], {
          display: 'none'
        },
        inEditor
      ),
      height: editedNavLinkOverrides['fontSize'] || "20px",
      width: editedNavLinkOverrides['fontSize'] || "20px",
      transform: 'translateY(23%)',
      fill: editedNavLinkOverrides['color'] || global.colors.dark.color3
    },
    dropDownMenuWrapper: {
      transform: 'translate(15%, 10px)',
      display: 'none',
      float: 'right',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
          display: 'inline-block',
          float: 'left'
        },
        inEditor
      ),
    },
    dropDownMenuIcon: {
      outline: 'none',
      border: 'none',
      overflow: 'visible',
      backgroundColor: global.colors.light.white,
      transform: 'translateY(-3px)',
      ...editedIconBackgroundColor
    },
    iconBar: {
      backgroundColor: global.colors.light.color3,
      width: '24px',
      height: '2px',
      marginBottom: '9px',
      position: 'relative',
      display: 'block',
      transition: 'all 0.3s ease-in-out 0s',
      '.open': {
        ':nth-child(1)': {
          transform: 'rotate(45deg)',
          top: '9px',
        },
        ':nth-child(3)': {
          transform: 'rotate(-45deg)',
          top: '-13px',
        },
        ':nth-child(2)': {
          opacity: '0'
        }
      },
      ...editedIconBarOverrides
    },
    mobileCartContainer: {
      display: 'none',
      position: 'absolute',
      width: '24px',
      right: '10px',
      top: _.get(data, "headerTopperSettings.display") ? '62px' : '24px',
      alignSelf: 'center',
      color: editedNavLinkOverrides['color'] || global.colors.dark.color3,
      fill: global.colors.dark.color3,
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
          display: 'inline-block',
          top: '43px',
          right: '76px'
        },
        inEditor
      ),
      ...generatePseudoMediaQuery(
        '@media(max-width: 475px)', ['mobile'], {
          top: `40px`,
          display: 'inline-block',
          right: '76px'
        },
        inEditor
      ),
      ...editedNavLinkOverrides,
      ...editedMobileCartOverrides
    },
    mobileCart: {
      display:'none',
      fill: global.colors.dark.color3,
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
          display:'block',
        },
        inEditor
      ),
    },
  })
}

export default navStylesTopRight;
