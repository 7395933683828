import React from 'react';
import PropTypes from 'prop-types';
import Component from '../../rootComponent';
import {css} from "@convertly/css";
import defaultGlobal from '../../themes/global.js';
import ReactHTMLParser from "@convertly/react-html-parser";

//Styles
import headingStyles from './heading5-styles';

//Utils
import generateId from '../../utils/generate-id';

/**
 * Class controls imports style functions that allows for user to override the css in the CMS editor for their site.
 **/
class Heading5 extends Component {

    constructor(props) {

        super(props);

    }

    render() {

        const inEditor = this.inEditor();

        /**
         * Calls Stylesheet function
         **/
        this.styles = headingStyles( this.props.global, this.props.data, this.props.data.headingStyle, inEditor);

        let className = '';

        let aphroditeClass = css ( this.styles.heading5 );

        if ( this.animatedClassName ) {
            className += " " + this.animatedClassName
        }
        className += aphroditeClass;

        const { animated } = this.props.data
        const inlineStyle = this.generateStyleAttribute( )

        return (

            <h5 { ...this.generateDataEditorAttribute() }
                {...generateId ( this.props.elementId )}
                { ...this.generateCustomAttributes() }
                onClick={( e ) => {
                    this.launchElementalEditor ( 'text', e );
                }}
                onMouseOver={ this.addEditableOutline }
                onMouseOut={ this.removeEditableOutline }
                { ...inlineStyle }
                data-animated={animated}
                className={ className }>
                { ReactHTMLParser(this.props.data.text) }</h5>

        );
    }
}


/**
 * Default Props for rendering when no data is being passed
 **/
Heading5.defaultProps = {
    data: {
        text: "Hello from the other side",
        bold: false,
        italic: false,
        underline: false,
        align: 'center',
        fontSize: '',
        color: ''
    },
    global: defaultGlobal
};

/**
 * Connects element to context for cms editor
 **/
Heading5.contextTypes = {
    store: PropTypes.object
};

export default Heading5

/**
 Component BoilerPlate

{
    "el": "Elements.Heading5",
    "data": {
        "text": "Your text here",
        "userOverrides": {

        }
    },
    "children": [],
    "sort", 900
}

 **/
