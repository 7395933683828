import React from "react";
import { injectStripe, Elements } from "react-stripe-elements";

/*
 Per docs, https://github.com/stripe/react-stripe-elements
 Elements needs to be above InjectedStripe HOC component

 InjectedWrapper gets passed stripe prop, which we save to Redux Store to use
 in actions
 */

class Wrapper extends React.Component {
  /**
   * Stripe is automatically passed in as a prop from the InjectStripe HOC
   * Need to save it to the Store so can use it in the stripeActions file
   */
  componentWillMount() {
    this.props.setStripe(this.props.stripe);
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

Wrapper.defaultProps = {
  setStripe: () => {}
};

const InjectedWrapper = injectStripe(Wrapper);

export const StripeWrapper = ({ children, setStripe }) => (
  <Elements>
    <InjectedWrapper setStripe={setStripe}>{children}</InjectedWrapper>
  </Elements>
);

StripeWrapper.defaultProps = {
  setStripe: () => {}
};
