import { generatePseudoMediaQuery } from "../../../elements/style-functions/pseudo-media-queries";

/**
 * Alt styles for the bubble widget in
 * product grid when this displays in the image overlay
 * @param global
 * @param data
 * @returns style object
 */

export default function StyleObject(global, data, inEditor) {
	return {
    ".bubble-page-wrapper": {
      overflow: 'auto',
      maxHeight: '250px',
      '-webkit-overflow-scrolling': 'touch',
      '::-webkit-scrollbar': {
        height: '5px',
        width: '5px',
        borderRadius: '25px'
      },
      '::-webkit-scrollbar-track': {
        background: global.colors.light.color2,
        borderRadius: '25px',
        height: '10px'
      },

      '::-webkit-scrollbar-thumb': {
        background: global.colors.light.color3,
        borderRadius: '25px',
        height: '10px'
      },

      '::-webkit-scrollbar-thumb:hover': {
        background: global.colors.dark.color3
      },
    },
    ".bubble-wrapper": {
      maxHeight: 'inherit',
      display: 'flex',
      flexDirection: 'row',
      '.pg-styles': {
        display: 'block'
      }
    },
    ".bubble-container": {
      whiteSpace: 'nowrap',
      padding: '1px 5px',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'hsla(0, 0%, 100%, .28)',
      borderRadius: '5px',
      backgroundColor: '#fff',
      transition: 'all 400ms ease',
      color: '#47474d',
      fontSize: '14px',
      textDecoration: 'none',
      margin: '0 4px 7px',
      cursor: 'pointer',
      fontFamily: global.fontFamily.primaryFont,
      ":hover": {
        background: global.colors.accent.color2,
        color: '#fff'
      },
      ".active": {
        background: global.colors.accent.color2,
        color: '#fff',
        borderColor: global.colors.light.white
      },
      ".disabled": {
        backgroundColor: 'hsla(0, 0%, 100%, .3)',
        direction: 'ltr',
        color: '#ccc',
        textDecoration: 'line-through',
        cursor: 'not-allowed'
      },
      '.more-button': {
        backgroundColor: '#000',
        color: '#fff',
      },
      '.pg-styles': {
        display: 'inline-block',
      },
      '.pg-styles .more-button': {
        display: 'inline-block',
      },
    },
		".more-caret": {
      fontSize:'.9em'
    },
    '.bubble-value': {
      display: 'inline-block',
    }
		
	}
	
	
}
