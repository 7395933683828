import { StyleSheet } from "@convertly/css";

const imageGalleryStyles = ( global, data ) => {


    return StyleSheet.create({

        imageGallery: {
            margin: '0',
            position: 'relative',
            borderRadius: '4px',
        },
        featureImgContainer: {
            height:'max-content'
        },
        featureImage: {
            boxShadow: '0 5px 12px #d7d7d7',
            display: 'block',
            borderRadius: 0,
            height: 'auto',
            width: '100%',
            maxHeight:'450px',
            maxWidth:'450px',
            margin: '0 auto',
            transition:'all 1s ease',
            '@media screen and (max-width: 766px)': {
                width: '50%'
            },
            '@media screen and (max-width:450px )': {
                width: '100%'
            },

        },
        imageThumbnailList: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
            textAlign:'center',
            marginTop: '40px',
        },
        imageThumbnailItem: {
            margin:'20px',
            position: 'relative',
            display: 'inline-block',
            opacity: '.5',
            zIndex: 2,
            cursor: 'pointer',
            boxShadow: '0 1px 4px rgba(0, 0, 0, 0.4)',
            height:'100px',
            width:'100px',
            transition:'all 1s ease',
            '.active': {
                opacity: '1',
            },
            ':hover': {
                opacity: '1',
                boxShadow: '0 3px 7px rgba(0, 0, 0, 0.4)',
            },
            '@media (max-width:991px)': {
                margin:'20px 10px',
                height:'75px',
                width:'75px'
            }
        },
        imageThumbnail: {
            maxHeight:'100%',
            maxWidth:'100%',
        },

    })

};

export default imageGalleryStyles;
