import React from "react";
import styled from "styled-components";

export const AppOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: ${({ zIndex }) => zIndex};
  opacity: ${({ opacity }) => opacity};
  background-color: rgba(255, 255, 255, 1);
`;

AppOverlay.defaultProps = {
  zIndex: 2000,
  opacity: 0
};
