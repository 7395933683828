import React from 'react';
import PropTypes from 'prop-types';
import Component from '../../rootComponent';
import { css } from '@convertly/css';
import _ from 'lodash'

//styles

import userOverrides from './bootstrap-user-overrides.js';


export default class Container extends Component {
    constructor ( props ) {
        super( props );
    }

    render() {

        const inEditor = this.inEditor();

        this.styles = userOverrides( this.props.global, this.props.data, inEditor );
        const elementId = _.get(this.props, 'data.elementId');
        /**
         * Adds ID and sort to elements
         * @param props
         * @returns {*}
         */

        let containerType = 'container';

        let className = '';

        let aphroditeClass = css( this.styles.userOverrides );

        if (this.props.data && this.props.data.containerFluid) {
            containerType = 'container-fluid';
        }

        if (this.props.data && this.props.data.defaultClass) {
            containerType += ' ' + this.props.data.defaultClass
        }


        className += containerType;
        className += " " + aphroditeClass;

        function renderChildren(props) {

            return React.Children.map(props.children, child => {
              if(!child) return;
                return React.cloneElement(child, {
                    moduleId: props.moduleId,
                    moduleSort: props.moduleSort,
                    styles: props.styles,
                    site: props.site,
                    sliderSettings: props.sliderSettings ? props.sliderSettings : null
                });
            })
        }

        /** In editor styles **/
            // return to inline react styles
        let container = {};

        let elementIdAttr = elementId ? {id:elementId} : {}

        if(inEditor) {

            if (this.context.store.getState().get('editor').editorReducer.get('editorViewportWidth') === 'mobile') {

                container = {width: '100%'};

            }
            else if (this.context.store.getState().get('editor').editorReducer.get('editorViewportWidth') === 'smallTablet') {

                container = {width: '750px'};

            }
            else if (this.context.store.getState().get('editor').editorReducer.get('editorViewportWidth') === 'largeTablet') {

                container = {width: '979px'};


            }
            else {

                container = {};

            }
        }

        return (
            <div
                {...elementIdAttr}
                { ...this.generateCustomAttributes() }
                className={ className } >
                { renderChildren( this.props ) }
            </div>
        )
    }
}
Container.contextTypes = {
    store: PropTypes.object
};
/**
 Component BoilerPlate

 {
     "el": "Elements.Container",
     "data": {
         "containerFluid": false,
         "userOverrides": {

         }
     },
     "children": [
         {
             "el": "Elements.Row",
             "data": {
                 "userOverrides": {

                 }
             },
             "children": [
                 {
                     "el": "Elements.Column",
                     "data": {
                         "xsOffset": 12,
                         "xsColumns": 12,
                         "xsPull": 12,
                         "xsPush": 12,
                         "userOverrides": {

                         }
                     },
                     "children": [

                     ],
                     "sort": 900
                 }
             ],
             "sort": 900
         }
     ],
     "sort": 900
 }

 **/
