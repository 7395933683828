import React from 'react';

export const ImgThumb = (props) => (
  <svg {...props} width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/thumb" fill="#414141">
        <path
          d="M22.5,21 C22.5,21.828 21.828,22.5 21,22.5 L17.124,22.5 L11.598,16.90125 L18,10.49925 L22.5,14.99925 L22.5,21 L22.5,21 Z M3,22.5 C2.172,22.5 1.5,21.828 1.5,21 L1.5,20.29575 L7.45875,14.95875 L15.00075,22.5 L3,22.5 L3,22.5 Z M6,3 C7.65675,3 9,4.34325 9,6 C9,7.65675 7.65675,9 6,9 C4.34325,9 3,7.65675 3,6 C3,4.34325 4.34325,3 6,3 L6,3 Z M21,0 L3,0 C1.34325,0 0,1.34325 0,3 L0,21 C0,22.65675 1.34325,24 3,24 L21,24 C22.65675,24 24,22.65675 24,21 L24,3 C24,1.34325 22.65675,0 21,0 L21,0 Z M6,7.5 C6.828,7.5 7.5,6.828 7.5,6 C7.5,5.172 6.828,4.5 6,4.5 C5.172,4.5 4.5,5.172 4.5,6 C4.5,6.828 5.172,7.5 6,7.5 L6,7.5 Z"
          id="Fill-216"/>
      </g>
    </g>
  </svg>
)
