/**
 * Assigns text colors to background and text
 * @param global
 * @returns {*}
 */
export function colorAssign ( global ) {

    Object.keys( global.colors ).map( ( color ) => {

        if(!global.textColors) {
            global.textColors = {}
        }
        if(!global.backgroundColors) {
            global.backgroundColors = {}
        }
        global.textColors[color] = global.colors[color];
        global.backgroundColors[color] = global.colors[color];

    });

return global;
}
