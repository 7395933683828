import { getRenderState } from '@convertly/thor';

/**
 * Generates an HTML ID attribute
 * @param id
 * @returns {*}
 */
export function generateId( id ) {

    const idObj = {
        id: id
    };

    return id ? idObj : {};

}


/**
 * Generates HTML data attribute
 * @param dataAttribute - string of full data attribute you want to add
 * @param value - value you want assigned to data
 * @returns data-attribute="value"
 */
export function generateDataAttribute( dataAttribute, value ) {


    if( value ) {
        let customDataAttribute = [];

        customDataAttribute[dataAttribute] = value;

        return value ? customDataAttribute : {};
    }
    else {
        return;
    }

}


/**
 * Generates a HTML className
 * @param className
 * @returns {*}
 */
export function generateClassName( className ) {

    const classNameObj = {
        className: className
    };

    return className ? classNameObj : {};

}


/**
 * Generates an Access key
 * @param accessKey
 * @param showAccessKey
 * @returns {*}
 */
export function generateAccessKey( accessKey, showAccessKey ) {

    let showAccessKeyValue = showAccessKey ? showAccessKey : true;

    const accessKeyObj = {
        accessKey: accessKey
    };

    return showAccessKeyValue ? accessKeyObj : {};

}
/**
 * WIP:Generates Style tag only in editor
 * @param style
 * @returns {*}
 */
export function generateStyleAttribute( rule, style ) {

    if( !getRenderState() ) {

        const styleAttribute = {
            style: rule + style
        };

        return style ? styleAttribute : {};
    }
    const styleAttribute = {
        style: rule + style
    };

    return style ? styleAttribute : {};
    return {}





}
