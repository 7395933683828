import React from 'react';
import _ from 'lodash';
import Component from '../../../../rootComponent';
import {css} from "@convertly/css";
import {id}  from '@convertly/thor';
import ReactHTMLParser from "@convertly/react-html-parser";

//Default Props
import defaultData from '../../header-default-data';
import defaultGlobal from '../../../../themes/global';

//Styles
import navStyles07 from './nav-styles-07.js';

//Scripts
import toggleMobileNav from '../../scripts/mobile-nav';
import toggleDropDown from '../../scripts/nav-dropdown-toggle';

import cartScript from '../../scripts/cart-script';
import isSignedIn from '../../scripts/is-signed-in'

//Components
import Elements from 'source//elements/index';
import Widgets from 'source/widgets/index';
import SVG from '../../../../svg/index';
import GoogleMapsLink from '../../../../elements/links/google-map-link';
import EmailLink from '../../../../elements/links/email-link';
import NavList07 from './nav-list-07';
import PhoneNumberLink from "../../../../elements/links/phone-number-link";

//utils
import {generateLink} from '../../../../utils/generateLink';
import {clientImage} from '../../../../utils/generateImage';


export default class HeaderNav07 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showHeaderMenu: false,
            toggledClass: ''
        };

    }


    render() {

        let { data, global, styles, site, inEditor } = this.props;

        this.styles = navStyles07(global, data, inEditor);

        let rootId = id(toggleMobileNav);

        const clientImgSrc = clientImage("", {width: '60', height: '60', crop: 'fit'});
        const clientLinkUrl = generateLink({linkType: "inferno", url: ""});




        let cartDiv = null;
        let signIn = null;

        const slug = _.get( this.props, 'page.slug' );


            const enabled = _.get( this.props, 'site.ecommerce.enabled' );

            if ( enabled ) {
                if(slug !== "/cart") {
                    cartDiv =
                        <div className={ css(styles.navItemContainer) }>
                            <div className={ css(this.styles.desktopCartInfernoComponent) }
                                 id={ id(cartScript, clientLinkUrl, clientImgSrc, false, slug) }/>
                        </div>;
                }
                signIn =
                    <div className={ css(styles.navItemContainer, styles.mobileHide) }>
                        <div className={ css(this.styles.desktopCartInfernoComponent) }
                             id={ id( isSignedIn, clientLinkUrl ) }/>
                    </div>;
            }

            let topHeaderText = "Connect";

            if ( data.topHeaderText && data.topHeaderText.text ) {
                topHeaderText = <Elements.Paragraph
                                    data={
                                        { ...data.topHeaderText }
                                    } />;
            }

        return (
            <Elements.Container>

                <Elements.Row>

                    <div className={css(styles.headerTop)}>

                        <div className={ css(styles.mobileMenu ) + " mobileNav_" + rootId }>
                            <div className={ css( styles.mobileNavContent ) }>

                                <NavList07
                                    styles={ this.styles }
                                    global={ global }
                                    site={ site }
                                    data={ data.menu } />

                                <Widgets.SocialSharing01
                                    data={ data }
                                    global={ global }
                                    styles={ styles }
                                    socialIconLinkClass={ styles.socialIconContainer }/>


                            </div>
                        </div>

                        <div className={ css(styles.mobileMenu2 ) + " mobileNav2_" + rootId}>
                        </div>

                        {/** TOP HEADER */}
                        <div className={css(styles.header_top)}>

                            {/** SOCIAL / CONNECT WITH US CONTAINER */}
                            <div>
                                <div className={css(styles.header_top_lft)}>

                                    {/*SOCIAL ICONS*/}
                                    <Widgets.SocialSharing01
                                        data={ data }
                                        global={ global }
                                        styles={ styles }
                                        socialIconLinkClass={ styles.socialIconContainer }/>

                                    {/** CONNECT WITH US */}
                                    <div className={css(styles.connect_us)}>
                                        <span className={css(styles.span_ymLn)}>
                                            { topHeaderText }
                                        </span>
                                    </div>

                                </div>
                            </div>

                            {/** TOP HEADER LINKS CONTAINER */}
                            <div className={css(styles.account)}>

                            </div>

                        </div>

                        {/** BOTTOM HEADER */}
                        <div className={css(styles.mainHeader)}>

                            {/** MOBILE MENU TOGGLE BUTTON*/}
                            <button
                                id={ rootId }
                                data-target="#menu"
                                type="button"
                                className={ css(this.styles.navLaunch) }>

                                <span className="sr-only">
                                    Toggle navigation
                                </span>

                                <span
                                    className={ css(this.styles.iconBar ) + " icon-bar" }
                                    data-target="#menu">
                                </span>

                                <span
                                    className={ css(this.styles.iconBar ) + " icon-bar" }
                                    data-target="#menu">
                                </span>

                                <span
                                    className={ css(this.styles.iconBar ) + " icon-bar" }
                                    data-target="#menu">
                                </span>

                            </button>

                            {/** LOGO */}
                            <a href={ data.logoLink } className={css(styles.logo)}>
                                <img src={ clientImage(data.logo.src, {}, site.siteId ) }
                                     className={ css( styles.logoImg ) }/>

                            </a>

                                    {/** SHOP BUTTON + DROPDOWN MENU */}
                            <div className={ css(styles.navRight) }>
                                    <NavList07
                                        styles={ this.styles }
                                        hideMobile={ true }
                                        global={ global }
                                        data={ data.menu }/>

                                { cartDiv }
                                { signIn }
                            </div>


                        </div>

                    </div>

                </Elements.Row>

            </Elements.Container>

        )
    }
}

HeaderNav07.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
