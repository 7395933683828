import React from "react";
import Component from "source/rootComponent";
import { css } from "@convertly/css";
import _ from "lodash";

// Style
import { TitleStyles }from "./TitleStyles";

export class Title extends Component {
  render() {
    const { data, inEditor, global, blogData } = this.props;

    if(_.isEmpty(blogData)) {
      return null;
    }

    let title;

    if (_.isArray(blogData)) {
      title = _.get(blogData[0], "postTitle") || "";
    } else {
      title = _.get(blogData, "postTitle") || "";
    }

    this.styles = TitleStyles({ global, data, inEditor });

    const { titleStyle } = this.styles;

    const { characterCap, text } = data || {};

    if (!title && !text) {
      return null;
    } else if (!title) {
      title = text;
    }

    if (Number.isInteger(+characterCap) && +characterCap > 0 && title.length > characterCap) {
      title = `${title.substring(0, characterCap)}...`;
    }

    const inlineStyle = this.generateStyleAttribute();
    return (
      <div
        {...inlineStyle}
        {...this.generateDataEditorAttribute()}
        {...this.generateCustomAttributes()}
        className={css(titleStyle)}
      >
        {title}
      </div>
    );
  }
}
