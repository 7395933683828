/*global Inferno, $ */
/* eslint-disable */
import {include} from '@convertly/thor';

const Script = (id) => {

        /** Imports **/
        const Graph = include('../../../utils/graph', 'Graph');
        const Dispatcher = include('../../../utils/dispatcher', 'Dispatcher');
        const ConvertToCurrency = include('../../../utils/ecomm-utils/convert-to-currency-ii', 'ConvertToCurrency');


        return (id) => {
            const hasLocalStorage = $('html').hasClass('localstorage');

            const root = document.getElementById(id);
            /** Init Imports **/
            //utils
            const convertToCurrency = ConvertToCurrency();
            const graph = Graph();
            const dispatch = Dispatcher(id);

            const SubTotals = Inferno.createClass({

                    getInitialState: function () {

                        return {
                            items: [],
                            subTotal: parseFloat('0.00'),
                            cartSubTotal: 0,
                            shippingData:{}
                        };
                    },
                    /* updates sub total based on all pricing factors */
                /** Gets total cost of all items in cart **/
                getCartTotal: function () {
                    dispatch.on('cart', (cart) => {
                        let cartTotal = 0;
                        cart.map((cartItem) => {
                            let cartItemPrice = parseFloat(cartItem.finalPrice * cartItem.quantity);
                            cartTotal += cartItemPrice;
                            return cartTotal
                        });
                        this.setState({cartSubTotal: cartTotal});
                        this.updateSubTotal();
                    })
                },
                updateSubTotal: function () {
                        let subTotal = this.state.cartSubTotal;
                        if (this.state.shippingData.amount_local) {
                            subTotal = parseInt(this.state.cartSubTotal) + parseInt(this.state.shippingData.amount_local);
                        }
                        this.setState({
                            subTotal: subTotal,
                            montizedsubTotal: convertToCurrency(subTotal),
                        });
                    },
                    componentDidMount() {
                        this.getCartTotal();

                    },

                    render: function () {

                        return (
                            <div>
                                <div className={ `${root.className} chkLabel` }>Subtotal:</div>
                                <div className={ `${root.className} chkSubtotal` }>{ convertToCurrency( this.state.subTotal ) }</div>
                            </div>
                        )
                    }

                }
            );

            Inferno.render(<SubTotals />, root);

        }
            ;

    }
;

export default Script;
