import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { browserHistory } from "react-router";
import _ from "lodash";

import Logo from "components/icons/convertly-logo.svg";
import { ProfileLink } from "./ProfileLink";

import Menu from "../MainMenu";
import MenuConductor from "./MenuConductor";
import { closeBannerEditor } from "containers/Marketing/Banners/actions";
import { toggleMainMenu, setMainMenu } from "containers/App/actions";
import { setNavigation } from "containers/Editor/actions/editorActions";
import { SiteSelector } from "./SiteSelector";

import { isPartner } from "containers/Authentication/PermissionsCheck";

const PARTNER_SLUG = "/partner";

const Burger = styled.i`
  vertical-align: middle;
  margin: 0 6px;
  background-color: #fff;
  color: #666;
  position: relative;
  font-size: 40px !important;
  cursor: pointer;
  display: inline-block;

  @media print {
    display: none;
  }
`;

const css = {
  container: {
    boxShadow: "0 0 4px 0 rgba(0,0,0,0.25)",
    zIndex: 2004,
    width: "100%",
    position: "fixed",
    backgroundColor: "white",
    height: "3em",
    top: "0em",
    display: "flex",
    alignItems: "center",
  },
  menu: {
    verticalAlign: "middle",
    marginRight: "0.5em",
    backgroundColor: "black",
    color: "white",
    position: "relative",
    fontSize: "3em",
    cursor: "pointer",
  },
  chat: {
    marginLeft: "1.2em",
  },
  img: {
    height: "2em",
  },
  imgWrapper: {
    position: "relative",
    width: "250px",
    paddingLeft: "20px",
    borderRight: "1px solid #E5E5E5",
  },
  editMenu: {
    display: "flex",
    alignItems: "center",
    marginLeft: "2em",
  },
  publish: {
    display: "inline-block",
    position: "relative",
    height: "24px",
    width: "74px",
    backgroundColor: "#90ee90",
    borderRadius: "5px",
    float: "right",
    marginRight: "20px",
    outline: "none",
    opacity: 1,
  },
  publishIcon: {
    fontSize: "16px",
    marginRight: "3px",
  },
  publishWrapper: {
    alignSelf: "center",
  },
  rightMenu: {
    display: "flex",
    alignItems: "center",
  },
};

export default class Header extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.toggleMainMenu = this.toggleMainMenu.bind(this);
    this.setMenu = this.setMenu.bind(this);
    this.handleChatClick = this.handleChatClick.bind(this);
    this.closeBanner = this.closeBanner.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    // not sure what use case this would be... PM
    if (_.get(nextProps, "location.pathname") !== "/marketing/banners") {
      if (this.props.bannerEditorIsOpen) {
        this.closeBanner();
      }
    }
  }

  /**
   *
   * @returns {*}
   */
  renderHamburger() {
    return null;
    if (this.props.location.pathname === PARTNER_SLUG) {
      return null;
    }

    if (this.props.bannerEditorIsOpen || this.props.editorIsOpen)
      return (
        <i
          className="material-icons"
          style={{ fontSize: 30, marginRight: 20, marginLeft: 10, cursor: "pointer" }}
          onClick={this.closeBanner}
        >
          arrow_back
        </i>
      );

    return (
      <Burger
        data-action={"toggle-navigation-menu"}
        className="material-icons"
        onClick={this.toggleMainMenu}
      >
        menu
      </Burger>
    );
  }

  closeBanner() {
    if (this.props.bannerEditorIsOpen) {
      return this.props.dispatch(closeBannerEditor());
    }
    return browserHistory.push("/dashboard");
  }

  /**
   *
   * @param e
   */
  handleChatClick(e) {
    this.props.dispatch(setNavigation(false));
  }

  /**
   *
   */
  toggleMainMenu() {
    this.props.dispatch(toggleMainMenu());
    this.props.dispatch(setNavigation(false));
  }

  /**
   *
   * @param e
   */
  setMenu(e) {
    this.props.dispatch(setMainMenu(true));
  }

  /**
   *
   * @returns {*}
   */
  renderMainMenu() {
    if (this.props.location.pathname === PARTNER_SLUG) {
      return null;
    }
    if (this.props.isMenuOpen || this.props.location.pathname !== "/editor") {
      return (
        <Menu
          site={this.props.currentSite}
          isMenuOpen={this.props.isMenuOpen}
          left="0em"
          location={this.props.location.pathname}
          bannerEditorIsOpen={this.props.bannerEditorIsOpen}
        />
      );
    }
    return <Menu site={this.props.currentSite} isMenuOpen={this.props.isMenuOpen} left="-13em" />;
  }

  /**
   *
   * @returns {XML}
   */
  render() {
    if (window.location.pathname === "/editor") {
      return null;
    }

    const {
      user,
      isUsingNewEditor,
      setUsingNewEditor,
      sites,
      activeSite,
      activateSite,
    } = this.props;

    return (
      <div style={css.container}>
        {this.renderHamburger()}
        <div style={css.imgWrapper}>
          <img style={css.img} src={Logo} alt="Convertly" />
        </div>
        {isPartner() && this.props.location.pathname !== PARTNER_SLUG && (
          <SiteSelector sites={sites} activeSite={activeSite} activateSite={activateSite} />
        )}

        <MenuConductor {...this.props} location={location} />

        <div style={css.rightMenu}>
          <div />

          <ProfileLink
            setUsingNewEditor={setUsingNewEditor}
            isUsingNewEditor={isUsingNewEditor}
            user={user}
          />
        </div>

        {this.renderMainMenu()}
      </div>
    );
  }
}

Header.contextTypes = {
  store: PropTypes.object,
};
