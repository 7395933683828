import React from 'react';
import PropTypes from 'prop-types';
import Component from '../../rootComponent';
import {css} from "@convertly/css";
import defaultGlobal from '../../themes/global.js';
import ReactHTMLParser from "@convertly/react-html-parser";
import _ from 'lodash';

//Utils
import {generateId, generateDataAttribute} from '../../utils/generate-attribute';
import {generateLink} from '../../utils/generateLink';

//Styles
import linkStyles from './link-styles';

/**
 * Class controls imports style functions that allows for user to override the css in the CMS editor for their site.
 **/
class Link extends Component {

  constructor(props) {

    super(props);

  }


  render() {

    const inEditor = this.inEditor();

    const {id, parentClassName, data, isChild, editable} = this.props;
    const {url, link, target, text, animated} = data;

    //Style Sheet
    this.styles = linkStyles(this.props.global, data, this.props.isChild, inEditor);

    // let openInNewTab = target;
    let openInNewTab = _.get(data, 'link.newTab') ;

    let className = '';

    let aphroditeClass = css(this.styles.link);

    if (this.animatedClassName) {
      className += " " + this.animatedClassName
    }

    else if (parentClassName) {

      className += " " + parentClassName

    }

    className += " " + aphroditeClass;

    const inlineStyle = this.generateStyleAttribute();

    let gaEvent, gaAction;
    if (data.link) {
      if (data.link.gaEvent) {
        gaEvent = data.link.gaEvent.event;
        gaAction = data.link.gaEvent.trigger;
      }
    }
    let params = !inEditor ? { href: url ? url : generateLink(link) } : {};
    if (editable) {

      return (
        <a { ...this.generateDataEditorAttribute() }
           {...generateId(this.props.elementId)}
           { ...!this.props.isChild && this.generateCustomAttributes() }
           {...generateDataAttribute('data-action', gaAction)}
           {...generateDataAttribute('data-event', gaEvent)}
           onMouseOver={(e) => {
             this.addEditableOutline(e, "child")
           }}
           onMouseOut={(e) => {
             this.removeEditableOutline(e, "child")
           }}
           {...inlineStyle}
           data-animated={animated}
           className={className}
           {...params}
           target={ openInNewTab ? "_blank" : "_self"}>{
          isChild ? this.props.children : ReactHTMLParser(data.text)
        }
        </a>
      );
    } else {
      return (
        <a {...generateDataAttribute('data-action', gaAction)}
           {...generateDataAttribute('data-event', gaEvent)}
           className={className}
           {...params}
           target={openInNewTab ? "_blank" : "_self"}>{
          isChild ? this.props.children : ReactHTMLParser(data.text)
        }
        </a>
      );
    }


  }
}


/**
 * Connects element to context for cms editor
 **/
Link.contextTypes = {
  store: PropTypes.object
};

Link.defaultProps = {
  editable: true, // if this element is in a header, then editable is passed in as false
};

export default Link

/**
 Component BoilerPlate

 {

    "el": "Elements.WebLink",
    "data": {
        "text": "Your text here",
        "link": {
            "linkType": "internal",
            "url": "/shop"
        },
        "userOverrides": {

        }
    },
    "children": [],
    "sort": 900
}

 **/

