import {StyleSheet} from "@convertly/css";
import Typography from '../../elements/style-functions/typography';
import ButtonGlobal from '../../elements/buttons/button-global-styles';
import UserOverrides from '../../elements/style-functions/user-overrides';

const newsletterSignupStyle = (global, data, buttonType) => {

  const userOverrides = new UserOverrides(global, data)

  return StyleSheet.create({
    newsletterWrapper: {
      position: 'relative',
      ".ty": {
        fontSize: '1.1em',
        transition: 'all 750ms ease',
        position: 'absolute',
        top: '0%',
        left: '120vw',
        transform: 'translate( -50% )',
        overflow: "hidden",
        backgroundColor: global.colors.light.color1,
        padding: "10px 25px",
        borderRadius: "10px",
        color: global.colors.dark.color1,
        fontFamily: global.fontFamily.secondaryFont,
        "@media (max-width: 600px)": {
          width: "90"
        },
        ".in": {
          left: "50%"
        }
      }
    },
    newsletterSignupForm: {
      marginTop: '4px',
      position: 'relative',
      transition: 'all 750ms ease',
      left: 0,
      ".flyout": {
        left: '-900px',
        opacity: '0'
      },
    },
    newsletterSignupFormInput: {
      width: '70%',
      maxWidth: '800px',
      padding: '9px',
      margin: '20px 0',
      border: "1px solid white",
      color: global.colors.dark.color2,
      fontSize: '16px',
      background: global.colors.light.white,
      transition: "background 100ms linear",
      ":placeholder": {
        color: global.colors.dark.color3,
      },
      '@media (max-width:991px)': {
        width: '55%'
      },
      '@media (max-width:478px)': {
        width: '95%'
      },
      ".disabled ": {
        backgroundColor: "rgba( 50, 50, 50, .25)"
      },
      ...userOverrides
    },
    newsletterSignupFormSubmit: {
      position: 'relative',
      display: 'inline-block',
      fontSize: '16px',
      background: global.colors.accent.color1,
      padding: '7px 35px',
      border: '3px solid',
      borderColor: global.colors.accent.color1,
      transition: 'all 0.3s linear 0s',
      color: global.colors.light.white,
      textTransform: "uppercase",
      fontFamily: global.fontFamily.secondaryFont,
      fontWeight: "bold",
      "@media(min-width:991px)": {
        ":hover": {

          transition: 'ease 400ms',
          boxShadow: "0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.3)"
        },
      },
      "@media(max-width:991px)": {
        ":focus": {
          boxShadow: "0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.3)"
        }
      },
      '@media (max-width:478px)': {
        width: '95%',
        display: 'block',
        margin: '0 auto'
      },
      ".disabled": {
        ":hover": {
          opacity: '1'
        }
      }
    }
  })
};
export default newsletterSignupStyle;
