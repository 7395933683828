import React from "react";
import { GLOBALS } from "../../../styles/global-default-styles";
import styled from "styled-components";
import { Input } from "ui-components";

const TRANSITION_TIMER = "400ms";

export const Wrapper = styled.div`
  position: relative;
  z-index: 2002;
`;

export const ActiveSite = styled.div`
  color: ${GLOBALS.colorPrimaryDark};
  fill: ${GLOBALS.colorPrimaryDark};
  width: 400px;
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

export const ActiveSiteText = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  & > p {
    user-select: none;
  }
`;

export const SearchBarWrapper = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${GLOBALS.colorSecondaryLightest};
  border-top: 1px solid ${GLOBALS.colorSecondaryLightest};
`;

export const SitesDropDown = styled.div`
  position: absolute;
  width: 400px;
  left: 0;
  top: 32px;
  background: #fff;
  z-index: 2001;
  box-shadow: 0px 2px 4px rgba(30, 30, 30, 0.25);
  border-radius: 0px 0px 6px 6px;
  transition: all ${TRANSITION_TIMER} ease;
  max-height: ${({ open }) => (open ? "600px" : "0")};
  overflow: hidden;
`;

export const Sites = styled.div`
  overflow: auto;
  max-height: 400px;
  background: #fff;
  padding: 10px;
`;

export const Site = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  padding: 10px;
  ${({ active }) => active && `background:${GLOBALS.colorSecondaryLightest}`}
`;

export const Divider = styled.div`
  background: ${GLOBALS.colorSecondaryLightest};
  height: 1px;
  margin: 10px auto;
  width: calc(100% - 20px);
  padding: 0 20px;
`;

export const Thumbnail = styled.img`
  width: 40%;
  height: 100%;
  margin: 0;
  object-fit: cover;
`;

export const SiteDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px 15px;
`;

export const Link = styled.div`
  color: ${GLOBALS.colorPrimary};
  cursor: pointer;
  text-decoration: underline;
`;

export const Bottom = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${GLOBALS.colorSecondaryLightest};
`;

const Icon = styled.svg`
  margin-left: 10px;
  transition: all ${TRANSITION_TIMER} ease;
  ${({ open }) => !open && "transform:rotate(180deg);"}
`;

export const Chevron = ({ open }) => {
  return (
    <Icon
      open={open}
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.40714e-08 5.00005L5 5.39422e-05L10 5.00005L8.82 6.18005L5 2.34005L1.18 6.18005L1.40714e-08 5.00005Z"
        fill="#414141"
      />
    </Icon>
  );
};
