//Sets feedback category and category enum
//Connected to "type of feedback" buttons in first screen
export const setCategory = function (e) {
  //Pull data form attrs
  const {category, categoryType} = e.target.dataset;
  //update the state
  this.setState(
    {
      activeCategoryText: category,
      activeCategory: categoryType
    }
  );
  return false;
};
