import React from 'react';
import Component from 'source/rootComponent';
import { css } from '@convertly/css';
import { id } from '@convertly/thor';
import _ from 'lodash';

// Utils
import { generateLink } from '../../../utils/generateLink';

// Component
import {NavDropdownMenu} from './NavDropdownMenu';

// Styles

// Scripts
import toggleNavDropdown from '../scripts/toggleNavDropdown';
import toggleDropDown from '../../scripts/toggle-dropdown';
import togglePlusMinusSign from '../scripts/togglePlusMinusSign';


export class NavLinkListItem extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const inEditor = this.inEditor();
    const { global, data, navLinkItemSettings, styles, page } = this.props;
    const { text, dropDownNav, link } = navLinkItemSettings;
    
    if (!text) {
      return null;
    }

    const toggleDropdownId = id(toggleDropDown);
    const toggleSignId = id(togglePlusMinusSign);

    const generatedLinkObject = {
      url: _.get(navLinkItemSettings, 'link.url') || '',
      linkType: _.get(navLinkItemSettings, 'link.linkType') || 'internal'
    }
    
    const {
      selectionItem,
      selectionItemLink,
      minusSign,
      plusSign,
      dropdownPlusMinusSign
    } = styles;

    return (

      <div
        className={ css(selectionItem) }>
        {
          dropDownNav &&
            <div
              id={ `dropdown${toggleDropdownId}` }
              className={ css(selectionItemLink) }
              href={ generateLink(generatedLinkObject) }>
              { text.toUpperCase() }
            </div>
        }
        {
          dropDownNav &&
          <div className={ css(dropdownPlusMinusSign) }>
            <div className={ css(minusSign) } />
            <div
              id={ `plusMinusSign${toggleSignId}` }
              className={ css(plusSign) } />  
          </div>
        }
        {
          dropDownNav &&
            <NavDropdownMenu
              global={ global }
              data={ data }
              navDropdownItems={ dropDownNav } 
              selectionItemLinkStyles={ selectionItemLink }
              toggleId={ toggleDropdownId }/> 
        }
        {
          !dropDownNav &&
          <a
            className={ css(selectionItemLink) + `${ page && page.slug === link.url ? ' active' : '' }` }
            href={ generateLink(generatedLinkObject) }>
            { text.toUpperCase() }
          </a>
        }
      </div>
    )
  }
}
