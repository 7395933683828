/*global Inferno */
/* eslint-disable */

import { include } from '@convertly/thor';

const Scripts = () => {
    // Thor import
    const SpinnerComponent = include( '../../svg/spinner/spinner', 'SpinnerComponent' );
    const Graph = include( '../../utils/graph', 'Graph' );
    const Dispatcher = include( '../../utils/dispatcher', 'Dispatcher' );

    return ( id, siteId, events, formId ) => {

        // Reference variables for classNames in render fn
        const root = document.getElementById(id);
        const form = document.getElementById(`${ id }-form`);
        const name = document.getElementById(`${ id }-name`);
        const textArea = document.getElementById(`${ id }-textArea`);
        const email = document.getElementById(`${ id }-email`);
        const submit = document.getElementById(`${ id }-submit`);
        const formHeader = document.getElementById(`${ id }-heading`);
        const wrapper = document.getElementById( `${ id }-wrapper` );

        // Initialize imported functions
        // Inferno components must be Capitalized or they will not render
        // <Spinner /> = OK
        // <spinner /> = BAD
        const Spinner = SpinnerComponent('50px');
        const graph = Graph();
        const dispatch = Dispatcher( id );

        dispatch.attach( events );

        // Set up our Graph query
        const query = `
            mutation($id:ID!, $fields:String!) { form(id: $id, fields: $fields)
                { success, message }
            }`;

        let data = {
            formFields: [
                {
                    type: 'input',
                    name: "first-name",

                }
            ]
        };

        const variables = {
            fields: JSON.stringify( data )
        };


        // System error message
        const errorMessage = 'There was a problem signing you up. Please try again.';

        const ContactUsForm = Inferno.createClass({

            /**
             * Set Component state
             * @returns {{email: string, placeholder: string, submit: string, message: string, processing: boolean, complete: boolean}}
             */
            getInitialState: function () {
                return {
                    email: '',
                    name: '',
                    message: '',
                    namePlaceholder: name.getAttribute('placeholder'),
                    emailPlaceholder: email.getAttribute('placeholder'),
                    textAreaPlaceholder: textArea.getAttribute('placeholder'),
                    submit: submit.getAttribute('value'),
                    confirmationMessage: 'Thanks for signing up!',
                    processing: false,
                    complete: false
                };
            },

            /**
             * Event handler for submit button
             *
             * @param e
             */
            onSubmit: function (e) {

                e.preventDefault();

                this.setState({
                    processing: true
                });

                const variables = {
                    id: formId,
                    fields: JSON.stringify( {
                        email: this.state.email,
                        name: this.state.name,
                        message: this.state.message
                    } )
                };

                graph({query, variables, cb: this.cb});
            },

            /**
             * API callback
             *
             * @param result
             * @param error
             */
            cb: function ( error = null, result ) {
                if ( error ) {
                    this.setState({
                        complete: true,
                        message: errorMessage
                    });
                } else {
                    const {success} = result.form;
                    if (success) {
                        dispatch.trigger( 'submit' );
                        this.setState({
                            complete: true
                        });
                    } else {
                        this.setState({
                            complete: true,
                            message: errorMessage
                        });
                    }
                }

            },

            /**
             * Change handler for input field
             * @param e
             */
            onChangeName: function ( e ) {
                const name = e.target.value;
                this.setState ( {
                    name: name
                } );

            },
            onChangeEmail: function ( e ) {
                const email = e.target.value;
                this.setState({
                    email: email
                });
            },
            onChangeMessage: function ( e ) {
                const message = e.target.value;
                this.setState({
                    message: message
                });
            },

                /**
             * Main render fn
             * @returns {XML}
             */
            render: function () {

                const spinnerStyles = {
                    position: 'absolute',
                    transform: 'translate( -50%, -50% )',
                    top: '56%',
                    left: '50%'
                };

                let disabledInputClass = this.state.processing ? " disabled" : " ";
                let formStateClass = this.state.complete ? " flyout " : "" ;
                let tyTextClass = this.state.complete ? " in" : " ";


                return (
                    <div>
                        <div className={ formStateClass + wrapper.className}>
                            <h2 className={ formHeader.className }>
                                { formHeader.textContent }
                            </h2>
                            <div className={root.className + " divider" }/>
                            <form onSubmit={this.onSubmit} className={ form.className }>
                                <input name="name" required disabled={this.state.processing} onInput={this.onChangeName}
                                       value={this.state.name} className={name.className + disabledInputClass}
                                       type="text" placeholder={this.state.namePlaceholder}/>
                                <input name="email" required disabled={this.state.processing} onInput={this.onChangeEmail}
                                       value={this.state.email} className={email.className + disabledInputClass}
                                       type="email" placeholder={this.state.emailPlaceholder}/>

                                <textarea name="email" required disabled={this.state.processing} onInput={this.onChangeMessage}
                                          value={this.state.message} className={textArea.className + disabledInputClass}
                                          type="email" placeholder={this.state.textAreaPlaceholder}
                                          rows="4"/>
                                <button disabled= {this.state.processing }
                                        className={submit.className + disabledInputClass}>
                                        {this.state.submit}
                                    <div style={spinnerStyles}><Spinner display={ this.state.processing }/></div>
                                </button>
                            </form>
                        </div>

                        { /** Thank you message **/ }
                        <div className={ root.className + " confirmationMessageWrapper" +  tyTextClass}>
                            <p className={root.className + " confirmationMessageHeader" }>Thanks for reaching out!
                                <div  className={root.className + " divider" }/>
                            </p>
                            <p  className={root.className + " confirmationMessage" }>Your information has been sent and someone will reach out to you soon.</p>
                        </div>

                    </div>
                )
            }
        });

        Inferno.render(<ContactUsForm/>, root);

    };

};

export default Scripts;
