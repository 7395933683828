import React from 'react';
import Component from '../../../rootComponent';
import { css } from "@convertly/css";
import Types from '../../../attribute-types';
import ReactHTMLParser from "@convertly/react-html-parser";

//utils
import generateAccessKey from '../../../utils/generateAccessKey';

//Styles
import footerNavItemStyles from './footer-nav-item-styles-1';

//Componets
import Link from '../../../elements/links/web-link'

export default class
FooterNav1 extends Component {

    constructor( props ) {

        super( props );

    }

    render() {

        this.styles = footerNavItemStyles( this.props.global, this.props.data );

        const indexString = this.props.index.toString();

        return(
            <li key={this.props.key} className={ css( this.props.styles.footerNavListItem ) } itemProp="headline">
                <Link { ...this.props }
                      className={ css( this.props.styles.footerNavLink, this.styles.footerNavLink ) }
                      { ...generateAccessKey( "link" + indexString ) }/>
            </li>
        )
    }
}
