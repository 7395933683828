/**
 * extracts image from blog-post and runs client image
 */
import { clientImage } from "../..";

export const parseImages = (content, inEditor = false) => {
    //split up to find all images
    const contentArr = content.split("CUSTOM_IMG~");
    //replace images with client image
    return contentArr
        .map(section => {
            return replaceImage(section, inEditor);
        })
        .join("");
};

//find the image and replaces it with client image
const replaceImage = (content, inEditor) => {
    const startIndex = content.indexOf(".");
    const endIndex = content.indexOf("]");
    let ext;
    let fileName;

    if (startIndex && endIndex) {
        ext = content.slice(startIndex + 1, endIndex);
        fileName = content.slice(1, startIndex);
    }
    //verify text has an image in it and replace it.
    if (imgExtensions.has(ext)) {
        //return replaced content
        return content.replace(`[${fileName}.${ext}]`, clientImage(`${fileName}.${ext}`, { inEditor }));
    }
    //return content
    return content;
};

//img file extensions
let imgExtensions = new Set(["jpg", "jpeg", "png", "gif", "tif", "raw", "psd", "svg", "webp"]);
