import { StyleSheet } from "@convertly/css";
import UserOverrides from "../../elements/style-functions/user-overrides";

export const HorizontalDividerStyles = ({ global, data, inEditor }) => {

  return StyleSheet.create({
    horizontalDivider: {
      width: "100%",
      border: "1px solid",
      "border-color": global.colors.dark.black,
      "background-color": global.colors.dark.black,
      height: "15px",
      ...UserOverrides(global, data, inEditor)
    }
  });
};
