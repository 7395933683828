/*global Inferno, $ */
/* eslint-disable */
import {include} from '@convertly/thor';

const Script = () => {

        return (id, imgPath) => {


            const root = document.getElementById(id);

            const YourComponentName = Inferno.createClass({

                getInitialState: function () {

                    return {
                        close: false,
                    };
                },

                closeModal() {
                    $('.modal-wrapper').addClass('close')
                },


                render: function () {

                    const rootClass = root.className;
                    const {metaData} = this.props;
                    return (
                        <div className={ `${rootClass} modal-wrapper` }>

                            <div className={ `${rootClass} modal` }>
                                {
                                    metaData.img ?
                                        <img className={ `${rootClass} modal-image` }
                                             src={ `${imgPath}${metaData.img.src}` }/>
                                        :
                                        <div className={ `${rootClass} modal-image` }/>
                                }
                                <div className={`${rootClass} close-text`}
                                     onClick={ this.closeModal }>
                                    CLOSE
                                </div>
                                <div className={ `${rootClass} modal-bottom` }>
                                    <div className={ `${rootClass} description-topper` }>
                                        <h3 className={`${rootClass} main-title`}>

                                            { metaData.name && metaData.name.text  }

                                            <div className={`${rootClass} border-bottom`}/>

                                        </h3>
                                        <p className={`${rootClass} price`}>

                                            {metaData.price && `$${metaData.price.toFixed(2)}`}

                                        </p>
                                    </div>
                                    <div className={`${rootClass} description-bottom`}>
                                        <p className={ `${rootClass} description-text` }>

                                            { metaData.description && metaData.description.text }

                                        </p>
                                        {
                                            metaData.tags &&
                                            <div className={ `${rootClass} item-tag` }>
                                                {metaData.tags.map((tag) => {
                                                    return (
                                                        <div className={ `${rootClass} tag` }> { tag } </div>
                                                    )
                                                })
                                                }
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    )
                }

            });

            /** Loads modal with data on click **/
            $('.menu-item').on('click', function () {
                let metadata = JSON.parse(this.dataset.metadata);

                $('.modal-wrapper').removeClass('close');
                Inferno.render(<YourComponentName metaData={ metadata }/>, root);
            })


        };

    }
;

export default Script;
