import {buildProductPricingObject} from "../../../utils/ecomm-utils/build-product-pricing-object";
import { prepStringForInferno } from '../../../utils/prep-string-for-inferno';

export const getProducts = ( products, hasVariants ) => {
	
	let filteredProducts = [];
	
	products.map((product) => {
		
		product.pricing = buildProductPricingObject(product);
		
		
		if (product.variationTypeMeta) {
			hasVariants = true;
			product.variationTypeMeta = JSON.parse(product.variationTypeMeta);
		}
		
		product.description = prepStringForInferno( product.description );
		
		filteredProducts.push(product);
	});
	return {
		filteredProducts,
		hasVariants
	}
	
};