import React from "react";
import Component from "source/rootComponent";
import { css } from "@convertly/css";

// Style
import { HorizontalDividerStyles } from "./HorizontalDividerStyles";

// Util
import renderChildren from "../util/renderChildren";
import generateId from "../../utils/generate-id";

export class HorizontalDivider extends Component {
  render() {
    const { data, inEditor, global } = this.props;

    this.styles = HorizontalDividerStyles({ global, data, inEditor });

    const { horizontalDivider } = this.styles;
    const inlineStyle = this.generateStyleAttribute();

    return (
      <div
        {...inlineStyle}
        {...generateId(this.props.elementId)}
        {...this.generateDataEditorAttribute()}
        {...this.generateCustomAttributes()}
        className={css(horizontalDivider)}
      >
        {renderChildren(this.props, {
          inEditor
        })}
      </div>
    );
  }
}
