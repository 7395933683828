import { createAction } from "redux-act";
import { getBlogPanelState } from "../actions";

export const setCategory = createAction("BLOGS PANEL: Set active category object");
export const setCategorySnapshot = createAction("BLOGS PANEL: Set active category snapshot");

export const updateCategory = (field, value) => async dispatch => {
  const category = getBlogPanelState(store.getState()).get("category");
  await dispatch(setCategory(category.set(field, value)));
};
