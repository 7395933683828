import { StyleSheet } from "@convertly/css";
import { clientImage } from '../../utils/generateImage';

let inEditor = false;

export function isInEditor( flag ) {

    inEditor = flag;

}


const ModuleGlobal = ( global, element ) => {

    const moduleGlobal = {};

    if(element.moduleTextColor) {
        moduleGlobal.color = element.moduleTextColor;
    }
    //Background Handler
    if(element.backgroundColor) {
        moduleGlobal.backgroundColor = element.backgroundColor;
    }
    if(element.backgroundImage) {
        moduleGlobal.backgroundImage = "url(" + clientImage( element.backgroundImage ) + ")";
        if(!element.backgroundRepeat) {
            moduleGlobal.backgroundRepeat = 'no-repeat';
        }
        else if(element.backgroundRepeat) {
            moduleGlobal.backgroundRepeat = 'repeat';
        }
        if(element.backgroundCover) {
            moduleGlobal.backgroundSize = "cover";
        }
        else if(!element.backgroundCover) {
            moduleGlobal.backgroundSize = "auto";
        }
        if(element.backgroundContain) {
            moduleGlobal.backgroundContain = "contain"
        }
    }

    //Divider Handler

    if( element.divider === "solidNarrow" ) {
        moduleGlobal.borderBottom = "1px solid";
        moduleGlobal.borderColor = global.colors.dark.color1
    }
    if( element.divider === "solidThick" ) {
        moduleGlobal.borderBottom = "3px solid";
        moduleGlobal.borderColor = global.colors.dark.color1;
    }
    if( element.divider === "shadowLight" ) {
        moduleGlobal.boxShadow = '0px 1px 15px 0px rgba(22,22,22,0.48)';
    }
    if( element.divider === "shadowBold" ) {
        moduleGlobal.boxShadow = '0px 10px 15px 0px rgba(22,22,22,0.48)';
    }

    //Text Align

    if( element.textAlign ) {
        moduleGlobal.textAlign = element.textAlign
    }

    if( inEditor ) {
        moduleGlobal.transition = "all .2s linear"
    }

    return moduleGlobal;

};
export default ModuleGlobal;
