const blogPostFields = `date:publishedAt
          author {
            id
            name
            shortBio:bio
            avatar {
              src
              alt
            }
            url
          }
          slug
          shortDescription
          categories {
            name
            url
            id
          }
          postTitle
          previewVideo {
            placeHolderImage {
              src
              alt
              src
            }
          }
          previewImage:thumbnailImage {
            src
              alt
          }
          featureImage {
            src
              alt
          }`;

export const generateGraphQuery = (disablePagination, inEditor) => {


    return inEditor ? `query($limit:Int) {
        BlogPosts:DummyBlogData(limit:$limit) {
          ${blogPostFields}
        }
      }` : `query getPostsAndCount($blogQueryInput: blogQueryInput) {
        BlogPosts( blogQueryInput: $blogQueryInput ) {
          ${blogPostFields}
        }
            
      }`;


};

export const singleItemQuery = `query getSingleBlogPost( $siteId:ID $id:String!) {
                      BlogPost(id:$id siteId:$siteId) {
                        ${blogPostFields}
                        content:postContent
                        draftContent
                      }
                    }`;
