import React from 'react';
import { TextWrapper } from '../root-components';
import { ConfirmationWrapper } from './components';

/**
 * Stage 3, the final screen, lets user know their message was successfully sent
 */
export const Confirmation = ({name, email}) => {

  return (
      <ConfirmationWrapper>
        <TextWrapper>
          {/** name gotten from app.js passed down **/}
          <p>Thanks {name}! Your message has been sent!</p>
        </TextWrapper>
        {/** Envelope icon **/}
        <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0)">
            <path d="M45 73.324C42.1137 73.324 39.2273 72.4345 36.7629 70.6539L0 44.0968V81.5629C0 86.2228 3.77754 90.0004 8.4375 90.0004H81.5625C86.2225 90.0004 90 86.2228 90 81.5629V44.0968L53.2371 70.6539C50.7727 72.4328 47.8863 73.324 45 73.324ZM86.7674 28.6439C85.2117 27.424 83.7369 26.2761 81.5625 24.6343V16.8754C81.5625 12.2154 77.785 8.43786 73.125 8.43786H59.4932C58.9588 8.05114 58.4613 7.68903 57.9041 7.28473C54.9492 5.1279 49.0781 -0.0611642 45 0.000359245C40.9219 -0.0611642 35.0525 5.1279 32.0959 7.28473C31.5387 7.68903 31.0412 8.05114 30.5068 8.43786H16.875C12.215 8.43786 8.4375 12.2154 8.4375 16.8754V24.6343C6.26309 26.2744 4.78828 27.424 3.23262 28.6439C2.22567 29.433 1.41137 30.4409 0.851333 31.5911C0.291292 32.7413 0.000173286 34.0038 0 35.2832L0 37.1552L16.875 49.3457V16.8754H73.125V49.3457L90 37.1552V35.2832C90 32.6922 88.8082 30.2435 86.7674 28.6439Z" fill="#414141"/>
            <path d="M41.85 50.5519C41.6833 49.3415 41.7167 48.232 41.95 47.2233C42.2167 46.2147 42.5833 45.2901 43.05 44.4496C43.55 43.609 44.1 42.8357 44.7 42.1297C45.3333 41.39 45.9167 40.684 46.45 40.0115C47.0167 39.3055 47.4833 38.6162 47.85 37.9438C48.2167 37.2714 48.4 36.5485 48.4 35.7752C48.4 34.5648 48.0333 33.5898 47.3 32.8501C46.6 32.1105 45.6167 31.7406 44.35 31.7406C43.3167 31.7406 42.3833 31.976 41.55 32.4467C40.7167 32.9174 39.9333 33.5562 39.2 34.3631L36 31.3876C37.1 30.11 38.4 29.0677 39.9 28.2608C41.4333 27.4203 43.15 27 45.05 27C46.35 27 47.55 27.1849 48.65 27.5548C49.75 27.891 50.6833 28.4121 51.45 29.1182C52.25 29.8242 52.8667 30.6984 53.3 31.7406C53.7667 32.7829 54 33.9933 54 35.3718C54 36.4476 53.8 37.4227 53.4 38.2968C53.0333 39.1374 52.5667 39.9443 52 40.7176C51.4333 41.4909 50.8167 42.2305 50.15 42.9366C49.5167 43.6427 48.9333 44.3823 48.4 45.1556C47.9 45.9289 47.4833 46.7526 47.15 47.6268C46.85 48.501 46.7667 49.476 46.9 50.5519H41.85ZM44.4 62C43.3333 62 42.45 61.6302 41.75 60.8905C41.05 60.1508 40.7 59.2094 40.7 58.0663C40.7 56.9232 41.05 55.9817 41.75 55.2421C42.45 54.5024 43.3333 54.1326 44.4 54.1326C45.4667 54.1326 46.35 54.5024 47.05 55.2421C47.7833 55.9817 48.15 56.9232 48.15 58.0663C48.15 59.2094 47.7833 60.1508 47.05 60.8905C46.35 61.6302 45.4667 62 44.4 62Z" fill="#414141"/>
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="90" height="90" fill="white"/>
            </clipPath>
          </defs>
        </svg>

        <p>We’ll get back to you as soon as possible at the email address of {email}.</p>
      </ConfirmationWrapper>
  )

};
