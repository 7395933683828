import React from 'react';
import { css } from "@convertly/css";
import { id } from "@convertly/thor";
import Component from '../../rootComponent';
//Default Props
import defaultGlobal from '../../themes/global';

//Styles
import Styles from './scroll-to-top-styles.js';

//Scripts
import ScrollToTopScripts from './scroll-to-top-script.js';

//Components
import SVG from '../../svg/index';


export default class ScrollToTop extends Component {


    constructor(props) {

        super(props);

    };
    render() {
        this.styles = Styles(this.props.global, this.props.data);

        if (this.props.editor) {
            return this.editor();
        }

        return (

            <div id={ id( ScrollToTopScripts ) } className={ css( this.styles.scrollToTop ) + " closed" }>
                <SVG.ArrowHeadOpenUp className={ this.styles.cheveron } />
            </div>

        )
    }
}

ScrollToTop.defaultProps = {
    global: defaultGlobal
};
