import PropTypes from 'prop-types';
import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { css } from "@convertly/css";
import styles from "../../Editor/components/editor/editor-forms-styles";
import TabStyled from "../../Editor/components/editor/css-Tab-Styles";
import AddPagePlaceHolder from "./add-new-page-placeholder";
import Toggle from "../../Editor/components/editor/toggle";
import ModalWrapper from "./ModalWrapper";
import { keyframes } from "styled-components";
import Icon from "../../../components/icons/add-page-thumbnail";

import {
  createPage,
  loadPageTemplates,
  setOverlayStyles,
  closeModal
} from "../../Editor/actions/editorActions";

const closeAnimation = keyframes`
  from {
    opacity: 1;
    transform: translate(-50%, 0%)  scale(1);
  }

  to {
    opacity: 0;
    transform: translate(-50%, 0%) scale(0.95);
  }
`;

export default class AddNewPageModal extends Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      selectedImage: null,
      hoveredIndex: null,
      step: 2,
      pageName: "",
      title: "Create New Page",
      transition: {}
    };

    this.handleInput = this.handleInput.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
    this.createPage = this.createPage.bind(this);
  }

  /**
   * Load available page templates
   */
  componentDidMount() {
    this.context.store.dispatch(loadPageTemplates());
  }

  /**
   * Move form to Step 2
   */
  handleNextStep() {
    this.setState({
      step: 2,
      title: "Create New Page"
    });
  }

  /**
   * Close button event handler
   */
  closeModal() {
    this.setState({ transition: { animation: `${closeAnimation} 250ms` } });
    setTimeout(() => {
      this.context.store.dispatch(closeModal());
      this.context.store.dispatch(setOverlayStyles(null));
    }, 250);
  }

  /**
   * Keep state and value of page name input in sync
   * @param e
   */
  handleInput(e) {
    this.setState({ pageName: e.target.value });
  }

  /**
   * Create Page event handler
   */
  createPage() {
    if (!this.state.pageName) {
      return;
    }

    const payload = {
      name: this.state.pageName,
      siteId: this.props.currentSite.siteId
    };

    this.closeModal();
    this.context.store.dispatch(createPage(payload));
  }

  /**
   * Render View for Step 1
   */
  renderStep1() {
    const { pageTemplates } = this.props;

    return (
      <div>
        <TabStyled>
          <Tabs>
            <TabList>
              {pageTemplates.map((tabList, index) => {
                return React.createElement(Tab, { key: index }, tabList.optionName);
              })}
            </TabList>

            {pageTemplates.map((tabList, index) => {
              return React.createElement(TabPanel, { key: index }, <AddPagePlaceHolder />);
            })}
          </Tabs>
        </TabStyled>

        <div>
          <div className={css(styles.galleryCollectionButton)} onClick={this.closeModal}>
            Cancel
          </div>

          <div
            className={css(styles.galleryCollectionButton, styles.galleryCollectionButtonGreen)}
            onClick={this.handleNextStep}
          >
            Next
          </div>
        </div>
      </div>
    );
  }

  /**
   * Render View for Step 2
   */
  renderStep2() {
    const style = this.state.pageName
      ? {
          cursor: "pointer"
        }
      : {
          opacity: ".5",
          cursor: "default"
        };

    return (
      <div>
        <div className={css(styles.modalCollectionContainer)}>
          <div className={css(styles.newPageCollectionContainer)}>
            <div className={css(styles.newPageNameContainer)}>
              <p style={{ fontWeight: "bold" }}>Page Name</p>
              <p style={{ fontSize: "12px" }}>
                The name for this page, as it appears in navigations. Renaming this page does not
                change the URL. To change it, go to SEO settings.
              </p>
              <input
                value={this.state.pageName}
                onChange={this.handleInput}
                placeholder={"Enter New Page Name"}
                className="form-control"
              />
              {false && (
                <div style={{ marginTop: "10px" }}>
                  <p style={{ fontWeight: "bold" }}>Show in Header Navigation</p>
                  <Toggle
                    callback={() => {
                      //alert ( 'toggle' )
                    }}
                  />
                </div>
              )}
            </div>

            <div
              className={css(styles.newPageNameContainer, styles.newPageNameContainerRightPanel)}
            >
              <div className={css(styles.newPageCollectionItem)}>
                <div>
                  <Icon />
                </div>
                <div className={css(styles.newPageName)}>Blank</div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className={css(styles.galleryCollectionButton)} onClick={this.closeModal}>
            Cancel
          </div>

          <div
            style={style}
            className={css(styles.galleryCollectionButton, styles.galleryCollectionButtonGreen)}
            onClick={this.createPage}
          >
            Create Page
          </div>
        </div>
      </div>
    );
  }

  /**
   * Helper function to render form for current state
   *
   * @param step
   * @returns {*}
   */
  renderForm(step) {
    switch (step) {
      case 1:
        return this.renderStep1();
        break;

      case 2:
        return this.renderStep2();
        break;

      default:
        return null;
    }
  }

  /**
   * Main Render Fn
   */
  render() {
    return (
      <ModalWrapper
        transition={this.state.transition}
        {...this.props}
        title={this.state.title}
        width={840}
      >
        {this.renderForm(this.state.step)}
      </ModalWrapper>
    );
  }
}

AddNewPageModal.contextTypes = {
  store: PropTypes.object
};
