import { fromJS } from "immutable";
import { createReducer } from "redux-act";

import { setClipboardVisible, setViewPortVisible, setClipboardItems } from "./actions";

const initialState = fromJS({
  clipboardVisible: false,
  viewPortVisible: false,
  clipboardItems: [],
});

const editorOptionsReducer = createReducer(
  {
    [setClipboardVisible]: (state, clipboardVisible) => state.merge({ clipboardVisible }),
    [setViewPortVisible]: (state, viewPortVisible) => state.merge({ viewPortVisible }),
    [setClipboardItems]: (state, clipboardItems) => state.merge({ clipboardItems }),
  },
  initialState
);

export { editorOptionsReducer };
