export default function StyleObject(global, data) {
	
	return {
		".color-picker-wrapper": {
			height:'40px',
			overflowX: "auto",
			overflowY: 'hidden',
			border: '1px solid black',
			backgroundColor: 'transparent',
			margin:'0 auto',
			"-webkitBackgroundClip": 'text',
			transition: "all .8s",
			borderColor: 'transparent',
			"::-webkit-scrollbar": {
				height: '8px'
			},
			"::-webkit-scrollbar-thumb": {
				backgroundColor: 'inherit',
				borderRadius: '25px',
				borderWidth: '1px',
				borderStyle: 'solid',
				borderColor: `inherit`,
			},
			":hover": {
				backgroundColor: `#3337`,
				borderColor: '#9997',
			},
		},
		".scrollable-wrapper": {
			width: "max-content",
			margin:'0 auto',
			padding:'5px'
		},
		".color-picker-container": {
			height: '20px',
			width: '20px',
			borderRadius: '100%',
			display: 'inline-block',
			border: `4px solid #eef5fe`,
			cursor: 'pointer',
			marginRight: '5px',
			clear: 'both',
			transition: 'all',
			".active": {
				boxShadow: '0 0 0 3px #667785'
			},
			":hover": {
				boxShadow: '0 0 0 3px #667785'
			}
		},
		".color-picker-value": {
			height: '100%',
			width: '100%',
			borderRadius: '100%'
		},
		".color-picker-disabled": {
			opacity: "0.34",
			cursor: "not-allowed",
			":hover": {
				border: `3px solid transparent`,
			}
		},
		
	}
}