/*global Inferno, $ */
import React from 'react'
const Script = (id, dispatch, PageJumpBySelection, PageJumpSingle) => {
  return (props) => {

    const { rootClass, pages, currentPage, footer, toggleDropDown } = props;

    const changePageNum = function (pageNum) {
      dispatch.update(`${id}-active-page`, pageNum);
    };

    return (
      <div className={ `${ rootClass } page-jump-container` }>
        <PageJumpBySelection rootClass={ rootClass }
                             changePageNum={changePageNum}
                             currentPage={ currentPage }
                             pages={ pages }
                             footer={ footer }
                             toggleDropDown={ toggleDropDown } />
        <PageJumpSingle  rootClass={ rootClass }
                         changePageNum={changePageNum}
                         currentPage={ currentPage }
                         pages={ pages }
         />
      </div>
    )
  }
}

export default Script;
