import { StyleSheet } from "@convertly/css";
import userOverrides from "../../elements/style-functions/user-overrides";
import removeNestedUserOverrides from "../../site-header/site-header/utils/removeNestedUserOverrides";

export const BlogLandingStyles = ({ global, data, inEditor }) => {
  const blogLanding = removeNestedUserOverrides({ data });

  return StyleSheet.create({
    blogLanding: {
      ...userOverrides(global, { userOverrides: blogLanding }, inEditor)
    }
  });
};
