import { clientImage } from "./utils/generateImage";

const regeneratorRuntime = require("regenerator-runtime");
import React from "react";
import PropTypes from "prop-types";
import { deeplog } from "./utils/deeplog";
import _ from "lodash";

const store = {};

export default class rootComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hover: false,
      elementEditableBorder: "",
      inEditor: this.props.inEditor,
      isDragging: false,
      editorIsDragging: false,
      zoomFactor: 1,
      disableImageLazyLoad: true, //this.props.inEditor || _.get(this.props, 'page.isCreative') || _.get(this.props, 'site.disableLazyLoad') || _.get(this.props, 'page.disableLazyLoad')
      isTemplatePreview: _.get(this.props, "site.isTemplatePreview"),
    };
    this.launchElementalEditor = this.launchElementalEditor.bind(this);
    this.removeEditableOutline = this.removeEditableOutline.bind(this);
    this.addEditableOutline = this.addEditableOutline.bind(this);
    this.cancelClick = this.cancelClick.bind(this);
    this._isLocked = this._isLocked.bind(this);
    this.handleLayerHighlightEvent = this.handleLayerHighlightEvent.bind(this);
    this.handleEditorIsDraggingElement = this.handleEditorIsDraggingElement.bind(this);
    this.updateZoomFactor = this.updateZoomFactor.bind(this);
    this.getParentState = this.getParentState.bind(this);
    this.deeplog = deeplog.bind(this);
    this.inEditor = this.inEditor.bind(this);
    this.subs = [];
    this.store = store;
  }

  isTemplatePreview() {
    return _.get(this.props, "site.isTemplatePreview");
  }

  componentWillMount() {
    /**
     * Checks to see if we are in the cms and updates inEditor
     **/
    if (this.context.hasOwnProperty("store")) {
      if (this.context.store) {
        if (!this.props.notEditable) {
          this.setState({ inEditor: true });
        }
      }
    }

    if (this.state.inEditor) {
      const { Emitter } = require("services/Emitter");

      this.subs.push(Emitter.on(`layerHighlight`, this.handleLayerHighlightEvent));
      this.subs.push(
        Emitter.listen(`editor_element_is_dragging`, this.handleEditorIsDraggingElement)
      );
      this.subs.push(Emitter.listen(`zooming`, this.updateZoomFactor));
    }
  }

  getParentState(key) {
    return this.state[key];
  }

  componentWillUnmount() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  handleEditorIsDraggingElement(id) {
    this.setState({
      isDragging: id === this.props.id,
      editorIsDragging: id !== null,
    });
  }

  updateZoomFactor(zoomFactor) {
    this.setState({ zoomFactor: zoomFactor / 100 });
  }

  handleLayerHighlightEvent({ id, on }) {
    if (this.props.id !== id) {
      return;
    }
    const border = on ? `${1 / this.state.zoomFactor}px dashed #F5A623` : "";
    this.setState({
      elementEditableBorder: border,
    });
  }

  _isLocked() {
    return !!this.props.data.locked;
  }

  /** STORE **/
  updateStoreItem = (key, newValue) => {
    const { siteId, STORE_KEY } = this.props.site;
    if (!store[STORE_KEY]) {
      this.buildLocalStore();
    }
    //updates store item as new value or by calling cb.
    store[STORE_KEY][siteId][key] = typeof newValue === "funciton" ? newValue() : newValue;
  };

  buildLocalStore() {
    Object.defineProperties(store, {
      [this.props.site.STORE_KEY]: {
        value: { [this.props.site.siteId]: {} },
        writable: true,
      },
    });
  }

  getStoreValue(key) {
    const { siteId, STORE_KEY } = this.props.site;
    return _.get(store, `${STORE_KEY}.${siteId}.${key}`);
  }

  cancelClick(e) {
    e.preventDefault();
  }

  inEditor() {
    return this.state.inEditor;
  }

  /** Outline for Editibale Outline */
  addEditableOutline(e) {
    return;
    if (this._isLocked() || this.state.editorIsDragging) {
      return;
    }

    if (!e.hoverOverChild) {
      if (this.state.elementEditableBorder === "2px dashed #F5A623") {
        this.setState({
          elementEditableBorder: `${2 / this.state.zoomFactor}px dashed #F5A623`,
        });
      } else {
        this.setState({
          elementEditableBorder: `${2 / this.state.zoomFactor}px dashed #F5A623`,
        });
        e.hoverOverChild = true;
      }
    }
  }

  removeEditableOutline(e) {
    return;
    if (this._isLocked() || this.state.editorIsDragging) {
      return;
    }

    if (this.state.inEditor) {
      if (
        !this.context.store
          .getState()
          .get("editor")
          .editorReducer.get("activeModuleId")
      ) {
        this.setState({
          elementEditableBorder: "",
        });
        e.hoverOverChild = false;
      } else if (
        this.context.store
          .getState()
          .get("editor")
          .editorReducer.get("editorType")
          .get("field") !== this.props.id
      ) {
        this.setState({
          elementEditableBorder: "",
        });
        e.hoverOverChild = false;
      }
    }
  }

  /** Returns a style attribute if there is value assigned to it */
  generateStyleAttribute() {
    this.animatedClassName = "";

    let inlineStyles = {
      style: {},
    };

    if (!this.props.notEditable && this.state.inEditor && this.props.id) {
      inlineStyles.style.outline = this.state.inEditor ? this.state.elementEditableBorder : "";
    }

    if (this.props.data.animated && !this.props.inEditor && !this.isTemplatePreview()) {
      this.animatedClassName = " animated ";
      inlineStyles.style.visibility = "hidden";
    }

    if (this.state.inEditor) {
      delete inlineStyles.style.visibility;
    }

    return inlineStyles;
  }

  /**
   * Returns the data-editor attribute
   * if rendered within the Editor
   *
   * @returns {*}
   */
  generateDataEditorAttribute() {
    const attr = {};

    if (this.state.inEditor && this.props.id) {
      attr["data-editor"] = "c" + this.props.id;
    }

    return attr;
  }

  generateCustomAttributes = () => {
    let attrs = _.get(this.props, "data.customAttributes") || {};
    let bgImg = _.get(this.props, "data.userOverrides.backgroundImage");
    let bgImgIsObj = typeof bgImg === "object";

    if (bgImg) {
      attrs["data-bg-img"] = true;
    }
    //handles defer img attributes;
    if (bgImg && !this.state.disableImageLazyLoad) {
      attrs["data-bg-img"] = true;
      attrs["data-lazy-img"] = clientImage(
        bgImgIsObj ? bgImg.url : bgImg,
        bgImgIsObj ? bgImg.params : {}
      );

      attrs["data-lazy-webp-img"] = clientImage(bgImgIsObj ? bgImg.url : bgImg, {
        ...(bgImgIsObj ? bgImg.params : {}),
        format: "webp",
      });
    }

    return attrs;
  };

  //check settings for defer image and will assign the attributes
  deferImage = () => {
    //return empty object if lazy load is disabled
    if (this.state.disableImageLazyLoad) return {};

    const attr = {};
    const src = _.get(this.props, "data.src");
    const params = _.get(this.props, "data.imgParams");
    return {
      "data-lazy-img": clientImage(src, params),
      "data-lazy-webp-img": clientImage(src, { ...params, format: "webp" }),
    };
  };

  /**
   * Launches Elemental Editor
   * @param  {[string]} type type of editor
   * @param  {[event]} e
   */
  launchElementalEditor(type, e) {
    return;
    if (e) {
      e.preventDefault();
    }

    if (this._isLocked()) {
      return;
    }

    if (this.state.inEditor) {
      if (!this.props.notEditable) {
        //prevent click from reaching parent bootstrap-containers
        e.stopPropagation();
        //Sends data needed to launch editor
        this.context.store.dispatch(
          this.context.store.popEditor({ type: type, field: this.props.id })
        );
        this.context.store.dispatch(
          this.context.store.setModules(this.props.moduleId || this.props.id)
        );
        this.context.store.dispatch(this.context.store.setActiveComponent(this.props.moduleSort));
      }
    }
  }
}

rootComponent.contextTypes = {
  store: PropTypes.object,
};
