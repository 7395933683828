import React from 'react';
import _ from 'lodash';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import Types from '../../../attribute-types';
import {id}  from '@convertly/thor';
import ReactHTMLParser from "@convertly/react-html-parser";


//utils
import {generateLink} from '../../../utils/generateLink';
import {clientImage} from '../../../utils/generateImage'


//Default Props
import defaultData from '../common/header-nav-default-data';
import defaultGlobal from '../../../themes/global';

//Scripts
import searchBarToggle from '../scripts/search-bar-toggle';
import toggleChildrenOpen from '../scripts/hidden-nav-toggle';
import cartHandler from '../scripts/cart-script';
import isSignedIn from '../scripts/is-signed-in';

//Components
import HeaderNavList2 from '../common/header-nav-list/header-nav-list-2';
import SocialIcons1 from '../../../widgets/social-icons/no-background-social-icons/social-icon-list-1';

//Styles
import headerNavStyles from './header-nav-styles-3';
import {generateId} from "../../../utils/generate-attribute";

export default class HeaderNav3 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showHeaderMenu: false,
            toggledClass: ''
        };

        this.toggleHeaderMenu = this.toggleHeaderMenu.bind(this);

    }

    toggleHeaderMenu() {
        this.setState((prevState) => {
            return prevState.showHeaderMenu = !prevState.showHeaderMenu;
        });

        this.setState((prevState) => {
            return this.state.showHeaderMenu ? prevState.toggledClass = ' open' : prevState.toggledClass = '';
        });
    }


    render() {
        const inEditor = this.state.inEditor;
        const {siteId} = this.props.site;
        const { data } = this.props;

        this.styles = headerNavStyles(this.props.global, data, inEditor);

        const clientImgSrc = clientImage("", {width: '60', height: '60', crop: 'fit'}, siteId);
        const clientLinkUrl = generateLink({linkType: "inferno", url: ""});

        const slug = _.get(this.props, 'page.slug');
        const ecommEnabled = _.get(this.props, 'site.ecommerce.enabled');

        /** hides cart on cart page **/
        let cart = null;


        if (slug !== "/cart" && ecommEnabled) {

            cart = <div className={ css(this.styles.headerNavBox) }>

                <div id={ id(cartHandler, clientLinkUrl, clientImgSrc, false, slug) }
                     style={ {fontWeight: 'bold'} }
                     href={ generateLink({linkType: 'internal', url: '/cart'}) }
                     className={ css(this.styles.desktopCartInfernoComponent) }>Cart
                </div>
            </div>
        }

        return (
            <div className={`container-fluid header-nav-3 ${css(this.styles.fitHeight)}`}>
                <div className={ `row ${css(this.styles.fitHeight)}`}>
                    <div className={ "col-xs-9 col-sm-7 col-md-6 col-lg-5" + " " + css( this.styles.hidePadding, this.styles.fitHeight ) }>
                        { data.showNavigation &&
                        <div className={ css(this.styles.menu) + this.state.toggledClass } id="menuIcon">
                            {/** Menu Launcher **/}

                                <button id={ id(toggleChildrenOpen) }
                                        onClick={ this.toggleHeaderMenu }
                                        data-target="#sideMenu"
                                        type="button"
                                        className={ css(this.styles.navLaunch) }>
                                    <span className="sr-only">Toggle navigation</span>
                                    <span
                                        className={ css(this.styles.iconBar) + this.state.toggledClass }
                                        data-target="#sideMenu">
                                            </span>
                                    <span
                                        className={ css(this.styles.iconBar) + this.state.toggledClass}
                                        data-target="#sideMenu">
                                            </span>
                                    <span
                                        className={ css(this.styles.iconBar) + this.state.toggledClass }
                                        data-target="#sideMenu">
                                            </span>
                                </button>

                        </div>
                        }
                        { /** Slide out menu **/ }
                        <div className={ css(this.styles.overlay) }
                             data-target="#sideMenu"
                             id="header03Overlay"/>

                        <div id="sideMenu"
                             className={ css(this.styles.popOutMenu) + this.state.toggledClass }>
                            { /** Maps through nav items **/ }
                            <HeaderNavList2
                                styles={ this.styles }
                                page={ this.props.page }
                                global={ this.props.global }
                                data={ data.menu }
                                clientLinkUrl={ clientLinkUrl }
                                clientImgSrc={ clientImgSrc }/>
                        </div>
                        { data.showLogo &&
                        <div className={ css(this.styles.logo) } id="logo">
                            {  data.logoLinkToHomePage ?
                                <a href={ generateLink({linkType: 'internal', "url": '/'}) }>
                                    <picture>
                                        { /** WEBP **/}
                                        <source srcSet={ clientImage(this.props.site.logo.src, {format:"webp" } ) } type="image/webp"/>
                                        { /** normal **/}
                                        <source srcSet={ clientImage(this.props.site.logo.src, data.params ) } type="image/jpeg"/>
                                    <img src={ clientImage(this.props.site.logo.src, {}) }
                                         alt={ this.props.site.alt }
                                         title={ this.props.site.title }
                                         className={ css(this.styles.logoImg) }
                                    />
                                    </picture>
                                </a>
                                :
                                <picture>
                                    { /** WEBP **/}
                                    <source srcSet={ clientImage(this.props.site.logo.src, {format:"webp" } ) } type="image/webp"/>
                                    { /** normal **/}
                                    <source srcSet={ clientImage(this.props.site.logo.src, data.params ) } type="image/jpeg"/>
                                    <img src={ clientImage(this.props.site.logo.src, {}) }
                                         alt={ this.props.site.alt }
                                         title={ this.props.site.title }
                                         className={ css(this.styles.logoImg) }
                                    />
                                </picture>

                            }
                        </div>
                        }
                        <div className="clearfix"/>
                    </div>
                    { ecommEnabled &&
                    <div className={ "col-xs-3 col-sm-5 col-md-6 col-lg-7" + " " + css( this.styles.hidePadding) + " " + css(this.styles.fitHeight)} >
                        <div className={ css(this.styles.rightWrapper, this.styles.fitHeight) }>
                            <div className={ css(this.styles.socialIcons) }>
                                {/*<SocialIcons1 global={ this.props.global }
                                              styles={ this.styles }
                                              data={ data }/>*/}
                                <div className="clearfix"/>
                            </div>
                            {/* Hiding search until it is set up
                             <div className={ css(this.styles.headerNavBox) }
                             id={ id(searchBarToggle) }
                             data-target="#searchform">
                             <form id="searchform" className={ css(this.styles.headerSearchForm) }>
                             <input type="text"
                             className={ css(this.styles.headerSearchBar) }
                             data-target="#searchform"
                             placeholder="Search"/>
                             <input type="submit"
                             className={ css(this.styles.headerSearchBarButton) }
                             id="searchsubmit"/>
                             <div id="closeButon" className={ css(this.styles.closeSearch) }>
                             <img id="closeButton" src="https://d393hjtdh3b27x.cloudfront.net/convertly/img/close_btn.png" alt="" data-target="#searchform"/>
                             </div>
                             <span
                             className={ css(this.styles.headerNavLink) }>{ ReactHTMLParser("<strong>Search</strong>") }</span>
                             </form>
                             </div>
                             */}

                            { /** Cart Inferno Component **/ }

                            { inEditor ?
                                <div className={ css(this.styles.headerNavBox) }>

                                    <div className={ css(this.styles.headerNavLink) + " cart" }>Cart</div>
                                </div> : cart }

                            { inEditor ?
                                <div className={ css(this.styles.headerNavBox) }>
                                    <div className={ css(this.styles.headerNavLink) }>Sign In</div>
                                </div>
                                :
                                <div className={ css(this.styles.headerNavBox) }>
                                    <div
                                        id={ id(isSignedIn, clientLinkUrl) }
                                        href={ generateLink({linkType: 'internal', url: '/account'}) }
                                        className={ css(this.styles.desktopSignInInfernoComponent) }>
                                        Sign In
                                    </div>
                                </div> }


                            <div className="clearfix"/>
                        </div>
                        <div className="clearfix"/>
                    </div>
                    }
                    <div className="clearfix"/>

                </div>
            </div>

        )
    }
}

HeaderNav3.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
