import React from "react";
import PropTypes from "prop-types";
import { fromJS } from "immutable";
import { clientImage } from "source/utils/generateImage";

import { Slider } from "containers/Editor/components/editor/components/slider/Slider";

import {
  ImageEditingPanel,
  SliderContainer,
  ImageContainer,
  ImgResponsive
} from "./css-image-editor";

const DEFAULT_VALUE = 0;

export class SaturationPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.params.get("saturation") || 0
    };

    this.handleToolSelect = this.handleToolSelect.bind(this);
    this.update = this.update.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.trigger !== this.props.trigger) {
      this.update(DEFAULT_VALUE);
    }
  }

  handleToolSelect(index) {
    this.setState({ selectedIndex: index });
  }

  update(value) {
    this.setState({
      value
    });
    this.props.update("saturation", value);
  }

  render() {
    const { image, params, siteId } = this.props;
    const { value } = this.state;

    const min = -1;
    const max = 1;

    return (
      <ImageEditingPanel>
        <ImageContainer>
          <ImgResponsive src={clientImage(image, params.delete("width").toJS(), siteId)} />
        </ImageContainer>
        <SliderContainer>
          <div>
            <Slider update={this.update} value={value} min={min} max={max} />
          </div>
        </SliderContainer>
      </ImageEditingPanel>
    );
  }
}

SaturationPanel.defaultProps = {
  image: "",
  params: fromJS({}),
  siteId: "0",
  trigger: 0
};

SaturationPanel.propTypes = {
  image: PropTypes.string,
  params: PropTypes.object,
  siteId: PropTypes.string,
  trigger: PropTypes.number
};
