import React from 'react';
import Component from '../rootComponent';
import { css } from "@convertly/css";
import ReactHTMLParser from "@convertly/react-html-parser";

//Default Props
import defaultGlobal from '../themes/global';
import defaultData from './header-top-default-data';

//Styles
import headerTopStyles from './header-top-styles-2';

//Components
import Elements from '../elements/index';

export default class
HeaderTop2 extends Component {
    constructor( props ) {
        super( props );

    }
    
    render() {

        this.styles = headerTopStyles( this.props.global, this.props.data );

        return(
            <div className={ css( this.styles.headerTop ) }>
                <div className="container">
                    <div className="row">
                        <div className={ css( this.styles.headerIn ) }>
                            <div className={ css( this.styles.headerLft ) }>
                                <Elements.Heading6
                                    parentClassName={ this.styles.headerSale }
                                    data={ this.props.data.callOutText }
                                    elementDataName="callOutText"
                                    moduleId={ this.props.id }
                                    moduleSort={ this.props.sort }/>
                            </div>
                            <div className={ css( this.styles.headerRgt, this.styles.pullRight )  } itemScope itemType="http://schema.org/contact">
                                <ul className={ css( this.styles.headerContactList ) }>
                                    <li className={ css( this.styles.headerContactListItem ) } itemProp="url">
                                        <a id="headerContactPhone"
                                           href={ "tel:" + this.props.data.phone.text }
                                           className={ css( this.styles.headerContactListItemLink, this.styles.phoneIcon ) }>
                                            <span className={ css( this.styles.headerContactListItemIcon ) }>
                                                <i className="fa fa-phone" aria-hidden="true"></i>
                                            </span>{ ReactHTMLParser( this.props.data.phone.text ) }</a>
                                    </li>
                                    <li className={ css( this.styles.headerContactListItem ) } itemProp="url">
                                        <a id="headerContactEmail"
                                           href= { "mailto:" + this.props.data.email.text }
                                           className={ css( this.styles.headerContactListItemLink, this.styles.emailIcon ) }>
                                            <span className={ css( this.styles.headerContactListItemIcon ) }>
                                                <i className="fa fa-envelope" aria-hidden="true"></i>
                                            </span>Contact Us</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
HeaderTop2.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};