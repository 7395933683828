import React from 'react';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';

//Utils
import getHeaderPosition from '../header-style-functions/get-header-position';

//scripts
import absoluteToFixed from '../scripts/absolute-to-fixed-header';

//defaultProps
import defaultGlobal from '../../../themes/global';
import defaultData from '../header-default-data';

//Styles
import headerStyles from './header-styles-09.js';

//Components
import Nav09 from './components/nav-09';
import Elements from '../../../elements/index';


export class Header09 extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const headerPosition = getHeaderPosition(this.props.data.position, this.props.page);

        this.styles = headerStyles(this.props.global, this.props.data, headerPosition);

        if (this.props.editor) {
            return this.editor();
        }

        let headerID = headerPosition === "overlayFixed" ? id(absoluteToFixed) : "header";

        /**
         * Fixed Header Fix
         */
        let fixedHeaderFix;

        if (headerPosition === "fixed") {
            fixedHeaderFix =
                <div id="test" className={css(this.styles.fixedHeaderFix)}/>;
        }
        else {
            fixedHeaderFix = <div/>
        }



        return (
            <div id={headerID}>
                <header id="header09"
                        className={css(this.styles.header)}
                        data-elementType="header"
                        itemProp="siteHeader"
                        itemScope
                        itemType="http://schema.org/siteHeader">
                    <Nav09 global={this.props.global}
                           data={this.props.data}
                           headerPosition={headerPosition}
                           styles={this.styles}
                           page={ this.props.page }
                           site={this.props.site}/>
                </header>

                {fixedHeaderFix}


            </div>
        )
    }

}

Header09.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
