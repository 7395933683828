import { StyleSheet } from '@convertly/css';
import userOverride from "../../../../elements/style-functions/user-overrides";
import removeNestedUserOverrides from '../../utils/removeNestedUserOverrides';
import _ from 'lodash';

const searchBarStyles = ({ global, data, inEditor }) => {
  const topLevelSearchBar = removeNestedUserOverrides({ data });

  const {
    userOverrides
  } = data;

  const {
    searchBarContainer,
    searchBarWrapper,
    searchBar,
    searchIconWrapper,
    searchIcon,
    xIconWrapper,
    xIcon,
    searchBarInput,
    searchBarResultsWrapper,
    searchBarResults,
    searchBarProductItemList,
    productItem,
    productItemText,
    spinner,
    searchBarInputWrapper,
    searchBarInputPlaceholderOnFocus,
    searchBarInputPlaceholder,
    xSearchIconWrapper,
    xSearchIcon,
  } = userOverrides;

    const noResultsFlag = {
        '0%': {
            transform: 'rotate(0deg)',
            color: 'red',
            fontWeight: '700'
        },

        '20%': {
            transform: 'translateX(2px)',
            color: 'red',
            fontWeight: '700'
        },
        '40%': {
            transform: 'translateX(-1px)',
            color: 'red',
            fontWeight: '700'
        },

        '60%': {
            transform: 'translateX(1px)',
            color: 'red',
            fontWeight: '700'
        },
        '80%': {
            transform: 'translateX(-1px)',
            color: 'red',
            fontWeight: '700'
        },
        '100%': {
            transform: 'translateX(1px)',
            color: 'initial',
            fontWeight: 'initial'
        },
    };
    
    const topLevelsearchBarUserOverrides = !_.isEmpty(topLevelSearchBar) ? userOverride(global, { userOverrides: topLevelSearchBar }, inEditor) : {};
    const searchBarContainerUserOverrides = _.get(searchBarContainer, 'userOverrides') ? userOverride(global, searchBarContainer, inEditor) : {};
    const searchBarWrapperUserOverrides = _.get(searchBarWrapper, 'userOverrides') ? userOverride(global, searchBarWrapper, inEditor) : {};
    const searchBarUserOverrides = _.get(searchBar, 'userOverrides') ? userOverride(global, searchBar, inEditor) : {};
    const searchIconWrapperUserOverrides = _.get(searchIconWrapper, 'userOverrides') ? userOverride(global, searchIconWrapper, inEditor) : {};
    const searchIconUserOverrides = _.get(searchIcon, 'userOverrides') ? userOverride(global, searchIcon, inEditor) : {};
    const xIconWrapperUserOverrides = _.get(xIconWrapper, 'userOverrides') ? userOverride(global, xIconWrapper, inEditor) : {};
    const xIconUserOverrides = _.get(xIcon, 'userOverrides') ? userOverride(global, xIcon, inEditor) : {};
    const searchBarInputUserOverrides = _.get(searchBarInput, 'userOverrides') ? userOverride(global, searchBarInput, inEditor) : {};
    const searchBarResultsWrapperUserOverrides = _.get(searchBarResultsWrapper, 'userOverrides') ? userOverride(global, searchBarResultsWrapper, inEditor) : {};
    const searchBarResultsUserOverrides = _.get(searchBarResults, 'userOverrides') ? userOverride(global, searchBarResults, inEditor) : {};
    const searchBarProductItemListUserOverrides = _.get(searchBarProductItemList, 'userOverrides') ? userOverride(global, searchBarProductItemList, inEditor) : {};
    const productItemUserOverrides = _.get(productItem, 'userOverrides') ? userOverride(global, productItem, inEditor) : {};
    const productItemTextUserOverrides = _.get(productItemText, 'userOverrides') ? userOverride(global, productItemText, inEditor) : {};
    const spinnerUserOverrides = _.get(spinner, 'userOverrides') ? userOverride(global, spinner, inEditor) : {};
    const searchBarInputWrapperUserOverrides = _.get(searchBarInputWrapper, 'userOverrides') ? userOverride(global, searchBarInputWrapper, inEditor) : {};
    const searchBarInputPlaceholderOnFocusUserOverrides = _.get(searchBarInputPlaceholderOnFocus, 'userOverrides') ? userOverride(global, searchBarInputPlaceholderOnFocus, inEditor) : {};
    const searchBarInputPlaceholderUserOverrides = _.get(searchBarInputPlaceholder, 'userOverrides') ? userOverride(global, searchBarInputPlaceholder, inEditor) : {};
    const xSearchIconWrapperUserOverrides = _.get(xSearchIconWrapper, 'userOverrides') ? userOverride(global, xSearchIconWrapper, inEditor) : {};
    const xSearchIconUserOverrides = _.get(xSearchIcon, 'userOverrides') ? userOverride(global, xSearchIcon, inEditor) : {};

    return StyleSheet.create({
      searchBar: {
        ...topLevelsearchBarUserOverrides,
        '.search-bar-container': {
          fontFamily: _.get(global, 'fontFamily.secondaryFont'),
          ...searchBarContainerUserOverrides
        },
  
        '.search-bar-wrapper': {
          ...searchBarWrapperUserOverrides
        },
  
        '.search-bar': {
          ...searchBarUserOverrides
        },
  
        '.search-icon-wrapper': {
          ...searchIconWrapperUserOverrides
        },
  
        '.search-icon': {
            width: '20px',
            height: '20px',
            ...searchIconUserOverrides
        },
  
        '.x-icon-wrapper': {
            order: '2',
            display: 'flex',
            ...xIconWrapperUserOverrides
        },
  
        '.x-icon': {
            cursor: 'pointer',
            ...xIconUserOverrides
        },

        '.x-search-icon-wrapper': {
          ...xSearchIconWrapperUserOverrides
        },

        '.x-search-icon': {
          ...xSearchIconUserOverrides
        },
  
        '.search-bar-input': {
          ...searchBarInputUserOverrides
        },
  
        '.search-bar-results-wrapper': {
          ...searchBarResultsWrapperUserOverrides
        },
  
        '.search-bar-results': {
          ...searchBarResultsUserOverrides
        },
  
        '.search-bar-product-items-list': {
          ...searchBarProductItemListUserOverrides
        },
  
        '.product-item': {
          ...productItemUserOverrides
        },

        ".product-item-text": {
          ...productItemTextUserOverrides
        },

        '.spinner': {
            height: '10%',
            width: '10%',
            ...spinnerUserOverrides
        },
  
        '.search-bar-input-wrapper': {
          ...searchBarInputWrapperUserOverrides
        },
  
        '.search-bar-input:focus + .search-bar-input-placeholder': {
            ...searchBarInputPlaceholderOnFocusUserOverrides
        },
  
        '.search-bar-input-placeholder': {
          ...searchBarInputPlaceholderUserOverrides
        },

      }

    })
}

export default searchBarStyles;