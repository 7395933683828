import React from 'react';
import {css} from "@convertly/css";
import Component from '../rootComponent';
import ReactHTMLParser from "@convertly/react-html-parser";

import defaultGlobal from '../themes/global';

//styles
import landingPageStyles from './landing-page-styles';

import Scripts from './landing-page-script';
import {id} from '@convertly/thor';

export default class ImageOverlayText1 extends Component {
	constructor ( props ) {
		super ( props );

	}

	render () {

		this.styles = landingPageStyles ( this.props.global, this.props.data );

		return (
			<div id={ id ( Scripts ) } className={ css ( this.styles.landingPage ) }>
				<div className={ css ( this.styles.logoRow ) }>
					<img className={ css ( this.styles.logoImg ) } src="https://d393hjtdh3b27x.cloudfront.net/convertly/img/convertly-logo.png" alt="Convertly Logo"/>
				</div>

				<div className={ css ( this.styles.formContainer ) }>
					<div className={ css ( this.styles.landingPageTextContainer ) }>
						<div className={ css ( this.styles.landingPageTitle ) }>
							<h1>Sell more stuff on Convertly</h1>
						</div>
						<div className={ css ( this.styles.landingPageParagraph ) }>
							<p>Poke yuccie hot chicken, offal chicharrones dreamcatcher messenger bag whatever. Organic
								chicharrones poke, biodiesel chartreuse pinterest dreamcatcher. Gentrify chillwave
								seitan, meditation try-hard heirloom vexillologist man bun kombucha synth bushwick
								lo-fi.
							</p>
						</div>
					</div>
					<div className={ css ( this.styles.landingPageFormContainer ) }>
						<h2>Flexitarian disrupt photo</h2>
						<p> Chicharrones mixtape dreamcatcher skateboard master cleanse.</p>
						<form id="submit-form" className={ css ( this.styles.landingPageForm ) }>
							<input name="form-name" className={ css ( this.styles.landingPageInput ) } placeholder="Name"/>
							<input required name="form-email" className={ css ( this.styles.landingPageInput ) } placeholder="Email (required)"/>
							<input name="form-website" className={ css ( this.styles.landingPageInput ) } placeholder="Existing Website"/>
							<input type="submit" className={ css ( this.styles.landingPageButton ) } value="Sign up"/>
							<input type="hidden" name="form-list" value="15c402f719"/>
							<p id="form-message" className={ css( this.styles.landingPageFormMessage ) }>&nbsp;</p>
						</form>
					</div>

					<div className={ css ( this.styles.landingPageParagraphMobile ) }>
						<p>Poke yuccie hot chicken, offal chicharrones dreamcatcher messenger bag whatever. Organic
							chicharrones poke, biodiesel chartreuse pinterest dreamcatcher. Gentrify chillwave seitan,
							meditation try-hard heirloom vexillologist man bun kombucha synth bushwick lo-fi.
						</p>
					</div>
				</div>
				<div className={ css ( this.styles.footerText ) }>
					<p> &copy; 2017 Convertly, All Rights Reserved.</p>
				</div>

			</div>
		)
	}
}

ImageOverlayText1.defaultProps = {
	data: {},
	global: defaultGlobal
};
