const data = {
    callOutText: {
        text: "Free Standard Shipping On Orders Over $50",
        align: '',
        fontFamily: '',
        textEditorSize: '',
    },
    phone: {
        text: "1-800-555-4455",
        link: {
            url: "1-800-555-4455",
            linkType: "phoneNumber"
        },
        align: '',
        fontSize: '',
        color: '',
        fontFamily: '',
    },
    email: {
        text: 'test@test.com',
        align: '',
        fontSize: '',
        color: '',
        fontFamily: '',
    },
};

export default data;