import React, { Component } from 'react';
import { generateLink } from '../../utils/generateLink';
import { css } from "@convertly/css";

export default class WrapperLink extends Component {

    constructor ( props ) {
        super( props );
    }

	renderChildren(props) {
		return React.Children.map(props.children, child => {
			return React.cloneElement(child, {
				moduleId: props.moduleId,
				moduleSort: props.moduleSort,
				styles: props.styles
			});
		})
	}

    render() {



        return ! this.props.inEditor ? (

            <a className={ css( this.props.parentClassName ) }
               href={ generateLink( this.props.data.link ) }
               target={ this.props.data.target ? "_blank" : "_self" }>{ this.renderChildren( this.props ) }</a>

		) : ( <a className={ css( this.props.parentClassName )} >{ this.renderChildren( this.props ) }</a> );
    }
}
/** Component Boilerplate
 *
     <Elements.WrapperLink
     data={ this.props.data.link }
     parentClassName={ this.styles.className }
     >
 *
 **/
