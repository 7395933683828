import React from 'react';
import Component from '../../../rootComponent';
import { css } from "@convertly/css";

import getHeaderPosition from '../header-style-functions/get-header-position';


//defaultProps
import defaultGlobal from'../../../themes/global';
import defaultData from '../header-default-data';

//Styles
import headerStyles from './header-styles-04.js';

//Components
import HeaderNav3 from '../header-03/header-nav-3';

export class Header4 extends Component {

    constructor( props ) {
        super( props );
    }

    render() {
        const { data, page, site, global } = this.props;
        this.styles = headerStyles({...this.props});

        let headerPosition = getHeaderPosition(data.position, this.props.page);
        if (this.props.editor) {
            return this.editor();
        }

        if (headerPosition === "noHeader") {
            return null
        }

        else {

            return (
                <header
                    className={ "header04 " + css(this.styles.header) }
                    data-elementType="header"
                    itemProp="siteHeader"
                    itemScope
                    itemType="http://schema.org/siteHeader">
                    <HeaderNav3 global={ global }
                                data={ data }
                                page={ page }
                                site={ site }/>
                </header>
            )
        }
    }
}

Header4.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
