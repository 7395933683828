import { generatePseudoMediaQuery } from "../../../../../elements/style-functions/pseudo-media-queries";

const quickViewModalStyleObject = (global, data, inEditor, inPDP) => {
  let mobileStyles = {};
	

  if (inPDP) {
    mobileStyles = {
      ...generatePseudoMediaQuery(
        "@media(max-width:991px)",
        ['largeTablet', 'smallTablet', 'mobile'],
        {
          position: 'fixed',
          bottom: '0',
          left: '0',
          width: "100vw",
          'pointer-event': 'all',
        },
        inEditor
      ),
      ...generatePseudoMediaQuery(
        '@media(max-width: 768px)',
        ['smallTablet'],
        {
          width: inEditor ? '768px' : '100vw'
        },
        inEditor
      ),
      ...generatePseudoMediaQuery(
        '@media(max-width: 375px)',
        ['mobile'], {
          width: inEditor ? '375px' : '100vw'
        },
        inEditor
      )
    }
  }

  const productVariantBackgroundColor = {
      backgroundColor: global.colors.dark.color2,
      backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .3))',
  };

  return {
    ".quick-view-product-variant-container": {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'flex-end',
      ...productVariantBackgroundColor,
    },
    ".quick-view-product-variant-wrapper": {
      width: "100%"
    },
    ".quick-view-modal-container": {
      top: 0,
      left: 0,
      position: 'fixed',
      zIndex: '25',
      width: '100vw',
      height: '100vh',
      background: 'rgba(0,0,0,.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...generatePseudoMediaQuery(
        '@media(max-width: 768px)',
        ['smallTablet'],
        {
          width: inEditor ? '768px' : '100vw',
          display: 'none'
        },
        inEditor
      ),
      ...generatePseudoMediaQuery(
        '@media(max-width: 375px)',
        ['mobile'], {
          width: inEditor ? '375px' : '100vw'
        },
        inEditor
      )
    },
    ".quick-view-modal": {
      position: "relative",
      minWidth: '700px',
      height: '90%'
    },
    ".quick-view-header": {
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, .4)',
      backgroundColor: global.colors.light.white,
      position: 'relative',
      'z-index': '2'
    },
    '.closeButton': {
      position: 'static',
      top: '0px',
      display: 'inline-block',
      border: '1px solid #dbdbdb',
      backgroundColor: 'transparent',
      color: global.colors.light.color3,
      height: '40px',
      width: '71px',
      padding: '9px 15px',
      fontSize: '14px',
      cursor: 'pointer',
      marginLeft: '10px',
      ':hover': {
        backgroundColor: '#f6f6f6'
      }
    },
    ".title-container": {
      color: global.colors.dark.color1,
      fontSize: "14px",
      lineHeight: "20px",
      position: "relative",
      textAlign: "left",
      width: 'fit-content',
      padding: '10px 25px 7px 5px',
      display: 'inline-block',
      ".on-sale": {
        borderBottomRightRadius: '50px',
        background: global.colors.accent.color2,
      }
    },

    '.quick-view-header-close-detail-wrapper': {
      display: 'inline-block',
      float: 'right',
      marginTop: '14px',
      marginRight: '10px'
    },

    '.view-more-detail-wrapper': {
      display: 'inline-block',
    },

    '.view-more-detail-link': {
      fontSize: '12px',
      cursor: 'pointer'
    },

    ".producttitle": {
      color: global.colors.dark.color1,
      fontSize: '18px',
      lineHeight: '24px',
      paddingBottom: '5px',
      fontWeight: 'bold',
      'text-overflow': 'ellipsis',
      'overflow': 'hidden',
      'white-space': 'nowrap',
    },
    ".quick-view-content": {
      ...productVariantBackgroundColor,
      position: 'relative'
    },
    ".quick-view-content-slot": {
      width: '50%',
      height: '100%',
      verticalAlign: 'bottom',
      display: 'inline-block',
      ...productVariantBackgroundColor,
      padding: '0px 10px 0px',
      position: 'relative',
    },
		".buying-options-wrapper": {
		  width: '100%',
		  background: global.colors.light.color3,
		  ...mobileStyles
    },
    
    ".quick-view-product-variant-spinner-wrapper": {
      width: '50%',
      position: 'absolute',
      zIndex: '1',
      background: 'rgba(0,0,0,0.3)',
      height: '100%'
    }
  }
}

export default quickViewModalStyleObject;
