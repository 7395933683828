import React from 'react';
import { css } from "@convertly/css";
import { id } from '@convertly/thor';
import { Frontload } from '@convertly/react-frontload';
import _ from 'lodash';
import rootComponent from './rootComponent'

//Styles
import appStyles from './app-styles';

//Default props
import defaultGlobal from './themes/global';
import defaultData from './app-default-data.js';

//Scripts
import preventPageRefresh from './app-scripts/prevent-page-refresh';
import registerYoutubeHandler from './app-scripts/register-youtube-hander';
import registerAnimatedElements from './app-scripts/register-animated-elements';
import registerLazyLoadImages from './app-scripts/register-lazy-load-images';
import registerEventTracking from './app-scripts/register-event-tracking';
import TheStore from './utils/Store';
import authMasterControl from './sign-in-up/auth-master-control';
import registerSocialLoginAppIds from './app-scripts/register-social-login-app-ids';
import DomController from './inferno-controllers/dom-controller';
import findIndexPolyfill from './find-index-polyfill';
import {registerIdleCallbackAPI} from "./app-scripts/register-idle-callback";

//The Cart
import TheCart from './ecommerce/the-cart/the-cart';

//Utils
import { generateLink } from './utils/generateLink';

//Snack Bar Alert
import SnackBarAlert from './snack-bar-alert/snack-bar-alert-wrapper'
//Utils
import { setSite } from '@convertly/utils';
import {deferBgImages, deferImages} from "./app-scripts/defer-bg-images";


export default class App extends rootComponent {

	constructor ( props ) {

		super( props );

		let classes = this.props.data.classes || [];
		this.class = classes.join( ' ' );
	}

	render() {
		const systemFacebookPixelId = _.get(this.props, 'site.systemFacebookPixelId');
		const customUserFacebookPixelId = _.get(this.props, 'site.facebookPixelId');
		const siteId = _.get( this.props, 'site.siteId' );

		this.styles = appStyles( this.props.global, this.props.data, this.props.page, this.props.inEditor );

		setSite( this.props.data.site );

		const fbAppId = _.get( this.props, 'site.socialLogin.facebook.id' );
		const googleAppId = _.get( this.props, 'site.socialLogin.google.id' );
		id( registerEventTracking, 'send', 'event', 'Convertly', 'heartbeat', systemFacebookPixelId, customUserFacebookPixelId, siteId );
		id( preventPageRefresh );
		id( registerYoutubeHandler );
		id( registerAnimatedElements );
		id( registerLazyLoadImages );
		id( TheStore );
		id( authMasterControl, this.props.data.site.siteId );
		id( registerSocialLoginAppIds, fbAppId, googleAppId );
		id( DomController );
		id( findIndexPolyfill );

		if(!this.state.disableImageLazyLoad) {
			id( registerIdleCallbackAPI );
			id( deferBgImages );
		}

		let ecommerce = null;
		let snackBarAlert = null;

		if(!this.props.site.disableSnackBarAlert) {

            snackBarAlert = <div className={ css( this.styles.snackBarInfernoComponent ) } id={ id( SnackBarAlert ) } />
		}

		let theCart = null;

		if( _.get( this.props, 'site.ecommerce.enabled' ) ) {
			let siteLink = generateLink( { linkType: "inferno", url:""} );
			let pageSlug = _.get( this.props, 'page.slug' );
			let validateCart = pageSlug === '/cart' || pageSlug === '/checkout';

			theCart = <div id={ id( TheCart, siteLink, validateCart ) } />
		}

		return (
			<Frontload>

				<div id="page-app"
					 data-pagewidth='mobile'
					 data-wrapper="pageWrapper"
					 className={ `${css( this.styles.app )} page-app` }>
					 {this.props.children}
					{ theCart }
					{ snackBarAlert }
				</div>


			</Frontload>
		);
	}
}

App.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
