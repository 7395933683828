
//"use strict"
const fontLoader = (id) => {

    return (id) => {

        (function() {

            window.WebFontConfig = {
                google: {
                    families: ['Rubik']
                },
                timeout: 2000 // Set the timeout to two seconds
            };


        }());

    }

};


export default fontLoader;
