import React from 'react';
import Component from 'source/rootComponent';
import { css } from '@convertly/css';
import { id } from '@convertly/thor';
import _ from 'lodash';

// Utils
import { generateLink } from '../../../utils/generateLink';
import { clientImage } from '../../../utils/generateImage';

// Scripts
import cartHandler from '../../scripts/cart-script';
import isSignedIn from '../../scripts/is-signed-in';
import toggleBurgerDropdown from '../scripts/toggleBurgerDropdown';
import SearchBarWrapper from '../../scripts/inferno-components/search-bar-wrapper/SearchBarWrapper';


// Components
import cartIconSVG from '../../../svg/carts/cart';

// Styles
import cartScriptStyleObject from '../../scripts/cart-script-style-object';

export class NavTopRight extends Component {
  
  constructor(props) {
    super(props);
    this.toggleMenuDropdown = this.toggleMenuDropdown.bind(this);
  }

  toggleMenuDropdown(e) {
    e.preventDefault();
  }

  render() {

    const CartIconSVG = cartIconSVG(React)
    
    const { global, data, site, styles, page, inEditor } = this.props;
    const { siteId } = site;
    
    const clientImgSrc = clientImage("", { width: '60', height: '60', crop: 'fit' }, siteId);
    const clientLinkUrl = generateLink({ linkType: "inferno", url: "" });
    const slug = _.get(page, 'slug') || '/';
    const ecommEnabled = _.get(site, 'ecommerce.enabled') || false;
    const showEcomm = (slug !== '/cart' && ecommEnabled);
    

    const {
      mainNavRightWrapper,
      mainNavRight,
      navRightItems,
      verticalLines,
      searchIcon,
      iconBar,
      dropDownMenuIcon, 
      dropDownMenuWrapper,
      navRightItem,
      desktopCartInfernoWrapper,
      desktopCartInfernoComponent,
      mobileCartContainer,
      mobileCart,
      searchBarWrapper,
    } = styles;
    
    if(!data.showNavigation) {
      return (
        <div className={ css(mainNavRightWrapper) }/>
      )
    }
    return (
      <div className={ css(mainNavRightWrapper) }>
        {/* Burger Dropdown Button */}
        <div className={ css(dropDownMenuWrapper) }>
          <button
            id={ id(toggleBurgerDropdown) }
            className={ css(dropDownMenuIcon) }
            onClick={ this.toggleMenuDropdown }>
            <span className={ css(iconBar) }/>
            <span className={ css(iconBar) }/>
            <span className={ css(iconBar) }/>
          </button>
        </div>

        <div className={ css(mainNavRight) }>
          <div className={ css(navRightItems) }>
						{ !_.get(this.props.page, 'hideSearchBar') &&
              _.get(this.props, 'site.ecommerce.enabled') &&
            <div id={ id(SearchBarWrapper, this.props.site.siteId)} className={ css(searchBarWrapper) }/>
						}
            <div className={ css(verticalLines) }/>

            <div 
              id={ id(isSignedIn, clientLinkUrl) }
              className={ css(navRightItem) }
              href={ generateLink({ linkType: 'internal', url: '/account' }) }>
              Sign In
            </div>

            <div className={ css(verticalLines) }/>

            {
              showEcomm &&
              data.showNavigation &&
              <div className={ css(desktopCartInfernoWrapper) }>
                <div 
                  id={ id(cartHandler, clientLinkUrl, clientImgSrc, "true") }
                  className={ css(desktopCartInfernoComponent) }
                  style={ { borderLeft: '0' } }
                  href={ generateLink({ linkType: 'internal', url: '/cart' }) }>
                  Cart
                </div>
                <div className={ css(mobileCartContainer) }>
                  <CartIconSVG nameOfClass={ 'mobileCartContainer' }/>
                </div>
              </div>
            }

          </div>
        </div>
      </div>
    )
  }
}
