import { createAction } from "redux-act";
import { fromJS } from "immutable";
import { setSite } from "@convertly/utils";
import _ from "lodash";

import { graph } from "utils/graph";
import { store } from "app/app";
import { activeSite } from "utils";
import { getSiteImages } from "services/sites";
import { can } from "containers/Authentication/PermissionsCheck";

export const setConversionPoints = createAction(
  "Editor: Set the current site's conversion points. Used in Track As dropdowns."
);
export const setMailingLists = createAction(
  "Editor: Set the current site's mailing lists. Used in newsletter forms"
);
export const setSiteDataPages = createAction(
  "Editor: Set the current site's pages. Used in dropdowns that show all site pages."
);

/**
 * Catch all function for loading data needed in the Editor
 *
 * @returns {function(*)}
 */
export const loadData = () => async dispatch => {
  setSite({
    siteId: activeSite().get("siteId")
  });
  return await Promise.all([
    dispatch(loadConversionPoints()),
    dispatch(loadMailingLists()),
    dispatch(loadPages()),
    dispatch(getSiteImages())
  ]);
};

export const loadPages = () => async dispatch => {
  const pages = store.getState().getIn(["auth", "activeSite", "pages"]) || fromJS({});
  return await dispatch(setSiteDataPages(pages.toJS()));
};

export const loadMailingLists = () => async dispatch => {
  const userId = store
    .getState()
    .get("auth")
    .get("user")
    .get("sub");
  const siteId = store
    .getState()
    .get("auth")
    .get("activeSite")
    .get("siteId");

  const query = `
		query ($id: ID!, $siteId: String) {
		  Account(id: $id) {
			site(siteId: $siteId) {
			  MailingLists {
				listId
				listName
			  }
			}
		  }
		}
		`;

  const variables = {
    id: userId,
    siteId
  };

  const response = await graph({ query, variables });
  const { MailingLists } = _.get(response, "Account.site");
  return await dispatch(setMailingLists(MailingLists));
};

export const loadConversionPoints = () => async dispatch => {
  const userId = store.getState().getIn(["auth", "user", "sub"]);
  const siteId = store.getState().getIn(["auth", "activeSite", "siteId"]);

  const query = `
		query ($id: ID!, $siteId: String) {
		  Account(id: $id) {
			site(siteId: $siteId) {
			  ConversionPoints {
				pixelTitle
				pixelType
				targetedPages
				google
				facebook
        trackAsGoal
        ${can("view_facebook") ? "facebookCustomEventId" : ""}
			  }
			}
		  }
		}
		`;

  const variables = {
    id: userId,
    siteId
  };

  const response = await graph({ query, variables });
  const { ConversionPoints } = _.get(response, "Account.site");
  return await dispatch(setConversionPoints(ConversionPoints));
};
