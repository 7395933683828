import { StyleSheet } from "@convertly/css";

import SocialIconGlobalStyles from '../social-icon-global-styles';

const SocialIconsStyles = ( global, local ) => {

    const socialIconStyles = new SocialIconGlobalStyles( global, local  );

    return StyleSheet.create({
        socialIcons: {
            padding: 0,
        },
        socialIconsList: {
            ...socialIconStyles.socialIconItem
        },
    })

};

export default SocialIconsStyles;