/*global Inferno, $ */
/* eslint-disable */

const Scripts = ( id ) => {

    return ( id ) => {

        const root = document.getElementById( id );
        const dispatch  = document.getElementById( 'page-app' );

        const $id       = $( '[name=id]' );
        const $price    = $( '[name=price]' );

        const productId = $id.val();
        const price     = $price.val();

        const hasLocalStorage = $('html').hasClass( 'localstorage' );

        const CartHandler = Inferno.createClass({

            getInitialState: function () {

                dispatch.addEventListener( 'add_to_cart', this.handle );

                return {
                    items: [],
                    subtotal: parseFloat( '0.00' ),
                    stepper: 1
                };
            },

            componentDidMount: function() {
                this.getSavedCart();
            },

            componentDidUpdate: function ( prevProps, prevState ) {

                if( this.state.items.length !== prevState.items.length ) {
                    this.broadCastCart();
                }
            },

            broadCastCart: function() {
                const props = {
                    detail : {
                        action: 'current_cart',
                        value: this.state.items
                    }
                };

                const event = new CustomEvent( 'cart', props );
                dispatch.dispatchEvent( event );
            },

            addToCart: function() {

                let inCart = false;

                this.state.items.map( ( item ) => {
                    if( item.id === productId ) {
                        inCart = true;
                        this.addExistingItemToCart( item );
                    }
                });

                if( ! inCart ) {
                    this.addNewItemToCart();
                }

            },

            addNewItemToCart: function() {

                const item = {
                    id: productId,
                    quantity: this.state.stepper,
                    price: price
                };

                const items = this.state.items;
                items.push( item );

                this.setState( { items: items }, this.updateCartTotal );

            },

            addExistingItemToCart: function( item ) {

                item.quantity += this.state.stepper;

                const items = this.state.items.filter( ( cartItem ) => {
                    return cartItem.id !== item.id;
                });

                items.push( item );

                this.setState( { items: items }, this.updateCartTotal );

            },

            updateCartTotal: function() {


                let subtotal = parseFloat( '0.00' );

                this.state.items.map( ( item ) => {
                    const total = item.quantity * parseFloat( item.price );
                    subtotal += total;
                });

                this.setState( { subtotal: subtotal }, this.saveCart );

            },

            saveCart: function() {

                const cart = {
                    items: this.state.items,
                    subtotal: this.state.subtotal
                };

                if( hasLocalStorage ) {
                    localStorage.setItem( 'cart', JSON.stringify( cart ) );
                }

                this.broadCastCart();

            },

            getSavedCart: function() {

                if( hasLocalStorage ) {
                    let cart = localStorage.getItem( 'cart' );
                    if( cart ) {
                        try {
                            cart = JSON.parse( cart );
                            this.setState( { items: cart.items } );
                        } catch( e ) {
                            localStorage.removeItem( 'cart' );
                        }

                    }
                }

            },

            setStepper: function( value ) {
                this.setState( { stepper: value } );
            },

            handle: function( e ) {
                const { action, value } = e.detail;

                switch( action ) {
                    case 'set_stepper':
                        this.setStepper( value );
                        break;
                    case 'add_to_cart':
                        this.addToCart();
                        break;
                    default:
                        break;
                }

            },

            render: function () {
                return ( null )
            }

        });

        Inferno.render( <CartHandler />, root );

    };

};

export default Scripts;
