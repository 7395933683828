import { StyleSheet } from "@convertly/css";
import UserOverrides from "../../elements/style-functions/user-overrides";
import removeNestedUserOverrides from "../../site-header/site-header/utils/removeNestedUserOverrides";

export const AuthorListStyles = ({ global, data, inEditor }) => {

  const { userOverrides } = data || {},
        authorList = userOverrides ? removeNestedUserOverrides({ data }) : {},
        { authorAvatar, authorName, authorWrapper } = userOverrides || {};

  return StyleSheet.create({
    authorListStyle: {
      ...UserOverrides(global, { userOverrides: authorList }, inEditor)
    },
    authorWrapper: {
      display: "flex",
      "align-items": "center",
      ...UserOverrides(global, authorWrapper, inEditor)
    },
    authorAvatar: {
      width: "50px",
      height: "50px",
      "border-radius": "50px",
      ...UserOverrides(global, authorAvatar, inEditor)
    },
    authorName: {
      ...UserOverrides(global, authorName, inEditor)
    }
  });
};

