import {StyleSheet} from "@convertly/css";
import {clientImage} from "source/utils/generateImage";
import {sortByKey} from '../../utils/sort-by-key';
import {generatePseudoMediaQuery, buildEditorMediaQueries} from './pseudo-media-queries';
import {getColor} from './get-color';
import {sortUserOverrides} from './sort-user-overrides';
import _ from "lodash";
import {deeplog} from "../../utils/deeplog";
import {handleBgImg} from "./handle-bg-img";

const UserOverrides = (global, element, inEditor) => {

    let overrides = _.get(element, "userOverrides");

    let userOverrides = {};
    if (!overrides) {
        return {}
    }
    //Sorts Media Queries
    if (overrides) {
        let mediaQueries = {};
        let otherStyles = {};

        Object.keys(overrides).map((key) => {

            if (key.indexOf('@media') !== -1) {
                mediaQueries[key] = overrides[key];
            } else {
                otherStyles[key] = overrides[key];
            }

        });

        if (Object.keys(mediaQueries).length > 0) {

            let sortedMediaQueries = {};
            let sortedMediaQueriesStrings = sortByKey(Object.keys(mediaQueries)).reverse();
            sortedMediaQueriesStrings.map((mediaQueryString, index) => {
                Object.keys(mediaQueries).map((key) => {
                    if (key === mediaQueryString) {
                        sortedMediaQueries[key] = mediaQueries[key];
                    }
                })
            });

            overrides = {...otherStyles, ...sortedMediaQueries};

        }
    }



    //Gets viewport for editor
    function getVeiwportSize(mediaQuery) {
        let viewPx = mediaQuery.replace(/\D/g, "");
        viewPx = parseFloat(viewPx);


        if (viewPx <= 580) {
            return ['mobile']
        } else if (viewPx >= 580 && viewPx <= 768) {
            return ['mobile', 'smallTablet']

        } else if (viewPx > 768 && viewPx <= 991) {
            return ['mobile', 'smallTablet', 'largeTablet']
        } else {
            return ['null']
        }

    }


    if (overrides) {
        Object.keys(overrides).map(function (key, index) {

            /** Handles Font Family to match theme */
            if (key.indexOf('@media') !== -1 || key.indexOf(':hover') !== -1) {
                let viewportSize = getVeiwportSize(key);
                let mediaOverrides = {};
                Object.keys(overrides[key]).map(function (mediaKey, index) {

                    if (mediaKey === "fontFamily") {
                        if (overrides[key][mediaKey] === "secondaryFont") {
                            mediaOverrides[mediaKey] = global.fontFamily.secondaryFont
                        } else if (overrides[key][mediaKey] === "primaryFont") {
                            mediaOverrides[mediaKey] = global.fontFamily.primaryFont
                        } else {
                            mediaOverrides[mediaKey] = overrides[mediaKey];
                        }
                    }
                    /** Convert Theme Based colors into real color values */
                    else if (mediaKey === "color") {
                        mediaOverrides[mediaKey] = getColor(overrides[key][mediaKey], 'colors', global)
                    } else if (mediaKey === "backgroundColor") {
                        mediaOverrides[mediaKey] = getColor(overrides[key][mediaKey], 'colors', global)
                    } else if (mediaKey === "fill") {
                        mediaOverrides[mediaKey] = getColor(overrides[key][mediaKey], 'colors', global)
                    } else if (mediaKey === "stroke") {
                        mediaOverrides[mediaKey] = getColor(overrides[key][mediaKey], 'colors', global)
                    } else if (mediaKey === "borderColor") {
                        mediaOverrides[mediaKey] = getColor(overrides[key][mediaKey], 'colors', global)
                    }

                    /** Font Size **/

                    else if (mediaKey === "fontSize") {
                        let fontSize = overrides[key][mediaKey];
                        if(!mediaOverrides.lineHeight) {
                            let lineHeight = fontSize.replace('px', '');
                            lineHeight = parseInt(lineHeight) + 10;
                            mediaOverrides.lineHeight = `${lineHeight}px`;
                        }
                        mediaOverrides[mediaKey] = overrides[key][mediaKey];
                    }

                    /** Center Align **/

                    else if (mediaKey === "center") {
                        if (overrides[key][mediaKey] === "vertical") {
                            mediaOverrides['position'] = "relative";
                            mediaOverrides['top'] = "50%";
                            mediaOverrides['transform'] = "translateY(-50%)";
                        }
                    }

                    /** Animate on hover **/

                    else if (mediaKey === "animateOnHover" && element.animated) {
                        mediaOverrides[":hover"] = {
                            "animation-name": element.animated,
                            "animation-duration": "1s"
                        }

                    }


                    /** Handles Background Images */
                    else if (mediaKey === "backgroundImage" && overrides[key][mediaKey]) {
                        try{
                            mediaOverrides = {...mediaOverrides, ...handleBgImg(overrides[key][mediaKey], inEditor) }
                        }
                        catch(err) {
                            console.log(err)
                        }

                    }

                    /** Handles gradients **/
                    else if (mediaKey === "backgroundGradient") {
                        let valueArray = userOverrides[key][mediaKey].split(' ');
                        //See if any value is a global value and get it's color
                        let newValue = [];
                        valueArray.map((value) => {
                            if (value.indexOf('dark') !== -1 ||
                                value.indexOf('accent') !== -1 ||
                                value.indexOf('light') !== -1) {
                                newValue.push(getColor(value))
                            } else {
                                newValue.push(value)
                            }
                        });
                        mediaOverrides["background"] = newValue.join('')
                    } else {

                        mediaOverrides[mediaKey] = overrides[key][mediaKey];
                    }
                });


                let mediaQueryStyles = {
                    ...userOverrides, ...generatePseudoMediaQuery(key, viewportSize,
                        {...mediaOverrides}, false)
                };

                userOverrides = mediaQueryStyles;

            } else if (key === "fontFamily") {
                if (overrides[key] === "secondaryFont") {
                    userOverrides[key] = global.fontFamily.secondaryFont
                } else if (overrides[key] === "primaryFont") {
                    userOverrides[key] = global.fontFamily.primaryFont
                } else {
                    userOverrides[key] = overrides[key];
                }
            }

            /** Theme Based colors */
            else if (key === "color") {
                userOverrides[key] = getColor(overrides[key], 'colors', global)
            } else if (key === "backgroundColor") {
                userOverrides[key] = getColor(overrides[key], 'colors', global)
            } else if (key === "fill") {
                userOverrides[key] = getColor(overrides[key], 'colors', global)
            } else if (key === "stroke") {
                userOverrides[key] = getColor(overrides[key], 'colors', global)
            } else if (key === "borderColor") {
                userOverrides[key] = getColor(overrides[key], 'colors', global)
            }

            /** Handles Background Images assigns to class of (no-)webp to handle webp imgs
             * assigns to .bg-img to enable lazy load of images.
             * **/
            else if (key === "backgroundImage") {
                try{
                    userOverrides = {...userOverrides, ...handleBgImg(overrides[key], inEditor) }
                }
                catch(err) {
                    console.log(err)
                }
            }

            /** Handles gradients **/
            else if (key === "backgroundGradient") {
                let valueArray = overrides[key];

                valueArray = valueArray.split(" ");
                //See if any value is a global value and get it's color
                let newValue = [];

                valueArray.map((value, index) => {
                    if (value.indexOf('dark') !== -1 ||
                        value.indexOf('accent') !== -1 ||
                        value.indexOf('light') !== -1) {

                        if (index !== parseInt(valueArray.length) - 1) {
                            valueArray[index] = (`${getColor(value.replace(',', ""), "colors", global)},`);
                        } else {
                            valueArray[index] = (`${getColor(value.replace(')', ""), "colors", global)})`);
                        }

                    }


                });
                userOverrides["background"] = valueArray.join(' ')
            }

            /** Vertical Align **/
            else if (key === "center") {
                if (overrides[key] === "vertical") {
                    userOverrides['position'] = "relative";
                    userOverrides['top'] = "50%";
                    userOverrides['transform'] = "translateY(-50%)";
                }
            }

            /**
             * Animate on hover
             * animateOnHover: value is the duration
             * **/
            else if (key === "animateOnHover") {
                userOverrides["animation-name"] = "''";
                userOverrides[":hover"] = {
                    "animation-name": overrides[key].name,
                    "animation-duration": overrides[key].duration || '1s'
                }
            }

            /** Handles Borders **/
            else if (key.indexOf("border") !== -1) {
                if (overrides[key] === "defaultBorder") {
                    userOverrides[key] = global.border
                } else {
                    userOverrides[key] = overrides[key]
                }
            } else if (key.indexOf("fontSize") !== -1) {
                let fontSize = overrides[key];
                if(!userOverrides.lineHeight) {
                    let lineHeight = fontSize.replace('px', '');
                    lineHeight = parseInt(lineHeight) + 10;
                    userOverrides.lineHeight = `${lineHeight}px`;
                }
                userOverrides[key] = overrides[key];
            }
            /** Copies the rest of the styles over */
            else {
                userOverrides[key] = overrides[key];
            }
        })
    }

    if (inEditor) {
        userOverrides = buildEditorMediaQueries(userOverrides)
    }

    if (userOverrides) {
        userOverrides = sortUserOverrides(userOverrides);
    }


    return userOverrides;


};

export default UserOverrides;
