import FieldContainer from './form/field-containers/field-containers';
import SelectInput from './form/select-input/select-input';
import CheckboxInput from './form/checkbox-input/checkbox-input';
import RadioInput from './form/radio-input/radio-input';
import TextArea from './form/text-area';
import Input from './form/input/input';
import Form from './form/form';
import SubmitButton from './form/submit-button/submit-button';
import Label from './form/label/label';
import Heading1 from './heading/heading1';
import Heading2 from './heading/heading2';
import Heading3 from './heading/heading3';
import Heading4 from './heading/heading4';
import Heading5 from './heading/heading5';
import Heading6 from './heading/heading6';
import HTMLContentRenderer from './html-content-renderer/HTMLContentRenderer';
import Paragraph from './paragraph/paragraph';
import BlockQuote from './blockquote/blockquote';
import Image from './image/image';
import WrapperLink from './links/wrapper-link-01';
import WebLink from './links/web-link';
import GoogleMapLink from './links/google-map-link';
import UnorderedList from './unordered-list/un-ordered-list';
import Button from './buttons/button-web-link';
import Container from './bootstrap-containers/container';
import ContainerFluid from './bootstrap-containers/container-fluid';
import Row from './bootstrap-containers/row';
import Column from './bootstrap-containers/column';
import ModuleWrapper from './module-wrapper/module-wrapper';
import ButtonPhoneLink from './buttons/button-phone-link';
import Video from './video/video-player/video-player';
import VideoBG from './video/video-bg/video-bg';
import WrapperDiv from './wrapper-div/wrapper-div';
import Divider from './divider/divider';
import EmailLink from './links/email-link';
import ZeroFillContactForm from './zero-fill-contact-form/zero-fill-contact-form';
import KyrishContactForm from './kyrish-contact-form/kyrish-contact-form';
import DivWithOverlay from './div-with-overlay/div-with-overlay';
import DivWithOverlay02 from './div-with-overlay/div-with-overlay-02';
import DivWithImage from './div-with-overlay/div-with-image';
import Overlay from './overlay/overlay';
import SVGCodeWrapper from './svg-code-wrapper/svg-code-wrapper';
import Pre from './pre/pre';
import Address from './address/address';
import {FullPageScrollingBanner} from './full-page-scrolling-banner/FullPageScrollingBanner';
import {Logo} from './logo/Logo';
import LightBoxImageGallery from './lightbox-image-gallery/LightBoxImageGallery';

const elements = {

    ModuleWrapper: ModuleWrapper,
    Heading1: Heading1,
    Heading2: Heading2,
    Heading3: Heading3,
    Heading4: Heading4,
    Heading5: Heading5,
    Heading6: Heading6,
    HTMLContentRenderer,
    Paragraph: Paragraph,
    BlockQuote: BlockQuote,
    WebLink: WebLink,
    WrapperLink: WrapperLink,
    GoogleMapLink: GoogleMapLink,
    Button: Button,
    ButtonPhoneLink: ButtonPhoneLink,
    Image: Image,
    Form: Form,
    Input: Input,
    SelectInput: SelectInput,
    CheckboxInput: CheckboxInput,
    RadioInput: RadioInput,
    FieldContainer: FieldContainer,
    SubmitButton: SubmitButton,
    Label: Label,
    DivWithOverlay: DivWithOverlay,
    DivWithOverlay02: DivWithOverlay02,
    DivWithImage: DivWithImage,
    Overlay: Overlay,
    // AddressLink: AddressLink,
    UnorderedList: UnorderedList,
    Container: Container,
    ContainerFluid: ContainerFluid,
    Row: Row,
    Column: Column,
    Video: Video,
    VideoBG: VideoBG,
    WrapperDiv: WrapperDiv,
    EmailLink: EmailLink,
    Divider: Divider,
    ZeroFillContactForm: ZeroFillContactForm,
    KyrishContactForm: KyrishContactForm,
    SVGCodeWrapper,
    Pre,
    FullPageScrollingBanner,
    Logo,
    Address,
    LightBoxImageGallery,
    TextArea
};

export default elements






