import React from 'react';
import PropTypes from 'prop-types';
import {css} from "@convertly/css";
import Component from '../../../rootComponent';
import {id} from '@convertly/thor';
import ReactHTMLParser from "@convertly/react-html-parser";
import {clientImage} from '../../../utils/generateImage';
import {generateLink} from '../../../utils/generateLink';
import Promise from 'bluebird';
import {frontloadConnect} from '@convertly/react-frontload';
import axios from 'axios';
import config from '../../../config';
import {buildProductPricingObject} from "../../../utils/ecomm-utils/build-product-pricing-object";

//Inferno Components
import ProductGrid from './inferno-components/product-grid-inferno-component';

//Styles
import productGridStyles from './product-grid-styles-01';

import ReactHTMLparser from '@convertly/react-html-parser';

import EcommerceSettings from '../../../ecommerce/ecommerce-settings';

import generateId from '../../../utils/generate-id';


const products = [];
const filename = 'modules/product-grid/product-grid-02';
const noop = (e) => {
	e.preventDefault();
};
let loaded = false;

const loadProductsAsync = (props, component) => {
	
	return new Promise((resolve, reject) => {
		
		if (loaded && component.props.inEditor) {
			return resolve();
		}
		
		products.length = 0;
		
		
		const query = `query( $id:ID!){
          Products( siteId: $id ) {
            id, slug, price, optionsCollection, description, title, sku, onSale, active, inStock,
            manufacture_details { manufacturer, model_number }, images, salePrice, discountValue, discountType,
            variationTypeMeta
            }
        }`;
		
		const variables = {id: props.site.siteId};
		
		const local = false;
		
		const url = local ? 'http://localhost:4000' : 'https://graph.convertly.com';
		
		const params = {
			url: url,
			method: 'post',
			data: {
				query: query,
				variables: variables
			}
		};
		
		axios(params)
			
			.then((res) => {
				//Live::: const returnedProducts = res.data.Products;
				const returnedProducts = res.data.data.Products;
				//returnedProducts.length = 6;
				
				returnedProducts.filter(p => p.active && p.inStock).map((product) => {
					
					product.pricing = buildProductPricingObject(product);
					
					if (product.optionsCollection) {
						product.optionsCollection = JSON.parse(product.optionsCollection);
					}
					if (product.variationTypeMeta) {
						product.variationTypeMeta = JSON.parse(product.variationTypeMeta);
					}
					
					product.description = product.description.replace(/"/g, '\\"');
					product.description = product.description.replace(/(['])/g, "<<>>");
					
					products.push(product);
					if (component) {
						//component.props.data.products.push(product);
					}
				});
				
				if (component && component.inEditor()) {
					component.forceUpdate();
				}
				
				loaded = true;
				
				resolve();
				
			})
			.catch((err) => {
				console.error('Axios error', err);
				console.error('Axios error', err.message);
				resolve();
			});
		
	});
};

const frontload = (props) => loadProductsAsync(props);

const options = {
	noServerRender: false,
	onMount: true,
	onUpdate: true
};

class ProductGrid03 extends Component {
	
	constructor(props) {
		super(props);
	}
	
	componentDidMount2() {
		
		if (!loaded) {
			return loadProductsAsync(this.props, this);
		}
	}
	
	componentWillMount() {
		
		if (!loaded && this.inEditor()) {
			return loadProductsAsync(this.props, this);
		}
		super.componentWillMount();
	}
	
	
	render() {
		this.styles = productGridStyles(this.props.global, this.props.data, this.inEditor());
		
		let stringifiedProducts = JSON.stringify(products);
		
		stringifiedProducts = stringifiedProducts.replace(/(?:\r\n|\r|\n)/g, '<br/>');
		
		const siteLink = generateLink({linkType: "inferno"});
		const imageUrl = clientImage("", {width: '555', height: '300', crop: 'fit'});
		
		let imageUrls = {
			twoColumnGrid: clientImage("", {width: '555', height: '300', crop: 'fit'}),
			threeColumnGrid: clientImage("", {width: '360', height: '300', crop: 'fit'}),
			fourColumnGrid: clientImage("", {width: '263', height: '300', crop: 'fit'}),
			sixColumnGrid: clientImage("", {width: '165', height: '225', crop: 'fit'}),
			thumbnail: clientImage("", {width: '61', height: '61', crop: 'fit'}),
			featureImage: clientImage("", {width: '555', height: '300', crop: 'fit'}),
		};
		imageUrls = JSON.stringify(imageUrls);
		
		
		let ecommerceSettings = EcommerceSettings(this.props.site);
		
		ecommerceSettings = JSON.stringify(ecommerceSettings);
		
		const {rows, columns} = this.props.data;
		const maxProducts = rows * columns;
		let count = 0;
		
		const inlineStyle = this.generateStyleAttribute();
		
		return (
			
			<div
				className={ css(this.styles.productGridContainer) }
				{ ...this.generateDataEditorAttribute() }
				{...generateId(this.props.elementId)}
				onClick={(e) => {
					this.launchElementalEditor('ProductGrid', e);
				}}
				onMouseOver={ (e) => {
					this.addEditableOutline(e)
				} }
				onMouseOut={ (e) => {
					this.removeEditableOutline(e)
				} }
				{...inlineStyle}>
				
				<div className="container">
					<div className="row">
						<div className={ css(this.styles.sectionTitleContainer) + " col-xs-12"}>
							<h2 className={ css(this.styles.sectionTitle) }>{ this.props.data.categoryName }</h2>
						</div>
					</div>
					<div className={ css(this.styles.productGridInferno) }
							 id={ id(ProductGrid, stringifiedProducts, siteLink, imageUrl, this.props.data.columns, this.props.data.rows, ecommerceSettings, imageUrls, this.props.site.siteId)}>
						<div className="row">
							<div>
								
								{ products.map((product, index) => {
									if (++count > maxProducts) {
										return null;
									}
									
									const price = product.price;
									const brand = product.manufacture_details.manufacturer;
									const {title} = product;
									const url = {
										linkType: 'internal',
										url: product.slug
									};
									/** Rendering gets done to replicate the inferno lay out
									 * as without this it creates a "flash" which looks odd on page load
									 */
									/** 3 columns **/
									if (parseInt(this.props.data.columns) === 3) {
										return (
											
											<div key={index}
													 className={ css(this.styles.productBox) + " col-xs-12 col-sm-12 col-md-4" }>
												<div className={ css(this.styles.productBoxWrapper) }>
													<div className={ css(this.styles.imageContainer) }>
														<img
															src={ clientImage(product.images[0], {
																width: '360', height: '300', crop: 'fit'
															}) }
															alt={ title }
															className={ css(this.styles.productImg) }/>
													</div>
													
													<div className={ css(this.styles.productDescriptionWrapper) }>
														<div className={ css(this.styles.productDescription) }>
															<h4 className={ css(this.styles.subTitle) }>
																#6162734</h4>
															<div className={ css(this.styles.productTitleSection) }>
																<strong
																	className={ css(this.styles.salePrice) }>${ price }</strong>
																<h4 className={ css(this.styles.productTitle) }>{ title }</h4>
															</div>
														</div>
													
													</div>
												</div>
											</div>
										
										)
									}
									/** 4 columns **/
									if (parseInt(this.props.data.columns) === 4) {
										return (
											
											<div key={index}
													 className={ css(this.styles.productBox) + " col-xs-12 col-sm-12 col-md-3" }>
												<div className={ css(this.styles.productBoxWrapper) }>
													<div className={ css(this.styles.imageContainer) }>
														<img
															src={ clientImage(product.images[0], {
																width: '263', height: '300', crop: 'fit'
															}) }
															alt={ title }
															className={ css(this.styles.productImg) }/>
														<div
															className={ css(this.styles.imageOverlay) + " imgOverlay" }>
															<a href={ generateLink(url) }
																 className={ css(this.styles.productButtons) }>Quick
																Buy</a>
															<a href={ generateLink(url) }
																 className={ css(this.styles.productButtons) }>Add to
																Cart</a>
														</div>
													</div>
													
													<div className={ css(this.styles.productDescriptionWrapper) }>
														<div className={ css(this.styles.productDescription) }>
															<h4 className={ css(this.styles.subTitle) }>
																#6162734</h4>
															<div className={ css(this.styles.productTitleSection) }>
																<strong
																	className={ css(this.styles.salePrice) }>${ price }</strong>
																<h4 className={ css(this.styles.productTitle) }>{ title }</h4>
															</div>
														</div>
													
													</div>
												</div>
											</div>
										
										)
									}
									/** 6 columns **/
									if (parseInt(this.props.data.columns) === 6) {
										return (
											
											<div key={index}
													 className={ css(this.styles.productBox) + "col-xs-12 col-sm-4  col-sm-2" }>
												<div className={ css(this.styles.productBoxWrapper) }>
													<div className={ css(this.styles.imageContainer) }>
														<img
															src={ clientImage(product.images[0], {
																width: '165', height: '225', crop: 'fit'
															}) }
															alt={ title }
															className={ css(this.styles.productImg) }/>
														<div
															className={ css(this.styles.imageOverlay) + " imgOverlay" }>
															<a href={ generateLink(url) }
																 className={ css(this.styles.productButtons) }>Quick
																Buy</a>
															<a href={ generateLink(url) }
																 className={ css(this.styles.productButtons) }>Add to
																Cart</a>
														</div>
													</div>
													
													<div className={ css(this.styles.productDescriptionWrapper) }>
														<div className={ css(this.styles.productDescription) }>
															<h4 className={ css(this.styles.subTitle) }>
																#6162734</h4>
															<div className={ css(this.styles.productTitleSection) }>
																<strong
																	className={ css(this.styles.salePrice) }>${ price }</strong>
																<h4 className={ css(this.styles.productTitle) }>{ title }</h4>
															</div>
														</div>
													
													</div>
												</div>
											</div>
										
										)
									}
									/** 2 columns **/
									else {
										let image = null
										if (index > this.props.data.rows * 2) {
											image = null
										}
										
										return (
											
											<div key={index}
													 className={ `${css(this.styles.productBox)} col-xs-12 col-sm-12 col-md-6` }>
												{/* quickViewModal */}
												
												<div className={ css(this.styles.productBoxWrapper) }>
													<a href={ this.props.url }>
														<div className={ css(this.styles.imageContainer) }>
															<img
																src={  clientImage(product.images[0], {
																	width: '555',
																	height: '300',
																	crop: 'fit'
																}) }
																alt={ this.props.title }
																className={ css(this.styles.productImg) }/>
															<div onClick={ (e) => {
																this.preventLink(e)
															} }
																	 className={ `${css(this.styles.imageOverlay)} imgOverlay` }>
																<a onClick={noop} href={ generateLink(url) }
																	 className={ css(this.styles.productButtons) }>Quick
																	Buy</a>
																<a onClick={noop} href={ generateLink(url) }
																	 className={ css(this.styles.productButtons) }>Add
																	to
																	Cart</a>
															</div>
															<div onClick={ (e) => this.displayQuickViewModal(e) }
																	 className={ css(this.styles.quickViewSVGContainer) }>
																
																<svg version="1.1"
																		 className={ css(this.styles.quickViewSVG) }
																		 xmlns="http://www.w3.org/2000/svg"
																		 viewBox="0 0 442.04 442.04">
																	<g>
																		<g>
																			<path d="M221.02,341.304c-49.708,0-103.206-19.44-154.71-56.22C27.808,257.59,4.044,230.351,3.051,229.203
			c-4.068-4.697-4.068-11.669,0-16.367c0.993-1.146,24.756-28.387,63.259-55.881c51.505-36.777,105.003-56.219,154.71-56.219
			c49.708,0,103.207,19.441,154.71,56.219c38.502,27.494,62.266,54.734,63.259,55.881c4.068,4.697,4.068,11.669,0,16.367
			c-0.993,1.146-24.756,28.387-63.259,55.881C324.227,321.863,270.729,341.304,221.02,341.304z M29.638,221.021
			c9.61,9.799,27.747,27.03,51.694,44.071c32.83,23.361,83.714,51.212,139.688,51.212s106.859-27.851,139.688-51.212
			c23.944-17.038,42.082-34.271,51.694-44.071c-9.609-9.799-27.747-27.03-51.694-44.071
			c-32.829-23.362-83.714-51.212-139.688-51.212s-106.858,27.85-139.688,51.212C57.388,193.988,39.25,211.219,29.638,221.021z"/>
																		</g>
																		<g>
																			<path d="M221.02,298.521c-42.734,0-77.5-34.767-77.5-77.5c0-42.733,34.766-77.5,77.5-77.5c18.794,0,36.924,6.814,51.048,19.188
			c5.193,4.549,5.715,12.446,1.166,17.639c-4.549,5.193-12.447,5.714-17.639,1.166c-9.564-8.379-21.844-12.993-34.576-12.993
			c-28.949,0-52.5,23.552-52.5,52.5s23.551,52.5,52.5,52.5c28.95,0,52.5-23.552,52.5-52.5c0-6.903,5.597-12.5,12.5-12.5
			s12.5,5.597,12.5,12.5C298.521,263.754,263.754,298.521,221.02,298.521z"/>
																		</g>
																		<g>
																			<path
																				d="M221.02,246.021c-13.785,0-25-11.215-25-25s11.215-25,25-25c13.786,0,25,11.215,25,25S234.806,246.021,221.02,246.021z"/>
																		</g>
																	</g>
																</svg>
															
															</div>
														</div>
														<div
															className={ css(this.styles.productDescriptionWrapper) }>
															<div className={ css(this.styles.productDescription) }>
																<h4 className={ css(this.styles.subTitle) }>{ product.subtitle }</h4>
																<div
																	className={ css(this.styles.productTitleSection) }>
																	{/** Price **/}
																	<div
																		className={ css(this.styles.priceContainer) }>
																		<strong
																			className={ css(this.styles.salePrice) }>${ price }</strong>
																	</div>
																	
																	<h3 className={ css(this.styles.productTitle) }>{ product.title }</h3>
																</div>
															</div>
														</div>
													</a>
												</div>
											</div>
										)
									}
									
								}) }
							</div>
						</div>
					</div>
				</div>
			</div>
		
		
		)
	}
}

ProductGrid03.contextTypes = {
	store: PropTypes.object
};

ProductGrid03.displayName = 'ProductGrid03';

export default frontloadConnect(frontload, options)(ProductGrid03);
