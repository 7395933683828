import React from 'react';
import Component from 'source/rootComponent';
import { css } from '@convertly/css';
import { id } from '@convertly/thor';

// Utils

// Components
import SearchBarWrapperInferno from '../../scripts/search-bar/SearchBarWrapper';

// Styles
import searchBarStyles from './searchBarStyles';

// Scripts

export class SearchBar extends Component {

  render() {
    const { data, global, inEditor } = this.props;

    this.styles = searchBarStyles({ global, data, inEditor });

    return (
      <div id={ 
        id(
          SearchBarWrapperInferno, 
          JSON.stringify(data), 
          JSON.stringify(global), 
          JSON.stringify(inEditor)
        ) }
          className={ css(this.styles.searchBar) }/>
    )
  }

}