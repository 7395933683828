/*global Inferno, $ */
import {include} from '@convertly/thor';

const Script = (id) => {

    const SpinnerComponent = include ( './spinner', 'SpinnerComponent' );
    const IsIOSScript = include('../../utils/is-ios', 'IsIOSScript');

    return (id) => {

        const isIOS = IsIOSScript();

        const root = document.getElementById(id);

        const Spinner = SpinnerComponent ( '50px' );

        const ios = isIOS();
        
        const PageInsights = Inferno.createClass({

            getInitialState: function() {
                return {
                    urlQuery: '',
                    processing: false
                };
            },

            inputHandler: function(e) {
                this.setState({
                    urlQuery: e.target.value
                })
            },

            openGeneratedLink: function(e) {
                e.preventDefault();

                this.setState({
                    urlQuery: this.state.urlQuery,
                    processing: true,
                    showModal: false,

                });



                    
                let that = this;
                setTimeout(function() {

                    that.setState({
                        processing: false,
                        showModal: true
                    });

                }, '1000');
            
            },

            closeLink() {

                this.setState({
                    showModal:false
                })

            },

            getWindowWidth() {
                return window.innerWidth;
            },

            render: function() {

                const rootClass = root.className;

                const spinnerStyles = {
					position: 'absolute',
					transform: 'translate( -50%, -50% )',
					top: '56%',
					left: '50%'
                };

                return (
                    <form onSubmit={ (e) => this.openGeneratedLink(e) }>
                        <input className="url-input" placeholder="Enter your URL to test your page speed." onInput={ (e) => this.inputHandler(e) } required type="text" diabled={ this.state.processing }/>
                        <button className="submit-button" diabled={ this.props.processing }>
                            { this.state.processing ? "Testing Page Speed..." : "Test with Google" }
                            <div style={spinnerStyles}><Spinner display={this.state.processing}/></div>
                        </button>
                        { this.state.showModal &&
                            <div className={`${rootClass} insights-modal`}>
                                <div onClick={ this.closeLink }
                                     className={`${rootClass} insights-modal-close`}>x</div>
                                <iframe width={ this.getWindowWidth() } className={`${rootClass} insights`}
                                        src={`https://developers.google.com/speed/pagespeed/insights/?url=${ this.state.urlQuery}`}/>
                            </div>
                        }
                    </form>
                )
            }

        });

        Inferno.render(<PageInsights />, root)
        
    }


};

export default Script;
