import React from "react";
import Component from "source/rootComponent";
import { css } from "@convertly/css";
import _ from "lodash";

// Style
import { BlogLinkStyles } from "./BlogLinkStyles";

export class BlogLink extends Component {
  render() {
    const { data, inEditor, global, blogData } = this.props,
          inlineStyle = this.generateStyleAttribute(),
          text = _.get(this.props, "data.text") && !_.isEmpty(this.props.data.text)
                ? this.props.data.text
                : "Read More";

    let { slug } = blogData || {};

    if (!_.isString(slug)) {
      slug = _.get(data, "slug");
    }

    this.styles = BlogLinkStyles({ global, data, inEditor });
    const { blogLink } = this.styles;

    return (
      <a
        {...inlineStyle}
        {...this.generateDataEditorAttribute()}
        {...this.generateCustomAttributes()}
        className={css(blogLink)}
        href={inEditor ? "#" : slug || "/"}
      >
        <div>{text || ""}</div>
      </a>
    );
  }
}
