import React from 'react';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import Link from '../../../elements/links/web-link';
import { clientImage } from '../../../utils/generateImage';
import _ from 'lodash';

//Default Data
import defaultData from '../footer-default-data';
import defaultGlobal from '../../../themes/global';

//Styles
import footerStyles from './footer-styles-08';

//Components
import FooterNav1 from '../components/footer-nav-list-1';
import Nav from './components/nav';

//Attributes
import SocialIcons1 from '../../../widgets/social-icons/no-background-social-icons/social-icon-list-1';

//utils
import { generateLink } from '../../../utils/generateLink';

export default class Footer08 extends Component {

    constructor(props) {

        super(props);

    }

    render() {
        const hideConvertlyBranding = _.get( this.props, 'site.hideConvertlyBranding' );

        const inEditor = this.inEditor();

        this.styles = footerStyles(this.props.global, this.props.data, hideConvertlyBranding, inEditor);

        if (this.props.editor) {
            return this.editor();
        }

        const footerNavLinks = this.props.data.footerNavLinks || [];

        const { paragraph } = this.props.data;

        const text = paragraph ? paragraph.text : "";

        const copyRightLinks = this.props.data.copyRightLinks || [];
        const inlineStyle = this.generateStyleAttribute();

        return (

            <footer className={ css(this.styles.footer) + " footer08" }
                onClick={(e) => {
                    this.launchElementalEditor('footer', e);
                }}
                onMouseOver={ this.addEditableOutline }
                onMouseOut={ this.removeEditableOutline }
                { ...inlineStyle }
                { ...this.generateDataEditorAttribute() }
            >

                <div className={ css(this.styles.navArea) }>
                     <Nav global={this.props.global}
                        data={this.props.data}
                        styles={this.styles}
                        page={ this.props.page }
                        site={this.props.site}/> 
                </div>


                {/** Copy right and privacy links **/}
                <div className={ css( this.styles.copyright ) }>
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <h6 className={ css( this.styles.copyrightText ) } >{text}</h6>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <nav>
                                <ul className={ css( this.styles.copyrightNavList ) }>
                                    { copyRightLinks.map( ( link ) =>  {
                                        return(
                                            <li className={ css( this.styles.copyrightNavListItem ) } >
                                                <a className={ css( this.styles.copyrightNavListItemLink ) } href={ generateLink( link.link ) }>{ link.text }</a>
                                            </li>
                                        )
                                    })
                                    }

                                    </ul>
                                </nav>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

Footer08.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
