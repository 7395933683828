import _ from 'lodash';

const getHeaderPosition = ( localData, pageData, inEditor ) => {

    if( inEditor ) {
        return 'static'
    }
	if( ! pageData ) {
		return localData;
	}

    if( pageData.options ) {
        if (pageData.options.header) {
            if (pageData.options.header.headerPositionOverwrite)
                return pageData.options.header.headerPositionOverwrite;
        }
    }
    else {
        return localData;
    }

};

export default getHeaderPosition;
