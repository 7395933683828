import { assignStyleValues } from './assign-style-value';

export function handleFormElements( global ) {

    // Elements to transform
    let elements = [
        'selectInput',
        'textArea',
        'svgDiv',
        'input'];

    //CSS Rules and their defaults
    let cssRules = [
        {
            name: 'backgroundColor',
            default: global.backgroundColors.light.white
        },
        {
            name: 'borderColor',
            default: global.backgroundColors.dark.color1
        }
    ];


    return assignStyleValues(elements, cssRules, global);

}