import { StyleSheet } from "@convertly/css";
import UserOverrides from "../../elements/style-functions/user-overrides";
import removeNestedUserOverrides from "../../site-header/site-header/utils/removeNestedUserOverrides";

export const BannerStyles = ({ global, data, inEditor }) => {
  const banner = removeNestedUserOverrides({ data }),
        { bannerOverlay } = data.userOverrides || {};

  return StyleSheet.create({
    banner: {
      backgroundColor: global.colors.accent.color1,
      ...UserOverrides(global, { userOverrides: banner }, inEditor)
    },

    bannerOverlay: {
      position: "absolute",
      top: "0",
      left: "0",
      height: "100%",
      width: "100%",
      background: "linear-gradient(0deg, rgba(0, 0, 0, .84), rgba(36, 36, 36, .02))",
      ...UserOverrides(global, bannerOverlay, inEditor)
    }
  });
};
