import React from 'react';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';

//scripts
import toggleDropDown from '../../scripts/nav-dropdown-toggle';


//Components
import WebLink from '../../../elements/links/web-link'

export default class
DropdownNavLink05 extends Component {

    constructor(props) {

        super(props);

    }


    render() {

        return (

            <li className={ css(this.props.styles.downNavItem) }>
                <WebLink { ...this.props } className={ css( this.props.styles.dropDownlink ) } />
            </li>
        )
    }
}

