import React from 'react';
import Component from '../../rootComponent';
import {css} from "@convertly/css";


export default class LinkedIn extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (

            <svg version="1.1"
                 className={ css(this.props.className) }
                 xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 1792 1792"
                 width="50"
                 height="50">
                <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z"/>

            </svg>
        )
    }
}
