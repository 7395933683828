import { StyleSheet } from "@convertly/css";
import UserOverrides from '../../../elements/style-functions/user-overrides';


const modalLauncherStyles = ( global, data ) => {

    let closeButtonColor = {};

    if (data.closeButtonColor) {
        closeButtonColor = {
            color: data.closeButtonColor
        }
    }

    const userOverrides = UserOverrides( global, data )

    return StyleSheet.create({

        launcher: {
            fontFamily: global.fontFamily.primaryFont,
            color: global.colors.light.white,
            backgroundColor: global.colors.dark.black,
            fontWeight: '700',
            padding: "20px",
            fontSize: "12px",
            cursor: 'pointer',
            ':hover': {
                opacity: '.6'
            }
        },

        modal: {
            display: 'none',
            position: 'fixed',
            zIndex: '50',
            left: '0',
            top: '0',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            backgroundColor: 'rgba(0,0,0,0.3)',
            '.open': {
                display: 'flex'
            }
        },

        modalContentArea: {
            margin: 'auto',
            backgroundColor: global.colors.light.white,
            zIndex: '2',
            maxWidth: '570px',
            width: '80%',
            textAlign: 'center',
            padding: '0px 0px',
            height: 'fit-content',
            position: "relative",
            overflow: "auto",
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            minHeight: "400px",
            height: '60vh',
            maxHeight: "500px",
            ...userOverrides,
            "@media(max-width: 600px), (max-height: 480px)": {
                height: "100vh",
                width: "100vw",
                maxHeight: "100vh",
                maxWidth: "100%"
            }

        },

        modalHeading: {
        },

        modalParagraph: {
            fontFamily: 'secondaryFont',
            color: 'dark1',
            paddingBottom: '5px'
        },

        closeButton: {
            fontSize: "46px",
            cursor: "pointer",
            position: "absolute",
            right: "15px",
            top: '0px',
            ...closeButtonColor
        },

        newsletterFooter: {
            fontFamily: 'secondaryFont'
        },
        buttonLauncher: {
            cursor: "pointer",
            marginTop: "40px",
            padding: "10px 25px",
            background: "black",
            backgroundColor: "black",
            borderRadius: "0",
            fontFamily: "Rubik",
            ...global.button2,
            ":hover": {
                "opacity": ".7"
            }
        }

        

    });

};

export default modalLauncherStyles;