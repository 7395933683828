import { createGlobalStyle } from "styled-components";

/* eslint no-unused-expressions: 0 */
const GlobalStyle = createGlobalStyle`
  html,
  body {
    background-color: #F5F8FA;
    height: 100%;
    width: 100%;
    margin-top: 0;
    
  }
  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body.fontLoaded {
    font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  
  #app {    
    min-height: 100%;
    min-width: 100%;
  }    
`;
