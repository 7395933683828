import { generateScripts, id } from '@convertly/thor';

export function generateId( id ) {

        const idObj = {
            id: id
        };


    return id ? idObj : {};

}

export default generateId;
