/*global Inferno, $ */
import React from 'react';

const Script = (dispatch, MinusIcon, PlusIcon) => {
	
	return (props) => {
		
		
    const {rootClass, currentQty} = props;
    
		let addToCartClassName = `${ rootClass } quick-buy-button`;
		addToCartClassName += props.disableAddToCartButton ? ' disabled' : '';
		
		return (
			
			<div className={ `${ rootClass } quick-buy-qty-stepper-container` }>
				<button
					onClick={ (e) => {
						e.preventDefault();
						props.addToCart(props.product, true);
					} }
					className={ addToCartClassName }>
					CHECKOUT NOW
				</button>
			</div>
		)
	}
};

export default Script;
