import { connect } from "react-redux";
import { ImageEditor as Component } from "containers/App/modals/ImageEditor2";
import { closeModal } from "../actions/editorActions";
import { saveEditedImage } from "../actions/imageEditor";

const mapStateToProps = state => {
  const Editor = state.get("editor").editorReducer;
  const ImageEditor = state.get("editor").ImageEditor;

  return {
    image: ImageEditor.get("filename"),
    params: ImageEditor.get("params"),
    siteId: Editor.getIn(["currentSite", "siteId"]),
    onSave: ImageEditor.get("cb"),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    closeModal: () => dispatch(closeModal()),
    saveEditedImage: async payload => await dispatch(saveEditedImage(payload)),
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default Container;
