import { createAction } from "redux-act";
import { graph } from "utils";
import _ from "lodash";
import { store } from "app/app";

import { fetchCustomModules } from "containers/Editor/actions/customModules";
import { findType } from "containers/Editor/PageHelpers/common-methods";
import { setProcessing } from "../../actions/navBarActions";
import { getActiveSite } from "services/sites";

export const setFilter = createAction("ADD ELEMENT PANEL: Set current filter");
export const setSearch = createAction("ADD ELEMENT PANEL: Set current search query");
export const setElementCategories = createAction("ADD ELEMENT PANEL: Set element categories");
export const setActiveElement = createAction("ADD ELEMENT PANEL: Set active Element");
export const setPublicModules = createAction("ADD ELEMENT PANEL: Set public modules");
export const setCustomModules = createAction("ADD ELEMENT PANEL: Set custom modules");

export const setLoaded = createAction("ADD ELEMENT PANEL: Set loaded flag");

export const load = elementsThatCanBeAddedToPage => async dispatch => {
  const res = await Promise.all([
    fetchCustomModules(),
    fetchPageElementCategories(),
    fetchPublicModules(),
    dispatch(setProcessing(true)),
  ]);

  const customModules = res[0].map(module => {
    const moduleType = findType(module.elements.el, module.elements, {
      props: { elementsThatCanBeAddedToPage },
    });
    return {
      id: module.id,
      editorType: moduleType,
      label: module.moduleName,
      element: module.elements,
      thumbnail: module.thumbnail,
    };
  });
  const categories = res[1];
  const publicModules = res[2];

  categories.push({
    id: "custom",
    label: "My Elements",
    elements: customModules,
  });

  await dispatch(setLoaded(true));

  await Promise.all([
    dispatch(setElementCategories(categories)),
    dispatch(setPublicModules(publicModules)),
    dispatch(setProcessing(false)),
  ]);
};

/*Gets all public modules*/
export const fetchPublicModules = async () => {
  const query = `query ( $siteId:ID $all:Boolean ) {
  PageModules(all:$all siteId:$siteId) {
    id
    thumbnail
    label:moduleName
    moduleType
    element:elements
  }
  }`;

  const res = await graph({ query });
  const modules = res.PageModules;
  return modules.map(module => {

    if (typeof module.element === "object") return module;
    module.editorType = "section";
    module.element = JSON.parse(module.element);
    return module;
  });
};

export const fetchPageElementCategories = async () => {
  const query = `{
    PageElementCategories {
      id
      label
      elements {
        id
        thumbnail
        type
        sort
        editorType
        label
        element
      }
    }
  }`;

  const res = await graph({ query });

  let categories = _.get(res, "PageElementCategories") || [];

  const site = getActiveSite();

  if (!site.get("blogEnabled")) {
    categories = categories.filter(cat => cat.id !== "blog");
  }

  return categories.map(cat => {

    cat.elements = cat.elements.map(el => {
      el.element = JSON.parse(el.element);

      return el;
    });
    return cat;
  });
};
