/* global PubNub */
//import $ from 'jquery';

const chatWidget = ( id ) => {

    return ( id ) => {

        const actions = {
            SEND_TO_LIVE_AGENT: 'agent',
            AGENT_TYPING: 'typing',
            REQUEST_LIVE_CHAT: 'new-connection',
            SEND_MESSAGE: 'new-message'
        };

        const Ellipsis = () => {

            const $ellipsisContainer = $( '#ellipsis' );
            const $ellipsisChildren  = $ellipsisContainer.children();
            const numberOfCircles    = $ellipsisChildren.length;

            let counter = 0;
            let $circles = $( '.dot' );
            let active = false;
            let interval;
            let timeout;

            const animate = () => {

                counter = counter === numberOfCircles ? 0 : counter;

                $circles.removeClass( 'active' );
                $( $circles[ counter ] ).addClass( 'active' );
                counter++;

            };

            const clearAnimation = () => {

                clearInterval( interval );

                if( active ) {
                    hide();
                }

            };

            const show = () => {

                if( active ) {
                    clearTimeout( timeout );
                } else {
                    active = true;
                    $ellipsisContainer.addClass( 'active' );
                    animate();
                    interval = setInterval( animate, 250 );
                }
                    timeout = setTimeout(clearAnimation, 2000);

            };

            const hide = () => {

                if( active ) {

                    $ellipsisContainer.removeClass( 'active' );
                    active = false;

                }

            };

            return {
                show: show,
                hide: hide
            };

        };

        ( function(){

            /**
             * Set up our reference variables
             */
            const that                 = this; // store for later use

            let chatServiceStarted     = false;
            let context                = null; // used with Watson
            let widgetOpen             = false;
            let agentTyping            = false;
            let talkingToWatson        = true;

            /**
             * Set up our DOM variables
             */
            const $toggleWidgetBtn       = $( '[data-target=chatWidget]' );
            const $body                  = $( 'body' );
            const $theWidget             = $( '#convertlyChatWidget' );

            const $submitButton          = $( '#submitButton' );
            const $customerMessageField  = $( '#customerMessageField' );
            const $messageBoard          = $( '#messageBoard' );
            const messageBoard           = document.getElementById('messageBoard');

            const $clientMessage         = $( '#firstClientMessage' );
            const clientMessageClass     = $clientMessage.attr( 'class' );
            const clientMessageStyle     = clientMessageClass + ' show';

            const $agentMessage          = $( '#firstAgentMessage' );
            const agentMessageStyle      = $agentMessage.attr( 'class' );

            const $messageLink           = $( '#firstMessageLink' );
            const messageLinkStyles      = $messageLink.attr( 'class' );


            const messageBoardContainer  = document.getElementById( 'messageBoardContainer' );
            const $messageBoardContainer = $( '#messageBoardContainer' );

            const $ellipsisContainer     = $( '#ellipsis' );

            /**
             * Init PubNub channels.  These will be set after PubNub Service
             * has started.
             */
            let watsonPublishChannel;
            let watsonResponseChannel;
            let requestChatChannel;
            let clientChannel;


            /**
             * Init function injects PubNub script
             */
            this.init = () => {

                const scriptTag = document.createElement('script');

                scriptTag.type = "text/javascript";
                scriptTag.src = '//cdn.pubnub.com/sdk/javascript/pubnub.4.8.0.min.js';
                scriptTag.async = true;
                $('head').append( scriptTag );

                scriptTag.onload = that.PubNubScriptLoadedHandler;

            };

            /**
             * Starts PubNub Service and sets initial channel values
             */
            this.PubNubScriptLoadedHandler = () => {

                that.PubNub = new PubNub({
                    publish_key: 'pub-c-9b459218-7874-4d83-847b-f4e3dbf2e40c',
                    subscribe_key: 'sub-c-bc2448d2-246f-11e7-bc52-02ee2ddab7fe',
                });

                const ID = that.PubNub.getUUID();

                watsonPublishChannel = 'convertly-watson.' + ID;
                watsonResponseChannel = 'convertly-watson-response-' + ID;

                // requestChannel will eventually change to
                // custom channel based on company ID
                requestChatChannel = 'RequestChat';

                clientChannel = 'convertly-user.' + ID;

                that.ID = ID;

                that.startChat();

            };

            /**
             * Toggle display of chat scripts
             * @param e - Event object
             */
            this.toggleWidget = ( e ) => {

                //let target    = this.dataset.target;
                //let targetDiv = $( '[data-scripts=' + target + ']' );

                e.preventDefault();

                $theWidget.toggleClass( 'open' );

                widgetOpen = ! widgetOpen;

				that.scrollToBottom();

                if( widgetOpen && screen.width < 500) {
                    $body.css({
                        position: 'fixed',
                        maxHeight: '100%',
                        maxWidth: '100%',
                        width:'100%'
                    });
                }
                else {
                    $body.removeAttr( 'style' );
                }

            };

            /**
             * Event Handler for clicking submit button
             * @param e - Event object
             */
            this.submitHandler = ( e ) => {

                e.preventDefault();

                that.sendMessage( $customerMessageField.val() );
                $customerMessageField.val( "" );
                $customerMessageField.focus();

            };

            /**
             * Invokes submitHandler on 'Enter' key
             * @param e - Event object
             */
            this.keyUpHandler = ( e ) => {

                if ( e.keyCode === 13 && ! e.shiftKey ) {
                    e.stopPropagation();
                    that.submitHandler( e );
                }

            };

            /**
             * Prevents 'Enter' key from making a string, so as to not confuse watson
             * @param e - Event object
             */
            this.keyDownHandler =( e ) => {
                if ( e.keyCode === 13 && ! e.shiftKey ) {
                    e.preventDefault();
                }
            };

            /**
             * Subscribe to the Watson channels
             * Set up PubNub message listener
             * Since we are using private channels based on UUID
             * We can set this up before the chat scripts has been opened
             */
            this.startChat = () => {

                that.PubNub.subscribe({
                    channels: [ watsonResponseChannel, watsonPublishChannel ]
                });

                that.PubNub.addListener({
                    message: that.newMessageHandler
                });

            };

            /**
             * Process new message from PubNub
             * @param m
             */
            this.newMessageHandler = ( m ) => {

                let channelName = m.channel; 	// The channel for which the message belongs
                let msg = m.message; 			// The Payload

                context = msg.context;

                // Message came from live agent or Watson
                if ( msg.sender !== "client" ) {

                	if( msg.action === actions.AGENT_TYPING ) {
                        that.ellipsis.show();
						that.scrollToBottom();
                        return;
                    }

                    that.ellipsis.hide();
					that.addMessageToMessageBoard(agentMessageStyle, msg.text);
					that.scrollToBottom();

					if (msg.action === actions.SEND_TO_LIVE_AGENT) {
						that.requestLiveChat()
					}

                }

                // Message is message we just sent
                else {

                    that.addMessageToMessageBoard( clientMessageStyle, msg.text );

                    if( talkingToWatson ) {
                        that.ellipsis.show();
                    }

                    that.scrollToBottom();

                }

            };

            /**
             * Init our Ellipsis functionality
             * @type {Ellipsis}
             */
            this.ellipsis = Ellipsis();

            /**
             * Add new message to the DOM
             * @param className - The class assigned to message div
             * @param message - The message text
             */
            this.addMessageToMessageBoard = ( className, message ) => {

                let newMessage = document.createElement( 'div' );

                $( newMessage ).addClass( className );
                $( newMessage ).append( message );

                let $messageLink = $( newMessage ).find( 'a' );
                $messageLink.addClass( messageLinkStyles );

                $ellipsisContainer.before( $( newMessage ) );

            };

            /**
             * Wrapper function to send messages
             * @param payload
             */
            this.sendPubNubMessage = ( payload ) => {

                that.PubNub.publish( payload );

            };

            /**
             * Prep and send message
             * @param msg - text to send
             */
            this.sendMessage = ( msg ) => {

                const payload = {
                    text: msg,
                    ID: that.ID,
                    sender: 'client',
                    action: actions.SEND_MESSAGE
                };

                if( context !== null && talkingToWatson ) {
                    payload.context = context;
                }

                const publish = {
                    channel: talkingToWatson ? watsonPublishChannel : clientChannel,
                    message: payload
                };

                that.sendPubNubMessage( publish );

            };

            /**
             * Switch PubNub channel subscriptions
             * Send off requestChat message to network
             */
            this.requestLiveChat = () => {

                that.PubNub.unsubscribe({
                    channels: [ watsonResponseChannel, watsonPublishChannel ]
                });

                that.PubNub.subscribe({
                    channels: [ clientChannel ]
                });

                const publishPayload = {
                    channel: requestChatChannel,
                    message: {
                        text: clientChannel,
                        ID: that.ID,
                        action: actions.REQUEST_LIVE_CHAT,
                        channel: clientChannel
                    }
                };

                talkingToWatson = false;

                that.sendPubNubMessage( publishPayload );

            };

            /**
             * Scroll message board to bottom
             */
            this.scrollToBottom = () => {
                let scrollHeight = messageBoard.scrollHeight;
                $messageBoard.scrollTop( scrollHeight );
            };

            /**
             * Set up our event handlers
             */
            $toggleWidgetBtn.on( 'click', that.toggleWidget );
            $submitButton.on( 'click', that.submitHandler );

            $customerMessageField.on( 'keydown', that.keyDownHandler );
            $customerMessageField.on( 'keyup', that.keyUpHandler );

            /**
             * And we're off!
             */
            this.init();

        } )();

    };

};


export default chatWidget;
