import React from 'react';
import Component from 'source/rootComponent';
import { css } from '@convertly/css';
import _ from 'lodash';

// Styles
import FooterLinksStyles from './FooterLinksStyles';

// Components
import Elements from 'source/elements/index';

export class FooterLinks extends Component {
  render() {  

    const { global, data, id, sort } = this.props;
    const { navLinks } = data || {};
    const navLinksIsArrayAndHasLinks = Array.isArray(navLinks) || navLinks.length > 0;

    if(!navLinksIsArrayAndHasLinks) {
      return null;
    }

    const inEditor = this.inEditor();
    this.styles = FooterLinksStyles({ global, data, inEditor })
    const { footerLink, footerLinksWrapper } = this.styles;
    return (
      <ul className={ css(footerLinksWrapper) }>

        {  
          navLinks.map((item, index) => {
            return (
              <li key={ `footer-link-${ index }` } className={ css(footerLink) }>
                <Elements.WebLink
                    data={ item }
                    moduleId={ this.props.id }
                    moduleSort={ this.props.sort }
                />
              </li>
            )
          })
        }

      </ul>
    )
  }
}