/*
 * These functions are used by Components AND Pegasus Renderer
 * Fully test both when modifying
 */

export function buildProductPricingObject(product) {
	
	let price;
	let currency;
	const {pricing} = product;
	if (product.price) {
		price = product.price;
	} else if (pricing) {
		price = pricing.price;
	}
	
	if (product.currency) {
		currency = product.currency;
	} else if (pricing) {
		currency = pricing.currency;
	} else {
		currency = "";
	}
	
	return {
		price: price,
		salePrice: isNaN(product.salePrice) ? null : product.salePrice,
		onSale: product.onSale,
		discount: calculateDiscountText(product),
		currency: currency
	};
}

export function calculateDiscountText(product) {
	
	if (!product.onSale) {
		return "";
	}
	
	switch (product.discountType) {
		case '$':
			return product.discountType + product.discountValue;
			break;
		
		case '%':
			return product.discountValue + product.discountType;
			break;
	}
	
	return '';
}
