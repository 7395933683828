import React from "react";
import PropTypes from "prop-types";
import {DragSource, DropTarget} from "react-dnd";
import {findDOMNode} from "react-dom";
import {DraggableListItem} from './DraggableListItem'
import {isEqual} from "underscore";

export class DraggableListWidget extends React.Component {
  constructor(props) {
    super(props);
    this.updateSortOrder = this.updateSortOrder.bind(this);

    this.state = {
      list: this.props.list
    }

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!isEqual(prevProps.list, this.props.list)) {
      this.setState({list: this.props.list})
    }
  }

  updateSortOrder(draggingItemIndex, hoveredItemIndex) {
    const {list} = this.state;
    const temp = list[hoveredItemIndex];
    const temp2 = list[draggingItemIndex];

    this.setState((prevState) => {
        let newList = prevState.list;
        newList[hoveredItemIndex] = temp2;
        newList[draggingItemIndex] = temp
        return {list: newList};
      },
      this.props.updateSortOrder(this.state.list)
    )

  }


  render() {
    const {list} = this.state;

    return (list.map(
        (item, index) => {
          return <DraggableListItem {...this.props}
                                    updateSortOrder={this.updateSortOrder}
                                    key={`${index} + ${item.label}`}
                                    index={index}
                                    label={item.label}
                                    optionsGenerator={this.props.optionsGenerator}
                                    item={item}/>
        }
      )
    );
  }
}


DraggableListWidget.contextTypes = {
  store: PropTypes.object
};

const draggingSourceSpec = {
  beginDrag: (props, monitor, component) => {
    return {
      index: props.index,
      updateSortOrder: component.updateSortOrder
    };
  }
};

const draggingSourceCollector = (connect, monitor) => {
  return {
    isDragging: monitor.isDragging(),
    connectDragSource: connect.dragSource()
  };
};

const draggingSource = DragSource("NAV_LINK", draggingSourceSpec, draggingSourceCollector)(
  DraggableListWidget
);

const draggingTargetSpec = {
  drop: (props, monitor, component) => {
  },
  hover: (props, monitor, component) => {
    const draggingItemIndex = monitor.getItem().index;
    const hoveredItemIndex = props.index;
    if (draggingItemIndex === hoveredItemIndex) {
      return;
    }
    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (draggingItemIndex < hoveredItemIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (draggingItemIndex > hoveredItemIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    monitor.getItem().updateSortOrder(draggingItemIndex, hoveredItemIndex);

    monitor.getItem().index = hoveredItemIndex;
  }
};

const draggingTargetCollector = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget()
  };
};

const draggingTarget = DropTarget("NAV_LINK", draggingTargetSpec, draggingTargetCollector)(
  draggingSource
);

//export const DraggableListWidget = draggingTarget;
