import {StyleSheet} from "@convertly/css";
import Typography from '../../../../elements/style-functions/typography';
import ModuleStyles from '../../../../elements/style-functions/module-global-styles';
import UserOverrides from '../../../../elements/style-functions/user-overrides';
import {generatePseudoMediaQuery} from '../../../../elements/style-functions/pseudo-media-queries';


const screenSize = {
    sm: '@media (min-width: 595px)',
    md: '@media (min-width: 992px)',
    lg: '@media (min-width: 1200px)',
}

const HeaderStyles7 = (global, data, inEditor) => {

    let button = global.button2;

    const moduleStyles = new ModuleStyles(global, data);
    let modifiedColor;
    let overlayBorderStyle = {};

    if (data.position === "overlayFixed") {
        overlayBorderStyle = {
            '.absolute': {
                borderColor: global.backgroundColors.light.white,
                color: global.textColors.light.white,
                stroke: global.textColors.light.white
            }
        };
    }

    if (data.position === "overlay") {

        modifiedColor = global.textColors.light.white

    }
    else {
        modifiedColor = global.textColors.dark.color1
    }

    const navListItemOverrides = (data.menu && data.menu.userOverrides) ? new UserOverrides(global, data.menu) : {};


    const caret = {
        display: "inline-block",
        height: '17px',
        width: '17px',
        cursor: 'pointer',
        boxSizing: 'content-box',
        marginTop: '-6px',
        marginLeft: '5px',
        stroke: 'inherit',
        fill: global.backgroundColors.accent.color1,
        position: 'absolute',
        top: '27px',
        right: '-13px',
        ...generatePseudoMediaQuery('@media( max-width:1201px )', ['largeTablet', 'smallTablet', 'mobile'], {
            top: '34px',
            right: '-17px'
        }, inEditor)


    };

    const navListItem = {
        display: 'inline-block',
        cursor: 'pointer',
        padding: '0px 10px',
        fontSize: '18px',
        stroke: 'inherit',
        position: 'relative',
        ...navListItemOverrides
    };

    const dropdown = {
        float: 'right',
        listStyle: 'none',
        height: 'auto',
        width: 'fit-content',

    };
    const dropdownList = {
        display: 'none',
        position: 'absolute',
        top: '140%',
        right: '-34px',
        width: 'max-content',
        background: global.backgroundColors.accent.color1,
        listStyle: 'none',
        padding: '20px 20px 8px',
        zIndex: '1',
        '.open': {
            display: 'block'
        },
        ...generatePseudoMediaQuery('@media( max-width:1064px )', ['largeTablet', 'smallTablet', 'mobile'], {
            top: '205%',
            right: '3px'
        }, inEditor)

    };


    return StyleSheet.create({
        ".float-right": {
            float: 'right'
        },
        headerTop: {
            borderBottom: `2px solid ${global.colors.dark.color1}`,
            display: 'flex',
        },
        topNavLinks: {
            display: 'flex',
        },
        leftLinks: {
            display: 'flex',
        },
        rightLinks: {
            padding: '0px 15px',
        },
        btnToggle: {
            color: 'red',
        },
        headerNav: {
            display: 'inline-block'
        },
        headerBottom: {
            display: 'flex',
            justifyContent: 'space-between',
            ...generatePseudoMediaQuery('@media (min-width: 595px)', ['smallTablet', 'largeTablet'], {
                top: '205%',
                right: '3px'
            }, inEditor)
        },
        bottomRightLinks: {
            display: 'none'
        },

        menuLauncherIcon: {
            float: 'right',
            marginTop: '20px',
            marginRight: "15px",
            '@media( max-width:1064px )': {
                marginTop: "19px"
            },
            ...generatePseudoMediaQuery('@media( max-width:991px )', ['largeTablet', 'smallTablet', 'mobile'], {
                marginTop: "13px"
            }, inEditor)
        },
        mobileHide: {
            ...generatePseudoMediaQuery('@media(max-width:767px)', ['smallTablet', 'mobile'], {
                display: 'none'
            }, inEditor)
        },
        navLaunch: {
            position: 'relative',
            zIndex: 45,
            paddingLeft: '10px',
            overflow: "visible",
            background: "none",
            border: "none",
            outline: "none",
            display: 'inline-block',
            paddingTop: '10px',
            float: 'left',
            ...generatePseudoMediaQuery('@media (min-width: 767px)', ['largeTablet'], {
                display: 'none'
            }, inEditor),
            ...generatePseudoMediaQuery(false, ['smallTablet', 'mobile'], {
                display: 'block'
            }, inEditor)
        },
        navListItem: {
            color: global.colors.dark.black,
            fontFamily: global.fontFamily.primaryFont,
            fontWeight: '700',
            fontSize: '14px',
            ...navListItem
        },
        navItemContainer: {
            display: "inline-block",
            paddingTop: "3px",
            ...generatePseudoMediaQuery('@media (max-width: 768px)', ['smallTablet', 'mobile'], {
                display: "block"
            }, inEditor)
        },
        iconBar: {
            backgroundColor: global.colors.dark.color1,
            width: "20px",
            height: "3px",
            marginBottom: "3px",
            position: "relative",
            display: "block",
            zIndex: 50,
            transition: 'all 0.3s ease-in-out 0s',

            ".absolute": {
                backgroundColor: global.backgroundColors.light.white
            },
            '.open': {
                ":nth-child(2)": {
                    transform: "rotate(45deg)",
                    top: "7px",
                    background: global.backgroundColors.dark.color1
                },
                ":nth-child(4)": {
                    transform: "rotate(-45deg)",
                    top: "-5px",
                    background: global.backgroundColors.dark.color1
                },
                ":nth-child(3)": {
                    opacity: 0
                }
            },
        },

        dropdown: {
            ...dropdown,
        },

        dropdownList: {
            ...dropdownList,
        },

        caret: {
            ...caret,
        },

        desktopCartInfernoComponent: {
            ":hover ": {
                "@media(hover:hover)": {
                    ".cartDropDown": {
                        display: 'block'
                    },
                    ".casper": {
                        display: 'block'
                    }
                }
            },
            ".casper": {
                position: 'absolute',
                top: '0',
                right: '0',
                height: '60px',
                width: '425px',
                background: 'transparent',
                display: 'none'
            },

            ".headerNavLink": {
                ...navListItem,
                color: '#000'
            },

            ".cartLink": {
                color: global.textColors.accent.color1
            },
            ".cartDropDown": {
                display: 'none',
                cursor: 'default',
                position: 'absolute',
                top: '0',
                right: '-1px',
                width: '425px',
                height: "460px",
                background: 'white',
                zIndex: '50',
                paddingRight: "0",
                border: global.border
            },
            ".cartContainer": {
                height: '350px',
                overflowY: 'auto',
                width: '100%',
                borderBottom: global.border
            },
            ".cartContainerFooter": {
                marginTop: '20px',
                textAlign: 'center'
            },
            ".checkoutLink": {
                display: 'block',
                ...global.button1
            },
            ".viewCartLink": {
                display: 'block',
                color: global.textColors.dark.color1
            },
            ".checkRightPanel": {
                padding: '30px',
                alignItems: "stretch",
                backgroundColor: "transparent",
                color: global.textColors.dark.color1,
                display: "flex",
                flex: "1",
                flexDirection: "row",
                fontSize: "14px",
                justifyContent: "center",
                lineHeight: "20px",
                paddingTop: "30px"
            },
            ".cart-item-container": {
                padding: '15px'
            },
            ".change-message-bg": {
                backgroundColor: '#e3f2fd'
            },
            ".change-message-container": {
                borderTop: `1px solid ${global.backgroundColors.light.color2}`,
                paddingTop: '10px',
                fontFamily: global.fontFamily.secondaryFont
            },
            ".change-message-text": {
                textTransform: 'none',
                fontSize: '14px',
                color: global.backgroundColors.dark.color2
            },
            ".change-message-action": {
                display: 'block'
            },
            ".change-message-link": {
                fontSize: '16px',
                display: 'inline-block',
                margin: "5px 10px 0 0",
                cursor: 'pointer'
            },

            ".chkRightPanelContainer": {
                color: global.textColors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "100%",
                width: "100%"
            },

            ".chkTitle1_mobileTitle1": {
                borderBottom: "2px none #667785",
                color: global.textColors.dark.color1,
                display: "none",
                flex: "0 auto",
                flexDirection: "row",
                fontSize: "32px",
                fontWeight: "400",
                lineHeight: "36px",
                marginBottom: "10px",
                marginTop: "0px",
                paddingBottom: "20px",
                width: "100%"
            },

            ".mobileUnderline_titleUnderline": {
                backgroundColor: global.backgroundColors.dark.color1,
                borderRadius: "5px",
                color: global.textColors.dark.color1,
                display: "none",
                fontSize: "14px",
                height: "3px",
                lineHeight: "20px",
                marginBottom: "20px",
                width: "45%"
            },

            ".chkGridheadercontainer": {
                color: global.textColors.dark.color1,
                display: "flex",
                fontSize: "14px",
                justifyContent: "flex-end",
                lineHeight: "20px",
                marginBottom: "15px"
            },

            ".chkProdgridimgcontainer": {
                alignItems: "flex-start",
                color: global.textColors.dark.color1,
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                justifyContent: "center",
                lineHeight: "20px",
                maxWidth: "18%",
                textAlign: "center",
                width: "20%"
            },

            ".chkGridprice_th": {
                alignItems: "flex-start",
                color: global.textColors.dark.color1,
                display: "flex",
                flex: "0 auto",
                fontFamily: global.fontFamily.secondaryFont,
                fontSize: "11px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                paddingLeft: "0px",
                width: "125px"
            },

            ".chkGridqty_chkUnitprice_th": {
                alignItems: "flex-start",
                color: global.textColors.dark.color1,
                display: "flex",
                flex: "1",
                fontFamily: global.fontFamily.secondaryFont,
                fontSize: "11px",
                justifyContent: "center",
                lineHeight: "20px",
                textAlign: "center"
            },

            ".chkGridsubtotal_th": {
                color: global.textColors.dark.color1,
                display: "flex",
                flex: "1",
                fontFamily: global.fontFamily.secondaryFont,
                fontSize: "11px",
                justifyContent: "flex-end",
                lineHeight: "20px",
                textAlign: "center"
            },

            ".chkProdgrid": {
                color: global.textColors.dark.color1,
                display: "flex",
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "20px",
                position: "relative",
            },

            ".chkProdgridimg": {
                border: "0",
                color: global.textColors.dark.color1,
                display: "inline-block",
                fontSize: "14px",
                height: "60px",
                width: "60px",
                lineHeight: "20px",
                maxWidth: "100%",
                textAlign: "center",
                verticalAlign: "middle",
                marginBottom: '10px'

            },

            ".chkRemovelink": {
                backgroundColor: "transparent",
                color: "#b4bbc3",
                fontSize: "10px",
                lineHeight: "20px",
                maxWidth: "100%",
                textAlign: "center",
                paddingLeft: '7px'
            },

            ".divBlock_2": {
                color: global.textColors.dark.color1,
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                lineHeight: "20px",
                width: "225px"
            },

            ".chkGridprice": {
                alignItems: "flex-start",
                color: global.textColors.dark.color1,
                display: "flex",
                flex: "0 auto",
                fontSize: "12px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                paddingLeft: "0px",
                width: "225px"
            },
            ".subTitle": {
                fontSize: '10px',
                color: global.textColors.dark.color2
            },
            ".chkGridprice_chkGridpricenote": {
                alignItems: "flex-start",
                color: "#118bef",
                display: "flex",
                flex: "0 auto",
                fontSize: "12px",
                justifyContent: "flex-start",
                lineHeight: "16px",
                paddingLeft: "0px",
                width: "auto"
            },

            ".chkGridqty_chkUnitprice_strikethrough": {
                alignItems: "flex-start",
                color: "#667785",
                display: "flex",
                flex: "0 auto",
                fontSize: "12px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                textAlign: "center",
                textDecoration: "line-through"
            },

            ".actualprice_chkGridqty_chkUnitprice": {
                alignItems: "flex-start",
                color: "#118bef",
                display: "flex",
                flex: "1",
                fontSize: "14px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                textAlign: "center"
            },

            ".chkGridqty": {
                alignItems: "flex-start",
                color: "#667785",
                display: "flex",
                flex: "1",
                fontSize: "14px",
                justifyContent: "flex-end",
                lineHeight: "20px",
                textAlign: "center"
            },

            ".counterIndicator": {
                backgroundColor: "transparent",
                color: "#667785",
                fontSize: "14px",
                lineHeight: "20px",
                padding: "4px 9px",
                textAlign: "center",
                textDecoration: "none"
            },

            ".counternumber": {
                border: global.border,
                color: "#667785",
                flex: "0 auto",
                fontSize: "14px",
                lineHeight: "20px",
                padding: "4px 9px 3px",
                textAlign: "center"
            },

            ".chkGridsubtotal": {
                color: global.textColors.dark.color2,
                display: "flex",
                flex: "1",
                fontSize: "14px",
                lineHeight: "20px"
            },

            ".progresscontainer": {
                color: global.textColors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                paddingTop: "10px",
                position: "relative"
            },

            ".truck": {
                border: "0",
                color: global.textColors.dark.color1,
                display: "inline-block",
                fontSize: "14px",
                lineHeight: "20px",
                maxWidth: "55px",
                position: "absolute",
                right: "0px",
                top: "0px",
                verticalAlign: "middle"
            },

            ".progresstext": {
                borderBottomColor: "#000",
                borderBottomWidth: "3px",
                borderStyle: "none",
                color: global.textColors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                paddingBottom: "10px",
                paddingRight: "80px"
            },

            ".progressline_titleUnderline": {
                backgroundColor: global.backgroundColors.dark.color1,
                borderRadius: "5px",
                color: global.textColors.dark.color1,
                fontSize: "14px",
                height: "3px",
                lineHeight: "20px",
                marginBottom: "15px",
                width: "100%"
            },

            ".cartToggleContainer": {
                color: global.textColors.dark.color1,
                display: "none",
                fontSize: "14px",
                lineHeight: "20px"
            },
            ".cartTitleSub": {
                color: global.textColors.dark.color1,
                fontSize: "32px",
                fontWeight: "bold",
                lineHeight: "36px",
                marginBottom: "10px",
                marginTop: "20px"
            },

            ".toggleLinkblock_wInlineBlock": {
                backgroundColor: "transparent",
                color: global.textColors.dark.color1,
                display: "inline-block",
                fontSize: "14px",
                lineHeight: "20px",
                maxWidth: "100%"
            },
            ".toggleCollapsed": {
                border: "0",
                color: global.textColors.dark.color1,
                display: "inline-block",
                fontSize: "14px",
                lineHeight: "20px",
                maxWidth: "100%",
                verticalAlign: "middle"
            }
        },

    });

};

export default HeaderStyles7
