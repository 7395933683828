/*global Inferno, $ */
/* eslint-disable */

import { include } from '@convertly/thor';

const Scripts = () => {

    return ( id, mapsApi, addressString ) => {

        const root = document.getElementById ( id, );

        const GoogleMapsIframe = Inferno.createClass ( {

            getInitialState: function () {
                return {
                    loadVideo: false
                };
            },


            render: function () {

                let mapsApiKey = mapsApi !== "undefined" ? mapsApi : "AIzaSyArBjawFp_rUBj6YYFqNHCTmt1LuNOEhjw"

                return (
                    <iframe
                        className={ root.className + ' map'}
                        src={  "https://www.google.com/maps/embed/v1/place?q=" + addressString +
                        "&zoom=16&key=" +
                        mapsApiKey } />
                )
            }
        } );
        Inferno.render(<GoogleMapsIframe/>, root);
    };

};

export default Scripts;
