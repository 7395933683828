import { generatePseudoMediaQuery } from '../../elements/style-functions/pseudo-media-queries';

export default function StyleObject(global, data, inEditor) {

	
	return {
		".spinner-container": {
			position:'fixed',
			top:0,
			bottom:0,
			left:0,
			right:0,
			background:'rgba(0, 0, 0, .5)',
			blur:'5px',
			zIndex:'999999',
			display:'flex',
			alignContent:'center',
			justifyContent:'center'
		},
		".spinner": {
      alignSelf:'center',
      height: '15%',
      width: '15%',
    },
	}
}
