import {generatePseudoMediaQuery} from "../../../../elements/style-functions/pseudo-media-queries";

export default function StyleObject(global, data, inEditor) {
	
	
	return {
		
		'.sort-by-container': {
			border: `1px solid ${ global.colors.light.color2 }`,
			width: '12%',
			height: '100%',
			verticalAlign: 'top',
			display: 'inline-block',
			'.mobile': {
				width: '50%',
				cursor: 'pointer',
				display: 'none',
				...generatePseudoMediaQuery(
					'@media(max-width: 767px)',
					['smallTablet', 'mobile'],
					{
            height: '36px',
            display: 'inline-block',
			      border: `1px solid ${ global.colors.light.color3 }`,
					},
					inEditor
				),
			}
		},
		
		'.sort-by-button': {
			height: '100%',
			display: 'table',
      width: '100%',
      cursor: 'pointer'
		},
		
		'.sort-by-button-text': {
			display: 'table-cell',
			textAlign: 'center',
			verticalAlign: 'middle',
			fontSize: '12px',
			...generatePseudoMediaQuery(
				'@media(max-width: 767px)',
				['smallTablet', 'mobile'],
				{
					textTransform: 'uppercase',
					fontSize: '13px',
					color: global.colors.dark.color3,
					fontWeight: '500',
				},
				inEditor
			),
		},
		
		'.sort-by-button-text .arrow-down': {
			marginLeft: '10px',
			paddingTop: '2px',
			...generatePseudoMediaQuery(
				'@media(max-width: 767px)', ['smallTablet', 'mobile'],
				{
					display: 'none'
				},
				inEditor
			),
		},
		
		'.sort-by-dropdown-menu': {
			maxHeight: '0px',
			position: 'absolute',
			zIndex: '6',
			width: '30%',
			marginLeft: '-1px',
			transition: 'max-height .3s ease',
			overflow: 'hidden',
			'.active': {
				maxHeight: '250px'
			},
			...generatePseudoMediaQuery(
				'@media(max-width: 767px)',
				['smallTablet', 'mobile'],
				{
					'.active': {
						maxHeight: '250px'
					}
				},
				inEditor
			),
		},
		
		'.sort-by-dropdown-item': {
			border: `1px solid ${ global.colors.light.color2 }`,
			backgroundColor: global.colors.light.white,
			paddingLeft: '18px',
			paddingRight: '18px',
		},
		
		'.sort-by-dropdown-item-title': {
			height: '32%',
			width: '100%',
			paddingBottom: '10px',
			paddingTop: '10px',
			fontSize: '16px',
			fontWeight: '700',
		},
		
		'.sort-by-dropdown-item-options': {
			display: 'table',
			height: '23%',
			width: '100%'
		},
		
		'.sort-by-dropdown-item-left-option': {
			display: 'table-cell',
			verticalAlign: 'middle',
			alignItem: 'center',
			fontSize: '10px',
			textTransform: 'uppercase',
		},
		
		'.sort-by-dropdown-item-right-option': {
			display: 'table-cell',
			verticalAlign: 'middle',
			alignItem: 'center',
			fontSize: '10px',
			textTransform: 'uppercase',
			float: 'right'
		},
		
		'.sort-by-dropdown-item-switch': {
			height: '45%',
			width: '100%',
			marginTop: '10px',
			marginBottom: '10px',
		},
		
		'.sort-by-dropdown-item-switch-checkbox': {
			display: 'none',
		},
		
		'.sort-by-dropdown-item-switch-label': {
			display: 'block',
			overflow: 'hidden',
			cursor: 'pointer',
			background: global.colors.light.color1,
			minHeight: '40px',
			borderRadius: '100px',
			padding: '5px 0 3px',
			border: `1px solid ${ global.colors.light.color2 }`
		},
		
		'.sort-by-dropdown-item-switch-switch': {
			display: 'block',
			width: '50%',
			background: global.colors.dark.color3,
			height: '30px',
			borderRadius: '100px',
			transition: 'all 0.5s ease-in 0s',
			marginLeft: '6px',
			marginRight: '6px',
			marginTop: '-1px',
			".active": {
				background: global.colors.accent.color1
			},
			'.checked': {
				marginLeft: '48%'
			},
		},
		
		'.sort-by-mobile-modal-container': {
			display: 'none',
			transition: 'opacity 400 ms',
			'.active': {
				...generatePseudoMediaQuery(
					'@media(max-width: 767px)',
					['smallTablet', 'mobile'],
					{
						display: 'flex'
					},
					inEditor
				),
			},
			justifyContent: 'center',
			position: 'fixed',
			left: '0px',
			right: '0px',
			top: '0px',
			bottom: '0px',
			zIndex: '6',
      backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5))',
      maxWidth: '100%',
      maxHeight: '100%'
		},
		
		'.sort-by-mobile-modal': {
			width: '95%',
			padding: '10px',
			backgroundColor: global.colors.light.white,
			top: '50%',
			position: 'absolute',
      paddingBottom: '5px',
      transform: 'translateY(-50%)'
		},
		
		'.sort-by-mobile-modal-header': {
			marginBottom: '10px'
		},
		
		'.sort-by-mobile-modal-title': {
			display: 'inline-block',
			fontSize: '24px',
			fontWeight: '700',
			
		},
		
		'.sort-by-mobile-modal-close-button': {
			display: 'inline-block',
			float: 'right',
			textDecoration: 'underline'
		},
		
		'.sort-by-mobile-modal-single-option-wrapper': {
      marginBottom: '5px',
      fontSize: '3vw',
		},
		
		'.sort-by-mobile-modal-single-option-label': {
			paddingLeft: '10px',
		},
		
		'.sort-by-mobile-modal-apply-button-wrapper': {
			margin: '20px',
			display: 'table',
			width: '94.5%'
		},
		
		'.sort-by-mobile-modal-apply-button': {
			display: 'table-cell',
			backgroundColor: global.colors.accent.color3,
			color: global.colors.light.white,
			textAlign: 'center',
			verticalAlign: 'middle',
			height: '50px',
			width: '100%',
			cursor: 'pointer',
		}
		
	}
}
