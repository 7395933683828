import React from 'react';
import Component from '../../rootComponent';
import {css} from '@convertly/css';
import defaultGlobal from '../../themes/global.js';
import {id} from '@convertly/thor';
import { fromJS } from 'immutable';


import generateId from '../../utils/generate-id';

// Styles
import formStyleSheet from './form-styles';

//Inferno
import { InfernoForm } from './inferno-form';

export default class Form extends Component {
 
	constructor(props) {
        super(props);
        
        this.state = {
            formFieldsMeta: []
        };

    }

    /** Renders the fields **/
    renderFormFields = function () {


            let formFieldsMeta = this.state.formFieldsMeta;

            let formFields = formFieldsMeta.map( (formFieldMeta ) => {
                let formWidthClass = this.getFormWidthClass(formFieldMeta.width);
                let formField = this.generateFormField(formFieldMeta);
                let pullUp = formFieldMeta.el === "select" ? 'pullUp' : '';

                return (
                    <div
                        { ...this.generateCustomAttributes() }
                        className={ `${css(this.styles.formInfernoContainer)} fieldContainer ${pullUp} ${formWidthClass}` }>
                        { formField }
                    </div>
                )

            });



        return formFields;

    };

    /** Sorts the form fields so they render in proper order **/
    sortFormFields = function ( props ) {


        let formFieldsMeta = props.data.formElements.sort((a, b) => {
            return (( parseInt(a["sort"]) > parseInt(b["sort"]) ) ? -1 : ((parseInt(a["sort"]) < parseInt(b["sort"]) ) ? 1 : 0));

        });
        this.generateStateObject(formFieldsMeta);

    };

    /** Updates inputs values **/
    updateInputField = function (e, field) {
        const newState = {};
        newState[field] = e.target.value;
        this.setState(newState);
    };

    /** Updates inputs values **/
    updateCheckBox = function (e, field, value) {
        const newState = {};

        newState[`${field}_checkbox`] = !this.state[`${field}_checkbox`];
        newState[field] = newState[`${field}_checkbox`] ? value : "";

        this.setState(newState);
    };

    /** Generates the class name based on width in data **/
    getFormWidthClass = function (elementWidth) {

        let elementWidthClassName;

        switch (elementWidth) {
            case "quarter":
                elementWidthClassName = "quarter";
                break;
            case "half":
                elementWidthClassName = "half";
                break;
            case "third":
                elementWidthClassName = "third";
                break;
            case "full":
                elementWidthClassName = "full";
                break;
            default:
                elementWidthClassName = "full";
                break;
        }


        return elementWidthClassName

    };

    /** Generates form field **/
    generateFormField = function (formFieldMeta) {

        let formField = [];

        switch (formFieldMeta.el) {
            case "input": {
                formFieldMeta.label &&
                formField.push(
                    <label className={ `${css(this.styles.formInfernoContainer)} label` }>
                        { formFieldMeta.label }
                    </label>
                )
            }
                formField.push(
                    <input
                        className={ `${css(this.styles.formInfernoContainer)} input` }
                        onChange={ (e) => this.updateInputField(e, formFieldMeta.name) }
                        type={ formFieldMeta.type || 'text' }
                        name={ formFieldMeta.name }
                        placeholder={ formFieldMeta.placeholder }
                        required={ formFieldMeta.required }
                        aria-required={ formFieldMeta.required }/>
                );

                if (formFieldMeta.required) {
                    formField.push(
                        <p className={ `${css(this.styles.formInfernoContainer)} form-error-message` }>This field is
                            required</p>
                    )
                }
                break;
            case "textArea":
                if (formFieldMeta.label) {
                    formField.push(
                        <label className={ `${css(this.styles.formInfernoContainer)} label` }>
                            { formFieldMeta.label }
                        </label>
                    )
                }
                formField.push(
                    <textArea
                        className={ `${css(this.styles.formInfernoContainer)} textArea` }
                        onChange={ (e) => this.updateInputField(e, formFieldMeta.name) }
                        type={ formFieldMeta.type || 'text' }
                        rows={ formFieldMeta.rows || 4 }
                        name={ formFieldMeta.name }
                        placeholder={ formFieldMeta.placeholder }
                        required={ formFieldMeta.required }
                        aria-required={ formFieldMeta.required }/>
                );

                if (formFieldMeta.required) {
                    formField.push(
                        <p className={ `${css(this.styles.formInfernoContainer)} form-error-message` }>This field is
                            required</p>
                    )
                }
                break;
            case "checkbox": {
                formField.push(
                    <label className={ `${css(this.styles.formInfernoContainer)} checkboxLabel` }>

                        <input
                            className={ `${css(this.styles.formInfernoContainer)} checkbox` }
                            type={ "checkbox" }
                            checked={ this.state[`${formFieldMeta.name}_checkbox`] }
                            name={ formFieldMeta.name }
                            onClick={ (e) => this.updateCheckBox(e, formFieldMeta.name, formFieldMeta.value) }
                            placeholder={ formFieldMeta.placeholder }
                            required={ formFieldMeta.required }/>

                        { formFieldMeta.label }
                    </label>
                );
                if (formFieldMeta.required) {
                    formField.push(
                        <p className={ `${css(this.styles.formInfernoContainer)} form-error-message` }>This field is
                            required</p>
                    )
                }
                break;
            }
            case "radio":
                formField.push(
                    <label className={ `${css(this.styles.formInfernoContainer)} radioLabel` }
                           onChange={ (e) => {
                               this.updateInputField(e, formFieldMeta.name)
                           } }>

                        <input
                            className={ `${css(this.styles.formInfernoContainer)} radio` }
                            type={ "radio" }
                            name={ formFieldMeta.name }
                            placeholder={ formFieldMeta.placeholder }
                            onChange={ (e) => {
                                this.updateInputField(e, formFieldMeta.name)
                            } }
                            required={ formFieldMeta.required }/>

                        { formFieldMeta.label }

                    </label>);

                if (formFieldMeta.required) {
                    formField.push(
                        <p className={ `${css(this.styles.formInfernoContainer)} form-error-message` }>This field is
                            required</p>
                    )
                }
                break;
            case "select": {
                {
                    formFieldMeta.label &&
                    formField.push(
                        <label className={ `${css(this.styles.formInfernoContainer)} label` }>
                            { formFieldMeta.label }
                        </label>
                    )
                }
                formField.push(
                    <div className={ `${css(this.styles.formInfernoContainer)} selectContainer` }>
                        { /** Arrow Down SVG **/ }
                        <svg version="1.1"
                             className={ `${css(this.styles.formInfernoContainer)} caretDown` }
                             xmlns="http://www.w3.org/2000/svg"
                             width="50"
                             height="50"
                             viewBox="0 0 404.308 404.309">
                            <g>
                                <path d="M0,101.08h404.308L202.151,303.229L0,101.08z"/>
                            </g>
                        </svg>

                        <select className={`${ css(this.styles.formInfernoContainer) } selectInput` }
                                onChange={ (e) => this.updateInputField(e, formFieldMeta.name)}
                        >
                            { formFieldMeta.options.map((option) => {
                                return (
                                    <option value={ option.value }> { option.label } </option>
                                )
                            })
                            }
                        </select>

                    </div>
                );
                if (formFieldMeta.required) {
                    formField.push(
                        <p className={ `${css(this.styles.formInfernoContainer)} form-error-message` }>This field is
                            required</p>
                    )
                }
                break;
            }
        }

        return formField;
    };

    /** Generates object that handles forms state **/
    generateStateObject = function (formFieldsMeta) {

        let tempState = this.state;
        tempState.formFieldsMeta = formFieldsMeta;
        formFieldsMeta.map((metaItem, index) => {

            if (metaItem.el === "checkbox") {
                tempState[`${metaItem.name}_checkbox`] = false
            }

            tempState[metaItem.name] = "";

            if (metaItem.el === "select") {
                tempState[metaItem.name] = metaItem.options[0];
            }

        });
        this.setState(tempState);
    };

    /**
     * Renders initial form fields
     */
    componentDidMount() {
        this.sortFormFields( this.props );
    }
    
    /**
     * updates form fields when changed in the editor
     * @param nextProps
     */
    componentWillReceiveProps(nextProps) {
        const next = fromJS( nextProps.data );
        const now = fromJS( this.props.data );
        const equals = next.equals( now );

        if( ! equals ) {
            this.sortFormFields( nextProps );
        }
    }


    render() {

        let inEditor = this.inEditor();

        const events = this.props.data.gaEvent || [];

        this.styles = formStyleSheet(this.props.global, this.props.data, inEditor);

        const inlineStyle = this.generateStyleAttribute();
        const {animated} = this.props.data;

        let className = css(this.styles.form);

        if (this.animatedClassName) {
            className += " " + this.animatedClassName;
        }

        function renderChildren(props) {
            return React.Children.map(props.children, child => {
                return React.cloneElement(child, {
                    moduleId: props.moduleId,
                    moduleSort: props.moduleSort,
                    styles: props.styles,
                    sliderSettings: props.sliderSettings ? props.sliderSettings : null
                });
            })
        }

        let data = JSON.stringify(this.props.data.formElements);

        let formFields = this.renderFormFields();

        let renderedTempState = {};

        this.state.formFieldsMeta.map((metaItem) => {

            renderedTempState[metaItem.name] = this.state[metaItem.name]

        });
        
        return (

            <div { ...this.generateDataEditorAttribute() }
                 {...generateId(this.props.elementId)}
                 { ...inlineStyle }
                 data-animated={animated}
                 className={ className }>

                <div className={ css(this.styles.formTitle) }>
                    { renderChildren(this.props) }
                </div>
                <div id={ id(InfernoForm,
                    data,
                    this.props.data.formId,
                    this.props.data.submitText,
                    this.props.data.confirmationTitle,
                    this.props.data.confirmationMessage,
                    JSON.stringify(events),
                    this.props.data.formName,
                    !!this.props.data.recaptchaEnabled
                    ) }
                     className={ css(this.styles.formInfernoContainer) }>
                    { inEditor &&
                    <form>
                        { formFields }

                        {!this.props.data.themeEditor && <input
                            className={`${css(this.styles.formInfernoContainer)} submitButton`}
                            type="submit"
                            value={this.props.data.submitText}/>
                        }
                    </form>}
                </div>

            </div>

        )
    }
};


Form.defaultProps = {
    global: defaultGlobal,
    data: {}
};
