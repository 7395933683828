import React from 'react';
import Component from '../../rootComponent';
import {css} from "@convertly/css";
import {sortFooterElements} from '../footer-utils/sort-footer-elements';
import _ from 'lodash';

//Styles
import footerStyles from './footer-styles-01';


//Components
import Elements from '../../elements/index';


export class Footer01 extends Component {

    constructor(props) {

        super(props);

    }

    render() {

        const {page, site, data, global} = this.props;

        const inEditor = this.inEditor();

        const hideConvertlyBranding = _.get(this.props, 'site.hideConvertlyBranding');

        this.styles = footerStyles(global, data, hideConvertlyBranding, inEditor);

        /** Get displayed elements and assign them to
         * correct locations in footer
         **/
        let logo, copyText, footerNavLinks = null;

        if (data.showLogo) {
            logo =
                <div>
                    {site.logo &&
                    <Elements.Image
                        parentFigureClassName={ this.styles.logo }
                        parentImgClassName={ this.styles.logo }
                        data={ {
                            ...site.logo,
                            userOverrides: data.logoUserOverrides
                        } }
                        global={ global }
                        elementDataName="logo"
                        moduleId={ this.props.id }
                        moduleSort={ this.props.sort }/>}
                </div>
        }

        if (data.showCopyText) {
            copyText = <p className={ css(this.styles.copyText) }>
                { data.copyText.text }
            </p>
        }

        if (data.showFooterNav) {
            footerNavLinks =
                <ul className={ css(this.styles.footerNavLinks) }>
                    { data.showTerms &&
                    <li key={ 'terms' } className={ css(this.styles.copyRightLinks) }>
                        <a href="/terms">Terms and Conditions</a>
                    </li>
                    }
                    { data.showPrivacy &&
                    <li key={ 'privacy' } className={ css(this.styles.copyRightLinks) }>
                        <a href="/privacy">Privacy Policy</a>
                    </li>
                    }
                    {  //Maps through any other nav links and renders them.
                        _.get(data, 'additionalFooterLinks') &&
                        data.additionalFooterLinks.map((item, index) => {
                            return (
                                <li key={ index } className={ css(this.styles.copyRightLinks) }>
                                    <Elements.WebLink

                                        data={ item }
                                        moduleId={ this.props.id }
                                        moduleSort={ this.props.sort }
                                    />
                                </li>
                            )
                        })
                    }
                </ul>
        }

        let elementsArray = sortFooterElements(data.layoutStyle, logo, copyText, footerNavLinks);

        const inlineStyle = this.generateStyleAttribute();

        return (

            <footer className={css(this.styles.mainFooter) }
                    onClick={(e) => {
                        this.launchElementalEditor('footer', e);
                    }}
                    onMouseOver={ this.addEditableOutline }
                    onMouseOut={ this.removeEditableOutline }
                    { ...inlineStyle }
                    { ...this.generateDataEditorAttribute() }>

                {/** Footer Logo **/}
                <div>
                    <div className={ css(this.styles.contentSlot1) }>
                        {elementsArray[0]}

                    </div>
                </div>
                <div className={ css(this.styles.right) }>

                    {/** Copy Text **/}
                    <div className={ css(this.styles.contentSlot2) }>

                        {elementsArray[1]}

                    </div>
                    {/** Privacy Links **/}
                    <div className={ css(this.styles.contentSlot3) }>
                        {elementsArray[2]}
                    </div>
                </div>

            </footer>


        )
    }

}
