import {StyleSheet} from "@convertly/css";
import UserOverrides from '../style-functions/user-overrides';

const divWithImageStyles = (global, data) => {

	const userOverrides = new UserOverrides(global, data);
	
	return StyleSheet.create({
		divWithImage: {
			
			border: "1px solid",
			borderColor: global.colors.light.color3,
			...userOverrides
			
		}, 
		imageDiv: {
			position: "relative",
			border: "2px solid",
			backgroundRepeat: "no-repeat",			
			backgroundSize: "100% 100%",
			backgroundImage: data.backgroundImage,
			height:'200px',
			borderColor: global.colors.light.color3
			 
		},
		 
		textDiv: {	
					
			height: "auto",
			width: "100%",
			padding: "24px 15px",
			backgroundColor: global.colors.light.white,
			bottom: "0",
			borderTop: "2px solid",			
			borderColor: global.colors.light.color3
		}
	})
};

export default divWithImageStyles;
