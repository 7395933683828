import {clientImage} from "../..";
import _ from 'lodash';

export const handleBgImg = (bgImgData, inEditor) => {
    let parsedStyles = {
        '(.webp': {
            ".bg-img": {}
        },
        '(.no-webp': {
            ".bg-img": {}
        },
    };

  if (inEditor) {
    if(typeof bgImgData === "string") {
      return {
        backgroundImage: `${addBGOpacity(bgImgData)}
                        url( ${clientImage(bgImgData)}  )`
      }
    }
    return {
      backgroundImage: `${addBGOpacity(bgImgData)}
                        url( ${clientImage(bgImgData.url)}  )`
    }
  }

    // Checks if bgImgData is a string or is an object with the property url that is a string
    // Also deals with falsey values such as false or an empty string
    if (isExternalLink(bgImgData)) {
        if (typeof bgImgData === "string") {
            return {
                backgroundImage: `${addBGOpacity(bgImgData)}
                            url( ${clientImage(bgImgData)}  )`
            }
        } else if (typeof _.get(bgImgData, 'url') === "string") {
            return {
                backgroundImage: `${addBGOpacity(bgImgData)}
                            url( ${clientImage(bgImgData.url)}  )`
            }
        } else {
            return {
                backgroundImage: addBGOpacity(bgImgData)
            };
        }
    }





    if (bgImgData.params && bgImgData.url) {
        //webp
        parsedStyles['(.webp'] = {
            backgroundImage: `${addBGOpacity(bgImgData)}
						url( ${clientImage(bgImgData.url, {...bgImgData.params, format: 'webp'})} )`

        };
        //no webp
        parsedStyles['(.no-webp'] = {
            backgroundImage: `${addBGOpacity(bgImgData)}
						url( ${clientImage(bgImgData.url, {...bgImgData.params})} )`
        };


    } else if (bgImgData.url) {
        //webp version
        parsedStyles['(.webp'] = {
            backgroundImage: `${addBGOpacity(bgImgData)}
                        url( ${clientImage(bgImgData.url, {format: 'webp', test: 'test'})}  )`
        };
        //non-webp
        parsedStyles['(.no-webp'] = {
            backgroundImage: `${addBGOpacity(bgImgData)}
                            url( ${clientImage(bgImgData.url)}  )`

        };


    } else if (typeof bgImgData === "string") {
        //webp version
        parsedStyles['(.webp'] = {
            backgroundImage: `
						${addBGOpacity(bgImgData)}
						 url( ${clientImage(bgImgData, {format: 'webp'})}  )`

        };
        //non-webp version
        parsedStyles['(.no-webp'] = {
            backgroundImage: `
						${addBGOpacity(bgImgData)}
						 url( ${clientImage(bgImgData)} )`

        };


    }
    return parsedStyles


    // if (bgImgData.params && bgImgData.url) {
    //     //webp
    //     parsedStyles['(.webp'] = {
    //         ".bg-img": {
    //             backgroundImage: `${addBGOpacity(bgImgData)}
    // 					url( ${clientImage(bgImgData.url, {...bgImgData.params, format: 'webp'})} )`
    //         },
    //         ".no-js": {
    //             backgroundImage: `${addBGOpacity(bgImgData)}
    // 					url( ${clientImage(bgImgData.url, {...bgImgData.params, format: 'webp'})} )`
    //         },
    //     };
    //     //no webp
    //     parsedStyles['(.no-webp'] = {
    //         ".bg-img": {
    //             backgroundImage: `${addBGOpacity(bgImgData)}
    // 					url( ${clientImage(bgImgData.url, {...bgImgData.params})} )`
    //         }
    //     };
    //     //no-lazy
    //     parsedStyles['(.no-lazy'] = {
    //         backgroundImage: `${addBGOpacity(bgImgData)}
    // 					url( ${clientImage(bgImgData.url, {...bgImgData.params})} )`
    //     }
    //
    //
    // } else if (bgImgData.url) {
    //     //webp version
    //     parsedStyles['(.webp'] = {
    //         ".bg-img": {
    //             backgroundImage: `${addBGOpacity(bgImgData)}
    //                     url( ${clientImage(bgImgData.url, {format: 'webp', test: 'test'})}  )`
    //         },
    //         ".no-js": {
    //             backgroundImage: `${addBGOpacity(bgImgData)}
    //                     url( ${clientImage(bgImgData.url, {format: 'webp', test: 'test'})}  )`
    //         }
    //     };
    //     //non-webp
    //     parsedStyles['(.no-webp'] = {
    //         ".bg-img": {
    //             backgroundImage: `${addBGOpacity(bgImgData)}
    //                         url( ${clientImage(bgImgData.url)}  )`
    //         },
    //         ".no-js": {
    //             backgroundImage: `${addBGOpacity(bgImgData)}
    //                         url( ${clientImage(bgImgData.url)}  )`
    //         }
    //
    //     };
    //
    //     //no-lazy
    //     parsedStyles['(.no-lazy'] = {
    //         backgroundImage: `${addBGOpacity(bgImgData)}
    // 					url( ${clientImage(bgImgData.url, {...bgImgData.params})} )`
    //     }
    //
    // } else if (typeof bgImgData === "string") {
    //     //webp version
    //     parsedStyles['(.webp'] = {
    //         ".bg-img": {
    //             backgroundImage: `
    // 					${addBGOpacity(bgImgData)}
    // 					 url( ${clientImage(bgImgData, {format: 'webp'})}  )`
    //         },
    //         ".no-js": {
    //             backgroundImage: `
    // 					${addBGOpacity(bgImgData)}
    // 					 url( ${clientImage(bgImgData, {format: 'webp'})}  )`
    //         }
    //     };
    //     //non-webp version
    //     parsedStyles['(.no-webp'] = {
    //         ".bg-img": {
    //             backgroundImage: `
    // 					${addBGOpacity(bgImgData)}
    // 					 url( ${clientImage(bgImgData)} )`
    //         },
    //         ".no-js": {
    //             backgroundImage: `
    // 					${addBGOpacity(bgImgData)}
    // 					 url( ${clientImage(bgImgData)} )`
    //         }
    //     };
    //
    //     //no-lazy
    //     parsedStyles['(.no-lazy'] = {
    //         backgroundImage: `
    // 					${addBGOpacity(bgImgData)}
    // 					 url( ${clientImage(bgImgData)} )`
    //     };
    //
    //     return parsedStyles
    // }

}

//Adds bg gradient to mimic an opacity layer
function addBGOpacity(bgImg) {
    if (bgImg.opacityLayer) {
        return `linear-gradient(to right, ${bgImg.opacityLayer}, ${bgImg.opacityLayer}), `
    }
    return '';
}


function isExternalLink(bgImg) {
    if (!bgImg) return bgImg
    if (typeof bgImg === "string") {
        return bgImg.includes("http")
    }
    if(bgImg.hasOwnProperty('url')) {
        if (typeof bgImg.url === "string") {
            return bgImg?.url.includes("http")
        }
    }
    return bgImg

}
