import React from 'react';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import defaultGlobal from '../../../themes/global.js';
import ReactHTMLParser from "@convertly/react-html-parser";

// Styles
import styles from '../form-styles';


export default class Label extends Component {
	constructor(props){
		super(props);
	}

	render(){
		this.styles = styles(this.props.global, this.props.data, this.inEditor());

	return(
		<label className={css(this.styles.label)}>{this.props.data.text}</label>
		)
	}
};

Label.defaultProps = {
	global: defaultGlobal,
	data: {}
}
