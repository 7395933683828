import React from 'react';
import PropTypes from 'prop-types';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';
import {generateLink} from '../../../utils/generateLink';
import {clientImage} from '../../../utils/generateImage';
import _ from 'lodash';

//Styles
import navStyles04 from './nav-styles-04.js';

//Scripts
import toggleChildrenOpen from '../../scripts/hidden-nav-toggle';
import cartHandler from '../../scripts/cart-script'

//Components
import Elements from 'source/elements/index';
import SVG from 'source/svg/index';
import GoogleMapsLink from 'source/elements/links/google-map-link';
import EmailLink from 'source/elements/links/email-link';
import {NavList04} from './nav-list-04';
import PhoneNumberLink from "source/elements/links/phone-number-link";

export class HeaderNav4 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showHeaderMenu: false,
            toggledClass: ''
        };

        this.toggleHeaderMenu = this.toggleHeaderMenu.bind(this);

    }

    toggleHeaderMenu(e) {
        e.preventDefault();

        let showMobileMenu = this.context.store.getState().get('editor').editorReducer.get('showClientNav');
        this.context.store.dispatch(this.context.store.toggleClientNav(showMobileMenu));
        this.forceUpdate();
    }

    render() {

        const {data, site, global, styles, page} = this.props;

        const inEditor = this.inEditor();

        this.styles = navStyles04(global, data, this.props.headerPosition, inEditor);

        let showMobileMenu;

        if (inEditor && this.context.store) {
            showMobileMenu = this.context.store.getState().get('editor').editorReducer.get('showClientNav');
        }
        const inlineStyle = this.generateStyleAttribute();

        /** Adds cart script **/
        let ecommEnabled = _.get(site, 'ecommerce.enabled');
        const clientImgSrc = clientImage("", {width: '60', height: '60', crop: 'fit'}, site.siteId);
        const clientLinkUrl = generateLink({linkType: "inferno", url: ""});
        let cart = null;

        if (page && page.slug !== "/cart" && ecommEnabled) {

            cart =
                <div href={generateLink({linkType: 'internal', url: '/cart'})}
                     className={css(styles.mobileCartContainer)}>
                    <span id={id(cartHandler, clientLinkUrl, clientImgSrc, false, "cartIcon")}
                          className={css(styles.mobileCart)}
                    >Cart</span>
                </div>

        }

        return (

            <div className={css(this.styles.menuContainer) + " headerNav"}
                 {...this.generateDataEditorAttribute()}
                 onClick={(e) => {
                     this.launchElementalEditor('nav', e);
                 }}
                 onMouseOver={this.addEditableOutline}
                 onMouseOut={this.removeEditableOutline}
                 {...inlineStyle}>

                <div className={css(this.styles.menuLauncherIcon) + this.state.toggledClass}
                     id="menuIcon">
                    {data.showNavigation &&
                    <button
                        id={id(toggleChildrenOpen)}
                        onClick={(e) => {
                            this.toggleHeaderMenu(e)
                        }}
                        data-target="#menu"
                        type="button"
                        className={css(this.styles.navLaunch)}>
                        <span className="sr-only">Toggle navigation</span>
                        <span
                            className={`${css(this.styles.iconBar)} ${showMobileMenu ? 'open' : ''} icon-bar`}
                            data-target="#menu">
                                            </span>
                        <span
                            className={`${css(this.styles.iconBar)} ${showMobileMenu ? 'open' : ''} icon-bar`}
                            data-target="#menu">
                                            </span>
                        <span
                            className={`${css(this.styles.iconBar)} ${showMobileMenu ? 'open' : ''} icon-bar`}
                            data-target="#menu">
                                            </span>
                    </button>
                    }
                </div>

                {/** LOGO */}
                {data.showLogo &&

                <div onClick={(e) => {
                    this.launchElementalEditor('logo', e);
                }}
                     onMouseOver={this.addEditableOutline}
                     onMouseOut={this.removeEditableOutline}
                     {...inlineStyle}>
                    {data.logoLinkToHomePage ?
                        <a href={'/'}
                           onClick={(e) => {
                               this.cancelClick(e)
                           }}
                           className={css(this.styles.logo)}>
                            {data.useTextLogo && _.get(data.textLogo, 'text') ?
                                <Elements.Heading2 data={{...data.textLogo}}>Text Logo
                                    Here</Elements.Heading2>
                                :
                                _.get(site, 'logo.src') ?
                                    <picture>
                                        { /** WEBP **/}
                                        {/*<source srcSet={clientImage(site.logo.src, {format: "webp"})}*/}
                                        {/*        type="image/webp"/>*/}
                                        { /** normal **/}
                                        <source srcSet={clientImage(site.logo.src, data.params)} type="image/jpeg"/>
                                        <img src={clientImage(site.logo.src)}
                                             data-logo
                                             alt={site.logo.alt}
                                             title={site.logo.title}
                                             className={css(this.styles.logoImg) + " logo"}/>
                                    </picture>
                                    : null

                            }
                        </a>
                        :
                        <span className={css(this.styles.logo)}>
                                        {data.useTextLogo && _.get(data.textLogo, 'text') ?
                                            <Elements.Heading2 data={{...data.textLogo}}>Text Logo
                                                Here</Elements.Heading2>
                                            :
                                            _.get(site, 'logo.src') ?
                                                <picture>
                                                    { /** WEBP **/}
                                                    {/*<source srcSet={clientImage(site.logo.src, {format: "webp"})}*/}
                                                    {/*        type="image/webp"/>*/}
                                                    { /** normal **/}
                                                    <source srcSet={clientImage(site.logo.src, data.params)}
                                                            type="image/jpeg"/>
                                                    <img src={clientImage(site.logo.src)}
                                                         data-logo
                                                         alt={site.logo.alt}
                                                         title={site.logo.title}
                                                         data-altimg={clientImage(site.alt_logo.src)}
                                                         className={css(this.styles.logoImg) + " logo"}/>
                                                </picture>
                                                : null
                                        }
                                    </span>}

                </div>

                || <span className={css(styles ? styles.logo : '')}/>

                }

                <div
                    className={css(this.styles.mainMenu) + this.state.toggledClass}>
                    {data.showNavigation &&
                    <NavList04
                        styles={this.styles}
                        site={site}
                        page={page}
                        moduleId={this.props.moduleId}
                        moduleSort={this.props.moduleSort}
                        showMobileMenu={showMobileMenu}
                        global={global}
                        displaySocial={{
                            headerTopper: _.get(data, "headerTopperSettings.display"),
                            socialIcons: _.get(data, "headerTopperSettings.displaySocialIcons")
                        }}
                        socialIconLinkClass={styles.socialIconContainer}
                        data={data.menu}/>
                    }
                </div>

                {/** Cart **/}
                {cart}

            </div>

        )
    }
}

HeaderNav4.contextTypes = {
    store: PropTypes.object
};
