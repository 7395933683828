import { generatePseudoMediaQuery } from "../../elements/style-functions/pseudo-media-queries";

export default function StyleObject (global, data, inEditor) {

  const skeletonLoadingAnimationBackgroundGradient = {
    'background-image': `linear-gradient(90deg, #F4F4F4 0px, rgba(229,229,229,0.1) 0px, ${ global.colors.light.color3 } 80px)`,
    'background-size': '600px'
  };

  const skeletonLoadingAnimationShineLineKeyframe = {
    '100%': { 
      'background-position': '-100% 0%'
    },
    '0%': {
      'background-position': '100% 0%'
    }
  };

  const skeletonLoadingAnimation = {
    ...skeletonLoadingAnimationBackgroundGradient,
    animationName: [skeletonLoadingAnimationShineLineKeyframe],
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'ease-out',
  };

  return {
    '.skeleton-product-filter-container': {
      width: '20%',
      display: 'inline-block',
      ...generatePseudoMediaQuery(
        '@media(max-width: 991px)',
        ['largeTablet', 'smallTablet', 'mobile'], {
          display: 'none'
        },
        inEditor
      ),
    },
    '.skeleton-product-grid-pagination-container': {
      width: '78%',
      float: 'right',
      display: 'inline-block',
      ...generatePseudoMediaQuery(
        '@media(max-width: 991px)',
        ['largeTablet', 'smallTablet', 'mobile'], {
          width: '100%',
        },
        inEditor
      ),
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)',
        ['largeTablet', 'smallTablet', 'mobile'], {
          marginTop: '70px'
        },
        inEditor
      ),
    },
    '.skeleton-product-filter': {
      width: '100%',
      marginBottom: '15px',
      paddingLeft: '20px',
      paddingTop: '25px',
      backgroundColor: global.colors.light.color2
    },
    '.skeleton-product-item': {
      width: '80%',
      height: '15%',
    },
    '.skeleton-product-title': {
      width: '40%',
      height: '15px',
      marginBottom: '10px',
      ...skeletonLoadingAnimation
    },
    '.skeleton-product-description': {
      width: '100%',
      height: '8px',   
      marginBottom: '25px',
      ...skeletonLoadingAnimation
    },
    '.skeleton-pagination-container': {
      width: '100%',
      backgroundColor: global.colors.light.color2,
      paddingLeft: '8px',
      paddingTop: '8px',
      paddingBottom: '8px',
      marginBottom: '15px',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)',
        ['largeTablet', 'smallTablet', 'mobile'],
        {
          display: 'none'
        },
        inEditor
      )
    },
    '.skeleton-pagination-title': {
      width: '8%',
      height: '12px',
      marginBottom: '10px',
      ...skeletonLoadingAnimation
    },
    '.skeleton-pagination-description': {
      width: '21%',
      height: '8px',
      ...skeletonLoadingAnimation
    },
    '.skeleton-product-grid-container': {
      display: 'inline-flex',
      'flex-wrap': 'wrap',
      justifyContent: 'space-between',
    },

    '.skeleton-product-grid-item': {
      display: 'inline-block',
      width: '49%',
      marginBottom: '15px',
      '.col-3-styles': {
        width: '32%',
        ...generatePseudoMediaQuery(
          '@media(max-width: 480px)',
          ['mobile'], {
            width: '100%',
          },
          inEditor
        ),
      },
      '.col-4-styles': {
        width: '24%',
        ...generatePseudoMediaQuery(
          '@media(max-width: 767px)',
          ['mobile'], {
            width: '49%',
          },
          inEditor
        ),
      },
      '.col-6-styles': {
        width: '16%',
        ...generatePseudoMediaQuery(
          '@media(max-width: 991px)',
          ['largeTablet', 'smallTablet', 'mobile'], {
            width: '32%',
          },
          inEditor
        ),
        ...generatePseudoMediaQuery(
          '@media(max-width: 767px)',
          ['mobile'], {
            width: '49%',
          },
          inEditor
        ),
      }
    },

    '.skeleton-product-grid-image': {
      width: '100%',
      height: '275px',
      margin: '0 auto',
      'object-fit': 'cover',
      display: 'block',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)',
        ['smallTablet', 'mobile'],
        {
          height: '175px'
        },
        inEditor
      ),
      ...generatePseudoMediaQuery(
        '@media(max-width: 480px)',
        ['mobile'],
        {
          height: '150px'
        },
        inEditor
      ),
      '.col-3-styles': {
        height: '225px',
        ...generatePseudoMediaQuery(
          '@media(max-width: 767px)',
          ['smallTablet', 'mobile'],
          {
            height: '150px'
          },
          inEditor
        ),
        ...generatePseudoMediaQuery(
          '@media(max-width: 480px)',
          ['mobile'],
          {
            height: '175px'
          },
          inEditor
        ),
      },
      '.col-4-styles': {
        height: '125px',
        ...generatePseudoMediaQuery(
          '@media(max-width: 767px)',
          ['smallTablet', 'mobile'], {
            height: '175px'
          },
          inEditor
        ),
      },
      '.col-6-styles': {
        height: '100px',
        ...generatePseudoMediaQuery(
          '@media(max-width: 991px)',
          ['largeTablet', 'smallTablet', 'mobile'], {
            height: '225px',
          },
          inEditor
        ),
        ...generatePseudoMediaQuery(
          '@media(max-width: 767px)',
          ['smallTablet', 'mobile'],
          {
            height: '175px'
          },
          inEditor
        ),
        ...generatePseudoMediaQuery(
          '@media(max-width: 480px)',
          ['mobile'],
          {
            height: '150px'
          },
          inEditor
        ),
      }
    },

    '.skeleton-product-grid-description-container': {
      height: '100px',
      backgroundColor: global.colors.light.color2,
      paddingTop: '25px',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)',
        ['smallTablet', 'mobile'], {
          height: '75px',
          paddingTop: '20px',
        },
        inEditor
      ),
      ...generatePseudoMediaQuery(
        '@media(max-width: 480px)',
        ['smallTablet', 'mobile'], {
          height: '50px',
          paddingTop: '10px',
        },
        inEditor
      ),
      '.col-3-styles': {
        height: '75px',
        paddingTop: '20px',
        ...generatePseudoMediaQuery(
          '@media(max-width: 767px)',
          ['smallTablet', 'mobile'], {
            height: '50px',
            paddingTop: '10px',
          },
          inEditor
        ),
      },
      '.col-4-styles': {
        height: '50px',
        paddingTop: '10px',
      },
      '.col-6-styles': {
        height: '40px',
        paddingTop: '8px',
        ...generatePseudoMediaQuery(
          '@media(max-width: 991px)',
          ['largeTablet', 'smallTablet', 'mobile'], {
            height: '75px',
            paddingTop: '20px',
          },
          inEditor
        ),
        ...generatePseudoMediaQuery(
          '@media(max-width: 767px)',
          ['smallTablet', 'mobile'], {
            height: '75px',
            paddingTop: '20px',
          },
          inEditor
        ),
        ...generatePseudoMediaQuery(
          '@media(max-width: 480px)',
          ['smallTablet', 'mobile'], {
            height: '50px',
            paddingTop: '10px',
          },
          inEditor
        ),
      }
    },

    '.skeleton-product-grid-description-wrapper': {
      display: 'inline-block',
      width: '80%',
      paddingLeft: '8px',
    },

    '.skeleton-product-grid-title': {
      height: '18px',
      marginBottom: '12px',
      width: '70%',
      ...skeletonLoadingAnimation,
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)',
        ['smallTablet', 'mobile'], {
          height: '14px',
          marginBottom: '8px',
        },
        inEditor
      ),
      ...generatePseudoMediaQuery(
        '@media(max-width: 480px)',
        ['smallTablet', 'mobile'], {
          height: '10px',
        },
        inEditor
      ),
      '.col-3-styles': {
        height: '14px',
        marginBottom: '8px',
        ...generatePseudoMediaQuery(
          '@media(max-width: 767px)',
          ['smallTablet', 'mobile'], {
            height: '10px',
          },
          inEditor
        ),
      },
      '.col-4-styles': {
        height: '10px',
      },
      '.col-6-styles': {
        height: '8px',
        marginBottom: '8px',
        ...generatePseudoMediaQuery(
          '@media(max-width: 991px)',
          ['largeTablet', 'smallTablet', 'mobile'], {
            height: '14px',
            marginBottom: '8px'
          },
          inEditor
        ),
        ...generatePseudoMediaQuery(
          '@media(max-width: 767px)',
          ['smallTablet', 'mobile'], {
            height: '14px',
            marginBottom: '8px',
          },
          inEditor
        ),
        ...generatePseudoMediaQuery(
          '@media(max-width: 480px)',
          ['smallTablet', 'mobile'], {
            height: '10px',
          },
          inEditor
        ),
      }
    },

    '.skeleton-product-grid-description': {
      ...skeletonLoadingAnimation,
      height: '18px',
      width: '35%',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)',
        ['smallTablet', 'mobile'], {
          height: '14px',
        },
        inEditor
      ),
      ...generatePseudoMediaQuery(
        '@media(max-width: 480px)',
        ['smallTablet', 'mobile'], {
          height: '10px',
        },
        inEditor
      ),
      '.col-3-styles': {
        height: '14px',
        marginBottom: '8px',
        ...generatePseudoMediaQuery(
          '@media(max-width: 767px)',
          ['smallTablet', 'mobile'], {
            height: '10px',
          },
          inEditor
        ),
      },
      '.col-4-styles': {
        height: '10px',
      },
      '.col-6-styles': {
        height: '8px',
        ...generatePseudoMediaQuery(
          '@media(max-width: 991px)',
          ['largeTablet', 'smallTablet', 'mobile'], {
            height: '14px',
            marginBottom: '8px'
          },
          inEditor
        ),
        ...generatePseudoMediaQuery(
          '@media(max-width: 767px)',
          ['smallTablet', 'mobile'], {
            height: '14px',
          },
          inEditor
        ),
        ...generatePseudoMediaQuery(
          '@media(max-width: 480px)',
          ['smallTablet', 'mobile'], {
            height: '10px',
          },
          inEditor
        ),
      }
    },

    '.skeleton-product-grid-price-container': {
      display: 'inline-block',
      width: '20%'
    },

    '.skeleton-product-grid-top-price': {
      backgroundColor: global.colors.light.color3,
    },

    '.skeleton-product-grid-bottom-price': {
      backgroundColor: global.colors.light.color3,
    }
  }
}
