import React from 'react';
import Component from 'source/rootComponent';
import {css} from "@convertly/css";

//Default Props
import defaultGlobal from 'source/themes/global';

//Styles
import Styles from './gear-spinner-styles';

//utils
import { clientImage } from 'source/utils/generateImage';



export default class GearSpinner extends Component {

    constructor(props) {

        super(props);

    }

    render() {

        this.styles = Styles(this.props.global, this.props.data);

        if (this.props.editor) {
            return this.editor();
        }

        return (
            <div className={ css( this.styles.gearSpinnerContainer ) } >

                <img src={ clientImage( 'image-convertly-gear-compressor.png' ) }
                     className={ css( this.styles.spinningGear ) } />

            </div>
        )
    }
}


GearSpinner.defaultProps = {
    global: defaultGlobal
};
