import { StyleSheet } from "@convertly/css";
import UserOverrides from '../../../elements/style-functions/user-overrides';
import { generatePseudoMediaQuery } from "../../../elements/style-functions/pseudo-media-queries";


const slideStyles = ( global, data, sliderSettings, inEditor ) => {

    const userOverrides = new UserOverrides( global, data, inEditor );
    let arrowStyles = {};

    if( data.arrowStyles ) {

        arrowStyles = UserOverrides(global, data.arrowStyles, inEditor);
    }
    return StyleSheet.create({

        arrowsHolder: {
            position:'absolute',
            top:'50%',
            width:'100%',
            height:'50px',
            zIndex: "20",
            margin: "0 auto",
            left: '50%',
            transform: 'translateX(-50%)',
            ...generatePseudoMediaQuery(
              '@media (max-width: 767px)',
              ['smallTablet'],
              {
                  width: inEditor ? '768px' : '100vw'
              },
              inEditor
            ),
            ...generatePseudoMediaQuery(
              '@media (max-width: 375px)',
              ['mobile'], {
                width: inEditor ? '375px' : '100vw'
              },
              inEditor
            ),
            ...userOverrides
        },
        arrowRelativeContainer: {
            position:'relative',
            ...generatePseudoMediaQuery(
              "@media(min-width:1600px)",
              ['desktop', 'largeTablet', 'smallTablet', 'mobile'],
              {
                  width: "900px",
                  margin:'0 auto'
              },
              inEditor
            )
        },
        arrowLeft: {
            position:'absolute',
            left:'0',

        },
        arrowRight: {
            position:'absolute',
            right:'0',

        },
        arrow: {
            height:'25px',
            width:'25px',
            cursor:"pointer",
            zIndex: "20",
            fill:global.colors.dark.color3,
            boxSizing: 'content-box',
            ":hover": {
                fill:global.colors.dark.color1,
            },
            ...arrowStyles
        }


    })
};

export default slideStyles;
