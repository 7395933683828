/*global Inferno, $ */

const Script = ( graph, dispatch, GoogleButton, FacebookButton, SignUpForm, siteLink, NotificationBox, cookie ) => {

    const hasLocalStorage = $('html').hasClass('localstorage');


    return Inferno.createClass({

        getInitialState: function () {
            let isSignedIn;

                let token = cookie.read({name:'authToken'});
                isSignedIn = token;


            return {
                billingAddress: {},
                shippingAddress: {},
                shippingInfo: {},
                createdAccount: false,
                isSignedIn: isSignedIn,
                email:this.props.email
            };
        },

        componentWillMount() {
        },

        checkIfUser: function () {


        },

        componentDidMount() {

            dispatch.watch('accessToken', () => {

                this.setState({createdAccount: true})
            });

        },

        render: function () {

            let root = this.props.rootDiv;

            let createAnAccount = this.state.createdAccount;

            let signUpSection = null;

            let googleButton = null;
            let facebookButton = null;

            if (true) {
                googleButton = ( <GoogleButton rootDiv={ this.props.rootDiv } action={"signup"} /> );
            }

            if (true) {
                facebookButton = ( <FacebookButton rootDiv={ this.props.rootDiv } action={"signup"}/> );
            }

            if (!this.state.isSignedIn) {
                signUpSection = <div className={ this.props.rootDiv.className + " account-container" }>
                    {/** Account Sign Up **/ }
                    <div className={ `${this.props.rootDiv.className} create-account ${ createAnAccount ? '' : 'active' }` }>
                        <h3 className={ this.props.rootDiv.className + " chkTitle2" }>
                            Sign up for faster checkout next time.
                        </h3>
                        <div className={ this.props.rootDiv.className + " formContainer_wForm" }>

                            <div className={ this.props.rootDiv.className + " socialButton nudgeRight" }>
                                { facebookButton }
                            </div>

                            <div className={ this.props.rootDiv.className + " socialButton nudgeLeft" }>
                                { googleButton }
                            </div>

                            <div className={ this.props.rootDiv.className + " orContainer" }>
                                <div className={ this.props.rootDiv.className + " orDivider" }/>
                                <div className={ this.props.rootDiv.className + " orSeparator" }>Or</div>
                                <div className={ this.props.rootDiv.className + " orDivider" }/>
                            </div>

                            <SignUpForm baseClass={this.props.rootDiv.className}
                                        action={"signup"}
                                        defaultEmail={ this.state.email }/>

                            <a className={ `${ this.props.rootDiv.className } chkGuestlink_wInlineBlock` }
                               href={ `${siteLink}/` }>Continue Shopping <svg version="1.1"
                                                                              className={ `${root.className} chkGuestarrow` }
                                                                              xmlns="http://www.w3.org/2000/svg"
                                                                              width="50"
                                                                              height="50"
                                                                              viewBox="0 0 268.832 268.832">
                                <g>
                                    <path
                                        d="M265.171,125.577l-80-80c-4.881-4.881-12.797-4.881-17.678,0c-4.882,4.882-4.882,12.796,0,17.678l58.661,58.661H12.5   c-6.903,0-12.5,5.597-12.5,12.5c0,6.902,5.597,12.5,12.5,12.5h213.654l-58.659,58.661c-4.882,4.882-4.882,12.796,0,17.678   c2.44,2.439,5.64,3.661,8.839,3.661s6.398-1.222,8.839-3.661l79.998-80C270.053,138.373,270.053,130.459,265.171,125.577z"
                                        fill="#253039"/>
                                </g>
                            </svg>
                            </a>

                        </div>
                    </div>
                    {/** Create Account Confirmation **/}
                    <div
                        className={ `${this.props.rootDiv.className} account-creation-success ${ createAnAccount ? 'active' : '' }` }>
                        <NotificationBox rootDiv={ this.props.rootDiv } messageType={ "success" } >
                            <h3 className={ this.props.rootDiv.className + " account-creation-title" }>
                                Your account has been created!
                            </h3>

                            <h3 className={ this.props.rootDiv.className + " account-text" }>See your past orders, or update your shipping and account information.</h3>
                            <a href={`${siteLink}/account`} className={ this.props.rootDiv.className + " account-link" }>See
                                Account</a>
                        </NotificationBox>
                        <a className={ `${ this.props.rootDiv.className } chkGuestlink_wInlineBlock` }
                           href={ `${siteLink}/` }>Continue Shopping
                            <svg version="1.1"
                                 className={ `${root.className} chkGuestarrow` }
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="50"
                                 height="50"
                                 viewBox="0 0 268.832 268.832">
                                <g>
                                    <path
                                        d="M265.171,125.577l-80-80c-4.881-4.881-12.797-4.881-17.678,0c-4.882,4.882-4.882,12.796,0,17.678l58.661,58.661H12.5   c-6.903,0-12.5,5.597-12.5,12.5c0,6.902,5.597,12.5,12.5,12.5h213.654l-58.659,58.661c-4.882,4.882-4.882,12.796,0,17.678   c2.44,2.439,5.64,3.661,8.839,3.661s6.398-1.222,8.839-3.661l79.998-80C270.053,138.373,270.053,130.459,265.171,125.577z"
                                        fill="#253039"/>
                                </g>
                            </svg>
                        </a>

                    </div>

                </div>
            }


            return (
                <div className={ this.props.rootDiv.className + " checkoutFlowColumn" }>
                    {/** Order Confirmation **/ }
                    <div>
                        <h2 className={ this.props.rootDiv.className + " chkTitle1" }>Your order has been placed</h2>
                        <div className={ this.props.rootDiv.className + " titleUnderline" }/>
                        <h3 className={ this.props.rootDiv.className + " textBlock" }>
                            An email confirmation has been sent to { this.state.email }
                        </h3>

                        { signUpSection }

                    </div>
                </div>
            )
        }
    });
};

export default Script;
