import {createAction} from "redux-act";
import {Alerts} from "services/Alerts";
import {browserHistory} from "react-router";
import PropTypes from "prop-types";
import {fromJS} from "immutable";
import _ from "lodash";

import constants from "../constants";
import Logger from "utils/logger";
import {LocalStorage} from "utils/localStorage";
import {noop, graph} from "utils";
import {store} from "app/app";
import {
  showAppOverlay,
  showLoadingIcon,
  setAppOverlayIndex,
  setAppOverlayOpacity,
} from "containers/App/actions";
import {savePage} from "./savePage";
import {uploadImages, uploadImage} from "services/sites";
import {fetchUser} from "services/User";
import {purge, cache} from "utils/purge";
import {setSite as setAuthSite} from "containers/Authentication/actions";

export * from "./customModules";
export * from "./siteDataActions";
export * from "./elementsActions";
export {saveTheme, setupSiteTheme, fetchFonts} from "./editTheme";

import CONSTANTS from "containers/Editor/constants";

/**
 * THESE ACTIONS ARE USED IN REDUCER(S)
 */
export const setCommonElements = createAction("EDITOR_SET_COMMON_ELEMENTS");
export const activateModule = createAction("ACTIVATE_MODULE");
export const setPageElements = createAction("SET_PAGE_ELEMENTS");
export const setImageUrls = createAction("SET_IMAGE_URLS");
export const setSiteImages = createAction("EDITOR: Set site images");
export const toggleModal = createAction("Editor: Toggle the current modal");
export const toggleAddNewPageModal = createAction("Editor: Toggle the add new page modal");
export const setOptions = createAction("Editor: Set the current options");
export const setHeaders = createAction("SET_HEADERS");
export const setIsActiveComponent = createAction("SET_IS_ACTIVE_COMPONENT");
export const setOverlay = createAction("SET_OVERLAY");
export const loadSiteTheme = createAction("LOAD_SITE_THEME");
export const setNavigationMenu = createAction("SET_NAVIGATION_MENU");
export const setEditorSaveState = createAction("SET_EDITOR_SAVE_STATE");
export const setPageData = createAction("Editor: Set page data");
export const setSiteData = createAction("Editor: Set the current active site");
export const setPageTemplates = createAction("SET_PAGE_TEMPLATES");
export const createPage = createAction("CREATE_PAGE");
export const setSiteTheme = createAction("SET_SITE_THEME");
export const setSitePages = createAction("SET_SITE_PAGES");
export const setCurrentModal = createAction("SET_CURRENT_MODAL");
export const setPageSettings = createAction("SET_PAGE_SETTINGS");
export const updateSiteSettings = createAction("UPDATE_SITE_SETTINGS");
export const setSiteLoadingStatus = createAction("SET_SITE_LOADING_STATUS");
export const setCustomCSSFlag = createAction(
  "Editor: Toggle the CUSTON_CSS flag. @Pedro - this could use a better description."
);
export const setPageLoadingStatus = createAction("SET_PAGE_LOADING_STATUS");
export const setImageModalSource = createAction("SET_IMAGE_MODAL_SOURCE");
export const setImageGalleryCallback = createAction(
  "Editor: Set the image gallery callback function. This is the function that is executed after the image gallery saves the updated image."
);
export const setEditorPanel = createAction("Editor: SET_EDITOR_TYPE");
export const setLoadingModules = createAction("Editor: SET_LOADING_MODULES");
export const setCustomModules = createAction("Editor: SET_CUSTOM_MODULES");
export const resetState = createAction("Editor: Reset the store");
export const setEditorViewport = createAction("Editor: SET_EDITOR_VIEWPORT");
export const setActiveEditorTab = createAction("Editor: SET_ACTIVE_EDITOR_TAB");
export const setPageLoaded = createAction("Editor: SET_PAGE_LOADED");
export const setActivePage = createAction("Editor: SET_ACTIVE_PAGE");
export const setPageRendered = createAction("Editor: SET_PAGE_RENDERED");
export const setPageMetaData = createAction("Editor: SET_PAGE_META_DATA");
export const setPageSnapshot = createAction("Editor: SET_PAGE_SNAPSHOT");
export const setUnsavedPrompt = createAction("Editor: SET_UNSAVED_PROMPT");
export const setStateWasCancelledFrom = createAction("Editor: SET_STATE_WAS_CANCELLED_FROM");
export const setOverrideLeaveRoute = createAction("Editor: SET_OVERRIDE_LEAVE_ROUTE");
export const toggleClientNavigation = createAction("Editor: TOGGLE_CLIENT_NAVIGATION");
export const setImageToEdit = createAction("Editor: EDITOR_SET_IMAGE_EDITOR_SRC");
export const setPageType = createAction("Editor: EDITOR_SET_PAGE_TYPE");
export const setSocialImage = createAction("Editor: SITE_SETTINGS_SOCIAL_IMAGE");
export const setSocialImageFilename = createAction("Editor: SITE_SETTINGS_SET_SOCIAL_IMAGE");
export const setSocialImagePreview = createAction("Editor: SITE_SETTINGS_SET_SOCIAL_IMAGE_PREVIEW");
export const setSiteThemeData = createAction("Editor: SET_SITE_THEME_DATA");
export const setGoogleFonts = createAction("Editor: SET_GOOGLE_FONTS");
export const setLoadingTheme = createAction("Editor: SET_LOADING_THEME");
export const setSavingTheme = createAction("Editor: SET_SAVING_THEME");
export const toggleEditor = createAction("Editor: Toggle RTE Editor Type");
export const setEditingBlogLayout = createAction("Editor: Set Editing Blog Layout flag");
export const setDraggingNewElement = createAction("Editor: Set dragging new element object");
export const clearEditorPanelAction = createAction("Editor: CLEAR_EDITOR_PANEL_ACTION");
/**
 * THESE ACTIONS ARE USED IN SAGAS
 */
//export const savePage = createAction('SAVE_PAGE');
export const loadImageGallery = createAction("LOAD_IMAGE_GALLERY");
export const setEditingBlogPost = createAction("Editor: Set editing blog post flag");
export const loadPageElements = createAction("LOAD_PAGE_ELEMENTS");
export const loadPageTemplates = createAction("LOAD_PAGE_TEMPLATES");
export const setSettings = createAction("SET_SETTINGS");
export const resetUndoRedo = createAction("RESET_UNDO_REDO");
export const setPublishedPageElements = createAction("EDITOR: Set Published Page Elements");
export const setBlogTitle = createAction("EDITOR: Set Blog Post Title");
export const setBlogContent = createAction("EDITOR:   Set Blog Post Content");
export const setPhotosFromPexelsSearchQuery = createAction("EDITOR: Set stock image search query");
export const setPhotosFromPexels = createAction("EDITOR: Set stock images");
export const setPhotosFromPexelsLoading = createAction("EDITOR: Set stock images is loading");
export const setUserEditingSite = createAction("EDITOR: Set active user editing the site");

/**
 * STANDARD ACTIONS
 */

export const init = () => async dispatch => {
};

export const launchImageGallery = args => async dispatch => {
  const {cb, src} = args;

  await dispatch(setImageGalleryCallback(cb));
  await dispatch(setImageModalSource(src));
  await dispatch(setCurrentModal(CONSTANTS.MODALS.IMAGE_GALLERY));
  await dispatch(setOverlayStyles({zIndex: 2700}));
};

launchImageGallery.propsTypes = {
  cb: PropTypes.func,
  src: PropTypes.string,
};

export const closeImageGallery = () => async dispatch => {
  await dispatch(setOverlayStyles(null));
  await dispatch(closeModal());
};

export function changePageType(type) {
  return async dispatch => {
    dispatch(setPageType(type));
  };
}

export function changeEditor(param) {
  return async dispatch => {
    dispatch(toggleEditor(param));
  };
}

export function updateLogo(src) {
  return async dispatch => {
    const site = store
      .getState()
      .get("auth")
      .get("activeSite");
    const editorSite = store
      .getState()
      .get("editor")
      .editorReducer.get("currentSite");

    try {
      const logo = site.get("logo") || fromJS({});

      const query = `
			mutation( $id:ID!, $site:siteInput ) {
			  editSite( id: $id, site: $site ) {
				  id
			  }
			}
			`;

      const variables = {
        id: site.get("siteId"),
        site: {
          logo: logo.set("src", src).toJS(),
        },
      };

      await graph({query, variables});

      const updatedSite = site.setIn(["logo", "src"], src);
      const updatedEditorSite = editorSite.setIn(["logo", "src"], src);

      await dispatch(setSiteData(updatedEditorSite));
      await dispatch(setAuthSite(updatedSite));
    } catch (e) {
      Logger.info("Error", e.message);
    }
  };
}

export function openImageGallery({
                                   cb = () => {
                                   }, source = ""
                                 }) {
  return async dispatch => {
    await dispatch(setImageGalleryCallback(cb));
    await dispatch(setImageModalSource(source));
    await dispatch(setCurrentModal(CONSTANTS.MODALS.IMAGE_GALLERY));
    await dispatch(setOverlayStyles({zIndex: 2700}));
  };
}

export function closeAppModal() {
  return async dispatch => {
    await dispatch(setOverlayStyles(null));
    await dispatch(closeModal());
  };
}

export function deletePage(page) {
  return async dispatch => {
    const siteId = store.getState().getIn(["auth", "activeSite", "siteId"]);
    const currentPage = store
      .getState()
      .get("editor")
      .editorReducer.get("page");
    const site = store.getState().getIn(["auth", "activeSite"]);

    const query = `
    mutation( $id: ID!, $siteId: ID! ) {
      deletePageAndReturnUpdatedPages(id: $id, siteId: $siteId) {
      id, pages {
        id, slug, name, pageId
      }
      }
    }
    `;

    const variables = {
      id: page.get("id"),
      siteId,
    };

    const response = await graph({query, variables});
    const object = response.deletePageAndReturnUpdatedPages;

    if (currentPage.get("id") === page.get("id")) {
      const homePage = site.get("pages").filter(page => {
        return page.get("slug") === "/";
      });

      const homePageId = homePage.get(0).get("id");
      await dispatch(setActivePage(homePageId));
      await dispatch(loadActivePage());
    }

    await dispatch(setSitePages(object.pages));
  };
}

export const openImageEditor = ({src = "", cb = noop, params = {}}) => async dispatch => {
  return new Promise(async resolve => {
    const callback = (params, src) => {
      cb(params, src);
      resolve(src);
    };

    await Promise.all([
      dispatch(setImageToEdit({filename: src, params, cb: callback})),
      dispatch(setCurrentModal(CONSTANTS.MODALS.IMAGE_EDITOR)),
      dispatch(setOverlayStyles({zIndex: 2700})),
    ]);
  });
};

export function confirmSaveEditorBeforeLeaving(pageId) {
  return async dispatch => {
    dispatch(savePage({publish: false}, true, pageId));
    await dispatch(confirmLeaveEditorWithoutSaving());
  };
}

export function confirmLeaveEditorWithoutSaving() {
  return async dispatch => {
    const route = store
      .getState()
      .get("editor")
      .editorReducer.get("stateToGoToAfterSavePrompt");
    await dispatch(setOverrideLeaveRoute(true));
    await dispatch(setUnsavedPrompt(false));
    browserHistory.push(route.get("pathname"));
  };
}

export function savePageSettings({
                                   updatedPageSettings,
                                   currentPage,
                                   currentSite,
                                   pageBeingEdited,
                                 }) {
  return async dispatch => {
    const editingCurrentPage = currentPage.get("id") === pageBeingEdited.get("id");
    const query = `
      mutation( $id:ID!, $page:pageInput ) {
        editPage( id: $id, page: $page ) {
          id, description, name, pageTitle, socialTitle, socialDescription, socialImage,
          description, pageId, slug, siteId
        }
      }
    `;

    if (updatedPageSettings.options) {
      updatedPageSettings.options = JSON.stringify(updatedPageSettings.options);
    }

    const variables = {
      id: pageBeingEdited.get("id"),
      page: updatedPageSettings,
    };

    const response = await graph({query, variables});

    if (response.error) {
      console.log("errors", response);
      return;
    }

    const updatedPage = response.editPage;

    const pagesWithUpdatedPage = currentSite.get("pages").map(page => {
      if (pageBeingEdited.get("id") === page.get("id")) {
        return updatedPage;
      }
      return page;
    });

    await dispatch(setSitePages(pagesWithUpdatedPage.toJS()));

    if (editingCurrentPage) {
      await dispatch(setPageMetaData(updatedPage));
    }

    await purge(`${currentSite.get("domain")}${pageBeingEdited.get("slug")}`);
    await cache(`${currentSite.get("domain")}${pageBeingEdited.get("slug")}`);
  };
}

export const loadActivePage = () => async dispatch => {
  const productId = store
    .getState()
    .get("editor")
    .SiteData.get("pageType");

  let pageId = store
    .getState()
    .get("editor")
    .editorReducer.get("activePage");

  if (!pageId) {
    pageId = getActivePage();
  }

  LocalStorage.setItem("active-page", pageId);

  await dispatch(setAppOverlayIndex(1000));
  await dispatch(showLoadingIcon(true));
  await dispatch(showAppOverlay(true));

  if (!productId) {
    await dispatch(loadPageData(pageId));
  } else {
    await dispatch(loadProductData(productId));
  }

  await dispatch(setPageRendered(false));
  await dispatch(setPageLoaded(true));
  await dispatch(showLoadingIcon(false));
  await dispatch(showAppOverlay(false));
  await dispatch(setAppOverlayIndex(2000));
};

function getActivePage() {
  const pageId = LocalStorage.getItem("active-page");

  if (pageId && pageId !== "0") {
    return pageId;
  }

  return getSiteHomepage();
}

function getSiteHomepage() {
  const site = store
    .getState()
    .get("auth")
    .get("activeSite");
  let homepageId = 0;

  site.get("pages").map((page, index) => {
    if (page.get("slug") === "/") {
      homepageId = page.get("id");
    }
  });

  return homepageId;
}

export function loadProductData(id) {
  return async dispatch => {
    const query = `
            query($id: ID!){
                getProductElements(id:$id)
            }
        `;
    const variables = {
      id: id,
    };
    const response = await graph({query, variables});

    const Page = {};
    Page.elements = JSON.parse(response.getProductElements);

    await dispatch(setPageData(Page));
    await dispatch(setPageSnapshot(Page));
  };
}

export const loadPageData = pageId => async dispatch => {
  const query = `
			query( $id:ID! ){
				Page( id: $id ) {
					description, elements, name, id, pageTitle, siteId, slug, pageId,
          socialTitle, socialDescription, socialImage, publishedPageElements
				}
			}
			`;

  const variables = {
    id: pageId,
  };

  const response = await graph({query, variables});

  const {Page} = response;

  if (!Page) {
    console.error("Error loading page.");
  }

  Page.elements = JSON.parse(Page.elements);
  const publishedPageElements = JSON.parse(Page.publishedPageElements);

  await Promise.all([
    dispatch(setPublishedPageElements(publishedPageElements)),
    dispatch(setPageData(Page)),
    dispatch(setPageSnapshot(Page)),
  ]);
};

export function setEditorViewportWidth(width) {
  return async dispatch => {
    dispatch(setEditorViewport(width));
  };
}

export const closeModal = () => async dispatch => {
  await dispatch(setCurrentModal(""));
  await dispatch(showAppOverlay(false));
};

export function setEditor({editorType, activeModuleId, isActiveComponent}) {
  return async dispatch => {
    dispatch(setEditorPanel({editorType, activeModuleId, isActiveComponent}));
  };
}

/**
 * Action for launching the Create Page Modal
 */
export const userClickedAddPage = () => async dispatch => {
  await Promise.all([dispatch(setAppOverlayOpacity(0)), dispatch(setAppOverlayIndex(2699))]);

  await Promise.all([
    dispatch(showAppOverlay(true)),
    dispatch(setCurrentModal(constants.MODALS.ADD_PAGE)),
  ]);
};

/**
 * Launches new site modal
 */
export function createSite() {
  return async dispatch => {
  };
}

/**
 *
 * @param pageId
 */
export function setCurrentPage(pageId) {
}

/**
 *
 * @param params
 * @returns {function(*)}
 */
export function loadSiteEditor(params) {
  const sites = params.get("sites").toJS();

  return async dispatch => {
  };
}

/**
 *
 * @param params
 * @returns {function(*)}
 */
export const userClickedSaveButton = (params = {}) => async dispatch => {
  const pageId = store
    .getState()
    .get("editor")
    .SiteData.get("pageType");
  await Promise.all([dispatch(setEditorSaveState(true)), dispatch(savePage(params, true, pageId))]);
};

export const setNavigation = param => async dispatch => await dispatch(setNavigationMenu(param));

export const clearEditorPanel = () => async dispatch => {
  dispatch(clearEditorPanelAction());
};

export function setModules(moduleId) {
  return async dispatch => {
    dispatch(activateModule(moduleId));
  };
}

export function toggleAddModal(sort) {
  return async dispatch => {
    dispatch(toggleModal(sort));
  };
}

/**
 * Toggles the Create Page Modal
 *
 * @returns {function(*)}
 */
export function setToggleAddNewPageModal() {
  return async dispatch => {
    dispatch(toggleAddNewPageModal());
  };
}

export function setActiveComponent(sort) {
  return async dispatch => {
    dispatch(setIsActiveComponent(sort));
  };
}

export function setElements(elements, forceUpdate = false, editorType, action) {
  return async dispatch => {
    dispatch(setPageElements({elements, forceUpdate, editorType, action}));
  };
}

export function setModuleOptions() {
  return async dispatch => {
    const userId = store
      .getState()
      .get("auth")
      .get("user")
      .get("sub");
    const siteId = store
      .getState()
      .get("auth")
      .get("activeSite")
      .get("siteId");
    const query = `query ($siteId: ID, $accountId: ID) {
		  PageModules(siteId: $siteId, accountId: $accountId) {
			id, moduleName, moduleType, elements, theme, thumbnail
		  }
		}
		`;

    const variables = {
      siteId,
      accountId: userId,
    };

    const response = await graph({query, variables});
    const {PageModules} = response;

    dispatch(setOptions(PageModules));
  };
}

export function setHeadersOptions() {
  return async dispatch => {
    const query = `query($siteId:ID,$accountId:ID){PageHeaders(siteId:$siteId,accountId:$accountId)}`;

    const variables = {
      siteId: "",
      accountId: "",
    };

    const response = await graph({query, variables});
    const {PageHeaders} = response;
    dispatch(setHeaders(JSON.parse(PageHeaders)));
  };
}

export function setOverlayStyles(overlayStyles) {
  return async dispatch => {
    dispatch(setOverlay(overlayStyles));
  };
}

export function toggleClientNav(navState) {
  return async dispatch => {
    dispatch(toggleClientNavigation(navState));
  };
}

export const uploadSocialImg = file => async dispatch => {
  console.log("uploadSocialImg", file);
  return await dispatch(uploadImage(file));
};

export function uploadSocialImg_OLD(files) {
  return async dispatch => {
    await dispatch(uploadImages(files, false));
    // await dispatch( setSocialImage( files[0] ) );
    // dispatch( setSocialImageFilename( files[0].name ) );
    // await dispatch( setSocialImagePreview( files[0].preview ) );
  };
}

export const updatePhotosFromPexelsSearchQuery = query => async dispatch => {
  await dispatch(setPhotosFromPexelsSearchQuery(query));
  debouncedFetchPhotosFromPexels(query, dispatch);
};

// Gets images from pexels
async function fetchPhotosFromPexels(searchQuery, dispatch) {
  const query = `
    query($search:String!) {
      getPexelPhotos(search:$search) {
        photos {
          url
          id
          src {
            original
            large
            large2x
            medium
            small
            portrait
            landscape
            tiny
          }
        }
      }
    }
  `;

  const variables = {
    search: searchQuery,
  };

  await dispatch(setPhotosFromPexelsLoading(true));

  const res = await graph({query, variables}, false, true);

  // Renders an alert box if an error message comes back.
  if (_.get(res, "message[0].message")) {
    await Alerts.alert(res.message[0].message, {
      label: "Close",
    });
  } else {
    const stockImages = _.get(res, "data.getPexelPhotos.photos") || [];
    await dispatch(setPhotosFromPexels(fromJS(stockImages)));
  }

  await dispatch(setPhotosFromPexelsLoading(false));
}

// Debounced because we don't want to run the query on every key down
const debouncedFetchPhotosFromPexels = _.debounce(async function (searchQuery, dispatch) {
  await fetchPhotosFromPexels(searchQuery, dispatch);
}, 700);
