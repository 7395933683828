import { StyleSheet } from "@convertly/css";
import ModuleGlobalStyles from '../../../elements/style-functions/module-global-styles';

const productListingStyles = ( global, data ) => {

    const productListingModuleStyles = new ModuleGlobalStyles( global, data );

    const quantityControlButton = {
        width: '30px',
        height: '20px',
        right: 0,
        fill: global.backgroundColors.accent.color2,
        border: 'none',
        position: 'absolute',
        background:'none',
        fillOpacity: '.7',
        fontFamily: global.fontFamily.primaryFont,
        ':focus': {
            outline:'none'
        },
        ':hover': {
            fillOpacity:'1'
        }

    };

   const similarProductDimensions = {
       position:'absolute',
       bottom:0,
       width:'220px',
       height:'220px',
       maxWidth:'220px',
       maxHeight:'220px',
   };

    return StyleSheet.create({
        productListing: {
            fontFamily: global.fontFamily.primaryFont,
            color: global.textColors.dark.color1,
            paddingTop:'70px',
            ...productListingModuleStyles,
        },
        followUpLinksContainer: {
            textAlign: 'center',
            paddingTop: '20px',
            '.mobile':{
                display:'none',
                '@media (max-width:767px)': {
                    display:'block'
                }
            },
            '.desktop': {
                '@media (max-width:767px)': {
                    display:'none'
                }
            }
        },
        followUpLinks: {
            fontFamily: global.fontFamily.primaryFont,
            color: global.textColors.accent.color2,
            fontSize: '1.2em',
            lineHeight: '1.2em',
            margin:'10px',
            ':hover': {
                opacity: '.7'
            },

        },
        productDescriptionContainer: {
            '@media (max-width:767px)': {
                textAlign:'center'
            }
        },

        brandName: {
            fontFamily:global.fontFamily.secondaryFont,
            marginTop:'30px',
            color: global.textColors.dark.color3,
            fontSize: '1.8rem',
            lineHeight: '1.2rem',
        },
        productTitle: {
            marginTop: '20px',
            fontSize: '4em',
            lineHeight: '1.2em',
            '@media (max-width:1032px)': {
                fontSize: '40px',
                lineHeight: '50px'
            },

        },
        productPrice: {
            fontSize: '35px',
            color: global.textColors.accent.color1,
            margin: '30px 0 0 0'
        },
        productOptionSelection: {
            appearance:'none',
            backgroundColor:global.backgroundColors.light.white,
            backgroundImage:'url(https://d393hjtdh3b27x.cloudfront.net/magic-dragons/img/toggle.png)',
            backgroundRepeat:'no-repeat',
            backgroundPositionX:'98%',
            backgroundPositionY:'50%',
            width:'80%',
            border:'2px solid',
            borderColor: global.backgroundColors.dark.color2,
            color: global.backgroundColors.dark.color2,
            borderRadius:'0',
            maxHeight:'4em',
            fontSize:'1.5em',
            padding:'10px',
            outline:global.backgroundColors.accent.color1
        },
        quantityContainer: {
            paddingTop: '40px'
        },
        quantityWrapper: {
            display: 'inline-block',
            paddingLeft: '11px'
        },
        stepperWidget: {
            position: 'relative',
            paddingRight: '20px'
        },
        stepperWidgetSpan: {
            fontSize: '16px',
            color: global.textColors.dark.color1,
            display: 'inline-block',
            verticalAlign: 'middle',
            position: 'relative',
            top:'-3px'
        },
        stepperWidgetQuantityNumber: {
            border: 'medium none',
            boxShadow: 'none',
            clear: 'none',
            color: global.backgroundColors.dark.color1,
            display: 'inline-block',
            float: 'none',
            fontSize: '35px',
            height: 'auto',
            lineHeight: '45px',
            padding: '0 6px 0 6px',
            background: 'none',
            width: '30px',
            verticalAlign: 'middle',
            textAlign: 'center',
            fontFamily:global.fontFamily.primaryFont,
            margin:'0 10px 10px 10px',
            ':focus': {
                outline:'none'
            }
        },
        downArrow: {
            ...quantityControlButton,
            bottom: '17px'
        },
        upArrow: {
            ...quantityControlButton,
            top: '3px'

        },
        arrow: {
            height:'20px',
            width:'20px',
        },
        addToCartButtonContainer: {
            display: 'inline-block',
            paddingLeft:'30px',
            position: 'relative',
            top: '-4px',
            '@media (max-width:991px) and (min-width: 767px)': {
                paddingLeft:'0',
                paddingTop:'10px'
            },
            '@media (max-width:504px)': {
                paddingLeft:'0',
                paddingRight:'30px',
                paddingTop:'10px',
                margin:'0 auto'
            }
        },
        productDescription: {
            color: global.textColors.dark.color2,
            fontSize: '16px',
            lineHeight: '26px',
        },
        share: {
            fontFamily:global.fontFamily.secondaryFont,
            marginTop:'20px',
            fontSize: '16px',
            color: global.textColors.light.color3,
        },
        socialIcons: {
            marginTop:'10px',
            float:'left'
        },
        socialIcon: {
            height: '2em',
            width: '2em',
            cursor: 'pointer',
            fill: global.backgroundColors.dark.color2,
            ':hover': {
                fillOpacity: '.8'
            }
        },
        tabContainer: {
            display: 'block',
            width: '100%',
            margin: '40px 0 0 0',
            padding: '45px 0 100px'
        },
        tabHeaderSection: {
            textAlign: 'center',
            borderBottom: '3px solid',
            borderBottomColor: global.backgroundColors.dark.color2,
            marginBottom: '50px',
            '@media (max-width:767px)': {
                display:'none'
            }
        },
        tabHeaderListItem: {
            color: global.backgroundColors.accent.color2,
            display: 'inline-block',
            fontSize: '20px',
            lineHeight: '20px',
            padding: '0 32px 28px',
            position: 'relative',
            top: '3px',
            borderBottom: '3px solid transparent',
            cursor:'pointer',
            ':hover': {
                borderColor:global.backgroundColors.accent.color2
            },
            '.active': {
                borderColor:global.backgroundColors.accent.color2
            }
        },
        mobileTabHeader: {
            display:'none',
            '@media( max-width:767px )': {
                display:'block',
                background: global.backgroundColors.light.white,
                marginTop: '0',
                marginBottom: '18px',
                fontSize: '20px',
                padding:'10px 0',
                color: global.backgroundColors.accent.color1,
                border: '3px solid ',
                borderColor:global.backgroundColors.accent.color1,
                textAlign: 'center',
                cursor:'pointer'
            },
            '.active': {
                background:global.backgroundColors.accent.color1,
                color: global.textColors.light.white
            }


        },
        tabsBody: {
            padding: '7px 0 0',
            clear: 'left',
            '@media (max-width:767px)': {
                textAlign:'center',
            }
        },
        tab: {
            display:'none',
            '.active': {
              display:'block'
            }
        },
        tabTitle: {
            fontSize: '3em',
            lineHeight: '1em',
            fontFamily: global.fontFamily.secondaryFont
        },
        tabSubTitle: {
            color: global.backgroundColors.dark.color2,
            fontSize: '22px',
            fontWeight: '700',
            lineHeight: '22px',
            padding: '24px 0',
            margin:'10px 0 0'
        },
        tabParagraph: {
            fontSize: '1.3em',
            lineHeight: '34px',
        },
        reviewLink: {
            margin:'25px 0',
            color: global.backgroundColors.accent.color1,
            display: 'inline-block',
            fontSize: '20px',
            lineHeight: '20px',
            transition: 'all 0.3s linear 0s',
            ':hover': {
                opacity:.7
            }
        },
        productDetailContainer: {
            width:'50%',
            display:'inline-block',
            margin:'20px 0'
        },
        productDetailCategory: {
            fontSize: '1.5em',
        },
        productDetail: {
            fontSize: '1.5em',
        },
        similarProductsTab: {
            textAlign:'center'
        },
        similarProductListing: {
            width:'220px',
            height:'250px',
            display:'inline-block',
            position:'relative',
            overflow:'hidden',
            margin:'20px',
            textAlign:'left'
        },
        productImage: {
            zIndex:1,
            ...similarProductDimensions,
        },
        callOut: {
            marginTop:'0',
            fontFamily:global.fontFamily.secondaryFont,
            color:global.textColors.accent.color1,
            fontSize:'1.2em'
        },
        overlay: {
            zIndex:2,
            background:'rgba(240,240,240,.9)',
            transition:'all .3s linear',
            textAlign:'center',
            color: global.textColors.dark.color1,
            ...similarProductDimensions,
            opacity:'0',
            ':hover': {
                opacity:'1'
            },
            '@media(max-width:767px)': {
                opacity:'1'
            }

        },
        similarProductBrandName: {
            fontSize:'1em',
            padding:'10px 0',
        },
        similarProductTitle: {
            fontSize:'1.4em',
        },
        similarProductPreSalePrice: {
            display:'block',
            minHeight:'1em',
            height:'1em',
            fontSize:'1em',
            color:global.textColors.dark.color2,
            opacity:'.8',
            textDecoration:'line-through',
            marginBottom:'15px',
        },
        similarProductPrice: {
            fontSize:'1.4em',
        },
        quickBuy: {
            position:'absolute',
            bottom:'0',
            left:'0',
            width:'100%',
            padding:'10px 0',
            background: global.textColors.accent.color1,
            fontSize:'1.2em',
            color: global.textColors.light.white,
            ':hover': {
                opacity:'.7'
            }
        },




    })

};

export default productListingStyles;
