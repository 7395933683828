export const ContestSubmissionsStyles = (global, data) => ({
    "< .contest-submissions-grid": {
        display:'grid',
        "grid-template-columns": "1fr 1fr 1fr",
        "grid-auto-rows": "min-content",
        "column-gap": "20px",
        "row-gap": "20px",
        "height": "fit-content",
        "@media(max-width:991px)": {
            "grid-template-columns": "1fr 1fr",
        },
        "@media(max-width:550px)": {
            "grid-template-columns": "1fr",
        }
    },

    "< .submissions-grid-item": {
    },
    "< .submissions-grid-image": {
        height:'300px',
        objectFit:'cover',
        width:'100%'
    },
    "< .contest-image-name": {
        marginTop: "5px",
        marginLeft: "5px",
        fontSize:"14px",
        fontWeight:"bold",
        letterSpacing: "1.2"
    },
    "< .tabs-wrapper": {
        width:"100%",
        display:"flex",
        justifyContent:"space-between",
        margin:'40px 0'
    },
    "< .contest-tab": {
        width:'33.33333%',
        cursor:'pointer',
        background:global.colors.light.color2,
        color:global.colors.dark.color3,
        textAlign:'center',
        borderBottom:`2px solid ${global.colors.dark.color3}`,
        fontSize:'14px',
        letterSpacing: '1px',
        transition:'all 1s ease',
        padding:'10px',
        ".active": {
            color: global.colors.dark.color1,
            borderBottom:`3px solid ${global.colors.dark.color1}`,
        }
    }
})
