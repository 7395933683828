import {prepStringForInferno} from '../../../utils/prep-string-for-inferno';

export const handleVariants = (variants) => {
	
	variants.map((variant) => {
		variant.values.map((value) => {
			value.valueName = value.valueName
		})
	});
	
	return variants;
	
	
};