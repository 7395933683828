/*global Inferno, $ */
/* eslint-disable */
import { include } from '@convertly/thor';

const Script = ( id, siteId, ecommerceSetttings ) => {

    /** Imports **/
    const Graph = include( '../../../utils/graph', 'Graph' );
    const Dispatcher = include( '../../../utils/dispatcher', 'Dispatcher' );
    const ConvertToCurrency = include( '../../../utils/ecomm-utils/convert-to-currency-ii', 'ConvertToCurrency' );
    const ShippingMethodModal = include( '../../shipping-widgets/shipping-widget', "ShippingMethodModal" );


    return( id, siteId, ecommerceSettings ) => {
        const hasLocalStorage = $( 'html' ).hasClass( 'localstorage' );

        const root = document.getElementById( id );
        /** Init Imports **/

        //utils
        const convertToCurrency = ConvertToCurrency( );
        const graph = Graph( );
        const dispatch = Dispatcher( id );
        const ShippingMethodModalInferno = ShippingMethodModal( dispatch, graph, siteId );

        const ShippingCalculator = Inferno.createClass( {

            getInitialState: function( ) {

                return {
                    zipCode: '',
                    displayShippingMethodModal: false
                };
            },
            /** Updates inputs values **/
            onChangeGenericField: function( e, field ) {
                const newState = {};
                newState[ field ] = e.target.value;
                this.setState( newState );
            },
            launchShippingModal: function( ) {

                this.setState( { displayShippingMethodModal: true } );

            },
            /** Launches shipping modal */
            initShippingModalListeners: function( ) {
                dispatch.on( 'launch_shipping_method_modal', ( ) => {
                    this.setState( { displayShippingMethodModal: true } )
                } );
                dispatch.on( 'close_shipping_method_modal', ( ) => {
                    this.setState( { displayShippingMethodModal: false } )
                } );
            },
            componentDidMount( ) {
                this.initShippingModalListeners( )
            },

            render: function( ) {

                let shippingModal = null;
                if (this.state.displayShippingMethodModal) {

                    shippingModal =
                    <div>
                        <div className={ `${root.className} overlay` } />
                        <ShippingMethodModalInferno rootDiv={ root }
                                                    shippingAddress={ { zipCode: this.state.zipCode } }/>;

                    </div>
                } else {
                    shippingModal = null
                }

                return(
                    <div>
                            <form className={ `${root.className} couponInput` }
                                  data-name="Email Form 2"
                                  name="email-form-2">
                                  <input type="text"
                                         className={ `${root.className} couponInput_textInput_wInput` }
                                         data-name="Name"
                                         name="name"
                                         onInput={ (e) => this.onChangeGenericField( e, 'zipCode' ) }
                                         value={ this.state.zipCode }
                                         placeholder="Zip Code"/>
                                <p
                                        onClick={ this.launchShippingModal }
                                       className={ `${root.className} couponApplyLink_wButton` }>Estimate Shipping</p>
                            </form>
                                { shippingModal }
                            </div>
                )
            }

        } );

        Inferno.render( <ShippingCalculator />, root );

    };

};

export default Script;