import {StyleSheet} from "@convertly/css";
import addToCartQtyStepperStyleObject
    from '../../../ecommerce-common-components/add-to-cart-stepper/add-to-cart-qty-stepper-styles';
import ImageGalleryStyleObject
    from '../../../ecommerce-common-components/product-image-gallery/product-image-gallery-2/product-image-gallery-styles';
import bubbleStyleAltObject
    from '../../../ecommerce-common-components/product-variant-widgets/bubble/bubble-style-object';
import progressBarStyleAltObject
    from '../../../ecommerce-common-components/product-variant-widgets/product-variant-progress-bar/product-grid-progress-bar-styles';
import buyingOptionsStyleObject
    from '../../../ecommerce-common-components/buying-options/buying-options-wrapper/buying-options-style-object';
import addToCartStyleObject
    from '../../../ecommerce-common-components/buying-options/add-to-cart-stepper/add-to-cart-qty-stepper-styles';
import quickBuyStyleObject from '../../../ecommerce-common-components/buying-options/quick-buy/quick-buy-style-object';
import {generatePseudoMediaQuery} from "../../../elements/style-functions/pseudo-media-queries";

const ProductDetailPageStyles = (global, data, inEditor) => {
    const addToCartQtyStepperStyles = addToCartQtyStepperStyleObject(global, data, inEditor);
    const imageGalleryStyleObject = ImageGalleryStyleObject(global, data);

    const bubbleStyles = bubbleStyleAltObject(global, data, inEditor);
    const progressBarStyles = progressBarStyleAltObject(global, data);

    const buyingOptionsStyles = buyingOptionsStyleObject(global, data, true, inEditor);
    const addToCartStyles = addToCartStyleObject(global, data);
    const quickBuyStyles = quickBuyStyleObject(global, data, inEditor);

    return StyleSheet.create({
      placeholderContent: {
        padding:'50px',
        fontSize:"22px",
        textAlign:"center"

      },
        productDetailPage: {
            color: global.colors.dark.black,
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "14px",
            lineHeight: "20px",
            marginLeft: "auto",
            marginRight: "auto",
            position: "relative",
            zIndex: "21"
        },
        column_wCol_wCol_6: {
            color: global.colors.dark.black,
            float: "left",
            fontSize: "14px",
            left: "auto",
            lineHeight: "20px",
            minHeight: "1px",
            paddingLeft: "10px",
            paddingRight: "0px",
            position: "relative",
            right: "auto",
            width: "100%"
        },

        productrow_wRow: {
            backgroundColor: "transparent",
            color: global.colors.dark.black,
            fontSize: "14px",
            lineHeight: "20px",
            marginLeft: "0",
            marginRight: "0",
            marginTop: "0px"
        },

        column_2_wCol_wCol_6: {
            color: global.colors.dark.black,
            float: "left",
            fontSize: "14px",
            left: "auto",
            lineHeight: "20px",
            minHeight: "1px",
            paddingLeft: "0px",
            paddingRight: "10px",
            position: "relative",
            right: "auto",
            width: "100%"
        },

        pdpContentInferno: {
            ...addToCartStyles,
            ...quickBuyStyles,
            ...imageGalleryStyleObject,
            ...bubbleStyles,
            ...progressBarStyles,
            ...buyingOptionsStyles,
            /** START: Product Header **/
            '.back-button': {
                fontWeight: '600',
                cursor: 'pointer',
                fontSize: '12px',
                display:'inline-block'
            },
            '.arrow-head-open-right-container': {
                height: '9px',
                width: '9px',
                display: 'inline-block',
                marginRight: '3px',
            },
            '.title-container': {
                height: '100%',
                width: '100%',
                position: 'relative',
                marginBottom: '40px',
                marginTop: '20px',
            },
            ".on-sale-flag": {
                height: '100%',
                width: '100%',
                opacity: '0',
                position: 'absolute',
                borderBottomRightRadius: "50px",
                transition: 'opacity 300ms ease',
                background: 'transparent',
                ".active": {
                    background: global.colors.accent.color3,
                    opacity: '.7',
                }
            },
            '.out-of-stock-pdp-banner': {
                width: '100%',
                height: '30px',
                backgroundColor: 'red',
                color: global.colors.light.white,
                fontSize: '16px',
                fontWeight: '700',
                paddingLeft: '5px',
                paddingTop: '4px',
            },
            ".title-content": {
                position: 'relative',
                padding: '10px 25px 10px 5px',
                borderBottomRightRadius: '60px',
                '.on-sale': {
                    color: global.colors.light.white
                }
            },
            ".sub-title": {
                fontSize: '12px',
            },
            '.product-title': {
                fontSize: '20px',
                lineHeight: '24px',
                fontWeight: '500',
                marginTop: '-2px',
                marginBottom: '3px',
                ...generatePseudoMediaQuery(
                    '@media(max-width: 767px)',
                    ['smallTablet', 'mobile'],
                    {
                        fontSize: '17px'
                    },
                    inEditor
                )
            },
            ".active-price": {
                fontSize: '17px',
                lineHeight: '17px',
                display: 'inline-block',
            },
            ".full-price-strike-out": {
                display: 'inline-block',
                textDecoration: 'line-through',
                fontSize: '12px',
                marginLeft: '10px',
            },
            /** END: Product Header **/
            /** START: Product Variant Styles Overwrite**/
            ".product-variant-row": {
                minHeight: '61px',
                textAlign: 'left',
                overflow: 'hidden',
                display: 'flex'
            },
            ".product-variant-name": {
                display: 'block',
                paddingLeft: '7px',
                color: '#fff',
                textTransform: 'uppercase',
                fontSize: '12px',
                marginBottom: '5px',
            },
            ".product-variant-container": {
                display: 'inline-block',
                verticalAlign: 'top',
                width: 'calc(100% - 40px)',
            },
            '.quick-view-product-variant-wrapper': {
                ...generatePseudoMediaQuery(
                    "@media(max-width:767px)",
                    ['smallTablet', 'mobile'], {
                        height: inEditor ? '85.8%' : '100%',
                        width: inEditor ? '768px' : '100vw',
                        position: 'fixed',
                        top: '0',
                        left: inEditor ? "20px" : '0',
                        // transition: 'all 1s ease',
                        'pointer-events': 'none',
                        transform: 'translateZ(0)',
                        '-moz-transform': 'translatez(0)',
                        '-ms-transform': 'translatez(0)',
                        '-o-transform': 'translatez(0)',
                        '-webkit-transform': 'translateZ(0)',
                        '-webkit-font-smoothing': 'antialiased',
                    },
                    inEditor
                ),
                ...generatePseudoMediaQuery(
                  '@media(max-width: 375px)',
                  ['mobile'], {
                    width: inEditor ? '375px' : '100vw',
                    height: inEditor ? '84%' : '100%',
                    position: 'fixed',
                    top: '0',
                    left: inEditor ? "20px" : '0',
                    // transition: 'all 1s ease',
                    'pointer-events': 'none',
                    transform: 'translateZ(0)',
                    '-moz-transform': 'translatez(0)',
                    '-ms-transform': 'translatez(0)',
                    '-o-transform': 'translatez(0)',
                    '-webkit-transform': 'translateZ(0)',
                    '-webkit-font-smoothing': 'antialiased',
                  },
                  inEditor
                )
            },
            ".quick-view-product-variant-container": {
                width: '100%',
                background: global.colors.dark.color2,
                transition: 'bottom 0.4s ease',
                padding: '10px',
                ...generatePseudoMediaQuery(
                    "@media(max-width:767px)",
                    ['smallTablet', 'mobile'],
                    {
                        position: 'absolute',
                        // bottom: 'calc(-100% + 55px)',
                        top: '100%',
                        left: '0',
                        zIndex: '1',
                        padding: '20px 20px 0',
                        'pointer-events': 'all',
                        ".active": {
                            bottom: '55px',
                            top: 'unset'
                        },
                    },
                    inEditor
                )
            },
            ".progress-bar-wrapper": {
                height: 'auto'
            },
            ".close-button": {
                display: 'none',
                ...generatePseudoMediaQuery(
                    "@media(max-width:767px)",
                    ['smallTablet', 'mobile'],
                    {
                        position: 'absolute',
                        top: '-55px',
                        left: '0',
                        display: 'flex',
                        width: '100%',
                        height: '55px',
                        marginTop: '0px',
                        backgroundColor: global.colors.dark.color1,
                        color: '#fff',
                        alignContent: 'center',
                        justifyContent: 'center',
                        zIndex: '1'
                    },
                    inEditor
                )
            },
            ".close-button-text": {
                alignSelf: 'center'
            },
            ".cart-activated .close-button": {
                zIndex: '1'
            },
            /** End: Product Variant Styles**/
            /** START: Product Description **/
            ".description-container": {
                padding: '30px 20px',
                '.no-variance': {
                    padding: '0px 20px'
                }

            },
            ".description-intro": {
                fontSize: '20px',
                lineHeight: '36px',
                fontWeight: '600'
            },
            ".description": {
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "10px",
                marginTop: "0",
                textAlign: "left"
            },

            ".descontainer": {
                margin: '70px auto',
                textAlign: 'left',
                color: global.colors.light.color3,
                fontSize: "14px",
                lineHeight: "24px",
                paddingBottom: "75px",
                width: "700px",
                maxWidth: '95%',
                background: "transparent",
                padding: "0",
                border: "none"
            },

            ".destitle": {
                color: global.colors.dark.black,
                fontFamily: global.fontFamily.secondaryFont,
                fontSize: "18px",
                lineHeight: "28px",
                margin: "0.67em 0",
                marginBottom: "10px",
                marginTop: "20px",
                textAlign: "center"
            },

            ".bodytext": {
                color: global.colors.dark.color2,
                fontSize: "14px",
                lineHeight: "24px",
                marginBottom: "10px",
                marginTop: "0",
                textAlign: "center"
            },

            '.add-to-cart-no-variance-container': {
                marginTop: '-10px',
                ...generatePseudoMediaQuery(
                    '@media(max-width: 767px)',
                    ['smallTablet', 'mobile'],
                    {
                        display: 'none'
                    },
                    inEditor
                )
            },

            '.add-to-cart-no-variance-container-mobile': {
                display: 'none',
                ...generatePseudoMediaQuery(
                    '@media(max-width: 767px)',
                    ['smallTablet', 'mobile'],
                    {
                        display: 'block'
                    },
                    inEditor
                )
            },

        },

    });

};

export default ProductDetailPageStyles;

