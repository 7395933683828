/*global $ */

const tabsHandler = () => {

    return ( id ) =>  {

        const button   = document.getElementById( id );
        const dispatch = document.getElementById( 'page-app' );

        button.addEventListener( 'click', (e) => {

            e.preventDefault();

            const props = {
                detail : {
                    action: 'add_to_cart'
                }
            };

            const event = new CustomEvent( 'add_to_cart', props );
            dispatch.dispatchEvent( event );

        });

    }
};

export default tabsHandler;
