import React from 'react';
import Component from '../../../rootComponent';
import { css } from "@convertly/css";
import ReactHTMLParser from "@convertly/react-html-parser"
import {id} from '@convertly/thor';

//Default Props
import defaultGlobal from '../../../themes/global';

//Styles
import LocationListItemStyles from './location-map-overlay-styles';

//Components
import Elements from '../../../elements/index'
import Widgets from '../../index';

//Scripts
import AsyncMapLoad from '../scripts/map-iframe-async-load';

//utils
import generateAddressString from '../../../utils/generateAddressString';


export default class ProductListing01 extends Component {

    constructor(props) {

        super(props);
    }

    render() {

        this.styles = LocationListItemStyles(this.props.global, this.props.data);

        /** Calls editor */
        if( this.props.editor ) {
            return this.editor();
        }

        /** Generates border in editor */
        const inlineStyle = this.generateStyleAttribute( );


        return (

            <div className={ css( this.styles.locationDiv ) + ' map-container' }
                 { ...this.generateDataEditorAttribute() }
                 { ...this.generateCustomAttributes() }
                 onClick={( e ) => {
                     this.launchElementalEditor( 'mapsWidget', e );
                 }}
                 onMouseOver={ (e) => {this.addEditableOutline(e)} }
                 onMouseOut={ (e) => {this.removeEditableOutline(e)} }
                 {...inlineStyle}>
                {/** Google Map Embed Code, to be changed API **/}
                <div id={ id( AsyncMapLoad, this.props.data.googleMapAPIKey, generateAddressString( this.props.data ) )} className={ css( this.styles.googleMap ) + ' map' }>
                </div>

                <div className={ css( this.styles.locationInfo ) }>

                    {/** Store Location Info **/}
                    <div className={ css( this.styles.addressBox ) }>
                        <h5 className={ css( this.styles.storeName ) } >{ this.props.data.storeName }</h5>

                        {/** Address **/}
                        <address  className={ css( this.styles.address ) }>

                            {
                                ReactHTMLParser( this.props.data.streetAddress +
                                    '<br/>' +
                                    this.props.data.city + " " +
                                    this.props.data.state + " " +
                                    this.props.data.zipCode )
                            }

                        </address>
                    </div>
                    <div className={ css( this.styles.callUsBox ) }>

                        { /** Phone and Email **/ }
                        <Elements.WebLink
                            data={ this.props.data.emailLink }
                            className={ css( this.styles.contactLink ) }
                            global={ this.props.global }
                            elementDataName="emailLink"
                            moduleId={ this.props.id }
                            moduleSort={ this.props.sort }/>
                        <Elements.WebLink
                            data={ this.props.data.phoneNumber }
                            className={ css( this.styles.contactLink ) }
                            global={ this.props.global }
                            elementDataName="emailLink"
                            moduleId={ this.props.id }
                            moduleSort={ this.props.sort }/>


                        { /** Hours of Operation **/ }
                        <h4 className={ css( this.styles.hoursTitle ) } > Hours Of Operation</h4>

                        <Widgets.HoursOfOperation1
                            data={ { hoursOfOperation: this.props.data.hoursOfOperation } }
                            styles={ this.styles }
                            global={ this.props.global }
                        />
                        <div className="clearfix"></div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>

        )
    }
}

ProductListing01.defaultProps = {
    global: defaultGlobal,
    data: {
        hoursTitle: {
            text: "Hours of Operation"
        }
    }
};
