/*global Inferno, $, React */

/** Box Selector Product Variant Widget **/
import React from 'react';

const Script = (dispatch, ArrowDownOpen, PriceSlider, SearchBar, XIcon) => {
    return ({
                rootClass,
                filtrationMeta,
                addFilter,
                removeFilter,
                rootId,
                activeFilters,
                toggleRefineModal,
                showRefineModal,
                updateSearchQuery,
                liveSearchResults,
                loadingProducts,
                getSearchResults,
                searchQuery,
                handleHeaderSearchQueryChange,
                updateMaxPrice,
                updateMinPrice,
                startMaxPrice,
                startMinPrice,
                currentMinPrice,
                currentMaxPrice,
                addMaxPriceFilter,
                addMinPriceFilter,
                clearMinMaxPrice,
                toggleDropDown
            }) => {

        let {variations, categories, pricingFilterMeta} = filtrationMeta;

        //Sort Categories alpha
        if (categories) {
            categories = categories.sort(
                function (a, b) {
                    if (a.categoryName.toLowerCase() < b.categoryName.toLowerCase()) return -1;
                    if (a.categoryName.toLowerCase() > b.categoryName.toLowerCase()) return 1;
                    return 0;
                }
            );
        }

        let renderYourSelection = false;

        let activeSelections = null;

        activeSelections = activeFilters.map((filter) => {
            if (filter.FilterType !== "onSale" && filter.FilterType !== "maxPrice" && filter.FilterType !== "minPrice") {
                renderYourSelection = true;
                let name = filter.FilterValue;

                if (filter.FilterType === "category" && categories) {
                    let index = categories.findIndex((o, index) => {
                        if (o.categoryId === filter.FilterValue) {
                            return o.categoryId === filter.FilterValue
                        }
                    });

                    name = categories[index].categoryName
                }

                return (
                    <p className={`${rootClass} your-selection-options`}
                       onClick={() => {
                           removeFilter(filter)
                       }}
                       key={`selected-${filter.FilterValue}`}>
                        {name}
                        <XIcon nameOfClass={`${rootClass} remove-filter-icon`}/>
                    </p>
                )
            }
        });

        function allCategoriesAreActive(categories, activeFilters) {

            if (activeFilters.length === 0) {
                return false;
            }

            let arrayOfCategoriesIds = categories.map(el => el.categoryId);
            let arrayOfActiveFiltersIds = activeFilters.map(el => el.FilterValue);
            let arrayOfLeftoverCategories = arrayOfCategoriesIds.filter(el => !arrayOfActiveFiltersIds.includes(el));

            if (arrayOfLeftoverCategories.length === 0) {
                return true;
            }

            return false;
        }

        return (
            <div className={`${rootClass} product-filter-modal-wrapper ${ showRefineModal ? 'active' : '' }`}>
                <div className={`${rootClass} overlay ${ showRefineModal ? 'active' : '' }`}/>
                <div className={`${rootClass} product-filter-container ${ showRefineModal ? 'active' : '' }`}>

                    {/** Mobile Header **/}
                    <div className={`${ rootClass } product-filter-mobile-header`}>
                        <div className={`${ rootClass } product-filter-mobile-row`}>
                            <h3 className={`${ rootClass } product-filter-mobile-header-text`}>Search</h3>
                            <div
                                className={`${ rootClass } product-filter-close-button`}
                                onClick={toggleRefineModal}>Close
                            </div>
                        </div>
                        <div className={`${ rootClass } product-filter-mobile-row`}>
                            <SearchBar
                                rootClass={rootClass}
                                liveSearchResults={liveSearchResults}
                                updateSearchQuery={updateSearchQuery}
                                searchQuery={searchQuery}
                                getSearchResults={getSearchResults}
                                searchIconPosition='right'
                                searchBarIsExpandable={false}
                                expandSearchBar={false}
                                showSearchIcon={true}
                                showXIcon={false}
                                handleHeaderSearchQueryChange={handleHeaderSearchQueryChange}
                                loadingProducts={loadingProducts}/>

                        </div>

                    </div>
                    {/** Your selection **/}
                    {renderYourSelection && activeFilters.length > 0 &&
                    <div className={`${rootClass} your-selection-container`}>
                        <h3 className={`${rootClass} your-selection-text`}>Your Selection</h3>
                        <div className={`${rootClass} divider`}/>

                        {activeSelections}

                    </div>

                    }


                    <h2
                        className={`${rootClass} product-filter-refine-text`}
                        onClick={toggleRefineModal}>Refine</h2>
                    {
                        pricingFilterMeta &&
                        <div className={`${ rootClass } filtration-container`}>
                            <div
                                className={`${ rootClass } filtration-header`}
                                onClick={() => toggleDropDown('pricing')}>
                                <h3 className={`${ rootClass } filtration-title`}>Pricing</h3>
                                <span
                                    className={`${ rootClass } arrow-container`}
                                    data-toggle='pricing'>
                    <ArrowDownOpen nameOfClass={`${ rootClass } arrow-down`}/>
							    </span>
                            </div>
                            <div className={`${ rootClass } filtration-content`} data-toggle='pricing'>
                                <PriceSlider
                                    rootClass={rootClass}
                                    rootId={rootId}
                                    addFilter={addFilter}
                                    updateMaxPrice={updateMaxPrice}
                                    updateMinPrice={updateMinPrice}
                                    startMaxPrice={startMaxPrice}
                                    startMinPrice={startMinPrice}
                                    currentMinPrice={currentMinPrice}
                                    currentMaxPrice={currentMaxPrice}
                                    addMaxPriceFilter={addMaxPriceFilter}
                                    addMinPriceFilter={addMinPriceFilter}
                                    minPrice={pricingFilterMeta.minPrice}
                                    maxPrice={pricingFilterMeta.maxPrice}
                                    clearMinMaxPrice={clearMinMaxPrice}
                                    removeFilter={removeFilter}/>
                                <div className={`${ rootClass } on-sale-checkbox-wrapper`}>
                                    <input
                                        className={`${ rootClass } on-sale-checkbox`}
                                        onClick={(e) => {
                                            dispatch.send('on-sale-filter', e.target.checked);
                                        }}
                                        defaultChecked={activeFilters &&
                                        activeFilters.findIndex((filter) => {
                                            return filter.FilterValue === "onSale"
                                        }) !== -1
                                        }
                                        type="checkbox"
                                        name="on-sale"/>On Sale
                                </div>
                            </div>
                        </div>
                    }
                    {/**  Categories **/}
                    {
                        !allCategoriesAreActive(categories, activeFilters) &&
                        categories &&
                        <div className={`${ rootClass } filtration-container`}>
                            <div
                                onClick={() => {
                                    toggleDropDown('category')
                                }}
                                className={`${ rootClass } filtration-header`}>
                                <h3 className={`${ rootClass } filtration-title`}>Categories</h3>
                                <span
                                    className={`${ rootClass } arrow-container`}
                                    data-toggle={`category`}>
								<ArrowDownOpen nameOfClass={`${ rootClass } arrow-down`}/>
							</span>
                            </div>
                            <div
                                className={`${ rootClass } filtration-content`}
                                data-toggle={`category`}>
                                {
                                    categories.map((category) => {

                                        let render = true;
                                        //Checks to see if any options have been selected

                                        if (activeFilters) {
                                            render = activeFilters.findIndex((filter) => {
                                                return filter.FilterValue.toLowerCase() === category.categoryId.toLowerCase() && filter.FilterType === "category"
                                            }) === -1
                                        }
                                        if (render) {
                                            return (
                                                <p className={`${ rootClass } filtration-option-text`}
                                                   onClick={() => {
                                                       addFilter("category", category.categoryId)
                                                   }}>
                                                    {`${category.categoryName} (${category.count}) `}</p>
                                            )
                                        }
                                    })}
                            </div>
                        </div>
                    }
                    {/**  Variations **/}
                    {
                        (variations && variations.length > 0) &&
                        <div>

                            {variations.map((variation, idx) => {

                                let filterActiveFilterValues = activeFilters.map((el) => el.FilterValue);
                                let variationValueNames = variation.values.map((el) => el.valueName);
                                let filteredActiveVariants = variationValueNames.filter(val => !filterActiveFilterValues.includes(val));

                                if (filteredActiveVariants.length === 0) {
                                    return null;
                                }
                                //Sort variations alpha
                                if (variation.values) {
                                    variation.values = variation.values.sort(
                                        function (a, b) {
                                            if (a.valueName.toLowerCase() < b.valueName.toLowerCase()) return -1;
                                            if (a.valueName.toLowerCase() > b.valueName.toLowerCase()) return 1;
                                            return 0;
                                        }
                                    );
                                }
                                let variantName = variation.variantName.replace(/\s+/g, '-').toLowerCase();
                                return (
                                    <div 
                                        key={ `filtration-container-${ idx }` }
                                        className={`${ rootClass } filtration-container`}>
                                        <div className={`${ rootClass } filtration-header`}
                                             onClick={() => {
                                                 toggleDropDown(variantName)
                                             }}>
                                            <h3 className={`${ rootClass } filtration-title`}>{variation.variantName}</h3>
                                            <span
                                                className={`${ rootClass } arrow-container`}
                                                data-toggle={`${ variantName }`}>
									<ArrowDownOpen nameOfClass={`${ rootClass } arrow-down`}/>
								</span>
                                        </div>
                                        <div className={`${ rootClass } filtration-content`}
                                             data-toggle={`${ variantName }`}>
                                            {variation.values.map((value, idx) => {
                                                let render = true;

                                                if (activeFilters) {
                                                    render = activeFilters.findIndex((filter) => {
                                                        return filter.FilterValue.toLowerCase() === value.valueName.toLowerCase() && filter.FilterType === "variant"
                                                    }) === -1
                                                }
                                                if (render) {
                                                    return (
                                                        <p
                                                          key={ `filtration-option-${ idx }` } 
                                                          className={`${ rootClass } filtration-option-text`}
                                                           onClick={() => {
                                                               addFilter("variant", value.valueName)
                                                           }}>
                                                            {`${value.valueName} (${value.count}) `}</p>
                                                    )
                                                }
                                            })
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    }
                    <SearchBar
                        rootClass={rootClass}
                        liveSearchResults={liveSearchResults}
                        updateSearchQuery={updateSearchQuery}
                        searchQuery={searchQuery}
                        getSearchResults={getSearchResults}
                        searchIconPosition='right'
                        searchBarIsExpandable={false}
                        expandSearchBar={false}
                        showSearchIcon={true}
                        showXIcon={false}
                        handleHeaderSearchQueryChange={handleHeaderSearchQueryChange}
                        loadingProducts={loadingProducts}/>

                </div>
            </div>
        );
    };
};

export default Script;
