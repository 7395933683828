import { StyleSheet } from "@convertly/css";
import UserOverrides from "../../elements/style-functions/user-overrides";
import removeNestedUserOverrides from "../../site-header/site-header/utils/removeNestedUserOverrides";

export const PublishedDateStyles = ({ global, data, inEditor }) => {
  const publishedDate = removeNestedUserOverrides({ data }),
      { userOverrides } = data,
      { publishedDateWrapper } = userOverrides || {};

  return StyleSheet.create({
    publishedDateStyle: {
      ...UserOverrides(global, { userOverrides: publishedDate }, inEditor)
    },
    publishedDateWrapper: {
      ...UserOverrides(global, publishedDateWrapper, inEditor)
    }
  });
};
