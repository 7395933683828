import { createAction } from "redux-act";
import _ from "lodash";
import { fromJS } from "immutable";
import uuid from "uuid";
import { store } from "app/app";
import Logger from "utils/logger";
import { graph, purgeSiteCache } from "app/utils";
import { setCurrentSite, setCurrentSiteSettings } from "containers/Authentication/actions";
import { uploadImages, loadSite } from "app/services/sites";
import { Alerts } from "services/Alerts";
import { toJS } from "immutable";

import { setSiteData } from "containers/Editor/actions/editorActions";
import { setProcessing } from "../../actions/navBarActions";
import {
  saveLocations,
  fillEditLocation,
  loadLocations,
  setEditLocationIndex,
  setEditLocation,
  setLocations,
} from "./locations";
import {
  saveBusinessInfo,
  loadBusinessInfo,
  takeBusinessInfoSnapshot,
  updateBusinessInfo,
  revertBusinessInfoSnapshot,
} from "./businessInfo";
import { can } from "containers/Authentication/PermissionsCheck";

export const setPixelData = createAction("Site Settings: Set pixel data");
export const updateConversionPoints = createAction("Site Settings: Set site conversion points");
export const updateCustomPixels = createAction("Site Settings: Set site custom pixels");
export const setAllPages = createAction("Site Settings: Set all pages");
export const setLoadedStatus = createAction("Site Settings: Set loaded flag");
export const setTab = createAction("Site Settings: Set current tab");
export const setLoadingPixels = createAction("Site Settings: Set loading pixels flag");
export const setSavingState = createAction("Site Settings: Set saving flag");
export const resetState = createAction("Site Settings: Reset store");
export const setValue = createAction("Site Settings: Set general store value");
export const setSnapshot = createAction("Site Settings: Set site snapshot");
export const setGeneratingPreviewDomain = createAction(
  "Site Settings: Set flag for generating a new preview domain"
);
export const setActiveSetting = createAction("Site Settings: Set the active setting");
export const setDomainFooterVisible = createAction("Site Settings: Toggle Domain Footer");
export const setToggleValue = createAction("Site Settings: Toggle Value");
export const setSiteSettingsSnapshot = createAction("Site Settings: Set Site Settings Snapshot");
export const setBusinessInfoLocationSnapshot = createAction(
  "Site Settings: setBusinessInfoLocationSnapshot"
);
export const setPixelSnapshot = createAction("Site Settings: Set Pixel Snapshot");

export const takeSiteSettingsSnapshot = () => async dispatch => {
  const siteSettings = store
    .getState()
    .get("auth")
    .get("activeSite");
  await dispatch(setSiteSettingsSnapshot(siteSettings));
};

export const takeBusinessInfoLocationSnapshot = () => async dispatch => {
  const locations = store
    .getState()
    .get("editor")
    .Locations.get("locations");

  const businessInfoLocation = locations.getIn([0]);
  await dispatch(setBusinessInfoLocationSnapshot(businessInfoLocation));
};

export const takePixelSnapshot = () => async dispatch => {
  const conversionPointsSnapshot = store
    .getState()
    .get("editor")
    .Settings.get("conversionPoints");
  const customPixelsSnapshot = store
    .getState()
    .get("editor")
    .Settings.get("customPixels");
  await dispatch(setPixelSnapshot({ conversionPointsSnapshot, customPixelsSnapshot }));
};

export const init = () => async dispatch => {
  await dispatch(setProcessing(true));
  await dispatch(setEditLocationIndex(0));
  await dispatch(loadPixels());
  await dispatch(loadLocations());
  await dispatch(loadBusinessInfo());
  await dispatch(fillEditLocation());
  await dispatch(takeSiteSettingsSnapshot());
  await dispatch(takeBusinessInfoLocationSnapshot());
  await dispatch(takeBusinessInfoSnapshot());
  await dispatch(takePixelSnapshot());
  await dispatch(setLoadedStatus(true));
  await dispatch(setProcessing(false));
};

export const toggleValue = field => async dispatch => {
  const site = store
    .getState()
    .get("auth")
    .get("activeSite");

  let value = !site.get(field);
  await dispatch(updateValue(field, value));
};

export const settingsNavClick = selectedOption => async dispatch => {
  await dispatch(setActiveSetting(selectedOption.key));
};

export const generatePreviewDomain = () => async dispatch => {
  if (
    !(await Alerts.confirm(
      "Refresh the preview url? Any previous preview urls will no longer be valid."
    ))
  ) {
    return;
  }

  await dispatch(setGeneratingPreviewDomain(true));

  const site = store
    .getState()
    .get("auth")
    .get("activeSite");

  const query = `
		query( $userId: ID!, $siteId: String ) {
		  Account(id:$userId) {
			site(siteId:$siteId) {
			  generatePreviewDomain
			}
		  }
		}`;

  const variables = {
    userId: store
      .getState()
      .get("auth")
      .get("user")
      .get("id"),
    siteId: site.get("id"),
  };

  const response = await graph({ query, variables });
  const preview = _.get(response, "Account.site.generatePreviewDomain");
  const Site = site.set("previewDomain", preview);

  await dispatch(setSiteData(Site)); // Editor reducer
  await dispatch(setCurrentSite(Site)); // Auth reducer

  await dispatch(setGeneratingPreviewDomain(false));
};

export const revertSnapshot = () => async dispatch => {
  const siteSettingsSnapshot = store
    .getState()
    .get("editor")
    .Settings.get("siteSettingsSnapshot");
  const businessInfoLocationSnapshot = store
    .getState()
    .get("editor")
    .Settings.get("businessInfoLocationSnapshot");

  const businessInfoSnapshot = store
    .getState()
    .get("editor")
    .Settings.get("businessInfoSnapshot");

  let locations = store
    .getState()
    .get("editor")
    .Locations.get("locations");

  let editLocation = store
    .getState()
    .get("editor")
    .Locations.get("editLocation");

  locations = locations.setIn([0], editLocation);

  const conversionPoints = store
    .getState()
    .get("editor")
    .Settings.get("conversionPointsSnapshot");

  const customPixels = store
    .getState()
    .get("editor")
    .Settings.get("customPixelsSnapshot");

  // reset siteSettings
  await dispatch(setCurrentSiteSettings(siteSettingsSnapshot));

  // reset businessInfo
  await dispatch(revertBusinessInfoSnapshot());

  // reset businessInfoLocation
  await dispatch(setEditLocation(businessInfoLocationSnapshot));
  await dispatch(setLocations(locations));

  // pixels
  await dispatch(setPixelData({ conversionPoints, customPixels }));
};

export const updateValue = (field, value) => async dispatch => {
  const activeSetting = store
    .getState()
    .get("editor")
    .Settings.get("activeSetting");

  const site = store
    .getState()
    .get("auth")
    .get("activeSite");

  // edit site value
  if (activeSetting !== "GENERAL" || field === "logo" || field === "siteName") {
    const newSite = site.merge({
      [field]: value,
    });
    await dispatch(setCurrentSite(newSite));
  } else {
    // always editing location, if editlocationIndex = 0, then also editing businessInfo
    let locations = store
      .getState()
      .get("editor")
      .Locations.get("locations");

    let editLocationIndex = store
      .getState()
      .get("editor")
      .Locations.get("editLocationIndex");

    let editLocation = store
      .getState()
      .get("editor")
      .Locations.get("editLocation");

    if (editLocationIndex === 0) {
      // edit businessInfo
      const businessInfo = store
        .getState()
        .get("editor")
        .BusinessInfo.get("businessInfo");

      let locationField;
      if (field === "businessName") {
        locationField = "locationName";
      }

      const newBusinessInfo = businessInfo.merge({
        [field]: value,
      });
      editLocation = editLocation.merge({
        [locationField || field]: value,
      });

      locations = locations.setIn([editLocationIndex], editLocation);
      await Promise.all([
        // dispatch(setCurrentSite(newSite)),
        dispatch(setEditLocation(editLocation)),
        dispatch(updateBusinessInfo(fromJS(newBusinessInfo))),
        dispatch(setLocations(locations)),
      ]);
    }
  }
};

export const handleSave = activeSetting => async dispatch => {
  if (activeSetting === "PIXELS") {
    await dispatch(savePixels());
  }
  await dispatch(saveSettings());
};

export const saveSettings = () => async dispatch => {
  // await dispatch(setSavingState(true));
  await dispatch(setProcessing(true));
  let site = store.getState().getIn(["auth", "activeSite"]);

  let locations = store
    .getState()
    .get("editor")
    .Locations.get("locations");

  let editLocation = store
    .getState()
    .get("editor")
    .Locations.get("editLocation");

  locations = locations.setIn([0], editLocation);

  try {
    await dispatch(setLocations(locations));
    await dispatch(setSnapshot(site));
    await Promise.all([dispatch(saveLocations()), dispatch(saveBusinessInfo())]);
    site = site.toJS();

    const Settings = store.getState().get("editor").Settings;
    const logo = _.get(site, "logo") || {};

    const query = `
			mutation( $id:ID!, $site:siteInput ) {
			  editSite( id: $id, site: $site ) {
				id, siteId, domain, theme, timeZone, ssl, goToTop, googleAnalytics, facebookUrl,
					twitterUrl, siteName, siteDescription, name, siteCategory, favicon, locked
			  }
			}
			`;
    const siteSettingsData = {
      facebookUrl: site.facebookUrl,
      twitterUrl: site.twitterUrl,
      instagramUrl: site.instagramUrl,
      timeZone: site.timeZone,
      siteCategory: site.siteCategory,
      siteDescription: site.siteDescription,
      siteName: site.siteName,
      siteKeywords: site.pageKeywords,
      siteTitle: site.siteTitle,
      domain: site.domain,
      ssl: site.on,
      goToTop: site.goToTop,
      favicon: site.favicon,
      googleAnalytics: site.googleAnalytics,
      facebookPixelId: site.facebookPixelId,
      mailchimp: site.mailchimp,
      locked: site.locked,
      logo,
      doNotIndex: site.doNotIndex,
      lazyLoadImages: site.lazyLoadImages,
    };

    const variables = {
      id: site.siteId,
      site: siteSettingsData,
    };
    await graph({ query, variables });
    // await dispatch(loadSite(site));
  } catch (e) {
    Logger.info("Error", e.message);
    // await dispatch(loadSite(site));
  }

  await purgeSiteCache(`${site.previewDomain}.convertly.io`);

  if (site.domain) {
    await purgeSiteCache(site.domain);
  }
  await saveLocations();
  // await dispatch(setSavingState(false));
  await dispatch(setProcessing(false));
};

export const saveAdwordsConversionPoints = ({ points, site }) => async dispatch => {
  if (!site.get("marketingEnabled")) {
    return points;
  }

  const siteId = site.get("siteId");

  for (let i = 0; i < points.length; i++) {
    const point = points[i];

    if (point.adwordsConversionTracker) {
      point.adwordsConversionTracker = await updateAdwordsConversion({
        point,
        siteId,
      });
    } else {
      point.adwordsConversionTracker = await createAdwordsConversion({
        point,
        siteId,
      });
    }

    points[i] = fromJS(point).toJS();
  }

  return points;
};

export const updateAdwordsConversion = async ({ point, siteId }) => {
  const query = `
	mutation( $siteId:ID!, $conversionTrackerId:Int!, $update:adwordsConversionTrackerUpdate! ) {
		updateAdwordsConversionTracker(siteId:$siteId,conversionTrackerId:$conversionTrackerId,update:$update) {
			id, name, status, category, googleEventSnippet, googleGlobalSiteTag
		}
	}`;

  const variables = {
    siteId,
    conversionTrackerId: point.adwordsConversionTracker.id,
    update: {
      name: `CONVERTLY--${point.pixelTitle}-${uuid()}`,
    },
  };

  try {
    const res = await graph({ query, variables }, true);
    return _.get(res, "data.updateAdwordsConversionTracker");
  } catch (e) {
    console.warn("updateAdwordsConversionTracker", point, e.message);
    return null;
  }
};

export const createAdwordsConversion = async ({ point, siteId }) => {
  const query = `
	mutation( $siteId:ID!, $name:String! ) {
		createAdwordsConversionTracker(siteId:$siteId, name: $name ) {
			id, name, status, category, googleEventSnippet, googleGlobalSiteTag
		}
	}`;

  const variables = {
    siteId,
    name: `CONVERTLY--${point.pixelTitle}-${uuid()}`,
  };

  try {
    const res = await graph({ query, variables }, true);
    return _.get(res, "data.createAdwordsConversionTracker");
  } catch (e) {
    console.warn("createAdwordsConversion", point, e.message);
    return null;
  }
};

export const savePixels = () => async dispatch => {
  // await dispatch(setSavingState(true));
  await dispatch(setProcessing(true));

  const userId = store
    .getState()
    .get("auth")
    .get("user")
    .get("sub");
  const site = store
    .getState()
    .get("auth")
    .get("activeSite");
  const siteId = site.get("siteId");
  const domain = site.get("domain");
  const Settings = store.getState().get("editor").Settings;
  let pixels = Settings.get("customPixels").toJS();
  pixels = pixels.map(p => {
    delete p.fullscreen;
    return p;
  });

  let points = Settings.get("conversionPoints").toJS();

  if (site.get("marketingEnabled")) {
    points = await dispatch(saveAdwordsConversionPoints({ points, site }));
  }

  let facebookPixelId = "";

  if (can("view_facebook")) {
    facebookPixelId = fetchFacebookPixelID({ userId, siteId });
  }

  const query = `
		query ($id: ID!, $siteId: String, $pixels:[PixelInput], $points:[PixelInput]${
      can("view_facebook") ? " ,$facebookPixelId:ID, $subId: ID" : ""
    }) {
		  Account(id: $id) {
				site(siteId: $siteId) {
					updatePixels(pixels:$pixels,points:$points${
            can("view_facebook") ? ",facebookPixelId:$facebookPixelId,subId:$subId" : ""
          })
				}
		  }
		}
		`;

  const facebookVariables = {
    subId: userId,
    facebookPixelId,
  };

  let variables = {
    id: userId,
    siteId,
    pixels,
    points,
  };

  if (can("view_facebook")) {
    variables = {
      ...variables,
      ...facebookVariables,
    };
  }

  await graph({ query, variables });
  await purgeSiteCache(domain);
  await purgeSiteCache(`${site.get("previewDomain")}.convertly.io`);

  // await dispatch(setSavingState(false));
  await dispatch(setProcessing(false));
};

/**
 * Loads conversion points, custom pixels and all pages
 * Sets loaded flag to only load once
 * @returns {function(*)}
 */
export const loadPixels = () => async dispatch => {
  const loaded = store
    .getState()
    .get("editor")
    .Settings.get("loaded");

  if (loaded) {
    return;
  }

  await dispatch(setLoadingPixels(true));

  const userId = store.getState().getIn(["auth", "user", "sub"]);
  const siteId = store.getState().getIn(["auth", "activeSite", "siteId"]);

  const response = await Promise.all([fetchAllPages({ userId, siteId }), fetchPixels(siteId)]);

  const { AllPages } = response[0];
  const { ConversionPoints, CustomPixels } = response[1];

  const sortedPages = _.sortBy(AllPages, o => o.name);

  await Promise.all([
    dispatch(setAllPages(sortedPages)),
    dispatch(updateConversionPoints(ConversionPoints)),
    dispatch(updateCustomPixels(CustomPixels)),
    dispatch(setLoadedStatus(true)),
    dispatch(setLoadingPixels(false)),
  ]);
};

export const fetchPixels = async id => {
  const query = `
    query( $id: ID! ){
      Site(id: $id) {
        CustomPixels {
					pixelTitle
					pixelSource
					targetedPages
			  }
			  ConversionPoints {
			    id
					pixelTitle
					pixelType
					targetedPages
					google
					facebook
					trackAsGoal
          adwordsConversionTrackerId
          ${can("view_facebook") ? "facebookCustomEventId" : ""}
			  }
      }
		}
  `;

  const variables = {
    id,
  };

  const response = await graph({ query, variables });

  const { ConversionPoints, CustomPixels } = _.get(response, "Site");

  return {
    ConversionPoints,
    CustomPixels,
  };
};

export const fetchAllPages = async ({ userId, siteId }) => {

  const query = `
		query( $id: ID! ){
		  Site(id: $id) {

			pages( id: $id ) {
			  id, name, slug, pageId
			}
		  }
		}
		`

  const variables = {
    id:siteId
  };

  const res = await graph({ query, variables });

  const AllPages = _.get(res, "Site.pages") || [];

  return {
    AllPages,
  };
};

export const fetchFacebookPixelID = async ({ userId, siteId }) => {
  const query = `
    query getPixelId($subId: ID!, $siteId: ID!) {
      getAllFacebookPixels(subId: $subId, siteId: $siteId) {
        id
        name
        code
      }
    }
  `;

  const variables = {
    siteId: siteId,
    subId: userId,
  };

  const res = await graph(
    {
      query,
      variables,
    },
    true
  );

  if (_.get(res, "data.getAllFacebookPixels")) {
    return res.data.getAllFacebookPixels[0].id;
  }
};
