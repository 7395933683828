import getAncestorObject from "./getAncestorObject";
import verifyIfChildrenHaveHeight from "./verifyIfChildrenHaveHeight";

/**
 * Some comments would have been good right about here.
 * If you drag a module and drop it somewhere an error is triggered in the function.
 *
 * I added some conditional check so the error doesn't happen.
 *
 * That was an hour or so ago.  Now I've added crap load of comments
 * added functions to explain what this code is doing
 *
 * Still don't know "why" it does what it does but at least know what it does
 *
 * Vote for Pedro.
 *
 * In case I broke something the original function code is
 * at the bottom of the file
 *
 * This function deletes an element and then goes up the tree
 * and checks if the parent should be deleted as well.
 *
 */

// Shorthand for checking if the obj does not have the key
const noKey = key => (obj = {}) => Object.keys(obj).indexOf(key) === -1;

const noBgColor = obj => noKey("backgroundColor")(obj);

const noBgImage = obj => noKey("backgroundImage")(obj);

const noBorder = obj => noKey("border")(obj);

const elementIsNotStyled = obj =>
  noBgColor(obj.data.userOverrides) &&
  noBgImage(obj.data.userOverrides) &&
  noBorder(obj.data.userOverrides);

const hasParent = obj => !!obj?.parentId;

const hasChildren = obj => !!obj?.children?.length;

function setElementHeight(obj, id) {
  obj.data.userOverrides.height = document.querySelector(`[data-editor=c${id}]`).clientHeight;
}

const elementHasHeightSet = element => !!element?.data?.userOverrides?.height;

const removeElement = ({ element, parent }) => {
  const index = parent.children.findIndex(thisObject => thisObject.id === element.id);
  parent.children.splice(index, 1);
};

function removeElementFromTree(tree, elementId) {
  const activeModuleObject = tree.filter(({ id }) => id === elementId)[0];
  const activeModuleIndex = tree.indexOf(activeModuleObject);
  tree.splice(activeModuleIndex, 1);
}

function normalizeUserOverrides(obj) {
  obj.data.userOverrides = obj.data.userOverrides || {};
}

/**
 * After cleaning up this function it seems to deal mostly
 * with making sure that elements have a height set
 *
 * Who knew.... ¯\_(ツ)_/`
 *
 * Maybe there were bugs with elements collapsing after deletes because
 * the height wasn't explicitly set.
 *
 * When you delete an element this function get's called on the element's parent
 *
 * Delete an image and call it on the column
 * Delete a column and call it on the row
 *
 * @param pageElements - The page element tree
 * @param element - the current element
 * @param activeModuleId - ???? ID of Active HeartOfGold module I think
 * @param it - Seems important although it's never used..... Maybe an object passed by reference...?
 */
export const checkAndDeleteParents = (pageElements, element, activeModuleId, it) => {
  normalizeUserOverrides(element);
  /**
   * Since getAncestorObject is called with generation 1 argument
   * I think ancestorObject is actually parentObject.  💡💡💡💡💡💡
   */
  const parent = getAncestorObject(pageElements, element, activeModuleId, 1);

  if (hasChildren(element)) {
    /**
     * If the children have height then our work is done.
     *
     * This function call actually checks if the children are specific types of elements
     * rows, columns, etc.  I guess that means they have a height set.
     */
    if (verifyIfChildrenHaveHeight(element.children)) return;
    /**
     * If they do not have height then we need to set it
     *
     * why is this important.... ¯\_(ツ)_/`
     */

    if (elementHasHeightSet(element)) return;

    let objectId;

    /**
     * If the parent does not have a parent then this is a Module ( I think )
     * so we set the height from the parent
     */
    if (!hasParent(parent)) {
      objectId = element.parentId;
    } else {
      /**
       * If the parent does have a parent then we could be anywhere in the tree
       * and we set the height from the element.
       */
      objectId = element.id;
    }

    return setElementHeight(element, objectId);
  }

  /**
   * We know that the element does not have children
   */

  /**
   * What significance is it if the module does not have backgroundColor, backgroundImage,
   * and a border?
   *
   * Who knows...?  Maybe the internet crashes....
   */
  if (elementIsNotStyled(element) && hasParent(parent)) {
    /**
     * If this element does not have bgImage or bgColor or border
     * and the parent has a parent
     *
     * Then remove the element from the tree.  And go up the tree.
     */
    removeElement({ element, parent });

    return checkAndDeleteParents(pageElements, parent, activeModuleId, it);
  }

  /**
   * At this point the element is either
   * a) styled or
   * b) parent does not have a parent
   */

  /**
   * If there is a parent and the parent does not have a parent
   * Then we remove the activeModuleId from the tree
   *
   * What is activeModuleId you ask?  Wouldn't I like to know.
   *
   * The more I read this code the more I think it has a lot of checks
   * for heart of gold which is in the tree but doesn't get displayed.
   *
   * If there is a parent that does not have a parent then the parent is top of the line
   * in the tree which is HeartOfGold
   *
   * activeModuleId might be the id of the HeartOfGold module
   *
   */
  if (parent && !hasParent(parent)) {
    /**
     * We deleted the top module in the tree
     * since heart of gold is actual top of tree but the UI doesn't let you delete it directly
     */
    removeElementFromTree(pageElements, activeModuleId);
  } else {
    setElementHeight(element, element.id);
  }

  /**
   * Not sure if this block is ever executed.
   */
  if (hasParent(parent)) {
    return checkAndDeleteParents(pageElements, parent, activeModuleId, it);
  }
};

export default checkAndDeleteParents;

/*
import findElementObject from "./findElementObject";
import getAncestorObject from "./getAncestorObject";
import verifyIfChildrenHaveHeight from "./verifyIfChildrenHaveHeight";
import { setEditor, setModules, setActiveComponent } from "./actions/editorActions";

export const checkAndDeleteParents = (pageElements, object, activeModuleId, it) => {
  const ancestorObject = getAncestorObject(pageElements, object, activeModuleId, 1);
  let index;
  let flag;
  if (object.children.length === 0) {
    object.data.userOverrides = object.data.userOverrides || {};
    if (
      Object.keys(object.data.userOverrides).indexOf("backgroundColor") === -1 &&
      Object.keys(object.data.userOverrides).indexOf("backgroundImage") === -1 &&
      Object.keys(object.data.userOverrides).indexOf("border") === -1 &&
      ancestorObject &&
      ancestorObject.parentId
    ) {
      index = ancestorObject.children.findIndex(thisObject => thisObject.id === object.id);
      ancestorObject.children.splice(index, 1);
    } else {
      if (ancestorObject && !ancestorObject.parentId) {
        /* object.data.userOverrides.height = document.querySelector(
          "[data-editor=c" + object.parentId + "]"
        ).clientHeight;
        it.context.store.dispatch(
          setEditor({
            editorType: { type: "background", field: object.parentId },
            activeModuleId,
            isActiveComponent: ancestorObject.sort,
          })
        ); */
/*
const deleteTest = pageElements.filter(obj => {
  return obj.id === activeModuleId;
})[0];
const index = pageElements.indexOf(deleteTest);
pageElements.splice(index, 1);
} else
object.data.userOverrides.height = document.querySelector(
  "[data-editor=c" + object.id + "]"
).clientHeight;
}
if (ancestorObject.parentId)
  checkAndDeleteParents(pageElements, ancestorObject, activeModuleId, it);
} else {
  object.data.userOverrides = object.data.userOverrides || {};
  flag = verifyIfChildrenHaveHeight(object.children, true);
  if (!flag) {
    if (!ancestorObject.parentId) {
      if (!object.data.userOverrides.height)
        object.data.userOverrides.height = document.querySelector(
          "[data-editor=c" + object.parentId + "]"
        ).clientHeight;
    } else {
      if (!object.data.userOverrides.height)
        object.data.userOverrides.height = document.querySelector(
          "[data-editor=c" + object.id + "]"
        ).clientHeight;
    }
  }
}
};
export default checkAndDeleteParents;

 */
