/* global window, $ */

import {include} from '@convertly/thor';

/**
 *
 * @returns {function(*)}
 */
const Script = () => {

	return () => {

		/**
		 * Initialize variables
		 */
		const $window    = $ ( window );

		// queue holds $el references to elements
		// that have an animation but have not been scrolled into view
		const queue      = [];

		let checking     = false;
		let dirty        = false;

		// Set string constants for reference
		// Use the minified var reference in production
		// and not the full string value
		const visibility = 'visibility';
		const animated   = '[data-animated]';

		getQueue();

		// wait 250ms for DOM and/or images to finish loading
		setTimeout( init, 250 );

		/**
		 * Register event listener and perform initialize check
		 */
		function init() {
			window.addEventListener( 'scroll', check );
			check();
		}

		/**
		 * Check if elements are in view
		 * If so, add the animation class
		 * Repopulate queue array with updated elements
		 * not yet in view
		 * Remove event listener if all elements have been processed
		 *
		 */
		function check() {

			// Only process one check() at a time
			if( checking ) {
				return;
			}

			dirty    = false;
			checking = true;

			queue.forEach( $el => {

				if( isScrolledIntoView( $el ) ) {

					const el  = $el[ 0 ];

					const animatedClass = $el.attr( 'data-animated' );
					el.removeAttribute( 'data-animated' );

					removeStyle( el, visibility );
					$el.addClass( animatedClass ).addClass( 'animated' );

					dirty = true;

				}

			});


			if( dirty ) {

				getQueue();

				if( ! queue.length ) {
					window.removeEventListener( 'scroll', check );
				}

			}

			checking = false;

		}

		/**
		 * Populate the Queue array with elements that have
		 * data-animated attribute
		 */
		function getQueue() {

			queue.length = 0;

			$ ( animated ).forEach( el => {
				queue.push( $( el ) );
			});
		}

		/**
		 * Check if element is within current view
		 * https://stackoverflow.com/a/488073
		 *
		 * @param $el
		 * @returns {boolean}
		 */
		function isScrolledIntoView ( $el ) {

			const docViewTop = $window.scrollTop();
			const docViewBottom = docViewTop + $window.height();

			const elemTop = $el.offset().top;
			const elemBottom = elemTop + $el.height();

			return ( ( elemBottom <= docViewBottom ) && ( elemTop >= docViewTop ) );
		}

		/**
		 * Remove inline Style from element
		 * https://stackoverflow.com/a/7971565
		 *
		 * @param el
		 * @param style
		 */
		function removeStyle( el, style ) {
			if (el.style.removeProperty) {
				el.style.removeProperty( style );
			} else {
				el.style.removeAttribute( style );
			}
		}

	};


};

export default Script;
