import React from "react";
import PropTypes from "prop-types";
import { fromJS } from "immutable";
import styled from "styled-components";
import { css } from "@convertly/css";
import styles from "containers/Editor/components/editor/editor-forms-styles";
import { clientImage } from "containers/Editor/components/pegasus-components/src/utils/generateImage";
import LoadingIcon from "components/icons/loadingIcon.svg";
import { isProduction } from "utils/index";

const CheckMark = props => (
  <div {...props} className={css(styles.galleryCollectionSelection)}>
    <i className={css(styles.galleryCollectionSelectedIcon) + " fa fa-check-circle-o fa-4x"} />
  </div>
);

const Wrapper = styled.div`
  width: 134px;
  height: 112px;
  margin: 0 20px 20px 0;
  display: inline-block;
  position: relative;
  cursor: pointer;
  opacity: ${props => (props.loaded ? `1` : `0`)};
  transition: 1000ms all linear;
`;

const Filename = styled.div`
  position: absolute;
  bottom: 4px;
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 0.75em;
  line-height: 1em;
  text-align: center;
  width: calc(100% - 8px);
  left: 4px;
  padding: 2px 0;
  display: none;
  ${Wrapper}:hover & {
    display: block;
  }
`;

const HoverState = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 22px;
  background-color: rgba(74, 144, 226, 0.5);
  border: 4px solid rgba(74, 144, 226, 1);
  display: none;
  ${Wrapper}:hover & {
    display: block;
  }
`;

export class GalleryImage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      src: LoadingIcon,
      error: false,
      loaded: false
    };

    this.onLoad = this.onLoad.bind(this);
    this.onError = this.onError.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.chooseImage = this.props.chooseImage.bind(this, this.props.image.filename);
  }

  componentDidMount() {
    const { image, site } = this.props;

    const params = {
      height: 112,
      width: 134,
      crop: "fit"
    };

    this.Image = new Image();
    this.Image.onload = this.onLoad;
    this.Image.onerror = this.onError;
    this.Image.src = image.src || clientImage(image.filename, params, site.get("id"));
  }

  componentWillUnmount() {
    // We set Image to null so we can check against it
    // in onLoad and onError functions
    //
    // If we don't do this then onLoad still triggers if we close
    // the Gallery while images are loading
    //
    // onLoad then calls setState on an unmounting or unmounted component
    // which generates a warning message
    this.Image = null;
  }

  shouldComponentUpdate(nextProps, nextState) {
    // This image was selected
    if (
      nextProps.selectedImage === this.props.image.filename &&
      this.props.selectedImage !== this.props.image.filename
    ) {
      return true;
    }

    // This image was selected and is now not selected
    if (
      this.props.selectedImage === this.props.image.filename &&
      nextProps.selectedImage !== this.props.image.filename
    ) {
      return true;
    }

    // Image source has changed - image has finished loading
    if (this.state.src !== nextState.src) {
      return true;
    }

    // Image finished loading with an error
    if (this.state.error !== nextState.error) {
      return true;
    }

    return false;
  }

  onError() {
    if (!this.Image) {
      return;
    }
    this.setState({ error: true });
    !isProduction() && console.warn("Error loading", this.props.image.filename);
  }

  onLoad() {
    if (!this.Image) {
      return;
    }
    this.setState({ src: this.Image.src, loaded: true });
  }

  isSelected() {
    return this.props.selectedImage === this.props.image.filename;
  }

  render() {
    if (this.state.error) {
      return null;
    }

    return (
      <Wrapper loaded={this.state.loaded}>
        <img src={this.state.src} width="134" height="112" />
        {this.isSelected() && <CheckMark />}
        {!this.isSelected() && <HoverState onClick={this.chooseImage} />}
        {/*<Filename>{this.props.image.filename}</Filename>*/}
      </Wrapper>
    );
  }
}

export default GalleryImage;

GalleryImage.propTypes = {
  showFilename: PropTypes.bool,
  image: PropTypes.object,
  site: PropTypes.object,
  selectedImage: PropTypes.string,
  chooseImage: PropTypes.func
};

GalleryImage.defaultProps = {
  showFilename: true,
  image: {},
  site: fromJS({}),
  selectedImage: "",
  chooseImage: () => {}
};
