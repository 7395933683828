import _ from "lodash";
import { graph } from "utils";

export const fetchPage = async ({ pageId, fields }) => {
  const query = `
    query ($pageId:ID!) {
      Page(id:$pageId){
        ${Array.isArray(fields) ? fields.join(",") : fields}
      }
    }
  `;

  const variables = {
    pageId,
  };

  const res = await graph({ query, variables });

  return _.get(res, "Page");
};

/**
 *
 * @param pageId
 * @param page
 * @param siteId
 * @returns {Promise<void>}
 */
export const updatePage = async ({ pageId, page, siteId }) => {
  const query = `mutation($id:ID!,$page:pageInput,$siteId:String){
    editPage(id:$id,page:$page,siteId:$siteId){id}
  }`;

  const variables = {
    id: pageId,
    page,
    siteId,
  };

  const res = await graph({ query, variables });
  !!_.get(res, "editPage.id");
};
