import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledButton = styled.button`
  display: inline-block;
  float: ${props => (props.noFloat ? "none" : "left")};
  width: ${props => props.config.width};
  height: ${props => props.config.height};
  font-size: ${props => props.config.fontSize};
  color: ${props => props.config.fontColor};
  border: ${props => props.config.border};
  background: ${props => props.config.bgColor};
  cursor: ${props => props.config.cursor};
  margin: ${props => props.config.margin};
  text-align: center;
  border-radius: 6px;
  padding: 0 20px;
  user-select: none;
  outline: none;
  line-height: 18px;
  transition: all 0.2s ease;
  position: relative;
  :hover {
    background: ${props => props.config.hoverBgColor};
    color: ${props => props.config.hoverColor};
    border: ${props => props.config.hoverBorder};
    opacity: ${props => props.config.hoverOpacity};
    text-decoration: ${props => props.config.underline};
  }
  div {
    display: inline-block;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    position: absolute;
  }
  i {
    opacity: ${props => (props.processing ? 1 : 0)};
    color: rgba(0, 0, 0, 0.2);
  }

  span {
    opacity: ${props => (props.processing ? 0 : 1)};
  }
`;

export const Button = ({ ...props }) => {
  let config = {
    label: props.label || "",
    width: props.width || "",
    height: props.height || "40px",
    fontSize: props.fontSize || "16px",
    fontColor: props.fontColor || "#444",
    border: props.border || "1px solid #CCC",
    background: props.background || "#FFF",
    hoverBgColor: props.hoverBgColor || "",
    hoverColor: props.hoverColor || "",
    hoverBorder: props.hoverBorder || "",
    hoverOpacity: props.hoverOpacity || "",
    cursor: props.disabled || props.processing ? "not-allowed" : "pointer",
    noFloat: !!props["no-float"],
    dataAction: props["data-action"] || "",
  };

  // BUTTON SIZES

  config.height = props.large ? "60px" : config.height;
  config.height = props.small ? "28px" : config.height;
  config.fontSize = props.small ? "14px" : config.fontSize;

  // BUTTON TYPES

  switch (props.type) {
    case "reset":
      config.hoverBgColor = "#000";
      config.hoverColor = "#FFF";
      break;
    case "warning":
      config.bgColor = "#EF4036";
      config.fontColor = "#EEE";
      config.hoverBgColor = "red";
      config.hoverColor = "#FFF";
      break;
    case "cancel":
      config.label = "Cancel";
      config.border = "none";
      config.underline = "underline";
      break;
    case "submit":
      config.bgColor = "linear-gradient(90deg, #8BD9B2 0%, #45AA91 100%)";
      config.fontColor = "#FFF";
      config.hoverOpacity = "0.75";
      config.margin = "0 0 0 20px";
      break;
    default:
      config.hoverBgColor = "#000";
      config.hoverColor = "#FFF";
  }

  config.bgColor = props.showLoadIcon || props.processing ? "#EEE" : config.bgColor;
  config.hoverBgColor = props.showLoadIcon || props.processing ? "#EEE" : config.hoverBgColor;

  const action = props.onClick || props.action;
  const onMouseDownAction = props.onMouseDownAction;
  return (
    <StyledButton
      processing={props.showLoadIcon || props.processing}
      config={config}
      onClick={action}
      onMouseDown={onMouseDownAction}
      data-action={config.dataAction}
    >
      <span>{config.label}</span>
      <div>
        <i className="fa fa-cog fa-spin" style={{ fontSize: "18px" }} />
      </div>
    </StyledButton>
  );
};

Button.defaultProps = {
  action: () => {},
  onMouseDownAction: () => {
    return;
  },
};

Button.propTypes = {
  type: PropTypes.string,
  bgColor: PropTypes.string,
  action: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  onClick: PropTypes.func,
  label: PropTypes.string,
};
