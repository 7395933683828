import React from 'react';
import Component from '../../../rootComponent';
import { css } from "@convertly/css";

//Default Data
import defaultData from '../footer-default-data';
import defaultGlobal from '../../../themes/global';

//Styles
import footerStyles from './footer-styles-06';


//Components
import Elements from '../../../elements/index';
import Widgets from '../../../widgets/index';


export default class
Footer06 extends Component {

    constructor ( props ) {

        super( props );

    }
    render() {

        this.styles = footerStyles( this.props.global, this.props.data );

        if( this.props.editor ) {
            return this.editor();
        }
        const inlineStyle = this.generateStyleAttribute();
        return (

                    <Elements.ModuleWrapper
                        parentClassName={ this.styles.heroContainer }
                        data={ this.props.data }
                        global={ this.props.global }
                        elementDataName="backgroundColor"
                        moduleId={ this.props.id }
                        moduleSort={ this.props.sort } >

                        <Elements.ContainerFluid>

                            <Elements.Row>

                                <div className={css(this.styles.header)}>

                                    <footer className={css( this.styles.mainFooter ) }
                                        onClick={(e) => {
                                                           this.launchElementalEditor('footer', e);
                                                      }}
                                                      onMouseOver={ this.addEditableOutline }
                                                      onMouseOut={ this.removeEditableOutline }
                                                      { ...inlineStyle }
                                                      { ...this.generateDataEditorAttribute() }
                                    >

                                                        {/** Footer Logo **/}


                                                        <Elements.Image
                                                            parentFigureClassName={ this.styles.logo }
                                                            parentImgClassName={ this.styles.logo }
                                                            data={ this.props.data.logo }
                                                            global={ this.props.global }
                                                            elementDataName="logo"
                                                            moduleId={ this.props.id }
                                                            moduleSort={ this.props.sort }/>


                                                    <div className={ css( this.styles.footerCopyText ) }>

                                                    {/** Copy Text **/}
                                                    <div className={ css( this.styles.copyRightText ) }>

                                                        <Elements.Paragraph
                                                            parentClassName={ this.styles.copyText }
                                                            data={ this.props.data.paragraph }
                                                            global={ this.props.global }
                                                            elementDataName="paragraph"
                                                            moduleId={ this.props.id }
                                                            moduleSort={ this.props.sort }
                                                        />

                                                    </div>
                                                    {/** Privacy Links **/}
                                                    <ul className={ css( this.styles.footerNavLinks ) } >
                                                        {
                                                            this.props.data.copyRightLinks.map( ( item, index )  => {
                                                                return (
                                                                    <li key={ index } className={ css( this.styles.copyRightLinks ) } >
                                                                        <Elements.WebLink
                                                                            data={ item }
                                                                            moduleId={ this.props.id }
                                                                            moduleSort={ this.props.sort }
                                                                        />
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                    </div>

                                                </footer>

                                </div>

                            </Elements.Row>

                        </Elements.ContainerFluid>

                    </Elements.ModuleWrapper>

        )
    }

}

Footer06.defaultProps= {
    data: defaultData,
    global: defaultGlobal
};
