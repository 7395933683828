import { fromJS } from "immutable";
import { createReducer } from "redux-act";
import { setSite } from "@convertly/utils";
import LocalStorage from "../../utils/localStorage";
import Logger from "../../utils/logger";

import {
  setUser,
  setAccount,
  setSites,
  restoreSavedUserSession,
  setCurrentSiteSettings,
  setCurrentSite,
  setAuthLoginToken,
  setAuthenticationSetupComplete,
  setAuthAccessToken,
  setAccessToken,
  setSite as setAuthSite,
} from "./actions";

const initialState = fromJS({
  user: {},
  token: null,
  accessToken: null,
  activeSite: {},
  authToken: null,
  sites: [],
  setupComplete: false,
});

const reducers = {
  [setAuthenticationSetupComplete]: (state, setupComplete) => {
    return state.merge({ setupComplete: true });
  },

  // This is the Cognito Access Token
  [setAccessToken]: (state, accessToken) => {
    return state.merge({
      token: accessToken,
      accessToken,
    });
  },

  // This is the Authentication Token
  [setAuthLoginToken]: (state, authToken) => state.merge({ authToken }),

  [setCurrentSite]: (state, activeSite) => {
    setSite(activeSite);
    return state.merge({ activeSite });
  },

  [setCurrentSiteSettings]: (state, activeSite) => state.merge({ activeSite }),

  [setAuthSite]: (state, activeSite) => state.merge({ activeSite }),

  [setAccount]: (state, account) => state.merge({ account }),

  [setSites]: (state, sites) => state.merge({ sites }),

  [setUser]: (state, user) => {
    return state.merge({ user });
  },
};

export default createReducer(reducers, initialState);
