import { createAction } from "redux-act";
import { uploadImages } from "services/sites";

import { setProcessing } from "../../actions/navBarActions";

export const setActiveImageOption = createAction("PANEL ASSETS: Set active image option");
export const setActiveAsset = createAction("PANEL ASSETS: Set active asset");
export const setSearch = createAction("PANEL ASSETS: Set current search query");

export const upload = files => async dispatch => {
  await dispatch(setProcessing(true));
  await dispatch(uploadImages(files, true));
  await dispatch(setProcessing(false));
};

export const load = () => async dispatch => {
  // might need to do something here
};

export const reset = () => async dispatch => {
  await Promise.all([
    dispatch(setSearch("")),
    dispatch(setActiveImageOption("ALL_IMAGES")),
    dispatch(setActiveAsset("IMAGES")),
  ]);
};
