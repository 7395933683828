export default function productImagesGalleryStyleObject(global, data) {

    return {

        ".productgridcontainer": {
            color: global.textColors.dark.black,
            display: "flex",
            flexDirection: "row",
            fontSize: "14px",
            lineHeight: "20px",
            position: "relative",
            width: "100%"
        },

        ".productboxcontainer": {
            alignItems: "flex-start",
            color: global.textColors.dark.black,
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            lineHeight: "20px",
            listStyle:'none',
        },
        ".productboxList": {
            listStyle:'none'
        },
        ".productbox": {
            border: "2px solid transparent",
            color: global.textColors.dark.black,
            fontSize: "14px",
            height: "65px",
            width: "65px",
            lineHeight: "20px",
            marginBottom: "10px",
            opacity: "1",
            paddingTop: "17px",
            textAlign: "center",
            padding:'0',
            cursor:"pointer",
            ".active": {
                borderColor:global.backgroundColors.dark.color1,
                border: "2px solid #253039",
                color: global.textColors.dark.black,
            },
            ':hover': {
                borderColor:global.backgroundColors.dark.color1,
                border: "2px solid #253039",
                color: global.textColors.dark.black,
            },
            ':last-child': {
                marginBottom:'0'
            },
        },
        ".productThumbnail": {
            height:'100%',
            width:'100%',
            transition: "border 200ms ease",
            "object-fit":"cover"
        },

        ".productimgcontainer": {
            color: global.textColors.dark.black,
            flex: "0 auto",
            fontSize: "14px",
            lineHeight: "20px",
            paddingLeft: "10px",
            width: "100%"
        },

        ".large_productbox": {
            backgroundColor: "transparent",
            border: global.border,
            color: global.textColors.dark.black,
            fontSize: "14px",
            lineHeight: "20px",
            marginBottom: "10px",
            height:'364px',
            maxWidth: "100%",
            minHeight: "100%",
            opacity: "1",
            //paddingTop: "17px",
            textAlign: "center",
            transition: "border 200ms ease",
            width: "auto",
        },
        ".featureImage": {
            maxHeight:'100%',
            maxWidth:'100%',
            minHeight:'100%',
            minWidth:'100%',
            "object-fit":"cover"
        }


    }


}