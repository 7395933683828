import { StyleSheet } from "@convertly/css";
import Typography from '../../../elements/style-functions/typography';

const footerNavListStyles = ( global, local ) => {

    const navTitleTypography = new Typography( global, local.listHeader );

    return StyleSheet.create({
        footerNavTitle: {
            ...navTitleTypography,
        },
    })
};

export default footerNavListStyles;