import React from 'react';
import Component from '../../rootComponent';

class SouljaBoy extends Component {
  constructor(props) {
    super(props);
    this.state = { elementEditableBorder: "" };
  }
  render() {
    const inEditor = this.state.inEditor;
    let elementEditableBorder = {};
		const { animated } = this.props.data;
		const inlineStyle = this.generateStyleAttribute();

    if(!this.props.notEditable) {
      if(inEditor) {
        elementEditableBorder.outline = this.state.elementEditableBorder;
      }
    }

    if(inEditor) {
      this._reactInternalInstance
          ._hostParent
          ._hostNode
          .style
          .outline = this.state.elementEditableBorder ? 'none' : '';
    }

    return(
      <div
        className={ className }
        style={ elementEditableBorder }
        data-animated={animated}
        onMouseOver={ this._addWrapperOutline }
        onMouseOut={ this._removeWrapperOutline }
        { ...inlineStyle }
        { ...this.generateDataEditorAttribute() }
        { ...generateId(this.props.divId) }>

          { renderChildren(this.props) }
      
      </div>
    )
  }

  _renderChildren(props) {
    const {
      children,
      id,
      moduleSort,
      site,
      styles,
      page,
      sliderSettings
    } = props;

    return React.Children.map(children, child => {
      return React.cloneElement(child, {
        moduleId: id,
        moduleSort: moduleSort,
        site: site,
        styles: styles,
        page: page,
        sliderSettings: sliderSettings ? sliderSettings : null
      });
    })
  }

  _removeWrapperOutline = (e) => {
    if (
      (!this.context.store.getState().get('editor').editorReducer.get('activeModuleId') || 
      (this.context.store.getState().get('editor').editorReducer.get('editorType').get('field') !== this.props.id))
    ) {
      this.setState({
        elementEditableBorder: ""
      });
      e.hoverOverChild = false;
    } 
  }

  _addWrapperOutline = (e) => {
    if (!e.hoverOverChild) {
      this.setState({
        elementEditableBorder: "6px dashed #F5A623 "
      });
    }
  }

}

export default SouljaBoy;