import React from 'react';
import Component from '../../../rootComponent';
import { css } from "@convertly/css";

//Default Data
import defaultData from '../footer-default-data';
import defaultGlobal from '../../../themes/global';

//Styles
import footerStyles from './footer-styles-1';

//Components
import SocialIcons1 from '../../../widgets/social-icons/no-background-social-icons/social-icon-list-1';


export default class
Footer1 extends Component {

	constructor ( props ) {
		super( props );
	}
	render() {

        this.styles = footerStyles( this.props.global, this.props.data );

        if( this.props.editor ) {
            return this.editor();
        }
        const inlineStyle = this.generateStyleAttribute();
		return (
			<footer id="footer01" className={css( this.styles.mainFooter ) }
			  onClick={(e) => {
                   this.launchElementalEditor('footer', e);
              }}
              onMouseOver={ this.addEditableOutline }
              onMouseOut={ this.removeEditableOutline }
              { ...inlineStyle }
              { ...this.generateDataEditorAttribute() }
			>
				<div className="row">
					<SocialIcons1 global={ this.props.global } data={ this.props.data } />
				</div>
				<div className="row">
					<p className={ css( this.styles.copyText ) }>{ this.props.data.copyRightText.text }</p>
				</div>
			</footer>
		)
	}

}

Footer1.defaultProps= {
	data: defaultData,
	global: defaultGlobal
};
