import React, { Component } from "react";
import { connect } from "react-redux";
import { fromJS } from "immutable";

import Logger from "utils/logger";
import config from "./config";
import * as actions from "./centralActions";
import { getChatThreads, setPubNub } from "./chatServiceActions";

class ChatService extends Component {
  constructor(props) {
    super(props);
    this.messageHandler = this.messageHandler.bind(this);
  }

  // use this life cycle hook to inject the PubNub library
  // Init the library and set up initial subscriptions
  // when the library has loaded
  componentDidMount() {
    // inject the PubNub library
    const s = document.createElement("script");

    s.type = "text/javascript";
    s.src = config.library_src;

    const sites = this.props.user.get("sites").filter(s => s.get("chatEnabled"));
    let channels = sites.map(s => `messages.${s.get("id")}`);

    if (!channels.size) {
      return;
    }

    this.props.getChatThreads();

    channels = channels.push(`user.${this.props.user.get("id")}`);

    Logger.chat("Subscribing to channels", channels.toJS());

    this.channels = channels.toJS();

    // need to wrap with bind so 'this' is bound to ChatService
    s.onload = function() {
      // Will be changed to authenticated USER ID
      const ID = Math.round(Math.random() * 1000000);

      this.PubNub = new PubNub({
        publish_key: config.publish_key,
        subscribe_key: config.subscribe_key,
        ssl: location.protocol.toLowerCase() === "https:",
        uuid: ID
      });

      this.PubNub.addListener({
        message: this.messageHandler.bind(this)
      });

      this.PubNub.subscribe({
        channels: this.channels
      });

      this.PubNub.setState({
        state: {
          online: true,
          zorkId: this.props.user.get("id")
        },
        channels: this.channels
      });

      this.props.setPubNub(this.PubNub);
    }.bind(this);

    document.head.appendChild(s);
  }

  componentWillUnmount() {
    this.PubNub.setState({
      state: {
        online: false,
        zorkId: this.props.user.get("id")
      },
      channels: this.channels
    });
  }

  componentWillUpdate() {}

  // use this life cycle hook to check if
  // watcher arrays have changed
  componentDidUpdate() {
    const { messagesToSend } = this.props;

    if (messagesToSend.length) {
      messagesToSend.map(message => {
        const payload = {
          message: {
            text: message.text,
            action: message.action ? message.action : config.action.newMessage
          },
          channel: message.channel
        };

        this.PubNub.publish(payload);

        this.props.processSentMessage(message);
      });
    }
  }

  messageHandler({ channel, message }) {
    const { action } = message;

    if (!action) {
      return;
    }

    if (typeof actions[action] === "function") {
      this.props.handleMessage(action, message);
    } else {
      console.log(action, "not valid action");
    }
  }

  render() {
    return <div />;
  }
}

const mapStateToProps = state => {
  return {
    unclaimedChatThreads: state.get("chatService").get("unclaimedChatThreads"),
    claimedChatThreads: state.get("chatService").get("claimedChatThreads"),
    historyChatThreads: state.get("chatService").get("historyChatThreads"),
    messagesToSend: state.get("chatService").get("sendMessageQueue"),
    user: state.get("auth").get("user")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setPubNub: p => dispatch(setPubNub(p)),
    getChatThreads: () => dispatch(getChatThreads()),
    handleMessage: (action, message) => dispatch(actions[action](message)),
    setPendingChatThreads: threads => {
      dispatch(setPendingChatThreads(threads));
    },
    removeChannelToSubscribe: channel => {
      dispatch(removeNewChannelToSubscribe(channel));
    },
    setActiveChatThreads: activeChatThreads => {
      dispatch(setActiveChatThreads(activeChatThreads));
    },
    processSentMessage: message => {
      dispatch(processSentMessage(message));
    }
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatService);

export default Container;
