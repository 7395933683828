import { StyleSheet } from '@convertly/css';
import UserOverrides from '../../elements/style-functions/user-overrides';

const FooterWrapperStyles = ({ global, data, inEditor }) => {

  const userOverrides = UserOverrides(global, data, inEditor);

  return StyleSheet.create({
    footer: {
      ...userOverrides
    }
  })

}

export default FooterWrapperStyles;