import {StyleSheet} from '@convertly/css';
import { clientImage } from '../../utils/generateImage'
import UserOverrides from '../../elements/style-functions/user-overrides';
import _ from 'lodash';

const Styles = (global, data) => {

    const userOverrides = UserOverrides(global, data);

    /** increases zIndex if there is an overlay or backgrond Image **/
    let zIndex = {};

    let hoveringImgOverrides = _.get(data, 'featureImage.userOverrides') ?
        UserOverrides(global, data.featureImage) : {};

    if(data.backgroundVideo || data.overlay) {
        if(data.backgroundVideo) {
            zIndex = {
                zIndex: 2
            }
        }
        if(data.overlay) {
            zIndex = {
                zIndex: 3
            }
        }

    }
    let overflow = {};
    if(data.backgroundVideo) {
        overflow = {
            overflow:'hidden'
        }
    }

    let imgAlign   = data.alignImgLeft ? { left: 0, top: 115 } : { right: 0 },
        heroHeight = data.alignImgLeft ? { minHeight: '600px' } :
                                         { minHeight: '700px' },
        heroBg     = data.alignImgLeft ? 'banner-homepage-secondary-compressor.jpg' : 'banner-homepage-hero-compressor.jpg'

    /** Checks if there is a bg image in userOverrides **/
    let background = _.get(data, 'userOverrides.background') ?
        {}
    : { backgroundImage: `url(${ clientImage( heroBg ) })`,
        backgroundSize:'cover'};



    return StyleSheet.create({
        homePageHero: {
            display: "block",
            position:'relative',
            alignItems: "center",
            backgroundPosition: "0px 0px, 50% 0px",
            backgroundSize: "auto, cover",
            "@media (max-width: 1200px)": {
                paddingTop: "0"
            },
            ...background,
            ...heroHeight,
            ...userOverrides
        },

        bottomImg: {
            position: "absolute",
            top: '125px',
            zIndex: 10,
            opacity: 1,
            height: 'auto',
            width: "45%",
            "@media(max-width:1200px)": {
                width: "40%"
            },
            "@media(max-width:991px)": {
                width: "40%"
            },
            "@media(max-width: 767px)": {
                width: "550px",
                top: 'auto',
                bottom: "-430px"
            },
            "@media(max-width: 600px)": {
                width: "420px",
                maxWidth: "95%",
                bottom: "-55%"
            },
            ...imgAlign,
            ...hoveringImgOverrides
        },
        convertlyContentArea: {
            ...zIndex,
            position:"relative"
        },
        overlay: {
            position: "absolute",
            height:'100%',
            width:'100%',
            top:0,
            left:0,
            background:data.overlay ? data.overlay.background : '',
            zIndex:2
        }

    })

};

export default Styles;