import { store } from "app/app";
import { graph } from "utils/graph";
import { purgeSiteCache, cache } from "utils/purge";
import { v4 as generateId } from "uuid";
import { setEditorSaveState, setPageSnapshot } from "./editorActions";
import { loadCommonElements } from "services/sites";
import { Alerts } from "services/Alerts";

/**
 *
 * @param publish
 * @param saveState Flag for if we should treat this as a save state
 * @param productId
 * @returns {function(*)}
 */
export const savePage = ({ publish }, saveState = false, productId) => async dispatch => {
  const Editor = store.getState().get("editor").editorReducer;
  const page = Editor.get("page").toJS();
  const site = Editor.get("currentSite");
  const elements = Editor.get("pageElements").toJS();
  const snapshot = Editor.get("snapshot");
  const updatedSnapshot = snapshot.set("elements", Editor.get("pageElements"));
  const domain = site.get("domain");
  const previewDomain = `${site.get("previewDomain")}.convertly.io`;

  let i = 1;

  elements.sort(function(a, b) {
    return a.sort - b.sort;
  });

  elements.forEach(obj => {
    obj.sort = i;
    i++;
  });

  if (elements.length) {
    elements[0].sort = 1;
  }

  const { id } = page;

  // Filter out the unique elements ( header and footer )
  let elementsToSave = elements.filter(el => !el.unique);

  const uniqueElements = elements.filter(el => el.unique);

  let clean = cleanElements(elementsToSave, null);

  let flat = flatten(clean);
  let random = shuffleArray(flat);

  let query;
  let variables;

  if (!productId) {
    query = `
				mutation($saveState:Boolean,$publish:Boolean,$id:ID!,$page:pageInput,$siteId:String){
					editPage(saveState:$saveState,publish:$publish,id:$id,page:$page,siteId:$siteId){id,elementsMutated}}
		   `;

    variables = {
      page: {
        elements: JSON.stringify(random),
      },
      id: id,
      siteId: site.get("siteId"),
      publish,
      saveState,
    };
  } else {
    query = `mutation($id: ID!, $elements:String, $flat:Boolean){
						saveProductElements(id:$id, elements:$elements, flat:$flat) {
								id
						}
				}`;
    variables = {
      id: productId,
      elements: JSON.stringify(random),
      flat: true,
    };
  }

  const saveUniqueQuery = `
		mutation( $saveState:Boolean,$publish:Boolean,$elements:String,$siteId:String ) {
			editCommonElements( saveState:$saveState,publish:$publish,elements:$elements,siteId:$siteId ) {
				id
			}
		}
		`;

  const saveUniqueVariables = {
    elements: JSON.stringify(uniqueElements),
    siteId: site.get("siteId"),
    publish,
    saveState,
  };

  const purgeSiteScreenShotQuery = `
		mutation($id:ID!,$site:siteInput) {
			editSite(id:$id,site:$site){
				id
			}
		}
		`;

  const purgeSiteScreenShotVariables = {
    id: site.get("siteId"),
    site: {
      screenshot: "",
    },
  };

  const results = await Promise.all([
    graph({ query, variables }),
    graph({ query: saveUniqueQuery, variables: saveUniqueVariables }),
    graph({ query: purgeSiteScreenShotQuery, variables: purgeSiteScreenShotVariables }),
  ]);

  const savePageResult = results[0];
  const elementsMutated = _.get(savePageResult, "editPage.elementsMutated");

  if (publish && domain) {
    await purgeSiteCache(domain);
  }

  await purgeSiteCache(previewDomain);

  const fetchScreenshotQuery = `
			query($id:ID!){ Site(id:$id){screenshot} }
		`;

  await Promise.all([
    dispatch(loadCommonElements({ id: site.get("id") })),
    cache(`${previewDomain}${page.slug}`),
    graph({ query: fetchScreenshotQuery, variables: { id: site.get("id") } }),
  ]);

  if (publish && domain) {
    await cache(`${domain}${page.slug}`);
  }

  await dispatch(setPageSnapshot(updatedSnapshot));
  await dispatch(setEditorSaveState(false));

  if (elementsMutated) {
    await Alerts.alert(
      "The page contents have changed since saving. This usually happens when you add a form to a page. The page will reload to reflect the updated contents."
    );
    location.reload();
  }
};

function flatten(array) {
  return flattenFrom(array);
}

function flattenFrom(array) {
  return flattenDown(array, []);
}

function flattenDown(array, result) {
  for (let i = 0; i < array.length; i++) {
    let value = array[i];

    if (Array.isArray(value.children)) {
      flattenDown(value.children, result);
      delete value.children;
    }

    result.push(value);
  }

  return result;
}

function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

function cleanElements(elementsArr, parentId) {
  if (Array.isArray(elementsArr)) {
    elementsArr.sort((a, b) => {
      return a.sort - b.sort;
    });

    let currentSort = 1;

    return elementsArr.map(o => {
      o.a = generateId();
      o.b = parentId;
      o.c = currentSort++;
      if (o.sort) delete o.sort;

      if (o.userOverrides) {
        o.p = o.userOverrides;
        delete o.userOverrides;
      }

      if (o.data) {
        if (o.data.site) delete o.data.site;
        if (o.data.global) delete o.data.global;
        if (o.data.activeSlide) delete o.data.activeSlide;
        if (o.data.userOverrides) {
          o.data.a = o.data.userOverrides;
          delete o.data.userOverrides;
        }
      }
      o.d = o.data;
      delete o.data;

      if (o.site) delete o.site;
      if (o.page) delete o.page;
      if (o.global) delete o.global;
      delete o.parentId;

      if (o.type) delete o.type;
      if (o.id) delete o.id;
      if (o.label) delete o.label;
      if (o.editable) {
        o.z = 1;
      }
      delete o.editable;

      o.e = o.el;
      delete o.el;

      o.children = cleanElements(o.children, o.a);
      return o;
    });
  } else {
    console.log("array error", elementsArr);
    return elementsArr;
  }
}
