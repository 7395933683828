/*global Inferno, $ */
/* eslint-disable */
import {include} from '@convertly/thor';

const Script = (id) => {
	
	/** Imports **/
	const CouponCodeField = include('./components/coupon-code-inferno', 'CouponCodeField');
	const CheckoutTotalsField = include('./components/checkout-totals-inferno', 'CheckoutTotalsField');
	const Graph = include('../../../../utils/graph', 'Graph');
	const Dispatcher = include('../../../../utils/dispatcher', 'Dispatcher');
	const ConvertToCurrency = include('../../../../utils/ecomm-utils/convert-to-currency-ii', 'ConvertToCurrency');
	const ShippingMethodModal = include('../../../../ecommerce-common-components/shipping-widgets/shipping-widget', 'ShippingMethodModal');
	const SpinnerSVGInferno = include('../../../../svg/spinner/spinner', 'SpinnerSVGInferno');
	const CartItemInferno = include('../../../cart/components/cart-item', 'CartItemInferno');
	const Parser = include('../../../../utils/json-parse-catch', 'Parser');
	
	return (id, clientImage, siteId, siteLink) => {
		
		const parser = Parser()
		
		const filename = 'checkout-cart-review-inferno';
		
		const hasLocalStorage = $('html').hasClass('localstorage');
		
		const root = document.getElementById(id);
		
		
		/** Init Imports **/
			//utils
		const convertToCurrency = ConvertToCurrency();
		const graph = Graph();
		const dispatch = Dispatcher(id);
		
		
		//Components
		const CartItem = CartItemInferno(dispatch, convertToCurrency, clientImage, siteLink);
		const SpinnerSVG = SpinnerSVGInferno();
		const CouponCodeFieldInferno = CouponCodeField();
		const ShippingMethodModalInferno = ShippingMethodModal(dispatch, graph, siteId);
		const CheckoutTotalsInferno = CheckoutTotalsField(dispatch, graph, siteId, convertToCurrency, SpinnerSVG, parser);
		
		let shippingAddress;
		if (hasLocalStorage) {
			shippingAddress = localStorage.getItem('shippingAddress');
			shippingAddress = parser(shippingAddress);
			
		}
		
		const Cart = Inferno.createClass({
			
			
			getInitialState: function () {
				return {
					items: [],
					subtotal: parseFloat('0.00'),
					shippingRate: null,
					displayShippingMethodModal: false,
					shippingAddress: shippingAddress,
					cartSubTotal: 0,
					rates: null,
					mobileCartClassActive: false,
					mobileCartClass: '',
					cartEmpty: false
				};
			},
			
			/** Gets cart and shipping data on load */
			componentWillMount: function () {
				this.getShippingRate();
				
				dispatch.on('full_cart', this.processFullCart);
				dispatch.on('cart', this.getCartInfo);
				dispatch.on('update_ShippingAddress', (shippingAddress) => {
					this.updateShippingAddress(shippingAddress)
				});
				
				this.initShippingModalListeners();
				
			},
			
			
			/** **/
			processFullCart: function ({items, cartId}) {
				if (cartId) {
					this.setState({
						cartId
					}, this.getCartTotal);
				}
			},
			
			
			/** listens for cart event and creates cart items */
			getCartInfo: function (cart) {
				
				if (cart.length === 0) {
					this.setState({
						items: cart,
						cartEmpty: true
					});
					window.location.replace(`${siteLink}/cart`);
				}
				else {
					this.setState({items: cart});
				}
				
				this.getCartTotal();
			},
			
			
			/** Gets shipping rates */
			/** Makes Shipping API request **/
			getShippingRate: function () {
				
				if (hasLocalStorage) {
					
					let shippingRate = localStorage.getItem('shipping_rate');
					this.setState({shippingRate: shippingRate})
					
				}
			},
			
			
			updateShippingRates: function (err, response) {
				
				if (response.shipping.rates) {
					this.setState({rates: response.shipping.rates});
				} else {
					
					
					// error handling
				}
			},
			
			
			/** Updates shipping Address **/
			updateShippingAddress(shippingAddress) {
				this.setState({shippingAddress: shippingAddress});
				//this.getShippingOptions( );
			},
			
			/** Launches shipping modal */
			initShippingModalListeners: function () {
				dispatch.on('launch_shipping_method_modal', () => {
					this.setState({
						displayShippingMethodModal: true,
						displayOverlay: false,
					})
				});
				
				dispatch.on('shipping_rates_loaded', () => {
					this.setState({displayOverlay: true})
				});
				
				dispatch.on('close_shipping_method_modal', () => {
					this.setState({displayShippingMethodModal: false})
				})
			},
			
			/** Gets total of all items in cart **/
			getCartTotal: function (cart) {
				
				if (!this.state.cartId) {
					return;
				}
				
				let cartTotal = 0;
				let cartItemPrice = 0;
				
				const {items} = this.state;
				
				items.forEach((cartItem) => {
					cartItemPrice = ( parseFloat(cartItem.finalPrice) * 100 );
					cartItemPrice = cartItemPrice * cartItem.quantity;
					cartTotal += ( cartItemPrice / 100 );
				});
				
				dispatch.send('cart_total', cartTotal);
				dispatch.update('cart_total', cartTotal);
				
				this.setState({cartSubTotal: cartTotal});
			},
			
			/** Opens and closes cart flow **/
			toggleMobileCartClass: function () {
				mobileCartClass = !this.state.mobileCartClassActive;
				if (mobileCartClass) {
					this.setState({
						mobileCartClassActive: mobileCartClass,
						mobileCartClass: ' cart-open'
					})
				}
				else {
					this.setState({
						mobileCartClassActive: mobileCartClass,
						mobileCartClass: ' cart-not-open '
					})
				}
				
			},
			
			render: function () {
				let CartItems;
				/*** Loads cart items **/
				
				CartItems =
					this.state.items.map((item, index) => {
						return (
							<CartItem noQtyAdjust={ true } rootDiv={ root } item={ item }/>
							/*
							 <div className={ root.className + " chkProdgrid" }>
							 <div className={ root.className + " chkProdgridimgcontainer" }>
							 <img
							 className={ root.className + " chkProdgridimg" }
							 src={ clientImage + item.image }/></div>
							 <div className={ root.className + " chkGridprice" }>{ item.title }
							 </div>
							 <div className={ root.className + " chkGridqty" }>{ item.quantity }</div>
							 <div
							 className={ root.className + " chkGridunitprice" }>{ convertToCurrency(item.finalPrice * item.quantity) }</div>
							 </div>
							 */
						)
					});
				
				/** Loads shipping method modal **/
				let ShippingMethodModal = null;
				if (this.state.displayShippingMethodModal) {
					
					ShippingMethodModal =
						<div>
							{ this.state.displayOverlay ? <div className={ `${root.className} overlay` }/> : null  }
							<ShippingMethodModalInferno rootDiv={ root }
																					shippingAddress={ this.state.shippingAddress }
																					shippingOptions={ this.state.rates }
							/>
						</div>
				}
				
				
				if (this.state.items.length !== 0) {
					return (
						<div className={ root.className + " cartColumn" }>
							<div className={ root.className + " mobile-cart-dropdown" }>
								<p className={ root.className + " chkTitle1_mobileTitle1" }>
									Your Selection
									{/*<strong>Your Cart
									 Subtotal</strong> {`${ convertToCurrency(this.state.cartSubTotal) }`} */}</p>
								
								{/** Mobile Cart Toggle **/}
								<p className={ `${root.className} mobileCartToggle` }
									 onClick={ this.toggleMobileCartClass }>
									{ this.state.mobileCartClassActive ? "COLLAPSE" : "EXPAND" }</p>
							</div>
							{ /** Container **/ }
							<div className={ root.className + " checkRightPanel " + this.state.mobileCartClass }>
								
								<div
									className={ root.className + " chkRightPanelContainer" + this.state.mobileCartClass }>
									{ /** Mobile Title **/ }
									
									
									{ /** Cart Grid **/ }
									<div className={ root.className + " chkProductgrid" }>
										<div className={ root.className + " chkGridheadercontainer" }>
											<div className={ root.className + " chkProdgridimgcontainer" }></div>
											{ /** Grid Titles **/ }
											
											<div className={ root.className + " chkGridprice_th" }>Item</div>
											<div className={ root.className + " chkGridqty_chkUnitprice_th" }>
												<span className={ `${root.className} chkGridqty_chkUnitprice_th-text` }>Unit Price</span>
											</div>
											<div className={ root.className + " chkGridqty_th" }>QTY</div>
											<div className={ root.className + " chkGridsubtotal_th" }>Subtotal</div>
										</div>
										
										{ /** Product List **/ }
										{ CartItems }
									
									</div>
									{ /** Coupon Code **/ }
									<CouponCodeFieldInferno display={ false } rootDiv={ root }/>
									
									{ /** Totals **/ }
									<CheckoutTotalsInferno cart={ this.state.items }
																				 cartSubTotal={ this.state.cartSubTotal }
																				 shippingAddress={ this.state.shippingAddress }
																				 shippingRate={ this.state.shippingRate }
																				 rootDiv={ root }/>
								
								</div>
							</div>
							{ ShippingMethodModal }
						</div>
					)
				}
				else if (this.state.cartEmpty) {
					return (
						<div className={ `${root.className} flexMiddle_middleRight` }>
							<div className={ `${root.className} checkRightPanel ${ this.state.mobileCartClass }` }>
								<div className={ `${root.className} empty-cart` }>
									<div>Your cart is empty</div>
									<a href={ `${siteLink}/` } className={ `${root.className} empty-cart-link` }>Continue Shopping</a>
								</div>
							</div>
						</div>
					)
				}
				else {
					return (
						<div className={ `${root.className} flexMiddle_middleRight` }>
						</div>
					)
				}
			}
			
			
		});
		
		
		Inferno.render(<Cart />, root);
		
	};
	
};

export default Script;
