import { StyleSheet } from "@convertly/css";
import UserOverrides from "../style-functions/user-overrides";


const dividerStyles = (global, data, inEditor) => {
  const userOverRides = new UserOverrides(global, data, inEditor);

  return StyleSheet.create({
    divider: {
      ...global.divider,
      ...userOverRides,
    },
  });
};

export default dividerStyles;
