import React from 'react';
import Component from 'source/rootComponent';
import { css } from '@convertly/css';

// Components
import { ScrollButton } from './components/ScrollButton';
import Elements from 'source/elements';

// Styles
import FullPageScrollingBannerStyles from './FullPageScrollingBannerStyles';

// Scripts
import { generateId } from '../../utils/generate-attribute';
import { clientImage } from '../../utils/generateImage';

export class FullPageScrollingBanner extends Component {

  render() {

    const inlineStyle = this.generateStyleAttribute();
    const inEditor = this.inEditor();
    const { global, data, site } = this.props;
    const { scrollButton, heading, location, backgroundImage } = data;
    const styles = FullPageScrollingBannerStyles({ global, data, inEditor });
    const { city, state } = site.settings || {};
    const { src, alt, title, imgParams } = backgroundImage || {};

    const { 
      banner,
      content,
      locationStyles,
      headingStyles,
      backgroundImageStyles
    } = styles;

    return (
      <div 
        className={ css(banner) }
        { ...inlineStyle }
        { ...this.generateDataEditorAttribute() }
        { ...generateId(this.props.elementId) }>

        <Elements.Image
            data={{
              src,
              imgParams,
              alt,
              title
            }}
            parentClass={css(backgroundImageStyles)}
        />

        <div className={ css(content) }>
          <p className={ css(locationStyles) }>{ city }, { state }</p>

          <h1
            className={ css(headingStyles) }>
            { heading.text }
          </h1>

        </div>

        <ScrollButton
            global={ global }
            data={ scrollButton }
            inEditor={ inEditor }/>
            
      </div>
    )
  }

}