import React from "react";
import styled from "styled-components";
import { HeaderLink } from "./HeaderLink";
import { Link } from "react-router";
import { permissionCheck } from "containers/Authentication/PermissionsCheck";

const HeaderSubItem = styled.li`
  position: relative;
  margin-left: -4px;
  display: block;
  width: calc(100% + 4px);
  min-height: 42px;
  padding: 12px 12px 12px 39px;
  font-size: 14px;
  &:hover {
    background-color: #ddd;
    color: #000;
  }
  color: #555;
  & > a:hover {
    text-decoration: none;
  }
  ${props => (props.active ? `background-color: #DDD;` : `background-color: #EFEFEF;`)}
  ${props => (props.hide ? `display: none;` : ``)}
`;

// StyledLink is used to exapand the clickable area for subnav //
const StyledLink = styled(Link)`
  display: block;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export class SubMenu extends React.Component {
  renderSubItem(subItem, index) {
    const { setActiveMenu, activeChild } = this.props;
    return (
      <HeaderSubItem
        active={activeChild === index}
        key={index}
        onClick={setActiveMenu.bind(this, index)}
      >
        <StyledLink to={subItem.url}>{subItem.label}</StyledLink>
      </HeaderSubItem>
    );
  }

  render() {
    const { items, setActiveMenu, activeChild } = this.props;
    return (
      <ul>
        {items.map((subItem, index) => {
          return permissionCheck(subItem.permission) ? this.renderSubItem(subItem, index) : null;
        })}
      </ul>
    );
  }
}
