import Vendor from "logrocket";
import { is } from "containers/Authentication/PermissionsCheck";

const LogRocket = {
  init: () => {},
  setUser: () => {},
  captureMessage: () => {},
};

if (process.env.STAGE === "production" && window.location.hostname.indexOf("localhost") !== 0) {
  LogRocket.init = () => Vendor.init("j5pjfj/convertly");

  LogRocket.setUser = user =>
    Vendor.identify(user.id, {
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
      partner: is("partner"),
      developer: is("developer"),
    });

  LogRocket.captureMessage = msg => Vendor.captureMessage(msg);
}

export { LogRocket };
