import React, { Component, useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { fromJS } from "immutable";
import gravatar from "gravatar";
import { Link } from "react-router";
import { css } from "@convertly/css";

import styles from "./css-header-styles";
import { logout } from "containers/Authentication/actions";
import AvatarIcon from "components/icons/avatar.svg";

const Panel = styled.div`
  animation-duration: 250ms !important;
`;

const Container = styled.div`
  position: relative;
  margin-left: 10px;
`;

const ProfileSet = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  height: 46px;
  padding: 0 20px;
  cursor: pointer;
`;

// this uses old context api to get dispatch
// don't feel like updating it right now
// so left it as a class
class LogoutLink extends Component {
  constructor(props) {
    super(props);

    this.logout = this.logout.bind(this);
  }

  logout() {
    this.context.store.dispatch(logout());
  }

  render() {
    return (
      <div className={css(styles.logoutContainer)}>
        <a onClick={this.logout} className={css(styles.emailLink)}>
          Log Out
        </a>
      </div>
    );
  }
}

LogoutLink.contextTypes = {
  store: PropTypes.object,
};

const Avatar = ({ user }) => {
  const calculateInitials = useCallback(user => {
    if (!user.get("first_name") || user.get("first_name") === "first_name") {
      return null;
    }

    return `${user.get("first_name", "").charAt(0)}${user.get("last_name", "").charAt(0)}`;
  }, []);

  const [initials, setInitials] = useState(() => calculateInitials(user));

  useEffect(() => {
    setInitials(calculateInitials(user));
  }, [user]);

  return (
    <div className={css(styles.profileAvatar)}>
      {initials ? initials : <img src={AvatarIcon} />}
    </div>
  );
};

export const ProfileLink = ({ user }) => {
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const toggleProfileView = useCallback(() => {
    setIsProfileOpen(!isProfileOpen);
  }, [isProfileOpen]);

  const closeProfileView = useCallback(() => {
    setIsProfileOpen(false);
  }, [isProfileOpen]);

  const fullName = useCallback(
    u => {
      if (u.get("first_name", "") === "first_name") {
        return "";
      }

      return `${u.get("first_name", "")} ${u.get("last_name", "")}`;
    },
    [user]
  );

  return (
    <Container>
      <ProfileSet onClick={toggleProfileView}>
        <img className={css(styles.profileAvatarSmall)} src={gravatar.url(user.get("email"))} />
      </ProfileSet>

      {isProfileOpen && (
        <div>
          <Panel className={"animated fadeIn " + css(styles.profilePanel)}>
            <div className={css(styles.profileDetail)}>
              <Avatar user={user} />
              <div className={css(styles.profileNameEmail)}>
                <p style={{ fontSize: "16px" }}>{fullName(user)}</p>
                <a className={css(styles.emailLink)}>{user.get("email")}</a>
              </div>
            </div>
            <div className={css(styles.profileSetting)}>
              <div>
                <span className={css(styles.emailLink)}>
                  <Link to="/account" onClick={closeProfileView} className={css(styles.textAnchor)}>
                    Account Settings
                  </Link>
                </span>
              </div>
            </div>

            <LogoutLink />
          </Panel>
          <div className={css(styles.panelUnderlay)} onClick={closeProfileView} />
        </div>
      )}
    </Container>
  );
};

ProfileLink.propTypes = {
  user: PropTypes.object,
};

ProfileLink.defaultProps = {
  user: fromJS({}),
};
