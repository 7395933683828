import styled from 'styled-components';
import {GLOBALS} from "../../styles/global-default-styles";

export const Wrapper = styled.div`
  z-index:21;
  display: flex;
  align-items: center;
`;

export const Tooltip = styled.div`
    background: ${GLOBALS.colorPrimaryDark};
    ${({show}) => show ?
        `z-index:99999;
        opacity:1` : 
        `z-index:-1;
        opacity:0`
};
    transition:opacity .3s ease;
    border-radius: 3px;
    color: #fff;
    position: fixed;
    width: 200px;
    top: ${({cords, height}) => cords.y - ( height + 10 ) }px;
    ${props => checkFlipLeft(props, "ToolTip") ? 'transform:translateX(-80%);': ''};
    left: ${({cords}) => cords.left - 12}px;
    font-size:12px;
    padding:10px;
`;

export const TooltipConnection = styled.div`
  background: ${GLOBALS.colorPrimaryDark};
  position: absolute;
  bottom: -5px;
  ${props => checkFlipLeft(props, "TOOLTIP CONNCTION") ? 'right': 'left'}: 15px;
  height: 10px;
  width: 10px;
  transform: rotate(45deg);
`;

const checkFlipLeft = ({cords, windowWidth}, caller) => {
  return (windowWidth - cords.left) < 200;

}

