import { findElementObject, findParentElement } from "../functions";
import _ from "lodash";
import { getElementPosition } from "containers/Editor/helpers/getElementPosition";
import { can, is } from "containers/Authentication/PermissionsCheck";
import {
  setElements,
  setOverlayStyles,
  setActiveComponent,
  setModules,
  toggleAddModal,
  setEditor,
  clearEditorPanel,
  setDraggingNewElement,
} from "../actions/editorActions";
import { Emitter } from "services/Emitter";
import * as DNDMethods from "./dnd-methods.js";
const GHOST_MAX_DIMENSION = 300;
const GHOST_OPACITY = 0.4;
const DRAGGED_ELEMENT_OPACITY = 0.2;
const emptyColumnBackground = `no-repeat center url(https://convertlyimguploadeast.s3.amazonaws.com/convertly-global-images/empty_column_placeholder.svg)`;
const marsThickness = "5px";
const elementsSideHoveringMargin = 30;
const elementsTopHoveringMargin = 30;
const elementsBottomHoveringMargin = 30;
const sliderOverride = elementName =>
  elementName.includes("convertlySlider") && elementName !== "convertlySlider.convertlySlider";

/**
 * The following is the main logic when dragging a new element
 * @returns {number}
 */

//clear editor on pressing espape key
export const escFunction = (event, Page) => {
  if (event.keyCode === 27) {
    Page.props.dispatch(clearEditorPanel());
  }
};

/**
 * Event handler for DRAG_NEW_ELEMENT Emit event
 * @param payload
 */

export const startAnimation = Page => {
  Page.animation = window.requestAnimationFrame(() => {
    DNDMethods.draggingHandler(Page);
  });
};

export const onScrollEditor = ({ scrollTop }, Page) => {
  Page.scrollOffset = scrollTop;
};

/**
 * Iterates over the flatElements array and checks if mouse is within box bounds
 */

export const assignListeners = (pageElements, Page) => {
  pageElements.forEach(elem => assignElementListener(elem, Page));
};

export const updateSaturnSize = textNode => {
  const page = document.querySelectorAll("[data-pagewidth]")[0];
  const saturn = document.querySelector(`[data-editor=saturn]`);
  if (!saturn) {
    return;
  }
  let editedElement = !textNode.style.display
    ? textNode
    : document.querySelector(`[data-clone=true]`);

  saturn.style.height = `${Number(getComputedStyle(editedElement).height.slice(0, -2)) + 40}px`;

  saturn.style.width = `${Number(getComputedStyle(editedElement).width.slice(0, -2)) + 40}px`;

  if (textNode.currentId) {
    document.querySelector(
      "[data-editor=c" + textNode.currentId + "]"
    ).style.height = `calc(${saturn.style.height} - 40px)`;
  }

  saturn.style.left = `${editedElement.getBoundingClientRect().left -
    page.getBoundingClientRect().left -
    20}px`;
};

export const updateNeptune = (id, Page) => {
  if (editorAlreadyActive(Page)) {
    if (Page.props.editorType.field === id) {
      Emitter.emit("UPDATE_NEPTUNE_STATE", false);
      return;
    }
  }
  Emitter.emit("UPDATE_NEPTUNE_STATE", id);
};
export const updateSaturn = id => {
  Emitter.emit("UPDATE_SATURN_STATE", id);
};

export const collapseSaturn = (e, Page) => {
  try {
    if (
      editorAlreadyActive(Page) ||
      e.relatedTarget.parentNode.attributes["data-editor"].nodeValue === "saturn"
    ) {
      Emitter.emit("UPDATE_NEPTUNE_STATE", false);
      return;
    }
  } catch (e) {}
  Emitter.emit("UPDATE_SATURN_STATE", false);
  Emitter.emit("UPDATE_NEPTUNE_STATE", false);
};

export const insertModule = Page => {
  const pageElements = Page.props.pageElements.toJS();
  const newElement = { el: "editor.newElement", sort: 16, children: [] };
  pageElements.push(newElement);
  Page.props.dispatch(setElements(pageElements));
  Page.props.dispatch(setOverlayStyles({ zIndex: 1700 }));
  Page.props.dispatch(setActiveComponent(16));
  Page.props.dispatch(toggleAddModal("adding"));
};
export const startScrolling = (page, i, offsetTop, id, viewPort, Page) => {
  let increment = i > 0 ? 1 : -1;
  i = i + increment;
  setTimeout(() => {
    const initialScrollAmount = page.scrollTop;
    let scrollAmount;
    if (!viewPort) {
      Page.scrollBar.view.scrollTop = page.scrollTop + i;
    } else {
      Emitter.emit("SCROLL_TO_FOCUSED_ELEMENT", initialScrollAmount + i);
    }
    scrollAmount = page.scrollTop;
    // this will determine the target scroll amount for a mobile device considering both the
    // the main page scroll and the device scroll
    if (
      document.getElementsByClassName("view2")[0] &&
      document.getElementsByClassName("view2")[0].children[0] &&
      viewPort
    ) {
      scrollAmount += document.getElementsByClassName("view2")[0].children[0].scrollTop;
    }
    if (initialScrollAmount === page.scrollTop) {
      // this will run when you reach the end of the scroll bar
      let scrollPage = document.getElementsByClassName("view2")[0]
        ? document.getElementsByClassName("view2")[0].children[0]
        : null;
      if (viewPort && scrollPage) {
        // this will run the mobile device scroll after the main scroll finishes runing
        // i2 is the only for devices
        let i2 = scrollPage.scrollTop - (offsetTop - initialScrollAmount) < 0 ? 1 : -1;
        startScrolling(scrollPage, i2, offsetTop - initialScrollAmount, id, Page);
        return;
      } else {
        // at the end of scrolling you have to update saturn position
        updateSaturn(id);
        return;
      }
    }
    if (i > 0) {
      if (scrollAmount < offsetTop && scrollAmount !== offsetTop) {
        startScrolling(page, i, offsetTop, id, viewPort, Page);
      } else {
        updateSaturn(id);
      }
    } else if (i < 0) {
      if (scrollAmount > offsetTop && scrollAmount !== offsetTop) {
        startScrolling(page, i, offsetTop, id, viewPort, Page);
      } else {
        updateSaturn(id);
      }
    }
  }, 10);
};

export const scrollToElement = (id, Page) => {
  const { editorViewportWidth } = Page.props;
  // gabAboveElement is a gab so the element is not at the very top of the window after scrolling
  const gapAboveElement = -60;
  // distance of target element from the top
  let pageAbsoluteOffsetTop, elementAbsoluteOffsetTop, page, element;
  page = document.getElementsByClassName("desktopPage")[0];
  page = page || document.getElementsByClassName("view2")[0].children[0];
  element = document.querySelector(`[data-editor=c${id}]`);
  element = element || document.querySelector(`[data-editor=c${id - 1}]`);
  pageAbsoluteOffsetTop = getElementPosition(page).top;
  if (editorViewportWidth && editorViewportWidth !== "desktop") {
    pageAbsoluteOffsetTop = getElementPosition(page.children[0]).top;
  }
  elementAbsoluteOffsetTop = getElementPosition(element).top;
  let offsetTop = elementAbsoluteOffsetTop - pageAbsoluteOffsetTop + gapAboveElement;
  if (offsetTop < 0) {
    // offset should never a negative number
    offsetTop = 0;
  }
  const scrollBarPage = document.getElementsByClassName("view")[0].children[0];
  let i = scrollBarPage.scrollTop - offsetTop < 0 ? 1 : -1;
  if (!editorViewportWidth || editorViewportWidth === "desktop") {
    startScrolling(scrollBarPage, i, offsetTop, id, "desktop", Page);
  } else {
    i = page.scrollTop - offsetTop < 0 ? 1 : -1;
    // before scrolling the mobile device, you have to scroll the main page scroll
    startScrolling(scrollBarPage, i, offsetTop, id, "desktop", Page);
  }
};
export const onScroll = Page => {
  const { editorType } = Page.props;
  if (editorType.field) {
    // this will update saturn position if you scroll within a mobile device if the editor is active
    Page.setState({ forceRenderSaturn: !Page.state.forceRenderSaturn });
  } else {
    // updates saturn as you scroll inside mobile device
    Emitter.emit("UPDATE_NEPTUNE_STATE", false);
    Emitter.emit("UPDATE_SATURN_STATE", false);
  }
};
export const closeEditor = (e, Page) => {
  const pageWrapper = document.getElementById("editorPageWrapper");
  if (pageWrapper === e.target) {
    Page.props.dispatch(clearEditorPanel());
  }
};
export const forceRenderSaturn = ({ id, objectBeingEdited, pageElements }, Page) => {
  Emitter.emit("UPDATE_SATURN_STATE", id);
  Page.setState({ forceRenderSaturn: !Page.state.forceRenderSaturn });
};

/**
 * Assigns onClick and onMouseOver event handlers to page element DOM nodes
 * @param el
 */
export const assignElementListener = (el, Page) => {
  const moduleId = el.moduleId;
  const { editorViewportWidth } = Page.props;
  let elementName = el.el;
  let id = el.id;
  let node = document.querySelector(`[data-editor=c${id}]`);
  let element = el;

  if (!element.parentId && element.children.length) {
    element = element.children[0];
    id = element.id;
    elementName = element.el;
  }
  if (node) {
    if (!Page.props.editingBlogPost) {
      node.draggable = true;
      node.ondragstart = e => DNDMethods.onStartDragging(node, element, e, Page);
      node.ondragend = e => DNDMethods.onEndDragging(node, element, e, Page);
      node.ondrag = e => DNDMethods.onDrag(e, Page);
    } else {
      node.draggable = false;
    }
    node.onclick = e => elementOnClick(moduleId, el.id, element, elementName, e, Page);
    node.onmouseover = e => elementOnMouseOver(el.id, element, e, Page);
    // node is a link with a button inside
    if (
      node.children[0] &&
      node.children[0].attributes["data-editor"] &&
      node.children[0].attributes["data-editor"].value === node.attributes["data-editor"].value
    ) {
      node = node.children[0];
    }
    const style = window.getComputedStyle(node);
    if (style.height === "auto" && style.width === "auto") {
      node.style.display = "inline-block";
      node.isAuto = true;
    }
    const width = parseFloat(style.width.replace("px", ""));
    const height = parseFloat(style.height.replace("px", ""));
    if (node.isAuto) {
      node.removeAttribute("style");
    }
    let differentViewport;
    if (editorViewportWidth && editorViewportWidth !== "desktop") {
      differentViewport = true;
    }
    //
    const position = getElementPosition(node, true);
    const { top, left } = position;
    Page.flatElements.push({
      elementName,
      moduleId,
      id,
      box: {
        top,
        left,
        bottom: top + height,
        right: left + width,
      },
      width,
      height,
      node,
      el,
    });
  }
  if (el.children.length) {
    assignListeners(el.children, Page);
  }
};

/**
 * Calls assignElementListener for each page element
 * @param pageElements
 */

/**
 * Helper function to get the editor component for element that was activated
 * @param element - String of element el, ex: Elements.Row
 * @returns {*}
 */
export const findType = (elementName, element, Page) => {
  // elementName is `el` from JSON

  const { elementsThatCanBeAddedToPage } = Page.props;
  //added check for no element name for backwards compatibility purposes
  if (elementName === "Elements.Container" || !elementName) {
    elementName = "heartOfGold.heartOfGold";
  }
  let type;
  elementsThatCanBeAddedToPage.map(el => {
    if (el.get("element")) {
      if (elementName && elementName.includes(el.getIn(["element", "el"]))) {
        type = el.get("editorType");
        if (type === "header" || type === "footer") {
          if (element.children.length) {
            type = "background";
          }
        }
      }
    }
  });
  if (elementName.toUpperCase().includes("HEADER")) {
    type = "header";
  }
  if (elementName.toUpperCase().includes("FOOTER")) {
    type = "footer";
  }
  return type;
};

/**
 * Helper fn to check if editor panel is already open
 *
 * @returns {boolean}
 */
export const editorAlreadyActive = Page => {
  return !!Page.props.editorType.field;
};

/**
 * Helper fn to check if element of id is the active element
 *
 * @param id
 * @returns {boolean}
 */
export const editingThisElement = (id, Page) => {
  return Page.props.editorType.field === id;
};

/**
 * Returns the onClick handler for an element
 *
 * @param moduleId
 * @param id
 * @param el
 * @param elementType
 * @returns {Function}
 */
export const elementOnClick = async (moduleId, id, el, elementName, e, Page) => {
  //return async e => {
  e.stopPropagation();
  if (!Page.props.editingBlogPost || (el.el === "Elements.HTMLContentRenderer" && elementName)) {
    let type = "text";
    let parentSort;
    if (moduleId === id) {
      parentSort = el.sort;
    } else {
      parentSort = Page.pageElements.filter(obj => obj.id === moduleId);
      if (parentSort[0]) {
        parentSort = parentSort[0].sort;
      } else parentSort = 16;
    }

    if (sliderOverride(elementName)) {
      type = "ConvertlySlide";
      id = el.parentId;
    } else {
      type = findType(elementName, el, Page);
    }
    await Page.props.dispatch(clearEditorPanel());
    Page.props.dispatch(
      setEditor({
        editorType: { field: id, type: type },
        isActiveComponent: parentSort,
        activeModuleId: moduleId,
      })
    );
    Emitter.emit("UPDATE_NEPTUNE_STATE", false);
    Emitter.emit("UPDATE_SATURN_STATE", id);
  }
  //};
};

export const elementOnMouseOver = (id, el, e, Page) => {
  if (Page.props.editingBlogPost && el.el !== "Elements.HTMLContentRenderer") {
    return;
  }

  if (e.stopPropagationWithoutStopingModuleWrapperHoverEvent) {
    return;
  }
  e.stopPropagationWithoutStopingModuleWrapperHoverEvent = true;
  if (Page.props.draggingNewElement) {
    return;
  }
  updateNeptune(id, Page);
};
