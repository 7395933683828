import React from 'react';
import PropTypes from 'prop-types';
import Component from '../../rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';


//Utils
import getHeaderPosition from '../header-style-functions/get-header-position';

//Components
import { HeaderNav4 } from './components/nav-04';
import { HeaderTopper } from '../components/header-topper';

//scripts
import absoluteToFixed from '../scripts/absolute-to-fixed-header';

//Styles
import headerStyles from './header-styles-04.js';
import moduleWrapper from '../../elements/module-wrapper/module-wrapper';

//Components

export class Header04 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            elementEditableBorder: ""
        };
        this.removeWrapperOutline = this.removeWrapperOutline.bind(this);
        this.addWrapperOutline = this.addWrapperOutline.bind(this);
        this.renderChildren = this.renderChildren.bind(this);
    }

    removeWrapperOutline(e) {
        if (!this.context.store.getState().get('editor').editorReducer.get('activeModuleId')) {
            this.setState({
                elementEditableBorder: ""
            });
            e.hoverOverChild = false;
        }
        else if (this.context.store.getState().get('editor').editorReducer.get('editorType').get('field') !== this.props.id) {
            this.setState({
                elementEditableBorder: ""
            });
            e.hoverOverChild = false;
        }
    }

    /**
     * Removes the style that outlines element
     **/
    addWrapperOutline(e) {
        if (!e.hoverOverChild) {
            if (this.state.elementEditableBorder === '6px dashed #F5A623') {
                this.setState({
                    elementEditableBorder: "6px dashed #F5A623 "
                });
            }
            else {
                this.setState({
                    elementEditableBorder: "6px dashed #F5A623"
                });
            }
        }
    }

    renderChildren(position) {
        return React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
                moduleId: this.props.id,
                moduleSort: this.props.sort,
                styles: this.styles,
                headerPosition: position,
                page: this.props.page,
                global: this.props.global,
                site: this.props.site
            });
        })
    }

    render() {

        const {data, global, site, page} = this.props;

        let inEditor = this.inEditor();

        let elementEditableBorder = {};
        if (!this.props.notEditable) {
            if (this.inEditor()) {
                elementEditableBorder.outline = this.state.elementEditableBorder;
            }
        }

        const inlineStyle = this.generateStyleAttribute();

        const headerPosition = inEditor ? 'static' : getHeaderPosition(data.position, page);

        this.styles = headerStyles(global, data, headerPosition, inEditor);

        let headerID = headerPosition === "overlayFixed" ? id(absoluteToFixed) : "header";

        /**
         * Fixed Header Fix
         */
        let fixedHeaderFix;

        if (headerPosition === "fixed") {
            fixedHeaderFix =
                <div id="test" className={css(this.styles.fixedHeaderFix)}/>;
        }
        else {
            fixedHeaderFix = <div/>
        }

        if (data.position === "noHeader") {
            return null
        }

        else {
            return (

                <div id="header04"
                     className={css(this.styles.header)}
                     data-elementType="header"
                     itemProp="siteHeader"
                     itemScope
                     itemType="http://schema.org/siteHeader"
                     onClick={(e) => {
                         this.launchElementalEditor('header', e);
                     }}
                     onMouseOver={ this.addWrapperOutline }
                     onMouseOut={ this.removeWrapperOutline }
                     style={ elementEditableBorder }
                     { ...this.generateDataEditorAttribute() }>
                    <HeaderTopper
                        global={ global }
                        site={ site }
                        styles={ this.styles }
                        data={ data.headerTopperSettings }/>

                    <div className={ css( this.styles.headerBottom ) }>
                        <HeaderNav4
                            data={ data }
                            site={ site }
                            page={ page }
                            styles={ this.styles }
                            moduleId={ this.props.id }
                            moduleSort={ this.props.sort }
                            global={ global }
                        />
                    </div>
                </div>

            )
        }
    }

}

Header04.contextTypes = {
    store: PropTypes.object
};
