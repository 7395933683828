/* eslint-disable */
//import $ from 'jquery';
//"use strict"
const hoursWidget = (id) => {

    return (id) => {

        (function () {


            const that = this;


            this.getHoursData = (div) => {

                let weeklyHoursData = [];

                let HoursData = function (hourItem) {
                    const $hourItem = $(hourItem);


                    this.dayListing = hourItem;
                    this.day = $hourItem.data('dayofweek');
                    this.openStatus = $hourItem.data('openstatus');
                    this.openingTime = $hourItem.data('openinghours');
                    this.closingTime = $hourItem.data('closinghours');

                };

                div.each(function (index, item) {
                    weeklyHoursData.push(new HoursData(item))
                });

                return weeklyHoursData

            };

            /**
             * Changes open message based on if store is open or not
             */

            this.displayHours = () => {

                //Time elements
                const currentDate = new Date();

                //get current day of week
                const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
                const currentDayNumber = currentDate.getDay();
                const currentDay = daysOfWeek[currentDayNumber];

                //get current time of day
                const currentHour = currentDate.getHours();
                const currentMinutes = currentDate.getMinutes();
                const editedCurrentMinutes = that.addZero(currentMinutes);

                //get data to work with
                const currentTime = parseFloat(currentHour + '.' + editedCurrentMinutes);
                // const $hoursDiv = $('.hours-launcher');
                // const weeklyHoursData = that.getHoursData($hoursDiv);

                const $hoursList = $('.hoursList');

                $hoursList.toArray().forEach(list => {
                    that.activateTab(list.children[0]);
                });

                // let setDayOnLoad;
                // let defaultDayLoaaded;
                // sets initial state, and and correct text for each day based on time
                // weeklyHoursData.forEach((daysData, index) => {

                //     let openingTime = that.floatifyTime(daysData.openingTime);
                //     let closingTime = that.floatifyTime(daysData.closingTime);
                //     let displayedData = $(daysData.dayListing).children();
                //     let openStatusSpan = $(displayedData).find('.open-status');

                //     if (daysData.day.toLowerCase() === currentDay.toLowerCase()) {
                //         if (currentTime <= closingTime && currentTime >= openingTime) {
                //             $(openStatusSpan).text('OPEN NOW');
                //         }
                //         if (!daysData.openStatus) {
                //             $(openStatusSpan).text('CLOSED TODAY');
                //         }
                //         that.activateTab(daysData.dayListing)
                //     } else {
                //         if (daysData.openStatus &&  !defaultDayLoaaded) {

                //             defaultDayLoaaded = true
                //             that.activateTab(daysData.dayListing)
                //         }
                //     }

                // });
            };

            const infoHover = (info) => {
                return () => {
                    info.style.bottom = "0";
                    console.log(info);
                }
            }

            this.adaptStyles = () => {
                const $locationInfo = $('.locationInfo');

                $locationInfo.each((idx, info) => {
                    const headingHeight = info.firstChild.firstChild.offsetHeight;
                    
                    if(headingHeight > 83) {
                        info.classList.add("tripleLine");
                    } else if (headingHeight > 51) {
                        info.classList.add("doubleLine");
                    }
                })
            }

            /**
             * Activates the needed tab
             * @param element - a jquery selector
             */
            this.activateTab = (element) => {
                let displayedHours = $(element).children();
                $(element, `#${id}`).addClass('active');
                $(displayedHours, `#${id}`).addClass('active');
            };

            this.floatifyTime = (time) => {
                time = time.replace(/:/g, '\.');
                if (time.indexOf('pm') !== -1) {

                    time = time.replace(/am|pm/g, '"');
                    return parseFloat(time) + 12
                } else {
                    //time = time.replace(/am|pm/g, '"');
                }
                return parseFloat(time);
            };

            /**
             *
             * @param i
             * @returns  number with a 0 before it
             */
            this.addZero = (i) => {
                if (i < 10) {
                    i = "0" + i;
                }
                return i;
            };

            //Tabs event listener
            $('.hours-launcher', "#" + id).on('click', function (e) {
                $('.hours-launcher', "#" + id).removeClass('active');
                $('.displayed-hours', "#" + id).removeClass('active');
                that.activateTab(this);
            });


        })();
        displayHours();
        adaptStyles();
    }

};


export default hoursWidget;
