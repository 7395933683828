import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { css } from "@convertly/css";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { styles } from "components/StoreFront/css-storefront-styles";
import { CancelSaveButtons } from "components/StoreFront/DetailPage/CancelSaveButtons";
import { noop } from "utils";

const Overlay = ({ onClose }) => <div className={css(styles.modalUnderlay)} onClick={onClose} />;

const Header = ({ children }) => (
  <div className={css(styles.modalHeader)}>
    <div>{children}</div>
  </div>
);

const Wrapper = styled.div`
  width: 580px;
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 9999;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
  top: 3em;
  @media (min-height: 640px) {
    top: calc(3em + 40px);
  }
`;

const CloseButtonWrapper = styled.span`
  font-size: 18px;
  cursor: pointer;
  float: right;
`;

const BodyContainer = styled.div`
  overflow: auto;
  max-height: 250px;
  @media (min-height: 640px) {
    max-height: 350px;
  }
  @media (min-height: 700px) {
    max-height: 400px;
  }
`;

const CloseButton = ({ onClose }) => (
  <CloseButtonWrapper>
    <i onClick={onClose} className={css(styles.modalCloseIcon) + " material-icons"}>
      close
    </i>
  </CloseButtonWrapper>
);

const Body = ({ Render, children }) => (
  <div style={{ padding: "20px" }}>
    <div className="row" style={{ marginBottom: "20px" }}>
      <BodyContainer className="col-xs-12">
        <Render />
        {children}
      </BodyContainer>
    </div>
  </div>
);

Body.defaultProps = {
  children: [],
};

/**
 *
 * @param {string} title
 * @param {function} onClose
 * @param {function} onCancel
 */
export const GenericModal = ({
  title,
  onClose,
  onCancel,
  onSave,
  render,
  transitionTime,
  transition,
  leftButtonLabel,
  rightButtonLabel,
  disabled,
  processing,
  explicitClose,
  showButtons,
  children,
}) => (
  <ReactCSSTransitionGroup
    component="div"
    transitionName={transition}
    transitionEnterTimeout={transitionTime}
    transitionLeaveTimeout={transitionTime}
  >
    <div>
      <Overlay onClose={explicitClose ? () => {} : onClose} />

      <Wrapper>
        <Header>
          {title}
          <CloseButton onClose={onClose} />
        </Header>

        <Body children={children} Render={render} />

        {showButtons && (
          <CancelSaveButtons
            leftButtonLabel={leftButtonLabel}
            rightButtonLabel={rightButtonLabel}
            test={() => console.log("ONSAVE::::::", onSave)}
            disabled={disabled}
            processing={processing}
            saveAction={onSave}
            cancelAction={onCancel || onClose}
          />
        )}
      </Wrapper>
    </div>
  </ReactCSSTransitionGroup>
);

GenericModal.propTypes = {
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  render: PropTypes.func,
  title: PropTypes.string,
  transitionTime: PropTypes.number,
  transition: PropTypes.string,
  leftButtonLabel: PropTypes.string,
  rightButtonLabel: PropTypes.string,
  disabled: PropTypes.bool,
  processing: PropTypes.bool,
  explicitClose: PropTypes.bool,
  showButtons: PropTypes.bool,
};

GenericModal.defaultProps = {
  onSave: noop,
  onClose: noop,
  render: () => null,
  title: "",
  transitionTime: 150,
  transition: "fade150",
  leftButtonLabel: "Cancel",
  rightButtonLabel: "Save",
  disabled: false,
  processing: false,
  explicitClose: false,
  showButtons: true,
  children: [],
};
