import React from 'react';
import {
  ControlsWrapper,
  CancelButton,
  NextButton
} from './components';

/**
 * this is the controls at the bottom of the widget.
 * the props are actually controlled by the function generateControlsProps
 * in ./functions.js
 */
export const Controls = ({disableContinue, stage, handler, toggleForm, text}) => {

  return (
    <ControlsWrapper>
      { stage !== 3  &&
      <CancelButton onClick={toggleForm}>Cancel</CancelButton>
      }
      <NextButton stage={stage} disabled={disableContinue} onClick={handler}>{text}</NextButton>
    </ControlsWrapper>
  )

}
