import React from 'react';
import Component from '../../../rootComponent';
import { id } from '@convertly/thor';
import {css} from "@convertly/css";

//Default Props
import defaultGlobal from '../../../themes/global';

//Styles
import poweredByConvertlyStyles from './powered-by-convertly-styles';

//Components
import Elements from '../../../elements/index';


import fontLoader from './font-loader';

//Utils
import { globalImage } from '../../../utils/generateImage';

/*
 **Layout

 Full width paragraph with section title
 */
export default class
PoweredByConvertly01 extends Component {

    constructor(props) {

        super(props);

    }

    render() {
        /**
         * Call Stylesheet function
         **/
        this.styles = poweredByConvertlyStyles(this.props.global, this.props.data, this.props.site );

        if (this.props.editor) {
            return this.editor();
        }
        //Loads convertly font
        //id( fontLoader );
        return (
            <Elements.ModuleWrapper
                parentClassName={ this.styles.poweredByConvertly }
                data={ this.props.data }
                global={ this.props.global }
                elementDataName="backgroundColor"
                moduleId={ this.props.id }
                moduleSort={ this.props.sort }>
                <Elements.Container>
                    <Elements.Row>
                        <div className="col-sm-12">

                                <h2 className={ css( this.styles.poweredByConvertlyText ) }>Powered By <a className={ css( this.styles.convertlyLink ) } href="https://www.convertly.com/" target="_blank">Convertly</a></h2>
                                <img className={ css( this.styles.convertlyLogoSmall ) } src={ globalImage( "convertly-logo-small.png" ) } alt="Sell more stuff at Convertly.com" title="Sell more stuff at Convertly.com" />

                        </div>
                    </Elements.Row>
                </Elements.Container>
            </Elements.ModuleWrapper>

        )
    }
}

PoweredByConvertly01.defaultProps = {
    global: defaultGlobal
};
