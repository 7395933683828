export function generateFooterOffsetMediaQueries( data, hideConvertlyBranding ) {
    let bodyPaddingMediaQueries = {};

    Object.keys( data.userOverrides ).map( ( key ) => {
        if (key.indexOf('@media') !== -1) {
            Object.keys(data.userOverrides[key]).map((mqKey) => {
                if (mqKey.indexOf('height') !== -1) {
                    bodyPaddingMediaQueries[key] = {};
                    let heightValue = data.userOverrides[key][mqKey];
                    bodyPaddingMediaQueries[key]['paddingBottom'] = hideConvertlyBranding ? heightValue : `calc(${heightValue} + 20px)`;
                }
            })
        }
    });

    return bodyPaddingMediaQueries
}
