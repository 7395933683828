import { StyleSheet } from "@convertly/css";
import Typography from '../elements/style-functions/typography';


const headerTopStyles = ( global, data ) => {

    const phoneTyphography = Typography( global, data.phone );
    const emailTyphography = Typography( global, data.email );
    const callOutTextTyphography = Typography( global, data.callOutText );


    return StyleSheet.create({

        headerTop: {
            background: data.headerContactBackgroundColor || global.colors.dark.color1,
            color: data.headerContactTextColor || global.colors.light.color1
        },
        headerIn: {
            padding:'10px 0 6px 0'
        },
        headerLft: {
            padding:0,
            float:'left',
            display:'inline-block'
        },
        headerSale: {
            color: global.colors.light.color1,
            ...callOutTextTyphography,
            padding:0,
            margin:0,
            lineHeight:'15px',
            '@media(max-width: 479px)': {
                    fontSize:'11px',
                    lineHeight:'11px'
            }
        },
        headerRgt: {
            padding:0
        },
        pullRight: {
            float:'right'
        },
        headerContactList: {
            padding:0,
            margin:0
        },
        headerContactListItem: {
            padding:'0 9px',
            margin:0,
            float:'left',
            display:'inline-block',
            lineHeight:'15px',
            listStyleType: 'none',
            '@media(max-width: 479px)': {
                    padding:'0 5px'
                }
        },
        headerContactListItemLink: {
            display:'inline-block',
            fontSize:'12px',
            lineHeight:'12px',
            fontWeight:400,
            ...phoneTyphography,
            '@media(max-width: 767px)': {
                fontSize: 0,
                lineHeight: 0
            },
        },
        headerContactListItemIcon: {
            marginRight: '10px',
            fontSize:'12px',
            lineHeight:'12px',
        },
        phoneIcon: {
            ...phoneTyphography
        },
        emailIcon: {
            ...emailTyphography
        }
    })

};
export default headerTopStyles;
