import {StyleSheet} from "@convertly/css";
import UserOverrides from '../style-functions/user-overrides';

const overlayStyles = (global, data, inEditor) => {


	const userOverrides = new UserOverrides(global, data, inEditor);
	return StyleSheet.create({
		overlay: {			
			height: "100%",
			width: "100%",
			backgroundColor: "rgba(0,0,0,0.3)",
			position: "absolute",
			top: "0",
			left: "0",
			right: "0",
			...userOverrides
		

		}
	})
};

export default overlayStyles;