import {StyleSheet} from "@convertly/css";
import ShippingWidgetStyles from '../../../ecommerce-common-components/shipping-widgets/shipping-widget-styles'
import googleButtonStyleObject from '../../../sign-in-up/google-button/google-button-style-object';
import facebookButtonStyleObject from '../../../sign-in-up/facebook-button/facebook-button-style-object';
import signInUpStyleObject from '../../../sign-in-up/sign-in-up-form/sign-in-up-form-styles'
import notificatoinBoxSytleObject from '../../../ecommerce-common-components/notification-box/notification-box-styles';
import staySignedInStyleObject from '../../../sign-in-up/sign-in-up-form/stay-signed-in/stay-signed-in-styles';
import { generateBrowserBreakPoints } from '../../../utils/browserSpecificStyles';

const Styles = (global, data) => {

    const shippingWidgetStyles = ShippingWidgetStyles(global, data);
    const googleButtonStyles = googleButtonStyleObject(global, data);
    const facebookButtonStyles = facebookButtonStyleObject(global, data);
    const signInUpStyles = signInUpStyleObject(global, data);
    const notificatoinBoxSytle = notificatoinBoxSytleObject( global, data );
    const staySignedInStyles = staySignedInStyleObject( global, data );

    const browserStyles = generateBrowserBreakPoints()





    const formFieldStyles = {
        backgroundColor: global.colors.light.white,
        boxShadow: '0 0 0px 1000px white inset',
        border: "1px solid",
        borderColor: global.colors.accent.color3,
        color: global.colors.dark.color2,
        display: "block",
        fontSize: "16px",
        height: "38px",
        lineHeight: "18px",
        margin: "0",
        padding: "8px 12px",
        fontWeight: '300',
        verticalAlign: "middle",
        width: '100%',
        '.input[disabled]': {
            boxShadow: `0 0 0px 1000px #e4e4e4 inset`,
            background: "#e4e4e4",
            borderColor: "#b4bbc3",
            color: global.colors.dark.color3
        },
        '::placeholder': {
            color: global.colors.dark.color3,
            fontWeight: '300',
        },
        ":focus": {
            outline: 'none'
        }
    };


    return StyleSheet.create({
        stripeFormField: {
            ...formFieldStyles
        },

        checkoutFlowWrapper: {
            minHeight: '100vh',
            fontFamily: global.fontFamily.primaryFont,
        },
        checkoutBody: {
            color: global.colors.dark.color2,
            display: "block",
            flex: "0 0 auto",
            fontSize: "14px",
            lineHeight: "20px",
            background: "#fafafa"
        },

        cartToggleContainer: {
            display: "none",
            fontSize: "14px",
            lineHeight: "20px"
        },

        checkoutFlowContainer: {
            display: "flex",
            fontSize: "14px",
            lineHeight: "20px",
            maxWidth: "95%",
            paddingTop: "0px",
            width: "100%",
            minHeight: 'calc(100vh - 80px)',
            "@media( max-width:767px )": {
                display: "block",
                margin:'0 auto'
            },
            "@media( max-width:480px )": {
                width:'100%',

                margin: "0 auto"
            }
        },
        /** Checkout Flow styles **/
        checkoutFlowInfernoComponent: {
            ...shippingWidgetStyles,
            /** notification box styles **/
            ...notificatoinBoxSytle,
            ...staySignedInStyles,
            ".cc-error-container": {
                maxWidth:'355px'
            },
            /** Sign in styles **/

            ".socialButton": {
                textAlign: 'center',
                width: '50%',
                display: 'inline-block',
                marginBottom:'35px',
                "@media(max-width:889px)": {
                    width:'100%'
                }
            },

            ".nudgeLeft": {
                paddingLeft: '10px',
                "@media(max-width:889px)": {
                    padding:0
                }
            },
            ".nudgeRight": {
                paddingRight: '10px',
                "@media(max-width:889px)": {
                    padding:0
                }
            },

            ...googleButtonStyles,
            ...facebookButtonStyles,
            ...signInUpStyles,
            ".sign-in-up-form": {
                marginTop: '30px'
            },

            /** User shipping Address **/
            ".ac2_preferences_textcontainer": {
                color: global.colors.dark.color1,
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                justifyContent: "center",
                lineHeight: "20px",
                marginTop: "0px",
                maxWidth: "100%",
                position: "relative",
                textAlign: "left",
                width: "700px",
                "@media (max-width:767px)": {
                    width: '100%',
                    alignItems: "center",
                    textAlign: "center"
                }
            },
            ".account_selector": {
                backgroundColor: global.colors.dark.color3,
                border: "4px solid",
                borderColor: global.colors.light.color1,
                borderRadius: "50%",
                boxShadow: `0 0 0 3px ${global.colors.dark.color3}`,
                color: global.colors.dark.color1,
                fontSize: "14px",
                height: "19px",
                lineHeight: "20px",
                marginLeft: "3px",
                marginRight: "3px",
                textAlign: "center",
                width: "19px",
                cursor: "pointer"
            },
            ".account_ordercontainer": {
                width: '100%',
                backgroundColor: global.colors.light.white,
                border: "1px solid",
                borderColor: global.colors.dark.color3,
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                marginTop: "20px",
                padding: "0px",
                textAlign: "left"
            },

            ".account_orderheader_wClearfix": {
                alignItems: "center",
                backgroundColor: global.colors.light.white,
                borderColor: global.colors.dark.color3,
                borderStyle: "none none solid",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                borderWidth: "1px",
                color: global.colors.dark.color1,
                display: "flex",
                fontSize: "14px",
                justifyContent: "space-between",
                lineHeight: "20px",
                padding: "15px",
                textAlign: "left"
            },

            ".account_orderlabel_ordernum": {
                color: global.colors.dark.color1,
                display: "inline-block",
                flex: "1",
                float: "left",
                fontFamily: global.fontFamily.primaryFont,
                fontSize: "12px",
                lineHeight: "20px",
                paddingRight: "15px",
                textAlign: "left",
                textTransform: "none"
            },

            ".account_orderlabel_reorder": {
                color: global.colors.dark.color2,
                display: "inline-block",
                flex: "1",
                float: "right",
                fontFamily: global.fontFamily.primaryFont,
                fontSize: "12px",
                lineHeight: "20px",
                paddingRight: "0px",
                textAlign: "left",
                textDecoration: "underline",
                textTransform: "none",
                cursor: 'pointer'
            },

            ".orderitemization": {
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                paddingLeft: "15px",
                paddingRight: "15px",
                textAlign: "left",
                overflow: 'hidden',
                maxHeight: '200px',
                height: "auto",
                transition: 'all .3s ease',
                ".open": {
                    maxHeight: '2400px',
                    transition: 'max-height .3s ease',
                }
            },
            ".shippingitem": {
                alignItems: "center",
                color: global.colors.dark.color1,
                display: "flex",
                fontSize: "14px",
                lineHeight: "20px",
                marginTop: "16px",
                textAlign: "left",
                maxWidth: '375px',
            },
            ".editAccountLink": {
                cursor: 'pointer'
            },
            ".bodytext_selected_shippingaddy": {
                width: '100%'
            },
            ".address-text": {
                maxWidth: '80%'
            },
            ".address-text-section": {
                display:'block',
            },
            ".trashIcon": {
                height: '13px',
                width: '13px',
                marginLeft: '10px',
                verticalAlign: 'text-top',
                cursor: 'pointer'
            },

            ".defaultcontainer": {
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                minWidth: "35px",
                textAlign: "center"
            },

            ".account_selected_selector": {
                backgroundColor: global.colors.accent.color1,
                border: "4px solid",
                borderColor: global.colors.light.color1,
                borderRadius: "50%",
                boxShadow: `0 0 0 3px ${global.colors.accent.color1}`,
                color: global.colors.dark.color1,
                fontSize: "14px",
                height: "19px",
                lineHeight: "20px",
                marginLeft: "3px",
                marginRight: "3px",
                textAlign: "center",
                width: "19px"
            },

            ".addresscontainer": {
                color: global.colors.dark.color1,
                display: "flex",
                flex: "1",
                fontSize: "14px",
                justifyContent: "space-between",
                lineHeight: "20px",
                textAlign: "left"
            },

            ".change-address-modal": {
                position: 'fixed',
                margin: 'auto',
                top: '-100vh',
                transform: 'translateY(-30vh)',
                minHeight: '35vh',
                width: '525px',
                left: 'calc( 50% - 230px)',
                background: global.colors.light.white,
                border: global.border,
                zIndex: '11',
                padding: "30px 60px 45px",
                ".active": {
                    top: '50vh',
                },
                "@media (max-width:767px)": {
                    width: '100%',
                    left: '0'
                },
                "@media (max-width:767px) and (min-width: 515px)": {
                    paddingTop: '20px'
                },
                "@media (max-width: 515px)": {
                    padding: '17px'
                }

            },
            ".save-button": {
                backgroundColor: global.colors.dark.color1,
                border: "0",
                borderRadius: "0",
                color: "white",
                cursor: "pointer",
                display: "block",
                fontSize: "14px",
                lineHeight: "inherit",
                margin: "10px 0 0",
                padding: "9px 15px",
                paddingBottom: "11px",
                paddingTop: "11px",
                textDecoration: "none",
                textTransform: "uppercase",
                width: "100%",
                textAlign: "center"
            },
            ".delete-button": {
                backgroundColor: global.colors.dark.color1,
                border: "0",
                borderRadius: "0",
                color: "white",
                cursor: "pointer",
                display: "inline-block",
                fontSize: "14px",
                lineHeight: "inherit",
                margin: "10px 0 0 5px",
                padding: "9px 15px",
                paddingBottom: "11px",
                paddingTop: "11px",
                textDecoration: "none",
                textTransform: "uppercase",
                width: "48%",
                textAlign: "center"

            },
            ".delete-button-cancel": {
                backgroundColor: global.colors.dark.color3,
                border: "0",
                borderRadius: "0",
                color: "white",
                cursor: "pointer",
                display: "inline-block",
                fontSize: "14px",
                lineHeight: "inherit",
                margin: "10px 5px 0 0",

                padding: "9px 15px",
                paddingBottom: "11px",
                paddingTop: "11px",
                textDecoration: "none",
                textTransform: "uppercase",
                width: "48%",
                textAlign: "center"
            },

            ".modal-header": {
                fontSize: '24px',
                marginBottom: '25px',
                textAlign: 'center'
            },
            ".modal-title": {
                display: 'inline-block',
                fontSize: '22px'
            },
            ".modal-close": {
                position: 'relative',
                top: '3px',
                right: '3px',
                display: 'inline-block',
                float: 'right',
                width: '50%',
                fontSize: '20px',
                textAlign: 'right'
            },


            /** end user shipping addresses **/

            // this fixes the error on iphone when the payment keyboard comes up and hides the rest of the page
            "@media(max-width: 480px)" :{

                "* #page-app": {
                    overflow:'initial',
                },

            },


            ".paymentButtonContainer": {
                position: 'relative'
            },
            ".spinnerIcon": {
                height: '30px',
                width: '30px',
                position: 'absolute',
                right: '15px',
                top: '5px'

            },

            ".stripeFormField": {
                ...formFieldStyles,
                width: '100%',
                margin: '10px 0'
            },
            ".stripeFormField-half-width": {
                ...formFieldStyles,
                margin: '10px 0',
                width: '47%',
                display: 'inline-block',
                ".right": {
                    float: 'right'
                }
            },

            ".checkoutFlowColumn": {
                display: "flex",
                //position:'relative',
                flex: "1",
                flexDirection: "column",
                fontSize: "14px",
                lineHeight: "20px",
                paddingBottom: "30px",
                paddingRight: "20px",
                paddingTop: "30px",
                minHeight: '100vh',
                //overflow: "hidden",

            },
            ".checkoutFlowWrapper": {
                position: 'relative',
                left: 0,
                width: '100%',
                height: 'fit-content',
            },

            ".animationContainer": {
                float: 'left',
                display: 'inline',
                position: 'relative',
                left: '0',
                top: '0',
                transition: 'left .4s cubic-bezier(0.86, 0.1, 1, 1), right .4s cubic-bezier(0.86, 0.1, 1, 1)',
                maxWidth: '375px',
                height: '100vh'
            },
            ".checkoutFlow1Container": {
                float: 'left',
                display: 'inline',
                position: 'relative',
                marginTop: '25px',
                left: '0',
                top: '0',
                transition: 'left .4s ease',
                width:"400px",
                "@media(max-width:580px)": {
                    position:'static',
                    transition:'none'
                },
                ".stage-left": {
                    position: 'absolute',
                    marginTop: '25px',
                    left: "-100vw",
                    maxWidth: '375px',
                    width:"100%",
                    height: '100vh',
                    transition: 'left .4s ease',
                    "@media(max-width:580px)": {
                        transition:'none'
                    },
                },
                ".stage-right": {
                    position: 'absolute',
                    marginTop: '25px',
                    top: '0',
                    float: 'right',
                    left: '150%',
                    zIndex: '1',
                    width: '40vw',
                    height: '100vh',
                    "@media(max-width:580px)": {
                        transition:'none'
                    },
                },
                "@media(max-width:480px)": {
                    width:"100%"
                }

            },
            ".checkboxLabel": {
                display: 'block',
                margin: '10px 0'
            },
            ".checkboxContainer": {
                marginRight: '10px'
            },
            ".checkBox": {
                border: '1px solid',
                cursor: 'pointer',
                verticalAlign: 'text-bottom',
                borderColor: global.colors.accent.color3,
                fill: global.colors.light.white,
                background: global.colors.light.white,
                '.open': {
                    border: 'none',
                    fill: global.colors.accent.color1,
                }
            },
            ".checkboxCheckBox": {
                visibility: 'hidden',
                height: 0,
                width: 0
            },
            ".chkbreadcrumbContainer": {
                fontFamily: global.fontFamily.secondaryFont,
                display: "flex",
                fontSize: "14px",
                justifyContent: "flex-start",
                lineHeight: "20px"
            },

            ".breadcrumbSeparator": {
                color: global.colors.dark.color3,
                fontSize: "14px",
                lineHeight: "20px",
                marginLeft: "15px",
                marginRight: "15px"
            },

            ".chkBreadcrumb": {
                color: global.colors.dark.color3,
                display: "block",
                flex: "0 auto",
                fontSize: "12px",
                lineHeight: "22px",
                marginBottom: "10px",
                marginRight: "0px",
                textAlign: "left",
                textDecoration: "none",
                borderBottom: '2px solid',
                borderColor: 'transparent',
                cursor: 'default',
                transition: 'all 400ms ease',
                ".clickable": {
                    cursor: 'pointer',
                    "@media(min-width:991px)": {
                        ":hover": {
                            color: global.colors.dark.color1,
                            borderColor: global.colors.dark.color1,
                        }
                    }
                },
                '.active': {
                    cursor: 'default',
                    color: global.colors.dark.color1,
                    borderColor: global.colors.dark.color1,
                },


            },

            ".chkTitle1": {
                borderBottom: "2px none",
                borderColor: global.colors.dark.color1,
                color: global.colors.dark.color1,
                display: "block",
                flex: "0 auto",
                flexDirection: "row",
                fontSize: "32px",
                lineHeight: "25px",
                marginBottom: "10px",
                marginTop: "10px",
                paddingBottom: "20px",
                width: "100%",
                clear: "both",
                fontWeight:"bold"
            },
            ".chk-title2": {
                color: global.colors.dark.color1,
                fontSize: "12px",
                display: 'inline-block',
                borderColor: global.colors.dark.color1,
                borderBottom: "2px solid"

            },
            ".sign-in-account-link": {
                fontSize: "12px",
                cursor: 'pointer',
                display: 'inline-block',
                float: "right",
                color: global.colors.dark.color3,

                ".left": {
                    float: 'left',
                    marginBottom: '15px'
                }
            },
            ".titleUnderline": {
                borderRadius: "5px",
                fontSize: "14px",
                height: "3px",
                lineHeight: "20px",
                marginBottom: "40px",
                width: "69%",
                background: global.colors.dark.color1
            },
            ".titleWrapper": {
              width:"fit-content"
            },

            ".chkGuestlink_wInlineBlock": {
                backgroundColor: "transparent",
                color: global.colors.dark.color1,
                display: "flex",
                width: 'fit-content',
                fontSize: "16px",
                lineHeight: "20px",
                maxWidth: "100%",
                paddingBottom: "20px",
                paddingTop: "15px",
                transition: 'border .3s ease',
                ':hover ': {
                    '.chkGuestarrow': {
                        marginLeft: '15px'
                    }
                },
            },


            ".textBlock": {
                color: global.colors.dark.color1,
                fontSize: "16px",
                lineHeight: "20px"
            },

            ".chkGuestarrow": {
                border: "0",
                color: global.colors.dark.color1,
                display: "inline-block",
                fontSize: "16px",
                height: '100%',
                width: '20px',
                lineHeight: "20px",
                marginLeft: "10px",
                maxWidth: "100%",
                transition: 'margin .3s ease',
            },

            ".orContainer": {
                alignItems: "center",
                display: "flex",
                fontSize: "14px",
                justifyContent: "center",
                lineHeight: "20px",
                width: "50%",

            },

            ".orDivider": {
                backgroundColor: global.colors.dark.color3,
                flex: "1",
                fontSize: "14px",
                height: "3px",
                lineHeight: "20px",
                marginTop: '-1px'
            },

            ".orSeparator": {
                color: global.colors.dark.color3,
                display: "flex",
                fontSize: "14px",
                lineHeight: "20px",
                padding: "5px 20px"
            },

            ".chkTitle2": {
                color: global.colors.dark.color2,
                fontSize: "18px",
                lineHeight: "30px",
                marginBottom: "10px",
                marginTop: "20px"
            },
            ".chkTitle3": {
                color: global.colors.dark.color1,
                fontSize: "22px",
                lineHeight: "30px",
                marginBottom: "10px",
                marginTop: "20px"
            },

            ".buttonContainer": {
                display: "block",
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "25px",
                float: 'left'
            },
            ".clearFix": {
                height: '1px'
            },

            ".row_wRow": {
                flex: "1",
                fontSize: "14px",
                lineHeight: "20px",
                marginLeft: "0",
                marginRight: "0"
            },

            ".column_wClearfix_wCol_wCol_6_wColSmall_6_wColTiny_6": {
                display: 'block',
                margin: '15px 0',
                fontSize: "14px",
                left: "auto",
                lineHeight: "20px",
                minHeight: "1px",
                paddingLeft: "0",
                paddingRight: "0",
                position: "relative",
                right: "auto",
                width: "50%",
                textAlign: 'center',

                "@media (max-width:991px)": {
                    display: 'block',
                    width: '100%',
                    margin: '10px 0'
                }
            },
            /** External Payment Buttons **/
            ".quickCheckoutContainer": {
                height: '163px'
            },
            ".apple-pay": {
                "-webkit-appearance": "-apple-pay-button",
                "-apple-pay-button-type": "black"
            },
            ".btnLeft_placholderBtn_wButton": {
                backgroundColor: global.colors.dark.black,
                border: "0",
                borderRadius: "3px",
                clear: "right",
                color: global.colors.light.white,
                cursor: "pointer",
                display: "flex",
                flex: "1",
                flexDirection: "row",
                float: "left",
                fontSize: "14px",
                justifyContent: "center",
                lineHeight: "inherit",
                marginBottom: "15px",
                marginLeft: "0px",
                marginRight: "5px",
                padding: "9px 15px",
                paddingBottom: "10px",
                paddingTop: "10px",
                textDecoration: "none",
                width: "auto"
            },

            ".btnRight_placholderBtn_wButton": {
                backgroundColor: global.colors.dark.black,
                border: "0",
                borderRadius: "3px",
                clear: "right",
                color: global.colors.light.white,
                cursor: "pointer",
                display: "flex",
                flex: "1",
                flexDirection: "row",
                float: "left",
                fontSize: "14px",
                justifyContent: "center",
                lineHeight: "inherit",
                marginBottom: "15px",
                marginLeft: "5px",
                marginRight: "0px",
                padding: "9px 15px",
                paddingBottom: "10px",
                paddingTop: "10px",
                textDecoration: "none",
                width: "auto"
            },

            ".column_wClearfix_wCol_wCol_6_wColMedium_6_wColSmall_6_wColTiny_6": {
                display: "flex",
                float: "left",
                fontSize: "14px",
                left: "auto",
                lineHeight: "20px",
                minHeight: "1px",
                paddingLeft: "0px",
                paddingRight: "0px",
                position: "relative",
                right: "auto",
                width: "50%"
            },
            ".editForm": {
                fontSize: '12px',
                marginBottom: '10px',
                color: global.colors.dark.color1,
                cursor: 'pointer',
                width: 'fit-content',
                ':hover': {
                    textDecoration: 'underline'
                }
            },
            ".formContainer_wForm": {
                fontSize: "14px",
                lineHeight: "20px",
                margin: "35px 0 15px",
                maxWidth: "375px",
                "@media(min-width:991px)": {
                    maxWidth: "375px"
                }
            },
            ".formContainer_shipping": {
                fontSize: "14px",
                lineHeight: "20px",
                margin: "20px 0 15px",
                maxWidth: "375px"
            },
            ".shippingOptionsForm": {
                fontSize: "14px",
                lineHeight: "20px",
                margin: "22px 0 15px",
                maxWidth: "375px"
            },

            ".form_HCPV": {
                fontSize: "14px",
                lineHeight: "24px",
                marginBottom: '10px',
                marginTop: '22px'
            },
            ".textInput_wInput": {
                ...formFieldStyles,
                width: "100%"
            },
            ".input-container-half-width": {
                width: "48%",
                height: '55px',
                display: "inline-block",
                '.right': {
                    float: 'right'
                }
            },
            ".input-container": {
                width: "100%",
                height: '55px',
                display: 'block',
                clear: 'both',
            },
            ".textInput_halfWidth": {
                ...formFieldStyles,
            },
            '.selectInput_wInput': {
                appearance: 'none',
                ...formFieldStyles,
                fontWeight: 300,
                width: '100%',
                borderRadius: '0',
                color: global.colors.dark.color3,
                ".active": {
                    color: global.colors.dark.color1
                }
            },
            ".selectInput_halfWidth": {
                appearance: 'none',
                ...formFieldStyles,
                display: 'inline-block',
                width: "47%",
                borderRadius: '0',
                '> option': {
                    color: global.colors.dark.color3,
                },
                '.right': {
                    float: 'right'
                },
                ".active": {
                    color: global.colors.dark.color1
                }
            },


            ".btnContinue_wButton": {
                backgroundColor: global.colors.dark.color1,
                border: "0",
                borderRadius: "0",
                color: "white",
                cursor: "pointer",
                display: "block",
                fontSize: "14px",
                lineHeight: "inherit",
                margin: "10px 0 0",
                padding: "9px 15px",
                paddingBottom: "11px",
                paddingTop: "11px",
                textDecoration: "none",
                textTransform: "uppercase",
                width: "100%",
                textAlign: "center",

                position: 'relative',
                ':disabled': {
                    opacity: '.5',
                    cursor: 'auto'
                }
            },
            ".paymentButton": {
                backgroundColor: global.colors.dark.color1,
            },

            '.btnContinue_wButton_step2': {
                backgroundColor: global.colors.dark.color1,
                border: "0",
                borderRadius: "0",
                color: "white",
                cursor: "pointer",
                display: "block",
                fontSize: "14px",
                lineHeight: "inherit",
                margin: "20px 0 0",
                padding: "9px 15px",
                paddingBottom: "11px",
                paddingTop: "11px",
                textDecoration: "none",
                textTransform: "uppercase",
                width: "100%",
                textAlign: "center",
                maxWidth: '375px',
                position: 'relative'
            },
            ".wFormDone": {
                backgroundColor: global.colors.light.color3,
                display: "none",
                fontSize: "14px",
                lineHeight: "20px",
                padding: "20px",
                textAlign: "center"
            },

            ".div_lLsU": {
                fontSize: "14px",
                lineHeight: "20px",
                textAlign: "center"
            },

            ".wFormFail": {
                backgroundColor: global.colors.light.color1,
                display: "none",
                fontSize: "14px",
                lineHeight: "20px",
                marginTop: "10px",
                padding: "10px"
            },

            ".div_xoTq": {
                fontSize: "14px",
                lineHeight: "20px"
            },
            ".pay-now-button-container": {
                maxWidth:'355px',
                height:'fit-content',
                position:'relative'
            },
            ".pay-now-disable": {
                width:'100%',
                height:'100%',
                position:'absolute',
                top:'0',
                left:'0',
                background:'#fff2',
                zIndex:'9999999999'
            }
        },

        checkoutCartReviewContainer: {
            height: '100%',
            background: global.colors.light.white,
            minHeight: "calc(100vh - 140px)",
            boxShadow: ' 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3)',
            '@media( max-width: 780px )': {
                boxShadow: 'none',
                background: 'transparent',
                height: 'auto',
                minHeight: 'unset'
            }
        },
        /** Cart Styles **/
        checkoutCartReviewInfernoContainer: {

            ".spinnerIcon": {
                height: '20px',
                width: '30px',
                verticalAlign: 'middle'
            },

            ".empty-cart": {
                display: "flex",
                width: "95%",
                padding: "20px",
                justifyContent: "space-between",
                border: "2px solid",
                borderColor: global.colors.dark.color3,
                borderRadius: "3px",
                backgroundColor: "#fff",
                height: "65px",
            },
            ".empty-cart-link": {
                textDecoration: "underline"
            },
            ".mobileCartToggle": {
                float:'right',
                fontSize:'14px',
                color:global.colors.dark.color3,
                cursor:"pointer"
            },


            ".cartColumn": {
                padding: '3px 0',
                display: "block",
                overflow: "hidden",
                height: '100%',
                flex: "1",
                fontSize: "14px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                "@media(max-width:480px)": {
                    padding: '15px',
                    marginTop:'15px',
                    boxShadow: `0 2px 4px -1px rgba(0,0,0,0.3)`,
                    background:"#fff"
                }

            },
            ".chkGuestlink_wInlineBlock": {
                backgroundColor: "transparent",
                color: global.colors.dark.color1,
                display: "flex",
                width: 'fit-content',
                fontSize: "16px",
                lineHeight: "20px",
                maxWidth: "100%",
                paddingBottom: "20px",
                paddingTop: "15px",
                transition: 'border .3s ease',
                ':hover ': {
                    '.chkGuestarrow': {
                        marginLeft: '15px'
                    }
                },
            },
            ".chkGuestarrow": {
                border: "0",
                color: global.colors.dark.color1,
                display: "inline-block",
                fontSize: "16px",
                height: '100%',
                width: '20px',
                lineHeight: "20px",
                marginLeft: "10px",
                maxWidth: "100%",
                transition: 'margin .3s ease',
            },
            ".no-products-title": {
                fontSize: '22px',
                marginTop: '20px'
            },
            ".checkRightPanel": {
                alignItems: "stretch",
                backgroundColor: "transparent",
                display: "flex",
                flex: "1",
                flexDirection: "row",
                fontSize: "14px",
                justifyContent: "center",
                paddingTop: "27px",
                overflow: 'hidden',
                height: "auto",
                transition: 'all .5s ease',
                position: 'relative',
                "@media( max-width:480px )": {
                    maxHeight: '0',
                    paddingTop:'0',
                    ".cart-open": {
                        maxHeight: "2000px",
                    }
                },

            },
            ".mobile-cart-dropdown": {
                display: 'none',
                marginBottom: "10px",
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                height: '40px',
                borderBottom: global.border,
                width: '100%',
                marginTop:'15px',
                '@media( max-width:480px )': {
                    display: 'block'
                }
            },
            ".mobile-cart-dropdown-cheveron": {
                float: "right",
                height: '25px',
                width: '25px',
                fill: global.colors.dark.color1,
                cursor: 'pointer',
                transition: 'all .2s',
                ".cart-open": {
                    transform: 'rotate(180deg)'
                }
            },
            ".chkRightPanelContainer": {
                fontSize: "14px",
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "100%",
                width: "100%",
                transition: 'none'
            },
            ".chkTitle1_mobileTitle1": {
                color: global.colors.dark.color1,
                display: "inline-block",
                fontSize: "16px",
                lineHeight: "26px",
                marginBottom: "10px",
                marginTop: "0px",
                paddingBottom: "5px"
            },

            ".mobileUnderline_titleUnderline": {
                backgroundColor: global.colors.dark.color1,
                borderRadius: "5px",
                display: "none",
                fontSize: "14px",
                height: "3px",
                lineHeight: "20px",
                marginBottom: "15px",
                width: "45%"
            },

            ".chkProductgrid": {
                fontSize: "14px",
                lineHeight: "20px"
            },

            ".chkGridheadercontainer": {
                display: "flex",
                fontSize: "14px",
                justifyContent: "flex-end",
                lineHeight: "20px",
                marginBottom: "15px",
                padding:'0 30px',
                "@media(max-width:767px)": {
                    justifyContent: "unset",
                    padding:'0'

                }
            },

            ".chkProdgridimgcontainer": {
                alignItems: "flex-start",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                lineHeight: "20px",
                maxWidth: "20%",
                textAlign: "center",
                width: "20%",
            },

            ".chkGridprice_th": {
                fontFamily: global.fontFamily.secondaryFont,
                alignItems: "flex-start",
                color: global.colors.dark.color3,
                display: "flex",
                flex: "0 auto",
                fontSize: "12px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                width: "180px",
                "@media(max-width:767px)": {
                    fontSize:'14px',
                    width:"120px",
                    marginLeft:'10px'
                }

            },

            ".chkGridqty_chkUnitprice_th": {
                alignItems: "flex-start",
                color: "#828588",
                display: "flex",
                flex: "1",
                fontFamily: global.fontFamily.secondaryFont,
                fontSize: "11px",
                justifyContent: "center",
                lineHeight: "20px",
                textAlign: "center",
                "@media(max-width:480px)": {
                    display: 'none',

                }

            },
            ".chkGridqty_chkUnitprice_th-text": {
                "@media(max-width:767px)": {
                    display: 'none'
                }
            },

            ".chkGridpriceing": {
                color: "#667785",
                display: "flex",
                flex: "1",
                fontSize: "14px",
                justifyContent: "flex-end",
                lineHeight: "20px",
                textAlign: "center",
                "@media(max-width:767px)": {
                    display: 'none'
                }
            },

            ".chkGridqty_th": {
                fontFamily: global.fontFamily.secondaryFont,
                alignItems: "flex-start",
                color: global.colors.dark.color3,
                display: "flex",
                flex: "1",
                fontSize: "12px",
                justifyContent: "center",
                lineHeight: "20px",
                textAlign: "center",
                "@media(max-width:767px)": {
                    maxWidth: '10%',
                }

            },

            ".chkGridsubtotal_th": {
                fontFamily: global.fontFamily.secondaryFont,
                color: global.colors.dark.color3,
                display: "flex",
                flex: "1",
                fontSize: "12px",
                justifyContent: "flex-end",
                lineHeight: "20px",
                textAlign: "center",
                "@media(max-width:767px)": {
                    maxWidth: '20%',
                    justifyContent:'flex-end'
                }
            },

            ".chkProdgrid": {
                display: "flex",
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "20px",
                position: "relative"
            },

            ".chkProdgridimg": {
                border: "0",
                display: "inline-block",
                fontSize: "14px",
                height: "70px",
                lineHeight: "20px",
                marginBottom: "21px",
                maxWidth: "100%",
                textAlign: "center",
                verticalAlign: "middle",
                width: "70px"
            },

            ".chkRemovelink": {
                backgroundColor: "transparent",
                bottom: "0px",
                color: global.colors.dark.color3,
                fontSize: "10px",
                lineHeight: "20px",
                maxWidth: "100%",
                position: "absolute",
                textAlign: "center",
                paddingLeft: '20px',
                ":hover": {
                    textDecoration: 'underline'
                }
            },
            ".cart-item-container": {
                padding:'15px 30px',
                "@media(max-width:991px)": {
                    padding:'10px',
                },
                "@media(max-width:480px)": {
                    padding:0
                }
            },
            ".change-message-bg": {
                backgroundColor: '#e3f2fd',
            },
            ".change-message-container": {
                borderTop:`1px solid ${global.colors.light.color2}`,
                paddingTop:'10px',
                fontFamily:global.fontFamily.secondaryFont
            },
            ".change-message-text": {
                textTransform:'none',
                color:global.colors.dark.color3
            },
            ".change-message-action": {
                display:'block'
            },
            ".change-message-link": {
                fontSize:'16px',
                fontWeight:700,
                display:'inline-block',
                margin:"5px 10px 0 0",
                cursor:'pointer'
            },

            ".chkGridprice": {
                alignItems: "flex-start",
                color: global.colors.dark.color1,
                display: "flex",
                flex: "0 auto",
                fontSize: "14px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                width: "180px",
                "@media(max-width:991px)": {
                    width:'150px'
                },
                "@media(max-width:767px)": {
                    fontSize:'14px',
                    width:"120px",
                    marginLeft:'10px'
                }
            },
            ".divBlock_3": {
                alignItems: "center",
                color: "#333",
                display: "flex",
                flex: "1",
                flexDirection: "column",
                fontSize: "14px",
                lineHeight: "20px",
                "@media(max-width:480px)": {
                    display:'none'
                }
            },
            ".chkGridprice_chkGridpricenote": {
                alignItems: "flex-start",
                color: "#118bef",
                display: "flex",
                flex: "0 auto",
                fontSize: "12px",
                justifyContent: "flex-start",
                lineHeight: "16px",
                paddingLeft: "0px",
                width: "auto",
                "@media(max-width:767px)": {
                    marginLeft:'10px'
                }
            },
            ".chkGridqty_chkUnitprice_strikethrough": {
                alignItems: "flex-start",
                color: "#667785",
                display: "flex",
                flex: "0 auto",
                fontSize: "14px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                textAlign: "center",
                textDecoration: "line-through",
                "@media(max-width:767px)": {
                    display: 'none'
                }

            },

            ".actualprice_chkGridqty_chkUnitprice": {
                alignItems: "flex-start",
                color: "#118bef",
                display: "flex",
                flex: "1",
                fontSize: "14px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                textAlign: "center",
                "@media(max-width:767px)": {
                    display: 'none'
                }
            },

            ".chkGridqty": {
                alignItems: "flex-start",
                color: global.colors.dark.color2,
                display: "flex",
                flex: "1",
                fontSize: "14px",
                justifyContent: "center",
                lineHeight: "20px",
                textAlign: "center",
                "@media(max-width:767px)": {
                    maxWidth: '10%',
                }
            },

            ".chkGridsubtotal": {
                color: global.colors.dark.color2,
                display: "flex",
                flex: "1",
                fontSize: "14px",
                justifyContent: "flex-end",
                lineHeight: "20px",
                textAlign: "center",
                maxWidth: '17.5%'

            },
            ".chkGridunitprice": {
                color: global.colors.dark.color2,
                display: "flex",
                flex: "1",
                fontSize: "14px",
                justifyContent: "flex-end",
                lineHeight: "20px",
                textAlign: "center",
                "@media(max-width:767px)": {
                    maxWidth: '20%'
                }
            },

            ".chkPricinggrid": {
                fontSize: "14px",
                lineHeight: "20px"
            },

            ".chkProductcouponcontainer": {
                display: "flex",
                fontSize: "14px",
                justifyContent: "flex-end",
                lineHeight: "20px",
                marginBottom: "25px",
                padding:"0 30px"
            },

            ".formBlock_wForm": {
                fontSize: "14px",
                lineHeight: "20px",
                margin: "0 0 15px",
                marginBottom: "0px"
            },

            ".couponInput": {
                display: "flex",
                fontSize: "14px",
                justifyContent: "flex-end",
                lineHeight: "20px"
            },

            ".couponInput_textInput_wInput": {
                color: global.colors.dark.color3,
                display: "flex",
                fontSize: "16px",
                height: "38px",
                justifyContent: "flex-end",
                lineHeight: "18px",
                margin: "0",
                marginBottom: "10px",
                padding: "8px 12px",
                textAlign: "left",
                verticalAlign: "middle",
                width: "100%"
            },

            ".couponApplyLink_wButton": {
                backgroundColor: "transparent",
                border: "0",
                borderRadius: "0",
                color: global.colors.dark.color1,
                cursor: "pointer",
                display: "inline-block",
                fontSize: "12px",
                lineHeight: "14px",
                margin: "0",
                padding: "9px 15px",
                paddingBottom: "11px",
                paddingRight: "0px",
                paddingTop: "0px",
                textDecoration: "underline",
                maxWidth: '375px'
            },

            ".div_BiMt": {
                fontSize: "14px",
                lineHeight: "20px",
                textAlign: "center"
            },

            ".div_cMfr": {

                fontSize: "14px",
                lineHeight: "20px"
            },

            ".chkSubtotalcontainer": {
                alignItems: "flex-end",
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                lineHeight: "20px",
                padding:'0 30px',
                "@media(max-width:480px)": {
                    padding:'0'
                }
            },

            '.shipping-cost-container': {
                textAlign: 'right'
            },

            ".checkboxLabel": {
                display: 'block',
                lineHeight: '36px',
                fontSize: '20px',
                color: global.colors.dark.color1,
                verticalAlign: 'middle',
                cursor: 'pointer',
                margin: '0'
            },

            ".chkLabel": {
                color: global.colors.dark.color3,
                fontFamily: global.fontFamily.secondaryFont,
                fontSize: "12px",
                lineHeight: "20px",
                marginBottom: "5px"
            },


            ".chkTaxtotal": {
                float: 'right',
                color: global.colors.dark.color2,
                fontSize: "20px",
                lineHeight: "30px",
                marginBottom: "27px"
            },
            ".editLink": {
                fontSize: '12px',
                display: 'block',
                cursor: 'pointer',
                color: global.colors.dark.color2
            },
            ".chkSubtotal": {
                color: global.colors.dark.color2,
                fontSize: "45px",
                lineHeight: "55px"
            },

            ".cartTextContainer": {
                display: "none",
                fontSize: "14px",
                lineHeight: "20px"
            },

            ".cartTitleSub": {
                fontSize: "32px",
                lineHeight: "36px",
                marginBottom: "10px",
                marginTop: "20px"
            },

            ".cartPriceSub": {
                fontSize: "32px",
                lineHeight: "36px",
                marginBottom: "10px",
                marginTop: "20px"
            },
            ...shippingWidgetStyles,


        },
        toggleLinkblock_wInlineBlock: {
            backgroundColor: "transparent",
            display: "inline-block",
            fontSize: "14px",
            lineHeight: "20px",
            maxWidth: "100%"
        },

        toggleCollapsed: {
            border: "0",
            display: "inline-block",
            fontSize: "14px",
            lineHeight: "20px",
            maxWidth: "100%",
            verticalAlign: "middle"
        }


    });

};

export default Styles;
