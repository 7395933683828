//import $ from "jquery";

const toggleDropdown = () => {
  return id => {
    const $el = $(`#${id}`);
    const dataTypeValue = $el.attr("data-dropdown-button");
    const $associatedDropdownList = $(`[data-dropdown-button-target=${dataTypeValue}`);
    const $collectionOfSiblings = $("[data-dropdown-button-target]");

    $el.on("click", e => {
      if ($associatedDropdownList.hasClass("active")) {
        $associatedDropdownList.removeClass("active");
      } else {
        $collectionOfSiblings.removeClass("active");
        $associatedDropdownList.addClass("active");
      }
    });
  };
};

export default toggleDropdown;
