import {StyleSheet} from "@convertly/css";
import {generatePseudoMediaQuery} from '../../elements/style-functions/pseudo-media-queries';
import {generateHeaderOffsetMediaQueries} from '../header-style-functions/fixed-header-media-queries';
import {createNewCssRules} from '../../utils/edit-userOverrides';
import UserOverrides from '../../elements/style-functions/user-overrides';
import _ from 'lodash';


const Styles = (global, data, headerPosition, inEditor) => {
	
	const userOverrides = data.userOverrides ? new UserOverrides(global, data, inEditor) : {};
	
	data.position = inEditor ? 'static' : data.position;
	
	const displayHeaderTop = _.get(data, "headerTopperSettings.display");
	
	let positionFixed = data.position === "fixed";
	let bodyPaddingMediaQueries = {};
	let headerHeight = _.get(data, 'userOverrides.height') ?
		_.get(data, 'userOverrides.height') :
		displayHeaderTop ? '140px' : '100px';
	let logoOverrides = _.get(data, 'logoOverrides.userOverrides') ?
		UserOverrides(global, data.logoOverrides, inEditor) :
		{};
	
	let mobileBackgroundColor = {};
	if( data.userOverrides ) {
		mobileBackgroundColor = createNewCssRules(data.userOverrides, 'background', ['background']);
		mobileBackgroundColor = createNewCssRules(data.userOverrides, 'backgroundColor', ['background']);
	}
	/** Searches for height in media queries and assigns is
	 to a property that can be passed to height */
	if (data.userOverrides && positionFixed) {
		bodyPaddingMediaQueries = generateHeaderOffsetMediaQueries(data);
	}
	let headerOffset;
	
	if (inEditor) {
		headerOffset = {}
	}
	let headerStyles = {};
	
	if (headerPosition === "overlay") {
		headerStyles = {
			position: 'absolute',
			color: global.colors.light.white,
			stroke: global.colors.light.white,
			background: "transparent"
		}
	}
	else if (headerPosition === "overlayFixed") {
		headerStyles = {
			'.absolute': {
				position: 'absolute',
				color: global.colors.light.white,
				stroke: global.colors.light.white,
				background: "transparent"
			},
		}
	}
	else if (headerPosition === "fixed") {
		
		headerStyles = {
			position: "fixed",
			top: '0',
			zIndex: "12",
			"* body": {
				paddingTop: headerHeight || '0',
				minHeight: `calc(100vh - ${ headerHeight })` || '100vh',
				...generatePseudoMediaQuery(
					"@media( max-width:768px )",
					['smallTablet', 'mobile'],
					{
						paddingTop: displayHeaderTop ? 'calc(80px + 40px)' : '80px',
						minHeight: displayHeaderTop ? `calc(100vh - 80px)` : '100vh',
					},
					inEditor
        ),
        
				...bodyPaddingMediaQueries
			}
		}
	}
	
	else {
		headerStyles = {}
	}
	
	return StyleSheet.create({
		headerTop: {
			fontFamily: global.fontFamily.primaryFont,
			fontSize: "16px",
			lineHeight: "16px",
			margin: "0 auto",
			padding: "0"
		},
		mobileMenu: {
			height: '100vh',
			transition: '.15s all ease',
			width: "290px",
			backgroundColor: global.colors.light.white,
			position: 'fixed',
			top: '0',
			left: "-290px",
			zIndex: '21',
			display: "none",
			'.open': {
				left: "0px",
				...generatePseudoMediaQuery("@media (max-width: 768px)", ['mobile', 'smallTablet'], {
					display: 'block',
				}, inEditor),
				
			},
			...mobileBackgroundColor
		},
		header: {
			display: "block",
			fontFamily: global.fontFamily.primaryFont,
			fontSize: "16px",
			lineHeight: "16px",
			margin: "0",
			padding: "0",
			backgroundColor: global.colors.light.white,
			zIndex: '10',
			width: '100%',
			borderBottom: `3px solid ${global.colors.light.color3}`,
			height: displayHeaderTop ? '140px' : '100px',
			...generatePseudoMediaQuery(
				"@media(max-width:768px)",
				['smallTablet', 'mobile'],
				{
					height: displayHeaderTop ? '120px' : '80px'
				},
				inEditor
			),
			...headerStyles,
			...userOverrides
		},
		mobileMenu2: {
			backgroundColor: "rgba(0,0,0,0.3)",
			display: "none",
			position: 'fixed',
			right: "0",
			top: '0',
			height: "100vh",
			width: "100vw",
			zIndex: 1,
			".open": {
				display: "block"
			}
		},
		mobileNavContent: {
			marginTop: '180px',
			height: "100%"
		},
		header_top_lft: {
			boxSizing: "border-box",
			float: "none",
			fontFamily: global.fontFamily.primaryFont,
			fontSize: "16px",
			lineHeight: "16px",
			margin: "0",
			padding: "0",
			display: 'flex',
			paddingTop: "5px",
		},
		
		header_top: {
			fontFamily: global.fontFamily.primaryFont,
			fontSize: "16px",
			margin: '0px 4%',
			padding: '6px 0px',
			display: 'block',
			borderBottom: `2px solid ${global.colors.dark.color1}`,
			height: '40px',
			...generatePseudoMediaQuery("@media (max-width: 768px)", ['smallTablet', 'mobile'], {
				display: "none",
			}, inEditor),
		},
		socialIconContainer: {
			height: '20px',
			width: '20px',
			backgroundColor: global.colors.dark.color3,
			fill: global.colors.light.white,
			borderRadius: '50%',
			backgroundSize: '30px',
			...generatePseudoMediaQuery("@media (max-width: 768px)", ['smallTablet', 'mobile'], {
				marginTop: "20px",
				marginLeft: "10px"
			}, inEditor),
		},
		connect_us: {
			fontFamily: global.fontFamily.primaryFont,
			margin: "0",
			padding: "0",
		},
		socialIcons: {
			paddingTop: "5px"
		},
		
		span_ymLn: {
			color: global.colors.light.color3,
			display: "inline-block",
			float: "none",
			fontFamily: global.fontFamily.primaryFont,
			fontSize: "10px",
			lineHeight: "12px",
			margin: "0"
			
		},
		
		account: {
			display: "inline-block",
			float: "none",
			fontFamily: global.fontFamily.primaryFont,
			fontSize: "16px",
			lineHeight: "16px",
			margin: "0",
			padding: "5px 5px 0 0"
		},
		navRight: {
			display: 'inline-block',
			float: 'right',
			...generatePseudoMediaQuery("@media (max-width: 768px)", ['smallTablet'], {
				paddingTop: '10px'
			}, inEditor),
			...generatePseudoMediaQuery("@media (max-width: 580px)", ['mobile'], {
				paddingTop: '0px'
			}, inEditor),
		},
		
		mobileHide: {
			...generatePseudoMediaQuery("@media(max-width:767px)", ['smallTablet', 'mobile'], {
				display: 'none'
			}, inEditor),
		},
		navItemContainer: {
			float: 'right',
			paddingTop: '2px'
		},
		logo: {
			backgroundColor: "transparent",
			boxSizing: "border-box",
			display: "inline-block",
			float: "none",
			fontFamily: global.fontFamily.primaryFont,
			fontSize: "16px",
			lineHeight: "16px",
			margin: "0 0 0 1px",
			outline: "0 none !important",
			textDecoration: "none",
			zIndex: "5",
			transition: "all ease-in-out .3s",
			maxWidth: '300px',
			height: '100%',
		},
		logoImg: {
			width: data.logoSize || "auto",
			maxWidth: "350px",
			margin: "16px auto",
			verticalAlign: 'middle',
      maxHeight:headerHeight,
			...generatePseudoMediaQuery("@media(max-width:767px)", ['smallTablet', 'mobile'], {
				width: "auto",
				maxWidth: '100%'
			}, inEditor),
			...generatePseudoMediaQuery(
				"@media(max-width: 768px)",
				['smallTablet', 'mobile'],
				{
					"margin": "0 auto"
				},
				inEditor
			),
			...logoOverrides
		},
		socialIcon: {
			height: '100%',
			width: '100%',
			padding: '3px',
			fill: 'inherit'
		},
		fitHeight: {
			height: '100%'
		}
	});
	
};

export default Styles;
