import  colorAssign  from '../colorAssign';

let defaultGlobal = {
    colors: {
        light: {
            color1: '#CACACA',
            color2: '#B4BBC3',
            color3: '#98A0A9',
            white: "#fff"
        },
        dark: {
            color1: '#1D1D1E',
            color2: '#4A5663',
            color3: '#6F7E90',
            black: '#000'
        },
        accent: {
            color1: '#39618F',
            color2: '#297804',
            color3: '#F26522'
        },
    },
    textColors: {
        light: {},
        dark: {},
        accent: {}
    },
    backgroundColors: {
        light: {},
        dark: {},
        accent: {}
    },
     "moduleDefault": {
        margin:'50px 0',
        padding:'50px 0',
        fontFamily: "'Montserrat', sans-serif"
    },
    /** Typography **/
    fontFamily: {
        primaryFont: " 'Roboto', sans-serif",
        secondaryFont: " 'Rubik', sans-serif"
    },
    fonts: [ 'Roboto', 'Rubik' ],
    h1: {
        color: "#2d343d",
        fontSize: "65px",
        margin:'20px 0',
        lineHeight: "75px",
        "@media (max-width: 767px)": {
            fontSize: "50px",
            lineHeight: "60px"
        },
        "@media (max-width: 479px)": {
            fontSize: "45px",
            lineHeight: "55px"
        },
        "@media (max-width: 340px)": {
            fontSize: "35px",
            lineHeight: "45px"
        },
    },
    h2: {
        color: "#2d343d",
        margin:'20px 0',
        fontSize: '36px',
        lineHeight: '46px',
    },
    h3: {
        fontSize: '24px',
        lineHeight:"34px",
        color:'#4a5663',
    },
    h4: {
        color: '#4a5663',
        fontSize: '16px',
        lineHeight: '26px',
    },
    h5: {
        fontSize: '16px',
        lineHeight:'26px',
    },
    h6: {
        color: '#6f7e90',
        fontSize:'12px',
        lineHeight:'22px',
    },
    p: {
        margin: '10px 0',
        color: '#6f7e90',
        fontSize: '16px',
        lineHeight: '26px',
    },
    list: {
        marginBottom: '20px',
        paddingLeft: '30px'
    },
    listItem: {
        marginBottom:'10px'
    },
    /** Misc Styles */
    divider: {
        backgroundColor: "#98A0A9",
        width: "100%",
        height: "3px",
        marginTop: "10px",
        marginBottom: "10px"
    },
    themeBorder: {
        border: '3px solid #b4bbc3',
        borderRadius: '3px'
    },
    figCaption: {
        position:'absolute',
        bottom:'5px',
        background:'rgba(0, 0, 0, .5)',
        width:'100%',
        padding:'30px 20px',
        color:'#fff',
        "@media (max-width: 479px)": {
            padding:'10px'
        }
    },

    /** Buttons **/
    button1: {
        display: 'inline-block',
        marginBottom: '15px',
        padding: '10px 25px',
        borderRadius: '3px',
        border:'none',
        fontSize:'14px'
    },
    button2: {
        display: 'inline-block',
        marginBottom: '15px',
        padding: '10px 25px',
        border:'3px solid',
        borderRadius: '0px',
        fontSize:'14px'
    },
    button3: {
        display: 'inline-block',
        marginBottom: '15px',
        padding: '20px 50px',
        borderRadius: '3px',
        fontSize:'14px'
    },
    bodyBackgroundColor: ''
};

export default function theme ( global ) {

    global = global ? global : defaultGlobal;

    //colorAssign( global );

    //Body Background Color
    global.bodyBackgroundColor = global.backgroundColors.light.white;
    //Button Styles

    const defaultButtonStyles = {
        background: global.backgroundColors.dark.color2,
        color: global.textColors.light.white,
        borderColor: global.backgroundColors.dark.black,
        fontFamily: global.fontFamily.primaryFont
    };
    const alternateButtonStyles = {
        background: global.backgroundColors.accent.color3,
        textColor: global.textColors.light.white,
        fontFamily: global.fontFamily.primaryFont,
        borderColor: global.backgroundColors.accent.color1
    };

    global.button1 = {
        ...global.button1,
        ...defaultButtonStyles
    };

    global.button1A = {
        ...global.button1,
        ...alternateButtonStyles
    };

    global.button2 = {
        ...global.button2,
        ...defaultButtonStyles
    };

    global.button2A = {
        ...global.button2,
        ...alternateButtonStyles
    };

    global.button3 = {
        ...global.button3,
        ...defaultButtonStyles
    };

    global.button3A = {
        ...global.button3,
        ...alternateButtonStyles
    };

    return global;


}
