import { StyleSheet } from "@convertly/css";
import UserOverrides from '../elements/style-functions/user-overrides';
import {ContestFormStyles} from "./InfernoComponents/ContestForm/ContestFormStyles";
import {ContestSubmissionsStyles} from './InfernoComponents/ContestSubmissions/ContestSubmissionsStyles';
import {ContestSubmissionsModalStyles} from "./InfernoComponents/ContestSubmissionModal/ContestSubmissionModalStyles";

const accordionStyles = ( global, data ) => {

    const userOverrides = UserOverrides( global, data );

    return StyleSheet.create({
        contestManager: {
            padding:'10px',
            ...userOverrides
        },
        contestFormWrapper: {
            ...ContestFormStyles(global, data),   
        },
        contestSubmissionsWrapper: {
            ...ContestSubmissionsStyles(global, data),
            ...ContestSubmissionsModalStyles(global, data)
        }

    });

};

export default accordionStyles;
