import { connect } from "react-redux";
import SaveModuleModal from "../../App/modals/save-module-modal";
import { closeModal } from "../actions/editorActions";

const mapStateToProps = state => {
  return {
    activeModuleId: state.get("editor").editorReducer.get("activeModuleId"),
    pageElements: state.get("editor").editorReducer.get("pageElements"),
    overlayZIndex: state.get("editor").editorReducer.get("overlayZIndex"),
    options: state.get("editor").editorReducer.get("options"),
    site: state.get("editor").editorReducer.get("currentSite"),
    editorType: state.get("editor").editorReducer.get("editorType")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: closeModal
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(SaveModuleModal);

export default Container;
