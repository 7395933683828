import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { findDOMNode } from "react-dom";
import { Emitter } from "services/Emitter";
import _ from "lodash";

import {
  EditorOptionsSC,
  OptionBlock,
  Undo,
  Redo,
  Options,
  Sections,
  Display,
  Option,
  SaveSC,
  XSC,
  ReturnToEditor,
} from "@convertly/ui-components/features/EditorOptions";

import { SVGIconRenderer, Span } from "@convertly/ui-components/general";
import { clearEditorPanel } from "containers/Editor/actions/editorActions";
import { displayOptions } from "./displayOptions";

const GhostBox = styled.div`
  position: absolute;
  right: 100%;
  bottom: 0;
  height: 230px;
  width: 340px;
  border: 1px solid gray;
  background-color: white;
`;

const GHOST_MAX_HEIGHT = 230;
const GHOST_MAX_WIDTH = 340;
const GHOST_OPACITY = 0.4;
const GHOST_PADDING = 50;

const updateOptions = activeViewport =>
  displayOptions.map(opt => {
    opt.active = activeViewport === opt.key;
    return opt;
  });

const handler = (action, payload) => () => action(payload);

export const getGhost = id => document.getElementById(`clipboardItem${id}`);

export class EditorOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCopyItem: null,
    };

    this.activateGhostHandler = this.activateGhostHandler.bind(this);
    this.deactivateGhostHandler = this.deactivateGhostHandler.bind(this);
  }

  pixelToNumber = str => {
    return Number(str.slice(0, -2));
  };

  activateGhostHandler(ghostId, opt) {
    return e => this.activateCorrespondingGhost({ e, idx: ghostId, element: opt });
  }

  deactivateGhostHandler(ghostId) {
    return e => this.deactivateCorrespondingGhost({ e, idx: ghostId });
  }

  activateCorrespondingGhost = ({ e, idx, element }) => {
    const ghost = getGhost(idx);

    if (!ghost) return;

    // this will prevent childNOdes from firing mouseOut inside ghostBox
    ghost.childNodes.forEach(childNode => {
      if (childNode.style) {
        childNode.style.pointerEvents = "none";
      }
    });

    ghost.style.display = "block";

    this.scaleAndPositionGhost(ghost);

    this.dragElement({ elmnt: ghost, element });

    ghost.style.opacity = GHOST_OPACITY;

    this.setState({ activeCopyItem: idx });
  };

  deactivateCorrespondingGhost = ({ e, idx }) => {
    // why is this if statement here?
    // what does it prevent?
    if (e.relatedTarget && e.relatedTarget.id.includes("clipboardItem")) {
      return;
    }

    const ghost = getGhost(idx);

    if (!ghost) {
      return;
    }

    ghost.style.display = "none";

    this.setState({ activeCopyItem: null });
  };

  scaleAndPositionGhost = ghost => {
    const { pixelToNumber } = this;
    let leftPosition = findDOMNode(this.ghostBox).getBoundingClientRect().left;
    let topPosition = findDOMNode(this.ghostBox).getBoundingClientRect().top;
    const boxDimensionStyle = getComputedStyle(findDOMNode(this.ghostBox));
    const ghostStyle = getComputedStyle(ghost);
    const scalingCoefficient = this.calculateScalingCoefficient({
      height: pixelToNumber(ghostStyle.height),
      width: pixelToNumber(ghostStyle.width),
    });
    leftPosition +=
      (pixelToNumber(boxDimensionStyle.width) -
        pixelToNumber(ghostStyle.width) * scalingCoefficient) /
      2;
    topPosition +=
      (pixelToNumber(boxDimensionStyle.height) -
        pixelToNumber(ghostStyle.height) * scalingCoefficient) /
      2;
    ghost.style.left = `${leftPosition}px`;
    ghost.style.top = `${topPosition}px`;
    ghost.style.transform = `scale(${scalingCoefficient})`;
    ghost.style.transformOrigin = "top left";
    ghost.style.margin = "0px";
  };

  calculateScalingCoefficient = ({ height, width }) => {
    let scalingCoefficient;

    if (height / (GHOST_MAX_HEIGHT - GHOST_PADDING) > width / (GHOST_MAX_WIDTH - GHOST_PADDING)) {
      if (height > GHOST_MAX_HEIGHT - GHOST_PADDING) {
        scalingCoefficient = (GHOST_MAX_HEIGHT - GHOST_PADDING) / height;
      } else scalingCoefficient = 1;
    } else {
      if (width > GHOST_MAX_WIDTH - GHOST_PADDING) {
        scalingCoefficient = (GHOST_MAX_WIDTH - GHOST_PADDING) / width;
      } else scalingCoefficient = 1;
    }
    return scalingCoefficient;
  };

  dragElement = ({ elmnt, element }) => {
    let pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;

    const dragMouseDown = async e => {
      e = e || window.event;
      //e.preventDefault();

      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;

      document.onmouseup = event => closeDragElement(e);

      Emitter.emit("DRAG_NEW_ELEMENT", {
        clientX: e.clientX,
        clientY: e.clientY,
      });
      Emitter.emit("START_ANIMATION");
      this.props.setDraggingNewElement(element);

      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    };

    const elementDrag = e => {
      e = e || window.event;
      e.preventDefault();

      // hide the clipboard ghost box
      findDOMNode(this.ghostBox).style.visibility = "hidden";

      if (Number.isInteger(this.state.activeCopyItem)) {
        this.setState({ activeCopyItem: null });
      }
      // when you start dragging you must close the editor panel
      if (!pos1 && !pos2) {
        if (this.props.editorType.get("field")) {
          window.wasEditorPanelActive = true;
        }
        this.context.store.dispatch(clearEditorPanel());
      }

      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;

      // set the element's new position:
      elmnt.style.top = elmnt.offsetTop - pos2 + "px";
      elmnt.style.left = elmnt.offsetLeft - pos1 + "px";

      Emitter.emit("DRAG_NEW_ELEMENT", {
        clientX: e.clientX,
        clientY: e.clientY,
      });
    };

    const closeDragElement = async e => {
      /* stop moving when mouse button is released:*/
      const ghost = e.target;
      if (ghost) {
        ghost.style.display = "none";
      }
      if (element.get("parentId")) element = element.delete("parentId"); // indicates that element isn't an existing one
      document.onmouseup = null;
      document.onmousemove = null;
      Emitter.emit("DROP_NEW_ELEMENT", {
        element: element,
      });

      await Promise.all([
        this.props.setDraggingNewElement(null),
        this.setState({ activeCopyItem: null }),
      ]);
    };
    elmnt.onmousedown = dragMouseDown;
  };

  deleteClipboardItem = opt => {
    const clipboardItems = this.props.clipboardItems.toJS();
    let index = clipboardItems.findIndex(item => {
      return item.ghostId === opt.get("ghostId");
    });
    clipboardItems.splice(index, 1);
    document.getElementById(`clipboardItem${opt.get("ghostId")}`).remove();
    this.props.updateClipboardItems(clipboardItems);
  };
  toggleViewPort = opt => {
    this.props.toggleViewPortVisible();
    this.props.changeActiveViewport(opt.key);
  };
  render() {
    const {
      activeViewport,
      clipboardVisible,
      viewPortVisible,
      changeActiveViewport,
      toggleClipboardVisible,
      toggleViewPortVisible,
      clipboardItems,
      saveClipboardItem,
      onRedo,
      onUndo,
      isRedoDisabled,
      isUndoDisabled,
      elementsThatCanBeAddedToPage,
    } = this.props;

    const hoveredStyle = {
      opacity: 1,
      backgroundColor: "#555555",
    };

    return (
      <EditorOptionsSC viewPortVisible={viewPortVisible}>
        <OptionBlock>
          <Undo onClick={onUndo} disabled={isUndoDisabled} />
          <Redo onClick={onRedo} disabled={isRedoDisabled} />
        </OptionBlock>

        <OptionBlock>
          <Options active={clipboardVisible && !!clipboardItems.size}>
            {clipboardItems.map(opt => {
              const ghostId = opt.get("ghostId");
              const isActiveGhostItem = this.state.activeCopyItem === ghostId;

              return (
                <div
                  onMouseOver={this.activateGhostHandler(ghostId, opt)}
                  onMouseOut={this.deactivateGhostHandler(ghostId)}
                  key={`clipboard-item-${ghostId}`}
                >
                  <GhostBox
                    style={{ visibility: isActiveGhostItem ? "visible" : "hidden" }}
                    ref={el => (this.ghostBox = el)}
                  />
                  <Option key={opt.get("id")} style={isActiveGhostItem ? hoveredStyle : {}}>
                    {opt.icon && <SVGIconRenderer url={opt.icon} width={"20px"} height={"20px"} />}
                    <Span>{opt.get("el").split(".")[1]}</Span>
                    <SaveSC
                      onClick={handler(saveClipboardItem, {
                        item: opt,
                        elementsThatCanBeAddedToPage,
                      })}
                      url={
                        "https://s3.amazonaws.com/convertlyimguploadeast/convertly/ui-components/assets/icons/save_white.svg"
                      }
                    />
                    <XSC onClick={handler(this.deleteClipboardItem, opt)}>&#10005;</XSC>
                  </Option>
                </div>
              );
            })}
          </Options>
          <Sections count={clipboardItems.size} onClick={toggleClipboardVisible} />
        </OptionBlock>

        <ReturnToEditor onClick={toggleViewPortVisible}>Return to Editor</ReturnToEditor>

        <OptionBlock>
          <Options active={viewPortVisible}>
            {updateOptions(activeViewport).map((opt, idx) => (
              <Option key={idx} onClick={e => this.toggleViewPort(opt)} active={opt.active}>
                {opt.icon && <SVGIconRenderer url={opt.icon} width={"20px"} height={"20px"} />}
                <Span>{opt.text}</Span>
              </Option>
            ))}
          </Options>
          <Display onClick={toggleViewPortVisible} />
        </OptionBlock>
      </EditorOptionsSC>
    );
  }
}
EditorOptions.contextTypes = {
  store: PropTypes.object,
};
EditorOptions.defaultProps = {
  activeViewport: "",
  clipboardVisible: false,
  viewPortVisible: false,
  onRedo: () => console.log("onRedo"),
  onUndo: () => console.log("onUndo"),
  clipboardItems: [
    {
      text: "Section 01",
      id: "asdfasdfasdf",
    },
    {
      text: "Row 01",
      id: "23rwekhwutyiw4hjkwhtiuh",
    },
  ],
  saveClipboardItem: item => console.log("save", item),
};
