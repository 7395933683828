//import $ from 'jquery';
//"use strict"
const navDropdownToggle = (id) => {

    return (id) => {

        const el = $('#' + id);

        let mobileNav = $('.mobileNav_' + id);
        let mobileNav2 = $('.mobileNav2_' + id);

        el.on('click', function (e) {

            e.preventDefault;

            const target = this.dataset.target;
            const $menuIcon = $('#menuIcon');
            let children = $(el).children();

            $(target).toggleClass('open');
            $(target).children().toggleClass('open');

            if(mobileNav.hasClass('open')) {
                $menuIcon.removeClass('open');
                children.removeClass('open');
                mobileNav.removeClass('open');
                mobileNav2.removeClass('open');
            }
            else {
                $menuIcon.addClass('open');
                children.addClass('open');
                mobileNav.addClass('open');
                mobileNav2.addClass('open');
            }

        })

        $(mobileNav2).on('click', function() {

            $('[data-target="#menu"]').removeClass('open');
            mobileNav.removeClass('open');
            mobileNav2.removeClass('open');

        });
    }

};


export default navDropdownToggle;
