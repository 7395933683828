/*global Inferno, $, React */
/** Drop down Product Variant Widget **/

const Script = (React) => {
		
		return ({nameOfClass}) => {
			return (
            <svg version="1.1" id="Capa_1"
                 xmlns="http://www.w3.org/2000/svg"
                 x="0px"
                 y="0px"
                 className={ nameOfClass }
                 viewBox="0 0 512 512" >
                <g>
                    <g>
                        <path d="M418.783,334.787c15.56-11.689,26.829-27.669,32.602-46.23L512,109.102h-70.307V0.178H261.818v38.973h-99.93v69.951
			h-39.551l-4.299-47.671c-1.479-16.766-9.131-32.26-21.543-43.627C84.082,6.439,67.975,0.178,51.144,0.178H0V40.15h51.144
			c14.189,0,25.829,10.66,27.08,24.833l31.281,346.848c0.005,0.062,0.011,0.124,0.018,0.185
			c3.023,30.358,25.644,54.136,54.656,59.401c8.127,23.487,30.452,40.406,56.668,40.406c25.68,0,47.634-16.233,56.16-38.973h45.571
			c8.526,22.74,30.479,38.973,56.16,38.973c33.061,0,59.958-26.897,59.958-59.958s-26.897-59.958-59.958-59.958
			c-26.426,0-48.897,17.191-56.859,40.971h-44.171c-7.962-23.78-30.434-40.971-56.859-40.971c-25.378,0-47.11,15.858-55.846,38.178
			c-8.513-3.89-14.681-12.05-15.695-21.95l-4.979-55.203h220.418C384.296,352.933,402.981,346.658,418.783,334.787z
			 M378.737,431.878c11.02,0,19.986,8.966,19.986,19.986s-8.966,19.986-19.986,19.986c-11.02,0-19.986-8.966-19.986-19.986
			S367.716,431.878,378.737,431.878z M220.846,431.878c11.02,0,19.986,8.966,19.986,19.986s-8.966,19.986-19.986,19.986
			s-19.986-8.966-19.986-19.986S209.826,431.878,220.846,431.878z M301.79,40.15h99.93v68.952h-99.93V40.15z M201.86,79.123h59.958
			v29.979H201.86V79.123z M140.722,312.96l-14.78-163.886h330.366l-42.875,126.933l-0.167,0.519
			c-6.6,21.452-26.553,36.435-48.52,36.435H140.722z"/>
                    </g>
                </g>
            </svg>

			)
		}
	}
;

export default Script;
