/*global Inferno, $, React */
/** Todo-list-01 wrapper **/
import TodoInput_I from './todo-input';
import ActiveTodos_I from './active-todos';
import {importAndExec} from '@convertly/thor';

const TodoUiWrapper = () => {

    importAndExec([TodoInput_I, ActiveTodos_I]);

    return ({data, rootClass}) => {

        return (
            <div className={ `${rootClass} todo-app-wrapper` } >
                <h1>Todo List</h1>
                <TodoInput
                    rootClass={rootClass}
                    todoInputValue={data.todoInputValue}/>
                <ActiveTodos
                    rootClass={rootClass}
                    activeTodos={data.activeTodos}/>
            </div>
        );
    }
};

export default TodoUiWrapper;
