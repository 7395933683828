import React from 'react';
import PropTypes from 'prop-types';
import Component from '../../rootComponent';
import {css} from "@convertly/css";
import defaultGlobal from '../../themes/global.js';
import _ from 'lodash';

//Styles
import moduleWrapperStyles from './hovering-image-section-styles';

//Utils
import generateId from '../../utils/generate-id';
import {clientImage} from '../../utils/generateImage';
import Elements from '../../elements/index';

/**
 * Class controls imports style functions that allows for user to override the css in the CMS editor for their site.
 **/
class HoveringImageSection extends Component {

    constructor(props) {

        super(props);
        this.state = {
            elementEditableBorder: ""
        };
        this.removeWrapperOutline = this.removeWrapperOutline.bind(this);
        this.addWrapperOutline = this.addWrapperOutline.bind(this);

    }

    removeWrapperOutline(e) {
        if (!this.context.store.getState().get('editor').editorReducer.get('activeModuleId')) {
            this.setState({
                elementEditableBorder: ""
            });
            e.hoverOverChild = false;
        }
        else if (this.context.store.getState().get('editor').editorReducer.get('editorType').get('field') !== this.props.id) {
            this.setState({
                elementEditableBorder: ""
            });
            e.hoverOverChild = false;
        }
    }

    /**
     * Removes the style that outlines element
     **/
    addWrapperOutline(e) {
        if (!e.hoverOverChild) {
            if (this.state.elementEditableBorder === '6px dashed #F5A623')
                this.setState({
                    elementEditableBorder: "6px dashed #F5A623 "
                });
            else
                this.setState({
                    elementEditableBorder: "6px dashed #F5A623"
                });

        }

    }

    render() {
        /**
         * Calls stylesheet function
         **/
        this.styles = moduleWrapperStyles(this.props.global, this.props.data);
        let elementEditableBorder = {};
        if (!this.props.notEditable) {
            if (this.state.inEditor) {
                elementEditableBorder.outline = this.state.elementEditableBorder;
            }
        }


        let className;

        className = css(this.styles.homePageHero);

        if (this.props.defaultClass) {
            className += " " + this.props.defaultClass;
        }
        /** Clones children and assigns them props **/
        function renderChildren(props) {
            return React.Children.map(props.children, child => {
                return React.cloneElement(child, {
                    moduleId: props.moduleId,
                    moduleSort: props.moduleSort,
                    styles: props.styles
                });
            })
        }

        /** Background video functionality **/
        let backgroundVideo = null;
        if (this.props.data.backgroundVideo) {
            backgroundVideo =
                <div className={ css(this.styles.styles) }>
                    <Elements.VideoBG data={ this.props.data.backgroundVideo }/>
                </div>

        }
        let overlay = null;
        if (this.props.data.overlay) {
            overlay =
                <div className={ css(this.styles.overlay) }/>
        }

        //let bottomImg = _.get(this.props.data, 'featureImage.src') ? this.props.featureImage.src : 'screenshot-homepage-placeholder-1.png';

        let featureImgParams = _.get(this.props.data, 'featureImage.params') || {}
        
        //console.log("PARAMS", featureImgParams);

        return (
            <div
                { ...this.generateDataEditorAttribute() }
                onClick={ (e) => {
                    this.launchElementalEditor('background', e);
                }  }
                onMouseOver={ this.addWrapperOutline }
                onMouseOut={ this.removeWrapperOutline }
                className={ className }
                style={ elementEditableBorder }
                { ...generateId(this.props.divId) }
            >
                { backgroundVideo }
                { overlay }
                <div className={ css(this.styles.convertlyContentArea) }>
                    { renderChildren(this.props) }
                </div>
                <img src={ clientImage(_.get(this.props.data, 'featureImage.src'),
                    featureImgParams
                ) }
                     alt={ _.get(this.props.data, 'featureImage.alt') }
                     className={ css(this.styles.bottomImg) }/>

            </div>

        );


    }
}


/**
 * Default Props for rendering when no data is being passed
 **/
HoveringImageSection.defaultProps = {
    data: {},
    global: defaultGlobal
};

/**
 * Connects element to context for cms editor
 **/
HoveringImageSection.contextTypes = {
    store: PropTypes.object
};

export default HoveringImageSection

/**
 Component BoilerPlate

 <Elements.ModuleWrapper
 parentClassName={ this.styles.heroSection }
 data={ this.props.data }
 global={ this.props.global }
 elementDataName="backgroundColor"
 moduleId={ this.props.id }
 moduleSort={ this.props.sort }>

 **/
