/*global Inferno, $, React */
/** Box Selector Product Variant Widget **/
import React from 'react';
const Script = (dispatch, AddToCartStepper, QuickBuy) => {
	return (props) => {
		if (props) {

			
			return (
				<div className={ `${props.rootClass} buying-options-wrapper` }>
					{ !props.showAddToCart ?
            <div 
              className={ `${ props.rootClass } options-bar` }
              onClick={ props.toggleMobileOptions }>
							<div className={ `${ props.rootClass } options-content`  }>
								Options
							</div>
						</div>
						:
						<div>
							<AddToCartStepper
								rootClass={ props.rootClass }
								disableAddToCartButton={ props.disableAddToCart }
								product={ props.product }
								currentQty={ props.currentQty }
								resetButtonState={ props.resetButtonState }
								increaseQuantity={ props.increaseQuantity }
								decreaseQuantity={ props.decreaseQuantity }
								addToCart={ props.addToCart }
							/>
							<QuickBuy
								rootClass={ props.rootClass }
								disableAddToCartButton={ props.disableAddToCart }
								product={ props.product }
								currentQty={ props.currentQty }
								resetButtonState={ props.resetButtonState }
								increaseQuantity={ props.increaseQuantity }
								decreaseQuantity={ props.decreaseQuantity }
								addToCart={ props.addToCart }
							/>
						</div>
					}
				</div>
			);
		}
	}
};

export default Script;
