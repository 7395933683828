import {StyleSheet} from "@convertly/css";
import SocialIconGlobalStyles from '../social-icon-global-styles';
//import svgIconGlobal from '../../../global/svg-icon-global-styles';

const SocialIconStyles = (global, data) => {

    const socialIconStyles = new SocialIconGlobalStyles(global, data.socialIcon);

    return StyleSheet.create({
        socialIconItem: {
            ...socialIconStyles.socialIconItem
        },
        socialIconLink: {
            display: 'block',

        },
        socialIcon: {
            height: '1em',
            width: '1em',
            cursor: 'pointer',
            fill: global.colors.dark.color2,

            //...socialIconStyles,
            ':hover': {
                fillOpacity: '.8'
            }
        }
    })
};

export default SocialIconStyles;
