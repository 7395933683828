/*global Inferno, $ */
import {include} from '@convertly/thor';
const Script = () => {

	const Graph = include('../graph', 'Graph');
	const Dispatcher = include('../dispatcher', 'Dispatcher');

    return ( id ) => {

        const graph = Graph();
        const dispatch = Dispatcher(id);

        const hasLocalStorage = $('html').hasClass('localstorage');

        let currentCart;
        /** Loads cart from local Storage **/
        let loadLocalCart = function () {
            let cart = localStorage.getItem('cart');
            let cartLoaded = false;

            const query = `query( $ids:[ID] ){
                            Products( ids: $ids ) {
                                id, brand, price, id, title, image
                                }
                            }`;

            const variables = {
                ids: productIdsArray
            };

            const callback = (error, response) => {

                if (error) {
                    // error handling
                }

                const products = response.Products;
            };

            graph({query: query, variables: variables, cb: callback});

            let productIdsArray = [];
            if (cart) {

                try {
                    cart = JSON.parse(cart);

                    cart.items.map((item) => {
                        productIdsArray.push(item.id);
                    });
                    /**
                     * Gets items from product database by id.
                     * @param  {[type]} $ids:[ID]
                     * @return {[type]} products array
                     */
                    const query = `query( $ids:[ID] ) {
                                          Products( ids: $ids ) {
                                            id, brand, price, id, title, image
                                          }
                                        }`;
                    const variables = {
                        ids: productIdsArray
                    };

                    const callback = (response, error) => {

                        if (error) {
                        }
                        //Graph response
                        const products = response.Products;
                        /**
                         * Iterates throught response and gives proper quantities to each item in
                         * graph response
                         * @param  {[type]} ( responseItem  ) [description]
                         * @return {[type]}   [description]
                         */

                        products.forEach( ( responseItem ) => {
                                cart.items.forEach( ( cartItem ) => {
                                if( cartItem.id === responseItem.id ) {
                                    responseItem.quantity = cartItem.quantity;
                                }
                            })
                        });
                        dispatch.send('cart', products);
                    };

                    graph({query: query, variables: variables, cb: callback});


                } catch (e) {
                    localStorage.removeItem('cart');
                }

            } else {
                loadRemoteCart();
            }
        };
        /** Loads cart from back up api **/
        let loadRemoteCart = function () {

        };

        let init = function () {
            if (hasLocalStorage) {
                loadLocalCart();
            } else {
                loadRemoteCart();
            }
        };
        init();
        dispatch.on('request_cart', ( ) => {
            dispatch.send('cart', currentCart);
        })
    }
};

export default Script;
