import React from 'react';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import defaultGlobal from '../../../themes/global.js';
import ReactHTMLParser from "@convertly/react-html-parser";

// Styles
import styles from '../form-styles'


export default class RadioInput extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        this.styles = styles(this.props.global, this.props.data);

        return (
            <label className={`${css(this.styles.formInfernoContainer)} radioLabel`}>
                <input
                    className={`${css(this.styles.formInfernoContainer)} radio`}
                    type={"radio"}/>
                {this.props.data.label}
            </label>

        )
    }
};

RadioInput.defaultProps = {
    global: defaultGlobal,
    data: {}
};



