/*global Inferno, $, TodoInput */
/* eslint-disable */

import {include} from '@convertly/thor'
import ContestSubmissionsModalGenerator from "../ContestSubmissionModal/ContestSubmissionModal";

const ContestFormInferno = () => {

        const ContestSubmissionsInferno = include('./ContestSubmissions', 'ContestSubmissionsInferno');
        const ContestSubmissionsModalGenerator = include( '../ContestSubmissionModal/ContestSubmissionModal', 'ContestSubmissionsModalGenerator' )
        const Graph = include( '../../../utils/graph', 'Graph' )


        return (rootId, siteId, contestId, imgPath) => {

            const root = document.getElementById(rootId);

            const ContestSubmissions = ContestSubmissionsInferno();
            const ContestSubmissionModal = ContestSubmissionsModalGenerator()
            const graph = Graph();

            const {contestString} = root.dataset;
            const contest = JSON.parse(contestString);

            const ContestFormWrapper = Inferno.createClass({

                getInitialState: function () {

                    return {
                        showModal:false,
                        submissions:[],
                        activeSubmission: {},
                        activeTabId: this.getContestTabInitialValue()
                    }
                    this.setActiveContest = this.setActiveContest.bind(this);
                    this.closeModal = this.closeModal.bind(this);
                    this.fetchSubmissions = this.fetchSubmissions.bind(this);
                    this.setActiveTab = this.setActiveTab.bind(this);
                },

                getContestTabInitialValue() {
                    return contest.tabSettings && contest.tabSettings.length !== 0 ? contest.tabSettings[0].id : null;
                },

                fetchSubmissions() {
                    const query = `
                    query($contestId:ID! $siteId:ID! $tabId:ID $limit:Int $page:Int) {
                          SUBMISSIONS:getContestSubmissions(contestId:$contestId siteId:$siteId tabId:$tabId limit:$limit page:$page) {
                            totalCount
                            submissions {
                            id
                            contestId
                            tabId
                            dateSubmitted
                            imageName
                            imageDescription
                            imageUrl
                            email    
                            } 
                          }
                        }`;

                    const variables = {
                        contestId,
                        siteId
                    }

                    if(this.state.activeTab) {
                        variables.tab = this.state.activeTab
                    }
                    const addSubmissionsToState = (error, data) => {
                        this.setState({submissions:data.SUBMISSIONS.submissions})
                    }

                    graph({query, variables, cb:addSubmissionsToState})

                },

                componentDidMount() {
                    this.fetchSubmissions()
                },

                setActiveContest(submission) {
                    this.setState({
                        showModal:true,
                        activeSubmission:submission
                    })
                },

                closeModal() {
                    this.setState({
                        showModal:false,
                        activeSubmission:{}
                    })
                },

                setActiveTab(id) {
                  this.setState({activeTabId:id})
                },

                render: function () {

                    return (
                        <div>
                        <ContestSubmissions
                            clickHandler={this.setActiveContest}
                            imgPath={imgPath}
                            setActiveTab={this.setActiveTab}
                            activeTabId={this.state.activeTabId}
                            tabs={contest.tabSettings}
                            submissions={this.state.submissions}/>
                            {
                               this.state.showModal &&
                                <ContestSubmissionModal
                                    closeModal={this.closeModal}
                                    imgPath={imgPath}
                                    activeSubmission={this.state.activeSubmission}/>
                            }

                        </div>
                    )
                }

            });

            Inferno.render(<ContestFormWrapper/>, root);

        };

    };

export default ContestFormInferno
