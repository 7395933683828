import React from "react";
import PropTypes from "prop-types";
import { fromJS } from "immutable";
import styled, { keyframes } from "styled-components";
import PageIcon from "../icons/page";
import { css } from "@convertly/css";
import { styles } from "containers/Editor/components/editor/editor-forms-styles";
import { setMainMenu, showLoadingIcon } from "containers/App/actions";

import {
  setNavigation,
  userClickedAddPage,
  setCurrentModal,
  setOverlayStyles,
  setSettings,
  setActivePage,
  loadActivePage,
  changePageType,
} from "containers/Editor/actions/editorActions";

import CONSTANTS from "containers/Editor/constants";

const cssStyle = {
  chat: {
    paddingLeft: "5px",
    display: "inline-block",
  },
  closeModal: {
    height: "5em",
    backgroundColor: "#F6F6F6",
    marginBottom: "1.5em",
  },
  closeIcon: {
    margin: "3px",
    fontSize: "18px",
    float: "right",
    cursor: "pointer",
  },
  search: {
    backgroundColor: "white",
    width: "240px",
    height: "25px",
    border: "1px solid #C6C6C6",
    color: "#414141",
    marginLeft: "15px",
    marginTop: "5px",
    borderRadius: "2px",
    paddingLeft: "10px",
  },
  li: {
    margin: "15px 0 15px 0",
    display: "flex",
    height: "30px",
    alignItems: "center",
  },
  addButton: {
    backgroundColor: "green",
    color: "white",
    borderRadius: "5px",
    width: "240px",
    height: "30px",
    lineHeight: "20px",
  },
};

const enter = keyframes`
	
	from {
		opacity: 0;
		transform: translateY( 100px ) scale( .75 );
	}
	
	to {
		opacity: 1;
		transform: translateY( 0 ) scale( 1 );
	}

`;

const exit = keyframes`
	
	to {
		opacity: 0;
		transform: translateY( 100px ) scale( .75 );
	}
	
	from {
		opacity: 1;
		transform: translateY( 0 ) scale( 1 );
	}

`;

const Wrapper = styled.div`
  background-color: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  width: 20em;
  max-height: 560px;
  left: 5em;
  position: fixed;
  top: 3.1em;
  bottom: 2.1em;
  z-index: 5000;
  padding: 0;
  overflow: auto;
  animation: ${props => (props.transition === "enter" ? enter : exit)} 100ms ease-in forwards;

  .icon:hover {
    opacity: 1 !important;
  }
`;

const PageLink = styled.span`
  cursor: pointer;
  user-select: none;
  &:hover {
    color: #428bca;
  }
`;

const Icon = styled.i`
  font-size: 20px !important;
  opacity: 0.5;
  margin-right: 10px;
  cursor: pointer;
  display: none !important;
`;

const PageRow = styled.li`
  :hover ${Icon} {
    display: inline-block !important;
  }
`;

const Underlay = ({ onClick }) => (
  <div className={css(styles.editMenuUnderlay)} onClick={onClick} />
);

export class EditMenu extends React.Component {
  // eslint-disable-line react/prefer-stateless-function

  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      transition: "enter",
    };

    this.handleClick = this.handleClick.bind(this);
    this.setMenu = this.setMenu.bind(this);
    this.handleAddPage = this.handleAddPage.bind(this);
    this.handleLoadPage = this.handleLoadPage.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSettings = this.handleSettings.bind(this);
    this.collapseMenu = this.collapseMenu.bind(this);
    this.updateSearch = this.updateSearch.bind(this);
  }

  updateSearch(e) {
    const search = e.target.value;
    this.setState({ search });
  }

  /**
   *
   * @param e
   */
  handleClick(e) {
    this.context.store.dispatch(setMainMenu(false));
  }

  /**
   *
   * @param e
   */
  setMenu(e) {
    this.context.store.dispatch(setMainMenu(true));
  }

  /**
   * Event handler for Add Page button
   * @param e
   */
  handleAddPage() {
    this.collapseMenu().then(() => true);
    this.context.store.dispatch(userClickedAddPage());
  }

  /**
   * Event handler to load the page in the site Editor
   * @param page
   */
  async handleLoadPage(page) {
    if (page.get("pageId") === this.props.page.get("id")) {
      return;
    }
    await this.context.store.dispatch(changePageType(""));
    await this.context.store.dispatch(setActivePage(page.get("pageId")));
    await this.context.store.dispatch(loadActivePage());
  }

  /**
   *
   * @param e
   * @param page
   */
  async handleDelete(page, e) {
    e.preventDefault();
    e.stopPropagation();

    let confirmation = confirm("Do you want to proceed to delete this page? " + page.get("name"));

    if (confirmation) {
      await this.props.deletePage(page);
    }
  }

  /**
   *
   * @param e
   * @param page
   */
  handleSettings(page, e) {
    e.preventDefault();
    e.stopPropagation();

    this.collapseMenu();
    this.context.store.dispatch(setCurrentModal(CONSTANTS.MODALS.PAGE_SETTINGS));
    this.context.store.dispatch(
      setOverlayStyles({
        zIndex: 2700,
        backgroundColor: "rgba(30,30,30,0.5)",
      })
    );
    this.context.store.dispatch(showLoadingIcon(true));
    this.context.store.dispatch(setSettings(page.get("pageId")));
  }

  async collapseMenu() {
    await this.setState({ transition: "exit" });
    setTimeout(() => this.context.store.dispatch(setNavigation(false)), 100);
  }

  /**
   *
   * @returns {*|boolean|XML}
   */
  render() {
    if (!this.props.showNavigationMenu) {
      return null;
    }

    const { site } = this.props;

    let pages = site.get("pages") || fromJS([]);

    if (this.state.search) {
      pages = pages.filter(p => {
        return (
          p
            .get("name")
            .toLowerCase()
            .indexOf(this.state.search.toLowerCase()) !== -1
        );
      });
    }

    return (
      <div>
        <Wrapper transition={this.state.transition}>
          <div style={cssStyle.closeModal}>
            <i style={cssStyle.closeIcon} className="material-icons" onClick={this.collapseMenu}>
              close
            </i>
            <br />
            <input
              onChange={this.updateSearch}
              value={this.state.search}
              type="text"
              style={cssStyle.search}
              placeholder="Search"
            />
          </div>
          <div>
            <ul style={{ listStyle: "none", padding: "0 1em 0 1em" }}>
              {pages.map((page, index) => {
                return (
                  <PageRow key={"Page" + index} style={cssStyle.li}>
                    <PageIcon />
                    <PageLink
                      onClick={this.handleLoadPage.bind(null, page)}
                      style={{ cursor: "pointer" }}
                    >
                      {page.get("name")}
                    </PageLink>
                    <div style={{ marginLeft: "auto", marginTop: "8px" }}>
                      <Icon
                        className="icon material-icons"
                        onClick={this.handleSettings.bind(this, page)}
                      >
                        settings
                      </Icon>
                      {page.get("slug") !== "/" && (
                        <Icon
                          className="icon material-icons"
                          onClick={this.handleDelete.bind(this, page)}
                        >
                          delete
                        </Icon>
                      )}
                    </div>
                  </PageRow>
                );
              })}
            </ul>
          </div>
          <hr />
          <br />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button style={cssStyle.addButton} onClick={this.handleAddPage}>
              +Add Page
            </button>
          </div>
        </Wrapper>

        <Underlay onClick={this.collapseMenu} />
      </div>
    );
  }
}

EditMenu.contextTypes = {
  store: PropTypes.object,
};

EditMenu.propTypes = {
  deletePage: PropTypes.func,
};

EditMenu.defaultProps = {
  deletePage: () => {},
};
