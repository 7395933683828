import { createAction } from "redux-act";
import _ from "lodash";
import { graph } from "utils";

import { PANELS } from "../../constants";
import { getActiveSiteId } from "services/sites";
import { changePanel, setProcessing } from "containers/Editor/actions/navBarActions";

export const setBlogLandingDefaultTemplate = createAction(
  "BLOG LAYOUT: Set blogLandingDefaultTemplate"
);
export const setBlogLandingFrontPageDefaultTemplate = createAction(
  "BLOG LAYOUT: Set blogLandingFrontPageDefaultTemplate"
);
export const setBlogPostDefaultTemplate = createAction("BLOG LAYOUT: Set blogPostDefaultTemplate");
export const setBlogCategoryDefaultTemplate = createAction(
  "BLOG LAYOUT: Set blogCategoryDefaultTemplate"
);

export const init = () => async dispatch => {
  await dispatch(setProcessing(true));

  const query = `
  query($siteId:ID!){
    Site(id:$siteId){
      blogLandingDefaultTemplate
      blogLandingFrontPageDefaultTemplate
      blogPostDefaultTemplate
      defaultAuthorTemplate
      defaultCategoryTemplate
    }
  }`;

  const variables = {
    siteId: getActiveSiteId(),
  };

  const res = await graph({ query, variables });

  const {
    blogLandingDefaultTemplate,
    blogLandingFrontPageDefaultTemplate,
    blogPostDefaultTemplate,
    defaultCategoryTemplate,
  } = _.get(res, "Site");

  await Promise.all([
    dispatch(setBlogLandingDefaultTemplate(blogLandingDefaultTemplate)),
    dispatch(setBlogLandingFrontPageDefaultTemplate(blogLandingFrontPageDefaultTemplate)),
    dispatch(setBlogPostDefaultTemplate(blogPostDefaultTemplate)),
    dispatch(setBlogCategoryDefaultTemplate(defaultCategoryTemplate)),
    dispatch(setProcessing(false)),
  ]);
};

export const editLayout = ({ key }) => async dispatch => {
  await dispatch(changePanel(key, PANELS.BLOG));
};
