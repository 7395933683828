import React from "react";
const ReportsIcon = () => (
  <svg
    stroke="white"
    strokeWidth="1"
    viewBox={"0 0 16 16"}
    style={{
      marginRight: "1em",
      position: "relative",
      top: "1px",
      left: "5px",
      height: "1.5em",
      width: "1.5em"
    }}
  >
    <path
      fill={"black"}
      fillOpacity={0.5}
      d="M9.8833871,1.10761364 L9.88258065,1.10761364 L9.88258065,0.422386364 C9.88258065,0.259431818 9.75774194,0.1275 9.60354839,0.1275 L9.2083871,0.1275 L4.58096774,0.1275 L4.30193548,0.1275 L0.116451613,4.55079545 L0.116451613,4.96789773 L0.116451613,5.14056818     L0.116451613,14.1596591 L0.116451613,14.5767614 C0.116451613,14.7397159 0.241290323,14.8716477 0.395483871,14.8716477 L0.650645161,14.8716477 L9.3483871,14.8716477 L9.60354839,14.8716477 C9.75774194,14.8716477 9.88258065,14.7397159 9.88258065,14.5767614 L9.88258065,14.1594886 L9.8833871,14.1594886 L9.8833871,1.10761364 L9.8833871,1.10761364 Z M1.72241935,13.1747727 L1.72241935,5.14056818 L4.58096774,5.14056818 C4.73516129,5.14056818 4.86,5.00863636 4.86,4.84568182 L4.86,1.82471591 L8.27741935,1.82471591 L8.27741935,13.1747727 L1.72241935,13.1747727 L1.72241935,13.1747727 Z"
    />
  </svg>
);
export default ReportsIcon;
