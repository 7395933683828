import { StyleSheet } from "@convertly/css";
import UserOverrides from '../style-functions/user-overrides';

const unorderedListStyles = ( global, data ) => {

    const userOverRides = new UserOverrides( global, data );

    let listStyle = {};

    if(data.listStyle === "dashed") {
            listStyle = {listStyleType:"none"}
    }

    return StyleSheet.create({

        unorderedList: {
            ...global.list,
            ...listStyle,
            ...userOverRides,
        }

    })

};

export default unorderedListStyles;