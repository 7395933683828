import React from 'react';
import Component from 'source/rootComponent';
import { css } from '@convertly/css';
import { id } from '@convertly/thor';
import _ from 'lodash';

// Utils

// Scripts

// Components
import {NavDropdownItem} from './NavDropdownItem';

// Styles
import navStylesDropdownMenu from '../styles/navStylesDropdownMenu';

export class NavDropdownMenu extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const inEditor = this.inEditor();
    const {
      global,
      data,
      selectionItemLinkStyles,
      navDropdownItems,
      toggleId
    } = this.props;

    this.styles = navStylesDropdownMenu(global, data, inEditor);

    const { mainNavDropdownMenu } = this.styles;

    return (
      <ul
        id='navDropdownMenu' 
        className={ css(mainNavDropdownMenu) }
        data-dropdown={ toggleId }
        data-category='dropdown'>
        {
          navDropdownItems &&
          navDropdownItems.map((navDropdownItemSettings, index) => {
            return (
              <NavDropdownItem
                global={ global }
                data={ data }
                navDropdownItemSettings = { navDropdownItemSettings }
                selectionItemLinkStyles={ selectionItemLinkStyles }
                key={ `navDropdownItem ${ index }` }/>
            )
          })
        }
      </ul>
    )
  }
}