export const CONSTANTS = {
  MODALS: {
    ADD_PAGE: "ADD_PAGE",
    IMAGE_GALLERY: "IMAGE_GALLERY",
    IMAGE_EDITOR: "IMAGE_EDITOR",
    PAGE_SETTINGS: "PAGE_SETTINGS",
    SITE_SETTINGS: "SITE_SETTINGS",
    PRODUCT_SEO: "PRODUCT_SEO",
    SAVE_MODULE: "SAVE_MODULE",
    SAVE_BANNER_TITLE: "SAVE_BANNER_TITLE",
  },
  mobile: "@media (max-width:475px)",
  smallTablet: "@media (max-width:767px)",
  largeTablet: "@media (max-width:991px)",
  desktop: "@media (max-width:1200px)",
};

export default CONSTANTS;
