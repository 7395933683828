import { StyleSheet } from '@convertly/css';
import userOverrides from '../../../../elements/style-functions/user-overrides';
import removeNestedUserOverrides from '../../utils/removeNestedUserOverrides';
import _ from 'lodash';

const logoStyles = ({ global, data, inEditor }) => {

  const logo = removeNestedUserOverrides({ data });

  const { textLogo, imageLogo } = _.get(data, 'userOverrides');
  
  const logoUserOverrides = !_.isEmpty(logo) ? userOverrides(global, { userOverrides: logo }, inEditor) : {};
  const textLogoUserOverrides = _.get(textLogo, 'userOverrides') ? userOverrides(global, textLogo, inEditor) : {};
  const imgLogoUserOverrides = _.get(imageLogo, 'userOverrides') ? userOverrides(global, imageLogo, inEditor) : {};

  return StyleSheet.create({
    logo: {
      ...logoUserOverrides
    },
    txtLogo: {
      fontFamily: global.fontFamily.primaryFont,
      ...textLogoUserOverrides
    },
    imgLogo: {
      ...imgLogoUserOverrides
    }
  })

}

export default logoStyles;