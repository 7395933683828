import { StyleSheet } from "@convertly/css";
import UserOverrides from "../../elements/style-functions/user-overrides";

export const SearchBarStyles = ({ global, data, inEditor }) => {
  const userOverrides = new UserOverrides(global, data, inEditor);

  return StyleSheet.create({
    searchBar: {
      display: "flex",
      height: "2em",
      "padding-left": "5px",
      ...userOverrides
    },
    searchBarInput: {
      border: "unset",
      width: "100%"
    },
    searchIcon: {
      height: "100%"
    }
  });
};
