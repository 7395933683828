import React from "react";
import styled, { keyframes } from "styled-components";
import loadingIcon from "./loadingIcon.svg";

const Icon = styled.img`
  opacity: 0.5;
  position: fixed;
  z-index: 2500;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const LoadingIcon = () => <Icon src={loadingIcon} />;

const spin = keyframes`
  from {
    transform: rotate( 0deg );
  }

  to {
    transform: rotate( 360deg );
  }
`;

const SVG = styled.svg`
  animation: ${spin} 1500ms linear infinite;
`;

export const LoadingIconNative = () => {
  return (
    <SVG
      width="200px"
      height="200px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      className="lds-rolling"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#349886"
        strokeWidth="10"
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138"
      />
    </SVG>
  );
};
