import {StyleSheet} from "@convertly/css";
import createAccontStyleObject from '../../ecommerce-common-components/create-account/create-account-styles'
import googleButtonStyleObject from '../../sign-in-up/google-button/google-button-style-object';
import facebookButtonStyleObject from '../../sign-in-up/facebook-button/facebook-button-style-object';
import signInUpStyleObject from '../../sign-in-up/sign-in-up-form/sign-in-up-form-styles'
import notificationBoxStyleObject from '../../ecommerce-common-components/notification-box/notification-box-styles';
import {generateBrowserBreakPoints} from '../../utils/browserSpecificStyles';
import staySignedInStyleObject from '../../sign-in-up/sign-in-up-form/stay-signed-in/stay-signed-in-styles';
import OrderStatusStyles from "../../ecommerce-common-components/OrderStatus/OrderStatusStyles";
import OrderNotificationsStyles from "../../ecommerce-common-components/OrderNotifications/OrderNotificationsStyles";
import EditNotificationsModalStyles
	from "../../ecommerce-common-components/EditNotificationsModal/EditNotificationsModalStyles";
import GenericModalStyles from "../../ecommerce-common-components/GenericModal/GenericModalStyles";

const Styles = (global, data) => {

	const createAccountStyles = createAccontStyleObject(global, data);
	const googleButtonStyles = googleButtonStyleObject(global, data);
	const facebookButtonStyles = facebookButtonStyleObject(global, data);
	const signInUpStyles = signInUpStyleObject(global, data);
	const notificationBoxStyles = notificationBoxStyleObject(global, data);
	const staySignedInStyles = staySignedInStyleObject(global, data);

	const orderStatusStyles = OrderStatusStyles(global, data);
	const orderNotificationsStyles = OrderNotificationsStyles(global, data);
	const editNotificationsStyles = EditNotificationsModalStyles(global, data);
	const genericModalStyles = GenericModalStyles(global, data)

	const breakpoints = generateBrowserBreakPoints();

	return StyleSheet.create({


		checkoutFlowWrapper: {
			minHeight: '100vh',
			fontFamily: global.fontFamily.primaryFont,
		},
		checkoutBody: {
			color: global.colors.dark.color2,
			display: "block",
			flex: "0 0 auto",
			fontSize: "14px",
			lineHeight: "20px",
			background: global.bodyBackgroundColor || global.colors.light.white
		},

		cartToggleContainer: {
			display: "none",
			fontSize: "14px",
			lineHeight: "20px"
		},

		checkoutFlowContainer: {
			display: "block",
			flexDirection: "row",
			fontSize: "14px",
			lineHeight: "20px",
			maxWidth: "90%",
			paddingTop: "0px",
			width: "100%",
			"@media(max-width:990px)": {
				maxWidth: '100%',
				width: '100%'
			}
		},

		checkoutFlowInfernoComponent: {},

		/** Cart Styles **/
		orderDetailReviewInfernoContainer: {
			...notificationBoxStyles,
			...staySignedInStyles,
			...orderStatusStyles,
			...orderNotificationsStyles,
			...editNotificationsStyles,
			...genericModalStyles,
			".checkoutCartReviewContainer": {
				height: 'auto',
				background: global.colors.light.white,
				boxShadow: ' 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3)',
			},
			".orderDetailColumn": {
				padding: '30px',
				height: '100%',
				flex: "1",
				fontSize: "14px",
				lineHeight: "20px",
				textAlign: 'center',
				margin: '50px 0'

			},
			".titleUnderline_od": {
				backgroundColor: global.colors.dark.color1,
				borderRadius: "5px",
				fontSize: "14px",
				height: "3px",
				lineHeight: "20px",
				marginBottom: "20px",
				width: "45%",
				margin: '0 auto 20px'
			},
			'.orderNumber': {
				fontSize: '16px',
				color: global.colors.light.color2,
				marginTop: "25px"
			},
			'.orderDate': {
				fontSize: '12px',
				color: global.colors.light.color2,
				marginBottom: '40px'
			},
			'.orderDetailsRow': {
				marginTop: '30px',
				width: '100%',
				textAlign: 'left'
			},
			'.itemColumn': {
				display: 'inline-block',
				width: '50%',
				marginRight: '5px',
				verticalAlign: '-webkit-baseline-middle'
			},
			'.quantityColumn': {
				display: 'inline-block',
				width: '20%',
				margin: '0 5px',
				textAlign: "center",
				verticalAlign: 'top'
			},
			'.subTotalColumn': {
				display: 'inline-block',
				width: '20%',
				margin: '0 5px',
				textAlign: 'right',
				float: 'right'
			},
			".chkGridprice_chkGridpricenote": {
				alignItems: "flex-start",
				color: "#118bef",
				display: "flex",
				flex: "0 auto",
				fontSize: "12px",
				justifyContent: "flex-start",
				lineHeight: "16px",
				paddingLeft: "0px",
				width: "auto",
				"@media(max-width:767px)": {
					marginLeft: '10px'
				}
			},
			".chkGridqty_chkUnitprice_strikethrough": {
				alignItems: "flex-start",
				color: "#667785",
				display: "flex",
				flex: "0 auto",
				fontSize: "14px",
				justifyContent: "flex-start",
				lineHeight: "20px",
				textAlign: "center",
				textDecoration: "line-through",
				"@media(max-width:767px)": {
					display: 'none'
				}

			},

			".actualprice_chkGridqty_chkUnitprice": {
				alignItems: "flex-start",
				color: "#118bef",
				display: "flex",
				flex: "1",
				fontSize: "14px",
				justifyContent: "flex-start",
				lineHeight: "20px",
				textAlign: "center",
				"@media(max-width:767px)": {
					display: 'none'
				}
			},
			'.orderDetailHeading': {
				fontFamily: global.fontFamily.secondaryFont,
				color: global.colors.dark.color1,
				fontWeight: '700',
				fontSize: '18px'
			},
			'.orderDetailItem': {
				margin: '8px 0'
			},
			".totalsSection": {
				textAlign: 'right',
				marginTop: '50px'

			},
			".chkLabel": {
				color: global.colors.dark.color1,
				fontFamily: global.fontFamily.secondaryFont,
				fontSize: "14px",
				lineHeight: "20px",
				marginBottom: "5px"
			},
			".chkSubtotal": {
				color: global.colors.dark.color1,
				fontSize: "45px",
				lineHeight: "45px"
			},
			".shippingInformation": {
				width: '100%',
				margin: '30px auto 0',
				height: 'fit-content',
				background: global.colors.light.color1,
				border: '2px solid',
				borderColor: global.colors.dark.color2,
				padding: '15px 0px'
			},
			".shippingInformationTitle": {
				fontFamily: global.fontFamily.primaryFont,
				textTransform: 'uppercase',
				color: global.colors.dark.color1,
				fontSize: '14px',
				fontWeight: 700,
			},
			".shippingAddress": {

				display: 'block',
				width: "100%",
				marginBottom: '15px'
			},
			'.shippingMethod': {
				display: 'inline-block',
				width: '40%',
				verticalAlign: 'top'
			},
			'.shippingAddressFullName': {
				textTransform: 'uppercase',
				fontSize: '12px',
				display: 'block',
				lineHeight: '18px'
			},
			".shippingAddressAddress": {
				fontSize: '12px',
				display: 'block'
			},
			/** Sign in styles **/

			".socialButton": {
				textAlign: 'center',
				width: '50%',
				display: 'inline-block',
				marginBottom: '35px',
				[breakpoints.edge]: {
					display: 'block',
					width: '100%'
				},
				[breakpoints.ie]: {
					display: 'block',
					width: '100%'
				},
				[breakpoints.mozilla]: {
					display: 'block',
					width: '100%'
				},

				"@media(max-width:450px)": {
					display: 'block',
					width: '100%'
				}
			},
			".nudgeLeft": {
				paddingLeft: '5px'
			},
			".nudgeRight": {
				paddingRight: '5px',
			},

			...googleButtonStyles,
			...facebookButtonStyles,
			...signInUpStyles,
			".sign-in-up-form": {
				marginTop: '30px'
			},

			".checkoutFlowColumn": {
				display: "block",
				flex: "1",
				flexDirection: "column",
				fontSize: "14px",
				lineHeight: "20px",
				paddingBottom: "0",
				paddingRight: "20px",
				paddingTop: "60px"
			},

			".chkbreadcrumbContainer": {
				display: "flex",
				fontSize: "14px",
				justifyContent: "flex-start",
				lineHeight: "20px"
			},

			".breadcrumbSeparator": {
				color: global.colors.dark.color3,
				fontSize: "14px",
				lineHeight: "20px",
				marginLeft: "15px",
				marginRight: "15px"
			},

			".chkBreadcrumb": {
				color: global.colors.dark.color3,
				display: "block",
				flex: "0 auto",
				fontSize: "12px",
				lineHeight: "20px",
				marginBottom: "10px",
				marginRight: "0px",
				textAlign: "left",
				textDecoration: "none",
				'.active': {
					color: global.colors.dark.color1,
				}
			},

			".chkTitle1": {
				borderBottom: "2px none",
				borderColor: global.colors.dark.color1,
				color: global.colors.dark.color1,
				display: "block",
				flex: "0 auto",
				flexDirection: "row",
				fontSize: "32px",
				lineHeight: "36px",
				marginBottom: "10px",
				marginTop: "0px",
				paddingBottom: "20px",
				width: "100%"
			},

			".titleUnderline": {
				backgroundColor: global.colors.dark.color1,
				borderRadius: "5px",
				fontSize: "14px",
				height: "3px",
				lineHeight: "20px",
				marginBottom: "40px",
				width: "45%"
			},

			".chkGuestlink_wInlineBlock": {
				backgroundColor: "transparent",
				color: global.colors.dark.color1,
				display: "flex",
				fontSize: "16px",
				lineHeight: "20px",
				maxWidth: "100%",
				paddingBottom: "20px",
				paddingTop: "15px",
				textDecoration: "underline"
			},

			".textBlock": {
				color: global.colors.dark.color3,
				fontSize: "13px",
				lineHeight: "20px",
				maxWidth: '80%'
			},

			".chkGuestarrow": {
				border: "0",
				color: global.colors.dark.color1,
				display: "inline-block",
				fontSize: "16px",
				lineHeight: "20px",
				marginLeft: "10px",
				marginTop: "4px",
				maxWidth: "100%",
				verticalAlign: "middle"
			},

			".orContainer": {
				alignItems: "center",
				display: "flex",
				fontSize: "14px",
				justifyContent: "center",
				lineHeight: "20px",
				width: "50%"
			},

			".orDivider": {
				backgroundColor: global.colors.dark.color3,
				flex: "1",
				fontSize: "14px",
				height: "3px",
				lineHeight: "20px"
			},

			".orSeparator": {
				color: global.colors.dark.color3,
				display: "flex",
				fontSize: "14px",
				lineHeight: "20px",
				padding: "5px 20px"
			},

			".chkTitle2": {
				color: global.colors.dark.color1,
				fontSize: "18px",
				lineHeight: "30px",
				marginBottom: "10px",
				marginTop: "20px"
			},

			".buttonContainer": {
				alignItems: "stretch",
				display: "block",
				flexDirection: "row",
				fontSize: "14px",
				justifyContent: "flex-start",
				lineHeight: "20px",
				marginBottom: "25px",
				maxWidth: "375px"
			},

			".row_wRow": {
				flex: "1",
				fontSize: "14px",
				lineHeight: "20px",
				marginLeft: "0",
				marginRight: "0"
			},

			".column_wClearfix_wCol_wCol_6_wColSmall_6_wColTiny_6": {
				display: "flex",
				float: "left",
				fontSize: "14px",
				left: "auto",
				lineHeight: "20px",
				minHeight: "1px",
				paddingLeft: "0px",
				paddingRight: "0px",
				position: "relative",
				right: "auto",
				width: "50%"
			},
			/** External Payment Buttons **/
			".apple-pay": {
				"-webkit-appearance": "-apple-pay-button",
				"-apple-pay-button-type": "black"
			},

			".btnLeft_placholderBtn_wButton": {
				backgroundColor: global.colors.dark.black,
				border: "0",
				borderRadius: "3px",
				clear: "right",
				color: global.colors.light.white,
				cursor: "pointer",
				display: "flex",
				flex: "1",
				flexDirection: "row",
				float: "left",
				fontSize: "14px",
				justifyContent: "center",
				lineHeight: "inherit",
				marginBottom: "15px",
				marginLeft: "0px",
				marginRight: "5px",
				padding: "9px 15px",
				paddingBottom: "10px",
				paddingTop: "10px",
				textDecoration: "none",
				width: "auto"
			},

			".btnRight_placholderBtn_wButton": {
				backgroundColor: global.colors.dark.black,
				border: "0",
				borderRadius: "3px",
				clear: "right",
				color: global.colors.light.white,
				cursor: "pointer",
				display: "flex",
				flex: "1",
				flexDirection: "row",
				float: "left",
				fontSize: "14px",
				justifyContent: "center",
				lineHeight: "inherit",
				marginBottom: "15px",
				marginLeft: "5px",
				marginRight: "0px",
				padding: "9px 15px",
				paddingBottom: "10px",
				paddingTop: "10px",
				textDecoration: "none",
				width: "auto"
			},

			".column_wClearfix_wCol_wCol_6_wColMedium_6_wColSmall_6_wColTiny_6": {
				display: "flex",
				float: "left",
				fontSize: "14px",
				left: "auto",
				lineHeight: "20px",
				minHeight: "1px",
				paddingLeft: "0px",
				paddingRight: "0px",
				position: "relative",
				right: "auto",
				width: "50%"
			},

			".formContainer_wForm": {
				fontSize: "14px",
				lineHeight: "20px",
				margin: "0 0 15px",
				maxWidth: "375px",
				paddingBottom: '10px',

			},
			...createAccountStyles,
			".account-container": {
				position: 'relative',
				maxWidth: '375px',
				height: '675px',
				overflow: 'hidden',


			},
			".create-account": {
				position: 'absolute',
				left: '-110%',
				transition: 'all 1s ease',
				maxWidth: '100%',
				".active": {
					left: 0
				}
			},
			".account-creation-success": {
				position: 'absolute',
				left: '110%',
				transition: 'all 1s ease',
				width: '375px',
				maxWidth: '100%',
				".active": {
					left: 0
				}
			},
			".message-container": {
				width: "100%",
				marginTop: "35px",
				padding: "20px",
				border: global.border,
				borderRadius: "3px",
				backgroundColor: global.colors.light.color2,
				fontFamily: global.fontFamily.secondaryFont
			},
			".account-creation-title": {
				fontWeight: '700',
				fontSize: '20px',
				paddingBottom: '5px'
			},
			".account-text": {
				paddingBottom: "15px",
			},
			".account-link": {
				paddingBottom: '0px',
				fontSize: "14px",
				lineHeight: "24px",
				fontFamily: global.fontFamily.primaryFont,
				textDecoration: "underline"
			},

			".btnContinue_wButton": {
				backgroundColor: global.colors.dark.color1,
				border: "0",
				borderRadius: "0",
				color: "white",
				cursor: "pointer",
				display: "block",
				fontSize: "14px",
				lineHeight: "inherit",
				margin: "10px 0 0",
				padding: "9px 15px",
				paddingBottom: "11px",
				paddingTop: "11px",
				textDecoration: "none",
				textTransform: "uppercase",
				width: "100%"
			},

			".wFormDone": {
				backgroundColor: global.colors.light.color3,
				display: "none",
				fontSize: "14px",
				lineHeight: "20px",
				padding: "20px",
				textAlign: "center"
			},

			".div_lLsU": {
				fontSize: "14px",
				lineHeight: "20px",
				textAlign: "center"
			},

			".wFormFail": {
				backgroundColor: global.colors.light.color1,
				display: "none",
				fontSize: "14px",
				lineHeight: "20px",
				marginTop: "10px",
				padding: "10px"
			},

			".div_xoTq": {
				fontSize: "14px",
				lineHeight: "20px"
			},
			".chkGuestlink_wInlineBlock": {
				backgroundColor: "transparent",
				color: global.colors.dark.color1,
				display: "flex",
				width: 'fit-content',
				fontSize: "16px",
				lineHeight: "20px",
				maxWidth: "100%",
				paddingBottom: "20px",
				paddingTop: "15px",
				transition: 'border .3s ease',
				textDecoration: 'underline',
				':hover ': {
					'.chkGuestarrow': {
						marginLeft: '15px'
					}
				},
			},
			".chkGuestarrow": {
				border: "0",
				color: global.colors.dark.color1,
				display: "inline-block",
				fontSize: "16px",
				height: '100%',
				width: '20px',
				lineHeight: "20px",
				marginLeft: "10px",
				maxWidth: "100%",
				transition: 'margin .3s ease',
			},
			".curbside-container": {
				padding:'0 20px'
			},
			".curbside-instructions": {
				textAlign:"left",
				"> p": {
					margin:5
				}
			}

		},

		toggleLinkblock_wInlineBlock: {
			backgroundColor: "transparent",
			display: "inline-block",
			fontSize: "14px",
			lineHeight: "20px",
			maxWidth: "100%"
		},

		toggleCollapsed: {
			border: "0",
			display: "inline-block",
			fontSize: "14px",
			lineHeight: "20px",
			maxWidth: "100%",
			verticalAlign: "middle"
		},
		socialIcon: {
			background: 'red'
		},
		chkGuestlink_wInlineBlock: {
			backgroundColor: "transparent",
			color: global.colors.dark.color1,
			display: "flex",
			width: 'fit-content',
			fontSize: "16px",
			lineHeight: "20px",
			maxWidth: "100%",
			paddingBottom: "20px",
			paddingTop: "15px",
			transition: 'border .3s ease',
			textDecoration: 'underline',
			':hover ': {
				'.chkGuestarrow': {
					marginLeft: '15px'
				}
			},
		},
		textBlock: {
			color: global.colors.dark.color1,
			fontSize: "16px",
			lineHeight: "20px"
		},
		chkGuestarrow: {
			".chkGuestarrow": {
				border: "0",
				color: global.colors.dark.color1,
				display: "inline-block",
				fontSize: "16px",
				height: '100%',
				width: '20px',
				lineHeight: "20px",
				marginLeft: "10px",
				maxWidth: "100%",
				transition: 'margin .3s ease',
			},
		}


	});

};

export default Styles;
