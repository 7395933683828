import React from "react";
import Component from "source/rootComponent";
import { css } from "@convertly/css";

// Components
import { VerticalDivider }from "../vertical-divider/VerticalDivider";

// Styles
import {SubtitleStyles} from "./SubtitleStyles";

// Utils
import formatDate from "../util/formatDate";
import _ from "lodash";
import { generateBlogLink } from '../util/generate-blog-link'

export class Subtitle extends Component {
  render() {
    let subtitleToUse;

    const { data, inEditor, page, global, blogData: originalBlogData } = this.props;

    if(_.isEmpty(data) && _.isEmpty(originalBlogData)) {
      return null;
    }

    let blogData;

    if (_.isArray(originalBlogData)) {
      blogData = originalBlogData[0];
    } else {
      blogData = originalBlogData;
    }

    let { subtitle, author, date, categories } = blogData || data || {};

    this.styles = SubtitleStyles({ global, data, inEditor });

    const { subtitleStyle, link } = this.styles;
    const { format, hideAuthor } = data || {};

    let dataText = _.get(data, "text");

    subtitleToUse = dataText
      ? dataText
      : this._determineSubtitleFormat(format, author, date, categories, subtitle, hideAuthor);
    const inlineStyle = this.generateStyleAttribute();

    return (
      <div
        {...inlineStyle}
        {...this.generateDataEditorAttribute()}
        {...this.generateCustomAttributes()}
        className={css(subtitleStyle)}
      >
        {subtitleToUse}
      </div>
    );
  }

  _determineSubtitleFormat(format, author, date, categories = [], subtitle, hideAuthor) {
    const lastCategoryIdx = categories ? categories.length - 1 : -1;
    const { name } = author || {};
    const showAuthor = name && !hideAuthor;
    const hasCategories = categories.length > 0;

    let usedString;
    let userOverridesForVD = _.get(this.props, "data.userOverrides.verticalDivider") || {};

    switch (parseInt(format)) {
      // Format 1: December 13, 2018 By Fisher House
      case 1:
        if (showAuthor && date) {
          return (
            <p>
              <span className={css(this.styles.date)}>{formatDate(date)}</span> by{" "}
              <span className={css(this.styles.name)}>{name}</span>
            </p>
          );
        } else if(!showAuthor && date) {
          return (
            <p>
              <span className={css(this.styles.date)}>{formatDate(date)}</span>
            </p>
          )
        } else {
          return "";
        }
        break;

      // Format 2: MARCH 25, 2015 | COW MAN | IN  ENTERTAINMENT, BUSINESS, TECHNOLOGY, FINANCE, AND LEADERSHIP
      case 2:
        const { inEditor } = this.props;
        return (
          <div>
            <span className={css(this.styles.date)}>{formatDate(date)}</span>
            {
              showAuthor && <VerticalDivider global={this.props.global} data={userOverridesForVD} />
            }
            {
              showAuthor && <span className={css(this.styles.name)}>{name}</span>
            }
            {
              hasCategories && <VerticalDivider global={this.props.global} data={userOverridesForVD} />
            }
            {
              hasCategories && "in "
            }

            {hasCategories && lastCategoryIdx >= 0 &&
              categories.map((category, idx) => {
                switch (idx) {
                  case lastCategoryIdx:
                    usedString = "";
                    break;
                  case lastCategoryIdx - 1:
                    if (lastCategoryIdx === 1) {
                      usedString = " and ";
                    } else {
                      usedString = ", and ";
                    }
                    break;
                  default:
                    usedString = ", ";
                }

                if (_.get(this, "props.blogData.clickToRedirect")) {
                  return (
                    <span className={css(this.styles.link)} key={`category-${idx}`}>
                      {category.name}
                      {usedString}
                    </span>
                  );
                } else {
                  return (
                    <a
                      className={css(this.styles.link)}
                      key={`category-${idx}`}
                      href={generateBlogLink(this.props.page.blogUrl, category.url, inEditor)}
                    >
                      {category.name}
                      {usedString}
                    </a>
                  );
                }
              })}
          </div>
        );
        break;
      case 3:
        if(date) {
          return (
            <div>
              <span className={css(this.styles.date)}>{formatDate(date)}</span>
              {
                showAuthor && <VerticalDivider global={this.props.global} data={userOverridesForVD} />
              }
              {
                showAuthor && <span className={css(this.styles.name)}>{name}</span>
              }
            </div>
          );
        } else {
          return null;
        }
        break;
      default:
        return subtitle ? subtitle : "";
        break;
    }
  }
}
