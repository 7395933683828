/*global Inferno, $, React */

import React from 'react';

const Script = (dispatch, clientUrl, ImageThumbNail, imageUrls) => {
	
	return (props) => {
		
		const {rootClass, updateFeatureImage, activeImgIndex, featureImage, imgArr} = props;
		
    let imageThumbnails = null;
    let productHasImages = false;

		if (imgArr.length >= 1) {
      productHasImages = true;
			imageThumbnails =
				<ul id="imageGalleryList" className={ `${rootClass} productboxList ` }>
					{imgArr.map((image, index) => {
						return (
							
							<ImageThumbNail rootClass={ rootClass }
															updateFeatureImage={ updateFeatureImage }
															product={ props.product }
															index={ index }
															activeImgIndex={ activeImgIndex }
															image={ image }/>
						);
					})
					}
				</ul>
    }

		return (
			
			<div className={ `${rootClass} product-image-container ` }>
				
				<div className={ `${rootClass} feature-image-container ` }>
          {
            productHasImages ?
            <img src={ `${imageUrls.featureImage}${ featureImage.src }` }
                 className={ `${rootClass} featureImage ` }/>
            :
            <img
              src={ `${imageUrls.globalImg}product-image-placeholder.jpg `}
              alt={ `No Product Image Placeholder` }
              className={ `${rootClass} featureImage` } />
          }
				
				</div>
        {
          imgArr.length > 1 &&
          <div className={ `${rootClass} thumbnails-container` }>
            { imageThumbnails }
          </div>
        }
			</div>
		)
	}
};

export default Script;
