import { StyleSheet } from "@convertly/css";

const landingPage = ( global, local ) => {

    const altState = {
        boxShadow: '0 0 5px 0 rgba(70,72,140,.88)'
    };

    return StyleSheet.create({
        landingPage: {
            position:'relative',
            margin: 0,
            minHeight:'100vmin',
            height:'100%',
            width: '100%',
            background: 'radial-gradient(circle, #3BA4DB 2.91%, #0076B3 100%)',
        },
        logoRow: {
            textAlign:'center',
            '@media( max-width: 987px )': {
            }
        },
        logoImg: {
            margin:'20px',
            height: '140px'
        },
        formContainer: {
            width:'80%',
            margin:'0 auto',
            maxWidth:'1100px',
            '@media( max-width: 987px )': {
                width:'400px',
                margin:'0 auto',
                textAlign: 'center',
                marginBottom:'50px'
            },
            '@media( max-width: 500px )': {
                width:'90%'
            }
        },
        landingPageTextContainer: {
            width:'45%',
            display:'inline-block',
            marginRight:'10%',
            '@media( max-width: 987px )': {
                width:'100%',
                display:'block',
                margin:'0 auto',
                textAlign: 'center'
            }
        },
        landingPageTitle: {
            fontSize:'1.4em',
            color:'#fff',
            '@media( max-width: 987px )': {
                width:'100%',
                display:'block',
                margin:'0 auto',
                fontSize:'1.2em'
            }

        },
        landingPageParagraph: {
            fontSize:'1.1em',
            lineHeight:'1.5em',
            fontWeight:300,
            color:'#fff',
            '@media( max-width: 987px )': {
                width:'100%',
                display:'none',
                margin:'0 auto'
            }
        },
		landingPageFormMessage: {
        	padding: '0',
			margin: '10px 0 0 0',
			fontSize: '1em',
			'.error' : {
        		color: '#ff0000'
			},
			'.success' : {
        		fontWeight: 'bold',
				color: 'green'
			}
		},
        landingPageFormContainer: {
            padding:'50px',
            width:'400px',
            background:'#fff',
            display:'inline-block',
            order:'2',
            textAlign:'center',
			position: 'relative',
            '@media( max-width:1126px )': {
                width: '350px'
            },
            '@media( max-width: 987px )': {
                width:'100%',
                display:'block',
                margin:'0 auto 50px auto'
            }
        },
		landingPageForm: {

		},
        landingPageInput: {
            display: 'block',
            margin: '15px auto',
            padding: '5px',
            paddingLeft: '10px',
            width: '90%',
            fontSize: '1em',
            backgroundColor: '#FFFFFF',
            boxShadow: '0 0 2px 0 rgba(70,72,140,.88)',
            border: '0.5px solid #E7E7E7',
            borderRadius: '32.5px',
            outline: 'none',
			transition: 'all 250ms ease',
			':active' : {
				...altState
			},
			':hover' : {
				...altState
			},
			':focus' : {
				...altState
			},
			'.disabled' : {
            	backgroundColor: 'rgba( 50,50,50,.25)'
			},
			'.success' : {
            	opacity: '0'
			}
        },
        landingPageButton: {
            alignSelf: 'left',
            padding: '5px 35px',
            background: 'linear-gradient(17.71deg, #0076B3 15%, #3BA4DB 64.52%)',
            border:'none',
            boxShadow: '0 0.5px 1px 0 rgba(0,0,0,0.2)',
            fontSize: '1.2em',
            outline: 'none',
            color: '#fff',
            borderRadius: '32.5px',
			':hover' : {
                ...altState
			},
			'.disabled' : {
            	opacity: '.5'
			},
			'.success' : {
				opacity: '0'
			}
        },
        landingPageParagraphMobile: {
            display:'none',
            fontSize:'1.1em',
            lineHeight:'1.5em',
            fontWeight:300,
            textAlign:'center',
            color:'#fff',
            '@media( max-width: 987px )': {
                width:'100%',
                display:'block',
                margin:'0 auto'
            }
        },
        footerText: {
            fontSize:'.8em',
            fontWeight:300,
            position:'absolute',
            width:'100%',
            textAlign:'center',
            bottom:'0',
            color:'#fff'
        },
    })
};

export default landingPage;
