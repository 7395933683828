import React from 'react';
import Component from 'source/rootComponent';
import { css } from "@convertly/css";
import { id } from '@convertly/thor';

//Default Props
//import defaultData from '';
const defaultData = {};
import defaultGlobal from 'source/themes/global';

//Styles
import Styles from './cart-page-styles';

//Components
import Elements from 'source/elements';

//Inferno Components
import TotalsSection from './components/totals-section';
import Cart from './components/cart';
import ShippingCalculator from './components/shipping-estimate';
import QuickBuyOptions from './components/quick-buy-options-container';
import TotalsSectionLink from './components/totals-section-link';

//Utils

import { clientImage } from 'source/utils/generateImage';
import { generateLink } from 'source/utils/generateLink';

//Ecommerce Setttings
import EcommerceSettings from '../ecommerce-settings';

const filename = 'ecommerce/cart/cart-page';

export default class CartPage extends Component {

    constructor( props ) {

        super( props );

    }

    render( ) {

        this.styles = Styles( this.props.global, this.props.data );

        if( this.props.editor ) {
            return this.editor( );
        }


        let clientImageSrc = clientImage('', { width: '70', height: '70', crop: 'fit'}, this.props.site.siteId );

        /** loads ecommerce settings */
        let ecommerceSettings = EcommerceSettings(this.site);


        let shippingEsitmator = null;
        if(ecommerceSettings.allowZipCodeShippingEstimate) {
            shippingEsitmator =
                    <div id={ id(ShippingCalculator, this.props.site.siteId, ecommerceSettings ) }
                         className={ css( this.styles.shippingEstimateInfernoComponent ) }/>
        }
        let quickBuyOptions = null;
        if(ecommerceSettings.quickBuy) {
            quickBuyOptions =

            <div id={ id( QuickBuyOptions ) } className={ css( this.styles.quickBuyInfernoContainer ) } />
        }

        const siteLink = generateLink( {url:'', linkType:"inferno"} );

        return(
            <div className={ css(this.styles.chkFlexMiddle) }>
                <div className={ css(this.styles.cartToggleContainer) }/>
                <Elements.Container>
                    <Elements.Row>
                        <div className={ css(this.styles.chkFlexMiddleContainer) }>
                            {/** Cart Section **/}
                            <Elements.Column
                                data={{
                                    smColumns: 8,
                                    smPush: 4,
                                    mdColumns: 6,
                                    mdPush: 6,
                                    xsColumns: 12,
                                    xsOffset: 0,
                                    xsPush: 0
                                }}>

                                <div id={ id( Cart, clientImageSrc, siteLink ) } className={ css(this.styles.cartInfernoContainer) }/>
                            </Elements.Column>

                            <Elements.Column
                                data={{
                                    xsColumns: 12,
                                    xsPull: 0,
                                    mdColumns: 6,
                                    mdPull: 6,
                                    smColumns: 4,
                                    smPull: 8,
                                }}>

                                <div className={ css(this.styles.flexMiddle_middleLeft) }>

                                    {/** Totals Section Inferno Component **/}
                                    <div id={ id( TotalsSection ) }
                                         className={ css(this.styles.totalsSectionInfernoContainer) }>
                                    </div>
                                    {/**Continue Shopping Link **/}
                                        <div id={ id( TotalsSectionLink, siteLink ) }
                                             className={ css( this.styles.textBlock ) }/>

                                </div>

                            </Elements.Column>
                        </div>
                    </Elements.Row>
                </Elements.Container>
            </div>
        )
    }
}


CartPage.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
