import React from 'react';
import Component from 'source/rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';
import _ from 'lodash';

import getEcommerceSettings from '../../ecommerce-settings';

//Default Props
//import defaultData from '';
const defaultData = {};
import defaultGlobal from 'source/themes/global';

//Styles
import Styles from './checkout-flow-styles';

//utils
import {clientImage} from '../../../utils/generateImage';
import LoadCart from 'source/utils/ecomm-utils/load-cart';

//Ecommerce Settings
import EcommerceSettings from '../../ecommerce-settings';

//Inferno Component
import CheckoutCartSectionInferno from './checkout-cart-review/checkout-cart-review-inferno';
import CheckoutFlowWrapperInferno from './checkout-stage-inferno/checkout-stage-wrapper';
import CheckoutMasterControl from './checkout-master-control';

//Components
import Elements from 'source/elements';
import CheckoutHeader from '../checkout-header/checkout-header-01/checkout-header-01';

//Utils
import {generateLink} from 'source/utils/generateLink'

export default class CheckoutStage01 extends Component {

	constructor ( props ) {

		super ( props );

		/**
		 * Ecommerce Settings
		 **/


	}

	render () {

		this.styles = Styles ( this.props.global, this.props.data );

		if ( this.props.editor ) {
			return this.editor ();
		}

		let clientImageSrc = clientImage ( '', { width: '70', height: '70', crop: 'fit'} );


		let ecommerceSettings = JSON.stringify ( EcommerceSettings ( this.props.site ) );

		let facebookClientId = _.get ( this.props.site, 'socialLogin.facebook.id' );
		let stripe = _.get ( this.props.site, 'stripe.stripe_publishable_key' ) || false;
		let stripeAccountId = _.get ( this.props.site, 'stripe.stripe_user_id' ) || false;
		let stripeModeLiveMode = _.get( this.props.site, 'stripe.livemode' ) || false;
		let paypalInstalled = !! _.get( this.props.site, 'paypal.client_id');

		if( process.env.STRIPE_MODE === 'test' ) {
			stripeModeLiveMode = false;
		}
		
		const siteLink = generateLink ( { linkType: "inferno" } );

		id( CheckoutMasterControl );

		return (
			<div className={css ( this.styles.checkoutFlowWrapper )}>
				{/** Checkout Header **/}
				<CheckoutHeader data={this.props.data} site={this.props.site}/>
				{/** Checkout Body **/}
				<div className={css ( this.styles.checkoutBody )}>
					{/** Controls Mobile View of cart **/}
					<div className={css ( this.styles.cartToggleContainer )}/>
					{/** Checkout Flow Wrapper **/}

					<Elements.Container data={ {
						userOverrides: {
							"@media (max-width:991px)": {
								width:'100%'
							}
                        }
					} }>
						<Elements.Row>

							<div className={css ( this.styles.checkoutFlowContainer )}>
								{/** Checkout Column **/}

								<Elements.Column
									data={{
										smColumns: 6,
										smOffset: 0,
										smPush: 6,
										sxsColumns: 12,
										xsOffset: 0,
										xsPush: 0
									}}>

									<div className={css ( this.styles.checkoutCartReviewContainer )}>

										<div id={id ( CheckoutCartSectionInferno, clientImageSrc, this.props.site.siteId, siteLink, ecommerceSettings )}
											 className={css ( this.styles.checkoutCartReviewInfernoContainer )}>
										</div>
									</div>
								</Elements.Column>
								<Elements.Column
									data={{
										xsColumns: 12,
										xsPull: 0,
										smColumns: 6,
										smPull: 6
									}}>

									<div id={id ( CheckoutFlowWrapperInferno, clientImageSrc, this.props.site.siteId, ecommerceSettings, facebookClientId, stripe, siteLink, paypalInstalled, stripeAccountId, stripeModeLiveMode )}
										 className={css ( this.styles.checkoutFlowInfernoComponent )}/>
									{/** Cart Review Column **/}

								</Elements.Column>
							</div>
						</Elements.Row>
					</Elements.Container>
				</div>

			</div>
		)
	}
}


CheckoutStage01.defaultProps = {
	data: defaultData,
	global: defaultGlobal
};
