const facebookButtonStyleObject = ( global, data ) => {

    return {
        ".facebookButton": {
            display: 'inline-block',
            verticalAlign: 'middle',
            background: '#3b5998',
            color: '#fff',
            padding:'8px 0',
            width:'100%',
            borderRadius: 2,
            border: '1px solid transparent',
            fontSize: 14,
            fontWeight: '500',
            fontFamily: "'Roboto', sans-serif",
            cursor: "pointer",
            transition:"all 400ms ease",
            "@media(min-width:991px)": {
                ":hover": {
                    boxShadow:"0 0 3px 3px rgba(59, 89, 152,.3)"
                }
            }

        },
        ".facebookIcon": {
            float:'left',
            height:'24px',
            width:'24px',
            marginLeft:'10px',

        },
        ".facebookLinkText": {
            verticalAlign:'sub'
        }
    }

};

export default facebookButtonStyleObject;