import React from 'react';
import Component from 'source/rootComponent';
import { css } from '@convertly/css';
import { id } from '@convertly/thor';
import _ from 'lodash';

// Utils
import { generateLink } from '../../../utils/generateLink';
import { clientImage } from '../../../utils/generateImage';

// Scripts
import cartHandler from '../../scripts/cart-script';
import isSignedIn from '../../scripts/is-signed-in';

// Components
import {NavLinkListItem} from './NavLinkListItem';
import {NavDropdownMenu} from './NavDropdownMenu';
import SearchBarWrapperHeader01 from '../../scripts/inferno-components/search-bar-wrapper/SearchBarWrapperHeader01';


// Styles
import navStylesLinkList from '../styles/navStylesLinkList';
import navStylesLinkListItem from '../styles/navStyleLinkListItem';

export class NavLinkList extends Component {
  
  constructor(props) {
    super(props);
  }

  render() {

    const { global, data, navLinks, site, page, inEditor } = this.props;
    const { siteId } = site;
    // const { slug } = page;

    const ecommEnabled = _.get(site, 'ecommerce.enabled') || false;

    this.navListItemStyles = navStylesLinkListItem(global, data, inEditor);
    this.navListStyles = navStylesLinkList(global, data, inEditor);

    const { menu, dropDownMenu, dropDownMenuWrapper, navListWrapper, searchBarWrapper } = this.navListStyles;
    const { selectionItem, selectionItemLink } = this.navListItemStyles;

    const clientImgSrc = clientImage("", {width: '60', height: '60', crop: 'fit'}, siteId);
    const clientLinkUrl = generateLink({linkType: "inferno", url: ""});
    const arrOfNavLinkListItems = navLinks.map((navLinkItem, index) => {
      if(navLinkItem.text) {
        return (
          <div
            key={ `navLinkList ${ index }` } 
            className={ css(navListWrapper) }>
            <NavLinkListItem
              global= { global }
              data={ data }
              styles={ this.navListItemStyles }
              navLinkItemSettings={ navLinkItem }
              key={ `navLinkListItem ${ index }`}
              page={ page }
              inEditor={ inEditor }/>
            <NavDropdownMenu
              key={ `navDropdownMenu ${ index }` }
              global={ global }
              data={ data }
              navDropdownItems={ navLinkItem.dropDownNav } 
              selectionItemLinkStyles={ selectionItemLink }
              inEditor={ inEditor }/> 
          </div>
        )
      }
    })

    if(!data.showNavigation) {
      return null;
    }
    
    return (
      <div>
        <div
          id='menuWrapper' 
          className={ css(menu) }>
          { arrOfNavLinkListItems }
        </div>
        <div className={ css(dropDownMenuWrapper) }>
          <div
            data-burgertoggle="true"
            className={ css(dropDownMenu) }>
            { 
              !_.get(this.props.page, 'hideSearchBar') &&
              _.get(this.props, 'site.ecommerce.enabled') &&
              <div id={ id(SearchBarWrapperHeader01, this.props.site.siteId)}
                  className={ `${ css(searchBarWrapper) } mobile` }/>
            }
            { arrOfNavLinkListItems }
            {
            // ecommEnabled &&
            // slug !== '/cart' &&
            <div className={ css(selectionItem) }>
              <div 
                style={ { paddingLeft: '51px' } }
                id={ id(isSignedIn, clientLinkUrl) }
                href={ generateLink({ linkType: 'internal', url: '/account' }) }>
                Sign in
              </div>
            </div>
            }
            
          </div>
        </div>
      </div>
    )
  }
}
