import { StyleSheet } from "@convertly/css";
import UserOverrides from '../style-functions/user-overrides';

const heading2Styles = ( global, data, headingStyle, inEditor ) => {


    let titleStyle = headingStyle ? headingStyle : "h2";

    const userOverRides = new UserOverrides( global, data, inEditor );


    return StyleSheet.create({
        heading2: {
            ...global[titleStyle],
            ...userOverRides,

        }
    })

};

export default heading2Styles;
