/**
 *  Takes array of css styles and selectors
 *  and evaluates if it should be its default or come from global
 * @param elements - array
 * @param cssRules - array
 */
export function assignStyleValues( elements, cssRules, global) {

    let newValues = global;

    elements.map( ( element ) => {

        cssRules.map( (rule) => {
            if(!newValues[element]) {
                newValues[element] = {};
            }
            if(element === 'textArea' || element === 'textArea') {
                if(!newValues[element]) {
                    newValues[element] = {};
                }
                if(!newValues[element]["::placeholder"]) {
                    newValues[element]["::placeholder"] = {};
                }
                newValues[element]["::placeholder"][rule.name] = newValues[element]["::placeholder"][rule.name] || rule.default;
            }
            else {
                newValues[element][rule.name] = newValues[element][rule.name] || rule.default;
            }

        })
    });

    return newValues
}