import React, { Component } from "react";
import { css } from "@convertly/css";
import styles from "./editor-forms-styles";

export class ContextMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      on: false
    };
  }

  openOptions = () => {
    this.setState({ on: true });
  };

  closeOptions = () => {
    this.setState({ on: false });
  };

  handleOptionSelection = index => {
    if (this.props.parentAction) {
      this.props.parentAction();
    }
    this.props.options[index].optionsCallback(this.props.itemIndex);
    this.closeOptions();
  };

  render() {
    const {
      options,
      itemIndex,
      top,
      bottom,
      left,
      right,
      button,
      onTop,
      optionStyles,
      optionIconStyle
    } = this.props;

    /**
     * this code determines relative position of the options popup, passed in through props.
     * you can specify a corner from which the position will the calculated.
     * e.g. top/left, bottom/right, etc.
     * you cannot specify top AND bottom or left AND right
     * - "button" prop renders the ellipses inside a button for usage in the header menu.
     */
    const optionsPosition = {};
    if (!bottom) {
      optionsPosition.top = top || "-15px";
    }
    if (button) {
      optionsPosition.top = top || "30px";
    }
    optionsPosition.bottom = bottom || null;
    if (!left) {
      optionsPosition.right = right || "-15px";
    }
    optionsPosition.left = left || null;

    return (
      <div
        style={optionStyles}
        className={css(
          onTop
            ? styles.optionsContainerOver
            : button
            ? styles.optionsContainerButton
            : styles.optionsContainer
        )}
      >
        <i
          style={optionIconStyle}
          className={
            "material-icons " + css(button ? styles.optionsEllipsesButton : styles.optionsEllipses)
          }
          onClick={this.openOptions}
        >
          more_horiz
        </i>
        {this.state.on && (
          <div>
            <ul ref="container" className={css(styles.optionsList)} style={optionsPosition}>
              {options.map((option, index) => {
                let liStyle;

                if (index === options.length - 1) liStyle = css(styles.optionsBottomItem);
                else if (!index) liStyle = css(styles.optionsTopItem);
                else liStyle = css(styles.optionsItem);

                const { isLink, href } = option;

                if (isLink) {
                  return (
                    <li
                      style={{
                        padding: "0"
                      }}
                      onClick={this.handleOptionSelection.bind(this, index)}
                      className={liStyle}
                      key={`options${index}`}
                    >
                      <a
                        style={{
                          display: "block",
                          padding: "10px"
                        }}
                        href={href}
                        target={"_blank"}
                      >
                        {option.optionsItemTitle}
                      </a>
                    </li>
                  );
                }

                return (
                  <li
                    ref={"item" + index}
                    className={liStyle}
                    key={`options${index}`}
                    onClick={this.handleOptionSelection.bind(this, index)}
                  >
                    {option.optionsItemTitle}
                  </li>
                );
              })}
            </ul>
            <OptionsUnderlay onClick={this.closeOptions} />
          </div>
        )}
      </div>
    );
  }
}

export const OptionsUnderlay = props => (
  <div className={css(styles.optionsListUnderlay)} {...props} />
);

export const ContextMenuList = ({ optionsPosition, options }) => (
  <div>
    <ul className={css(styles.optionsList)} style={optionsPosition}>
      {options.map((option, index) => {
        let liStyle;
        if (index === options.length - 1) liStyle = css(styles.optionsBottomItem);
        else if (!index) liStyle = css(styles.optionsTopItem);
        else liStyle = css(styles.optionsItem);

        console.log(option, 'what you gunning for')

        return (
          <li className={liStyle} key={`options${index}`} onClick={() => option.optionsCallback()}>
            {option.optionsItemTitle}
          </li>
        );
      })}
    </ul>
  </div>
);

ContextMenuList.defaultProps = {
  optionsPosition: {},
  options: []
};

export default ContextMenu;
