import { StyleSheet } from "@convertly/css";
import UserOverrides from '../style-functions/user-overrides';


const paragraphStyles = ( global, data, inEditor ) => {

  const userOverrides = new UserOverrides(global, data, inEditor);

	return StyleSheet.create({
		paragraph: {
            ...global.p,
			...userOverrides
		}
	});

};

export default paragraphStyles;
