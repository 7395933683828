import React, {Component} from 'react';

/**Functions */
//index level functions
import {
  toggleForm,
  getOS,
  sendMessage,
  resetState,
} from './functions'

//Controls Functions
import {
  generateControlsProps,
  nextStage,
  prevStage
} from './Controls/functions';

//FeedbackCategory functions
import {setCategory} from "./FeedbackCategory/functions";

//MessageStage functions
import {updateMessage} from "./MessageStage/functions"

/**Components*/
import {
  HelpWrapper,
  FeedbackWrapper,
  LauncherIcon,
  FeedbackContentWrapper
} from './root-components'
import {FeedbackCategory} from './FeedbackCategory/FeedbackCategory';
import {MessageStage} from './MessageStage/MessageStage';
import {Controls} from "./Controls/Controls";
import {Confirmation} from './Confirmation/Confirmation';
import {FeedbackHeader} from './FeedbackHeader/FeedbackHeader';

export class FeedbackWidget extends Component {

  constructor(props) {
    super(props);

    /** Bind Functions **/
    this.setCategory = setCategory.bind(this);
    this.toggleForm = toggleForm.bind(this);
    this.generateControlsProps = generateControlsProps.bind(this);
    this.getOS = getOS.bind(this);
    this.nextStage = nextStage.bind(this);
    this.updateMessage = updateMessage.bind(this);
    this.sendMessage = sendMessage.bind(this);
    this.resetState = resetState.bind(this);
    this.prevStage = prevStage.bind(this);
  }

  state = {
    active: false, //toggles displaying form
    stage: 1, //updates stage of form
    message: "", //Message form client
    activeCategory: "", //Enum Type of feedback
    activeCategoryText: "", //English version of category
    categories: [ //Arr of feed back categories
      {text: "My Web Site Editor", type: "EDITOR"},
      {text: "My Convertly Dashboard", type: "DASHBOARD"},
      {text: "My Facebook / Google Ad Product", type: "ADTECH"},
      {text: "Something else completely", type: "OTHER"}
    ],
    osData: {},//OS data to be sent up with data
    timeStamp: new Date().getTime(), //initial time defaults to start of session but gets updated every time the icon opens
    processing: false, //state set to true when sending data to graph
  };

  componentDidMount() {
    this.getOS();
  }

  render() {

    const {active, activeCategoryText, categories, stage, message, processing} = this.state;
    const {firstName, email} = this.props.user;

    return (
      <HelpWrapper>
        <LauncherIcon onClick={this.toggleForm}>
          <svg width="16" height="30" viewBox="0 0 16 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.2 20.1873C5.05185 19.1499 5.08148 18.1988 5.28889 17.3343C5.52593 16.4697 5.85185 15.6772 6.26667 14.9568C6.71111 14.2363 7.2 13.5735 7.73333 12.9683C8.2963 12.3343 8.81482 11.7291 9.28889 11.1527C9.79259 10.5476 10.2074 9.95677 10.5333 9.3804C10.8593 8.80404 11.0222 8.18444 11.0222 7.52161C11.0222 6.48415 10.6963 5.64842 10.0444 5.01441C9.42222 4.3804 8.54815 4.0634 7.42222 4.0634C6.5037 4.0634 5.67407 4.26513 4.93333 4.66859C4.19259 5.07205 3.4963 5.6196 2.84444 6.31124L0 3.76081C0.977778 2.66571 2.13333 1.77234 3.46667 1.08069C4.82963 0.360231 6.35556 0 8.04445 0C9.2 0 10.2667 0.158502 11.2444 0.475506C12.2222 0.76369 13.0519 1.21038 13.7333 1.81556C14.4444 2.42075 14.9926 3.17003 15.3778 4.0634C15.7926 4.95677 16 5.99424 16 7.17579C16 8.09798 15.8222 8.93372 15.4667 9.683C15.1407 10.4035 14.7259 11.0951 14.2222 11.7579C13.7185 12.4207 13.1704 13.0548 12.5778 13.6599C12.0148 14.2651 11.4963 14.8991 11.0222 15.562C10.5778 16.2248 10.2074 16.9308 9.91111 17.6801C9.64444 18.4294 9.57037 19.2651 9.68889 20.1873H5.2ZM7.46667 30C6.51852 30 5.73333 29.683 5.11111 29.049C4.48889 28.415 4.17778 27.6081 4.17778 26.6282C4.17778 25.6484 4.48889 24.8415 5.11111 24.2075C5.73333 23.5735 6.51852 23.2565 7.46667 23.2565C8.41481 23.2565 9.2 23.5735 9.82222 24.2075C10.4741 24.8415 10.8 25.6484 10.8 26.6282C10.8 27.6081 10.4741 28.415 9.82222 29.049C9.2 29.683 8.41481 30 7.46667 30Z"
              fill="white"/>
          </svg>
        </LauncherIcon>
        {/**form gets toggled on click of wrapper icon*/}
        <FeedbackWrapper active={active}>
          <FeedbackHeader prevStage={this.prevStage} stage={stage} toggleForm={this.toggleForm}/>
          <FeedbackContentWrapper>
            { /** FEEDBACK SELECTOR **/
              stage === 1 &&
              <FeedbackCategory {...{activeCategoryText, categories}} name={firstName} setCategory={this.setCategory}/>
            }
            {/** Message **/
              stage === 2 &&
              <MessageStage activeCategoryText={activeCategoryText} updateMessage={this.updateMessage}
                            message={message}/>
            }
            {
              stage === 3 &&
              <Confirmation name={firstName} email={email}/>
            }

          </FeedbackContentWrapper>
          <Controls {...this.generateControlsProps()} toggleForm={this.toggleForm} processing={processing}/>
        </FeedbackWrapper>
      </HelpWrapper>
    )

  }


}
