import React from 'react';
import Component from '../../../../rootComponent';
import { css } from "@convertly/css";
import Types from '../../../../attribute-types';
import { id } from '@convertly/thor';
import { clientImage } from '../../../../utils/generateImage';


//Default Props
import defaultData from '../header-nav-default-data';
import defaultGlobal from '../../../../themes/global';

//Components
import HeaderNavLink2 from '../header-nav-link/header-nav-link-2';

//Scripts
import cartHandler from '../../scripts/cart-script';
import isSignedIn from '../../scripts/is-signed-in';


export default class
HeaderNavList2 extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        let featureImg = null;
        if( this.props.data.featureImage ) {

            featureImg = <img src={ clientImage( this.props.data.featureImage.src ) }
                 className={ css( this.props.styles.featuredImage ) }
                 alt="" />
        }

        return(
                    <div id="menu_bar" className="header-nav-list-2" >
                    <span>
                        { featureImg }
                    </span>
                        <ul className={ css( this.props.styles.navList ) }>
                        {  this.props.data.navLinks.map( ( navItem, index ) => {

                            return (
                                <HeaderNavLink2 global={ this.props.global }
                                                key={ "headerNavLink" + index }
                                                id={index}
                                                page={ this.props.page }
                                                data={ navItem }
                                                styles={ this.props.styles } />
                            )})}
                        </ul>

                    <div className={ css( this.props.styles.headerBottomLinks ) }>
                        {/*Hiding until search is set up
                        <div className={ css( this.props.styles.navListItem ) }>
                            <form id="searchform1" className={ css( this.props.styles.headerSearchForm ) }>
                                <span className={ css( this.props.styles.searchIcon ) } >Search</span>
                                <input type="text"
                                       name="search"
                                       className={ css( this.props.styles.navSearchBar ) }
                                       placeholder="Search" />
                                <input type="submit"
                                       id="searchsubmit"
                                       className={ css( this.props.styles.navSearchButton ) }
                                />
                            </form>
                        </div>
                         */}
                        <div className={ css( this.props.styles.navListItem ) }>
                            <a className={ css( this.props.styles.navListItemLink ) }
                               id={ id( cartHandler, this.props.clientLinkUrl, this.props.clientImgSrc, true ) }
                               href="/cart">Cart</a>
                        </div>
                        <div className={ css( this.props.styles.navListItem )}>
                            <a href="/account"
                               id={ id( isSignedIn, this.props.clientLinkUrl ) }
                               className={ css( this.props.styles.navListItemLink ) }>Sign In</a>
                        </div>
                        <div className="clearfix"/>
                    </div>

                </div>
        )
    }
}

HeaderNavList2.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
