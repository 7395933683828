import { fromJS } from "immutable";
import { store } from "app/app";

import {
  getUnclaimedChatsFromStore,
  setUnclaimedChatThreads,
  setClaimedChatThreads,
  setUnreadClaimedChats,
  setUnreadUnclaimedChats,
  setActiveChat
} from "./chatServiceActions";

export const getService = () => store.getState().get("chatService");
export const getUser = () =>
  store
    .getState()
    .get("auth")
    .get("user");

export function message_from_zork({ b2c, chatId, text }) {
  return async dispatch => {
    const activeChat = getService().get("activeChat");
    if (activeChat.get("id") === chatId) {
      const message = {
        fromClient: true,
        message: text,
        timestamp: new Date().getTime()
      };
      const messages = activeChat.get("messages").push(fromJS(message));
      await dispatch(setActiveChat(activeChat.set("messages", messages)));
    }
    const claimedChats = getService().get("claimedChatThreads");
  };
}

const addMessageToActiveChat = async (thread, text) => {
  const message = {
    fromClient: true,
    message: text,
    timestamp: new Date().getTime()
  };
};

export function thread_counts({ unreadClaimed, unreadUnclaimed }) {
  return async dispatch => {
    await dispatch(setUnreadUnclaimedChats(unreadUnclaimed));
    await dispatch(setUnreadClaimedChats(unreadClaimed));
  };
}

export function chat_thread_claimed({ chatId, zork, b2c, lastMessage, id }) {
  return async dispatch => {
    try {
      const service = getService();
      const user = getUser();

      let unclaimedChats;
      let chatClaimed;
      let claimedChats;
      let formattedChat;

      if (user.get("id") !== zork.id) {
        // chat was claimed by another user
        unclaimedChats = service.get("unclaimedChatThreads").filter(c => c.get("id") !== chatId);
        await dispatch(setUnclaimedChatThreads(unclaimedChats));
        return;
      }

      if (!lastMessage) {
        return;
      }

      unclaimedChats = service.get("unclaimedChatThreads").filter(c => {
        const isSame = c.get("id") === id;
        if (isSame) {
          chatClaimed = c.toJS();
        }
        return !isSame;
      });

      await dispatch(setUnclaimedChatThreads(unclaimedChats));

      chatClaimed.chatId = id;

      claimedChats = service.get("claimedChatThreads").push(fromJS(chatClaimed));

      // chat was claimed by this user

      await dispatch(setClaimedChatThreads(claimedChats));
    } catch (e) {
      console.log(e.message);
    }
  };
}

export function chat_thread_ended({ chatId }) {
  return async dispatch => {
    const service = getService();

    const claimedChats = service.get("claimedChatThreads");
    const filteredClaimedChats = claimedChats.filter(c => c.get("id") !== chatId);

    if (filteredClaimedChats.size !== claimedChats.size) {
      await dispatch(setClaimedChatThreads(filteredClaimedChats));
      return;
    }

    const unclaimedChats = service.get("unclaimedChatThreads");
    const filteredUnclaimedChats = unclaimedChats.filter(c => c.get("id") !== chatId);

    if (filteredUnclaimedChats.size !== unclaimedChats.size) {
      await dispatch(setUnclaimedChatThreads(filteredUnclaimedChats));
    }

    if (service.get("activeChat").get("id") === chatId) {
      const activeChat = service.get("activeChat").set("ended", true);
    }
  };
}

export function message_from_b2c({ active, b2c, chatId, messageId, text }) {
  return async dispatch => {
    if (!active) {
      await dispatch(processUnclaimedChatMessage({ active, b2c, chatId, messageId, text }));
    }
  };
}

export function processUnclaimedChatMessage({ active, b2c, chatId, messageId, text }) {
  return async dispatch => {
    let addedToExistingThread = false;

    const unclaimedChats = getUnclaimedChatsFromStore().map(thread => {
      if (thread.get("id") === chatId) {
        const messages = thread.get("messages").push(
          fromJS({
            fromClient: false,
            message: text,
            timestamp: new Date().getTime()
          })
        );

        addedToExistingThread = true;
        return thread.set("messages", messages);
      }
      return thread;
    });

    if (addedToExistingThread) {
      await dispatch(setUnclaimedChatThreads(unclaimedChats));
    } else {
      const chatThread = fromJS({
        b2cUser: b2c,
        id: chatId,
        active,
        messages: [
          {
            message: text,
            timestamp: new Date().getTime()
          }
        ]
      });

      const updatedThreads = unclaimedChats.push(chatThread);

      await dispatch(setUnclaimedChatThreads(updatedThreads));
    }
  };
}
