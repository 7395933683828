import pageJumpStyleObject from './components/page-jump/pageJumpStyleObject';
import productsPerPageStyleObject from './components/products-per-page/productsPerPageStyleObject';
import sortByDropdownStyleObject from './components/sort-by/sortByDropdownStyleObject';
import { generatePseudoMediaQuery } from '../../elements/style-functions/pseudo-media-queries';

export default function StyleObject(global, data, inEditor) {
	

  const pageJumpStyles = pageJumpStyleObject(global, data, inEditor);
  const productsPerPageStyles = productsPerPageStyleObject(global, data, inEditor);
  const sortByDropdownStyles = sortByDropdownStyleObject(global, data, inEditor);

  return {
    ...pageJumpStyles,
    ...productsPerPageStyles,
    ...sortByDropdownStyles,
    '.pagination-container': {
      display: 'block',
      height: '43px',
      marginBottom: '20px',
      width: 'calc(100% + 14px)',
      transform: 'translateX(-7.5px)',
      zIndex: '1',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)',
        ['smallTablet', 'mobile'],
        {
          height: '36px',
          marginBottom: '20px'
        },
        inEditor
      ),
    },
    
    '.mobile-refine-sort-by-wrapper': {
      display: 'none',
      height: '100%',
      width: '100%',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)',
        ['smallTablet', 'mobile'],
        {
          display: 'block'
        },
        inEditor
      ),
    },

    '.pagination-wrapper': {
      display: 'block',
      width: '100%',
      height: '100%',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)',
        ['smallTablet', 'mobile'],
        {
          display: 'none'
        },
        inEditor
      ),
    },

    '.refine-placeholder-container': {
      border: `1px solid ${ global.colors.light.color2 }`,
      width: '50%',
      height: '100%',
      display: 'inline-block',
      cursor: 'pointer',
      ...generatePseudoMediaQuery(
        '@media(max-width: 991px)',
        ['largeTablet', 'smallTablet', 'mobile'],
        {
          width: '75px',
          border: `1px solid ${ global.colors.dark.color2 }`,
        },
        inEditor
      ),
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)',
        ['smallTablet', 'mobile'],
        {
          height: '36px',
          width: '50%',
          border: `1px solid ${ global.colors.light.color3 }`,
        },
        inEditor
      ),
    },
    
    '.refine-placeholder-button': {
      width: '100%',
      height: '100%',
      display: 'table',
    },

    '.refine-placeholder-text': {
      display: 'table-cell',
      textAlign: 'center',
      verticalAlign: 'middle',
      '.mobile': {
        textTransform: 'uppercase',
        fontSize: '13px',
        fontWeight: '500',
        color: global.colors.dark.color3,
        paddingTop: '4px',
      }
    },

    '.number-of-user-filters-container': {
      display: 'inline-block',
      fontSize: '13px',
      fontWeight: '500',
      color: global.colors.dark.color3,
      marginLeft: '3px',
    },

    '.number-of-user-filters': {
      height: '17px',
      width: '17px',
      border: '1px solid black',
      borderRadius: '9px'
    }

  }
}
