import React from 'react';
import Component from '../../rootComponent';
import {css} from "@convertly/css";
import ReactHTMLParser from "@convertly/react-html-parser";
import {id} from '@convertly/thor';
//Default Props
import defaultData from './newsletter-signup-default-data';
import defaultGlobal from '../../themes/global';


//Styles
import newsletterSignUpStyles from './newsletter-signup-styles-4';

//Attributes
import Types from '../../attribute-types';

//Scripts
import Scripts from './newsletter-signup-4-scripts';

//Components
import Elements from '../../elements/index';

export default class NewsLetterSignUp4 extends Component {

    constructor ( props ) {

        super ( props );

        this.onSubmit = this.onSubmit.bind ( this );
    }

    onSubmit ( e ) {
        e.preventDefault ();
    }

    render () {

        const { site, data, global, buttonType } = this.props;

        this.styles = newsletterSignUpStyles ( global, data, buttonType );

        const events = data.gaEvent || [];
        const listId = data.listId || 0;

        let siteId = '';

        if( site ) {
            siteId = site.siteId;
        }

		let newsletterThankYouText = data.newsletterThankYouText ? data.newsletterThankYouText : 'Thanks for signing up!'

        const rootId = id ( Scripts, siteId, JSON.stringify( events ), listId, newsletterThankYouText );

        return (
            <div id={ rootId } className={ css( this.styles.newsletterWrapper ) }
                 { ...this.generateCustomAttributes() }
            >
                <form id={ `${rootId}-form` } onSubmit={ this.onSubmit } className={ css ( this.styles.newsletterSignupForm ) }>
                    <input id={ `${rootId}-name` }
                           className={ css ( this.styles.nameInput ) }
                           type="name" placeholder={ this.props.data.namePlaceholder.text }/>
                    <input id={ `${rootId}-email` }
                           className={ css ( this.styles.emailInput ) }
                           type="email" placeholder={ this.props.data.emailPlaceholder.text }/>
                    <input id={ `${rootId}-submit` }
                           className={ css ( this.styles.newsletterSignupFormSubmit ) }
                           type="submit"
                           value={ this.props.data.submitButton.text }/>
                </form>
            </div>
        )
    }
}
NewsLetterSignUp4.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};


