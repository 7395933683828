import HoursOfOperation1 from './hours-of-operation/hours-of-operation-01/hours-of-operation-01';
import HoursOfOperation2 from './hours-of-operation/hours-of-operation-02/hours-of-operation-02';
import HoursOfOperation3 from './hours-of-operation/hours-of-operation-03/hours-of-operation-03';
import HoursOfOperation4 from './hours-of-operation/hours-of-operation-04/hours-of-operation-04';
import SocialSharing01 from './social-icons/no-background-social-icons/social-icon-list-1';
import SocialSharing02 from './social-icons/social-icons-02/social-icon-list-02';
import LocationMapOverlay from './google-maps-widgets/location-map-overlay/location-map-overlay';
import MultipleLocationsMapOverlay from './google-maps-widgets/multiple-locations-map-overlay/multiple-locations-map-overlay';
import MapAddressOnSide from './google-maps-widgets/map-address-on-side/map-address-on-side.js'
import Accordion01 from './accordion/accordion-01/accordion-01';
import ModalLauncher01 from './modal-launcher/modal-launcher-01/modal-launcher-01';
import ModalLauncher02 from './modal-launcher/modal-launcher-02/ModalLauncher02';
import Newsletter02 from './newsletter-signup/newsletter-signup-2';
import Newsletter03 from './newsletter-signup/newsletter-signup-3';
import Newsletter04 from './newsletter-signup/newsletter-signup-4';
import RestaurantMenu01 from './restaurant-menu/restaurant-menu-01/restaurant-menu-01';
import CustomerReview from './customer-review/customer-review';
import ConvertlySocialIcons from './social-icons/convertly-social-icons/social-icon-list';
import SearchBar from '../ecommerce-common-components/search-bar/SearchBar';



const widgets = {
    HoursOfOperation1: HoursOfOperation1,
    SocialSharing01: SocialSharing01,
    SocialSharing02: SocialSharing02,
    LocationMapOverlay: LocationMapOverlay,
    HoursOfOperation2: HoursOfOperation2,
    HoursOfOperation3: HoursOfOperation3,
    HoursOfOperation4: HoursOfOperation4,
    Accordion01: Accordion01,
    ModalLauncher01: ModalLauncher01,
    Newsletter02: Newsletter02,
    Newsletter03: Newsletter03,
    Newsletter04: Newsletter04,
    RestaurantMenu01: RestaurantMenu01,
    MapAddressOnSide: MapAddressOnSide,
    CustomerReview: CustomerReview,
    ConvertlySocialIcons: ConvertlySocialIcons,
    MultipleLocationsMapOverlay: MultipleLocationsMapOverlay,
    SearchBar: SearchBar,
    ModalLauncher02: ModalLauncher02,

};

export default widgets;
