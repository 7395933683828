/*global Inferno, $, React */
import React from "react";

const ContestSubmissionsGenerator = () => {


    const ContestSubmissions = ({submissions, clickHandler, imgPath, activeTabId, setActiveTab, tabs}) => {

        return (
            <div>
                {generateTabs()}
                <div className={"contest-submissions-grid contest-submissions-grid-column-handler"}>
                    {
                        submissions.map(function renderSubmissions(submission) {
                            //if we have a tab we ignore all that don't have that id
                            if (activeTabId && activeTabId !== submission.tabId) return null;
                            //return the submission
                            return (
                                <div onClick={() => clickHandler(submission)} className={"submissions-grid-item"}>
                                    <img
                                        className={"submissions-grid-image"}
                                        alt={submission.imageName}
                                        src={generateImagePath(submission.imageUrl)}/>
                                    <p className={"contest-image-name"}>{submission.imageName}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )

        function generateImagePath(imgUrl) {
            if (imgUrl.includes('http')) {
                return imgUrl
            }
            return imgPath + imgUrl

        }

        function generateTabs() {
            if (!tabs || tabs.length === 0) return null;

            return (
                <div className={"tabs-wrapper"}>
                    {tabs.map(function renderTab(tab) {

                        return (
                            <div
                                onClick={() => setActiveTab(tab.id)}
                                className={`contest-tab ${tab.id === activeTabId ? "active" : ""}`}
                            >{tab.name}</div>
                        )

                    })}
                </div>
            )
        }

    }
    return ContestSubmissions


}

export default ContestSubmissionsGenerator



