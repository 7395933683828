import {StyleSheet} from '@convertly/css';
import UserOverrides from '../style-functions/user-overrides';

const Styles = (global, data, inEditor) => {
	
	let userOverrides = UserOverrides(global, data, inEditor);
	
	return StyleSheet.create({
		svgCodeWrapper: {
			
				...userOverrides
			
		}
	})
	
};

export default Styles;