import PropTypes from "prop-types";
import React, { Component } from "react";
import { css } from "@convertly/css";
import _ from "lodash";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import styled from "styled-components";
import TabStyled from "../../Editor/components/editor/css-Tab-Styles";
import styles from "../../Editor/components/editor/editor-forms-styles";
import FileUpload from "../../Editor/components/editor/file-upload";
import ModalWrapper from "./ModalWrapper";
import GalleryImage from "./GalleryImage";
import { keyframes } from "styled-components";
import { clientImage } from "../../Editor/components/pegasus-components/src/utils/generateImage";
import { setSite } from "@convertly/utils";
import findElementObject from "../../Editor/findElementObject";
import findObjectBeingEdited from "../../Editor/findObjectBeingEdited";
import {
  setElements,
  setOverlayStyles,
  closeModal,
  saveModule,
  setModules,
} from "../../Editor/actions/editorActions";

const closeAnimation = keyframes`
    from {
      opacity: 1;
      transform: translate(-50%, 0%) scale(1);
    }

    to {
      opacity: 0;
      transform: translate(-50%, 0%) scale(0.95);
    }
  `;

let objectBeingEdited;
export default class SaveModuleModal extends Component {
  /**
   * Class constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.pageElements = this.props.pageElements.toJS();
    this.objectBeingEdited = findObjectBeingEdited(
      this.pageElements,
      this.props.editorType.toJS().field,
      this.props.activeModuleId
    );

    this.state = {
      customModuleName: "",
      transition: {},
    };

    this.saveDesign = this.saveDesign.bind(this);
    this.cancelDesign = this.cancelDesign.bind(this);
    this.handleWheel = this.handleWheel.bind(this);
    this.changeName = this.changeName.bind(this);
  }

  componentWillUnmount() {
    this.context.store.dispatch(setModules(""));
  }

  /**
   * Cancel button handler
   */
  closeModal() {
    this.context.store.dispatch(setOverlayStyles(null));
  }

  /**
   * Iterates through pageElements and finds the active Component
   * Sets the field value to the image src
   *
   * Saves pageElements
   * Closes modal
   *
   * @param url
   */
  saveDesign() {
    const payload = {
      module: _.find(pageElements, ["id", this.props.activeModuleId]),
      moduleName: this.state.customModuleName,
    };
    this.context.store.dispatch(saveModule(payload));
    this.setState({ transition: { animation: `${closeAnimation} 250ms` } });
    setTimeout(() => {
      this.context.store.dispatch(setOverlayStyles(null));
      this.context.store.dispatch(closeModal());
    }, 250);
  }

  /**
   * Event handler for selecting an image
   *
   * @param index
   */

  /**
   * Cancel Button event handler
   *
   * @param url
   */
  cancelDesign(url) {
    this.setState({ transition: { animation: `${closeAnimation} 250ms` } });
    setTimeout(() => {
      this.context.store.dispatch(setOverlayStyles(null));
      this.context.store.dispatch(closeModal());
    }, 250);
  }

  handleWheel(e) {
    if (
      e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + e.deltaY) <
      e.currentTarget.clientHeight
    ) {
      e.currentTarget.scrollTop = e.currentTarget.scrollHeight - e.currentTarget.clientHeight;
      e.preventDefault();
    } else if (e.currentTarget.scrollTop + e.deltaY < 0) {
      e.currentTarget.scrollTop = 0;
      e.preventDefault();
    }
  }
  changeName(e) {
    this.setState({ customModuleName: e.target.value });
  }
  render() {
    //setSite( { siteId: 2 } );

    const { site, pageElements, field, activeModuleId } = this.props;
    const siteId = site.get("siteId");
    //const activeComponent = _.find ( pageElements, ['id', activeModuleId ] );

    const sortedUrls = this.props.imgUrls.sort();

    return (
      <ModalWrapper
        transition={this.state.transition}
        {...this.props}
        title="Save Design"
        width={500}
      >
        <div style={{ margin: "20px" }}>
          <p>
            We allow you to save your changes as a custom template to access at anytime. Once you
            have saved your changes you can access this template by going to Edit Design > Themes >
            Custom.
          </p>
          <br />
          Custom Template Name
          <div>
            <input
              onChange={this.changeName}
              value={this.state.customModuleName}
              placeholder={"Enter Custom Template Name"}
              style={{
                width: "460px",
                height: "30px",
                borderRadius: "3px",
                border: "1px solid #C6C6C6",
                paddingLeft: "5px",
                outline: "none",
              }}
            />
          </div>
        </div>
        <div>
          <div
            style={{ cursor: "pointer" }}
            className={css(styles.galleryCollectionButton, styles.galleryCollectionButtonWhite)}
            onClick={() => this.cancelDesign()}
          >
            Cancel
          </div>
          <div
            style={{ cursor: "pointer" }}
            className={css(styles.galleryCollectionButton, styles.galleryCollectionButtonGreen)}
            onClick={this.saveDesign}
          >
            Save
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

SaveModuleModal.contextTypes = {
  store: PropTypes.object,
};
