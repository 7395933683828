import React from 'react';
import Component from '../../../../rootComponent';
import {css} from "@convertly/css";
import Types from '../../../../attribute-types';
import {id} from '@convertly/thor';
import _ from 'lodash';


//scripts
import toggleDropDown from '../../../header/scripts/nav-dropdown-toggle';


//Components
import WebLink from '../../../../elements/links/web-link'
import DropdownNavItem from './drop-down-nav-item';

export default class
NavLink05 extends Component {

    constructor(props) {

        super(props);

    }


    render() {

        let navItem;

        let activeClass = '';
        let link = _.get(this.props.data, 'link.url') || _.get(this.props.data, 'url');
        if(this.props.page) {
            if(this.props.page.slug === link) {
                activeClass = ' active';
            }
        }

        if (this.props.data.dropDownNav) {
            navItem =
                <ul className={ css(this.props.styles.dropdown) }>
                    <li className={ css(this.props.styles.navListItem, this.props.styles.navListItemLink) + activeClass}
                        id={ id( toggleDropDown ) }
                        data-target={ "#dropDownItem" + this.props.index }>
                        { this.props.data.text }
                        <svg className={ css( this.props.styles.caret ) + ' caret'}
                             viewBox="0 0 32 18"
                             version="1.1"
                             xmlns="http://www.w3.org/2000/svg">
                                <g id="Mobile-Portrait" transform="translate(-254.000000, -929.000000)" strokeWidth="3">
                                        <g id="Group" transform="translate(270.000000, 938.000000) scale(-1, 1) rotate(-180.000000) translate(-270.000000, -938.000000) translate(256.000000, 931.000000)">
                                            <path d="M14,0.145833333 L0.286024275,13.7169551" id="Line"/>
                                            <path d="M27.8526316,0.145833333 L14.1386559,13.7169551" id="Line" transform="translate(20.926316, 7.000000) scale(1, -1) translate(-20.926316, -7.000000) "></path>
                                        </g>
                                    </g>
                        </svg>
                        <ul className={ css(this.props.styles.dropdownList) }
                            id={ "dropDownItem" + this.props.index } data-category="dropdown">
                            <div className={ css(this.props.styles.arrowUp) }>
                            </div>
                        { this.props.data.dropDownNav.map( ( item, index ) => {

                            return(
                                <DropdownNavItem key={ index }
												 global={ this.props.global }
                                                 data={ item }
                                                 styles={ this.props.styles }
                                                 id={ "dropDownItem" + this.props.index }
                                                 index={ index }
                                />
                                         )
                                         } )
                        }
                        </ul>
                    </li>
                </ul>;
        }
        else {
            navItem =
                <li className={ css(this.props.styles.navListItem) + activeClass}>
                    <WebLink { ...this.props }  />
                </li>;

        }

        return (
            <span className={ css(this.props.styles.navItemContainer) }>
                {navItem}
            </span>
        )
    }
}

