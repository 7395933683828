/** adds value to user overrides based on styles **/
export function addValueToUserOverrides(userOverrides, ruleToCheck, newValues) {

    Object.keys(userOverrides).map((key) => {
        /** look for the rule in userOverrides
         *  and assign it to the new rules
         */
        if(key === ruleToCheck) {
            let ruleValue = userOverrides[key];
            newValues.map((value) => {
                userOverrides[value] = ruleValue;
            });
        }

        /** repeat for each media query **/
        else if (key.indexOf('@media') !== -1) {
            Object.keys(userOverrides[key]).map((mqKey) => {
                if (mqKey === ruleToCheck) {
                    let ruleValue = userOverrides[key][mqKey];
                    newValues.map((value) => {
                        userOverrides[key][value] = ruleValue;
                    });
                }
            })
        }
    });

    return userOverrides

}

/** Creates new css values based on user override. **/
export function createNewCssRules(userOverrides, ruleToCheck, newValues) {

    let newRules = {};

    Object.keys(userOverrides).map((key) => {
        /** look for the rule in userOverrides
         *  and assign it to the new rules
         */

        if(key === ruleToCheck) {
            let ruleValue = userOverrides[key];
            newValues.map((value) => {
                newRules[value] = ruleValue;
            });
        }

        /** repeat for each media query **/
        else if (key.indexOf('@media') !== -1) {
            Object.keys(userOverrides[key]).map((mqKey) => {
                if (mqKey === ruleToCheck) {
                    let ruleValue = userOverrides[key][mqKey];
                        newRules[key] = {};
                    newValues.map((value) => {
                        newRules[key][value] = ruleValue;
                    });
                }
            })

        }
        else if (key.indexOf('~#editor') !== -1) {
            Object.keys(userOverrides[key]).map((mqKey) => {
                if (mqKey === ruleToCheck) {
                    let ruleValue = userOverrides[key][mqKey];
                    newRules[key] = {};
                    newValues.map((value) => {
                        newRules[key][value] = ruleValue;
                    });
                }
            });
        }
    });

    return newRules;

}