import React from 'react';
import Component from '../rootComponent';
import { css } from '@convertly/css'
import styles from '../app-styles'

export default class Div extends Component {

	constructor ( props ) {

		super( props );

		let classes = this.props.data.classes || [];
		this.class = classes.join( ' ' );

	}

	render() {
	  this.styles = styles(this.props.global, this.props.data, this.props.page, this.props.inEditor, this.props.site.isTemplatePreview);

		return (

			<div className={`${this.class} ${css(this.styles.app)}`}>
        {this.props.children}

			</div>
		);
	}

}
