import React from 'react';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';

//Utils
import getHeaderPosition from '../header-style-functions/get-header-position';

//scripts
import absoluteToFixed from '../scripts/absolute-to-fixed-header';

//defaultProps
import defaultGlobal from '../../../themes/global';
import defaultData from '../header-default-data';

//Styles
import headerStyles from './header-styles-05.js';

//Components
import Nav05 from './components/nav-05';
import Elements from '../../../elements/index';


export class Header05 extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        
        let { site, page, data, global } = this.props;
        let inEditor = this.inEditor();


        const headerPosition = getHeaderPosition(data.position, page, inEditor);
        this.styles = headerStyles(global, data, headerPosition, inEditor);

        if (this.props.editor) {
            return this.editor();
        }

        let headerID = headerPosition === "overlayFixed" ? id(absoluteToFixed) : "header";

        /**
         * Fixed Header Fix
         */
        let fixedHeaderFix;

        if (headerPosition === "fixed") {
            fixedHeaderFix =
                <div id="test" className={css(this.styles.fixedHeaderFix)}/>;
        }
        else {
            fixedHeaderFix = <div/>
        }


        if (headerPosition === "noHeader") {
            return null
        }

        else {
            return (
                <div id={headerID}>
                    <header id="header05"
                            className={css(this.styles.header)}
                            data-elementType="header"
                            itemProp="siteHeader"
                            itemScope
                            itemType="http://schema.org/siteHeader">
                        <Nav05 global={global}
                               data={data}
                               id={ this.props.id }
                               headerPosition={headerPosition}
                               styles={this.styles}
                               page={ page }
                               site={site}/>
                    </header>

                    {fixedHeaderFix}


                </div>
            )
        }
    }

}

Header05.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
