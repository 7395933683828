import React from 'react';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import defaultGlobal from '../../../themes/global.js';
import ReactHTMLParser from "@convertly/react-html-parser";

// Styles
import styles from '../form-styles';


export default class SelectInput extends Component {
	constructor(props){
		super(props);
	}

	render(){

		this.styles = styles(this.props.global, this.props.data);

	return(
		<div className={ `${css(this.styles.formInfernoContainer)} selectContainer` }>
			{ /** Arrow Down SVG **/ }
			<svg version="1.1"
				 className={ `${css(this.styles.formInfernoContainer)} caretDown` }
				 xmlns="http://www.w3.org/2000/svg"
				 width="50"
				 height="50"
				 viewBox="0 0 404.308 404.309">
				<g>
					<path d="M0,101.08h404.308L202.151,303.229L0,101.08z"/>
				</g>
			</svg>

			<select className={`${ css(this.styles.formInfernoContainer) } selectInput` }>
				{ this.props.data.options.map((option) => {
					return (
						<option key={ option.value } value={ option.value }> { option.label } </option>
					)
				})
				}
			</select>

		</div>

		)
	}
};

SelectInput.defaultProps = {
	global: defaultGlobal,
	data: {}
}
