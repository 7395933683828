import { include } from '@convertly/thor';

const Script = () => {
  const Dispatch = include('../../../../utils/dispatcher', 'Dispatch');
  const Graph = include('../../../../utils/graph', 'Graph');
  const ProductSearchController = include('../../../../inferno-controllers/product-search-controller', 'ProductSearchController');
  const SearchBarInferno = include('./SearchBar', 'SearchBarInferno');
  const SpinnerSVGInferno = include('../../../../svg/spinner/spinner', 'SpinnerSVGInferno');
  
  return (id, data, global, inEditor, siteId) => {
    const root = document.getElementById(id);

    // Utils
    const dispatch = Dispatch(id);
    const graph = Graph();

    // Components
    const Spinner = SpinnerSVGInferno();
    const SearchBar = SearchBarInferno({ dispatch, Spinner });
    const productSearchController = ProductSearchController(dispatch, graph);

    const SearchBarWrapper = Inferno.createClass({
      getInitialState() {
        this.ProductSearchController = new productSearchController(this, id, siteId);
        return {
          searchQuery: '',
          addOpenClassToSearchBar: false
        }
      },
    
      clearSearchQuery() {
        this.setState({searchQuery: ''});
      },
    
      componentDidMount() {
    		this.ProductSearchController.initDispatchers(true)
      },

      toggleSearchBarOpenClass() {
        let currentState = this.state.addOpenClassToSearchBar;
        this.setState({ addOpenClassToSearchBar: !currentState });
      },
      
      render() {
        return (
					<SearchBar
						rootClass={ root.className }
            data={ data }
            global={ global }
            inEditor={ inEditor }
						loadingProducts={ this.state.loadingProducts }
            searchQuery={ this.state.searchQuery }
            addOpenClassToSearchBar={ this.state.addOpenClassToSearchBar }
						liveSearchResults={ this.state.liveSearchResults }
            clearSearchQuery={ this.clearSearchQuery }
            toggleSearchBarOpenClass={ this.toggleSearchBarOpenClass }
						updateSearchQuery={ this.ProductSearchController.updateSearchQuery }/>
				)
      }
      
    })

    Inferno.render(<SearchBarWrapper />, root);
  }
};

export default Script;