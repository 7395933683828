import React from 'react';
import Component from 'source/rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';

//Default Props
//import defaultData from '';
const defaultData = {
    logo: {
        src: 'magic-dragons-logo.png',
        backgroundColor: '#253039'
    }
};
import defaultGlobal from 'source/themes/global';


import { generateLink } from 'source/utils/generateLink';
import {clientImage} from 'source/utils/generateImage';

//Styles
import Styles from './checkout-header-styles-01';

//Components
import Elements from 'source/elements';


export default class CheckoutHeader01 extends Component {

    constructor(props) {

        super(props);

    }

    render() {

        this.styles = Styles(this.props.global, this.props.data);

        if (this.props.editor) {
            return this.editor();
        }
        let logo = {};

        if (this.props.site.logo) {
            logo = this.props.site.logo;
        }

        return (
            <div className={ css(this.styles.checkoutHeader) }>
                <div className={ css(this.styles.checkoutHeaderContainer) }>
                    <a href={ generateLink( { linkType:"internal", url:"/" } ) }>
                    <img src={ clientImage(logo.src) }
                         className={ css(this.styles.checkoutLogo) }
                         alt={ logo.alt }/>
                    </a>
                </div>
            </div>

        )
    }
}


CheckoutHeader01.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
