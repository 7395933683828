import React from "react";
import Component from "source/rootComponent";
import { css } from "@convertly/css";
import _ from "lodash";

// Style
import { BlogPostStyles } from "./BlogPostStyles";

// Util
import HTMLContentRenderer from "../../elements/html-content-renderer/HTMLContentRenderer";
import { parseImages } from "../util/parse-images";

export class BlogPost extends Component {
  render() {
    const { data, inEditor, global, blogData } = this.props;

    if(_.isEmpty(blogData)) {
      return null;
    }

    const previewDomain = _.get(this.props, "page.isPreviewDomain");
    const inlineStyle = this.generateStyleAttribute();
    let content =
      (this.props.inEditor || previewDomain) && blogData.draftContent
        ? blogData.draftContent
        : blogData.content;
    content = content || "";
    content = parseImages(content);

    this.styles = BlogPostStyles({ global, data, inEditor });
    const { blogPost } = this.styles;

    return (
      <article
        {...inlineStyle}
        {...this.generateDataEditorAttribute()}
        {...this.generateCustomAttributes()}
        className={css(blogPost)}
      >
        <HTMLContentRenderer data={{ text: content }} />
      </article>
    );
  }
}
