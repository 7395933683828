import {getOSData} from './getOSData'
import { graph } from "utils";

//Opens and closes the form
//Tied to the open/close buttons
export const toggleForm = function () {
  //flip the state its prior state
  if(this.state.active) this.resetState()
  this.setState(prevState => {
    return {active: !prevState.active}
  });
  return false;
};

export const sendMessage = async function() {

  //this disabled send button and updates the text
  this.setState({processing:true});
  //windows chrome doesn't allow for chaining this for some reason so need to assign it then get time.
  const currentTime = new Date();
  //the data needed for the feedback submission
  const { firstName, email } = this.props.user;
  const { activeCategory, message, osData } = this.state;
  //graph mutation
  const query = `
  mutation ( $name:String, $email:String, $category:FeedbackCategory, $message:String, $browserinfo:String, $timestamp:String ) {
    SubmitFeedback( name:$name, email:$email, category:$category, message:$message, browserinfo:$browserinfo, timestamp:$timestamp )
  }
  `;
  //assign it to vars
  const variables = {
    name:firstName,
    email,
    category:activeCategory,
    message,
    browserinfo:osData,
    timestamp:currentTime.getTime()
  };
  //shoot it off to the graph
  const feedbackSuccess = await graph({query, variables});
  //update the stage and turn processing off
  this.nextStage();
  this.setState({processing:false});

};

//gets OS data and sets to state and stringifies it
export const getOS = function () {
  this.setState({osData: JSON.stringify( getOSData() )})
};

//easies way to reset state for now, probably a better way when I have a min to think about it
//yes I copy pasted, sue me
export const resetState = function() {
  this.setState({
      stage:1, //updates stage of form
      message:"", //Message form client
      activeCategory:"", //Enum Type of feedback
      activeCategoryText:"", //English version of category
      categories: [ //Arr of feed back categories
        {text:"My Web Site Editor", type:"EDITOR"},
        {text:"My Convertly Dashboard", type:"DASHBOARD"},
        {text:"My Facebook / Google Ad Product", type:"ADTECH"},
        {text:"Something else completely", type:"OTHER"}
      ],
      osData:{},//OS data to be sent up with data
      processing: false, //state set to true when sending data to graph
  });
};
