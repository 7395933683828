import React, { Component } from "react";
import PropTypes from "prop-types";
import { css } from "@convertly/css";
import styles from "./editor-forms-styles";

export class ContextMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      on: false
    };
  }

  openOptions = () => {
    this.setState({ on: true });
  };

  closeOptions = () => {
    this.setState({ on: false });
  };

  handleOptionSelection = index => {
    // this.props.resetIndex();
    this.props.options[index].optionsCallback(this.props.itemIndex);
    this.closeOptions();
  };

  render() {
    const { options, itemIndex, top, bottom, left, right } = this.props;

    /**
     * this code determines relative position of the options popup, passed in through props.
     * you can specify a corner from which the position will the calculated.
     * e.g. top/left, bottom/right, etc.
     * you cannot specify top AND bottom or left AND right
     */
    const optionsPosition = {};
    if (!bottom) {
      optionsPosition.top = top || "-15px";
    }
    optionsPosition.bottom = bottom || null;
    if (!left) {
      optionsPosition.right = right || "-15px";
    }
    optionsPosition.left = left || null;

    return (
      <div className={css(styles.optionsContainer)}>
        <i
          className={`fa fa-cog ${this.props.spin && ` fa-spin`}`}
          onClick={this.openOptions}
          style={{ fontSize: "22px", color: "#555" }}
        />
        {this.state.on && (
          <div>
            <ul ref="container" className={css(styles.optionsList)} style={optionsPosition}>
              {options.map((option, index) => {
                return (
                  <li
                    ref={"item" + index}
                    className={css(styles.optionsItem)}
                    key={`options${index}`}
                    onClick={this.handleOptionSelection.bind(this, index)}
                  >
                    {option.optionsItemTitle}
                  </li>
                );
              })}
            </ul>
            <div className={css(styles.optionsListUnderlay)} onClick={this.closeOptions} />
          </div>
        )}
      </div>
    );
  }
}

ContextMenu.defaultProps = {
  spin: false
};

ContextMenu.propTypes = {
  spin: PropTypes.bool
};

export default ContextMenu;

//<i class="fa fa-cog" aria-hidden="true"></i>
