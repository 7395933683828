import { StyleSheet } from "@convertly/css";

import SocialIconGlobalStyles from '../social-icon-global-styles';
import UserOverrides from '../../../elements/style-functions/user-overrides';

const SocialIconsStyles = ( global, local ) => {

    const socialIconStyles = new SocialIconGlobalStyles( global, local  );

    const userOverrides = new UserOverrides( global, local );

    return StyleSheet.create({
        socialIcons: {
            padding: 0,
        },
        socialIconsList: {
            
        },

        socialIcon: {
            fill: global.colors.dark.black,
            padding: '2px',
            height: '20px',
            width: '20px'
        },

        socialIconContainer: {
            background: "#95DDB6",
            borderRadius: '100%',
            height: '26px',
            padding: '3px',
            width: '26px',
            ":hover": {
                opacity: '.7'
            },
            ...userOverrides
        },


    })

};

export default SocialIconsStyles;