import React from "react";
import PropTypes from "prop-types";
import { clientImage } from "containers/Editor/components/pegasus-components/src/utils/generateImage";

import RotateLeft from "components/icons/imgedit_rotate_left.svg";
import RotateRight from "components/icons/imgedit_rotate_right.svg";
import FlipHorizontal from "components/icons/imgedit_flip_horizontal.svg";
import FlipVertical from "components/icons/imgedit_flip_vertical.svg";
import { EditCSSValueSlider } from "../../../Editor/components/editor/components/EditCSSValueSlider";

import {
  ImageEditingPanel,
  OrientationContainer,
  ImageContainer,
  SubToolContainer,
  SliderContainer2,
  ToolLabel,
  ToolsPairContainer,
  ToolsGroupContainer,
  AdjustmentToolsRow,
  ImgResponsive
} from "./css-image-editor";

export class OrientationPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTool: ""
    };

    this.handleToolSelect = this.handleToolSelect.bind(this);
  }

  handleToolSelect(tool) {
    this.setState({ selectedTool: tool });
  }

  render() {
    const { image, params, siteId } = this.props;
    const { selectedTool } = this.state;

    return (
      <ImageEditingPanel>
        <ImageContainer>
          <ImgResponsive src={clientImage(image, params.toJS(), siteId)} />
        </ImageContainer>
        <OrientationContainer>
          <ToolsGroupContainer>
            <ToolsPairContainer>
              <SubToolContainer
                selected={selectedTool === "RotateLeft"}
                onClick={this.handleToolSelect.bind(this, "RotateLeft")}
                style={{ marginRight: "8px" }}
              >
                <img src={RotateLeft} height="24" />
              </SubToolContainer>
              <SubToolContainer
                selected={selectedTool === "RotateRight"}
                onClick={this.handleToolSelect.bind(this, "RotateRight")}
              >
                <img src={RotateRight} height="24" />
              </SubToolContainer>
            </ToolsPairContainer>
            <ToolLabel>Rotate</ToolLabel>
          </ToolsGroupContainer>
          <ToolsGroupContainer>
            <ToolsPairContainer>
              <SubToolContainer
                selected={selectedTool === "FlipVertical"}
                onClick={this.handleToolSelect.bind(this, "FlipVertical")}
                style={{ marginRight: "8px" }}
              >
                <img src={FlipVertical} height="24" />
              </SubToolContainer>
              <SubToolContainer
                selected={selectedTool === "FlipHorizontal"}
                onClick={this.handleToolSelect.bind(this, "FlipHorizontal")}
              >
                <img src={FlipHorizontal} height="24" />
              </SubToolContainer>
            </ToolsPairContainer>
            <ToolLabel>Flip</ToolLabel>
          </ToolsGroupContainer>
        </OrientationContainer>
        <SliderContainer2>
          <div>
            <EditCSSValueSlider
              defaultValue={0}
              unit="vh"
              min={0}
              max={100}
              field={"height"}
              sectionWrapperStyle={{ marginBottom: 0, marginRight: 0, paddingBottom: 20 }}
              objectBeingEdited={this.sliderObject}
              pageElements={this.props.pageElements}
              editorViewportWidth={this.props.editorViewportWidth}
              sliderHandleStyle={{
                marginTop: "20px",
                width: 13,
                height: 13,
                backgroundColor: "#45AA91"
              }}
              sliderBarStyle={{ width: "250px", height: "3px" }}
              hideLabel
            />
          </div>
        </SliderContainer2>
      </ImageEditingPanel>
    );
  }
}

OrientationPanel.defaultProps = {
  image: "",
  params: {},
  siteId: {}
};

OrientationPanel.propTypes = {
  image: PropTypes.string,
  params: PropTypes.object,
  siteId: PropTypes.string
};
