import { StyleSheet } from '@convertly/css';

const Styles = ( global, data ) => {

    let contrast = {},
        contrastBorder = {};

    if ( data.textColor === 'dark' ) {
        contrast = {
            color: "black",
            borderColor: "black"
        }

        contrastBorder = {
            borderBottom: "3px solid black"
        }
    }

    let signInButton = {
        padding:"15px 25px",
        display:'inline-block',
        borderRadius: "5px",
        color:global.colors.light.white,
        border:'none',
        marginBottom: "15px",
        maxWidth:'90%',
        "@media(max-width:767px)": {
            textAlign:'center',
            width:'100%',
            display:'block',
            margin:'5px auto'
        }

    };

    return StyleSheet.create({

        freeTrialSection: {
            height:'80vh',
            backgroundImage:`linear-gradient(to left, ${global.colors.accent.color1}, ${global.colors.accent.color2}, ${global.colors.accent.color1} ) }`

        },

        signUpInferno: {
            ".login-wrapper": {
                width:"100%",
                "@media (max-width:767px)": {
                    maxWidth:"375px",
                    margin:'0 auto'
                }
            },
            ".input": {
                ...global.input,
                width:"50%",
                "@media (max-width:767px)": {
                    width:"100%",

                },
                border: 'none',
                borderBottom: '3px solid white',
                height: '40px',
                background: 'transparent',
                color: 'white',
                fontSize: '16px',
                margin: "10px 0px",
                ...contrast
            },
            '.input::placeholder': {
                color: 'rgba(255,255,255,0.6)',
                paddingLeft: '5px',
                ...contrast
            },
            ".form-error-message": {
                display:'none',
                fontSize: '10px',
                margin:'0'
            },
            ".show-form-error-message": {
                display:'block'
            },
            ".mobile-nav-terms-paragraph": {
                fontSize:'12px',
                color:global.colors.light.white,
                ...contrast
            },
            ".sign-up-buttons": {
                marginTop:'15px'
            },
            ".google-button": {
                ...signInButton,
                background:"#4285f4",
            },
            ".facebook-button": {
                ...signInButton,
                background:"red",

            },
            ".convertly-button": {
                ...signInButton,
                background:'#132755',
                color:'#fff'
            },

        }

    })

};

export default Styles;
