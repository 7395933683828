import React from "react";
import Component from "source/rootComponent";
import {css} from "@convertly/css";
import _ from "lodash";

// Components
import Elements from "source/elements";

// Style
import {AuthorInfoStyles} from "./AuthorInfoStyles";
import { generateBlogLink } from '../util/generate-blog-link'

// Utils
import {clientImage} from "../../utils/generateImage";
import generateId from "../../utils/generate-id";

export class AuthorInfo extends Component {


    render() {

        const {data, inEditor, global, blogData} = this.props;

        if(_.isEmpty(data) && _.isEmpty(blogData)) {
            return null;
        }

        this.styles = AuthorInfoStyles({global, data, inEditor});
        const {
                authorInfoStyle,
                authorAvatar,
                authorName,
                authorWrapper,
                authorBio,
                authorLink
            } = this.styles,
            authorInfo = _.get(this.props, "blogData.author"),
            {headingText, displayHeadingText} = data || {},
            {avatar, name, url, shortBio} = authorInfo || {},
            {src, alt, title, imgParams} = avatar || {},
            inlineStyle = this.generateStyleAttribute();

        if (!authorInfo) {
            return null;
        }

        return (
            <section
                {...inlineStyle}
                {...generateId(this.props.elementId)}
                {...this.generateDataEditorAttribute()}
                {...this.generateCustomAttributes()}
                className={css(authorInfoStyle)}
            >
                {displayHeadingText !== false && (
                    <Elements.Heading4
                        data={{
                            text: _.isString(headingText) ? headingText : "Author",
                            userOverrides: _.get(data, "userOverrides.headingText.userOverrides" || {})
                        }}
                    />
                )}

                <div className={css(authorWrapper)}>
                    {src && (
                        <Elements.Image
                            data={{
                                src,
                                imgParams,
                                alt,
                                title
                            }}
                            parentClass={css(authorAvatar)}
                        />
                    )}

                    {name && <span className={css(authorName)}>{name}</span>}
                </div>

                {shortBio && <p className={css(authorBio)}>{shortBio}</p>}

                {name && (
                    <a className={css(authorLink)} href={generateBlogLink(this.props.page.blogLink, url, inEditor)}>
                        More By {name}
                    </a>
                )}
            </section>
        );
    }
}
