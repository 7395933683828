import React from 'react';
import Component from '../../../../rootComponent';
import {css} from "@convertly/css";
import Types from '../../../../attribute-types';
import {id} from '@convertly/thor';


//scripts
import toggleDropDown from '../../../header/scripts/nav-dropdown-toggle';

//Components
import NavLink from './nav-link';

export default class
NavList05 extends Component {

    constructor(props) {

        super(props);

    }


    render() {


        return (
            <nav className={ css( this.props.styles.headerNav ) }>
                <ul className={ css(this.props.styles.navList) }
                    id="menu"
                    >
                    {this.props.data.navLinks.map((navItem, index) => {
                        return (
                            <NavLink key={ index }
									   data={navItem}
                                       page={ this.props.page }
                                       styles={ this.props.styles }
                                       index={ index }
                                       />
                        )
                    })}
                </ul>
            </nav>
        )
    }
}

