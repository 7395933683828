import findElementObject from "./findElementObject";
export const getAncestorObject = (pageElements, object, activeModuleId, generation) => {
  let parentObject;
  if (generation < 0) {
    console.log("negative generation not allowed");
    return;
  }
  if (generation === 0) return object;
  else {
    pageElements.forEach(element => {
      if (element.id === activeModuleId) {
        parentObject = findElementObject(element, object.parentId);
      }
    });
    return getAncestorObject(pageElements, parentObject, activeModuleId, generation - 1);
  }
};
export default getAncestorObject;
