import { StyleSheet } from "@convertly/css";

// background: 'linear-gradient(90deg, #8BD9B2 0%, #45AA91 100%)',

const headerGradient = "linear-gradient(180deg, #FFF 0%, #EEE 100%)";
const buttonGradient = "linear-gradient(90deg, #8BD9B2 0%, #45AA91 100%)";

export const styles = StyleSheet.create({
  sortingCaret: {
    fontSize: "18px",
    lineHeight: "18px",
    marginLeft: "6px",
    color: "#777",
    ":hover": {
      color: "#000"
    },
    cursor: "pointer"
  },
  thumbnail: {
    height:40,
    width:40
  },
  loadingScreen: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    position: "fixed",
    top: "0",
    left: "0",
    zIndex: "3900",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,.05)",
    color: "#FFF",
    fontSize: "48px"
  },
  loadingIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    position: "absolute",
    top: "0",
    left: "0",
    zIndex: "3900",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,.05)",
    color: "#FFF",
    opacity: "0.3"
  },
  dashLoadingIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "300px",
    opacity: "0.3"
  },
  storefrontContainer: {
    backgroundColor: "#F5F8FA",
    height: "100%"
  },
  formGroupWrapper: {
    backgroundColor: "#FFF",
    border: "1px solid lightgray",
    marginTop: "30px",
    borderRadius: "10px"
  },
  storefrontListContainer: {
    border: "1px solid lightgray",
    borderRadius: "10px",
    //marginTop: '50px',
    backgroundColor: "#FFF"
  },
  modalStorefrontListContainer: {
    border: "none",
    borderRadius: "none",
    marginTop: "0"
  },

  // ACCOUNT SETTINGS //

  subscriptionCardFeature: {
    fontSize: "14px",
    margin: "0 0 12px 20px"
  },

  subscriptionCardFeatureIcon: {
    fontSize: "16px",
    color: "rgba(52,152,134, 0.7)",
    marginRight: "10px"
  },

  subscriptionCardSelectButton: {
    margin: "0 20px",
    textAlign: "center",
    height: "35px",
    borderRadius: "3px",
    textTransform: "uppercase",
    fontSize: "14px",
    lineHeight: "35px",
    border: "1px solid #848484",
    ":hover": {
      cursor: "pointer"
    },
    userSelect: "none"
  },
  subscriptionCardMonthlySpacer: {
    marginBottom: "14px"
  },

  subscriptionCardRibbon: {
    textAlign: "center",
    height: "36px",
    fontSize: "14px",
    lineHeight: "36px",
    margin: "20px 0",
    backgroundColor: "rgba(52,152,134,0.1)",
    fontWeight: 600
  },

  subscriptionCard: {
    width: "250px",
    display: "flex",
    flexDirection: "column",
    borderRadius: "6px",
    padding: "20px 0 8px 0",
    ":hover": {
      boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 3px 3px rgba(0,0,0,0.23)"
    }
  },
  subscriptionFormFrame: {
    borderRadius: "6px",
    padding: "20px",
    border: "1px solid #CCC",
    marginBottom: "20px"
  },

  subscriptionPlan: {
    padding: "30px",
    margin: "0 auto"
  },
  subscriptionHeader: {
    textAlign: "center",
    fontSize: "26px",
    marginBottom: "5px"
  },
  subscriptionFooter: {
    borderTop: "1px solid #ccc",
    padding: "20px",
    height: "82px"
  },
  subscriptionFooterButtonsContainer: {
    float: "right",
    display: "inline-block",
    // width: '320px',
    height: "42px",
    margin: "0"
  },
  subscriptionMonitorIcon: {
    fontSize: "40px",
    color: "#666"
  },
  subscriptionFooterElement: {
    marginRight: "20px",
    display: "inline-block",
    position: "relative"
  },
  subscriptionFooterLeftGroup: {
    display: "inline-block"
  },
  subscriptionTOS: {
    display: "inline-block",
    float: "right",
    lineHeight: "38px"
  },

  subscriptionTerms: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline;",
    marginBottom: "20px"
  },
  subscriptionTotal: {
    display: "flex",
    fontWeight: "600",
    paddingTop: "10px",
    marginTop: "28px",
    borderTop: "1px solid #CCC",
    justifyContent: "space-between",
    alignItems: "flex-end"
  },
  subscriptionSummaryHead: {
    fontSize: "20px",
    marginBottom: "20px"
  },
  subscriptionSummaryItem: {
    fontSize: "14px",
    marginBottom: "12px"
  },
  subscriptionSummaryFooterText: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "38px",
    display: "inline-block",
    textTransform: "uppercase"
  },

  // from editor modal styles //

  modalCollectionContainer: {
    padding: "20px 0 20px 20px",
    maxHeight: "484px",
    overflowY: "auto"
  },
  galleryCollectionButton: {
    height: 52,
    padding: 13,
    width: "50%",
    display: "inline-block",
    textAlign: "center",
    borderTop: "1px solid #AAA",
    color: "#4A4A4A",
    fontSize: 16,
    verticalAlign: "center",
    userSelect: "none",
    cursor: "pointer",
    borderRadius: "0 0 10px 0"
  },
  galleryCollectionButtonGreen: {
    color: "#FFF",
    background: buttonGradient
  },
  galleryCollectionButtonDisabled: {
    color: "#FFF",
    background: "#CCC",
    cursor: "not-allowed"
  },

  // NOTIFICATION BAR //

  notificationBarContainer: {
    marginTop: "40px",
    position: "relative",
    height: "80px",
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    marginBottom: "-30px"
  },
  notificationBarIcon: {
    color: "red",
    fontSize: "50px",
    width: "80px",
    height: "80px",
    borderRadius: "6px 0 0 6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  notificationBarMessage: {
    paddingLeft: "20px"
  },
  notificationBarClose: {
    position: "absolute",
    top: "10px",
    right: "15px"
  },

  // BUSINESS SETTINGS //

  formHeader: {
    fontSize: "20px",
    fontWeight: "400"
  },
  paymentMethodsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "100px"
  },
  paymentMethodCard: {
    //width: '363px',
    height: "250px",
    border: "1px solid #CCC",
    borderRadius: "10px",
    padding: "20px",
    //margin: '20px 10px 0 10px',
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  paymentLogoContainer: {
    marginBottom: "20px"
  },
  paymentCardDescription: {
    marginBottom: "20px",
    textAlign: "center"
  },
  paymentConnectContainer: {},
  shippingButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "60px"
  },
  shippoContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "30px"
  },
  businessLocationsContainer: {
    display: "flex",
    marginBottom: "20px",
    flexWrap: "wrap"
  },
  businessLocationCard: {
    width: "254.5px",
    border: "1px solid #CCC",
    borderRadius: "10px",
    padding: "20px",
    margin: "20px 20px 0 0px",
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  businessLocationItem: {},
  textLink: {
    cursor: "pointer",
    color: "#428BCA",
    ":hover": {
      color: "#2A6496"
    }
  },
  businessLocationEdit: {
    position: "absolute",
    top: 20,
    right: 20,
    cursor: "pointer",
    color: "#428BCA",
    ":hover": {
      color: "#2A6496"
    }
  },
  businessModalWrapper: {
    width: "766px",
    position: "fixed",
    left: "50%",
    transform: "translate(-50%, 0%)",
    top: "calc(50% - 320px)",
    zIndex: "9999",
    background: "#FFF",
    borderRadius: "10px",
    boxShadow: "0 0 3px 0 rgba(0,0,0,0.5)"
  },
  modalHeader: {
    fontSize: 18,
    color: "#4A4A4A",
    padding: 20,
    height: 60,
    background: headerGradient,
    borderRadius: "10px 10px 0 0",
    borderBottom: "1px solid #CCC"
  },
  modalUnderlay: {
    position: "fixed",
    top: "0",
    left: "0",
    zIndex: "3900",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,.3)"
  },

  // PAGE SETTINGS //

  pageSettingsModalWrapper: {
    width: "810px",
    position: "fixed",
    left: "50%",
    transform: "translate(-50%, 0%)",
    top: "calc(50% - 320px)",
    zIndex: "9999",
    background: "#FFF",
    borderRadius: "10px",
    boxShadow: "0 0 3px 0 rgba(0,0,0,0.5)"
  },

  // REPORTS WIDGET //

  cardWidgetContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "273px",
    padding: "20px"
  },
  cardWidgetPrimaryData: {
    fontSize: "84px",
    lineHeight: "90px",
    flexItem: "center",
    textAlign: "center"
  },
  cardWidgetDelta: {
    paddingTop: "20px",
    marginBottom: "50px",
    textAlign: "center"
  },
  cardWidgetMore: {},
  convertlyWidgetContainer: {
    border: "1px solid #CCC",
    marginTop: "30px",
    backgroundColor: "#FFF",
    borderRadius: "7px",
    height: "120px",
    position: "relative",
    padding: "20px"
  },

  convertlyWidgetHeader: {
    textAlign: "center",
    borderRadius: "7px 7px 0 0",
    fontSize: "28px",
    color: "#4A4A4A",
    padding: 20,
    height: 60,
    backgroundColor: "#FFF"
  },

  convertlyWidgetLongMessageHeader: {
    textAlign: "center",
    borderRadius: "7px 7px 0 0",
    fontSize: "20px",
    color: "#4A4A4A",
    padding: 20,
    height: 60,
    backgroundColor: "#FFF"
  },

  convertlyWidgetBodyContainer: {
    position: "relative"
  },

  widgetContainer: {
    position: "relative",
    boxShadow: "0 0 2px 0 rgba(65,65,65,0.25)",
    marginTop: "30px",
    backgroundColor: "#FFF",
    borderRadius: "7px"
  },

  widgetHeader: {
    borderRadius: "7px 7px 0 0",
    fontSize: "18px",
    color: "#4A4A4A",
    padding: "17px",
    paddingLeft: "20px",
    height: 60,
    backgroundColor: "#FFF",
    borderBottom: "1px solid #CCC"
  },
  widgetOptions: {
    position: "absolute",
    top: "18px",
    right: "12px"
  },

  widgetBodyContainer: {
    position: "relative"
    // height: '300px',
    // '@media (max-width: 470px)': {
    //     height: '195px',
    // },
    // '@media (max-width: 400px)': {
    //     height: '175px',
    // },
  },

  // PRODUCT PROMOTION //

  // MODAL //

  modalButton: {
    height: 52,
    padding: 13,
    width: "50%",
    display: "inline-block",
    textAlign: "center",
    borderTop: "1px solid #AAA",
    color: "#4A4A4A",
    fontSize: 16,
    verticalAlign: "center",
    userSelect: "none",
    cursor: "pointer"
  },
  modalButtonGreen: {
    color: "#FFF",
    backgroundColor: "rgba(78,177,77,1)"
  },

  // PAGINATION //

  paginationContainer: {
    float: "right",
    padding: "10px 0",
    marginTop: "6px",
    display: "flex",
    flexWrap: "no-wrap",
    alignItems: "center"
  },
  compressedPageContainer: {
    display: "flex",
    flexWrap: "no-wrap",
    alignItems: "center"
  },
  pageNumber: {
    height: "21px",
    padding: "0 8px",
    borderRadius: "3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    userSelect: "none",
    margin: "0 2px",
    ":hover": {
      backgroundColor: "#999",
      color: "#FFF"
    }
  },
  selectedPageNumber: {
    backgroundColor: "#45AA91",
    color: "#FFF",
    ":hover": {
      backgroundColor: "#45AA91"
    }
  },
  paginationEllipses: {
    margin: "0 3px"
  },
  prevNext: {
    fontSize: "14px",
    color: "#999",
    width: "24px",
    borderRadius: "3px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ":hover": {
      fontSize: "16px",
      height: "21px",
      backgroundColor: "#999",
      color: "#FFF"
    }
  },

  // productOptions //

  cursor: {
    cursor: "pointer"
  },

  textAnchor: {
    textAlign: "center",
    cursor: "pointer",
    color: "#428BCA",
    ":hover": {
      color: "#2A6496"
    }
  },

  productOptionsText: {
    marginBottom: "10px"
  },

  productOptionsTrash: {
    fontSize: "28px",
    color: "#999",
    textAlign: "center",
    paddingTop: "22px",
    ":hover": {
      color: "#F05F54"
    },
    cursor: "pointer"
  },
  productOptionsContainer: {
    marginBottom: "5px",
    padding:'15px'
  },

  // File Uploader //

  plusIcon: {
    fontSize: 36,
    color: "#BBB",
    cursor: "pointer",
    lineHeight: "70px"
  },
  upLoadIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  fileUploadContainer: {
    display: "inline-block",
    height: "78px",
    width: "78px",
    border: "4px dashed #DDD",
    cursor: "pointer",
    backgroundColor: "#F6F6F6"
  },

  // TOGGLE //

  toggleLabel: {
    fontWeight: "400",
    display: "inline-block"
  },
  formToggleContainer: {
    display: "flex",
    alignItems: "flex-end"
  },

  // Customer Details //

  profileAvatar: {
    display: "inline-block",
    width: "60px",
    height: "60px",
    color: "#FFF",
    backgroundColor: "orange",
    borderRadius: "50%",
    fontWeight: "300",
    fontSize: "24px",
    textAlign: "center",
    lineHeight: "60px",
    letterSpacing: "2px",
    marginRight: "14px"
  },
  profileDetail: {
    display: "inline-block",
    alignItems: "center",
    flexWrap: "nowrap"
  },
  customerName: {
    fontSize: "18px",
    lineHeight: "20px",
    margin: "0"
  },
  customerOrderWrapper: {
    border: "1px solid #CCC",
    padding: "20px",
    width: "140px",
    display: "inline-block",
    margin: "0 20px 20px 0"
  },
  customerOrderImage: {
    border: "1px solid #CCC",
    width: "200px",
    marginBottom: "16px"
  },
  customerOrderImageLink: {
    textAlign: "center",
    margin: "0"
  },
  customerOrderImageDescrption: {
    textAlign: "center",
    margin: "0"
  },

  // Product Details //

  productImagesContainer: {
    backgroundColor: "#EEE",
    border: "1px solid #C6C6C6",
    padding: "20px"
  },
  productImagesContainerSecondary: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  productImageMain: {},
  productImagePrimary: {
    position: "relative"
  },
  productImagesSecondary: {
    marginTop: "20px"
  },
  productImagePrimarySelection: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    textAlign: "center",
    paddingTop: "150px",
    backgroundColor: "rgba(74,144,226,0.5)",
    border: "4px solid rgba(74,144,226,1)",
    //display: 'flex',
    justifyContent: "center",
    color: "#FFF"
  },
  productImageSecondarySelection: {
    position: "absolute",
    top: 20,
    left: 0,
    width: "100%",
    height: "calc(100% - 20px)",
    padding: "10px",
    paddingTop: "45px",
    textAlign: "right",
    backgroundColor: "rgba(74,144,226,0.5)",
    border: "4px solid rgba(74,144,226,1)",
    color: "#FFF"
  },
  productImageSelectionIconRowContainer: {
    //width: '120px',
    width: "100%",
    //display: 'flex',
    justifyContent: "space-between"
  },
  productImageSelectionIcon: {
    color: "rgba(255,255,255,0.5)",
    fontSize: "20px",
    ":hover": {
      color: "#FFF"
    },
    cursor: "pointer"
  },
  productPromotionLinks: {
    marginBottom: "10px"
  },

  // OrderHistory //

  orderHistoryItem: {
    marginBottom: "20px",
    display: "flex"
  },
  orderHistoryIcon: {
    width: "54px",
    display: "inline-block",
    fontSize: "38px",
    lineHeight: "38px"
  },
  orderHistoryDescriptionColumn: {
    width: "100%",
    display: "inline-block"
  },
  orderHistoryDescription: {},
  orderHistoryOrderNumber: {
    fontWeight: "700"
  },

  currency: {
    textAlign: "right"
  },

  // OrderSummary //

  orderSummaryItem: {},
  orderSummaryCategory: {
    display: "inline-block",
    textAlign: "right",
    width: "80%"
  },
  orderSummaryTotal: {
    marginTop: "6px",
    fontWeight: "700",
    fontSize: "16px"
  },
  orderSummaryPrice: {
    display: "inline-block",
    width: "20%",
    float: "right",
    textAlign: "right"
  },

  // OrderCustomer //

  orderCustomerAvatar: {
    width: "15%",
    display: "inline-block",
    fontSize: "38px",
    lineHeight: "38px",
    color: "rgba(74,144,226,1)"
  },
  orderCustomerNameEmail: {
    display: "inline-block",
    verticalAlign: "middle"
  },
  orderCustomerName: {
    marginTop: "-10px"
  },

  // OrderPaymentMethod //

  orderCreditCard: {
    fontSize: "22px",
    color: "rgba(74,144,226,1)",
    marginRight: "10px"
  },
  orderCreditCardNumber: {
    position: "relative",
    top: "-4px"
  },
  //

  formItemWrapper: {
    padding: "20px",
    borderBottom: "1px solid #DDD"
  },
  formItemEdit: {
    display: "inline-block",
    float: "right",
    alignSelf: "flex-start"
  },

  //

  orderItemInput: {
    display: "inline-block",
    height: "24px",
    width: "40px",
    padding: "2px 12px",
    fontSize: "14px",
    lineHeight: "1.428",
    color: "#555",
    backgroundColor: "#FFF",
    border: "1px solid #CCC",
    borderRadius: "4px",
    textAlign: "center",
    margin: "0 6px"
  },
  tableViewContentContainer: {
    padding: "20px"
  },
  tableViewHeader: {
    fontSize: 18,
    color: "#4A4A4A",
    padding: 20,
    height: 60,
    background: headerGradient,
    borderRadius: "10px 10px 0 0",
    borderBottom: "1px solid #CCC"
  },

  tableHead: {
    fontWeight: "bold",
    backgroundColor: "#FFF",
    borderTop: "none",
    paddingLeft: "200px"
  },

  searchContainer: {
    marginLeft: "20px",
    display: "inline-block"
  },
  searchBox: {
    width: "240px",
    height: "25px",
    display: "inline-block",
    marginRight: "10px"
  },
  searchCategories: {
    width: "120px",
    height: "25px",
    display: "inline-block"
  },
  filterContainer: {
    display: "flex"
  },
  filterText: {
    display: "inline-block",
    marginRight: "6px",
    marginLeft: "8px"
  },
  filterCategories: {
    width: "140px",
    display: "inline-block",
    "@media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 2)": {
      fontSize: "16px"
    }
  },
  searchFilterBarContainer: {
    padding: "20px 36px 5px 20px"
  },
  actionButtonsContainer: {
    display: "inline-block",
    float: "right"
  },
  actionButton: {
    display: "inline-block",
    width: "120px",
    height: "28px",
    lineHeight: "26px",
    textAlign: "center",
    fontSize: "14px",
    borderRadius: "14px",
    border: "1px solid #CCC",
    marginLeft: "10px"
  },
  pillButton: {
    display: "inline-block",
    float: "right",
    height: "28px",
    textAlign: "center",
    fontSize: "14px",
    borderRadius: "6px",
    border: "1px solid #CCC",
    padding: "0 20px",
    userSelect: "none",
    cursor: "pointer",
    outline: "none",
    alignItems: "center",
    ":hover": {
      opacity: "0.75"
    }
  },
  pillButtonTable: {
    display: "inline-block",
    float: "right",
    height: "28px",
    textAlign: "center",
    fontSize: "14px",
    borderRadius: "6px",
    border: "1px solid #CCC",
    padding: "0 6px",
    userSelect: "none",
    cursor: "pointer",
    outline: "none",
    alignItems: "center"
  },
  pillButtonLarge: {
    display: "inline-block",
    float: "left",
    height: "40px",
    textAlign: "center",
    fontSize: "16px",
    borderRadius: "6px",
    border: "1px solid #CCC",
    padding: "0 20px",
    userSelect: "none",
    cursor: "pointer",
    outline: "none",
    lineHeight: "18px",
    ":hover": {
      opacity: "0.75"
    }
  },

  actionButtonGreen: {
    color: "#FFF",
    backgroundColor: "#52AF52"
  },

  toggleTableView: {
    marginBottom: "10px",
    fontSize: "14px"
  },
  toggleTableViewContainer: {
    width: "120px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "baseline"
  },

  // bootstrap overrides //

  th: {
    padding: "24px",
    fontWeight: "500",
    fontSize: "14px",
    borderBottom: "2px solid #DDD"
  },
  td: {
    padding: "24px",
    display: "table-cell",
    "> a": {
      cursor:'pointer'
    }

  },

  formEditorModalWrapper: {
    width: "580px",
    position: "fixed",
    left: "50%",
    transform: "translate(-50%, 0%)",
    top: "calc(3em + 40px)",
    zIndex: "9999",
    background: "#FFF",
    borderRadius: "10px",
    boxShadow: "0 0 3px 0 rgba(0,0,0,0.5)"
  },

  menuEditorModalWrapper: {
    width: "1000px",
    position: "fixed",
    left: "50%",
    transform: "translate(-50%, 0%)",
    top: "calc(3em + 40px)",
    zIndex: "9999",
    background: "#FFF",
    borderRadius: "10px",
    boxShadow: "0 0 3px 0 rgba(0,0,0,0.5)"
  },

  // form input //

  label: {
    fontWeight: "400",
    color: "black",
    fontSize: "1em"
  },
  labelOptional: {
    marginLeft: "6px",
    fontSize: "12px",
    color: "#999"
  },

  //** TABLE HEADER **//

  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

export default styles;
