import React from "react";
export default class MobileIcon extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <svg
        style={this.props.style}
        width="11px"
        height="20px"
        viewBox="0 0 11 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0,12.5 L11,12.5 L11,4.375 L0,4.375 L0,12.5 Z M8.55555556,0 L2.44444444,0 C1.0945,0 0,1.119375 0,2.5 L0,3.125 L11,3.125 L11,2.5 C11,1.119375 9.9055,0 8.55555556,0 L8.55555556,0 Z M5.5,15 C6.17466667,15 6.72222222,15.56 6.72222222,16.25 C6.72222222,16.94 6.17466667,17.5 5.5,17.5 C4.82533333,17.5 4.27777778,16.94 4.27777778,16.25 C4.27777778,15.56 4.82533333,15 5.5,15 L5.5,15 Z M0,17.5 C0,18.880625 1.0945,20 2.44444444,20 L8.55555556,20 C9.9055,20 11,18.880625 11,17.5 L11,13.75 L0,13.75 L0,17.5 L0,17.5 Z"
          id="icon/mobile"
        />
      </svg>
    );
  }
}
