import { fromJS } from "immutable";
import { createReducer } from "redux-act";

import {
  setUnclaimedChatThreads,
  setClaimedChatThreads,
  setHistoryChatThreads,
  claimChatThreadAction,
  setActiveChatThreadsAction,
  addMessageToSendQueueAction,
  processSentMessageAction,
  setPubNub,
  setUnreadClaimedChats,
  setUnreadUnclaimedChats,
  setActiveChat
} from "./chatServiceActions";

const initialState = fromJS({
  unclaimedChatThreads: [],
  claimedChatThreads: [],
  historyChatThreads: [],
  sendMessageQueue: [],
  unreadClaimedChats: 0,
  unreadUnclaimedChats: 0,
  activeChat: {}
});

const reducers = {
  [setActiveChat]: (state, activeChat) => state.merge({ activeChat }),

  [setUnreadClaimedChats]: (state, unreadClaimedChats) => state.merge({ unreadClaimedChats }),

  [setUnreadUnclaimedChats]: (state, unreadUnclaimedChats) => state.merge({ unreadUnclaimedChats }),

  [processSentMessageAction]: (state, processedMessage) => {
    const messagesToSend = state.get("sendMessageQueue");

    const updatedMessagesToSend = messagesToSend.filter(message => {
      return processedMessage !== message;
    });

    return state.merge({
      sendMessageQueue: updatedMessagesToSend
    });
  },

  [addMessageToSendQueueAction]: (state, message) => {
    const messagesToSend = state.get("sendMessageQueue").push(message);

    return state.merge({
      sendMessageQueue: messagesToSend
    });
  },

  [setUnclaimedChatThreads]: (state, unclaimedChatThreads) => state.merge({ unclaimedChatThreads }),

  [setClaimedChatThreads]: (state, claimedChatThreads) => state.merge({ claimedChatThreads }),

  [setHistoryChatThreads]: (state, historyChatThreads) => state.merge({ historyChatThreads }),

  [claimChatThreadAction]: (state, claimedChatThread) => {
    const pendingChatThreads = state.get("pendingChatRequests");
    const chatThreads = state.get("activeChatThreads");

    let channelsToSubscribe;

    const updatedPendingChatThreads = pendingChatThreads.filter(chatThread => {
      return chatThread.get("channel") !== claimedChatThread.channel;
    });

    const updatedChatThreads = chatThreads.push(claimedChatThread);

    channelsToSubscribe = state.get("chatChannelsToSubscribe").push(claimedChatThread.channel);

    return state.merge({
      pendingChatRequests: updatedPendingChatThreads.toJS(),
      activeChatThreads: updatedChatThreads.toJS(),
      chatChannelsToSubscribe: channelsToSubscribe.toJS()
    });
  },

  [setActiveChatThreadsAction]: (state, activeChatThreads) => {
    return state.merge({
      activeChatThreads: activeChatThreads
    });
  }
};

const chatServiceReducer = createReducer(reducers, initialState);

export default chatServiceReducer;
