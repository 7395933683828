import {GOOGLE_FONT_API_KEY} from "../config";
import axios from "axios";

export const getGoogleFonts = async () => {

  const url = `https://www.googleapis.com/webfonts/v1/webfonts?key=${GOOGLE_FONT_API_KEY}`;

  const { data } = await axios.get(url);

  return data.items;

}
