import { createAction } from "redux-act";

export const setBlogPanel = createAction("BLOGS PANEL: Set Blog Option");
export const setCategories = createAction("BLOGS PANEL: Set Blog categories");
export const setAuthors = createAction("BLOGS PANEL: Set Blog authors");
export const setDefaultAuthor = createAction("BLOGS PANEL: Set default author ID");

export const resetBlogState = createAction("BLOGS PANEL: Reset blogs panel state");

export const setActiveNewPostOption = createAction("BLOGS PANEL: Set New Post Option");
export const setActiveLayoutOption = createAction("BLOGS PANEL: Set Layout Option");
export const setActiveThemeOption = createAction("BLOGS PANEL: Set Active Theme Option");

export const setValue = createAction("BLOG PANEL: Set store value");

export const setTempPageElements = createAction("BLOG PANEL: Store page elements from page");

export const getBlogPanelState = state => state.get("blogsPanel");

export const update = (field, value) => async dispatch => {
  await dispatch(setValue({ field, value }));
};
