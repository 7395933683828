import React from "react";
import { browserHistory } from "react-router";
import PropTypes from "prop-types";
import { saveProduct } from "containers/StoreFront/ProductDetail/actions";
import PillButton from "components/Button/pillButton";
import Options from "containers/Editor/components/editor/options";

const inlineCss = {
  spacing: {
    marginRight: "20px"
  },
  button: {
    cursor: "pointer"
  },
  container: {
    width: "100%"
  }
};

export class UserDetailMenu extends React.Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.onCancel = this.onCancel.bind(this);
  }

  onCancel(e) {
    e.preventDefault();

    const { matches } = this.props;

    let type = "";

    if (Array.isArray(matches) && matches[1]) {
      type = matches[1];
    }

    browserHistory.push(`/users/${type}`);
  }

  /**
   *
   * @returns {*}
   */
  render() {
    const { saving, dirty } = this.props;

    return (
      <div style={inlineCss.container}>
        <PillButton
          label="Save"
          backgroundColor="#52AF52"
          textColor="#FFF"
          action={this.props.save}
          showLoadIcon={saving}
          width={70}
          disabled={saving || !dirty}
        />
        <PillButton disabled={saving} label="Back" action={this.onCancel} />
      </div>
    );
  }
}

UserDetailMenu.propTypes = {
  save: PropTypes.func,
  saving: PropTypes.bool
};

UserDetailMenu.defaultProps = {
  save: () => {},
  saving: false
};
