import PropTypes from "prop-types";
import React, { Component } from "react";
import CONSTANTS from "./modal-constants";
import { connect } from "react-redux";

import AddNewPageModal from "../Editor/containers/add-new-page-modal";
import PageSettingsModal from "../Editor/containers/page-settings-modal";
import SiteSettingsModal from "../Editor/containers/site-settings-modal";
import { GalleryModal } from "../Editor/containers/GalleryModal";
import ImageEditor from "../Editor/containers/image-editor";
import SaveModuleModal from "../Editor/containers/save-module-modal";
//import { HelpForm } from "./modals/HelpFormModalContainer";

import * as actions from "../Editor/actions/editorActions";

let currentModal;

class ModalConductor extends Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (currentModal === nextProps.currentModal && typeof currentModal !== "undefined") {
      return false;
    }
    currentModal = nextProps.currentModal;
    return true;
  }

  render() {
    let { currentModal } = this.props;

    switch (currentModal) {
      case CONSTANTS.MODALS.HELP_FORM:
        //return <HelpForm />;
        return null;
        break;

      case CONSTANTS.MODALS.IMAGE_GALLERY:
        return <GalleryModal />;
        break;

      case CONSTANTS.MODALS.IMAGE_EDITOR:
        return <ImageEditor />;
        break;

      case CONSTANTS.MODALS.ADD_PAGE:
        return <AddNewPageModal {...this.props} />;
        break;

      case CONSTANTS.MODALS.PAGE_SETTINGS:
        return <PageSettingsModal {...this.props} />;
        break;

      case CONSTANTS.MODALS.SITE_SETTINGS:
        return <SiteSettingsModal {...this.props} />;
        break;

      case CONSTANTS.MODALS.SAVE_MODULE:
        return <SaveModuleModal {...this.props} />;
        break;

      default:
        return null;
        break;
    }
  }
}

ModalConductor.contextTypes = {
  store: PropTypes.object,
};

export default connect(
  state => {
    if (state.get("editor").editorReducer.get("currentModal")) {
      return state.get("editor").editorReducer.toJS();
    }
    return {};
  },
  () => actions
)(ModalConductor);
