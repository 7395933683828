import React from 'react';
import Component from 'source/rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';
import ReactHTMLParser from '@convertly/react-html-parser';
import _ from 'lodash';
import {clientImage} from '../../../utils/generateImage';
import {frontloadConnect} from '@convertly/react-frontload';
import generateId from '../../../utils/generate-id';
import axios from 'axios';
import {buildProductPricingObject} from "../../../utils/ecomm-utils/build-product-pricing-object";



//Default Props
//import defaultData from '';
const defaultData = {};
import defaultGlobal from 'source/themes/global';

//Utils
import ConvertToCurrency from '../../../utils/ecomm-utils/convert-to-currency-ii';
import InfernoHTMLStripper from '../../../utils/inferno-html-stripper';
import AddToCartStepperWidget
  from '../../../ecommerce-common-components/buying-options/add-to-cart-stepper/add-to-cart-qty-stepper';
import QuickBuyInferno from '../../../ecommerce-common-components/buying-options/quick-buy/quick-buy';

//SVGS
import PlusIconInferno from '../../../svg/plus-icon/plus-icon';
import MinusIconInferno from '../../../svg/minus-icon/minus-icon';
import ArrowHeadOpenRightInferno from '../../../svg/arrows/arrow-head-open-right-stateless';
import BubbleVariantContainerInferno
  from '../../../ecommerce-common-components/product-variant-widgets/bubble-variant-container/bubble-variant-container';
import BubbleInferno from '../../../ecommerce-common-components/product-variant-widgets/bubble/bubble';
import VariantProgressBarInferno
  from '../../../ecommerce-common-components/product-variant-widgets/product-variant-progress-bar/product-variant-progress-bar';
import BuyingOptionsWrapperInferno
  from '../../../ecommerce-common-components/buying-options/buying-options-wrapper/buying-options-wrapper';

//Styles
import Styles from './product-detail-page-styles';

//Components
import Elements from 'source/elements';
import ProductImageGalleryInferno
  from '../../../ecommerce-common-components/product-image-gallery/product-image-gallery-2/product-image-gallery';
import ImageThumbNailInferno
  from '../../../ecommerce-common-components/product-image-gallery/product-image-gallery-2/img-thumbnail';
import PDPContentInferno from './components/pdp-content-inferno.js';
import pdpWrapper from './components/pdp-wrapper-inferno';
import {deeplog} from "../../../utils/deeplog";


//Utils
const convertToCurrency = ConvertToCurrency();
const PlusIcon = PlusIconInferno();
const MinusIcon = MinusIconInferno();
const ArrowHeadOpenRight = ArrowHeadOpenRightInferno();

const noop = (e) => {
  if (typeof e.preventDefault === 'function') {
    e.preventDefault();
  }
};

//Components
const AddToCartStepper = AddToCartStepperWidget(noop, MinusIcon, PlusIcon);
const QuickBuy = QuickBuyInferno(noop);
const VariantProgressBar = VariantProgressBarInferno(noop);
const Bubble = BubbleInferno(noop);
const BubbleVariantContainer = BubbleVariantContainerInferno(noop, VariantProgressBar, Bubble);

const stripHtml = InfernoHTMLStripper();


export default class ProductDetailPage extends Component {

  constructor(props) {
    super(props);

    this.state = {}

  }


  getProduct() {
    const query = `query ($id: ID!) {
            Product(id:$id) {
                inStock
                id
                title
                description
                sku
                price
                salePrice
                discountType
                discountValue
                image
                images { src, alt }
                primaryImage
                onSale
                categoryMeta { categoryId, categoryName }
                productVariants {
                    id, variations { name, value }, price, salePrice, onSale, sku, qtyInStock, image {
                        src
                        alt
                    }
                }
                variationTypeMeta
            }
        }`;
    const local = false;

    const url = local ? 'http://localhost:4010' : 'https://g.convertly.com';

    const variables = {id: this.props.data.productId};

    const params = {
      url: url,
      method: 'post',
      data: {
        query: query,
        variables: variables
      },
      headers: {
        Authorization: 'convertly-front-load',
      }
    };


    axios(params)

      .then((res) => {

        const {Product} = res.data;
        this.setState({product: Product});

      })
      .catch((err) => {
        this.deeplog(err, 'Axios error');
        this.deeplog(err.message, 'Axios error');
        //resolve();
      });

  }

  componentWillMount() {
      if(this.props.inEditor) {
        this.getProduct()
      }

  }

  processProductDetails(product) {
    //Build pricing object
    let pricing = product.pricing ? product.pricing : buildProductPricingObject(product);

    //parse variation meta and prepare for inferno pass
    let variationTypeMeta = product.variationTypeMeta;
    if(variationTypeMeta) {
      if(typeof variationTypeMeta !== "object") {
        variationTypeMeta = JSON.parse(variationTypeMeta);
      }

      variationTypeMeta.map((variant, index) => {
        if(variationTypeMeta[index].values) {
          variationTypeMeta[index].values.map((value, i) => {

            variationTypeMeta[index].values[i].name = value.name;

          })
        }
      });
    }

    //Prepare product variants for inferno pass
    let productVariants;
    if(product.productVariants) {
      productVariants = product.productVariants.map((item, index) => {
        item.qtyInStock = item.qtyInStock || item.qtyInStock === 0 ? item.qtyInStock : product.quantity_in_stock;
        item.onSale = item.onSale !== undefined ? item.onSale : product.pricing ? product.pricing.onSale : product.onSale;
        item.salePrice = item.salePrice || item.salePrice === 0 ? item.salePrice :  product.pricing ? product.pricing.salePrice : product.salePrice;
        item.price = item.price || item.price > 0 ? item.price : product.pricing ? product.pricing.price : product.price;
        item.variations.map((value, index) => {
          item.variations[index].name = value.name;
        });
        return item
      });
    }



    return {
      id: product.id,
      pricing,
      price: product.pricing ? product.pricing.price : product.price,
      salePrice:  product.pricing ? product.pricing.salePrice : product.salePrice,
      title: product.title ? product.title : null,
      featuredImage:product.images[0],
      onSale:  product.pricing ? product.pricing.onSale : product.onSale,
      description:product.description ? product.description : null,
      subtitle: product.subTitle ? product.subTitle : null,
      long_description: product.long_description ? product.long_description : null,
      images: product.images,
      variationTypeMeta,
      productVariants,
      inStock: product.inStock ? product.inStock : product.quantity_in_stock ? product.quantity_in_stock > 0 : false
    };

  }

  render() {

    this.styles = Styles(this.props.global, this.props.data, this.props.inEditor);

    let product = this.state.product || this.props.data;
    deeplog(Object.keys(product).sort(), "BEACH HOUSE::::")
    if(!product.title) {
      return (
        <div className={css(this.styles.placeholderContent)}>
          <p>Product details will go here.  If you wish to add additional content to the page, please use the editor as usual</p>
        </div>
      )
    }

    let _this = this;
    let renderedPDP;

    delete this.props.data.global;

    Object.keys(_this.props.data).map(function (key, index) {

      if (key !== "global") {
        product[key] = _this.props.data[key]
      }

    });





    let productToPassToInferno = this.processProductDetails(product);
    let stringifiedProduct = JSON.stringify(productToPassToInferno);


    stringifiedProduct = stringifiedProduct.replace(/(?:\r\n|\r|\n)/g, '<br/>');

    let imageUrls = {
      thumbnail: clientImage("", {width: '61', height: '61', crop: 'fit'}),
      featureImage: clientImage("", {width: '555', height: '300', crop: 'fit'}),
      globalImg: clientImage("B317D7A25DF5B-", {width: '555', height: '300', crop: 'fit'})
    };

    imageUrls = JSON.stringify(imageUrls);

    const BuyingOptionsWrapper = BuyingOptionsWrapperInferno(noop, AddToCartStepper, QuickBuy);

      const ImageThumbNail = ImageThumbNailInferno({}, JSON.parse(imageUrls));
      const ProductImageGallery = ProductImageGalleryInferno({}, '', ImageThumbNail, JSON.parse(imageUrls));

    const PDPContent = PDPContentInferno(
      React,
      null,
      null,
      convertToCurrency,
        ProductImageGallery,
      AddToCartStepper,
      BubbleInferno,
      BubbleVariantContainer,
      VariantProgressBarInferno,
      BuyingOptionsWrapper,
      stripHtml,
      ArrowHeadOpenRight,
    );

    return (
      <div className={css(this.styles.productDetailPage)}
           {...this.generateDataEditorAttribute()}
           {...generateId(this.props.elementId)}
           onClick={(e) => {
             _this.launchElementalEditor('ProductDetailPage', e);
           }}
           onMouseOver={(e) => {
             _this.addEditableOutline(e)
           }}
           onMouseOut={(e) => {
             _this.removeEditableOutline(e)
           }}>
        <Elements.Container>
          {/*<Elements.Row className={ css(this.styles.productrow_wRow) }>*/}
          <div id="meta" data-product={ stringifiedProduct } />
          <div className={css(this.styles.pdpContentInferno)} id={id(pdpWrapper, imageUrls)}>

            <PDPContent data={productToPassToInferno}
                        rootClass={css(this.styles.pdpContentInferno)}
                        rootDiv={null}
                        currentQty={1}
                        selectedProductVariants={[]}
                        activeProductVariants={null}
                        variationTypeMeta={productToPassToInferno.variationTypeMeta}
                        outOfStockVariations={[]}
                        featureImage={productToPassToInferno.images[0]}
                        disableAddToCart={false}
                        imgArr={productToPassToInferno.images}
                        activeImgIndex={0}
                        openMobileOptions={false}
                        handleProductVariantArray={noop}
                        updateVariationTypeMeta={noop}
                        checkProductOptionSelection={noop}
                        displayBuyOptions={false}
                        enableAddToCart={false}
                        toggleMobileOptions={noop}
                        goBackToPreviousPage={ noop }
                        renderProductVariantWidget={this.renderProductVariantWidget}
                        resetButtonState={noop}
                        increaseQuantity={noop}
                        decreaseQuantity={noop}
                        updateFeatureImage={noop}
                        addToCart={noop}/>

          </div>
          {/*</Elements.Row>*/}
          <div className={css(this.styles.descontainer)}>
            <p className={css(this.styles.bodytext)}>{ReactHTMLParser(productToPassToInferno.long_description)}</p>
          </div>
        </Elements.Container>
      </div>
    );
  }
}


ProductDetailPage.defaultProps = {
  data: defaultData,
  global: defaultGlobal
};
