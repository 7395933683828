import  colorAssign  from '../colorAssign';

let defaultGlobal = {
    colors: {
        dark: {
            color1: '#2d343d',
            color2: '#4a5663',
            color3: '#6f7e90',
            black: '#000'
        },
        accent: {
            color1: '#39618f',
            color2: '#2978d4',
            color3: '#2979ff'
        },
        light: {
            color1: '#fbfcff',
            color2: '#b4bbc3',
            color3: '#98a0a9',
            white: "#fff"
        }        
    },
    
    
    textColors: {
        light: {},
        dark: {},
        accent: {}
    },
    backgroundColors: {
        light: {},
        dark: {},
        accent: {}
    },
     // Module Default wrapper
    moduleDefault: {
        margin:'50px 0',
        padding:'50px 20px',
    },
        themeBorder: {
        border: '3px solid',
    },
     /** Misc Styles */
    border: '2px solid',
    figCaption: {
        position:'absolute',
        bottom:'5px',
        background:'rgba(0, 0, 0, .5)',
        width:'100%',
        padding:'30px 20px',
        color:'#fff',
        "@media (max-width: 479px)": {
            padding:'10px'
        }
    },
    bodyBackgroundColor: '',
    /** Typography **/
    fontFamily: {
        primaryFont: " 'Libre Baskerville', serif",
        secondaryFont: " 'Rubik', sans-serif"
    },
    fonts: [ 'Libre Baskerville', 'Rubik' ],
    h1: {
       
        fontSize: "65px",
        margin:'20px 0',
        lineHeight: "75px",
        "@media (max-width: 767px)": {
            fontSize: "55px",
            lineHeight: "65px"
        },
        "@media (max-width: 479px)": {
            fontSize: "45px",
            lineHeight: "55px"
        }
    },
    h2: {
  
        margin:'20px 0',
        fontSize: '36px',
        lineHeight: '46px',
    },
    h3: {
  
        lineHeigth:"34px",
        color:'#4a5663',
    },
    h4: {
   
        fontSize: '16px',
        lineHeight: '26px',
    },
    h5: {
    
        fontSize: '16px',
        lineHeight:'26px',
    },
    h6: {
    
        fontSize:'12px',
        lineHeight:'22px',
    },
    p: {
     
        margin: '10px 0',
        fontSize: '16px',
        lineHeight: '26px',
    },
    list: {
       
        marginBottom: '20px',
        paddingLeft: '30px'
    },
    listItem: {
        marginBottom:'10px'
    },
    // Buttons
    button1: {
        display: 'inline-block',
        marginBottom: '15px',
        padding: '10px 25px',
        borderRadius: '3px',
        border:'none',
        fontSize:'14px'
    },
    button2: {
        display: 'inline-block',
        marginBottom: '15px',
        padding: '10px 25px',
        border:'3px solid',
        borderRadius: '3px',
        fontSize:'14px'
    },
    button3: {
        display: 'inline-block',
        marginBottom: '15px',
        padding: '20px 50px',
        borderRadius: '3px',
        fontSize:'14px'
    },
    // Form
    form: {
        height: 'auto',
        padding: "30px",
        width: '100%'
    },
    formErrorMessage: {
        color: "red",
        fontSize:'12px'
    },
    input: {
        height: "38px",
        width: '100%',      
        "::placeholder": {},
        fontSize: '16px',
        lineHeight: '16px',
        border: "none",
        borderRadius: '2px',
        padding: "8px 12px",
        marginBottom: '10px'   
    },
    selectInput: {
        borderRadius: '2px',
        width: '100%',
        height: "40px", 
        opactiy: "0",
        appearance: 'none',
        padding: "10px"
    },
    svgDiv: {    
        height: '40px'
    },
    textArea: {
        width: '100%',
        "::placeholder": {},
        fontSize: '16px',
        lineHeight: '16px',
        border: "none",
        borderRadius: '2px',
        padding: "8px 12px",
        marginBottom: '10px'
    },
    checkboxInput:{
        marginRight: "5px",
        marginLeft: "5px",
    },
    checkboxLabel:{},
    radioInput:{
       marginRight: "5px",
       marginLeft: "5px",
    },
    radioLabel:{},
    submitButton:{
        fontWeight: "700",
        border: "none",
        padding: "10px 25px"
    },
    label: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "5px"
    }
};

export default function theme ( global ) {

    global = global ? global : defaultGlobal;

    colorAssign( global );

    //Body Background Color
    global.bodyBackgroundColor = global.backgroundColors.light.white;
    
    // Module Default Wrapper Styles
    global.moduleDefault.fontFamily = global.fontFamily.primaryFont;

     // Theme Border 
    global.themeBorder.borderColor = global.backgroundColors.light.color2

    // Form Styles
    global.form.backgroundColor = global.backgroundColors.light.color2;

    global.input.backgroundColor = global.backgroundColors.light.white;
    global.input.borderColor = global.backgroundColors.light.color3;
    global.input.color = global.textColors.dark.color1;
    global.input.fontFamily = global.fontFamily.primaryFont;
    global.input["::placeholder"].color = global.textColors.light.color3;
    global.input["::placeholder"].fontFamily = global.fontFamily.primaryFont;

    global.textArea.backgroundColor = global.backgroundColors.light.white;
    global.textArea.borderColor = global.backgroundColors.light.color3;
    global.textArea.color = global.textColors.dark.color1;
    global.textArea.fontFamily = global.fontFamily.primaryFont;
    global.textArea["::placeholder"].color = global.textColors.light.color3;
    global.textArea["::placeholder"].fontFamily = global.fontFamily.primaryFont;


    global.selectInput.backgroundColor = global.backgroundColors.light.white;
    global.selectInput.borderColor = global.backgroundColors.light.color3;
    global.selectInput.color = global.textColors.dark.color3;
    global.selectInput.fontFamily = global.fontFamily.primaryFont;

    global.svgDiv.backgroundColor = global.backgroundColors.light.white;

    global.checkboxLabel.color = global.textColors.dark.color3;
    global.checkboxLabel.fontFamily = global.fontFamily.primaryFont;
    
    global.radioLabel.color = global.textColors.dark.color3;
    global.radioLabel.fontFamily = global.fontFamily.primaryFont;

    global.submitButton.color = global.textColors.light.white;
    global.submitButton.backgroundColor = global.backgroundColors.dark.color2;
    global.submitButton.fontFamily = global.fontFamily.primaryFont;

    global.label.color = global.textColors.dark.color1;
    global.label.fontFamily = global.fontFamily.primaryFont;

    // typography Styles
    global.h1.color = global.textColors.dark.color1;
    global.h2.color = global.textColors.dark.color1;
    global.h3.color = global.textColors.dark.color1;
    global.h4.color = global.textColors.dark.color3;
    global.h5.color = global.textColors.dark.color3;
    global.h6.color = global.textColors.dark.color3;
    global.p.color = global.textColors.dark.color3;
    global.list.color = global.textColors.dark.color3;

    global.h1.fontFamily = global.fontFamily.primaryFont;
    global.h2.fontFamily = global.fontFamily.primaryFont;
    global.h3.fontFamily = global.fontFamily.primaryFont;
    global.h4.fontFamily = global.fontFamily.primaryFont;
    global.h5.fontFamily = global.fontFamily.secondaryFont;
    global.h6.fontFamily = global.fontFamily.primaryFont;
    global.p.fontFamily = global.fontFamily.primaryFont;
    global.list.fontFamily = global.fontFamily.primaryFont;



    //Button Styles
    const defaultButtonStyles = {
        background: global.backgroundColors.dark.color2,
        color: global.textColors.light.white,
        borderColor: global.backgroundColors.dark.color1,
        fontFamily: global.fontFamily.primaryFont
    };
    const alternateButtonStyles = {
        background: global.backgroundColors.accent.color3,
        textColor: global.textColors.light.white,
        fontFamily: global.fontFamily.primaryFont,
        borderColor: global.backgroundColors.accent.color3
    };

    global.button1 = {
        ...global.button1,
        ...defaultButtonStyles
    };

    global.button1A = {
        ...global.button1,
        ...alternateButtonStyles
    };

    global.button2 = {
        ...global.button2,
        ...defaultButtonStyles
    };

    global.button2A = {
        ...global.button2,
        ...alternateButtonStyles
    };

    global.button3 = {
        ...global.button3,
        ...defaultButtonStyles
    };

    global.button3A = {
        ...global.button3,
        ...alternateButtonStyles
    };

    return global;

}
