/**
 * Splits menu items a 3d array for ui purposes
 * first layer is the number of rows,
 * second layer is the number of column
 * @param data
 */

export const generateColumnArray = ( data ) => {

    let rowArray = [];

    let columnArray = [];


    data.menuItems.forEach( (item, index) => {

        if( (index + 1) % 4 === 0 && index !== 0) {

            columnArray.push( item );
            rowArray.push( columnArray );
            columnArray = [];

        } else {

            columnArray.push( item );

        }

    });

    rowArray.push( columnArray );

    return rowArray;

};