/* eslint-disable */
//import $ from 'jquery';
import { StyleSheet } from "@convertly/css";
//"use strict"

const toggleScript = ( id ) => {

    return ( id ) => {

        let toggleButton = $('.toggleButton_' + id),
            arrow        = $('.arrow_' + id),
            content      = $('.accordion_' + id + ' > li > div'),
            toggleContent = $('.toggleArea'),
            allSVG       = $('svg', '#' + id);
        toggleButton.on("click", function(e) {

            let currentSVG = $(this).find('svg');


            // if the content area for the item clicked on is open, close it

            if ($(this).next().hasClass('open')) {

                content.removeClass('open');
                $(currentSVG).removeClass('arrowUp');


            // if it isn't open, close the others then open this one

            } else {

                toggleContent.removeClass('open');
                allSVG.removeClass('arrowUp');
                $(this).next().toggleClass('open');
                currentSVG.addClass('arrowUp');

                // $(this).children().addClass('arrowUp');

            }

                // $('.arrowUp').removeClass('arrowUp');
                // if ( $(this).next().css('display') === 'block') {
                //     $(this).next().slideUp();
                // } else {
                //     $('.accordion > li > div').slideUp();
                //     $(this).next().slideDown();
                //     $(this).children().addClass('arrowUp');
                // }

        })

    }

}

export default toggleScript
