import _ from 'lodash';
import { deeplog } from "../utils/deeplog";

export function handleButtonElements( global ) {

    const defaultStylesObj = _.get(global, 'defaultButtonStyles');
    const altStylesObj = _.get(global, 'defaultButtonStyles');

    //Button Styles

    const defaultButtonStyles =
        defaultStylesObj ?
    {
        backgroundColor: defaultStylesObj.background || defaultStylesObj.backgroundColor ||
                    global.colors.accent.color1,
        color: defaultStylesObj.color ||
               global.colors.light.white,
        borderColor: defaultStylesObj.borderColor ||
                     global.colors.accent.color1,
        fontFamily: defaultStylesObj.fontFamily ||
                    global.fontFamily.primaryFont,
    } :
    {
        color: global.colors.light.white,
        borderColor: global.colors.accent.color1,
        fontFamily: global.fontFamily.primaryFont,
    };

    global.button1 = {
        ...defaultButtonStyles,
        ...global.button1,
    };


    global.button2 = {
        ...defaultButtonStyles,
        ...global.button2,
    };


    global.button3 = {
        ...defaultButtonStyles,
        ...global.button3
    };

    global.submitButton = {
        ...defaultButtonStyles,
        ...global.submitButton,
    };


    return global

}
