import { StyleSheet } from "@convertly/css";
import UserOverrides from "../../elements/style-functions/user-overrides";
import removeNestedUserOverrides from "../../site-header/site-header/utils/removeNestedUserOverrides";
import _ from "lodash";
import { generatePseudoMediaQuery } from "../../elements/style-functions/pseudo-media-queries";

export const BlogPostPreviewStyles = ({ global, data, inEditor, numOfColumns }) => {
  const blogPostPreview = removeNestedUserOverrides({ data }),
        { userOverrides } = data || {},
        { blogPostPreviewWrapper } = userOverrides || {};

  let blogPostPreviewWidth = {
    width: "100%"
  };

  switch (numOfColumns) {
    case 2:
      blogPostPreviewWidth = {
        width: "49%",
        ...generatePseudoMediaQuery(
          "@media(max-width: 767px)",
          ["mobile", "smallTablet"],
          {
            width: "100%"
          },
          inEditor
        )
      };
      break;
    case 3:
      blogPostPreviewWidth = {
        width: "32%",
        ...generatePseudoMediaQuery(
          "@media(max-width: 767px)",
          ["mobile", "smallTablet"],
          {
            width: "49%"
          },
          inEditor
        ),
        ...generatePseudoMediaQuery(
          "@media(max-width: 495px)",
          ["mobile"],
          {
            width: "100%"
          },
          inEditor
        )
      };
      break;
    case 4:
      blogPostPreviewWidth = {
        width: "24%",
        ...generatePseudoMediaQuery(
          "@media(max-width: 991px)",
          ["mobile", "smallTablet", "largeTablet"],
          {
            width: "32%"
          },
          inEditor
        ),
        ...generatePseudoMediaQuery(
          "@media(max-width: 767px)",
          ["mobile", "smallTablet"],
          {
            width: "49%"
          },
          inEditor
        ),
        ...generatePseudoMediaQuery(
          "@media(max-width: 495px)",
          ["mobile"],
          {
            width: "100%"
          },
          inEditor
        )
      };
      break;
    default:
      break;
  }

  return StyleSheet.create({
    blogPostPreviewWrapper: {
      justifyContent: "space-between",
      flexDirection: "row",
      flexWrap: "wrap",
      display: "flex",
      ...UserOverrides(global, blogPostPreviewWrapper, inEditor)
    },
    blogPostPreview: {
      ...blogPostPreviewWidth,
      ...UserOverrides(global, { userOverrides: blogPostPreview }, inEditor)
    }
  });
};
