import React, { Component } from "react";
import { css } from "@convertly/css";
import styles from "../../Editor/components/editor/editor-forms-styles";
import Icon from "../../../components/icons/add-page-thumbnail";

class AddPagePlaceHolder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null
    };
  }

  render() {
    const pageTemplates = [
      {
        name: "Category 01",
        imageUrl: "https://s3.amazonaws.com/convertlyimguploadeast/convertly/add-page-thumbnail.svg"
      },
      {
        name: "Category 02",
        imageUrl: "https://s3.amazonaws.com/convertlyimguploadeast/convertly/add-page-thumbnail.svg"
      },
      {
        name: "Category 03",
        imageUrl: "https://s3.amazonaws.com/convertlyimguploadeast/convertly/add-page-thumbnail.svg"
      },
      {
        name: "Category 04",
        imageUrl: "https://s3.amazonaws.com/convertlyimguploadeast/convertly/add-page-thumbnail.svg"
      },
      {
        name: "Category 05",
        imageUrl: "https://s3.amazonaws.com/convertlyimguploadeast/convertly/add-page-thumbnail.svg"
      },
      {
        name: "about us",
        imageUrl: "https://s3.amazonaws.com/convertlyimguploadeast/convertly/add-page-thumbnail.svg"
      }
    ];

    return (
      <div className={css(styles.modalCollectionContainer)}>
        {pageTemplates.map((pageItem, index) => {
          const selectedClass =
            this.state.selected === index ? styles.activePageTemplatePlaceholderImg : "";

          return (
            <div className={css(styles.newPageCollectionItem)} key={`newPageModal${index}`}>
              <Icon
                svgClassName={selectedClass}
                onClick={() => this.setState({ selected: index })}
                className={css(styles.pageTemplatePlaceholderImg)}
              />

              <div className={css(styles.newPageName)}>{pageItem.name}</div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default AddPagePlaceHolder;
