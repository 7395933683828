import React from 'react';
import PropTypes from 'prop-types';
import Component from '../../rootComponent';
import {css} from "@convertly/css";
import defaultGlobal from '../../themes/global.js';
import ReactHtmlParser from '@convertly/react-html-parser';

//Styles
import moduleWrapperStyles from './list-element-styles';

//Utils
import generateId from '../../utils/generate-id';
import { clientImage } from '../../utils/generateImage';
import Elements from '../../elements/index';

//Components
import Arrow from '../svg/arrow-bullet';

/**
 * Class controls imports style functions that allows for user to override the css in the CMS editor for their site.
 **/
class ListElement extends Component {

    constructor(props) {

        super(props);
        this.state = {
            elementEditableBorder: ""
        };
        this.removeWrapperOutline = this.removeWrapperOutline.bind(this);
        this.addWrapperOutline = this.addWrapperOutline.bind(this);

    }

    removeWrapperOutline(e) {
        if (!this.context.store.getState().get('editor').editorReducer.get('activeModuleId')) {
            this.setState({
                elementEditableBorder: ""
            });
            e.hoverOverChild = false;
        }
        else if (this.context.store.getState().get('editor').editorReducer.get('editorType').get('field') !== this.props.id) {
            this.setState({
                elementEditableBorder: ""
            });
            e.hoverOverChild = false;
        }
    }

    /**
     * Removes the style that outlines element
     **/
    addWrapperOutline(e) {
        if (!e.hoverOverChild) {
            if (this.state.elementEditableBorder === '6px dashed #F5A623')
                this.setState({
                    elementEditableBorder: "6px dashed #F5A623 "
                });
            else
                this.setState({
                    elementEditableBorder: "6px dashed #F5A623"
                });

        }

    }

    render() {
        /**
         * Calls stylesheet function
         **/
        this.styles = moduleWrapperStyles(this.props.global, this.props.data);
        let elementEditableBorder = {};
        if (!this.props.notEditable) {
            if (this.state.inEditor) {
                elementEditableBorder.outline = this.state.elementEditableBorder;
            }
        }


        let className;

        className = css( this.styles.listItem );





        return (
            <div
                { ...this.generateDataEditorAttribute() }
                onClick={ (e) => {
                    this.launchElementalEditor('background', e);
                }  }
                onMouseOver={ this.addWrapperOutline }
                onMouseOut={ this.removeWrapperOutline }
                className={ className }
                style={ elementEditableBorder }
                { ...generateId(this.props.divId) }
            >
            <Arrow  className={ css(this.styles.arrowSvg ) } />

            <div className={ css(this.styles.text) } >
                {  ReactHtmlParser( this.props.data.text ) }
            </div>
            

            </div>

        );


    }
}


/**
 * Default Props for rendering when no data is being passed
 **/
ListElement.defaultProps = {
    data: {},
    global: defaultGlobal
};

/**
 * Connects element to context for cms editor
 **/
ListElement.contextTypes = {
    store: PropTypes.object
};

export default ListElement;

/**
 Component BoilerPlate

 <Elements.ModuleWrapper
 parentClassName={ this.styles.heroSection }
 data={ this.props.data }
 global={ this.props.global }
 elementDataName="backgroundColor"
 moduleId={ this.props.id }
 moduleSort={ this.props.sort }>

 **/
