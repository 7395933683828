import React from 'react';
import {StyleSheet, css} from "@convertly/css";
import Component from '../../rootComponent';

import ReactHTMLParser from "@convertly/react-html-parser";

//Styles
import sliderContainerStyles from './slider-container-styles';

export default class
Slide01 extends Component {

    constructor(props) {

        super(props);

    };

    render() {

        this.styles = sliderContainerStyles(this.props.global, this.props.data);

        function renderSlides(props) {
            return React.Children.map(props.children, child => {
                return React.cloneElement(child, {
                    moduleId: props.moduleId,
                    moduleSort: props.moduleSort,
                    sliderSettings: props.sliderSettings,
                    activeSlide:props.activeSlide,
                    inEditor:props.inEditor
                });
            })
        }
        const inlineStyle = this.generateStyleAttribute( );

        return (

            <ul
                className={ css( this.styles.sliderContainer ) + " slides"}
                data-timer={ this.props.sliderSettings.timer }
                data-effect={ this.props.sliderSettings.transition }
                data-hoverpause={ this.props.sliderSettings.hoverpause }
                {...inlineStyle }
                 { ...this.generateDataEditorAttribute() }
                onClick={(e) => {
                                    this.launchElementalEditor('ConvertlySlide', e);
                                }}
                onMouseOver={ (e) => {
                                    this.addEditableOutline(e)
                                } }
                                onMouseOut={ (e) => {
                                    this.removeEditableOutline(e)
                                } }
                data-autoscroll={ this.props.sliderSettings.autoScroll }>

                { renderSlides( this.props ) }

            </ul>

        )
    }
}