import React from 'react';
import Component from 'source/rootComponent';
import {css} from "@convertly/css";


export default class LocationSelector extends Component {

    constructor(props) {

        super(props);

    }

    render() {

        const { styles, location, index, rootId, selectStore, shortAddress } = this.props;

        return (
            <div className={ css(styles.location) }
                 onClick={ ()=> { selectStore(index) } }
                 data-target={ index + '_' + rootId } >

                <span data-target={ index + '_' + rootId } className={css(styles.locationName)}    > { location.city }    </span>
                <span data-target={ index + '_' + rootId } className={css(styles.locationAddress)} > { shortAddress } </span>

            </div>
        )
    }
}
