/*global Inferno, $, React */
import React from 'react';

const Script = (imageUrls) => {
  return ({ rootClass, numOfColumns, }) => {
    let arrayOfSkeletonProductItems = [];

    let generateSkeletonProductItem = (i) => {
    	return(
      <div
        className={ `${ rootClass } skeleton-product-grid-item col-${ numOfColumns }-styles` }
        key={ `skeleton-product-grid-item-${ i }` }>
        <div className={ `${ rootClass } skeleton-product-grid-image-container` }>
          <img
            className={ `${ rootClass } skeleton-product-grid-image col-${ numOfColumns }-styles` }
            src={ `${imageUrls.globalImg}convertly-product-placeholder.jpg `}/>
        </div>
        <div className={ `${ rootClass } skeleton-product-grid-description-container col-${ numOfColumns }-styles` }>
          <div className={ `${ rootClass } skeleton-product-grid-description-wrapper` }>
            <div className={ `${ rootClass } skeleton-product-grid-title col-${ numOfColumns }-styles` }/>
            <div className={ `${ rootClass } skeleton-product-grid-description col-${ numOfColumns }-styles` }/>
          </div>
        </div>
      </div>
    )};

    for (let i = 0; i < numOfColumns * 3; i++) {
      arrayOfSkeletonProductItems.push(generateSkeletonProductItem(i));
    }

    return (
      <div className={ `container` }>
        <div className={ `${ rootClass } skeleton-product-filter-container col-${ numOfColumns }-styles` }>
          <div className={ `${ rootClass } skeleton-product-filter` }>
            <div className={ `${ rootClass } skeleton-product-item` }>
              <div className={ `${ rootClass } skeleton-product-title` }/>
              <div className={ `${ rootClass } skeleton-product-description` }/>
            </div>
            <div className={ `${ rootClass } skeleton-product-item` }>
              <div className={ `${ rootClass } skeleton-product-title` }/>
              <div className={ `${ rootClass } skeleton-product-description` }/>
            </div>
            <div className={ `${ rootClass } skeleton-product-item` }>
              <div className={ `${ rootClass } skeleton-product-title` }/>
              <div className={ `${ rootClass } skeleton-product-description` }/>
            </div>
            <div className={ `${ rootClass } skeleton-product-item` }>
              <div className={ `${ rootClass } skeleton-product-title` }/>
              <div className={ `${ rootClass } skeleton-product-description` }/>
            </div>
          </div>
          <div className={ `${ rootClass } skeleton-product-filter` }>
            <div className={ `${ rootClass } skeleton-product-item` }>
              <div className={ `${ rootClass } skeleton-product-title` }/>
              <div className={ `${ rootClass } skeleton-product-description` }/>
            </div>
            <div className={ `${ rootClass } skeleton-product-item` }>
              <div className={ `${ rootClass } skeleton-product-title` }/>
              <div className={ `${ rootClass } skeleton-product-description` }/>
            </div>
            <div className={ `${ rootClass } skeleton-product-item` }>
              <div className={ `${ rootClass } skeleton-product-title` }/>
              <div className={ `${ rootClass } skeleton-product-description` }/>
            </div>
            <div className={ `${ rootClass } skeleton-product-item` }>
              <div className={ `${ rootClass } skeleton-product-title` }/>
              <div className={ `${ rootClass } skeleton-product-description` }/>
            </div>
          </div>
        </div>
        <div className={ `${ rootClass } skeleton-product-grid-pagination-container` }>
          <div className={ `${ rootClass } skeleton-pagination-container` }>
            <div className={ `${ rootClass } skeleton-pagination` }>
              <div className={ `${ rootClass } skeleton-pagination-title` }/>
              <div className={ `${ rootClass } skeleton-pagination-description` }/>
            </div>
          </div>
          <div className={ `${ rootClass } skeleton-product-grid-container` }>
            {
              arrayOfSkeletonProductItems.map(item => item)
            }
          </div>
          {/* <LoadingSpinner rootClass={ rootClass }/> */}
        </div>
      </div>
    )
  }
};

export default Script;
