import {StyleSheet} from '@convertly/css';
import { generatePseudoMediaQuery } from '../../../elements/style-functions/pseudo-media-queries';

const Styles = (global, data, sliderSettings, inEditor) => {

    const arrowContainerStyles = {
        '@media (min-width:991px)': {
            display: 'none'
        },
        display: 'block',
        position: 'absolute',
        background: global.colors.light.color2,
        borderRadius: '100%',
        height: '50px',
        width: '50px',
        top: '0px',
        zIndex: '3'
    };

    const arrow = {
        height: '20px',
        width: '20px',
        paddingTop: "5px"
    };

    return StyleSheet.create({

        convertlyControlNav: {
            margin: '0 0 30px 0',
            textAlign: "center",
            padding: '0',
            listStyle: 'none',
            overflow: 'hidden',
            ":after": {
                content: '//0020',
                display: 'block',
                clear: 'both',
                visibility: 'hidden',
                lineHeight: 0,
                height: 0,
            }

        },
        convertlyControlNavItem: {
            display: 'inline-block',
            cursor: 'pointer',
            fontFamily: global.fontFamily.primaryFont,
            fontSize: '20px',
            position: 'relative',
            width: '250px',
            margin: '20px',
            textAlign: 'center',
            color: global.colors.dark.color1,
            opacity: '.5',
            ...generatePseudoMediaQuery(
              '@media( max-width: 991px )',
              ['largeTablet', 'smallTablet', 'mobile'],
              {
                  opacity: '1',
                  display: 'none',
                  margin: "0 auto",
                  ".active": {
                      height: '45px',
                      display: 'block'
                  }
              },
              inEditor
            ),
            '.active': {
                opacity: '1'
            },
            ':hover': {
                opacity: '1'
            },
        },
        progressBar: {
            height: '5px',
            width: '250px',
            position: 'absolute',
            bottom: '-15px',
            background: global.colors.dark.color1,
            opacity: .3,
            ...generatePseudoMediaQuery(
              "@media (max-width:991px)",
              ['largeTablet', 'smallTablet', 'mobile'],
              {
                  bottom: '0px',
              },
              inEditor
            )
        },
        progress: {
            position: 'absolute',
            bottom: '-15px',
            height: '5px',
            opacity: 1,
            background: global.colors.dark.color1,
            '.active': {
                width: '100%'
            },
            ...generatePseudoMediaQuery(
              "@media (max-width:991px)",
              ['largeTablet', 'smallTablet', 'mobile'],
              {
                  bottom: '0px',
              },
              inEditor
            ),
        },
        arrowLeftContainer: {
            ...arrowContainerStyles,
            left: '-24px',
            padding: '12px 0 0px 21px',
        },
        arrowLeft: {
            ...arrow
        },
        arrowRightContainer: {
            ...arrowContainerStyles,
            right: '-27px',
            padding: '12px 11px 0 5px',
        },
        arrowRight: {
            ...arrow
        }

    })

};

export default Styles
