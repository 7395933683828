import React from 'react';
import {StyleSheet, css} from "@convertly/css";
import Component from '../../../rootComponent';
import ReactHTMLParser from "@convertly/react-html-parser";

import {generateId} from '../../../utils/generate-id';

//Styles
import slideStyles from './full-width-hero-slide-styles-ghost';

export default class Slide01 extends Component {

    constructor(props) {

        super(props);

    };

    render() {

        this.styles = slideStyles(this.props.global, this.props.data, this.props.sliderSettings, this.props.inEditor);

        let liClassName;

        if (this.props.data.slideNumber === this.props.activeSlide) {
            liClassName = " active";
        }
        else {
            liClassName = " right";
        }

        function renderChildren(props) {
            return React.Children.map(props.children, child => {
                return React.cloneElement(child, {
                    moduleId: props.moduleId,
                    moduleSort: props.moduleSort
                });
            })
        }

        let overlay = null;

        if (this.props.data.overlay) {
            overlay = <div className={ css(this.styles.overlay) }/>
        }

        const inlineStyle = this.generateStyleAttribute();


        return (


            <li className={ css(this.styles.slide) + liClassName + ' ' + css(this.styles.slide) }
                data-slide={ this.props.data.slideNumber }
                { ...this.generateDataEditorAttribute() }
                {...this.generateCustomAttributes()}
                {...generateId(this.props.elementId)}

                {...inlineStyle}
            >
                <div className={ css(this.styles.slideContent) }>

                    {/** Caption **/}
                    <div className={ css(this.styles.textArea) }>

                        { renderChildren(this.props) }

                    </div>
                </div>
                { overlay }
            </li>
        )
    }
}
