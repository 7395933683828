import { fromJS } from "immutable";
import { createReducer } from "redux-act";

import {
  setLoaded,
  setBusinessInfo,
  setBusinessInfoSnapshot,
  setBusinessInfoLoaded,
} from "../actions/businessInfo";

const initialState = fromJS({
  saving: false,
  businessInfoLoaded: false,
  loading: false,
  businessInfoSnapshot: {},
  businessInfo: {},
  locations: [],
  editLocationIndex: null,
  newSchedule: {},
  showRunTimeModal: false,
  schedules: [],
  editLocation: {},
});

export const businessInfoReducer = createReducer(
  {
    [setBusinessInfoLoaded]: (state, businessInfoLoaded) => state.merge({ businessInfoLoaded }),

    [setBusinessInfo]: (state, businessInfo) => state.merge({ businessInfo }),
    [setBusinessInfoSnapshot]: (state, businessInfoSnapshot) =>
      state.merge({ businessInfoSnapshot }),
  },
  initialState
);
