/*global Inferno, $ */
/* eslint-disable */
import {include} from '@convertly/thor';

const Script = () => {
	
	const Graph = include('../../../../utils/graph', 'Graph');
	const Dispatcher = include('../../../../utils/dispatcher', 'Dispatcher');
	const ConvertToCurrency = include('../../../../utils/ecomm-utils/convert-to-currency-ii', 'ConvertToCurrency');
	const InfernoHTMLStripper = include('../../../../utils/inferno-html-stripper', 'InfernoHTMLStripper');
	const ProductGridItemInferno = include('./product-grid-item', 'ProductGridItemInferno');
	const SortCategoryBarInferno = include('./components/sort-category-bar', "SortCategoryBarInferno");
	
	//Controller
	const ProductVariantController = include('../../../controllers/product-variant-controller/index', "ProductVariantController")
	
	const AddToCartStepperWidget = include('../../../../ecommerce-common-components/add-to-cart-stepper/add-to-cart-qty-stepper', "AddToCartStepperWidget");
	///Components
	const PriceInferno = include('./components/price', "PriceInferno");
	const QuickBuyButtonInferno = include('./components/quick-buy-button', "QuickBuyButtonInferno");
	const AddToCartButtonInferno = include('./components/add-to-cart', "AddToCartButtonInferno");
	const QuickViewModalInferno = include('./components/quick-view-modal', "QuickViewModalInferno");
	const ProductImageGalleryInferno = include('../../../../ecommerce-common-components/product-image-gallery/product-image-gallery', "ProductImageGalleryInferno");
	const ImageThumbNailInferno = include('../../../../ecommerce-common-components/product-image-gallery/img-thumbnail', 'ImageThumbNailInferno');
	const SpinnerSVGInferno = include('../../../../svg/spinner/spinner', 'SpinnerSVGInferno');
	const ArrowDownOpenInferno = include('../../../../svg/arrows/arrow-down-stateless', 'ArrowDownOpenInferno');
  
	//Product Variant Widgets
	const BoxSelectorInferno = include('../../../../ecommerce-common-components/product-variant-widgets/box-selector/box-selector', 'BoxSelectorInferno');
	const ColorPickerInferno = include('../../../../ecommerce-common-components/product-variant-widgets/color-picker/color-picker', 'ColorPickerInferno');
	const DropDownInferno = include('../../../../ecommerce-common-components/product-variant-widgets/drop-down/drop-down', 'DropDownInferno');
	
	
	return (id, productsArr, clientUrl, imageUrl, numOfColumns, numberOfRows, EcommerceSettings, imageUrlsStringified, siteId) => {
		
		
		const imageUrls = JSON.parse(imageUrlsStringified);
		
		//Utils
		const dispatch = Dispatcher(id);
		const infernoHTMLStripper = InfernoHTMLStripper();
		const convertToCurrency = ConvertToCurrency();
		const graph = Graph();
		
		
		
		//inferno components
		const ImageThumbNail = ImageThumbNailInferno(dispatch, imageUrls);
		const ArrowDownOpen = ArrowDownOpenInferno();
		const Spinner = SpinnerSVGInferno();
		const Price = PriceInferno();
		const ColorPicker = ColorPickerInferno(null, dispatch);
		const BoxSelector = BoxSelectorInferno(null, dispatch);
		const DropDown = DropDownInferno(null, ArrowDownOpen);
		const ProductGridItem = ProductGridItemInferno(
			dispatch,
			QuickBuyButtonInferno,
			AddToCartButtonInferno,
			QuickViewModalInferno,
			ProductImageGalleryInferno,
			AddToCartStepperWidget,
			Price,
			clientUrl,
			imageUrl,
			infernoHTMLStripper,
			convertToCurrency,
			ImageThumbNail,
			imageUrls,
			ColorPicker,
			BoxSelector,
			DropDown);
		const SortCategoryBar = SortCategoryBarInferno(
			dispatch,
			QuickBuyButtonInferno,
			AddToCartButtonInferno,
			clientUrl);
		const productVariantController = ProductVariantController(graph, dispatch, ColorPicker, BoxSelector, DropDown);
		
		const hasLocalStorage = $('html').hasClass('localstorage');
		
		const root = document.getElementById(id);
		root.innerHTML = '';
		
		const products = JSON.parse(productsArr);
		
		const ecommerceSettings = JSON.parse(EcommerceSettings);
		
		
		const ProductGallery01 = Inferno.createClass({
			
			getInitialState: function () {
				this.ProductVariantController = new productVariantController(this);
				return {
					products: products,
					renderStage: 1,
					productsRenderArray: [],
					paginationMeta: [],
					pagesLoaded: [],
					loadMoreIndex: 0,
					pages: []
				};
			},
			/** Sorts products - TODO: make able to handle more options */
			sortProducts: function (key, direction) {
				const compare = function (a, b) {
					if (a[key] < b[key]) {
						if (direction === "decending") {
							return -1;
						}
						else {
							return 1
						}
					}
					if (a[key] > b[key]) {
						if (direction === "decending") {
							return 1;
						}
						else {
							return -1
						}
					}
					return 0;
				};
				
				let products =
					this.state.products.sort(compare);
				this.setState({products: products});
				this.splitIntoPaginationArrays();
				
			},
			/** Converts products array into a 3d array
			 allowing to create arrays and columns
			 **/
			splitIntoPaginationArrays: function () {
				let pageArray = [];
				let rowArray = [];
				let productArray = [];
				
				
				this.state.products.map((product, index) => {
					product.description = product.description = product.description.replace(/<<>>/g, "'");
					
					if (( index + 1 ) % numOfColumns === 0) {
						productArray.push(product);
						rowArray.push(productArray);
						productArray = [];
						if (parseInt(index + 1) === parseInt(products.length)) {
							pageArray.push(rowArray);
							rowArray = [];
						}
						else if (parseInt(rowArray.length) === parseInt(numberOfRows)) {
							pageArray.push(rowArray);
							rowArray = [];
						}
					}
					else {
						productArray.push(product);
						if (parseInt(index + 1) === parseInt(products.length)) {
							rowArray.push(productArray);
							pageArray.push(rowArray);
							rowArray = [];
							productArray = [];
						}
					}
					
				});
				
				this.addPaginationMetaData(pageArray);
			},
			/** Creates class names for each page in pagination **/
			addPaginationMetaData: function (productsRenderArray) {
				paginationMeta = this.state.paginationMeta;
				
				productsRenderArray.map((page, index) => {
					
					if (index === 0) {
						paginationMeta.push({
							className: "active",
							launcherClassName: "active"
						})
					}
					else {
						paginationMeta.push({
							className: "page-inactive-right",
							launcherClassName: ""
						})
					}
				});
				this.setState({
					paginationMeta: paginationMeta,
					productsRenderArray: productsRenderArray
				});
				
				this.loadSetOfProducts(0)
				
				
			},
			/** Loads set of products for Load More button - TODO: pagination function**/
			loadSetOfProducts: function (index) {
				let pages = this.state.pages;
				
				pages.push(
					<div className={ `${root.className} page active` }>
						{  /** Creates a new row based on number of columns in row **/
							
							this.state.productsRenderArray[index].map((row) => {
								return (
									<div className="row">
										{  /** Adds products to row **/
											row.map((product) => {
												const pricing = product.pricing;
												const brand = product.manufacture_details.manufacturer;
												const {title} = product;
												const url = {
													linkType: 'internal',
													url: product.slug
												};
												return (
													<ProductGridItem
														product={ product }
														pricing={ pricing }
														brand={ brand }
														title={ title }
														findActiveProductVariants={ this.ProductVariantController.findActiveProductVariants }
														handleProductVariantArray={ this.ProductVariantController.handleProductVariantArray }
														findOutOfStockVariants={ this.ProductVariantController.findOutOfStockVariants }
														updateOutOfStockArray={ this.ProductVariantController.updateOutOfStockArray }
														checkProductOptionSelection={ this.ProductVariantController.checkProductOptionSelection }
														renderProductVariantWidget={ this.ProductVariantController.renderProductVariantWidget }
														columns={ numOfColumns }
														url={ `${clientUrl}${url.url}` }
														imgUrl={ product.images[0] }
														imgUrls={ imageUrls }
														rootDiv={root}/>
												)
											}) }
									</div>
								)
							}) }
					</div>
				);
				
				this.setState({
					pages: pages,
					loadMoreIndex: this.state.loadMoreIndex + 1
				});
				
				
			},
			/** Changes pages on click **/
			changePage: function (i) {
				
				
				paginationMeta = this.state.paginationMeta;
				paginationMeta.map((metaItem, index) => {
					
					if (index == i) {
						metaItem.className = "active";
						metaItem.launcherClassName = "active"
					}
					else if (index > i) {
						metaItem.className = "page-inactive-right";
						metaItem.launcherClassName = "";
					}
					else {
						metaItem.className = "page-inactive-left";
						metaItem.launcherClassName = "";
					}
				});
				
				this.setState({paginationMeta: paginationMeta})
			},
			componentWillMount() {
				this.splitIntoPaginationArrays();
			},
			
			render: function () {
				
				/** Sort/filtration bar **/
				let filtrationBar = null;
				let showFiltrationBar = false;
				if (showFiltrationBar) {
					filtrationBar =
						<SortCategoryBar rootDiv={root} products={ products }/>
				}
				
				let pages = this.state.pages;
				
				let ecommSettings = true;
				
				let displayMoreProductsMethod;
				
				/** pagination **/
				if (ecommerceSettings.displayMoreProductsMethod === "pagination") {
					displayMoreProductsMethod =
						<div className={ `${root.className} paginationIndexContainer` }>
							{  /**creates pagination tabs **/
								this.state.productsRenderArray.map((page, index) => {
									if (this.state.productsRenderArray.length <= 1) {
										return null
									}
									else {
										return (
											<p
												className={ `${root.className} paginationIndex ${this.state.paginationMeta[index].launcherClassName}` }
												onClick={ () => this.loadSetOfProducts(index) }>{ (index + 1) }</p>)
									}
								})
							}
						</div>
				}
				
				/** load more button **/
				
				if (ecommerceSettings.displayMoreProductsMethod === "loadMoreButton") {
					
					if (this.state.loadMoreIndex < this.state.productsRenderArray.length) {
						displayMoreProductsMethod =
							<div className={ `${root.className} load-more-button-container` }>
								<div className={ `${root.className} load-more-button` }
										 onClick={ () => this.loadSetOfProducts(this.state.loadMoreIndex) }>See More
									Products
								</div>
							</div>
					}
				}
				
				return (
					<div>
						<div>
							{ filtrationBar }
						</div>
						
						<div className={ `${root.className} product-grid-container` }>
							{ pages }
						</div>
						{ displayMoreProductsMethod }
					</div>
				)
			}
			
		});
		
		Inferno.render(<ProductGallery01 />, root);
		
	};
	
};

export default Script;
