/*global Inferno, $ */
import React from 'react';
import _ from 'lodash';

const Script = (dispatch, VariantProgressBar, Bubble) => {
    return ({
                rootClass,
                checkProductOptionSelection,
                variationTypeMeta,
                selectedProductVariants,
                handleProductVariantArray,
                updateVariationTypeMeta,
                outOfStockVariations,
                maxBubbleWidth,
                noMaxWidth,
                product,
                inPDP,
            }) => {
        let variationPages = [];

        const renderProductVariantWidget = function (uiType, product, rootClass, index, alternate, quickView) {
            return <Bubble
                rootClass={`${rootClass}${quickView ? ` quick-view-modal-widget ` : `${alternate ? ` img-overlay-widget ` : ' default-styles'}`}`}
                handleProductVariantArray={handleProductVariantArray}
                updateVariationTypeMeta={updateVariationTypeMeta}
                outOfStockVariations={outOfStockVariations}
                isSelected={true}
                selectedProductVariants={selectedProductVariants}
                productId={product.id}
                maxWidth={maxBubbleWidth}
                noMaxWidth={noMaxWidth}
                slug={product.slug}
                data={variationPages[index]}
                inPDP={inPDP}
                parsedSlug={selectedProductVariants}/>
        };

        const productRowCharacterLimit = 145;

        const getSelectedProductVariantActiveValue = function (variation) {
            let returnedVariantValue = false;
            if (!inPDP) {
                return returnedVariantValue;
            } else {
                selectedProductVariants.forEach(selectedVariant => {
                    if (selectedVariant.type === variation.name) {
                        returnedVariantValue = selectedVariant.value;
                    }
                })
            }
            return returnedVariantValue;
        };

        const separateVariationsByLetterLimit = function (letterLimit, variation) {

            if (!inPDP) {
                variationPages = [variation];
                return true;
            }

            let singlePage = [];
            let currentCharacterCount = 0
            let currentVariationPageIndex = 0;

            variation.values.forEach((el, idx) => {

                currentCharacterCount += el.name.length;
                singlePage.push(el);
                if (currentCharacterCount >= productRowCharacterLimit) {

                    variationPages.push({
                        name: variation.name,
                        values: singlePage,
                        id: variation.id,
                        activeValue: variation.activeValue || getSelectedProductVariantActiveValue(variation) || false
                    });

                    singlePage = [];
                    currentCharacterCount = 0;
                    currentVariationPageIndex += 1;
                }

            });

            if (singlePage.length !== 0) {
                variationPages.push({
                    name: variation.name,
                    values: singlePage,
                    id: variation.id,
                    activeValue: variation.activeValue || getSelectedProductVariantActiveValue(variation) || false
                });
            }
            return true;
        };

        return (

            <div
                className={`${rootClass} product-variant-wrapper`}>
                {
                    variationTypeMeta.map((variation, index) => {
                        let renderedVariation = (
                            <div 
                                key={ `product-variant-category-${ index }` } 
                                className={`${rootClass} product-variant-category`}>
                                <div className={`${rootClass} product-variant-row`}>
                                    <VariantProgressBar rootClass={rootClass}
                                                        variantMeta={variationTypeMeta}
                                                        index={index}
                                                        allSelected={checkProductOptionSelection(variationTypeMeta, selectedProductVariants, product.id)}
                                    />
                                    <div className={`${rootClass} product-variant-container`}>
                                        <h3 className={`${rootClass} product-variant-name`}>{variation.name}</h3>
                                        <div className={`${rootClass} bubble-page-wrapper`}>
                                            {
                                                variation &&
                                                separateVariationsByLetterLimit(productRowCharacterLimit, variation) &&
                                                variationPages.map((el, idx) => {
                                                    return renderProductVariantWidget(variation.uiType, product, rootClass, idx)
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );

                        variationPages = [];

                        return renderedVariation;

                    })
                }
            </div>
        )
    }
};

export default Script;
