export default function StyleObject(global, data) {

    const formFieldStyles = {
        backgroundColor: global.colors.light.white,
        border: "1px solid",
        borderColor: global.colors.accent.color3,
        color: global.colors.dark.color2,
        display: "block",
        fontSize: "16px",
        height: "38px",
        lineHeight: "18px",
        margin: "0",
        padding: "8px 12px",
        verticalAlign: "middle",
        '.input[disabled]': {
            background: global.colors.light.color2
        },
        '::placeholder': {
            color: global.colors.dark.color3
        }
    };

    return {
        ".form_HCPV": {
            fontSize: "14px",
            lineHeight: "20px"
        },

        ".textInput_wInput": {
            ...formFieldStyles,

            width: "100%"
        },
        ".textInput_halfWidth": {
            ...formFieldStyles,
            display:'inline-block',
            width: "47%",
            '.right': {
                float: 'right'
            }
        },
        ".input-container": {
            width: "100%",
            height:'55px',
            display: 'block',
            clear: 'both',
        },
        ".form-error-message": {
            color:'red',
            fontWeight:'bold',
            display:'none',
            fontSize:'8px'
        },
        ".show-form-error-message": {
            display:'block'
        },
        ".button-container": {
            position:'relative'
        },
        ".spinnerSVG": {
            position:'absolute',
            right:'10px',
            top: "6px",
            height:'30px',
            width:'30px'
        },
        ".btnContinue_wButton": {
            backgroundColor: global.colors.dark.color1,
            border: "0",
            borderRadius: "0",
            color: "white",
            cursor: "pointer",
            display: "block",
            fontSize: "14px",
            lineHeight: "inherit",
            margin: "10px 0 0",
            padding: "9px 15px",
            paddingBottom: "11px",
            paddingTop: "11px",
            textDecoration: "none",
            textTransform: "uppercase",
            width: "100%"
        },

    }


}
