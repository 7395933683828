import React from 'react';
import Component from '../../rootComponent';
import { css } from "@convertly/css";


export default class
ArrowHeadUpSvg extends Component {
    constructor ( props ) {
        super( props );
    }
    render() {
        return (

            <svg version="1.1"
                 className={ css( this.props.className ) }
                 xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 451.847 451.846">
                <g>
                    <path d="M248.292,106.406l194.281,194.29c12.365,12.359,12.365,32.391,0,44.744c-12.354,12.354-32.391,12.354-44.744,0
                        L225.923,173.529L54.018,345.44c-12.36,12.354-32.395,12.354-44.748,0c-12.359-12.354-12.359-32.391,0-44.75L203.554,106.4
                        c6.18-6.174,14.271-9.259,22.369-9.259C234.018,97.141,242.115,100.232,248.292,106.406z"/>
                </g>

            </svg>

        )
    }
}
