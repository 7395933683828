import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
`;
const ToolTip = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  white-space: nowrap;
  top: ${props => props.config.top};
  right: ${props => props.config.right};
  bottom: ${props => props.config.bottom};
  left: ${props => props.config.left};
  height: 26px;
  background-color: ${props => props.config.bgColor};
  color: ${props => props.config.color};
  padding: 0 20px;
  border-radius: 3px;
  font-size: 13px;
  z-index: 6000;
  transition: transform 0.2s;
  ${Wrapper}:hover & {
    display: flex;
  }
  :after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border: 6px solid transparent;

    top: ${props => props.config.ttTop};
    right: ${props => props.config.ttRight};
    bottom: ${props => props.config.ttBottom};
    left: ${props => props.config.ttLeft};
    margin-top: ${props => props.config.marginTop};
    margin-right: ${props => props.config.marginRight};
    margin-bottom: ${props => props.config.marginBottom};
    margin-left: ${props => props.config.marginLeft};
    border-top-color: ${props => props.config.borderTopColor};
    border-right-color: ${props => props.config.borderRightColor};
    border-bottom-color: ${props => props.config.borderBottomColor};
    border-left-color: ${props => props.config.borderLeftColor};
    border-top: ${props => props.config.borderTop};
    border-right: ${props => props.config.borderRight};
    border-bottom: ${props => props.config.borderBottom};
    border-left: ${props => props.config.borderLeft};
  }
`;

export const withToolTip = WrappedComponent => {
  return class extends React.Component {
    render() {
      let config = {
        color: this.props.color || "#FFF",
        bgColor: this.props.bgColor || "#000",
        top: this.props.top,
        right: this.props.right,
        bottom: this.props.bottom,
        left: this.props.left
      };

      switch (this.props.direction) {
        case "right":
          config.left = config.left || "100px";

          config.ttLeft = 0;
          config.ttTop = "50%";
          config.borderRightColor = config.bgColor;
          config.borderLeft = 0;
          config.marginTop = "-6px";
          config.marginLeft = "-6px";
          break;
        case "left":
          config.ttRight = 0;
          config.ttTop = "50%";
          config.borderLeftColor = config.bgColor;
          config.borderRight = 0;
          config.marginTop = "-6px";
          config.marginRight = "-6px";
          break;
        case "top":
          config.ttBottom = 0;
          config.ttLeft = "50%";
          config.borderTopColor = config.bgColor;
          config.borderBottom = 0;
          config.marginLeft = "-6px";
          config.marginBottom = "-6px";
          break;
        case "bottom":
          config.ttTop = 0;
          config.ttLeft = "50%";
          config.borderBottomColor = config.bgColor;
          config.borderTop = 0;
          config.marginLeft = "-6px";
          config.marginTop = "-6px";
          break;
        default:
      }

      return (
        <Wrapper className="tool-tip-wrapper">
          <WrappedComponent {...this.props} />
          {this.props.toolTip && (
            <ToolTip className="toolTip" direction={this.props.direction} config={config}>
              {this.props.toolTip}
            </ToolTip>
          )}
        </Wrapper>
      );
    }
  };
};
