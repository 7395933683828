export default function StyleObject(global, data) {

    return {


        ".stay-signed-in": {
            width:'100%',
            marginBottom:'13px',
            textAlign:'left'
        },
        ".checkbox": {
            overflow:'hidden',
            marginLeft:'0px'
        },
        ".checkbox-text": {
            margin:"0 10px 0 5px"
        },
        ".info-icon": {
            verticalAlign:"sub",
            height:'16px',
            transition:'all 300ms ease',
            borderRadius:'50px',
            cursor:'pointer',
            width:'16px',
            background:global.colors.dark.color3,
            fill:global.colors.light.color1,
            ":hover": {
                background:global.colors.dark.color1,
                fill:global.colors.light.white,
            }
        },
        ".info-modal-container": {
            display:'none',
            position:'fixed',
            top:0,
            left:0,
            right:0,
            bottom:0,
            background:'rgba(0,0,0,.6)',
            zIndex:'45',
            '.active': {
                display:'block'
            }
        },
        ".info-modal": {
            position: 'relative',
            top: '50%',
            margin: 'auto',
            transform: 'translateY(-30vh)',
            maxWidth: '625px',
            width:'fit-content',
            background: global.colors.light.white,
            padding:'40px 70px 50px',
            border:global.border,
            "@media (max-width:767px)": {
                width: '100%',
                left: '0',
                maxWidth:'unset',
                paddingTop: '20px',
                paddingBottom: '40px'
            },
            "@media (max-width: 515px)": {
                padding: '17px'
            },

        },
        ".info-modal-title": {
            fontSize:"25px",
            lineHeight:'35px',
            marginBottom:'10px',
            color:global.colors.dark.color1,
            "@media(max-width:768px)": {
                fontSize:"16px",
                lineHeight:"26px"
            }
        },
        ".info-modal-paragraph": {
            fontSize:"16px",
            lineHeight:'26px',
            color:global.colors.dark.color3,
            "@media(max-width:768px)": {
                fontSize:"14px",
                lineHeight:'24px',
            }
        },
        '.closeButtonInfoModal': {
            position: 'absolute',
            padding: '9px 10px 10px',
            top: '-15px',
            right: '-15px',
            height: '45px',
            width: '45px',
            border: global.border,
            borderRadius: '100%',
            background: global.colors.light.color1,
            cursor: 'pointer',
            zIndex: '2',
            "@media (max-width:767px)": {
                padding:'17px',
                right: '4px',
                top: '4px',
                background: 'none',
                border: 'none'
            },

        },
        '.closeButtonIconInfoModal': {
            fill: global.colors.dark.color1,
            stroke: global.colors.dark.color1,
            "@media (max-width:767px)": {
                height: '12px',
                width:'20px',

            },
        },

        '.closeIcon': {
            height: '20px',
            width: '20px'
        },

    }

}
