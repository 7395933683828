/*global Inferno, $ */

const Script = ( arg ) => {

	//const local = process.env.GRAPH === "local";
	// You can't use process.env in the browser.
	// I'm sure there's a way to parse this in babel when compiling
	// but I don't know how to do that off top of my head.  PM
	const local = false;


	let url = local ? 'http://localhost:4010/api/' : 'https://g.convertly.com';

	return function( { query, variables, cb } ) {

		cb = cb ? cb : () => {};
		variables = variables ? variables : {};

		const data = {
			query: query,
			variables: variables
		};

		const params = {
			type: 'POST',
			url,
			data: JSON.stringify( data ),
			dataType: 'json',
			processData: true,
			success: ( data ) => {
				let error = null;
				if( data.error ) {
					error = data.error;
					data = null;
				}
				cb( error, data );
			},
			error: ( xhr, type ) => {
				cb( () => {
					try {
						JSON.parse(xhr.responseText)
					}
					catch( err ) {
						window._LTracker = window._LTracker || [];
						window._LTracker.push({
							category: "JS Error",
							message:"json parse error - in graph.js",
							url:window.location.href,
							attemptedParse:xhr.responseText,
							err: err.message,
							localStorage:window.localStorage
						});
					}
				} );
			}
		};

		return $.ajax( params );
	}
};

export default Script;
