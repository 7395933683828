import { StyleSheet } from "@convertly/css";
import UserOverrides from '../../../elements/style-functions/user-overrides';



const hoursOfOperationStyles = ( global, data ) => {

    const userOverrides = UserOverrides( global, data );

    return StyleSheet.create({

        wrapper:  {
            margin: "25px 0px",
            padding: "0"
        },
        container: {
            position: "relative",
            margin: 'auto',
            '@media (max-width: 900px)': {
                width: "100%"
            },
            ...userOverrides
        },

        storeSelector: {
            color: global.colors.dark.color1,
            backgroundColor: global.colors.light.color1,
            fontFamily: global.fontFamily.primaryFont,
            textAlign: "center",
            height: "55px",
            border: `3px solid ${global.colors.dark.color1}`,
            display: 'flex',
            alignItems: 'center',
            padding: '0px 15px',
            cursor: "pointer",
            margin: '0px auto',
            justifyContent: 'center',
            maxWidth: "400px",
            "@media (min-width: 900px)": {
                margin: "0 auto"
            }
        },

        chooseLocation: {
            color: global.colors.dark.color1,
            backgroundColor: global.colors.light.white,
            textAlign: "space-between",
            display: "none",
            border: `3px solid ${global.colors.dark.color2}`,
            borderRadius: "2px",
            borderTop: "none",
            margin: '0px auto',
            position: 'absolute',
            width: "100%",
            zIndex: "5",
            left: '50%',
            maxWidth: '400px',
            transform: "translateX(-50%)",
            '.open': {
              display: "block"
            },
            "@media (min-width: 900px)": {
                margin: "0 auto"
            }
        },

        location: {
            display: "flex",
            justifyContent: "space-between",
            padding: "8px 8%",
            transition: "all .15s ease",
            cursor: "pointer",
            ":hover": {
                "@media (min-width: 768px)": {
                    backgroundColor: "rgba(200,200,200,.3)"
                }
            },
            "@media (max-width: 425px)": {
                padding: "8px"
            }
        },

        locationName: {
            fontSize: '14px',
            fontFamily: global.fontFamily.primaryFont,
            textTransform: "uppercase",
            textAlign: "left"
        },

        locationAddress: {
            textAlign: 'right',
            fontFamily: global.fontFamily.secondaryFont
        },

        locationContainer: {
            display: "none",
            color: global.colors.light.white,
            textAlign: 'center',
            marginTop: '25px',
            '.active': {
                display: 'block'
            }
        },

        storeName: {
            fontSize: "20px",
            fontFamily: global.fontFamily.primaryFont
        },

        storeAddress: {
            fontSize: '18px',
            fontFamily: global.fontFamily.secondaryFont,
            marginTop: "5px"
        },

        storeAddress2: {
            fontSize: '18px',
            fontFamily: global.fontFamily.secondaryFont,
        },

        contactButtons: {

        },

        call: {
            margin: "0 auto",
            display: 'block',
            color: global.colors.light.white,
            marginBottom: "35px",
            borderRadius: '3px',
            border:'none',
            fontSize:'16px',
            fontFamily: global.fontFamily.secondaryFont,
            ":hover": {
                opacity: ".7"
            }
        },

        orderPickup: {
            color: global.colors.light.white,
            margin: "0px 5px",
            fontSize: "12px",
            display: 'inline-block',
            border:'none',
            background: global.colors.accent.color1,
            fontFamily: global.fontFamily.secondaryFont,
            borderRadius: '3px',
            marginBottom: "15px",
            padding: "10px 25px",
            ":hover": {
                opacity: ".7"
            }
        },

        orderDelivery: {
            color: global.colors.light.white,  
            margin: "0px 5px",
            fontSize: "12px",
            display: 'inline-block',
            marginBottom: "15px",
            border:'none',
            background: global.colors.accent.color1,
            fontFamily: global.fontFamily.secondaryFont,
            borderRadius: '3px',
            padding: "10px 20px",
            ":hover": {
                opacity: ".7"
            }
        },

        hoverBox: {
            maxWidth: '300px',
            margin: 'auto',
            ':hover ': {
                '.hoursBox': {
                    visibility: 'visible',
                    backgroundColor: global.colors.light.color1,
                    zIndex: 100,
                    color: global.colors.dark.color1,
                    boxShadow: '0 0 12px -3px #999',
                },
            },
        },
        hoursBox: {
            visibility: 'hidden',
            width: '250px',
            padding: '10px',
            border: '1px solid ' + global.colors.dark.color3,
            position: 'absolute',
            margin: '0 auto',
            marginTop: '-18px',
            marginLeft: '-28px',
        },
        days: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },

        arrow: {
            height: ".6em",
            width: ".6em",
            fill: "black",
            fontSize: "28px",
            transition: "all .15s ease",
            position: "relative",
            right: "-65px",
            '.open': {
                transform: "rotate(-180deg)"
            }
        },

        viewOnMap: {
            color: global.colors.light.white,
            display: "block",
            fontFamily: global.fontFamily.secondaryFont,
            marginTop: "8px",
            marginBottom: "25px",
            textDecoration: "underline",
            fontSize: '12px',
            opacity: '0.7',
            ':hover': {
                opacity: '1'
            }
        },

        hoursOfOperation: {
            fontFamily:global.fontFamily.secondaryFont,
            width:'fit-content',
            margin:'0 auto',
            textAlign:'left',
            marginTop: "10px"
        },

        hoursList: {
            listStyleType:'none',
            position:'relative',
            height:'100px',
            maxWidth: "280px",
            margin: "0 auto"

        },
        dayOfWeek: {
            display:'inline-block',
            margin:'35px 5px 0px',
            textTransform:'uppercase',
            cursor:'pointer',
            fontSize: "12px",
            ':hover': {
                borderBottom: '2px solid',
                color:global.colors.light.white

            },
            '.active': {
                fontWeight:'700',
                borderBottom: '2px solid',
                color:global.colors.light.white
            },

        },
        displayedHours: {
            position:'absolute',
            top:0,
            left:'0',
            opacity:'0',
            width:'100%',
            transition:'opacity .2s linear',
            textAlign:'left',
            color:global.colors.light.white,
            display: 'flex',
            justifyContent: "center",
            '.active': {
                opacity:'1'
            }
        },
        openStatus: {
            fontSize:'17px',
            fontWeight:'bold',
            '@media( max-width:510px )': {
                fontSize:'18px'
            }
        },
        hours: {
            fontSize:'17px',
            '@media( max-width:510px )': {
                fontSize:'16px'
            }
        }

    });

};

export default hoursOfOperationStyles;
