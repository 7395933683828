/*global Inferno */
/* eslint-disable */

import {include} from '@convertly/thor';

const restaurantMenuScript = ( id ) => {

    return (
        (id) => {


        }
    )

};

export default restaurantMenuScript;
