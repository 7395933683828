import { isProduction } from "utils/index";

const StorefrontItems = [
  {
    label: "Storefront",
    icon: "fa-shopping-cart",
    //url: '/account',
    permission: can => can("view", "ecomm"),
    restraint: site => site.getIn(["ecommerce", "enabled"]),
    items: [
      {
        label: "Orders",
        url: "/storefront/orders",
        permission: (can, is) => can("view", "orders") || is("developer"),
      },
      {
        label: "Products",
        url: "/storefront/products",
        permission: (can, is) => can("view", "products") || is("developer"),
      },
      {
        label: "Categories",
        url: "/storefront/categories",
        permission: (can, is) => can("view", "categories") || is("developer"),
      },
      // {
      //   label: "Tags",
      //   url: "/storefront/tags",
      //   permission: (can, is) => can("view", "tags") || is("developer"),
      // },
      {
        label: "Customers",
        url: "/storefront/customers",
        permission: (can, is) => can("view", "customers") || is("developer"),
      },
      // {
      //   label: "Coupons",
      //   url: "/storefront/coupons",
      //   permission: (can, is) => can("view", "coupons") || is("developer"),
      // },
      {
        label: "Settings",
        url: "/storefront/settings",
        permission: (can, is) => can("edit", "ecomm_settings") || is("developer"),
      },
    ],
  },
];

const AdTechItems = [
  {
    label: "Marketing",
    icon: "fa-envelope",
    permission: (can, is) => {
      if (is("developer") || can("view", "adtech")) {
        return true;
      }

      if (isProduction()) {
        return false;
      }

      return true;
    },
    restraint: site => !!site.size,
    items: [
      {
        label: "My Banners",
        url: "/marketing/banners",
        permission: (can, is) => !isProduction() || can("view", "adtech"),
      },
      {
        label: "Banner Campaigns",
        url: "/marketing/campaigns",
        permission: (can, is) => !isProduction() || can("view", "adtech"),
      },
      {
        label: "Manage Shapes",
        url: "/marketing/shapes",
        permission: (can, is) => is("developer") || can("edit", "adminAdtech"),
      },
      {
        label: "Manage Templates",
        url: "/marketing/templates",
        permission: (can, is) => is("developer") || can("edit", "adminAdtech"),
      },
      {
        label: "Admin",
        url: "/marketing/admin",
        permission: (can, is) => is("developer"),
      },
    ],
  },
];

const ChatItems = [
  {
    label: "Chat",
    icon: "fa-weixin",
    permission: (can, is) => can("view", "chat") || is("developer"),
    items: [
      {
        label: "Chat Inbox",
        url: "/chat/inbox",
        permission: (can, is) => can("edit", "chat") || is("developer"),
      },
      {
        label: "Chat Settings",
        url: "/chat/settings",
        permission: (can, is) => can("edit", "chat") || is("developer"),
      },
    ],
  },
];

const HomeItems = [
  // {
  //   label: "Home",
  //   url: "/",
  //   icon: "fa-home",
  //   permission: (can, is) => !is("partner"),
  // },
  // {
  //   label: "Home",
  //   url: "/partner",
  //   icon: "fa-home",
  //   permission: (can, is) => is("partner"),
  // },
  // {
  //   label: "Dashboard",
  //   url: "/sites",
  //   icon: "fa-th",
  //   permission: can => can("view", "site_switcher"),
  // },

  {
    label: "Home",
    url: "/dashboard",
    icon: "fa-home",
    permission: (can, is) => true,
    restraint: site => true,
  },
];

const SiteItems = [
  {
    label: "Edit Site",
    url: "/editor",
    icon: "fa-pencil-square-o",
    permission: (can, is) => can("edit", "site"),
    restraint: site => !!site.get("siteId"),
  },

  {
    label: "Settings",
    url: "/settings",
    icon: "fa-cog",
    permission: can => can("edit", "site_settings"),
    restraint: site => !!site.get("siteId"),
  },

  // {
  //   label: "Forms",
  //   url: "/forms",
  //   icon: "fa-list-alt",
  //   permission: can => can("edit", "site"),
  //   restraint: site => !!site.get("siteId"),
  // },
];

const UsersItems = [
  {
    label: "Users",
    url: "/users/partners",
    icon: "fa-users",
    permission: (can, is) =>
      is("developer") || can("edit", "users") || is("admin") || is("account_owner"),
  },
];

const ClientsItems = [
  {
    label: "Clients",
    url: "/users/clients",
    icon: "fa-users",
    permission: (can, is) => is("partner") || is("admin"),
  },
];

const MyFiles = [
  {
    label: "My Files",
    url: "/files",
    icon: "fa-file",
    restraint: site => !!site.get("siteId"),
  },
];

const Account = [
  {
    label: "Account",
    url: "/account",
    icon: "fa-user-circle",
  },
];

const Contests = [
  {
    label: "Contests",
    url: "/contests",
    icon: "fa-star",
    permission: (can, is) => is("partner") || is("admin") &&  can("viewContests"),
  },
]

const AdminItems = [
  {
    label: "Admin",
    icon: "fa-envelope",
    permission: can => can("view", "adminScreens"),
    items: [
      {
        label: "Funnels",
        url: "/admin/funnels",
        permission: can => can("view", "adminScreens"),
      },
    ],
  },
];

export const menuItems = [
  ...HomeItems,

  ...StorefrontItems,

  ...SiteItems,

  ...AdTechItems,

  ...MyFiles,

  //...ChatItems,

  //...UsersItems,

  //...ClientsItems,

  ...Account,

  ...AdminItems,

  ...Contests,
];
