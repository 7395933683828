/*global Inferno, $ */
import React from 'react';
const Script = (id, dispatch, ArrowDownOpen) => {
	
	return ({
	    rootClass,
	    pages,
	    currentPage,
	    changePageNum,
	    footer,
	    currentPageUserInput,
	    toggleDropDown
	  }) => {
		
    const handlePageUserInput = function(e) {
      e.preventDefault();
      let userInput = e.target.value
      let encodedUserInput = userInput.replace(/</g, "&lt;").replace(/>/g, "&gt;");
      let parsedUserInput = parseInt(encodedUserInput);

      if (parsedUserInput && parsedUserInput >= 1 && parsedUserInput <= pages) {
        changePageNum(parsedUserInput, 'page-jump')
      } else {
        e.target.value = currentPage;
      }

    };
		
		let pageNumbers = [];
		
		for (let i = 1; i <= pages; i++) {
			pageNumbers.push(
				<div
					className={
						`${ rootClass } page-jump-page-number ${ i === currentPage ? 'current-page' : '' }`
					}
					key={ `page-number-${i}` }
					onClick={ () => changePageNum(i, 'page-jump') }>
					{ i }
				</div>
			)
    }
    
    const paginationForHeader = (
      <div
        className={ `${ rootClass } page-jump-dropdown` }
        onMouseEnter={ () => toggleDropDown('page-jump') }
        onMouseLeave={ () => toggleDropDown('page-jump') }>
        <div
          className={ `${ rootClass } page-jump-page-number-container` }>
          <div
            className={ `${ rootClass } page-jump-page-number`}>
            { currentPage }
            <ArrowDownOpen nameOfClass={ `${ rootClass } arrow-down` }/>
          </div>
          <div
            className={ `${ rootClass } page-jump-page-number-modal-container` }
            data-toggle={ 'page-jump' }>
            {
              pageNumbers
            }
          </div>
        </div>
      </div>
    );

    const paginationForFooter = (
      <div
        className={ `${ rootClass } page-jump-dropdown footer` }>
        { currentPage }
      </div>
    );

    const inputPaginationForHeader = (
      <div 
        className={ `${ rootClass } page-jump-user-input-container` }
        onChange={handlePageUserInput}>
        <input
          className={ `${ rootClass } page-jump-user-input` }
          value={ currentPage }
        />
      </div>
    );

		return (
			<div className={ `${ rootClass } page-jump-by-selection-container`}>
				<div className={ `${ rootClass } page-jump-by-selection-wrapper`}>
					<div className={ `${ rootClass } page-jump-by-selection ${ footer ? 'footer' : '' }`}>
						Page:
            {
              footer ? 
              paginationForFooter :
              paginationForHeader
            }
						of { pages }
					</div>
					{/* <div className={ `${ rootClass } page-jump-by-selection-mobile`}>
						{`Page:${currentPage} of ${pages}`}
					</div> */}
				</div>
			</div>
		)
	}
};


export default Script;
