/** generates style prefixes that allow styles just for MS browsers **/


 /*************                     *************/
 /*************    Browser Styles   *************/
 /*************                     *************/

/**
 * Microsoft browsers
 */
export function generateMSStyleSheet( styles ) {
    const breakpoints = generateBrowserBreakPoints();

    return {
        [breakpoints.edge]: {
            ...styles
        },
        [breakpoints.ie]: {
            ...styles
        }
    }

}

/**
 * Safari Styles
 */
export function generateSafariStyleSheet( styles ) {
    const breakpoints = generateBrowserBreakPoints();

    return {
        [breakpoints.safari10up]: {
            ...styles
        },
        [breakpoints.safari9down]: {
            ...styles
        }
    }

}

/*************                     *************/
/*************    Tablet Styles    *************/
/*************                     *************/

/**
 * all tablet
 */
export function generateTabletStyleSheet( styles ) {
    const breakpoints = generateBrowserBreakPoints();

    return {
        [ breakpoints.ipad ]: {
            ...styles
        },
        [ breakpoints.galaxyTablet ]: {
            ...styles
        }
    }
}

/**
 * all portrait
 */
export function generateTabletPortraitStyleSheet( styles ) {
    const breakpoints = generateBrowserBreakPoints();

    return {
        [ breakpoints.ipadPortrait ]: {
            ...styles
        },
        [ breakpoints.galaxyTabletPortrait ]: {
            ...styles
        }
    }
}

/**
 * returns selectors that override styles on specific browsers
 */
var browserStyles = generateBrowserBreakPoints();


export function generateBrowserBreakPoints( ) {

    return {
        //Mozilla Firefox
        mozilla:"@-moz-document url-prefix()",

        //MS
        edge:"@supports (-ms-ime-align: auto)",
        ie:"@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)",
        //Apple
        safari10up:"@media not all and (min-resolution:.001dpcm)",
        safari9down:"@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0)",
        ipad:"@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1)",
        ipadPortrait:"@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1)",
        ipadLandscape:"@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1)",
        iphone: "@media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 2)",
        iphonePortrait: "@media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)",
        iphoneLandscape: "@media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape)",

        //Android
        galaxyTablet:"@media (min-device-width: 800px) and (max-device-width: 1280px)",
        galaxyTabletPortrait:"@media(max-device-width: 800px) and (orientation: portrait)",
        galaxyTabletLandscape:"@media(max-device-width: 1280px) and (orientation: landscape)",

    }

}

//find more here https://css-tricks.com/snippets/css/media-queries-for-standard-devices/