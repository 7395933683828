import React from 'react';
import {css} from '@convertly/css';
import Styles from './menu-item-with-modal-styles';


import Elements from 'source/elements';


export const MenuItemWithModal = ({data, global, inEditor}) => {

    const styles = Styles(global, data, inEditor);

    let price, qty = null;


    if (data.price) {
        price = `$${data.price}`;
    }

    if (data.quantity) {
        qty = data.quantity.count + ' ' + data.quantity.type;
    }

    let metaData = JSON.stringify(data);

    return (

        <div data-metaData={metaData} className={`${css(styles.column)} menu-item`}>

            <div className={css(styles.menuItem)}>

                <div className={css(styles.menuItemTop)}>
                    {
                        data.tags &&
                        <div className={css(styles.itemTags)}>
                            {data.tags.map((tag) => {
                                return (
                                    <div className={css(styles.tag)}> {tag} </div>
                                )
                            })
                            }
                        </div>
                    }

                    {
                        data.img ?
                                <Elements.Image
                                    data={{
                                        src: data.img.src,
                                        params:{height: 230, crop: 'fit'},
                                        alt: data.img.alt,
                                    }}
                                    parentClass={css(styles.menuItemImage)}
                                />
                            :
                            <div className={css(styles.menuItemImagePlaceholder)}/>
                    }

                </div>
                {/* LEFT ITEM - CONTAINS ALL BUT PRICE */}

                <div className={css(styles.menuItemBottom)}>

                    <span className={css(styles.headingAndPrice)}>


                        <h3 className={css(styles.itemHeading)}>
                            {data.name && data.name.text}
                        </h3>



                    </span>

                    <div className={css(styles.left)}>
                        <p className={css(styles.itemDescription)}>
                            {data.description && data.description.text}
                        </p>
                    </div>
                    <div className={css(styles.right)}>
                        <p className={css(styles.itemPrice)}>
                            {data.price && data.price.toFixed(2)}
                        </p>
                    </div>

                </div>

            </div>

        </div>

    )


};