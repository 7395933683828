import { StyleSheet } from '@convertly/css';
import { generatePseudoMediaQuery } from '../../../elements/style-functions/pseudo-media-queries';
import UserOverride from '../../../elements/style-functions/user-overrides';
import removeNestedUserOverrides from '../../../site-header/site-header/utils/removeNestedUserOverrides';
import _ from 'lodash';

const MapStyles =  ( global, data, inEditor ) => {

    const locationDiv = removeNestedUserOverrides({ data });

    const { userOverrides } = data;

    const {
      googleMaps,
      locationInfo,
      locationContainerSingle,
      phoneLink,
      emailLink
    } = userOverrides || {};

    const googleMapsUserOverrides = _.get(googleMaps, 'userOverrides') ? UserOverride(global, googleMaps, inEditor) : {};
    const locationInfoUserOverrides = _.get(locationInfo, 'userOverrides') ? UserOverride(global, locationInfo, inEditor) : {};
    const locationDivUserOverrides = UserOverride(global, { userOverrides: locationDiv }, inEditor);
    const locationContainerSingleUserOverrides = _.get(locationContainerSingle, 'userOverrides') ? UserOverride(global, locationContainerSingle, inEditor) : {};
    const phoneLinkUserOverrides = _.get(phoneLink, 'userOverrides') ? UserOverride(global, phoneLink, inEditor) : {};
    const emailLinkUserOverrides = _.get(emailLink, 'userOverrides') ? UserOverride(global, emailLink, inEditor) : {};

    return StyleSheet.create({
        locationDiv: {
          ...locationDivUserOverrides
        },
        locationContainerSingle: {
            width:'100%',
            display:'inline-block',
            padding:'0px 5px',
            textAlign:'center',
            ...generatePseudoMediaQuery(
                "@media ( max-width:991px )",
                ['mobile', 'smallTablet', 'largeTablet'],
                {
                    display:'block',
                    width:'100%',
                    padding:"20px 0 0 0"

                },
                inEditor
            ),
            ...locationContainerSingleUserOverrides
        },
        locationContainer: {
            width:'50%',
            display:'inline-block',
            padding:'0px 5px',
            textAlign:'center',
            ...generatePseudoMediaQuery(
                "@media ( max-width:991px )",
                ['mobile', 'smallTablet', 'largeTablet'],
                {
                    display:'block',
                    width:'100%',
                    padding:"20px 0 0 0"
                },
                inEditor
            )
        },
        mapContainer: {
            display:"inline-block",
            width:'100%',
            marginBottom:'0px',
            maxHeight: '200px'
        },
        googleMap: {
            width:'100%',
            border: "none",
            height: "200px",
            overflow:'hidden',
            ".map": {
                height:"432px",
                marginTop:'-112px',
                width:'100%'
            },
            ...googleMapsUserOverrides
        },
        locationInfo: {
            width:'100%',
            display:'inline-block',
            verticalAlign: "top",
            padding:'20px 0 10px',
            background: '#ffffff',
            marginBottom:'25px',
            ...generatePseudoMediaQuery(
                "@media ( max-width:767px )",
                ['mobile', 'smallTablet'],
                {
                    width:'100%',
                    padding:"20px 0 0 0"
                },
                inEditor
            ),
            ...locationInfoUserOverrides
        },
        storeName: {
            margin:'0 0 10px 0',
            fontSize:'30px',
            fontWeight:'700'
        },
        address: {
            margin:'10px 0 20px'
        },
        phoneLink: {
            color: global.colors.accent.color1,
            fontFamily: global.fontFamily.secondaryFont,
            fontSize:'16px',
            fontWeight:"700",
            marginBottom:'10px',
            ...phoneLinkUserOverrides
        },
        emailLink: {
            color: global.colors.accent.color1,
            fontFamily: global.fontFamily.secondaryFont,
            ...emailLinkUserOverrides
        }


    })
};


export default MapStyles
