/**
 * store needs to be exported from this file
 * many action files import store from this file
 */


import { browserHistory } from "react-router";

import configureStore from "./store";
const initialState = {};


export const store = configureStore(initialState, browserHistory);
