import React from "react";
import styled from "styled-components";
import { pure } from "recompose";

const HIDDEN_ROUTES = ["/editor", "/login", "/forgot-password", "/reset-password", "/signup"];
const BACKGROUND_IMAGE =
  "https://convertlyimguploadeast.s3.amazonaws.com/convertly-global-images/dashboard-bg-wave.png";

const bgDisplay = ({ path }) => {
  if (HIDDEN_ROUTES.indexOf(path) === -1) {
    return "";
  }

  return "display:none";
};

const BG = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  width: 100vw;
  height: 60%;
  ${bgDisplay}
`;

const BackgroundComponent = ({ path }) => (
  <BG src={BACKGROUND_IMAGE} alt={"app background"} path={path} />
);

export const Background = pure(BackgroundComponent);
