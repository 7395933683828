import React from 'react';
import Component from '../../rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';

//Utils
import getHeaderPosition from '../header-style-functions/get-header-position';

//scripts
import absoluteToFixed from '../scripts/absolute-to-fixed-header';

//Styles
import headerStyles from './header-styles-02.js';

//Components
import Nav02 from './components/nav-02';


export class Header02 extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        
        let { site, page, data, global } = this.props;
        let inEditor = this.inEditor();


        const headerPosition = getHeaderPosition(data.position, page, inEditor);
        this.styles = headerStyles(global, data, headerPosition, inEditor);

        if (this.props.editor) {
            return this.editor();
        }

        const inlineStyle = this.generateStyleAttribute();

        let headerID = headerPosition === "overlayFixed" ? id(absoluteToFixed) : "header";

        if (headerPosition === "noHeader") {
            return null
        }

        else {
            return (
                <div id={headerID}>
                    <header id="header05"
                            className={css(this.styles.header)}
                            data-elementType="header"
                            itemProp="siteHeader"
                            itemScope
                            itemType="http://schema.org/siteHeader"
                            onClick={(e) => {
                                this.launchElementalEditor('header', e);
                            }}
                            onMouseOver={ this.addEditableOutline }
                            onMouseOut={ this.removeEditableOutline }
                            { ...inlineStyle }
                            { ...this.generateDataEditorAttribute() }>
                        <Nav02 global={global}
                               data={data}
                               id={ this.props.id }
                               headerPosition={headerPosition}
                               styles={this.styles}
                               page={ page }
                               site={site}/>
                    </header>


                </div>
            )
        }
    }

}
