import React from 'react';
import PropTypes from 'prop-types';
import Component from '../../rootComponent';
import {css} from "@convertly/css";
import ReactHTMLParser from "@convertly/react-html-parser";

//Styles
import buttonStyles from './buttonStyles01';

//Utils
import {generateId} from '../../utils/generate-attribute';

//Components
import Link from '../links/web-link';

/**
 * Class controls imports style functions that allows for user to override the css in the CMS editor for their site.
 **/
class Button extends Component {

    constructor(props) {

        super(props);

    }

    render() {

        let inEditor = this.inEditor();

        let buttonType;

        if (!this.props.buttonType) {

            switch (this.props.data.buttonType) {
                case "button1":
                    buttonType = this.props.global.button1;
                    break;
                case "button2":
                    buttonType = this.props.global.button2;
                    break;
                case "button3":
                    buttonType = this.props.global.button3;
                    break;
                default:
                    buttonType = this.props.global.button1;
            }
        } else {
            buttonType = this.props.buttonType
        }

        this.styles = buttonStyles(this.props.global, this.props.data, buttonType, inEditor);

        let className = '';

        let aphroditeClass = css(this.styles.button);

        if (this.animatedClassName) {
            className += " " + this.animatedClassName
        }
        className += aphroditeClass;

        const {animated} = this.props.data;
        const inlineStyle = this.generateStyleAttribute();



        return (
            <Link { ...this.props }
                  isChild={ true }
                  notEditable={ true }
                  >
                <div {...generateId(this.props.elementId)}
                     { ...this.generateDataEditorAttribute() }
                     { ...this.generateCustomAttributes() }
                     onMouseOver={ (e) => {
                       this.addEditableOutline(e)
                      } }
                      onMouseOut={ (e) => {
                        this.removeEditableOutline(e)
                      } }
                     { ...inlineStyle }
                     data-animated={animated}
                     className={ className }>
                    {this.props.data.text}
                </div>
            </Link>
        );
    }
}


/**
 * Connects element to context for cms editor
 **/
Button.contextTypes = {
    store: PropTypes.object
};

export default Button

/**
 Component BoilerPlate

 {
     "el": "Elements.Button",
     "data": {
         "text": "Your text here",
         "buttonType": "button1",
         "link": {
             "linkType": "internal",
             "url": "/shop"
         },
         "userOverrides": {

         }
     },
     "children": [],
     "sort": 900
 }

 **/
