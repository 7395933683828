import React from 'react';
import _ from 'lodash';
import { id } from '@convertly/thor';

import registerPixels from './register-pixels-and-conversions';

export class Pixels extends React.Component {

	constructor(props) {
		super(props);
	}

	render() {
		
		let pixels = _.get( this.props, 'site.pixels' ) || [];

		let conversionPoints = _.get( this.props, 'site.conversionPoints' ) || [];

		let systemFacebookPixelId = _.get(this.props, 'site.systemFacebookPixelId');

		let customUserFacebookPixelId = _.get(this.props, 'site.facebookPixelId');

		if( conversionPoints.length ) {
			try {
				conversionPoints = JSON.stringify(conversionPoints);
			} catch(e) {
				conversionPoints = JSON.stringify([]);
			}

			id( registerPixels, conversionPoints, systemFacebookPixelId, customUserFacebookPixelId );
		}

		return null;
	}
}

Pixels.defaultProps = {
	site: {}
};
