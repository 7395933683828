import {StyleSheet} from "@convertly/css";
import UserOverrides from '../../../../elements/style-functions/user-overrides';
import { generatePseudoMediaQuery } from '../../../../elements/style-functions/pseudo-media-queries';
import _ from 'lodash'

const HeaderStyles4 = (global, data, headerPosition, inEditor) => {

    let modifiedColor;
    let overlayBorderStyle = {};

    const navLinksOverrides = UserOverrides(global, data.menu, inEditor);

    if (headerPosition === "overlayFixed" || headerPosition === "overlay") {
        overlayBorderStyle = {
            '.absolute': {
                borderColor: global.colors.light.white,
                color: global.colors.light.white,
                stroke: global.colors.light.white,
                fill: global.colors.light.white
            },
        };
    }

    if (headerPosition === "overlay") {
        modifiedColor = global.colors.light.white
    }
    else {
        modifiedColor = global.colors.dark.color1
    }


    const caret = {
        display: "inline-block",
        height: '17px',
        width: '17px',
        cursor: 'pointer',
        boxSizing: 'content-box',
        marginTop: '-6px',
        marginLeft: '5px',
        stroke: 'inherit',
        position: 'absolute',
        top: '27px',
        right: '-13px',
        ...generatePseudoMediaQuery('@media( max-width:1201px )',
            ['desktop', 'mobile', 'smallTablet', 'largeTablet'],
            {
                top: '34px',
                right: '-17px'
            },
            inEditor)
    };

    const navListItem = {
        display: 'inline-block',
        cursor: 'pointer',
        fontSize: '18px',
        stroke: 'inherit',
        position: 'relative',
        margin: "0 10px",
        ...generatePseudoMediaQuery(
            "@media (max-width: 991px)",
            [ 'smallTablet', 'mobile', 'largeTablet' ],
            {
                padding: "0px"
            }, inEditor)
    };

    const dropdown = {
        float: 'right',
        listStyle: 'none',
        height: 'auto',
        width: 'fit-content',


    };
    const dropdownList = {
        display: 'none',
        position: 'absolute',
        top: '140%',
        right: '-34px',
        background: global.colors.light.white,
        boxSizing: 'content-box',
        listStyle: 'none',
        padding: '20px 20px 8px',
        minWidth: '270px',
        zIndex: '1',
        '.open': {
            display: 'block'
        },
        ...generatePseudoMediaQuery(
            '@media( max-width:991px )',
            ['smallTablet', 'largeTablet', 'mobile'],
            {
                top: '205%',
                right: '3px'
            }, inEditor),
        ...generatePseudoMediaQuery(
            '@media( max-width:420px)',
            [ 'mobile' ],
            {
                right: '0'
            }, inEditor)
    };


    return StyleSheet.create({
        menuContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '25px 0px',
            borderBottom: "5px solid",
            borderColor: modifiedColor,
            borderRadius: '1px',
            fill: modifiedColor,
            color: modifiedColor,
            ...generatePseudoMediaQuery(
                '@media( max-width:991px)',
                ['smallTablet', 'largeTablet', 'mobile'],
                {
                    padding: '10px'
                }, inEditor),
            height: '100%',
            ...overlayBorderStyle
        },
        logo: {
            float: 'left',
            alignSelf: 'center',
            maxWidth: '180px',
            zIndex: '4'
        },
        logoImg: {
            width: 'auto',
            maxHeight: "45px",
            '.absolute': {
                display: 'block'
            },
            '.default': {
                display: 'block'
            }
        },
        menuLauncherIcon: {
            display: 'none',
            order: 2,
            float: 'right',
            marginRight: "15px",
            marginTop: '17px',
            ...generatePseudoMediaQuery(
                '@media( max-width:991px )',
                ['largeTablet'],
                {
                    display: "block"
                }, inEditor
            ),
            ...generatePseudoMediaQuery(
                '@media( max-width:768px )',
                ['mobile', 'smallTablet'],
                {
                    marginRight: '0',
                    display: "block"
                }, inEditor
            )
        },
        navLaunch: {
            zIndex: 45,
            display: "none",
            paddingBottom: 0,
            overflow: "visible",
            background: "none",
            border: "none",
            outline: "none",
            ...generatePseudoMediaQuery(
                '@media( max-width:991px )',
                ['largeTablet', 'mobile', 'smallTablet'],
                {
                    display: "block"
                }, inEditor
            )
        },
        iconBar: {
            backgroundColor: modifiedColor,
            width: "20px",
            height: "3px",
            marginBottom: "3px",
            position: "relative",
            display: "block",
            zIndex: 50,
            transition: 'all 0.3s ease-in-out 0s',
            ':nth-child(3)': {
                width: "16px"
            },
            ".absolute": {
                backgroundColor: global.colors.light.white,

            },
            '.open': {
                ":nth-child(2)": {
                    transform: "rotate(45deg)",
                    top: "7px",
                    background: global.colors.dark.color1
                },
                ":nth-child(4)": {
                    transform: "rotate(-45deg)",
                    top: "-5px",
                    background: global.colors.dark.color1
                },
                ":nth-child(3)": {
                    opacity: 0
                }
            },
        },
        mainMenu: {
            zIndex: 45,
            alignSelf: 'center',
            width: 'fit-content',
            ...generatePseudoMediaQuery(
                '@media( max-width:1065px )',
                ['desktop'],
                {
                    marginTop: '5px'
                }, inEditor
            ),
            ...generatePseudoMediaQuery(
                '@media( max-width:991px )',
                ['largeTablet', 'smallTablet', 'mobile'],
                {
                    marginTop: '-2px'
                }, inEditor
            ),
            '.open': {
                display: 'block',
                visibility: 'visible',
                left: '0',
            },
        },
        headerNav: {
            display: 'inline-block'
        },
        navList: {
            float: 'right',
            listStyle: 'none',
            paddingLeft: '15px',
            height: 'auto',
            width: 'fit-content',
            ...generatePseudoMediaQuery(
                '@media ( max-width:991px )',
                ['largeTablet', 'smallTablet', 'mobile'],
                {
                    position: 'absolute',
                    top: '-640%',
                    right: '0',
                    width: '100%',
                    background: global.colors.light.white,
                    transition: 'position 1s ease'
                }, inEditor
            ),
            '.open': {
                top: 0,
                zIndex: 2,
                minHeight: '80px',
                ...generatePseudoMediaQuery(
                    '@media ( max-width:991px )',
                    ['largeTablet', 'smallTablet', 'mobile'],
                    {
                        borderBottom: '3px solid',
                        borderColor: global.colors.dark.color1,
                    }, inEditor
                ),
            }
        },
        navListItem: {
            width: "fit-content",
            borderBottom: "3px solid transparent",
            ...navListItem,
            ".active": {
                borderBottom: "3px solid",
                transition: "100ms ease",
                borderColor: 'inherit'
            },
            ":hover": {
                "@media (hover:hover)": {
                    borderBottom: "3px solid",
                    transition: "100ms ease"
                }
            },
            ...generatePseudoMediaQuery(
                '@media ( max-width:991px )',
                ['largeTablet', 'smallTablet', 'mobile'],
                {
                    display: "block",
                    color: global.colors.dark.color1,
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    margin: "25px 0px"
                }, inEditor
            ),
            ...navLinksOverrides
        },

        contactNavListItem: {
            ...navListItem,
            padding: '24px 10px 0 10px',
            ...generatePseudoMediaQuery(
                '@media ( max-width:991px )',
                ['largeTablet', 'smallTablet', 'mobile'],
                {
                    marginTop: '10px',
                    textDecoration: 'underline'
                }, inEditor
            ),
            ...navLinksOverrides
        },
        caret: {
            ...caret,
            ...generatePseudoMediaQuery(
                '@media ( max-width:991px )',
                ['largeTablet', 'smallTablet', 'mobile'],
                {
                    stroke: _.get(data, "menu.userOverrides.color") || global.colors.dark.color1
                }, inEditor
            ),
        },
        contactCaret: {
            ...caret,
            ...generatePseudoMediaQuery(
                '@media ( max-width:991px )',
                ['largeTablet', 'smallTablet', 'mobile'],
                {
                    visibility: 'hidden'
                }, inEditor
            ),
        },
        dropdown: {
            ...dropdown,
            ...generatePseudoMediaQuery(
                '@media ( max-width:991px )',
                ['largeTablet', 'smallTablet', 'mobile'],
                {
                    float: 'none'
                }, inEditor
            ),
        },
        contactDropdown: {
            ...dropdown
        },
        dropdownList: {
            ...dropdownList,
            ...generatePseudoMediaQuery(
                '@media ( max-width:991px )',
                ['largeTablet', 'smallTablet', 'mobile'],
                {
                    paddingTop: '20px',
                    position: 'static'
                }, inEditor
            ),
        },
        contactDropdownList: {
            ...dropdownList,

        },
        dropDownItem: {
            display: 'none',
            position: 'absolute',
            top: '140%',
            right: '0',
            width: "200px",
            background: '#fff',
            listStyle: 'none',
            padding: '10px',
            marginBottom: '20px',
            '.open': {
                display: 'block'
            }
        },
        contactListHeader: {
            display: 'block',
            width: '50%',
            borderBottom: '1px solid',
            paddingBottom: '12px',
            color: global.colors.dark.color1,
            borderColor: global.colors.dark.color1,
            textTransform: 'uppercase',
            fontSize: '14px',
            fontWeight: 'bold'
        },
        arrowUp: {
            width: 0,
            height: 0,
            position: 'absolute',
            top: '-10px',
            right: '20px',
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderBottom: '10px solid',
            borderBottomColor: global.colors.light.white
        },
        downNavItem: {
            color: global.colors.dark.color1,
            margin: '20px 0',
            ':hover': {
                opacity: '.8'
            },
            ...navLinksOverrides

        },
        dropdownIconContainer: {
            fill: navLinksOverrides.color || global.colors.dark.color1,
            display: 'inline-block',
            width: '10%',
            verticalAlign: 'middle'
        },
        menuIcon: {
            width: '18px',
            height: '18px',
        },
        contactLinkContainer: {
            display: 'inline-block',
            marginLeft: '5px',
            width: '85%',
            verticalAlign: 'middle'
        },
        contactDownNavText: {
            fontSize: '12px',
            lineHeight: '12px',
            padding: '10px 12px 10px 5px',
            ...navLinksOverrides

        },
        dropDownlink: {
            fontSize: '15px',
            ...navLinksOverrides
        },
        /** Cart styles **/
        desktopCartInfernoComponent: {
            ":hover ": {
                "@media(hover:hover)": {
                    ".cartDropDown": {
                        display: 'block'
                    },
                    ".casper": {
                        display: 'block'
                    }
                }
            },
            ".casper": {
                position: 'absolute',
                top: '0',
                right: '0',
                height: '60px',
                width: '425px',
                background: 'transparent',
                display: 'none'
            },
            ".headerNavLink": {
                width: "fit-content",
                ...navListItem,
                ".active": {
                    borderBottom: "3px solid",
                    transition: "100ms ease",
                    borderColor: 'inherit'
                },
                ...generatePseudoMediaQuery(
                    '@media ( max-width:991px )',
                    ['largeTablet', 'smallTablet', 'mobile'],
                    {
                        display: "block",
                        color: global.colors.dark.color1,
                        paddingTop: '4px',
                        paddingBottom: '4px',
                        margin: "25px 0px"
                    }, inEditor
                ),
                ...navLinksOverrides
            },

            ".cartLink": {
                color: global.colors.accent.color1
            },
            ".cartDropDown": {
                display: 'none',
                cursor: 'default',
                position: 'absolute',
                top: '0',
                right: '-1px',
                width: '425px',
                height: "460px",
                background: 'white',
                zIndex: '50',
                paddingRight: "0",
                border: global.border
            },
            ".cartContainer": {
                height: '350px',
                overflowY: 'auto',
                width: '100%',
                borderBottom: global.border
            },
            ".cartContainerFooter": {
                marginTop: '20px',
                textAlign: 'center'
            },
            ".checkoutLink": {
                display: 'block',
                ...global.button1
            },
            ".viewCartLink": {
                display: 'block',
                color: global.colors.dark.color1
            },
            ".checkRightPanel": {
                padding: '30px',
                alignItems: "stretch",
                backgroundColor: "transparent",
                color: global.colors.dark.color1,
                display: "flex",
                flex: "1",
                flexDirection: "row",
                fontSize: "14px",
                justifyContent: "center",
                lineHeight: "20px",
                paddingTop: "30px"
            },
            ".cart-item-container": {
                padding: '15px'
            },
            ".change-message-bg": {
                backgroundColor: '#e3f2fd'
            },
            ".change-message-container": {
                borderTop: `1px solid ${global.colors.light.color2}`,
                paddingTop: '10px',
                fontFamily: global.fontFamily.secondaryFont
            },
            ".change-message-text": {
                textTransform: 'none',
                fontSize: '14px',
                color: global.colors.dark.color2
            },
            ".change-message-action": {
                display: 'block'
            },
            ".change-message-link": {
                fontSize: '16px',
                display: 'inline-block',
                margin: "5px 10px 0 0",
                cursor: 'pointer'
            },

            ".chkRightPanelContainer": {
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "100%",
                width: "100%"
            },

            ".chkTitle1_mobileTitle1": {
                borderBottom: "2px none #667785",
                color: global.colors.dark.color1,
                display: "none",
                flex: "0 auto",
                flexDirection: "row",
                fontSize: "32px",
                fontWeight: "400",
                lineHeight: "36px",
                marginBottom: "10px",
                marginTop: "0px",
                paddingBottom: "20px",
                width: "100%"
            },

            ".mobileUnderline_titleUnderline": {
                backgroundColor: global.backgroundColors.dark.color1,
                borderRadius: "5px",
                color: global.colors.dark.color1,
                display: "none",
                fontSize: "14px",
                height: "3px",
                lineHeight: "20px",
                marginBottom: "20px",
                width: "45%"
            },

            ".chkGridheadercontainer": {
                color: global.colors.dark.color1,
                display: "flex",
                fontSize: "14px",
                justifyContent: "flex-end",
                lineHeight: "20px",
                marginBottom: "15px"
            },

            ".chkProdgridimgcontainer": {
                alignItems: "flex-start",
                color: global.colors.dark.color1,
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                justifyContent: "center",
                lineHeight: "20px",
                maxWidth: "18%",
                textAlign: "center",
                width: "20%"
            },

            ".chkGridprice_th": {
                alignItems: "flex-start",
                color: global.colors.dark.color1,
                display: "flex",
                flex: "0 auto",
                fontFamily: global.fontFamily.secondaryFont,
                fontSize: "11px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                paddingLeft: "0px",
                width: "125px"
            },

            ".chkGridqty_chkUnitprice_th": {
                alignItems: "flex-start",
                color: global.colors.dark.color1,
                display: "flex",
                flex: "1",
                fontFamily: global.fontFamily.secondaryFont,
                fontSize: "11px",
                justifyContent: "center",
                lineHeight: "20px",
                textAlign: "center"
            },

            ".chkGridsubtotal_th": {
                color: global.colors.dark.color1,
                display: "flex",
                flex: "1",
                fontFamily: global.fontFamily.secondaryFont,
                fontSize: "11px",
                justifyContent: "flex-end",
                lineHeight: "20px",
                textAlign: "center"
            },

            ".chkProdgrid": {
                color: global.colors.dark.color1,
                display: "flex",
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "20px",
                position: "relative",
            },

            ".chkProdgridimg": {
                border: "0",
                color: global.colors.dark.color1,
                display: "inline-block",
                fontSize: "14px",
                height: "60px",
                width: "60px",
                lineHeight: "20px",
                maxWidth: "100%",
                textAlign: "center",
                verticalAlign: "middle",
                marginBottom: '10px'

            },

            ".chkRemovelink": {
                backgroundColor: "transparent",
                color: "#b4bbc3",
                fontSize: "10px",
                lineHeight: "20px",
                maxWidth: "100%",
                textAlign: "center",
                paddingLeft: '7px'
            },

            ".divBlock_2": {
                color: global.colors.dark.color1,
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                lineHeight: "20px",
                width: "225px"
            },

            ".chkGridprice": {
                alignItems: "flex-start",
                color: global.colors.dark.color1,
                display: "flex",
                flex: "0 auto",
                fontSize: "12px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                paddingLeft: "0px",
                width: "225px"
            },
            ".subTitle": {
                fontSize: '10px',
                color: global.colors.dark.color2
            },
            ".chkGridprice_chkGridpricenote": {
                alignItems: "flex-start",
                color: "#118bef",
                display: "flex",
                flex: "0 auto",
                fontSize: "12px",
                justifyContent: "flex-start",
                lineHeight: "16px",
                paddingLeft: "0px",
                width: "auto"
            },

            ".chkGridqty_chkUnitprice_strikethrough": {
                alignItems: "flex-start",
                color: "#667785",
                display: "flex",
                flex: "0 auto",
                fontSize: "12px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                textAlign: "center",
                textDecoration: "line-through"
            },

            ".actualprice_chkGridqty_chkUnitprice": {
                alignItems: "flex-start",
                color: "#118bef",
                display: "flex",
                flex: "1",
                fontSize: "14px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                textAlign: "center"
            },

            ".chkGridqty": {
                alignItems: "flex-start",
                color: "#667785",
                display: "flex",
                flex: "1",
                fontSize: "14px",
                justifyContent: "flex-end",
                lineHeight: "20px",
                textAlign: "center"
            },

            ".counterIndicator": {
                backgroundColor: "transparent",
                color: "#667785",
                fontSize: "14px",
                lineHeight: "20px",
                padding: "4px 9px",
                textAlign: "center",
                textDecoration: "none"
            },

            ".counternumber": {
                border: global.border,
                color: "#667785",
                flex: "0 auto",
                fontSize: "14px",
                lineHeight: "20px",
                padding: "4px 9px 3px",
                textAlign: "center"
            },

            ".chkGridsubtotal": {
                color: global.colors.dark.color2,
                display: "flex",
                flex: "1",
                fontSize: "14px",
                lineHeight: "20px"
            },

            ".progresscontainer": {
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                paddingTop: "10px",
                position: "relative"
            },

            ".truck": {
                border: "0",
                color: global.colors.dark.color1,
                display: "inline-block",
                fontSize: "14px",
                lineHeight: "20px",
                maxWidth: "55px",
                position: "absolute",
                right: "0px",
                top: "0px",
                verticalAlign: "middle"
            },

            ".progresstext": {
                borderBottomColor: "#000",
                borderBottomWidth: "3px",
                borderStyle: "none",
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                paddingBottom: "10px",
                paddingRight: "80px"
            },

            ".progressline_titleUnderline": {
                backgroundColor: global.backgroundColors.dark.color1,
                borderRadius: "5px",
                color: global.colors.dark.color1,
                fontSize: "14px",
                height: "3px",
                lineHeight: "20px",
                marginBottom: "15px",
                width: "100%"
            },

            ".cartToggleContainer": {
                color: global.colors.dark.color1,
                display: "none",
                fontSize: "14px",
                lineHeight: "20px"
            },
            ".cartTitleSub": {
                color: global.colors.dark.color1,
                fontSize: "32px",
                fontWeight: "bold",
                lineHeight: "36px",
                marginBottom: "10px",
                marginTop: "20px"
            },

            ".toggleLinkblock_wInlineBlock": {
                backgroundColor: "transparent",
                color: global.colors.dark.color1,
                display: "inline-block",
                fontSize: "14px",
                lineHeight: "20px",
                maxWidth: "100%"
            },
            ".toggleCollapsed": {
                border: "0",
                color: global.colors.dark.color1,
                display: "inline-block",
                fontSize: "14px",
                lineHeight: "20px",
                maxWidth: "100%",
                verticalAlign: "middle"
            }
        },
        noBorder: {
            borderBottom: '0px solid transparent',
            ":hover": {
                borderBottom: '0px solid transparent',
            }
        },



    });

};

export default HeaderStyles4
