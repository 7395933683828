import React from 'react';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';
import {generateLink} from '../../../utils/generateLink';
import _ from 'lodash';
import generateAddressString from '../../../utils/generateAddressString';


import DropDownNavLink2 from './dropdown-nav-item-1';

//Scripts
import toggleDropdown from '../../scripts/toggle-dropdown';

import SVG from '../../../svg'
import Elements from '../../../elements';

export default class HeaderNavLink1 extends Component {

    constructor(props) {
        super(props);
    }

    findLocations = (locations) => {

        let locationIndex = _.findIndex(locations, function (o) {
            return o.primaryLocation = true;
        });

        return locationIndex || 0

    };

    render() {

        const toggleId = id(toggleDropdown);

        let navItem;

        let activeClass = '';

        const {data, styles, site} = this.props;

        const slug = _.get(this.props, 'page.slug');
        const url = _.get(this.props, 'data.link.url');

        if (slug === url && slug && url) {

            activeClass = " active"
        }

        if (data.dropDownNav) {


            navItem =
                <li className={ css(styles.navListItem) }

                    data-target={ "#dropdown" + this.props.id}
                    key={ "navItem" + this.props.id }>
                    {/** Caret **/}
                    <div className={ css(styles.dropdownContainer) } id={ "dropdown" + toggleId}>
                        <SVG.ArrowHeadOpenDown attributes={
                            {
                                "data-dropdown": toggleId,
                                "data-category": "dropdown"
                            }
                        } className={ styles.caret }/>

                        <span className={ css(styles.dropDownLaunch) }
                        >{data.text}</span>
                    </div>
                    <ul data-category="dropdown"
                        data-dropdown={ toggleId }
                        className={ css(styles.dropDownNavList) }>
                        { data
                            .dropDownNav
                            .map((dropDownNavItem, index) => {
                                return (
                                    <DropDownNavLink2 key={ index }
                                                      global={ this.props.global }
                                                      data={ dropDownNavItem }
                                                      styles={ styles }/>
                                )
                            })
                        }
                    </ul>
                </li>;
        }

        else if (data.contactLink) {

            let locations = _.get(site, 'locations'),
                pl = 0,
                displayEmail = _.get(data, 'emailLinkSettings.display'),
                displayAddress = _.get(data, 'addressLinkSettings.display'),
                displayPhone = _.get(data, 'phoneLinkSettings.display'),
                emailGaEvent = _.get(data, 'emailLinkSettings.gaEvent') || {},
                phoneGaEvent = _.get(data, 'phoneLinkSettings.gaEvent') || {},
                addressGaEvent = _.get(data, 'addressLinkSettings.gaEvent') || {};
            if (locations && locations.length > 0 ) {
                if (displayEmail || displayAddress || displayPhone) {

                    pl = this.findLocations(locations);
                    let primeLocation = locations[pl];

                    navItem =
                        <li className={ css(styles.navListItem) }

                            data-target={ "#dropdown" + this.props.id}
                            key={ "navItem" + this.props.id }>
                            {/** Caret **/}
                            <div id={ "dropdown" + toggleId}>
                                <SVG.ArrowHeadOpenDown attributes={
                                    {
                                        "data-dropdown": toggleId,
                                        "data-category": "dropdown"
                                    }
                                } className={ styles.caret }/>

                                <span className={ css(styles.dropDownLaunch) }
                                >{data.text}</span>
                            </div>
                            <ul data-category="dropdown"
                                data-dropdown={ toggleId }
                                className={ css(styles.dropDownNavList) }>

                                {/** Phone **/}
                                { primeLocation.phone && displayPhone &&
                                <li className={ "dropdown-nav-item-2 " + css(styles.dropDownNavItem) }
                                    key={ this.props.index }>
                                    <Elements.WebLink data={ {
                                        link: {
                                            linkType: 'phoneNumber',
                                            url: primeLocation.phone,
                                            gaEvent: phoneGaEvent

                                        },
                                        text: primeLocation.phone
                                    } }/>
                                </li>
                                }
                                {/** Email **/}
                                { primeLocation.emailAddress && displayEmail &&
                                <li className={ "dropdown-nav-item-2 " + css(styles.dropDownNavItem) }
                                    key={ this.props.index }>
                                <span className={ css(styles.dropDownNavLinkText) }>
                                <Elements.WebLink data={ {
                                    link: {
                                        linkType: 'email',
                                        url: primeLocation.emailAddress,
                                        gaEvent: emailGaEvent
                                    },
                                    text: primeLocation.emailAddress
                                } }/>
                                </span>
                                </li>
                                }
                                {/** Address **/}
                                { primeLocation.address && displayAddress &&
                                <li className={ "dropdown-nav-item-2 " + css(styles.dropDownNavItem) }
                                    key={ this.props.index }>
                                    <Elements.WebLink data={ {
                                        link: {
                                            linkType: 'googleMap',
                                            url: generateAddressString(primeLocation),
                                            gaEvent: addressGaEvent
                                        },
                                        text: `${primeLocation.address} ${ primeLocation.city }, ${primeLocation.state} ${primeLocation.country} ${ primeLocation.zip }`
                                    } }/>
                                </li>
                                }
                            </ul>
                        </li>
                }
            }
            else navItem = null

        }

        else {
            navItem =
                <li className={ css(styles.navListItem) + activeClass }
                    key={ "navItem" + this.props.id }>
                    <span className={ css(styles.navListItemLink) }>
                        <Elements.WebLink data={ {...data} }/>
                    </span>
                </li>;
        }
        return (
            <div key={ "div" + this.props.id }
                 className={ "header-nav-link-2 " + css(styles.navItemContainer) }>
                { navItem }
            </div>
        )
    }
}
