export default function StyleObject(global, data) {

    const heightAnimation = {
        'from': {
            opacity:'0'
        },
        'to': {
            opaicty:"1"
        }
    };

    return {
        ".notification-box": {
            width:'100%',
            height:'fit-content',
            padding:'20px',
            border:`1px solid ${global.colors.dark.color3}`,
            color:global.colors.dark.color3

        },
        ".error": {
            backgroundColor:'#ffe7e7'
        },
        ".success": {
            backgroundColor:'#f3fff4'
        },
        ".animate": {
            animationName: [heightAnimation],
            animationDuration: '1s, 1200ms',
            animationIterationCount: '1',
        }
    }

}
