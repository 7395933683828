import { fromJS } from "immutable";
import { createReducer } from "redux-act";
import {
  setConversionPoints,
  setMailingLists,
  setSiteDataPages,
  setPageType
} from "../actions/editorActions";

const initialState = fromJS({
  conversionPoints: [],
  mailingLists: [],
  pages: [],
  pageType: ""
});

export const siteDataReducer = createReducer(
  {
    [setConversionPoints]: (state, conversionPoints) => state.merge({ conversionPoints }),

    [setMailingLists]: (state, mailingLists) => state.merge({ mailingLists }),

    [setSiteDataPages]: (state, pages) => state.merge({ pages }),

    [setPageType]: (state, pageType) => state.merge({ pageType })
  },
  initialState
);
