import { generatePseudoMediaQuery } from "../../../elements/style-functions/pseudo-media-queries";

export default function addToCartStyleObject(global, data, inEditor) {
	

	return {
    '.gridBuyingOptions': {
      lineHeight: '19px',
    },
		".gridBuyingOptions .quick-buy-qty-stepper-container": {
			width: '40%',
			textAlign: 'center',
			verticalAlign: 'middle',
			display: 'inline-flex',
			justifyContent:'space-around',
			transition:'background 1s ease',
      height: data.columns === 6 ? '35px' : '55px',
      backgroundColor: global.colors.dark.color3,
      lineHeight: '19px',
      opacity: '0.9',
      ':hover': {
        opacity: '0.7'
      }
    },
    ".modalBuyingOptions .quick-buy-qty-stepper-container": {
      width: '50%',
      textAlign: 'center',
      verticalAlign: 'middle',
      display: 'inline-flex',
      justifyContent: 'space-around',
      transition: 'background 1s ease',
      height: data.columns  === 6 ? '35px' : '55px',
      backgroundColor: global.colors.dark.color3,
      opacity: '0.9',
      ':hover': {
        opacity: '0.7'
      }
    },
		".quick-buy-button": {
			cursor: "pointer",
			display: "inline-block",
			textAlign: "center",
			color:'#fff',
			height:'100%',
			backgroundColor: global.colors.dark.black,
			transition: 'ease 400ms',
			border: 'none',
			fontSize:'10px',
			// lineHeight:'9px',
			width:'100%',
      zIndex:'1',
      ...generatePseudoMediaQuery(
        "@media(max-width:470px)",
        ['mobile'],
        {
          padding: '13px 35px'
        },
        inEditor
      ),
			".disabled": {
				opacity:'.3'
			}
		}
	}
	
	
}
