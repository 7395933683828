import checkoutHeader01 from './checkout-process/checkout-header/checkout-header-01/checkout-header-01';
import checkoutFlow from './checkout-process/checkout-flow/checkout-flow';
import checkoutFlow2 from './checkout-process/checkout-flow-02/checkout-flow';
import cart from './cart/cart-page';
import confirmationPage from './confirmation-page/confirmation-page';
import ProductGrid01 from './product-grid/product-grid-01/product-grid-01';
import ProductGrid02 from './product-grid/product-grid-02/product-grid-01';
import ProductDetailPage01 from './product-detail-page/product-detail-page-01/product-detail-page';

const ecommerce = {
	checkoutHeader01: checkoutHeader01,
	checkoutFlow: checkoutFlow,
	checkoutFlow2: checkoutFlow2,
	cart: cart,
	confirmationPage: confirmationPage,
	ProductGrid01,
	ProductGrid02,
	ProductDetailPage01
};

export default ecommerce;