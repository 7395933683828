import React from 'react';
import {StyleSheet, css} from "@convertly/css";
import Component from '../../../rootComponent';
import {id} from '@convertly/thor';


import ReactHTMLParser from "@convertly/react-html-parser";

//Styles
import sliderStyles from './circular-nav-icons-large-styles';


//Component
export default class SliderNav01 extends Component {

    constructor(props) {

        super(props);

    };

    componentDidMount() {

    }

    render() {
        if (this.props.editor) {
            return this.editor();
        }

        this.styles = sliderStyles(this.props.global, this.props.data);

        let navIcons = [];


        for( let i = 0; i < parseInt(this.props.sliderSettings.numOfSlides); i ++ ) {
            let liClassName = i === 0 ? " active" : "";
            navIcons.push(
                <li className={ css( this.styles.convertlyControlNavItem ) + liClassName + " controlNav" }
                    data-target={ i }/>
            )
        }



        return (



            <ol className={ css(this.styles.convertlyControlNav) } id="contorlNav">
                { navIcons }
            </ol>

        )
    }
}
