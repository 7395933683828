import React from "react";
import PropTypes from "prop-types";
import { saveProductAction } from "../../../containers/StoreFront/ProductDetail/actions";
import PillButton from "../../Button/pillButton";
import { SaveButton } from "../../Button/SaveButton";
import Options from "../../../containers/Editor/components/editor/options";
import { css } from "@convertly/css";
import styles from "../../StoreFront/css-storefront-styles";
import { browserHistory } from "react-router";
import { fromJS } from "immutable";
import { MenuWrapper } from "./MenuWrapper";
import { GenericModal } from "components/GenericModal";
import { NoDisplayOnMobile } from "components/commonCSS/utilityCSS";

const inlineCss = {
  spacing: {
    marginRight: "20px"
  },
  button: {
    cursor: "pointer"
  },
  container: {
    width: "100%"
  }
};

export default class ProductDetailMenu extends React.Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      showLoadIcon: false,
      snapshot: null,
      loaded: false
    };

    this.save = this.save.bind(this);
    this.backToOrders = this.backToOrders.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      const { order } = this.props;
      this.setState({
        snapshot: fromJS(order),
        loaded: true,
        order
      });
    }
  }

  backToOrders() {
    browserHistory.push("/storefront/orders");
  }

  save(e) {
    const { order, snapshot } = this.state;
    this.props.save(order, snapshot);
  }

  updateStatus(e) {
    const value = e.target.value;
    const { order } = this.state;
    this.setState({
      order: order.set("orderStatus", value)
    });
  }

  renderModal() {
    return <div>Do you want to return the items of this order to your inventory?</div>;
  }

  closeModal(updateInventory) {
    console.log("close", updateInventory);
    const { order, snapshot } = this.state;
    this.props.save(order, snapshot, updateInventory, true);
  }

  /**
   *
   * @returns {*}
   */
  render() {
    const { snapshot, loaded, order } = this.state;
    const { saving, showUpdateInventoryModal } = this.props;

    if (!loaded) {
      return null;
    }

    const options = [
      /*{
		    optionsItemTitle: 'Add Comment',
		    optionsCallback: this.props.editOption
		  },*/
      {
        optionsItemTitle: "Print Order",
        optionsCallback: this.props.makePrimary
      },
      {
        optionsItemTitle: "Delete",
        optionsCallback: this.props.deleteOption
      }
    ];

    const statusOptions = [
      "Pending",
      "Shipped",
      "Fulfilled",
      "Backordered",
      "Cancelled",
      "Returned"
    ];

    const dirty = !snapshot.equals(fromJS(order));

    return (
      <MenuWrapper className="animated fadeIn" style={inlineCss.container}>
        {showUpdateInventoryModal && (
          <GenericModal
            render={this.renderModal}
            title="Return to Stock"
            leftButtonLabel="No"
            rightButtonLabel="Yes"
            onClose={this.closeModal.bind(this, false)}
            onSave={this.closeModal.bind(this, true)}
          />
        )}
        <NoDisplayOnMobile>
          <SaveButton disabled={!dirty} action={this.save} loading={saving} />
          <PillButton label="Back" action={this.backToOrders} />
          {false && <Options options={options} button resetIndex={() => {}} />}
          <div style={{ float: "right", marginRight: "20px" }}>
            <p className={css(styles.filterText)}>Status </p>

            <select
              value={order.get("orderStatus")}
              onChange={this.updateStatus}
              className={css(styles.filterCategories) + " form-control"}
            >
              {statusOptions.map((opt, index) => (
                <option key={index}>{opt}</option>
              ))}
            </select>
          </div>
        </NoDisplayOnMobile>
      </MenuWrapper>
    );
  }
}

ProductDetailMenu.propTypes = {
  save: PropTypes.func,
  order: PropTypes.object,
  saving: PropTypes.bool,
  loading: PropTypes.bool,
  showUpdateInventoryModal: PropTypes.bool,
  closeModal: PropTypes.func
};

ProductDetailMenu.defaultProps = {
  showUpdateInventoryModal: false,
  save: () => {},
  order: fromJS({}),
  saving: false,
  loading: false,
  closeModal: () => {}
};

ProductDetailMenu.contextTypes = {
  store: PropTypes.object
};
