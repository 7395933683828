import { store } from "app/app";
import _ from "lodash";
import { fromJS } from "immutable";

const emptyArray = fromJS([]);

export function can(rawThing) {
  const permissions = store
    .getState()
    .get("auth")
    .get("user")
    .get("accessPermissions");
  let rawCheck = permissions.get(rawThing)

  if(rawCheck) return rawCheck;

  let thing = rawThing.split("_");
  //if something like edit_blog_posts we join blog_posts and leave out the edit
  if(thing.length > 2) {
    thing.splice(1, 0).join("_");
  }
  else {
    thing = thing[thing.length - 1];
  }

  return permissions.get("view" + "_" + thing) || permissions.get("edit" + "_" + thing);
}

/**
 * @param permission
 * @returns {*}
 */
export function permissionCheck(permission) {
  if (!permission) return true;

  const permissions = store.getState().getIn(["auth", "user", "accessPermissions"]) || fromJS({});

  if (_.isFunction(permission)) {
    return permission(check_permission, check_role);
  }

  return false;

  function check_permission(action, thing) {
    return permissions.get("view" + "_" + thing) || permissions.get("edit" + "_" + thing);
  }

  function check_role(role) {
    return is(role);
  }
}

export function is(role) {
  const roles = store.getState().getIn(["auth", "user", "roles"]) || emptyArray;
  return roles.includes(role);
}

export function isPartner() {
  return is("partner");
}

export function isUser() {
  return is("user");
}
