import React from 'react';
import PropTypes from 'prop-types';
import Component from '../../rootComponent';
import { css } from "@convertly/css";
import defaultGlobal from '../../themes/global.js';
import ReactHTMLParser from "@convertly/react-html-parser";

//Styles
import headingStyles from './heading3-styles';

//Utils
import generateId from '../../utils/generate-id';

/**
 * Class controls imports style functions that allows for user to override the css in the CMS editor for their site.
 **/
class Heading3 extends Component {

    constructor ( props ) {

        super( props );


    }

    render() {

        const inEditor = this.inEditor();
        /**
         * Calls Stylesheet function
         **/
        this.styles = headingStyles( this.props.global, this.props.data, this.props.data.headingStyle, inEditor );

		/**
         * Checks for default class name, if it exists, add to class
         **/

        let className = '';
        let text = ReactHTMLParser( this.props.data.text );
        let aphroditeClass = css ( this.styles.heading3 );

        if ( this.animatedClassName ) {
            className += " " + this.animatedClassName
        }
        className += aphroditeClass;

        const { animated } = this.props.data
        const inlineStyle = this.generateStyleAttribute( )
        /**
         * Loads h3 element
         **/

        if( this.props.editable ) {
			return (

				<h3 { ...this.generateDataEditorAttribute() }
					{...generateId ( this.props.elementId )}
                    { ...this.generateCustomAttributes() }
					onClick={( e ) => {
						this.launchElementalEditor ( 'text', e );
					}}
					onMouseOver={ this.addEditableOutline  }
					onMouseOut={  this.removeEditableOutline }
					{ ...inlineStyle }
					data-animated={animated}
					className={ className }>
					{ text }</h3>

			);
		} else {
			return (
				<h3 className={ className }>{ text }</h3>
			);
		}


    }
}


/**
 * Default Props for rendering when no data is being passed
 **/
Heading3.defaultProps = {
    data: {
        text: "",
        bold: false,
        italic: false,
        underline: false,
        align: 'center',
        fontSize: '',
        color: ''
    },
    global : defaultGlobal,
	editable: true,
};

/**
 * Connects element to context for cms editor
 **/
Heading3.contextTypes = {
    store: PropTypes.object
};

export default Heading3

/**
 Component BoilerPlate

{
    "el": "Elements.Heading3",
    "data": {
        "text": "Your text here",
        "userOverrides": {

        }
    },
    "children": [],
    "sort": 900
}

 **/
