/**
 * IMPORTANT
 *
 * This file is in the process of being deleted
 *
 * The available elements are being moved to API and can be maintained via Titan.
 *
 * Elements that have been entered into Titan are marked with // done
 *
 * DO NOT ADD ADDITIONAL ELEMENTS TO THIS FILE
 */

import { createAction } from "redux-act";

import button from "components/icons/addEl_button.svg";
import form from "components/icons/addEl_form.svg";
import grid from "components/icons/addEl_grid.svg";
import image from "components/icons/addEl_image.svg";
import list from "components/icons/addEl_list.svg";
import slider from "components/icons/addEl_slider.svg";
import text from "components/icons/addEl_text.svg";
import title from "components/icons/addEl_title.svg";
import ShapeIcon from "components/icons/shapeIcon.svg";
import button_white from "components/icons/addEl_button_white.svg";
import form_white from "components/icons/addEl_form_white.svg";
import grid_white from "components/icons/addEl_grid_white.svg";
import image_white from "components/icons/addEl_image_white.svg";
import list_white from "components/icons/addEl_list_white.svg";
import text_white from "components/icons/addEl_text_white.svg";
import title_white from "components/icons/addEl_title_white.svg";
import { graph } from "utils";

export const setElementsToAdd = createAction("EDITOR: Set elements to add to page");

export const loadElementsToAdd = () => async dispatch => {
  const query = `{
    PageElementCategories {
      id
      label
      elements {
        id
        thumbnail
        type
        editorType
        label
        element
      }
    }
  }`;

  const res = await graph({ query });
  const elementsFromAPI = [];
  res.PageElementCategories.forEach(el => {
    el.elements.forEach(elem => {
      elementsFromAPI.push(elem);
    });
  });
  elementsFromAPI.map(element => {
    element.element = JSON.parse(element.element);
    return element;
  });

  await dispatch(setElementsToAdd(elementsFromAPI));
};

export const elements = [
  /** Column **/
  // done
  {
    svg: text,
    svgInverted: text_white,
    label: "Column",
    editorType: "column",
    element: {
      el: "Elements.Column",
      data: { userOverrides: {} },
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: text,
    label: "Blog Category List",
    type: "CategoryList",
    editorType: "blogCategoryList",
    element: {
      el: "Blog.CategoryList",
      data: {},
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: text,
    label: "Blog Published Date",
    type: "PublishedDate",
    editorType: "blogPublishedDate",
    element: {
      el: "Blog.PublishedDate",
      data: {},
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: text,
    label: "Blog Tag List",
    type: "TagList",
    editorType: "blogTagList",
    element: {
      el: "Blog.TagList",
      data: {},
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: text,
    label: "Blog Author Info",
    type: "AuthorInfo",
    editorType: "blogAuthorInfo",
    element: {
      el: "Blog.AuthorInfo",
      data: {},
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: text,
    label: "Blog Title",
    type: "Title",
    editorType: "blogTitle",
    element: {
      el: "Blog.Title",
      data: {},
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: text,
    label: "Blog Subtitle",
    type: "Subtitle",
    editorType: "blogSubtitle",
    element: {
      el: "Blog.Subtitle",
      data: {},
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: text,
    label: "Blog Preview Slider",
    type: "BlogPreviewSlider",
    editorType: "blogPreviewSlider",
    element: {
      el: "Blog.BlogPreviewSlider",
      data: {},
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: text,
    label: "Blog Collection Header Text",
    type: "BlogCollectionHeaderText",
    editorType: "blogCollectionHeaderText",
    element: {
      el: "Blog.BlogCollectionHeaderText",
      data: {},
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: text,
    label: "HTML Content Renderer",
    type: "HTMLContentRenderer",
    editorType: "htmlContentRenderer",
    element: {
      el: "Elements.HTMLContentRenderer",
      data: {},
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: text,
    label: "Blog Banner",
    type: "BlogBanner",
    editorType: "blogBanner",
    element: {
      el: "Blog.Banner",
      data: {},
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: text,
    label: "Blog Author List",
    type: "BlogAuthorList",
    editorType: "blogAuthorList",
    element: {
      el: "Blog.AuthorList",
      data: {},
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: text,
    label: "Blog Landing",
    type: "BlogLanding",
    editorType: "blogLanding",
    element: {
      el: "Blog.BlogLanding",
      data: {},
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: text,
    label: "Blog Pagination",
    type: "BlogPagination",
    editorType: "blogPagination",
    element: {
      el: "Blog.Pagination",
      data: {},
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: text,
    label: "Blog Search Bar",
    type: "BlogSearchBar",
    editorType: "blogSearchBar",
    element: {
      el: "Blog.SearchBar",
      data: {},
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: text,
    label: "Blog Paragraph",
    type: "BlogParagraph",
    editorType: "blogParagraph",
    element: {
      el: "Blog.Paragraph",
      data: {},
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: text,
    label: "Blog Post Preview",
    type: "BlogPostPreview",
    editorType: "blogPostPreview",
    element: {
      el: "Blog.BlogPostPreview",
      data: {},
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: text,
    label: "Blog Preview Media",
    type: "BlogPreviewMedia",
    editorType: "blogPreviewMedia",
    element: {
      el: "Blog.BlogPreviewMedia",
      data: {},
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: text,
    label: "Blog Link",
    type: "BlogLink",
    editorType: "blogLink",
    element: {
      el: "Blog.BlogLink",
      data: {},
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: text,
    label: "Blog Filter Dropdown",
    type: "BlogFilterDropdown",
    editorType: "blogFilterDropdown",
    element: {
      el: "Blog.FilterDropdown",
      data: {},
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: text,
    svgInverted: null,
    label: "Blog List",
    type: "BlogList",
    editorType: "blogList",
    element: {
      el: "Blog.BlogList",
      data: {
        userOverrides: {
          width: "500px",
          height: "150px",
          backgroundColor: "#cccccc",
        },
      },
      editable: false,
      children: [],
      sort: 1,
    },
  },

  // done
  {
    svg: text,
    svgInverted: null,
    label: "Blog Div Wrapper",
    type: "BlogDivWrapper",
    editorType: "blogDivWrapper",
    element: {
      el: "Blog.DivWrapper",
      data: {
        userOverrides: {
          width: "500px",
          height: "150px",
          backgroundColor: "#cccccc",
        },
      },
      editable: false,
      children: [],
      sort: 1,
    },
  },

  // done
  {
    svg: text,
    label: "Locations Widget",
    type: "MultipleLocationsMapOverlay",
    editorType: "locationsWidget",
    element: {
      el: "Widgets.MultipleLocationsMapOverlay",
      data: {},
      sort: 1,
      children: [],
    },
  },

  /** Row **/
  // done
  {
    svg: text,
    svgInverted: text_white,
    label: "Row",
    editorType: "row",
    element: {
      el: "Elements.Row",
      data: { userOverrides: {} },
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: text,
    svgInverted: text_white,
    label: "Newsletter",
    editorType: "newsletterSignup",
    element: {
      el: "Widgets.Newsletter03",
      data: {
        userOverrides: {},
        placeholder: {
          text: "your email address",
        },
        submitButton: {
          text: "SUBSCRIBE",
        },
      },
      sort: 1,
      children: [],
    },
  },

  /** Image tag **/
  // done
  {
    svg: image,
    svgInverted: image_white,
    enabledForBanners: true,
    label: "Image",
    editorType: "image",
    element: {
      el: "Elements.Image",
      data: { src: "http://placehold.it/224x180", userOverrides: { width: "100%" } },
      editable: false,
      children: [],
      sort: 1,
    },
  },

  /** Wrapped Image
  {
    svg: image,
    svgInverted: image_white,
    label: "Wrapped Image",
    editorType: "wrapperDiv",
    element: {
      el: "Elements.WrapperDiv",
      data: {
        userOverrides: {},
      },
      editable: false,
      children: [
        {
          el: "Elements.Image",
          data: { src: "http://placehold.it/224x180", userOverrides: {} },
          editable: false,
          children: [],
          sort: 1,
        },
      ],
      sort: 1,
    },
  },
  **/
  /** Paragraph tag **/
  // done
  {
    svg: text,
    svgInverted: text_white,
    label: "Text Block",
    editorType: "text",
    element: {
      el: "Elements.Paragraph",
      data: { text: "Insert your paragraph", userOverrides: {} },
      sort: 1,
      children: [],
    },
  },

  {
    svg: text,
    label: "Div with Overlay",
    type: "DivWithOverlay",
    editorType: "divWithOverlay",
    element: {
      el: "Elements.DivWithOverlay",
      data: {},
      sort: 1,
      children: [],
    },
  },

  /** WebLink **/
  // done
  {
    svg: text,
    svgInverted: text_white,
    label: "Web Link",
    editorType: "weblink",
    element: {
      el: "Elements.WebLink",
      data: { text: "Link Text", userOverrides: {} },
      sort: 1,
      children: [],
    },
  },

  /** Button **/
  // done
  {
    svg:
      "https://s3.amazonaws.com/convertlyimguploadeast/convertly/element-thumbnails/addEl_button.svg",
    svgInverted: button_white,
    label: "Button",
    enabledForBanners: false,
    editorType: "button",
    element: {
      el: "Elements.Button",
      data: {
        text: "Your text here",
        buttonType: "button1",
        link: {
          linkType: "internal",
          url: "/shop",
        },
        userOverrides: {},
      },
      editable: false,
      children: [],
      sort: 1,
    },
  },

  /** Heading 1 tag **/
  // done
  {
    svg: title,
    svgInverted: title_white,
    enabledForBanners: true,
    label: "H1",
    editorType: "text",
    element: {
      el: "Elements.Heading1",
      data: { text: "Insert your title", userOverrides: {} },
      sort: 1,
      children: [],
    },
  },

  /** Heading 2 **/
  // done
  {
    svg: title,
    svgInverted: title_white,
    enabledForBanners: false,
    label: "H2",
    editorType: "text",
    element: {
      el: "Elements.Heading2",
      data: { text: "Insert your title", userOverrides: {} },
      sort: 1,
      children: [],
    },
  },

  /** Heading 3 **/
  // done
  {
    svg: title,
    svgInverted: title_white,
    enabledForBanners: false,
    label: "H3",
    editorType: "text",
    element: {
      el: "Elements.Heading3",
      data: { text: "Insert your title", userOverrides: {} },
      sort: 1,
      children: [],
    },
  },

  /** Heading 4 **/
  // done
  {
    svg: title,
    svgInverted: title_white,
    enabledForBanners: false,
    label: "H4",
    type: "title",
    editorType: "text",
    element: {
      el: "Elements.Heading4",
      data: { text: "Insert your title", userOverrides: {} },
      sort: 1,
      children: [],
    },
  },

  /** Wrapper Div **/
  // done
  {
    svg: text,
    svgInverted: null,
    label: "Wrapper Div",
    editorType: "wrapperDiv",
    element: {
      el: "Elements.WrapperDiv",
      data: {
        userOverrides: {
          display: "flex",
          width: "100%",
          height: "150px",
          padding: "10px",
          backgroundColor: "#cccccc",
        },
      },
      editable: false,
      children: [],
      sort: 1,
    },
  },

  /** Product Grid **/
  // done
  {
    svg: form,
    svgInverted: form_white,
    label: "Product Grid",
    editorType: "ProductGallery",
    element: {
      sort: 1,
      data: {
        disablePagination: false,
        rows: 4,
        disableFiltration: false,
        defaultCategories: [],
        columns: 2,
        disableSort: false,
      },
      children: [],
      el: "ecommerce.ProductGrid02",
    },
  },

  /** Form **/
  // done
  {
    svg: form,
    svgInverted: form_white,
    label: "Form",
    editorType: "formEditor",
    element: {
      el: "Elements.Form",
      data: {
        userOverrides: {},
        formElements: [],
      },
      editable: false,
      children: [],
      sort: 1,
    },
  },

  /** Header **/
  // done
  {
    svg: title,
    svgInverted: title_white,
    enabledForBanners: false,
    disabled: true,
    label: "Header",
    editorType: "header",
    element: {
      el: "SiteHeader.Header",
      sort: 1,
      children: [],
    },
  },

  /** Footer **/
  // done
  {
    svg: title,
    svgInverted: title_white,
    enabledForBanners: false,
    disabled: true,
    label: "Footer",
    editorType: "footer",
    element: {
      el: "SiteFooter",
      data: { text: "Insert your title", userOverrides: { width: 210 } },
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: title,
    svgInverted: title_white,
    enabledForBanners: false,
    disabled: true,
    label: "Slide",
    editorType: "slide",
    element: {
      el: "convertlySlider.fullWidthHeroSliderOverlay",
      data: {},
      sort: 1,
      children: [],
    },
  },
  /** Slider **/
  // done
  {
    svg: slider,
    enabledForBanners: false,
    label: "Slider",
    editorType: "ConvertlySlide",
    element: {
      el: "convertlySlider.convertlySlider",
      sort: 1,
      data: {
        userOverrides: {
          fontFamily: "primaryFont",
          height: "100vh",
          marginTop: "-1px",
          minHeight: "600px",
          textAlign: "left",
        },
        alignContent: "left",
        autoScroll: false,
        hoverpause: false,
        numOfSlides: 4,
        timer: 3000,
        transition: "fade",
      },
      children: [
        {
          data: {
            userOverrides: {
              height: "100%",
            },
          },
          sort: 2,
          el: "convertlySlider.sliderContainer",
          children: [
            {
              el: "convertlySlider.fullWidthHeroSliderOverlay",
              data: {
                userOverrides: {
                  backgroundImage: "B317D7A25DF5B-slider_default_bg_1.jpg",
                },
                slideNumber: 0,
                sort: 1,
                children: [],
                editable: false,
              },
            },
          ],
        },
        {
          el: "Elements.Container",
          sort: 1,
          data: {},
          children: [
            {
              el: "Elements.Row",
              sort: 1,
              data: {},
              children: [
                {
                  el: "Elements.Column",
                  sort: 1,
                  data: {},
                  children: [
                    {
                      sort: 1,
                      data: { userOverrides: { top: 130 } },
                      el: "convertlySlider.circularNavIcons",
                      children: [],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },

  // done
  {
    disabled: true,
    svg: ShapeIcon,
    label: "Shape",
    enabledForBanners: true,
    editorType: "svg",
    element: {
      sort: 1,
      data: {
        userOverrides: {
          width: "80px",
        },
        code: "",
      },
      el: "Elements.SVGCodeWrapper",
    },
  },

  /** Video **/
  // done
  {
    svg: image,
    svgInverted: image_white,
    label: "Video",
    editorType: "video",
    enabledForBanners: false,
    element: {
      sort: 1,
      data: {
        userOverrides: {
          position: "relative",
        },
        placeHolderImage: {
          src: "http://placehold.it/224x180",
        },
        src: "",
        videoType: "youtube",
      },
      children: [],
      el: "Elements.Video",
    },
  },

  /** Wrapped Video
  {
    svg: image,
    svgInverted: image_white,
    label: "Wrapped Video",
    editorType: "wrapperDiv",
    enabledForBanners: false,
    element: {
      el: "Elements.WrapperDiv",
      data: {
        userOverrides: {},
      },
      editable: false,
      children: [
        {
          sort: 1,
          data: {
            userOverrides: {
              position: "relative",
            },
            placeHolderImage: {
              src: "http://placehold.it/224x180",
            },
            src: "",
            videoType: "youtube",
          },
          children: [],
          el: "Elements.Video",
        },
      ],
      sort: 1,
    },
  },
  **/

  // done
  {
    svg: "",
    disabled: true,
    label: "New Element",
    editorType: "newElement",
    element: {
      el: "editor.newElement",
      data: { userOverrides: {} },
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: text,
    svgInverted: text_white,
    label: "Divider",
    editorType: "divider",
    element: {
      el: "Elements.Divider",
      data: { userOverrides: {} },
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: "",
    disabled: true,
    label: "Section",
    editorType: "background",
    element: {
      el: "Elements.Container",
      data: { userOverrides: {} },
      sort: 1,
      children: [],
    },
  },

  // done
  {
    svg: "",
    disabled: true,
    label: "Header",
    editorType: "header",
    element: {
      el: "SiteHeader",
      data: { userOverrides: {} },
      sort: 1,
      children: [],
    },
  },

  {
    svg: list,
    svgInverted: list_white,
    label: "list",
    disabled: true,
  },

  {
    svg: grid,
    svgInverted: grid_white,
    label: "grid",
    disabled: true,
  },
];
