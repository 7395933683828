import { StyleSheet } from "@convertly/css";
import UserOverrides from "../../elements/style-functions/user-overrides";

export const VerticalDividerStyles = ({ global, data, inEditor }) => {
  const userOverrides = new UserOverrides(global, data, inEditor);

  return StyleSheet.create({
    verticalDivider: {
      height: "10px",
      "border-color": global.colors.dark.black,
      border: "1px solid",
      display: "inline-block",
      width: "0px",
      ...UserOverrides(global, data, inEditor)
    }
  });
};
