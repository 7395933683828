import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const ToggleComponent = styled.div`
  border: 1px solid #ccc;
  width: 28px;
  height: 16px;
  border-radius: 8px;
  background-color: #349886;
  margin: 3px 6px 0 6px;
  align-self: flex-start;
  display: inline-block;
  cursor: pointer;
  ${props =>
    (props.disabled || !props.enabled) &&
    `
		cursor: not-allowed;
	`}
  ${props =>
    !props.active &&
    `
		background: lightgray;
	`}
`;

const Switch = styled.div`
  border: 1px solid #999;
  width: 12px;
  height: 12px;
  left: 1px;
  top: 1px;
  border-radius: 8px;
  background: white;
  position: relative;
  transition: left 0.1s ease-in-out;
  ${props =>
    props.active &&
    `
	left: 12px;
	`}
`;

const Icon = styled.i`
  font-size: 22px;
  display: inline-block;
  position: relative;
  left: 8px;
`;

const Spinner = () => <Icon className="far fa-spin fa-spinner" />;

export class Toggle extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (!this.props.enabled || this.props.disabled) {
      return;
    }
    this.props.callback(!this.props.on);
  }

  render() {
    const { disabled = "false" } = this.props;

    if (disabled) {
      return <Spinner />;
    }

    const props = {
      active: this.props.on,
      disabled: this.props.disabled,
      enabled: this.props.enabled,
    };

    return (
      <ToggleComponent className={"toggle"} {...props} onClick={this.handleClick}>
        <Switch {...props} />
      </ToggleComponent>
    );
  }
}

Toggle.propTypes = {
  enabled: PropTypes.bool,
  disabled: PropTypes.bool,
  on: PropTypes.bool,
  callback: PropTypes.func,
};

Toggle.defaultProps = {
  enabled: true,
  disabled: false,
  on: false,
  callback: () => {},
};

export default Toggle;
