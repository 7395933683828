export default function StyleObject(global, data) {
	
	return {
		".drop-down-container": {
			position: 'relative',
			cursor:'pointer'
		},
		".drop-down-content": {
			display: 'none',
			position: 'absolute',
			background: "#fff",
			height: 'max-content',
			minWidth: '125px',
			zIndex: '1',
			".active": {
				display: 'block'
			}
		},
		".drop-down-list-item": {
			cursor: 'pointer',
			padding:'10px',
			backgroundColor: '#fff',
			transition: 'all 400ms ease',
			color: '#828588',
			fontSize: '12px',
			":hover": {
				backgroundColor: global.colors.light.color2
			},
			".active": {
				backgroundColor: global.colors.light.color2
			},
			".disabled": {
				backgroundColor: global.colors.dark.color3,
				fontStyle: 'italic',
				cursor: "not-allowed"
			}
		}
	}
	
	
}