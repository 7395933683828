import signUp from './sign-up-with-input/sign-up-react-container';
import signUpFreeTrial from './sign-up-free-trial/sign-up-react-container';
import gearSpinner from './gear-spinner/gear-spinner'
import homePageHero from './homepage-hero/home-page-hero'
import hoveringImageSection from './hovering-image-section/hovering-image-section';
import stickyFooter from './convertly-sticky-footer/convertly-sticky-footer';
import phoneOverlay from './phone-overlay/phone-overlay';
import pricingInfo from './pricing-info/pricing-info';
import viewFeatureList from './view-feature-list/view-feature-list';
import customLi from './custom-li/list-element.js';



const convertlyWidgets = {
    signUp,
    signUpFreeTrial,
    gearSpinner,
    homePageHero,
    hoveringImageSection,
    stickyFooter,
    phoneOverlay,
    pricingInfo,
    viewFeatureList,
    customLi
};

export default convertlyWidgets;
