import { fromJS } from "immutable";
import { createReducer } from "redux-act";

import { setImageToEdit } from "../actions/editorActions";

const initialState = fromJS({
  filename: "",
  params: {},
  cb: () => {}
});

export const imageEditorReducer = createReducer(
  {
    [setImageToEdit]: (state, { filename, params, cb }) => state.merge({ filename, params, cb })
  },
  initialState
);
