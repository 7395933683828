import { graph } from "utils";
import _ from "lodash";

/**
 *
 * @param {string} email
 * @returns {Promise<*|{}>}
 */
export const getUserByEmail = async ({ email }) => {
  let query = `
	query($email:String!) {
		getUserByEmail(email:$email) {
			id, email, firstName, lastName, partner, roles
		}
	}
	`;

  let variables = {
    email
  };

  let res = await graph({ query, variables });

  return _.get(res, "getUserByEmail") || {};
};

export const addSiteToAccount = async ({ accountId, siteId }) => {
  const query = `
		mutation($accountId:ID!,$siteId:ID!) {
			AddSiteToAccount(accountId:$accountId,siteId:$siteId) {
				id
			}
		}
	`;

  const variables = {
    accountId,
    siteId
  };

  return await graph({ query, variables });
};
