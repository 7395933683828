import _ from 'lodash';

const getHeaderPosition = ( localData, pageData, inEditor ) => {

  if(inEditor || !pageData) {
    return 'static';
  }

  const headerPositionOverwrite = _.get(pageData, 'options.header.headerPositionOverwrite');

  if(headerPositionOverwrite) {
    return headerPositionOverwrite;
  } else {
    return localData;
  }

};

export default getHeaderPosition;
