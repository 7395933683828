/*global Inferno, $ */
/* eslint-disable */
import {include} from '@convertly/thor';

const Script = (id) => {

    /** Imports **/
    const Graph = include('../../../utils/graph', 'Graph');
    const Dispatcher = include('../../../utils/dispatcher', 'Dispatcher');
    const ConvertToCurrency = include('../../../utils/ecomm-utils/convert-to-currency-ii', 'ConvertToCurrency');
    const RemoveItem = include('../../../utils/ecomm-utils/remove-cart-item-ii', 'RemoveItem');
    const ChangeQuantity = include('../../../utils/ecomm-utils/cart-stepper-function-ii', 'ChangeQuantity');
    const CartItemInferno = include('./cart-item', 'CartItemInferno');


    return (id, clientImageSrc, siteLink) => {
        const root = document.getElementById(id);
        /** Init Imports **/
            //utils
        const convertToCurrency = ConvertToCurrency();
        const dispatch = Dispatcher(id);

        /** Inferno Components **/
        const CartItem = CartItemInferno(dispatch, convertToCurrency, clientImageSrc, siteLink);


        const Cart = Inferno.createClass({

            getInitialState: function () {

                return {
                    items: [],
                    subTotal: parseFloat('0.00'),
                    cartSubTotal: 0,
                    shippingData: {},
                    mobileCartClass: " cart-open",
                    mobileCartClassActive: true,
                };
            },

            /** Pull initial Cart Info **/
            getCartInfo: function () {
                dispatch.on('cart', (cart) => {
                    this.setState({items: cart});
                });

            },

            /**
             * Updates the subtotal of all orderd items
             * @return {[int]}  Total of all items/shipping/tax
             */
            updateSubTotal: function () {
                let subTotal = this.state.cartSubTotal;
                if (this.state.shippingData.amount_local) {
                    subTotal = parseInt(this.state.cartSubTotal) + parseInt(this.state.shippingData.amount_local);
                }
                this.setState({
                    subTotal: subTotal,
                    montizedsubTotal: convertToCurrency(subTotal),
                });
            },
            /**
             * Updates Cart total price on cart event
             * @return {[cart total price]} Cart Total price
             */
            getCartTotal: function () {

                dispatch.on('cart', (cart) => {
                    console.log(cart);
                    let cartTotal = 0;
                    cart.map((cartItem) => {
                        let cartItemPrice = parseFloat(cartItem.finalPrice * cartItem.quantity);
                        cartTotal += cartItemPrice;
                        return cartTotal
                    });
                    this.setState({cartSubTotal: cartTotal});
                    this.updateSubTotal();
                })
            },





            /**
             * Inits sub total calculation cart on component mount
             * @return {[int]}
             */
            /** Opens and closes cart flow **/
            toggleMobileCartClass: function () {
                let mobileCartClass = !this.state.mobileCartClassActive;
                if (mobileCartClass) {
                    this.setState({
                        mobileCartClassActive: mobileCartClass,
                        mobileCartClass: ' cart-open'
                    })
                }
                else {
                    this.setState({
                        mobileCartClassActive: mobileCartClass,
                        mobileCartClass: ' cart-not-open '
                    })
                }

            },


            componentDidMount() {
                this.getCartTotal();
                this.getCartInfo();

            },


            render: function () {

                let cartItems;

                if (this.state.items) {
                    cartItems =
                        this.state.items.map((item) => {
                            return(
                                <CartItem rootDiv={ root }
                                          items={this.state.items}
                                          item={ item } />
                            )
                        })
                }

                let freeShipping;
                let includeFreeShipping = false;

                if (includeFreeShipping) {
                    freeShipping = <div className={ `${root.className} progresscontainer` }>
                        <img className={ `${root.className} truck` } src="images/free_shipping.png"/>
                        <div className={ `${root.className} progresstext` }>You’re only $50.36 away from
                            free
                            shipping!
                        </div>
                        <div className={ `${root.className} progressline_titleUnderline` }/>
                    </div>
                }

                if (this.state.items.length === 0) {
                    return (
                        <div className={ `${root.className} flexMiddle_middleRight` }>
                            <div className={ `${root.className} checkRightPanel ${ this.state.mobileCartClass }` }>
                                <div className={ `${root.className} empty-cart` }>
                                    <div>Your cart is empty</div>
                                    <a href={ `${siteLink}/` } className={ `${root.className} empty-cart-link` }>Continue Shopping</a>
                                </div>
                            </div>
                        </div>
                    )
                }
                return (
                    <div className={ `${root.className} flexMiddle_middleRight` }>
                        <div className={ root.className + " mobile-cart-dropdown" }>
                            <p className={ root.className + " chkTitle1_mobileTitle1" }>
                                Your Selection

                                {/*<strong>Your Cart
                                Subtotal</strong> {convertToCurrency(this.state.cartSubTotal)}</p>*/}
                            </p>

                            {/** Mobile Cart Toggle **/}
                            <p className={ `${root.className} mobileCartToggle` }
                               onClick={ this.toggleMobileCartClass }>
                                { this.state.mobileCartClassActive ? "COLLAPSE" : "EXPAND" }</p>

                        </div>
                        <div className={ `${root.className} checkRightPanel ${ this.state.mobileCartClass }` }>
                            <div className={ `${root.className} chkRightPanelContainer` }><h2
                                className={ `${root.className} chkTitle1_desktopTitle1` }>Your Cart</h2>
                                <div className={ `${root.className} mobileUnderline_titleUnderline` }/>
                                <div className={ `${root.className} chkPricinggrid` }>
                                    <div className={ `${root.className} chkGridheadercontainer` }>
                                        <div className={ `${root.className} chkProdgridimgcontainer` }/>
                                        { /** Cart Title **/ }
                                        <div className={ `${root.className} chkGridprice_th` }>Item</div>
                                        <div className={ `${root.className} chkGridqty_chkUnitprice_th` }>
                                            <span className={ `${root.className} chkGridqty_chkUnitprice_th-text` }>Unit Price</span>
                                        </div>
                                        <div className={ `${root.className} chkGrdQty` }>QTY</div>
                                        <div className={ `${root.className} chkGridsubtotal_th` }>Subtotal</div>
                                    </div>

                                    { /** Cart Items **/ }
                                    { cartItems }

                                </div>

                                {/** Free shipping  */}
                                { freeShipping }

                            </div>
                            <div className={ `${root.className} cartToggleContainer` }>
                                <h2 className={ `${root.className} chkTitle1_1tdlcr3` }>Your Cart Subtotal:</h2>
                                <h2 className={ `${root.className} cartTitleSub` }>{ convertToCurrency(this.state.subTotal) }</h2>
                            </div>
                        </div>
                    </div>
                )
            }

        });

        Inferno.render(<Cart />, root);

    };

};

export default Script;
