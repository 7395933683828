import React from 'react';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import defaultGlobal from '../../../themes/global.js';
import ReactHTMLParser from "@convertly/react-html-parser";

// Styles
import styles from '../form-styles'


export default class Input extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        this.styles = styles(this.props.global, this.props.data);

        return (
            <input
                className={`${css(this.styles.formInfernoContainer)} input`}
                type={'text'}
                placeholder={this.props.data.placeholder}
            />
        )
    }
};

Input.defaultProps = {
    global: defaultGlobal,
    data: {}
}








