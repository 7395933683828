import { connect } from "react-redux";
import Index from "../index";

import { toggleEditor } from "containers/Editor/actions/editorActions";
import {activateSite} from "../../../containers/PartnersDashboard2/actions";

const mapStateToProps = state => {
  const Editor = state.get("editor").editorReducer;
  const Auth = state.get("auth");
  //const Dashboard  = state.get("PartnerDashboard").Dashboard

  return {
    bannerEditorIsOpen: state.get("marketing").Banners.get("bannerEditorIsOpen"),
    isMenuOpen: state.get("global").get("isMenuOpen"),
    showNavigationMenu: Editor.get("showNavigationMenu"),
    isUsingNewEditor: Editor.get("isUsingNewEditor"),
    saving: Editor.get("saving"),
    page: Editor.get("page"),
    currentSite: Editor.get("currentSite"),
    activeSite: Auth.get("activeSite"),
    sites: Auth.get("sites"),
    user: Auth.get("user"),
    editorIsOpen: !!Editor.get("page")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    setUsingNewEditor: async val => await dispatch(toggleEditor(val)),
    activateSite: async args => await dispatch(activateSite(args)),
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);

export default Container;
