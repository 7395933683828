import { StyleSheet } from '@convertly/css';

const Styles = ( global, data ) => {

    const spinThatGear = {
        'from': {
            transform: "rotate(0deg)",
        },

        'to': {
            transform: "rotate(360deg)",
        }
    };


    return StyleSheet.create({

        gearSpinnerContainer: {
            position: "relative",
            background: "transparent",
            overflow:'hidden',
            height:'500px',
            verticalAlign:'bottom',
            paddingTop:'50px',
            '@media(max-width:767px)': {
                paddingTop:'0px',
                height:'275px'
            },
            '@media(max-width:577px)': {
                display:'none'
            }
        },
        spinningGear: {
            animationName: [spinThatGear],
            animationDuration: '150s',
            animationIterationCount: 'infinite',
            animationTimingFunction: 'linear'
        }


    })

};

export default Styles;