import {StyleSheet} from '@convertly/css';
import { TodoStyleObjects } from './style-index';

const Styles = (global, data, todoUiName) => {

	const todoStyles = TodoStyleObjects[todoUiName] ? TodoStyleObjects[todoUiName](global, data) : {};

	return StyleSheet.create({
		todoWrapper: {
			...todoStyles
		}
	})
	
};

export default Styles;