import styled from 'styled-components';
import { GLOBALS } from "../../../styles/global-default-styles";

export const HelpFormHeaderWrapper = styled.div`
    background:${GLOBALS.colorPrimary};
    padding-bottom:20px;
    display:flex;
    flex-direction:column;
    justify-items:center;
    border-radius: 3px 3px 0 0;
    padding: 20px 0;
    position: relative;

`;

export const CloseIconContainer = styled.div`
    position:absolute;
    top:0;
    display:flex;
    justify-content:flex-end;
    padding: 10px;
    padding-bottom: 0;
    width:100%;
    display: flex;
    justify-content: space-between;
    flex-direction:row-reverse;
`;
export const HelpIcon = styled.svg`
   margin:0 auto;
`;

export const HeaderControlIcons = styled.svg`
  cursor:pointer;
`;

export const HeaderIconContainer = styled.div``;
