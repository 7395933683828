import { StyleSheet } from '@convertly/css';

const Styles = ( global, data ) => {


    return StyleSheet.create({
        scrollToTop: {
            position: 'fixed',
            background: global.backgroundColors.light.white,
            bottom: '20px',
            right: '20px',
            borderRadius: "5px",
            cursor: 'pointer',
            transition: 'all .2s linear',
            boxShadow: "0px 0px 3px 2px rgba(0, 0, 0, .2)",
            height: '30px',
            width: '30px',
            padding: '5px',
            zIndex:'10',
            "@media (max-width:991px) ": {
                height: '45px',
                width: '45px',
                padding: '9px',
            },
            ".closed": {
                bottom:'-50px'
            }
        },
        cheveron: {
            height:'20px',
            width:'20px',
            fill:global.backgroundColors.dark.color3,
            "@media (max-width:991px) ": {
                height:'25px',
                width:'25px',
            },
        }

    })

}

export default Styles;
