import React from 'react';
import styled from 'styled-components'
import {GLOBALS} from "../../styles/global-default-styles";


export const HelpWrapper = styled.div`
    position:fixed;
    bottom:20px;
    right:20px;
    z-index: 6;
`;

/** Launcher Icon **/
export const LauncherIcon = styled.div`
    background:${GLOBALS.colorPrimary};
    position:relative;
    z-index:2;
    transition:300ms box-shadow ease;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
    cursor:pointer;
    box-shadow:0px 0px 6px rgba(0, 0, 0, 0.5);
    transition:opacity 300ms ease;
    opacity:${ ({active}) => active ? 0 : 1 };
    `;


/** FORM Container **/

export const FeedbackWrapper = styled.div`
    height:480px;
    visibility:${ ({active}) => active ? 'visible' : 'hidden' };
    z-index:${ ({active}) => active ? 3 : 1 };
    width:20vw;
    bottom: -5px;
    right: -5px;
    position: absolute;
    background:#fff;
    transition:opacity 300ms ease 0ms ${({active}) => !active && `, visibility 0ms ease 300ms`};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    ${ ({active}) => `opacity: ${active ? '1': '0' };` }
    cursor:default;
`;

/** Content area **/
export const FeedbackContentWrapper = styled.div`
  height:290px;
  padding:20px;`;

export const TextWrapper = styled.div`
  margin-bottom:15px;`;
