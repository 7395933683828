import { createAction } from "redux-act";
import _ from "lodash";
import { v1 as uuid } from "uuid";
import { store } from "app/app";
import Logger from "utils/logger";
import { graph, purgeSiteCache } from "app/utils";
import { setCurrentSite } from "containers/Authentication/actions";
import { uploadImages, loadSite } from "app/services/sites";
import { Alerts } from "services/Alerts";
import { Notifications } from "services/Notifications";
import { can } from "containers/Authentication/PermissionsCheck";

import { setSiteData } from "containers/Editor/actions/editorActions";
import {
  saveAdwordsConversionPoints,
  fetchAllPages,
  fetchPixels,
} from "containers/Editor/Settings/actions";

export * from "./favicon";

import { saveLocations } from "./locations";
import { saveBusinessInfo } from "./businessInfo";

export const setPixelData = createAction("Site Settings: Set pixel data");
export const updateConversionPoints = createAction("Site Settings: Set site conversion points");
export const updateCustomPixels = createAction("Site Settings: Set site custom pixels");
export const setAllPages = createAction("Site Settings: Set all pages");
export const setLoadedStatus = createAction("Site Settings: Set loaded flag");
export const setTab = createAction("Site Settings: Set current tab");
export const setLoadingPixels = createAction("Site Settings: Set loading pixels flag");
export const setSavingState = createAction("Site Settings: Set saving flag");
export const resetState = createAction("Site Settings: Reset store");
export const setValue = createAction("Site Settings: Set general store value");
export const setSnapshot = createAction("Site Settings: Set site snapshot");
export const setGeneratingPreviewDomain = createAction(
  "Site Settings: Set flag for generating a new preview domain"
);
export const setPublishing = createAction("Site Settings: Set Publishing flag");

export const publishAll = () => async dispatch => {
  const publishing = store
    .getState()
    .get("siteSettings")
    .Settings.get("publishing");

  if (publishing) {
    return;
  }

  if (!(await Alerts.confirm("Publish all pages?"))) {
    return;
  }

  await dispatch(setPublishing(true));
  const note = await Notifications.info({
    message: "Publishing all pages",
    sticky: true,
    title: "Processing...",
  });

  const site = store.getState().getIn(["auth", "activeSite"]);

  const query = `
    mutation($siteId:ID!){
      publishAll(siteId:$siteId)
    }
  `;

  const variables = {
    siteId: site.get("id"),
  };

  await graph({ query, variables });

  await dispatch(setPublishing(false));
  await Notifications.clear(note);
  await Notifications.success({
    title: "Publish Complete",
  });
};

export const generatePreviewDomain = () => async dispatch => {
  if (
    !(await Alerts.confirm(
      "Refresh the preview url? Any previous preview urls will no longer be valid."
    ))
  ) {
    return;
  }

  await dispatch(setGeneratingPreviewDomain(true));

  const site = store
    .getState()
    .get("auth")
    .get("activeSite");

  const query = `
		query( $userId: ID!, $siteId: String ) {
		  Account(id:$userId) {
			site(siteId:$siteId) {
			  generatePreviewDomain
			}
		  }
		}`;

  const variables = {
    userId: store
      .getState()
      .get("auth")
      .get("user")
      .get("id"),
    siteId: site.get("id"),
  };

  const response = await graph({ query, variables });
  const preview = _.get(response, "Account.site.generatePreviewDomain");
  const Site = site.set("previewDomain", preview);

  await dispatch(setSiteData(Site)); // Editor reducer
  await dispatch(setCurrentSite(Site)); // Auth reducer

  await dispatch(setGeneratingPreviewDomain(false));
};

export function takeSnapshot() {
  return async dispatch => {
    const site = store
      .getState()
      .get("auth")
      .get("activeSite");
    await dispatch(setSnapshot(site));
  };
}

export function revertSnapshot() {
  return async dispatch => {
    const snapshot = store
      .getState()
      .get("siteSettings")
      .Settings.get("snapshot");
    await dispatch(setCurrentSite(snapshot));
  };
}

export function updateValue(field, value) {
  return async dispatch => {
    const site = store
      .getState()
      .get("auth")
      .get("activeSite");
    const newSite = site.merge({
      [field]: value,
    });
    await dispatch(setCurrentSite(newSite));
  };
}

export const saveSettings = () => async dispatch => {
  await dispatch(setSavingState(true));
  let site = store.getState().getIn(["auth", "activeSite"]);

  try {
    let favicon;

    await dispatch(setSnapshot(site));

    await Promise.all([dispatch(saveLocations()), dispatch(saveBusinessInfo())]);

    site = site.toJS();

    const Settings = store.getState().get("siteSettings").Settings;
    const newFavicon = Settings.get("faviconIsNew");

    if (newFavicon) {
      const file = Settings.get("favicon");
      await dispatch(uploadImages([file], false));
      favicon = Settings.get("faviconName");
    } else {
      favicon = site.favicon;
    }

    const logo = _.get(site, "logo") || {};

    const query = `
			mutation( $id:ID!, $site:siteInput ) {
			  editSite( id: $id, site: $site ) {
				id, siteId, domain, theme, timeZone, ssl, goToTop, googleAnalytics, facebookUrl,
					twitterUrl, siteName, siteDescription, name, siteCategory, favicon, locked
			  }
			}
			`;

    const siteSettingsData = {
      facebookUrl: site.facebookUrl,
      twitterUrl: site.twitterUrl,
      instagramUrl: site.instagramUrl,
      timeZone: site.timeZone,
      siteCategory: site.siteCategory,
      siteDescription: site.siteDescription,
      siteName: site.siteName,
      siteKeywords: site.pageKeywords,
      siteTitle: site.siteTitle,
      domain: site.domain,
      ssl: site.on,
      goToTop: site.goToTop,
      favicon,
      googleAnalytics: site.googleAnalytics,
      facebookPixelId: site.facebookPixelId,
      mailchimp: site.mailchimp,
      locked: site.locked,
      logo,
      doNotIndex: site.doNotIndex,
      lazyLoadImages: site.lazyLoadImages,
    };

    const variables = {
      id: site.siteId,
      site: siteSettingsData,
    };

    await graph({ query, variables });
    await dispatch(loadSite(site));
  } catch (e) {
    Logger.info("Error", e.message);
    await dispatch(loadSite(site));
  }

  await purgeSiteCache(`${site.previewDomain}.convertly.io`);

  if (site.domain) {
    await purgeSiteCache(site.domain);
  }

  await dispatch(setSavingState(false));
};

export const savePixels = () => async dispatch => {
  await dispatch(setSavingState(true));

  const noteId = await Notifications.add({
    sticky: true,
    title: "Saving",
    message:
      "Saving your Conversion Points and Custom Pixels. Please do not reload or navigate from this page while saving.",
    type: "success",
  });

  const userId = store.getState().getIn(["auth", "user", "sub"]);
  const site = store.getState().getIn(["auth", "activeSite"]);
  const siteId = site.get("siteId");
  const domain = site.get("domain");
  const facebookPixelId = can("view_facebook")
    ? await fetchFacebookPixelID({ userId, siteId })
    : "";
  const Settings = store.getState().get("siteSettings").Settings;
  let pixels = Settings.get("customPixels").toJS();
  pixels = pixels.map(p => {
    delete p.fullscreen;
    p.id = p.id || uuid();
    return p;
  });

  let points = Settings.get("conversionPoints")
    .toJS()
    .map(point => {
      point.id = point.id || uuid();
      return point;
    });

  const query = `
    query ($id: ID!, $siteId: String, $pixels:[PixelInput], $points:[PixelInput]${can("view_facebook") ? " ,$facebookPixelId:ID, $subId: ID" : ""}) {
      Account(id: $id) {
        site(siteId: $siteId) {
          updatePixels(pixels:$pixels,points:$points${can("view_facebook") ? ",facebookPixelId:$facebookPixelId,subId:$subId" : ""})
        }
      }
    }
  `;

  const facebookVariables = {
    subId: userId,
    facebookPixelId,
  }

  let variables = {
    id: userId,
    siteId,
    pixels,
    points,
  };

  if(can("view_facebook")) {
    variables = {
      ...variables,
      ...facebookVariables
    }
  }

  await graph({ query, variables });
  await purgeSiteCache(domain);
  await purgeSiteCache(`${site.get("previewDomain")}.convertly.io`);

  const { ConversionPoints } = await fetchPixels(siteId);
  await dispatch(updateConversionPoints(ConversionPoints));

  await Notifications.clear(noteId);

  await dispatch(setSavingState(false));
};

/**
 * Loads conversion points, custom pixels and all pages
 * Sets loaded flag to only load once
 * @returns {function(*)}
 */
export const loadPixels = () => async dispatch => {

  const loaded = store
    .getState()
    .get("siteSettings")
    .Settings.get("loaded");

  if (loaded) {
    return;
  }

  await dispatch(setLoadingPixels(true));

  const userId = store.getState().getIn(["auth", "user", "sub"]);
  const siteId = store.getState().getIn(["auth", "activeSite", "siteId"]);

  if(can("view_facebook")) {
    if(await facebookIsLoggedIn({subId: userId,})) {
      await facebookInit({subId: userId, siteId});
    }
  }

  const response = await Promise.all([fetchAllPages({ userId, siteId }), fetchPixels(siteId)]);

  const { AllPages } = response[0];
  const { ConversionPoints, CustomPixels } = response[1];

  const sortedPages = _.sortBy(AllPages, o => o.name);

  await Promise.all([
    dispatch(setAllPages(sortedPages)),
    dispatch(updateConversionPoints(ConversionPoints)),
    dispatch(updateCustomPixels(CustomPixels)),
    dispatch(setLoadedStatus(true)),
    dispatch(setLoadingPixels(false)),
  ]);
};

export const fetchFacebookPixelID = async ({ userId, siteId }) => {
  const query = `
    query getPixelId($subId: ID!, $siteId: ID!) {
      getAllFacebookPixels(subId: $subId, siteId: $siteId) {
        id
        name
        code
      }
    }
  `;

  const variables = {
    siteId: siteId,
    subId: userId,
  };

  const res = await graph(
    {
      query,
      variables,
    },
    true
  );

  if (_.get(res, "data.getAllFacebookPixels")) {
    return res.data.getAllFacebookPixels[0].id;
  }
};

export const facebookIsLoggedIn = async ({subId}) => {
  const query = `
    query ($subId: ID!) {
      isLoggedInFacebook(subId: $subId)
    }
  `;

  const variables = {
    subId,
  };

  const res = await graph({ query, variables }, true);

  if(_.get(res, 'data.isLoggedInFacebook')) {
    return res.data.isLoggedInFacebook;
  }
};

export const facebookInit = async ({subId, siteId}) => { 
  const query = `
    mutation ($subId: ID!, $siteId: ID!) {
      facebookInit(subId: $subId, siteId: $siteId) {
        SiteVisitors
        AddedToCart
        Purchased
        CartAbandoned
        NotConverted
      }
    }
  `;

  const variables = {
    siteId: siteId,
    subId,
  };

  const res = await graph({ query, variables }, true);

}
