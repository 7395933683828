
//given an array this finds object by value
//logic to handle immutable vs native js
export const findObjectByValue = (arr, key, value) => {



  let result = arr.filter( obj => {

    if(obj.size) {
      return obj.get(key) === value
    }
    return obj[key] === value
  } )

  return result.size ? result.get(0) : result[0]

};
