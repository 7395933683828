import { StyleSheet } from "@convertly/css";



const hoursOfOperationStyles = ( global, data ) => {

    return StyleSheet.create({
        hoverBox: {
            maxWidth: '300px',
            margin: 'auto',
            ':hover ': {
                    '.hoursBox': {
                        visibility: 'visible',
                        backgroundColor: global.colors.light.color1,
                        zIndex: 100,
                        color: global.colors.dark.color1,
                        boxShadow: '0 0 12px -3px #999',
                    },
            },
        },
        hoursBox: {
            visibility: 'hidden',
            width: '250px',
            padding: '10px',
            border: '1px solid ' + global.colors.dark.color3,
            position: 'absolute',
            margin: '0 auto',
            marginTop: '-18px',
            marginLeft: '-28px',
        },
        days: {
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
        }
    })

};

export default hoursOfOperationStyles;