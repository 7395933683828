import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { css } from "@convertly/css";
import { id } from "@convertly/thor";
import Component from "../../rootComponent";
import defaultGlobal from "../../themes/global.js";

//Styles
import imageStyles from "./image-styles";

//Utils
import { clientImage } from "../../utils/generateImage";
import { generateId } from "../../utils/generate-attribute";

import Elements from "../../elements";

import Scripts from "../scripts/common";

// Scripts
import lightbox from "./scripts/lightbox";
export default class Image extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    super.componentWillMount();
    if (!this.state || this.state.disableImageLazyLoad === undefined) {
      this.setState({ disableImageLazyLoad: this.getParentState("disableImageLazyLoad") });
    }
  }

  getSrcSet(data) {
    let srcSets = _.get(data, "srcSets");

    if (srcSets) {
      let srcSet = "";
      let sizes = "";

      /** Create attribute strings **/
      srcSets.map((img, index) => {
        let comma = ", ";
        if (index === srcSets.length - 1) {
          comma = "";
        }

        srcSet +=
          `${clientImage(img.src || data.src, { ...img.imgParams })} ${img.imgSize}w` + comma;
        sizes += `max-width(${img.viewPortSize}) ${img.imgSize}w,`;
      });

      if (srcSets[srcSets.length - 1] < 991) {
        sizes += `991w`;
        srcSet += `,${data.src} 991w 2x`;
      } else {
        sizes += `${srcSets[srcSets.length - 1].imgSize}w`;
      }

      return { srcSet, sizes: "100%" };
    }
  }

  generateSrcSet(data) {
    return data.srcSets.map(img => {
      return (
        <source
          media={`max-width(${img.viewPortSize}px)`}
          srcSet={clientImage(img.src || data.url, { ...img.imgParams })}
        />
      );
    });
  }

  generateSourceTag(data) {
    const { page, site } = this.props;

    let imgParams = {};
    if (data.imgParams) {
      imgParams = data.imgParams;
    }

    const source = clientImage(data.src, { ...imgParams });

    const attribute = {};

    const lazyLoad = _.get(site, "lazyLoadImages") || _.get(page, "lazyLoadImages");

    if (lazyLoad && !this.inEditor()) {
      attribute["data-image-source"] = source;
    } else {
      attribute["src"] = source;
    }

    return attribute;
  }

  render() {
    const { data, global, site, page } = this.props;
    const lazyLoad = _.get(site, "lazyLoadImages") || _.get(page, "lazyLoadImages");

    const inEditor = this.inEditor();

    /**
     * Calls Stylesheet function
     **/
    this.styles = imageStyles(global, data, inEditor);

    /**
     * Checks for default class name, if it exists, add to class
     **/

    let className = css(this.styles.figure);

    if (this.props.parentClass) className = ` ${this.props.parentClass}`;

    const { animated, locked, isOnBanner } = data;
    const inlineStyle = this.generateStyleAttribute();
    const events = data.gaEvent || [];

    if (animated && !this.state.inEditor) {
      className += " animated";

      inlineStyle.style.visibility = "hidden";
    }

    // disable ghost dragging of image if layer is locked
    if (isOnBanner && locked) {
      inlineStyle.style.pointerEvents = "none";
    }

    /**
     * Loads Image element
     **/

    const IdAttr = {};

    if (events.length) {
      IdAttr.id = id(Scripts, JSON.stringify(events));
    }

    const link = _.get(this.props, "data.link");
    const Image = (
      <img
        id={this.props.data.enableLightbox ? id(lightbox) : ""}
        src={
          this.state.disableImageLazyLoad || this.props.disableImageLazyLoad
            ? clientImage(this.props.data.src, data.imgParams)
            : clientImage(
                "B317D7A25DF5B-convertly-transparent-placeholder.png",
                _.get(data, "params") || {}
              )
        }
        {...this.deferImage()}
        {...this.generateCustomAttributes()}
        {...this.generateDataEditorAttribute()}
        // {...generateId(data.id)}
        {...this.getSrcSet(data)}
        {...inlineStyle}
        data-animated={animated}
        alt={data.alt || ""}
        className={className}
        title={data.title || ""}
      />
    );

    if (data.src) {
      if (!link || this.inEditor()) {
        return Image;
      }

      return (
        <Elements.WebLink {...this.props} isChild={true} notEditable={true}>
          {Image}
        </Elements.WebLink>
      );
    } else {
      return null;
    }
  }
}

Image.defaultProps = {
  data: {
    src: "light-bulb-planter.jpg",
    caption: {
      showCaption: false,
      text: "Light Bulb repurposed as Planter",
    },
    alt: "hello",
    userOverrides: {
      height: "100%",
      width: "100%",
    },
  },
  global: defaultGlobal,
};

Image.contextTypes = {
  store: PropTypes.object,
};

/**
 Component BoilerPlate

 {
     "el": "Elements.Image",
     "data": {
         "src": "hero-banner.jpg",
         "alt": "Alt text here",
         "title": "Title Text Here",
         "userOverrides": {

         }
     },
     "children": [],
     "sort": 900
 }
 **/
