import React from 'react';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import { generateLink } from '../../../utils/generateLink';

import Elements from '../../../elements';

export default class HeaderDropdownNavLink1 extends Component {

    constructor(props) {

        super(props);
        this.state = {
            dropDown: false
        };
    }

    render() {
        const { data, styles } = this.props;
        

        return (
            <li className={ "dropdown-nav-item-2 " + css( styles.dropDownNavItem ) }
                key={ this.props.index }
            >
                <Elements.WebLink data={ { ...data } } />
            </li>
        )
    }
}
