import { combineReducers } from "redux";

import editorReducer from "./reducers/editorReducer";
import { siteDataReducer } from "./reducers/SiteDataReducer";
import { imageEditorReducer } from "./reducers/ImageEditor";
import { navBarReducer } from "./reducers/navBarReducer";
import siteSettingsReducer from "./Settings/reducers";
import { locationsReducer } from "./Settings/reducers/locations";
import { businessInfoReducer } from "./Settings/reducers/businessInfo";
import { AddElementPanelReducer } from "./Panels/AddElement/reducer";
import { LayersPanelReducer } from "./Panels/Layers/reducer";
import { PagesPanelReducer } from "./Panels/Pages/reducer";
import { ThemesPanelReducer } from "./Panels/Themes/reducer";

import { AssetsPanelReducer } from "./Panels/Assets/reducer";

import { BlogsPanelReducer } from "./Panels/Blogs/reducer";

import { editorOptionsReducer } from "./EditorOptions/reducer";

const reducer = combineReducers({
  editorReducer,
  SiteData: siteDataReducer,
  ImageEditor: imageEditorReducer,
  NavBar: navBarReducer,
  Settings: siteSettingsReducer,
  Locations: locationsReducer,
  BusinessInfo: businessInfoReducer,
  AddElement: AddElementPanelReducer,
  Layers: LayersPanelReducer,
  Themes: ThemesPanelReducer,
  Pages: PagesPanelReducer,
  Assets: AssetsPanelReducer,
  // moved to own reducer to investigate speeding up
  // refresh of TextArea with typing
  //Blogs: BlogsPanelReducer,
  EditorOptions: editorOptionsReducer,
});

export { BlogsPanelReducer };

export default reducer;
