import { assign } from "./assign";
import CONSTANTS from "./constants";
export const assignUserOverridesValue = ({
  objectBeingEdited,
  editorViewportWidth,
  field,
  value,
}) => {
  let obj;
  if (!objectBeingEdited.data) {
    objectBeingEdited = objectBeingEdited.userOverrides || {};
  } else {
    obj = objectBeingEdited.data.userOverrides || {};
  }
  switch (editorViewportWidth) {
    case "mobile":
      if (value === "") {
        if (obj[CONSTANTS.mobile][field]) {
          delete obj[CONSTANTS.mobile][field];
        }
      } else {
        assign(obj, [CONSTANTS.mobile, field], value);
      }
      break;
    case "smallTablet":
      if (value === "") {
        if (obj[CONSTANTS.smallTablet][field]) {
          delete obj[CONSTANTS.smallTablet][field];
        }
      } else {
        assign(obj, [CONSTANTS.smallTablet, field], value);
      }
      break;
    case "largeTablet":
      if (value === "") {
        if (obj[CONSTANTS.largeTablet][field]) {
          delete obj[CONSTANTS.largeTablet][field];
        }
      } else {
        assign(obj, [CONSTANTS.largeTablet, field], value);
      }
      break;
    default:
      if (value === "") {
        if (obj[field]) {
          delete obj[field];
        }
      } else {
        obj[field] = value;
      }
  }
};
export default assignUserOverridesValue;
