import styled from 'styled-components';
import {GLOBALS} from "app/styles/global-default-styles";
import { StyleSheet, css } from "@convertly/css";
//TODO: Convert to styled components
export const DraggableListContainer = styled.div`
  padding:20px;
  border:1px solid ${GLOBALS.colorPrimaryDark};
`


const headerGradient = "linear-gradient(180deg, #FFF 0%, #EEE 100%)";
const buttonGradient = "linear-gradient(90deg, #8BD9B2 0%, #45AA91 100%)";

export const styles = StyleSheet.create({
  // IMAGE EDITOR //

  imageEditorFiltersContainer: {
    padding: "20px"
  },
  imageEditorFiltersGroup: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  imageEditorImageContainer: {
    border: "1px solid #000",
    height: "300px",
    backgroundSize: "cover",
    cursor: "pointer"
  },
  imageEditorFilterColorItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 0 20px 0",
    cursor: "pointer"
  },
  imageEditorFilterSwatch: {
    width: "66px",
    height: "66px",
    border: "2px solid #999",
    marginBottom: "4px",
    ":hover": {
      borderColor: "red"
    }
  },
  imageEditorFilterSwatchName: {},

  // MISC //

  plusIcon: {
    fontSize: 42,
    color: "#AAA",
    cursor: "pointer",
    lineHeight: 0
  },
  closeIcon: {
    position: "relative",
    left: "740px"
  },
  upLoadIconContainer: {
    padding: "40px 46px"
  },
  insertModule: {
    height: 200,
    border: "2px dashed #000",
    boxShadow: "0 0 0 8px #fff",
    background: "#f00"
  },
  fileUploadContainer: {
    display: "inline-block",
    height: 112,
    width: 134,
    border: "4px dashed #DDD",
    cursor: "pointer",
    backgroundColor: "#F6F6F6"
  },
  textAnchor: {
    textAlign: "center",
    cursor: "pointer",
    color: "#45aa91",
    ":hover": {
      color: "#2A6496"
    }
  },
  label: {
    fontWeight: "400"
  },
  editorPanelRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px"
  },
  editorPanelRowLabel: {
    fontWeight: "400"
  },
  formItem: {
    display: "flex",
    flexDirection: "row-reverse",
    width: "100%"
  },

  // EDITOR HEADER

  editorHeaderAnimeIconWhite: {
    border: "1px solid #444",
    background: "#FFF"
  },

  editorHeaderAnimeIconBlack: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "40px",
    height: "40px",
    borderRadius: "3px",
    background: "#000",
    cursor: "pointer",
    userSelect: "none"
  },

  // SITE SWITCHER PANEL //

  siteSwitcherContainer: {},
  siteSwitcherActiveContainer: {
    margin: "20px"
  },
  siteSwitcherActiveImg: {
    border: "1px solid #414141",
    marginBottom: "20px",
    cursor: "pointer"
  },
  siteSwitcherActiveHeader: {
    color: "#414141",
    marginBottom: "12px"
  },
  siteSwitcherAllThemesHeader: {
    background: "#f3fbf7",
    color: "#414141",
    marginBottom: "20px"
  },
  siteSwitcherAllThemesContainer: {
    margin: "20px",
    overflowY: "auto"
  },
  siteSwitcherAllThemesHeaderTab: {
    display: "inline-block",
    alignItems: "center",
    color: "#414141",
    lineHeight: "38px",
    height: "38px",
    paddingRight: "20px",
    marginLeft: "20px",
    cursor: "pointer",
    userSelect: "none"
  },
  siteSwitcherAllThemesHeaderTabActive: {
    borderBottom: "2px solid #88D7B1"
  },

  // THEME CUSTOMIZER PANEL //

  themeCustomizerPanelContainer: {
    margin: "-40px 20px 20px"
  },
  themeCustomizerHeader: {
    color: "#414141",
    marginBottom: "12px",
    fontWeight: "600"
  },
  themeCustomizerMenuItemContainer: {
    minHeight: "40px",
    border: "1px solid #E4E4E4",
    borderRadius: "3px",
    padding: "9px 10px 0 10px",
    marginBottom: "5px",
    userSelect: "none"
  },
  themeCustomizerMenuItemContainerActive: {
    backgroundColor: "#F5F8FA"
  },
  themeSiteTitleContainer: {
    marginTop: "16px"
  },
  themeCustomizerToolRow: {
    marginBottom: "20px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center"
  },
  themeCustomizerToolIncrementor: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90px"
  },

  // FORM EDITOR //

  formEditorContainer: {
    margin: "20px",
    minHeight: "700px"
  },
  formEditorFormFieldsContainer: {
    borderRadius: "3px",
    backgroundColor: "#f6f6f6",
    border: "solid 1px #e4e4e4",
    padding: "10px",
    marginBottom: "15px"
  },
  formEditorFormFieldItem: {
    height: "40px",
    padding: "0 10px",
    backgroundColor: "#FFF",
    borderRadius: "3px",
    border: "solid 1px #c6c6c6",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    marginBottom: "10px"
  },
  formEditorFormFieldItemPart: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
    userSelect: "none"
  },
  draggingIcon: {
    cursor:'drag'
  },
  draggableInputRow: {
    display: "flex",
    alignItem: "center",
    justifyContent: "space-between"
  },

  draggableOptionsTrash: {
    fontSize: "20px",
    color: "#999",
    textAlign: "center",
    ":hover": {
      color: "#F05F54"
    },
    cursor: "pointer"
  },
  headerLayoutContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: "10px",
    marginBottom: "6px"
  },
  headerLayoutOption: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "3px",
    height: "34px",
    width: "135px",
    marginBottom: "12px",
    cursor: "pointer",
    userSelect: "none",
    ":hover": {
      border: "1px solid #45AA91"
    }
  },
  headerLayoutOptionSelected: {
    border: "1px solid #45AA91"
  },

  // NAV EDITOR //

  navEditorNavFieldsContainer: {
    borderRadius: "3px",
    border: "solid 1px #e4e4e4",
    padding: "10px",
    marginBottom: "15px"
  },
  colorLabelContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px"
  },
  colorLabelSwatch: {
    width: "20px",
    height: "20px",
    borderRadius: "3px",
    marginLeft: "10px",
    cursor: "pointer"
  },
  colorLabelSwatchWhite: {
    border: "1px solid #979797"
  },
  colorLabelHex: {
    display: "flex",
    alignItems: "center"
  },
  labelFormatIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30px",
    height: "30px",
    borderRadius: "3px",
    border: "1px solid #414141",
    marginLeft: "10px"
  },

  // OPTIONS //

  optionsContainerOver: {
    position: "absolute",
    color: "white"
  },
  optionsContainer: {
    position: "relative"
  },
  optionsContainerButton: {
    position: "relative",
    display: "inline-block",
    float: "right",
    textAlign: "center",
    fontSize: "14px",
    lineHeight: "17px",
    borderRadius: "6px",
    border: "1px solid #CCC",
    padding: "0 20px",
    userSelect: "none",
    cursor: "pointer",
    outline: "none",
    alignItems: "center"
  },
  optionsEllipses: {
    fontWeight: "900",
    fontSize: "16px",
    cursor: "pointer",
    userSelect: "none",
    ":hover": {
      color: "#EEE"
    }
  },
  optionsEllipsesButton: {
    fontWeight: "900",
    fontSize: "16px",
    cursor: "pointer",
    userSelect: "none",
    ":hover": {
      color: "#999"
    }
  },

  optionsList: {
    position: "absolute",
    zIndex: "4000",
    minWidth: "160px",
    margin: "0",
    listStyle: "none",
    background: "#fff",
    color: "#333",
    boxShadow: "0 0 5px #999",
    borderRadius: "10px"
  },
  optionsListUnderlay: {
    position: "fixed",
    top: "0",
    left: "0",
    zIndex: "3900",
    width: "100%",
    height: "100%"
  },
  editMenuUnderlay: {
    position: "fixed",
    top: "0",
    left: "0",
    zIndex: "1900",
    width: "100%",
    height: "100%"
  },
  optionsItem: {
    overflow: "hidden",
    height: "36px",
    margin: "0",
    padding: "10px",
    textAlign: "left",
    borderBottom: "solid 1px #ccc",
    ":hover": {
      backgroundColor: "#EEE"
    },
    cursor: "pointer",
    userSelect: "none",
    fontSize: "14px"
  },
  optionsTopItem: {
    overflow: "hidden",
    height: "36px",
    margin: "0",
    padding: "10px",
    textAlign: "left",
    borderRadius: "10px 10px 0px 0px",
    borderBottom: "solid 1px #ccc",
    ":hover": {
      backgroundColor: "#EEE"
    },
    cursor: "pointer",
    userSelect: "none",
    fontSize: "14px"
  },
  optionsBottomItem: {
    overflow: "hidden",
    height: "36px",
    margin: "0",
    padding: "10px",
    textAlign: "left",
    borderBottom: "solid 1px #ccc",
    ":hover": {
      backgroundColor: "#EEE"
    },
    borderRadius: "0px 0px 10px 10px",
    cursor: "pointer",
    userSelect: "none",
    fontSize: "14px"
  },
  // FILE STRUCTURE MENU

  structureOptionsList: {
    position: "absolute",
    zIndex: "4000",
    minWidth: "260px",
    maxHeight: "50em",
    overflowY: "auto",
    margin: "0",
    listStyle: "none",
    background: "#fff",
    color: "#333",
    boxShadow: "0 0 5px #999"
  },
  StructureOptionsItem: {
    height: "36px",
    margin: "0",
    padding: "10px",
    textAlign: "left",
    borderBottom: "solid 1px #ccc",
    ":hover": {
      backgroundColor: "#F6F6F6"
    },
    cursor: "pointer",
    userSelect: "none",
    fontSize: "16px"
  },
  StructureOptionsSubItem: {
    height: "36px",
    marginLeft: "0px",
    padding: "10px",
    paddingLeft: "40px",
    textAlign: "left",
    borderBottom: "solid 1px #ccc",
    ":hover": {
      backgroundColor: "#F6F6F6"
    },
    cursor: "pointer",
    userSelect: "none",
    fontSize: "16px"
  },

  // RICH TEXT EDITOR

  RteContainer: {
    padding: 10,
    paddingBottom: 20
  },
  rteSubPanelItem: {
    margin: "0 20px 20px 20px"
  },
  rteColorPickerWrapper: {
    padding: "0 20px 10px 20px"
  },

  // MODAL

  modalCollectionContainer: {
    padding: "20px 0 20px 20px",
    maxHeight: "484px",
    overflowY: "auto",
    position: "relative"
  },
  modalLoading: {
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "500px",
    opacity: "0.6",
    backgroundColor: "rgba(255,255,255,1)",
    zIndex: 9999
  },

  // FORM EDITOR MODAL //

  formEditorModalWrapper: {
    width: "766px",
    position: "fixed",
    left: "50%",
    transform: "translate(-50%, 0%)",
    top: "calc(3em + 40px)",
    zIndex: "9999",
    background: "#FFF",
    borderRadius: "10px",
    boxShadow: "0 0 3px 0 rgba(0,0,0,0.5)"
  },

  // NEW PAGE MODAL

  newPageCollectionContainer: {
    padding: "20px 0",
    maxHeight: "500px",
    overflowY: "auto",
    display: "flex"
  },
  newPageCollectionItem: {
    width: 180,
    height: 150,
    marginRight: 20,
    marginBottom: 44,
    display: "inline-block",
    position: "relative",
    cursor: "pointer"
  },
  activePageTemplatePlaceholderImg: {
    stroke: "rgba(226, 239, 31, 0.27)"
  },
  pageTemplatePlaceholderImg: {
    transition: "all ease 250ms",
    ":hover": {
      transform: "scale( 1.02 )"
    }
  },
  newPageName: {
    textAlign: "center",
    marginTop: 8,
    fontSize: 14
  },
  newPageNameContainer: {
    width: "50%",
    display: "inline-block",
    borderRight: "1px solid lightgray",
    padding: "0 30px 0 20px"
  },
  newPageNameContainerRightPanel: {
    border: "none",
    textAlign: "center",
    paddingLeft: "50px"
  },

  // SITE SETTINGS //

  pageSettingsModalWrapper: {
    width: "1020px",
    height: "600px",
    position: "fixed",
    left: "50%",
    transform: "translate(-50%, 0%)",
    top: "calc(3em + 40px)",
    zIndex: "9999",
    background: "#FFF",
    borderRadius: "10px",
    boxShadow: "0 0 3px 0 rgba(0,0,0,0.5)"
  },
  modalUnderlay: {
    position: "fixed",
    top: "0",
    left: "0",
    zIndex: "3900",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,.3)"
  },
  modalHeader: {
    fontSize: 18,
    color: "#4A4A4A",
    padding: 20,
    height: 60,
    background: headerGradient,
    borderRadius: "10px 10px 0 0",
    borderBottom: "1px solid #CCC"
  },

  // EDITING MODAL //

  modalCloseDiv: {
    height: "3em",
    display: "flex",
    alignItems: "center",
    justifyContent:"space-between",
    top: "3.5em",
    right: "1em",
    color: GLOBALS.colorPrimaryDark,
    zIndex: 2000,
    width: 335,
    padding:'15px 20px',
    borderBottom: `1px solid ${GLOBALS.colorSecondaryLight}`
  },
  componentContainer: {
    backgroundColor: "#FFF",
    maxWidth: "100%",
    padding: 0,
    margin: "10px 0px"
  },
  panelContainer: {
    backgroundColor: "#FFF",
    padding: "20px 20px 4px 0px"
  },
  panelItem: {},
  subPanelContainer: {
    border: "1px solid #C6C6C6",
    borderRadius: 2,
    backgroundColor: "#F3F3F3",
    padding: 20,
    marginBottom: 20
  },
  subPanelTitleIcon: {
    float: "right",
    opacity: "0.5"
  },
  subPanelRadio: {
    width: 18,
    height: 18
  },
  subPanelHeader: {
    marginBottom: 16
  },
  subPanelItem: {
    marginBottom: 20,
    cursor: "pointer"
  },
  subPanelInput: {
    float: "right"
  },
  modalContainer: {
    position: "fixed",
    backgroundColor: "#FFF",
    width: 330,
    height:720,
    cursor: "auto",
    maxHeight: "85%",
    zIndex: 3000,
    borderRadius: "10px 10px 10px 10px",
    boxShadow: "0px 0px 2px rgba(65, 65, 65, 0.4)"
  },
  modalIcon: {
    color: GLOBALS.colorPrimaryDark,
    fontSize:'12px'
  },
  editingModalHeader: {
    fontSize: 14,
    width: "100%",
    fontColor: "#414141",
    height: 40,
    padding: "9px 20px",
    textTransform: "uppercase",
    cursor: "pointer",
    userSelect: "none",
    borderBottom: `1px solid ${GLOBALS.colorSecondaryLightest}`,

  },

  // COLOR PICKER

  colorPickerContainer: {
    padding: "10px 0"
  },
  colorCircle: {
    float: "right",
    border: "1px solid #C6C6C6",
    height: "22px",
    width: "22px",
    borderRadius: "3px"
  },
  colorPickerSwitchText: {
    marginTop: "20px",
    float: "right",
    color: "#4e92df",
    textDecoration: "underline",
    cursor: "pointer",
    userSelect: "none"
  },

  // GALLERY //

  modalLabelText: {
    fontSize: 16,
    textTransform: "uppercase"
  },

  imageFileName: {
    marginTop: 6,
    fontSize: 12
  },
  imageFileSize: {
    marginBottom: 10,
    fontSize: 12
  },
  replaceEditButtonsRow: {
    marginTop: "15px",
    display: "flex"
  },
  galleryButton: {
    fontSize: 16,
    textAlign: "center",
    height: 40,
    width: 100,
    boxShadow: "0 0 2px 0 rgba(0,0,0,0.2)",
    borderRadius: 2,
    border: "1px solid #C6C6C6",
    cursor: "pointer",
    padding: "6px 12px",
    textAlign: "center",
    userSelect: "none"
  },
  galleryEditButton: {
    color: "#FFF",
    backgroundColor: "rgba(78,177,77,1)"
  },
  galleryResetButton: {
    color: "#666",
    backgroundColor: "#FFF",
    marginRight: 18
  },
  galleryModalHeader: {
    fontSize: 18,
    color: "#4A4A4A",
    padding: 20,
    height: 60,
    backgroundColor: "#F1F1F1;"
  },
  galleryModalCloseIcon: {
    fontSize: 18
  },
  galleryCollectionItem: {
    width: 134,
    height: 112,
    marginRight: 20,
    marginBottom: 20,
    display: "inline-block",
    position: "relative",
    cursor: "pointer"
  },
  galleryCollectionButton: {
    height: 52,
    padding: 13,
    width: "50%",
    display: "inline-block",
    textAlign: "center",
    borderTop: "1px solid #AAA",
    color: "#4A4A4A",
    fontSize: 16,
    verticalAlign: "center",
    userSelect: "none",
    cursor: "pointer"
  },
  galleryCollectionButtonGreen: {
    color: "#FFF",
    background: buttonGradient
  },
  galleryCollectionSelection: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    textAlign: "center",
    paddingTop: 22,
    backgroundColor: "rgba(74,144,226,0.5)",
    border: "4px solid rgba(74,144,226,1)"
  },
  galleryCollectionSelectedIcon: {
    color: "rgba(255,255,255,1)"
  },
  galleryCollectionSelectedIconHovered: {
    color: "rgba(255,255,255,0.5)"
  },

  // WRAPPER //

  wrapper: {
    ":hover": {
      //outline: '4px solid rgba(74, 144, 226,.5)',
      zIndex: 1010
    },
    //outline: '4px solid rgba(74, 144, 226,0)',
    zIndex: 1000,
    cursor: "pointer",
    position: "relative"
  },
  wrapperHighlighted: {
    outline: "4px solid rgba(74, 144, 226,.5)",
    zIndex: 1010,
    cursor: "pointer",
    position: "relative"
  },
  wrapperOnEdit: {
    outline: "6px dashed #F5A623",
    zIndex: 1100,
    cursor: "pointer",
    position: "relative"
  },
  wrapperOverlay: {
    position: "absolute",
    zIndex: 1050,
    backgroundColor: "rgba(30,30,30,0.5)",
    width: "100%",
    height: "100%"
  },
  AddButton: {
    position: "absolute",
    left: "calc(50% - 20px)",
    zIndex: 2001,
    width: "40px",
    height: "40px",
    background: "#4EB14D",
    borderRadius: "20px",
    fontWeight: "100",
    fontSize: "32px",
    lineHeight: "30px",
    textAlign: "center",
    color: "white",
    border: "none",
    ":focus": {
      outline: "0"
    }
  },
  topAddButton: {
    top: "-20px",
    zIndex: 2001
  },
  bottomAddButton: {
    bottom: "-20px",
    zIndex: 2001
  },
  editButton: {
    position: "relative",
    top: "20px",
    zIndex: 2001,
    width: "40px",
    height: "40px",
    background: "rgba(74, 144, 226, .5)",
    borderRadius: "20px",
    fontWeight: "100",
    fontSize: "32px",
    lineHeight: "30px",
    textAlign: "center",
    color: "rgba(255,255,255, .8)",
    border: "none",
    ":focus": {
      outline: "0"
    },
    ":hover": {
      backgroundColor: "rgba(74, 144, 226, 1)"
    },
    boxShadow: "0 0 1px 1px rgba(0,0,0,.5)"
  },
  settingsButton: {
    right: "20px"
  },
  editingButton: {
    marginRight: "20px"
  },
  insertModule: {
    height: "350px",
    border: "4px solid rgba(74, 144, 226,.5)",
    outline: "2px dashed #ccc",
    outlineOffset: "-15px",
    lineHeight: "340px",
    fontSize: "28px",
    textAlign: "center",
    textTransform: "uppercase"
  }
});

export default styles;
