/* global $, ga, _paq, fbq, window */

import { include } from '@convertly/thor';

const Script = () => {

	const Dispatcher = include( '../utils/dispatcher', 'Dispatcher' );

	return ( id, fbAppId, googleAppId ) => {

		const dispatch = Dispatcher();

		dispatch.update( 'fbAppId', fbAppId );
		dispatch.update( 'googleAppId', googleAppId );

	};

};

export default Script;
