import { graph } from "utils/graph";
import _ from "lodash";
import { store } from "app/app";

export function saveEditedImage({ src, params }) {
  return async dispatch => {
    const siteId = store.getState().getIn(["auth", "activeSite", "siteId"]) || "0";

    const query = `
    mutation($filename:String!,$params:imageInput,$siteId:ID!) {
      saveEditedImage(filename:$filename,params:$params,siteId:$siteId) {
        success, filename
      }
    }`;

    const variables = {
      siteId,
      filename: src,
      params
    };

    const response = await graph({ query, variables });

    let filename = _.get(response, "saveEditedImage.filename");
    const success = _.get(response, "saveEditedImage.success") || false;

    if (!success) {
      filename = src;
    }

    return { src: filename, params: {} };
  };
}
