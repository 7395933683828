import React from "react";
import { createAction } from "redux-act";
import { fromJS } from "immutable";
import _ from "lodash";
import { browserHistory } from "react-router";
import { store } from "app/app";
import { graph } from "utils/graph";
import { setUser } from "containers/Authentication/actions";
import { Alerts } from "services/Alerts";
import { Notifications } from "services/Notifications";

import { greeting } from "containers/Dashboard/actions";
import { loadSite, resetActivePage } from "app/services/sites";
import { showLoadingIcon } from "containers/App/actions";
import { getUserByEmail, addSiteToAccount } from "requests";
import { delay } from "utils";

export const setGreeting = createAction("Partners Dashboard: Set Greeting");
export const setShowModal = createAction("Partners Dashboard: Set show modal");
export const setNewUserFieldVal = createAction("Partners Dashboard: Set new user field-value");
export const setNewClient = createAction("Partners Dashboard: Set new user");
export const setSaving = createAction("Partners Dashboard: Set saving flag");
export const setClients = createAction("Partners Dashboard: Set clients");
export const setClient = createAction("Partners Dashboard: Set client");
export const setIds = createAction("Partners Dashboard: Set IDs");
export const setStatusMessage = createAction("Partners Dashboard: Set status message");
export const setShowUpdateModal = createAction("Partners Dashboard: Set show update modal flag");
export const setThemes = createAction("Partners Dashboard: Set available themes");
export const setModal = createAction("Partners Dashboard: Set modal");
export const setSites = createAction("Partners Dashboard: Set sites");
export const setLoading = createAction("Partners Dashboard: Set loading flag");
export const setIntervalVar = createAction("Partners Dashboard: Set interval");

const getSites = () =>
  store
    .getState()
    .get("PartnerDashboard")
    .Dashboard.get("sites") || fromJS([]);

export const removeSiteFromDashboard = ({ site }) => async dispatch => {
  await dispatch(showLoadingIcon(true));

  const userId = store.getState().getIn(["auth", "user", "id"]);
  const siteId = site.get("id");

  const query = `
    mutation($accountId:ID!,$siteId:ID!){
      removeSiteFromUser(accountId:$accountId,siteId:$siteId)
    }
  `;

  const variables = {
    accountId: userId,
    siteId,
  };

  const res = await graph({ query, variables });

  const success = _.get(res, "removeSiteFromUser") || false;

  if (!success) {
    return await Alerts.alert("There was an error processing your request.");
  }

  let sites = getSites().filter(site => site.get("id") !== siteId);

  await dispatch(setSites(fromJS(sites)));
  await dispatch(showLoadingIcon(false));
};

export const resetState = () => async dispatch => {
  let interval = store
    .getState()
    .get("PartnerDashboard")
    .Dashboard.get("interval");

  if (interval) {
    clearInterval(interval);
  }

  await dispatch(setIntervalVar(null));
};

export const shareSite = ({ fields, site }) => async dispatch => {
  const { email } = fields;

  await dispatch(setSaving(true));
  await dispatch(setStatusMessage(`Searching for ${email}`));

  const user = await getUserByEmail({ email });

  if (!user.id) {
    await dispatch(setStatusMessage(`No user with ${email} found.`));
    await dispatch(setSaving(false));
    return null;
  }

  const partner = store.getState().getIn(["auth", "user"]);

  if (user.partner !== partner.get("partner")) {
    await dispatch(
      setStatusMessage(`You do not have access to share ${site.siteName} with ${email}.`)
    );
    await dispatch(setSaving(false));
    return null;
  }

  await dispatch(setStatusMessage(`Granting access to ${site.siteName} for ${email}`));

  await addSiteToAccount({ accountId: user.id, siteId: site.id });

  await delay(250);

  await Notifications.success({
    title: "Success",
    message: (
      <span>
        <b>{email}</b> can now access <b>{site.siteName}</b>
      </span>
    ),
  });

  await dispatch(setSaving(false));
  await dispatch(setModal(null));
};

export const lockSite = ({ site, locked }) => async dispatch => {
  const query = `mutation($id:ID!,$site:siteInput){
		editSite(id:$id,site:$site){
			id, locked
		}
	}`;

  const variables = {
    id: site.get("id"),
    site: {
      locked,
    },
  };

  await graph({ query, variables });

  let sites =
    store
      .getState()
      .get("PartnerDashboard")
      .Dashboard.get("sites") || fromJS([]);

  sites = sites.map(s => {
    if (s.get("id") !== site.get("id")) {
      return s;
    }
    return s.set("locked", locked);
  });

  await dispatch(setSites(sites));
};

export const deleteSite = ({ site }) => async dispatch => {
  const message = (
    <span>
      Permanently delete <b>{site.get("siteName")}</b>? This action can not be undone.
    </span>
  );

  if (!(await Alerts.confirm(message, { label: "Continue" }))) {
    return;
  }

  const prompt = (
    <span>
      Please enter <b style={{ color: "red" }}>DELETE</b> to permanently delete{" "}
      <b>{site.get("siteName")}</b>.
    </span>
  );

  if (
    (await Alerts.prompt(prompt, {
      label: "Delete",
      validations: [val => !!val && val === "DELETE"],
    })) !== "DELETE"
  ) {
    return;
  }

  let sites =
    store
      .getState()
      .get("PartnerDashboard")
      .Dashboard.get("sites") || fromJS([]);

  sites = sites.filter(s => site.get("id") !== s.get("id"));

  await dispatch(setSites(sites));

  const query = `mutation($siteId:ID!,$userId:ID!){deleteSite(siteId:$siteId,userId:$userId){success,message}}`;
  const variables = {
    siteId: site.get("id"),
    userId: store.getState().getIn(["auth", "user", "id"]),
  };

  const res = await graph({ query, variables });

  if (!_.get(res, "deleteSite.success")) {
    return await Alerts.alert(_.get(res, "deleteSite.message"));
  } else {
    await Notifications.add({
      title: "Success",
      message: (
        <span>
          <b>{site.get("siteName")}</b> has been deleted.
        </span>
      ),
      sticky: true,
      icon: "thumbs-up",
    });
  }

  if (!sites.size) {
    await dispatch(activateSite({ site: fromJS({}) }));

    return;
  }

  const activeSiteId = store.getState().getIn(["auth", "activeSite", "siteId"]);

  if (activeSiteId === site.get("id")) {
    await dispatch(activateSite({ site: sites.get(0) || fromJS({}) }));
  }
};

export const clearStatusMessage = () => async dispatch => {
  await dispatch(setStatusMessage(""));
};

export const activateSite = ({ site }) => async dispatch => {
  await dispatch(showLoadingIcon(true));
  await dispatch(setUserPermissions({ site }));
  await dispatch(resetActivePage());
  await dispatch(loadSite(site.toJS()));
  await dispatch(showLoadingIcon(false));
};

export const viewSiteDashboard = ({ site }) => async dispatch => {
  await dispatch(activateSite({ site }));
  browserHistory.push("/dashboard");
};

export const editSite = ({ site }) => async dispatch => {
  await dispatch(activateSite({ site }));
  browserHistory.push("/editor");
};

export const load = () => async dispatch => {
  //await dispatch( clearUserPermissions() );

  await Promise.all([dispatch(showLoadingIcon(true)), dispatch(setLoading(true))]);

  await Promise.all([
    dispatch(loadClientSites()),
    dispatch(loadThemes()),
    dispatch(setClient({})),
    dispatch(setGreeting(greeting())),
  ]);

  await Promise.all([dispatch(showLoadingIcon(false)), dispatch(setLoading(false))]);

  await dispatch(loadSiteScreenshots());
};

export const loadSiteScreenshots = () => async dispatch => {
  let sites =
    store
      .getState()
      .get("PartnerDashboard")
      .Dashboard.get("sites") || fromJS([]);

  for (let i = 0; i < sites.size; i++) {
    if (sites.getIn([i, "screenshot"])) {
      continue;
    }

    const query = `
		mutation( $siteId: ID ) {
			generateScreenshot( siteId: $siteId )
		}
		`;

    const variables = {
      siteId: sites.get(i).get("id"),
    };

    await graph({ query, variables });
  }

  let interval = setInterval(function() {
    (async function() {
      const promises = [];

      let results = [];

      let sites =
        store
          .getState()
          .get("PartnerDashboard")
          .Dashboard.get("sites") || fromJS([]);

      for (let i = 0; i < sites.size; i++) {
        if (sites.getIn([i, "screenshot"])) {
          continue;
        }

        const query = `
				query($siteId:ID!) {
					Site( id: $siteId ) {
						id, screenshot
					}
				}`;

        const variables = {
          siteId: sites.getIn([i, "id"]),
        };

        promises.push(graph({ query, variables }));
      }

      results = await Promise.all(promises);

      if (!promises.length) {
        clearInterval(interval);
        return;
      }

      sites = sites.map(site => {
        const result = results.filter(r => _.get(r, "Site.id") === site.get("id"));

        if (!result.length) {
          return site;
        }

        return site.set("screenshot", _.get(result, "[0].Site.screenshot"));
      });

      await dispatch(setSites(sites));
    })();
  }, 5000);

  await dispatch(setIntervalVar(interval));
};

export const loadClientSites = () => async dispatch => {
  const userId = store.getState().getIn(["auth", "user", "id"]);

  let query = `
		query($userId:ID!) {
			Account(id:$userId) {
				sites {
					id, siteName, logo { src }, updatedAt, screenshot, domain, previewDomain
					primaryAccountHolder { id, email, firstName, lastName, name }
					locked, createdBy, ecommerceSettings, imageVersion
				}
			}
		}
	`;

  let variables = {
    userId,
  };

  let res = await graph({ query, variables });

  let sites = _.get(res, "Account.sites") || [];

  await dispatch(setSites(fromJS(sites)));
};

export const loadThemes = () => async dispatch => {
  const themes = await dispatch(fetchThemes());
  themes.forEach(theme => {
    const image = new Image();
    image.src = theme.previewUrl;
  });
  return await dispatch(setThemes(fromJS(themes)));
};

export const fetchThemes = () => async dispatch => {
  const query = `
		query {
			Themes {
				id, themeName, previewUrl, url, active, siteId
			}
		}
	`;

  const res = await graph({ query });
  let themes = _.get(res, "Themes") || [];
  return themes.filter(theme => theme.active);
};

export const copySite = ({ site }) => async dispatch => {
  await dispatch(setSaving(true));

  await Promise.all([dispatch(setModal(null)), dispatch(setSaving(false))]);

  if (!(await Alerts.confirm(`Make a copy of ${site.get("siteName")}?`))) {
    return;
  }

  const user = store.getState().getIn(["auth", "user"]);
  const userId = user.get("sub");
  const partnerAccount = user.get("partner");
  const siteName = `Copy - ${site.get("siteName")}`;
  const sourceSiteId = site.get("id");
  const sourceSiteName = site.get("siteName");

  const params = {
    userId,
    partnerAccount,
    siteName,
    sourceSiteId,
    sourceSiteName,
  };

  await dispatch(copySiteAction(params));
};

export const createNewSite = site => async dispatch => {
  await dispatch(setSaving(true));

  await Promise.all([dispatch(setModal(null)), dispatch(setSaving(false))]);

  const user = store.getState().getIn(["auth", "user"]);
  const userId = user.get("sub");
  const partnerAccount = user.get("partner");
  const siteName = site.get("siteName");
  const sourceSiteId = site.getIn(["theme", "id"]);
  const sourceSiteName = site.getIn(["theme", "themeName"]);

  const params = {
    userId,
    partnerAccount,
    siteName,
    sourceSiteId,
    sourceSiteName,
  };

  await dispatch(copySiteAction(params));
};

export const copySiteAction = params => async dispatch => {
  const { userId, partnerAccount, siteName, sourceSiteId, sourceSiteName } = params;

  let query = ``;
  let variables = {};
  let res;
  let destinationSiteId;

  /**
   * COPY SITE
   */

  await dispatch(setStatusMessage(`Copying ${sourceSiteName} (Step 1/3)`));

  let notificationId = await Notifications.info({
    title: "Creating Site",
    message: (
      <div>
        Copying {sourceSiteName} <div>(Step 1/3)</div>
      </div>
    ),
    icon: "cog",
    spin: true,
  });

  query = `
			mutation( $partnerAccount: String, $siteName: String, $source: ID ) {
				CopySiteFromSourceSite( partnerAccount: $partnerAccount, siteName:$siteName, sourceSiteId: $source ) {
					id
				}
			}
		`;

  variables = {
    source: sourceSiteId,
    siteName,
    partnerAccount,
  };

  res = await graph({ query, variables });

  await delay(1500);

  destinationSiteId = _.get(res, "CopySiteFromSourceSite.id");

  if (!destinationSiteId) {
    await Alerts.alert("There was an error creating the site. Please try again later. [Stage 1]");
    console.warn(res);
    await dispatch(setSaving(false));
    await dispatch(setStatusMessage(""));
    return;
  }

  /**
   * COPY SITE PAGES AND MODULES
   */

  await dispatch(setStatusMessage(`Copying ${sourceSiteName} (Step 2/3)`));

  await Notifications.clear(notificationId);

  notificationId = await Notifications.info({
    title: "Creating Site",
    message: (
      <div>
        Copying {sourceSiteName} <div>(Step 2/3)</div>
      </div>
    ),
    icon: "cog",
    spin: true,
  });

  query = `
		mutation( $source: ID, $destination: ID ) {
			CopySitePagesAndModules(sourceSiteId: $source, destinationSiteId: $destination) {
				id
			}
		}
		`;

  variables = {
    source: sourceSiteId,
    destination: destinationSiteId,
  };

  res = await graph({ query, variables });

  await delay(1500);

  destinationSiteId = _.get(res, "CopySitePagesAndModules.id");

  if (!destinationSiteId) {
    await Alerts.alert("There was an error creating the site. Please try again later. [Stage 2]");
    console.warn(res);
    await dispatch(setSaving(false));
    await dispatch(setStatusMessage(""));
    return;
  }

  /**
   * COPY SITE IMAGES
   */

  await dispatch(setStatusMessage(`Copying ${sourceSiteName} (Step 3/3)`));

  await Notifications.clear(notificationId);

  notificationId = await Notifications.info({
    title: "Creating Site",
    message: (
      <div>
        Copying {sourceSiteName} <div>(Step 3/3)</div>
      </div>
    ),
    icon: "cog",
    spin: true,
  });

  query = `
		mutation( $source: ID, $destination: ID ) {
			CopySiteImages(sourceSiteId: $source, destinationSiteId: $destination) {
				id
			}
		}
		`;

  res = await graph({ query, variables });

  await delay(1500);

  destinationSiteId = _.get(res, "CopySiteImages.id");

  if (!destinationSiteId) {
    await Alerts.alert("There was an error creating the site. Please try again later. [Stage 3]");
    console.warn(res);
    await dispatch(setSaving(false));
    await dispatch(setStatusMessage(""));
    return;
  }

  /**
   * ADD NEW SITE TO ACCOUNT SITES
   */

  await dispatch(setStatusMessage(`Adding ${siteName} to your dashboard`));

  await Notifications.clear(notificationId);

  notificationId = await Notifications.info({
    title: "Creating Site",
    message: `Adding ${siteName} to your dashboard`,
    icon: "cog",
    spin: true,
  });

  query = `
		mutation( $account: ID!, $siteId: ID! ) {
			AddSiteToAccount(accountId:$account, siteId: $siteId ) {
				id
			}
		}
		`;

  variables = {
    account: userId,
    siteId: destinationSiteId,
  };

  res = await graph({ query, variables });

  await delay(1500);

  if (!_.get(res, "AddSiteToAccount.id")) {
    await Alerts.alert("There was an error adding the site to your account. [stage 4]");
    console.warn(res);
  }

  await Notifications.clear(notificationId);

  await dispatch(loadClientSites());

  await Promise.all([
    dispatch(loadClientSites()),
    dispatch(setModal(null)),
    dispatch(setSaving(false)),
  ]);

  await dispatch(loadSiteScreenshots());
};

export const setUserPermissions = args => async dispatch => {
  const site = _.get(args, "site") || fromJS({});
  const user = store.getState().getIn(["auth", "user"]);

  let ecommEnabled = false;

  try {
    const ecommSettings = JSON.parse(site.get("ecommerceSettings"));
    ecommEnabled = _.get(ecommSettings, "enabled");
  } catch (e) {}

  let permissions = user
    .get("accessPermissions")
    .set("edit_site_settings", true)
    .set("edit_site", true);

  if (ecommEnabled) {
    permissions = permissions.set("view_ecomm", true);
  }

  await dispatch(setUser(user.set("accessPermissions", permissions)));
};

export function clearUserPermissions() {
  return async dispatch => {
    let user = store.getState().getIn(["auth", "user"]);
    const permissions = user.get("accessPermissions").delete("edit_site_settings");
    await dispatch(setUser(user.set("accessPermissions", permissions)));
  };
}

export function setupSite(client) {
  return async dispatch => {
    await dispatch(setClient(client));
    await browserHistory.push("/start");
  };
}

export function createNewClient() {
  return async dispatch => {
    await dispatch(setSaving(true));

    const State = store.getState();
    const newUser = State.get("PartnerDashboard")
      .Dashboard.get("newClient")
      .toJS();
    const userId = State.get("auth")
      .get("user")
      .get("id");

    const query = `
			mutation( $userId: ID!, $account: accountInput ) {
			  createPartnerNewClient(account:$account, userId:$userId) {
				id
			  }
			}
		`;

    const variables = {
      account: {
        firstName: newUser.firstName,
        lastName: newUser.lastName,
        email: newUser.email,
        password: newUser.password,
        role: "normal",
        partner: "adtaxi",
      },
      userId,
    };

    const res = await graph({ query, variables });
    const id = _.get(res, "createPartnerNewClient.id") || "";

    await dispatch(toggleModal());
    await dispatch(loadClients());
  };
}

export function updateNewUserFieldVal(field, value) {
  return async dispatch => {
    await dispatch(setNewUserFieldVal({ field, value }));
  };
}

export function toggleModal() {
  return async dispatch => {
    const showModal = store
      .getState()
      .get("PartnerDashboard")
      .Dashboard.get("showModal");

    if (!showModal) {
      const password = generatePassword();
      await dispatch(updateNewUserFieldVal("password", password));
    }

    await dispatch(setShowModal(!showModal));

    if (showModal) {
      await dispatch(setNewClient(fromJS({})));
    }

    await dispatch(setSaving(false));
  };
}

export function loadClients() {
  return async dispatch => {
    const userId = store
      .getState()
      .get("auth")
      .get("user")
      .get("id");

    const query = `
		query( $id:ID! ) {
		  PartnerClients(id:$id) {
			id, firstName, lastName, sites { id, previewDomain, domain, siteName }
		  }
		}`;

    const variables = {
      id: userId,
    };

    const res = await graph({ query, variables });
    const clients = _.get(res, "PartnerClients") || [];
    await dispatch(setClients(fromJS(clients)));
  };
}

function generatePassword() {
  const s = "!@#$%&*abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ23456789";
  const idLength = 8;
  return Array.apply(null, Array(idLength))
    .map(function() {
      return s.charAt(Math.floor(Math.random() * s.length));
    })
    .join("");
}
