import  colorAssign  from '../colorAssign';

let defaultGlobal = {
    // Colors
    colors: {
        dark: {
            color1: '#000000',
            color2: '#4A4A4A',
            color3: '#A0A0A0',
            black: '#000'
        },
        accent: {
            color1: '#D22F2F',
            color2: '#F44336',
            color3: '#ff7a70'
        },
        light: {
            color1: '#EEEEEE',
            color2: '#E4E4E4',
            color3: '#B7B7B7',
            white: "#fff"
        }        
    },
    

    textColors: {
        light: {},
        dark: {},
        accent: {}
    },
    backgroundColors: {
        light: {},
        dark: {},
        accent: {}
    },    
    // Module Default wrapper
    moduleDefault: {
        margin:'50px 0',
        padding:'25px 0',
    },
        themeBorder: {
        border: '3px solid',
    },
     /** Misc Styles */
    
    divider: {
        backgroundColor: "#000000",
        width: "100%",
        height: "3px",
        marginTop: "10px",
        marginBottom: "10px"
    },
    border: '2px solid',
    figCaption: {
        position:'absolute',
        bottom:'5px',
        background:'rgba(0, 0, 0, .5)',
        width:'100%',
        padding:'30px 20px',
        color:'#fff',
        "@media (max-width: 479px)": {
            padding:'10px'
        }
    },
    bodyBackgroundColor: '',
    /** Typography **/
    fontFamily: {
        primaryFont: " 'Merriweather', serif",
        secondaryFont: " 'Roboto', sans-serif"
    },
    fonts: [ 'Merriweather', 'Roboto' ],
    h1: {
        fontSize: "65px",
        margin:'20px 0',
        lineHeight: "75px",
        
        "@media (max-width: 767px)": {
            fontSize: "55px",
            lineHeight: "65px"
        },
        "@media (max-width: 479px)": {
            fontSize: "45px",
            lineHeight: "55px"
        }

    },
    h2: {
        margin:'20px 0',
        fontSize: '30px',
        lineHeight: '40px',
        
    },
    h3: {
        fontSize: '24px',
       
        lineHeigth:"34px"        
    },
    h4: {
        fontSize: '24px',
       
        lineHeight: '34px'
    },
    h5: {
        fontSize: '16px',
       
        lineHeight:'26px'
    },
    h6: {
        fontSize:'12px',
      
        lineHeight:'22px'
    },
    p: {
        margin: '10px 0',
    
        fontSize: '14px',
        lineHeight: '24px'
    },
    list: {
       
        marginBottom: '20px',
        paddingLeft: '30px'
    },
    listItem: {
        marginBottom:'10px'
    },
    textLink: {
		color:"#f44336"
    },
    /** Buttons **/
    button1: {
        display:'inline-block',
		padding: '20px 50px',
    	borderRadius: '0px',
    	fontSize: "16px"
    },
    button2: {
        display: 'inline-block',
        marginBottom: '10px',
        padding: '10px 25px',
        borderRadius: '50px',
        fontSize:'14px'
    },
    button3: {
        display: 'inline-block',
        marginBottom: '10px',
        padding: '10px 25px',
        borderRadius: '3px',
        fontSize:'14px'
    },
    // Form 
    form: {
        height: 'auto',
        padding: "30px",
        width: '100%'
    },
    formErrorMessage: {
        color: "red",
        fontSize:'12px'
    },
    input: {
        height: "38px",
        width: '100%',
        "::placeholder": {},
        fontSize: '16px',
        lineHeight: '16px',
        border: "1px solid",
        borderRadius: '2px',
        padding: "8px 12px",
        marginBottom: '10px'   
    },
    selectInput: {
        borderRadius: '2px',
        width: '100%',
        height: "40px",
        opactiy: "0",
        appearance: 'none',
        padding: "10px"
    },
    svgDiv: {
        height: '40px'
    },
    checkboxInput:{
        marginRight: "5px",
        marginLeft: "5px",
    },
    checkboxLabel: {},

    radioInput:{
       marginRight: "5px",
       marginLeft: "5px",
    },
    radioLabel: {},

    submitButton:{
        fontWeight: "700",
        border: "none",
        padding: "10px 25px"
    },
    label: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "5px"
    }
};

export default function theme ( global ) {

    global = global ? global : defaultGlobal;

    //colorAssign( global );

    //Body Background Color
    global.bodyBackgroundColor = global.backgroundColors.light.white;


    // Module Default Wrapper Styles
    global.moduleDefault.fontFamily = global.fontFamily.primaryFont;

    

     // Theme Border 
    global.themeBorder.borderColor = global.backgroundColors.light.color3

    // Form Styles
    global.form.backgroundColor = global.backgroundColors.light.color2;

    global.input.backgroundColor = global.backgroundColors.light.white;
    global.input.borderColor = global.backgroundColors.dark.color3;
    global.input.color = global.textColors.dark.color1;
    global.input.fontFamily = global.fontFamily.primaryFont;
    global.input["::placeholder"].color = global.textColors.dark.color3;
    global.input["::placeholder"].fontFamily = global.fontFamily.secondaryFont;

    global.selectInput.backgroundColor = global.backgroundColors.light.white;
    global.selectInput.borderColor = global.backgroundColors.dark.color3;
    global.selectInput.color = global.textColors.dark.color1;
    global.selectInput.fontFamily = global.fontFamily.secondaryFont;
   

    global.svgDiv.backgroundColor = global.backgroundColors.light.white;

    global.checkboxLabel.color = global.textColors.dark.color3;
    global.checkboxLabel.fontFamily = global.fontFamily.secondaryFont;
    
    global.radioLabel.color = global.textColors.dark.color3;
    global.radioLabel.fontFamily = global.fontFamily.secondaryFont;

    global.submitButton.color = global.textColors.light.white;
    global.submitButton.backgroundColor = global.backgroundColors.accent.color2;
    global.submitButton.fontFamily = global.fontFamily.secondaryFont;

    global.label.color = global.textColors.dark.color2;
    global.label.fontFamily = global.fontFamily.secondaryFont;


// typography Styles
    global.h1.color = global.textColors.dark.color1;
    global.h2.color = global.textColors.dark.color1;
    global.h3.color = global.textColors.dark.color1;
    global.h4.color = global.textColors.light.color3;
    global.h5.color = global.textColors.dark.color1;
    global.h6.color = global.textColors.dark.color1;
    global.p.color = global.textColors.dark.color2;
    global.list.color = global.textColors.dark.color2;

    global.h1.fontFamily = global.fontFamily.primaryFont;
    global.h2.fontFamily = global.fontFamily.secondaryFont;
    global.h3.fontFamily = global.fontFamily.primaryFont;
    global.h4.fontFamily = global.fontFamily.primaryFont;
    global.h5.fontFamily = global.fontFamily.secondaryFont;
    global.h6.fontFamily = global.fontFamily.primaryFont;
    global.p.fontFamily = global.fontFamily.secondaryFont;
    global.list.fontFamily = global.fontFamily.secondaryFont;


    //Button Styles
    const defaultButtonStyles = {
        background: global.backgroundColors.accent.color1,
        color: global.textColors.light.white,
        borderColor: global.backgroundColors.accent.color1,
        fontFamily: global.fontFamily.primaryFont
    };

    const alternateButtonStyles = {
        background: global.backgroundColors.dark.color1,
        color: global.textColors.light.white,
        fontFamily: global.fontFamily.primaryFont,
        borderColor: global.backgroundColors.dark.color1
    };

    global.button1 = {
        ...global.button1,
        ...defaultButtonStyles
    };

    global.button1A = {
        ...global.button1,
        ...alternateButtonStyles
    };

    global.button2 = {
        ...global.button2,
        ...defaultButtonStyles
    };

    global.button2A = {
        ...global.button2,
        ...alternateButtonStyles
    };

    global.button3 = {
        ...global.button3,
        ...defaultButtonStyles
    };

    global.button3A = {
        ...global.button3,
        ...alternateButtonStyles
    };

    return global;

}
