// https://github.com/davezuko/react-redux-starter-kit/issues/1116

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toggleHelpModal, setStripe } from "containers/App/actions";

const { object } = PropTypes;

export default function Authenticated(Component) {
  class AuthenticationService extends React.Component {
    static contextTypes = {
      store: object.isRequired,
      router: object.isRequired
    };

    constructor(props) {
      super(props);
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  const mapStateToProps = state => {
    const Global = state.get("global");

    return {
      auth: state.get("auth").toJS(),
      displayAppOverlay: Global.get("displayAppOverlay"),
      displayLoadingIcon: Global.get("displayLoadingIcon"),
      appOverlayOpacity: Global.get("appOverlayOpacity"),
      appOverlayIndex: Global.get("appOverlayIndex"),
      graphRunning: Global.get("graphRunning"),
      graphRequests: Global.get("graphRequests")
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      toggleHelpModal: () => dispatch(toggleHelpModal()),
      setStripe: async stripe => await dispatch(setStripe(stripe))
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(AuthenticationService);
}
