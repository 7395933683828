// https://plainjs.com/javascript/styles/get-the-position-of-an-element-relative-to-the-document-24/

export function getElementPosition(el, differentViewport) {
  var rect = el.getBoundingClientRect();
  var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  var page = document.getElementById("editorPageWrapper");
  return {
    top: rect.top + scrollTop,
    left: rect.left - scrollLeft
  };
}
