import styled from "styled-components";
import {GLOBALS} from "../../../styles/global-default-styles";

export const CategorySelection = styled.div`
  transition:border 300ms ease; 
  ${ ({selected}) => selected && `background:${GLOBALS.colorPrimaryOpacity};` }
  border: 1px solid ${({selected}) => selected ? GLOBALS.colorPrimary : GLOBALS.colorSecondaryLightest};
  width:100%;
  height:45px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  margin: 10px 0px;
  border-radius:3px;
  cursor:pointer;
  &:hover {
    border-color:${GLOBALS.colorPrimary};
  }
  `;
