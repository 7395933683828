import React from 'react';
import Component from '../../../rootComponent';
import { css } from "@convertly/css";
import { id } from '@convertly/thor';
import ReactHTMLParser from "@convertly/react-html-parser";
import { frontloadConnect } from '@convertly/react-frontload';
import {clientImage} from '../../../utils/generateImage';

//Default Props
import defaultData from '../product-listing-default-data';
import defaultGlobal from '../../../themes/global';

//Styles
import productListingStyles from './product-listing-styles-01';

//Scripts
import tabsHandler from '../scripts/tabs-handler';
import addToCart from '../scripts/addToCart';
import increaseQuantityToggle from '../scripts/increaseQuantityToggle';
import decreaseQuantityToggle from '../scripts/decreaseQuantityToggle';
import cartHandler from '../scripts/cartHandler';
import StepperWidget from '../scripts/StepperWidget';

//Components
import SVG from '../../../svg/index';
import ConvertlyImageWidget01 from '../product-image-gallery/product-image-gallery-01/image-gallery-widget-01';
import Button from '../../../elements/buttons/button-web-link';
import SocialIcons1 from '../../../widgets/social-icons/no-background-social-icons/social-icon-list-1';

export default class ProductListing01 extends Component {

    constructor(props) {

        super(props);

    }

    render() {

        if ( this.props.editor ) {
            return this.editor();
        }

        function convertToDollarAmount( int ) {
            return `\$${ parseFloat( int ).toFixed( 2 ) }`
        }

        const { description, title, pricing, manufacture_details, images } = this.props.data;
        const productId = this.props.data.id;
        const brand = manufacture_details.manufacturer;


		this.styles = productListingStyles( this.props.global, this.props.data );

        return (

            <div id="productListing01" className={ css(this.styles.productListing) }>
				<div id={ id( cartHandler, 'cartHandler' ) }/>
                <div className="container">
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <ConvertlyImageWidget01 images={ images } />
                        <div className={ css(this.styles.followUpLinksContainer) + ' desktop' }>
                            <h5 className={ css(this.styles.followUpLinks) }>
                                <a href="#">Add To Favorites</a>
                            </h5>
                            <h5 className={ css(this.styles.followUpLinks) }>
                                <a href="#">Ask Us A Question</a>
                            </h5>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                        <div className={ css(this.styles.productDescriptionContainer) }>
                            <h5 className={ css(this.styles.brandName) }>{ brand }</h5>
                            <h2 className={ css(this.styles.productTitle) }>{ title }</h2>
                            <h4 className={ css(this.styles.productPrice) }><em>{convertToDollarAmount( pricing.price ) }</em></h4>
                            <select style={ { display: 'none' } } className={ css(this.styles.productOptionSelection) }>
                                <option value="SELECT OPTION">SELECT OPTION</option>
                                <option value="OPTION1">OPTION1</option>
                                <option value="OPTION2">OPTION2</option>
                                <option value="OPTION3">OPTION3</option>
                            </select>
                            <div id="qty" className={ css(this.styles.quantityContainer) }>
                                <div className={ css(this.styles.quantityWrapper) }>
                                    <div className={ css(this.styles.stepperWidget) }>
                                        <span className={ css(this.styles.stepperWidgetSpan) }>QTY</span>
                                        <strong>
											<p  id={ id( StepperWidget ) }
												className={ css(this.styles.stepperWidgetQuantityNumber) }
												data-value="1">
											</p>
										</strong>
                                        <button id={ id( increaseQuantityToggle ) } type="button" className={ css(this.styles.upArrow) }>
                                            <SVG.ArrowHeadClosedUp
                                                className={ this.styles.arrow } />
                                        </button>
                                        <button id={ id( decreaseQuantityToggle ) } type="button" className={ css(this.styles.downArrow) }>
                                            <SVG.ArrowHeadClosedDown
                                                className={ this.styles.arrow } />
                                        </button>
                                    </div>
                                </div>
                                <div className={ css(this.styles.addToCartButtonContainer) }>
                                    <Button id={ id( addToCart ) } className={ css( this.styles.caret ) }
                                            global={ this.props.global }
                                            buttonType={ this.props.global.button3 }
                                            data={ this.props.data.buttonWide }
                                    />
                                </div>
                                <div className={ css(this.styles.followUpLinksContainer) + ' mobile' }>
                                    <h5 className={ css(this.styles.followUpLinks) }>
                                        <a href="#">Add To Favorites</a>
                                    </h5>
                                    <h5 className={ css(this.styles.followUpLinks) }>
                                        <a href="#">Ask Us A Question</a>
                                    </h5>
                                </div>
                            </div>

                            {/** Product Description **/}
                            <h5 className={ css(this.styles.productDescription) }>{ ReactHTMLParser( description ) }</h5>
                            <div className="share_with_friends">
                                <h6 className={ css(this.styles.share) }>Share with your friends</h6>
                                <div className={ css( this.styles.socialIcons ) }>
                                    <SocialIcons1 global={ this.props.global }
                                                  styles={ this.styles }
                                                  data={ this.props.data }/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className={ css(this.styles.tabContainer) }>
                    <div className="container">
                        <div className="col-xs-12">
                            <ul id={ id(tabsHandler) } className={ css(this.styles.tabHeaderSection) }>
                                <li className={ css(this.styles.tabHeaderListItem) + " active"}
                                    data-target="#productReview">Product Reviews
                                </li>
                                <li className={ css(this.styles.tabHeaderListItem) }
                                    data-target="#productDescription">Product Details
                                </li>
                                <li className={ css(this.styles.tabHeaderListItem) }
                                    data-target="#similarProducts">Similar Products
                                </li>
                            </ul>
                            <h3 className={ css(this.styles.mobileTabHeader) + " tabLinkMobile active"}
                                data-target="#productReview">Product Reviews
                            </h3>
                            <div className={ css(this.styles.tabsBody) }>
                                <div id="productReview" className={ css(this.styles.tab) + ' tab  active'}>
                                    <h5 className={ css(this.styles.tabTitle) }>Culpa iceland et, brunch
                                        pinterest.</h5>
                                    <h6 className={ css(this.styles.tabSubTitle) }>{ ReactHTMLParser('<em>By: Assumenda Vegan</em>') }</h6>
                                    <p className={ css(this.styles.tabParagraph) }>Echo park banh mi duis scenester,
                                        deep v dolore fanny pack ea jianbing
                                        truffaut crucifix fam. Reprehenderit affogato echo park, sustainable
                                        voluptate bicycle rights health goth. Retro hashtag culpa, authentic
                                        portland pok pok church-key exercitation non. Elit live-edge iPhone,
                                        chicharrones vegan thundercats activated charcoal veniam retro keffiyeh.
                                        Plaid narwhal voluptate typewriter street art fixie. Forage four loko next
                                        level aute odio. Beard hashtag fashion axe cred, master cleanse austin
                                        church-key snackwave laborum chia meditation.</p>
                                    <a className={ css(this.styles.reviewLink) } href="#"><strong>Write a Review</strong></a>
                                </div>
                                <h3 className={ css(this.styles.mobileTabHeader) + " tabLinkMobile"}
                                    data-target="#productDescription">Product Description
                                </h3>
                                <div id="productDescription" className={ css(this.styles.tab) + " tab" }>
                                    <h5 className={ css(this.styles.tabTitle) }>{ ReactHTMLParser('<em>Product Details</em>') }</h5>
                                    <div className={ css(this.styles.productDetailContainer) }>
                                        <h4 className={ css(this.styles.productDetailCategory) }>WEIGHT</h4>
                                        <p className={ css(this.styles.productDetail) }>3<sup>oz.</sup></p>
                                    </div>
                                    <div className={ css(this.styles.productDetailContainer) }>
                                        <h4 className={ css(this.styles.productDetailCategory) }>WEIGHT</h4>
                                        <p className={ css(this.styles.productDetail) }>3<sup>oz.</sup></p>
                                    </div>
                                    <div className={ css(this.styles.productDetailContainer) }>
                                        <h4 className={ css(this.styles.productDetailCategory) }>WEIGHT</h4>
                                        <p className={ css(this.styles.productDetail) }>3<sup>oz.</sup></p>
                                    </div>
                                    <div className={ css(this.styles.productDetailContainer) }>
                                        <h4 className={ css(this.styles.productDetailCategory) }>WEIGHT</h4>
                                        <p className={ css(this.styles.productDetail) }>3<sup>oz.</sup></p>
                                    </div>
                                    <div className={ css(this.styles.productDetailContainer) }>
                                        <h4 className={ css(this.styles.productDetailCategory) }>WEIGHT</h4>
                                        <p className={ css(this.styles.productDetail) }>3<sup>oz.</sup></p>
                                    </div>
                                    <div className={ css(this.styles.productDetailContainer) }>
                                        <h4 className={ css(this.styles.productDetailCategory) }>WEIGHT</h4>
                                        <p className={ css(this.styles.productDetail) }>3<sup>oz.</sup></p>
                                    </div>
                                </div>
                                <h3 className={ css(this.styles.mobileTabHeader) + " tabLinkMobile"}
                                    data-target="#similarProducts">Similar Products
                                </h3>
                                <div id="similarProducts"
                                     className={ css(this.styles.tab, this.styles.similarProductsTab) + " tab" }>
                                    <div className={ css(this.styles.similarProductListing) }>
                                        <div className={ css(this.styles.callOutBox) }>
                                            <h3 className={ css(this.styles.callOut) }> { ReactHTMLParser('<em>BEST SELLER</em>') }</h3>
                                        </div>
                                        <img className={ css(this.styles.productImage) } src={ clientImage( '29.jpg', { width: 300 } ) } />
                                        <div className={ css(this.styles.overlay) }>
                                            <h4 className={ css(this.styles.similarProductBrandName) }>Brand
                                                Name</h4>
                                            <h2 className={ css(this.styles.similarProductTitle) }>Mixtape
                                                Snackwave</h2>
                                            <h3 className={ css(this.styles.similarProductPreSalePrice) }></h3>
                                            <h2 className={ css(this.styles.similarProductPrice) }>  { ReactHTMLParser('<strong>$24.95</strong>') }</h2>
                                            <a href="#" className={ css(this.styles.quickBuy) }>Quick Buy</a>
                                        </div>
                                    </div>

                                    <div className={ css(this.styles.similarProductListing) }>
                                        <div className={ css(this.styles.callOutBox) }>
                                            <h3 className={ css(this.styles.callOut) }> { ReactHTMLParser('<em></em>') }</h3>
                                        </div>
                                        <img className={ css(this.styles.productImage) } src={ clientImage( '28.jpg', { width: 300 } ) }/>
                                        <div className={ css(this.styles.overlay) }>
                                            <h4 className={ css(this.styles.similarProductBrandName) }>Brand
                                                Name</h4>
                                            <h2 className={ css(this.styles.similarProductTitle) }>Mixtape
                                                Snackwave</h2>
                                            <h3 className={ css(this.styles.similarProductPreSalePrice) }>
                                                $34.95</h3>
                                            <h2 className={ css(this.styles.similarProductPrice) }>  { ReactHTMLParser('<strong>$24.95</strong>') }</h2>
                                            <a href="#" className={ css(this.styles.quickBuy) }>Quick Buy</a>
                                        </div>
                                    </div>

                                    <div className={ css(this.styles.similarProductListing) }>
                                        <div className={ css(this.styles.callOutBox) }>
                                            <h3 className={ css(this.styles.callOut) }> { ReactHTMLParser('<em></em>') }</h3>
                                        </div>
                                        <img className={ css(this.styles.productImage) } src={ clientImage( '27.jpg', { width: 300 } ) }/>
                                        <div className={ css(this.styles.overlay) }>
                                            <h4 className={ css(this.styles.similarProductBrandName) }>Brand
                                                Name</h4>
                                            <h2 className={ css(this.styles.similarProductTitle) }>Mixtape
                                                Snackwave</h2>
                                            <h3 className={ css(this.styles.similarProductPreSalePrice) }></h3>
                                            <h2 className={ css(this.styles.similarProductPrice) }>  { ReactHTMLParser('<strong>$24.95</strong>') }</h2>
                                            <a href="#" className={ css(this.styles.quickBuy) }>Quick Buy</a>
                                        </div>
                                    </div>

                                    <div className={ css(this.styles.similarProductListing) }>
                                        <div className={ css(this.styles.callOutBox) }>
                                            <h3 className={ css(this.styles.callOut) }> { ReactHTMLParser('<em>On Sale</em>') }</h3>
                                        </div>
                                        <img className={ css(this.styles.productImage) } src={ clientImage( '26.jpg', { width: 300 } ) }/>
                                        <div className={ css(this.styles.overlay) }>
                                            <h4 className={ css(this.styles.similarProductBrandName) }>Brand
                                                Name</h4>
                                            <h2 className={ css(this.styles.similarProductTitle) }>Mixtape
                                                Snackwave</h2>
                                            <h3 className={ css(this.styles.similarProductPreSalePrice) }>
                                                $34.95</h3>
                                            <h2 className={ css(this.styles.similarProductPrice) }>  { ReactHTMLParser('<strong>$24.95</strong>') }</h2>
                                            <a href="#" className={ css(this.styles.quickBuy) }>Quick Buy</a>
                                        </div>
                                    </div>

                                    <div className={ css(this.styles.similarProductListing) }>
                                        <div className={ css(this.styles.callOutBox) }>
                                            <h3 className={ css(this.styles.callOut) }> { ReactHTMLParser('<em></em>') }</h3>
                                        </div>
                                        <img className={ css(this.styles.productImage) } src={ clientImage( '25.jpg', { width: 300 } ) }/>
                                        <div className={ css(this.styles.overlay) }>
                                            <h4 className={ css(this.styles.similarProductBrandName) }>Brand
                                                Name</h4>
                                            <h2 className={ css(this.styles.similarProductTitle) }>Mixtape
                                                Snackwave</h2>
                                            <h3 className={ css(this.styles.similarProductPreSalePrice) }></h3>
                                            <h2 className={ css(this.styles.similarProductPrice) }>  { ReactHTMLParser('<strong>$24.95</strong>') }</h2>
                                            <a href="#" className={ css(this.styles.quickBuy) }>Quick Buy</a>
                                        </div>
                                    </div>

                                    <div className={ css(this.styles.similarProductListing) }>
                                        <div className={ css(this.styles.callOutBox) }>
                                            <h3 className={ css(this.styles.callOut) }> { ReactHTMLParser('<em></em>') }</h3>
                                        </div>
                                        <img className={ css(this.styles.productImage) } src={ clientImage( '24.jpg', { width: 300 } ) }/>
                                        <div className={ css(this.styles.overlay) }>
                                            <h4 className={ css(this.styles.similarProductBrandName) }>Brand
                                                Name</h4>
                                            <h2 className={ css(this.styles.similarProductTitle) }>Mixtape
                                                Snackwave</h2>
                                            <h3 className={ css(this.styles.similarProductPreSalePrice) }></h3>
                                            <h2 className={ css(this.styles.similarProductPrice) }>  { ReactHTMLParser('<strong>$24.95</strong>') }</h2>
                                            <a href="#" className={ css(this.styles.quickBuy) }>Quick Buy</a>
                                        </div>
                                    </div>

                                    <div className={ css(this.styles.similarProductListing) }>
                                        <div className={ css(this.styles.callOutBox) }>
                                            <h3 className={ css(this.styles.callOut) }> { ReactHTMLParser('<em>BEST SELLER</em>') }</h3>
                                        </div>
                                        <img className={ css(this.styles.productImage) } src={ clientImage( '23.jpg', { width: 300 } ) }/>
                                        <div className={ css(this.styles.overlay) }>
                                            <h4 className={ css(this.styles.similarProductBrandName) }>Brand
                                                Name</h4>
                                            <h2 className={ css(this.styles.similarProductTitle) }>Mixtape
                                                Snackwave</h2>
                                            <h3 className={ css(this.styles.similarProductPreSalePrice) }></h3>
                                            <h2 className={ css(this.styles.similarProductPrice) }>  { ReactHTMLParser('<strong>$24.95</strong>') }</h2>
                                            <a href="#" className={ css(this.styles.quickBuy) }>Quick Buy</a>
                                        </div>
                                    </div>

                                    <div className={ css(this.styles.similarProductListing) }>
                                        <div className={ css(this.styles.callOutBox) }>
                                            <h3 className={ css(this.styles.callOut) }> { ReactHTMLParser('<em></em>') }</h3>
                                        </div>
                                        <img className={ css(this.styles.productImage) } src={ clientImage( '22.jpg', { width: 300 } ) }/>
                                        <div className={ css(this.styles.overlay) }>
                                            <h4 className={ css(this.styles.similarProductBrandName) }>Brand
                                                Name</h4>
                                            <h2 className={ css(this.styles.similarProductTitle) }>Mixtape
                                                Snackwave</h2>
                                            <h3 className={ css(this.styles.similarProductPreSalePrice) }></h3>
                                            <h2 className={ css(this.styles.similarProductPrice) }>  { ReactHTMLParser('<strong>$24.95</strong>') }</h2>
                                            <a href="#" className={ css(this.styles.quickBuy) }>Quick Buy</a>
                                        </div>
                                    </div>

                                    <div className={ css(this.styles.similarProductListing) }>
                                        <div className={ css(this.styles.callOutBox) }>
                                            <h3 className={ css(this.styles.callOut) }> { ReactHTMLParser('<em>On Sale</em>') }</h3>
                                        </div>
                                        <img className={ css(this.styles.productImage) } src={ clientImage( '21.jpg', { width: 300 } ) }/>
                                        <div className={ css(this.styles.overlay) }>
                                            <h4 className={ css(this.styles.similarProductBrandName) }>Brand
                                                Name</h4>
                                            <h2 className={ css(this.styles.similarProductTitle) }>Mixtape
                                                Snackwave</h2>
                                            <h3 className={ css(this.styles.similarProductPreSalePrice) }>
                                                $34.96</h3>
                                            <h2 className={ css(this.styles.similarProductPrice) }>  { ReactHTMLParser('<strong>$24.95</strong>') }</h2>
                                            <a href="#" className={ css(this.styles.quickBuy) }>Quick Buy</a>
                                        </div>
                                    </div>

                                    <div className={ css(this.styles.similarProductListing) }>
                                        <div className={ css(this.styles.callOutBox) }>
                                            <h3 className={ css(this.styles.callOut) }> { ReactHTMLParser('<em></em>') }</h3>
                                        </div>
                                        <img className={ css(this.styles.productImage) } src={ clientImage( '20.jpg', { width: 300 } ) }/>
                                        <div className={ css(this.styles.overlay) }>
                                            <h4 className={ css(this.styles.similarProductBrandName) }>Brand
                                                Name</h4>
                                            <h2 className={ css(this.styles.similarProductTitle) }>Mixtape
                                                Snackwave</h2>
                                            <h3 className={ css(this.styles.similarProductPreSalePrice) }></h3>
                                            <h2 className={ css(this.styles.similarProductPrice) }>  { ReactHTMLParser('<strong>$24.95</strong>') }</h2>
                                            <a href="#" className={ css(this.styles.quickBuy) }>Quick Buy</a>
                                        </div>
                                    </div>

                                    <div className={ css(this.styles.similarProductListing) }>
                                        <div className={ css(this.styles.callOutBox) }>
                                            <h3 className={ css(this.styles.callOut) }> { ReactHTMLParser('<em></em>') }</h3>
                                        </div>
                                        <img className={ css(this.styles.productImage) } src={ clientImage( '19.jpg', { width: 300 } ) }/>
                                        <div className={ css(this.styles.overlay) }>
                                            <h4 className={ css(this.styles.similarProductBrandName) }>Brand
                                                Name</h4>
                                            <h2 className={ css(this.styles.similarProductTitle) }>Mixtape
                                                Snackwave</h2>
                                            <h3 className={ css(this.styles.similarProductPreSalePrice) }></h3>
                                            <h2 className={ css(this.styles.similarProductPrice) }>  { ReactHTMLParser('<strong>$24.95</strong>') }</h2>
                                            <a href="#" className={ css(this.styles.quickBuy) }>Quick Buy</a>
                                        </div>
                                    </div>

                                    <div className={ css(this.styles.similarProductListing) }>
                                        <div className={ css(this.styles.callOutBox) }>
                                            <h3 className={ css(this.styles.callOut) }> { ReactHTMLParser('<em></em>') }</h3>
                                        </div>
                                        <img className={ css(this.styles.productImage) } src={ clientImage( '18.jpg', { width: 300 } ) }/>
                                        <div className={ css(this.styles.overlay) }>
                                            <h4 className={ css(this.styles.similarProductBrandName) }>Brand
                                                Name</h4>
                                            <h2 className={ css(this.styles.similarProductTitle) }>Mixtape
                                                Snackwave</h2>
                                            <h3 className={ css(this.styles.similarProductPreSalePrice) }></h3>
                                            <h2 className={ css(this.styles.similarProductPrice) }>  { ReactHTMLParser('<strong>$24.95</strong>') }</h2>
                                            <a href="#" className={ css(this.styles.quickBuy) }>Quick Buy</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
				<input type="hidden" value={ productId } name="id" />
				<input type="hidden" value={ pricing.price } name="price" />
            </div>

        )
    }
}

ProductListing01.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
