import React from 'react';
import Componenet from '../../../rootComponent';
import {css} from '@convertly/css';
import defaultGlobal from '../../../themes/global.js';
// Styles
import fieldContainerStyleSheet from './field-containers-styles';

export default class FieldContainer extends Componenet {
	constructor (props){
		super(props);
	}

	render(){
		this.styles = fieldContainerStyleSheet(this.props.global, this.props.data);

		let className;
		const aphroditeClass = css(this.styles.fieldContainer);
		className += aphroditeClass;

		function renderChildren(props){
			return React.Children.map(props.children, child => {
				return React.cloneElement(child, {
					moduleId: props.moduleId,
					moduleSort: props.moduleSort,
					styles: props.styles
				});
			});
		}

		return(
			<div className = { css(this.styles.fieldContainer)}>
				{renderChildren(this.props)}
				<p className={ css( this.styles.formErrorMessage ) + " form-error-message" }>
				{ this.props.data.customErrorMessage ? this.props.data.customErrorMessage : "*required field" }
				</p>
			</div>
		)
	}

};

FieldContainer.defaultProps = {
	global: defaultGlobal, 
	data: {}
}