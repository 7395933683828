import React from 'react';
import { ListOfSteps, Step, StepText } from './component';
import { RightPointingArrowIcon } from '../svg';
import {GLOBALS} from '../../styles/global-default-styles';

/**
 * @description Dynamic Stepper Component.
 * @param {Array[{}]} steps | Text array of each step.
 * @param {String} activeIndex | Index of the current step clicked.
 * @param {Callback()} cb | Determines the action when one of the steps is completed.
 */
export const Stepper = ({steps, activeIndex, cb}) => {  
    // Retrieves the onboarding steps along with the arrows to display the step.
  const stepsComponent = steps.map((step, idx) => {

    let textClass;
    let arrowColor;    // if a step is completed, the preceding arrow color is darker, otherwise its color is grey

    if (idx < activeIndex) {
      textClass = 'step--completed';
      arrowColor = `${GLOBALS.colorPrimaryDark}`;
    } else if (idx === activeIndex) {
      textClass = 'step--active';
      arrowColor = `${GLOBALS.colorSecondaryDark}`;
    } else {
      textClass = 'step';
      arrowColor = `${GLOBALS.colorSecondaryDark}`;
    }

    // Logic to remove the last arrow after each step. The last link does not have an arrow icon at the end.
    const optionalArrow = idx < steps.length - 1 && <RightPointingArrowIcon width="24px"  height="24px" fill={arrowColor} />;
    const moveToStep = () => idx < activeIndex && cb(idx);

    return (
      <>
        { /* onClick calls the cb when the user has completed the previous step */}
        <Step as="li" onClick={moveToStep} key={step}>
          <StepText as="p" className={textClass}>{step}</StepText>
          {optionalArrow}
        </Step>
      </>
      )
  });

    return <ListOfSteps as="ul">{stepsComponent}</ListOfSteps>;
}
