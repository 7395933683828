import React from 'react';
import Component from 'source/rootComponent';
import {css} from "@convertly/css";
import {id} from "@convertly/thor";
const {getSite} = require('@convertly/utils');

//Default Props
import defaultGlobal from 'source/themes/global';

//Styles
import Styles from './reset-password-styles';

//Components
import ResetPassword from './reset-password-inferno';

//Utils
import { generateLink } from 'source/utils/generateLink';

import Elements from 'source/elements/index';

export default class ForgotPasswordEmail extends Component {

    constructor(props) {

        super(props);

    }

    render() {

        this.styles = Styles(this.props.global, this.props.data);

        if (this.props.editor) {
            return this.editor();
        }

        const site = getSite();
        const {domain} = site;

        const siteId = this.props.site.siteId;
        const siteLink = generateLink( { linkType:'inferno', url:'' } );

        return (
            <Elements.Container>
                <Elements.Row>
                    <Elements.Column data={
                        {
                            xsColumns: 12,
                            userOverrides: {
                                padding:'50px 0'
                            }
                        }
                    }>
                        <div id={ id(ResetPassword, domain, siteId, siteLink) }
                             className={ css(this.styles.forgotPasswordInferno) }/>
                    </Elements.Column>
                </Elements.Row>
            </Elements.Container>
        )
    }
}


ForgotPasswordEmail.defaultProps = {
    global: defaultGlobal
};
