import { StyleSheet } from "@convertly/css";
import UserOverrides from '../../../elements/style-functions/user-overrides';


const accordionStyles = ( global, data ) => {

    const userOverrides = UserOverrides( global, data );

    return StyleSheet.create({


        toggle_drop: {
            boxSizing: "border-box",
            color: "#5C6976",
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "16px",
            lineHeight: "16px",
            padding: "18px 0 0 0",
        },
        ul_xipQ: {
            boxSizing: "border-box",
            color: "#5C6976",
            display: "inline-block",
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "16px",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0",
            width: "100%"
        },
        caretActive: {
            display: 'inline-block',
            width: '0',
            height: '0',
            borderLeft: '20px solid transparent',
            borderRight: '20px solid transparent',
            borderTop: '20px solid #f00',
        },
        caretInactive: {
            display: 'inline-block',
            width: '0',
            height: '0',
            borderLeft: '20px solid transparent',
            borderRight: '20px solid transparent',
            borderBottom: '20px solid #f00',
        },
        li_rIVy: {
            boxSizing: "border-box",
            color: "#5C6976",
            display: "block",
            fontSize: "16px",
            lineHeight: "16px",
            listStyle: "none",
            margin: "0 0 12px 0",
            padding: "0"
        },
        toggle: {
            background: global.colors.light.white,
            border: `2px solid ${ global.colors.light.color3 }`,
            borderRadius: "3px",
            boxSizing: "border-box",
            color: global.colors.dark.color1,
            cursor: "pointer",
            display: "flex",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "14px",
            fontWeight: "900",
            lineHeight: "14px",
            listStyle: "none",
            margin: "0",
            outline: "0 none",
            padding: "17px 17px 17px 17px",
            position: "relative",
            textDecoration: "none",
            transition: "all ease-in-out .3s",
            textAlign: 'left',
            justifyContent: 'space-between',
            },
        dropdownArea: {
            background: global.colors.light.color2,
            border: `2px solid ${ global.colors.light.color3 }`,
            borderTop: "none",
            boxSizing: "border-box",
            color: "#5C6976",
            display: "none",
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "16px",
            lineHeight: "16px",
            listStyle: "none",
            margin: "-2px 0 0 0",
            padding: "14px 22px 14px 22px",
            '.open': {
                display: 'block',
            }
        },
        p_vWqP: {
            boxSizing: "border-box",
            color: global.colors.dark.color2,
            fontFamily: global.fontFamily.secondaryFont,
            fontSize: "12px",
            fontWeight: "500",
            lineHeight: "21px",
            listStyle: "none",
            margin: "0",
            padding: "0"
        },
        arrow: {
            height: ".6em",
            width: ".6em",
            fill: "black",
            fontSize: "22px",
            transition: "all .15s ease",
            '.arrowUp': {
                transform: "rotate(-180deg)"
            }


        }
    });

};

export default accordionStyles;