import { StyleSheet } from '@convertly/css';

const poweredByConvertlyStyles = ( global, data, { poweredByBannerColor } ) => {

    return StyleSheet.create({
        poweredByConvertly: {
            //position:'absolute',
            //bottom:'0',
            width:'100vw',
            textAlign:'center',
            marginBottom:'50px',
        },
        poweredByConvertlyText: {
            fontFamily:'Rubik, sans-serif',
            fontSize:'14px',
            fontWeight:700,
            display:'inline',
			color: 'inherit'
        },
        convertlyLink: {
            fontWeight:700
        },
        convertlyLogoSmall: {
            height:'14px',
            width:'14px',
            display:'inline',
            marginLeft: '5px',
            verticalAlign: 'text-top'
        }

    })

};

export default poweredByConvertlyStyles;
