export default function StyleObject(global, data) {

    return {

        ".modal-wrapper": {
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: `${ global.colors.dark.color1 }dd`,
            zIndex: '2'
        },
        ".close-text": {
            color: '#fff',
            ":hover": {
                "color": global.colors.accent.color1
            },
            transition: 'all 500ms ease',
            textDecoration: 'underline',
            position: 'absolute',
            top: "10px",
            right: '10px',
            fontSize:'12px',
            cursor:'pointer'
        },
        ".close": {
            display: 'none'
        },
        ".modal": {
            background: '#fff',
            width: '560px',
            position: 'relative',
            top: '50%',
            transform: 'translateY(-50%)',
            margin: '0 auto',
            maxWidth:'100%'
        },
        ".modal-image": {
            width: '100%',
            height: '230px',
            objectFit: 'cover',
            background:global.colors.dark.color3
        },
        ".modal-bottom": {
            padding:'25px'
        },
        ".description-topper": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        ".main-title": {
            fontSize:'26px',
            color:global.colors.dark.color1,
            display:'inline-block',

        },
        ".price": {
            float:'right'
        },
        ".border-bottom": {
            height:'1px',
            borderBottom:"3px solid",
            borderBottomColor:global.colors.dark.color1,
            width:'80%',
            margin:'14px 0'
        },
        ".description-text": {
            fontSize:'16px',
            lineHeight:'26px'
        },
        ".tag": {
            display: 'inline-block',
            padding: "3px 10px",
            backgroundColor: global.colors.light.color3,
            color: global.colors.dark.color1,
            fontSize: "8px",
            marginTop: "3px",
            marginRight: "5px",
            fontFamily:global.fontFamily.secondaryFont
        }



    }


}