import {StyleSheet} from '@convertly/css';
import {clientImage} from '../../utils/generateImage'
import UserOverrides from '../../elements/style-functions/user-overrides';

const Styles = (global, data) => {

    const userOverrides = UserOverrides(global, data)

    /** increases zIndex if there is an overlay or backgrond Image **/
    let zIndex = {
        zIndex: 10
    };
    if (data.backgroundVideo || data.overlay) {
        if (data.backgroundVideo) {
            zIndex = {
                zIndex: 2
            }
        }
        if (data.overlay) {
            zIndex = {
                zIndex: 3
            }
        }

    }
    let overflow = {};
    if (data.backgroundVideo) {
        overflow = {
            overflow: 'hidden'
        }
    }

    let bannerImage = ['home-Banner1.jpg', 'home-Banner2.jpg', 'home-Banner3.jpg', 'home-Banner4.jpg'][Math.floor((Math.random() * 4))];

    return StyleSheet.create({
        homePageHero: {
            display: "block",
            position: 'relative',
            height: "100vh",
            alignItems: "center",
            backgroundImage: `linear-gradient(135deg, rgba(52, 152, 134, .27) 35%, rgba(10, 50, 141, .27) 61%, rgba(52, 152, 134, .73)), url(${ clientImage('home-Banner1.jpg', {width:'2000'}) })`,
            backgroundPosition: "0px 0px, 50% 0px",
            backgroundSize: "auto, cover",
            paddingTop:'275px',
            "@media(max-width:991px)": {
                maxHeight: '500px',
                paddingTop: '90px'
            },
            ...userOverrides
        },

        bottomImg: {
            position: "absolute",
            bottom: "-90px",
            zIndex: "5",
            minWidth: "100%",
            opacity: 1,
            "@media(max-width:991px)": {
                bottom: '0',
                zIndex: 0,
            },
            "@media(max-width:768px)": {
                display:"none"
            },
            "@media(max-height:670px)": {
                bottom:"-120px"
            }
        },
        convertlyContentArea: {
            ...zIndex,
            position: "relative"
        },
        overlay: {
            position: "absolute",
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            background: data.overlay ? data.overlay.background : '',
            zIndex: 2
        }

    })

};

export default Styles;