import {StyleSheet} from '@convertly/css';
import NotificationBoxStyleObject from '../../../ecommerce-common-components/notification-box/notification-box-styles';

const Styles = (global, data) => {

    const NotificationBoxStyles = NotificationBoxStyleObject( global, data );


    const formFieldStyles = {
        backgroundColor: global.colors.light.white,
        boxShadow: '0 0 0px 1000px white inset',
        border: "1px solid",
        borderColor: global.colors.accent.color3,
        color: global.colors.dark.color2,
        display: "block",
        fontSize: "16px",
        height: "38px",
        lineHeight: "18px",
        margin: "0",
        padding: "8px 12px",
        fontWeight: '300',
        verticalAlign: "middle",
        width: '100%',

        '.input[disabled]': {
            boxShadow: `0 0 0px 1000px ${global.colors.light.color3} inset`,
            background: global.colors.light.color3,
            borderColor: global.colors.dark.color3,
            color: global.colors.dark.color3
        },
        '::placeholder': {
            color: global.colors.dark.color3,
            fontWeight: '300',
        },
        ":focus": {
            boxShadow: 'inset 1px 1px 1px 2px rgba(250,250,250,0.3)',
            outline: 'none'
        }
    };

    return StyleSheet.create({

        forgotPasswordInferno: {
            ...NotificationBoxStyles,
            ".home-link": {
                textDecoration:'underline',
                fontWeight:'700',
                color:global.colors.dark.color1,
                marginLeft:'14px'
            },
            ".forgot-password-container": {
                padding:'100px 100',
                width: '475px',
                margin: '0 auto',
                fontFamily: global.fontFamily.primaryFont,
                textAlign:'center'
            },

            ".title": {
                marginTop: "0px",
                marginBottom: "5px",
                color: global.colors.dark.color1,
                fontSize: "25px"
            },
            ".text": {
                marginBottom: '15px',
                color: global.colors.dark.color3,
                fontSize: '13px'
            },

            ".form_HCPV": {
                fontSize: "14px",
                lineHeight: "24px",
                margin: '10px auto 22px',
                maxWidth: '375px'
            },

            ".textInput_wInput": {
                ...formFieldStyles,
                width: "100%"
            },




            ".input-container-half-width": {
                width: "48%",
                height: '55px',
                display: "inline-block",

                '.right': {
                    float: 'right'
                }
            },
            ".input-container": {
                width: "100%",
                height: '55px',
                display: 'block',
                clear: 'both',
            },
            ".btnContinue_wButton": {
                backgroundColor: global.colors.dark.color1,
                border: "0",
                borderRadius: "0",
                color: "white",
                cursor: "pointer",
                display: "block",
                fontSize: "14px",
                lineHeight: "inherit",
                margin: "0",
                padding: "9px 15px",
                paddingBottom: "11px",
                paddingTop: "11px",
                textDecoration: "none",
                textTransform: "uppercase",
                width: "100%",
                textAlign: "center",
                maxWidth: '375px',
                position: 'relative',
                ':disabled': {
                    opacity: '.5',
                    cursor: 'auto'
                }
            },


        }
    })

};

export default Styles;
