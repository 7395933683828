/*global Inferno, $, PubNub */
/* eslint-disable */
import {include} from '@convertly/thor';

const Script = () => {

    // Utility Components
    const Dispatcher = include('../../../utils/dispatcher', 'Dispatcher');
    const Messenger = include('./components/Messenger', 'Messenger');

    // Widget Components
    const LaunchButtonComponent = include('./components/LaunchButton', 'LaunchButtonComponent');
    const ConvertlyChatLauncher = include('./components/ConvertlyChatLauncher', 'ConvertlyChatLauncher');
    const ChatHeaderComponent = include('./components/ChatHeader', 'ChatHeaderComponent');
    const MessageBoardComponent = include('./components/MessageBoard', 'MessageBoardComponent');
    const MessageComponent = include('./components/Message', 'MessageComponent');
    const FormComponent = include('./components/Form', 'FormComponent');
    const EllipsisComponent = include('./components/Ellipsis', 'EllipsisComponent');
    const EllipsisDotComponent = include('./components/EllipsisDot', 'EllipsisDotComponent');

    const StageSelectorComponent = include('./components/StageSelector', 'StageSelectorComponent');

    return (id, siteId, convertlyChatLauncher, cocoImg, watsonEnabled, initialMessage) => {

        const useConvertlyLauncher = convertlyChatLauncher === "true";

        const hasLocalStorage = $('html').hasClass('localstorage');
        let initialStage = 'production';

        if (hasLocalStorage) {
            const storedStage = localStorage.getItem('stage');
            if (storedStage) {
                initialStage = storedStage;
            }
        }

        // Grab styles from DOM
        const styles = {
            convertlyChatWidgetContainer: $('[name=convertlyChatWidgetContainer]').val(),
            convertlyChatWidgetLaunch: $('[name=convertlyChatWidgetLaunch]').val(),
            convertlyChatWidgetLaunchText: $('[name=convertlyChatWidgetLaunchText]').val(),
            convertlyChatWidget: $('[name=convertlyChatWidget]').val(),
            chatWidgetHeader: $('[name=chatWidgetHeader]').val(),
            chatWidgetHeaderTextContainer: $('[name=chatWidgetHeaderTextContainer]').val(),
            chatWidgetHeaderText: $('[name=chatWidgetHeaderText]').val(),
            chatWidgetClose: $('[name=chatWidgetClose]').val(),
            chatWidgetCloseButton: $('[name=chatWidgetCloseButton]').val(),
            iconBar: $('[name=iconBar]').val(),
            messageBoardContainer: $('[name=messageBoardContainer]').val(),
            messagesBoard: $('[name=messagesBoard]').val(),
            systemMessage: $('[name=systemMessage]').val(),
            clientMessage: $('[name=clientMessage]').val(),
            chatWidgetLink: $('[name=chatWidgetLink]').val(),
            agentMessage: $('[name=agentMessage]').val(),
            ellipsisMessage: $('[name=ellipsisMessage]').val(),
            ellipsis: $('[name=ellipsis]').val(),
            formContainer: $('[name=formContainer]').val(),
            messageInput: $('[name=messageInput]').val(),
            submitButton: $('[name=submitButton]').val(),
            coco: $('[name=coco]').val()
        };

        //Initialize Dispatcher
        const dispatch = Dispatcher('chat');

        // Messenger class handles all PubNub logic
        const MessengerFn = Messenger(hasLocalStorage, dispatch, siteId, initialStage, watsonEnabled);
        const messenger = new MessengerFn;

        // Init Sub components
        const EllipsisDot = EllipsisDotComponent(styles);
        const Ellipsis = EllipsisComponent(styles, EllipsisDot);
        const LaunchButton = LaunchButtonComponent(styles);
        const Coco = ConvertlyChatLauncher(styles);
        const ChatHeader = ChatHeaderComponent(styles);
        const Message = MessageComponent(styles);
        const MessageBoard = MessageBoardComponent(styles, Message, Ellipsis, dispatch);
        const Form = FormComponent(styles);

        const StageSelector = StageSelectorComponent(dispatch);

        // Init DOM references
        const root = document.getElementById(id);
        const stylesDiv = document.getElementById('chatStyles');
        const widgetDiv = document.getElementById('convertlyChatWidget');

        // Millisecond interval to send b2c_is_typing signal
        const TYPING_INTERVAL = 3000;
        let LAST_TYPING_SIGNAL = 0;

        // Milliseconds for typing animation to render
        const ZORK_IS_TYPING_INTERVAL = 3000;

        // setTimeout reference
        let resetZorkIsTyping;

        const ConvertlyChatWidget = Inferno.createClass({

            /**
             * Set up initial state
             *
             * @returns {{}}
             */
            getInitialState: function () {

                return {
                    messages: [],
                    zorkIsTyping: false,
                    widgetIsOpen: false,
                    chatLoaded: false,
                    newMessage: '',
                    chatId: null,
                    zork: null,
                    connectedToZork: false,
                    stage: initialStage,
                    $messageBoard: null,
                    messageBoard:null,
					watson: watsonEnabled,

                    // If new chat thread disable the form after first message
                    // is sent while we wait for Central to create new Chat Thread ID
                    waitForCentral: false,

                    //Flag for triggering chat_initiated event
                    sentFirstMessage: false
                };
            },

            scrollToBottom() {

                let $messageBoard = this.state.$messageBoard,
                messageBoard = this.state.messageBoard;

                let scrollHeight = messageBoard.scrollHeight;
                //console.log(scrollHeight, $messageBoard, messageBoard);
                $messageBoard.scrollTop(scrollHeight);
            },

            /**
             * Register dispatch events
             */
            componentWillMount() {

                dispatch.on('setChatLoaded', (chatLoaded) => {
                    this.setState({chatLoaded});
                });

                dispatch.on('setChatId', (chatId) => {
                    this.setState({chatId, waitForCentral: false});
                });

                dispatch.on('setZork', (zork) => {
                    this.setState({
                        zork: zork,
                        connectedToZork: true
                    });
                });

                dispatch.on('setZorkIsTyping', () => {

                    // if flag already set, reset the timeout
                    if (this.state.zorkIsTyping) {
                        clearTimeout(resetZorkIsTyping);
                    }

                    // set the timeout
                    const cb = () => {
                        resetZorkIsTyping = setTimeout(() => {
                            this.setState({zorkIsTyping: false});
                        }, ZORK_IS_TYPING_INTERVAL);
                    };

                    this.setState({zorkIsTyping: true}, cb.bind(this));
                });

                dispatch.on('addMessage', ({message, from}) => {

                    const messages = this.state.messages;

                    messages.push({
                        message,
                        from
                    });
                    if(from === "zork") {
                        this.setState({
                            messages,
                            zorkIsTyping:false
                        });
                    }
                    else {
                        this.setState({
                            messages
                        });
                    }


                });

                dispatch.on('chatEnded', (payload) => {});

                dispatch.on('setStage', stage => {

                    this.setState({
                        messages: [],
                        zorkIsTyping: false,
                        chatId: null,
                        zork: null,
                        connectedToZork: false,
                        stage: stage,
                        waitForCentral: false
                    });

                    if (hasLocalStorage) {
                        localStorage.setItem('stage', stage);
                    }

                });

                dispatch.on('endChat', () => messenger.endChat());

                dispatch.on('resetChat', () => messenger.resetChat());

                dispatch.on('resetEverything', () => messenger.resetState());

                dispatch.on('sendToAgent', () => {
                	this.setState({
						watson: false,
						newMessage: 'Transferring to agent'
					}, () => this.sendMessage() );
				});

                dispatch.on('messageBoardRendered', () => {
                    this.setState({
                        $messageBoard: $( '#messageBoard' ),
                        messageBoard: document.getElementById('messageBoard')
                    })
                })

            },

            /**
             * Initialize messenger if the widget is open as default state
             * Removes the style input fields from the DOM
             */
            componentDidMount() {

                if (this.isOpen()) {
                    messenger.init();
                }

                if(initialMessage !== "undefined") {


                    const messages = this.state.messages;

                    messages.push({
                        message: initialMessage,
                        from: 'zork'
                    });

                    this.setState({ messages });
                }

                root.removeChild(stylesDiv);
            },

            /**
             * Check if widget is open
             *
             * @returns {boolean}
             */
            isOpen() {
                return this.state.widgetIsOpen;
            },

            /**
             * Check if chat library is loaded
             *
             * @returns {boolean}
             */
            chatIsLoaded() {
                return this.state.chatLoaded;
            },

            /**
             * Close the chat widget
             */
            closeWidget () {
                this.setState({
                    widgetIsOpen: false
                });
                $('#page-app').removeAttr('style')
            },

            /**
             * Open the chat widget. Load PubNub sdk if not already loaded
             */
            openWidget () {

                if (!this.chatIsLoaded()) {
                    messenger.init();
                }

                this.setState({
                    widgetIsOpen: true
                });
                if( window.innerWidth < 599 ) {
                    $('#page-app').css('height', "100vh")
                }

            },

            /**
             * Update new message form event handler
             * @param e
             */
            onUpdateNewMessage(e) {

                const value = e.target.value;

                this.setState({
                    newMessage: value
                });

                // only send b2c_is_typing if chat has been claimed
                if (this.state.connectedToZork) {

                    const now = new Date().getTime();

                    if (now - LAST_TYPING_SIGNAL > TYPING_INTERVAL) {
                        messenger.sendTypingStatus();
                        LAST_TYPING_SIGNAL = now;
                    }

                }

            },

            /**
             * Send message
             * Add new messages to view
             * Set waitForCentral flag if need to wait for new chat thread to be created
             */
            sendMessage() {

                const message = this.state.newMessage;
                const sentFirstMessage = this.state.sentFirstMessage;

                this.setState({
                    newMessage: '',
                    sentFirstMessage: true
                });

                // Add the new message to the view
                const cb = (status, response) => {

                    const messages = this.state.messages;

                    messages.push({
                        message: message,
                        from: 'b2c'
                    });

                    this.setState({messages: messages}, () => {

                        // there's a bug if you set the messages and waitForCentral
                        // in the same setState call.
                        if ( !this.state.chatId && ! this.state.watson ) {
                            this.setState({
                                waitForCentral: true
                            });
                        }

                    });
                };

                messenger.sendMessage(message, cb);

                if (!sentFirstMessage) {
                    Dispatcher().send('event', {action: 'chat_initiated'});
                }

            },

            /**
             * Main render function
             *
             * @returns {XML}
             */
            render: function () {

                const {
                    zork, messages, newMessage, waitForCentral, widgetIsOpen,
                    chatId, zorkIsTyping, stage, chatLoaded
                } = this.state;

                const openClass = widgetIsOpen ? ' open' : '';

                let placeholderMessage = 'Your message here.';

                if (waitForCentral && !chatId) {
                    placeholderMessage = 'Registering new chat...';
                }
                return (
                    <div className={styles.convertlyChatWidgetContainer + " chatArea"}>

                        { useConvertlyLauncher ?
                            <Coco onClick={ this.openWidget } cocoImg={ cocoImg }/>
                            :
                            <LaunchButton onClick={this.openWidget}/> }

                        <div className={styles.convertlyChatWidget + openClass}>


                            <ChatHeader onClose={this.closeWidget}/>

                            <MessageBoard scrollToBottom={this.scrollToBottom}
                                          zork={zork}
                                          messages={messages}
                                          zorkIsTyping={zorkIsTyping}/>

                            { chatLoaded && <Form
                                onUpdateMessage={this.onUpdateNewMessage}
                                message={newMessage}
                                sendMessage={this.sendMessage}
                                disabled={waitForCentral}
                                placeholderMessage={placeholderMessage}
                            /> }
                        </div>
                    </div>
                )
            }

        });

        Inferno.render(<ConvertlyChatWidget/>, widgetDiv);

    };

};

export default Script;
