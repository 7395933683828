/* global window */

import { include } from '@convertly/thor';

/**
 *
 * @returns {function(*)}
 */
const Script = () => {

	const Dispatcher = include( '../utils/dispatcher', 'Dispatcher' );

	return () => {

		const dispatch = Dispatcher();

		window.onYouTubeIframeAPIReady = () => {
			dispatch.send( 'youtube_api_ready' );
		};

	}
};

export default Script;
