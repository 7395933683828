/*

 */

const Script = () => {

	return () => {

		const app = document.getElementById( 'page-app' );
		app.TheStore = {};

	};

};

export default Script;
