export function generateYouTubeEmbed( src ) {

    let youTubeEmbedCode;
    
    if( ! src ) {
    	return '';
		}

    if( src.indexOf( '<', 0 ) === 0 ) {
        let embedCodeArray = src.split('\"');
        embedCodeArray.forEach((segment) => {
            if (segment.indexOf('http') === 0) {
                return youTubeEmbedCode = segment
            }
        });
    }
    else {
        return coerceIntoEmbedCode( src );
    }

        if(youTubeEmbedCode) {
            if (youTubeEmbedCode.length) {
                return youTubeEmbedCode;
            }
        }
        else {

        }

        function coerceIntoEmbedCode( url ) {
            if( url.indexOf("embed") === -1 ) {
                if( url.indexOf("youtube") !== -1 ) {
                    if( url.indexOf('watch?v=') !== -1 ) {
                        url = url.replace(/watch\?v=|&feature=youtu.be/g, '')
                        //url.replace('watch?v=', '"')
                    }
                    let embedStart = url.indexOf('com/') + 3;
                    url = url.split('');
                    url[embedStart] += 'embed/';
                    url = url.join('');

                        return youTubeEmbedCode = url

                }
                else {
                    let embedStart = url.indexOf('be/') + 2;

                    let embedCode = url.slice(embedStart, url.length);

                    return youTubeEmbedCode = "https://www.youtube.com/embed" + embedCode;

                }
            }
            else {
                return url;
            }
        }

}
