import React from "react";
import PropTypes from "prop-types";
import Component from "../../rootComponent";
import { css } from "@convertly/css";
import ReactHTMLParser from "@convertly/react-html-parser";

//Default props
import defaultGlobal from "../../themes/global.js";

//Styles
import headingStyles from "./heading2-styles";

//Utils
import generateId from "../../utils/generate-id";
import { generateStyle } from "source/utils/generate-attribute";

/**
 * Class controls imports style functions that allows for user to override the css in the CMS editor for their site.
 **/

class Heading2 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const inEditor = this.inEditor();
    /**
     * Calls Stylesheet function
     **/

    this.styles = headingStyles(
      this.props.global,
      this.props.data,
      this.props.data.headingStyle,
      inEditor
    );

    /**
     * Checks for default class name, if it exists, add to class
     **/

    let className = "";
    let text = ReactHTMLParser(this.props.data.text);
    let aphroditeClass = css(this.styles.heading2);
    if (this.animatedClassName) {
      //className += " " + this.animatedClassName
    }
    className += aphroditeClass;

    const { animated } = this.props.data;

    const inlineStyle = this.generateStyleAttribute();

    /**
     * Loads h2 element
     **/

    return (
      <h2
        {...this.generateDataEditorAttribute()}
        {...generateId(this.props.elementId)}
        {...this.generateCustomAttributes()}
        {...inlineStyle}
        data-animated={animated}
        className={className}
      >
        {text}
      </h2>
    );
  }
}

/**
 * Default Props for rendering when no data is being passed
 **/
Heading2.defaultProps = {
  data: {
    text: "",
    bold: false,
    italic: false,
    underline: false,
    align: "center",
    fontSize: "",
    color: "",
  },
  global: defaultGlobal,
};

/**
 * Connects element to context for cms editor
 **/
Heading2.contextTypes = {
  store: PropTypes.object,
};

export default Heading2;

/**
 Component BoilerPlate

{
    "el": "Elements.Heading2",
    "data": {
        "text": "Your text here",
        "userOverrides": {

        }
    },
    "children": [],
    "sort": 900
}

 **/
