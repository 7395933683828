import { StyleSheet } from "@convertly/css";
import Typography from '../../../elements/style-functions/typography';

const footerNavItemStyles = ( global, local ) => {

    const navLinkTypography = new Typography( global, local );

    return StyleSheet.create({
        footerNavLink: {
            ...navLinkTypography
        }
    })
};

export default footerNavItemStyles;