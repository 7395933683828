import React, { Component } from "react";
import PropTypes from "prop-types";
import { SliderBar } from "./SliderBar";
import { SliderHandle } from "./SliderHandle";
import { ToolTip } from "./ToolTip";
import interact from "interactjs";
import { findDOMNode } from "react-dom";
import styled from "styled-components";
import _ from "lodash";

const Wrapper = styled.div`
  position: relative;
  height: 35px;
`;

// MAX/MIN pixel position of handle
let MAX_X_POSITION = 135;
const MIN_X_POSITION = 0;

export class Slider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dragging: false,
      firstDrag: false
    };

    this.onMove = this.onMove.bind(this);
    this.onStart = this.onStart.bind(this);
    this.onEnd = this.onEnd.bind(this);
  }

  /**
   * Init dragging with interactjs
   */
  componentDidMount() {
    this.setState({
      ...this.calculateConstants()
    });
    interact(findDOMNode(this.handle.el)).draggable({
      onmove: this.onMove,
      onstart: this.onStart,
      onend: this.onEnd
    });
  }

  /**
   * Check if max or min props have updated
   *
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    if (prevProps.max !== this.props.max || prevProps.min !== this.props.min) {
      this.setState({
        ...this.calculateConstants()
      });
    }
  }

  /**
   * Calculates the constants used
   *
   * @returns {{SCALING_FACTOR: number, OFFSET: number}}
   */
  calculateConstants() {
    const styles = getComputedStyle(this.bar.el);
    let handleWidth = _.get(this.props, "sliderHandleStyle.width") || 20;
    if (!_.isNumber(handleWidth)) {
      handleWidth = parseInt(handleWidth);
    }

    let width = styles["width"];
    width = parseInt(width.replace("px", ""));

    if (width !== MAX_X_POSITION) {
      MAX_X_POSITION = width - handleWidth;
    }

    const { min, max } = this.props;
    const SCALING_FACTOR = MAX_X_POSITION / (max - min);
    const OFFSET = -1 * min * SCALING_FACTOR;
    return {
      SCALING_FACTOR,
      OFFSET
    };
  }

  /**
   * Callback for dragging start
   */
  onStart() {
    this.setState({
      dragging: true,
      firstDrag: true
    });
  }

  /**
   * Callback for dragging stop
   */
  onEnd() {
    this.setState({
      dragging: false
    });
    this.props.onEnd();
  }

  /**
   * Callback for drag move event
   * Calculates the new value
   * @param e
   */
  onMove(e) {
    const { value, update, max, min, convert } = this.props;

    const { clientX, dx } = e;
    const { left, right } = this.handle.el.getBoundingClientRect();

    if (clientX < left && value === min) {
      return;
    }

    if (clientX > right && value === max) {
      return;
    }

    const dValue = dx / this.state.SCALING_FACTOR;

    let newValue = value + dValue;

    if (newValue > max) {
      newValue = max;
    }

    if (newValue < min) {
      newValue = min;
    }

    update(Number(convert(newValue)));
  }

  render() {
    const { value, style, sliderBarStyle, sliderHandleStyle, twelveGridMarks } = this.props;
    const { SCALING_FACTOR, OFFSET, firstDrag, dragging } = this.state;
    const position = SCALING_FACTOR * value + OFFSET;
    const label = Math.round(value).toString();
    return (
      <Wrapper style={style}>
        <ToolTip firstDrag={firstDrag} x={position} label={label} dragging={dragging} />
        <SliderHandle
          sliderHandleStyle={sliderHandleStyle}
          x={position}
          ref={el => (this.handle = el)}
        />
        <SliderBar
          ref={el => (this.bar = el)}
          style={sliderBarStyle}
          twelveGridMarks={twelveGridMarks}
        />
      </Wrapper>
    );
  }
}

Slider.propTypes = {
  update: PropTypes.func,
  value: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  convert: PropTypes.func,
  onEnd: PropTypes.func,
  style: PropTypes.object
};

Slider.defaultProps = {
  update: () => {},
  value: 0,
  max: 10,
  min: 0,
  convert: x => x.toFixed(2),
  onEnd: () => {},
  style: {}
};
