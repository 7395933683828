import React from 'react';
import Component from '../../../../rootComponent';
import {css} from "@convertly/css";
import Types from '../../../../attribute-types';
import {id} from '@convertly/thor';


//scripts
import toggleDropDown from '../../scripts/nav-dropdown-toggle';

import {generateLink} from '../../../../utils/generateLink';

//Components
import NavLink05 from './convertly-nav-link';

export default class NavList05 extends Component {

    constructor(props) {

        super(props);

    }


    render() {


        return (
            <nav className={ css(this.props.styles.headerNav)  }
                 data-element="mobileMenu">
                <ul className={ css(this.props.styles.navList) }
                    id="menu"
                >
                    {this.props.data.navLinks.map((navItem, index) => {
                        return (
                            <NavLink05 key={ index }
                                       data={navItem}
                                       page={ this.props.page }
                                       styles={ this.props.styles }
                                       index={ index }
                            />
                        )
                    })}
                        <span>
                            <li className={ css(this.props.styles.navListItem) + " login-link"}>
                                    <a href={ generateLink({url: 'https://partners.convertly.com/managed-services', linkType: "external"}) }>START</a>
                            </li>
                        </span>
                </ul>
            </nav>
        )
    }
}

