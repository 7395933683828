import React from 'react';
import { CategorySelection } from './components';

/** First stage, here the user selects the area they want to give feedback on
 * categories are gotten from a list in ../index.js state
 *
 */
export const FeedbackCategory = ({categories, activeCategoryText, setCategory, name}) => {

  return (
    <>
        <p>{`Hi${ name ? ` ${name}!` : '!' } Have a question or comments for us? What do you want to contact us about?`}</p>
      { /** Iterates through the categories, creates a button which has an onclick handler that will
            update the active category**/}
      { categories.map( cat => {
        return (
        <CategorySelection
          onClick={setCategory}
          key={cat.type}
          data-category={cat.text}
          data-category-type={cat.type}
          selected={cat.text === activeCategoryText} >{ cat.text }
        </CategorySelection>)
      } ) }
    </>
  )

}
