import React from 'react';
import Component from '../../rootComponent';
import {css} from '@convertly/css';
import defaultGlobal from '../../themes/global.js';
import {PlaceholderContent} from './PlaceholderContent';

//Styles
import userOverrides from './bootstrap-user-overrides';


export default class Column extends Component {
  constructor(props) {
    super(props);
  }


  //I want to  return false for either 0 or undefined, thus  a triple !
  //operator we want it to return false if there is children
  checkForChildren  =   () => {

    return !!!_.get(this.props, 'children');

  };

  hasBackground = () => {
    const userOverrides = _.get(this.props, 'data.userOverrides')
    let hasBackground = false;

    for(var key in userOverrides) {

      if(key.includes('background')) hasBackground = true;
    }

    return hasBackground;

  };

  shouldRenderPlaceholder() {
    return this.inEditor() && this.checkForChildren() && !this.hasBackground()
  }

  render() {

    /**
     * Generates column size based on container
     **/

    let columnClass = '';

    const inEditor = this.inEditor();

    this.styles = userOverrides(this.props.global, this.props.data, inEditor);

    function generateClassString(dataProp, bootstrapProperty) {

      if (!dataProp && dataProp !== 0) {
        return columnClass
      } else {
        //console.log( 'COLUMN WIDTH', bootstrapProperty, dataProp )
        switch (parseInt(dataProp)) {
          case 1:
            columnClass += "col-" + bootstrapProperty + "-1 ";
            break;
          case 2:
            columnClass += "col-" + bootstrapProperty + "-2 ";
            break;
          case 3:
            columnClass += "col-" + bootstrapProperty + "-3 ";
            break;
          case 4:
            columnClass += "col-" + bootstrapProperty + "-4 ";
            break;
          case 5:
            columnClass += "col-" + bootstrapProperty + "-5 ";
            break;
          case 6:
            columnClass += "col-" + bootstrapProperty + "-6 ";
            break;
          case 7:
            columnClass += "col-" + bootstrapProperty + "-7 ";
            break;
          case 8:
            columnClass += "col-" + bootstrapProperty + "-8 ";
            break;
          case 9:
            columnClass += "col-" + bootstrapProperty + "-9 ";
            break;
          case 10:
            columnClass += "col-" + bootstrapProperty + "-10 ";
            break;
          case 11:
            columnClass += "col-" + bootstrapProperty + "-11 ";
            break;
          case 12:
            columnClass += "col-" + bootstrapProperty + "-12 ";
            break;
          default:
            columnClass += "col-" + bootstrapProperty + "-0 ";
            break;
        }
      }
    }

    generateClassString(this.props.data.xsOffset, "xs-offset");
    generateClassString(this.props.data.xsColumns, "xs");
    generateClassString(this.props.data.xsPull, "xs-pull");
    generateClassString(this.props.data.xsPush, "xs-push");
    generateClassString(this.props.data.smOffset, "sm-offset");
    generateClassString(this.props.data.smColumns, "sm");
    generateClassString(this.props.data.smPull, "sm-pull");
    generateClassString(this.props.data.smPush, "sm-push");
    generateClassString(this.props.data.mdOffset, "md-offset");
    generateClassString(this.props.data.mdColumns, "md");
    generateClassString(this.props.data.mdPull, "md-pull");
    generateClassString(this.props.data.mdPush, "md-push");
    generateClassString(this.props.data.lgOffset, "lg-offset");
    generateClassString(this.props.data.lgColumns, "lg");
    generateClassString(this.props.data.lgPull, "lg-pull");
    generateClassString(this.props.data.lgPush, "lg-push");

    /**
     * Adds ID and sort to elements
     * @param props
     * @returns {*}
     **/
    let className = columnClass;
    const aphroditeClass = css(this.styles.userOverrides);

    className += aphroditeClass;


    function renderChildren(props) {
      return React.Children.map(props.children, child => {
        return React.cloneElement(child, {
          moduleId: props.moduleId,
          moduleSort: props.moduleSort,
          styles: props.styles,
          site: props.site,
          sliderSettings: props.sliderSettings ? props.sliderSettings : null
        });
      })
    }

    const inlineStyle = this.generateStyleAttribute();
    const {animated} = this.props.data;
    if (this.animatedClassName) {
      className += " " + this.animatedClassName;
    }

    return ( this.shouldRenderPlaceholder() ) ?
      (<div
        {...this.generateCustomAttributes()}
        className={`${className} ${this.props.data.xsColumns ? '' : 'col-xs-12'}`}
        {...this.generateDataEditorAttribute()}
        onMouseOver={this.addEditableOutline}
        onMouseOut={this.removeEditableOutline}
        onClick={(e) => {
          this.launchElementalEditor('column', e);
        }}
        data-animated={animated}
        {...inlineStyle}>
        <PlaceholderContent/>
      </div>)
      : (
        <div
          {...this.generateCustomAttributes()}
          className={`${className} ${this.props.data.xsColumns ? '' : 'col-xs-12'}`}
          {...this.generateDataEditorAttribute()}
          onMouseOver={this.addEditableOutline}
          onMouseOut={this.removeEditableOutline}
          onClick={(e) => {
            this.launchElementalEditor('column', e);
          }}
          data-animated={animated}
          {...inlineStyle}>
          {renderChildren(this.props)}
        </div>
      )
  }
};

Column.defaultProps = {
  global: defaultGlobal,
  data: {}
};

/**
 Component BoilerPlate

 {
    "el": "Elements.Column",
    "data": {
        "xsOffset": 12,
        "xsColumns" 12,
        "xsPull": 12,
        "xsPush": 12,
        "userOverrides": {

        }
    },
    "children": [
        {
            "el": "Elements.Heading1",
            "data": {
                "text": "Your text here",
                "userOverrides": {

                }
            },
            "children": [],
            "sort", 900
        }
    ],
    "sort", 900
}

 **/
