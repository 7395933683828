import { generatePseudoMediaQuery } from "../../../elements/style-functions/pseudo-media-queries";
import { generateMSStyleSheet } from "../../../utils/browserSpecificStyles";

export default function addToCartStyleObject(global, data, inEditor) {

	return {
    ".outer-circle": {
      width: '113px',
      borderLeft: `2px solid ${ global.colors.light.color3 }`,
      borderRight: `2px solid ${ global.colors.light.color3 }`,
      borderRadius: '17px',
      height: '70%',
      position: 'relative',
      top: '12%',

    },
    ".middle-circle": {
      height: '141%',
      margin: '0 auto',
      width: '77%',
      borderLeft: `2px solid ${ global.colors.light.color3 }`,
      borderRadius: '25px',
      borderRight: `2px solid ${ global.colors.light.color3 }`,
      position: 'absolute',
      top: '-16%',
      left: '12%'
    },
    ".inner-circle": {
      height: '100%',
      width: '69%',
      margin: '0 auto',
      border: `2px solid ${ global.colors.light.color3 }`,
      borderRadius: '28px',
    },
		".gridBuyingOptions .add-to-cart-qty-stepper-container": {
			width: '60%',
			textAlign: 'center',
			verticalAlign: 'middle',
			display: 'inline-flex',
      height: data.columns === 6 ? '35px' : '55px',
			justifyContent:'space-around',
			transition:'background 1s ease',
      backgroundColor: 'rgba(0, 0, 0, .5)'
    },
    ".modalBuyingOptions .add-to-cart-qty-stepper-container": {
      width: '50%',
      textAlign: 'center',
      verticalAlign: 'middle',
      display: 'inline-flex',
      height: '55px',
      justifyContent: 'space-around',
      transition: 'background 1s ease',
      backgroundColor: 'rgba(0, 0, 0, .5)'
    },
		".qty-adjust-btn-container": {
			alignSelf:'center',
			color: '#fff',
      zIndex:'2',
      ...generateMSStyleSheet({
        height: '20px'
      }),
		},
		".pull-left": {
      marginRight: data.columns == 6 ? '9px' : '20px',
      float: 'right',
			// transform: data.columns == 6 ? 'translateX(-9px)' : 'translateX(-20px)'
		},
		".pull-right": {
      marginLeft: data.columns == 6 ? '9px' :'20px',
			// transform: data.columns == 6 ? 'translateX(9px)' : 'translateX(20px)'
    },
    ".pull-down": {
      transform: 'translateY(3px)'
    },
		".qty-adjust-btn": {
			cursor: "pointer",
			height: '20px',
			width: '15px',
			fill: '#fff7',
			":hover": {
        fill:'#fff',
        transition: 'all 400ms ease'
			}
		},
		".add-to-cart-button": {
			cursor: "pointer",
			display: "inline-block",
			textAlign: "center",
			color:'#fff',
			background:'transparent',
			transition: 'ease 400ms',
			border: 'none',
			fontSize:'9px',
			lineHeight:'10px',
			width:'100%',
      zIndex:'1',
      // ...generatePseudoMediaQuery(
      //   "@media(max-width:470px)",
      //   ['mobile'],
      //   {
      //     margin: '0 10px',
      //     padding: '0px 35px'
      //   },
      //   inEditor
      // ),
			".disabled": {
				opacity:'.3'
			}
		},
		".cart-button-qty": {
			display: 'block',
			padding: '4px 0px 3px',
			fontSize: data.columns == 6 ? '14px' : '16px',
			lineHeight: data.columns == 6 ? '6px' : '16px',
			fontWeight: '700',
	}
}
	
	
}
