import { StyleSheet } from "@convertly/css";
import UserOverrides from "../../elements/style-functions/user-overrides";
import removeNestedUserOverrides from "../../site-header/site-header/utils/removeNestedUserOverrides";

export const SubtitleStyles = ({ global, data, inEditor }) => {
  const subtitle = removeNestedUserOverrides({ data }),
      { userOverrides } = data,
      { link, date, name } = userOverrides;

  return StyleSheet.create({
    subtitleStyle: {
      ...global.h4,
      ...UserOverrides(global, { userOverrides: subtitle }, inEditor)
    },

    link: {
      ...UserOverrides(global, link, inEditor)
    },

    date: {
      ...UserOverrides(global, date, inEditor)
    },

    name: {
      ...UserOverrides(global, name, inEditor)
    }
  });
};
