import styled from "styled-components";

export const TabStyled = styled.div`
  .react-tabs {
    padding: 0;
    margin: 0;
    &:focus {
      outline: none;
    }
  }
  .react-tabs__tab-list {
    padding: 0;
    margin: 0;
    //border-bottom: 1px solid #c6c6c6;
  }
  .react-tabs__tab {
    display: inline-block;
    position: relative;
    list-style: none;
    padding: 10px 0 15px 0;
    cursor: pointer;
    margin-top: 5px;
    margin-left: 20px;
    user-select: none;
    color: #999;
    &:not(.react-tabs__tab--selected) {
      &:hover {
        color: #000;
        background: linear-gradient(
            to right,
            rgba(139, 217, 178, 0.5) 0%,
            rgba(69, 170, 145, 0.5) 100%
          )
          left bottom no-repeat;
        background-size: 100% 4px;
      }
    }
  }
  .react-tabs__tab--selected {
    color: #000;
    // this is same as hover background but is in hex
    background: linear-gradient(to right, #8bd9b2 0%, #45aa91 100%) left bottom no-repeat;
    //background: linear-gradient(to right, #349886 0%, #349886 100%) left bottom no-repeat;
    background-size: 100% 4px;
  }
`;

export default TabStyled;
