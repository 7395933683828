import {StyleSheet} from "@convertly/css";
import UserOverrides from '../../style-functions/user-overrides';

const checkboxInputStyles = (global, data) => {

	const userOverrides = new UserOverrides(global, data);
	return StyleSheet.create({

		checkboxInput: {
			...global.checkboxInput
		},
		checkboxLabel: {
			...global.checkboxLabel,
			...userOverrides
		}
	})
};

export default checkboxInputStyles;

