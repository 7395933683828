import React from 'react';
import Component from '../../../../rootComponent';
import {css} from "@convertly/css";


export default class ConvertlyLogo extends Component {
    constructor(props) {
        super(props);
    }

    render() {

        let {className} = this.props;

        const path = {"xlink:href":"#path-2"}

        /*viewBox="0 0 404.308 404.309"*/
        return (
            <div style={ {margin:"-11px 0 0 -6px"} }>

                <svg width="70px" height="22px" viewBox="0 20 70 22" version="1.1" xmlns="http://www.w3.org/2000/svg">

                    <title>Group 6</title>
                    <desc>Created with Sketch.</desc>
                    <defs>
                        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
                            <stop stop-color="#94DFB5" offset="0%"/>
                            <stop stop-color="#349886" offset="100%"/>
                        </linearGradient>
                        <path d="M18.271294,16.7879003 C16.9913561,19.0570755 14.5312395,20.5923996 11.7062078,20.5923996 C7.56216801,20.5923996 4.20291719,17.2881486 4.20291719,13.2136741 C4.20048198,11.4193483 4.86546386,9.68610066 6.07255373,8.34054425 L4.49568279,6.83749426 C5.56344222,5.37518313 7.13766677,4.34685771 8.92232989,3.94589043 L9.63184367,6.12182936 C11.2710084,5.66077772 13.0207277,5.76010801 14.5950541,6.40358719 L15.8502475,2.44463345 C13.445519,1.55289387 10.8097984,1.46111745 8.34695693,2.18336709 L7.63457801,0 C5.19793566,0.626461815 3.03155044,2.00865702 1.45680713,3.94153599 L3.0519108,5.46149144 C1.08474385,7.57929761 -0.00412898661,10.3450713 1.17665261e-05,13.213418 C1.17665261e-05,19.5714116 5.2411411,24.7252747 11.7062078,24.7252747 C15.8148239,24.7252747 19.4282911,22.6433405 21.5172414,19.4943124 L18.271294,16.7879003 Z" id="path-2"/>
                    </defs>
                    <g id="Home-Copy-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(-454.000000, -1169.000000)">
                        <g id="Group-6" transform="translate(454.000000, 1169.000000)">
                            <g id="C-symbol" transform="translate(8.000000, 0.000000)" fillRule="nonzero">
                                <g id="C_symbol" transform="translate(10.758621, 12.362637) rotate(-360.000000) translate(-10.758621, -12.362637) ">
                                    <use fill="url(#linearGradient-1)" fillRule="evenodd" { ...path }/>
                                    <use fill="#FFFFFF" fillRule="evenodd" { ...path }/>
                                </g>
                            </g>
                            <text id="[-BETA-]" font-family="Rubik-Regular, Rubik" font-size="10" font-weight="normal" fill="#FFFFFF">
                                <tspan x="0" y="40">[ BETA ]</tspan>
                            </text>
                        </g>
                    </g>
                </svg>
            </div>
        )
    }
}
