import React from 'react';
import Component from '../../rootComponent';
import { css } from "@convertly/css";
import { id } from '@convertly/thor';
import _ from 'lodash';
import { clientImage } from '../../utils/generateImage';

//Default Props
import defaultGlobal from '../../themes/global';

//Styles
import chatWidgetStyles from './chat-widget-styles-01';

//Scripts
import ConvertlyChatWidget from './scripts/convertlyChatWidget';

export default class ChatWidget03 extends Component {

	constructor ( props ) {

		super ( props );

	}

	render () {

		this.styles = chatWidgetStyles ( this.props.global, this.props.data );

		if ( this.props.editor ) {
			return this.editor ();
		}

		const siteId = _.get( this.props, 'site.siteId' );
		const watsonEnabled = _.get( this.props, 'site.watson_enabled' ) ? 1 : 0;
		const useConvertlyLauncher = _.get( this.props, 'data.convertlyLauncher' );
		let cocoImg = useConvertlyLauncher ? clientImage( 'coco-convertly-glow.png', {width:75, height:75} ) : '';
		return (
			<div id={ id ( ConvertlyChatWidget, siteId, useConvertlyLauncher, cocoImg, watsonEnabled, this.props.data.initialMessage ) }>
				<div id="convertlyChatWidget"/>
				<div id="chatStyles">
					<input type="hidden" name="convertlyChatWidgetContainer" value={ css ( this.styles.convertlyChatWidgetContainer ) } />
					<input type="hidden" name="convertlyChatWidgetLaunch" value={ css ( this.styles.convertlyChatWidgetLaunch ) } />
					<input type="hidden" name="convertlyChatWidgetLaunchText" value={ css ( this.styles.convertlyChatWidgetLaunchText ) } />
					<input type="hidden" name="convertlyChatWidget" value={ css ( this.styles.convertlyChatWidget ) } />
					<input type="hidden" name="chatWidgetHeader" value={ css ( this.styles.chatWidgetHeader ) } />
					<input type="hidden" name="chatWidgetHeaderTextContainer" value={ css ( this.styles.chatWidgetHeaderTextContainer ) } />
					<input type="hidden" name="chatWidgetHeaderText" value={ css ( this.styles.chatWidgetHeaderText ) } />
					<input type="hidden" name="chatWidgetClose" value={ css ( this.styles.chatWidgetClose ) } />
					<input type="hidden" name="chatWidgetCloseButton" value={ css ( this.styles.chatWidgetCloseButton ) } />
					<input type="hidden" name="iconBar" value={ css ( this.styles.iconBar ) } />
					<input type="hidden" name="messageBoardContainer" value={ css ( this.styles.messageBoardContainer ) } />
					<input type="hidden" name="messagesBoard" value={ css ( this.styles.messagesBoard ) } />
					<input type="hidden" name="systemMessage" value={ css ( this.styles.systemMessage ) } />
					<input type="hidden" name="clientMessage" value={ css ( this.styles.clientMessage ) } />
					<input type="hidden" name="chatWidgetLink" value={ css ( this.styles.chatWidgetLink ) } />
					<input type="hidden" name="agentMessage" value={ css ( this.styles.agentMessage ) } />
					<input type="hidden" name="ellipsisMessage" value={ css ( this.styles.ellipsisMessage ) } />
					<input type="hidden" name="ellipsis" value={ css ( this.styles.ellipsis ) } />
					<input type="hidden" name="formContainer" value={ css ( this.styles.formContainer ) } />
					<input type="hidden" name="messageInput" value={ css ( this.styles.messageInput ) } />
					<input type="hidden" name="submitButton" value={ css ( this.styles.submitButton ) } />
					<input type="hidden" name="coco" value={ css ( this.styles.coco ) } />
				</div>
			</div>
		)
	}
}


ChatWidget03.defaultProps = {
	global: defaultGlobal
};
