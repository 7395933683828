import { fromJS } from "immutable";
import _ from "lodash";

import { Notifications } from "services/Notifications";
import { activeSite, graph, adtech } from "utils";
import { getActiveSiteId } from "services/sites";
import { Alerts } from "services/Alerts";
import { showLoadingIcon, withLoadingIcon } from "containers/App/actions";
import { setElements } from "containers/Editor/actions/editorActions";
import {
  updateSetting,
  setActiveBanner,
  setSetting,
  setActiveBannerId,
  setBanners,
  setSaving,
  HashSorter,
  setBannerSizes,
} from "../actions";
import { store } from "app/app";
import { can } from "containers/Authentication/PermissionsCheck";

import banner_size_120x600 from "components/icons/bannerIcons/banner_size_120x600.svg";
import banner_size_160x600 from "components/icons/bannerIcons/banner_size_160x600.svg";
import banner_size_200x200 from "components/icons/bannerIcons/banner_size_200x200.svg";
import banner_size_250x250 from "components/icons/bannerIcons/banner_size_250x250.svg";
import banner_size_300x250 from "components/icons/bannerIcons/banner_size_300x250.svg";
import banner_size_300x600 from "components/icons/bannerIcons/banner_size_300x600.svg";
import banner_size_336x280 from "components/icons/bannerIcons/banner_size_336x280.svg";
import banner_size_468x60 from "components/icons/bannerIcons/banner_size_468x60.svg";
import banner_size_728x90 from "components/icons/bannerIcons/banner_size_728x90.svg";
import banner_size_970x90 from "components/icons/bannerIcons/banner_size_970x90.svg";
import banner_size_custom from "components/icons/bannerIcons/banner_size_custom.svg";

export const initMainComponent = () => async dispatch => {
  await dispatch(showLoadingIcon(true));

  await Promise.all([dispatch(getAllBanners()), dispatch(getBannerSizes())]);

  await dispatch(showLoadingIcon(false));
};

export const getBannerSizes = () => async dispatch => {
  const bannerSizes = [
    {
      title: "Square",
      icon: banner_size_250x250,
      dimensions: "250x250",
    },
    {
      title: "Small Square",
      icon: banner_size_200x200,
      dimensions: "200x200",
    },
    {
      title: "Banner",
      icon: banner_size_468x60,
      dimensions: "468x60",
    },
    {
      title: "Large Rectangle",
      icon: banner_size_336x280,
      dimensions: "336x280",
    },
    {
      title: "Wide Skyscraper",
      icon: banner_size_160x600,
      dimensions: "160x600",
    },
    {
      title: "Skyscraper",
      icon: banner_size_120x600,
      dimensions: "120x600",
    },
    {
      title: "Inline Rectangle",
      icon: banner_size_300x250,
      dimensions: "300x250",
    },
    {
      title: "Half-page Ad",
      icon: banner_size_300x600,
      dimensions: "300x600",
    },
    {
      title: "Leaderboard",
      icon: banner_size_728x90,
      dimensions: "728x90",
    },
    {
      title: "Large Leaderboard",
      icon: banner_size_970x90,
      dimensions: "970x90",
    },
  ];

  if (can("edit_createCustomBanner")) {
    bannerSizes.push({
      title: "Custom",
      icon: banner_size_custom,
      dimensions: "Custom",
      custom: true,
    });
  }

  await dispatch(setBannerSizes(bannerSizes));
};

export const copyToSiteImages = banner => async dispatch => {
  const id = await Notifications.info({
    title: "Saving",
    message: `Saving ${banner.get("title")} to site images`,
  });

  const query = `
    mutation($bannerId:ID!,$siteId:ID!,$title:String!){
      saveBannerToSiteImages(bannerId:$bannerId,siteId:$siteId,title:$title){
        success
        message
      }
    }
  `;

  const variables = {
    siteId: activeSite().get("id"),
    bannerId: banner.get("id"),
    title: banner.get("title"),
  };

  const res = await graph({ query, variables });

  const success = _.get(res, "saveBannerToSiteImages.success");

  await Notifications.clear(id);

  if (success) {
    await Notifications.success({
      title: "Saved!",
      message: `${banner.get("title")} has been saved to your site images`,
    });
  } else {
    await Notifications.success({
      title: "Houston we have a problem",
      message: `There was an error saving ${banner.get("title")} to your site images.`,
    });
  }
};

export const updateBannerTitle = () => async dispatch => {
  const banner = store
    .getState()
    .get("marketing")
    .Banners.get("activeBanner");
  const title = store
    .getState()
    .get("marketing")
    .Banners.getIn(["settings", "banner-temp-title"]);
  const settings = store
    .getState()
    .get("marketing")
    .Banners.get("settings");

  if (!title) {
    await dispatch(updateSetting("banner-temp-title", settings.get("banner-snapshot-title")));
    return;
  }

  const query = `mutation($id: ID!, $title: String) {updateBanner(id: $id, title: $title) {id}}`;

  const variables = {
    id: banner.get("id"),
    title,
  };

  const res = await graph({ query, variables }, true);

  if (_.get(res, "res.updateBanner.id") === banner.id) {
    await updateSetting("banner-snapshot-title", title);
    await dispatch(getAllBanners());
    await dispatch(setActiveBanner(banner.delete("isNew")));
  } else {
    await dispatch(updateSetting("banner-temp-title", settings.get("banner-snapshot-title")));
    await Alerts.alert("There was an error saving the title");
  }
};

export const copyBannerToProduction = () => async dispatch => {
  await dispatch(setSetting({ field: "processing-copy-banner-to-production", value: true }));

  const sourceSiteId = store
    .getState()
    .get("editor")
    .editorReducer.getIn(["currentSite", "siteId"]);
  const destinationSiteId = store
    .getState()
    .get("marketing")
    .Banners.getIn(["settings", "copy-banner-to-production-siteId"]);
  const banner = store
    .getState()
    .get("marketing")
    .Banners.getIn(["settings", "banner-to-copy"]);

  const query = `
	mutation ($sourceSiteId: ID!, $destinationSiteId: ID!, $bannerId: ID!) {
		copyBannerToProduction(sourceSiteId: $sourceSiteId, destinationSiteId: $destinationSiteId, bannerId: $bannerId)
	}`;

  const variables = {
    sourceSiteId: sourceSiteId,
    bannerId: banner.get("id"),
    destinationSiteId,
  };

  const { Error, message, ...result } = await graph({ query, variables });

  if (Error) {
    await dispatch(setSetting({ field: "show-copy-banner-modal", value: false }));
    await Alerts.alert(message);
    await dispatch(setSetting({ field: "show-copy-banner-modal", value: true }));
  } else {
    await dispatch(setSetting({ field: "show-copy-banner-modal", value: false }));
    await Alerts.alert("Success!");
    await dispatch(setSetting({ field: "copy-banner-to-production-siteId", value: "" }));
    await dispatch(setSetting({ field: "banner-to-copy", value: null }));
  }

  await dispatch(setSetting({ field: "processing-copy-banner-to-production", value: false }));
};

export const archiveBanner = ({ bannerId, archived }) => async dispatch => {
  await dispatch(showLoadingIcon(true));

  const query = `mutation($id:ID!,$archived:Boolean){updateBanner(id:$id,archived:$archived){id,archived}}`;
  const variables = {
    id: bannerId,
    archived,
  };

  await graph({ query, variables }, true);
  await dispatch(fetchMyBanners());

  await dispatch(showLoadingIcon(false));
};

export const createNewBanner = banner => async dispatch => {
  const siteId = store.getState().getIn(["auth", "activeSite", "siteId"]);

  let query = `
	mutation($archived:Boolean, $siteId: ID!, $title: String!, $dimensions: String!, $elements: String!, $image: String!){
		createBanner(archived: $archived, siteId: $siteId, title: $title, dimensions: $dimensions, elements: $elements, image:$image) {
			id, archived
		}
	}`;

  let variables = {
    siteId: siteId,
    title: "Untitled",
    dimensions: banner.dimensions,
    elements: "[]",
    image: "http://via.placeholder.com/200x160",
    archived: false,
  };

  const response = await graph({ query, variables }, true);
  const id = response.data.createBanner.id;
  await dispatch(fetchMyBanners());
  banner.id = id;
  banner.isNew = true;
  await dispatch(setActiveBannerId(id));
  await dispatch(setActiveBanner(banner));
  await dispatch(updateSetting("banner-temp-title", "Untitled"));
};

export const fetchBanner = banner => async dispatch => {
  const query = `
	query($id:ID!) {
		getBanner( id:$id ) {
			id, title, image, dimensions, adwordsImageAdId, version, generatingImage, locked, archived, deleted
		}
	}
	`;
  const variables = { id: banner.get("id") };

  const res = await graph({ query, variables }, true);
  const fetchedBanner = _.get(res, "data.getBanner") || {};
  const banners = store
    .getState()
    .get("marketing")
    .Banners.get("banners");
  const indexOf = banners.findIndex(b => b.get("id") === banner.get("id"));

  await dispatch(setBanners(banners.setIn([indexOf], fromJS(fetchedBanner))));
};

export const getAllBanners = () => async dispatch => {
  const banners = await fetchSiteBanners(getActiveSiteId());
  await dispatch(setBanners(banners));
  return banners;
};

export const fetchSiteBanners = async siteId => {
  const query = `
	query ($siteId: ID!) {
		getAllBanners(siteId: $siteId ) {
			id, title, image, dimensions, adwordsImageAdId, version, generatingImage, locked, archived, deleted
			}
	}`;

  const variables = {
    siteId,
  };

  const response = await adtech({ query, variables });
  return _.get(response, "data.getAllBanners") || [];
};

export const fetchMyBanners = withLoadingIcon(getAllBanners);

export const duplicateBanner = banner => async dispatch => {
  await dispatch(showLoadingIcon(true));
  const query = `mutation($id:ID!,$title:String){copyBanner(id:$id, title:$title){id}}`;
  const variables = { id: banner.id, title: `copy of ${banner.title}` };
  await graph({ query, variables }, true);
  await dispatch(fetchMyBanners());
  await dispatch(showLoadingIcon(false));
};

export const deleteBanner = id => async dispatch => {
  if (!(await Alerts.confirm("Delete this banner?"))) {
    return;
  }

  await dispatch(showLoadingIcon(true));
  await dispatch(deleteBannerApi(id));
  await dispatch(fetchMyBanners());
};

export const deleteBannerApi = id => async dispatch => {
  const query = `
	mutation($id: ID!){
			deleteBanner(id: $id) {
					success, error
			}
	}`;

  const variables = {
    id,
  };

  await graph({ query, variables }, true);
};

export const updateBannerImage = () => async () => {
  const siteId = store
    .getState()
    .get("editor")
    .editorReducer.getIn(["currentSite", "siteId"]);
  const bannerId =
    store
      .getState()
      .get("marketing")
      .Banners.get("activeBannerId") || "";

  const query = `
	mutation($siteId:ID!,$bannerId:ID!) {
		updateBannerImage(siteId:$siteId,bannerId:$bannerId)
	}
	`;

  const variables = {
    siteId,
    bannerId,
  };

  await graph({ query, variables });
};

export const saveActiveBanner = () => async dispatch => {
  const elements = store
    .getState()
    .get("editor")
    .editorReducer.get("pageElements");

  const id = store
    .getState()
    .get("marketing")
    .Banners.getIn(["activeBanner", "id"]);

  const title = store
    .getState()
    .get("marketing")
    .Banners.getIn(["settings", "banner-temp-title"]);

  const banner = store
    .getState()
    .get("marketing")
    .Banners.getIn(["activeBanner"]);

  let finalTitle = title;

  if (title === "Untitled") {
    finalTitle = await Alerts.prompt("Please rename banner before saving", {
      placeholder: "Enter banner title",
    });
  }

  if (!finalTitle) {
    return;
  }

  await dispatch(updateSetting("banner-snapshot-title", finalTitle));
  await dispatch(updateSetting("banner-temp-title", finalTitle));
  await dispatch(setSaving(true));

  const variables = {
    id,
    elements,
    title: finalTitle,
  };

  // needed add this back in
  // deleting these messed up the you have unsaved changes logic
  // comparing pageElements hash with and without these attributes isn't equal

  // will need to revist

  // variables.elements = variables.elements.map(el => {
  //   return el
  //     .delete("id")
  //     .delete("moduleId")
  //     .delete("moduleSort")
  //     .delete("parentId");
  // });

  variables.elements = JSON.stringify(variables.elements);

  const query = `
	mutation($id: ID!, $title: String, $elements: String) {
			updateBanner(id: $id, title: $title, elements: $elements) {
					id
			}
	}`;

  await graph({ query, variables }, true);

  await Promise.all([
    dispatch(updateSetting("banner-elements-hash", HashSorter.hash(elements))),
    dispatch(updateBannerImage()),
    dispatch(setSaving(false)),
    dispatch(setActiveBanner(banner.delete("isNew"))),
  ]);

  await dispatch(getAllBanners());
};

export const loadBanner = banner => async dispatch => {
  const templateSizes = store
    .getState()
    .get("marketing")
    .Banners.get("bannerSizes");

  const query = `query($id: ID!) {getBanner(id: $id) {elements,background}}`;
  const variables = { id: banner.id };

  const response = await graph({ query, variables }, true);
  let elements = _.get(response, "data.getBanner.elements");

  try {
    elements = JSON.parse(elements);
  } catch (e) {
    await Alerts.alert("There was an error loading this banner. Please try again");
    return;
  }

  const background = _.get(response, "data.getBanner.background");

  // hack until customSize is added to Banner graph query and mutation
  banner.customSize = true;

  templateSizes.forEach(template => {
    if (template.get("dimensions") === banner.dimensions) {
      banner.customSize = false;
    }
  });

  await Promise.all([
    dispatch(updateSetting("banner-temp-title", banner.title)),
    dispatch(updateSetting("banner-elements-hash", HashSorter.hash(fromJS(elements)))),
    dispatch(setElements(elements)),
    dispatch(setActiveBanner(banner)),
    dispatch(setActiveBannerId(banner.id)),
  ]);
};
