import { StyleSheet } from "@convertly/css";
import snackBarAlertStyleObject from "./snack-bar-alert/snack-bar-alert-styles";
import { clientImage } from "./utils/generateImage";
import _ from "lodash";
import { deeplog } from "./utils/deeplog";

const appStyles = (global, data, page, inEditor, isSiteTemplate) => {
  const snackBarAlertStyle = snackBarAlertStyleObject(global, data);

  let bannerPageStyles = {};
  if (page) {
    bannerPageStyles = getBannerPageStyles(page);
  }

  let bgImgData = _.get(page, "bodyBackgroundImage") || _.get(global, "bodyBackgroundImage") || {};

  if (bgImgData.backgroundImage && bgImgData.backgroundImage.indexOf("url") === -1) {
    bgImgData.backgroundImage = `url(${clientImage(bgImgData.backgroundImage)})`;
  }

  if (bgImgData["background-image"] && bgImgData["background-image"].indexOf("url") === -1) {
    bgImgData["background-image"] = `url(${clientImage(bgImgData["background-image"])})`;
  }

  const bodyStyles = {
    "* body": {
      position: "relative",
      scrollBehavior: "smooth",
      overflowY: "scroll",
      "-webkit-overflow-scrolling": "touch",
      backgroundColor: global.bodyBackgroundColor || "#fff",
      minHeight: "100vh",
      ...bgImgData,
    },
  };

  return StyleSheet.create({
    app: {
      overflow: "hidden",
      ...(!inEditor && bodyStyles),
      ...(inEditor ||
        (isSiteTemplate && { backgroundColor: global.bodyBackgroundColor || "#fff" })),
      ...(inEditor && bgImgData),
      //resets
      "* *": {
        ...(isSiteTemplate && { transition: "all .3s ease" }),
        ":focus": {
          outline: "none",
        },
      },
      ...bannerPageStyles,
      "* .inline-link": {
        ...global.inlineLink,
      },
    },

    snackBarInfernoComponent: {
      ...snackBarAlertStyle,
    },
  });
};

const getBannerPageStyles = ({ dimensions, type }) => {
  //Set page height and width
  if (type === "banner") {
    let parsedDimensions = dimensions.split("x");
    return {
      position: "absolute",
      width: `${parsedDimensions[0]}px`,
      height: `${parsedDimensions[1]}px`,
      // resetting the margins for banner elements
      // @TODO probably more elegant way to do this
      "* h1": {
        margin: "0",
      },
      "* h2": {
        margin: "0",
      },
      "* h3": {
        margin: "0",
      },
      "* h4": {
        margin: "0",
      },
      "* h5": {
        margin: "0",
      },
      "* h6": {
        margin: "0",
      },
      "* p": {
        margin: "0",
      },
    };
  }
  //return empty object
  else {
    return {};
  }
};

export default appStyles;
