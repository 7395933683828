export const ContestSubmissionsModalStyles = (global, data) => ({
    "< .contest-submission-modal-wrapper": {
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        zIndex: 9999,
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        justifyItems: 'center'
    },
    "< .contest-submission-modal-overlay": {
        position: 'absolute',
        height: '100vh',
        width: '100vw',
        background: "#47474daa",
        zIndex: 1
    },
    "< .contest-submission-modal": {
        width: '70vw',
        padding:'20px',
        background: "white",
        zIndex: 2,
        display:'flex',
        flexDirection:"row",
        position:"relative",
        "@media(max-width:991px )": {
            flexDirection:"column",
            width:"80vw"
        }
    },
    "< .contest-submission-modal-content": {
        padding:'20px 10px',
        "> h2": {
            "font-size": "30px",
            fontWeight:"bold",
            paddingBottom:"20px",
            marginBottom:"20px",
            borderBottom:`2px solid ${global.colors.light.color3}`
        },
        "> p": {
            fontSize:'18px',
            lineHeight:"1.4"
        }
    },
    "< .contest-submission-modal-image": {
        maxHeight:"500px",
        maxWidth:"500px",
        objectFit:"cover",
        "@media(max-width:550px)": {
            maxWidth:"100%"
        }
    }
})
