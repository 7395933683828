import { connect } from "react-redux";
import SiteSettingsModal from "../../App/modals/site-settings-modal";
import { closeModal } from "../actions/editorActions";

const mapStateToProps = state => {
  return {
    site: state.get("editor").editorReducer.get("currentSite")
      ? state
          .get("editor")
          .editorReducer.get("currentSite")
          .toJS()
      : [],
    pageSettings: state.get("editor").editorReducer.get("pageSettings"),
    page: state.get("editor").editorReducer.get("page"),
    sites: state.get("auth").get("sites")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: closeModal
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(SiteSettingsModal);

export default Container;
