import { StyleSheet } from '@convertly/css';
import UserOverrides from '../style-functions/user-overrides';


const wrapperDiv = ( global, data, inEditor ) => {

    const userOverRides = new UserOverrides( global, data, inEditor );

    return StyleSheet.create({
        wrapperDiv: {
            ...userOverRides
        },
        clearFix: {
            '::after': {
                display: "block",
                content: "''",
                clear: 'both'
            }
        }
    });
};

export default wrapperDiv;
