import {StyleSheet} from "@convertly/css";
import UserOverrides from '../../../elements/style-functions/user-overrides';
import _ from 'lodash';

import {generatePseudoMediaQuery} from '../../../elements/style-functions/pseudo-media-queries';
import {addValueToUserOverrides, createNewCssRules} from '../../../utils/edit-userOverrides';

import searchBarStyleObject from '../../../ecommerce-common-components/search-bar/searchBarStyleObject';
import {generateSafariStyleSheet, generateMSStyleSheet} from "../../../utils/browserSpecificStyles";

const HeaderStyles3 = (global, data, inEditor, page, site) => {
  const searchBarIsShown = !_.get(page, 'hideSearchBar') && _.get(site, 'ecommerce.enabled');
  const searchBarStyles = searchBarStyleObject(global, data, inEditor);

  let clientNavLinkStyles = {};

  let navLinksOverrides = {},
    navLinkAdditionalStyles = {},
    dropDownAdditionalStyles = {},
    dropdownOverrides = {},
    iconBackground = {};


  let editedNavLinkOverrides = {
    userOverrides: addValueToUserOverrides(_.get(data, "menu.userOverrides"),
      "color",
      ["fill"])
  };

  if (editedNavLinkOverrides) {
    clientNavLinkStyles = UserOverrides(global, editedNavLinkOverrides, inEditor);
  }

  const processedOverrides = UserOverrides(global, data, inEditor)

  // Pulls background color from userOverrides
  let mobileBackgroundColor = {};
  if (data.userOverrides) {
    mobileBackgroundColor = createNewCssRules(processedOverrides, 'background', ['background']);
    mobileBackgroundColor = createNewCssRules(processedOverrides, 'backgroundColor', ['background']);
  }
  let logoOverrides = _.get(data, 'logoUserOverrides.userOverrides') ?
    UserOverrides(global, data.logoUserOverrides) :
    {};

  if (_.get(data, "menu.userOverrides")) {
    /** map through userOverrides make fill match color **/
    let editedNavLinkOverrides = {
      userOverrides: addValueToUserOverrides(_.get(data, "menu.userOverrides"),
        "color",
        ["fill"])
    };
    navLinksOverrides = UserOverrides(global, editedNavLinkOverrides, inEditor);
    navLinkAdditionalStyles = createNewCssRules(navLinksOverrides, 'color', ['color', 'borderColor', 'fill']);
    iconBackground = createNewCssRules(navLinksOverrides, 'color', ['backgroundColor']);
  }

  if (_.get(data, "dropdown.userOverrides")) {
    /** map through userOverrides make fill match color **/
    let editedDropdownOverrides = {
      userOverrides: addValueToUserOverrides(_.get(data, "dropdown.userOverrides"),
        "color",
        ["fill"])
    };
    dropdownOverrides = UserOverrides(global, editedDropdownOverrides, inEditor);
    dropDownAdditionalStyles = createNewCssRules(dropdownOverrides, 'color', ['color', 'borderColor', 'fill'])
  }

  /** Browser Specific Styles */
  let safariSearchBarInputStyles = {
    lineHeight: '22px',
  };

  /** Custom styles for pop out menu in editor **/
  let menuEditorStyles = {};
  let closeIconEditorStyles = {};

  if (inEditor) {
    menuEditorStyles = {
      zIndex: '2000',
      "~#editorPageWrapper[data-pagewidth='smallTablet']": {
        top: '92px',
        '.open': {
          left: '20px'
        }
      },
      "~#editorPageWrapper[data-pagewidth='largeTablet']": {
        top: '61px',
        '.open': {
          left: '50px'
        }
      },
      "~#editorPageWrapper[data-pagewidth='mobile']": {
        top: '91px',
        '.open': {
          left: '20px'
        }
      }
    };
    closeIconEditorStyles = {
      "~#editorPageWrapper[data-pagewidth='smallTablet']": {
        '.open': {
          top: '119px',
          left: '19px'
        }
      },
      "~#editorPageWrapper[data-pagewidth='largeTablet']": {
        '.open': {
          top: '88px'
        }
      },
      "~#editorPageWrapper[data-pagewidth='mobile']": {
        '.open': {
          top: '114px',
          left: '24px'
        }
      },
      zIndex: '23001'
    }
  }

  const navListItem = {
    position: 'relative',
    width: '100%',
    cursor: 'pointer',
    willChange: 'transform',
    ...generatePseudoMediaQuery(
      '@media (max-width: 1023px)',
      ['largeTablet', 'smallTablet', 'mobile'],
      {padding: '15px'},
      inEditor
    ),
    ".active": {
      cursor: 'default'

    },
    ...clientNavLinkStyles,
    ...navLinkAdditionalStyles
  };


  const navListItemLink = {
    lineHeight: '24px',

    borderBottom: "2px solid transparent",
    transition: 'all 400ms ease',
    ".active": {
      borderColor: global.colors.dark.color1,
      cursor: "default"
    },
    ".cartDropDown": {
      ...generatePseudoMediaQuery(
        "@media (max-width:767px)",
        ['smallTablet', 'small'],
        {display: 'none'},
        inEditor
      )
    },
  };

  let popOutMenuBackgroundColor = global.colors.accent.color1;


  return StyleSheet.create({
    searchBarWrapper: {
      height: '100%',
      ...searchBarStyles,
      display: 'inline-block',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
          display: 'none',
        },
        inEditor
      ),
      '.search-bar-wrapper': {
        '.static': {
          display: 'flex',
          borderLeft: global.border,
          borderColor: data.moduleTextColor || "",
          padding: '4px',
          width: '300px',
          'align-items': 'center',
          height: '107%',
          transition: 'all 0.3s ease',
          marginRight: '-2px',
          marginTop: '-2px',
          ':focus-within': {
            // border: `2px solid ${ global.colors.accent.color2 }`,
            // fill: global.colors.accent.color2,
          },
        }
      },
      '.x-icon-wrapper': {
        display: 'flex',
        order: '2',
        height: 'auto',
        paddingBottom: '4px',
      },
      '.product-item': {
        display: 'block',
        padding: '8px 4px',
        border: `2px solid ${global.colors.light.color3}`,
        borderTop: 'none',
        listStyle: 'none',
        cursor: 'pointer',
        backgroundColor: global.colors.light.white,
        height: 'auto',
        color: 'inherit',
        'text-overflow': 'ellipsis',
        'overflow': 'hidden',
        'white-space': 'nowrap',
        ':hover': {
          backgroundColor: global.colors.light.color2
        },
        '.no-hover': {
          ':hover': {
            backgroundColor: 'white',
            cursor: 'default'
          },
        }
      },
      '.search-bar-results': {
        position: 'absolute',
        width: '100.7%',
      },
      '.x-icon': {
        fontSize: '26px',
        cursor: 'pointer',
      },
      '.search-bar-container': {
        width: '100%',
        ...generatePseudoMediaQuery(
          '@media(max-width: 991px)', ['largeTablet', 'smallTablet', 'mobile'], {
            display: 'block'
          },
          inEditor
        ),
        ':focus-within': {
          fill: global.colors.accent.color2,
        },
      },
      '.search-bar-input': {
        border: 'none',
        padding: '0px 2px',
        height: '100%',
        width: '100%',
        backgroundColor: 'transparent',
        color: 'inherit',
        lineHeight: '22px',
        fontSize: '16px',
      },
      '.mobile': {
        display: 'none',
        marginLeft: '4px',
        '.search-bar-wrapper': {
          '.static': {
            display: 'flex',
            border: `2px solid ${global.colors.light.color3}`,
            padding: '4px',
            width: '80%',
            'align-items': 'center',
            height: '107%',
            transition: 'all 0.3s ease',
            marginRight: '-2px',
            marginTop: '12px',
            ':focus-within': {
              border: `2px solid inherit`,
              fill: 'inherit',
              color: 'inherit'
            },
          }
        },
        '.search-bar-container': {
          width: '100%',
          ...generatePseudoMediaQuery(
            '@media(max-width: 991px)', ['largeTablet', 'smallTablet', 'mobile'], {
              display: 'block'
            },
            inEditor
          ),
        },
        '.search-bar-input': {
          border: 'none',
          padding: '0px 2px',
          height: '100%',
          width: '100%',
          backgroundColor: 'transparent',
          color: 'inherit',
          lineHeight: '22px',
          fontSize: '16px',
        },
        '.search-bar-product-items-list': {
          transition: 'all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s',
          position: 'absolute',
          width: '100%',
          color: 'inherit',
        },
        '.search-bar-results-wrapper': {
          position: 'relative',
          left: '-16px',
          width: '219.8px',
          'z-index': '22',
          display: 'none',
          '.active': {
            display: 'block'
          }
        },
        '.search-bar-results': {
          position: 'absolute',
          width: '100.7%',
        },
        '.x-icon': {
          fontSize: '26px',
          cursor: 'pointer',
        },
        ...generatePseudoMediaQuery(
          '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
            display: 'block',
          },
          inEditor
        ),
        '.product-item': {
          display: 'block',
          padding: '8px 4px',
          border: `2px solid ${global.colors.light.color3}`,
          borderTop: 'none',
          listStyle: 'none',
          cursor: 'pointer',
          backgroundColor: popOutMenuBackgroundColor,
          height: 'auto',
          color: 'inherit',
          'text-overflow': 'ellipsis',
          'overflow': 'hidden',
          'white-space': 'nowrap',
          ':hover': {
            backgroundColor: global.colors.light.color2
          },
          '.no-hover': {
            ':hover': {
              backgroundColor: 'white',
              cursor: 'default'
            },
          }
        },
      }
    },
    menu: {
      borderRight: global.border,
      borderRightColor: data.moduleTextColor || "",
      float: 'left',
      marginRight: '13px',
      padding: '0 14px',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      zIndex: '50',
      fontSize: '18px',

      ...generatePseudoMediaQuery(
        "@media (max-width:767px)",
        ['smallTablet'],
        {
          padding: '5px 10px 5px 0px',
          marginLeft: '-5px',
          marginRight: '0'
        },
        inEditor
      ),
      ...generatePseudoMediaQuery(
        "@media ( max-width:480px )",
        ['mobile'],
        {
          padding: '11px 5px 5px 0px',
          marginLeft: '-5px',
          marginRight: 0,
        },
        inEditor
      ),
      '.open': {
        position: 'fixed',
        top: inEditor ? '55px' : '15px',
        left: inEditor ? '49px' : '9px',
        height: 'auto',
        border: 'none',
        alignItems: 'unset'
      },
      ...closeIconEditorStyles,
      ...processedOverrides

    },
    navLaunch: {
      zIndex: inEditor ? 23001 : 45,
      display: "block",
      height: "41px",
      paddingBottom: 0,
      overflow: "visible",
      background: "none",
      border: "none",
      outline: "none",
      ...generatePseudoMediaQuery(
        "@media ( max-width:480px )",
        ['mobile'],
        {
          marginLeft: '0'
        },
        inEditor
      ),

    },
    iconBar: {
      backgroundColor: data.moduleTextColor || global.colors.dark.color1,
      width: "35px",
      height: "2px",
      marginBottom: "5px",
      position: "relative",
      display: "block",
      transition: 'all 0.3s ease-in-out 0s',
      ...iconBackground,
      '.open': {
        ":nth-child(2)": {
          transform: "rotate(45deg)",
          top: "2px",
        },
        ":nth-child(4)": {
          transform: "rotate(-45deg)",
          top: "-13px",
        },
        ":nth-child(3)": {
          opacity: 0
        }
      }
    },
    logo: {
      float: 'left',
      maxWidth: '280px',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      ...generatePseudoMediaQuery(
        "@media (max-width:767px)",
        ['smallTablet', 'mobile'],
        {
          position: "absolute",
          paddingTop: '7',
          float: 'left',
          top: "0",
          left: "50%",
          transform: "translateX(-25%)"
        },
        inEditor
      ),
      ...logoOverrides
    },
    logoImg: {
      height: '35px',
    },
    /** Menu that pops out after click **/
    overlay: {
      display: 'none',
      position: 'fixed',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 40,
      background: 'rgba(0,0,0,.3)',
      ".open": {
        display: 'block'
      }
    },
    hidePadding: {
      padding: '0',
      ...generatePseudoMediaQuery(
        "@media (max-width:768px)",
        ['smallTablet', 'mobile'],
        {
          paddingRight: '15px',
          paddingLeft: '15px',
        },
        inEditor
      ),
    },
    fitHeight: {
      height: '100%'
    },
    popOutMenu: {
      fontFamily: global.fontFamily.primaryFont,
      color: global.colors.dark.color1,
      background: "#fff",
      position: 'fixed',
      overflowX: 'hidden',
      left: '-275px',
      display: 'block',
      transition: 'all 0.3s ease',
      width: '275px',
      height: '100%',
      top: inEditor ? '40px' : 0,
      zIndex: 45,
      paddingTop: '54px',
      ...mobileBackgroundColor,
      ...generatePseudoMediaQuery(
        "@media (max-width:1023px)",
        ['largeTablet', 'smallTablet'],
        {
          paddingTop: '57px',
        },
        inEditor
      ),
      ...generatePseudoMediaQuery(
        "@media (max-width:480px)",
        ['mobile'],
        {
          paddingTop: '57px',
        },
        inEditor
      ),

      '.open': {
        visibility: 'visible',
        left: inEditor ? '40px' : '0',
      },
      ...menuEditorStyles,
    },
    featuredImage: {
      width: '100%',
    },
    navList: {
      listStyle: 'none',
      padding: 0,
      height: 'auto',
      marginTop: '15px'
    },
    navListItem: {
      ...navListItem,
      padding: '15px 20px',
      ...generatePseudoMediaQuery(
        "@media (max-width:767px)",
        ['smallTablet', 'mobile'],
        {
          ".cart": {},
          ".sign-in": {
            display: 'none'
          }
        },
        inEditor
      )
    },
    dropdownContainer: {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between'
    },
    dropDownNavLinkText: {
      border: '2px solid transparent',
      ":hover": {
        "@media (hover:hover)": {
          borderColor: global.colors.dark.color1
        }
      }
    },
    navListItemLink: {
      ...navListItemLink,
      ":hover": {
        "@media (hover:hover)": {
          borderColor: global.colors.dark.color1
        }
      },

    },
    caret: {
      height: '15px',
      width: '20px',
      marginTop: '3px',
      float: 'right',
      cursor: 'pointer',
      transform: 'rotate(-90deg)',
      transition: 'all .1s linear',
      fill: global.colors.dark.color1,
      '.open': {
        transform: 'rotate(0deg)',
      },
      ...navLinkAdditionalStyles
    },
    dropDownNavList: {
      display: 'none',
      listStyle: 'none',
      padding: 0,
      '.open': {
        display: 'block'
      }
    },
    dropDownLaunch: {
      ...navListItemLink,
      cursor: 'pointer'
    },
    dropDownNavItem: {
      ...navListItem,
      lineHeight: '20px',
      padding: '4px 0',
      ...navLinksOverrides,
      ...dropdownOverrides,
      ...navLinkAdditionalStyles,
      ...dropDownAdditionalStyles,
    },

    dropDownNavItemLink: {
      padding: '10px 0 10px 0px',
      borderBottom: '2px solid transparent',
      ":hover": {
        borderBottomColor: 'white'
      }
    },
    dropDownNavItemImg: {
      height: '50px',
      width: '50px',
      display: 'inline-block',
      verticalAlign: 'middle',
      marginRight: '35px',
      paddingLeft: 0,
      ...generatePseudoMediaQuery(
        "@media (max-width:1023px)",
        ['largeTablet', 'smallTablet', 'mobile'],
        {
          marginRight: '10px'
        },
        inEditor
      ),

    },
    /** right side ecommerce buttons **/
    navBarSearch: {
      display: 'none',
      ...generatePseudoMediaQuery(
        "@media (max-width:480px)",
        ['mobile'],
        {
          display: 'block',
          padding: 0,
          color: global.colors.dark.color1,
        },
        inEditor
      )
    },
    searchIcon: {
      ...navListItemLink,
    },
    navSearchBar: {
      background: global.colors.light.white + ' none repeat scroll 0 0',
      width: '89%',
      padding: '0 32px 0 15px',
      height: '40px',
      lineHeight: '40px',
      borderRadius: '0',
      fontSize: '16px',
      transition: 'all 0.25s ease 0s',
      marginTop: '15px',
      color: global.colors.dark.color1,
    },
    navSearchButton: {
      display: 'none'
    },
    rightWrapper: {
      display: '-webkit-inline-box',
      float: 'right',
      ...generatePseudoMediaQuery(
        "@media (max-width:767px)",
        ['mobile', 'smallTablet'],
        {
          marginRight: "-15px"
        },
        inEditor
      )
    },
    socialIcons: {
      float: 'left',
      marginRight: '10px',
      paddingTop: '18px',
      ...generatePseudoMediaQuery(
        "@media (max-width:1151px)",
        ['mobile', 'smallTablet', 'largeTablet'],
        {
          display: 'none'
        },
        inEditor
      )
    },
    socialIcon: {
      height: '1.5em',
      width: '2.5em',
      cursor: 'pointer',
      fill: '#fff',
      ':hover': {
        opacity: '.7'
      },
      ...navLinkAdditionalStyles,
    },
    headerSearchForm: {
      position: 'relative',
    },
    headerSearchBar: {
      background: global.colors.light.white,
      border: 'medium none',
      borderRadius: 0,
      height: '6x5px',
      lineHeight: '58px',
      padding: '0 80px 0 30px',
      position: 'absolute',
      cursor: 'pointer',
      right: '0',
      top: 0,
      opacity: 0,
      transition: 'all 0.25s ease 0s',
      width: '100%',
      zIndex: 45,
      color: global.colors.light.color3,
      fontSize: '20px',
      borderLeft: '3px solid',
      borderLeftColor: global.colors.light.color3,
      ...generatePseudoMediaQuery(
        "@media (max-width:1023px)",
        ['largeTablet'],
        {
          lineHeight: '54px'
        },
        inEditor
      ),
      ...generatePseudoMediaQuery(
        "@media (max-width:767px)",
        ['smallTablet', 'mobile'],
        {
          height: '40px',
          lineHeight: '40px',
          padding: '0 30px 0 15px',
          fontSize: '16px',
        },
        inEditor
      ),
      ':focus': {
        outlineColor: global.colors.accent.color1
      },
      '.open': {
        opacity: 1,
        width: '595px',
        cursor: 'default',
        ...generatePseudoMediaQuery(
          "@media (max-width:1023px)",
          ['largeTablet'],
          {
            width: '360px'
          },
          inEditor
        ),
        ...generatePseudoMediaQuery(
          "@media (max-width:767px)",
          ['smallTablet', 'mobile'],
          {
            width: '277px',
          },
          inEditor
        )
      },
    },
    closeSearch: {
      position: 'absolute',
      right: '27px',
      top: '20px',
      display: 'none',
      opacity: 0,
      zIndex: 45,
      cursor: 'pointer',
      '.open': {
        display: 'block',
        opacity: 1,
      }
    },

    headerSearchBarButton: {
      background: 'rgba(0, 0, 0, 0) url(https://d393hjtdh3b27x.cloudfront.net/magic-dragons/img/search_icon.png) no-repeat scroll center center',
      border: 'medium none',
      boxShadow: 'none',
      cursor: 'pointer',
      display: 'none',
      fontSize: 0,
      height: '12px',
      opacity: 0,
      padding: '0 15px',
      width: '11px'
    },
    cart: {
      float: 'left',
      ...generatePseudoMediaQuery(
        "@media (max-width:480px)",
        ['mobile'],
        {
          float: 'none'
        },
        inEditor
      )
    },
    /** Desktop Cart component **/
    desktopCartInfernoComponent: {

      ".casper": {
        position: 'absolute',
        top: '0',
        right: '0',
        height: '60px',
        width: '425px',
        background: 'transparent',
        display: 'none',
        cursor: 'default'
      },

      ".headerNavLink": {
        cursor: 'pointer',
        borderLeft: global.border,
        borderColor: data.moduleTextColor || "",
        display: 'flex',
        alignItems: 'center',
        padding: '0px 17px 0px',
        height: '100%',
        fontSize: '13px',
        lineHeight: '17px',
        textTransform: 'uppercase',
        transition: 'all 0.3s linear 0s',
        position: 'relative',
        '.sign-in': {
          borderLeft: searchBarIsShown ? 'unset' : ''
        },
        ...generatePseudoMediaQuery(
          "@media (max-width:767px)",
          ['smallTablet'],
          {
            '.sign-in': {
              borderLeft: global.border,
            },
            paddingTop: '0'
          },
          inEditor
        ),
        ...generatePseudoMediaQuery(
          "@media (max-width:480px)",
          ['mobile'],
          {
            padding: "0px 14px 0px"
          },
          inEditor
        ),
        ":hover ": {
          "@media(hover:hover)": {
            ".cartDropDown": {
              height: '460px',
              visibility: 'visible',
            },
            ".casper": {
              display: 'block'
            }
          },
        },
      },
      ".cartLink": {
        color: global.colors.accent.color1
      },
      ".cartDropDown": {
        display: 'block',
        cursor: 'default',
        position: 'absolute',
        top: '100%',
        right: '-1px',
        width: '425px',
        height: "0px",
        boxSizing: 'border-box',
        overflowY: 'hidden',
        transition: 'height .3s ease',
        background: 'white',
        padding: '0px',
        zIndex: '50',
        paddingRight: "0",
        border: global.border,
        visibility: 'hidden',
      },
      ".cartContainer": {
        height: '350px',
        overflowY: 'auto',
        overflowX: 'hidden',
        width: '100%',
        borderBottom: global.border,

      },
      ".cartContainerFooter": {
        marginTop: '20px',
        textAlign: 'center'
      },
      ".checkoutLink": {
        display: 'block',
        ...global.button1
      },
      ".viewCartLink": {
        display: 'block',
        marginTop: "10px",
        color: global.colors.dark.color1
      },
      ".checkRightPanel": {
        padding: '30px',
        alignItems: "stretch",
        backgroundColor: "transparent",
        color: global.colors.dark.color1,
        display: "flex",
        flex: "1",
        flexDirection: "row",
        fontSize: "14px",
        justifyContent: "center",
        lineHeight: "20px",
        paddingTop: "30px"
      },

      ".chkRightPanelContainer": {
        color: global.colors.dark.color1,
        fontSize: "14px",
        lineHeight: "20px",
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "100%",
        width: "100%"
      },

      ".chkTitle1_mobileTitle1": {
        borderBottom: "2px none #667785",
        color: global.colors.dark.color1,
        display: "none",
        flex: "0 auto",
        flexDirection: "row",
        fontSize: "32px",
        fontWeight: "400",
        lineHeight: "36px",
        marginBottom: "10px",
        marginTop: "0px",
        paddingBottom: "20px",
        width: "100%"
      },

      ".mobileUnderline_titleUnderline": {
        backgroundColor: global.colors.dark.color1,
        borderRadius: "5px",
        color: global.colors.dark.color1,
        display: "none",
        fontSize: "14px",
        height: "3px",
        lineHeight: "20px",
        marginBottom: "20px",
        width: "45%"
      },

      ".chkGridheadercontainer": {
        color: global.colors.dark.color1,
        display: "flex",
        fontSize: "14px",
        justifyContent: "flex-end",
        lineHeight: "20px",
        marginBottom: "15px"
      },

      ".chkProdgridimgcontainer": {
        alignItems: "flex-start",
        color: global.colors.dark.color1,
        display: "flex",
        flexDirection: "column",
        fontSize: "14px",
        justifyContent: "center",
        lineHeight: "20px",
        maxWidth: "18%",
        textAlign: "center",
        width: "20%"
      },

      ".chkGridprice_th": {
        alignItems: "flex-start",
        color: global.colors.dark.color1,
        display: "flex",
        flex: "0 auto",
        fontFamily: global.fontFamily.secondaryFont,
        fontSize: "11px",
        justifyContent: "flex-start",
        lineHeight: "20px",
        paddingLeft: "0px",
        width: "125px"
      },

      ".chkGridqty_chkUnitprice_th": {
        alignItems: "flex-start",
        color: global.colors.dark.color1,
        display: "flex",
        flex: "1",
        fontFamily: global.fontFamily.secondaryFont,
        fontSize: "11px",
        justifyContent: "center",
        lineHeight: "20px",
        textAlign: "center"
      },

      ".chkGridsubtotal_th": {
        color: global.colors.dark.color1,
        display: "flex",
        flex: "1",
        fontFamily: global.fontFamily.secondaryFont,
        fontSize: "11px",
        justifyContent: "flex-end",
        lineHeight: "20px",
        textAlign: "center"
      },

      ".chkProdgrid": {
        color: global.colors.dark.color1,
        display: "flex",
        fontSize: "14px",
        lineHeight: "20px",
        marginBottom: "5px",
        position: "relative",
      },

      ".chkProdgridimg": {
        border: "0",
        color: global.colors.dark.color1,
        display: "inline-block",
        fontSize: "14px",
        height: "60px",
        width: "60px",
        lineHeight: "20px",
        maxWidth: "100%",
        textAlign: "center",
        verticalAlign: "middle",
        marginBottom: '10px'

      },

      ".chkRemovelink": {
        backgroundColor: "transparent",
        color: "#b4bbc3",
        fontSize: "10px",
        lineHeight: "20px",
        maxWidth: "100%",
        textAlign: "center",
        paddingLeft: '7px'
      },

      ".divBlock_2": {
        color: global.colors.dark.color1,
        display: "flex",
        flexDirection: "column",
        fontSize: "14px",
        lineHeight: "20px",
        width: "225px"
      },

      ".chkGridprice": {
        alignItems: "flex-start",
        color: global.colors.dark.color1,
        display: "flex",
        flex: "0 auto",
        fontSize: "12px",
        justifyContent: "flex-start",
        lineHeight: "20px",
        paddingLeft: "0px",
        width: "225px"
      },
      ".subTitle": {
        fontSize: '10px',
        color: global.colors.dark.color2
      },
      ".chkGridprice_chkGridpricenote": {
        alignItems: "flex-start",
        color: "#118bef",
        display: "flex",
        flex: "0 auto",
        fontSize: "12px",
        justifyContent: "flex-start",
        lineHeight: "16px",
        paddingLeft: "0px",
        width: "auto"
      },

      ".chkGridqty_chkUnitprice_strikethrough": {
        alignItems: "flex-start",
        color: "#667785",
        display: "flex",
        flex: "0 auto",
        fontSize: "14px",
        justifyContent: "flex-start",
        lineHeight: "20px",
        textAlign: "center",
        textDecoration: "line-through"
      },

      ".actualprice_chkGridqty_chkUnitprice": {
        alignItems: "flex-start",
        color: "#118bef",
        display: "flex",
        flex: "1",
        fontSize: "14px",
        justifyContent: "flex-start",
        lineHeight: "20px",
        textAlign: "center"
      },

      ".chkGridqty": {
        alignItems: "flex-start",
        color: "#667785",
        display: "flex",
        flex: "1",
        fontSize: "14px",
        justifyContent: "flex-end",
        lineHeight: "20px",
        textAlign: "center"
      },

      ".counterIndicator": {
        backgroundColor: "transparent",
        color: "#667785",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "4px 9px",
        textAlign: "center",
        textDecoration: "none"
      },

      ".counternumber": {
        border: global.border,
        color: "#667785",
        flex: "0 auto",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "4px 9px 3px",
        textAlign: "center"
      },

      ".chkGridsubtotal": {
        color: global.colors.dark.color2,
        display: "flex",
        flex: "1",
        fontSize: "14px",
        lineHeight: "20px"
      },

      ".progresscontainer": {
        color: global.colors.dark.color1,
        fontSize: "14px",
        lineHeight: "20px",
        paddingTop: "10px",
        position: "relative"
      },

      ".truck": {
        border: "0",
        color: global.colors.dark.color1,
        display: "inline-block",
        fontSize: "14px",
        lineHeight: "20px",
        maxWidth: "55px",
        position: "absolute",
        right: "0px",
        top: "0px",
        verticalAlign: "middle"
      },

      ".progresstext": {
        borderBottomColor: "#000",
        borderBottomWidth: "3px",
        borderStyle: "none",
        color: global.colors.dark.color1,
        fontSize: "14px",
        lineHeight: "20px",
        paddingBottom: "10px",
        paddingRight: "80px"
      },

      ".progressline_titleUnderline": {
        backgroundColor: global.colors.dark.color1,
        borderRadius: "5px",
        color: global.colors.dark.color1,
        fontSize: "14px",
        height: "3px",
        lineHeight: "20px",
        marginBottom: "15px",
        width: "100%"
      },

      ".cartToggleContainer": {
        color: global.colors.dark.color1,
        display: "none",
        fontSize: "14px",
        lineHeight: "20px"
      },
      ".cartTitleSub": {
        color: global.colors.dark.color1,
        fontSize: "32px",
        fontWeight: "bold",
        lineHeight: "36px",
        marginBottom: "10px",
        marginTop: "20px"
      },

      ".toggleLinkblock_wInlineBlock": {
        backgroundColor: "transparent",
        color: global.colors.dark.color1,
        display: "inline-block",
        fontSize: "14px",
        lineHeight: "20px",
        maxWidth: "100%"
      },
      ".toggleCollapsed": {
        border: "0",
        color: global.colors.dark.color1,
        display: "inline-block",
        fontSize: "14px",
        lineHeight: "20px",
        maxWidth: "100%",
        verticalAlign: "middle"
      },
      /** Change in cart item **/
      ".cart-item-container": {
        padding: '15px'
      },
      ".change-message-bg": {
        backgroundColor: '#e3f2fd'
      },
      ".change-message-container": {
        borderTop: `1px solid ${global.colors.light.color2}`,
        paddingTop: '10px',
        fontFamily: global.fontFamily.secondaryFont
      },
      ".change-message-text": {
        textTransform: 'none'
      },
      ".change-message-action": {
        display: 'block'
      },
      ".change-message-link": {
        fontSize: '16px',
        display: 'inline-block',
        margin: "5px 10px 0 0",
        cursor: 'pointer'
      }

    },

    /** Desktop account/sign in component **/
    desktopSignInInfernoComponent: {
      height: '100%',
      ".headerNavLink": {
        cursor: 'pointer',
        borderLeft: global.border,
        borderColor: data.moduleTextColor || "",
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: '13px',
        lineHeight: '17px',
        textTransform: 'uppercase',
        transition: 'all 0.3s linear 0s',
        position: 'relative',
      },
      '.headerNavLinkLast': {
        padding: '0px 17px 0px 17px',
      },
      "@media( max-width:767px)": {
        display: 'none'
      }
    },

    headerNavBox: {
      cursor: 'pointer',
      height: '100%',
      display: 'inherit',
      ...generateMSStyleSheet({
        display: 'inline-block'
      })
    },
    headerNavLink: {
      cursor: 'pointer',
      borderLeft: global.border,
      borderColor: data.moduleTextColor || global.colors.light.color3,
      fontSize: '13px',
      lineHeight: '17px',
      textTransform: 'uppercase',
      transition: 'all 0.3s linear 0s',
      padding: '0px 17px',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      ...generatePseudoMediaQuery(
        "@media (max-width:767px)",
        ['smallTablet', 'mobile'],
        {
          display: 'none'
        },
        inEditor
      ),
      ".cart": {
        ...generatePseudoMediaQuery(
          "@media (max-width:767px)",
          ['smallTablet'],
          {
            paddingTop: '0px',
            display: 'flex'
          },
          inEditor
        ),
        ...generatePseudoMediaQuery(
          "@media (max-width:480px)",
          ['mobile'],
          {
            padding: "0px 7px 0px",
            display: 'flex'
          },
          inEditor
        ),
      }
    },
    headerNavLinkLast: {
      paddingRight: 0
    },
    headerBottomLinks: {
      ...generatePseudoMediaQuery(
        "@media (min-width: 768px)",
        ['largeTablet', 'desktop'],
        {
          display: 'none'
        },
        inEditor
      )
    },
  });

};

export default HeaderStyles3
