import { createAction } from "redux-act";
import _ from "lodash";
import { store } from "app/app";
import { graph } from "app/utils";

import { showLoadingIcon } from "containers/App/actions";

export const setLoadingState = createAction("USER_DETAIL_SET_LOADED_STATE");
export const setSavingState = createAction("USER_DETAIL_SET_SAVING_STATE");
export const resetState = createAction("RESET_USER_DETAIL_STATE");
export const setUser = createAction("SET_USER_DETAIL");
export const setUserSnapshot = createAction("SET_USER_SNAPSHOT_DETAIL");

export function saveUser() {
  return async dispatch => {
    await dispatch(setSavingState(true));

    const user = store
      .getState()
      .get("userdetail")
      .get("user");

    const query = `
		mutation( $id: ID!, $account: accountInput ) {
		  editAccount( id:$id, account:$account ) {
		  	id
		  }
		}`;

    const permissions = {};

    Object.keys(user.toJS()).forEach(key => {
      if (key.indexOf("permission_") === 0) {
        const permission = key.replace("permission_", "");
        permissions[permission] = user.get(key);
      }
    });

    const variables = {
      id: user.get("id"),
      account: {
        firstName: user.get("firstName"),
        lastName: user.get("lastName"),
        permissions: JSON.stringify(permissions)
      }
    };

    await graph({ query, variables });

    await dispatch(setSavingState(false));
  };
}

export const loadUser = ({ id, type }) => async dispatch => {
  await dispatch(showLoadingIcon(true));

  const query = `
		query( $id: ID! ) {
		  Account(id:$id) {
				id, name, firstName, lastName, email, permissions
				sites { id, siteName, screenshot }, roles
		  }
		}`;

  const variables = {
    id
  };

  const response = await graph({ query, variables });

  const account = _.get(response, "Account") || {};

  const permissions = JSON.parse(account.permissions);

  Object.keys(permissions).forEach(key => {
    const value = permissions[key];
    const fullKey = `permission_${key}`;
    account[fullKey] = value;
  });

  await dispatch(setUser(account));
  await dispatch(setUserSnapshot(account));
  await dispatch(setLoadingState(false));
  await dispatch(showLoadingIcon(false));
};
