import {StyleSheet} from '@convertly/css';

const Styles = (global, data) => {

    return StyleSheet.create({

        stickyFooter: {
            position: 'fixed',
            bottom: '0',
            left: '0',
            height: '80px',
            width: "100%",
            background: global.colors.light.white,
            boxShadow: `0 2px 4px -1px rgba(0,0,0,0.3)`,
            padding: '15px 0',
            zIndex:'50',
            transition:'all 400ms ease',
            ".closed": {
                bottom:'-120%'
            },
            "@media(max-width:991px)": {
                display:'none'
            }
        },
        logoContainer: {
            width: '40px',
            display: 'inline-block'
        },
        navigationContainer: {
            height: "100%",
            padding: '10px 0',
            display: 'inline-block',
            marginLeft: '25px',
            verticalAlign: 'bottom',
            position: 'relative',
            zIndex:'2'
        },
        navLauncher: {
            padding: '7px',
            background: global.colors.accent.color1,
            borderRadius: '5px',
            cursor:'pointer',

        },

        navLauncherIcon: {
            display: 'inline-block',
            padding: '5px',
            height: 0,
            margin: 0,
            marginLeft: '5px',
            background: "#fff",
            borderRadius: '100%',

            ":nth-child(1)": {
                marginLeft: 0
            },


        },
        navigation: {
            position: 'absolute',
            top: '0',
            background: global.colors.dark.color1,
            width: '100px',
            borderRadius: '5px',
            textAlign: 'center',
            color: global.colors.light.color1,
            transform: 'translate(-25%, -100%)',
            fontFamily: global.fontFamily.secondaryFont,
            display:'none',
            ".active": {
                display:'block'
            }
        },
        triangle: {
            height: 0,
            width: 0,
            borderLeft: '20px solid transparent',
            borderRight: '20px solid transparent',
            borderTop: `20px solid ${global.colors.dark.color1}`,
            position: 'absolute',
            bottom: '-19px',
            left: '50%',
            transform: 'translateX(-50%)'
        },
        navItem: {
            width: '100%',
            padding: '7px',
            cursor: 'pointer',
            listStyle: 'none',
            display:'block',
            ":nth-child(1)": {
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px'
            },
            ":last-child": {
                borderBottomLeftRadius: '5px',
                borderBottomRightRadius: '5px'
            },
            ".active": {
                background: global.colors.accent.color1
            },
            ":hover": {
                background: global.colors.accent.color1
            }
        },
        navItemLink: {
            width:'100%',
            height:'100%'
        },
        signUp: {
            zIndex:'1',
            left: 0,
            top:0,
            width: '100%',
            textAlign: 'center',
            position: 'absolute',
            padding: '20px'

        }


    })

};

export default Styles;
