import { createAction } from "redux-act";
import { List } from "immutable";
import { browserHistory } from "react-router";
import _ from "lodash";

import {
  clearEditorPanel,
  resetUndoRedo,
  setElements
} from "containers/Editor/actions/editorActions";
import { Alerts } from "services/Alerts";
import { store } from "app/app";
import { deleteBannerApi, getAllBanners } from "./banners";

const Hash = require("node-object-hash");
export const HashSorter = Hash({ sort: true, coerce: true });

export * from "./banners";
export * from "./shapes";
export * from "./templates";

export const setBanners = createAction("Banners: Set the current banners");
export const popBannerEditorOpen = createAction("POP_BANNER_EDITOR_OPEN");
export const setBannerSizes = createAction("Banners: Set available banner sizes");
export const setBannerTemplates = createAction("Banners: Set banner templates");
export const setSaving = createAction("Banners: Set saving flag");
export const switchToolPanel = createAction("SWITCH_TOOL_PANEL");
export const setActiveBannerId = createAction("Banners: Set Active Banner ID");
export const setBannerShapes = createAction("SET_BANNER_SHAPES");
export const setActiveBanner = createAction("SET_ACTIVE_BANNER");
export const setContextMenu = createAction("SET_SHOW_CONTEXT_MENU");
export const setViewFilter = createAction("BANNERS_SET_VIEW_FILTER");
export const setIsTemplate = createAction("BANNERS_SET_IS_TEMPLATE");
export const setSetting = createAction("Banners: Set Setting");
export const setEditingTemplate = createAction("Banners: Set Editing Template flag");

// used in Reducer

/**
 * Adding this function in case in the future we decide to only fetch
 * banners for the current filter
 * @param filter - String
 */
export const updateViewFilter = filter => async dispatch => {
  await dispatch(setViewFilter(filter));
};

/**
 *
 * Misc functions
 *
 */

export const closeBannerEditor = (returnToBanners = true) => async dispatch => {
  const settings = store
    .getState()
    .get("marketing")
    .Banners.get("settings");
  const snapshotHash = settings.get("banner-elements-hash");
  const elements = store
    .getState()
    .get("editor")
    .editorReducer.get("pageElements");
  const banner = store
    .getState()
    .get("marketing")
    .Banners.get("activeBanner");
  const bannerIsEmpty = !elements.size;
  const isNewBanner = !!banner.get("isNew");
  const id = banner.get("id");
  const editingTemplate = store
    .getState()
    .get("marketing")
    .Banners.get("editingTemplate");

  if (bannerIsEmpty && isNewBanner) {
    await dispatch(removeBannerFromBanners(id));
    await dispatch(clearBannerEditor(returnToBanners));
    await dispatch(deleteBannerApi(id));
    await dispatch(getAllBanners());
    return;
  }

  let dirty = HashSorter.hash(elements) !== snapshotHash;

  if (dirty && !(await Alerts.confirm("You have unsaved changes. Do you still want to leave?"))) {
    return false;
  }

  if (isNewBanner && !snapshotHash) {
    await dispatch(removeBannerFromBanners(id));
    await dispatch(clearBannerEditor(returnToBanners));
    await dispatch(deleteBannerApi(id));
    await dispatch(getAllBanners());
    return;
  }

  if (editingTemplate) {
    await dispatch(clearBannerEditor(true));
    return browserHistory.push("/marketing/templates");
  }

  return await dispatch(clearBannerEditor(returnToBanners));
};

export const removeBannerFromBanners = id => async dispatch => {
  const banners = store
    .getState()
    .get("marketing")
    .Banners.get("banners");
  await dispatch(setBanners(banners.filter(b => b.get("id") !== id)));
};

export const clearBannerEditor = (returnToBanners = true) => async dispatch => {
  if (returnToBanners) await dispatch(toggleBannerEditor(false));

  await Promise.all([
    dispatch(updateSetting("banner-temp-title", null)),
    dispatch(updateSetting("banner-elements-snapshot", null)),
    dispatch(updateSetting("banner-elements-hash", null)),
    dispatch(setEditingTemplate(false)),
    dispatch(setElements([])),
    dispatch(clearEditorPanel()),
    dispatch(setActiveBanner(null)),
    dispatch(setActiveBannerId(null))
  ]);
};

export const loadInitialFonts = elements => async () => {
  const fontsToLoad = [];

  const testForFonts = ({ elements }) => {
    let font;
    if (Array.isArray(elements)) {
      for (let i = 0; i < elements.length; i++) {
        font = _.get(elements[i], "data.userOverrides.fontFamily");
        if (font) {
          fontsToLoad.push(`${font}:400,700`);
        }
        testForFonts({ elements: elements[i].children });
      }
    }
  };

  if (List.isList(elements)) {
    elements = elements.toJS();
  }

  testForFonts({ elements: elements });

  if (!fontsToLoad.length) {
    return;
  }

  window.WebFontConfig = { google: { families: fontsToLoad } };
  const wf = document.createElement("script");
  const s = document.scripts[0];
  wf.src = "https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js";
  wf.async = false;
  wf.onerror = e => console.warn(e.message);
  s.parentNode.insertBefore(wf, s);
};

export const updateSetting = (field, value) => async dispatch => {
  await dispatch(
    setSetting({
      field,
      value
    })
  );
};

/**
 *
 * Updating Redux store functions
 *
 */
export const toggleBannerEditor = params => async dispatch => {
  await dispatch(popBannerEditorOpen(params));
  if (!params) {
    dispatch(resetUndoRedo());
  }
};

export const switchTool = params => async dispatch => await dispatch(switchToolPanel(params));
