import { StyleSheet } from '@convertly/css';

const LocationMapOverlayStyles = ( global, data ) => {


    return StyleSheet.create({
        locationDiv: {
            position:"relative",
            width:'375px',
            height:'375px',
            float:'right',
            overflow:'hidden',
            border:'2px solid black',
            background: 'rgba(90,90,90,.2)',
            fontFamily:global.fontFamily.primaryFont,
            '@media (max-width:991px)': {
                float:'none',
                margin:'50px auto 0'
            },
            '@media (max-width:418px)': {
                width:'100%'
            }

        },
        googleMap: {
            height:'300px',
            width:'100%',
            '.map': {
                marginTop: '-35px',
            }
        },
        locationInfo: {
            position:'absolute',
            background:global.colors.light.color1,
            bottom:'0',
            height:'232px',
            width:'100%',
            padding:'20px'
        },
        storeName: {
            fontSize:'18px',
            color:global.colors.dark.color1,
        },
        address: {
            fontSize:'12px',
            fontFamily:global.fontFamily.secondaryFont,
            marginBottom: "8px"
        },
        contactLink: {
            fontSize:'12px',
            display:'block',
            margin:'8px 0'
        },

        /** Hours of Operation Styles **/
        hoursTitle: {
            fontSize:'12px',
            margin:'8px 0',
            color:global.colors.dark.color3
        },
        hoursOfOperation: {
            fontFamily:global.fontFamily.secondaryFont,
        },
        hoursList: {
            listStyleType:'none',
            position:'relative',
            height:'100px',
        },
        dayOfWeek: {
            display:'inline-block',
            fontSize:'10px',
            margin:'30px 5px',
            textTransform:'uppercase',
            cursor:'pointer',
            color:global.colors.dark.color3,
            borderBottom: '2px solid',
            borderColor:global.colors.dark.color1,
            ':hover': {
                fontWeight:'700',
                color:global.colors.dark.color1

            },
            '.active': {
                fontWeight:'700',
                color:global.colors.dark.color1
            },
            ":first-child": {
                'marginLeft':"0"
            }

        },
        displayedHours: {
            position:'absolute',
            top:0,
            left:'-1000px',
            opacity:'0',
            width:'100%',
            transition:'all .2s linear',
            textAlign:'left',
            color:global.colors.dark.color1,
            '.active': {
                left: 0,
                opacity:'1'
            }
        },
        openStatus: {
            display:'none'
        },
        hours: {
            fontSize:'14px',
            textTransform:'initial',
            fontFamily:global.fontFamily.primaryFont,
            ':nth-child(1)': {
                marginLeft:'30px'
            },
            '@media( max-width:510px )': {
                fontSize:'16px'
            }
        }


    })

};

export default LocationMapOverlayStyles;
