import { connect } from "react-redux";
import PageSettingsModal from "../../App/modals/page-settings-modal";
import { closeModal, uploadSocialImg } from "../actions/editorActions";

const mapStateToProps = state => {
  return {
    site: state.get("editor").editorReducer.get("currentSite"),
    pageSettings: state.get("editor").editorReducer.get("pageSettings"),
    page: state.get("editor").editorReducer.get("page"),
    sites: state.get("auth").get("sites"),
    loading: state.get("editor").editorReducer.get("pageSettingsIsLoading")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: closeModal,
    uploadSocialImage: files => dispatch(uploadSocialImg(files))
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageSettingsModal);

export default Container;
