import {StyleSheet} from "@convertly/css";

const footerGlobalStyles = (global, element) => {

    const globalFooterStyles = {
        height: 'auto',
        clear: 'both',
        width: '100%',
    };
    return globalFooterStyles;
};
export default footerGlobalStyles;
