import React from 'react';
import { css } from "@convertly/css";
import Component from '../../../rootComponent';
import Types from '../../../attribute-types';
import _ from 'lodash';

import { id } from '@convertly/thor';


//Styles
import hoursOfOperationStyles from './hours-of-operation-styles-01';

//Scripts
import hoursWidget from '../scripts/hours-widget';
import testScript from '../scripts/HoursWidget';

//Components
import Elements from '../../../elements/index';

//Default Props
import defaultGlobal from '../../../themes/global.js';


//Component
export default class
HoursOfOperation01 extends Component {
    constructor ( props ) {
        super( props );

    }


    render() {

        let { editor, global, data, site  } = this.props;

        let locations = _.get(this.props, 'site.locations') || [],

        // set default hours
            location  = {
                businessHours: [
                    {
                        "day": "monday",
                        "startHour": "9",
                        "startMinutes": "00",
                        "startPeriod": "AM",
                        "endHour": "5",
                        "endMinutes": "00",
                        "endPeriod": "PM"
                    },
                    {
                        "day": "tuesday",
                        "startHour": "9",
                        "startMinutes": "00",
                        "startPeriod": "AM",
                        "endHour": "5",
                        "endMinutes": "00",
                        "endPeriod": "PM"
                    },
                    {
                        "day": "wednesday",
                        "startHour": "9",
                        "startMinutes": "00",
                        "startPeriod": "AM",
                        "endHour": "5",
                        "endMinutes": "00",
                        "endPeriod": "PM"
                    },
                    {
                        "day": "thursday",
                        "startHour": "9",
                        "startMinutes": "00",
                        "startPeriod": "AM",
                        "endHour": "5",
                        "endMinutes": "00",
                        "endPeriod": "PM"
                    },
                    {
                        "day": "friday",
                        "startHour": "9",
                        "startMinutes": "00",
                        "startPeriod": "AM",
                        "endHour": "5",
                        "endMinutes": "00",
                        "endPeriod": "PM"
                    },
                    {
                        "day": "saturday",
                        "startHour": "9",
                        "startMinutes   ": "00",
                        "startPeriod": "AM",
                        "endHour": "5",
                        "endMinutes": "00",
                        "endPeriod": "PM"
                    },
                    {
                        "day": "sunday",
                        "startHour": "9",
                        "startMinutes": "00",
                        "startPeriod": "AM",
                        "endHour": "5",
                        "endMinutes": "00",
                        "endPeriod": "PM"
                    }
                ],
                isDefault: true
            };

        if (locations.length > 0 && locations[0].businessHours) {
            location = site.locations[0];
        }

        
        /** Calls editor */
        if( editor ) {
            return this.editor();
        }

        /** Generates border in editor */
        const inlineStyle = this.generateStyleAttribute( );


        /**
         * Calls Stylesheet
         **/
        this.styles = hoursOfOperationStyles( global, data );


        let styles;

        if( styles ) {
            styles = styles;
        }
        else {
            styles = this.styles;
        }



        return (

            <div className={ css( styles.hoursOfOperation ) }
                 { ...this.generateDataEditorAttribute() }
                 { ...this.generateCustomAttributes() }
                 onClick={( e ) => {
                     this.launchElementalEditor( 'hoursWidget', e );
                 }}
                 onMouseOver={ (e) => {this.addEditableOutline(e)} }
                 onMouseOut={ (e) => {this.removeEditableOutline(e)} }
                 {...inlineStyle}>
                {

                    // if no locations data is found, display default data disclaimer
                    function(data) {
                        if (data.isDefault) {
                            return (
                                <div style={{textAlign: 'center', paddingBottom: '15px'}} >Showing Default Data</div>
                            )
                        }
                    }(location)
                }
                <div id={ id( hoursWidget ) }>

                    <ul className={ css( styles.hoursList ) }>
                        {
                            location.businessHours.map( ( day, index ) => {

                                let openTime = '',
                                    closeTime = '',
                                    openStatus = false,
                                    displayOpenOrClosed = ' closed',
                                    hoursField;

                                if ( day.startHour && day.endHour ) {

                                    openTime  = day.startHour.concat(':', day.startMinutes, day.startPeriod),
                                    closeTime = day.endHour.concat(':', day.endMinutes, day.endPeriod),
                                    openStatus = true;
                                    displayOpenOrClosed = ' open';

                                    let currentlyOpen = (day) => {
                                        let start24,
                                            close24,
                                            currentTime = new Date(),
                                            currentHour = currentTime.getHours().toString() + currentTime.getMinutes();

                                        if ( day.startPeriod.toUpperCase() === "AM") {
                                            start24 = day.startHour + day.startMinutes
                                        } else {
                                            start24 = + day.starHour + 12 + day.startMinutes
                                        }

                                        if ( day.endPeriod.toUpperCase() === "AM") {
                                            close24 = day.endHour + day.endMinutes
                                        } else {
                                            close24 = + day.endHour + 12 + day.endMinutes
                                        }

                                        if ( parseInt(currentHour) >= start24 && parseInt(currentHour) <= close24 ) {
                                            return 'OPEN';
                                        } else {
                                            return 'CLOSED';
                                        }

                                    };

                                    hoursField = 
                                    
                                    <div className={ css( this.styles.displayedHours ) + " displayed-hours" + displayOpenOrClosed }>
                                            
                                        <span className={ css( this.styles.hours)}>
                                            { `${ openTime } - ${ closeTime }` }
                                        </span>

                                    </div>
                                    

                                } else { 

                                    hoursField = 
                                    
                                    <div className={ css( this.styles.displayedHours ) + " displayed-hours" + displayOpenOrClosed }>
                                        
                                        <span className={ css( this.styles.openStatus )}>
                                            CLOSED
                                        </span>

                                    </div>

                                }

                                return (
                                    <li className={ css( this.styles.dayOfWeek ) + " hours-launcher" }
                                        data-openinghours={ openTime }
                                        data-closinghours={ closeTime }
                                        data-dayofweek={ day.day }
                                        data-openstatus={ openStatus }
                                        key={ index }>
                                        { day.day.slice(0,3) }
                                        
                                        { hoursField }
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>

        )
    }
}

HoursOfOperation01.defaultProps = {
    data: {
        hoursOfOperation: [
            {
                dayOfWeek: "monday",
                openStatus: true,
                openingTime: "9:00am",
                closingTime: "5:30pm",
                displayText: "mon"
            },
            {
                dayOfWeek: "tuesday",
                openStatus: true,
                openingTime: "9:00am",
                closingTime: "12:00pm",
                displayText:'tue'
            },
            {
                dayOfWeek: "wednesday",
                openStatus: true,
                openingTime: "9:00am",
                closingTime: "5:30pm",
                displayText: "wed"

            },
            {
                dayOfWeek: "thursday",
                openStatus: true,
                openingTime: "9:00am",
                closingTime: "5:30pm",
                displayText: "thur"
            },
            {
                dayOfWeek: "friday",
                openStatus: true,
                openingTime: "9:00am",
                closingTime: "4:30am",
                displayText: "fri"
            },
            {
                dayOfWeek: "saturday",
                openStatus: false,
                openingTime: "9:00am",
                closingTime: "5:30pm",
                displayText: "sat"
            },
            {
                dayOfWeek: "sunday",
                openStatus: false,
                openingTime: "9:00am",
                closingTime: "5:30pm",
                displayText: "sun"
            }
        ]

    },
    global: defaultGlobal
};

