import React from 'react';
import _ from 'lodash';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';


//utils
import {generateLink} from '../../../utils/generateLink';
import {clientImage} from '../../../utils/generateImage'

//Scripts
import searchBarToggle from '../../scripts/search-bar-toggle';
import toggleChildrenOpen from '../../scripts/hidden-nav-toggle';
import cartHandler from '../../scripts/cart-script';
import isSignedIn from '../../scripts/is-signed-in';
import SearchBarWrapperHeader01 from '../../scripts/inferno-components/search-bar-wrapper/SearchBarWrapperHeader01';


//Components
import HeaderNavList1 from './header-nav-list-1';
import Elements from '../../../elements';

//Styles
import headerNavStyles from './header-nav-styles-1';

export default class HeaderNav1 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showHeaderMenu: false,
            toggledClass: ''
        };

        this.toggleHeaderMenu = this.toggleHeaderMenu.bind(this);

    }

    toggleHeaderMenu(e) {

        e.preventDefault();

        let showMobileMenu = this.context.store.getState().get('editor').editorReducer.get('showClientNav');

        this.context.store.dispatch(this.context.store.toggleClientNav(showMobileMenu));
        this.forceUpdate();
    }

    displayMobileMenu = () => {
      if(!this.inEditor()) return "";

      const showMobileMenu = this.context.store.getState().get('editor').editorReducer.get('showClientNav');
      return showMobileMenu ? "open" : "closed"

    }


    render() {
        const {data, site, global, page, inEditor} = this.props;
        const {siteId} = site;

        let showMobileMenu = false;

        if (inEditor) {
            showMobileMenu = this.context.store.getState().get('editor').editorReducer.get('showClientNav');
        }

        this.styles = headerNavStyles(global, data, inEditor, page, site);

        const clientImgSrc = clientImage("", {width: '60', height: '60', crop: 'fit'}, siteId);
        const clientLinkUrl = generateLink({linkType: "inferno", url: ""});

        const slug = _.get(this.props, 'page.slug');
        const ecommEnabled = _.get(this.props, 'site.ecommerce.enabled');

        const inlineStyle = this.generateStyleAttribute();

        /** hides cart on cart page **/
        let cart = null;


        if (slug !== "/cart" && ecommEnabled) {
            cart =

                <div className={css(this.styles.headerNavBox)}>

                    <div id={id(cartHandler, clientLinkUrl, clientImgSrc, true, slug)}
                         style={{fontWeight: 'bold', height: '100%'}}
                         href={generateLink({linkType: 'internal', url: '/cart'})}
                         className={css(this.styles.desktopCartInfernoComponent)}>Cart
                    </div>
                </div>
        }

        return (
            <div className={`container-fluid header-nav-3 ${css(this.styles.fitHeight)} `}>
                <div className={`row ${css(this.styles.fitHeight)}`}>
                    <div
                        className={"col-xs-9 col-sm-7 col-md-6 col-lg-5" + " " + css(this.styles.hidePadding, this.styles.fitHeight)}>
                        {data.showNavigation &&
                        <div className={css(this.styles.menu) + this.state.toggledClass} id="menuIcon">
                            {/** Menu Launcher **/}

                            <button id={id(toggleChildrenOpen)}
                                    onClick={(e) => this.toggleHeaderMenu(e)}
                                    data-target="#sideMenu"
                                    type="button"
                                    className={css(this.styles.navLaunch)}>
                                <span className="sr-only">Toggle navigation</span>
                                <span
                                    className={`${css(this.styles.iconBar)} ${this.displayMobileMenu() }`}
                                    data-target="#sideMenu">
                                            </span>
                                <span
                                    className={`${css(this.styles.iconBar)} ${this.displayMobileMenu() }`}
                                    data-target="#sideMenu">
                                            </span>
                                <span
                                    className={`${css(this.styles.iconBar)} ${this.displayMobileMenu() }`}
                                    data-target="#sideMenu">
                                            </span>
                            </button>

                        </div>
                        }
                        { /** Slide out menu **/}
                        <div className={css(this.styles.overlay)}
                             data-target="#sideMenu"
                             id="header03Overlay"/>
                        <div id="sideMenu"
                             className={`${css(this.styles.popOutMenu)} ${ this.displayMobileMenu() }`}>
                            { /** Maps through nav items **/}
                            <HeaderNavList1
                                styles={this.styles}
                                page={page}
                                id={this.props.id}
                                global={global}
                                data={data.menu}
                                displaySocial={{
                                    headerTopper: _.get(data, "headerTopperSettings.display"),
                                    socialIcons: _.get(data, "headerTopperSettings.displaySocialIcons")
                                }
                                }
                                site={site}
                                clientLinkUrl={clientLinkUrl}
                                clientImgSrc={clientImgSrc}/>
                        </div>
                        {data.showLogo &&
                        <div className={css(this.styles.logo)}
                             onClick={(e) => {
                                 this.cancelClick(e)
                             }}
                             onMouseOver={this.addEditableOutline}
                             onMouseOut={this.removeEditableOutline}
                             {...inlineStyle}
                             id="logo">
                            {data.logoLinkToHomePage ?
                                <a href={generateLink({linkType: 'internal', "url": '/'})}
                                   onClick={(e) => {
                                       this.cancelClick(e)
                                   }}>

                                    {data.useTextLogo && _.get(data.textLogo, 'text') ?
                                        <Elements.Heading2 data={{...data.textLogo}}>Text Logo
                                            Here</Elements.Heading2>
                                        :
                                        _.get(site, 'logo.src') &&
                                        <picture>
                                            { /** WEBP **/}
                                            {/*<source srcSet={clientImage(site.logo.src, {format: "webp"})}*/}
                                            {/*        type="image/webp"/>*/}
                                            { /** normal **/}
                                            <source srcSet={clientImage(site.logo.src, data.params)} type="image/jpeg"/>
                                            <img src={clientImage(site.logo.src, {})}
                                                 data-logo
                                                 alt={site.alt}
                                                 title={site.title}
                                                 className={css(this.styles.logoImg)}/>
                                        </picture>
                                    }
                                </a>
                                :
                                <span>
                                {data.useTextLogo && _.get(data.textLogo, 'text') ?
                                    <Elements.Heading2 data={{...data.textLogo}}>Text Logo Here</Elements.Heading2>
                                    :
                                    _.get(site, 'logo.src') &&
                                    <picture>
                                        { /** WEBP **/}
                                        {/*<source srcSet={clientImage(site.logo.src, {format: "webp"})}*/}
                                        {/*        type="image/webp"/>*/}
                                        { /** normal **/}
                                        <source srcSet={clientImage(site.logo.src, data.params)} type="image/jpeg"/>
                                        <img src={clientImage(site.logo.src, {})}
                                             data-logo
                                             alt={site.alt}
                                             title={site.title}
                                             className={css(this.styles.logoImg)}
                                        />
                                    </picture>
                                }
                                </span>
                            }
                        </div>
                        || <span className={css(this.styles.logo)} id="logo"/>
                        }
                        <div className="clearfix"/>
                    </div>
                    {ecommEnabled &&
                    <div
                        className={"col-xs-3 col-sm-5 col-md-6 col-lg-7" + " " + css(this.styles.hidePadding) + " " + css(this.styles.fitHeight)}>
                        <div className={css(this.styles.rightWrapper, this.styles.fitHeight)}>
                            {!_.get(this.props.page, 'hideSearchBar') &&
                            _.get(this.props, 'site.ecommerce.enabled') &&
                            <div id={id(SearchBarWrapperHeader01, this.props.site.siteId)}
                                 className={css(this.styles.searchBarWrapper)}/>
                            }
                            {
                                _.get(data, "headerTopperSettings.displaySocialIcons") &&
                                <div className={css(this.styles.socialIcons)}>
                                    <div className="clearfix"/>
                                </div>
                            }

                            { /** Cart Inferno Component **/}

                            {inEditor ?
                                <div className={css(this.styles.headerNavBox)}>

                                    <div className={css(this.styles.headerNavLink) + " cart"}>Cart</div>
                                </div> : cart}

                            {inEditor ?
                                <div className={css(this.styles.headerNavBox)}>
                                    <div className={css(this.styles.headerNavLink)}>Sign In</div>
                                </div>
                                :
                                <div className={css(this.styles.headerNavBox)}>
                                    <div
                                        id={id(isSignedIn, clientLinkUrl)}
                                        href={generateLink({linkType: 'internal', url: '/account'})}
                                        className={css(this.styles.desktopSignInInfernoComponent)}>
                                        Sign In
                                    </div>
                                </div>}


                            <div className="clearfix"/>
                        </div>
                        <div className="clearfix"/>
                    </div>
                    }
                    <div className="clearfix"/>

                </div>
            </div>

        )
    }
}

