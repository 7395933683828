/*global Inferno, $, React */
import {prepStringForInferno} from "../../../../utils/prep-string-for-inferno";

/** Box Selector Product Variant Widget **/

const Script = (React,
                dispatch,
                graph,
                convertToCurrency,
                ProductImageGallery,
                AddToCartStepper,
                BubbleInferno,
                BubbleVariantContainer,
                VariantProgressBarInferno,
                BuyingOptionsWrapper,
                stripHtml,
                ArrowHeadOpenRight
) => {


    return ({
                data,
                rootClass,
                rootDiv,
                variationTypeMeta,
                enableAddToCart,
                checkProductOptionSelection,
                handleProductVariantArray,
                updateVariationTypeMeta,
                outOfStockVariations,
                selectedProductVariants,
                toggleMobileOptions,
                currentQty,
                resetButtonState,
                increaseQuantity,
                decreaseQuantity,
                addToCart,
                disableAddToCart,
                openMobileOptions,
                updateFeatureImage,
                activeImgIndex,
                featureImage,
                imgArr,
                goBackToPreviousPage
            }) => {


        let description = '';
        if (data.description) {
            description = data.description.replace(/<<>>/g, "'");

            description = description.replace(/&lt;/g, '<');
            description = description.replace(/&gt;/g, '>');
        }


        data.variationTypeMeta.map((variant, index) => {
            variationTypeMeta[index].values.map((value, i) => {
                variationTypeMeta[index].values[i].name = value.name.replace(/&quot;/g, '"');
            })
        });


        //Define Price Section:
        let priceSection =
            <div className={`${rootClass} active-price`}>
                {convertToCurrency(data.price * (currentQty || 1))}
            </div>;

        const hasVariants = data.variationTypeMeta;
        if (data.onSale && data.inStock) {
            priceSection =
                <div className={`${rootClass} priceContainer`}>
                    <p className={`${rootClass} active-price`}>{convertToCurrency(data.salePrice * (currentQty || 1))}</p>
                    <p className={`${rootClass} full-price-strike-out`}>{convertToCurrency(data.price * (currentQty || 1))}</p>
                </div>;
        }


        const addToCartNoVariance = (
            <div className={`${ rootClass } add-to-cart-no-variance-container`}>
                <div className={`${rootClass} gridBuyingOptions`}>
                    <BuyingOptionsWrapper rootClass={rootClass}
                                          currentQty={currentQty}
                                          showAddToCart={true}
                                          resetButtonState={resetButtonState}
                                          increaseQuantity={increaseQuantity}
                                          decreaseQuantity={decreaseQuantity}
                                          addToCart={addToCart}
                                          product={data}/>
                </div>
            </div>
        );

        const addToCartNoVarianceMobile = (
            <div className={`${ rootClass } add-to-cart-no-variance-container-mobile`}>
                <div className={`${rootClass} gridBuyingOptions`}>
                    <BuyingOptionsWrapper rootClass={rootClass}
                                          currentQty={currentQty}
                                          showAddToCart={true}
                                          resetButtonState={resetButtonState}
                                          increaseQuantity={increaseQuantity}
                                          decreaseQuantity={decreaseQuantity}
                                          addToCart={addToCart}
                                          product={data}/>
                </div>
            </div>
        );

        const productDescription =
            (<div
                className={`${rootClass} description-container ${ hasVariants && data.inStock ? '' : 'no-variance' }`}>
                {/** Description **/}
                <h2 className={`${rootClass} description-intro`}>Product Description</h2>
                <div id="description"
                     dangerouslySetInnerHTML={{__html: description}}
                     className={`${rootClass} description`}/>
                {
                  Array.isArray(variationTypeMeta) &&
                  variationTypeMeta.length === 0 &&
                  addToCartNoVarianceMobile
                }
  
            </div>);


        return (
            <div>
                <div className="row">
                    <div className="col-xs-12">
                        <div
                            className={`${rootClass} title-container ${ data.onSale && data.inStock ? 'on-sale' : '' }`}>
                            <div
                                className={`${rootClass} back-button`}
                                onClick={goBackToPreviousPage}>
                                <div className={`${rootClass} arrow-head-open-right-container`}>
                                    <ArrowHeadOpenRight className={rootClass}/>
                                </div>
                                Back
                            </div>
                            <div
                                className={`${rootClass} on-sale-flag${data.onSale && data.inStock ? ' active' : ''}`}/>
                            {
                                !data.inStock &&
                                <div className={`${rootClass} out-of-stock-pdp-banner`}>Out of Stock</div>
                            }
                            {/** Title **/}
                            <div
                                className={`${rootClass} title-content ${data.onSale && data.inStock ? 'on-sale' : ''}`}>
                                {data.subTitle && <div className={`${rootClass} sub-title`}>{data.subTitle}</div>}
                                <h3 className={`${rootClass} product-title`}>{data.title}</h3>
                                {priceSection}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className={data.inEditor ? 'col-md-6' : `col-md-6 col-sm-6 col-xs-12`}>
                        <ProductImageGallery rootClass={rootClass}
                                             updateFeatureImage={updateFeatureImage}
                                             featureImage={featureImage}
                                             activeImgIndex={activeImgIndex}
                                             imgArr={imgArr}
                                             product={data}/>
                        {
                            Array.isArray(variationTypeMeta) &&
                            variationTypeMeta.length === 0 &&
                            data.inStock &&
                            addToCartNoVariance
                        }
                        {
                            Array.isArray(variationTypeMeta) &&
                            variationTypeMeta.length > 0 &&
                            data.inStock &&
                            productDescription
                        }

                    </div>

                    <div className="col-md-6 col-sm-6 col-xs-12">
                        {/** Product options section **/}
                        {
                            (Array.isArray(variationTypeMeta) &&
                              variationTypeMeta.length > 0 && data.inStock) ?
                                <div className={` ${ rootClass } quick-view-product-variant-wrapper`}>
                                    <div
                                        className={` ${ rootClass } quick-view-product-variant-container ${ openMobileOptions ? 'active' : ''} ${ !openMobileOptions && enableAddToCart ? 'cart-activated' : '' }`}>
                                        <div className={` ${ rootClass } close-button `}
                                             onClick={toggleMobileOptions}
                                        ><span
                                            className={` ${ rootClass } close-button-text`}>{!openMobileOptions ? 'Options' : 'Close'}</span>
                                        </div>
                                        <BubbleVariantContainer
                                            rootClass={rootClass}
                                            checkProductOptionSelection={checkProductOptionSelection}
                                            handleProductVariantArray={handleProductVariantArray}
                                            updateVariationTypeMeta={updateVariationTypeMeta}
                                            outOfStockVariations={outOfStockVariations}
                                            variationTypeMeta={variationTypeMeta}
                                            selectedProductVariants={selectedProductVariants}
                                            noMaxWidth={true}
                                            maxBubbleWidth={true}
                                            product={data}
                                            inPDP={true}
                                        />
                                        <div className={`${rootClass} gridBuyingOptions`}>
                                            <BuyingOptionsWrapper rootClass={rootClass}
                                                                  currentQty={currentQty}
                                                                  showAddToCart={enableAddToCart}
                                                                  disableAddToCart={disableAddToCart}
                                                                  resetButtonState={resetButtonState}
                                                                  increaseQuantity={increaseQuantity}
                                                                  decreaseQuantity={decreaseQuantity}
                                                                  addToCart={addToCart}
                                                                  toggleMobileOptions={toggleMobileOptions}
                                                                  product={data}/>
                                        </div>
                                    </div>
                                </div>
                                :
                                productDescription
                        }
                    </div>
                </div>
            </div>
        );
    }
};

export default Script;
