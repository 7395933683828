import React from 'react';
import Component from '../../rootComponent';
import {css} from "@convertly/css";
import { id } from '@convertly/thor';

import MVCWrapper from './mvc-root-component';



export class MVCModel extends Component {
	
	constructor(props) {
		
		super(props);
		
	}
	
	render() {
		
		return (
			<div id={ id(MVCWrapper) }/>
		)
	}
	
}
