//import $ from 'jquery';
//toggles nav's drop down icons
const navDropdownToggle = (id) => {

    return (id) => {


        (function () {
            /**
             * Select root id
             */
            const $el = $('#' + id);
            const el = document.getElementById(id);

            // Gets data target, finds elements with that selector
            // and toggles class open
            $el.on('click', function(e) {
                let target = e.target.dataset.target;

                $(`[data-element=${target}]`).toggleClass('open');

            })

        })();
    };


};
export default navDropdownToggle;
