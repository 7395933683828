import React from "react";
import EditorMenu from "./container/EditorMenu";
import AdtechMenu from "./container/AdtechMenu";
import ProductDetailMenu from "./container/ProductDetailMenu";
import OrderDetailMenu from "./container/OrderDetailMenu";
import CustomerDetailMenu from "./container/CustomerDetailMenu";
import CategoryDetailMenu from "./container/CategoryDetailMenu";
import { CouponDetailMenu } from "./container/CouponDetailMenu";
import { UserDetailMenu } from "./container/UserDetailMenu";

export default class MenuConductor extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { location } = this.props;

    let matches;

    if ((matches = location.pathname.match(/\/users\/(partners|clients)\/([a-z0-9\-]+)/))) {
      return <UserDetailMenu matches={matches} {...this.props} />;
    }

    if (location.pathname === "/editor") {
      return <EditorMenu {...this.props} />;
    }

    if (location.pathname.match(/\/marketing\/banners/)) {
      return <AdtechMenu {...this.props} />;
    }

    if (location.pathname.match(/\/storefront\/products\/([a-z0-9\-]+)/)) {
      return <ProductDetailMenu />;
    }

    if (location.pathname.match(/\/storefront\/orders\/([a-z0-9\-]+)/)) {
      return <OrderDetailMenu />;
    }

    if (location.pathname.match(/\/storefront\/customers\/([a-z0-9\-]+)/)) {
      return <CustomerDetailMenu />;
    }

    if (location.pathname.match(/\/storefront\/categories\/([a-z0-9\-]+)/)) {
      return <CategoryDetailMenu />;
    }

    if (location.pathname.match(/\/storefront\/coupons\/([a-z0-9\-]+)/)) {
      return <CouponDetailMenu />;
    }

    return <div style={{ width: "100%" }} />;
  }
}
