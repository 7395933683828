import { assignStyleValues } from './assign-style-value';

export function handleTextElements( global ) {

    // Elements to transform
    let elements = [
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'p',
        'list',
        'label',
        'radioLabel',
        'checkboxLabel',
        'selectInput',
        'textArea',
        'moduleDefault',
        'input'];

    //CSS Rules and their defaults
    let cssRules = [
        {
            name: 'fontFamily',
            default: global.fontFamily.primaryFont
        },
        {
            name: 'color',
            default: global.textColors.dark.color1
        }
    ];


    return assignStyleValues(elements, cssRules, global);

}