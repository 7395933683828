import React from 'react';
import Component from '../../rootComponent';
import { css } from "@convertly/css";


export default class
ArrowHeadDownSvg extends Component {
    constructor ( props ) {
        super( props );
    }
    render() {
        return (
            <svg version="1.1"
                 className={ css( this.props.className ) }
                 xmlns="http://www.w3.org/2000/svg"
                 width="50"
                 height="50"
                 { ...this.props.attributes }
                 viewBox="0 0 404.308 404.309">
                <g>
                    <path d="M0,101.08h404.308L202.151,303.229L0,101.08z"/>
                </g>
            </svg>
        )
    }
}
