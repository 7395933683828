import { connect } from "react-redux";
import Menu from "../menus/OrderDetailMenu";
import { saveOrder, setUpdateInventoryModal } from "containers/StoreFront/OrderDetail/actions";

const mapStateToProps = state => {
  return {
    order: state.get("Order").Order.get("currentOrder"),
    loading: state.get("Order").Order.get("loading"),
    saving: state.get("Order").Order.get("saving"),
    showUpdateInventoryModal: state.get("Order").Order.get("showUpdateInventoryModal")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    save: (order, snapshot, updateInventory, cameFromModal) => {
      return dispatch(saveOrder(order, snapshot, updateInventory, cameFromModal));
    }
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu);

export default Container;
