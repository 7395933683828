/*global Inferno, $ */
/* eslint-disable */
import {include} from '@convertly/thor';

const Script = () => {

        /** Imports **/
        const Graph = include('../../utils/graph', 'Graph');
        const Dispatcher = include('../../utils/dispatcher', 'Dispatcher');
        const FormValidation = include('../../utils/form-validation', 'FormValidation');

        const SignUpInferno = include('./sign-up', 'SignUpInferno');


        return (id) => {

            const hasLocalStorage = $('html').hasClass('localstorage');


            const root = document.getElementById(id);
            const rootClass = root.className;

            /** Init Imports **/

                //Convertly Utils
            const graph = Graph();
            const dispatch = new Dispatcher(id);
            const validateForm = new FormValidation();

            const SignUp = SignUpInferno( id, dispatch, validateForm );



            const CheckoutWrapper = Inferno.createClass({

                getInitialState: function () {


                    return {
                        renderStage: 1,
                    };
                },

                updateRenderStage: function(stage) {

                    this.setState({ renderStage:stage })

                },


                render: function () {

                    let renderStage = null;



                    return (
                        <div className={ `${rootClass} login_container` }>

                            <SignUp rootDiv={ root } isActive={ this.state.renderStage === 1 } />

                        </div>
                    )
                }

            });

            Inferno.render(<CheckoutWrapper />, root);

        };

    }
;

export default Script;
