import React from 'react';
import Component from 'source/rootComponent';
import {css} from '@convertly/css';
import _ from 'lodash';

// Utils
import {generateLink} from 'source/utils/generateLink';
import Elements from "source/elements";

// Components

// Styles
import LogoStyles from './LogoStyles';

// Scripts

export class Logo extends Component {

    render() {

        const {global, data, inEditor, site} = this.props;
        const {alt, title} = site || {};
        const {
            showLogo,
            logoLinkToHomePage,
            textLogo,
            useTextLogo
        } = data || {};

        if (!showLogo) {
            return null;
        }

        const imgSrc = _.get(site, 'logo.src') || '';
        this.styles = LogoStyles({global, data, inEditor});
        const {imgLogo, txtLogo, logo} = this.styles;

        let generatedLinkObject;

        const inlineStyle = this.generateStyleAttribute();

        let logoComponentToBeRendered = (


            <Elements.Image
                data={{
                    src:imgSrc,
                    alt,
                    title,
                    customAttributes: {
                        "data-logo":true,
                        ...data.customAttributes
                    }
                }}
                parentClass={`imgLogo ${ css(imgLogo) }`}
            />
        );

        if (useTextLogo) {
            logoComponentToBeRendered = (
                <div
                    className={css(txtLogo)}
                    {...inlineStyle}
                    {...this.generateDataEditorAttribute()}
                    {...this.generateCustomAttributes()}>
                    {textLogo.text}
                </div>
            );
        }


        if (logoLinkToHomePage) {
            let logoComponent = logoComponentToBeRendered;

            generatedLinkObject = {
                url: '/',
                linkType: 'internal'
            };

            logoComponentToBeRendered = (
                <a
                    className={css(logo)}
                    href={generateLink(generatedLinkObject)}
                    {...inlineStyle}
                    {...this.generateDataEditorAttribute()}
                    {...this.generateCustomAttributes()}>
                    {logoComponent}
                </a>
            );
        }

        return logoComponentToBeRendered;

    }

}
