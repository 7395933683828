import axios from "axios";
import { store } from "app/app";
import _ from "lodash";
import { setUnclaimedChatThreads, setClaimedChatThreads } from "./chatServiceActions";

const getAccessToken = () =>
  store
    .getState()
    .get("auth")
    .get("accessToken");

export const getChatById = chatId => {
  return new Promise((resolve, reject) => {
    const accessToken = getAccessToken();

    const data = {
      chatThreadId: chatId,
      limit: 50,
      start: 1,
      AccessToken: accessToken
    };

    const params = {
      method: "post",
      url: "https://api.convertly.com/production/getChatThreadById",
      data
    };

    axios(params)
      .then(({ data }) => {
        if (!data) {
          resolve([]);
        }

        const thread = _.get(data, "ChatThread") || {};
        resolve(thread);
      })
      .catch(err => {
        console.log(err.message);
        resolve([]);
      });
  });
};

export const getHistoryChatThreads = async () => {
  const data = {
    ShowTeam: true,
    Limit: 50,
    Start: 1,
    Active: false,
    UserId: true
  };

  return await getThreads(data);
};

export function getClaimedChatThreads() {
  return async dispatch => {
    const data = {
      ShowTeam: false,
      Limit: 50,
      Start: 1,
      Active: true,
      UserId: false
    };

    const threads = await getThreads(data);
    await dispatch(setClaimedChatThreads(threads));
  };
}

export function getUnclaimedChatThreads() {
  return async dispatch => {
    const data = {
      ShowTeam: false,
      Limit: 50,
      Start: 1,
      Active: false,
      UserId: false
    };

    const threads = await getThreads(data);
    await dispatch(setUnclaimedChatThreads(threads));
  };
}

function getThreads(data) {
  return new Promise(resolve => {
    const accessToken = getAccessToken();

    data.AccessToken = accessToken;

    const params = {
      method: "post",
      url: "https://api.convertly.com/production/getChatThreads",
      data
    };

    axios(params)
      .then(({ data }) => {
        if (!data) {
          resolve([]);
        }

        const threads = _.get(data, "ChatThreads.threads") || [];
        resolve(threads);
      })
      .catch(err => {
        console.log(err.message);
        resolve([]);
      });
  });
}
