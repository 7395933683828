import { StyleSheet } from "@convertly/css";

const Styles = ( global, data ) => {

    return StyleSheet.create({
        sliderContainer: {
            height:'100%',
            position:'relative',
            overflow: 'hidden'
        }

    })

};
export default Styles;