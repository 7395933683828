/*global  Inferno, $ */
/* eslint-disable */

const CurbsidePickupWidget = (dispatch, graph, OrderStatus, OrderNotifications, EditNotificationsModal) => {

    const hasLocalStorage = $('html').hasClass('localstorage');


    return Inferno.createClass({


        /** Sets states **/
        getInitialState: function () {

        },

        renderNotificationModal: function () {
            const {order} = this.props;

            let render = true
            //if sms is already enabled we don't need to render this.
            if (order.smsAlert && order.smsAlert.enabled) {
                render = false
            }
            //if item has been shipped we don't need to display notification modal
            if (order.orderStatus === "Shipped") {
                render = false
            }
            //return the bool
            return render

        },

        render: function () {
            const {rootClass, order} = this.props;
console.log("ORDER::::::", order)
            return (
                <div>
                    { /** ORDER STATUS **/}
                    <OrderStatus orderStatus={order.orderStatus} rootClass={rootClass}/>
                    { /** Edit Notifications **/}
                    {
                        this.renderNotificationModal() &&
                        <EditNotificationsModal messageSettings={order.smsAlert}
                                                orderId={order.orderId}
                                                rootClass={rootClass}/>
                    }

                    {/** ORDER NOTIFICATION **/}
                    <OrderNotifications rootClass={rootClass} order={order}/>
                </div>
            )
        }
    });
};

export default CurbsidePickupWidget;
