/*global navigator $ */

const Script = (arg) => {
	
	return function (rawText) {
		
		let polishedText = '';
		
		polishedText = rawText.replace(/<p>|<\/p>|<script>|<\/script>|<script\/>/g, '');
		
		
		return polishedText;
		
	}
};

export default Script;

