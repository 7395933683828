import { generateMSStyleSheet } from '../../utils/browserSpecificStyles';

export default function StyleObject(global, data) {

    const ieShippingModalStyles = {
        maxWidth:'650px',

    };

    const ieShippingModal = generateMSStyleSheet( ieShippingModalStyles );

    return {

        ".chkTitle3": {
            color: global.colors.dark.color1,
            fontSize: "22px",
            lineHeight: "30px",
            marginBottom: "10px",
            marginTop: "20px"
        },
        ".titleCentered": {
            textAlign:'center'
        },
        ".checkBox": {
            border:'1px solid',
            cursor: 'pointer',
            verticalAlign: 'text-bottom',
            borderColor:global.colors.accent.color3,
            fill:global.colors.light.white,
            background:global.colors.light.white,
            '.open': {
                border:'none',
                fill:global.colors.accent.color1,
            }
        },
        ".checkboxCheckBox": {
            visibility:'hidden',
            height:0,
            width:0
        },
        ".checkboxContainer": {
            marginRight:'10px'
        },
        ".checkboxLabel": {
            display: 'block',
            color: global.colors.dark.color1,
            verticalAlign: 'middle',
            cursor: 'pointer',
            margin: '0 0 30px'
        },
        ".labelText": {
            "@media(max-width:480px)": {
                fontSize: "12px"
            }
        },

        ".chkLabel": {
            color: global.colors.dark.color3,
            fontFamily: global.fontFamily.secondaryFont,
            fontSize: "12px",
            lineHeight: "20px",
            marginBottom: "5px"
        },
        ".overlay": {
            position:'fixed',
            top:0,
            left:0,
            height:'100vh',
            width:'100vw',
            background:'rgba(200,200,200,.7)',
            zIndex:'19'
        },
        ".wrapper": {
            position:'fixed',
            height:'100%',
            width:'100%',
            left:'0',
            top:'0',
            zIndex:'20'
        },
        ".shippingModal": {
            position: 'relative',
            top: '50%',
            margin: 'auto',
            transform: 'translateY(-30vh)',
            width: '500px',

            background: "#fff",
            padding:'20px',
            border:global.border,
            ...ieShippingModal,
            "@media (max-width:767px)": {
                width: '100%',
                left: '0',
                minWidth:'unset'
            },
            "@media (max-width:767px) and (min-width: 515px)": {
                paddingTop: '20px'
            },
            "@media (max-width: 515px)": {
                padding: '17px'
            }

        },
        ".shippingModalHeader": {
            fontSize: '24px',
            marginBottom: '25px',
            textAlign: 'center'
        },
        ".btnContinue_wButton_Modal": {
            backgroundColor: global.colors.dark.color3,
            border: "0",
            borderRadius: "0",
            color: "white",
            display: "block",
            fontSize: "14px",
            lineHeight: "inherit",
            margin: "30px 0 0",
            padding: "9px 15px",
            paddingBottom: "11px",
            paddingTop: "11px",
            textDecoration: "none",
            textTransform: "uppercase",
            width: "100%",
            textAlign:"center",
            cursor:'default',
            ".active": {
                backgroundColor: global.colors.dark.color1,
                cursor:'pointer'
            }
        },
        '.closeButton': {
            position: 'absolute',
            padding: '9px 10px 10px',
            top: '-15px',
            right: '-15px',
            height: '45px',
            width: '45px',
            border: global.border,
            borderRadius: '100%',
            background: global.colors.light.color1,
            cursor: 'pointer',
            zIndex: '2',
            "@media (max-width:767px)": {
                right: '10px',
                top: '10px',
                background: 'none',
                border: 'none'
            },

        },
        '.closeButtonIcon': {
            fill: global.colors.dark.color1,
            stroke: global.colors.dark.color1,
            "@media (max-width:767px)": {
                height: '20px',
                width: '20px'
            },
        },

        '.closeIcon': {
            height: '20px',
            width: '20px'
        },

        /** CURBSIDE PICKUP **/
        ".curbsidePickupSection": {
            borderTop: "1px solid #b4bbc3",
            margin: "20px 0"
        },
        ".curbsideHeader": {
            color:global.colors.dark.color1,
            fontWeight:'bold',
            margin:"10px 0"
        }

    }


}
