import React from "react";
import PropTypes from "prop-types";
import { clientImage } from "containers/Editor/components/pegasus-components/src/utils/generateImage";

import { EditCSSValueSlider } from "../../../Editor/components/editor/components/EditCSSValueSlider";

import {
  ImageEditingPanel,
  SliderContainer,
  ImageContainer,
  ImgResponsive
} from "./css-image-editor";

export class FramesPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.handleToolSelect = this.handleToolSelect.bind(this);
  }

  handleToolSelect(index) {
    this.setState({ selectedIndex: index });
  }

  render() {
    const { image, params, siteId } = this.props;

    return (
      <ImageEditingPanel>
        <ImageContainer>
          <ImgResponsive
            src={clientImage(image, params.toJS(), siteId)}
            maxWidth="900"
            maxHeight="350"
          />
        </ImageContainer>
        <SliderContainer>
          <div>
            <EditCSSValueSlider
              defaultValue={0}
              unit="vh"
              min={0}
              max={100}
              field={"height"}
              sectionWrapperStyle={{ marginBottom: 0, marginRight: 0, paddingBottom: 20 }}
              objectBeingEdited={this.sliderObject}
              pageElements={this.props.pageElements}
              editorViewportWidth={this.props.editorViewportWidth}
              sliderHandleStyle={{
                marginTop: "20px",
                width: 13,
                height: 13,
                backgroundColor: "#45AA91"
              }}
              sliderBarStyle={{ width: "250px", height: "3px" }}
              hideLabel
            />
          </div>
        </SliderContainer>
      </ImageEditingPanel>
    );
  }
}

FramesPanel.defaultProps = {
  image: "",
  params: {},
  siteId: {}
};

FramesPanel.propTypes = {
  image: PropTypes.string,
  params: PropTypes.object,
  siteId: PropTypes.string
};
