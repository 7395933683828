import React from "react";
import {CloseIconContainer, HeaderIconContainer, HelpFormHeaderWrapper, HelpIcon, HeaderControlIcons} from "./components";

/** Header of feedback widget, has close icon and a back arrow on stage 2 **/
export const FeedbackHeader = ({toggleForm, prevStage, stage}) => (
  <HelpFormHeaderWrapper>
    <CloseIconContainer>
      {/*Close Icon, closes widget*/}
      <HeaderControlIcons onClick={toggleForm} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="white"/>
      </HeaderControlIcons>
      {/*Back Arrow, moves back from stage 2 only currently*/}
      {stage === 2 &&
      <HeaderControlIcons onClick={prevStage} width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 12.3433L3.05533 7L8 1.645L6.47773 0L0 7L6.47773 14L8 12.3433Z" fill="white"/>
      </HeaderControlIcons>
      }
    </CloseIconContainer>
    <HelpIcon width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.1688 35.871C33.1688 38.8882 32.0039 41.7818 29.9305 43.9153C27.857 46.0488 25.0448 47.2473 22.1125 47.2473C19.1802 47.2473 16.368 46.0488 14.2946 43.9153C12.2211 41.7818 11.0563 38.8882 11.0563 35.871C11.0563 32.8538 12.2211 29.9602 14.2946 27.8267C16.368 25.6933 19.1802 24.4947 22.1125 24.4947C25.0448 24.4947 27.857 25.6933 29.9305 27.8267C32.0039 29.9602 33.1688 32.8538 33.1688 35.871ZM44.225 64.3118V70H0V64.3118C0 58.0264 9.89535 52.9355 22.1125 52.9355C34.3297 52.9355 44.225 58.0264 44.225 64.3118Z" fill="white"/>
      <path d="M40.1479 0H66.683C67.5626 0 68.4063 0.359572 69.0283 0.999615C69.6504 1.63966 69.9998 2.50774 69.9998 3.4129V23.8903C69.9998 24.7954 69.6504 25.6635 69.0283 26.3036C68.4063 26.9436 67.5626 27.3032 66.683 27.3032H56.5665L50.4303 33.6341C50.0986 33.9583 49.684 34.129 49.2693 34.129H48.4401C48.0003 34.129 47.5785 33.9492 47.2674 33.6292C46.9564 33.3092 46.7817 32.8751 46.7817 32.4225V27.3032H40.1479C39.2682 27.3032 38.4246 26.9436 37.8025 26.3036C37.1805 25.6635 36.8311 24.7954 36.8311 23.8903V3.4129C36.8311 2.50774 37.1805 1.63966 37.8025 0.999615C38.4246 0.359572 39.2682 0 40.1479 0V0ZM53.7305 5.97257C52.2545 5.97257 51.077 6.27973 50.1815 6.89405C49.2693 7.50838 48.805 8.53225 48.8879 9.7097H52.155C52.155 9.23189 52.3209 8.87354 52.5862 8.63463C52.9179 8.39573 53.2828 8.27628 53.7305 8.27628C54.2447 8.27628 54.6924 8.41279 54.991 8.71996C55.2895 9.01005 55.4387 9.38547 55.4387 9.89741C55.4387 10.3752 55.3061 10.8018 55.0739 11.1602C54.7919 11.5356 54.4437 11.8428 54.0125 12.0817C53.1501 12.6277 52.5862 13.1055 52.2379 13.5151C51.9228 13.9246 51.757 14.5048 51.757 15.358H55.0739C55.0739 14.8802 55.1568 14.5048 55.3061 14.1977C55.4553 13.9076 55.7373 13.6516 56.1684 13.3956C56.9313 13.0373 57.5615 12.5595 58.0425 11.9451C58.5234 11.3138 58.7722 10.6482 58.7722 9.89741C58.7722 8.70289 58.3244 7.74728 57.4289 7.03057C56.5333 6.33093 55.2895 5.97257 53.7305 5.97257V5.97257ZM51.757 17.0645V20.4774H55.0739V17.0645H51.757Z" fill="white"/>
    </HelpIcon>
    <HeaderIconContainer/>
  </HelpFormHeaderWrapper>
);
