import { connect } from "react-redux";
import Menu from "../menus/ProductDetailMenu";

const mapStateToProps = state => {
  return {
    product: state.get("product").get("currentProduct"),
    saving: state.get("product").get("saving"),
    site: state.get("auth").get("activeSite")
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu);

export default Container;
