export const verifyIfChildrenHaveHeight = arr => {
  for (let i in arr) {
    if (
      arr[i].el.split(".")[1] !== "Container" &&
      arr[i].el.split(".")[1] !== "Row" &&
      arr[i].el.split(".")[1] !== "Column" &&
      arr[i].el.split(".")[1] !== "WrapperDiv"
    )
      return true;
    else if (arr[i].children.length) {
      if (verifyIfChildrenHaveHeight(arr[i].children)) return true;
    }
  }
  return false;
};
export default verifyIfChildrenHaveHeight;
