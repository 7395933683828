import React from 'react';
import Component from 'source/rootComponent';
import { css } from '@convertly/css';
import _ from 'lodash';

// Components
import {NavLinkList} from './NavLinkList';

// Styles 
import headerStylesBottom from '../styles/headerStylesBottom';

export class HeaderBottom extends Component {
  
  constructor(props) {
    super(props);
  }

  render() {

    const { global, data, site, inEditor, page } = this.props;
  	

    this.styles = headerStylesBottom(global, data, inEditor);
    const { mainHeaderBottom } = this.styles;
    
    const navLinks = _.get(data, 'menu.navLinks') || [];

    return (
      <div className={ css(mainHeaderBottom) }>
        {
          navLinks &&
          <NavLinkList
            global={ global }
            data={ data }
            site={ site }
            navLinks={ navLinks } 
            inEditor={ inEditor }
            page={ page }/>
        }
      </div>
    )
  }
}
