import PropTypes from 'prop-types';
import React, { Component } from "react";
import { css } from "@convertly/css";
import styles from "./editor-forms-styles";
import { setImageUrl } from "../../actions/editorActions";
import { uploadImages } from "services/sites";

import Dropzone from "react-dropzone";

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.onDrop = this.onDrop.bind(this);
    this.onOpenClick = this.onOpenClick.bind(this);
  }

  onDrop(acceptedFiles) {
    const { siteId } = this.props;
    this.context.store.dispatch(uploadImages(acceptedFiles, siteId));
  }

  onOpenClick() {
    this.dropzone.open();
  }

  render() {
    return (
      <div className={css(styles.fileUploadContainer)}>
        <Dropzone
          className={css(styles.upLoadIconContainer)}
          ref={node => {
            this.dropzone = node;
          }}
          onDrop={this.onDrop}
          multiple={true}
        >
          <div className={css(styles.plusIcon) + " fa fa-4x fa-plus"} />
        </Dropzone>
      </div>
    );
  }
}

FileUpload.contextTypes = {
  store: PropTypes.object
};

export default FileUpload;
