import _app from './app';
import _footer from './modules/footer';
import {header} from './modules/header';
import _sectionTitles from './modules/section-title';
import _landingPage from './landing-page';
import _productGrid from './modules/product-grid';
import _productListing from './modules/product-listing';
import _themes from './themes';
import _chatWidget from './modules/chat-widget';

import _Elements from './elements/index';
import _heartOfGold from './modules/heart-of-gold';
import _widgets from './widgets';
import _poweredByConvertly from './modules/powered-by-convertly';
import _ecommerce from './ecommerce'
import _scrollToTop from './modules/scroll-to-top';
import _accountPage from './account-page';
import _errorPages from './modules/error-pages';
import _convertlySlider from './sliders'
import _convertlyWidgets from './convertly-components'
import {Pixels} from './modules/pixels';
import _structure from './structure/index';
import {SiteHeader} from './site-header';
import {SiteFooter} from './site-footer';
import {Blog} from './blog';
import {SouljaBoy} from './modules/soulja-boy';
import {rAndD} from './r-d'
import {InfernoUis} from './inferno-ui-lookup';
import SVG from './svg'
import {ContestManager} from "./ContestManager/ContestManager";

export const Components = module.exports = {
    app: _app,
    sectionTitle: _sectionTitles,
    header,
    footer: _footer,
    landingpage: _landingPage,
    productGrid: _productGrid,
    productListing: _productListing,
    themes: _themes,
    chatWidget: _chatWidget,
    Elements: _Elements,
    heartOfGold: _heartOfGold,
    Widgets: _widgets,
    poweredByConvertly: _poweredByConvertly,
    ecommerce: _ecommerce,
    scrollToTop: _scrollToTop,
    errorPages: _errorPages,
    accountPage: _accountPage,
    convertlySlider: _convertlySlider,
    convertlyWidgets: _convertlyWidgets,
    pixels: Pixels,
    structure: _structure,
    SiteHeader,
    SiteFooter,
    Blog,
    SouljaBoy,
    SVG,
    ContestManager
};
