import React, { Component } from "react";
import PropTypes from "prop-types";
import { css } from "@convertly/css";
import styles from "../css-storefront-styles";
import styled from "styled-components";

const Icon = styled.i`
  font-size: 24px;
  display: inline-block;
  position: relative;
  line-height: 15px;
`;

const Spinner = () => <Icon className="fa fa-spin fa-spinner" />;

export const CancelSaveButtons = ({
  leftButtonLabel,
  rightButtonLabel,
  cancelAction,
  saveAction,
  processing,
  disabled
}) => (
  <div>
    <div className={css(styles.galleryCollectionButton)} onClick={cancelAction}>
      {leftButtonLabel}
    </div>
    <div
      className={css(
        styles.galleryCollectionButton,
        styles.galleryCollectionButtonGreen,
        (processing || disabled) && styles.galleryCollectionButtonDisabled
      )}
      onClick={processing || disabled ? null : saveAction}
    >
      {processing ? <Spinner /> : rightButtonLabel}
    </div>
  </div>
);

CancelSaveButtons.propTypes = {
  disabled: PropTypes.bool,
  processing: PropTypes.bool,
  rightButtonLabel: PropTypes.string,
  leftButtonLabel: PropTypes.string,
  saveAction: PropTypes.func,
  cancelAction: PropTypes.func
};

CancelSaveButtons.defaultProps = {
  disabled: false,
  processing: false,
  rightButtonLabel: "Save",
  leftButtonLabel: "Cancel",
  saveAction: () => {},
  cancelAction: () => {}
};
