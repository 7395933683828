import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { setElements } from "containers/Editor/actions/editorActions";
import { Slider } from "./slider/Slider";
import { SectionWrapper } from "./Layout";
import { assign } from "containers/Editor/assign";
import { assignUserOverridesValue } from "containers/Editor/assignUserOverridesValue";
import CONSTANTS from "containers/Editor/constants";
// During drag manually alters css
// When dragging is complete, update userOverrides and pageElements
// with updated value

// Use state and cssValue to make React re-render the slider
// when values have changed

export class EditCSSValueSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cssValue: "0",
    };

    this.update = this.update.bind(this);
    this.updateElements = this.updateElements.bind(this);
    this.init = this.init.bind(this);
  }

  componentDidMount(props) {
    // need to wrap in timeout so the page elements have rendered
    setTimeout(this.init, 0, props || this.props);
  }

  init(props) {
    const obj = this.getObject(props);
    let cssValue = this.getCssValue(obj, props);

    if (typeof cssValue === "undefined") {
      // need to do this for default css styles from base stylesheet
      // headings with default margins that don't have userOverrides
      cssValue = this.getComputedValue();
    }

    // edge case for letterSpacing which has default value in styles of ""
    if (cssValue === "") {
      cssValue = this.getComputedValue();
    }

    this.setState({ cssValue });
  }

  getObject(props) {
    let obj;
    if (!props.objectBeingEdited.data) {
      props.objectBeingEdited.menu.userOverrides = props.objectBeingEdited.menu.userOverrides || {};
      obj = props.objectBeingEdited.menu.userOverrides;
    } else {
      props.objectBeingEdited.data.userOverrides = props.objectBeingEdited.data.userOverrides || {};
      obj = props.objectBeingEdited.data.userOverrides;
    }
    return obj;
  }

  getCssValue(obj, props) {
    let cssValue;
    switch (props.editorViewportWidth) {
      case "mobile":
        cssValue = _.get(obj, `[${CONSTANTS.mobile}][${props.field}]`);
        break;
      case "smallTablet":
        cssValue = _.get(obj, `[${CONSTANTS.smallTablet}][${props.field}]`);
        break;
      case "largeTablet":
        cssValue = _.get(obj, `[${CONSTANTS.largeTablet}][${props.field}]`);
        break;
      default:
        cssValue = obj[props.field];
    }
    return cssValue;
  }

  // Update the values if we switch to a different element
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.objectBeingEdited.id !== this.props.objectBeingEdited.id ||
      nextProps.editorViewportWidth !== this.props.editorViewportWidth
    ) {
      this.componentDidMount(nextProps);
    }

    const obj = this.getObject(nextProps);
    let cssValue = this.getCssValue(obj, nextProps);

    if (!cssValue) {
      return;
    }

    const equal = cssValue.replace("px", "") === this.state.cssValue.replace("px", "");

    if (!equal) {
      this.componentDidMount(nextProps);
    }
  }

  getComputedValue() {
    const id = this.context.store
      .getState()
      .get("editor")
      .editorReducer.getIn(["editorType", "field"]);
    const el = document.querySelector("[data-editor=c" + id + "]");
    let value = "";
    if (el) {
      const styles = getComputedStyle(el);
      const computedValue = styles[this.props.field];
      if (computedValue) {
        value = computedValue;
      } else {
        value = this.props.defaultValue;
      }
    }
    return value;
  }

  // onEnd handler
  updateElements() {
    let obj, obj2;

    if (this.props.update) {
      return;
    }

    // data does not exist if we are editing a navigation attribute
    // objectBeingEdited is passed in as this.props.objectBeingEdited.data
    // TODO Handle this more gracefully. Doesn't seem very maintable or scalable.
    //later note from someone else, no it wasn't not at all....
    if (!this.props.objectBeingEdited.data) {
      obj = _.get(this.props.objectBeingEdited, "menu");
      obj2 = _.get(this.props.objectBeingEdited, "dropdown");
    } else {
      obj = this.props.objectBeingEdited;
    }
    const value = this.state.cssValue + this.props.unit;
    assignUserOverridesValue({
      objectBeingEdited: obj,
      field: this.props.field,
      editorViewportWidth: this.props.editorViewportWidth,
      value,
    });
    if (obj2) {
      assignUserOverridesValue({
        objectBeingEdited: obj2,
        field: this.props.field,
        editorViewportWidth: this.props.editorViewportWidth,
        value,
      });
    }

    this.context.store.dispatch(setElements(this.props.pageElements, true));

    const id = this.props.objectBeingEdited.id;
    const el = document.querySelector("[data-editor=c" + id + "]");

    if (el) {
      const property = this.props.field.replace(/([A-Z])/g, g => `-${g[0].toLowerCase()}`);

      el.style.removeProperty(property);
    }
    delete window.DontRunCallback;
  }

  update(value) {
    if (!window.DontRunCallback) {
      this.props.callback();
      window.DontRunCallback = true;
    }
    this.setState({
      cssValue: value.toString(),
    });

    if (!this.props.objectBeingEdited.menu) {
      const id = this.props.objectBeingEdited.id;
      const el = document.querySelector("[data-editor=c" + id + "]");
      const saturn = document.querySelector(`[data-editor=saturn]`);
      const page = document.querySelectorAll("[data-pagewidth]")[0];
      let elStyle;
      el.style[this.props.field] = value + this.props.unit;
      elStyle = getComputedStyle(el);
      if (saturn) {
        saturn.style.height = `${Number(elStyle.height.slice(0, -2)) + 40}px`;
        saturn.style.width = `${Number(elStyle.width.slice(0, -2)) + 40}px`;
        saturn.style.left = `${el.getBoundingClientRect().left -
          page.getBoundingClientRect().left -
          20}px`;
      }
    }
  }

  render() {
    const {
      min,
      max,
      label,
      sliderHandleStyle,
      sliderBarStyle,
      hideLabel,
      sectionWrapperStyle,
      twelveGridMarks,
    } = this.props;
    const { cssValue } = this.state;

    if (typeof cssValue === "undefined") {
      return null;
    }
    const value = Number(cssValue.replace(this.props.unit, ""));

    return (
      <SectionWrapper style={sectionWrapperStyle || {}}>
        {label && <div style={{ width: "50%", paddingTop: "20px" }}>{label}</div>}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row-reverse",
            paddingTop: "20px",
          }}
        >
          <Slider
            sliderBarStyle={sliderBarStyle}
            sliderHandleStyle={sliderHandleStyle}
            onEnd={this.updateElements}
            update={this.props.update || this.update}
            value={typeof this.props.value === "number" ? this.props.value : value}
            twelveGridMarks={twelveGridMarks}
            min={min}
            max={max}
          />
        </div>
      </SectionWrapper>
    );
  }
}

EditCSSValueSlider.defaultProps = {
  unit: "",
  max: 10,
  min: 0,
  field: "margin-top",
  objectBeingEdited: {},
  pageElements: [],
  label: "",
  defaultValue: null,
  callback: () => {},
};

EditCSSValueSlider.propTypes = {
  unit: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  field: PropTypes.string,
  objectBeingEdited: PropTypes.object,
  pageElements: PropTypes.array,
  label: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  callback: PropTypes.func,
};

EditCSSValueSlider.contextTypes = {
  store: PropTypes.object,
};
