import _ from "lodash";
import { graph } from "utils";
import { store } from "app/app";
import { updateUser as fetchUseDataAndSaveToStore } from "containers/Authentication/actions";

export const loadUserData = async () => {
  const Auth = store.getState().get("auth");
  const accessToken = Auth.get("token");

  await store.dispatch(fetchUseDataAndSaveToStore(accessToken));
};

export const updateUser = async ({ id, user, fields }) => {
  const query = `
    mutation($id:ID!,$user:accountInput){
      editAccount(id:$id,account:$user){
        id
        ${_.isString(fields) ? fields : ""}
        ${_.isArray(fields) ? fields.join(",") : ""}
      }
    }
  `;

  const variables = {
    id,
    user,
  };

  const res = await graph({ query, variables });

  return _.get(res, "editAccount") || {};
};

export const fetchUser = async ({ id, fields }) => {
  const query = `
    query($id:ID!) {
      Account(id:$id) {
        id
        ${_.isString(fields) ? fields : ""}
        ${_.isArray(fields) ? fields.join(",") : ""}
      }
    }
  `;

  const variables = {
    id,
  };

  const res = await graph({ query, variables });

  return _.get(res, "Account") || {};
};

/**
 * This function was written for original onboarding logic.
 * We can refactor it for new user SaaS flow.
 */
export const shouldDoOnboarding = user => {
  const roles = user.roles || [];

  if (!roles.length || -1 !== _.findIndex(roles, role => role === "normal")) {
    if (!user.completedOnBoarding) {
      return true;
    }
  }

  return false;
};

export const userHomeScreen = user => {
  const sites = u => {
    const userSites = _.get(u, "sites");
    return _.isArray(userSites) ? userSites.length : 0;
  };

  if (sites(user) > 1) {
    return "/partner";
  }

  return "/dashboard";
};
