export const displayOptions = [
  {
    text: "Desktop",
    key: "",
    icon:
      "https://s3.amazonaws.com/convertlyimguploadeast/convertly/ui-components/assets/icons/desktop.svg",
  },
  {
    text: "Tablet (landscape)",
    key: "largeTablet",
    icon:
      "https://s3.amazonaws.com/convertlyimguploadeast/convertly/ui-components/assets/icons/tablet_white_landscape.svg",
  },
  {
    text: "Tablet (portrait)",
    key: "smallTablet",
    icon:
      "https://s3.amazonaws.com/convertlyimguploadeast/convertly/ui-components/assets/icons/tablet.svg",
  },
  {
    text: "Phone",
    key: "mobile",
    icon:
      "https://s3.amazonaws.com/convertlyimguploadeast/convertly/ui-components/assets/icons/phone_white.svg",
  },
];
