import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

// This needs to be a class so ref is available
// https://stackoverflow.com/questions/37266411/react-stateless-component-this-refs-value

const DEFAULT_HANDLE_STYLES = {
  height: "20px",
  width: "20px",
  backgroundImage: "linear-gradient(-180deg, #80C3F3 0%, #4A90E2 100%)",
  borderRadius: "50%",
};

export class SliderHandle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      transformY: "13px",
      styles: {
        ...DEFAULT_HANDLE_STYLES,
      },
    };
  }

  componentWillMount() {
    const { sliderHandleStyle } = this.props;

    if (sliderHandleStyle) {
      this.setState({
        transformY: sliderHandleStyle.transform ? "0px" : "8px",
        styles: {
          height: sliderHandleStyle.height,
          width: sliderHandleStyle.width,
          backgroundColor: sliderHandleStyle.backgroundColor,
          backgroundImage: "",
        },
      });
    }
  }

  render() {
    const { sliderHandleStyle } = this.props;

    let transform = `translate(${this.props.x}px, ${this.state.transformY})`;
    return (
      <div
        ref={el => (this.el = el)}
        style={{
          transform: transform,
          borderRadius: "50%",
          ...this.state.styles,
        }}
      />
    );
  }
}

SliderHandle.defaultProps = {
  x: 0,
};

SliderHandle.propTypes = {
  x: PropTypes.number,
};
