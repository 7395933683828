import React from "react";
import Component from "source/rootComponent";
import { css } from "@convertly/css";
import _ from "lodash";

// Style
import { BlogCollectionHeaderTextStyles } from "./BlogCollectionHeaderTextStyles";

export class BlogCollectionHeaderText extends Component {
  render() {

    const { data, inEditor, global, blogData } = this.props;

    if(_.isEmpty(blogData)) {
      return null;
    }

    const inlineStyle = this.generateStyleAttribute();
    const firstAuthorId = _.get(blogData[0], "author.id");
    const sameAuthor = blogData.every(getCategoryAndAuthor);

    let arrayOfAllCategories = [];
    arrayOfAllCategories = [...new Set(arrayOfAllCategories)];
    const numOfUniqueCategories = arrayOfAllCategories.length;

    this.styles = BlogCollectionHeaderTextStyles({ global, data, inEditor });

    function getCategoryAndAuthor(blogDataObj) {
      for (let blogDataCategory of blogDataObj.categories) {
        arrayOfAllCategories.push(blogDataCategory.name);
      }
      return firstAuthorId === _.get(blogDataObj, "author.id");
    }

    function formatMultipleCategoryText() {
      let text = "";

      for (let categoryIdx in arrayOfAllCategories) {
        const currentEl = arrayOfAllCategories[categoryIdx];

        if (categoryIdx === numOfUniqueCategories - 1) {
          text += `and ${currentEl}`;
        } else if (categoryIdx === 0) {
          text += currentEl;
        } else {
          text += `, ${currentEl}`;
        }
      }

      return text;
    }

    let authorText, categoryText;

    if (sameAuthor) {
      authorText = `AUTHOR: ${_.get(blogData[0], "author.name")}`;
    } else {
      categoryText =
        numOfUniqueCategories > 1
          ? `CATEGORIES: ${formatMultipleCategoryText()}`
          : `CATEGORY: ${arrayOfAllCategories[0]}`;
    }

    const text = `SHOWING POSTS IN ${sameAuthor ? authorText : categoryText}`;

    const { collectionHeaderText } = this.styles;

    return (
      <div
        {...inlineStyle}
        {...this.generateDataEditorAttribute()}
        {...this.generateCustomAttributes()}
        className={css(collectionHeaderText)}
      >
        {text}
      </div>
    );
  }
}
