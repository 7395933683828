import React, { useCallback, useMemo } from "react";

/**
 * custom hook to create a cached text onChange handler
 *
 * @param onChange
 * @param key
 */
export const useTextHandler = (onChange, key) => {
  const handler = useCallback(key => onChange.bind(this, key), [key, onChange]);
  return useMemo(() => handler(key), [handler]);
};
