import React, {
  useReducer,
  useCallback,
  useContext,
  createContext,
  useEffect,
  useState,
} from "react";

import { graph, getActiveUserId } from "utils";

export const VIEWS = {
  OVERVIEW: "Overview",
  EDIT_SUBSCRIPTION: "EditSubscription",
  UPDATE_PAYMENT: "UpdatePayment",
};

const initialState = {
  view: VIEWS.OVERVIEW,
  plan: null,
  card: null,
  plans: null,
  loaded: false,
};

const ACTIONS = {
  CHANGE_VIEW: "ChangeView",
  SET_PLAN: "SetPlan",
  SET_CARD: "SetCard",
  SET_LOADED: "SetLoaded",
  SET_PLANS: "SetPlans",
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.CHANGE_VIEW:
      return { ...state, view: action.payload };

    case ACTIONS.SET_PLAN:
      return { ...state, plan: action.payload };

    case ACTIONS.SET_CARD:
      return { ...state, card: action.payload };

    case ACTIONS.SET_LOADED:
      return { ...state, loaded: action.payload };

    case ACTIONS.SET_PLANS:
      return { ...state, plans: action.payload };

    default:
      return { ...state };
  }
}

export const useSubscriptionsReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return {
    ...state,
    dispatch,
  };
};

const SubscriptionsContext = createContext([]);

export const SubscriptionsProvider = ({ children }) => {
  const contextValue = useSubscriptionsReducer(reducer, initialState);
  return (
    <SubscriptionsContext.Provider value={contextValue}>{children}</SubscriptionsContext.Provider>
  );
};

export const useView = initialView => {
  const { view, dispatch } = useContext(SubscriptionsContext);

  const setView = useCallback(
    view => () => dispatch({ type: ACTIONS.CHANGE_VIEW, payload: view }),
    [view]
  );

  useEffect(() => {
    if (initialView) {
      setView(initialView)();
    }
  }, []);

  return [view, setView];
};

const fetchPaymentSource = async id => {
  const query = `
       query($id:ID!) {
          Account(id:$id) {
            paymentSource
          }
        }`;

  const variables = {
    id,
  };

  const result = await graph({ query, variables });

  try {
    const source = JSON.parse(result.Account.paymentSource);
    if (!source?.last4) {
      return null;
    }
    return source;
  } catch (e) {
    return null;
  }
};

const fetchActivePlan = async id => {
  const query = `
       query($id:ID!) {
          Account(id:$id) {
            subscription
          }
        }`;

  const variables = {
    id,
  };

  const result = await graph({ query, variables });

  try {
    const subscription = result?.Account?.subscription;

    if (subscription) {
      return JSON.parse(subscription);
    }
  } catch (e) {
    // catch the error here and return free plan
  }

  return {
    name: "Free plan",
    id: 0,
  };
};

export const useLoadData = () => {
  const { plan, card, loaded, dispatch } = useContext(SubscriptionsContext);

  const [error, setError] = useState(null);

  const refreshData = useCallback(() => {
    (async function() {
      const [paymentSource, activePlan] = await Promise.all([
        fetchPaymentSource(getActiveUserId()),
        fetchActivePlan(getActiveUserId()),
      ]);

      dispatch({ type: ACTIONS.SET_CARD, payload: paymentSource });
      dispatch({ type: ACTIONS.SET_PLAN, payload: activePlan });
      dispatch({ type: ACTIONS.SET_LOADED, payload: true });
    })();
  }, []);

  useEffect(() => {
    if (loaded) {
      return;
    }

    refreshData();
  }, []);

  let data = null;

  if (loaded) {
    data = {
      plan,
      card,
    };
  }

  return [data, error, refreshData];
};

export const useLoaded = () => {
  const { loaded, dispatch } = useContext(SubscriptionsContext);

  const setLoaded = useCallback(
    newLoaded => dispatch({ type: ACTIONS.SET_LOADED, payload: newLoaded }),
    [loaded]
  );

  return [loaded, setLoaded];
};

export const usePlans = () => {
  const { plans, dispatch } = useContext(SubscriptionsContext);

  useEffect(() => {
    if (Array.isArray(plans)) return;

    (async function() {
      const query = `query Subs {SubscriptionPlans {id, features, price, priceId, name, sort}}`;
      const variables = {};
      const { SubscriptionPlans } = await graph({ query, variables }).catch(() => []);

      if (!Array.isArray(SubscriptionPlans)) {
        dispatch({ type: ACTIONS.SET_PLANS, payload: [] });
        return;
      }

      dispatch({ type: ACTIONS.SET_PLANS, payload: [...SubscriptionPlans] });
    })();

    // const plans = [
    //   {
    //     id: "123",
    //     name: "Monthly Subscription",
    //     price: 29.99,
    //   },
    // ];
    //
    // setTimeout(() => {
    //   dispatch({ type: ACTIONS.SET_PLANS, payload: [...plans] });
    // }, 2000);
  }, []);

  return [plans];
};
