import {StyleSheet} from "@convertly/css";

const Styles = (global, data) => {

    const screenSize = {
        phillipsPhone: '@media (max-width: 767px)'
    };

    return StyleSheet.create({


        checkoutHeader: {
            backgroundColor: global.colors.dark.color1,
            padding:'20px',
            height:'100px',

        },
        checkoutHeaderContainer: {
            height:'100%',
            padding:'0 15px',
            maxWidth:'1170px',
            margin:'0 auto',
            [screenSize.phillipsPhone]: {
                textAlign:"center"
            }
        },
        checkoutLogo: {
            border: "0",
            verticalAlign: "middle",
            maxHeight:'100%',
            //maxWidth: "220px",


        }


    });

};

export default Styles;
