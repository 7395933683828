import { StyleSheet } from '@convertly/css';
import UserOverrides from '../../../elements/style-functions/user-overrides';
import { createNewCssRules } from '../../../utils/edit-userOverrides';
import _ from 'lodash';
import { generatePseudoMediaQuery } from '../../../elements/style-functions/pseudo-media-queries';

const navStyleLinkListItem = (global, data, inEditor) => {

  const navLinkOverrides = UserOverrides(global, data.menu, inEditor);
  

  let iconBorderColor = {};
  let navLinkColor = {};
  
  if(_.get(data, 'menu.userOverrides')) {
    iconBorderColor = createNewCssRules(navLinkOverrides, 'color', ['borderColor', 'backgroundColor']);
    navLinkColor = createNewCssRules(navLinkOverrides, 'color', ['color']);
  }

  return StyleSheet.create({
    selectionItem: {
      display: 'inline-block',
      backgroundColor: _.get(data, 'userOverrides.backgroundColor') || global.colors.light.white,
      color: _.get(data, 'menu.userOverrides.color') || global.colors.dark.color2,
      fontSize: '14px',
      fontStyle: 'normal',
      fontFamily: 'open sans',
      lineHeight: '19px',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)', ['smallTablet'], {
          display: 'block',
          paddingTop: '10px',
          width: inEditor ? '768px' : '100vw',
          lineHeight: '44px'
        },
        inEditor
      ),
      ...generatePseudoMediaQuery(
        '@media(max-width: 375px)', ['mobile'], {
          display: 'block',
          paddingTop: '10px',
          width: inEditor ? '375px' : '100vw',
          lineHeight: '44px'
        },
        inEditor
      ),
      '.open': {
        display: 'block'
      },
      ...navLinkColor
    },
    selectionItemLink: {
      display: 'inline-block',
      height: '100%',
      paddingLeft: '10px',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
          width: '95%',
          paddingLeft: '50px'
        },
        inEditor
      ),
      '@media(hover: hover)': {
        ':hover': {
          color: '#FF7800',
          cursor: 'pointer'
        },
      },
      ...navLinkOverrides,
      '.active': {
        textDecoration: 'underline'
      }
    },
    minusSign: {
      border: `1px solid ${global.colors.dark.color2}`,
      width: '12px',
      transform: 'translateY(7px)',
      ...iconBorderColor
    },
    plusSign: {
      border: `1px solid ${global.colors.dark.color2}`,
      height: '12px',
      width: '1px',
      transform: 'translateX(5px)',
      transition: 'all 0.3s ease-in-out 0s',
      '.turnIntoMinusSign': {
        transform: 'translateX(5px) rotate(90deg)',
      },
      ...iconBorderColor
    },
    dropdownPlusMinusSign: {
      display: 'none',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
          display: 'inline-block',
          transform: 'translate(-40px, 4%)',
        },
        inEditor
      ),
    }
  })
}

export default navStyleLinkListItem;

