

//Generate Email
export function generateEmailLink( linkData ) {
    return "mailTo:" + linkData
};

export function generatePhoneNumberLink( phoneNumber, countryCode ) {

    const phoneNumberString = phoneNumber.replace(/\(|\)|\-|\s/gi, '');
    return "tel:" + phoneNumberString;

}
