import React from 'react';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';
import _ from 'lodash';
import generateAddressString from '../../../utils/generateAddressString';

//scripts
import toggleDropDown from '../../scripts/toggle-dropdown';


//Components
import Elements from '../../../elements'
import { DropdownNavLink04 } from './drop-down-nav-item-04';
import SVG from '../../../svg';
import WebLink from '../../../elements/links/web-link';

export class NavLink04 extends Component {

    constructor(props) {

        super(props);

    }

    findLocations = (locations) => {

        let locationIndex = _.findIndex(locations, function (o) {
            return o.primaryLocation = true;
        });

        return locationIndex || 0

    };


    render() {

        let navItem;
        let toggleId = id(toggleDropDown);

        const {data, styles, global, site} = this.props;

        let activeClass = '';
        if (this.props.page) {
            if (this.props.page.slug === data.url) {
                activeClass = ' active';
            }
        }

        if (data.dropDownNav) {
            navItem =
                <ul className={ css(styles.dropdown) }>
                    <li className={ css(styles.navListItem, styles.navListItemLink)}
                        id={ "dropDownItem" + this.props.index }
                        data-dropdown={toggleId}
                        data-target={ "#dropDownItem" + this.props.index }>
                        <span className={ `${css(styles.navListItemText)} ${activeClass}` }
                              id={ `dropdown${toggleId}` }>
                        { data.text }
                            <SVG.ArrowHeadOpenDown attributes={ {"data-dropdown": toggleId} }
                                                   className={ styles.caret }/>
                        </span>
                        <ul className={ css(styles.dropdownList) }
                            id={ "dropDownItem" + this.props.index }
                            data-dropdown={toggleId}
                            data-category="dropdown">
                            <div className={ css(styles.arrowUp) }>
                                <div className={ css(styles.innerArrow) }/>
                            </div>
                            { data.dropDownNav.map((item, index) => {

                                return (
                                    <DropdownNavLink04 key={ index }
                                                     global={ global }
                                                     data={ item }
                                                     styles={ styles }
                                                     id={ "dropDownItem" + this.props.index }
                                                     index={ index }
                                    />
                                )
                            })
                            }
                        </ul>
                    </li>
                </ul>;
        }

        else if (data.contactLink) {

            let locations = _.get(site, 'locations'),

                pl = 0,
                displayEmail = _.get(data, 'emailLinkSettings.display'),
                displayAddress = _.get(data, 'addressLinkSettings.display'),
                displayPhone = _.get(data, 'phoneLinkSettings.display'),
                emailGaEvent = _.get(data, 'emailLinkSettings.gaEvent') || {},
                phoneGaEvent = _.get(data, 'phoneLinkSettings.gaEvent') || {},
                addressGaEvent = _.get(data, 'addressLinkSettings.gaEvent') || {};


            if (locations && locations.length > 0) {
                if (displayEmail || displayAddress || displayPhone) {

                    pl = this.findLocations(locations);
                    let primeLocation = locations[pl];

                    navItem =
                        <ul className={ css(styles.dropdown) }>
                            <li className={ css(styles.navListItem, styles.navListItemLink) }
                                id={ id(toggleDropDown) }
                                data-target="#contactDropDown">
                            <span id={ `dropdown${toggleId}` }
                                  className={ `${css(styles.navListItemText)} ${activeClass}` }>

                        { data.text }
                                <SVG.ArrowHeadOpenDown attributes={ {"data-dropdown": toggleId} }
                                                       className={ styles.caret }/>

                        </span>

                                <ul className={ css(styles.contactDropdownList) }
                                    data-category="dropdown"
                                    data-dropdown={toggleId}
                                    id="contactDropDown">
                                    <div className={ css(styles.arrowUp) }>
                                        <div className={ css(styles.innerArrow) }/>
                                    </div>

                                    <span className={ css(styles.contactListHeader) }>Contact Us</span>
                                    { /** Address **/  }
                                    { primeLocation.address && displayAddress &&
                                    <li className={ css(styles.downNavItem) }>
                                        <span className={ css(styles.dropdownIconContainer) }>

                                            <svg
                                                className={ css(styles.menuIcon) }
                                                viewBox="0 0 27 25"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g id="Pages" stroke="none">
                                                    <g id="stridebar_2_callModal"
                                                       transform="translate(-400.000000, -568.000000)">
                                                        <g id="Call-Us-Active"
                                                           transform="translate(0.000000, 93.000000)">
                                                            <g id="ic_business_black_48px"
                                                               transform="translate(398.000000, 472.000000)">
                                                                <path
                                                                    d="M15.5,9.04166667 L15.5,3.875 L2.58333333,3.875 L2.58333333,27.125 L28.4166667,27.125 L28.4166667,9.04166667 L15.5,9.04166667 Z M7.75,24.5416667 L5.16666667,24.5416667 L5.16666667,21.9583333 L7.75,21.9583333 L7.75,24.5416667 Z M7.75,19.375 L5.16666667,19.375 L5.16666667,16.7916667 L7.75,16.7916667 L7.75,19.375 Z M7.75,14.2083333 L5.16666667,14.2083333 L5.16666667,11.625 L7.75,11.625 L7.75,14.2083333 Z M7.75,9.04166667 L5.16666667,9.04166667 L5.16666667,6.45833333 L7.75,6.45833333 L7.75,9.04166667 Z M12.9166667,24.5416667 L10.3333333,24.5416667 L10.3333333,21.9583333 L12.9166667,21.9583333 L12.9166667,24.5416667 Z M12.9166667,19.375 L10.3333333,19.375 L10.3333333,16.7916667 L12.9166667,16.7916667 L12.9166667,19.375 Z M12.9166667,14.2083333 L10.3333333,14.2083333 L10.3333333,11.625 L12.9166667,11.625 L12.9166667,14.2083333 Z M12.9166667,9.04166667 L10.3333333,9.04166667 L10.3333333,6.45833333 L12.9166667,6.45833333 L12.9166667,9.04166667 Z M25.8333333,24.5416667 L15.5,24.5416667 L15.5,21.9583333 L18.0833333,21.9583333 L18.0833333,19.375 L15.5,19.375 L15.5,16.7916667 L18.0833333,16.7916667 L18.0833333,14.2083333 L15.5,14.2083333 L15.5,11.625 L25.8333333,11.625 L25.8333333,24.5416667 Z M23.25,14.2083333 L20.6666667,14.2083333 L20.6666667,16.7916667 L23.25,16.7916667 L23.25,14.2083333 Z M23.25,19.375 L20.6666667,19.375 L20.6666667,21.9583333 L23.25,21.9583333 L23.25,19.375 Z"
                                                                    id="Shape"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>

                                        </span>
                                        <span className={  css(styles.contactLinkContainer) }>
                                    <Elements.WebLink data={ {
                                        link: {
                                            linkType: 'googleMap',
                                            url: generateAddressString(primeLocation),
                                            gaEvent: addressGaEvent
                                        },
                                        text: `${primeLocation.address} ${ primeLocation.city }, ${primeLocation.state} ${primeLocation.country} ${ primeLocation.zip }`
                                    } }/>
                                </span>
                                    </li>
                                    }
                                    { /** Phone **/  }
                                    { primeLocation.phone && displayPhone &&
                                    <li className={ css(styles.downNavItem) }>

                                                            <span className={ css(styles.dropdownIconContainer) }>
                                                                <svg className={ css(styles.menuIcon) }
                                                                     viewBox="0 0 24 19"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <g id="Pages" strokeWidth="1" fillRule="evenodd">
                                                                        <g id="03---downloads_selected"
                                                                           transform="translate(-46.000000, -118.000000)">
                                                                            <g id="[0]-StrideBar">
                                                                                <g id="Call-Us"
                                                                                   transform="translate(0.000000, 93.000000)">
                                                                                    <g id="Group-8"
                                                                                       transform="translate(25.000000, 23.000000)">
                                                                                        <g id="ic_ring_volume_white_48px"
                                                                                           transform="translate(21.000000, 0.000000)">
                                                                                            <g id="Group">
                                                                                                <path
                                                                                                    d="M23.71,16.67 C20.66,13.78 16.54,12 12,12 C7.46,12 3.34,13.78 0.29,16.67 C0.11,16.85 0,17.1 0,17.38 C0,17.66 0.11,17.91 0.29,18.09 L2.77,20.57 C2.95,20.75 3.2,20.86 3.48,20.86 C3.75,20.86 4,20.75 4.18,20.58 C4.97,19.84 5.87,19.22 6.84,18.73 C7.17,18.57 7.4,18.23 7.4,17.83 L7.4,14.73 C8.85,14.25 10.4,14 12,14 C13.6,14 15.15,14.25 16.6,14.72 L16.6,17.82 C16.6,18.21 16.83,18.56 17.16,18.72 C18.14,19.21 19.03,19.84 19.82,20.57 C20,20.75 20.25,20.85 20.52,20.85 C20.8,20.85 21.05,20.74 21.23,20.56 L23.71,18.08 C23.89,17.9 24,17.65 24,17.37 C24,17.1 23.89,16.85 23.71,16.67 L23.71,16.67 Z M21.16,6.26 L19.75,4.85 L16.19,8.4 L17.6,9.81 C17.6,9.81 21.05,6.29 21.16,6.26 L21.16,6.26 Z M13,2 L11,2 L11,7 L13,7 L13,2 Z M6.4,9.81 L7.81,8.4 L4.26,4.84 L2.84,6.26 C2.95,6.29 6.4,9.81 6.4,9.81 L6.4,9.81 Z"
                                                                                                    id="Shape"></path>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                        <span className={ css(styles.contactLinkContainer) }>
                                                                    <Elements.WebLink data={ {
                                                                        link: {
                                                                            linkType: 'phoneNumber',
                                                                            url: primeLocation.phone,
                                                                            gaEvent: phoneGaEvent

                                                                        },
                                                                        text: primeLocation.phone
                                                                    } }/>
                                                                </span>
                                    </li>
                                    }
                                    { /** Email **/  }
                                    { primeLocation.emailAddress && displayEmail &&
                                    <li className={ css(styles.downNavItem) }>
                                                            <span className={ css(styles.dropdownIconContainer) }>
                                                                <svg className={ css(styles.menuIcon) }
                                                                     viewBox="0 0 1792 1792"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z"/>
                                                                </svg>
                                                            </span>
                                        <span className={ css(styles.contactLinkContainer) }>
                                                                    <Elements.WebLink data={ {
                                                                        link: {
                                                                            linkType: 'email',
                                                                            url: primeLocation.emailAddress,
                                                                            gaEvent: emailGaEvent
                                                                        },
                                                                        text: primeLocation.emailAddress
                                                                    } }/>
                                                                </span>
                                    </li>
                                    }

                                </ul>
                            </li>
                        </ul>
                }
            }
            else navItem = null
        }

        else {
            navItem =
                <li className={ css(styles.navListItem) + activeClass}>
                    <span className={ `${css(styles.navListItemText)} ${activeClass}` }>
                        <Elements.WebLink { ...this.props } editable={false}/>
                    </span>
                </li>

        }

        return (
            <span className={ css(styles.navItemContainer) }>
                {navItem}
            </span>
        )
    }
}

