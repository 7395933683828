import { StyleSheet } from "@convertly/css";
import UserOverrides from "../../elements/style-functions/user-overrides";
import removeNestedUserOverrides from "../../site-header/site-header/utils/removeNestedUserOverrides";

export const AuthorInfoStyles = ({ global, data, inEditor }) => {
  const { userOverrides } = data || {},
        authorInfo = userOverrides ? removeNestedUserOverrides({ data }) : {},
        { authorAvatar, authorName, authorWrapper, authorBio, authorLink } = userOverrides || {};

  return StyleSheet.create({
    authorInfoStyle: {
      ...UserOverrides(global, { userOverrides: authorInfo }, inEditor)
    },
    authorWrapper: {
      display: "flex",
      "align-items": "center",
      ...UserOverrides(global, authorWrapper, inEditor)
    },
    authorAvatar: {
      width: "50px",
      height: "50px",
      "border-radius": "50px",
      ...UserOverrides(global, authorAvatar, inEditor)
    },
    authorName: {
      ...UserOverrides(global, authorName, inEditor)
    },
    authorBio: {
      ...UserOverrides(global, authorBio, inEditor)
    },
    authorLink: {
      ...UserOverrides(global, authorLink, inEditor)
    }
  });
};

