import React from "react";
import PropTypes from "prop-types";
import Component from "../../rootComponent";
import { css } from "@convertly/css";
import defaultGlobal from "../../themes/global.js";

//Styles
import moduleWrapperStyles from "./module-wrapper-styles";

//Utils
import generateId from "../../utils/generate-id";

import Elements from "../index";

/**
 * Class controls imports style functions that allows for user to override the css in the CMS editor for their site.
 **/
class ModuleWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elementEditableBorder: "",
    };
    this.removeWrapperOutline = this.removeWrapperOutline.bind(this);
    this.addWrapperOutline = this.addWrapperOutline.bind(this);
  }
  getSnapshot() {
    super.getSnapshot();
    this.deeplog(this.refs);
  }

  removeWrapperOutline(e) {
    if (
      !this.context.store
        .getState()
        .get("editor")
        .editorReducer.get("activeModuleId")
    ) {
      this.setState({
        elementEditableBorder: "",
      });
      e.hoverOverChild = false;
    } else if (
      this.context.store
        .getState()
        .get("editor")
        .editorReducer.get("editorType")
        .get("field") !== this.props.id
    ) {
      this.setState({
        elementEditableBorder: "",
      });
      e.hoverOverChild = false;
    }
  }

  /**
   * Removes the style that outlines element
   **/
  addWrapperOutline(e) {
    if (!e.hoverOverChild) {
      if (this.state.elementEditableBorder === "6px dashed #F5A623")
        this.setState({
          elementEditableBorder: "6px dashed #F5A623 ",
        });
      else
        this.setState({
          elementEditableBorder: "6px dashed #F5A623",
        });
    }
  }

  render() {
    const inEditor = this.state.inEditor;

    /**
     * Calls stylesheet function
     **/
    this.styles = moduleWrapperStyles(this.props.global, this.props.data, inEditor);

    let elementEditableBorder = {};
    if (!this.props.notEditable) {
      if (this.state.inEditor) {
        elementEditableBorder.outline = this.state.elementEditableBorder;
      }
    }

    let className;

    className = css(this.props.parentClassName, this.styles.moduleStyles);

    if (this.props.defaultClass) {
      className += " " + this.props.defaultClass;
    }
    /** Clones children and assigns them props **/
    function renderChildren(props) {
      return React.Children.map(props.children, child => {
        return React.cloneElement(child, {
          moduleId: props.moduleId,
          moduleSort: props.moduleSort,
          styles: props.styles,
        });
      });
    }

    /** Background video functionality **/
    let backgroundVideo = null;
    if (this.props.data.backgroundVideo) {
      backgroundVideo = (
        <div className={css(this.styles.styles)}>
          <Elements.VideoBG site={this.props.site} data={this.props.data.backgroundVideo} />
        </div>
      );
    }
    let overlay = null;
    if (this.props.data.overlay) {
      overlay = <div className={css(this.styles.overlay)} />;
    }

    // This code does not work in React v16.8
    // I do not know what it does
    // but needs to be fixed for future versions of React
    try {
      if (this.inEditor()) {
        if (this.state.elementEditableBorder)
          this._reactInternalInstance._hostParent._hostNode.style.outline = "none";
        else this._reactInternalInstance._hostParent._hostNode.style.outline = "";
      }
    } catch (e) {}

    const inlineStyle = this.generateStyleAttribute();
    const { animated } = this.props.data;
    if (this.animatedClassName) {
      className += " " + this.animatedClassName;
    }

    if (backgroundVideo || overlay) {
      return (
        <div
          {...this.generateDataEditorAttribute()}
          {...this.generateCustomAttributes()}
          onClick={e => {
            this.launchElementalEditor("background", e);
          }}
          onMouseOver={this.addWrapperOutline}
          onMouseOut={this.removeWrapperOutline}
          className={className}
          style={elementEditableBorder}
          {...generateId(this.props.divId)}
        >
          {backgroundVideo}
          {overlay}
          <div className={css(this.styles.convertlyContentArea)}>{renderChildren(this.props)}</div>
        </div>
      );
    } else {
      return (
        <div
          {...this.generateDataEditorAttribute()}
          {...this.generateCustomAttributes()}
          className={className}
          style={elementEditableBorder}
          {...inlineStyle}
          data-animated={animated}
          {...generateId(this.props.divId)}
        >
          {renderChildren(this.props)}
        </div>
      );
    }
  }
}

/**
 * Default Props for rendering when no data is being passed
 **/
ModuleWrapper.defaultProps = {
  data: {},
  global: defaultGlobal,
};

/**
 * Connects element to context for cms editor
 **/
ModuleWrapper.contextTypes = {
  store: PropTypes.object,
};

export default ModuleWrapper;

/**
 Component BoilerPlate

 <Elements.ModuleWrapper
 parentClassName={ this.styles.heroSection }
 data={ this.props.data }
 global={ this.props.global }
 elementDataName="backgroundColor"
 moduleId={ this.props.id }
 moduleSort={ this.props.sort }>

 **/
