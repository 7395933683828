import _ from "lodash";

import { graph } from "utils";
import { store } from "app/app";
import { getBlogPanelState } from "../actions";
import { setPageData } from "containers/Editor/actions/editorActions";

export const createTemplate = async template => {
  const query = `
  mutation($data: SiteTemplateInput){
    createSiteTemplate(inputsForSiteTemplate: $data){
      id
    }
  }
  `;

  const variables = {
    data: template,
  };

  const res = await graph({ query, variables });

  return _.get(res, "createSiteTemplate.id");
};

export const updateTemplate = async template => {
  const query = `
  mutation($data: SiteTemplateInput){
    updateSiteTemplate(inputsForSiteTemplate: $data){
      id
    }
  }
  `;

  const variables = {
    data: template,
  };

  const res = await graph({ query, variables });

  return _.get(res, "updateSiteTemplate.id");
};

export const fetchGlobalTemplate = async id => {
  const query = `
    query($id:String!) {
      GlobalTemplate(id:$id) {
        id, elements
      }
    }
  `;

  const variables = {
    id,
  };

  const res = await graph({ query, variables });
  const template = _.get(res, "GlobalTemplate");
  template.elements = JSON.parse(template.elements);
  return template;
};

export const fetchTemplate = async templateId => {
  const query = `
    query($templateId:String!) {
      SiteTemplate(id:$templateId) {
        id, name, type, usage, version, versionTableLookupId, thumbnail, elements, siteId, globalTemplateId
      }
    }
  `;

  const variables = {
    templateId,
  };

  const res = await graph({ query, variables });
  const template = _.get(res, "SiteTemplate");
  template.elements = JSON.parse(template.elements);
  return template;
};

export const getPageTemplateId = async pageId => {
  const query = `
    query($pageId:ID!) {
      Page(id: $pageId) {
        templateId
      }
    }
  `;

  const variables = {
    pageId,
  };

  const res = await graph({ query, variables });

  return _.get(res, "Page.templateId");
};

export const getTemplateKeyFromType = itemType => {
  switch (itemType) {
    case "blogMaster":
      return "blogLandingDefaultTemplate";

    case "blogPost":
      return "blogPostDefaultTemplate";

    case "blogCategory":
      return "defaultCategoryTemplate";

    default:
      return "";
  }
};

export const fetchGlobalTemplates = async ({ type }) => {
  const query = `
  query GlobalTemplates($type:String) {
    GlobalTemplates(type:$type) {
      id, version, type, name, thumbnail
    }
  }
  `;

  const variables = {
    type,
  };

  const res = await graph({ query, variables });

  const templates = _.get(res, "GlobalTemplates") || [];

  return templates
    .filter(template => template.type === type)
    .map(template => ({ ...template, isGlobal: true }));
};

export const fetchSiteTemplates = async ({ siteId, type }) => {
  const query = `
  query SiteTemplates($siteId: ID!) {
    SiteTemplates(siteId: $siteId) {
      id
      name
      type
      usage
      version
      versionTableLookupId
      thumbnail
      siteId
      globalTemplateId
    }
  }
  `;

  const variables = {
    siteId,
  };

  const res = await graph({ query, variables });

  const templates = _.get(res, "SiteTemplates") || [];

  return templates.filter(template => template.type === type);
};

export const cloneTemplate = async ({ siteId, templateId }) => {
  const query = `
    mutation($siteId:ID, $templateId:ID){
      cloneFromGlobalTemplate(siteId:$siteId, globalTemplateId: $templateId){
        id
      }
    }
  `;

  const variables = {
    siteId,
    templateId,
  };

  const res = await graph({ query, variables });

  return _.get(res, "cloneFromGlobalTemplate.id");
};
