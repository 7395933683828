import React from 'react';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import Link from '../../../elements/links/web-link';
import { clientImage } from '../../../utils/generateImage';

//Default Data
import defaultData from '../footer-default-data';
import defaultGlobal from '../../../themes/global';

//Styles
import footerStyles from './footer-styles';

//Components
import FooterNav1 from '../components/footer-nav-list-1';
import Nav from './components/nav';
import Widgets from '../../../widgets';
import Elements from 'source/elements/index';

//Attributes
import SocialIcons1 from '../../../widgets/social-icons/no-background-social-icons/social-icon-list-1';

//utils
import { generateLink } from '../../../utils/generateLink';

export default class Footer extends Component {

    constructor(props) {

        super(props);

    }

    render() {

        this.styles = footerStyles(this.props.global, this.props.data);

        if (this.props.editor) {
            return this.editor();
        }

        const footerNavLinks = this.props.data.footerNavLinks || [];

        const { paragraph, socialIcons } = this.props.data;

        const text = paragraph ? paragraph.text : "";

        const copyRightLinks = this.props.data.copyRightLinks || [];


        return (

        <Elements.Container>
            <Elements.Row>

            <footer className={ css(this.styles.footer) + " footer08" }>

                <div className={ css(this.styles.navArea) }>

                     <Nav global={this.props.global}
                        data={this.props.data}
                        styles={this.styles}
                        page={ this.props.page }
                        site={this.props.site}/> 

                    <div className={ css( this.styles.socialIcons ) }>

                        <Widgets.ConvertlySocialIcons
                            data={ this.props.data } 
                            site={ this.props.site } />

                    </div>
                    <div className={ css( this.styles.pAndtLinksContainer ) }>

                        <a className={ css( this.styles.termsLink ) }
                           href="/terms">Terms and Conditions</a>
                        <a className={ css( this.styles.privacyLink ) }
                           href="/privacy">Privacy Policy</a>
                    </div>

                </div>
            </footer>

            </Elements.Row>

        </Elements.Container>
        )
    }
}

Footer.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
