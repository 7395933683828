import styled from "styled-components";

export const ConfirmationWrapper = styled.div`
  height:100%;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-content:center;
  align-items: center;
  text-align: center;
  padding:10px;
  justify-content: space-around;`;
