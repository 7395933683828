import { StyleSheet } from '@convertly/css';
import userOverride from '../../../../elements/style-functions/user-overrides';
import removeNestedUserOverrides from '../../utils/removeNestedUserOverrides';
import { generatePseudoMediaQuery } from '../../../../elements/style-functions/pseudo-media-queries';
import _ from 'lodash';

const ecommerceStyles = ({ global, data, inEditor }) => {

  const ecommerce = removeNestedUserOverrides({ data });

  const {
    userOverrides
  } = data || {};

  const {
    cartWrapper,
    cart,
    signIn,
    signInWrapper,
    mobileCart
  } = userOverrides || {};

  const cartWrapperUserOverrides = _.get(cartWrapper, 'userOverrides') ? userOverride(global, cartWrapper, inEditor) : {};
  const cartUserOverrides = _.get(cart, 'userOverrides') ? userOverride(global, cart, inEditor) : {};
  const signInWrapperUserOverrides = _.get(signInWrapper, 'userOverrides') ? userOverride(global, signInWrapper, inEditor) : {};
  const mobileCartUserOverrides = _.get(mobileCart, 'userOverrides') ? userOverride(global, mobileCart, inEditor) : {};
  const ecommerceUserOverrides = !_.isEmpty(ecommerce) ? userOverride(global, { userOverrides: ecommerce }, inEditor) : {};
  const signInUserOverrides = _.get(signIn, 'userOverrides') ? userOverride(global, signIn, inEditor) : {};

  const defaultDisplayProperty = 'inline-block';

  return StyleSheet.create({

    ecommerce: {
      display: defaultDisplayProperty,
      fontFamily: _.get(global, 'fontFamily.secondaryFont'),
      ...ecommerceUserOverrides
    },

    cartWrapper: {
      display: defaultDisplayProperty,
      ...cartWrapperUserOverrides
    },

    cart: {
      display: defaultDisplayProperty,
      ...cartUserOverrides
    },

    signInWrapper: {
      display: defaultDisplayProperty,
      ...signInWrapperUserOverrides
    },

    signIn: {
      display: defaultDisplayProperty,
      ...signInUserOverrides
    },

    mobileCart: {
      display: 'none',
      ...mobileCartUserOverrides
    },

  })

}

export default ecommerceStyles;
