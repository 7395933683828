import React from 'react';
import Component from 'source/rootComponent';
import {css} from "@convertly/css";
import ReactHTMLParser  from '@convertly/react-html-parser';
import { id } from '@convertly/thor'

//Components
import Elements from '../../../elements/index';

//Scripts
import hoursWidget from '../scripts/hours-widget';


class Location extends Component {

    render() {
        // Conditional functions return buttons if (data)

        const { styles, location, index, rootId, activeIndex } = this.props;

        let callNow = (link) => {

            if (link.contactButtons) {

                if (link.contactButtons.callToOrder) {

                    if (link.contactButtons.callToOrder.text) {

                        if (link.contactButtons.callToOrder.url) {

                            return (
                                <a className={ css(styles.callToOrder) }
                                   href={ `tel:${link.phone}` }> { link.contactButtons.callToOrder.text }<br/>{ link.phone }
                                </a>
                            )

                        }
                    }

                }

            }

        };

        let orderDelivery = (link) => {

            if (link.contactButtons) {

                if (link.contactButtons.orderDelivery) {

                    if (link.contactButtons.orderDelivery.text) {

                        if (link.contactButtons.orderDelivery.url) {

                            return (
                                <a className={ css(styles.orderDelivery) }
                                   href={ link.contactButtons.orderDelivery.url }
                                   target="_blank"> { link.contactButtons.orderDelivery.text } </a>
                            )

                        }
                    }

                }

            }

        };

        let orderPickup = (link) => {

            if (link.contactButtons) {

                if (link.contactButtons.orderPickup) {

                    if (link.contactButtons.orderPickup.text) {

                        if (link.contactButtons.orderPickup.url) {

                            return (
                                <a className={ css(styles.orderPickup) } href={ link.contactButtons.orderPickup.url }
                                   target="_blank"> { link.contactButtons.orderPickup.text } </a>
                            )

                        }
                    }

                }

            }

        };

        return (
            <div id={ 'store_' + index } className={ `${css(styles.storeHours)} storeHours_${rootId} ${ parseInt(index) === parseInt(activeIndex) ? 'active' : ''}` }>
                <div className={ css(styles.storeName)}>   { ReactHTMLParser(location.locationName) }   </div>
                <div className={ css(styles.storeAddress)}>{ location.address }</div>
                <div
                    className={ css(styles.storeAddress2)}>{ location.city }, { location.state } { location.zip }</div>

                <Elements.GoogleMapLink
                    data={ {location: location, customLinkMessage: "View On Map"} }
                    className={ css(styles.viewOnMap) }/>

                <div className={ css(styles.hoursOfOperation) }>
                    <div id={ id(hoursWidget) }>

                        <ul className={ css(styles.hoursList) }>
                            {
                                location.businessHours.map((day, index) => {

                                    let openTime = '',
                                        closeTime = '',
                                        openStatus = false,
                                        displayOpenOrClosed = ' closed',
                                        hoursField;

                                    if (day.startHour && day.endHour) {

                                        openTime = day.startHour.concat(':', day.startMinutes, day.startPeriod),
                                            closeTime = day.endHour.concat(':', day.endMinutes, day.endPeriod),
                                            openStatus = true;
                                        displayOpenOrClosed = ' open';

                                        let currentlyOpen = (day) => {
                                            let start24,
                                                close24,
                                                currentTime = new Date(),
                                                currentHour = currentTime.getHours().toString() + currentTime.getMinutes();

                                            if (day.startPeriod.toUpperCase() === "AM") {
                                                start24 = day.startHour + day.startMinutes
                                            } else {
                                                start24 = +day.starHour + 12 + day.startMinutes
                                            }

                                            if (day.endPeriod.toUpperCase() === "AM") {
                                                close24 = day.endHour + day.endMinutes
                                            } else {
                                                close24 = +day.endHour + 12 + day.endMinutes
                                            }

                                            if (parseInt(currentHour) >= start24 && parseInt(currentHour) <= close24) {
                                                return 'OPEN';
                                            } else {
                                                return 'CLOSED';
                                            }

                                        };

                                        hoursField =

                                            <div
                                                className={ css(styles.displayedHours) + " displayed-hours" + displayOpenOrClosed }>

                                                                        <span className={ css(styles.hours)}>
                                                                            { `${ openTime } - ${ closeTime }` }
                                                                        </span>

                                            </div>


                                    } else {

                                        hoursField =

                                            <div
                                                className={ css(styles.displayedHours) + " displayed-hours" + displayOpenOrClosed }>

                                                                        <span className={ css(styles.openStatus)}>
                                                                            CLOSED
                                                                        </span>

                                            </div>

                                    }

                                    return (
                                        <li className={ css(styles.dayOfWeek) + " hours-launcher" }
                                            data-openinghours={ openTime }
                                            data-closinghours={ closeTime }
                                            data-dayofweek={ day.day }
                                            data-openstatus={ openStatus }
                                            key={ index }>
                                            { day.day.slice(0, 3) }
                                            { hoursField }
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>

                <div className="">

                    { callNow(location) }

                    { orderDelivery(location) }

                    { orderPickup(location) }

                </div>

            </div>
        )
    }

}

export default Location;