import React from "react";
import PropTypes from "prop-types";
import { PillButton } from "components/Button/pillButton";
import { SaveButton } from "components/Button/SaveButton";
import { ContextMenu } from "containers/Editor/components/editor/options";
import { FullWidthContainer } from "./commonComponents";

export const CouponDetailMenu = ({ dirty, hasErrors, onSave, onCancel, onDelete, processing }) => {
  const options = [
    {
      optionsItemTitle: "Delete coupon",
      optionsCallback: onDelete
    }
  ];
  return (
    <FullWidthContainer>
      <SaveButton action={onSave} processing={processing} disabled={!dirty || hasErrors} />
      <PillButton label="Cancel" action={onCancel} disabled={processing} />
      <ContextMenu disabled={processing} options={options} button />
    </FullWidthContainer>
  );
};

CouponDetailMenu.propTypes = {
  processing: PropTypes.bool,
  dirty: PropTypes.bool,
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func
};

CouponDetailMenu.defaultProps = {
  processing: false,
  dirty: false,
  onSave: () => {},
  onDelete: () => {},
  onCancel: () => {}
};
