import { generatePseudoMediaQuery } from "../../elements/style-functions/pseudo-media-queries";

export default function addToCartStyleObject(global, data, inEditor) {
	

    return {
        ".add-to-cart-qty-stepper-container": {
            width: '100%',
            textAlign: 'center'
        },
        ".qty-adjust-btn-container": {
            display: 'inline-block',
            color: global.colors.dark.color1,

            verticalAlign: 'middle'
        },
        ".qty-adjust-btn": {
            cursor: "pointer",
            height: '20px',
            width: '20px',
            fill: global.colors.dark.color1,

        },
        ".add-to-cart-button": {
            cursor: "pointer",
            display: "inline-block",
            textAlign: "center",
            width: "auto",
            padding: "15px 75px",
            background: global.colors.dark.color1,
            color: "#fff",
            fontSize: "16px",
            textTransform: "uppercase",
            fontWeight: "900",
            margin: '0 20px',
            transition:'ease 400ms',
            border:'none',
            ...generatePseudoMediaQuery(
              "@media(min-width:991px)",
              ['largeTablet', 'smallTablet', 'mobile'],
              {
                  ":hover": {
                      transition:'ease 400ms',
                      boxShadow: "0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.3)"
                  },
              },
              inEditor
            ),
            ...generatePseudoMediaQuery(
              "@media(max-width:991px)",
              ['largeTablet', 'smallTablet', 'mobile'],
              {
                  ":focus": {
                      boxShadow: "0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.3)"
                  }
              },
              inEditor
            ),
            ...generatePseudoMediaQuery(
              "@media(max-width:470px)",
              ['mobile'],
              {
                  margin: '0 10px',
                  padding:'13px 35px'
              },
              inEditor
            ),

            '.disabled': {
                opacity: '.5',
                cursor: 'default'
            }
        }
    }


}
