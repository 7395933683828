import {generatePseudoMediaQuery} from "../../elements/style-functions/pseudo-media-queries";

export default function searchBarStyleObject(global, data, inEditor) {

    const noResultsFlag = {
        '0%': {
            transform: 'rotate(0deg)',
            color: 'red',
            fontWeight: '700'
        },

        '20%': {
            transform: 'translateX(2px)',
            color: 'red',
            fontWeight: '700'
        },
        '40%': {
            transform: 'translateX(-1px)',
            color: 'red',
            fontWeight: '700'
        },

        '60%': {
            transform: 'translateX(1px)',
            color: 'red',
            fontWeight: '700'
        },
        '80%': {
            transform: 'translateX(-1px)',
            color: 'red',
            fontWeight: '700'
        },
        '100%': {
            transform: 'translateX(1px)',
            color: 'initial',
            fontWeight: 'initial'
        },
    };

    return {

        '.search-bar-container': {
            width: '100%',
            ...generatePseudoMediaQuery(
                '@media(max-width: 991px)', ['largeTablet', 'smallTablet', 'mobile'], {
                    display: 'none'
                },
                inEditor
            ),
        },

        '.search-bar-wrapper': {
            display: 'flex',
            border: `1px solid ${ global.colors.dark.color3 }`,
            padding: '0px 4px',
            width: '100%',
            'align-items': 'center',
            paddingTop: '3px',
            '.expandable': {
                border: 'none',
                paddingLeft: '85%',
                transition: 'all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s',
                '.expanded': {
                    border: `1px solid ${ global.colors.dark.color3 }`,
                    paddingLeft: '4px',
                }
            },
        },

        '.search-bar': {
            width: '100%',
            '.left': {
                order: '2'
            },
            '.right': {
                order: '1',
                width: '89%',
            },
            '.with-x-icon': {
                width: '95%',
                '.left': {
                    width: '83%'
                }
            },
        },

        '.search-icon-wrapper': {
            display: 'flex',
            '.left': {
                order: '1'
            },
            '.right': {
                float: 'right',
                order: '2',
                marginTop: '-3px',
            },
            ".enable-click": {
                cursor: 'pointer'
            }
        },

        '.search-icon': {
            width: '20px',
            height: '20px',
        },

        '.x-icon-wrapper': {
            order: '2',
            display: 'flex'
        },

        '.x-icon': {
            cursor: 'pointer',
        },

        '.search-bar-input': {
            border: 'none',
            padding: '0px 2px',
            height: '100%',
            width: '100%',
            backgroundColor: 'transparent',
            color: 'inherit',
            lineHeight: '22px',
            fontSize: '16px',
            '::-ms-clear': {
              display: 'none'
            }
        },

        '.search-bar-results-wrapper': {
            position: 'relative',
            width: '100%',
            display: 'none',
            'z-index': '22',
            '.active': {
                display: 'block'
            }
        },

        '.search-bar-results': {
            position: 'absolute',
            width: '100%',
            height: '0'
        },

        '.search-bar-product-items-list': {
            transition: 'all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s',
            position: 'absolute',
            width: '100%',
            color: 'inherit',
        },

        '.product-item': {
            display: 'block',
            padding: '4px 4px',
            border: `1px solid ${ global.colors.dark.color3 }`,
            borderTop: 'none',
            listStyle: 'none',
            cursor: 'pointer',
            backgroundColor: global.colors.light.white,
            'text-overflow': 'ellipsis',
            'overflow': 'hidden',
            'white-space': 'nowrap',
            ':hover': {
                backgroundColor: global.colors.light.color2
            },
            '.no-hover': {
                ':hover': {
                    backgroundColor: 'white',
                    cursor: 'default'
                },
            }
        },
        ".product-item-text": {
            display: 'block',
            transition: 'all .3s ease',
            ".no-results-animation": {
                animationName: [noResultsFlag],
                animationDuration: '.7s',
                animationIterationCount: '1',
            }
        },
        '.spinner': {
            height: '10%',
            width: '10%',
        },

        '.search-bar-input-wrapper': {
            position: 'relative'
        },


        '.search-bar-input:focus + .search-bar-input-placeholder': {
            marginTop: '-15px',
            fontSize: '11px',
            color: global.colors.accent.color2,
        },

        '.search-bar-input-placeholder': {
            position: 'absolute',
            left: '0',
            transition: 'all 0.3s linear',
            color: 'inherit',
            'pointer-events': 'none',
            '.has-input': {
                marginTop: '-15px',
                fontSize: '11px',
                color: global.colors.accent.color2,
            }
        },
    }
}
