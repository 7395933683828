import React from "react";
export default class TablePortraitIcon extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <svg
        style={this.props.style}
        width="15px"
        height="20px"
        viewBox="0 0 15 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.3913043,0 L2.60869565,0 C1.16804348,0 0,1.119375 0,2.5 L0,3.125 L15,3.125 L15,2.5 C15,1.119375 13.8319565,0 12.3913043,0 L12.3913043,0 Z M0,14.375 L15,14.375 L15,4.375 L0,4.375 L0,14.375 Z M7.5,16.25 C8.04,16.25 8.47826087,16.67 8.47826087,17.1875 C8.47826087,17.705 8.04,18.125 7.5,18.125 C6.96,18.125 6.52173913,17.705 6.52173913,17.1875 C6.52173913,16.67 6.96,16.25 7.5,16.25 L7.5,16.25 Z M0,17.5 C0,18.880625 1.16804348,20 2.60869565,20 L12.3913043,20 C13.8319565,20 15,18.880625 15,17.5 L15,15.625 L0,15.625 L0,17.5 L0,17.5 Z"
          id="Fill-144"
        />
      </svg>
    );
  }
}
