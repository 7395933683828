import { StyleSheet } from '@convertly/css';

const Styles = ( global, data ) => {

    return StyleSheet.create({

       featureListContainer: {
            textAlign: 'center'
       },

       featureLists: {
            display: 'flex', 
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: "0px",
            transition: "height .5s",
            overflow: "hidden",
            "@media (max-width: 767px)": {
                flexDirection: 'column'
            },
            '.active': {
                height: "600px",
                "@media (max-width: 767px)": {
                    height: "1020px"
                }
            }
       },

       list: {
            width: "50%",
            padding: "0px 15px",
            textAlign: 'left',
            maxWidth: "450px",
            margin: "50px auto 0px",
            fontSize: "18px",
            "@media (max-width: 767px)": {
                width: "100%"
            },
            "@media (max-width: 767px)": {
                width: "100%"
            }
       },

       heading: {
            marginBottom: "20px"
       },

       listItem: {
            fontSize: "14px",
            padding: "5px 0px",
            display: 'flex',
            alignItems: 'center',
            "> span": {
                padding: "0px 10px"
            }
       },

        launcher: {
            display: 'block',
            cursor: 'pointer',
            fontSize: "18px",
            lineHeight: "28px",
            padding: "25px 0px",
            ":hover": {
                "@media (min-width: 991px)": {
                    textDecoration: "underline",
                    color: global.colors.accent.color2
                }
            }
        }

     })
};

export default Styles;