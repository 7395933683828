import React, { Component } from 'react';
import  generatePhoneNumberLink  from '../../utils/generatePhoneNumberLink';
import { css } from "@convertly/css";
import ReactHTMLParser from "@convertly/react-html-parser";

//utils
import generateAccessKey from '../../utils/generateAccessKey';

//Default Props
import defaultGlobal from '../../themes/global.js';

export default class
PhoneNumberLink extends Component {

    constructor ( props ) {
        super( props );
    }

    render() {

        return (

            <a { ...generateAccessKey( "button" ) }
               className={ css( this.props.className ) }
			   data-event="click to call" data-action="click"
               href={ generatePhoneNumberLink( this.props.data.text ) }
               >{ReactHTMLParser( this.props.data.text ) }</a>

        );
    }
}

PhoneNumberLink.defaultProps = {
    data: {
        text: "Hello from the other side",
        bold: false,
        italic: false,
        underline: false,
        link: {
            linkType: 'external',
            url: "https://google.com"
        },
        align: 'center',
        fontSize: '',
        color: ''
    },
    global: defaultGlobal
};
