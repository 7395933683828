import { fromJS } from "immutable";
import { createReducer } from "redux-act";

import {
  setLoaded,
  setLocations,
  // setEditNewLocation,
  setResetNewLocation,
  setNewLocation,
  setSavingState,
  setEditLocationIndex,
  setResetEditLocationIndex,
  setLaunchRunTimeModal,
  setCloseRunTimeModal,
  setSchedules,
  setResetSchedules,
  setEditLocation,
  setResetEditLocation,
} from "../actions/locations";

const initialState = fromJS({
  editLocationIndex: 0,
  saving: false,
  loadedLocations: false,
  loading: false,
  showRunTimeModal: false,
  locations: [],
  schedules: [],
  newSchedule: {},
  editLocation: {},
});

export const locationsReducer = createReducer(
  {
    [setLoaded]: (state, loadedLocations) => state.merge({ loadedLocations }),

    [setSavingState]: (state, saving) => state.merge({ saving }),

    [setLocations]: (state, locations) => state.merge({ locations }),

    [setNewLocation]: (state, newLocation) => state.merge({ newLocation }),

    [setResetNewLocation]: state =>
      state.merge({
        newLocation: {
          country: "United States",
          businessHours: [],
          primaryLocation: false,
        },
      }),

    [setEditLocationIndex]: (state, editLocationIndex) => state.merge({ editLocationIndex }),
    [setResetEditLocationIndex]: state => state.merge({ editLocationIndex: 0 }),
    [setLaunchRunTimeModal]: state => state.merge({ showRunTimeModal: true }),
    [setCloseRunTimeModal]: state => state.merge({ showRunTimeModal: false }),

    [setSchedules]: (state, schedules) => state.merge({ schedules }),
    [setResetSchedules]: state => state.merge({ schedules: [] }),

    [setEditLocation]: (state, editLocation) => state.merge({ editLocation }),
    [setResetEditLocation]: state => state.merge({ editLocation: {} }),
    // [setAddNewLocation]: (state, newLocation) => state.({ })
  },
  initialState
);
