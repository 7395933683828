import React from "react";
import styled from "styled-components";

const headerGradient = "linear-gradient(180deg, #FFF 0%, #EEE 100%)";
const buttonGradient = "linear-gradient(90deg, #8BD9B2 0%, #45AA91 100%)";

export const ToolsContainer = styled.div`
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ToolContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  cursor: pointer;
  opacity: ${props => (props.selected ? "1" : "0.3")};
  &:hover {
    opacity: 1;
  }
`;
export const ToolLabel = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3px;
`;
export const ImageEditorContainer = styled.div`
  position: relative;
`;
export const ImgResponsive = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
export const ImageEditingPanel = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f5f8fa;
  padding: 20px 20px 0 20px;
`;
export const AdjustmentToolsRow = styled.div`
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f8fa;
`;
export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4px;
  background-color: #f5f8fa;
  height: 300px;
  width: 1000px;
  position: relative;
`;
export const ImageEditorFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 82px;
  background-color: #fff;
`;
export const SliderContainer = styled.div`
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const SliderContainer2 = styled(SliderContainer)`
  height: 42px;
`;
export const OrientationContainer = styled.div`
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f8fa;
`;
export const ToolsPairContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;
export const ToolsGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const SubToolContainer = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${props => (props.selected ? "1" : "0.3")};
  &:hover {
    opacity: 1;
  }
`;
