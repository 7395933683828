import React from "react";
import styled from "styled-components";

const Caret = styled.span`
  float: right;
  font-size: 10px;
  display: block;
  line-height: 18px;
  margin-right: 10px;
  cursor: pointer;
`;

export const ToggleMenuIcon = ({ active, onClick }) => (
  <Caret onClick={onClick}>
    {active && <i className="fa fa-chevron-up" />}
    {!active && <i className="fa fa-chevron-down" />}
  </Caret>
);
