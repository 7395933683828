import { StyleSheet } from "@convertly/css";
import UserOverrides from '../../../elements/style-functions/user-overrides';
import { generatePseudoMediaQuery } from '../../../elements/style-functions/pseudo-media-queries';
import { getColor } from '../../../elements/style-functions/get-color';

const slideStyles = ( global, data, sliderSettings, inEditor ) => {

    const userOverrides = new UserOverrides( global, data );

    let navOrietation = {
        bottom:'0'
    };

    if(data.navOrientation === 'left') {
        navOrietation = {
            left:'20px',
            top:'50%',
            transform:'translateY(-100%)',
            width:'auto',
            ...generatePseudoMediaQuery( '@media(max-width:768px)', ['smallTablet', 'mobile'], {
                left:'5px'
            }, inEditor )
        }
    }
    if(data.navOrientation === 'right') {
        navOrietation = {
            right: '20px',
            top: '50%',
            transform: 'translateY(-50%)',
            width:'auto',
            ...generatePseudoMediaQuery( '@media(max-width:768px)', ['smallTablet', 'mobile'], {
                right:'5px'
            }, inEditor )
        };
    }
    if(data.navOrientation === 'right') {
        navOrietation = {
            right: '20px',
            top: '50%',
            transform: 'translateY(-50%)',
            width:'auto',
            ...generatePseudoMediaQuery( '@media(max-width:768px)', ['smallTablet', 'mobile'], {
                right:'5px'
            }, inEditor )
        };
    }
    if(data.navOrientation === 'top') {
        navOrietation = {
            top:'0'
        };
    }



    return StyleSheet.create({

        convertlyControlNav: {
            width: '100%',
            position: 'absolute',
            ...navOrietation,
            textAlign: 'center',
            padding:0,
            zIndex: 5,
            ...userOverrides

        },
        convertlyControlNavItem: {
            margin: data.navOrientation === 'left' || data.navOrientation === 'right' ? '7px 0px' : '0 7px',
            display: data.navOrientation === 'left' || data.navOrientation === 'right' ? 'block' : 'inline-block',
            width: data.navItemSize || '18px',
            height: data.navItemSize || '18px',
            background: data.navItemColorInactive ? getColor(data.navItemColorInactive, 'colors', global) : global.colors.light.color3,
            cursor: 'pointer',
            textIndent: '-9999px',
            borderRadius: '100%',
            ':before': {
                fontFamily: "flexslider-icon",
                fontSize: '0',
                display: 'inline-block',
                color: 'rgba(0, 0, 0, 0.8)',
                textShadow: '1px 1px 0 rgba(255, 255, 255, 0.3)',
                opacity: '1'
            },
            '.active': {
                background: data.navItemColorActive ? getColor(data.navItemColorActive, 'colors', global) : global.colors.light.white
            },
            ':hover': {
                background: data.navItemColorActive ? getColor(data.navItemColorActive, 'colors', global) : global.colors.light.white
            },
            ':first-child': {
                marginLeft:0
            }
        }
    })
};

export default slideStyles;
