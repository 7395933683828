import React from 'react';
import Component from 'source/rootComponent';
import { css } from '@convertly/css';
import { id } from '@convertly/thor';
import _ from 'lodash';

// Utils
import { generateLink } from '../../../utils/generateLink';

// Scripts

// Components

// Styles
import navStylesDropdownItem from '../styles/navStylesDropdownItem';

export class NavDropdownItem extends Component {

  constructor(props) {
    super(props);
  }

  render() { 

    const inEditor = this.inEditor();

    const {
      global,
      data,
      navDropdownItemSettings,
      selectionItemLinkStyles
    } = this.props;
    
    const { link, text } = navDropdownItemSettings;

    const generatedLinkObject = {
      url: link.url || '/',
      linkType: link.linkType || 'none'
    }

    this.styles = navStylesDropdownItem(global, data, inEditor);
    const { dropdownItem, subSelectionItemLinkStyles } = this.styles;

    return (
      <li className={ css(dropdownItem) }>
        <a 
          className={ css(subSelectionItemLinkStyles) }
          href={ generateLink(generatedLinkObject) }>
          { (text || '').toUpperCase() }
        </a>
      </li>
    )
  }
}