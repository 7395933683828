/*global Inferno, $ */
import React from 'react'
const Script = () => {

    return (props) =>  {
      let { rootClass, currentQty, convertToCurrency } = props;

            if(!rootClass){
              rootClass = props.rootDiv.className;
            }

            let priceSection =
                <div className={ `${rootClass} price-container` }>
                    <span className={ `${rootClass} price` }>{ convertToCurrency(props.pricing.price * (currentQty ? currentQty : 1)) }</span>
                </div>;
            if( props.pricing.onSale ) {
                priceSection =
                    <div className={ `${rootClass} on-sale-price-container` }>
                        <span className={ `${rootClass} price sale-price` }>{ convertToCurrency(props.pricing.salePrice * (currentQty ? currentQty : 1)) }</span>
                        <span className={ `${rootClass} price on-sale` }>{ convertToCurrency(props.pricing.price * (currentQty ? currentQty : 1)) }</span>
                        
                    </div>;

            }

            return (
                <div className={ `${rootClass} price-wrapper` }>
                    { priceSection }
                </div>

            )
        }
};

export default Script;
