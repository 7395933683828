import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { noop } from "utils";
import { Input } from "./index";

export const Wrapper = styled.div`
  margin: 0 0 20px 0;
  width:100%;
`;

export const Label = styled.label`
  font-weight: ${({ smallLabel }) => {
    if (smallLabel) return "500";
    return "600";
  }};
  font-size: ${({ smallLabel }) => {
    if (smallLabel) return "12px";
    return "14px";
  }};
`;

export const Description = styled.p`
  margin: 5px 0 10px 0;
  font-size: 12px;
`;

export const InputWithLabel = ({ description, label, children, smallLabel, ...props }) => {
  const { containerStyles, name } = props;
  return (
    <Wrapper style={containerStyles}>
      <Label smallLabel={smallLabel} htmlFor={name}>
        {label}
      </Label>
      {!!description && <Description>{description}</Description>}
      <Input {...props} />
      {!!children && children}
    </Wrapper>
  );
};

InputWithLabel.propTypes = {
  description: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

InputWithLabel.defaultProps = {
  description: "",
  label: "",
  onChange: noop,
};

export const InputWithSmallLabel = props => <InputWithLabel {...props} smallLabel={true} />;
