import { pure } from "recompose";
import { ColorPicker as ColorPickerComponent } from "./ColorPicker";
import { DropDown as DropDownComponent } from "./Dropdown";
import { HelpToolTip as HelpToolTipComponent } from "./HelpToolTip";
import * as icons from "./svg";
import { Input } from "./Input";
import {
  InputWithLabel as InputWithLabelComponent,
  Label as LabelComponent,
  InputWithSmallLabel,
} from "./Input/InputWithLabel";
import { SearchBar } from "./SearchBar";
import { Slider } from "./Slider";
import { Stepper } from "./Stepper";
import { TextLink, Heading3 } from "./Typography";
import { TextAreaWithLabel, TextAreaWithSmallLabel } from "./TextArea";
import {TextArea} from "./TextArea";
import {
  MainButton as MainButtonComponent,
  CancelButton as CancelButtonComponent,
} from "./Buttons";

import { EllipsisMenu } from "./EllipsisMenu";
import { UploadInputWrapper } from "./UploadInputWrapper";
import { DraggableListWidget } from './DraggableListWidget/DraggableListWidget'
import { ButtonMultiSelect as ButtonMultiSelectComponent  } from "ui-components/ButtonMultiSelect";

//functional components to get run through pure
const UiComponents = {
  Slider: Slider,
  ...icons,
  Input: Input,
  SearchBar: SearchBar,
  Stepper: Stepper,
  TextAreaWithLabel,
  TextArea,
  TextLink,
  EllipsisMenu,
  UploadInputWrapper,
  Heading3,
  DraggableListWidget
};
//wraps each component with pure to prevent unneeded re-renders
Object.keys(UiComponents).forEach(key => {
  const comp = UiComponents[key];
  module.exports[key] = typeof comp === "function" ? pure(comp) : comp;
});

export const Label = pure(LabelComponent);
export const InputWithLabel = pure(InputWithLabelComponent);
export const MainButton = pure(MainButtonComponent);
export const CancelButton = pure(CancelButtonComponent);
export const ColorPicker = pure(ColorPickerComponent)
export const DropDown = pure(DropDownComponent);
export const HelpToolTip = pure(HelpToolTipComponent);
export const ButtonMultiSelect = pure(ButtonMultiSelectComponent);

export { InputWithSmallLabel, TextAreaWithSmallLabel };
