import { StyleSheet } from "@convertly/css";
import googleButtonStyleObject from '../../sign-in-up/google-button/google-button-style-object';
import facebookButtonStyleObject from '../../sign-in-up/facebook-button/facebook-button-style-object';
import signInUpStyleObject from '../../sign-in-up/sign-in-up-form/sign-in-up-form-styles'
import staySignedInStyleObject from '../../sign-in-up/sign-in-up-form/stay-signed-in/stay-signed-in-styles';
import OrderStatusStyles from "../../ecommerce-common-components/OrderStatus/OrderStatusStyles";
import OrderNotificationsStyles from "../../ecommerce-common-components/OrderNotifications/OrderNotificationsStyles";
import EditNotificationsModalStyles from "../../ecommerce-common-components/EditNotificationsModal/EditNotificationsModalStyles";
import ShippingWidgetStyles from '../../ecommerce-common-components/shipping-widgets/shipping-widget-styles';
import GenericModalStyles from "../../ecommerce-common-components/GenericModal/GenericModalStyles";

const Styles = (global, data) => {

    const googleButtonStyles = googleButtonStyleObject(global, data);
    const facebookButtonStyles = facebookButtonStyleObject(global, data);
    const signInUpStyles = signInUpStyleObject(global, data);
    const staySignedInStyles = staySignedInStyleObject(global, data);
    const orderStatusStyles = OrderStatusStyles(global, data);
    const orderNotificationsStyles = OrderNotificationsStyles(global, data);
    const editNotificationsStyles = EditNotificationsModalStyles(global, data);
    const genericModalStyles = GenericModalStyles(global, data)

    const formFieldStyles = {

        backgroundColor: global.colors.light.white,
        border: "1px solid",
        borderColor: global.colors.accent.color3,
        color: global.colors.dark.color2,
        display: "block",
        fontSize: "16px",
        height: "38px",
        lineHeight: "18px",
        margin: "0",
        padding: "8px 12px",
        fontWeight: '300',
        verticalAlign: "middle",
        width: '100%',
        '.input[disabled]': {
            background: global.colors.light.color1,
            borderColor: global.colors.dark.color3,
            color: global.colors.dark.color3,
            '::placeholder': {
                color: global.colors.dark.color3,
                fontWeight: '300',
            }
        },
        '::placeholder': {
            color: global.colors.dark.color3,
            fontWeight: '300',
        }


    };


    return StyleSheet.create({


        accountbody: {
            color: global.colors.dark.color1,
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "14px",
            lineHeight: "20px",
            paddingBottom: "50px",
            paddingTop: "50px",
            minHeight: '100vh'
        },

        container: {
            color: global.colors.dark.color1,
            fontSize: "14px",
            lineHeight: "20px",
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "1140px",
            position: "relative",
            width: "95%",
            zIndex: "10"
        },

        accountPageInfernoRoot: {
            ...googleButtonStyles,
            ...facebookButtonStyles,
            ...signInUpStyles,
            ...staySignedInStyles,
            ...orderStatusStyles,
            ...orderNotificationsStyles,
            ...editNotificationsStyles,
            ...genericModalStyles,
            /** Sign in Page **/
            ".login-screen-container": {
                position: 'relative',
                overflow: 'hidden',
                minHeight: '100vh'
            },
            ".sign-in-container": {
                position: 'absolute',
                left: '-110%',
                transition: 'all 1s ease',
                width: '100%',
                display:'none',
                '.active': {
                    position:"static",
                    display:'block'
                }
            },
            ".sign-up-container": {
                position: 'absolute',
                left: '110%',
                transition: 'all 1s ease',
                width: '100%',
                margin: "0 auto",
                display:'none',
                '.active': {
                    position:"static",
                    display:'block'
                }
            },
            ".login-screen-link": {
                margin: "20px auto",
                border: `1px solid ${global.colors.accent.color1}`,
                backgroundColor: "transparent",
                transition: "all 400ms ease",
                fontFamily: global.fontFamily.secondaryFont,
                color: global.colors.accent.color1,
                display: "inline-block",
                padding: "9px 15px",
                cursor: 'pointer',
                ":hover": {
                    background: global.colors.accent.color1,
                    color: "#fff"
                }
            },
            ".wrapper": {
                width: '100%',
                maxWidth: '375px',
                margin: '0 auto',
                textAlign: 'center',
            },
            ".sign-in-form": {
                width: '100%',
                textAlign: 'center',
            },

            ".orContainer": {
                margin: '0 auto',
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                justifyContent: "center",
                lineHeight: "20px",
                width: "50%"
            },

            ".orDivider": {
                backgroundColor: global.colors.dark.color3,
                flex: "1",
                fontSize: "14px",
                height: "3px",
                lineHeight: "20px",
                marginTop: '-1px'
            },

            ".orSeparator": {
                color: global.colors.dark.color3,
                display: "flex",
                fontSize: "14px",
                lineHeight: "20px",
                padding: "5px 20px"
            },

            ".chkTitle2": {
                marginTop: '0px',
                marginBottom: '5px',
                fontFamily: global.fontFamily.secondaryFont,
                color: global.colors.dark.color1,
                fontSize: '25px',
            },
            ".signuptitle": {
                marginTop: '0px',
                marginBottom: '30px',
                fontFamily: global.fontFamily.secondaryFont,
                color: global.colors.dark.color1,
                fontSize: '25px',
                textAlign: 'center'
            },
            ".buttonContainer": {
                width: '50%',
                padding: '0 20px',
                display: 'inline-block',
                margin: '20px 0',
                verticalAlign: 'middle',
                "@media ( max-width:515px )": {
                    display: 'block',
                    width: "100%"
                },
                ".push-down": {
                    paddingTop: '2px'
                }
            },
            ".socialButtons": {
                margin: '0 auto',
                maxWidth: '600px'
            },

            ".row_wRow": {
                flex: "1",
                fontSize: "14px",
                lineHeight: "20px",
                marginLeft: "0",
                marginRight: "0"
            },

            ".column_wClearfix_wCol_wCol_6_wColSmall_6_wColTiny_6": {
                display: 'inline-block',
                width: '50%'
            },

            /** Navigation Styles **/
            ".accountContainer": {
                "@media (max-width: 767px)": {
                    textAlign: 'center',
                    marginBottom: '20px'
                },
            },

            ".accountlink": {
                borderRadius: "5px",
                color: global.colors.dark.color2,
                display: "block",
                border: "1px solid transparent",
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "10px",
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: "100%",
                paddingBottom: "10px",
                paddingTop: "10px",
                textAlign: "center",
                textDecoration: "none",
                transition: "background-color 400ms ease",
                width: "200px",
                cursor: 'pointer',
                "@media (max-width: 767px)": {
                    display: 'inline-block',
                    fontSize: '12px',
                    width: '28%',
                    margin: '10px',

                },
                "@media (max-width:478px)": {
                    lineHeight: "5px",
                    margin: '5px'
                },
                ":hover": {
                    backgroundColor: global.colors.light.white
                },
                ".active": {
                    backgroundColor: global.colors.light.white,
                    borderColor: global.colors.dark.color2
                },
            },

            ".account_textcontainer": {
                color: global.colors.dark.color1,
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                justifyContent: "flex-start",
                lineHeight: "20px",
                marginTop: "0px",
                maxWidth: "100%",
                position: "relative",
                textAlign: "left",
                width: "700px",
                "@media( max-width:700px )": {
                    width: '100%'
                }
            },

            /** Order History Styles **/

            ".paginationPage": {
                position: 'absolute',
                right: '-200%',
                display: 'inline-block',
                width: '100%',
                transition: 'all 1s ease',
                ".active": {
                    position: 'static'
                }


            },

            ".orderfiltercontainer_wClearfix": {
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                textAlign: "left"
            },

            ".accounts_subtitle": {
                color: global.colors.dark.color3,
                display: "inline",
                float: "left",
                fontSize: "13px",
                lineHeight: "20px",
                marginBottom: "15px",
                textAlign: "left"
            },

            ".dropdownorderfreq_wDropdown": {
                color: global.colors.dark.color1,
                display: "inline-block",
                float: "left",
                fontSize: "14px",
                lineHeight: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                position: "relative",
                textAlign: "left",
                zIndex: "900"
            },
            ".password_section": {
                height: "auto",
                maxHeight: "0",
                transition: 'all 400ms ease',
                overflow: 'hidden',
                ".active": {
                    maxHeight: '200px'
                }
            },

            ".toggle_wDropdownToggle": {
                color: global.colors.dark.color1,
                cursor: "pointer",
                display: "inline-block",
                float: "none",
                fontSize: "14px",
                lineHeight: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                padding: "0px 17px 0px 0px",
                position: "relative",
                textAlign: "left",
                textDecoration: "none",
                userSelect: "none",
                verticalAlign: "top",
                whiteSpace: "nowrap"
            },

            ".icon_wIconDropdownToggle": {
                bottom: "0",
                color: global.colors.dark.color1,
                cursor: "pointer",
                fontFamily: "'webflow-icons'",
                fontSize: "14px",
                fontVariant: "normal",
                height: "1em",
                lineHeight: "1",
                margin: "auto",
                marginRight: "0px",
                position: "absolute",
                right: "0",
                speak: "none",
                textAlign: "left",
                textTransform: "none",
                top: "0",
                whiteSpace: "nowrap",
                width: "1em"
            },

            ".accounts_drop_toggletext": {
                color: global.colors.dark.color1,
                cursor: "pointer",
                flex: "0 auto",
                fontSize: "13px",
                lineHeight: "20px",
                paddingLeft: "5px",
                textAlign: "right",
                whiteSpace: "nowrap"
            },

            ".dropdownList_wDropdownList": {
                background: global.colors.light.color1,
                color: global.colors.dark.color1,
                display: "none",
                fontSize: "14px",
                lineHeight: "20px",
                minWidth: "100%",
                position: "absolute",
                textAlign: "left"
            },

            ".accont_dropdownlink_wDropdownLink": {
                backgroundColor: global.colors.light.white,
                color: global.colors.dark.color2,
                display: "block",
                fontSize: "12px",
                lineHeight: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                minWidth: "60px",
                padding: "10px 20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                position: "relative",
                textAlign: "right",
                textDecoration: "none",
                verticalAlign: "top",
                whiteSpace: "nowrap"
            },

            ".ordercontainer": {
                backgroundColor: "#fff",
                border: "1px solid",
                borderColor: global.colors.dark.color3,
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                marginTop: "20px",
                padding: "0px",
                textAlign: "left",
                transition: 'all 1s ease',
            },

            ".orderheader_wClearfix": {
                alignItems: "center",
                backgroundColor: global.colors.light.white,
                borderColor: global.colors.dark.color3,
                borderStyle: "none none solid",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                borderWidth: "1px",
                color: global.colors.dark.color1,
                display: "flex",
                fontSize: "14px",
                justifyContent: "space-between",
                lineHeight: "20px",
                padding: "15px",
                textAlign: "left"
            },

            ".divBlock_5": {
                color: global.colors.dark.color1,
                display: "flex",
                flex: "1",
                flexDirection: "column",
                fontSize: "14px",
                lineHeight: "20px",
                textAlign: "left"
            },

            ".orderlabel_ordernum": {
                color: global.colors.dark.color2,
                display: "flex",
                flex: "1",
                fontSize: "13px",
                lineHeight: "20px",
                paddingRight: "15px",
                textTransform: "none",
                justifyContent:"space-between"
            },

            ".downloadinvoice_orderlabel_ordernum": {
                color: global.colors.dark.color2,
                display: "inline-block",
                flex: "1",
                float: "left",
                fontSize: "10px",
                lineHeight: "15px",
                paddingRight: "15px",
                textAlign: "left",
                textDecoration: "underline",
                textTransform: "none"
            },

            ".orderlabel_reorder": {
                color: global.colors.dark.color2,
                display: "inline-block",
                flex: "1",
                float: "left",
                fontSize: "13px",
                lineHeight: "20px",
                paddingRight: "0px",
                textAlign: "right",
                textDecoration: "underline",
                textTransform: "none",
                cursor: 'pointer'
            },
            ".no-orders": {
                border: global.border,
                width: '100%',
                padding: '10px 20px'
            },

            ".orderdetails": {
                borderBottom: "1px solid",
                borderColor: global.colors.dark.color3,
                color: global.colors.dark.color1,
                display: "flex",
                fontSize: "14px",
                justifyContent: "space-between",
                lineHeight: "20px",
                marginBottom: "5px",
                marginLeft: "auto",
                marginRight: "auto",
                padding: "10px 0px",
                textAlign: "left",
                width: "95%"
            },

            ".orderparam_total": {
                color: global.colors.dark.color2,
                flex: "0 auto",
                fontSize: "12px",
                lineHeight: "22px",
                paddingRight: "20px",
                textAlign: "center"
            },

            ".orderparam": {
                color: global.colors.dark.color2,
                flex: "0 auto",
                fontSize: "12px",
                lineHeight: "22px",
                textAlign: "center"
            },
            ".addressBox": {
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                padding: "10px 15px 20px",
                textAlign: "left"
            },
            ".orderitem_th": {
                alignItems: "flex-start",
                color: global.colors.dark.color1,
                display: "flex",
                fontSize: "14px",
                justifyContent: "space-between",
                lineHeight: "20px",
                marginBottom: "15px",
                marginTop: "10px",
                textAlign: "left"
            },

            ".orderitemimg_th": {
                backgroundImage: "none",
                backgroundPosition: "50% 50%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                color: global.colors.dark.color1,
                fontSize: "14px",
                height: "20px",
                lineHeight: "20px",
                marginRight: "5px",
                textAlign: "left",
                width: "40px",
                "@media (max-width:767px)": {
                    display: 'none'
                }

            },

            ".col1_ordertitlecontainer": {
                color: global.colors.dark.color1,
                flex: "0 auto",
                fontSize: "14px",
                lineHeight: "20px",
                maxWidth: "auto",
                textAlign: "left",
                width: "245px",
                "@media (max-width:515px)": {
                    width: '200px'
                }
            },

            ".item_orderitemtitle_th": {
                color: global.colors.dark.color1,
                fontFamily: global.fontFamily.secondaryFont,
                fontSize: "11px",
                lineHeight: "20px",
                paddingLeft: "5px",
                textAlign: "left"
            },

            ".ordertitlecontainer": {
                color: global.colors.dark.color1,
                flex: "1",
                fontSize: "14px",
                lineHeight: "20px",
                textAlign: "left",
                maxWidth: '80%'
            },

            ".orderitemtitle_th": {
                color: global.colors.dark.color1,
                fontFamily: global.fontFamily.secondaryFont,
                fontSize: "11px",
                lineHeight: "20px",
                paddingLeft: "0px",
                textAlign: "center"
            },

            ".ordertitlecontainer_th": {
                color: global.colors.dark.color1,
                flex: "1",
                fontSize: "14px",
                height: "20px",
                lineHeight: "20px",
                marginRight: "0px",
                maxWidth: "24%",
                textAlign: "left"
            },

            ".orderitem": {
                alignItems: "flex-start",
                color: global.colors.dark.color1,
                display: "flex",
                fontSize: "14px",
                justifyContent: "space-between",
                lineHeight: "20px",
                marginBottom: "15px",
                textAlign: "left"
            },

            ".orderitemimg": {

                color: global.colors.dark.color1,
                fontSize: "14px",
                height: "40px",
                lineHeight: "20px",
                marginRight: "5px",
                textAlign: "left",
                width: "40px",
                "@media (max-width:767px)": {
                    display: 'none'
                }
            },

            ".orderitemtitle": {
                color: global.colors.dark.color1,
                fontSize: "12px",
                lineHeight: "20px",
                paddingLeft: "5px",
                textAlign: "left"
            },

            ".orderitemtitle_qty": {
                color: global.colors.dark.color2,
                fontSize: "12px",
                lineHeight: "20px",
                paddingLeft: "0px",
                textAlign: "center"
            },

            ".orderitemtitle_reorder": {
                display: 'block',
                color: global.colors.dark.color2,
                fontSize: "12px",
                lineHeight: "20px",
                maxWidth: "auto",
                paddingLeft: "5px",
                textAlign: "right",
                textDecoration: "underline",
                cursor: "pointer"
            },

            ".last_orderitem": {
                alignItems: "flex-start",
                color: global.colors.dark.color1,
                display: "flex",
                fontSize: "14px",
                justifyContent: "space-between",
                lineHeight: "20px",
                marginBottom: "0px",
                textAlign: "left"
            },

            ".itemcollapse": {
                alignItems: "center",
                zIndex: '1',
                backgroundImage: `linear-gradient(0deg, ${global.colors.light.white}, ${global.colors.light.color1})`,
                color: global.colors.dark.color1,
                display: "flex",
                fontSize: "14px",
                justifyContent: "center",
                lineHeight: "20px",
                position: "relative",
                textAlign: "left",
                paddingBottom: '7px',
                paddingTop: '10px'
            },

            ".collapselink": {
                backgroundColor: "transparent",
                color: global.colors.accent.color1,
                fontSize: "10px",
                lineHeight: "20px",
                textAlign: "left",
                textDecoration: "underline"
            },

            ".paginationcontainer_textcontainer": {
                alignItems: "center",
                color: global.colors.dark.color1,
                display: "flex",
                flexDirection: "row",
                fontSize: "14px",
                justifyContent: "flex-end",
                lineHeight: "20px",
                marginTop: "35px",
                maxWidth: "95%",
                paddingLeft: "0px",
                paddingRight: "0px",
                position: "relative",
                textAlign: "left",
                width: "700px"
            },

            ".inactive_paginationtoggle": {
                backgroundColor: "transparent",
                border: "2px solid transparent",
                borderColor: global.colors.light.color2,
                borderRadius: "3px",
                borderWidth: "1px",
                color: global.colors.dark.color3,
                fontSize: "14px",
                lineHeight: "14px",
                padding: "5px 10px",
                textAlign: "left",
                cursor: 'default'
            },

            ".paginationcontainer": {
                alignItems: "center",
                color: global.colors.dark.color1,
                display: "flex",
                fontSize: "14px",
                lineHeight: "20px",
                paddingLeft: "15px",
                paddingRight: "15px",
                textAlign: "left"

            },

            ".paginationtext": {
                color: global.colors.dark.color3,
                fontSize: "12px",
                lineHeight: "20px",
                textAlign: "left",

            },

            ".paginationtoggle": {
                backgroundColor: "#fff",
                border: "2px solid transparent",
                borderRadius: "3px",
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "14px",
                padding: "5px 10px",
                textAlign: "left",
                cursor: 'pointer'
            },

            /** Account Preferences Styles **/

            ".ac2_preferences_textcontainer": {
                color: global.colors.dark.color1,
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                justifyContent: "center",
                lineHeight: "20px",
                marginTop: "0px",
                maxWidth: "100%",
                position: "relative",
                textAlign: "left",
                width: "700px",
                "@media (max-width:767px)": {
                    width: '100%',
                    alignItems: "center",
                    textAlign: "center"
                }
            },

            ".account_ordercontainer": {
                width: '100%',
                backgroundColor: global.colors.light.white,
                border: "1px solid",
                borderColor: global.colors.dark.color3,
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                marginTop: "20px",
                padding: "0px",
                textAlign: "left"
            },

            ".account_orderheader_wClearfix": {
                alignItems: "center",
                backgroundColor: global.colors.light.white,
                borderColor: global.colors.dark.color3,
                borderStyle: "none none solid",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                borderWidth: "1px",
                color: global.colors.dark.color1,
                display: "flex",
                fontSize: "14px",
                justifyContent: "space-between",
                lineHeight: "20px",
                padding: "15px",
                textAlign: "left"
            },

            ".account_orderlabel_ordernum": {
                color: global.colors.dark.color1,
                display: "inline-block",
                flex: "1",
                float: "left",
                fontFamily: global.fontFamily.primaryFont,
                fontSize: "12px",
                lineHeight: "20px",
                paddingRight: "15px",
                textAlign: "left",
                textTransform: "none"
            },

            ".account_orderlabel_reorder": {
                color: global.colors.dark.color2,
                display: "inline-block",
                flex: "1",
                float: "left",
                fontFamily: global.fontFamily.primaryFont,
                fontSize: "12px",
                lineHeight: "20px",
                paddingRight: "0px",
                textAlign: "right",
                textDecoration: "underline",
                textTransform: "none",
                cursor: 'pointer'
            },

            ".orderitemization": {
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                paddingLeft: "15px",
                paddingRight: "15px",
                textAlign: "left",
                overflow: 'hidden',
                maxHeight: '200px',
                height: "auto",
                transition: 'all .3s ease',
                ".open": {
                    maxHeight: '2400px',
                    transition: 'max-height .3s ease',
                }
            },

            ".shippingitem": {
                alignItems: "flex-start",
                color: global.colors.dark.color1,
                display: "flex",
                fontSize: "14px",
                lineHeight: "20px",
                marginTop: "12px",
                textAlign: "left"
            },
            ".trashIcon": {
                height: '13px',
                width: '13px',
                marginLeft: '10px',
                verticalAlign: 'text-top',
                cursor: 'pointer'
            },

            ".defaultcontainer": {
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                minWidth: "35px",
                textAlign: "center"
            },

            ".account_selected_selector": {
                backgroundColor: global.colors.accent.color1,
                border: "4px solid",
                borderColor: global.colors.light.color1,
                borderRadius: "50%",
                boxShadow: `0 0 0 3px ${global.colors.accent.color1}`,
                color: global.colors.dark.color1,
                fontSize: "14px",
                height: "19px",
                lineHeight: "20px",
                marginLeft: "3px",
                marginRight: "3px",
                textAlign: "center",
                width: "19px"
            },

            ".addresscontainer": {
                color: global.colors.dark.color1,
                display: "flex",
                flex: "1",
                fontSize: "14px",
                justifyContent: "space-between",
                lineHeight: "20px",
                textAlign: "left"
            },

            ".bodytext_selected_shippingaddy": {
                color: global.colors.dark.color1,
                fontFamily: global.fontFamily.primaryFont,
                fontSize: "14px",
                lineHeight: "24px",
                textAlign: "left",
                maxWidth: "80%"
            },

            ".account_actionlabel": {
                backgroundColor: "transparent",
                color: global.colors.dark.color3,
                fontFamily: global.fontFamily.primaryFont,
                fontSize: "11px",
                lineHeight: "20px",
                marginBottom: "5px",
                textAlign: "center",
            },
            ".editAccountLink": {
                cursor: 'pointer'
            },
            ".account_selector": {
                backgroundColor: global.colors.dark.color3,
                border: "4px solid",
                borderColor: global.colors.light.color1,
                borderRadius: "50%",
                boxShadow: "0 0 0 3px #fff",
                color: global.colors.dark.color1,
                fontSize: "14px",
                height: "19px",
                lineHeight: "20px",
                marginLeft: "3px",
                marginRight: "3px",
                textAlign: "center",
                width: "19px",
                cursor: "pointer"
            },

            ".bodytext_shippingaddy": {
                color: global.colors.dark.color2,
                fontFamily: global.fontFamily.primaryFont,
                fontSize: "14px",
                lineHeight: "24px",
                textAlign: "left"
            },

            ".div_bVgU": {
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                textAlign: "left",
                position: 'relative',
            },
            ".spinnerIcon": {
                height: '20px',
                width: '30px',
                verticalAlign: 'middle',
                position: 'absolute',
                right: '10px',
                top: '28px'
            },

            ".actionlabel_billing_selected": {
                color: global.colors.dark.color1,
                fontFamily: global.fontFamily.primaryFont,
                fontSize: "11px",
                lineHeight: "20px",
                marginBottom: "5px",
                textAlign: "left"
            },

            ".actionlabel_billing": {
                color: global.colors.dark.color3,
                fontFamily: global.fontFamily.primaryFont,
                fontSize: "11px",
                lineHeight: "20px",
                marginBottom: "5px",
                textAlign: "left"
            },

            ".emailpref_formcontainer_wForm": {
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                margin: "0 0 15px",
                marginTop: "35px",
                maxWidth: "100%",
                textAlign: "left",
                width: "400px",

            },

            ".account_actionbtn_presave_wButton": {
                backgroundColor: "transparent",
                border: "1px solid",
                borderColor: global.colors.dark.color1,
                borderRadius: "0",
                color: global.colors.dark.color3,
                display: "inline-block",
                font: "inherit",
                fontFamily: global.fontFamily.primaryFont,
                fontSize: "17px",
                lineHeight: "inherit",
                margin: "15px 0",
                maxWidth: "100%",
                padding: "9px 15px",
                paddingBottom: "12px",
                paddingTop: "12px",
                textAlign: "center",
                textDecoration: "none",
                width: "100%",
                transition: 'all .3s ease',
                cursor: 'default',
                '.active': {
                    cursor: "pointer",
                    background: global.colors.dark.color1,
                    color: global.colors.light.white,
                    ":hover": {
                        opacity: '.8'
                    }
                }

            },


            ".change-address-modal": {
                position: 'fixed',
                margin: 'auto',
                top: '-100vh',
                transform: 'translateY(-30vh)',
                minHeight: '35vh',
                width: '525px',
                left: 'calc( 50% - 230px)',
                background: global.colors.light.white,
                border: global.border,
                zIndex: '46',
                padding: "30px 60px 45px",
                ".active": {
                    top: '50vh',
                },
                "@media (max-width:767px)": {
                    width: '100%',
                    left: '0'
                },
                "@media (max-width:767px) and (min-width: 515px)": {
                    paddingTop: '20px'
                },
                "@media (max-width: 515px)": {
                    padding: '17px'
                }

            },
            ".save-button": {
                backgroundColor: global.colors.dark.color1,
                border: "0",
                borderRadius: "0",
                color: "white",
                cursor: "pointer",
                display: "block",
                fontSize: "14px",
                lineHeight: "inherit",
                margin: "10px 0 0",
                padding: "9px 15px",
                paddingBottom: "11px",
                paddingTop: "11px",
                textDecoration: "none",
                textTransform: "uppercase",
                width: "100%",
                textAlign: "center"
            },
            ".delete-button": {
                backgroundColor: global.colors.dark.color1,
                border: "0",
                borderRadius: "0",
                color: "white",
                cursor: "pointer",
                display: "inline-block",
                fontSize: "14px",
                lineHeight: "inherit",
                margin: "10px 0 0 5px",
                padding: "9px 15px",
                paddingBottom: "11px",
                paddingTop: "11px",
                textDecoration: "none",
                textTransform: "uppercase",
                width: "48%",
                textAlign: "center"

            },
            ".delete-button-cancel": {
                backgroundColor: global.colors.dark.color3,
                border: "0",
                borderRadius: "0",
                color: "white",
                cursor: "pointer",
                display: "inline-block",
                fontSize: "14px",
                lineHeight: "inherit",
                margin: "10px 5px 0 0",

                padding: "9px 15px",
                paddingBottom: "11px",
                paddingTop: "11px",
                textDecoration: "none",
                textTransform: "uppercase",
                width: "48%",
                textAlign: "center"
            },
            '.closeButton': {
                position: 'absolute',
                padding: '10px',
                top: '-15px',
                right: '-15px',
                height: '45px',
                width: '45px',
                border: global.border,
                borderRadius: '100%',
                background: global.colors.light.color1,
                cursor: 'pointer',
                "@media (max-width:767px)": {
                    right: '10px',
                    top: '10px',
                    background: 'none',
                    border: 'none'
                },

            },
            '.closeButtonIcon': {
                fill: global.colors.dark.color1,
                stroke: global.colors.dark.color1,
                "@media (max-width:767px)": {
                    height: '20px',
                    width: '20px'
                },
            },

            '.closeIcon': {
                height: '20px',
                width: '20px'
            },
            ".modal-header": {
                fontSize: '24px',
                marginBottom: '25px',
                textAlign: 'center'
            },
            ".modal-title": {
                display: 'inline-block',
                fontSize: '22px'
            },
            ".modal-close": {
                position: 'relative',
                top: '3px',
                right: '3px',
                display: 'inline-block',
                float: 'right',
                width: '50%',
                fontSize: '20px',
                textAlign: 'right'
            },
            ".formContainer_wForm": {
                fontSize: "14px",
                lineHeight: "20px",
                margin: "35px auto",
                maxWidth: "375px",

            },
            ".formContainer_shipping": {
                fontSize: "14px",
                lineHeight: "20px",
                margin: "20px 0 15px",
                maxWidth: "375px"
            },
            ".shippingOptionsForm": {
                fontSize: "14px",
                lineHeight: "20px",
                margin: "25px 0 15px",
                maxWidth: "375px"
            },

            ".form_HCPV": {
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: '22px'
            },

            ".textInput_wInput": {
                ...formFieldStyles,
                width: "100%"
            },
            ".input-container-half-width": {
                width: "48%",
                height: '55px',
                display: "inline-block",
                '.right': {
                    float: 'right'
                }
            },
            ".input-container": {
                width: "100%",
                height: '55px',
            },
            ".textInput_halfWidth": {
                ...formFieldStyles,
            },
            '.selectInput_wInput': {
                appearance: 'none',
                ...formFieldStyles,
                width: '100%',
                borderRadius: '0',
                color: global.colors.dark.color3,
                '> option': {
                    color: global.colors.dark.color3,
                },
                ".active": {
                    color: global.colors.dark.color2
                }
            },
            ".selectInput_halfWidth": {
                appearance: 'none',
                ...formFieldStyles,
                display: 'inline-block',
                width: "47%",
                borderRadius: '0',
                '> option': {
                    color: global.colors.dark.color3,
                },
                '.right': {
                    float: 'right'
                }
            },

            ".overlay": {
                display: 'none',
                position: 'fixed',
                top: '0',
                left: '0',
                bottom: '0',
                right: '0',
                zIndex: '45',
                background: 'rgba(100,100,100,.7)',
                ".active": {
                    display: 'block'
                }
            },


            /** Edit Profile Styles **/

            ".account2_textcontainer": {
                alignItems: "flex-start",
                color: global.colors.dark.color1,
                display: "flex",
                flexDirection: "column",
                fontSize: "14px",
                justifyContent: "center",
                lineHeight: "20px",
                maxWidth: "95%",
                position: "relative",
                textAlign: "left",
                width: "700px",
                margin: '0 auto',
                "@media (max-width:767px)": {
                    alignItems: 'center'
                }

            },

            ".titleholder": {
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                position: "relative",
                textAlign: "left"
            },

            ".producttitle": {
                color: global.colors.dark.color1,
                fontFamily: global.fontFamily.primaryFont,
                fontSize: "25px",
                lineHeight: "44px",
                margin: "0.67em 0",
                marginBottom: "5px",
                marginTop: "0px",
                textAlign: "left"
            },

            ".formcontainer_wForm": {
                color: global.colors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                margin: "0 0 15px",
                marginTop: "15px",
                maxWidth: "100%",
                textAlign: "left",
                width: "400px"
            },

            ".form_wClearfix": {
                color: global.colors.dark.color1,
                flexDirection: "column",
                fontSize: "14px",
                lineHeight: "20px",
                textAlign: "left"
            },
            ".profile_change_link_container": {
                margin: '20px 0'
            },
            ".inputcontainer_wClearfix": {
                color: global.colors.dark.color1,
                display: "flex",
                flexDirection: "row",
                fontSize: "14px",
                justifyContent: "space-between",
                lineHeight: "20px",
                textAlign: "left"
            },

            ".firstline_inputfield_leftinput_wInput": {
                backgroundColor: global.colors.light.white,
                border: "1px solid",
                borderColor: global.colors.dark.color3,
                color: global.colors.light.color2,
                display: "block",
                float: "left",
                font: "inherit",
                fontFamily: global.fontFamily.primaryFont,
                fontSize: "16px",
                height: "38px",
                lineHeight: "1.428571429",
                margin: "0",
                marginBottom: "10px",
                marginRight: "3px",
                padding: "8px 12px",
                textAlign: "left",
                verticalAlign: "middle",
                width: "50%"
            },

            ".firstline_inputfield_rightinput_wInput": {
                backgroundColor: global.colors.light.white,
                border: "1px solid",
                borderColor: global.colors.dark.color3,
                color: global.colors.light.color2,
                display: "block",
                float: "left",
                font: "inherit",
                fontFamily: global.fontFamily.primaryFont,
                fontSize: "16px",
                height: "38px",
                lineHeight: "1.428571429",
                margin: "0",
                marginBottom: "10px",
                marginLeft: "3px",
                padding: "8px 12px",
                textAlign: "left",
                verticalAlign: "middle",
                width: "50%"
            },

            ".disabled_inputfield_wInput": {
                backgroundColor: global.colors.light.color3,
                border: "1px solid",
                borderColor: global.colors.dark.color3,
                color: global.colors.light.color2,
                display: "block",
                float: "left",
                font: "inherit",
                fontFamily: global.fontFamily.primaryFont,
                fontSize: "16px",
                height: "38px",
                lineHeight: "1.428571429",
                margin: "0",
                marginBottom: "5px",
                padding: "8px 12px",
                textAlign: "left",
                verticalAlign: "middle",
                width: "100%"
            },

            ".formeditaction": {
                backgroundColor: "transparent",
                color: global.colors.dark.color3,
                fontFamily: global.fontFamily.primaryFont,
                fontSize: "12px",
                lineHeight: "14px",
                marginBottom: "25px",
                marginTop: "4px",
                textAlign: "left"
            },

            ".ac3_account_actionbtn_wButton": {
                backgroundColor: global.colors.dark.color1,
                border: "0",
                borderRadius: "0",
                color: "white",
                cursor: "pointer",
                display: "inline-block",
                font: "inherit",
                fontFamily: global.fontFamily.primaryFont,
                fontSize: "17px",
                lineHeight: "inherit",
                margin: "0",
                marginBottom: "10px",
                maxWidth: "100%",
                padding: "9px 15px",
                paddingBottom: "12px",
                paddingTop: "12px",
                textAlign: "center",
                textDecoration: "none",
                width: "100%"
            },

            ".deletebox_wCheckbox": {
                color: global.colors.dark.color1,
                display: "block",
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "35px",
                paddingLeft: "20px",
                textAlign: "left"
            },

            ".wCheckboxInput": {
                color: "inherit",
                float: "left",
                font: "inherit",
                lineHeight: "normal",
                margin: "4px 0 0",
                marginLeft: "-20px",
                marginTop: "1px",
                padding: "0",
                textAlign: "left"
            },

            ".actionlabel_wFormLabel": {
                color: global.colors.dark.color3,
                cursor: "pointer",
                display: "inline-block",
                fontFamily: global.fontFamily.primaryFont,
                fontSize: "11px",
                lineHeight: "20px",
                marginBottom: "5px",
                textAlign: "center"
            },

            ".fieldlabel": {
                color: global.colors.dark.color1,
                display: "block",
                fontFamily: global.fontFamily.primaryFont,
                fontSize: "16px",
                lineHeight: "20px",
                marginBottom: "5px",
                textAlign: "left"
            },

            ".wCheckbox": {
                color: global.textColors.dark.color1,
                display: "block",
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "5px",
                paddingLeft: "20px",
                textAlign: "left"
            },

            ".fieldlabelsmall_wFormLabel": {
                color: global.textColors.dark.color1,
                cursor: "pointer",
                display: "inline-block",
                fontFamily: global.fontFamily.primaryFont,
                fontSize: "12px",
                lineHeight: "20px",
                textAlign: "left"
            },

            ".checkboxField_2_wCheckbox": {
                color: global.textColors.dark.color1,
                display: "block",
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "5px",
                marginTop: "5px",
                paddingLeft: "20px",
                textAlign: "left"
            },

            ".checkboxField_wCheckbox": {
                color: global.textColors.dark.color1,
                display: "block",
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "15px",
                paddingLeft: "20px",
                textAlign: "left"
            },



            ".div_CMTT": {
                color: global.textColors.dark.color1,
                fontSize: "14px",
                lineHeight: "20px",
                textAlign: "left"
            },

            ".wFormFail": {
                backgroundColor: "#ffdede",
                color: global.textColors.dark.color1,
                display: "none",
                fontSize: "14px",
                lineHeight: "20px",
                marginTop: "10px",
                padding: "10px",
                textAlign: "left"
            },
            ".deleted-title": {
                marginTop: '0px',
                marginBottom: '5px',
                fontSize: '20px',
                lineHeight: '30px',
                color: global.textColors.dark.color1
            },
            ".delete-text": {
                color: global.textColors.dark.color3,
                fontFamily: global.fontFamily.secondaryFont,
                fontSize: "14px",
                lineHeight: '24px',

            },
            ".deleted-link": {
                textDecoration: 'underline'
            }

        },

        account2_textcontainer: {
            alignItems: "flex-start",
            color: global.textColors.dark.color1,
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            justifyContent: "center",
            lineHeight: "20px",
            maxWidth: "95%",
            position: "relative",
            textAlign: "left",
            width: "700px",
            margin: "0 auto"
        },

        titleholder: {
            color: global.textColors.dark.color1,
            fontSize: "14px",
            lineHeight: "20px",
            position: "relative",
            textAlign: "left"
        },

        producttitle: {
            color: global.textColors.dark.color1,
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "25px",
            lineHeight: "44px",
            margin: "0.67em 0",
            marginBottom: "5px",
            marginTop: "0px",
            textAlign: "left"
        },

        formcontainer_wForm: {
            color: global.textColors.dark.color1,
            fontSize: "14px",
            lineHeight: "20px",
            margin: "0 0 15px",
            marginTop: "15px",
            maxWidth: "100%",
            textAlign: "left",
            width: "400px"
        },

        form_wClearfix: {
            color: global.textColors.dark.color1,
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            lineHeight: "20px",
            textAlign: "left"
        },

        inputcontainer_wClearfix: {
            color: global.textColors.dark.color1,
            display: "flex",
            flexDirection: "row",
            fontSize: "14px",
            justifyContent: "space-between",
            lineHeight: "20px",
            textAlign: "left"
        },

        firstline_inputfield_leftinput_wInput: {
            backgroundColor: global.colors.light.white,
            border: "1px solid",
            borderColor: global.colors.dark.color3,
            color: global.textColors.light.color2,
            display: "block",
            float: "left",
            font: "inherit",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            height: "38px",
            lineHeight: "1.428571429",
            margin: "0",
            marginBottom: "10px",
            marginRight: "3px",
            padding: "8px 12px",
            textAlign: "left",
            verticalAlign: "middle",
            width: "50%"
        },

        firstline_inputfield_rightinput_wInput: {
            backgroundColor: global.colors.light.white,
            border: "1px solid",
            borderColor: global.colors.dark.color3,
            color: global.textColors.light.color2,
            display: "block",
            float: "left",
            font: "inherit",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            height: "38px",
            lineHeight: "1.428571429",
            margin: "0",
            marginBottom: "10px",
            marginLeft: "3px",
            padding: "8px 12px",
            textAlign: "left",
            verticalAlign: "middle",
            width: "50%"
        },

        disabled_inputfield_wInput: {
            backgroundColor: global.colors.light.color3,
            border: "1px solid",
            borderColor: global.colors.dark.color3,
            color: global.textColors.light.color2,
            display: "block",
            float: "left",
            font: "inherit",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            height: "38px",
            lineHeight: "1.428571429",
            margin: "0",
            marginBottom: "5px",
            padding: "8px 12px",
            textAlign: "left",
            verticalAlign: "middle",
            width: "100%"
        },

        formeditaction: {
            backgroundColor: "transparent",
            color: global.textColors.dark.color3,
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "12px",
            lineHeight: "14px",
            marginBottom: "25px",
            marginTop: "4px",
            textAlign: "left"
        },

        ac3_account_actionbtn_wButton: {
            backgroundColor: global.colors.dark.color1,
            border: "0",
            borderRadius: "0",
            color: "white",
            cursor: "pointer",
            display: "inline-block",
            font: "inherit",
            ffontFamily: global.fontFamily.primaryFont,
            fontSize: "17px",
            lineHeight: "inherit",
            margin: "0",
            marginBottom: "10px",
            maxWidth: "100%",
            padding: "9px 15px",
            paddingBottom: "12px",
            paddingTop: "12px",
            textAlign: "center",
            textDecoration: "none",
            width: "100%"
        },

        deletebox_wCheckbox: {
            color: global.textColors.dark.color1,
            display: "block",
            fontSize: "14px",
            lineHeight: "20px",
            marginBottom: "35px",
            paddingLeft: "20px",
            textAlign: "left"
        },

        wCheckboxInput: {
            color: "inherit",
            float: "left",
            font: "inherit",
            lineHeight: "normal",
            margin: "4px 0 0",
            marginLeft: "-20px",
            marginTop: "1px",
            padding: "0",
            textAlign: "left"
        },

        actionlabel_wFormLabel: {
            color: global.textColors.dark.color3,
            cursor: "pointer",
            display: "inline-block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "11px",
            lineHeight: "20px",
            marginBottom: "5px",
            textAlign: "center"
        },

        fieldlabel: {
            color: global.textColors.dark.color1,
            display: "block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "20px",
            marginBottom: "5px",
            textAlign: "left"
        },

        wCheckbox: {
            color: global.textColors.dark.color1,
            display: "block",
            fontSize: "14px",
            lineHeight: "20px",
            marginBottom: "5px",
            paddingLeft: "20px",
            textAlign: "left"
        },

        fieldlabelsmall_wFormLabel: {
            color: global.textColors.dark.color1,
            display: "inline-block",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "12px",
            lineHeight: "20px",
            marginBottom: "0px",
            textAlign: "left"
        },

        checkboxField_2_wCheckbox: {
            color: global.textColors.dark.color1,
            display: "block",
            fontSize: "14px",
            lineHeight: "20px",
            marginBottom: "5px",
            marginTop: "5px",
            paddingLeft: "20px",
            textAlign: "left"
        },

        checkboxField_wCheckbox: {
            color: global.textColors.dark.color1,
            display: "block",
            fontSize: "14px",
            lineHeight: "20px",
            marginBottom: "15px",
            paddingLeft: "20px",
            textAlign: "left"
        },


        div_CMTT: {
            color: global.textColors.dark.color1,
            fontSize: "14px",
            lineHeight: "20px",
            textAlign: "left"
        },

        wFormFail: {
            backgroundColor: "#ffdede",
            color: global.textColors.dark.color1,
            display: "none",
            fontSize: "14px",
            lineHeight: "20px",
            marginTop: "10px",
            padding: "10px",
            textAlign: "left"
        },



    });

};

export default Styles;
