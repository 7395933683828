import React from "react";
import PropTypes from "prop-types";
import { css } from "@convertly/css";
import dragIcon from "components/icons/icon_drag.svg";

import _ from "lodash";
import { setElements } from "containers/Editor/actions/editorActions";
import { DragSource, DropTarget } from "react-dnd";
import { findDOMNode } from "react-dom";
import { EllipsisMenu } from "ui-components";

import styles from "./Components";

export class DraggableListItemComp extends React.Component {
  constructor(props) {
    super(props);
    this.duplicate = this.duplicate.bind(this);
    this.deleteLink = this.deleteLink.bind(this);
    this.edit = this.edit.bind(this);
    this.updateSortOrder = this.updateSortOrder.bind(this);

    this.state = {
      list: this.props.list,
    };
  }

  edit() {
    const index = this.props.objectBeingEdited.data.menu.navLinks.indexOf(this.props.link);
    this.props.openNavEditorModal(index);
  }

  duplicate() {
    const link2 = _.cloneDeep(this.props.link);
    this.props.objectBeingEdited.data.menu.navLinks.push(link2);
    this.context.store.dispatch(setElements(this.props.pageElements));
  }

  deleteLink() {
    const index = this.props.objectBeingEdited.data.menu.navLinks.indexOf(this.props.link);
    this.props.objectBeingEdited.data.menu.navLinks.splice(index, 1);
    this.context.store.dispatch(setElements(this.props.pageElements));
  }

  updateSortOrder(draggingItemIndex, hoveredItemIndex) {
    console.log(draggingItemIndex, hoveredItemIndex, "MY HEAD");
    this.props.updateSortOrder(draggingItemIndex, hoveredItemIndex);
    // const {link} = this.state;
    // const temp = links[hoveredItemIndex];
    // const temp2 = links[draggingItemIndex];
    //
    // this.setState( (prevState) => {
    //   let newLinks = prevState.links;
    //   newLinks[hoveredItemIndex] = temp2;
    //   newLinks[draggingItemIndex] = temp
    //   return { link: newLinks };
    // } )
    // console.log(this.state.list, 'ABC')
    // this.props.objectBeingEdited.data.menu.navLinks[draggingItemIndex] = temp;
    // this.props.objectBeingEdited.data.menu.navLinks[hoveredItemIndex] = temp2;
    // this.context.store.dispatch(setElements(this.props.pageElements));
  }

  render() {
    const { label, item, connectDragSource, connectDropTarget, optionsGenerator } = this.props;

    return connectDragSource(
      connectDropTarget(
        <div className={css(styles.formEditorFormFieldItem)}>
          <div className={css(styles.formEditorFormFieldItemPart)}>
            <img className={css(styles.draggingIcon)} src={dragIcon} />
            <span style={{ marginLeft: "8px" }}>{label}</span>
          </div>
          <div className={css(styles.formEditorFormFieldItemPart)}>
            <EllipsisMenu options={optionsGenerator(item)} />
          </div>
        </div>
      )
    );
  }
}

DraggableListItemComp.propTypes = {
  label: PropTypes.string,
  openNavEditorModal: PropTypes.func,
  index: PropTypes.number,
  onDelete: PropTypes.func,
};

DraggableListItemComp.defaultProps = {
  label: "",
  openNavEditorModal: () => {},
  index: 0,
  onDelete: () => {},
};

DraggableListItemComp.contextTypes = {
  store: PropTypes.object,
};

const draggingSourceSpec = {
  beginDrag: (props, monitor, component) => {
    return {
      index: props.index,
      updateSortOrder: component.updateSortOrder,
    };
  },
};

const draggingSourceCollector = (connect, monitor) => {
  return {
    isDragging: monitor.isDragging(),
    connectDragSource: connect.dragSource(),
  };
};

const draggingSource = DragSource(
  "NAV_LINK",
  draggingSourceSpec,
  draggingSourceCollector
)(DraggableListItemComp);

const draggingTargetSpec = {
  drop: (props, monitor, component) => {},
  hover: (props, monitor, component) => {
    const draggingItemIndex = monitor.getItem().index;
    const hoveredItemIndex = props.index;

    if (draggingItemIndex === hoveredItemIndex) {
      return;
    }
    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (draggingItemIndex < hoveredItemIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (draggingItemIndex > hoveredItemIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    monitor.getItem().updateSortOrder(draggingItemIndex, hoveredItemIndex);

    monitor.getItem().index = hoveredItemIndex;
  },
};

const draggingTargetCollector = (connect, monitor) => {
  return {
    connectDropTarget: connect.dropTarget(),
  };
};

const draggingTarget = DropTarget(
  "NAV_LINK",
  draggingTargetSpec,
  draggingTargetCollector
)(draggingSource);

export const DraggableListItem = draggingTarget;
