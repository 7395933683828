import { StyleSheet } from "@convertly/css";
import Typography from '../../elements/style-functions/typography';
import ButtonGlobal from '../../elements/buttons/button-global-styles';

const newsletterSignupStyle = ( global, data, buttonType ) => {

    /** Hack for convertly Site */


    return StyleSheet.create({
        newsletterWrapper: {
            position: 'relative',
            margin: "0 auto",
            overflow: "hidden",
            ".ty": {
                fontSize: '1.1em',
                transition: 'all 750ms ease',
                position: 'absolute',
                width: "100",
                top: '0%',
                left: '120vw',
                transform: 'translate( -50% )',
                overflow: "hidden",
                backgroundColor: global.colors.light.color1,
                padding: "10px 25px",
                borderRadius: "10px",
                color: global.colors.dark.color1,
                fontFamily: global.fontFamily.secondaryFont,
                "@media (max-width: 600px)": {
                    width: "90"
                },      
                ".in": {
                    left: "50%"
                }
            },
            "@media (max-width: 425px)": {
                width: "100%"
            }
        },
        newsletterSignupForm: {
            marginTop: '4px',
            position: 'relative',
            transition: 'all 750ms ease',
            left: 0,
            ".flyout": {
                left: '-900px',
                opacity: '0'
            },
        },
        nameInput: {
            width:'40%',
            padding:'9px',
            margin:'10px 0',
            border:global.border,
            borderRadius: "3px",
            color:global.colors.dark.color2,
            fontSize:'16px',
            background:global.colors.light.white,
            fontFamily: global.fontFamily.secondaryFont,
            transition: "background 100ms linear",
            display: 'block',
            ":placeholder": {
                color:global.colors.dark.color3,
            },
            ':focus': {
                outline:global.colors.accent.color1
            },
            '@media (max-width:991px)': {
                width: "100%"
            },
            '@media (max-width:478px)': {

            },
            ".disabled ": {
                backgroundColor: "rgba( 50, 50, 50, .25)"
            }
        },
        emailInput: {
            width:'55%',
            padding:'9px',
            margin:'10px 0',
            borderRadius: "3px",
            border:global.border,
            color:global.colors.dark.color2,
            fontSize:'16px',
            background:global.colors.light.white,
            transition: "background 100ms linear",
            display: 'block',
            fontFamily: global.fontFamily.secondaryFont,
            ":placeholder": {
                color:global.colors.dark.color3,
            },
            ':focus': {
                outline:global.colors.accent.color1
            },
            '@media (max-width:991px)': {
                width: "100%"
            },
            '@media (max-width:478px)': {

            },
            ".disabled ": {
                backgroundColor: "rgba( 50, 50, 50, .25)"
            }
        },
        newsletterSignupFormSubmit: {
            position: 'relative',
            display: 'flex',
            flexDirection: "column",
            fontSize: '14px',
            background: global.colors.dark.color1,
            padding: '12px 65px',
            border: '2px solid',
            fontFamily: global.fontFamily.secondaryFont,
            borderColor: global.colors.dark.color1,
            borderRadius: "3px",
            transition: 'all 0.3s linear 0s',
            fontWeight: 700,
            color: global.colors.light.white,
            ':hover': {
                opacity:'.7'
            },
            ".disabled": {
                ":hover": {
                    opacity: '1'
                }
            }
        }
    })
};
export default newsletterSignupStyle;
