/*global Inferno, $ */

const Script = () => {
	
	return function (int) {
		let currencyString = `\$${ parseFloat(int).toFixed(2) }`;
		currencyString = currencyString.replace('.00', '');
		currencyString = currencyString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return currencyString;
		
	}
};

export default Script;
