export const assignUniqueId = (() => {
  let uniqueId = 1;
  return function(arr, parentId, reset, moduleId, moduleSort, loadingPage) {
    let firstSort = 16;
    if (reset === true) {
      uniqueId = 1;
    }
    if (arr) {
      arr.sort(function(a, b) {
        return a.sort - b.sort;
      });
      arr.forEach(o => {
        o.sort = o.sort || 16;
        o.id = uniqueId;
        o.sort = firstSort;
        o.parentId = parentId;
        if (o.page) delete o.page;
        if (o.global) delete o.global;
        if (o.site) delete o.site;
        if (!o.parentId) {
          o.moduleId = uniqueId;
          o.moduleSort = o.sort;
        } else {
          if (o.editable) {
            delete o.editable;
          }
          o.moduleId = moduleId;
          o.moduleSort = moduleSort;
        }
        uniqueId++;
        firstSort = firstSort + 16;
        o.children = o.children || [];
        if (o.data && loadingPage) {
          if (o.data.activeSlide) {
            delete o.data.activeSlide;
          }
        }
        /*
		if(o.data) {
			if(o.data.site) delete o.data.site;
			if(o.data.global) delete o.data.global;
		}
		if(o.site) delete o.site;
		*/
        if (Array.isArray(o.children[0])) {
          o.children.splice(0, 1);
        }
        assignUniqueId(o.children, o.id, false, o.moduleId, o.moduleSort, loadingPage);
      });
    }
  };
})();
export default assignUniqueId;
