import React, { Component } from 'react';
import ReactHTMLParser from "@convertly/react-html-parser";
import { css } from "@convertly/css";

//utils
import { generateEmailLink } from '../../utils/link-generator';

export default class
EmailLink extends Component {

    constructor ( props ) {
        super( props );

        this.cancelClick = this.cancelClick.bind( this );
    }

    cancelClick( e ) {
        e.preventDefault();
    }

    render() {



        return (

            <a onClick={ this.cancelClick }
               className={ css( this.props.className ) }
               href={ generateEmailLink( this.props.data.email.text ) }
			   data-event="email" data-action="click"
               target="_self">{ ReactHTMLParser( this.props.data.email.text ) }</a>

        );
    }
}
