//import $ from 'jquery';

const togglePlusMinusSign = (id) => {
  return (id) => {
    const $el = $(`#${id}`);

    $el.on('click', (e) => {
      e.preventDefault();
      const $plusMinusEl = $(`#plusMinusSign${id}`);

      if ($plusMinusEl.hasClass('turnIntoMinusSign')) {
        $plusMinusEl.removeClass('turnIntoMinusSign');
      } else {
        $("*[id^='plusMinusSign']").removeClass('turnIntoMinusSign');
        $(`#plusMinusSign${id}`).toggleClass('turnIntoMinusSign');
      }
    })
  }
}

export default togglePlusMinusSign;
