const SignInUpStyles = ( global, data ) => {

    const formFieldStyles = {
        backgroundColor: global.colors.light.white,
        boxShadow: '0 0 0px 1000px white inset',
        border: "1px solid",
        borderColor: global.colors.accent.color3,
        color: global.colors.dark.color2,
        display: "block",
        fontSize: "16px",
        height: "38px",
        lineHeight: "18px",
        margin: "0",
        padding: "8px 12px",
        fontWeight: '300',
        verticalAlign: "middle",
        width: '100%',

        '.input[disabled]': {
            boxShadow: `0 0 0px 1000px ${global.colors.light.color3} inset`,
            background: global.colors.light.color3,
            borderColor: global.colors.dark.color3,
            color: global.colors.dark.color3
        },
        '::placeholder': {
            color: global.colors.dark.color3,
            fontWeight: '300',
        },
        ":focus": {
            boxShadow:'inset 1px 1px 1px 2px rgba(250,250,250,0.3)',
            outline: 'none'
        }
    };

    return {
        ".sign-in-up-form": {
            fontSize: "14px",
            lineHeight: "20px",
            marginBottom: '22px'
        },

        ".sign-in-up-textInput": {
            ...formFieldStyles,
            width: "100%"
        },
        ".sign-in-up-input-container": {
            width: "100%",
            height: '60px',
            textAlign:'left'
        },
        ".sign-in-up-button": {
            backgroundColor: "transparent",
            border: "1px solid",
            borderColor: global.colors.dark.color1,
            borderRadius: "0",
            color: global.colors.dark.color3,
            display: "inline-block",
            font: "inherit",
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "17px",
            lineHeight: "inherit",
            margin: "0px 0 15px",
            maxWidth: "100%",
            padding: "9px 15px",
            paddingBottom: "12px",
            paddingTop: "12px",
            textAlign: "center",
            textDecoration: "none",
            width: "100%",
            transition: 'all .3s ease',
            cursor: 'default',
            '.active': {
                cursor: "pointer",
                background: global.colors.dark.color1,
                color: global.colors.light.white,
                ":hover": {
                    opacity: '.8'
                }
            },
        },
        ".button-container": {
            position:'relative'
        },
        ".spinnerSVG": {
            height: '30px',
            width: '30px',
            position: 'absolute',
            right: '15px',
            top: '50%',
            transform: "translateY(-50%)"
        }
    }

};

export default SignInUpStyles
