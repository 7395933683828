import { StyleSheet } from "@convertly/css";
import Typography from "../../../../elements/style-functions/typography";
import headerNavGlobalStyles from '../../common/header-nav-global-styles';

const desktopNavStyles = ( global, data ) => {

    const transition1 = "all ease-in-out 0.5s";
    const headerNavGlobal = headerNavGlobalStyles( global, data );

    const mobileNavContainer = {
        position: "absolute",
        margin:0,
        minHeight: "100vh",
        minWidth: "100vw",
        zIndex: 1000,
        transition: transition1,
        color: global.colors.dark.color1,
        background: global.colors.light.white,
        ...headerNavGlobal
    };
    return StyleSheet.create({
        desktopNavbar: {
            zIndex: 50,
            width: "100%",
            padding: "10px",
            paddingRight: 0,
            "@media (max-width: 989px)": {
                height:'auto',
                top: 0,
                left: 0,
                zIndex: 100,
                width: "100%",
                padding: "10px",
            }
        },
        navLogo: {
            width: "100%",
            "@media (max-width: 989px)":{
                width: "50%",
                maxWidth: "400px",
                margin: "0 auto"
            }
        },
        logoImg: {
            maxWidth:'100%',
            maxHeight:'5em',
        },
        mobileNavContainer: {
            float: "right",
            display: "inline-block",
            "@media (max-width: 989px)": {
                ...mobileNavContainer,
                left: "-1000px"
            },
            '.open': {
                display: "block",
                "@media (max-width: 989px)": {
                    ...mobileNavContainer,
                    left: 0
                }
            },
        },
        navList: {
            listStyle: "none",
            float: "right",
            "@media (max-width: 989px)": {
                marginTop:'100px',
                float:"none",
                textAlign:"center"
            }
        },
        closeNavIcon: {
            paddingLeft: "10px",
            paddingTop: "10px"
        },
        mobileNavIconContainer: {
            marginTop: "20px",
            marginLeft: "5px",
            paddingBottom: "5px",
            zIndex:1001,
            '@media (max-width:475px)': {
                marginTop:'5px'
            }
        },
        navItem: {
            display: "inline-block",
            position:'relative',
            fontSize: "25px",
            marginRight: "15px",
            ":last-child:": {
                marginRight: 0
            },
            "@media (max-width: 989px)": {
                display:"block",
            }
        },
        navItemLinkActive: {
            opacity:'.7'
        },
        dropDownNavList: {
            display: "none",
            '.open': {
                background: data.backgroundColor || global.colors.light.white,
                display: "block",
                position: 'absolute',
                top:'100%',
                boxShadow: '-1px 2px 3px #999',
                borderTop:'none',
                zIndex: 12,
                width:'150px',
                textAlign:'center',
                marginTop:'20px',
                padding:'20px',
                '@media(max-width: 768px)': {
                    background: 'transparent',
                    boxShadow:'none',
                    position: 'static',
                    marginTop:0,
                    width:'100%'
                },
            },
        },
        dropDownNavItemLink: {
            fontSize:'.6em'
        },
        dropDownNavItem: {
            listStyle: "none",
            ':hover': {
                opacity:'.9'
            }
        },
        navItemContainer: {
            float:"left",
            "@media (max-width: 989px)": {
                float:"none",
                marginBottom:'15px'
            }
        },
        navLaunch: {
            display: "block",
            "@media (min-width: 989px)": {
                display: "none"
            },
            height: "40px",
            marginLeft:'-5px',
            paddingBottom: 0,
            overflow: "visible",
            background:"none",
            border:"none",
            outline: "none",
        },
        iconBar: {
            backgroundColor:global.colors.light.color3,
            width: "35px",
            height: "5px",
            marginBottom: "5px",
            position: "relative",
            display: "block",
            zIndex:10000,
            transition: 'all 0.3s ease-in-out 0s',
            '.open': {
                ":nth-child(2)":{
                    transform:"rotate(45deg)",
                    top:"7px",
                    background:global.colors.accent.color1
                },
                ":nth-child(4)": {
                    transform:"rotate(-45deg)",
                    top:"-13px",
                    background:global.colors.accent.color1
                },
                ":nth-child(3)": {
                    opacity:0
                }
            }
        },

    });
};


module.exports = desktopNavStyles;
