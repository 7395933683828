import { createAction } from "redux-act";
import { fromJS, List } from "immutable";

import { store } from "app/app";
import { findElementObject } from "../../findElementObject";
import { Emitter } from "services/Emitter";
import { setEditor, setElements } from "containers/Editor/actions/editorActions";
import { getAncestorId } from "../../getAncestorId";

const Layers = key =>
  key
    ? store
        .getState()
        .get("editor")
        .Layers.get(key)
    : store.getState().get("editor").Layers;

const Editor = key =>
  store
    .getState()
    .get("editor")
    .editorReducer.get(key);

export const setToggled = createAction("LAYERS PANEL: Set toggled layers");

const findType = (elementName, element) => {
  // elementName is `el` from JSON


  const elementsThatCanBeAddedToPage = Editor("elementsThatCanBeAddedToPage");

  let type = "";



  elementsThatCanBeAddedToPage.map(el => {
    if (el.get("element")) {
      if (elementName.includes(el.getIn(["element", "el"]))) {
        type = el.get("editorType");
        if (type === "header" || type === "footer") {
          if (element.children.length) {
            type = "background";
          }
        }
      }
    }
  });

  return type;
};

const isContainer = node => node.element.split(".")[1] === "Container";

const isSlider = node => node.element.split(".")[1] === "convertlySlider";

const getFieldFromNode = node => {
  if (isContainer(node)) {
    return node.parentId;
  }

  if (isSlider(node)) {
    return node.children.filter(obj => {
      return obj.name === "convertlySlider.sliderContainer";
    })[0].id;
  }

  return node.id;
};

export const activateLayer = ( ignoreMe, node ) => async dispatch => {
  
  const type = findType(node.element, node);
  const activeModuleId = node.moduleId;
  const isActiveComponent = node.moduleSort;
  const field = getFieldFromNode(node);

  const updates = [];

  if (type === "slide") {
    const pageElements = Editor("pageElements").toJS();

    let sliderObject = findElementObject(
      pageElements.filter(elem => {
        return elem.id === node.moduleId;
      })[0],
      getAncestorId(pageElements, node, node.moduleId, 2)
    );
    sliderObject.data.activeSlide = node.data.slideNumber;

    updates.push(dispatch(setElements(this.props.pageElements)));
  }

  updates.push(
    dispatch(
      setEditor({
        editorType: { type, field },
        activeModuleId,
        isActiveComponent,
      })
    )
  );

  await Promise.all(updates);

  Emitter.emit("SCROLL_TO_ELEMENT", node.id);
};

export const toggleAll = toggledOn => async dispatch => {
  if (!toggledOn) {
    return await dispatch(setToggled(fromJS({})));
  }

  let toggledLayers = Layers("toggledLayers");
  const pageElements = Editor("pageElements");

  toggledLayers = toggleLayers(pageElements, toggledLayers);

  await dispatch(setToggled(toggledLayers));
};

const toggleLayers = (pageElements, toggledLayers) => {
  if (!List.isList(pageElements)) {
    return toggledLayers;
  }

  pageElements.forEach(element => {
    toggledLayers = toggledLayers.set(`node${element.get("id")}`, true);
    if (element.get("children")) {
      toggledLayers = toggleLayers(element.get("children"), toggledLayers);
    }
  });

  return toggledLayers;
};

/**
 *
 * @param node is the page element object
 * @param toggled is the current state of the node
 * @returns {Function}
 */
export const toggleNode = (node, toggled) => async dispatch => {
  let toggledLayers = Layers("toggledLayers");

  if (toggled) {
    toggledLayers = toggledLayers.set(`node${node.id}`, true);
  } else {
    toggledLayers = toggledLayers.delete(`node${node.id}`);
  }

  await dispatch(setToggled(toggledLayers));
};
