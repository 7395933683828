import React from "react";
import { css } from "@convertly/css";
import Component from "../../../rootComponent";
import { id } from "@convertly/thor";

//Styles
import ModalLauncher02Styles from "./ModalLauncher02Styles";

//Scripts
import { showModalBasedOffCookie } from "../scripts/showModalBasedOffCookie";
import generateId from "../../../utils/generate-id";

class ModalLauncher02 extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { global, data, inEditor } = this.props;

    this.styles = ModalLauncher02Styles({ global, data, inEditor });

    const { modalLauncher02 } = this.styles;
    const inlineStyle = this.generateStyleAttribute();

    const rootID = id(showModalBasedOffCookie, JSON.stringify(data));

    return (
      <div
        {...inlineStyle}
        {...generateId(this.props.elementId)}
        {...this.generateDataEditorAttribute()}
        {...this.generateCustomAttributes()}
        id={`modal-launcher-02-${rootID}`}
        className={css(modalLauncher02)}
      >
        {renderChildren(this.props)}
      </div>
    );
  }
}

function renderChildren(props) {
  return React.Children.map(props.children, child => {
    return React.cloneElement(child, {
      inEditor: props.inEditor,
      moduleId: props.moduleId,
      moduleSort: props.moduleSort,
      styles: props.styles,
      site: props.site,
      sliderSettings: props.sliderSettings ? props.sliderSettings : null,
    });
  });
}

export default ModalLauncher02;