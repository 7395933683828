/**
 * Creates a menu ui
 */
import React from 'react';
import { css } from "@convertly/css";
import Component from '../../../rootComponent';
import Types from '../../../attribute-types';
import { clientImage } from '../../../utils/generateImage';

import { id } from '@convertly/thor';

import { generateColumnArray } from '../generate-column-array';


//Styles
import restaurantMenuStyles from './restaurant-menu-styles-01';

//Scripts
import restaurantMenuScript from '../scripts/restaurant-menu-script';

//Components
import { SimpleMenuItem } from '../components/simple-menu-item/simple-menu-item';
import { MenuItemWithModal } from '../components/menu-item-with-modal/menu-item-with-modal'
import Elements from '../../../elements/index';

// Scripts
import MenuItemModal from '../components/menu-item-with-modal/inferno-menu-item-modal';

//Default Props
import defaultGlobal from '../../../themes/global.js';


//Component
export default class
RestaurantMenu01 extends Component {
    constructor ( props ) {
        super( props );
    }

    render() {

        const { data, global, site } = this.props;

        /** Generates border in editor */
        const inlineStyle = this.generateStyleAttribute( );

        const inEditor = this.inEditor();
        /** init styles **/
        this.styles = restaurantMenuStyles( global, data, inEditor );

        let clientImgUrl = clientImage("", {width: 550, height: 230, crop: 'fit'});

        let styles;

        if( this.props.styles ) {
            styles = this.props.styles;
        }
        else {
            styles = this.styles;
        }

        let rootId = id( restaurantMenuScript);

        const rowArray = generateColumnArray( data );

        let menu = rowArray.map( ( childArray ) => {
            // Generate row
            return (

                <div className={css( this.styles.customRow ) }
                     onClick={( e ) => {
                         this.launchElementalEditor( 'simpleRestrauntMenu', e );
                     }}
                     onMouseOver={ (e) => {this.addEditableOutline(e)} }
                     onMouseOut={ (e) => {this.removeEditableOutline(e)} }
                     {...inlineStyle}>
                    {
                        //Generate column with menu item in it
                        childArray.map( ( childArrayItem, index ) => {
                        switch( data.menuType ) {

                            case "menu-item-with-modal":
                                return ( <MenuItemWithModal
                                    data={ childArrayItem }
                                    global={ global }
                                    inEditor={ inEditor }
                                /> );
                                break;
                            default:
                            return ( <SimpleMenuItem
                                    data={ childArrayItem }
                                    global={ global }
                                    inEditor={ inEditor }
                                /> );

                        }

                        })
                    }
                </div>
            )
        });

        return (

            <div id={ rootId } className={ css(this.styles.menuItemContainer) } >
                {
                    data.menuType === "menu-item-with-modal" &&
                    <div className={css( styles.infernoModal )}
                         id={id(MenuItemModal, clientImgUrl ) }/>
                }
                { menu }

            </div>

        )
    }
}
