const config = {
  publish_key: "pub-c-9b459218-7874-4d83-847b-f4e3dbf2e40c",
  subscribe_key: "sub-c-bc2448d2-246f-11e7-bc52-02ee2ddab7fe",
  library_src: "//cdn.pubnub.com/sdk/javascript/pubnub.4.8.0.min.js",
  requestChatChannel: "RequestChat",
  action: {
    messageFromB2C: "message_from_b2c"
  }
};

export default config;
