import React from "react";
import PropTypes from "prop-types";
import { fromJS } from "immutable";
import styled from "styled-components";
import { css } from "@convertly/css/lib/index";
import styles from "containers/Editor/components/editor/editor-forms-styles";
import { GalleryImage } from "./GalleryImage";
import { SearchBar } from "components/SearchBar";
import { graph } from "utils/graph";
import _ from "lodash";
import loadingIcon from "components/icons/loadingIcon.svg";

const noop = () => {};

const SearchWrapper = styled.div`
  margin: 20px 20px 20px 0;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`;

export class StockPhotosGallery extends React.Component {
  render() {
    const site = fromJS({});
    
    return (
      <div className={css(styles.modalCollectionContainer)} onWheel={this.props.onScroll}>
        <SearchWrapper>
          <SearchBar onChange={this.props.updatePhotosFromPexelsSearchQuery} value={this.props.search} />
        </SearchWrapper>

        {this.props.loading ? (
          <LoadingWrapper>
            <img src={loadingIcon} width="20%" />
          </LoadingWrapper>
        ) : (
          this.props.images.map(image => {
            return (
              <GalleryImage
                key={image.get("id")}
                image={{
                  // Renders tiny images so we don't end up rendering huge images for no reason
                  src: image.getIn(["src", "tiny"]),
                  filename: image.getIn(["src", "original"]),
                }}
                selectedImage={this.props.selectedImage}
                chooseImage={this.props.selectImage}
                site={site}
                showFilename={false}
              />
            );
          })
        )}
      </div>
    );
  }
}

StockPhotosGallery.defaultProps = {
  onScroll: noop,
};

StockPhotosGallery.propTypes = {
  onScroll: PropTypes.func,
};
