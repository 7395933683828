/*global Inferno */
/* eslint-disable */

import {include} from '@convertly/thor';

const Scripts = () => {

    // Thor import
    const SpinnerComponent = include ( './spinner', 'SpinnerComponent' );
    const Graph = include ( '../../utils/graph', 'Graph' );
    const Dispatcher = include( '../../utils/dispatcher', 'Dispatcher' );

    return ( id, siteId, events, listId, newsletterThankYouText ) => {

        // Reference variables for classNames in render fn
        const root = document.getElementById ( id );
        const form = document.getElementById ( `${ id }-form` );
        const name = document.getElementById (`${ id}-name`);
        const email = document.getElementById ( `${ id }-email` );
        const submit = document.getElementById ( `${ id }-submit` );

        // Initialize imported functions
        // Inferno components must be Capitalized or they will not render
        // <Spinner /> = OK
        // <spinner /> = BAD
        const Spinner = SpinnerComponent ( '50px' );
        const graph = Graph ();
        const dispatch = Dispatcher( id );

        dispatch.attach( events );

        // Set up our Graph query
        const query = `mutation($id:ID!,$fields:String!,$list:String!){newsletterSignup(id: $id,fields:$fields,list:$list){success,message}}`;

        // System error message
        const errorMessage = 'There was a problem signing you up. Please try again.';

        const NewsletterSignUp = Inferno.createClass ( {

            /**
             * Set Component state
             * @returns {{email: string, placeholder: string, submit: string, message: string, processing: boolean, complete: boolean}}
             */
            getInitialState: function () {
                return {
                    email: '',
                    name: '',
                    namePlaceholder:  name.getAttribute( 'placeholder' ),
                    emailPlaceholder: email.getAttribute ( 'placeholder' ),
                    submit: submit.getAttribute ( 'value' ),
                    message: newsletterThankYouText,
                    processing: false,
                    complete: false
                };
            },

            /**
             * Event handler for submit button
             *
             * @param e
             */
            onSubmit: function ( e ) {
                
                e.preventDefault ();

                this.setState ( {
                    processing: true
                } );

                const variables = {
                    id: siteId,
                    fields: JSON.stringify( {
                        name: this.state.name,
                        email: this.state.email
                    } ),
                    list: listId
                };

                graph ( { query, variables, cb: this.cb } );
            },

            /**
             * API callback
             *
             * @param result
             * @param error
             */
            cb: function ( error = null, result ) {
                if( ! result ) {
                    this.setState ( {
                        complete: false,
                        message: "An unexpected error happened. Please try again later.",
                        processing:false
                    } );
                    dispatch.send('snack_bar_alert', {
                        alert: this.state.message,
                        type: 'error',
                        timeout: '5000'
                    });
                    return;
                }
                if ( error ) {
                    this.setState ( {
                        complete: false,
                        message: errorMessage,
                        processing:false
                    } );
                    dispatch.send('snack_bar_alert', {
                        alert: this.state.message,
                        type: 'error',
                        timeout: '5000'
                    });
                } else {

                    const { success, message } = result.newsletterSignup;

                    if ( success ) {
                        dispatch.trigger( 'submit' );
                        this.setState ( {
                            complete: true
                        } );
                    } else {
                        this.setState ( {
                            complete: false,
                            message: message || errorMessage,
                            processing:false
                        } );
                        dispatch.send('snack_bar_alert', {
                            alert: this.state.message,
                            type: 'error',
                            timeout: '5000'
                        });
                    }

                }

            },

            /**
             * Change handler for input field
             * @param e
             */
            onNameChange: function ( e ) {

                const name = e.target.value;

                this.setState ( {
                    name: name
                } );

            },

            /**
             * Change handler for input field
             * @param e
             */
            onFieldChange: function ( field, e ) {

                const newObj = {

                }



                const value = e.target.value;

                newObj[field]= value;

                this.setState ( newObj );

            },

            onFocus: function ( e ) {

                dispatch.trigger('focus');
            },

            /**
             * Main render fn
             * @returns {XML}
             */
            render: function () {

                const spinnerStyles = {
                    position: 'absolute',
                    transform: 'translate( -50%, -50% )',
                    top: '56%',
                    left: '50%'
                };

                const disabledInputClass = this.state.processing ? " disabled" : "";
                const formStateClass = this.state.complete ? " flyout" : "";
                const tyTextClass = this.state.complete ? " in" : "";
                const messageStyles = {};
                if( ! this.state.complete ) {
                    messageStyles.opacity = 0;
                }

                return (
                    <div className={root.className}>
                        <form onSubmit={this.onSubmit} className={form.className + formStateClass}>
                            <input name="name" required disabled={this.state.processing} onInput={this.onFieldChange.bind(this,"name")}
                                   value={this.state.name} className={name.className + disabledInputClass}
                                   type="name" placeholder={this.state.namePlaceholder}/>
                            <input name="email" required disabled={this.state.processing} onInput={this.onFieldChange.bind(this,"email")} onFocus={this.onFocus}
                                   value={this.state.email} className={email.className + disabledInputClass}
                                   type="email" placeholder={this.state.emailPlaceholder}/>
                            <button disabled={this.state.processing}
                                    className={submit.className + disabledInputClass}>
                                {this.state.submit}
                                <div style={spinnerStyles}><Spinner display={this.state.processing}/></div>
                            </button>
                        </form>
                        <p style={ messageStyles } className={root.className + " ty" + tyTextClass}>{this.state.message}</p>
                    </div>
                )
            }
        } );

        Inferno.render ( <NewsletterSignUp/>, root );

    };

};

export default Scripts;
