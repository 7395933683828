import React from 'react';
import {css} from "@convertly/css";
import _ from 'lodash';
import Component from '../../../rootComponent';
import Types from '../../../attribute-types';


import {id} from '@convertly/thor';
import ReactHTMLParser from "@convertly/react-html-parser";


//Styles
import hoursOfOperationStyles from './hours-of-operation-styles-04';

//Scripts
import hoursWidget from '../scripts/hours-widget';
import LocationSelectorScript from '../scripts/location-selector';
//import openStatus from '../scripts/open-status';

//Components
import Elements from '../../../elements/index';
import SVG from '../../../svg/index';

//Default Props
import defaultGlobal from '../../../themes/global.js';

//Utils     
import {generateId, generateDataAttribute} from '../../../utils/generate-attribute';


//Component
export default class HoursOfOperation04 extends Component {
    constructor(props) {
        super(props);

    }


    render() {

        /** Calls editor */
        if (this.props.editor) {
            return this.editor();
        }

        /** Generates border in editor */
        const inlineStyle = this.generateStyleAttribute();

        /** Init styles **/
        this.styles = hoursOfOperationStyles(this.props.global, this.props.data);

        let rootId = id(LocationSelectorScript);

        let foo = true;

        const locations = _.get(this.props, 'site.locations') || [];

        return (

            <div className={ css(this.styles.wrapper) }
                 { ...this.generateDataEditorAttribute() }
                 { ...this.generateCustomAttributes() }
                 onClick={(e) => {
                     this.launchElementalEditor('hoursWidget', e);
                 }}
                 onMouseOver={ (e) => {
                     this.addEditableOutline(e)
                 } }
                 onMouseOut={ (e) => {
                     this.removeEditableOutline(e)
                 } }
                 {...inlineStyle}>
                <Elements.Container
                    data={ {"containerFluid": true} }>

                    <div className={ css(this.styles.container)}>

                        <div className={ css(this.styles.storeSelector) + ' storeSelector_' + rootId }>
                            <span>Browse Locations</span>
                            <SVG.ArrowHeadOpenDown className={ this.styles.arrow } dataTarget={ "arrow_" + rootId }/>
                        </div>

                        <div className={ css(this.styles.chooseLocation) + ' chooseLocation_' + rootId }>
                            {
                                locations.map((location, index) => {

                                    let shortAddress = location.address.replace(/[#\d]/g, '');

                                    return (
                                        <div className={ css(this.styles.location) }
                                             data-target={ index + '_' + rootId }>

                                            <span data-target={ index + '_' + rootId }
                                                  className={css(this.styles.locationName)}> { location.city }    </span>
                                            <span data-target={ index + '_' + rootId }
                                                  className={css(this.styles.locationAddress)}> { shortAddress } </span>

                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div id={'storeInfo_' + rootId}>
                            {
                                locations.map((location, index) => {

                                    let phone = (link) => {

                                        if (link.phone) {

                                            return (
                                                <div>
                                                    <Elements.Image
                                                        data={
                                                            {
                                                                "src": "icon-call2.png",
                                                                "userOverrides": {
                                                                    "margin": "0 auto"
                                                                }
                                                            }
                                                        }
                                                    />
                                                    <a className={ css(this.styles.call) } href={ `tel:${link.phone}` }
                                                       target="_blank"> { link.phone } </a>
                                                </div>
                                            )

                                        }

                                    };

                                    let email = (link) => {

                                        if (link.emailAddress) {

                                            return (
                                                <div>
                                                    <Elements.Image
                                                        data={
                                                            {
                                                                "src": "icon-email.png",
                                                                "userOverrides": {
                                                                    "margin": "0 auto"
                                                                }
                                                            }
                                                        }
                                                    />
                                                    <a className={ css(this.styles.call) }
                                                       href={ `mailto:${link.emailAddress}` }
                                                       target="_blank"> { link.emailAddress } </a>
                                                </div>
                                            )

                                        }

                                    };

                                    let contactSection = (location) => {

                                        let section;

                                        if (location) {

                                            if (location.phone || location.emailAddress) {

                                                section = (

                                                    <Elements.Column
                                                        data={
                                                            {
                                                                "smColumns": "12",
                                                                "mdColumns": "4",
                                                                "userOverrides": {
                                                                    "@media (max-width: 991px)": {
                                                                        "order": 3
                                                                    }
                                                                }
                                                            }
                                                        }>

                                                        <div className="">

                                                            { phone(location) }
                                                            { email(location) }

                                                        </div>

                                                    </Elements.Column>

                                                )

                                            } else {

                                                {/* Return an empty column so the spacing still looks nice even if there is no contact data  */
                                                }

                                                section = (
                                                    <Elements.Column
                                                        data={
                                                            {
                                                                "smColumns": "12",
                                                                "mdColumns": "4",
                                                                "userOverrides": {
                                                                    "@media (max-width: 991px)": {
                                                                        "display": "none"
                                                                    }
                                                                }
                                                            }
                                                        }>

                                                    </Elements.Column>
                                                )

                                            }

                                        }

                                        return section;
                                    }

                                    let hoursSection = (location) => {

                                        let section;

                                        if (location) {

                                            if (location.businessHours) {

                                                section = (
                                                    <Elements.Column
                                                        data={
                                                            {
                                                                "smColumns": "12",
                                                                "mdColumns": "4",
                                                                "userOverrides": {
                                                                    "@media (max-width: 991px)": {
                                                                        "order": 1
                                                                    }
                                                                }
                                                            }
                                                        }>

                                                        <Elements.Image
                                                            data={
                                                                {
                                                                    "src": "icon-today.png",
                                                                    "userOverrides": {
                                                                        "margin": "0 auto"
                                                                    }
                                                                }
                                                            }
                                                        />

                                                        <div className={ css(this.styles.hoursOfOperation) }>
                                                            <div id={ id(hoursWidget) }>

                                                                <ul className={ css(this.styles.hoursList) }>
                                                                    {
                                                                        location.businessHours.map((day, index) => {

                                                                            let openTime = '',
                                                                                closeTime = '',
                                                                                openStatus = false,
                                                                                displayOpenOrClosed = ' closed',
                                                                                hoursField;

                                                                            if (day.startHour && day.endHour) {

                                                                                openTime = day.startHour.concat(':', day.startMinutes, day.startPeriod),
                                                                                    closeTime = day.endHour.concat(':', day.endMinutes, day.endPeriod),
                                                                                    openStatus = true;
                                                                                displayOpenOrClosed = ' open';

                                                                                let currentlyOpen = (day) => {
                                                                                    let start24,
                                                                                        close24,
                                                                                        currentTime = new Date(),
                                                                                        currentHour = currentTime.getHours().toString() + currentTime.getMinutes();

                                                                                    if (day.startPeriod.toUpperCase() === "AM") {
                                                                                        start24 = day.startHour + day.startMinutes
                                                                                    } else {
                                                                                        start24 = +day.starHour + 12 + day.startMinutes
                                                                                    }

                                                                                    if (day.endPeriod.toUpperCase() === "AM") {
                                                                                        close24 = day.endHour + day.endMinutes
                                                                                    } else {
                                                                                        close24 = +day.endHour + 12 + day.endMinutes
                                                                                    }

                                                                                    if (parseInt(currentHour) >= start24 && parseInt(currentHour) <= close24) {
                                                                                        return 'OPEN';
                                                                                    } else {
                                                                                        return 'CLOSED';
                                                                                    }

                                                                                };

                                                                                hoursField =

                                                                                    <div
                                                                                        className={ css(this.styles.displayedHours) + " displayed-hours" + displayOpenOrClosed }>
                                                                                        
                                                                                    <span
                                                                                        className={ css(this.styles.hours)}>
                                                                                        { `${ openTime } - ${ closeTime }` }
                                                                                    </span>

                                                                                    </div>


                                                                            } else {

                                                                                hoursField =

                                                                                    <div
                                                                                        className={ css(this.styles.displayedHours) + " displayed-hours" + displayOpenOrClosed }>
                                                                                    
                                                                                    <span
                                                                                        className={ css(this.styles.openStatus)}>
                                                                                        CLOSED
                                                                                    </span>

                                                                                    </div>

                                                                            }

                                                                            return (
                                                                                <li className={ css(this.styles.dayOfWeek) + " hours-launcher" }
                                                                                    data-openinghours={ openTime }
                                                                                    data-closinghours={ closeTime }
                                                                                    data-dayofweek={ day.day }
                                                                                    data-openstatus={ openStatus }
                                                                                    key={ index }>
                                                                                    { day.day.slice(0, 3) }
                                                                                    { hoursField }
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </Elements.Column>
                                                )

                                            } else {

                                                section = (
                                                    <Elements.Column
                                                        data={
                                                            {
                                                                "smColumns": "12",
                                                                "mdColumns": "4",
                                                                "userOverrides": {
                                                                    "@media (max-width: 991px)": {
                                                                        "order": 1
                                                                    }
                                                                }
                                                            }
                                                        }>

                                                    </Elements.Column>
                                                )

                                            }

                                        }

                                        return section;
                                    }

                                    return (

                                        <div id={ 'store_' + index }
                                             className={ css(this.styles.locationContainer) + ' storeHours_' + rootId }>

                                            <Elements.Row
                                                data={
                                                    {
                                                        "userOverrides": {
                                                            "display": "flex",
                                                            "justifyContent": "center",
                                                            "@media (max-width: 991px)": {
                                                                "flexDirection": "column"
                                                            }
                                                        }
                                                    }
                                                }>

                                                { hoursSection(location) }

                                                <Elements.Column
                                                    data={
                                                        {
                                                            "smColumns": "12",
                                                            "mdColumns": "4",
                                                            "userOverrides": {
                                                                "@media (max-width: 991px)": {
                                                                    "order": 0
                                                                }
                                                            }
                                                        }
                                                    }>

                                                    <Elements.Image
                                                        data={
                                                            {
                                                                "src": "icon-hq.png",
                                                                "userOverrides": {
                                                                    "margin": "0 auto"
                                                                }
                                                            }
                                                        }
                                                    />

                                                    <div
                                                        className={ css(this.styles.storeName)}>   { ReactHTMLParser(location.locationName) }   </div>
                                                    <div
                                                        className={ css(this.styles.storeAddress)}>{ location.address }</div>
                                                    <div
                                                        className={ css(this.styles.storeAddress2)}>{ location.city }, { location.state } { location.zip }</div>

                                                    <Elements.GoogleMapLink
                                                        data={ {location: location, customLinkMessage: "View On Map"} }
                                                        className={ css(this.styles.viewOnMap) }/>

                                                </Elements.Column>

                                                { contactSection(location) }

                                            </Elements.Row>

                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>

                </Elements.Container>
            </div>

        )
    }
}

HoursOfOperation04.defaultProps = {
    data: {
        hoursOfOperation: [
            {
                dayOfWeek: "MONDAY",
                openStatus: true,
                openingTime: "9:00am",
                closingTime: "5:30pm",
                displayText: "mon"
            },
            {
                dayOfWeek: "TUESDAY",
                openStatus: true,
                openingTime: "9:00am",
                closingTime: "12:00pm",
                displayText: 'tue'
            },
            {
                dayOfWeek: "WEDNESDAY",
                openStatus: true,
                openingTime: "9:00am",
                closingTime: "5:30pm",
                displayText: "wed"

            },
            {
                dayOfWeek: "THURSDAY",
                openStatus: true,
                openingTime: "9:00am",
                closingTime: "5:30pm",
                displayText: "thur"
            },
            {
                dayOfWeek: "FRIDAY",
                openStatus: true,
                openingTime: "9:00am",
                closingTime: "4:30am",
                displayText: "fri"
            },
            {
                dayOfWeek: "SATURDAY",
                openStatus: false,
                openingTime: "9:00am",
                closingTime: "5:30pm",
                displayText: "sat"
            },
            {
                dayOfWeek: "SUNDAY",
                openStatus: false,
                openingTime: "9:00am",
                closingTime: "5:30pm",
                displayText: "sun"
            }
        ]

    },
    global: defaultGlobal
};
