import React from 'react';
import Component from '../../../rootComponent';
import { css } from "@convertly/css";

//Default Data
import defaultData from '../footer-default-data';
import defaultGlobal from '../../../themes/global';

//Styles
import footerStyles from './footer-styles-02';

//Components
import FooterNav1 from '../components/footer-nav-list-1';
import NewsLetterSignUp1 from '../../../widgets/newsletter-signup/newsletter-signup-1';
import SocialIcons1 from '../../../widgets/social-icons/no-background-social-icons/social-icon-list-1';

export default class
Footer2 extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        this.styles = footerStyles( this.props.global, this.props.data );

        if( this.props.editor ) {
            return this.editor();
        }
        const inlineStyle = this.generateStyleAttribute();
        return (
            <footer id="footer02" className={ css( this.styles.mainFooter ) + " h-card" } itemProp="siteFooter"
                itemScope itemType="http://schema.org/siteFooter"
                onClick={(e) => {
                    this.launchElementalEditor('footer', e);
                }}
                onMouseOver={ this.addEditableOutline }
                onMouseOut={ this.removeEditableOutline }
                { ...inlineStyle }
                { ...this.generateDataEditorAttribute() }
            >
                <div className="container">
                    <div className="row">
                        <div className={ css( this.styles.footerIn ) }>
                            <div className={ css( this.styles.footerLft ) + " col-sm-6"}>
                                <div className={ css( this.styles.footerLinks ) }>
                                        { this.props.data.footerNavLinks.map( ( navItem, index ) => {
                                            return (
                                                <FooterNav1 key={ index }
                                                            index={ index }
                                                            data={ navItem }
                                                            global={ this.props.global }
                                                            styles={ this.styles } />
                                                )}
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={ css( this.styles.footerRgt ) + " col-md-4 col-sm-6" } >
                                    <NewsLetterSignUp1 global={ this.props.global } />
                                    <SocialIcons1
                                        global={ this.props.global }
                                        data={ this.props.data }
                                        styles={ this.styles } />
                                </div>
                            <div className="clearfix"/>
                        </div>
                        <div className={ css( this.styles.copyRight ) }>
                            <div className="clearfix"/>
                            <p className={ css( this.styles.copyText ) } itemProp="description">{ this.props.data.copyRightText.text }</p>
                        </div>
                    </div>
            </footer>
        )
    }
}

Footer2.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
