/*global Inferno, $, React */
import React from 'react';

const Script = (dispatch, Spinner) => {
    return ({
                rootClass,
                searchIconPosition,
                searchBarIsExpandable,
                expandSearchBar,
                showSearchIcon,
                showXIcon,
                updateSearchQuery,
                liveSearchResults,
                getSearchResults,
                searchQuery,
                cornersAreRounded,
                loadingProducts,
                handleHeaderSearchQueryChange,
                clearSearchQuery,
                forHeaders
            }) => {

        liveSearchResults = liveSearchResults || [];

        function generateSearchPageLink() {
            const ALL_PRODUCTS_URL = "/all-products"
            return `${ALL_PRODUCTS_URL}?filters=[{"FilterType":"search","FilterValue":"${searchQuery}"}]`
        }
console.log(liveSearchResults)
        const searchIcon = (
            <div
                onClick={getSearchResults}
                className={`${rootClass} search-icon-wrapper ${searchQuery ? 'enable-click' : ''} ${showSearchIcon ? searchIconPosition : ''}`
                }>
                <svg
                    className={`${rootClass} search-icon`}
                    version='1.0'
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32">
                    <path
                        stroke='inherit'
                        d="M19.4271164,20.4271164 C18.0372495,21.4174803 16.3366522,22 14.5,22 C9.80557939,22 6,18.1944206 6,13.5 C6,8.80557939 9.80557939,5 14.5,5 C19.1944206,5 23,8.80557939 23,13.5 C23,15.8472103 22.0486052,17.9722103 20.5104077,19.5104077 L26.5077736,25.5077736 C26.782828,25.782828 26.7761424,26.2238576 26.5,26.5 C26.2219324,26.7780676 25.7796227,26.7796227 25.5077736,26.5077736 L19.4271164,20.4271164 L19.4271164,20.4271164 Z M14.5,21 C18.6421358,21 22,17.6421358 22,13.5 C22,9.35786417 18.6421358,6 14.5,6 C10.3578642,6 7,9.35786417 7,13.5 C7,17.6421358 10.3578642,21 14.5,21 L14.5,21 Z">
                    </path>
                </svg>
            </div>
        );

        const xIcon = (
            <div className={`${rootClass} x-icon-wrapper`}>
                <div
                    className={`${rootClass} x-icon`}
                    onClick={clearSearchQuery}>
                    &times;
                </div>
            </div>

        );

        const productNameDropDown = (
            <div className={`${rootClass} search-bar-product-items-list-wrapper`}>
                <ul className={`${rootClass} search-bar-product-items-list`}>
                    {loadingProducts && searchQuery ?
                        <li
                            className={`${rootClass} product-item no-hover`}
                            key={`product-item-${0}`}>
                            <Spinner
                                className={`${rootClass} spinner`}
                                display={true}/>
                        </li>
                        :
                        <div>
                            {
                                liveSearchResults.map((result, idx) => {
                                    if (result.noResults) {
                                        return (
                                            <li
                                                className={`${rootClass} product-item no-hover`}

                                                key={`product-item-${idx}`}>
												<span className={`${rootClass} product-item-text`}
                                                      data-noResults="true">{result.title}</span>
                                            </li>
                                        )
                                    } else if (result.moreResults) {
                                        return (
                                            <a
                                                key={`product-item-${idx}`}
                                                href={generateSearchPageLink()}>
                                                <li
                                                    className={`${rootClass} product-item`}
                                                    key={`product-item-${idx}`}>
                                                    {result.title}
                                                </li>
                                            </a>
                                        )
                                    } else {
                                        return (
                                            <a
                                                key={`product-item-${idx}`}
                                                href={result.slug}>
                                                <li className={`${rootClass} product-item`}>
                                                    {result.title}
                                                </li>
                                            </a>
                                        )
                                    }

                                })
                            }
                        </div>
                    }
                </ul>
            </div>
        );

        return (
            <div className={`${rootClass} search-bar-container`}>
                <div
                    className={`${rootClass} search-bar-wrapper ${searchBarIsExpandable ? 'expandable' : 'static'} ${(searchBarIsExpandable && expandSearchBar) ? 'expanded' : ''} ${searchQuery ? 'has-input' : ''}`}>
                    <div
                        className={`${rootClass} search-bar ${showSearchIcon ? searchIconPosition : ''} ${showXIcon ? 'with-x-icon' : ''}`}>
                        {
                            forHeaders ?
                                (
                                    <div className={`${rootClass} search-bar-input-wrapper`}>
                                        <input
                                            className={`${rootClass} search-bar-input`}
                                            type='text'
                                            onFocus={() => {
                                                dispatch.send('toggleDropDown', 'search-bar-results');
                                            }}
                                            onBlur={() => {
                                                window.setTimeout(function () {
                                                    dispatch.send('toggleDropDown', 'search-bar-results');
                                                }, 300)
                                            }}
                                            onKeyUp={updateSearchQuery}
                                            value={searchQuery}/>
                                        <div
                                            className={`${rootClass} search-bar-input-placeholder ${searchQuery ? 'has-input' : ''}`}>Search
                                        </div>
                                    </div>
                                ) :
                                (
                                    <input
                                        className={`${rootClass} search-bar-input`}
                                        type='text'
                                        placeholder='Search...'
                                        onFocus={() => {
                                            dispatch.send('toggleDropDown', 'search-bar-results');
                                        }}
                                        onBlur={() => {
                                            window.setTimeout(function () {
                                                dispatch.send('toggleDropDown', 'search-bar-results');
                                            }, 300)
                                        }}
                                        onKeyUp={updateSearchQuery}
                                        value={searchQuery}/>
                                )
                        }
                    </div>
                    {showSearchIcon && searchIcon}
                    {showXIcon && searchIconPosition !== 'right' && xIcon}
                </div>
                <div
                    className={`${rootClass} search-bar-results-wrapper`}
                    data-toggle='search-bar-results'>
                    <div className={`${rootClass} search-bar-results`}>
                        {
                            searchQuery.length > 0 && productNameDropDown
                        }
                    </div>
                </div>
            </div>
        )
    }


};


export default Script;
