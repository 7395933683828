//import $ from 'jquery';

const toggleBurgerDropdown = (id) => {
  return (id) => {
    const $el = $(`#${id}`);
    const $dropDownMenu = $('#dropdown-nav-menu');

    $el.on('click', (e) => {
      e.preventDefault();

      $el.children().toggleClass('open');
      $dropDownMenu.toggleClass('open');
    })

  }
}

export default toggleBurgerDropdown;
