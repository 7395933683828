//import $ from 'jquery';
//"use strict"
const navDropdownToggle = (id) => {

    return ( id ) => {

        const el = $('#' + id);
        let overlay = $('#header03Overlay');

        let $menuIcon = $('#menuIcon');
        let children = $(el).children();
        let mobileNav = $('.mobileNav_' + id);

        el.on('click', function (e) {

            e.preventDefault;

            let target = this.dataset.target;


            $(target).toggleClass('open');
            $(target).children().toggleClass('open');

            if($menuIcon.hasClass('open')) {
                $menuIcon.removeClass('open');
                children.removeClass('open');
                mobileNav.removeClass('open');
                overlay.removeClass('open')
            }

            else {
                $menuIcon.addClass('open');
                children.addClass('open');
                mobileNav.addClass('open');
                overlay.addClass('open')
            }

        });
        overlay.on('click', function() {
            let target = this.dataset.target;

            $(target).toggleClass('open');
            $(target).children().toggleClass('open');

            $menuIcon.removeClass('open');
            children.removeClass('open');
            mobileNav.removeClass('open');
            overlay.removeClass('open')
        })
    }

};


export default navDropdownToggle;
