import { StyleSheet } from '@convertly/css';
import userOverride from '../../../../elements/style-functions/user-overrides';
import removeNestedUserOverrides from '../../utils/removeNestedUserOverrides';
import _ from 'lodash';

const navigationStyles = ({ global, data, inEditor }) => {
  const navigation = removeNestedUserOverrides({ data });

  const {
    userOverrides
  } = data;
  
  const { 
    dropDownMenu, 
    dropDownItem,
    dropDownItemLink,
    dropDownArrow,
    hamburgerIconBar,
    mobileSocialIcon,
    mobileSocialIconContainer,
    navigationContainer,
    navigationLinkText,
    navigationLinkWrapper,
    navigationWrapper,
    navLaunch,
    nestedDropdownWrapper,
    nestedDropdownText,
    nestedDropdownArrowOpenDown,
    nestedDropDownMenu,
    nestedDropDownItem,
    nestedDropDownItemLink
  } = userOverrides;

  const navigationUserOverrides = !_.isEmpty(navigation) ? userOverride(global, { userOverrides: navigation }, inEditor) : {};
  const navigationLinkWrapperUserOverrides = _.get(navigationLinkWrapper, 'userOverrides') ? userOverride(global, navigationLinkWrapper, inEditor) : {};
  const dropDownMenuUserOverrides = _.get(dropDownMenu, 'userOverrides') ? userOverride(global, dropDownMenu, inEditor) : {};
  const dropDownItemUserOverrides = _.get(dropDownItem, 'userOverrides') ? userOverride(global, dropDownItem, inEditor) : {};
  const navigationWrapperUserOverrides = _.get(navigationWrapper, 'userOverrides') ? userOverride(global, navigationWrapper, inEditor) : {};
  const hamburgerIconBarUserOverrides = _.get(hamburgerIconBar, 'userOverrides') ? userOverride(global, hamburgerIconBar, inEditor) : {};
  const dropDownArrowUserOverrides = _.get(dropDownArrow, 'userOverrides') ? userOverride(global, dropDownArrow, inEditor) : {};
  const mobileSocialIconContainerUserOverrides = _.get(mobileSocialIconContainer, 'userOverrides') ? userOverride(global, mobileSocialIconContainer, inEditor) : {};
  const navigationContainerUserOverrides = _.get(navigationContainer, 'userOverrides') ? userOverride(global, navigationContainer, inEditor) : {};
  const navLaunchUserOverrides = _.get(navLaunch, 'userOverrides') ? userOverride(global, navLaunch, inEditor) : {};
  const mobileSocialIconUserOverrides = _.get(mobileSocialIcon, 'userOverrides') ? userOverride(global, mobileSocialIcon, inEditor) : {};
  const navigationLinkTextUserOverrides = _.get(navigationLinkText, 'userOverrides') ? userOverride(global, navigationLinkText, inEditor) : {};
  const dropDownItemLinkUserOverrides = _.get(dropDownItemLink, 'userOverrides') ? userOverride(global, dropDownItemLink, inEditor) : {};
  const nestedDropdownWrapperUserOverrides = _.get(nestedDropdownWrapper, "userOverrides") ? userOverride(global, nestedDropdownWrapper, inEditor) : {};
  const nestedDropdownTextUserOverrides = _.get(nestedDropdownText, "userOverrides") ? userOverride(global, nestedDropdownText, inEditor) : {};
  const nestedDropdownArrowOpenDownUserOverrides = _.get(nestedDropdownArrowOpenDown, "userOverrides") ? userOverride(global, nestedDropdownArrowOpenDown, inEditor) : {};
  const nestedDropDownMenuUserOverrides = _.get(nestedDropDownMenu, "userOverrides") ? userOverride(global, nestedDropDownMenu, inEditor) : {};
  const nestedDropDownItemUserOverrides = _.get(nestedDropDownItem, "userOverrides") ? userOverride(global, nestedDropDownItem, inEditor) : {};
  const nestedDropDownItemLinkUserOverrides = _.get(nestedDropDownItemLink, "userOverrides") ? userOverride(global, nestedDropDownItemLink, inEditor) : {};

  return StyleSheet.create({

    navigation: {
      ...navigationUserOverrides,
    },

    navigationContainer: {
      fontFamily: _.get(global, 'fontFamily.secondaryFont'),
      display: 'inline-block',
      ...navigationContainerUserOverrides
    },

    dropDownMenu: {
      cursor: "pointer",
      display: "none",
      ".open": {
        display: "block"
      },
      ...dropDownMenuUserOverrides,
    },

    navigationLinkText: {
      display: "inline-block",
      position: "relative",
      ...navigationLinkTextUserOverrides
    },

    navArrowOpenDown: {
      display: "inline-block",
      width: "17px",
      ...dropDownArrowUserOverrides
    },

    dropDownItem: {
      listStyle: "none",
      ...dropDownItemUserOverrides
    },

    dropDownItemLink: {
      ...dropDownItemLinkUserOverrides
    },

    navigationLinkWrapper: {
      display: "inline-block",
      listStyle: "none",
      cursor: "pointer",
      position: 'relative',
      "text-align": "left",
      ...navigationLinkWrapperUserOverrides
    },

    navigationWrapper: {
      ...navigationWrapperUserOverrides,
    },

    navLaunch: {
      display: 'none',
      outline: 'none',
      border: 'none',
      overflow: 'visible',
      backgroundColor: 'inherit',
      '.mobile': {
        display: 'unset'
      },
      ...navLaunchUserOverrides
    },

    nestedDropdownWrapper: {
      ...nestedDropdownWrapperUserOverrides
    },

    nestedDropdownText: {
      ...nestedDropdownTextUserOverrides
    },

    nestedDropdownArrowOpenDown: {
      ...nestedDropdownArrowOpenDownUserOverrides
    },

    nestedDropDownMenu: {
      ...nestedDropDownMenuUserOverrides
    },

    nestedDropDownItem: {
      ...nestedDropDownItemUserOverrides
    },

    nestedDropDownItemLink: {
      ...nestedDropDownItemLinkUserOverrides
    },


    iconBar: {
      backgroundColor: 'black',
      width: '24px',
      height: '2px',
      marginBottom: '9px',
      position: 'relative',
      display: 'block',
      transition: 'all 0.3s ease-in-out 0s',
      '.open': {
        ':nth-child(1)': {
          transform: 'rotate(45deg)',
          top: "3px",
        },
        ':nth-child(3)': {
          transform: 'rotate(-45deg)',
          top: '-13px',
        },
        ':nth-child(2)': {
          opacity: '0'
        },
      },
      ...hamburgerIconBarUserOverrides
    },

    mobileSocialIcons: {
      display: "none",
      ...mobileSocialIconUserOverrides
    },

    socialIconContainer: {
      height: '20px',
      width: '20px',
      backgroundColor: 'transparent',
      borderRadius: '50%',
      backgroundSize: '30px',
      ...mobileSocialIconContainerUserOverrides
    },

    socialIcons: {
      paddingTop: "5px"
    },

    socialIcon: {
      height: '100%',
      width: '100%',
      padding: '3px',
      fill: 'inherit'
    },

  });

}

export default navigationStyles;