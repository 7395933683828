/*global Inferno, $ */
/* eslint-disable */
import { include } from '@convertly/thor';

const Script = ( id ) => {

    /** Imports **/

    const Dispatcher = include( '../../../../utils/dispatcher', 'Dispatcher' );

    return( id, productId, productPrice, quantity ) => {

        const hasLocalStorage = $( 'html' ).hasClass( 'localstorage' );

        const root = document.getElementById( id );
        /** Init Imports **/

        //Convertly Utils
        const dispatch = new Dispatcher( id );


        const TheCart = Inferno.createClass( {

            getInitialState: function( ) {

                return {
                    quantity: 1
                };
            },
            increaseQuantity: function( ) {
                this.setState( ( prevState, props ) => ( {
                    quantity: prevState.quantity + 1
                } ) );
                this.adjustQuantity()
            },
            decreaseQuantity: function( ) {
                if( this.state.quantity > 1 ) {
                    this.setState( ( prevState, props ) => ( {
                        quantity: prevState.quantity - 1
                    } ) );
                }
                this.adjustQuantity()
            },
            adjustQuantity: function( ) {

                dispatch.send( 'stepper_adjusted', this.state.quantity );
            },

            render: function( ) {

                return(
                    <div className={ `${root.className} qtycontainer` }>
                                        <a onClick={ this.decreaseQuantity } className={ `${root.className} counterindicator` } href="#">-</a>
                                            <div className={ `${root.className} counternumber` }>{ this.state.quantity }</div>
                                        <a onClick={ this.increaseQuantity } className={ `${root.className} counterindicator` } href="#">+</a>
                                    </div>

                )
            }

        } );

        Inferno.render( <TheCart />, root );

    };

};

export default Script;
