import _ from "lodash";
//makes function to color look up
function generateColorLookupFunction( executor ) {
  return function (colors) {
    //init lookup
    let colorLookup = {};
    //pull out the keys
    const keys = Object.keys(colors);
    //iterate over the keys
    keys.forEach(key => {
      for (let i = 1; i <= 3; i++) {
        //check for duplicates, the hierarchy is in order of
        //smaller to greater
        if (!colorLookup.hasOwnProperty(colors[key][`color${i}`])) {
          //run through the executor to build out the object
          colorLookup = executor(colorLookup, colors[key][`color${i}`], `${key}${i}` )

        }
      }
    });
//return that puppy
    return colorLookup
  }
}

//builds lookup object by theme var nam eg dark1
export const lookupByThemeVar = generateColorLookupFunction( (obj, color, themeVar ) => {
    obj[themeVar] = color;
    return obj
  }
);

//builds lookup object by theme var nam eg #000:dark1
export const lookupByColorValue = generateColorLookupFunction( (obj, color, themeVar ) => {
  obj[color] = themeVar;
  return obj
  }
)

function handleFontLookup ( executor ) {
  return (fonts) => {

  }

}
