//import $ from 'jquery';
//toggles nav's drop down icons
const navDropdownToggle = (id) => {

    return (id) => {


        (function () {
            /**
             * Select root id
             */
            const $el = $('#' + id);
            const el = document.getElementById(id);

            /**
             * Handles opening and closing of drop down
             */
            $el.on('click', function (e) {
                e.preventDefault;
                let target = this.dataset.target;
                let $caret = $('.caret');

                if ($(target).hasClass('open')) {
                    $(target).toggleClass('open');
                    $(this).find('svg').toggleClass('open');

                }

                else {
                    $('ul[data-category="dropdown"]').removeClass('open');
                    $(target).toggleClass('open');
                    $caret.removeClass('open');
                    $(this).find('svg').toggleClass('open');

                }
                if ($('ul[data-category="dropdown"]').hasClass('open')) {

                    $('body').on('click', function (e) {
                        if(e.target == el) {
                            return
                        }
                        else {
                            $('ul[data-category="dropdown"]').removeClass('open');
                            $caret.removeClass('open');
                        }
                    });

                }


            })
        })();
    };


};
export default navDropdownToggle;
