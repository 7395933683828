const lightboxMeBaby = id => {
  return id => {
    const divOverlayElement = document.createElement("div");
    const overlayClass = "overlay_748932489234394927492";
    const imgClickClass = "click_82978938989423979239";
    const divClickClass = "click_845943953495793894739";
    const pageAppElement = document.getElementById("page-app");

    divOverlayElement.classList.add(overlayClass);
    divOverlayElement.style.cssText =
      "display: none; transition: opacity 0.6s linear; position: fixed; top: 0; left: 0; width: 100vw; opacity: 0; height: 100vh; background-color: black; z-index: 9999";
    pageAppElement.appendChild(divOverlayElement);
    divOverlayElement.addEventListener("click", clickHandler("none"));

    const $galleryWrapper = document.getElementById(id);
    const $imageCollection = $galleryWrapper.children;

    for (let imgWrapper of $imageCollection) {
      let imgLink = "";
      let img = imgWrapper.children[0];

      if (img.src) {
        imgLink = img.src;
      } else if (img.dataset["imageSource"]) {
        imgLink = img.dataset["imageSource"];
      } else {
        continue;
      }

      img.addEventListener("click", clickHandler(imgLink));
    }

    function clickHandler(imageLink) {
      if (imageLink !== "none") {
        const newImgElement = document.createElement("img");
        newImgElement.classList.add(imgClickClass);
        newImgElement.src = imageLink;
        return function() {
          if (divOverlayElement.classList.contains(divClickClass)) {
            return;
          }
          divOverlayElement.classList.add(divClickClass);
          divOverlayElement.style.display = "block";
          divOverlayElement.style.opacity = "0.8";
          newImgElement.style.cssText =
            "position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: 9999; border: 5px solid white; border-radius: 5px; max-width: 60vw; max-height: 60vh;";
          pageAppElement.appendChild(newImgElement);
        };
      } else {
        return function() {
          divOverlayElement.style["pointer-events"] = "none";
          divOverlayElement.style.opacity = "0";
          removeChildren();
          setTimeout(function() {
            divOverlayElement.style.display = "none";
            divOverlayElement.classList.remove(divClickClass);
            divOverlayElement.style["pointer-events"] = "unset";
          }, 600);
        };
      }

      function removeChildren() {
        const imgCollection = document.querySelectorAll("." + imgClickClass);
        for (let img of imgCollection) {
          pageAppElement.removeChild(img);
        }
      }
    }
  };
};

export default lightboxMeBaby;
