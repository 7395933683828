import { fromJS } from "immutable";
import { createReducer } from "redux-act";
import { getPixelNumber } from "./helpers";

import {
  setSnapshot,
  setActiveStyle,
  setActiveElement,
  setThemeData,
  setLivePreview,
  setIsNewTheme,
  setThemes,
  setNewThemeName,
  setSearch,
  setThumbnail,
  setThemeName,
  setUpdateThemeData,
  setLoaded,
  resetSaveModal,
  setResetState,
} from "./actions";

const initialParams = {
  activeStyle: "TEXT",
  activeElement: "p",
  themeData: {},
  themes: [],
  snapshot: {},
  showModal: false,
  selectedCategory: "ALL_THEMES",
  saveThemeAs: "CURRENT_THEME",
  livePreview: true,
  isNewTheme: true,
  newThemeName: "",
  themeName: "",
  search: "",
  thumbnail: "",
  loaded: false,
  resetState: 1,
};

const initialState = fromJS(initialParams);

const reducers = {
  [setResetState]: (state, resetState) => state.merge({ resetState }),

  [resetSaveModal]: state =>
    state.merge({
      isNewTheme: true,
      newThemeName: "",
    }),

  [setLoaded]: (state, loaded) => state.merge({ loaded }),

  [setUpdateThemeData]: (state, themeData) => {
    state.merge({
      themeData,
    });
  },

  [setThemeName]: (state, themeName) =>
    state.merge({
      themeName,
    }),

  [setThumbnail]: (state, thumbnail) =>
    state.merge({
      thumbnail,
    }),

  [setSearch]: (state, search) => state.merge({ search }),

  [setNewThemeName]: (state, newThemeName) => state.merge({ newThemeName }),

  [setThemes]: (state, themes) => state.merge({ themes }),

  [setSnapshot]: (state, snapshot) => state.merge({ snapshot }),

  [setActiveStyle]: (state, selectedOption) =>
    state.merge({
      activeStyle: selectedOption.key,
      activeElement: selectedOption.first,
    }),

  [setActiveElement]: (state, activeElement) =>
    state.merge({ activeElement, resetState: state.resetState++ }),

  [setThemeData]: (state, themeData) => state.merge({ themeData }),

  [setLivePreview]: (state, livePreview) => state.merge({ livePreview }),

  [setIsNewTheme]: (state, isNewTheme) =>
    state.merge({
      isNewTheme,
    }),
};

export const ThemesPanelReducer = createReducer(reducers, initialState);
