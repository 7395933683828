import React from 'react';
import Component from 'source/rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';
import _ from 'lodash';

//Default Props
//import defaultData from '';
const defaultData = {};
import defaultGlobal from 'source/themes/global';

//Styles
import styles from './pricing-info-styles';

//utils
import {generateLink} from 'source/utils/generateLink';

//Components


//Scripts
import script from './pricing-info-script';



export default class PricingInfo extends Component {

    constructor(props) {

        super(props);

    }

    render() {

        let { data } = this.props,
        annualPrice  = data.annualPrice,
        monthlyPrice = data.monthlyPrice,
        heading      = data.heading,
        paragraph    = data.paragraph; 

        this.styles = styles(this.props.global, this.props.data);

        if (this.props.editor) {
            return this.editor();
        }


        return (
            <div id={ id( script ) } className={ css( this.styles.priceInfoBox ) }>

               <div>
                   <a className={ css( this.styles.button ) + ` active` }>Annual</a>
                   <a className={ css( this.styles.button ) }>Monthly</a>
               </div>

               <div className={ css( this.styles.priceAnnual ) + ` option active` }>
                   <span>{ annualPrice }</span>
                   <span className={ 'yr' }>/mo</span></div>

               <div className={ css( this.styles.priceMonthly ) + ` option` }>
                   <span>{ monthlyPrice}</span>
                   <span className={ 'mo' }>/mo</span></div>

               <div className={ css( this.styles.heading ) }>{ heading }</div>
               
               <div className={ css( this.styles.paragraph ) }>{ paragraph }</div>

            </div>
        )
    }
}


PricingInfo.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
