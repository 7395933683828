import React, { Component } from "react";
import PropTypes from "prop-types";
import { css } from "@convertly/css";
import styles from "../StoreFront/css-storefront-styles";

import Confetti from "react-dom-confetti";

export const cssStyle = {
  loadIcon: {
    fontSize: "16px",
    marginRight: "3px",
  },
  buttonStyle: (disabled, processing, gradatedBackground, textColor, width, marginLeft) => ({
    backgroundColor: "#FFF",
    background: gradatedBackground,
    color: textColor,
    width: width || "",
    marginLeft: marginLeft,
    cursor: disabled || processing ? "not-allowed" : "pointer",
    display: "flex",
    justifyContent: "center",
  }),
};

export class PillButton extends Component {
  /**
   * Class constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      fireConfetti: false,
    };
  }

  render() {
    let {
      disabled,
      processing,
      label,
      textColor,
      backgroundColor,
      action,
      marginLeft,
      className,
      width,
      large,
    } = this.props;

    let gradatedBackground =
      backgroundColor === "#FFF" ? "#FFF" : "linear-gradient(90deg, #8BD9B2 0%, #45AA91 100%)";

    gradatedBackground = disabled || processing ? "#CCC" : gradatedBackground;

    if (label === "...") {
      label = <i className="material-icons">more_horiz</i>;
    }

    const showLoadIcon = this.props.showLoadIcon || processing;

    return (
      <button
        className={css(large ? styles.pillButtonLarge : styles.pillButton) + " " + className}
        disabled={disabled}
        style={{
          ...cssStyle.buttonStyle(
            disabled,
            processing,
            gradatedBackground,
            textColor,
            width,
            marginLeft
          ),
          lineHeight: "2em",
        }}
        onClick={action}
      >
        {!showLoadIcon ? (
          label
        ) : (
          <i style={cssStyle.loadIcon} className={"fa fa-spinner fa-pulse fa-3x fa-fw"} />
        )}
      </button>
    );
  }
}

PillButton.defaultProps = {
  showLoadIcon: false,
  disabled: false,
  processing: false,
  label: "",
  backgroundColor: "#52AF52",
  textColor: "#FFF",
  marginLeft: 20,
  action: () => {},
  className: "",
  large: false,
};

PillButton.propTypes = {
  showLoadIcon: PropTypes.bool,
  disabled: PropTypes.bool,
  processing: PropTypes.bool,
  label: PropTypes.string,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  marginLeft: PropTypes.number,
  width: PropTypes.number,
  action: PropTypes.func,
  className: PropTypes.string,
  large: PropTypes.bool,
};

export default PillButton;
