import { createAction } from "redux-act";

import { updateUserObject } from "./Auth";
import { loadSite } from "services/sites";
import { LocalStorage } from "utils/localStorage";
import Logger from "utils/logger";
import { graph, parse } from "utils";
import { is } from "./PermissionsCheck";
import { store } from "app/app";
import { LogRocket } from "utils/logRocket";

import { setUserPermissions } from "containers/PartnersDashboard/actions";

// used in Reducer
//export const loginSuccess = createAction();
export const restoreSavedUserSession = createAction("Auth: RESTORE_SAVED_USER_SESSION");
export const restoreSavedUserSessionFailure = createAction(
  "Auth: RESTORE_SAVED_USER_SESSION_FAILURE"
);
export const setUser = createAction("Auth: Set current user");
export const setAccount = createAction("Auth: Set current account");
export const setSites = createAction(`Auth: Set user sites`);
export const setCurrentSite = createAction("Auth: Set active site - 1");
export const setCurrentSiteSettings = createAction("Auth - Set active site settings");
export const setSite = createAction("Auth: Set active site - 2");
export const setAuthLoginToken = createAction("Auth: Set login token");
export const setAuthenticationSetupComplete = createAction(
  "Auth: Toggle authentication setup complete flag"
);
export const setAuthAccessToken = createAction("Auth: Set auth token");
export const setAccessToken = createAction("Auth: Set access token");

/**
 *
 * @param AccessToken
 * @param RefreshToken
 * @param accessPermissions
 * @param remember
 * @returns {function(*)}
 */
export function loginSuccess({ AccessToken, RefreshToken, accessPermissions, remember }) {
  Logger.auth("Authentication/actions::loginSuccess()");

  return async dispatch => {
    const { authenticationToken } = accessPermissions;
    const user = buildUserWithAccessPermissions(accessPermissions);

    updateUserObject(true);

    if (RefreshToken) {
      LocalStorage.setItem("refresh", RefreshToken);
    }

    LogRocket.setUser(user);

    LocalStorage.setItem("user", user.username);

    await Promise.all([
      dispatch(setAuthLoginToken(authenticationToken)),
      dispatch(setAccessToken(AccessToken)),
    ]);

    await Promise.all([dispatch(setupUserSites(user)), dispatch(setUser(user))]);

    Logger.auth("Authentication/actions::loginSuccess() DONE");
    await dispatch(setAuthenticationSetupComplete());

    if (is("partner")) {
      const activeSite = store.getState().getIn(["auth", "activeSite", "siteId"]);
      if (activeSite) {
        await dispatch(setUserPermissions());
      }
    }

    return user;
  };
}

/**
 *
 * @param AccessToken
 * @returns {function(*)}
 */
export function updateUser(AccessToken) {
  Logger.auth("Authentication/actions::updateUser()");

  return async dispatch => {
    const { user } = await getUserWithAccessPermissions(AccessToken);

    await dispatch(setUser(user));

    Logger.auth("Authentication/actions::updateUser() DONE", user);

    return user;
  };
}

/**
 *
 * @param user
 * @returns {function(*)}
 */
export function setupUserSites(user) {
  return async dispatch => {
    Logger.auth("setupUserSites", user);

    if (!user.sites.length) {
      Logger.auth("WARNING:", "User has no sites");
      return;
    }

    Logger.auth("dispatch( setSites( sites )");
    await dispatch(setSites(user.sites));

    let currentSite;

    if (user.sites.length) {
      currentSite = user.sites[0];
    }

    Logger.auth("user sites", user.sites);

    if (LocalStorage.getItem("active-site")) {
      const savedActiveSite = LocalStorage.getItem("active-site");
      user.sites.map(site => {
        if (savedActiveSite === site.id) {
          currentSite = site;
        }
      });
    }

    Logger.auth("dispatch( setCurrentSite( currentSite ) )");
    await dispatch(setCurrentSite(currentSite));

    Logger.auth("dispatch( loadSite( currentSite ) )");
    await dispatch(loadSite(currentSite));
  };
}

const buildUserWithAccessPermissions = accessPermissionsObject => {
  const { isSubscriber, trialStart, user } = accessPermissionsObject;

  let accessPermissions = parse(accessPermissionsObject.permissions) || {};
  let subscriptionData = parse(accessPermissionsObject.subscriptionData) || {};
  let currentPlan = parse(accessPermissionsObject.currentPlan) || {};

  user.isSubscriber = isSubscriber;
  user.trialStart = trialStart;
  user.stripeCustomerData = subscriptionData;
  user.sub = user.id;
  user.accessPermissions = accessPermissions;
  user.currentPlan = currentPlan;

  user.first_name = user.firstName;
  user.last_name = user.lastName;

  delete user.firstName;
  delete user.lastName;

  return user;
};

/**
 *
 * @param accessToken - Cognito accessToken
 * @returns {Promise}
 */
const getUserWithAccessPermissions = async accessToken => {
  Logger.auth("Authentication/actions::getUserWithAccessPermissions()");

  const query = `query ( $token: ID! ) {
		  AccessPermissions( accessToken: $token ) {
				accessPermissions
				authenticationToken
				isSubscriber
				trialStart
				subscriptionData
				currentPlan
				user { firstName, lastName, name, username, email, id, completedOnBoarding, roles }
		  }
		}`;

  const variables = {
    token: accessToken,
  };

  const { AccessPermissions } = await graph({ query, variables });
  const { authenticationToken, isSubscriber, trialStart, user, trialEnd } = AccessPermissions;

  const accessPermissions = JSON.parse(AccessPermissions.accessPermissions);
  const subscriptionData = JSON.parse(AccessPermissions.subscriptionData);

  user.isSubscriber = isSubscriber;
  user.trialStart = trialStart;
  user.stripeCustomerData = subscriptionData;
  user.sub = user.id;
  user.accessPermissions = accessPermissions;
  user.currentPlan = JSON.parse(AccessPermissions.currentPlan);

  user.first_name = user.firstName;
  user.last_name = user.lastName;

  delete user.firstName;
  delete user.lastName;

  return { authenticationToken, user };
};

/**
 * Clears local storage. Redirects to /login
 *
 * @returns {function(*)}
 */
export function logout() {
  return async dispatch => {
    await dispatch(clearStoredData());
    window.location.href = window.location.origin + "/login";
  };
}

export const clearStoredData = () => async dispatch => {
  LocalStorage.clear();
};

// used in app
export const actions = {
  isAuthenticated() {
    return false;
  },

  setup() {
    return async dispatch => {
      dispatch(restoreSavedUserSession());
    };
  },
};
