import React from "react";
import Styled from "styled-components";

const SectionWrapperDiv = Styled.div`
	margin: 0 0px 0px;
	overflow: hidden;
	display: flex;
	align-items: center;
	width: 100%;
`;

export const SectionWrapper = ({ style, children }) => (
  <SectionWrapperDiv style={style}>{children}</SectionWrapperDiv>
);
