import { StyleSheet } from "@convertly/css";
import Typography from '../../../../elements/style-functions/typography';
import ModuleStyles from '../../../../elements/style-functions/module-global-styles';

const HeaderStyles4 = ( global, data, headerPosition ) => {

    let modifiedColor;
    let overlayBorderStyle = {};
    let overlayFixedNavLink = {};

        if( headerPosition === "overlayFixed" || headerPosition === "overlay" ) {
            overlayBorderStyle = {
                '.absolute': {
                    borderColor: global.backgroundColors.light.white,
                    color: global.textColors.light.white,
                    stroke: global.textColors.light.white,
                    fill:global.textColors.light.white
                }
            };
        }


        if( headerPosition === "overlay" ) {
            modifiedColor = global.textColors.light.white
        }
        else {
            modifiedColor = global.textColors.light.white
        }


        const caret = {
            display: "inline-block",
            height: '17px',
            width: '17px',
            cursor: 'pointer',
            boxSizing: 'content-box',
            marginTop: '-6px',
            marginLeft: '5px',
            stroke: 'inherit',
            position:'absolute',
            top:'27px',
            right:'-13px',
            '@media( max-width:1201px )': {
                top:'34px',
                right: '-17px'
            }

        };

        const navListItem = {
            display:'inline-block',
            cursor:'pointer',
            fontSize: '18px',
            stroke: 'inherit',
            position:'relative',
            margin: "0 10px",
            "@media (max-width: 768px)": {
                padding: "0px"
            },
            "@media (max-width: 768px)": {
                padding: "10px"
            }

        };
        const dropdown= {
            float:'right',
            listStyle: 'none',
            height: 'auto',
            width:'fit-content',


        };
        const dropdownList = {
            display: 'none',
            position:'absolute',
            top:'140%',
            right:'-34px',
            background:global.backgroundColors.light.white,
            boxSizing:'content-box',
            listStyle: 'none',
            padding:'20px 20px 8px',
            minWidth:'270px',
            zIndex:'1',
            '.open': {
                display:'block'
            },
            '@media( max-width:768px )': {
                top: '205%',
                right: '3px'
            },
            '@media( max-width:420px)': {
                right:'0'
            }

        };




    return StyleSheet.create({
        menuContainer: {
            display: 'flex',
            justifyContent: 'center',
            padding:'25px 0px',
            borderColor: modifiedColor,
            borderRadius:'1px',
            fill: modifiedColor,
            color:modifiedColor,
            height: "80px",
            ...overlayBorderStyle
        },
        logo: {
            position: "absolute",
            left: "15px",
            width: 'auto',
            maxHeight: "35px" ,
            "@media (max-width: 768px)": {
                position: "static",
                order: "2"
            }

        },
        logoImg: {
            width: 'auto',
            maxHeight: "35px",  
            '.absolute': {
                display:'block'
            },
            '.default': {
                display:'block'
            }

        },
        menuLauncherIcon: {
            display: 'none',
            order: 2,
            float:'right',
            marginRight:"15px",
            marginTop: '8px',
            '@media( max-width:768px )': {
                display: "block",
                marginRight:'0',
                position: "absolute",
                left: "10px"
            }


        },
        navLaunch: {
            zIndex:45,
            display: "none",
            paddingBottom: 0,
            overflow: "visible",
            background:"none",
            border:"none",
            outline: "none",
            '@media( max-width:768px )': {
                display:'block'
            }
        },
        iconBar: {
            backgroundColor:modifiedColor,
            width: "20px",
            height: "3px",
            marginBottom: "3px",
            position: "relative",
            display: "block",
            zIndex:50,
            transition: 'all 0.3s ease-in-out 0s',
            ':nth-child(3)': {
                width:"16px"
            },
            ".absolute": {
                backgroundColor: global.backgroundColors.light.white,

            },
            '.open': {
                ":nth-child(2)":{
                    transform:"rotate(45deg)",
                    top:"7px"
                },
                ":nth-child(4)": {
                    transform:"rotate(-45deg)",
                    top:"-5px"
                },
                ":nth-child(3)": {
                    opacity:0
                }
            },
        },
        mainMenu: {
            zIndex: 45,
            width:'fit-content',
            '.open': {
                display: 'block',
                visibility:'visible',
                left: '0',
            },
        },
        headerNav: {
            marginTop: "5px",
            display:'inline-block'
        },
        navList: {
            listStyle: 'none',
            height: 'auto',
            width:'fit-content',
            transition:'position 1s ease',
            '@media ( max-width:768px )': {
                paddingBottom: "10px",
                position:'absolute',
                top:'-640%',
                right:'0',
                width:'100%',
                background: "rgba(0,0,0,0.9)"
            },
            '.open':{
                top:80,
                zIndex:2,
                minHeight:'80px',
                '@media( max-width:768px )': {

                }
            }
        },
        navListItem: {
            width: "fit-content",
            opacity: ".4",
            ...navListItem,
            ".active": {
                opacity: "1",
                transition: "100ms ease"
            },
            ":hover": {
                "@media (min-width: 768px)": {
                    transition: "100ms ease",
                    opacity: "1"
                }
            },
            '@media ( max-width:768px )': {
                display: "block",
                color:global.textColors.dark.color1
            },
        },

        contactNavListItem: {
            ...navListItem,
            padding:'24px 10px 0 10px',
            '@media( max-width: 768px )': {
                marginTop:'-6px',
                textDecoration: 'underline'
            },
            '@media( max-width: 768px )': {
                marginTop:'10px',
                textDecoration: 'underline'
            },
        },
        caret: {
            ...caret,
            '@media( max-width: 768px )': {
                stroke:global.backgroundColors.dark.color1
            }
        },
        contactCaret: {
            ...caret,
            '@media( max-width: 768px )': {
                visibility:'hidden'
            }
        },
        dropdown: {
            ...dropdown,
            '@media( max-width:768px)': {
                float:'none',

            }

        },
        contactDropdown: {
            ...dropdown
        },
        dropdownList: {
            ...dropdownList,
            '@media( max-width:768px )': {
                paddingTop:'20px',
                position:'static'
            },

        },
        contactDropdownList: {
            ...dropdownList,

        },
        dropDownItem: {
            display: 'none',
            position:'absolute',
            top:'140%',
            right:'0',
            width:"200px",
            background:'#fff',
            listStyle: 'none',
            padding:'10px',
            marginBottom:'20px',
            '.open': {
                display:'block'
            }

        },
        contactListHeader: {
            display:'block',
            width:'50%',
            borderBottom:'1px solid',
            paddingBottom:'12px',
            color:global.textColors.dark.color1,
            borderColor: global.backgroundColors.dark.color1,
            textTransform: 'uppercase',
            fontSize:'14px',
            fontWeight:'bold'
        },
        arrowUp: {
            width: 0,
            height: 0,
            position:'absolute',
            top:'-10px',
            right:'20px',
            borderLeft: '10px solid transparent',
            borderRight: '10px solid transparent',
            borderBottom: '10px solid',
            borderBottomColor: global.backgroundColors.light.white
        },
        downNavItem: {
            color: global.textColors.dark.color1,
            //fill: global.textColors.dark.color1,
            margin:'20px 0',
            ':hover': {
                opacity:'.8'
            },

        },
        dropdownIconContainer: {
            fill:global.backgroundColors.dark.color1,
            display:'inline-block',
            width:'10%',
            verticalAlign:'middle'
        },
        menuIcon: {
            width:'18px',
            height:'18px',


        },
        contactLinkContainer: {
            display:'inline-block',
            marginLeft:'5px',
            width:'85%',
            verticalAlign:'middle'
        },
        contactDownNavText: {
            fontSize:'12px',
            lineHeight:'12px',
            padding:'10px 12px 10px 5px',

        },
        dropDownlink: {
            fontSize: '15px'
        },

        ctaLink: {
            position: "absolute",
            right: "25px"
        },

        navLink: {
            color: global.colors.light.white,
            fontFamily: global.fontFamily.primaryFont
        }

    });

};

export default HeaderStyles4
