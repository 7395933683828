import  colorAssign  from '../colorAssign';

let defaultGlobal = {
    colors: {
        light: {
            color1:'#FAF9F8',
            color2:'#F3F4F8',
            color3:'#707374',
            white: "#fff"
        },
        dark: {
            color1:'#012550',
            color2:'#3D3D3D',
            color3:'#757575',
            black:'#000'
        },
        accent: {
            color1:'#FFA243',
            color2:'#CCECF7',
            color3:'#72AAD3',
        },
    },
    textColors: {
        light:{},
        dark:{},
        accent:{}
    },
    backgroundColors: {
        light:{},
        dark:{},
        accent:{}
    },
    "moduleDefault": {
        margin:'50px 0',
        padding:'50px 0px',
        fontFamily: "'Montserrat', sans-serif",
        "@media( max-width:991px )": {
            margin:"25px 0",
            padding:"25px 0"
        }
    },
        themeBorder: {
        border: '3px solid #002550',
    },
    /** Typography **/
    fontFamily: {
        primaryFont: "'Montserrat', sans-serif",
        secondaryFont: " 'Montserrat', sans-serif"
    },
    fonts: [ 'Work Sans', 'Montserrat' ],
    h1: {
        fontSize: "85px",
        margin:'20px 0',
        lineHeight: "95px",
        "@media (max-width: 991px)": {
            fontSize: "70px",
            lineHeight: "80px"
        },
        "@media (max-width: 767px)": {
            fontSize: "50px",
            lineHeight: "60px"
        },
        "@media (max-width: 479px)": {
            fontSize: "40px",
            lineHeight: "50px"
        }
    },
    h2: {
        margin:'10px 0',
        fontSize: '35px',
        lineHeight: '45px',
    },
    h3: {
        fontSize: '27px',
        lineHeight:"37px"
    },
    h4: {
        color: '#5F5F5F',
        fontSize: '14px',
        lineHeight: '24px',
    },
    h5: {
        fontSize: '34px',
        lineHeight:'44px',
    },
    h6: {
        color: '#002550',
        fontSize:'12px',
        lineHeight:'22px',
        fontFamily: " 'Montserrat', sans-serif"
    },
    p: {
        margin: '10px 0',
        color: '#5f5f5f',
        fontSize: '15px',
        lineHeight: '25px',
    },
    list: {
        marginBottom: '20px',
        paddingLeft: '30px'
    },
    listItem: {
        marginBottom:'10px'
    },
    /** Misc Styles */
    border: '3px solid #002550',
    divider: {
        backgroundColor: "#2d343d",
        width: "70%",
        height: "3px",
        marginTop: "10px",
        marginBottom: "10px"
    },
    figCaption: {
        position:'absolute',
        bottom:'5px',
        background:'rgba(0, 0, 0, .5)',
        width:'100%',
        padding:'30px 20px',
        color:'#fff',
        "@media (max-width: 479px)": {
            padding:'10px'
        }
    },
    /** Buttons **/
     /** Buttons **/
    button1: {
        display: 'inline-block',
        marginBottom: '15px',
        padding: '10px 25px',
        border:'none',
        fontSize:'14px'
    },
    button2: {
        display: 'inline-block',
        marginBottom: '15px',
        padding: '20px 45px',
        border:'none',
        fontSize:'14px'
    },
    button3: {
        display: 'inline-block',
        marginBottom: '15px',
        padding: '40px 100px',
        fontSize:'18px'
    },
    bodyBackgroundColor: ''
};

export default function theme ( global ) {

    global = global ? global : defaultGlobal;

    colorAssign( global );

    //Body Background Color
    global.bodyBackgroundColor = global.backgroundColors.light.color2;
    //Button Styles

    const defaultButtonStyles = {
        background: global.backgroundColors.dark.color1,
        color: global.textColors.light.white,
        borderColor: global.backgroundColors.dark.color1,
        fontFamily: global.fontFamily.primaryFont
    };
    const alternateButtonStyles = {
        background: global.backgroundColors.accent.color2,
        textColor: global.textColors.light.white,
        fontFamily: global.fontFamily.primaryFont,
        borderColor: global.backgroundColors.accent.color2
    };

    global.button1 = {
        ...global.button1,
        ...defaultButtonStyles
    };

    global.button1A = {
        ...global.button1,
        ...alternateButtonStyles
    };

    global.button2 = {
        ...global.button2,
        ...defaultButtonStyles
    };

    global.button2A = {
        ...global.button2,
        ...alternateButtonStyles
    };

    global.button3 = {
        ...global.button3,
        ...defaultButtonStyles
    };

    global.button3A = {
        ...global.button3,
        ...alternateButtonStyles
    };

    return global;


}
