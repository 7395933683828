import React from 'react';
import PropTypes from 'prop-types';
import {css} from "@convertly/css";
import Component from '../../../rootComponent';
import {id} from '@convertly/thor';
import {clientImage} from '../../../utils/generateImage';
import {generateLink} from '../../../utils/generateLink';
import {frontloadConnect} from '@convertly/react-frontload';
import {deeplog} from '../../../utils/deeplog';


//Inferno Components
import ProductGrid from './inferno-components/product-grid-inferno-component';

import axios from 'axios';
import _ from 'lodash';

import {getProducts} from '../grid-meta-helpers/get-products';
import {handleVariants} from '../grid-meta-helpers/handle-variants';
import {handleCategories} from '../grid-meta-helpers/handle-categories';
import Elements from 'source/elements';

//INFERNO COMPONENTS
import ProductFiltrationInferno from '../../../ecommerce-common-components/product-filtration/product-filtration-01/product-filtration-01';
import ArrowDownOpenInferno from '../../../svg/arrows/arrow-down-stateless';
import PriceSliderInferno from '../../../ecommerce-common-components/price-slider/price-slider';
import SearchBarInferno from '../../../ecommerce-common-components/search-bar/SearchBar';
import SpinnerSVGInferno from '../../../svg/spinner/spinner';
import SortByDropdownInferno from '../../../ecommerce-common-components/pagination/components/sort-by/SortByDropdown';
import SortByDropdownItemInferno from '../../../ecommerce-common-components/pagination/components/sort-by/SortByDropdownItem';
import SortByDropdownMenuInferno from '../../../ecommerce-common-components/pagination/components/sort-by/SortByDropdownMenu';
import SortByMobileModalInferno from '../../../ecommerce-common-components/pagination/components/sort-by/SortByMobileModal';
import PaginationInferno from '../../../ecommerce-common-components/pagination/Pagination';
import PageJumpInferno from '../../../ecommerce-common-components/pagination/components/page-jump/PageJump';
import PageJumpBySelectionInferno from '../../../ecommerce-common-components/pagination/components/page-jump/PageJumpBySelection';
import PageJumpSingleInferno from '../../../ecommerce-common-components/pagination/components/page-jump/PageJumpSingle';
import QuickViewIconInferno from '../../../svg/quick-view/quick-view-svg';
import PriceInferno from './inferno-components/components/price';
import BuyingOptionsWrapperInferno from '../../../ecommerce-common-components/buying-options/buying-options-wrapper/buying-options-wrapper';
import AddToCartStepperWidget from '../../../ecommerce-common-components/buying-options/add-to-cart-stepper/add-to-cart-qty-stepper';
import PlusIconInferno from '../../../svg/plus-icon/plus-icon';
import MinusIconInferno from '../../../svg/minus-icon/minus-icon';
import QuickBuyInferno from '../../../ecommerce-common-components/buying-options/quick-buy/quick-buy';
import BubbleVariantContainerInferno from '../../../ecommerce-common-components/product-variant-widgets/bubble-variant-container/bubble-variant-container';
import BubbleInferno from '../../../ecommerce-common-components/product-variant-widgets/bubble/bubble';
import VariantProgressBarInferno from '../../../ecommerce-common-components/product-variant-widgets/product-variant-progress-bar/product-variant-progress-bar';
import SkeletonLoaderInferno from '../../skeleton-loader-layout/skeletonLoadingLayout';
//Styles
import productGridStyles from './product-grid-styles-01';

import ReactHTMLparser from '@convertly/react-html-parser';

import EcommerceSettings from '../../../ecommerce/ecommerce-settings';

import generateId from '../../../utils/generate-id';

import ConvertToCurrency from '../../../utils/ecomm-utils/convert-to-currency-ii';


let regeneratorRuntime = require("regenerator-runtime");


let products = [];
let filtrationMeta = {};
let defaultCategories = [];
let date;
const noop = (e) => {
    e.preventDefault();
};
let loaded = false;
let hasVariants = false;

/** Register Inferno Components**/
/*** FILTRATION ***/
const convertToCurrency = ConvertToCurrency();
const ArrowDownOpen = ArrowDownOpenInferno();
const PriceSlider = PriceSliderInferno(convertToCurrency);
const Spinner = SpinnerSVGInferno();
const SearchBar = SearchBarInferno(null, Spinner);
const ProductFiltration = ProductFiltrationInferno(
    {},
    ArrowDownOpen,
    PriceSlider,
    SearchBar
);

/*** SORT ***/
const SortByDropdownItem = SortByDropdownItemInferno();
const SortByDropdownMenu = SortByDropdownMenuInferno(SortByDropdownItem);
const SortByMobileModal = SortByMobileModalInferno({});
const SortByDropdown = SortByDropdownInferno(SortByDropdownMenu, {}, ArrowDownOpen, SortByMobileModal);

/*** PAGINATION ***/
const PageJumpBySelection = PageJumpBySelectionInferno('', {}, ArrowDownOpen);
const PageJumpSingle = PageJumpSingleInferno(ArrowDownOpen);
const PageJump = PageJumpInferno('', {}, PageJumpBySelection, PageJumpSingle);
const Pagination = PaginationInferno(PageJump);

/*** PRODUCT GRID ITEM ***/
const QuickViewIcon = QuickViewIconInferno();
const Price = PriceInferno();
const PlusIcon = PlusIconInferno();
const MinusIcon = MinusIconInferno();
const QuickBuy = QuickBuyInferno({});
const AddToCartStepper = AddToCartStepperWidget({}, MinusIcon, PlusIcon);
const BuyingOptionsWrapper = BuyingOptionsWrapperInferno({}, AddToCartStepper, QuickBuy);
const VariantProgressBar = VariantProgressBarInferno();
const Bubble = BubbleInferno();
const BubbleVariantContainer = BubbleVariantContainerInferno({}, VariantProgressBar, Bubble);




/** Get Products from db **/
const loadProductsAsync = (props, component) => {
    let disableFiltration = JSON.stringify(_.get(props, 'data.disableFiltration'));
    return new Promise((resolve, reject) => {

        if (loaded && _.get( component, 'props.inEditor' )) {

            return resolve();
        }


        products.length = 0;


        let query = `query($id: ID!, $filters: [Filter], $limit: Int, $startIndex: Int,$initialCategories:[String]){
						ProductGridMeta(siteId: $id, filters: $filters, limit: $limit, startIndex: $startIndex,initialCategories:$initialCategories ) {
          Products {id slug price description title sku onSale active inStock images { src alt } salePrice discountValue discountType variationTypeMeta }
					ProductCount
					DefaultCategories { defaultCategoryName defaultCategoryId }
          	}
          }`;

        if (disableFiltration !== 'true') {

            query = `query($id: ID!, $filters: [Filter], $limit: Int, $startIndex: Int,$initialCategories:[String]){
						ProductGridMeta(siteId: $id, filters: $filters, limit: $limit, startIndex: $startIndex,initialCategories:$initialCategories ) {
						ProductCount
						Categories { categoryName categoryId count }
						PricingFilterMeta { minPrice maxPrice }
						Products {id slug price description title sku onSale active inStock images { src alt } salePrice discountValue discountType variationTypeMeta }
						Variations { variantName values { valueName count } }
						DefaultCategories { defaultCategoryName defaultCategoryId }
						}
          }`;
        }

        const variables = {id: props.site.siteId};
        const {columns, rows} = props.data;

        const local = false;

        const url = local ? 'http://localhost:4010' : 'https://g.convertly.com';

        const params = {
            url: url,
            method: 'post',
            data: {
                query: query,
                variables: variables
            },
            headers: {
                Authorization: 'convertly-front-load',
            }
        };
        if (props.data.defaultCategories) {
            variables.initialCategories = props.data.defaultCategories;
        }
        if (rows >= 0 && columns >= 0) {

            params.data.variables.limit = rows * columns;
            params.data.variables.startIndex = 0;
        }

        axios(params)

            .then((res) => {

                const {Products, Variations, Categories, PricingFilterMeta, ProductCount, DefaultCategories} = local ? res.data.data.ProductGridMeta : res.data.ProductGridMeta;

                let productData = getProducts(Products, hasVariants);
                let now = new Date();
                date = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
                hasVariants = productData.hasVariants;
                products = productData.filteredProducts;
                defaultCategories = DefaultCategories;
                if (disableFiltration !== 'true') {

                    filtrationMeta.variations = Variations;
                    filtrationMeta.categories = Categories;
                    filtrationMeta.pricingFilterMeta = PricingFilterMeta;
                }
                filtrationMeta.productCount = ProductCount;
                if (component && _.get( component, 'props.inEditor' )) {
                    component.forceUpdate();
                }


                loaded = true;

                resolve();

            })
            .catch((err) => {
                //deeplog(err, 'Axios error');
                deeplog(err.message, 'Axios error');
                deeplog( params );
                resolve();
            });

    });
};

const frontload = async (props) => loadProductsAsync(props);


const options = {
    noServerRender: false,
    onMount: true,
    onUpdate: true
};


class ProductGrid03 extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        sortMeta: [
            {
                title: 'Price',
                leftOption: 'low',
                rightOption: 'high',
                mobileText1: 'Price (Low to High)',
                mobileText2: 'Price (High to Low)',
                active: false,
                toggle: false,
                value1: 'priceLow',
                value2: 'priceHigh'
            },
            {
                title: 'Name',
                leftOption: 'A-Z',
                rightOption: 'Z-A',
                mobileText1: 'Product Name (A to Z)',
                mobileText2: 'Product Name (Z to A)',
                active: false,
                toggle: false,
                value1: 'az',
                value2: 'za'
            }
        ],
        maxBubbleWidth: 300
    };

    componentWillMount() {
			  super.componentWillMount();
        if (!loaded && this.props.inEditor) {
            return loadProductsAsync(this.props, this);
        }
    }

    splitIntoPaginationArrays(products) {
        let pageArray = [];
        let rowArray = [];
        let productArray = [];
        products.map((product, index) => {
            if (( index + 1 ) % this.props.data.columns === 0 && index !== products.length - 1) {
                productArray.push(product);
                rowArray.push(productArray);
                productArray = [];
                if (parseInt(index + 1) === parseInt(products.length)) {
                    pageArray.push(rowArray);
                    rowArray = [];
                }
                else if (parseInt(rowArray.length) === parseInt(this.props.data.rows)) {
                    pageArray.push(rowArray);
                    rowArray = [];
                }
            }
            else {
                productArray.push(product);
                if (parseInt(index + 1) === parseInt(products.length)) {
                    rowArray.push(productArray);
                    pageArray.push(rowArray);
                    rowArray = [];
                    productArray = [];
                }
            }
        });

        return pageArray
    }

    getOptionsCount(variationTypeMeta) {
        let totalCount = 0;
        variationTypeMeta.map((variant) => {
            totalCount += variant.values.length;
        });

        return totalCount;
    }

    shouldComponentUpdate() {
        return false
    }

    componentDidMount() {

    }

    render() {

        this.styles = productGridStyles(this.props.global, this.props.data, this.inEditor());

        const {data, global} = this.props;

        const {disableFiltration, disableSort, disablePagination, rows, columns} = data;

        let pageArray = this.splitIntoPaginationArrays(products);

        let stringifiedProducts = JSON.stringify(products);
        const strinifiedFiltrationMeta = JSON.stringify(filtrationMeta);

        stringifiedProducts = stringifiedProducts.replace(/(?:\r\n|\r|\n)/g, '<br/>');

        const siteLink = generateLink({linkType: "inferno"});
        const imageUrl = clientImage("", {width: '555', height: '300', crop: 'fit'});
        let imageUrls = {
            twoColumnGrid: clientImage("", {width: '555', height: '300', crop: 'fit'}),
            threeColumnGrid: clientImage("", {width: '360', height: '300', crop: 'fit'}),
            fourColumnGrid: clientImage("", {width: '219', height: '125', crop: 'pad'}),
            sixColumnGrid: clientImage("", {width: '165', height: '225', crop: 'fit'}),
            thumbnail: clientImage("", {width: '61', height: '61', crop: 'fit'}),
            featureImage: clientImage("", {width: '555', height: '300', crop: 'fit'}),
            globalImg: clientImage("B317D7A25DF5B-", {width: '555', height: '300', crop: 'fit'})
        };
        /** Skeleton Loader **/
        const SkeletonLoader = SkeletonLoaderInferno(imageUrls);


        let ecommerceSettings = EcommerceSettings(this.props.site);

        ecommerceSettings = JSON.stringify(ecommerceSettings);

        const maxProducts = rows * columns;
        let count = 0;

        let renderFilter = hasVariants && JSON.stringify(disableFiltration) !== 'true' && strinifiedFiltrationMeta;

        const inlineStyle = this.generateStyleAttribute();

        const rootClass = css(this.styles.productGridInferno);

        //grid className
        let colClass = "col-sm-6 col-xs-6";
        let clientImgSrc = imageUrls.twoColumnGrid;
        if (parseInt(columns) === 3) {
            clientImgSrc = imageUrls.threeColumnGrid;
            colClass = `col-md-4 col-sm-4 col-xs-12 col-${ columns }-sm-xs-buffer`
        }
        else if (parseInt(columns) === 4) {
            clientImgSrc = imageUrls.fourColumnGrid;
            colClass = `col-md-3 col-sm-6 col-xs-12 col-${ columns }-sm-xs-buffer`
        }
        else if (parseInt(columns) === 6) {
            clientImgSrc = imageUrls.sixColumnGrid;
            colClass = `col-md-2 col-sm-4 col-xs-12 col-${ columns }-sm-xs-buffer`
        }

        imageUrls = JSON.stringify(imageUrls);

        /** Category Name **/
        let categoryName = '';
        if (defaultCategories)
            defaultCategories.map((cat) => {
                categoryName += cat.defaultCategoryName + " "
            });
        if (_.get(this.props.page, 'slug') !== '/products') {

            return (


                <div
                    className={ css(this.styles.productGridContainer) }
                    { ...this.generateDataEditorAttribute() }
                    { ...this.generateCustomAttributes() }
                    {...generateId(this.props.elementId)}
                    {...inlineStyle}>
                    <div className={ `container ${ css(this.styles.containerOverride) } section-title` }>
                        <div className="row">
                            <div className={ css(this.styles.sectionTitleContainer) + " col-xs-12"}>
                                <h2 className={ css(this.styles.sectionTitle) }>{categoryName || "All Products" }<span
                                    className={ css(this.styles.productCount) } ><span id="productCount">{`(${filtrationMeta.productCount}`}</span> products)</span> </h2>
                            </div>
                        </div>
                    </div>
                    <div data-products={ stringifiedProducts }
                         data-filtration={strinifiedFiltrationMeta}
                         data-categories={ JSON.stringify(defaultCategories) }
                         id="productMeta"/>
                    <div className={ rootClass }
                         id={ id(
                             ProductGrid,
                             "",
                             siteLink,
                             imageUrl,
                             columns,
                             rows,
                             ecommerceSettings,
                             imageUrls,
                             this.props.site.siteId,
                             hasVariants,
                             disableFiltration,
                             disableSort,
                             disablePagination,
                             _.get(this.props.page, 'slug')
                         )}>

                        <div>

                            <div className="container">
                                {
                                    JSON.stringify(disableFiltration) !== 'true' &&
                                    strinifiedFiltrationMeta &&
                                    <ProductFiltration
                                        rootId={ id }
                                        filtrationController={ null }
                                        activeFilters={ [] }
                                        filtrationMeta={ filtrationMeta }
                                        rootClass={ rootClass }
                                        updateSearchQuery={ noop }
                                        getSearchResults={ noop }
                                        searchQuery={ "" }
                                        loadingProducts={ false }
                                        liveSearchResults={ [] }
                                        toggleRefineModal={ noop }
                                        showRefineModal={ false }/>
                                }

                                <div className={ `${rootClass} product-grid-container ${renderFilter ? "with-filter" : ""}` }>
                                    {
                                        // Pagination:
                                        //
                                        // Simple logic that will only render pagination if it is enabled
                                        // and there are more than one page.
                                        // The logic is based on the amount of product and the limit of
                                        // products per page. If There is a greater number of products than
                                        // there are limited, then there will be more than one page.

                                        JSON.stringify(disablePagination) !== 'true' &&
                                        filtrationMeta.productCount > maxProducts &&
                                        <div className={ `${ rootClass } pagination-container` }>

                                            <div className={ `${ rootClass } mobile-refine-sort-by-wrapper` }>
                                                <div
                                                    onClick={ this.toggleRefineModal }
                                                    className={ `${ rootClass } refine-placeholder-container` }>
                                                    <div className={ `${ rootClass } refine-placeholder-button` }>
                                                        <div className={ `${ rootClass } refine-placeholder-text mobile` }>
                                                            Refine
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    JSON.stringify(disableSort) !== 'true' &&
                                                    this.state.sortMeta &&
                                                    <SortByDropdown
                                                        viewport='mobile'
                                                        sortMeta={ this.state.sortMeta }
                                                        toggleSwitch={ noop}
                                                        rootClass={ rootClass }/>
                                                }
                                            </div>

                                            <div className={ `${ rootClass } pagination-wrapper` }>
                                                {
                                                    JSON.stringify(disableSort) !== 'true' &&
                                                    this.state.sortMeta &&
                                                    <SortByDropdown
                                                        sortMeta={ this.state.sortMeta }
                                                        toggleSwitch={ noop }
                                                        rootClass={ rootClass }/>
                                                }

                                                <Pagination
                                                    rootClass={ rootClass }
                                                    pages={ Math.ceil(filtrationMeta.productCount / ( columns * rows )) }
                                                    currentPage={ 1 }
                                                    footer={ false }/>
                                            </div>
                                        </div>
                                    }
                                    { pageArray &&
                                    pageArray.map((row, index) => {
                                        return (

                                            <div key={ `product-page-${index}` }>

                                                {row.map((row, index) => {
                                                    return (
                                                        <div key={ `product-row-${index}` } className="row">
                                                            { row.map((product) => {
                                                                let productHasVariants = product.variationTypeMeta.length !== 0;
                                                                return (
                                                                    <div key={ `product-page-${product.id}` }
                                                                         className={ `${rootClass} productBox  ${colClass}` }>

                                                                        <div className={ `${rootClass} productBoxWrapper` }>
                                                                            <div className={ `${rootClass} imageContainer col-${ columns }-styles`}>
                                                                                {/** Feature Image **/ }
                                                                                <Elements.Image
                                                                                    data={{
                                                                                        src: (_.isArray(_.get(product, 'images')) && product.images[0] ) ? `${clientImgSrc}${ product.images[0].src}` : '',
                                                                                        alt: product.title
                                                                                    }}
                                                                                    parentClass={ `${rootClass} productImg col-${ columns }-styles` }
                                                                                />
                                                                                { /** Add to cart/quick view **/}
                                                                                <div className={ `${rootClass} cart-quick-view-container` }>
                                                                                    <div
                                                                                        className={ `${rootClass} buying-option-wrapper-pg-container${ productHasVariants ? '.no-variance' : '' } col-${ columns }-styles` }>
                                                                                        <div className={ `${rootClass} gridBuyingOptions` }>
                                                                                            <BuyingOptionsWrapper rootClass={ rootClass }
                                                                                                                  currentQty={ 1 }
                                                                                                                  showAddToCart={ product.variationTypeMeta.length === 0 }
                                                                                                                  disableAddToCart={ false }
                                                                                                                  resetButtonState={ noop }
                                                                                                                  increaseQuantity={ noop }
                                                                                                                  decreaseQuantity={ noop }
                                                                                                                  addToCart={ noop }
                                                                                                                  toggleMobileOptions={ noop }
                                                                                                                  product={ product }
                                                                                                                  columnCount={ columns }/>
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        productHasVariants &&
                                                                                        <div
                                                                                            onClick={ columns !== '6' && columns !== '4' ? (e) => this.displayQuickViewModal(e) : () => window.location += product.slug.slice(1) }
                                                                                            className={ `${rootClass} quickViewSVGContainer col-${ columns }-styles` }>
                                                                                            {/** Quick View Icon **/}
                                                                                            <QuickViewIcon
                                                                                                nameOfClass={ `${rootClass} quickViewSVG col-${ columns }-styles` }/>
                                                                                        </div>
                                                                                    }
                                                                                </div>

                                                                                {/** Img Overlay **/}
                                                                                {
                                                                                    productHasVariants &&
                                                                                    <div
                                                                                        className={ `${rootClass} imageOverlay imgOverlay col-${ columns }-styles ${ false && 'with-filter' }` }>
                                                                                        { /** Product Variants and buying options **/ }
                                                                                        <BubbleVariantContainer
                                                                                            rootClass={ rootClass }
                                                                                            checkProductOptionSelection={ () => {
                                                                                                return false
                                                                                            } }
                                                                                            handleProductVariantArray={ noop }
                                                                                            updateVariationTypeMeta={ noop }
                                                                                            maxBubbleWidth={ this.state.maxBubbleWidth }
                                                                                            outOfStockVariations={ [] }
                                                                                            variationTypeMeta={ product.variationTypeMeta }
                                                                                            selectedProductVariants={ [] }
                                                                                            product={ product }
                                                                                        />
                                                                                    </div>
                                                                                }
                                                                            </div>

                                                                        </div>
                                                                        <div
                                                                            className = {
                                                                                `${rootClass} productDescriptionWrapper ${ !productHasVariants ? 'no-variance' : '' } col-${ columns }-styles`
                                                                            } >
                                                                            <div className={ `${rootClass} productDescription` }>
                                                                                {
                                                                                    productHasVariants &&
                                                                                    <h4
                                                                                        className={ `${rootClass} subTitle` }>{ `${this.getOptionsCount(product.variationTypeMeta)} Options` }</h4>
                                                                                }
                                                                                <div className={ `${rootClass} productTitleSection` }>


                                                                                    <h3
                                                                                        className={ `${rootClass} productTitle ${ !productHasVariants ? 'no-variance' : '' } col-${ columns }-styles` }>{ product.title }</h3>
                                                                                </div>
                                                                                {/** Price **/}
                                                                                <div className={ `${rootClass} gridPricing col-${ columns }-styles` }>
                                                                                    <Price rootClass={ rootClass }
                                                                                           convertToCurrency={ convertToCurrency }
                                                                                           currentQty={ this.state.currentQty }
                                                                                           productId={ product.id }
                                                                                           pricing={ product.pricing }/>
                                                                                    { product.pricing.onSale && <div
                                                                                        className={ `${rootClass} on-sale-pricing col-${ columns }-styles` }>
                                                                                        On Sale</div> }
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className={ `${rootClass} details-button-wrapper col-${ columns }-styles ${ product.pricing.onSale ? 'on-sale' : '' }`}>
                                                                                <a href={ product.slug } className={ `${ rootClass } details-button`}>details</a>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    )

                                                })}
                                            </div>

                                        )
                                    })

                                    }
                                    {
                                        JSON.stringify(disablePagination) !== 'true' &&
                                        filtrationMeta.productCount > maxProducts &&
                                        <div className={ `${ rootClass } pagination-wrapper-mobile` }>
                                            <Pagination
                                                rootClass={ rootClass }
                                                pages={ Math.ceil(filtrationMeta.productCount / ( maxProducts )) }
                                                currentPage={ 1 }
                                                footer={ true }/>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            );
        }

        else {
            return (
                <div
                    className={ css(this.styles.productGridContainer) }
                    { ...this.generateDataEditorAttribute() }
                    { ...this.generateCustomAttributes() }
                    {...generateId(this.props.elementId)}
                    {...inlineStyle}>

                    <div 
                      className={ `container ${ css(this.styles.containerOverride) }` }>
                        <div className="row">
                            <div className={ css(this.styles.sectionTitleContainer) + " col-xs-12"}>
                                <h2 className={ css(this.styles.sectionTitle) }>{categoryName || "All Products" }<span
                                    className={ css(this.styles.productCount) }>{`(${filtrationMeta.productCount} products)`}</span></h2>
                            </div>
                        </div>
                    </div>
                    <div data-products={ stringifiedProducts }
                         data-filtration={strinifiedFiltrationMeta}
                         data-categories={ JSON.stringify(defaultCategories) }
                         id="productMeta"/>
                    <div className={ rootClass }
                         id={ id(
                             ProductGrid,
                             "",
                             siteLink,
                             imageUrl,
                             columns,
                             rows,
                             ecommerceSettings,
                             imageUrls,
                             this.props.site.siteId,
                             hasVariants,
                             disableFiltration,
                             disableSort,
                             disablePagination,
                             _.get(this.props.page, 'slug')
                         )}>
                        <SkeletonLoader rootClass={ rootClass } numOfColumns={ columns }/>
                    </div>
                </div>
            )
        }
    }
}

ProductGrid03.contextTypes = {
    store: PropTypes.object
};

ProductGrid03.displayName = 'ProductGrid03';

export default frontloadConnect(frontload, options)(ProductGrid03);
