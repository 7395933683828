import { createAction } from "redux-act";
import { store } from "app/app";
import { clientImage } from "source/utils/generateImage";
import { uploadImages, uploadImage } from "services/sites";
import { updateValue } from "./index";

export const setFavicon = createAction("Site Settings: Set site favicon");
export const setFaviconFilename = createAction("Site Settings: Set site favicon filename");
export const setFaviconPreview = createAction("Site Settings: Set site favicon preview url");
export const setFaviconIsNew = createAction("Site Settings: Set site favicon is new flag");

export function loadFavicon() {
  return async dispatch => {
    const site = store
      .getState()
      .get("auth")
      .get("activeSite");
    const favicon = site.get("favicon");

    if (!favicon) {
      return;
    }

    await dispatch(setFaviconFilename(favicon));
    await dispatch(
      setFaviconPreview(
        clientImage(favicon, { width: 32, height: 32, crop: "fit" }, site.get("id"))
      )
    );
  };
}

export function updateFavicon(files) {
  return async dispatch => {
    console.log("Here is files ", files);

    const filename = await dispatch(uploadImage(files[0], false));
    await dispatch(setFavicon(files[0]));
    await dispatch(setFaviconFilename(filename));
    await dispatch(setFaviconPreview(files[0].preview));
    await dispatch(setFaviconIsNew(true));
  };
}

export function updateLogo(files) {
  return async dispatch => {
    const filename = await dispatch(uploadImage(files[0], false));
    const logo = store.getState().getIn(["auth", "activeSite", "logo"]) || {};
    await dispatch(updateValue("logo", logo.set("src", filename)));
  };
}
