import { StyleSheet } from '@convertly/css';
import UserOverrides from '../../../elements/style-functions/user-overrides';

const ModalLauncher02Styles = ({ global, data, inEditor }) => {
  
  const userOverrides = new UserOverrides(global, data, inEditor);
  
  return StyleSheet.create({
    modalLauncher02: {
      ...userOverrides
    }
  })
};

export default ModalLauncher02Styles;