import React from 'react';
import Component from '../../rootComponent';
import { css } from "@convertly/css";
import ReactHTMLParser from "@convertly/react-html-parser";

//Default Props
import defaultData from './newsletter-signup-default-data';
import defaultGlobal from '../../themes/global';


//Styles
import newsletterSignUpStyles from './newsletter-signup-styles-1';

//Attributes
import Types from '../../attribute-types';

export default class
NewsLetterSignUp1 extends Component {

    constructor(props) {

        super(props);

    }

    render() {

        this.styles = newsletterSignUpStyles( this.props.global, this.props.data );

        return (
            <div className={ css( this.styles.signUpBlock ) }
                 { ...this.generateCustomAttributes() }
            >
                <h4 className={ css( this.styles.signUpBlockHeader ) }>{ ReactHTMLParser( this.props.data.newsletterTitle.text ) }</h4>
                <p className={ css( this.styles.signUpBlockParagraph ) }>{ ReactHTMLParser( this.props.data.newsletterCta.text ) }</p>
                    <div className={ css( this.styles.signUpField ) }>
                        <input type="text" placeholder="Email" className={ css( this.styles.textButton ) }/>
                        <input type="button" defaultValue="JOIN" className={ css( this.styles.joinButton ) }/>
                        <div className="clearfix"/>
                    </div>
            </div>
        )
    }
}
NewsLetterSignUp1.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};


