import _ from "lodash";
import { graph } from "utils";
import { getActiveSiteId } from "services/sites";
import { getBlogPanelState } from "../actions";
import { setPosts } from "../PostsList/actions";
import { fetchPage } from "services/pages";

const NUM_POSTS_TO_LOAD_PER_FETCH = 20;

/**
 *
 * @param post
 * @returns {Promise<*>}
 */
export const updateBlogPost = async post => {
  const query = `
    mutation UpdateBlogPost($data:BlogPostPageInput) {
      updateBlogPost(blogPostPageInput:$data){
        id,
      }
    }
  `;
  post.publishedAt = `${post.publishedAt}`
  const variables = {
    data: post,
  };

  const res = await graph({ query, variables });

  return _.get(res, "updateBlogPost");
};

/**
 *
 * @param post
 * @returns {Promise<*>}
 */
export const createBlogPost = async post => {
  const query = `
    mutation CreateBlogPost($data:BlogPostPageInput) {
      createBlogPost(blogPostPageInput:$data){
        id,
      }
    }
  `;

  const variables = {
    data: post,
  };

  const res = await graph({ query, variables });

  return _.get(res, "createBlogPost.id");
};

/**
 *
 * @param startIndex
 * @param drafts
 * @returns {Promise<*|Array>}
 */
export const fetchPosts = async (startIndex, drafts = false) => {
  const query = `
    query($data:blogPostPageInput){
      BlogPostPages(blogPostPageInput:$data) {
        id
        siteId
        updatedAt
        name
        featuredPost
        title: postTitle
        published
        slug
        thumbnailImage {
          src
        }
        featureImage {
          src
        }
        author {
          name
        }
        publishedAt
        deletedAt
      }
    }
  `;

  const variables = {
    data: {
      siteId: getActiveSiteId(),
      limit: NUM_POSTS_TO_LOAD_PER_FETCH,
      startIndex,
      unpublished: drafts,
    },
  };

  const res = await graph({ query, variables });

  return (_.get(res, "BlogPostPages") || []).filter(post => !post.deletedAt);
};

/**
 *
 * @param postId
 * @returns Object
 */
export const fetchPost = async (postId = "") => {
  if (!postId) {
    return {
      categories: [],
    };
  }

  const query = `
  query BlogPostPage($siteId: ID!, $id: String!) {
    BlogPostPage(siteId: $siteId, id: $id) {
      id
      siteId
      name
      slug

      featuredPost
      title: postTitle

      thumbnailImage { src }
      featureImage { src }
      author { id }

      updatedAt
      publishedAt
      published

      categories { id }

      postContent
      draftContent

      shortDescription

      pageTitle, description, keywords, socialImage

    }
  }
  `;

  const variables = {
    id: postId,
    siteId: getActiveSiteId(),
  };

  const res = await graph({ query, variables });

  let post = _.get(res, "BlogPostPage");
  post.categories = Array.isArray(post.categories) ? post.categories : [];

  return post;
};

/**
 *
 * @param postId
 * @param featured
 * @returns {Promise<void>}
 */
export const setFeaturedStatus = async (postId, featured) => {
  const query = `
    mutation($post:BlogPostPageInput){
      updateBlogPost(blogPostPageInput:$post){
        id, featuredPost
      }
    }
  `;

  const variables = {
    post: {
      id: postId,
      featuredPost: featured,
    },
  };

  await graph({ query, variables });
};

/**
 *
 * @param postId
 * @returns {Promise<void>}
 */
export const publishPost = async postId => {
  const query = `
    mutation($post:BlogPostPageInput){
      updateBlogPost(blogPostPageInput:$post){
        id, published
      }
    }
  `;

  const variables = {
    post: {
      id: postId,
      published: true,
      publishedAt: `${new Date().getTime()}`,
    },
  };


  await graph({ query, variables });
};

/**
 *
 * @param postId
 * @returns {Promise<void>}
 */
export const deletePost = async postId => {
  const query = `
    mutation($data:DeleteArgsInput){
      deleteBlogPost(deleteBlogPost:$data){
        success, message
      }
    }
  `;

  const variables = {
    data: {
      id: postId,
      siteId: getActiveSiteId(),
      hardDelete: true,
    },
  };

  await graph({ query, variables });
};

/**
 *
 * @param postId
 * @returns {Promise<void>}
 */
export const unPublishPost = async postId => {
  const query = `
    mutation($post:BlogPostPageInput){
      updateBlogPost(blogPostPageInput:$post){
        id, publishedAt, published
      }
    }
  `;

  const variables = {
    post: {
      id: postId,
      publishedAt: null,
      published: false,
    },
  };

  await graph({ query, variables });
};
