import React from 'react';
import Component from '../../../../../rootComponent';
import {css} from "@convertly/css";
import Types from '../../../../../attribute-types';
import headerDropdownNavLinkstyles from './dropdown-nav-item-styles-1'
import { generateLink } from '../../../../../utils/generateLink';
import { clientImage } from '../../../../../utils/generateImage';

export default class HeaderDropdownNavLink1 extends Component {

    constructor(props) {

        super(props);
        this.state = {
            dropDown: false
        };
    }

    render() {

        this.styles = headerDropdownNavLinkstyles(this.props.global, this.props.data);

        let featureProdImg = null;

        if( this.props.data.featureProdImg ) {
            featureProdImg = <img className={ css( this.props.styles.dropDownNavItemImg ) }
                 src={ clientImage( this.props.data.featureProdImg.src ) }
                 alt={ this.props.data.alt }
                 title={ this.props.data.title } />

        }


        return (
            <li className={ "dropdown-nav-item-2 " + css( this.props.styles.dropDownNavItem ) }
                key={ this.props.index }
            >
                <a className={ css( this.props.styles.dropDownNavItemLink, this.styles.headerDropdownNavLink ) }
                   href={ generateLink( this.props.data.url ) }
                   target={ this.props.data.target ? '_blank' : '_self' }>
                    { featureProdImg }
                    { this.props.data.text }
                </a>
            </li>
        )
    }
}
