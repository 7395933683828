import React from 'react';
import Component from '../../rootComponent';
import {css} from "@convertly/css";
import defaultGlobal from '../../themes/global.js';
import ReactHTMLParser from "@convertly/react-html-parser";

// Styles
import divWithImageStyleSheet from './div-with-image-styles';

// Components

import Elements from '../index';


export default class DivWithImage extends Component { 
	constructor(props){
		super(props);
	}

	render(){
		this.styles = divWithImageStyleSheet(this.props.global, this.props.data);

		let className;
		const aphroditeClass = css(this.styles.divOverDiv);

		className +=aphroditeClass;	

		function renderChildren(props){
			return React.Children.map(props.children, child => {
				return React.cloneElement(child, {
					moduleId: props.moduleId,
					moduleSort: props.moduleSort,
					styles: props.styles
				});
			});
		}

	return(

		<div className={css(this.styles.divWithImage)}
             { ...this.generateCustomAttributes() }
		>

			<Elements.Image data={ this.props.data.image } />

			
			<div className={css(this.styles.textDiv)}>
				{renderChildren(this.props)}
			</div>
		</div>
		)
	}

};

DivWithImage.defaultProps = {
	global: defaultGlobal,
	data: {}
}