import { StyleSheet } from "@convertly/css";

const Styles = ( global, data ) => {

	return StyleSheet.create({

		
			_404_lpBody: {
				alignItems: "center",backgroundImage: "linear-gradient(180deg, #f3f3f3, #f3f3f3)",color: global.backgroundColors.dark.color1,display: "flex",fontFamily: "Arial,sans-serif",fontSize: "14px",height: "100vh",justifyContent: "center",lineHeight: "20px"
			},

			contentcontainer: {
				color: global.backgroundColors.dark.color1,fontFamily: "Arial,sans-serif",fontSize: "14px",lineHeight: "20px",marginLeft: "auto",marginRight: "auto",maxWidth: "95%",width: "1140px"
			},

			_404Container: {
				backgroundColor: "#fff",border: "1px solid #dbdbdb",boxShadow: "0 4px 10px -2px rgba(0, 0, 0, .2), 0 0 12px -1px #e9e9e9",color: global.backgroundColors.dark.color1,display: "flex",flexDirection: "column",fontFamily: "Arial,sans-serif",fontSize: "14px",lineHeight: "20px",marginLeft: "auto",marginRight: "auto",maxWidth: "90%",padding: "20px 30px",textAlign: "center",width: "500px"
			},

			_404_lg_lpH1: {
				color: "#585858",fontFamily: "Rubik, sans-serif",fontSize: "100px",lineHeight: "110px",margin: ".67em 0",marginBottom: "0px",marginTop: "0px",textAlign: "center",textTransform: "uppercase"
			},

			_404_lpH1: {
				color: "#585858",fontFamily: "Rubik, sans-serif",fontSize: "25px",lineHeight: "45px",margin: ".67em 0",marginBottom: "0px",marginTop: "0px",textAlign: "center",textTransform: "uppercase"
			},

			_404_lpBodytext: {
				color: "#9b9b9b",fontFamily: "Rubik, sans-serif",fontSize: "14px",lineHeight: "20px",marginBottom: "20px",marginLeft: "auto",marginRight: "auto",marginTop: "0",maxWidth: "100%",textAlign: "center",width: "700px"
			},

			_404_btn_wButton: {
				backgroundColor: "#4e4e4e",border: "0",borderRadius: "0",color: "white",cursor: "pointer",display: "inline-block",flex: "0 auto",fontFamily: "Rubik, sans-serif",fontSize: "14px",height: "40px",lineHeight: "inherit",marginBottom: "15px",marginTop: "5px",padding: "10px 35px",textAlign: "center",textDecoration: "none"
			},

			link: {
				backgroundColor: "transparent",color: "#8b8b8b",fontFamily: "Rubik, sans-serif",fontSize: "14px",lineHeight: "14px",textAlign: "center",textDecoration: "underline"
			}

	});

};

export default Styles;
