import { fromJS } from "immutable";
import { createReducer } from "redux-act";

import { POST_STATUS, BLOG_PANEL_TAB_OPTION_KEYS } from "../constants";

import {
  setBlogPanel,
  setCategories,
  setAuthors,
  setDefaultAuthor,
  setActiveNewPostOption,
  setActiveLayoutOption,
  setActiveThemeOption,
  setValue,
  resetBlogState,
  setTempPageElements,
} from "./actions";

import {
  setPosts,
  setActivePostTypeOption,
  setPostsListInitialLoadComplete,
  setNextPostLoadIndex,
  resetPostsList,
} from "./PostsList/actions";

import { setPost } from "./EditPost/actions";

import { setCategory, setCategorySnapshot } from "./EditCategory/actions";

import {
  setBlogLandingDefaultTemplate,
  setBlogLandingFrontPageDefaultTemplate,
  setBlogPostDefaultTemplate,
  setBlogCategoryDefaultTemplate,
} from "./Layout/actions";

import {
  setActiveLayoutEditingPanel,
  setActiveLayoutType,
  setTemplate,
} from "./EditLayout/actions";

/**
 * BlogSettings
 */
const BlogSettingsInitialParams = {};

const BlogSettingsReducer = {};

/**
 * Blogs
 */
const BlogsInitialParams = {
  activeBlogPanel: BLOG_PANEL_TAB_OPTION_KEYS.POST,
};

const BlogsReducer = {
  [setBlogPanel]: (state, activeBlogPanel) => state.merge({ activeBlogPanel }),
};

/**
 * PostsList
 */
const PostsListInitialParams = {
  draftPosts: [],
  publishedPosts: [],
  nextDraftPostLoadIndex: 0,
  nextPublishedPostLoadIndex: 0,
  searchPosts: "",
  activePostTypeOption: POST_STATUS.PUBLISHED,
  postsListInitialLoadComplete: false,
};

const PostsListReducer = {
  [setPostsListInitialLoadComplete]: (state, t) => state.merge({ postsListInitialLoadComplete: t }),

  [setPosts]: (state, posts) => state.merge({ posts }),

  [setActivePostTypeOption]: (state, activePostTypeOption) => state.merge({ activePostTypeOption }),

  [setNextPostLoadIndex]: (state, nextPostLoadIndex) => state.merge({ nextPostLoadIndex }),

  [resetPostsList]: state =>
    state.merge({
      posts: [],
      nextPostLoadIndex: 0,
      postsListInitialLoadComplete: false,
    }),
};

/**
 * EditPost
 */
const EditPostInitialParams = {
  post: {},
};

const EditPostReducer = {
  [setPost]: (state, post) => state.merge({ post }),

  [setTempPageElements]: (state, tempPageElements) => state.merge({ tempPageElements }),
};

/**
 * EditCategory
 */
const EditCategoryInitialParams = {
  category: {},
};

const EditCategoryReducer = {
  [setCategory]: (state, category) => state.merge({ category }),

  [setCategorySnapshot]: (state, snapshot) => state.merge({ snapshot }),
};

/**
 * Layout
 */
const LayoutInitialParams = {
  blogLandingDefaultTemplate: "",
  blogLandingFrontPageDefaultTemplate: "",
  blogPostDefaultTemplate: "",
  blogCategoryDefaultTemplate: "",
};

const LayoutReducer = {
  [setBlogCategoryDefaultTemplate]: (state, template) =>
    state.merge({ blogCategoryDefaultTemplate: template }),

  [setBlogLandingDefaultTemplate]: (state, template) =>
    state.merge({ blogLandingDefaultTemplate: template }),

  [setBlogLandingFrontPageDefaultTemplate]: (state, template) =>
    state.merge({ blogLandingFrontPageDefaultTemplate: template }),

  [setBlogPostDefaultTemplate]: (state, template) =>
    state.merge({ blogPostDefaultTemplate: template }),
};

/**
 * EditLayout
 */
const EditLayoutInitialParams = {
  activeLayoutEditingPanel: "",
  activeTemplate: null,
  activeLayoutItemType: null,
};

const EditLayoutReducer = {
  [setActiveLayoutType]: (state, p) => state.merge({ activeLayoutItemType: p }),

  [setActiveLayoutEditingPanel]: (state, p) => state.merge({ activeLayoutEditingPanel: p }),

  [setTemplate]: (state, activeTemplate) => state.merge({ activeTemplate }),
};

/**
 * Common
 */
const initialParams = {
  activeAsset: "IMAGES",
  activeLayoutOption: "",
  activeThemeOption: "ALL",
  categories: [],
  authors: [],
  defaultAuthor: "",
  snapshot: {},
  tempPageElements: [],
};

const CommonReducers = {
  [setDefaultAuthor]: (state, defaultAuthor) => state.merge({ defaultAuthor }),

  [setCategories]: (state, categories) => state.merge({ categories }),

  [setAuthors]: (state, authors) => state.merge({ authors }),

  [setValue]: (state, { field, value }) => state.merge({ [field]: value }),

  [setActiveNewPostOption]: (state, activeNewPostOption) => state.merge({ activeNewPostOption }),

  [setActiveLayoutOption]: (state, activeLayoutOption) => state.merge({ activeLayoutOption }),

  [setActiveThemeOption]: (state, activeThemeOption) => state.merge({ activeThemeOption }),

  [resetBlogState]: () => initialState,
};

/**
 * Merge everything
 */

const initialState = fromJS({
  ...initialParams,
  ...BlogsInitialParams,
  ...PostsListInitialParams,
  ...EditPostInitialParams,
  ...EditCategoryInitialParams,
  ...LayoutInitialParams,
  ...EditLayoutInitialParams,
  ...BlogSettingsInitialParams,
});

const reducers = {
  ...CommonReducers,

  ...PostsListReducer,

  ...BlogsReducer,

  ...EditPostReducer,

  ...EditCategoryReducer,

  ...LayoutReducer,

  ...EditLayoutReducer,

  ...BlogSettingsReducer,
};

export const BlogsPanelReducer = createReducer(reducers, initialState);
