export function findElementObject(object, id) {
  if (object.id == id) return object;
  object.children = object.children || [];
  for (let i = 0; i < object.children.length; i++) {
    if (findElementObject(object.children[i], id)) {
      return findElementObject(object.children[i], id);
    }
  }
}

export default findElementObject;
