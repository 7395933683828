import {StyleSheet} from '@convertly/css';
import {generatePseudoMediaQuery} from '../../../elements/style-functions/pseudo-media-queries';

const LocationMapOverlayStyles = (global, data, inEditor) => {


    return StyleSheet.create({

        locationsContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            maxWidth: "1235px",
            margin: "0px auto",
            padding: "7px"
        },

        locationDiv: {
            position: "relative",
            width: '375px',
            height: '375px',
            float: 'right',
            margin: "10px 15px",
            overflow: 'hidden',
            boxShadow: "0px 2px 5px 1px #CCCCCC",
            background: 'rgba(90,90,90,.2)',
            fontFamily: global.fontFamily.primaryFont,
            transition: "all .2s ease",
            ...generatePseudoMediaQuery(
                '@media (max-width:991px)',
                ['mobile', 'smallTablet', 'largeTablet'],
                {
                    float: 'none'
                },
                inEditor
            ),
            ...generatePseudoMediaQuery(
                '@media (max-width:768px)',
                ['mobile', 'smallTablet'],
                {
                    height: "300px",
                    width: "100%"
                },
                inEditor
            ),
            ":hover": {
                boxShadow: "0px 2px 10px 4px #CCCCCC",
            }

        },
        googleMap: {
            height: '455px',
            width: '101%',
            '.map': {
                marginLeft: "-1px",
                marginTop: '-35px',
                ...generatePseudoMediaQuery(
                    '@media (max-width:768px)',
                    ['mobile', 'smallTablet'],
                    {
                        marginTop: "-60px"
                    },
                    inEditor
                ),
            }
        },
        locationInfo: {
            position: 'absolute',
            background: global.colors.light.white,
            bottom: '-304px',
            height: '100%',
            boxShadow: `0px 0px 5px 0px #CCCCCC`,
            width: '100%',
            padding: '20px',
            transition: "all .15s ease",
            ".doubleLine": {
                bottom: "-273px",
            },
            ".tripleLine": {
                bottom: "-242px",
            },
            ":hover": {
                bottom: "0"
            },
            '.active': {
                bottom: "0"
            }
        },
        storeName: {
            fontSize: '22px',
            color: global.colors.dark.color1,
            textAlign: "left",
            paddingBottom: "20px",
            textTransform: "uppercase"
        },
        address: {
            fontSize: '14px',
            fontFamily: global.fontFamily.secondaryFont,
            color: global.colors.dark.color1,
            marginBottom: "8px",
            textAlign: 'left'
        },
        emailAddress: {},
        phoneNumber: {},
        streetAddress: {
            display: 'block'
        },
        contactLink: {
            fontSize: '12px',
            display: 'block',
            margin: '8px 0'
        },

        contactInfo: {
            padding: "10px 0px"
        },

        /** Hours of Operation Styles **/
        hoursTitle: {
            fontSize: '14px',
            margin: '8px 0',
            fontFamily: global.fontFamily.secondaryFont,
            color: global.colors.dark.color3,
            textAlign: 'left'
        },
        hoursOfOperation: {
            fontFamily: global.fontFamily.secondaryFont,
            color: global.colors.dark.color3,
            textAlign: 'left'
        },
        hoursList: {
            listStyleType: 'none',
            position: 'relative',
            height: '100px',
        },
        hours: {
            color: 'red'
        },
        dayOfWeek: {
            display: 'inline-block',
            fontSize: '10px',
            margin: '30px 5px',
            textTransform: 'uppercase',
            cursor: 'pointer',
            color: global.colors.dark.color3,
            borderBottom: '2px solid',
            borderColor: global.colors.dark.color1,
            ':hover': {
                fontWeight: '700',
                color: global.colors.dark.color1

            },
            '.active': {
                fontWeight: '700',
                color: global.colors.dark.color1
            },
            ":first-child": {
                'marginLeft': "0"
            }

        },
        displayedHours: {
            position: 'absolute',
            top: 0,
            left: '-1000px',
            opacity: '0',
            width: '100%',
            transition: 'all .2s linear',
            textAlign: 'left',
            color: global.colors.dark.color1,
            '.active': {
                left: 0,
                opacity: '1'
            }
        },
        openStatus: {
            display: 'none'
        },
        hours: {
            fontSize: '14px',
            textTransform: 'initial',
            fontFamily: global.fontFamily.primaryFont,
            ':nth-child(1)': {
                marginLeft: '30px'
            },
            ...generatePseudoMediaQuery(
                '@@media( max-width:510px )',
                ['mobile'],
                {
                    fontSize: '16px'
                },
                inEditor
            ),
            '@media( max-width:510px )': {
                fontSize: '16px'
            }
        }


    })

};

export default LocationMapOverlayStyles;
