import { StyleSheet } from "@convertly/css";
import UserOverrides from '../style-functions/user-overrides';

const heading1Styles = ( global, data, inEditor ) => {

	let userOverRides = {};
	if(data) {
    	userOverRides = new UserOverrides( global, data, inEditor );
	}


    return StyleSheet.create({
        userOverrides: {
            ...userOverRides,
        },
    })

};


export default heading1Styles;