/*global Inferno, $ */
/* eslint-disable */

import { include } from '@convertly/thor';

const Scripts = () => {

    return ( id ) => {
        
        let storeName = $(".storeName");

        storeName.on('click', (e) => {

            if ( $( window ).width() < 768 ) {

                let specificLocation = $(`.locationOverlay_${ e.target.dataset.target }${ id }`);

                specificLocation.toggleClass('active');

            }
        });
    };

};

export default Scripts;
