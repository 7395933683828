/*global Inferno, $, React */
/** Drop down Product Variant Widget **/
import React from 'react';

const Script = () => {

    return ({rootClass, variantMeta, index, allSelected}) => {

        let currentAndNextActive;
        let nextActive;
        let currentActive;

        if (variantMeta[index]) {
            currentActive = variantMeta[index].activeValue;
            if (index !== variantMeta.length - 1) {
                nextActive = variantMeta[index + 1].activeValue;
                currentAndNextActive = currentActive && nextActive;
            }

        }
        return (
            <div className={`${rootClass} progress-bar-wrapper`}>
                <div className={`${rootClass} progress-bar-container ${ currentActive ? 'active' : ''}`}/>
                <div className={`${rootClass} vertical-line-container`}>
                    <div
                        className={`${rootClass} vertical-line ${ allSelected || currentAndNextActive ? ' active ' : currentActive ? 'top' : '' }`}/>
                    <div
                        className={`${rootClass} vertical-line ${ allSelected || currentAndNextActive ? ' active ' : nextActive ? 'bottom' : '' }`}/>
                </div>
            </div>
        );
    }
};

export default Script;
