import React from 'react';
import { css } from "@convertly/css";
import Component from '../../rootComponent';
//Default Props
import defaultGlobal from '../../themes/global';


//Styles



//Components
import Elements from '../../elements/index';


export default class HeartOfGold extends Component {


    constructor(props) {

        super(props);

    };

    render() {

        function renderChildren(props) {
            return React.Children.map(props.children, child => {
                return React.cloneElement(child, {
                    moduleId: props.id,
                    moduleSort: props.moduleSort,
                    site: props.site,
                    styles: props.styles,
                    page: props.page,
                    sliderSettings: props.sliderSettings ? props.sliderSettings : null
                });
            })
        }
        
        /**
         * Adds ID and sort to elements
         * @param props
         * @returns {*}
         */

        try {
			return (

				<Elements.ModuleWrapper
					data={ this.props.data }
					global={ this.props.global }
					elementDataName="backgroundColor"
					moduleId={ this.props.id }
					site={ this.props.site }
					page={ this.props.page }
					id={this.props.id}
					moduleSort={ this.props.sort }>

					{ renderChildren(this.props) }

				</Elements.ModuleWrapper>

			)
		} catch (e) {
        	console.log( 'Error rendering block' );
        	//console.log( this.props );

		}


    }
}

HeartOfGold.defaultProps = {
    global: defaultGlobal,
};
