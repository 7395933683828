import React from 'react';
import Component from '../../../../rootComponent';
import {css} from "@convertly/css";
import Types from '../../../../attribute-types';
import {id} from '@convertly/thor';


//scripts
import toggleDropDown from '../../scripts/nav-dropdown-toggle';


//Components
import WebLink from '../../../../elements/links/web-link'
import DropdownNavItem from './drop-down-nav-item-07';
import SVG from 'source/svg/index';

export default class
NavLink07 extends Component {

    constructor(props) {

        super(props);

    }

    render() {

        let { data, styles, index, id } = this.props;

        let navItem;

        if (data.dropDownNav) {

            navItem =
            
                <ul className={ css(styles.dropdown) }>

                    <li 
                        className={ css(styles.navListItem, styles.navListItemLink) }
                        id={ id( toggleDropDown ) }
                        data-target={ "#dropDownItem" + index } >

                        { data.text }

                        <svg 
                            className={ css( styles.caret ) + ' caret'}
                            viewBox="0 0 32 18"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg">
                            <g id="Mobile-Portrait" transform="translate(-254.000000, -929.000000)" strokeWidth="3">
                                <g id="Group" transform="translate(270.000000, 938.000000) scale(-1, 1) rotate(-180.000000) translate(-270.000000, -938.000000) translate(256.000000, 931.000000)">
                                    <path d="M14,0.145833333 L0.286024275,13.7169551" id="Line"></path>
                                    <path d="M27.8526316,0.145833333 L14.1386559,13.7169551" id="Line" transform="translate(20.926316, 7.000000) scale(1, -1) translate(-20.926316, -7.000000) "></path>
                                </g>
                            </g>
                        </svg>

                        <ul 
                            className={ css(styles.dropdownList) }
                            id={ "dropDownItem" + index } 
                            data-category="dropdown">
                            
                            <div className={ css(styles.arrowUp) }></div>

                            { data.dropDownNav.map( ( item, index ) => {

                                return (

                                    <DropdownNavItem 
                                        key={ index }
                                        global={ global }
                                        data={ item }
                                        styles={ styles }
                                        id={ "dropDownItem" + index }
                                        index={ index } />

                                )

                            })

                        }
                        
                        </ul>
                    </li>
                </ul>
        }
        else {
            navItem =
                <li className={ css(styles.navListItem) } id={ id }>
                    <strong>
                        <WebLink { ...this.props } />
                    </strong>
                </li>

        }

        return (
            <div className={ css(styles.navItemContainer) } >
                {navItem}
            </div>
        )
    }
}

