/*global Inferno, $ */
/* eslint-disable */
import {include} from '@convertly/thor';

const Scripts = (id) => {

    const Dispatch = include('../../utils/dispatcher', 'Dispatch');
    const Cookie = include('../../utils/cookie', 'Cookie');


    return (id, siteUrl) => {


        const hasLocalStorage = $('html').hasClass('localstorage');

        const root = document.getElementById(id);

        const dispatch = Dispatch( id );
        const cookie = Cookie( );

        //Utils


        const IsSignedIn = Inferno.createClass({

            getInitialState: function () {

                return {
                    isSignedIn: false
                };
            },

            checkLogIn: function () {
                if (hasLocalStorage) {
                    let loginToken = cookie.read({name:'authToken'});
                    if (loginToken) {
                        this.setState({
                            loginToken: loginToken,
                            isSignedIn: true
                        });

                    }

                }
            },

            componentDidMount: function () {
                this.checkLogIn();

                dispatch.watch('accessToken', (token) => {
                    this.setState({
                        loginToken: token,
                        isSignedIn: true
                    });
                });

                dispatch.on('account_deleted', () => {
                    this.setState({
                        loginToken: '',
                        isSignedIn: false
                    });
                })

            },

            render: function () {


                if (this.state.isSignedIn) {

                    const numberOfItemsInCart = ` (${ this.state.itemsInCart })`;
                    return (
                        <div className={ `${root.className} headerNavLink headerNavLinkLast` }>
                            <a href={ `/account` }>
                                <p className={ `${root.className} cartLink` }>Account</p>
                            </a>

                        </div>

                    )
                } else {

                    return (
                        <div className={ `${root.className} headerNavLink headerNavLinkLast sign-in` }>
                            <a href={ `/account` }>
                                Sign in
                            </a>
                        </div>
                    )
                }
            }
        });

        Inferno.render(<IsSignedIn />, root);

    };

};

export default Scripts;
