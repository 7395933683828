import React from 'react';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';
import {generateLink} from '../../../utils/generateLink';
import {clientImage} from '../../../utils/generateImage';
import _ from 'lodash';

//Styles
import navStyles02 from './nav-styles-02.js';

//Scripts
import toggleChildrenOpen from '../../scripts/hidden-nav-toggle';
import toggleDropDown from '../../scripts/nav-dropdown-toggle';
import cartHandler from '../../scripts/cart-script';

//Components
import Elements from 'source/elements/index';
import NavList02 from './nav-list-02';
import {HeaderTopper} from '../../components/header-topper';
import {generateId} from "../../../utils/generate-attribute";


export default class HeaderNav2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showHeaderMenu: false,
      toggledClass: ''
    };

    this.toggleHeaderMenu = this.toggleHeaderMenu.bind(this);

  }

  componentDidMount() {

  }

  toggleHeaderMenu() {
    let showMobileMenu = this.context.store.getState().get('editor').editorReducer.get('showClientNav');

    this.context.store.dispatch(this.context.store.toggleClientNav(showMobileMenu));
  }

  render() {

    const {site, page, data, global, headerPosition} = this.props;

    const {store} = this.context;

    const inEditor = this.inEditor();

    let showMobileMenu = false;

    const inlineStyle = this.generateStyleAttribute();

    if (inEditor) {
      showMobileMenu = store.getState().get('editor').editorReducer.get('showClientNav');
    }

    let siteHasLogo = _.get(site, 'logo.src');


    this.styles = navStyles02(global, data, headerPosition, inEditor, siteHasLogo);

    /** Adds cart script **/
    let ecommEnabled = _.get(site, 'ecommerce.enabled');
    const clientImgSrc = clientImage("", {width: '60', height: '60', crop: 'fit'}, site.siteId);
    const clientLinkUrl = generateLink({linkType: "inferno", url: ""});

    let cart = null;

    if (page && page.slug !== "/cart" && ecommEnabled) {

      cart =

        <div href={generateLink({linkType: 'internal', url: '/cart'})}
             className={css(this.styles.cartContainer, this.styles.pullUp)}>
                    <span id={id(cartHandler, clientLinkUrl, clientImgSrc, false, "cartIcon")}
                          className={css(this.styles.desktopCartInfernoComponent)}
                    >Cart</span>
        </div>


    }

    return (
      <Elements.Container data={
        {
          userOverrides: {height: '100%'}
        }
      }>


        {
          _.get(data, "headerTopperSettings.display") &&
          <Elements.Row>
            <HeaderTopper
              global={global}
              site={site}
              styles={this.styles}
              data={data.headerTopperSettings}/>
          </Elements.Row>
        }


        <Elements.Row editable={false} data={
          {
            userOverrides: {height: '100%'}
          }
        }>

          <div className={css(this.styles.menuContainer) + " headerNav"}>
            {/** Logo **/}
            {data.showLogo &&
            <div className={css(this.styles.logo)}

                 id="logo">
              {data.logoLinkToHomePage ?
                <a href={generateLink({linkType: 'internal', "url": '/'})}
                   onClick={(e) => {
                     this.cancelClick(e)
                   }}
                   className={css(this.styles.logoContainer)}>
                  {data.useTextLogo && _.get(data.textLogo, 'text') ?
                    <Elements.Heading2 editable={false} data={{...data.textLogo}}>Text Logo
                      Here</Elements.Heading2>
                    :
                    siteHasLogo &&
                    <picture>
                      { /** WEBP **/}
                      {/*<source srcSet={ clientImage(site.logo.src, {format:"webp" } ) } type="image/webp"/>*/}
                      { /** normal **/}
                      <source srcSet={clientImage(site.logo.src, data.params)} type="image/jpeg"/>
                      <img src={clientImage(site.logo.src)}
                           data-logo={true}
                           alt={site.logo.alt}
                           id='logo'
                           data-altimg={clientImage(site.alt_logo.src)}
                           className={css(this.styles.logoImg) + " logo"}
                      />
                    </picture>
                  }
                </a>
                :
                <span className={css(this.styles.logoContainer)}>
                                   {data.useTextLogo && _.get(data.textLogo, 'text') ?
                                     <Elements.Heading2 data={{...data.textLogo}}>Text Logo Here</Elements.Heading2>
                                     :
                                     siteHasLogo &&
                                     <picture>
                                       { /** WEBP **/}
                                       {/*<source srcSet={ clientImage(site.logo.src, {format:"webp" } ) } type="image/webp"/>*/}
                                       { /** normal **/}
                                       <source srcSet={clientImage(site.logo.src, data.params)} type="image/jpeg"/>
                                       <img src={clientImage(site.logo.src)}
                                            data-logo={true}
                                            alt={site.logo.alt}
                                            id='logo'
                                            data-altimg={clientImage(site.alt_logo.src)}
                                            title={site.logo.title}
                                            className={css(this.styles.logoImg) + " logo"}
                                       />
                                     </picture>
                                   }
                                </span>
              }
            </div>
            ||
            <div className="col-xs-3 align-content-center">
              <span className={css(this.styles.logo)}/>
            </div>

            }
            {/** Navigation **/}

            {data.showNavigation &&
            <div className={css(this.styles.menuLauncherIcon) + this.state.toggledClass}
                 id="menuIcon">
              { /** Mobile Cart **/}
              {cart}

              <button
                id={id(toggleChildrenOpen)}
                onClick={this.toggleHeaderMenu}
                data-target="#menu"
                type="button"
                className={css(this.styles.navLaunch)}>
                <span className="sr-only">Toggle navigation</span>
                <span

                  className={`${css(this.styles.iconBar)} ${showMobileMenu ? 'open' : ''} icon-bar`}
                  data-target="#menu">
                                            </span>
                <span

                  className={`${css(this.styles.iconBar)} ${showMobileMenu ? 'open' : ''} icon-bar`}
                  data-target="#menu">
                                            </span>
                <span

                  className={`${css(this.styles.iconBar)} ${showMobileMenu ? 'open' : ''} icon-bar`}
                  data-target="#menu">
                                            </span>
              </button>


            </div>
            }

            {data.showNavigation &&
            <div className={`${css(this.styles.mainMenu)} ${showMobileMenu ? 'open' : ''}`}>
              <NavList02
                styles={this.styles}
                page={page}
                site={site}
                showMobileMenu={showMobileMenu}
                id={this.props.id}
                global={global}
                displaySocial={{
                  headerTopper: _.get(data, "headerTopperSettings.display"),
                  socialIcons: _.get(data, "headerTopperSettings.displaySocialIcons")
                }
                }
                data={data.menu}/>
            </div>
            }
          </div>
        </Elements.Row>
      </Elements.Container>

    )
  }
}
