import { StyleSheet } from "@convertly/css";
import UserOverrides from '../style-functions/user-overrides';

const divWithOverlay02Styles = (global, data) => {

    const userOverrides = new UserOverrides(global, data);

    return StyleSheet.create({

        divWithOverlay02: {
            backgroundRepeat: "no-repeat",
            position: "relative",
            width: "100%",
            height: "auto",
            minHeight: "150px", 
            backgroundSize: "100% 100%",
            margin: "0",
            ":hover ": {
                ".divOverlay02": {
                   opacity: 1
                },                
            },
            ...userOverrides
        },

        divOverlay02: {
            backgroundColor: "rgba(255,255,255,.6)",
            opacity: "0",
            width: "100%",
            height: "100%",
            minHeight: "150px",  
            transition: "all .3s ease",
            //zIndex:'3',
        }
    })
};

export default divWithOverlay02Styles;