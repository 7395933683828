import _ from "lodash";

import { graph } from "utils";
import { setBannerShapes } from "../actions";

export const getBannerShapes = () => async dispatch => {
  const results = await Promise.all([fetchShapes(), fetchShapeCategories()]);

  let shapes = results[0];
  const cats = results[1];

  shapes = shapes.filter(({ active }) => active);

  let shapeCategories = cats.map(category => {
    category.shapes = shapes.filter(shape => shape.category === category.id);
    return category;
  });

  shapeCategories.push({
    name: "Uncategorized",
    shapes: shapes.filter(shape => !shape.category)
  });

  shapeCategories = shapeCategories.filter(({ shapes }) => !!shapes.length);

  await dispatch(setBannerShapes(shapeCategories));
};

export const fetchShapes = async () => {
  const query = `query{getAllShapes{id, name, thumbnail, active, preserveAspectRatio, category}}`;
  const response = await graph({ query }, true);

  return _.get(response, "data.getAllShapes") || [];
};

export const fetchShapeCategories = async () => {
  const query = `query{getAllShapeCategories{id, name}}`;
  const response = await graph({ query }, true);

  return _.get(response, "data.getAllShapeCategories") || [];
};

export async function getShapeCode(id) {
  const query = `query($id: ID!){getShapeById(id: $id){code}}`;
  const variables = { id };
  const response = await graph({ query, variables }, true);
  return _.get(response, "data.getShapeById.code") || "";
}
