import _ from "lodash";

const MONTHS = {
  "01": "January",
  "02": "February",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
  "10": "October",
  "11": "November",
  "12": "December"
};

const formatDate = date => {
  let dateObj, dateArr, day, month, year;

  try {
    if (_.isNumber(+date)) {
      dateObj = new Date(+date);
    } else {
      dateObj = new Date(date);
    }

    dateArr = dateObj
      .toISOString()
      .substring(0, 10)
      .split("-");
    year = dateArr[0];
    month = MONTHS[dateArr[1]];
    day = dateArr[2];

    return `${month} ${day}, ${year}`;
  } catch (error) {
    return "Invalid date format";
  }
};

export default formatDate;
