import { createAction } from "redux-act";
import _ from "lodash";
import moment from "moment";
import { fromJS } from "immutable";
import { store } from "app/app";
import { graph } from "utils/graph";

export const setWidgets = createAction("SET_WIDGETS");
export const setWidgetLoadedState = createAction("SET_WIDGET_LOADED_STATE");
export const setWidget = createAction("SET_WIDGET");
export const resetState = createAction("RESET_WIDGETS");

export const setSnapshotCards = createAction("SNAPSHOT_SET_CARDS");
export const setSnapshotSubtitle = createAction("SNAPSHOT_SET_SUBTITLE");
export const setSnapshotTitle = createAction("SNAPSHOT_SET_TITLE");
export const setSnapshotCard = createAction("SNAPSHOT_SET_CARD");
export const setLoaded = createAction("SNAPSHOT_SET_LOADED");

export const setLoading = createAction("DASHBOARD_ORDERS_TABLE_SET_LOADING");
export const setOrders = createAction("DASHBOARD_ORDERS_TABLE_SET_ORDERS");

export function loadOrdersTable() {
  return async dispatch => {
    const site = store
      .getState()
      .get("auth")
      .get("activeSite");

    await dispatch(setLoading(true));

    const query = `
			query ( $id: ID!, $limit: Int ) {
			  Orders(siteId: $id, limit: $limit) {
				id
				publicId
				createdAt
				cart
				orderStatus
				total
				customer {
				  id, firstName, lastName
				}
			  }
			}`;

    const variables = {
      id: site.get("siteId"),
      limit: 6
    };

    const response = await graph({ query, variables });

    const orders = response.Orders.map(order => {
      order.name = `${order.customer.firstName} ${order.customer.lastName}`;
      order.customerId = order.customer.id;
      order.total = parseFloat(order.total).toFixed(2);
      const cart = JSON.parse(order.cart);
      let count = 0;
      cart.items.forEach(item => {
        count += item.quantity;
      });
      order.count = count;
      return order;
    });

    await dispatch(setOrders(orders));
    await dispatch(setLoading(false));
  };
}

export function setDateRange(index, event) {
  return async dispatch => {
    const widget = store
      .getState()
      .get("homeDashboard")
      .Dashboard.get("widgets")
      .get(index);
    await dispatch(
      setWidget({
        index,
        widget: widget.set("dateRange", event.target.value).set("loading", true)
      })
    );
    await dispatch(fetchChartData(index));
  };
}

export function fetchChartData(index) {
  return async dispatch => {
    try {
      const Widgets = store
        .getState()
        .get("homeDashboard")
        .Dashboard.get("widgets");
      const siteId = store
        .getState()
        .get("auth")
        .get("activeSite")
        .get("siteId");

      const widget = Widgets.get(index);

      const dRange = widget.get("dateRange") === "" ? "Today" : widget.get("dateRange");

      const postData = {
        dateRange: dRange,
        metric: widget.get("metric"),
        type: widget.get("type"),
        site: siteId
      };

      const query = `
			query( $id:ID!, $data: String) {
				SiteMetrics(id:$id, data:$data)
			  }
			`;

      const variables = {
        id: siteId,
        data: JSON.stringify(postData)
      };

      const { SiteMetrics } = await graph({ query, variables });

      const data = JSON.parse(SiteMetrics);

      await dispatch(
        setWidget({
          index,
          widget: Widgets.get(index)
            .set("data", data)
            .set("loading", false)
        })
      );
    } catch (e) {
      console.log("fetchChartData Error", e.message);
    }
  };
}

export function editWidget(index) {
  return async dispatch => {};
}

export function fetchWidgets() {
  return async dispatch => {
    await dispatch(setWidgetLoadedState(false));

    const Auth = store.getState().get("auth");

    const query = `
		query( $userId: ID!, $siteId: String ) {
		  Account(id:$userId) {
			dashboardWidgets(siteId:$siteId)
		  }
		}`;

    const variables = {
      userId: Auth.get("user").get("id"),
      siteId: Auth.get("activeSite").get("id")
    };

    const response = await graph({ query, variables });
    let widgets = _.get(response, "Account.dashboardWidgets");

    try {
      widgets = JSON.parse(widgets);
    } catch (e) {
      widgets = [];
    }

    await dispatch(setWidgets(widgets));
    await dispatch(setWidgetLoadedState(true));
  };
}

export function resetSnapshot() {
  return async dispatch => {
    await dispatch(setLoaded(false));
    await dispatch(setSnapshotCards(fromJS([])));
  };
}

export function loadSnapshot() {
  return async dispatch => {
    const Auth = store.getState().get("auth");
    const site = Auth.get("activeSite");

    const name = Auth.get("user").get("first_name") || Auth.get("user").get("email");

    await dispatch(setSnapshotSubtitle(`Here's your daily snapshot for ${site.get("siteName")}`));
    await dispatch(setSnapshotTitle(`${greeting()} ${name}`));

    if (
      !store
        .getState()
        .get("homeDashboard")
        .Snapshot.get("loaded")
    ) {
      await dispatch(fetchSnapshot());
    }

    await dispatch(loadSnapshotCards());
  };
}

export function loadSnapshotCards() {
  return async dispatch => {
    const cards = store
      .getState()
      .get("homeDashboard")
      .Snapshot.get("cards");

    for (let i = 0; i < cards.size; i++) {
      dispatch(loadCard(cards.get(i)));
    }

    await dispatch(setLoaded(true));
  };
}

export function fetchSnapshot() {
  return async dispatch => {
    const Auth = store.getState().get("auth");

    const query = `
		query( $userId: ID!, $siteId: String ) {
		  Account(id:$userId) {
			snapshot(siteId:$siteId)
		  }
		}`;

    const variables = {
      userId: Auth.get("user").get("id"),
      siteId: Auth.get("activeSite").get("id")
    };

    const response = await graph({ query, variables });
    let cards = _.get(response, "Account.snapshot");

    try {
      cards = JSON.parse(cards);
    } catch (e) {
      cards = [];
    }

    cards = cards.map(card => {
      card.loading = true;
      card.value = "---";
      return card;
    });

    await dispatch(setSnapshotCards(fromJS(cards)));
  };
}

export function loadCard(card) {
  return async dispatch => {
    const siteId = store
      .getState()
      .get("auth")
      .get("activeSite")
      .get("id");
    const cards = store
      .getState()
      .get("homeDashboard")
      .Snapshot.get("cards");
    const index = cards.findIndex((item, index) => item.get("id") === card.get("id"));
    const storeCard = cards.get(index);

    const startOfDay = new Date();

    startOfDay.setHours(0);
    startOfDay.setMinutes(0);
    startOfDay.setSeconds(0);

    const params = {
      type: "Snapshot",
      metric: card.get("type"),
      site: siteId,
      timestamp: new Date().getTime(),
      start: startOfDay.getTime(),
      date: moment().format("YYYY-MM-DD")
    };

    const query = `
		query( $id:ID!, $data: String) {
			SiteMetrics(id:$id, data:$data)
		  }
		`;

    const variables = {
      id: siteId,
      data: JSON.stringify(params)
    };

    const { SiteMetrics } = await graph({ query, variables });

    return await dispatch(
      setSnapshotCard({
        index,
        card: storeCard.set("value", SiteMetrics).set("loading", false)
      })
    );
  };
}

function delay(t) {
  return new Promise(resolve => {
    setTimeout(() => resolve(), t);
  });
}

export function greeting() {
  const thehours = new Date().getHours();
  let themessage;
  const morning = "Good morning";
  const afternoon = "Good afternoon";
  const evening = "Good evening";

  if (thehours >= 0 && thehours < 12) {
    themessage = morning;
  } else if (thehours >= 12 && thehours < 17) {
    themessage = afternoon;
  } else if (thehours >= 17 && thehours < 24) {
    themessage = evening;
  }
  return themessage;
}
