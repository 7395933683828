import { StyleSheet } from "@convertly/css";
import { clientImage } from '../../../utils/generateImage';
import convertlySliderTransitions from '../../convertly-slider-transistions';
import userOverrides from '../../../elements/style-functions/user-overrides';
import { generatePseudoMediaQuery } from '../../../elements/style-functions/pseudo-media-queries'


const slideStyles = ( global, data, sliderSettings, inEditor ) => {

    const transition = convertlySliderTransitions( global, sliderSettings );
    const slideUserOverrides = userOverrides( global, data, inEditor );
    let overlayOverrides = {};
    if(data.overlay) {
        overlayOverrides = userOverrides(global, data.overlay, inEditor);
    }
    let textAreaOverrides = {};
    if(data.textAreaOverrides) {
        textAreaOverrides = userOverrides(global, data.textAreaOverrides, inEditor);
    }


    return StyleSheet.create({

        slide: {
            width: '100%',
            height:'100%',
            top:0,
            display:'inline-block',
            zIndex: 1,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50%",
            backgroundSize: "cover",
            "@media(hover:hover)": {
                transition:transition.activeTransition,
            },
            ...transition.activeBaseStyles,
            '.active': {
                zIndex:2,
                ...transition.activeActiveStyles,
            },
            ...slideUserOverrides
        },
        slideContent: {
            height:'100%',
            width:'100%',
            zIndex:'3',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            flexDirection: 'column',
            position:'absolute',
            overflow: 'hidden'
        },
        textArea: {
            width:'60%',
            alignSelf: sliderSettings.alignContent || 'center',
            height: 'auto',
            maxWidth: '700px',
            ...generatePseudoMediaQuery( '@media(max-width:768px)', ['smallTablet', 'mobile'], {
                width:'70%'
            }, inEditor ),
                ...textAreaOverrides

        },
        overlay: {
            position:'absolute',
            top:0,
            left:0,
            height:'100%',
            width:'100%',
            zIndex:'2',
            ...overlayOverrides
        }
    })
};

export default slideStyles;
