import React from 'react';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import Link from '../../../elements/links/web-link';
import { clientImage } from '../../../utils/generateImage';
import _ from 'lodash';

//Default Data
import defaultData from '../footer-default-data';
import defaultGlobal from '../../../themes/global';

//Styles
import footerStyles from './footer-styles-03';

//Components
import FooterNav1 from '../components/footer-nav-list-1';

import Elements from 'source/elements';

import SocialIcons1 from '../../../widgets/social-icons/no-background-social-icons/social-icon-list-1';

//utils
import { generateLink } from '../../../utils/generateLink';

export default class Footer03 extends Component {

    constructor(props) {

        super(props);

    }

    render() {

        const hideConvertlyBranding = _.get( this.props, 'site.hideConvertlyBranding' );

        this.styles = footerStyles(this.props.global, this.props.data, hideConvertlyBranding);

        if (this.props.editor) {
            return this.editor();
        }

        /** Shows Footer Site Map */
        let footerSiteMap;



        const footerNavLinks = this.props.data.footerNavLinks || [];
        const inlineStyle = this.generateStyleAttribute();
        if( this.props.data.showSiteMap ) {
            footerSiteMap =  <div className={ css(this.styles.footerLinks) }>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-8">
                                <div className={ css( this.styles.footerSectionContainer ) }>
                                { footerNavLinks.map((navItem, index) => {
                                        return (
                                            <FooterNav1 key={ index }
                                                        index={ index }
                                                        data={ navItem }
                                                        global={ this.props.global }
                                                        styles={ this.styles }/>
                                        )
                                    }
                                )}
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                                <div className={ css( this.styles.footerSectionContainer ) }>
                                    <a href="#" className="logo">
                                        <Elements.Image
                                            data={{
                                                src: this.props.data.logo.src,
                                                imgParams: { width:300 },
                                                alt: this.props.data.logo.alt,
                                            }}
                                            parentClass={css(this.styles.footerNavLogo)}
                                        />
                                    </a>
                                    <div className={ css(this.styles.addressBox) }>
                                        <h6 className={ css(this.styles.footerNavOfficeName) }>Office 1</h6>
                                        <address className={ css(this.styles.footerNavAddress) }>123 Street Ave.
                                            <br />
                                            City, California 00012
                                        </address>
                                        <h6 className={ css(this.styles.footerNavPhoneNumber) }>
                                            <a className={ css(this.styles.footerNavPhoneNumberLink) }
                                               href="tel:5555555555">555-555-5555</a>
                                        </h6>
                                    </div>
                                    <div className={ css(this.styles.addressBox) }>
                                        <h6 className={ css(this.styles.footerNavOfficeName) }>Office 2</h6>
                                        <address className={ css(this.styles.footerNavAddress) }>123 Avenue St.
                                            <br />
                                            City, California 00012
                                        </address>
                                        <h6 className={ css(this.styles.footerNavPhoneNumber) }>
                                            <a className={ css(this.styles.footerNavPhoneNumberLink) }
                                               href="tel:5555555555">555-555-5555</a>
                                        </h6>
                                    </div>

                                    <div className="social_icons">
                                        <SocialIcons1 global={ this.props.global }
                                                      styles={ this.styles }
                                                      data={ this.props.data }/>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        }

        const { paragraph } = this.props.data;

        const text = paragraph ? paragraph.text : "";

        const copyRightLinks = this.props.data.copyRightLinks || [];


        return (

            <footer className={ css(this.styles.footer) + " footer03" }
                onClick={(e) => {
                                   this.launchElementalEditor('footer', e);
                              }}
                              onMouseOver={ this.addEditableOutline }
                              onMouseOut={ this.removeEditableOutline }
                              { ...inlineStyle }
                              { ...this.generateDataEditorAttribute() }
            >
               {/** footer site map **/}
               { footerSiteMap }

                {/** Copy right and privacy links **/}
                <div className={ css( this.styles.copyright ) }>
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <h6 className={ css( this.styles.copyrightText ) } >{text}</h6>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <nav>
                                <ul className={ css( this.styles.copyrightNavList ) }>
                                    { copyRightLinks.map( ( link, index ) =>  {
                                        return(
                                            <li key={index} className={ css( this.styles.copyrightNavListItem ) } >
                                                <a className={ css( this.styles.copyrightNavListItemLink ) } href={ generateLink( link.link ) }>{ link.text }</a>
                                            </li>
                                        )
                                    })
                                    }

                                    </ul>
                                </nav>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

Footer03.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
