import { StyleSheet } from "@convertly/css";

const footerStyles = ( global, data ) => {

    return StyleSheet.create({
        mainFooter: {
            fontFamily:global.fontFamily.primaryFont,
            maxWidth:'100%',
            textAlign: 'center',
            color: global.colors.dark.color3,
            marginTop:'80px'
        },
        footerLogoFigure: {
            display:'inline-block'
        },
        footerLogo: {
            maxWidth: '200px',
            marginBottom:'35px'
        },
        copyRightText: {
            float: 'left',
            '@media ( max-width: 991px )': {
                float:'none'
            }
        },
        copyText: {
            display: 'inline',
        },
        footerNavLinks: {
            float: 'right',
            marginBottom:'75px',
            '@media ( max-width: 991px )': {
                float:'none'
            }
        },
        copyRightLinks: {
            display:'block',

            '@media ( min-width: 991px )': {
                display:'inline',
                ":before": {
                    content: '" - "'
                },
                ':first-child': {
                    ":before": {
                        content: '""'
                    },
                },
            }
        },


    })

};
export default footerStyles;
