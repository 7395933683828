const regeneratorRuntime = require("regenerator-runtime");
import React from "react";
import Component from "source/rootComponent";
import { css } from "@convertly/css";
import _ from "lodash";
import { emitter } from "../../emitter";
import { doQuery } from "../../utils/doQuery";

// Style
import { PaginationStyles } from "./PaginationStyles";

// Utils
import generateId from "../../utils/generate-id";
import { frontloadConnect } from "@convertly/react-frontload";
import { frontLoadOptions } from "../../utils/frontLoadOptions";
import { getPageNumber } from "../util/getPageNumber";

const postCounts = {};

const getPostsCount = props => {
    return new Promise(async (resolve, reject) => {
       if(props.inEditor) {
         postCounts[props.id] = 100;
         resolve();
         return false
       }

        let otherFrontloadsComplete = false;
        const { STORE_KEY, siteId } = props.site;

        //Listens for blog posts frontloads to complete

        //query to get blog post count
        /**TODO:  REFACTOR TO HAVE A CENTRAL QUERY POINT SO ALL QUERIES GET DONE A ONCE THEN RETURNED**/
        const query = `query ($siteId:ID, $pageId:ID) {
                          BlogPageCount( siteId:$siteId pageId:$pageId ) {
                            pageCount 
                            frontPageData
                          }
                        }`;

        const variables = {
            siteId: props.site.siteId,
            pageId: props.page.id,
        };

        const data = await doQuery({ query, variables });

        postCounts[props.id] = _.get(data, "data.BlogPageCount.pageCount");
        //frontPageData[props.id] = _.get(data, 'data.BlogPageCount.pageCount');
        //TODO: delay query for blog posts until this is gotten, so as to save requests
        emitter.emit(
            `${STORE_KEY}-${siteId}-frontPageData`,
            _.get(data, "data.BlogPageCount.pageCount")
        );
        resolve();
    });
};

const frontload = async props => getPostsCount(props);

class Pagination extends Component {
    constructor(props) {
        super(props);
    }

    generateLink(slug, nextPage) {
        let currentPage = parseInt(getPageNumber(slug));
        let link = slug.replace(/\/page-|\d+/g, "");

        return link + `/page-${nextPage}`
    }

    shouldComponentUpdate(  ) {
      return !!this.props.inEditor;
    }


    componentWillMount() {
      if(this.props.inEditor) {
        getPostsCount(this.props)
      }
    }

    calculateTotalPages() {
        const blogKraken = this.getStoreValue("blogKraken");
        //posts per page count
        const countData = blogKraken.getPostsPerPage(),
            { incrementor, staticCount } = countData;

        //total post count
        const tp = postCounts[this.props.id];

        return Math.ceil((tp - staticCount) / incrementor);
    }

    render() {
        if (_.get(this.props, "disablePagination") || !postCounts[this.props.id]) {

            return null;
        }

        const { data, global, inEditor, slug } = this.props,
            currentPage = parseInt(getPageNumber(slug)),
            totalPages = postCounts[this.props.id];

        if (totalPages === 1) {

            return null;
        }

        this.styles = {}

        const { pagination, nextButton, previousButton, buttonTextStyles, pageCount } = PaginationStyles({ global, data, inEditor });
        const inlineStyle = this.generateStyleAttribute();

        return (
            <div
                className={css(pagination)}
                {...inlineStyle}
                {...generateId(this.props.elementId)}
                {...this.generateDataEditorAttribute()}
                {...this.generateCustomAttributes()}
            >
                {inEditor || currentPage !== 1 && (
                    <a
                        className={css(previousButton)}
                        href={
                            inEditor || currentPage === 1
                                ? "#"
                                : `${this.generateLink(slug, currentPage -1)}`
                        }
                    >
                        <div className={css(buttonTextStyles)}>&#60; Previous</div>
                    </a>
                )}
                {currentPage != totalPages && (
                    <a
                        className={css(nextButton)}
                        href={
                            inEditor || currentPage == totalPages
                                ? "#"
                                : `${this.generateLink(slug, currentPage + 1)}`
                        }
                    >
                        <span className={css(buttonTextStyles)}>Next &#62;</span>
                    </a>
                )}
                <div className={css(pageCount)}>
                    {currentPage} / {totalPages}
                </div>
            </div>
        );
    }
}

module.exports = {
    Pagination: frontloadConnect(frontload, frontLoadOptions)(Pagination),
};
