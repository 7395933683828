import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { saveCoupon, deleteCoupon } from "containers/StoreFront/CouponDetail/actions";
import { CouponDetailMenu as Menu } from "../menus/CouponDetailMenu";

const mapStateToProps = state => {
  const Coupon = state.get("coupon").Coupon;
  return {
    dirty: !Coupon.get("coupon").equals(Coupon.get("snapshot")),
    processing: Coupon.get("saving") || Coupon.get("processing"),
    hasErrors: !!Coupon.get("validationErrors").size
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCancel: () => browserHistory.push("/storefront/coupons"),
    onSave: () => dispatch(saveCoupon()),
    onDelete: () => dispatch(deleteCoupon())
  };
};

export const CouponDetailMenu = connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu);
