import React from 'react';
import Component from '../../rootComponent';
import { css } from "@convertly/css";


//defaultProps
import defaultGlobal from'../../themes/global.js';
import defaultData from './header-default-data';

//Styles
import headerStyles from './header-02/header-styles-02.js';

//Components
import HeaderNav2 from './header-02/components/header-nav-2';
import HeaderTop2 from '../../header-top/header-top-2';



export class Header03 extends Component {

    constructor( props ) {
        super( props );
    }

    render() {

        this.styles = headerStyles( this.props.global, this.props.data );

        if( this.props.editor ) {
            return this.editor();
        }

        return (
            <div id="header03">
                <header className={ css( this.styles.header ) } itemProp="siteHeader" itemScope itemType="http://schema.org/siteHeader">

                    <div className="row">
                        <HeaderNav2 global={ this.props.global } data={ this.props.data } />
                    </div>
                </header>
                <div className={ css( this.styles.fixedHeaderFix ) }>
                </div>
            </div>
        )
    }
}

Header03.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
