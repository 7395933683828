import { StyleSheet } from "@convertly/css";

const SocialIconItem = ( global, element ) => {

    const colors = global.colors;

    const socialIconstyles = {
        socialIconsList: {
            display: 'inline-block',
            margin:'0 auto'
        },
        socialIconItem: {
            display: 'inline-block',
            marginRight: '.5em',
            padding: '0 .1em'
        }
    };
    return socialIconstyles;

};

export default SocialIconItem;