import { fromJS } from "immutable";
import { createReducer } from "redux-act";

import {
  toggleMenu,
  setMenu,
  showAppOverlay,
  showLoadingIcon,
  setAppOverlay,
  setAppOverlayIndex,
  setNotifications,
  setUploadingImage,
  setHelpFormSent,
  setAlert,
  setAlertConfirmation,
  setSetting,
  setAppOverlayOpacity,
  setStripe,
  setGraphRunning,
  setGraphRequests
} from "./actions";

const initialState = fromJS({
  isMenuOpen: true,
  site: {},
  displayAppOverlay: false,
  appOverlayOpacity: 0.5,
  displayLoadingIcon: false,
  appOverlayIndex: 2000,
  notifications: [],
  uploadingImage: false,
  helpFormSent: false,
  alert: null,
  alertConfirmation: null,
  settings: {},
  stripe: {},
  graphRunning: false,
  graphRequests: []
});

const reducers = {
  [setStripe]: (state, stripe) => state.merge({ stripe }),

  [setGraphRunning]: (state, graphRunning) => state.merge({ graphRunning }),

  [setGraphRequests]: (state, graphRequests) => state.merge({ graphRequests }),

  [setSetting]: (state, { field, value }) =>
    state.mergeDeep({
      settings: {
        [field]: value
      }
    }),

  [setHelpFormSent]: (state, helpFormSent) => state.merge({ helpFormSent }),

  [setAlert]: (state, alert) => state.merge({ alert }),

  [setAlertConfirmation]: (state, alertConfirmation) => state.merge({ alertConfirmation }),

  [setNotifications]: (state, notifications) => state.merge({ notifications }),

  [setUploadingImage]: (state, uploadingImage) => state.merge({ uploadingImage }),

  [setAppOverlay]: (state, appOverlayOpacity) => state.merge({ appOverlayOpacity }),

  [setAppOverlayIndex]: (state, appOverlayIndex) => state.merge({ appOverlayIndex }),

  [setAppOverlayOpacity]: (state, appOverlayOpacity) => state.merge({ appOverlayOpacity }),

  [showLoadingIcon]: (state, displayLoadingIcon) => state.merge({ displayLoadingIcon }),

  [showAppOverlay]: (state, displayAppOverlay) => state.merge({ displayAppOverlay }),

  [toggleMenu]: state => state.merge({ isMenuOpen: !state.get("isMenuOpen") }),

  [setMenu]: (state, isMenuOpen) => state.merge({ isMenuOpen })
};

const appReducer = createReducer(reducers, initialState);

export default appReducer;
