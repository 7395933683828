/*global Inferno, $ */
/* eslint-disable */
import {include} from '@convertly/thor';

export const InfernoForm = () => {

    /** Imports **/
    const Graph = include('../../utils/graph', 'Graph');
    const ValidateForm = include('../../utils/form-validation', "ValidateForm");
    const Dispatch = include("../../utils/dispatcher", "Dispatch");
    const RecaptchaInferno = include( "./Recaptcha/Recaptcha", "RecaptchaInferno" )

    return (id, data, formId, submitText, confirmationTitle, confirmationMessage, events, formName, recaptchaEnabled ) => {

        const hasRecaptcha = recaptchaEnabled === "true"
        const root = document.getElementById(id);
        const dispatch = Dispatch(id);
        var Recaptcha
        if(hasRecaptcha){
             Recaptcha = RecaptchaInferno(id)();
        }



        //GA Events
        dispatch.attach(events);

        /** Init Imports **/

            //Convertly Utils
        const graph = Graph();
        const validateForm = ValidateForm();

        // Set up our Graph query
        const query = `mutation($id:ID!, $fields:String!, $attachments: [ String ]) { form(id: $id, fields: $fields, attachments: $attachments)
                { success, message }
            }`;


        const CheckoutWrapper = Inferno.createClass({

            getInitialState: function () {

                return {
                    formFieldsMeta: [],
                    processingForm: false
                };
            },



            /** Sorts the form fields so they render in proper order **/
            sortFormFields: function () {

                let parseData = JSON.parse(data);
                let formFieldsMeta = parseData.sort((a, b) => {
                    return ((parseInt(a["sort"]) > parseInt(b["sort"])) ? -1 : ((parseInt(a["sort"]) < parseInt(b["sort"])) ? 1 : 0));

                });
                this.generateStateObject(formFieldsMeta);

            },

            /** Updates inputs values **/
            updateInputField: function (e, field) {
                const newState = {};
                newState[field] = e.target.value;
                this.setState(newState);
            },

            /** Updates inputs values **/
            updateCheckBox: function (e, field, value) {
                const newState = {};

                newState[`${field}`] = !this.state[`${field}`];

                this.setState(newState);
            },

            /** Generates form field **/
            generateFormField: function (formFieldMeta) {

                let formField = [];
                switch (formFieldMeta.el) {
                    case "fileupload": {
                        formField.push(
                            <label className={`${root.className} convertly-form-builder-label`} >
                                {formFieldMeta.label}
                            </label>
                        )
                        formField.push(
                            <div>
                                <input
                                    className={`${root.className} input`}
                                    id="fileupload"
                                    onChange={(e) => this.updateInputField(e, formFieldMeta.name)}
                                    type={"file"}
                                    accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png, image/jpeg, application/pdf"
                                    //cap at 6 mb
                                />
                                <span>Max File Size 6<small>MB</small></span>
                                <p className={`${root.className} form-error-message`}>This field is required</p>
                            </div>
                        );
                        break;
                    }
                    case "input": {
                        formFieldMeta.label &&
                        formField.push(
                            <label className={`${root.className} convertly-form-builder-label`}>
                                {formFieldMeta.label}
                            </label>
                        )
                    }
                        formField.push(
                            <input
                                className={`${root.className} input`}
                                onChange={(e) => this.updateInputField(e, formFieldMeta.name)}
                                type={formFieldMeta.type || 'text'}
                                name={formFieldMeta.name}
                                placeholder={formFieldMeta.placeholder}
                                value={this.state[formFieldMeta.name]}
                                required={formFieldMeta.required}
                                aria-required={formFieldMeta.required}/>
                        );

                        if (formFieldMeta.required) {
                            formField.push(
                                <p className={`${root.className} form-error-message`}>This field is required</p>
                            )
                        }
                        break;
                    case "textArea":
                        if (formFieldMeta.label) {
                            formField.push(
                                <label className={`${root.className} convertly-form-builder-label`}>
                                    {formFieldMeta.label}
                                </label>
                            )
                        }
                        formField.push(
                            <textArea
                                className={`${root.className} textArea`}
                                onChange={(e) => this.updateInputField(e, formFieldMeta.name)}
                                type={formFieldMeta.type || 'text'}
                                rows={formFieldMeta.rows || 4}
                                name={formFieldMeta.name}
                                placeholder={formFieldMeta.placeholder}
                                value={this.state[formFieldMeta.name]}
                                required={formFieldMeta.required}
                                aria-required={formFieldMeta.required}/>
                        );

                        if (formFieldMeta.required) {
                            formField.push(
                                <p className={`${root.className} form-error-message`}>This field is required</p>
                            )
                        }
                        break;
                    case "checkbox": {
                        formField.push(
                            <label className={`${root.className} checkboxLabel`}>

                                <input
                                    className={`${root.className} checkbox`}
                                    type={"checkbox"}
                                    checked={this.state[`${formFieldMeta.name}`]}
                                    name={formFieldMeta.name}
                                    onClick={(e) => this.updateCheckBox(e, formFieldMeta.name, formFieldMeta.value)}
                                    placeholder={formFieldMeta.placeholder}
                                    value={this.state[`${formFieldMeta.name}`]}
                                    required={formFieldMeta.required}/>

                                {formFieldMeta.label}
                            </label>
                        );
                        if (formFieldMeta.required) {
                            formField.push(
                                <p className={`${root.className} form-error-message`}>This field is required</p>
                            )
                        }
                        break;
                    }
                    // case "checkboxGroup": {
                    //     formField.push(
                    //         <div>
                    //             <label className={`${root.className} checkboxLabel`}>
                    //                 {formFieldMeta.label}
                    //             </label>
                    //             {formFieldMeta.options.map((option) => {
                    //                 return (
                    //                     <div>
                    //                         <input
                    //                             className={`${root.className} checkbox`}
                    //                             type={"checkbox"}
                    //                             checked={this.state[`${formFieldMeta.name}_checkbox`]}
                    //                             name={formFieldMeta.name}
                    //                             onClick={(e) => this.updateCheckBox(e, formFieldMeta.name, formFieldMeta.value)}
                    //                             placeholder={formFieldMeta.placeholder}
                    //                             value={option.name}
                    //                             required={formFieldMeta.required}/>
                    //                         <label>{option.label}</label>
                    //                     </div>
                    //                 )
                    //             })
                    //             }
                    //         </div>
                    //     );
                    //     if (formFieldMeta.required) {
                    //         formField.push(
                    //             <p className={`${root.className} form-error-message`}>This field is required</p>
                    //         )
                    //     }
                    //     break;
                    // }
                    case "radio":
                        formField.push(
                            <label className={`${root.className} radioLabel`}
                                   onChange={(e) => {
                                       this.updateInputField(e, formFieldMeta.name)
                                   }}
                            >

                                <input
                                    className={`${root.className} radio`}
                                    type={"radio"}
                                    name={formFieldMeta.name}
                                    placeholder={formFieldMeta.placeholder}
                                    value={formFieldMeta.value}
                                    onChange={(e) => {
                                        this.updateInputField(e, formFieldMeta.name)
                                    }}
                                    required={formFieldMeta.required}/>

                                {formFieldMeta.label}

                            </label>);

                        if (formFieldMeta.required) {
                            formField.push(
                                <p className={`${root.className} form-error-message`}>This field is required</p>
                            )
                        }
                        break;
                    case "radioGroup": {
                        formField.push(
                            <div>
                                <label className={`${root.className} radioLabel`}
                                       onChange={(e) => {
                                           this.updateInputField(e, formFieldMeta.name)
                                       }}>
                                    {formFieldMeta.label}
                                </label>
                                {formFieldMeta.options.map((option) => {
                                    return (
                                        <div>
                                            <input
                                                className={`${root.className} radio`}
                                                type={"radio"}
                                                name={formFieldMeta.name}
                                                placeholder={formFieldMeta.placeholder}
                                                value={option.value}
                                                onChange={(e) => {
                                                    this.updateInputField(e, formFieldMeta.name)
                                                }}
                                                required={formFieldMeta.required}/>
                                            <label htmlFor={formFieldMeta.name}>{option.label}</label>
                                        </div>
                                    )
                                })}
                            </div>
                        );

                        if (formFieldMeta.required) {
                            formField.push(
                                <p className={`${root.className} form-error-message`}>This field is required</p>
                            )
                        }
                        break;
                    }
                    case "select": {
                        {
                            formFieldMeta.label &&
                            formField.push(
                                <label className={`${root.className} convertly-form-builder-label`}>
                                    {formFieldMeta.label}
                                </label>
                            )
                        }
                        formField.push(
                            <div className={`${root.className} selectContainer`}>
                                {/** Arrow Down SVG **/}
                                <svg version="1.1"
                                     className={`${root.className} caretDown`}
                                     xmlns="http://www.w3.org/2000/svg"
                                     width="50"
                                     height="50"
                                     viewBox="0 0 404.308 404.309">
                                    <g>
                                        <path d="M0,101.08h404.308L202.151,303.229L0,101.08z"/>
                                    </g>
                                </svg>

                                <select className={`${ root.className } selectInput`}
                                        value={this.state[formFieldMeta.name] || formFieldMeta.options[0].value}
                                        onChange={(e) => this.updateInputField(e, formFieldMeta.name)}
                                >

                                    <option selected hidden
                                            disabled>{formFieldMeta.defaultText ? formFieldMeta.defaultText : "Select An Option:"} </option>

                                    {formFieldMeta.options.map((option) => {
                                        return (
                                            <option value={option.value}> {option.label} </option>
                                        )
                                    })
                                    }
                                </select>

                            </div>
                        );
                        if (formFieldMeta.required) {
                            formField.push(
                                <p className={`${root.className} form-error-message`}>This field is required</p>
                            )
                        }
                        break;
                    }
                }

                return formField;
            },

            /** Generates the class name based on width in data **/
            getFormWidthClass: function (elementWidth) {

                let elementWidthClassName;

                switch (elementWidth) {
                    case "quarter":
                        elementWidthClassName = "quarter";
                        break;
                    case "half":
                        elementWidthClassName = "half";
                        break;
                    case "third":
                        elementWidthClassName = "third";
                        break;
                    case "full":
                        elementWidthClassName = "full";
                        break;
                    default:
                        elementWidthClassName = "full";
                        break;
                }


                return elementWidthClassName

            },

            /** Generates object that handles forms state **/
            generateStateObject: function (formFieldsMeta) {

                let tempState = this.state;
                tempState.formFieldsMeta = formFieldsMeta;
                formFieldsMeta.map((metaItem, index) => {

                    if (metaItem.el === "fileupload") {
                        return
                    }

                    if (metaItem.el === "checkbox") {
                        tempState[`${metaItem.name}_checkbox`] = false
                    }

                    tempState[metaItem.name] = "";

                    if (metaItem.el === "select") {
                        tempState[metaItem.name] = metaItem.options[0].value;
                    }
                });
                this.setState(tempState);

            },

            /** Renders the fields **/
            renderFormFields: function () {


                let formFieldsMeta = this.state.formFieldsMeta;

                let formFields = formFieldsMeta.map((formFieldMeta, index) => {
                    let formWidthClass = this.getFormWidthClass(formFieldMeta.width);
                    let formField = this.generateFormField(formFieldMeta);
                    let pullUp = formFieldMeta.el === "select" ? 'pullUp' : '';

                    return (
                        <div className={`${root.className} fieldContainer ${pullUp} ${formWidthClass}`}>
                            {formField}
                        </div>
                    )

                });

                return formFields;

            },

            cb: function (error = null, result) {

                console.log('cb', result, error);

                const {success} = result.form;

                dispatch.trigger('convertly_form_submission_post_request', {
                    formName: formName,
                    success,
                    error,
                    fields: this.formatFieldsForEvent(this.getRenderedTempState())
                });


                dispatch.trigger(`convertly_form_submission_post_request_${formName}`, {
                    success,
                    error,
                    fields: this.formatFieldsForEvent(this.getRenderedTempState())
                });

                if (error) {
                    this.setState({
                        complete: true,
                        processingForm: false,
                        formCompletionMessage: <div>
                            <h3 className={root.className + " confirmationMessageHeader"}>Something went wrong
                                <div className={root.className + " divider"}/>
                            </h3>

                            <p className={root.className + " confirmationMessage"}>Please try again</p>
                        </div>
                    });

                } else {

                    if (success) {
                        this.setState({
                            complete: true,
                            processingForm:false,
                            formCompletionMessage: <div>
                                <h3 className={root.className + " confirmationMessageHeader"}>{confirmationTitle || `Thanks for reaching out!`}
                                    <div className={root.className + " divider"}/>
                                </h3>

                                <p className={root.className + " confirmationMessage"}>{confirmationMessage || `Your information has been sent
                                    and
                                    someone will reach out to you soon.`}</p>
                            </div>

                        });
                        dispatch.trigger('submit', this.state.fieldsForDispatch);
                    } else {
                        this.setState({
                            complete: true,
                            formCompletionMessage: <div>
                                <h3 className={root.className + " confirmationMessageHeader"}>Something went wrong
                                    <div className={root.className + " divider"}/>
                                </h3>

                                <p className={root.className + " confirmationMessage"}>Please try again</p>
                            </div>
                        });
                    }
                }

            },

            /** validates Form and sends message **/
            sendMessage: function (e, fields) {

                e.preventDefault();

                if(hasRecaptcha) {
                    const recaptchaInvalid = !Recaptcha.fetchResponse();

                    this.setState({recaptchaInvalid});

                    if(recaptchaInvalid) return false;
                }

                const {formFieldsMeta} = this.state;

                const fieldsForDispatch = Object.keys(fields).map(field => {

                    let fieldMeta = formFieldsMeta.filter(meta => meta.name === field);

                    if (!fieldMeta.length) {
                        return {};
                    }
                    if (fieldMeta[0].el === "fileupload") {
                        return {}
                    }

                    fieldMeta = fieldMeta[0]


                    let value;

                    const testValue = fields[field];
                    console.log(fields)

                    if (testValue.toString() === "[object Object]") {
                        value = testValue.value;
                    } else {
                        value = testValue;
                    }

                    return {
                        label: fieldMeta.label,
                        value: value,
                    };

                });

                // encodes special characters etc & that break the api call
                Object.keys(fields).forEach(field => {
                    fields[field] = encodeURIComponent(fields[field]);
                });

                let formValid = validateForm(`form_${id}`);

                const formattedFields = this.formatFieldsForEvent(fields);

                dispatch.send(`convertly_form_submission_post_validation_${formName}`, {
                    fields: formattedFields,
                    valid: formValid
                });

                dispatch.send(`convertly_form_submission_post_validation`, {
                    formName,
                    fields: formattedFields,
                    valid: formValid
                });
                if (true) {
                    this.setState({
                        processing: true,
                        processingForm:true
                    });

                    this.handleUploads().then((fileUrls) => {

                        const variables = {
                            id: formId,
                            fields: JSON.stringify({fields}),
                            attachments: fileUrls
                        };
                        this.setState({
                            fields,
                            fieldsForDispatch,
                        }, () => {

                            graph({query, variables, cb: this.cb});
                        });

                    });


                }
            },

            handleUploads() {
                return new Promise((resolve, reject) => {
                    const uploadFields = document.querySelectorAll(`input[type=file]`);


                    if (uploadFields.length > 0) {
                        const formData = new FormData();

                        uploadFields.forEach((fileUpload, index) => {
                            if (fileUpload.files[0] && (fileUpload.files[0].size / 1048576).toFixed(1) > 4) {
                                $(fileUpload).next().addClass('show-form-error-message').text('File Too Large');
                                reject('file too large')
                            }

                            formData.append('file', fileUpload.files[0])
                        });
                        fetch("https://uploader.convertly.com/form-upload", {
                            method: "POST",
                            body: formData
                        }).then(response => resolve(
                            resolve(response.json())
                            )
                        ).catch(
                            () => {
                                return false
                            }
                        )

                    }
                    else {
                        resolve([])
                    }
                })

            },

            formatFieldsForEvent(formFields) {

                let Data;

                try {
                    Data = JSON.parse(data);
                } catch (e) {
                    return [];
                }

                return Data.map(({el, label, name, required}) => {

                    required = required === 'true';
                    let value = formFields[name];
                    if (el === 'checkbox') {
                        value = !!value;
                    }

                    return {
                        type: el,
                        label,
                        name,
                        required,
                        value,
                    };

                });

            },

            sendFormSubmissionEvent(formFields) {
                const fields = this.formatFieldsForEvent(formFields);

                dispatch.send(`convertly_form_submitted_${formName}`, fields);
                dispatch.send(`convertly_form_submitted`, {formName, fields});

                dispatch.send(`convertly_form_submission_pre_validation_${formName}`, fields);
                dispatch.send(`convertly_form_submission_pre_validation`, {formName, fields});

            },

            componentDidMount() {
                this.sortFormFields();
                if(hasRecaptcha) {
                    Recaptcha.createRecatchaScript();
                }

            },

            getRenderedTempState() {

                let renderedTempState = {};

                this.state.formFieldsMeta.map((metaItem) => {
                    if (metaItem.el === "fileupload") {
                        return false
                    }
                    renderedTempState[metaItem.name] = this.state[metaItem.name]

                });

                return renderedTempState;

            },

            render: function () {

                let formFields = this.renderFormFields();

                const renderedTempState = this.getRenderedTempState();

                let formStateClass = this.state.complete ? " flyout " : "";
                let tyTextClass = this.state.complete ? " in" : " ";

                return (
                    <div className={root.className + " formContainer"}>
                        <form id={`form_${id}`}
                              onSubmit={(e) => {
                                  this.sendMessage(e, renderedTempState)
                              }}
                              className={`${root.className} formWrapper ${formStateClass}`}>
                            {formFields}
                            { hasRecaptcha &&
                            <div className={`${root.className} fieldContainer`}>
                            <Recaptcha.Component
                                data-callback={ () => console.log("BEACH HOUSE") }
                                onClick={(e) => console.log(e, 'tell me something')}/>
                                    { this.state.recaptchaInvalid && <p>Please complete this step</p>}
                                </div>

                            }
                            <input
                                onClick={() => this.sendFormSubmissionEvent(renderedTempState)}
                                className={`${root.className} submitButton ${this.state.processingForm ? 'disabled' : ''}`}
                                disabled={this.state.processing}
                                type="submit"
                                value={submitText}/>


                        </form>

                        {/** Confirmation Message **/}
                        <div className={root.className + " confirmationMessageWrapper" + tyTextClass}>
                            {this.state.formCompletionMessage}
                        </div>

                    </div>
                )
            }

        });

        Inferno.render(<CheckoutWrapper/>, root);

    };

};
