import React from 'react';
import Component from 'source/rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';
import _ from 'lodash';

import getEcommerceSettings from '../ecommerce-settings';

//Default Props
//import defaultData from '';
const defaultData = {};
import defaultGlobal from 'source/themes/global';

//Styles
import Styles from './confirmation-page-styles';

//utils
import {clientImage} from '../../utils/generateImage';
import LoadCart from 'source/utils/ecomm-utils/load-cart';
import {generateLink} from 'source/utils/generateLink';


//Components
import Elements from 'source/elements';
import CheckoutHeader from '../checkout-process/checkout-header/checkout-header-01/checkout-header-01'
import ConfirmationMessage from './confirmation-message/confirmation-message-inferno';
import OrderDetails from './order-details/order-details';

export default class CheckoutStage01 extends Component {

    constructor(props) {

        super(props);


        /**
         * Ecommerce Settings
         **/



    }

    render() {
        let ecommerceSettings = getEcommerceSettings(this.props.site);

        this.styles = Styles(this.props.global, this.props.data);

        const siteLink = generateLink({url: '', linkType: 'inferno'});
        const siteId = _.get(this.props, 'site.siteId');

        return (
            <div className={ css(this.styles.checkoutFlowWrapper) }>
                { /** Checkout Header **/ }
                <div className={ css(this.styles.checkoutFlowWrapper) }>
                    { /** Checkout Body **/ }
                    <div className={ css(this.styles.checkoutBody) }>
                        { /** Controls Mobile View of cart **/ }
                        <div className={ css(this.styles.cartToggleContainer) }/>
                        { /** Checkout Flow Wrapper **/}
                        <Elements.Container>
                            <Elements.Row>

                                <div className={ css(this.styles.checkoutFlowContainer) }>
                                    { /** Checkout Column **/ }


                                        <div id={ id(OrderDetails, siteId, siteLink, JSON.stringify(ecommerceSettings)) }
                                             className={ css(this.styles.orderDetailReviewInfernoContainer) }/>

                                </div>
                            </Elements.Row>
                        </Elements.Container>
                    </div>

                </div>
            </div>
        )
    }
}


CheckoutStage01.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
