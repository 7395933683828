import { StyleSheet } from "@convertly/css";
import Typography from '../../elements/style-functions/typography';

const newsLetterSignUpStyles = ( global, local ) => {

    const titleTypography = new Typography ( global, local.newsletterTitle.text );
    const ctaTypography = new Typography ( global, local.newsletterCta.text );

    const colors = global.colors;

    return StyleSheet.create({

        launcher: {
            fontFamily: global.fontFamily.primaryFont,
            color: global.colors.dark.black,
            fontWeight: '700',
            cursor: 'pointer',
            ':hover': {
                opacity: '.6'
            }
        },

        modal: {
            display: 'none',
            position: 'fixed',
            zIndex: '1',
            left: '0',
            top: '0',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            backgroundColor: 'rgba(0,0,0,0.3)',
        },

        modalContentArea: {
            margin: 'auto',
            backgroundColor: global.colors.light.white,
            zIndex: '2',
            maxWidth: '550px',
            width: '80%',
            textAlign: 'center',
            padding: '0px 0px',
            height: '500px',
            marginTop: '175px',
        },

        modalHeading: {
            fontFamily: global.fontFamily.primaryFont,
            color: global.colors.dark.black,
            fontWeight: '900',
            fontSize: '36px',
            marginBottom: '30px',
            marginTop: '40px',
            padding: '0px 40px'
        },

        modalParagraph: {
            marginBottom: '30px',
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '24px',
            color: global.colors.dark.black,
            fontFamily: global.fontFamily.primaryFont,
            padding: '0px 70px'

        },

        closeButton: {
            fontSize: '46px',
            fontWeight: '400',
            textAlign: 'right',
            marginLeft: '480px',
            padding: '20px',
            cursor: "pointer",
        },

        newsLetterForm: {

        }



    })
};

export default newsLetterSignUpStyles;