/*global Inferno, $ */
/* eslint-disable */
import {include} from '@convertly/thor';

const Script = () => {

        const Dispatch = include('../utils/dispatcher', "Dispatch");
        const SnackBarAlertInferno = include( "./snack-bar-alert", "SnackBarAlertInferno" );

        return (id) => {

        	const filename = 'snack-bar-alert-wrapper';
        	const report = false;

			const s = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
			const idLength = 7;

			const generateId = () => {
				return Array.apply(null, Array( idLength )).map(function() { return s.charAt(Math.floor(Math.random() * s.length)); }).join('');
			};

            const dispatch = Dispatch(id);

            const root = document.getElementById(id);
            if( !root) {
            	console.log('returning', id, this);
            	return
			}

            const SnackBarAlert = SnackBarAlertInferno( dispatch );


            const SnackBar = Inferno.createClass({

				getInitialState: function () {

                    return {
                        alert: '',
                        type: "info",
                        title: '',
                        timeout: 5000,
                        activeStatus: true,
                        messages: [],
						activeMessages: [],
                        messagesMeta: [],
                        removeDispatchCalled: false,
                        addDispatchCalled: false,
						nextHeight: 0
                    };
                },

				componentDidMount() {

					if( report ) console.log( filename, '::componentDidMount' );

					dispatch.on( 'snack_bar_alert', this.launchSnackAlertBar );
					dispatch.on( 'remove_snack_bar', this.removeSnackAlertBar );

				},

				launchSnackAlertBar: function ( alertPayload ) {

					if( report ) console.log( filename, '::launchSnackAlertBar' );

					const { messages } = this.state;

					const message = {
						alert: alertPayload.alert,
						type: alertPayload.type,
						title: alertPayload.title,
						timeout: alertPayload.timeout ? alertPayload.timeout : 5000,
						id: generateId(),
						top: this.state.nextHeight,
						active: true
					};

					messages.push(
						message
					);

					this.setState({
						messages: messages
					});

				},

                removeSnackAlertBar: function( id ) {

					if( report ) console.log( filename, '::removeSnackAlertBar', 'id:', id );

					const { messages } = this.state;

					const updatedActiveMessages = messages.map( ( msg ) => {
						if( msg.id !== id ) {
							msg.active = false;
						}
						return msg;
					});

					this.setState({
						messages: updatedActiveMessages
					}, () => {
						this.updateNextHeight();
					});

                },

				updateNextHeight: function() {

					const total = this.state.messages.reduce( (runningTotal,msg) => {
						return msg.active ? runningTotal + msg.height : 0;
					}, 0 );
					this.setState({
						nextHeight: total
					});
				},

				reportStyles: function( styles, id ) {

					let { height } = styles;
					height = height.replace( 'px', '' );
					height = parseFloat( height );

					const { messages } = this.state;

					const updatedMessages = messages.map( msg => {
						if( msg.id === id ) {
							msg.height = height;
						}
						return msg;
					});

					this.setState({
						messages: updatedMessages
					}, () => {
						this.updateNextHeight();
					});

				},

                render: function () {

                    const activeClass = this.state.activeStatus ? "active" : "";
                    const { messages, nextHeight } = this.state;
                    const numActiveMessages = this.state.activeMessages.length;

                    return (
                        <div className={ `${ root.className } snack-alert-container` }>
                            { messages.map( ( message ) => (
                            	<SnackBarAlert
									top={ nextHeight }
									reportStyles={ this.reportStyles }
									root={ root }
									message={ message }
								/>
							) ) }
                        </div>
                    )
                }

            });

            Inferno.render(<SnackBar />, root);

        };

    }
;

export default Script;
