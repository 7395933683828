/*global Inferno, $, ga, Convertly */
/* eslint-disable */
import {include} from '@convertly/thor';

const Script = () => {

    /** Imports **/
    const Graph = include('../../../utils/graph', 'Graph');
    const Dispatcher = include('../../../utils/dispatcher', 'Dispatcher');
    const ConvertToCurrency = include('../../../utils/ecomm-utils/convert-to-currency-ii', 'ConvertToCurrency');
    const FormValidation = include('../../../utils/form-validation', 'FormValidation');
    const Cookie = include('../../../utils/cookie', 'Cookie');
    const BuildPricingObject = include('../../../utils/ecomm-utils/inferno-utils/build-product-pricing-object-inferno', 'BuildPricingObject');

    /** Component **/
    const ConfirmationMessageInferno = include('../confirmation-message/confirmation-message-inferno', 'ConfirmationMessageInferno');
    const SpinnerSVGInferno = include('../../../svg/spinner/spinner', 'SpinnerSVGInferno');
    const GoogleButtonInferno = include('../../../sign-in-up/google-button/GoogleButton', 'GoogleButtonInferno');
    const FacebookButtonInferno = include('../../../sign-in-up/facebook-button/FacebookButton', 'FacebookButtonInferno');
    const SignInFormInferno = include('../../../sign-in-up/sign-in-up-form/upSigninButton', 'SignInFormInferno');
    const NotificationBoxInferno = include('../../../ecommerce-common-components/notification-box/notification-box', 'NotificationBoxInferno');
    const StaySignedInInferno = include('../../../sign-in-up/sign-in-up-form/stay-signed-in/stay-signed-in', 'StaySignedInInferno');

    const CurbsidePickupWidgetInferno = include("../../../ecommerce-common-components/CurbsidePickupWidget/CurbsidePickupWidget", "CurbsidePickupWidgetInferno");
    const OrderStatusInferno = include("../../../ecommerce-common-components/OrderStatus/OrderStatus", "OrderStatusInferno");
    const OrderNotificationsInferno = include("../../../ecommerce-common-components/OrderNotifications/OrderNotifications", "OrderNotificationsInferno")
    const EditNotificationsModalInferno = include('../../../ecommerce-common-components/EditNotificationsModal/EditNotificationsModal', "EditNotificationsModalInferno")
    const GenericModalInferno = include("../../../ecommerce-common-components/GenericModal/GenericModal", "GenericModalInferno")


    return (id, siteId, siteLink, ecommerceSettings) => {
        ecommerceSettings = JSON.parse(ecommerceSettings)
        const hasLocalStorage = $('html').hasClass('localstorage');

        const root = document.getElementById(id);

        function getUrlParameter(name) {
            name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
            let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
            let results = regex.exec(location.search);
            return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        }

        const urlParams = new getUrlParameter(window.location.search);
        //const orderId = urlParams.get('orderId');


        /** Init Imports **/

            //Convertly Utils
        const graph = Graph();
        const dispatch = new Dispatcher(id);
        const convertToCurrency = new ConvertToCurrency();
        const validateForm = new FormValidation();
        const cookie = Cookie();
        const buildPricingObject = BuildPricingObject();


        /** Components */
        const SpinnerSVG = SpinnerSVGInferno();
        const GoogleButton = GoogleButtonInferno(dispatch, null);
        const FacebookButton = FacebookButtonInferno(dispatch, null);
        const StaySignedIn = StaySignedInInferno(dispatch);
        const SignUpForm = SignInFormInferno(dispatch, null, validateForm, SpinnerSVG, StaySignedIn);
        const NotificationBox = NotificationBoxInferno();

        const OrderStatus = OrderStatusInferno(dispatch);
        const OrderNotifications = OrderNotificationsInferno();
        const GenericModal = GenericModalInferno();
        const EditNotificationsModal = EditNotificationsModalInferno(dispatch, graph, GenericModal, SpinnerSVG);
        const CurbsidePickupWidget = CurbsidePickupWidgetInferno(dispatch, graph, OrderStatus, OrderNotifications, EditNotificationsModal)


        const ConfirmationMessage = ConfirmationMessageInferno(graph, dispatch, GoogleButton, FacebookButton, SignUpForm, siteLink, NotificationBox, cookie);


        /** Get order details query **/
        const query = `query ($id: ID!) {
          OrderConfirmation(id: $id) {
          id, 
          salestax 
          billingAddress { firstName lastName address1 address2 city state zip country }
          orderStatus 
          shippingInfo { object_id provider rate service location} 
          shippingRate 
          shippingAddress { firstName lastName address1 address2 city state zip country email}
          email
          smsAlert {enabled sms}
          messages {
                    id timestamp message callBackNumber from
                  }
          items { title quantity image price salePrice onSale variants } total orderNumber orderDate  }
          
          }`

        ;

        const mutation = `mutation ($accessToken: ID!, $profile: AccountProfileInput, $shipping: [AddressInput], $billing: [AddressInput]) {
                            EditAccount(accessToken: $accessToken, profileData: $profile, shippingAddresses: $shipping, billingAddresses: $billing ) 
                            { id, shippingAddresses { id defaultAddress firstName lastName address1 address2 city state zip country email } }
                        }`;

        const orderMutation = `mutation ( $accessToken: String!, $orderId: ID! ) {
                                  attachOrder( accessToken: $accessToken, orderId: $orderId ) {
                                    success, message
                                  }
                                }`;

        const OrderDetails = Inferno.createClass({

            getInitialState: function () {

                const orderId = getUrlParameter('orderId');

                return {
                    billingAddress: {},
                    shippingAddress: {},
                    shippingInfo: {},
                    orderNumber: orderId,
                    orderDate: "",
                    items: [],
                    shippingRate: {},
                    total: 0,
                    orderLoaded: false,
                    isThankYouPage: false
                };
            },

            getOrderConfirmationDetails: function () {

                const orderId = getUrlParameter('orderId');


                const variables = {
                    id: orderId,
                };

                graph({query, variables, cb: this.handleOrderQuery})

            },

            // https://davidwalsh.name/query-string-javascript
            getUrlParameter: function (name) {
                name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
                let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
                let results = regex.exec(location.search);
                return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
            },

            /** gets order info **/
            handleOrderQuery: function (err, response) {

                const {OrderConfirmation} = response;

                if (!OrderConfirmation.id) {
                    return;
                }

                const thankYouParam = this.getUrlParameter('typage');

                let isThankYouPage = false;

                if (thankYouParam === "true") {
                    isThankYouPage = true
                }
                console.log(response.OrderConfirmation)
                const {
                    billingAddress,
                    shippingInfo,
                    shippingRate,
                    shippingAddress,
                    items,
                    orderDate,
                    orderNumber,
                    total,
                    email,
                    salestax,
                    smsAlert,
                    orderStatus,
                    messages,
                } = response.OrderConfirmation;


                items.map((item, index) => {

                    items[index].variants = JSON.parse(item.variants);

                });


                this.setState(
                    {
                        billingAddress: billingAddress,
                        orderId: id,
                        salestax: salestax,
                        shippingAddress: shippingAddress,
                        firstName: shippingAddress.firstName,
                        lastName: shippingAddress.lastName,
                        items: items,
                        shippingRate: shippingRate,
                        total: total,
                        orderStatus: orderStatus,
                        orderNumber: orderNumber,
                        orderDate: orderDate,
                        shippingInfo: shippingInfo,
                        orderLoaded: true,
                        isThankYouPage: isThankYouPage,
                        email: shippingAddress.email || email,
                        smsAlert: smsAlert,
                        messages
                    });

                dispatch.send('received_email');

                const orderId = cookie.read({name: 'placed_convertly_order'});

                if (orderId) {
                    // Order Confirmation is being loaded directly after placing an order
                    this.sendOrderConfirmationEvent(OrderConfirmation);
                }

            },

            sendOrderConfirmationEvent(order) {

                Convertly.DataLayer = {
                    Order_Id: order.orderNumber,
                    revenue: order.total,
                    shipping: order.shippingInfo.rate,
                    tax: order.salestax,
                    items: []
                };

                if (ga) {
                    ga('ecommerce:addTransaction', {
                        'id': order.orderNumber,                   // Transaction ID. Required.
                        //'affiliation': 'Acme Clothing',   // Affiliation or store name.
                        'revenue': order.total,               // Grand Total.
                        'shipping': order.shippingInfo.rate,                  // Shipping.
                        'tax': order.salestax                     // Tax.
                    });
                }

                order.items.forEach(item => {

                    Convertly.DataLayer.items.push({
                        'id': order.orderNumber,
                        'name': item.title,
                        'price': item.price,
                        'quantity': item.quantity
                    });

                    if (ga) {
                        ga('ecommerce:addItem', {
                            'id': order.orderNumber,                     // Transaction ID. Required.
                            'name': item.title,    // Product name. Required.
                            //'sku': 'DD23444',                 // SKU/code.
                            //'category': 'Party Toys',         // Category or variation.
                            'price': item.price,                 // Unit price.
                            'quantity': item.quantity                   // Quantity.
                        });
                    }

                });

                if (ga) ga('ecommerce:send');

                dispatch.send('event', {action: 'Order Confirmation', payload: Convertly.DataLayer});

                cookie.remove({
                    name: '2placed_convertly_order',
                    path: '/',
                    domain: ''
                })

            },

            createAccountQuery: function () {

                dispatch.watch('accessToken', (accessToken) => {

                    this.saveAccountInfoToAccount(accessToken);
                    this.saveOrderToAccount(accessToken);

                })

            },

            saveOrderToAccount: function (accessToken) {

                let variables = {
                    accessToken: accessToken,
                    orderId: orderId
                };

                let that = this;

                graph({query: orderMutation, variables, cb: that.accountInfoCB()})

            },

            /** Saves the account info to the account **/
            saveAccountInfoToAccount: function (accessToken) {

                let variables = {
                    accessToken: accessToken,
                    profile: {
                        "firstName": this.state.firstName,
                        "lastName": this.state.lastName
                    },
                    shipping: [{
                        "id": id,
                        "firstName": this.state.shippingAddress.firstName,
                        "lastName": this.state.shippingAddress.lastName,
                        "city": this.state.shippingAddress.city,
                        "zip": this.state.shippingAddress.zip,
                        "defaultAddress": true,
                        "address1": this.state.shippingAddress.address1,
                        "address2": this.state.shippingAddress.address2,
                        "state": this.state.shippingAddress.state,
                        "country": this.state.shippingAddress.country
                    }]
                };
                graph({query: mutation, variables, cb: this.accountInfoCB})


            },

            accountInfoCB: function (error = null, result) {
                console.log('result', result);
                console.log('error', error);

            },

            componentWillMount() {
                this.getOrderConfirmationDetails();
                this.createAccountQuery();

            },

            /** Renders callouts (on sale, gluten-free etc) **/
            renderCallOutSection(cartItemData) {
                let callOutSection = [];

                if (cartItemData.onSale) {
                    callOutSection.push(
                        <div className={`${root.className} chkGridprice_chkGridpricenote`}>On Sale</div>)
                }

                if (cartItemData.variants) {
                    cartItemData.variants.map((variant) => {
                        callOutSection.push(
                            <div className={`${root.className} chkGridprice_chkGridpricenote`}>
                                {variant.name}</div>
                        )
                    })
                }

                return callOutSection;
            },

            render: function () {

                if (!this.state.orderLoaded) {
                    return null;
                }


                let cartItems =
                    this.state.items.map((item) => {

                        item.pricing = buildPricingObject(item);

                        let finalPrice = item.pricing.onSale ? item.pricing.salePrice : item.pricing.price;


                        return (
                            <div className={`${root.className} orderDetailHeader`}>
                                <div className={`${root.className} itemColumn`}>
                                    <h4 className={`${root.className} orderDetailItem`}>{item.title}</h4>
                                    {this.renderCallOutSection(item)}
                                </div>

                                <div className={`${root.className} quantityColumn`}>
                                    <h4 className={`${root.className} orderDetailItem`}>{item.quantity}</h4>
                                </div>
                                <div className={`${root.className} subTotalColumn`}>
                                    <h4
                                        className={`${root.className} orderDetailItem`}>{convertToCurrency(finalPrice * item.quantity)}</h4>
                                </div>
                            </div>
                        )
                    });

                let addressString = this.state.shippingAddress.address1;

                if (this.state.shippingAddress.address2) {
                    addressString += ` #${this.state.shippingAddress.address2}`
                }

                let shippingAddress = null;


                if (this.state.shippingInfo) {

                    if (this.state.shippingInfo.provider !== "Will Call") {
                        shippingAddress =
                            <div className={`${root.className} shippingAddress`}>
                                <h4 className={`${root.className} shippingInformationTitle`}>Shipping
                                    to</h4>
                                <address>
                                <span className={root.className + " shippingAddressFullName"}>
                                    {`${this.state.shippingAddress.firstName} ${this.state.shippingAddress.lastName}`}
                                </span>

                                    <span className={root.className + " shippingAddressAddress"}>
                                    {addressString}
                                </span>
                                    <span className={root.className + " shippingAddressAddress"}>
                                    {`${this.state.shippingAddress.city}, ${this.state.shippingAddress.state}, ${this.state.shippingAddress.zip}`}
                                </span>
                                    <span className={root.className + " shippingAddressAddress"}>
                                    {`${this.state.shippingAddress.country}`}
                                </span>
                                </address>
                            </div>
                    }
                }
                if (this.state.shippingRate) {
                    let shippingMethod = `${this.state.shippingRate.provider} ${this.state.shippingRate.service}`
                }
                let shippingRate = null
                if (this.state.shippingInfo) {
                    shippingRate =
                        <div>
                        <span className={root.className + " shippingAddressFullName"}>
                            {this.state.shippingInfo.provider !== 'false' ? this.state.shippingInfo.provider : ''}
                        </span>

                            <span className={root.className + " shippingAddressFullName"}>
                            {this.state.shippingInfo.service || ''}
                        </span>

                            <span className={root.className + " shippingAddressFullName"}>
                            {this.state.shippingInfo.location || ''}
                        </span>

                            <span className={root.className + " shippingAddressAddress"}>
                            {this.state.shippingRate ? convertToCurrency(this.state.shippingRate) : ''}
                        </span>
                        </div>;

                }
                if (this.state.isThankYouPage) {
                    return (
                        <div>
                            <div className="col-md-6 col-xs-12">
                                <ConfirmationMessage rootDiv={root} email={this.state.email}/>
                            </div>
                            <div className="col-md-6 col-xs-12">
                                {/** Order Details Title **/}
                                <div className={`${root.className} checkoutCartReviewContainer`}>
                                    <div className={root.className + " orderDetailColumn"}>
                                        <div className={root.className + " orderDetailTitle"}>
                                            <h2 className={root.className + " chkTitle1"}>Order Details</h2>
                                            <div className={root.className + " titleUnderline_od"}/>
                                            <h3 className={root.className + " orderNumber"}>Order
                                                # {this.state.orderNumber}</h3>
                                            <h4 className={root.className + " orderDate"}>Placed
                                                on {this.state.orderDate}</h4>
                                        </div>
                                        <div className={`${root.className} orderDetailsRow`}>
                                            <div className={`${root.className} orderDetailHeader`}>
                                                <div className={`${root.className} itemColumn`}>
                                                    <h4 className={`${root.className} orderDetailHeading`}>Item</h4>
                                                </div>
                                                <div className={`${root.className} quantityColumn`}>
                                                    <h4 className={`${root.className} orderDetailHeading`}>Qty.</h4>
                                                </div>
                                                <div className={`${root.className} subTotalColumn`}>
                                                    <h4 className={`${root.className} orderDetailHeading`}>
                                                        Subtotal</h4>
                                                </div>
                                            </div>
                                            {cartItems}
                                        </div>
                                        <div className={`${root.className} totalsSection`}>
                                            <div>Tax: {this.state.salestax}</div>
                                            <div className={`${root.className} chkLabel`}>Total:</div>
                                            <div className={`${root.className} chkSubtotal`}>
                                                {convertToCurrency(this.state.total)}</div>
                                        </div>
                                        {this.state.shippingInfo &&
                                        <div className={`${root.className} shippingInformation`}>

                                            {
                                                this.state.shippingInfo && this.state.shippingInfo.object_id === "CURBSIDE" ?
                                                    <div className={`${root.className} curbside-container`}>
                                                        <h3>Curbside Pickup</h3>
                                                        <CurbsidePickupWidget order={
                                                            {
                                                                orderId: this.state.orderId,
                                                                orderStatus: this.state.orderStatus,
                                                                shipping: this.state.shippingInfo,
                                                                smsAlert: this.state.smsAlert,
                                                                messages: this.state.messages
                                                            }} rootClass={root.className}/>
                                                        <div className={ `${root.className} curbside-instructions` }>
                                                            <p><strong>Directions:</strong> {ecommerceSettings.curbsidePickupInstructions}</p>
                                                            { ecommerceSettings.curbsideCallLink &&
                                                            <p><strong>Phone Number:</strong> {ecommerceSettings.curbsideCallLink}</p>
                                                            }

                                                        </div>
                                                    </div> :
                                                    <div>
                                                        {shippingAddress}

                                                        <div className={`${root.className} shippingMethod`}>
                                                            <h4 className={`${root.className} shippingInformationTitle`}>Via</h4>
                                                            {shippingRate}
                                                        </div>
                                                    </div>
                                            }



                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className="col-xs-12 col-sm-8 col-sm-offset-2 col-md-offset-3">
                            {/** Order Details Title **/}
                            <div className={`${root.className} checkoutCartReviewContainer`}>
                                <div className={root.className + " orderDetailColumn"}>
                                    <div className={root.className + " orderDetailTitle"}>
                                        <h2 className={root.className + " chkTitle1"}>Order Details</h2>
                                        <div className={root.className + " titleUnderline_od"}/>
                                        <h3 className={root.className + " orderNumber"}>Order
                                            # {this.state.orderNumber}</h3>
                                        <h4 className={root.className + " orderDate"}>Placed
                                            on {this.state.orderDate}</h4>
                                    </div>
                                    <div className={`${root.className} orderDetailsRow`}>
                                        <div className={`${root.className} orderDetailHeader`}>
                                            <div className={`${root.className} itemColumn`}>
                                                <h4 className={`${root.className} orderDetailHeading`}>Item</h4>
                                            </div>
                                            <div className={`${root.className} quantityColumn`}>
                                                <h4 className={`${root.className} orderDetailHeading`}>Qty.</h4>
                                            </div>
                                            <div className={`${root.className} subTotalColumn`}>
                                                <h4 className={`${root.className} orderDetailHeading`}>
                                                    Subtotal</h4>
                                            </div>
                                        </div>
                                        {cartItems}
                                    </div>

                                    <div className={`${root.className} totalsSection`}>
                                        <div>Tax: {this.state.salestax}</div>
                                        <div className={`${root.className} chkLabel`}>Total:</div>
                                        <div className={`${root.className} chkSubtotal`}>
                                            {convertToCurrency(this.state.total)}</div>
                                    </div>
                                    <div className={`${root.className} shippingInformation`}>
                                        {
                                            this.state.shippingInfo && this.state.shippingInfo.object_id === "CURBSIDE" ?
                                                <div className={`${root.className} curbside-container`}>
                                                    <h3>Curbside Pickup</h3>
                                                    <CurbsidePickupWidget order={
                                                        {
                                                            orderId: this.state.orderId,
                                                            orderStatus: this.state.orderStatus,
                                                            shipping: this.state.shippingInfo,
                                                            smsAlert: this.state.smsAlert,
                                                            messages: this.state.messages
                                                        }} rootClass={root.className}/>
                                                        <div className={ `${root.className} curbside-instructions` }>
                                                            <p><strong>Directions:</strong> {ecommerceSettings.curbsidePickupInstructions}</p>
                                                            { ecommerceSettings.curbsideCallLink &&
                                                                <p><strong>Phone Number:</strong> {ecommerceSettings.curbsideCallLink}</p>
                                                            }

                                                        </div>
                                                </div> :
                                                <div>
                                                    <div className={`${root.className} shippingAddress`}>
                                                        {shippingAddress}
                                                    </div>
                                                    <div className={`${root.className} shippingMethod`}>
                                                        <h4 className={`${root.className} shippingInformationTitle`}>Via</h4>
                                                        {shippingRate}
                                                    </div>
                                                </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            }

        });

        Inferno.render(<OrderDetails/>, root);

    };

};

export default Script;
