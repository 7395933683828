import styled from 'styled-components';
import {MainButton} from "app/ui-components/Buttons"
import {GLOBALS} from "styles/global-default-styles";


export const ButtonMultiSelectWrapper = styled.div`
  display:flex;
  width:100%;
  align-items: stretch;
`

export const Button = styled(MainButton)`
  background:${({active}) => active ? GLOBALS.colorPrimary : GLOBALS.colorPrimaryLight};
  ${ ({active}) => !active && `color:${GLOBALS.colorPrimaryDarkOpacity};` }
  border-radius:0;
  padding:unset;
  padding-top:10px;
  padding-bottom:10px;
  margin:0;
  flex-grow:1;
`
