import _ from "lodash";
import axios from "axios";
import { fromJS } from "immutable";
import { store } from "app/app";
import {
  setSiteData,
  setGoogleFonts,
  setSiteThemeData,
  setLoadingTheme,
  setSavingTheme,
} from "containers/Editor/actions/editorActions";
import { setCurrentSite } from "containers/Authentication/actions";
import { GOOGLE_FONT_API_KEY } from "app/config";
import { graph } from "utils/graph";
import { Alerts } from "services/Alerts";

import { Components } from "source/index";
import { generateGlobalObject } from "source/default-themes/generate-global-object";
import {getGoogleFonts} from "../../../utils/get-google-fonts";

export function updateTheme(updatedTheme) {
  return async dispatch => {
    const Editor = store.getState().get("editor").editorReducer;
    const site = Editor.get("currentSite");

    const themeData = JSON.parse(updatedTheme);

    await dispatch(setSiteData(site.set("themeData", fromJS(themeData))));
  };
}

export const reloadTheme = () => async dispatch => {
  await dispatch(setLoadingTheme(true));

  const Editor = store.getState().get("editor").editorReducer;
  const site = Editor.get("currentSite");

  const query = `
			query( $id: ID! ){
			  Site(id: $id) {
          id,
          themeData
			  }
			}
			`;

  const variables = {
    id: site.get("id"),
  };

  const response = await graph({ query, variables });

  let themeData = _.get(response, "Site.themeData") || "{}";
  const siteId = _.get(response, "Site.id");

  if (siteId !== site.get("id")) {
    return;
  }

  themeData = JSON.parse(themeData);

  await dispatch(setSiteData(site.set("themeData", fromJS(themeData))));
  await dispatch(setLoadingTheme(false));
};

export function setupSiteTheme() {
  return async dispatch => {
    const Editor = store.getState().get("editor").editorReducer;
    const site = Editor.get("currentSite");
    const themeData = Editor.getIn(["currentSite", "themeData"]);

    if (themeData.size) {
      return;
    }

    const themeFunction = _.get(Components, "themes." + site.get("theme"));

    if (!themeFunction) {
      console.error(`INVALID SITE THEME: ${site.get("theme")}`);
      return null;
    }

    let theme = themeFunction();
    theme = cleanFonts(theme);
    theme = generateGlobalObject(theme);

    await dispatch(setSiteThemeData(fromJS(theme)));

    await saveSiteThemeData({
      siteId: site.get("siteId"),
      themeData: theme,
    });
  };
}

/**
 * This function is called to save a site themeData if it doesn't exist.
 * Should only need to be called once per site
 *
 * @param siteId
 * @param themeData
 * @returns {Promise<void>}
 */
export const saveSiteThemeData = async ({ siteId, themeData }) => {
  await Alerts.alert(
    "We need to upgrade your site.  This is a one time thing.  We will reload the app when we are done"
  );

  const query = `mutation( $id: ID!, $themeData: String ) {
      editSiteThemeData( id: $id, themeData: $themeData ) {
        id
      }
    }`;

  const variables = {
    id: siteId,
    themeData: JSON.stringify(themeData),
  };

  const res = await graph({ query, variables });

  const id = _.get(res, "editSiteThemeData.id");

  if (!id) {
    return await Alerts.alert("There was an issue upgrading your site. Please contact support.");
  }

  window.location.reload();
};

export function cleanFonts(obj) {
  Object.keys(obj).forEach(tKey => {
    if (_.isObject(obj[tKey])) {
      obj[tKey] = cleanFonts(obj[tKey]);
    }

    delete obj[tKey]["fontFamily"];
  });

  return obj;
}

export const saveTheme = () => async dispatch => {
  await dispatch(setSavingTheme(true));

  const Editor = store.getState().get("editor").editorReducer;

  let id;
  let themeData;

  try {
    id = Editor.getIn(["currentSite", "siteId"]);

    themeData = Editor.getIn(["currentSite", "themeData"]).toJS();
    themeData = JSON.stringify(themeData);
  } catch (e) {
    console.error(e);
    return;
  }

  const query = `mutation( $id: ID!, $themeData: String ) {
      editSiteThemeData( id: $id, themeData: $themeData ) {
        id
      }
    }`;

  const variables = {
    id,
    themeData,
  };

  await graph({ query, variables });
  await dispatch(setSavingTheme(false));
};

export const fetchFonts = () => async dispatch => {
  const fonts = await getGoogleFonts();

  await dispatch(setGoogleFonts(fonts));
};

export function updateFont(type, font) {
  return async dispatch => {
    const site = store
      .getState()
      .get("editor")
      .editorReducer.get("currentSite");
    const theme = site.get("themeData");
    const fontFamilies = site.getIn(["themeData", "fontFamily"]);
    const newFontFamilies = fontFamilies.set(`${type}Font`, `'${font}', sans-serif`);

    const fonts = [
      extractFont(newFontFamilies.get("primaryFont")),
      extractFont(newFontFamilies.get("secondaryFont")),
    ];

    const newTheme = theme.set("fonts", fonts).set("fontFamily", newFontFamilies);
    let cleanTheme = cleanFonts(newTheme.toJS());
    cleanTheme = generateGlobalObject(cleanTheme);

    const newSite = site.set("themeData", cleanTheme).toJS();

    await dispatch(setSiteData(newSite)); // Editor reducer
    await dispatch(setCurrentSite(newSite)); // Auth reducer
  };
}

function extractFont(font) {
  try {
    let fonts = font.split(",");
    return fonts[0].trim().replace(/'/g, "");
  } catch (e) {
    return "";
  }
}
