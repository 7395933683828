import {StyleSheet} from "@convertly/css";
import { generatePseudoMediaQuery } from "../../../elements/style-functions/pseudo-media-queries";

//browser specific overrides
import {generateTabletPortraitStyleSheet} from '../../../utils/browserSpecificStyles';

//Style objects
import addToCartStyleObject from '../../../ecommerce-common-components/add-to-cart-stepper/add-to-cart-qty-stepper-styles';
import productImagesGalleryStyleObject from './inferno-components/components/product-image-gallery-styles';

const Styles = (global, data, inEditor) => {
	

    //Inferno component style sheets
    const addToCartStyles = addToCartStyleObject(global, data, inEditor);
    const productImagesGalleryStyles = productImagesGalleryStyleObject(global, data);
    //Browser Specific Overrides
    let hideQuickViewTablePortraitStyles = {
        display: 'none'
    };

    const hideQuickViewTablePortrait = generateTabletPortraitStyleSheet(hideQuickViewTablePortraitStyles);

    let twoColumnLayOut = {
        ...addToCartStyles,
        ...productImagesGalleryStyles,
        ".productBox": {
            height: 'auto',
            overflow: 'hidden',
            marginBottom: '20px',
        },
        ".sectionTitleContainer": {
            margin: '20px 0'
        },
        ".productBoxWrapper": {
            minHeight: 'auto',
            marginBottom: '100px',
            ":hover ": {
                ".imgOverlay": {
                    bottom: '0'
                }
            }
        },
        ".sectionTitle": {
            fontWeight: "700",
            fontSize: '30px',
            color: global.textColors.light.color3
        },
        ".imageContainer": {
            position: "relative",
            width: '100%',
            height: '300px',
            overflow: "hidden",
            margin: '0 auto',
            marginBottom: '10px',
            textAlign: 'center',
            ...generatePseudoMediaQuery(
                '@media (max-width:991px ) and ( min-width:767px)',
                ['largeTablet'],
                { height: '220px' },
                inEditor
            ),
            ...generatePseudoMediaQuery(
                '@media(max-width:767px)',
                ['smallTablet, mobile'],
                { height: 'auto' },
                inEditor
            ),
        },
        ".productImg": {
            width: 'auto',
            height: '300px',
            margin: '0 auto',
            ...generatePseudoMediaQuery(
                '@media (max-width:991px ) and ( min-width:767px)',
                ['largeTablet'],
                { height: '220px' },
                inEditor
            ),
            ...generatePseudoMediaQuery(
                '@media(max-width:767px)',
                ['smallTablet','mobile'],
                { height: 'auto' },
                inEditor
            )
        },
        ".imageOverlay": {
            position: "absolute",
            bottom: "-100%",
            transition: 'all 400ms ease',
            height: '80px',
            background: 'linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .6))',
            width: '100%',
            textAlign: 'center',
            padding: '20px',
            cursor: 'default',
            "@media (max-width:767px)": {
                display: "none"
            }
        },
        ".productButtons": {
            display: 'inline-block',
            margin: '0 10px',
            padding: "10px 15px",
            fontSize: '14px',
            color: global.textColors.light.white,
            background: global.backgroundColors.accent.color1,
            borderRadius: '20px',
            cursor: 'pointer',
            "@media(min-width:991px)": {
                ":hover": {
                    transition: 'ease 400ms',
                    boxShadow: "0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.3)"
                },
            },
            "@media(max-width:991px)": {
                ":focus": {
                    boxShadow: "0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.3)"
                }
            },
            ".disabled": {
                opacity: '.5',
                cursor: 'default'
            }
        },
        ".productDescription": {
            display: "block"
        },
        ".subTitle": {
            fontFamily: global.fontFamily.secondaryFont
        },
        ".productTitleSection": {
            display: 'block',
        },
        ".productTitle": {
            display: 'inline-block',
            marginTop: '0px',
            marginBottom: '0px',
            float: 'left',
            fontSize: "16px",
            maxWidth: '75%',
            color: global.textColors.dark.color1,
            fontFamily: global.fontFamily.primaryFont,
        },
        ".price-container": {
            float: "right",
            display: 'inline-block',
            textAlign: 'right'
        },
        ".quickBuy": {
            display: "block"
        },
        ".product-grid-container": {
            position: 'relative',
            height: 'auto',
            display: "flex",
            flexDirection: 'column',
            overflow: 'hidden'

        },
        ".page": {
            position: 'relative',
            minWidth: '100%',
            transition: 'all .2s ease',
            height: 'auto',
            ".active": {
                top: 0,
                left: 0,
                right: 0
            },
        }
    };

    let threeColumnLayOut = {
        ".productBox": {
            height: 'auto',
            overflow: 'hidden',
            marginBottom: '20px'
        },
        ".sectionTitleContainer": {
            margin: '20px 0'
        },
        ".productBoxWrapper": {
            ":hover ": {
                ".imgOverlay": {
                    bottom: '0'

                }
            }
        },
        ".sectionTitle": {
            fontWeight: "700",
            fontSize: '30px',
            color: global.textColors.light.color3
        },
        ".imageContainer": {
            position: "relative",
            width: '100%',
            height: '300px',
            overflow: 'hidden',
            margin: '0 auto',
            marginBottom: '10px',
            textAlign: 'center'
        },
        ".productImg": {
            width: 'auto',
            height: '300px',
            margin: '0 auto',
        },
        ".imageOverlay": {
            position: "absolute",
            bottom: '-100%',
            height: '80px',
            transition: 'all 400ms ease',
            background: 'linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .6))',
            width: '100%',
            textAlign: 'center',
            padding: '20px',
            cursor: 'default'
        },
        ".productButtons": {
            display: 'inline-block',
            margin: '0 10px',
            padding: "10px 15px",
            fontSize: '14px',
            cursor: 'pointer',
            color: global.textColors.light.white,
            background: global.backgroundColors.accent.color1,
            borderRadius: '20px'
        },
        ".productDescription": {
            display: "block"
        },
        ".subTitle": {
            fontFamily: global.fontFamily.secondaryFont,
            color: global.textColors.dark.color1
        },
        ".productTitleSection": {
            display: 'block',
        },

        ".productTitle": {
            display: 'inline-block',
            marginTop: '0px',
            marginBottom: '0px',
            float: 'left',
            fontSize: "16px",
            maxWidth: '75%',
            color: global.textColors.dark.color1,
            fontFamily: global.fontFamily.secondaryFont
        },
        ".salePrice": {
            display: "inline-block",
            marginTop: "0px",
            marginBottom: "0px",
            float: "right",
            fontSize: "16px",
            fontFamily: global.fontFamily.secondaryFont
        },
        ".quickBuy": {
            display: "block"
        },
        ".product-grid-container": {
            position: 'relative',
            height: 'auto',
            display: "flex",
            flexDirection: 'column',
            overflow: 'hidden'

        },
        ".page": {
            position: 'relative',
            minWidth: '100%',
            transition: 'all .2s ease',
            height: 'auto',
            ".active": {
                top: 0,
                left: 0,
                right: 0
            },
        }
    };

    let fourColumnLayOut = {
        ".productBox": {
            height: 'auto',
            overflow: 'hidden',
            marginBottom: '20px'
        },
        ".sectionTitleContainer": {
            margin: '20px 0'
        },
        ".productBoxWrapper": {
            ":hover ": {
                ".imgOverlay": {
                    bottom: '0'
                }
            }
        },
        ".sectionTitle": {
            fontWeight: "700",
            fontSize: '30px',
            color: global.textColors.light.color3
        },
        ".imageContainer": {
            position: "relative",
            width: '100%',
            height: '300px',
            overflow: "hidden",
            margin: '0 auto',
            marginBottom: '10px',
            textAlign: 'center'

        },
        ".productImg": {
            width: 'auto',
            height: '300px',
            margin: '0 auto',
        },
        ".imageOverlay": {

            position: "absolute",
            bottom: '-100%',
            transition: 'all 1s ease',
            height: '80px',
            background: 'linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .6))',
            width: '100%',
            textAlign: 'center',
            padding: '20px',
            cursor: 'default'
        },
        ".productButtons": {
            display: 'inline-block',
            margin: '0 3px',
            padding: "10px 15px",
            fontSize: '14px',
            cursor: 'pointer',
            color: global.textColors.light.white,
            background: global.backgroundColors.accent.color1,
            borderRadius: '20px'
        },
        ".productDescription": {
            display: "block"
        },
        ".subTitle": {
            fontFamily: global.fontFamily.secondaryFont
        },
        ".productTitleSection": {
            display: 'block',
        },

        ".productTitle": {
            display: 'inline-block',
            marginTop: '0px',
            marginBottom: '0px',
            float: 'left',
            fontSize: "16px",
            maxWidth: '75%',
            color: global.textColors.dark.color1,
            fontFamily: global.fontFamily.secondaryFont
        },
        ".salePrice": {
            display: "inline-block",
            marginTop: "0px",
            marginBottom: "0px",
            float: "right",
            fontSize: "16px",
            fontFamily: global.fontFamily.secondaryFont
        },
        ".quickBuy": {
            display: "block"
        },
        ".product-grid-container": {
            position: 'relative',
            height: 'auto',
            display: "flex",
            flexDirection: 'column',
            overflow: 'hidden'

        },
        ".page": {
            position: 'relative',
            minWidth: '100%',
            transition: 'all .2s ease',
            height: 'auto',
            ".active": {
                top: 0,
                left: 0,
                right: 0
            },
        }
    };

    let sixColumnLayOut = {
        ".productBox": {
            height: 'auto',
            overflow: 'hidden',
            marginBottom: '20px'
        },
        ".sectionTitleContainer": {
            margin: '20px 0'
        },
        ".productBoxWrapper": {},
        ".sectionTitle": {
            fontWeight: "700",
            fontSize: '30px',
            color: global.textColors.light.color3
        },
        ".imageContainer": {
            position: "relative",
            width: '100%',
            height: '225px',
            margin: '0 auto',
            marginBottom: '10px',
            textAlign: 'center',
        },
        ".productImg": {
            width: 'auto',
            height: '225px',
            margin: '0 auto',
        },
        ".imageOverlay": {
            display: 'none',
        },
        ".productButtons": {
            display: 'inline-block',
            margin: '0 10px',
            padding: "10px 15px",
            fontSize: '14px',
            cursor: 'pointer',
            color: global.textColors.light.white,
            background: global.backgroundColors.accent.color1,
            borderRadius: '20px'
        },
        ".productDescription": {
            display: "block"
        },
        ".subTitle": {
            fontFamily: global.fontFamily.secondaryFont
        },
        ".productTitleSection": {
            display: 'block',
        },

        ".productTitle": {
            display: 'inline-block',
            marginTop: '0px',
            marginBottom: '0px',
            float: 'left',
            fontSize: "14px",
            maxWidth: '100%',
            color: global.textColors.dark.color1,
            fontFamily: global.fontFamily.primaryFont
        },
        ".salePrice": {
            display: "inline-block",
            marginTop: "0px",
            marginBottom: "0px",
            float: "right",
            fontSize: "14px",
            fontFamily: global.fontFamily.secondaryFont
        },
        ".quickBuy": {
            display: "block"
        },
        ".product-grid-container": {
            position: 'relative',
            height: 'auto',
            display: "flex",
            flexDirection: 'column',
            overflow: 'hidden'
        },
        ".page": {
            position: 'relative',
            minWidth: '100%',
            transition: 'all .2s ease',
            height: 'auto',
            ".active": {
                top: 0,
                left: 0,
                right: 0
            },
        },

    };


    let twoColumnLayOutReact = {
        ...addToCartStyles,
        ...productImagesGalleryStyles,
        productBox: {
            height: 'auto',
            overflow: 'hidden',
            marginBottom: '20px',
        },
        sectionTitleContainer: {
            margin: '20px 0'
        },
        productBoxWrapper: {
            minHeight: 'auto',
            marginBottom: '100px',
            ":hover ": {
                ".imgOverlay": {
                    bottom: '0'
                }
            }
        },
        sectionTitle: {
            fontWeight: "700",
            fontSize: '30px',
            color: global.textColors.light.color3
        },
        imageContainer: {
            position: "relative",
            width: '100%',
            height: '300px',
            overflow: "hidden",
            margin: '0 auto',
            marginBottom: '10px',
            textAlign: 'center',
            ...generatePseudoMediaQuery(
                '@media (max-width:991px ) and ( min-width:767px)',
                ['largeTablet'],
                { height: '220px' },
                inEditor
            ),
            ...generatePseudoMediaQuery(
                '@media(max-width:767px)',
                ['smallTablet', 'mobile'],
                { height: 'auto' },
                inEditor
            )
        },
        productImg: {
            width: 'auto',
            height: '300px',
            margin: '0 auto',
            ...generatePseudoMediaQuery(
                '@media (max-width:991px ) and ( min-width:767px)',
                ['largeTablet'],
                { height: '220px' },
                inEditor
            ),
            ...generatePseudoMediaQuery(
                '@media(max-width:767px)',
                ['smallTablet', 'mobile'],
                { height: 'auto' },
                inEditor
            ),
        },
        imageOverlay: {
            position: "absolute",
            bottom: "-100%",
            transition: 'all 400ms ease',
            height: '80px',
            background: 'linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .6))',
            width: '100%',
            textAlign: 'center',
            padding: '20px',
            cursor: 'default',
            ...generatePseudoMediaQuery(
                '@media(max-width:767px)',
                ['smallTablet', 'mobile'],
                { display: 'none' },
                inEditor
            ),
        },
        productButtons: {
            display: 'inline-block',
            margin: '0 10px',
            padding: "10px 15px",
            fontSize: '14px',
            color: global.textColors.light.white,
            background: global.backgroundColors.accent.color1,
            borderRadius: '20px',
            cursor: 'pointer',
            "@media(min-width:991px)": {
                ":hover": {
                    transition: 'ease 400ms',
                    boxShadow: "0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.3)"
                },
            },
            "@media(max-width:991px)": {
                ":focus": {
                    boxShadow: "0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.3)"
                }
            },
            ".disabled": {
                opacity: '.5',
                cursor: 'default'
            }
        },
        productDescription: {
            display: "block"
        },
        subTitle: {
            fontFamily: global.fontFamily.secondaryFont
        },
        productTitleSection: {
            display: 'block',
        },
        productTitle: {
            display: 'inline-block',
            marginTop: '0px',
            marginBottom: '0px',
            float: 'left',
            fontSize: "16px",
            maxWidth: '75%',
            color: global.textColors.dark.color1,
            fontFamily: global.fontFamily.primaryFont,
        },
        priceContainer: {
            float: "right",
            display: 'inline-block',
            textAlign: 'right'
        },
        quickBuy: {
            display: "block"
        },
        productGridContainer: {
            position: 'relative',
            height: 'auto',
            display: "flex",
            flexDirection: 'column',
            overflow: 'hidden'

        },
        page: {
            position: 'relative',
            minWidth: '100%',
            transition: 'all .2s ease',
            height: 'auto',
            ".active": {
                top: 0,
                left: 0,
                right: 0
            },
        }
    };

    let threeColumnLayOutReact = {
        productBox: {
            height: 'auto',
            overflow: 'hidden',
            marginBottom: '20px'
        },
        sectionTitleContainer: {
            margin: '20px 0'
        },
        productBoxWrapper: {
            ":hover ": {
                ".imgOverlay": {
                    bottom: '0'

                }
            }
        },
        sectionTitle: {
            fontWeight: "700",
            fontSize: '30px',
            color: global.textColors.light.color3
        },
        imageContainer: {
            position: "relative",
            width: '100%',
            height: '300px',
            overflow: 'hidden',
            margin: '0 auto',
            marginBottom: '10px',
            textAlign: 'center'
        },
        productImg: {
            width: 'auto',
            height: '300px',
            margin: '0 auto',
        },
        imageOverlay: {
            position: "absolute",
            bottom: '-100%',
            height: '80px',
            transition: 'all 400ms ease',
            background: 'linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .6))',
            width: '100%',
            textAlign: 'center',
            padding: '20px',
            cursor: 'default'
        },
        productButtons: {
            display: 'inline-block',
            margin: '0 10px',
            padding: "10px 15px",
            fontSize: '14px',
            cursor: 'pointer',
            color: global.textColors.light.white,
            background: global.backgroundColors.accent.color1,
            borderRadius: '20px'
        },
        productDescription: {
            display: "block"
        },
        subTitle: {
            fontFamily: global.fontFamily.secondaryFont,
            color: global.textColors.dark.color1
        },
        productTitleSection: {
            display: 'block',
        },

        productTitle: {
            display: 'inline-block',
            marginTop: '0px',
            marginBottom: '0px',
            float: 'left',
            fontSize: "16px",
            maxWidth: '75%',
            color: global.textColors.dark.color1,
            fontFamily: global.fontFamily.secondaryFont
        },
        salePrice: {
            display: "inline-block",
            marginTop: "0px",
            marginBottom: "0px",
            float: "right",
            fontSize: "16px",
            fontFamily: global.fontFamily.secondaryFont
        },
        quickBuy: {
            display: "block"
        },
        productGridContainer: {
            position: 'relative',
            height: 'auto',
            display: "flex",
            flexDirection: 'column',
            overflow: 'hidden'

        },
        page: {
            position: 'relative',
            minWidth: '100%',
            transition: 'all .2s ease',
            height: 'auto',
            ".active": {
                top: 0,
                left: 0,
                right: 0
            },
        }
    };

    let fourColumnLayOutReact = {
        productBox: {
            height: 'auto',
            overflow: 'hidden',
            marginBottom: '20px'
        },
        sectionTitleContainer: {
            margin: '20px 0'
        },
        productBoxWrapper: {
            ":hover ": {
                ".imgOverlay": {
                    bottom: '0'
                }
            }
        },
        sectionTitle: {
            fontWeight: "700",
            fontSize: '30px',
            color: global.textColors.light.color3
        },
        imageContainer: {
            position: "relative",
            width: '100%',
            height: '300px',
            overflow: "hidden",
            margin: '0 auto',
            marginBottom: '10px',
            textAlign: 'center'

        },
        productImg: {
            width: 'auto',
            height: '300px',
            margin: '0 auto',
        },
        imageOverlay: {

            position: "absolute",
            bottom: '-100%',
            transition: 'all 1s ease',
            height: '80px',
            background: 'linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .6))',
            width: '100%',
            textAlign: 'center',
            padding: '20px',
            cursor: 'default'
        },
        productButtons: {
            display: 'inline-block',
            margin: '0 3px',
            padding: "10px 15px",
            fontSize: '14px',
            cursor: 'pointer',
            color: global.textColors.light.white,
            background: global.backgroundColors.accent.color1,
            borderRadius: '20px'
        },
        productDescription: {
            display: "block"
        },
        subTitle: {
            fontFamily: global.fontFamily.secondaryFont
        },
        productTitleSection: {
            display: 'block',
        },

        productTitle: {
            display: 'inline-block',
            marginTop: '0px',
            marginBottom: '0px',
            float: 'left',
            fontSize: "16px",
            maxWidth: '75%',
            color: global.textColors.dark.color1,
            fontFamily: global.fontFamily.secondaryFont
        },
        salePrice: {
            display: "inline-block",
            marginTop: "0px",
            marginBottom: "0px",
            float: "right",
            fontSize: "16px",
            fontFamily: global.fontFamily.secondaryFont
        },
        quickBuy: {
            display: "block"
        },
        productGridContainer: {
            position: 'relative',
            height: 'auto',
            display: "flex",
            flexDirection: 'column',
            overflow: 'hidden'

        },
        page: {
            position: 'relative',
            minWidth: '100%',
            transition: 'all .2s ease',
            height: 'auto',
            ".active": {
                top: 0,
                left: 0,
                right: 0
            },
        }
    };

    let sixColumnLayOutReact = {
        productBox: {
            height: 'auto',
            overflow: 'hidden',
            marginBottom: '20px'
        },
        sectionTitleContainer: {
            margin: '20px 0'
        },
        productBoxWrapper: {},
        sectionTitle: {
            fontWeight: "700",
            fontSize: '30px',
            color: global.textColors.light.color3
        },
        imageContainer: {
            position: "relative",
            width: '100%',
            height: '225px',
            margin: '0 auto',
            marginBottom: '10px',
            textAlign: 'center',
        },
        productImg: {
            width: 'auto',
            height: '225px',
            margin: '0 auto',
        },
        imageOverlay: {
            display: 'none',
        },
        productButtons: {
            display: 'inline-block',
            margin: '0 10px',
            padding: "10px 15px",
            fontSize: '14px',
            cursor: 'pointer',
            color: global.textColors.light.white,
            background: global.backgroundColors.accent.color1,
            borderRadius: '20px'
        },
        productDescription: {
            display: "block"
        },
        subTitle: {
            fontFamily: global.fontFamily.secondaryFont
        },
        productTitleSection: {
            display: 'block',
        },

        productTitle: {
            display: 'inline-block',
            marginTop: '0px',
            marginBottom: '0px',
            float: 'left',
            fontSize: "14px",
            maxWidth: '100%',
            color: global.textColors.dark.color1,
            fontFamily: global.fontFamily.primaryFont
        },
        salePrice: {
            display: "inline-block",
            marginTop: "0px",
            marginBottom: "0px",
            float: "right",
            fontSize: "14px",
            fontFamily: global.fontFamily.secondaryFont
        },
        quickBuy: {
            display: "block"
        },
        productGridContainer: {
            position: 'relative',
            height: 'auto',
            display: "flex",
            flexDirection: 'column',
            overflow: 'hidden'
        },
        page: {
            position: 'relative',
            minWidth: '100%',
            transition: 'all .2s ease',
            height: 'auto',
            ".active": {
                top: 0,
                left: 0,
                right: 0
            },
        },

    };

    let currentLayout;
    let currentLayoutReact;

    if (parseInt(data.columns) === 6) {
        currentLayout = sixColumnLayOut;
        currentLayoutReact = sixColumnLayOutReact;
    }
    else if (parseInt(data.columns) === 4) {
        currentLayout = fourColumnLayOut;
        currentLayoutReact = fourColumnLayOutReact;
    }
    else if (parseInt(data.columns) === 3) {
        currentLayout = threeColumnLayOut;
        currentLayoutReact = threeColumnLayOutReact;
    }
    else {
        currentLayout = twoColumnLayOut;
        currentLayoutReact = twoColumnLayOutReact;
    }

    /** TESTING STUFF REMOVE **/
    const moveLeft = {
        'from': {
            left: '-1400px',
        },

        'to': {
            left: '500px',
        }
    };
    const moveRight = {
        'from': {
            right: '-1400px',
        },

        'to': {
            right: '500px',
        }
    };
    const moveUp = {
        'from': {
            bottom: '-5400px',
        },

        'to': {
            bottom: '0px',
        }
    };

    return StyleSheet.create({
            productGalleryContainer: {
                fontFamily: global.fontFamily.primaryFont,
                color: global.textColors.dark.color3,
                paddingTop: '20px'
            },
            productGridInferno: {
                ...addToCartStyles,
                ...productImagesGalleryStyles,
                ...currentLayout,
                ".img-1": {
                    display:'none',
                    position:'fixed',
                    top:'50%',
                    zIndex:'9999',
                    height:'160px',
                    left:'500px',
                    animationName: [moveLeft],
                    animationDuration: '3s, 1200ms',
                    animationIterationCount: '1',
                },
                ".img-2": {
                    display:'none',
                    position:'fixed',
                    top:'50%',
                    right:'500px',
                    zIndex:'9999',
                    height:'160px',
                    animationName: [moveRight],
                    animationDuration: '3s, 1200ms',
                    animationIterationCount: '1',
                },
                ".img-3": {
                    position:'fixed',
                    display:'none',
                    bottom:'0px',
                    right:'500px',
                    zIndex:'9999',
                    animationName: [moveUp],
                    animationDuration: '5s, 1200ms',
                    animationIterationCount: '1',
                },
                ".widget-1-container": {
                    display:'block',
                    clear:'both'
                },
                ".color-picker-container": {
                    height:'28px',
                    width:'28px',
                    padding:'4px',
                    borderRadius:'100%',
                    display:'inline-block',
                    border:`3px solid transparent`,
                    cursor:'pointer',
                    marginRight:'5px',
                    clear:'both',
                    transition:'border 500ms ease',
                    ".active": {
                        border:`0px solid ${global.colors.dark.color1}`
                    },
                    ":hover": {
                        border:`3px solid ${global.colors.dark.color1}`
                    }
                },
                ".color-picker-value": {
                    height:'100%',
                    width:'100%',
                    borderRadius:'100%'
                },
                ".salePrice": {
                    fontSize: '16px',
                    color: global.textColors.accent.color1
                },
                ".price": {
                    display: "block",
                    marginTop: "0px",
                    marginBottom: "0px",
                    fontSize: "16px",
                    fontFamily: global.fontFamily.secondaryFont,
                    color: global.textColors.dark.color2,
                    ".onsale": {
                        fontSize: '12px',
                        textDecoration: "line-through"
                    }
                },
                ".page-inactive-right": {
                    height: '0',
                    left: '200%'
                },
                ".page-inactive-left": {
                    height: '0',
                    left: '-150%'
                },
                ".paginationIndexContainer": {
                    width: '100%',
                    textAlign: 'center'
                },
                ".paginationIndex": {
                    display: 'inline-block',
                    fontFamily: global.fontFamily.secondaryFont,
                    color: global.textColors.dark.color3,
                    fontSize: '18px',
                    width: "15px",
                    height: '20px',
                    cursor: "pointer",
                    ":hover": {
                        fontWeight: '700',
                        color: global.textColors.dark.color1,
                    },
                    ".active": {
                        fontWeight: '700',
                        color: global.textColors.dark.color1,
                    }
                },
                ".load-more-button-container": {
                    textAlign: 'center',
                    "marginBottom": "30px"
                },
                ".load-more-button": {
                    cursor: 'pointer',
                    margin: '0 auto',
                    ...global.button1
                },
                /** Filtration section **/
                ".filtrationBar": {
                    width: '100%',
                    height: '40px',
                    background: global.backgroundColors.dark.color2
                },
                ".categorySortContainer": {
                    width: '200px',
                    height: '100%',
                    position: 'relative'
                },
                ".filterOptions": {
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    background: '#fff'
                },

                /** Quick View modal **/
                ".quickViewSVGContainer": {
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    padding: '4px 8px 0',
                    background: 'rgba(0, 0, 0, .5)',
                    transition: 'all .5s ease',
                    ":hover ": {
                        background: '#fff',
                        ".quickViewSVG": {
                            fill: global.backgroundColors.dark.color1
                        }
                    },
                    "@media (max-width:767px)": {
                        display: 'none'
                    },
                    ...hideQuickViewTablePortrait,
                    display: data.columns == 6 ? "none" : "block"
                },
                ".quickViewSVG": {
                    height: "25px",
                    width: "25px",
                    fill: global.backgroundColors.light.white,
                    transition: 'all .5s ease'
                },
                ".quick-view-modal-container": {
                    top: 0,
                    left: 0,
                    position: 'fixed',
                    zIndex: '20',
                    width: '100vw',
                    height: '100vh',
                    background: 'rgba(0,0,0,.5)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                },
                ".quick-view-modal": {
                    position: "relative",
                    width: '90vw',
                    maxWidth: "1140px",
                    height: 'auto',
                    background: '#fff',
                    border: global.border,
                    padding: '30px'
                },
                ".quick-view-modal-content-container": {
                    width: "50%",
                    padding: "10px",
                    display: 'inline-block',
                    verticalAlign: 'top',
                    "@media ( max-width:767px )": {
                        width: '100%',
                        height: '50%'
                    }
                },
                '.closeButton': {
                    position: 'absolute',
                    padding: '10px',
                    top: '-15px',
                    right: '-15px',
                    height: '45px',
                    width: '45px',
                    border: global.border,
                    borderRadius: '100%',
                    background: global.backgroundColors.light.color1,
                    cursor: 'pointer',
                    zIndex: '2',
                },
                '.closeIcon': {
                    height: '20px',
                    width: '20px'
                },
                ".producttitle": {},

                /** styles copied from product listing **/



                ".sharebtn_wButton": {
                    backgroundColor: global.backgroundColors.light.color2,
                    backgroundImage: "url('../images/chevright2.png')",
                    backgroundPosition: "100% 50%",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "auto",
                    border: "1px solid #667785",
                    borderRadius: "50px",
                    color: global.textColors.dark.color2,
                    cursor: "pointer",
                    display: "inline-block",
                    float: "right",
                    fontSize: "13px",
                    lineHeight: "inherit",
                    padding: "5px 25px 5px 20px",
                    textDecoration: "none"
                },

                ".productrow_wRow": {
                    backgroundColor: "transparent",
                    color: global.textColors.dark.black,
                    fontSize: "14px",
                    lineHeight: "20px",
                    marginLeft: "0",
                    marginRight: "0",
                    marginTop: "0px"
                },

                ".column_2_wCol_wCol_6": {
                    color: global.textColors.dark.black,
                    float: "left",
                    fontSize: "14px",
                    left: "auto",
                    lineHeight: "20px",
                    minHeight: "1px",
                    paddingLeft: "0px",
                    paddingRight: "10px",
                    position: "relative",
                    right: "auto",
                    width: "100%"
                },


                ".column_wCol_wCol_6": {
                    color: global.textColors.dark.black,
                    float: "left",
                    fontSize: "14px",
                    left: "auto",
                    lineHeight: "20px",
                    minHeight: "1px",
                    paddingLeft: "10px",
                    paddingRight: "0px",
                    position: "relative",
                    right: "auto",
                    width: "100%"
                },

                ".textcontainer": {
                    color: global.textColors.dark.black,
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "14px",
                    justifyContent: "center",
                    lineHeight: "20px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    maxWidth: "95%",
                    position: "relative",
                    textAlign: "left",
                    width: "700px"
                },

                ".titleholder": {
                    color: global.textColors.dark.black,
                    fontSize: "14px",
                    lineHeight: "20px",
                    textAlign: "left",
                    fontFamily: global.fontFamily.secondaryFont
                },

                ".titlecontainer": {
                    color: global.textColors.dark.black,
                    display: "flex",
                    fontSize: "14px",
                    justifyContent: "space-between",
                    lineHeight: "20px",
                    position: "relative",
                    textAlign: "left",
                    display: 'inline-block',
                },

                '.quick-view-header-close-detail-wrapper': {
                  display: 'inline-block'
                },

                '.view-more-detail-wrapper': {
                  display: 'inline-block',
                },

                ".producttitle": {
                    color: global.textColors.dark.color1,
                    fontSize: "25px",
                    lineHeight: "44px",
                    margin: "0.67em 0",
                    marginBottom: "5px",
                    marginTop: "0px",
                    textAlign: "left",
                    maxWidth: '80%',
                    fontFamily: global.fontFamily.primaryFont
                },

                ".price_producttitle": {
                    color: global.textColors.dark.color2,
                    fontSize: "25px",
                    lineHeight: "44px",
                    margin: "0.67em 0",
                    marginBottom: "5px",
                    marginTop: "0px",
                    textAlign: "left"
                },

                ".subtitle": {
                    color: global.textColors.dark.color3,
                    fontSize: "13px",
                    lineHeight: "20px",
                    marginBottom: "15px",
                    textAlign: "left"
                },

                ".description": {
                    color: global.textColors.dark.color1,
                    fontSize: "12px",
                    lineHeight: "22px",
                    marginBottom: "10px",
                    marginTop: "0",
                    textAlign: "left"
                },
                ".div-block_2divider": {
                    width: "100%",
                    height: "1px",
                    background: global.backgroundColors.light.color2,
                    marginTop: "10px",
                },

                ".optionscontainer": {
                    borderBottom: "1px solid #253039",
                    color: global.textColors.dark.black,
                    display: "flex",
                    fontSize: "14px",
                    lineHeight: "20px",
                    paddingBottom: "8px",
                    textAlign: "left"
                },

                ".divBlock_section1": {
                    color: global.textColors.dark.black,
                    flex: "1",
                    fontSize: "14px",
                    lineHeight: "20px",
                    marginBottom: "15px",
                    maxWidth: "auto",
                    minWidth: "30%",
                    textAlign: "left"
                },

                ".label_subtitle": {
                    color: global.textColors.dark.color3,

                    fontSize: "11px",
                    lineHeight: "20px",
                    marginBottom: "7px",
                    textAlign: "left"
                },

                ".optionbtn": {
                    backgroundColor: global.backgroundColors.light.color2,
                    border: "2px solid #667785",
                    color: global.textColors.dark.black,
                    display: "inline-block",
                    fontSize: "11px",
                    height: "30px",
                    lineHeight: "13px",
                    marginRight: "5px",
                    padding: "6px 8px 0px",
                    textAlign: "center",
                    textDecoration: "none",
                    textTransform: "uppercase"
                },

                ".optionbtn_selected": {
                    backgroundColor: "#b4bbc3",
                    border: "2px solid #667785",
                    color: global.textColors.light.white,
                    display: "inline-block",
                    fontSize: "11px",
                    height: "30px",
                    lineHeight: "13px",
                    marginRight: "5px",
                    padding: "6px 8px 0px",
                    paddingBottom: "0px",
                    textAlign: "center",
                    textDecoration: "none",
                    textTransform: "uppercase"
                },

                ".divBlock_2": {
                    color: global.textColors.dark.black,
                    display: "flex",
                    flex: "1",
                    fontSize: "14px",
                    lineHeight: "20px",
                    textAlign: "left"
                },

                ".divBlock_var2": {
                    color: global.textColors.dark.black,
                    flex: "1",
                    fontSize: "14px",
                    lineHeight: "20px",
                    marginBottom: "15px",
                    maxWidth: "auto",
                    textAlign: "left"
                },

                ".variant1container": {
                    color: global.textColors.dark.black,
                    display: "flex",
                    fontSize: "14px",
                    lineHeight: "20px",
                    textAlign: "left"
                },

                ".selected_selector": {
                    backgroundColor: "#0098ff",
                    border: "4px solid #f5f5f5",
                    borderRadius: "50%",
                    boxShadow: "0 0 0 3px #667785",
                    color: global.textColors.dark.black,
                    fontSize: "14px",
                    height: "29px",
                    lineHeight: "20px",
                    marginLeft: "3px",
                    marginRight: "3px",
                    textAlign: "left",
                    width: "29px"
                },

                ".selector": {
                    backgroundColor: "#0098ff",
                    border: "4px solid #f5f5f5",
                    borderRadius: "50%",
                    boxShadow: "0 0 0 3px #f5f5f5",
                    color: global.textColors.dark.black,
                    fontSize: "14px",
                    height: "29px",
                    lineHeight: "20px",
                    marginLeft: "3px",
                    marginRight: "3px",
                    textAlign: "left",
                    width: "29px"
                },

                ".centerize_label_subtitle": {
                    color: global.textColors.dark.color3,
                    fontSize: "11px",
                    lineHeight: "20px",
                    marginBottom: "7px",
                    textAlign: "center"
                },


                ".subscriptioncontainer": {
                    alignItems: "center",
                    color: global.textColors.dark.black,
                    display: "flex",
                    fontSize: "14px",
                    justifyContent: "center",
                    lineHeight: "20px",
                    marginTop: "22px",
                    textAlign: "left"
                },

                ".freqselector_wButton": {
                    backgroundColor: "transparent",
                    border: "2px solid #828588",
                    borderRadius: "0",
                    color: global.textColors.dark.color3,
                    cursor: "pointer",
                    display: "inline-block",
                    fontFamily: "'Libre Baskerville', sans-serif",
                    fontSize: "10px",
                    lineHeight: "14px",
                    marginLeft: "0px",
                    marginRight: "5px",
                    padding: "9px 15px",
                    textAlign: "center",
                    textDecoration: "none"
                },

                ".freqselector_selected_wButton": {
                    backgroundColor: global.backgroundColors.light.color2,
                    border: "2px solid #828588",
                    borderColor: global.backgroundColors.dark.color1,
                    borderRadius: "0",
                    color: global.textColors.dark.color1,
                    cursor: "pointer",
                    display: "inline-block",
                    fontFamily: "'Libre Baskerville', sans-serif",
                    fontSize: "10px",
                    lineHeight: "14px",
                    marginLeft: "0px",
                    marginRight: "5px",
                    padding: "9px 15px",
                    textAlign: "center",
                    textDecoration: "none"
                },

                ".frequencycontainer": {
                    alignItems: "center",
                    color: global.textColors.dark.black,
                    display: "flex",
                    flex: "0 auto",
                    fontSize: "14px",
                    justifyContent: "flex-end",
                    lineHeight: "20px",
                    position: "relative",
                    textAlign: "left"
                },

                ".first_toggletext": {
                    color: global.textColors.dark.color1,
                    flex: "0 auto",

                    fontSize: "14px",
                    lineHeight: "20px",
                    paddingLeft: "10px",
                    textAlign: "right"
                },

                ".wDropdown": {
                    color: global.textColors.dark.black,
                    display: "inline-block",
                    fontSize: "14px",
                    lineHeight: "20px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    position: "relative",
                    textAlign: "left",
                    zIndex: "900"
                },

                ".toggle_wDropdownToggle": {
                    color: "#222222",
                    cursor: "pointer",
                    display: "inline-block",
                    float: "none",
                    fontSize: "14px",
                    lineHeight: "20px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    padding: "10px 17px 10px 0px",
                    position: "relative",
                    textAlign: "left",
                    textDecoration: "none",
                    userSelect: "none",
                    verticalAlign: "top",
                    whiteSpace: "nowrap"
                },

                ".icon_wIconDropdownToggle": {
                    bottom: "0",
                    color: global.textColors.dark.color1,
                    cursor: "pointer",
                    fontFamily: "'webflow-icons'",
                    fontSize: "14px",
                    fontVariant: "normal",
                    height: "1em",
                    lineHeight: "1",
                    margin: "auto",
                    marginRight: "0px",
                    position: "absolute",
                    right: "0",
                    speak: "none",
                    textAlign: "left",
                    textTransform: "none",
                    top: "0",
                    whiteSpace: "nowrap",
                    width: "1em"
                },

                ".drop_toggletext": {
                    color: global.textColors.dark.color1,
                    cursor: "pointer",
                    flex: "0 auto",

                    fontSize: "14px",
                    lineHeight: "20px",
                    paddingLeft: "10px",
                    textAlign: "right",
                    whiteSpace: "nowrap"
                },

                ".dropdownList_wDropdownList": {
                    background: "#dddddd",
                    color: global.textColors.dark.black,
                    display: "none",
                    fontSize: "14px",
                    lineHeight: "20px",
                    minWidth: "100%",
                    position: "absolute",
                    textAlign: "left"
                },

                ".dropdownlink_wDropdownLink": {
                    backgroundColor: global.backgroundColors.light.white,
                    color: global.textColors.dark.color2,
                    display: "block",
                    fontSize: "12px",
                    lineHeight: "20px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    minWidth: "60px",
                    padding: "10px 20px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    position: "relative",
                    textAlign: "center",
                    textDecoration: "none",
                    verticalAlign: "top",
                    whiteSpace: "nowrap"
                },

                ".dropdown_wDropdown": {
                    color: global.textColors.dark.black,
                    display: "inline-block",
                    flex: "0 auto",
                    fontSize: "14px",
                    lineHeight: "20px",
                    marginLeft: "auto",
                    marginRight: "0px",
                    position: "relative",
                    textAlign: "left",
                    zIndex: "900"
                },

                ".actioncontainer": {
                    alignItems: "center",
                    color: global.textColors.dark.black,
                    display: "flex",
                    flexDirection: "column",
                    fontSize: "14px",
                    justifyContent: "space-between",
                    lineHeight: "20px",
                    marginTop: "25px",
                    textAlign: "left"
                },
                ".addToCartInfernoComponent": {
                    ".actionbtn_wButton": {
                        backgroundColor: global.backgroundColors.dark.color1,
                        border: "0",
                        borderRadius: "0",
                        color: "white",
                        cursor: "pointer",
                        display: "inline-block",
                        fontSize: "17px",
                        lineHeight: "inherit",
                        marginBottom: "15px",
                        maxWidth: "95%",
                        minWidth: "300px",
                        padding: "9px 15px",
                        paddingBottom: "12px",
                        paddingTop: "12px",
                        textAlign: "center",
                        textDecoration: "none"
                    },
                },

                ".actionlabel": {
                    color: global.textColors.dark.color3,
                    fontSize: "11px",
                    lineHeight: "20px",
                    marginBottom: "5px",
                    textAlign: "left"
                },

                ".paymentlink_wInlineBlock": {
                    backgroundColor: "transparent",
                    color: global.textColors.dark.black,
                    display: "inline-block",
                    fontSize: "14px",
                    lineHeight: "20px",
                    marginLeft: "5px",
                    marginRight: "5px",
                    maxWidth: "100%",
                    opacity: "0.3",
                    textAlign: "left",
                    transition: "opacity 400ms ease"
                },
                ".paymenticon": {
                    border: "0",
                    color: global.textColors.dark.black,
                    display: "inline-block",
                    fontSize: "14px",
                    height: "36px",
                    lineHeight: "20px",
                    maxWidth: "100%",
                    textAlign: "left",
                    verticalAlign: "middle",
                    width: "36px"
                },

                ".descontainer": {
                    color: global.textColors.dark.black,
                    fontSize: "14px",
                    lineHeight: "20px",
                    margin: "75px auto 0px",
                    maxWidth: "95%",
                    paddingBottom: "25px",
                    textAlign: "center",
                    width: "700px"
                },

                ".destitle": {
                    color: global.textColors.dark.black,
                    fontFamily: global.fontFamily.secondaryFont,
                    fontSize: "18px",
                    lineHeight: "28px",
                    margin: "0.67em 0",
                    marginBottom: "10px",
                    marginTop: "20px",
                    textAlign: "center"
                },

                ".bodytext": {
                    color: global.textColors.dark.color2,
                    fontSize: "14px",
                    lineHeight: "24px",
                    marginBottom: "10px",
                    marginTop: "0",
                    textAlign: "center"
                }


            },
            ...currentLayoutReact,

        salePrice:  {
            fontSize: '16px',
            color: global.textColors.accent.color1
        },
        price: {
            display: "block",
            marginTop: "0px",
            marginBottom: "0px",
            fontSize: "16px",
            fontFamily: global.fontFamily.secondaryFont,
            color: global.textColors.dark.color2,
            ".onsale": {
                fontSize: '12px',
                textDecoration: "line-through"
            }
        },
        pageInactiveRight: {
            height: '0',
            left: '200%'
        },
        pageInactiveLeft: {
            height: '0',
            left: '-150%'
        },
        paginationIndexContainer: {
            width: '100%',
            textAlign: 'center'
        },
        paginationIndex: {
            display: 'inline-block',
            fontFamily: global.fontFamily.secondaryFont,
            color: global.textColors.dark.color3,
            fontSize: '18px',
            width: "15px",
            height: '20px',
            cursor: "pointer",
            ":hover": {
                fontWeight: '700',
                color: global.textColors.dark.color1,
            },
            ".active": {
                fontWeight: '700',
                color: global.textColors.dark.color1,
            }
        },
        }
    );

};

export default Styles;
