import { StyleSheet } from "@convertly/css";

const Typography = ( global, element ) => {


    const typographyStyles = {};

    if( element.color ) {
        typographyStyles.color = element.color;
    }
    if( element.fontSize ) {
        typographyStyles.fontSize = element.fontSize;
    }
    if( element.fontFamily ) {
        typographyStyles.fontFamily = element.fontFamily;
    }
    if( element.textAlign ) {
        typographyStyles.textAlign = element.textAlign;
    }



    const TypographyStyles = {
        ...typographyStyles,
    };


    return TypographyStyles;



};

export default Typography;
