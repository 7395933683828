import React from "react";
import styled from "styled-components";

import PillButton from "components/Button/pillButton";

// original code had the double div
// leaving it for now b/c don't want to spend time refactoring it

const Wrapper = styled.div`
  height: 82px;
  border-top: 1px solid #ccc;
  padding-top: 20px;

  & > div {
    height: 42px;
  }
`;

export const SiteSettingsFooter = ({ handleSave, saving }) => {
  return (
    <Wrapper>
      <div>
        <PillButton
          action={handleSave}
          disabled={saving}
          processing={saving}
          label={"Save"}
          width={138}
          large
        />
      </div>
    </Wrapper>
  );
};

export default SiteSettingsFooter;
