import { generatePseudoMediaQuery } from "../../../../elements/style-functions/pseudo-media-queries";

export default function StyleObject(global, data, inEditor) {
	

  return {
    '.page-jump-container': {
      display: 'inline-block',
      width: '36%',
      height: '100%',
      verticalAlign: 'top',
      float: 'right',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)',
        ['smallTablet', 'mobile'],
        {
          width:'100%',
          textAlign: 'right',
        },
        inEditor
      ),
    },

    '.page-jump-user-input-container': {
      display: 'inline-block',
      width: '30px',
      margin: '0px 5px'
    },

    '.page-jump-user-input': {
      width: '100%',
      textAlign: 'center',
    },

    '.page-jump-by-selection-container': {
      display: 'inline-block',
      width: '63%',
      height: '100%',
    },
	
		".page-jump-by-selection-mobile": {
      display:'none',
			// "@media(max-width:767px)": {
			// 	display:'block'
			// }
		},

    '.page-jump-by-selection-wrapper': {
      display: 'table',
      height: '100%',
      width: '100%'
    },

    '.page-jump-by-selection': {
      display: 'table-cell',
      textAlign: 'right',
      verticalAlign: 'middle',
      fontSize: '12px',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)',
        ['smallTablet', 'mobile'],
        {
          display:'none'
        },
        inEditor
      ),
      '.footer': {
        textAlign: 'right',
        paddingTop: '8px',
        ...generatePseudoMediaQuery(
          '@media(max-width: 767px)',
          ['smallTablet', 'mobile'],
          {
            display: 'table-cell',
            verticalAlign: 'unset',
          },
          inEditor
        ),
      }
    },

    '.page-jump-dropdown': {
      width: '35%',
      display: 'inline-block',
      verticalAlign: 'middle',
      height: '100%',
      margin: '0px 10%',
      "@-moz-document url-prefix()": {
        height: '43px',
      },
      '.footer': {
        verticalAlign: 'unset',
        textAlign: 'center',
        margin: '0px',
        width: '6%',
      }
    },

    '.page-jump-single-container': {
      display: 'inline-block',
      width: '37%',
      height: '100%',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)',
        ['smallTablet', 'mobile'], {
          width: 'unset',
          marginLeft: '10px',
        },
        inEditor
      ),
    },

    '.page-jump-single-wrapper': {
      display: 'table',
      height: '100%',
      width: '100%'
    },

    '.page-jump-single': {
      float: 'right'
    },

    '.page-jump-page-number-container': {
      position: 'absolute',
      zIndex: '5',
      backgroundColor: global.colors.light.white,
      width: '7.8%',
      marginLeft: '-1px',
      marginTop: '-1px',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)',
        ['smallTablet', 'mobile'],
        {
          display:'none'
        },
        inEditor
      ),
    },

    '.page-jump-page-number-modal-container': {
      maxHeight:'0px',
			transition:'max-height .3s ease',
      overflow:'hidden',
      '.active': {
        maxHeight: '237px',
        overflowY: 'scroll',
        transition:'max-height .2s ease',
        borderBottom: `1px solid ${ global.colors.light.color2 }`,
      },
    },
    
    '.page-jump-page-number': {
      border: `1px solid ${ global.colors.light.color2 }`,
      padding: '12px',
      display: 'block',
      cursor: 'pointer',
      textAlign: 'center',
      '.current-page': {
        backgroundColor: global.colors.light.color2
      }
    },

    '.page-jump-page-number .arrow-down': {
      float: 'right',
      marginTop: '3px',
    },

    '.page-jump-single-arrow': {
			display: 'inline-block',
			height: '25px',
			width: '25px',
			padding: '5px',
			margin: '5px 7px',
			cursor: 'pointer',
			backgroundColor: global.colors.dark.color2,
			fill: global.colors.light.white,
      ".disabled": {
				fill: global.colors.dark.color3,
				backgroundColor: global.colors.light.color2,
      },
      ".left": {
				transform: 'rotate(90deg)'
      },
			".right": {
        transform: 'rotate(-90deg)',
        marginRight: '0px',
			}
    },
    ".arrow": {
			width: '16px',
			height: '16px',
    }

  }
}
