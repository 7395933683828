import { createAction } from "redux-act";
import _ from "lodash";
import { fromJS } from "immutable";
import { graph } from "utils/graph";
import { cache, purgeSiteCache } from "utils/purge";
import { store } from "app/app";
import uuid from "uuid";

import { setCurrentModal } from "containers/Editor/actions/editorActions";

import { showLoadingIcon } from "containers/App/actions";

import { openImageGallery as openImageGalleryHelper } from "utils/imageGallery";

export const setCurrentProduct = createAction("Set the current product to the store");
export const setSnapshot = createAction("Set the current product snapshot");
export const clearProductDetailPage = createAction("Clear the product page store");
export const loadProductCategories = createAction("Load prodcut categories");
export const setStoreProductCategories = createAction("Set the product categories");
export const saveProductAction = createAction("Save the product");
export const setLoadedState = createAction("Set product detail loaded state");
export const setSavingState = createAction("Set product detail saving state");
export const setCreatingCategory = createAction("Set creating category flag");
export const setProductValue = createAction("Set generic product detail value");
export const setVariationTypeMeta = createAction("Set product variation type meta values");
export const setProductVariants = createAction("Set the product variants");
export const setProductTags = createAction("Set the product tags");
export const setSiteProductTags = createAction("Set the site product tags");

const getProduct = () => store.getState().getIn(["product", "currentProduct"]);

/**
 *
 * @param tagId ID for the tag group
 * @param labelId ID for the label within the tag group
 * @param {boolean} checked status for the labelId
 * @returns {function(*): *}
 */
export const updateProductTag = ({ tagId, labelId, checked }) => async dispatch => {
  let product = getProduct();

  let productTags = product.get("tags") || fromJS([]);

  const productHasTag = productTags.filter(tag => tag.get("id") === tagId);

  // product does not have this tagId in it's tags array
  if (!productHasTag.size) {
    productTags = productTags.push(
      fromJS({
        id: tagId,
        labels: [labelId],
      })
    );
  } else if (!checked) {
    productTags = productTags.map(productTag => {
      if (productTag.get("id") !== tagId) {
        return productTag;
      }

      let labels = productTag.get("labels") || fromJS([]);

      labels = labels.filter(label => label !== labelId);

      return productTag.set("labels", labels);
    });

    // remove any tag from product tags array if there are no
    // active lavels for the tag
    productTags = productTags.filter(productTag => {
      const labels = productTag.get("labels") || fromJS([]);
      return !!labels.size;
    });
  } else {
    productTags = productTags.map(productTag => {
      if (productTag.get("id") !== tagId) {
        return productTag;
      }

      let labels = productTag.get("labels") || fromJS([]);

      labels = labels.push(labelId);

      return productTag.set("labels", labels);
    });
  }

  product = product.set("tags", productTags);

  return await dispatch(setCurrentProduct(product));
};

export const addTag = ({ labelName, tagGroup }) => async dispatch => {
  await dispatch(showLoadingIcon(true));

  const siteId = store.getState().getIn(["auth", "activeSite", "id"]);

  let query = `
	mutation($siteId:ID!,$labelName:String!,$tagId:ID!) {
		addProductTagLabel(siteId:$siteId,labelName:$labelName,tagId:$tagId) {
			success, message
		}
	}
	`;

  let variables = {
    labelName,
    siteId,
    tagId: tagGroup.get("id"),
  };

  await graph({ query, variables });

  query = `
	query($siteId:ID!){
		ProductTags(siteId:$siteId) {
			siteId, id, name, labels { id, name }
		}
	}`;

  variables = {
    siteId,
  };

  const res = await graph({ query, variables });

  const siteProductTags = _.get(res, "ProductTags") || [];

  await dispatch(setSiteProductTags(fromJS(siteProductTags)));

  await dispatch(showLoadingIcon(false));
};

export const updateProductCategories = categoryId => async dispatch => {
  let product = getProduct();
  let productCategories;

  if (product.get("categories").includes(categoryId)) {
    productCategories = product.get("categories").filter(s => s !== categoryId);
  } else {
    productCategories = product.get("categories").push(categoryId);
  }

  product = product.set("categories", productCategories);
  await dispatch(setCurrentProduct(product));
};

function calculateSalePrice(discountType, discountValue, price) {
  let result = 0;

  if (!price || !discountValue) return 0;
  else if (discountType === "%") result = (price * (100 - discountValue)) / 100;
  else if (discountType === "$" || discountType === "") result = price - discountValue;

  return result >= 0 ? result : 0;
}

export const currency = x => parseFloat(x).toFixed(2);

export const updateProductValue = ({ field, value }) => async dispatch => {
  const updates = [];

  if (field === "price") {
    const product = getProduct() || fromJS({});

    if (product.get("onSale")) {
      let salePrice = calculateSalePrice(
        product.get("discountType"),
        product.get("discountValue"),
        value
      );
      salePrice = currency(salePrice);

      updates.push({
        field: "salePrice",
        value: salePrice,
      });
    }
  }

  if (field === "discountValue") {
    if (value === "") {
      updates.push({
        field: "salePrice",
        value: "",
      });
    } else {
      const product = getProduct() || fromJS({});

      const salePrice = calculateSalePrice(
        product.get("discountType"),
        value,
        product.get("price")
      );

      if (product.get("discountType") === "$" && value > product.get("price")) {
        return;
      }

      updates.push({
        field: "salePrice",
        value: currency(salePrice),
      });
    }
  }

  if (field === "salePrice") {
    if (value === "") {
      updates.push({
        field: "discountValue",
        value: "",
      });
    } else {
      const product = getProduct() || fromJS({});

      const discountType = product.get("discountType");
      const price = product.get("price");
      let discountValue;

      if (discountType === "$" || discountType === "") {
        discountValue = price - value;
      } else {
        discountValue = (1 - value / price) * 100;
      }

      if (discountValue < 0) {
        return;
      }

      updates.push({
        field: "discountValue",
        value: parseFloat(`${discountValue}`).toFixed(2),
      });
    }
  }

  if (field === "discountType") {
    const product = getProduct() || fromJS({});

    const salePrice = product.get("salePrice");
    const price = product.get("price");
    let discountValue;

    if (product.get("discountValue") !== "") {
      if (value === "$") {
        discountValue = price - salePrice;
      } else {
        discountValue = (1 - salePrice / price) * 100;
      }

      updates.push({
        field: "discountValue",
        value: currency(discountValue),
      });
    }
  }

  updates.push({
    field,
    value,
  });

  // need to push all the store updates at once
  // or the input cursor values jump to the end of the input field
  const promises = updates.map(({ field, value }) => dispatch(setProductValue({ field, value })));

  await Promise.all(promises);
};

export const openImageGallery = () => async dispatch => {
  await dispatch(openImageGalleryHelper(addImage));
};

export function deleteImage(index) {
  return async dispatch => {
    const product = store
      .getState()
      .get("product")
      .get("currentProduct");
    const images = product.get("images").toJS();
    images.splice(index, 1);
    await dispatch(setCurrentProduct(product.set("images", fromJS(images))));
  };
}

export const addImage = image => async dispatch => {
  await dispatch(setCurrentModal(""));

  const product = store
    .getState()
    .get("product")
    .get("currentProduct");
  const images = product.get("images");

  await dispatch(setCurrentProduct(product.set("images", images.push(fromJS({ src: image })))));
};

export function makeImagePrimary(index) {
  return async dispatch => {
    const newProduct = store
      .getState()
      .get("product")
      .get("currentProduct");
    const imagesArray = newProduct.get("images").toJS();

    imagesArray.unshift(imagesArray[index + 1]);
    imagesArray.splice(index + 2, 1);

    await dispatch(setCurrentProduct(newProduct.set("images", fromJS(imagesArray))));
  };
}

export const addCategory = catName => async dispatch => {
  const Auth = store.getState().get("auth");
  const siteId = Auth.getIn(["activeSite", "siteId"]);
  const userId = Auth.getIn(["user", "sub"]);

  const query = `
		mutation ($id: ID!, $siteId: String, $catName: String ) {
		  Account(id: $id) {
				AddProductCategory(siteId: $siteId, catName: $catName ) {
					id, name
				}
		  }
		}
		`;

  const variables = {
    id: userId,
    siteId,
    catName,
  };

  const response = await Promise.all([
    dispatch(setCreatingCategory(true)),
    graph({ query, variables }),
  ]);

  const newCatId = _.get(response, "[1].Account.AddProductCategory.id");

  const categories = await getProductCategories(siteId);

  await Promise.all([
    dispatch(setStoreProductCategories(categories)),
    dispatch(setCreatingCategory(false)),
    dispatch(updateProductCategories(newCatId)),
  ]);
};

export const fetchProduct = productId => async dispatch => {
  const site = store.getState().getIn(["auth", "activeSite"]);

  const query = `
	query($id:ID!,$siteId:ID!){
		Product(id:$id){
			id,description,hidden,quantity_in_stock,sku,title,active,product_type,salePrice,slug,onSale,
			categories,images { src, alt }, discountType,discountValue,
			pricing{currency,price},
			shipping_details{weight},
			siteId, productVariants, variationTypeMeta, metaDescription,pageTitle, tags { id, labels }
		}
		ProductTags(siteId:$siteId) {
			siteId, id, name, labels { id, name }
		}
	}`;

  const variables = {
    id: productId,
    siteId: site.get("id"),
  };

  const productResponse = await graph({ query, variables });
  const storeCategories = await getProductCategories(productResponse.Product.siteId);

  const product = productResponse.Product;
  const siteProductTags = _.get(productResponse, "ProductTags") || [];

  product.variationTypeMeta = JSON.parse(product.variationTypeMeta);
  product.variationTypeMeta = product.variationTypeMeta.map(variant => {
    if (!variant.id) {
      variant.id = uuid();
    }

    variant.values.forEach(v => {
      if (!v.id) {
        v.id = uuid();
      }
    });

    return variant;
  });

  product.variationTypeMeta = fromJS(product.variationTypeMeta);

  product.productVariants = JSON.parse(product.productVariants);

  product.productVariants = product.productVariants.map(variant => {
    variant.id = variant.id || uuid();
    variant.variations = variant.variations || [];
    variant.variations = variant.variations.map(v => {
      if (!v.name) {
        v.name = v.value;
        delete v.value;
      }

      return v;
    });

    if (variant.salePrice === false) {
      variant.salePrice = "";
    }

    if (variant.sku === false) {
      variant.sku = "";
    }

    if (variant.price === false) {
      variant.price = "";
    }

    if (variant.qtyInStock === false) {
      variant.qtyInStock = "";
    }

    if (variant.image && _.isString(variant.image)) {
      variant.image = {
        src: variant.image,
      };
    }

    return variant;
  });

  product.price = _.get(product, "pricing.price") || 0.0;

  product.price = currency(product.price);

  product.discountType = product.discountType === "" ? "$" : product.discountType;

  if (product.discountType === "" || product.discountType === "$") {
    product.discountValue = currency(product.discountValue);
  }

  if (product.discountValue === "NaN") {
    product.discountValue = "";
  }

  product.salePrice = currency(product.salePrice);

  if (product.salePrice === "NaN") {
    product.salePrice = "";
  }

  product.weight = _.get(product, "shipping_details.weight") || "";

  await dispatch(setStoreProductCategories(fromJS(storeCategories)));
  await dispatch(setSiteProductTags(fromJS(siteProductTags)));
  await dispatch(setCurrentProduct(product));
  await dispatch(setSnapshot(product));
  await dispatch(setLoadedState(true));
};

export const loadProduct = productId => async dispatch => {
  await dispatch(showLoadingIcon(true));

  await dispatch(fetchProduct(productId));

  await dispatch(showLoadingIcon(false));
};

export async function getProductCategories(siteId) {
  const userId = store.getState().getIn(["auth", "user", "sub"]);

  const query = `
		query ($id: ID!, $siteId: String) {
		  Account(id: $id) {
				ProductCategories(siteId: $siteId) {
					name, id
				}
		  }
		}
		`;

  const variables = {
    id: userId,
    siteId,
  };

  const response = await graph({ query, variables });
  return _.get(response, "Account.ProductCategories") || [];
}

export function updateCurrentProduct(param) {
  return async dispatch => {
    dispatch(setCurrentProduct(param));
  };
}

export const saveProduct = () => async dispatch => {
  await dispatch(setSavingState(true));

  const product = (store.getState().getIn(["product", "snapshot"]) || fromJS({})).toJS();
  const productId = product.id;
  const site = store.getState().getIn(["auth", "activeSite"]);
  const productTags = store.getState().getIn(["product", "siteProductTags"]) || fromJS([]);

  if (product.salePrice) {
    product.salePrice = parseFloat(product.salePrice).toFixed(2);
  }

  delete product.id;

  product.quantity_in_stock = parseInt(product.quantity_in_stock);

  product.shipping_details = {
    weight: product.weight || 0,
  };

  product.pricing = {
    price: product.price,
  };

  product.productVariants = product.productVariants || [];

  product.productVariants = product.productVariants.map(v => {
    if (v.salePrice !== "") {
      v.onSale = true;
    } else {
      v.onSale = false;
    }
    if (v.qtyInStock !== "") {
      v.qtyInStock = parseInt(v.qtyInStock);
    }
    return v;
  });

  product.productVariants = JSON.stringify(product.productVariants);
  product.variationTypeMeta = JSON.stringify(product.variationTypeMeta);

  delete product.price;
  delete product.weight;
  delete product.product_type;

  const query = `
	mutation($product:productInput, $id:ID!) {
		editProduct(id: $id, product: $product) {
			id
		}
	}
	`;

  const variables = {
    id: productId,
    product: product,
  };

  const query2 = `
	mutation($site:siteInput, $id:ID!) {
		editSite(id: $id, site: $site) {
			id
		}
	}
	`;

  const variables2 = {
    id: site.get("id"),
    site: {
      productTags: productTags.toJS(),
    },
  };

  await Promise.all([graph({ query, variables }), graph({ query: query2, variables: variables2 })]);

  await purgeSiteCache(site.get("domain"));
  await cache(site.get("domain") + product.slug);
  await dispatch(setSavingState(false));
};
