/*global Inferno, $ */
/* eslint-disable */
import {include} from '@convertly/thor';

const Script = () => {
		
		/** Imports **/
		const Graph = include('../../../../utils/graph', 'Graph');
		const Dispatcher = include('../../../../utils/dispatcher', 'Dispatcher');
		const Cookie = include('../../../../utils/cookie', 'Cookie');
		const FormValidation = include('../../../../utils/form-validation', 'FormValidation');
		const LogglyWrapper = include('../../../../utils/loggly', 'LogglyWrapper');
		const Parser = include('../../../../utils/json-parse-catch', 'Parser');
		const CheckoutFlowStageInferno1 = include('./checkout-stage-inferno-1.js', 'CheckoutFlowStageInferno1');
		const CheckoutFlowStageSignInInferno = include('./checkout-stage-signin.js', 'CheckoutFlowStageSignInInferno');
		const CheckoutFlowStageInferno3 = include('./checkout-stage-inferno-3.js', 'CheckoutFlowStageInferno3');
		const CheckoutFlowUserStage1Inferno = include('./checkout-stage-user-stage-1.js', 'CheckoutFlowUserStage1Inferno');
		const CheckoutFlowUserStage2Inferno = include('./checkout-stage-user-stage-2.js', 'CheckoutFlowUserStage2Inferno');
		const CheckoutBreadCrumbsInferno = include('./components/checkout-breadcrumbs', 'CheckoutBreadCrumbsInferno');
		//const CreditCardFormInferno = include('./components/brain-tree-cc-form', 'CreditCardFormInferno');
		const CreditCardFormInferno = include('./components/stripe-form', 'CreditCardFormInferno');
		const ApplePayButtonInferno = include('./components/apple-pay-button', 'ApplePayButtonInferno');
		const PayPalButtonInferno = include('./components/paypal-button', 'PayPalButtonInferno');
		const UserShippingAddressInferno = include('./components/shipping-address', 'UserShippingAddressInferno');
		
		/** Sign in **/
		const GoogleButtonInferno = include('../../../../sign-in-up/google-button/GoogleButton', 'GoogleButtonInferno');
		const FacebookButtonInferno = include('../../../../sign-in-up/facebook-button/FacebookButton', 'FacebookButtonInferno');
		const SignInFormInferno = include('../../../../sign-in-up/sign-in-up-form/upSigninButton', 'SignInFormInferno');
		const StaySignedInInferno = include('../../../../sign-in-up/sign-in-up-form/stay-signed-in/stay-signed-in', 'StaySignedInInferno');
		
		const PayWithExternalAccountInferno = include('./components/pay-with-external-account', 'PayWithExternalAccountInferno');
		const ShippingCalculatorInferno = include('../../../../ecommerce-common-components/shipping-widgets/shipping-widget', "ShippingCalculatorInferno");
		const NotificationBoxInferno = include('../../../../ecommerce-common-components/notification-box/notification-box', "NotificationBoxInferno");
		const SpinnerSVGInferno = include('../../../../svg/spinner/spinner', 'SpinnerSVGInferno');
		
		//Child Components
		const AddressFormInferno = include('./components/address-form', 'AddressFormInferno');
		
		
		return (id, clientImage, siteId, EcommerceSettings, facebookClientId, stripePublicKey, siteLink, paypalInstalled, stripeAccountId, stripeLiveMode) => {
			
			const filename = 'checkout-stage-wrapper';
			
			const parser = new Parser();
			const hasLocalStorage = $('html').hasClass('localstorage');
			
			const ecommerceSettings = parser(EcommerceSettings);
			
			const root = document.getElementById(id);
			const rootClass = root.className;
			
			paypalInstalled = paypalInstalled === 'true';
			
			/** Init Imports **/
				
				//Convertly Utils
			const graph = Graph();
			const dispatch = new Dispatcher(id);
			const validateForm = new FormValidation();
			const cookie = new Cookie();
			const Loggly = new LogglyWrapper();
			
			//Components
			
			const AddressForm = new AddressFormInferno(id, dispatch, validateForm);
			const SpinnerSVG = SpinnerSVGInferno();
			const UserShippingAddress = UserShippingAddressInferno(dispatch, validateForm);
			const NotificationBox = NotificationBoxInferno();
			
			const GoogleButton = GoogleButtonInferno(dispatch, null);
			const FacebookButton = FacebookButtonInferno(dispatch, null);
			const StaySignedIn = StaySignedInInferno(dispatch);
			const SignInForm = SignInFormInferno(dispatch, null, validateForm, SpinnerSVG, StaySignedIn);
			
			const CheckoutFlowStageSignIn = CheckoutFlowStageSignInInferno(dispatch, validateForm, siteId, facebookClientId, GoogleButton, FacebookButton, SignInForm);
			
			const ShippingCalculator = ShippingCalculatorInferno(dispatch, graph, siteId);
			const CheckoutBreadCrumbs = CheckoutBreadCrumbsInferno(dispatch, siteLink);
			
			const CreditCardForm = CreditCardFormInferno(id, dispatch, validateForm, stripePublicKey, SpinnerSVG, NotificationBox, stripeAccountId, stripeLiveMode, graph, Loggly);
			
			const ApplePayButton = ApplePayButtonInferno(dispatch);
			const PayPalButton = PayPalButtonInferno(dispatch, graph, siteId);
			
			const PayWithExternalAccount = PayWithExternalAccountInferno();
			const CheckoutFlowStage1 = CheckoutFlowStageInferno1(id, rootClass, dispatch, PayWithExternalAccount, validateForm, siteId, facebookClientId, AddressForm, ShippingCalculator, ecommerceSettings, SpinnerSVG);
			
			
			const CheckoutFlowStage3 = CheckoutFlowStageInferno3(dispatch, AddressForm, ShippingCalculator, CreditCardForm, PayWithExternalAccount, validateForm, ecommerceSettings, ApplePayButton, PayPalButton, paypalInstalled);
			const CheckoutFlowUserStage1 = CheckoutFlowUserStage1Inferno(id, graph, dispatch, AddressForm, ShippingCalculator, CreditCardForm, PayWithExternalAccount, validateForm, ecommerceSettings, UserShippingAddress, ShippingCalculator, SpinnerSVG);
			const CheckoutFlowUserStage2 = CheckoutFlowUserStage2Inferno(graph, dispatch, AddressForm, ShippingCalculator, CreditCardForm, PayWithExternalAccount, validateForm, ecommerceSettings, UserShippingAddress, ApplePayButton, PayPalButton, paypalInstalled);
			
			let stripeInterval;
			let stripeInitCalled = false;
			
			/**
			 * sets Initial render state for app
			 */
				//update renderState dispatch event
			const CheckoutWrapper = Inferno.createClass({
					
					getInitialState: function () {
						
						//this.loadBraintree();
						let renderStage;
						let isSignedIn;
						let accessToken = '';
						let shippingInfo;
						let shippingAddress;
						let billingAddress;
						
						
						//Check to see if Logged in or if data in local storage
						accessToken = cookie.read({name: 'authToken'});
						if (hasLocalStorage) {
							if (accessToken) {
								accessToken = accessToken;
								isSignedIn = true
							}
							shippingInfo = localStorage.getItem('shipping_rate');
							shippingInfo = parser(shippingInfo);
							
							shippingAddress = localStorage.getItem('shippingAddress');
							shippingAddress = parser(shippingAddress);
							
							billingAddress = localStorage.getItem('billingAddress');
							billingAddress = parser(billingAddress);
							
						}
						
						else {
							//something else we'll set up later
						}
						
						
						/** Stage 4  todo: set up user api request **/
						if (isSignedIn && $.isEmptyObject(shippingInfo)) {
							renderStage = 1;
							localStorage.setItem('renderStage', JSON.stringify(renderStage));
							
							
						}
						else if (isSignedIn && !$.isEmptyObject(shippingInfo)) {
							renderStage = 3;
							localStorage.setItem('renderStage', JSON.stringify(renderStage));
						}
						/** Stage 3 **/
						else if (!$.isEmptyObject(shippingAddress) && !$.isEmptyObject(shippingInfo)) {
							renderStage = 3;
							localStorage.setItem('renderStage', JSON.stringify(renderStage));
							
						}
						/** Stage 2 **/
						else if (!$.isEmptyObject(shippingAddress) || $.isEmptyObject(shippingInfo) && shippingInfo) {
							renderStage = 1;
							localStorage.setItem('renderStage', JSON.stringify(renderStage));
							
						}
						else {
							renderStage = 1;
							localStorage.setItem('renderStage', JSON.stringify(renderStage));
							dispatch.send('event', {action: 'Checkout Stage 1'});
						}
						
						dispatch.update('renderStage', renderStage);
						
						
						return {
							renderStage: renderStage,
							billingAddress: billingAddress,
							shippingInfo: shippingInfo,
							shippingAddress: shippingAddress,
							currentCheckOutStage: null,
							haveShippingInfo: false,
							braintreeLoaded: false,
							fingerprint: null,
							accessToken: accessToken,
							maxRenderStage: renderStage,
							isSignedIn: isSignedIn,
							selectedAddressIndex: null
							
							
						};
					},
					/**
					 *  for now this just checks if the site sitting is enabled,
					 *  but eventually we'll have it check a bunch of other things like user location
					 *  radius compared to zipcode
					 *  if items in stock are availalbe at location etc, etc.
					 * @returns {*}
					 */
					checkCurbsidePickup() {
						return ecommerceSettings.curbsideEnabled
					},
					
					
					componentWillMount() {
						//this.getInitialRenderStage();
						dispatch.on('process_payment', this.processPayment);
						dispatch.on('cart_total', this.set.bind(this, 'cart_total'));
						dispatch.on( 'install_stripe', this.installStripeSDK );
						//putting check for curbside pickup in a function as
						//eventually we'll be implementing checking on radius
						//and possibly other options.
						dispatch.update( 'curbside_pickup_enabled', this.checkCurbsidePickup.bind(this) )
						dispatch.update('curbside_pickup_data', {
							curbsidePickupInstructions:ecommerceSettings.curbsidePickupInstructions,
							curbsideCallLink:ecommerceSettings.curbsideCallLink
						})
					},

					componentDidMount () {
            
            window.loadFingerprint().then( fingerprint => this.setState({fingerprint}) );
						
						// new Fingerprint2().get((hash) => {
						// 	this.setState({
						// 		fingerprint: hash
						// 	});
						// });
						this.updateShippingAddress();
						this.updateBillingAddress();
						this.updateRenderStage();
						
					},
				
					installStripeSDK() {
						
						console.log( filename, '::installStripeSDK' );
						
						let script = document.createElement('script');
						script.type = "text/javascript";
						script.src = 'https://js.stripe.com/v3/';
						script.async = true;
						script.addEventListener( 'load', this.stripeInstalled, false );
						script.addEventListener( 'error', this.stripeInstallError, false );
						//script.onload = this.stripeInit;
						//script.onerror = this.stripeInitError;
						stripeInterval = setInterval( this.checkStripeInstalled, 10 );
						$('head').append(script);
						
					},
					
					/**
					 * There is a bug with the addEventListener script
					 * This works on page load if reloading the checkout page and
					 * already at Payment section
					 *
					 * If you go from shipping to payment the addEventListener load
					 * script never fires.
					 *
					 * The only thing I can see that could make a difference is that localStorage
					 * has renderStage as 1 on the payment screen if you go from shipping
					 *
					 * On reload renderStage is 3
					 *
					 * Other than that couldn't see anything else different
					 * I don't know the script well enough to know why that would make a difference
					 *
					 * On reload there are several stripe-form::render calls ( stripe form correctly renders )
					 * When going from shipping -> payment there is only one stripe-form::render call
					 */
				
					checkStripeInstalled() {
						if( !! window.Stripe ) {
							console.log( filename, 'stripe installed' );
							clearInterval( stripeInterval );
							dispatch.send( 'stripe_sdk_installed' );
						} else {
							console.log( filename, 'stripe not installed' );
						}
					},
				
					stripeInstalled() {
						
						console.log( filename, '::stripeInstalled' );
						
						//stripeInitCalled = true;
						
						//dispatch.send( 'stripe_sdk_installed' );
					},
				
					stripeInstallError( e ) {
						
						console.log( filename, '::stripeInstallError' );
						
						console.log( 'stripeInstallError', e );
					},
					
					set: function (field, value) {
						const params = {};
						params[field] = value;
						this.setState(params);
					},
					
					loadBraintree () {
						
						const tags = [
							{
								src: '//js.braintreegateway.com/web/3.22.0/js/client.min.js'
							},
							{
								src: '//js.braintreegateway.com/web/3.22.0/js/hosted-fields.min.js',
								cb: this.braintreeInit
							}
						];
						
						tags.forEach(tag => {
							let script = document.createElement('script');
							script.type = "text/javascript";
							script.src = tag.src;
							script.async = false;
							if (tag.cb) {
								script.onload = tag.cb;
							}
							$('head').append(script);
						});
						
					},
					
					// callback for braintree script tag
					braintreeInit: function () {
						
						this.setState({
							braintreeLoaded: true
						});
						
					},
					
					logError(payload, message) {
						
						let query = `mutation ($payload: String, $key: String) {
                            SaveLog(payload: $payload, key: $key)
                        }`;
						
						payload = JSON.stringify(payload);
						
						const variables = {
							key: message,
							payload
						};
						
						const cb = (err, success) => {
							if (success) {
								console.log('sent error data data', success);
							}
							else {
								console.log('sent error data data', err);
							}
						};
						
						
						graph({query, variables, cb})
						
						
					},
					
					
					/**
					 * Submits request to server for payment
					 * Is triggered from dispatch 'process_payment' event
					 *
					 * @param nonce - Payment nonce from BT Client SDK
					 */
					processPayment ({token, vendor, ev}) {
						
						const query = `
                        	mutation ($accessToken: String, $nonce: ID!, $vendor: String, $id: ID!, $data: String!) {
                                      Payment(accessToken: $accessToken, nonce: $nonce, vendor: $vendor, id: $id, data: $data) {
                                        success
                                        message
                                        orderId
                                      }
                                    }`;
						
						this.ev = ev;
						
						const shippingAddress = parser(localStorage.getItem('shippingAddress'));
						
						const billingAddress = parser(localStorage.getItem('billingAddress'));
						const shipping = parser(localStorage.getItem('shipping_rate'));
						const shippingCost = shipping.rate || '0';
						const cart = parser(localStorage.getItem('cart'));
						const salestax = localStorage.getItem('salestax');
						const accessToken = cookie.read({name: 'authToken'});
						const fingerprint = this.state.fingerprint;
						const email = shippingAddress.email;
						//const total = parseFloat(localStorage.getItem('total')).toFixed(2);
						let cartTotal = this.state.cart_total;
						
						let total = parseFloat(cartTotal) + parseFloat(salestax) + parseFloat(shippingCost);
						total = parseFloat(total).toFixed(2);
						
						cart.total = total;
						
						const data = {
							shippingAddress,
							billingAddress,
							shipping,
							cart,
							fingerprint,
							salestax,
							email
						};
						
						const variables = {
							nonce: token,
							accessToken: accessToken,
							vendor: vendor,
							id: siteId,
							data: JSON.stringify(data)
						};
						
						console.log( 'processPayment', data );
						
						graph({query, variables, cb: this.submitPaymentCallback});
						
					},
					
					/**
					 * Process Payment callback
					 *
					 * @param err
					 * @param response
					 */
					submitPaymentCallback (err, response) {
						
						console.log( 'submitPaymentCallback', err, response );
						
						Loggly({
							"category": "Payment Response",
							"message": response.Payment,
							"localStorage": window.localStorage,
						});
						
						if (response) {
							
							const {success, message, orderId} = response.Payment;
							
							if (success) {
								//empties local storage
								
								if (this.ev) {
									this.ev.complete('success');
								}
								
								dispatch.send('empty_cart', '');
								localStorage.removeItem('shipping_rate');
								localStorage.removeItem('shippingAddress');
								localStorage.removeItem('billingAddress');
								localStorage.removeItem('salestax');
								
								cookie.create({
									name: 'placed_convertly_order',
									value: orderId
								});
								
								window.location.href = window.location.origin + '/order-confirmation?orderId=' + orderId + "&typage=true";
								
							}
							
							else {
								// error handling
								if (this.ev) {
									this.ev.complete('fail');
								}
								
								
								dispatch.send( 'payment_error', response.Payment );
								
								Loggly({
									"category": "Payment Failure",
									"message": response.Payment,
									"localStorage": window.localStorage,
								});
							}
						}
						
						else {
							
							console.log('ERR', err);
							
							Loggly(
								{
									"category": "Payment Failure",
									"message": err,
									"localStorage": window.localStorage,
								});
							
							dispatch.send('payment_error', err);
						}
					},
					
					/** Checks if billing info is in local storage, updates state **/
					getBillingInfo: function () {
						
						if (hasLocalStorage) {
						
						}
						try {
							let billingAddress = localStorage.getItem('billingAddress');
							billingAddress = parser(billingAddress);
							this.setState({billingAddress: billingAddress}, () => {
								dispatch.send('send_billing_info', this.state.billingAddress)
							});
							
						}
						catch (err) {
							return false
						}
					},
					
					/** Checks if shipping info is in local storage, updates state **/
					updateBillingAddress: function () {
						dispatch.on('update_billingAddress', (newAddress) => {
							this.setState({billingAddress: newAddress});
							//this.renderCurrentStage(this.state.renderStage);
						});
						
						
						dispatch.on('remove_billing_info', () => {
							this.setState({billingAddress: null});
							//this.renderCurrentStage(this.state.renderStage);
						});
					},
					
					/** Checks if shipping info is in local storage, updates state **/
					getShippingAddress: function () {
						if (hasLocalStorage) {
							try {
								let shippingAddress = localStorage.getItem('shippingAddress');
								shippingAddress = parser(shippingAddress);
								
								this.setState({shippingAddress: shippingAddress});
							}
							catch (err) {
								return false
							}
						}
					},
					
					/** Listens for shipping address update sets new address to state */
					updateShippingAddress: function () {
						
						dispatch.on('update_shippingAddress', (newAddress) => {
							this.setState({shippingAddress: newAddress});
						});
						dispatch.on('update_shippingAddress_index', (index) => {
							this.setState({selectedAddressIndex: index});
						});
						
					},
					
					/** Checks if shipping info is in local storage, updates state **/
					getShippingInfo: function () {
						
						if (hasLocalStorage) {
							try {
								let shippingInfo = localStorage.getItem('shipping_rate');
								shippingInfo = parser(shippingInfo);
								
								this.setState({shippingInfo: shippingInfo, haveShippingInfo: true});
								
							}
							catch (err) {
								return false
							}
						}
						
					},
					/** Sets initial render state **/
					getRenderStage: function () {
						
						if (hasLocalStorage) {
							try {
								let renderStage = localStorage.getItem('renderStage');
								renderStage = parser(renderStage);
								
								this.setState({renderStage: renderStage});
							}
							catch (err) {
								return false
							}
						}
					},
					
					/** Listens for update and sets stage to active **/
					updateRenderStage: function () {
						/** test run on animation **/
						dispatch.on('update_render_stage_check', () => {
							this.getInitialRenderStage();
						});
						
						dispatch.on('update_render_stage', (renderStage) => {
							
							
							dispatch.update('renderStage', renderStage);
							
							if (renderStage.stage > this.state.maxRenderStage) {
								this.setState({
									renderStage: renderStage.stage,
									maxRenderStage: renderStage.stage
								});
								dispatch.send('event', {action: 'Checkout Stage ' + renderStage.stage});
							}
							else {
								this.setState({
									renderStage: renderStage.stage
								})
							}
							
							if (renderStage.stage === 3) {
								dispatch.send('get_sales_tax');
							}
							
						});
						
						dispatch.watch('accessToken', (token) => {
							this.getShippingInfo();
							if (!$.isEmptyObject(this.state.shippingInfo)) {
								this.setState({
									isSignedIn: true,
									accessToken: token,
									renderStage: 3
								})
							}
							
							else {
								this.setState({
									isSignedIn: true,
									accessToken: token,
									renderStage: 1
								})
							}
							
						})
						
					},
					
					render: function () {
						
						//Watching for errors that may be preventing the stripe form to load
						window.onerror = function (errorMsg, url, lineNumber, error) {
							Loggly(
								{
									"category": "JS Error on checkout page",
									"message": errorMsg,
									"lineNumber": lineNumber,
									"error": error
								})
						};
						
						/** Determines the current screen based on render stage **/
						let checkoutStage1 = null;
						if (parseInt(this.state.renderStage) === 1) {
							checkoutStage1 = <CheckoutFlowStage1 billingAddress={ this.state.billingAddress }
																									 shippingAddress={ this.state.shippingAddress }
																									 shippingInfo={ this.state.shippingInfo }
																									 renderStage={ this.state.renderStage }
																									 rootDiv={ root }/>;
						}
						
						let checkoutStage2 = null;
						if (parseInt(this.state.renderStage) === 3) {
							checkoutStage2 = <CheckoutFlowStage3 billingAddress={ this.state.billingAddress }
																									 shippingAddress={ this.state.shippingAddress }
																									 shippingInfo={ this.state.shippingInfo }
																									 renderStage={ this.state.renderStage }
																									 rootDiv={ root }/>;
						}
						if (this.state.isSignedIn) {
							if (parseInt(this.state.renderStage) === 1) {
								checkoutStage1 = <CheckoutFlowUserStage1 rootDiv={ root }
																												 selectedAddressIndex={  this.state.selectedAddressIndex }
																												 shippingAddress={ this.state.shippingAddress }
																												 accessToken={ this.state.accessToken }
																												 renderStage={ this.state.renderStage }
																												 loggedIn={ true }
																												 animateIn={ true }/>;
							}
							if (parseInt(this.state.renderStage) === 3) {
								checkoutStage2 = <CheckoutFlowUserStage2 rootDiv={ root }
																												 selectedAddressIndex={  this.state.selectedAddressIndex }
																												 shippingAddress={ this.state.shippingAddress }
																												 accessToken={ this.state.accessToken }
																												 renderStage={this.state.renderStage}
																												 loggedIn={ true }
																												 animateIn={ true }/>;
							}
						}
						
						
						return (
							<div className={ root.className + " checkoutFlowColumn" }>
								<CheckoutBreadCrumbs rootDiv={ root }
																		 maxRenderStage={ this.state.maxRenderStage }
																		 currentStage={ this.state.renderStage }/>
								<div className={ `${root.className} +  checkoutFlowWrapper` }>
									<div
										className={ `${root.className} +  checkoutFlow1Container ${ parseInt(this.state.renderStage) === 0 ? "" : "stage-left"}`  }>
										<CheckoutFlowStageSignIn billingAddress={ this.state.billingAddress }
																						 shippingAddress={ this.state.shippingAddress }
																						 shippingInfo={ this.state.shippingInfo }
																						 rootDiv={ root }
										/>
									</div>
									
									<div
										className={ `${root.className} +  checkoutFlow1Container ${ parseInt(this.state.renderStage) === 1 ? "" : "stage-left"}` }>
										{ checkoutStage1 }
									</div>
									
									<div
										className={ `${root.className} +  checkoutFlow1Container ${ parseInt(this.state.renderStage) === 3 ? "" : "stage-left"}` }>
										{ checkoutStage2 }
									</div>
								</div>
							
							
							</div>
						)
					}
					
				});
			
			Inferno.render(<CheckoutWrapper />, root);
			
		};
		
	}
;

export default Script;
