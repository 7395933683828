/*global Inferno, $, React */
/** Box Selector Product Variant Widget **/

const ActiveTodos = (React, dispatch) => {
	return ({activeTodos, rootClass}) => {
		return (
			<div className="row">
				{ activeTodos.length > 0 ?
					activeTodos.map((todo, index) => {
						return (
							<div className="col-md-offset-4 col-md-4">
								<div className={`${ rootClass } todo`} >{todo}</div>
								<span data-function="removeTodo"
                                      className={`${ rootClass } remove-todo`}
									  data-index={ index }>delete</span>
							</div>
						)
					}) :
					<div>All Caught up!</div>
					
				}

			</div>
		)
		
	}
	
};

export default ActiveTodos;
