import { StyleSheet } from "@convertly/css";
import ModuleStyles from '../../../elements/style-functions/module-global-styles';
import FooterGlobal from '../footer-global-styles';

const footerStyles = ( global, data, hideConvertlyBranding ) => {

    const moduleStyles = new ModuleStyles( global, data );
    const footerGlobalStyles = new FooterGlobal( global, data );

    return StyleSheet.create({
        footer: {
            display:'block',
            position:'absolute',
            bottom: hideConvertlyBranding ? '0' : '20px',
            color: global.colors.dark.color2,
            padding: '0',
            fontFamily:global.fontFamily.secondaryFont,
            "* body": {
                paddingBottom: hideConvertlyBranding ? "80px" : "100px",
            },
            '@media (max-width:991px)': {
                textAlign:'center',
                "* body": {
                    paddingBottom: hideConvertlyBranding ? "120px" : "140px",
                },
            },
            '@media (max-width:420px)': {

                "* body": {
                    paddingBottom: hideConvertlyBranding ? "140px" : "160px",
                },
            },
            ...footerGlobalStyles,
            ...moduleStyles
        },

        footerLinksContainer: {
            float: 'left',
            '@media ( max-width:991px )': {
                float:'none',
                textAlign:'center'
            }
        },
        footerLinks: {
            marginTop:'40px',
            background: global.colors.light.color2,
            padding: '50px 0 75px',
        },
        footerNavList: {
            marginRight:'30px',
            listStyle: 'none',
            marginLeft:'10px',
            textAlign:'left',
            '@media( max-width:991px )': {
                textAlign:'center',
                margin:'0 auto'
            }
        },
        footerNavTitle: {
            fontFamily:global.fontFamily.primaryFont,
            fontSize: '16px',
            marginTop: '10px'
        },
        footerNavLink: {
            fontSize: '12px',
            opacity:'.7',
            lineHeight: '26px',
            transition: 'all 0.3s linear 0s',
            display: 'inline-block',
            ':hover': {
                opacity:'1'
            }
        },
        footerNavLogo: {
            maxWidth: '200px',
            display: 'block',
            '@media(max-width:991px)': {
                margin: '50px auto 10px auto',
            }
        },
        addressBox: {
            width:'50%',
            margin:0,
            display:'inline-block',
            '@media ( max-width:1124px )': {
                width:'100%',
                marginTop:'10px'
            }
        },
        footerNavOfficeName: {
            fontFamily:global.fontFamily.primaryFont,
            fontSize: '16px',
            marginBottom: '5px',
            marginTop: '20px',
        },
        footerNavAddress: {
            marginBottom:'3px',
            fontSize: '14px',
            lineHeight: '26px',
            transition: 'all 0.3s linear 0s',
            display: 'inline-block'
        },
        footerNavPhoneNumber: {
            color:global.colors.accent.color1,
            fontSize: '14px',
            margin:0,
            marginBottom: '5px'
        },
        footerNavPhoneNumberLink: {
            marginBottom:0,
            opacity:'1',
            transition: 'all 0.3s linear 0s',
            ':hover': {
                opacity:'.7',
            }
        },
        copyright: {
            padding: '30px 0',
            background: global.colors.dark.black,
            color: global.colors.light.white,
        },
        copyrightText: {
            margin:0,
            fontSize: '1em',
            '@media ( max-width:991px )': {
                marginBottom: '20px'
            }
        },
        copyrightNavList: {
            margin:0,
            listStyle: 'none'
        },
        copyrightNavListItem: {
            opacity:'1',
            float:'right',
            marginRight: '10px',
            ':hover': {
                opacity:'.7'
            },
            '@media ( max-width:991px )': {
                display:'inline-block',
                float:'none'
            }
        },
        copyrightNavListItemLink: {
            fontSize: '1em',
            transition: 'all 0.3s linear 0s',
            marginRight: '15px',
            display: 'block'
        },
        socialIcon: {
            height: '1.5em',
            width: '2.5em',
            cursor: 'pointer',
            fill: global.colors.light.color2,
            ':hover': {
                fillOpacity:'.7'
            }
        },
        footerSectionContainer: {
            display:'inline-block',
            textAlign:'left',
            minWidth:'440px',
            '@media( max-width:991px )': {
                textAlign:'center'
            },
            '@media (max-width:440px)': {
                minWidth:'100%'
            }
        }
    })


};
export default footerStyles;
