import { createAction } from "redux-act";
import { v1 as uuid } from "uuid";
import { fromJS } from "immutable";
import { browserHistory } from "react-router";
import { store } from "app/app";
import { graph } from "utils/graph";
import CONSTANTS from "containers/App/modal-constants";
import { setCurrentModal } from "containers/Editor/actions/editorActions";
import { closeModal } from "containers/Editor/actions/editorActions";
import { NOTIFICATIONS } from "app/config";

export const toggleMenu = createAction("App: Toggle the main menu");
export const setMenu = createAction("SET_MENU");
export const showAppOverlay = createAction("App: Set the app overlay flag");
export const setAppOverlay = createAction("App: Set the app overlay opacity - 1");
export const setAppOverlayOpacity = createAction("App: Set the app overlay opacity - 2");
export const showLoadingIcon = createAction("App: Toggle loading icon");
export const setAppOverlayIndex = createAction("App: Set the app overlay z-index");
export const setNotifications = createAction("App: SET_NOTIFICATIONS");
export const setUploadingImage = createAction("App: APP_SET_UPLOADING_IMAGE");
export const setHelpFormSent = createAction("App: APP_SET_HELPFORM_SENT");
export const setAlert = createAction("App: APP_SET_ALERT");
export const setAlertConfirmation = createAction("App: APP_SET_ALERT_CONFIRMATION");
export const setSetting = createAction("App: APP_SET_SETTING");
export const setStripe = createAction("App: Set Stripe object");
export const setGraphRunning = createAction("App: Set Graph Running flag");
export const setGraphRequests = createAction("App: Set Graph Requests array");

// Wrapper HOC for dispatch functions
export const withLoadingIcon = callingFunction => {
  return args => async dispatch => {
    await dispatch(showLoadingIcon(true));
    await dispatch(callingFunction(args));
    await dispatch(showLoadingIcon(false));
  };
};

export const toggleMainMenu = () => async dispatch => await dispatch(toggleMenu());

export function setMainMenu(isMenuOpen) {
  return async dispatch => {
    dispatch(setMenu(isMenuOpen));
  };
}

const getNotifications = () => store.getState().getIn(["global", "notifications"]) || fromJS([]);

export const removeNotification = id => async dispatch => {
  const notifications = getNotifications();

  const index = notifications.findIndex(n => n.get("id") === id);

  if (index === -1) {
    return;
  }

  await dispatch(setNotifications(notifications.delete(index)));
};

export const clearNotification = (id, args) => async dispatch => {
  let notifications = getNotifications();

  let index = notifications.findIndex(n => n.get("id") === id);

  if (index === -1) {
    return;
  }

  await dispatch(setNotifications(notifications.setIn([index, "transition"], "exit")));

  setTimeout(async () => await dispatch(removeNotification(id)), NOTIFICATIONS.TRANSITION);
};

export const registerNotification = ({ sticky, ...args }) => async dispatch => {
  const id = uuid();

  await dispatch(
    setNotifications(
      getNotifications().push(
        fromJS(args)
          .set("id", id)
          .set("transition", "enter")
      )
    )
  );

  setTimeout(async () => {
    const notifications = getNotifications();

    const index = notifications.findIndex(n => n.get("id") === id);

    if (index === -1) {
      return;
    }

    await dispatch(setNotifications(notifications.setIn([index, "transition"], "")));
  }, NOTIFICATIONS.TRANSITION);

  if (!sticky) {
    setTimeout(async () => await dispatch(clearNotification(id)), NOTIFICATIONS.TIMEOUT);
  }

  return id;
};

export const registerAlert = args => async dispatch => {
  await dispatch(setAlertConfirmation(null));

  await dispatch(
    setSetting({
      field: "alert-prompt-value",
      value: args.value || "",
    })
  );

  await dispatch(
    setSetting({
      field: "alert-transition",
      value: "enter",
    })
  );

  await dispatch(
    setAlert(
      fromJS({
        id: uuid(),
        ...args,
      })
    )
  );

  let confirmation = null;

  return new Promise(resolve => {
    const interval = setInterval(async () => {
      confirmation = store.getState().getIn(["global", "alertConfirmation"]);

      if (confirmation !== null) {
        clearInterval(interval);

        await dispatch(
          setSetting({
            field: "alert-transition",
            value: "exit",
          })
        );

        setTimeout(async () => {
          resolve(confirmation);
          await dispatch(setAlertConfirmation(null));
          await dispatch(setAlert(null));
          await dispatch(clearPromptValue());
        }, 100);
      }
    }, 100);
  });
};

export const updateSetting = (field, value) => async dispatch => {
  await dispatch(
    setSetting({
      field,
      value,
    })
  );
};

export const clearPromptValue = () => async dispatch => {
  await dispatch(
    setSetting({
      field: "alert-prompt-value",
      value: "",
    })
  );
};

export function toggleHelpModal() {
  return async dispatch => {
    dispatch(setCurrentModal(CONSTANTS.MODALS.HELP_FORM));
  };
}

export function submitHelpForm({ payload, location }) {
  return async dispatch => {
    const site = store.getState().getIn(["auth", "activeSite"]);
    const page =
      store
        .getState()
        .get("editor")
        .editorReducer.get("page") || fromJS({});
    const location = browserHistory.getCurrentLocation();

    const message = `${payload.message}

*User Email:* ${payload.email}
*User ID:* ${payload.id}
*Site:* ${site.get("siteName")}
*Site ID:* ${site.get("id")}
*Page:* ${page.get("name") || "not in editor"}
*Page ID:* ${page.get("id") || "not in editor"}
*Location:* ${location.pathname}`;

    delete payload.message;

    const query = `
      query ($id: ID!, $name: String, $email: String, $message: String) {
        EmailHelp(id: $id, name: $name, email: $email, message: $message)
      }
    `;
    const variables = {
      ...payload,
      message,
    };

    const res = await graph({ variables, query });
    await dispatch(setHelpFormSent(true));
  };
}

export function closeHelpForm() {
  return async dispatch => {
    await dispatch(closeModal());
    await dispatch(setHelpFormSent(false));
  };
}
