import React from 'react';
import {css} from "@convertly/css";
import Component from '../rootComponent';
import Elements from '../elements'
import _, {isEmpty} from 'lodash'
import ContestFormScriptGenerator from "./InfernoComponents/ContestForm/ContestForm";
import {ReactInfernoRenderer} from "../utils/ReactInfernoRenderer";
import ContestFormInferno from "./InfernoComponents/ContestForm/ContestFormInferno";
import ContestSubmissionsGenerator from './InfernoComponents/ContestSubmissions/ContestSubmissions';
import ContestSubmissionsInferno from './InfernoComponents/ContestSubmissions/ContestSubmissionsInferno'
import {id} from '@convertly/thor';
import {DUMMY_SUBMISSIONS} from "./ContestsDummyData";
import {contestImage} from "../utils/generateImage";
import {mercury} from "../utils/mercury";

const ContestForm = ContestFormScriptGenerator();
const ContestSubmissions = ContestSubmissionsGenerator()


const {
    Column,
    Container,
    Row,
    Paragraph,
    Heading2,
    Heading3
} = Elements;
//Styles
import Styles from './ContestManagerStyles';
import {doQuery} from "../utils/doQuery";
import {deeplog} from "../utils/deeplog";
import {frontloadConnect} from "@convertly/react-frontload";
import {frontLoadOptions} from "../utils/frontLoadOptions";


let contest = {};
let loaded = false;

const fetchContestData = (props, component) => {
    return new Promise(async (resolve, reject) => {
        if (loaded && _.get(component, "props.inEditor")) {
            return resolve();
        }

        const siteId = _.get(props, "site.siteId");
        const contestId = _.get(props, 'data.contestId');

        const variables = {
            siteId,
            contestId
        };

        const query = `query($contestId:ID!, $siteId:ID!) {
                          Contest:getContest(contestId:$contestId siteId:$siteId) {
                            id
                            name
                            status
                            limit
                            duplicatePrevention
                            tabSettings {
                              displayImageTitle
                              name
                              id
                              allowVisitorsToSubmit
                            }
                            columnsPerTab
                          }
                        }`


        //return contest.Contest
        let res;
        try {
            res = await mercury({service: "sites", environment: "production", query, variables});

            contest = res.Contest;
        } catch (err) {
            deeplog(err)
        }


        //const data = await doQuery({query, variables});
        //authorList[props.id] = _.get( data.data, 'BlogAuthors' ) || [];
        loaded = true;
        resolve();
    });
};


const frontload = async props => fetchContestData(props);

//Component
class ContestManager extends Component {
    constructor(props) {
        super(props);

        this.imagePath = contestImage({url:'', contestId:this.props.data.contestId})
    }

    async componentWillMount() {

        this.setState({
            contest:await fetchContestData(this.props, this)
        })
        if (!loaded) {
            await fetchContestData(this.props, this).then(res => {

            });
        }

    }


    render() {
        const {data, global} = this.props;
        /**
         * Calls Stylesheet
         **/
        this.styles = Styles(this.props.global, this.props.data);

        /** Generates border in editor */
        const inlineStyle = this.generateStyleAttribute();
        if (!contest.id) {
            return null;
        }

        return (

            <div
                {...this.generateCustomAttributes()}
                className={css(this.styles.contestManager)}
                {...this.generateDataEditorAttribute()}
                onClick={(e) => {
                    this.launchElementalEditor('Accordion', e);
                }}
                onMouseOver={(e) => {
                    this.addEditableOutline(e)
                }}
                onMouseOut={(e) => {
                    this.removeEditableOutline(e)
                }}
                {...inlineStyle}
            >
                <Container>
                    <Row notEditable={true}>
                        <Column
                            data={{
                                mdColumns: 7
                            }}>
                            <div>
                                {!isEmpty(data.title1) && <Heading3 global={global} data={{text: data.title1}}/>}
                                {!isEmpty(data.title2) && <Heading2 global={global} data={{text: data.title2}}/>}
                                {!isEmpty(data.paragraph) && <Paragraph global={global} data={{text: data.paragraph}}/>}
                                {data.sharing && <p>Sharing stuff goes here</p>}
                            </div>
                        </Column>
                        <Column
                            data={{
                                mdColumns: 5
                            }}>
                            <div
                                className={css(this.styles.contestFormWrapper)}
                                id={id(
                                    ContestFormInferno,
                                    this.props.site.siteId,
                                    _.get(this.props, 'data.allowPhotoDescription')
                                    )}
                                data-contest-string={JSON.stringify(contest)}
                            >
                                <ContestForm
                                    allowPhotoDescription={contest.allowPhotoDescription}
                                    tabs={contest.tabSettings}
                                    contestStatus={"live"}
                                />
                            </div>
                        </Column>
                    </Row>
                    <Row>
                        <Column data={{xsColumns: 12}}>
                            <div id={id(
                                ContestSubmissionsInferno,
                                this.props.site.siteId,
                                this.props.data.contestId,
                                this.imagePath,

                            )}
                                 data-contest-string={JSON.stringify(contest)}
                                 className={css(this.styles.contestSubmissionsWrapper)}>
                                {this.props.inEditor &&
                                <ContestSubmissions
                                    submissions={DUMMY_SUBMISSIONS}/>}
                            </div>

                        </Column>
                    </Row>
                </Container>

            </div>

        )
    }
}

module.exports = {
    ContestManager: frontloadConnect(frontload, frontLoadOptions)(ContestManager)
};
