import {StyleSheet} from "@convertly/css";
import UserOverrides from '../../../elements/style-functions/user-overrides';
import {generatePseudoMediaQuery} from '../../../elements/style-functions/pseudo-media-queries';
import {addValueToUserOverrides, createNewCssRules} from '../../../utils/edit-userOverrides';
import searchBarStyleObject from '../../../ecommerce-common-components/search-bar/searchBarStyleObject';

import _ from 'lodash';

const HeaderStyles2 = (global, data, headerPosition, inEditor) => {

  const searchBarStyles = searchBarStyleObject(global, data, inEditor);

  let modifiedColor,
    overlayBorderStyle = {},
    navLinksOverrides = {},
    navLinkAdditionalStyles = {},
    dropDownAdditionalStyles = {},
    dropdownOverrides = {},
    socialIconBGOverrides = {},
    callOutTextColorOverrides = {},
    iconBackground = {};

  let callOutUserOverrides = _.get(data, 'customHeaderMessage.userOverrides') ?
    UserOverrides(global, data.customHeaderMessage, inEditor) : {};


  let logoOverrides = _.get(data, 'logoOverrides.userOverrides') ?
    UserOverrides(global, data.logoOverrides, inEditor) :
    {};

  // Pulls background color from userOverrides
  let mobileBackgroundColor = {};
  if (data.userOverrides) {
    mobileBackgroundColor = createNewCssRules(data.userOverrides, 'background', ['background']);
    mobileBackgroundColor = createNewCssRules(data.userOverrides, 'backgroundColor', ['background']);
  }

  if (_.get(data, "menu.userOverrides")) {
    /** map through UserOverrides make fill match color **/
    let editedNavLinkOverrides = {
      userOverrides: addValueToUserOverrides(_.get(data, "menu.userOverrides"),
        "color",
        ["fill"])
    };

    navLinksOverrides = UserOverrides(global, editedNavLinkOverrides, inEditor);
    navLinkAdditionalStyles = createNewCssRules(navLinksOverrides, 'color', ['color', 'borderColor', 'fill'])
    iconBackground = createNewCssRules(navLinksOverrides, 'color', ['backgroundColor']);
    socialIconBGOverrides = createNewCssRules(navLinksOverrides, 'color', ['backgroundColor']);
    callOutTextColorOverrides = createNewCssRules(navLinksOverrides, 'color', ['color']);

  }

  if (_.get(data, "dropdown.UserOverrides")) {
    /** map through UserOverrides make fill match color **/
    let editedDropdownOverrides = {
      userOverrides: addValueToUserOverrides(_.get(data, "dropdown.userOverrides"),
        "color",
        ["fill"])
    };
    dropdownOverrides = UserOverrides(global, editedDropdownOverrides, inEditor);
    dropDownAdditionalStyles = createNewCssRules(dropdownOverrides, 'color', ['color', 'borderColor', 'fill'])
  }


  if (headerPosition === "overlayFixed" || headerPosition === "overlay") {
    overlayBorderStyle = {
      '.absolute': {
        borderColor: global.colors.light.white,
        color: global.colors.light.white,
        stroke: global.colors.light.white,
        fill: global.colors.light.white
      },
    };
  }


  if (headerPosition === "overlay") {
    modifiedColor = global.colors.light.white
  }
  else {
    modifiedColor = global.colors.dark.color1
  }


  const caret = {
    display: "inline-block",
    height: '17px',
    width: '17px',
    cursor: 'pointer',
    boxSizing: 'content-box',
    marginTop: '-6px',
    marginLeft: '5px',
    stroke: 'inherit',
    verticalAlign: 'sub',
    ...generatePseudoMediaQuery('@media( max-width:991px )',
      ['desktop', 'mobile', 'smallTablet', 'largeTablet'],
      {
        top: '16px',
        right: '-25px'
      },
      inEditor)
  };

  const navListItem = {
    display: 'inline-block',
    cursor: 'pointer',
    fontSize: '1.03em',
    stroke: 'inherit',
    position: 'relative',
    margin: "0 20px",
    ...generatePseudoMediaQuery(
      "@media (max-width: 991px)",
      ['smallTablet', 'mobile', 'largeTablet'],
      {
        padding: "0px",
        margin: '0px 20px'
      }, inEditor)
  };

  const dropdown = {
    listStyle: 'none',
    height: 'auto',
    width: 'fit-content'
  };
  const dropdownList = {
    display: 'none',
    position: 'absolute',
    top: '140%',
    right: '-22px',
    background: global.colors.light.white,
    boxSizing: 'content-box',
    listStyle: 'none',
    padding: '20px 20px 8px',
    minWidth: '270px',
    zIndex: '1',
    boxShadow: '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3)',
    fontSize: '14px',
    '.open': {
      display: 'block'
    },
    ...generatePseudoMediaQuery(
      '@media( max-width:991px )',
      ['smallTablet', 'largeTablet', 'mobile'],
      {
        boxShadow: 'none',
        position: 'static',
        background: 'transparent'
      }, inEditor),
    ...navLinksOverrides,
    ...dropdownOverrides,
  };

  const searchBarWrapperFocusStyles = {
    borderBottom: `2px solid ${ global.colors.accent.color2 }`,
    fill: global.colors.accent.color2,
    stroke: global.colors.accent.color2,
    'box-shadow': `0 0 0 0 ${ global.colors.dark.color3 }`,
  }

  return StyleSheet.create({

    searchBarWrapper: {
      display: 'inline-block',
      ...searchBarStyles,
      '.search-bar-results': {
        position: 'absolute',
        width: '100%',
        top: '-16px',
        height: '0',
        zIndex: '1',
        ...generatePseudoMediaQuery(
          '@media(max-width: 767px)',
          ['smallTablet', 'mobile'],
          {
            top: '-8px',
          },
          inEditor
        )
      },
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
          display: 'none',
        },
        inEditor
      ),
      '.search-bar-wrapper': {
        display: 'flex',
        borderBottom: `2px solid transparent`,
        'box-shadow': `0 1px 0 0 ${ global.colors.dark.color3 }`,
        padding: '0px 4px',
        width: '12.4vw',
        transition: 'all 0.3s linear',
        'align-items': 'center',
        ':focus-within': {
          ...searchBarWrapperFocusStyles
        },
        '.has-input': {
          ...searchBarWrapperFocusStyles
        }
      },
      '.mobile': {
        marginLeft: '4px',
        display: 'none',
        '.x-icon': {
          fontSize: '26px',
          cursor: 'pointer',
        },
        '.search-bar-container': {
          width: '100%',
          ...generatePseudoMediaQuery(
            '@media(max-width: 991px)', ['largeTablet', 'smallTablet', 'mobile'], {
              display: 'block'
            },
            inEditor
          ),
        },
        '.search-bar-wrapper': {
          '.static': {
            display: 'flex',
            border: `2px solid ${ global.colors.light.color3 }`,
            padding: '4px',
            width: '100%',
            'align-items': 'center',
            height: '107%',
            transition: 'all 0.3s ease',
            marginRight: '-2px',
            transform: 'translateY(0px)',
            'box-shadow': `0 0 0 0 ${ global.colors.dark.color3 }`,
            ':focus-within': {
              border: `2px solid ${ global.colors.accent.color2 }`,
              fill: global.colors.accent.color2,
              color: global.colors.accent.color2
            },
          }
        },
        '.search-bar-input': {
          border: 'none',
          padding: '0px 2px',
          height: '100%',
          width: '100%',
          backgroundColor: 'transparent',
          color: global.colors.dark.black,
          lineHeight: '22px',
          fontSize: '16px'
        },
        '.search-bar-product-items-list': {
          transition: 'all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s',
          position: 'absolute',
          width: '100%',
          color: global.colors.dark.black
        },
        '.search-bar-results-wrapper': {
          display: 'none',
          position: 'relative',
          width: '100%',
          left: '-16px',
          'z-index': '22',
          top: '8px',
          '.active': {
            display: 'block'
          }
        },
        '.search-bar-results': {
          position: 'absolute',
          width: '100%',
        },
        '.product-item': {
          display: 'block',
          padding: '4px 4px',
          border: `2px solid ${ global.colors.light.color3 }`,
          borderTop: 'none',
          listStyle: 'none',
          cursor: 'pointer',
          backgroundColor: global.colors.light.white,
          'text-overflow': 'ellipsis',
          'overflow': 'hidden',
          'white-space': 'nowrap',
          width: '100%',
          ':hover': {
            backgroundColor: global.colors.light.color2
          },
          '.no-hover': {
            ':hover': {
              backgroundColor: 'white',
              cursor: 'default'
            },
          }
        },
        ...generatePseudoMediaQuery(
          '@media(max-width: 991px)', ['smallTablet', 'mobile'], {
            display: 'block',
          },
          inEditor
        ),
      }
    },

    menuContainer: {
      borderBottom: "5px solid",
      borderColor: modifiedColor,
      borderRadius: '1px',
      fill: modifiedColor,
      color: modifiedColor,
      ...generatePseudoMediaQuery(
        '@media( max-width:991px)',
        ['smallTablet', 'largeTablet', 'mobile'],
        {
          padding: '10px'
        }, inEditor),
      height: '100%',
      width:"100%",
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      ...overlayBorderStyle
    },
    logo: {
      maxWidth:180,
      ...generatePseudoMediaQuery(
        '@media( max-width:991px)',
        ['smallTablet', 'largeTablet', 'mobile'],
        {
          marginTop: '10px'
        }, inEditor),
      ...logoOverrides

    },
    logoContainer: {
    },
    logoImg: {
      //maxHeight: '100%',
      width: 'auto',
      maxWidth:'100%',
      maxHeight: '100%',
      '.absolute': {
        display: 'block'
      },
      '.default': {
        display: 'block'
      }
    },
    menuLauncherIcon: {
      display: 'none',
      order: 2,
      float: 'right',
      width: 'auto',
      marginRight: "15px",
      marginTop: '17px',
      ...generatePseudoMediaQuery(
        '@media( max-width:991px )',
        ['largeTablet'],
        {
          display: "block"
        }, inEditor
      ),
      ...generatePseudoMediaQuery(
        '@media( max-width:768px )',
        ['mobile', 'smallTablet'],
        {
          display: "block",
          marginRight: '0'
        }, inEditor
      )
    },
    navLaunch: {
      zIndex: inEditor ? 20001 : 45,
      display: "none",
      paddingBottom: 0,
      overflow: "visible",
      background: "none",
      border: "none",
      outline: "none",
      ...generatePseudoMediaQuery(
        '@media( max-width:991px )',
        ['largeTablet', 'mobile', 'smallTablet'],
        {
          display: "block"
        }, inEditor
      )
    },
    iconBar: {
      backgroundColor: modifiedColor,
      width: "20px",
      height: "3px",
      marginBottom: "3px",
      position: "relative",
      display: "block",
      zIndex: inEditor ? 20001 : 45,
      transition: 'all 0.3s ease-in-out 0s',
      ':nth-child(3)': {
        width: "16px"
      },
      ".absolute": {
        backgroundColor: global.colors.light.white,

      },
      '.open': {
        ":nth-child(2)": {
          transform: "rotate(45deg)",
          top: "7px",
          background: global.colors.dark.color1,
          ...iconBackground

        },
        ":nth-child(4)": {
          transform: "rotate(-45deg)",
          top: "-5px",
          background: global.colors.dark.color1,
          ...iconBackground

        },
        ":nth-child(3)": {
          opacity: 0
        }
      },
      ...iconBackground
    },
    mainMenu: {
      zIndex: 45,
      float: 'right',
      height: '100%',
      display: 'inline-block',
      width: 'fit-content',
      maxWidth: '81%',
      ...generatePseudoMediaQuery(
        '@media( max-width:1065px )',
        ['desktop'],
        {
          marginTop: '5px'
        }, inEditor
      ),
      ...generatePseudoMediaQuery(
        '@media( max-width:991px )',
        ['largeTablet', 'smallTablet', 'mobile'],
        {
          marginTop: '-2px'
        }, inEditor
      ),
      '.open': {
        display: 'block',
        visibility: 'visible',
        left: '0',
      },
    },
    headerNav: {
      display: 'flex',
      alignContent: 'center',
      height: '100%'
    },

    navListItemWrapper: {
      // display: 'flex',
      // 'flex-direction': 'column',
    },

    navList: {
      float: 'right',
      listStyle: 'none',
      paddingLeft: '15px',
      height: 'auto',
      width: 'fit-content',
      display: 'flex',
      alignItems: 'center',
      zIndex: '22',
      ...generatePseudoMediaQuery(
        '@media ( max-width:991px )',
        ['largeTablet', 'smallTablet', 'mobile'],
        {
          position: 'absolute',
          top: '-640%',
          right: '0',
          width: '100%',
          background: global.colors.light.white,
          transition: 'position 1s ease',
          flexDirection: 'column',
          alignItems: 'right',
          ...mobileBackgroundColor
        }, inEditor
      ),
      '.open': {
        top: 0,
        zIndex: inEditor ? 2000 : 22,
        minHeight: '80px',
        ...generatePseudoMediaQuery(
          '@media ( max-width:991px )',
          ['largeTablet', 'smallTablet', 'mobile'],
          {
            top: '0',
            borderBottom: '3px solid',
            borderColor: global.colors.dark.color1,
          }, inEditor
        ),
      }
    },
    cartContainer: {
      float: 'left',
      display: 'inline-block',
      cursor: 'pointer',
      fontSize: '18px',
      stroke: 'inherit',
      position: 'relative',
      margin: "0 10px",
      marginTop: '-5px',
      ...generatePseudoMediaQuery(
        "@media (max-width: 991px)",
        ['smallTablet', 'mobile', 'largeTablet'],
        {
          padding: "0px",
        }, inEditor)
    },
    pullUp: {
      marginTop: '-5px'
    },
    navListItem: {
      width: "fit-content",
      borderBottom: "3px solid transparent",

      ":hover": {
        borderBottom: "3px solid",
        transition: "100ms ease"
      },
      ".active": {
        borderBottom: "3px solid",
        transition: "100ms ease",
        borderColor: 'inherit'
      },
      ...generatePseudoMediaQuery(
        '@media ( max-width:991px )',
        ['largeTablet', 'smallTablet', 'mobile'],
        {
          display: "block",
          color: global.colors.dark.color1,
          paddingTop: '4px',
          paddingBottom: '4px',
          border: 'none'
        }, inEditor
      ),
      ...navListItem,
      ...navLinksOverrides
    },

    contactNavListItem: {
      ...navListItem,
      ...generatePseudoMediaQuery(
        '@media ( max-width:991px )',
        ['largeTablet', 'smallTablet', 'mobile'],
        {
          color: global.colors.dark.color1,
          marginTop: '10px',
        }, inEditor
      ),
      ...navLinksOverrides
    },
    caret: {
      ...caret,
      ...generatePseudoMediaQuery(
        '@media ( max-width:991px )',
        ['largeTablet', 'smallTablet', 'mobile'],
        {
          stroke: global.colors.dark.color1,
          fill: global.colors.dark.color1,
        }, inEditor
      ),
    },
    contactCaret: {
      ...caret,
      ...generatePseudoMediaQuery(
        '@media ( max-width:991px )',
        ['largeTablet', 'smallTablet', 'mobile'],
        {
          visibility: 'hidden'
        }, inEditor
      ),
    },
    dropdown: {
      ...dropdown,
    },
    contactDropdown: {
      ...dropdown
    },
    dropdownList: {
      ...dropdownList,
    },
    contactDropdownList: {
      ...dropdownList,

    },
    dropDownItem: {
      display: 'none',
      position: 'absolute',
      top: '140%',
      right: '0',
      width: "200px",
      background: '#fff',
      listStyle: 'none',
      padding: '10px',
      marginBottom: '20px',
      '.open': {
        display: 'block'
      }
    },
    contactListHeader: {
      display: 'block',
      width: '50%',
      borderBottom: '1px solid',
      paddingBottom: '12px',
      color: global.colors.dark.color1,
      borderColor: global.colors.dark.color1,
      textTransform: 'uppercase',
      fontSize: '18px',
      fontWeight: 'bold',
      ...generatePseudoMediaQuery(
        '@media(max-width:991px)',
        ['mobile', 'smallTablet', 'largeTablet'],
        {
          display: 'none'
        },
        inEditor
      ),
      ...navLinkAdditionalStyles,
      ...dropDownAdditionalStyles
    },
    arrowUp: {
      width: 0,
      height: 0,
      position: 'absolute',
      top: '-10px',
      right: '20px',
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderBottom: '10px solid',
      borderBottomColor: global.colors.light.white,
      ...generatePseudoMediaQuery(
        '@media ( min-width:991px )',
        ['desktop'],
        {
          borderBottomColor: _.get(data, 'dropdown.userOverrides.backgroundColor')
            || _.get(data, 'dropdown.userOverrides.background')
            || global.colors.light.white,
        }, inEditor
      )
    },
    downNavItem: {
      margin: '20px 0',
      ':hover': {
        opacity: '.8'
      },
      ...generatePseudoMediaQuery(
        '@media ( max-width:991px )',
        ['largeTablet', 'smallTablet', 'mobile'],
        {
          margin: '0',
          width: 'fit-content'
        }, inEditor
      ),
      ...navLinksOverrides,
      ...dropdownOverrides
    },
    dropdownIconContainer: {
      fill: dropdownOverrides.color || navLinksOverrides.color || global.colors.dark.color1,
      display: 'inline-block',
      width: '10%',
      verticalAlign: 'middle',
      ...navLinkAdditionalStyles,
      ...dropDownAdditionalStyles
    },
    menuIcon: {
      width: '18px',
      height: '18px',
    },
    contactLinkContainer: {
      display: 'inline-block',
      marginLeft: '5px',
      width: '85%',
      verticalAlign: 'middle'
    },
    contactDownNavText: {
      fontSize: '12px',
      lineHeight: '12px',
      padding: '10px 12px 10px 5px',
      ...navLinksOverrides

    },
    dropDownlink: {
      fontSize: '15px',
      ...navLinksOverrides
    },
    /** Cart styles **/
    desktopCartInfernoComponent: {
      ...generatePseudoMediaQuery(
        "@media(min-width:991px)",
        ['largeTablet', 'smallTablet', 'mobile'],
        {
          ":hover ": {
            ".cartDropDown": {
              display: 'block'
            },
            ".casper": {
              display: 'block'
            }
          }
        },
        inEditor
      ),
      ".casper": {
        position: 'absolute',
        top: '0',
        right: '0',
        height: '60px',
        width: '425px',
        background: 'transparent',
        display: 'none'
      },
      ".headerNavLink": {
        ".active": {
          borderBottom: "3px solid",
          transition: "100ms ease",
          borderColor: 'inherit'
        },
        ...generatePseudoMediaQuery(
          '@media ( max-width:991px )',
          ['largeTablet', 'smallTablet', 'mobile'],
          {
            display: "block",
            color: global.colors.dark.color1,
            margin: 0,
          }, inEditor
        )
      },

      ".cartIcon": {
        height: '24px',
        marginTop: '-5px',
        verticalAlign: 'middle',

      },
      ".cartLink": {
        ...navLinkAdditionalStyles
      },
      ".cartLinkActive": {
        color: global.colors.accent.color1,
        fill: global.colors.accent.color1
      },
      ".cartDropDown": {
        display: 'none',
        cursor: 'default',
        position: 'absolute',
        top: '0',
        right: '-1px',
        width: '425px',
        height: "460px",
        background: 'white',
        zIndex: '50',
        paddingRight: "0",
        border: global.border
      },
      ".cartContainer": {
        height: '350px',
        overflowY: 'auto',
        width: '100%',
        borderBottom: global.border
      },
      ".cartContainerFooter": {
        marginTop: '20px',
        textAlign: 'center'
      },
      ".checkoutLink": {
        display: 'block',
        ...global.button1
      },
      ".viewCartLink": {
        display: 'block',
        color: global.colors.dark.color1
      },
      ".checkRightPanel": {
        padding: '30px',
        alignItems: "stretch",
        backgroundColor: "transparent",
        color: global.colors.dark.color1,
        display: "flex",
        flex: "1",
        flexDirection: "row",
        fontSize: "14px",
        justifyContent: "center",
        lineHeight: "20px",
        paddingTop: "30px"
      },
      ".cart-item-container": {
        padding: '15px'
      },
      ".change-message-bg": {
        backgroundColor: '#e3f2fd'
      },
      ".change-message-container": {
        borderTop: `1px solid ${global.colors.light.color2}`,
        paddingTop: '10px',
        fontFamily: global.fontFamily.secondaryFont
      },
      ".change-message-text": {
        textTransform: 'none',
        fontSize: '14px',
        color: global.colors.dark.color2
      },
      ".change-message-action": {
        display: 'block'
      },
      ".change-message-link": {
        fontSize: '16px',
        display: 'inline-block',
        margin: "5px 10px 0 0",
        cursor: 'pointer'
      },

      ".chkRightPanelContainer": {
        color: global.colors.dark.color1,
        fontSize: "14px",
        lineHeight: "20px",
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "100%",
        width: "100%"
      },

      ".chkTitle1_mobileTitle1": {
        borderBottom: "2px none #667785",
        color: global.colors.dark.color1,
        display: "none",
        flex: "0 auto",
        flexDirection: "row",
        fontSize: "32px",
        fontWeight: "400",
        lineHeight: "36px",
        marginBottom: "10px",
        marginTop: "0px",
        paddingBottom: "20px",
        width: "100%"
      },

      ".mobileUnderline_titleUnderline": {
        backgroundColor: global.colors.dark.color1,
        borderRadius: "5px",
        color: global.colors.dark.color1,
        display: "none",
        fontSize: "14px",
        height: "3px",
        lineHeight: "20px",
        marginBottom: "20px",
        width: "45%"
      },

      ".chkGridheadercontainer": {
        color: global.colors.dark.color1,
        display: "flex",
        fontSize: "14px",
        justifyContent: "flex-end",
        lineHeight: "20px",
        marginBottom: "15px"
      },

      ".chkProdgridimgcontainer": {
        alignItems: "flex-start",
        color: global.colors.dark.color1,
        display: "flex",
        flexDirection: "column",
        fontSize: "14px",
        justifyContent: "center",
        lineHeight: "20px",
        maxWidth: "18%",
        textAlign: "center",
        width: "20%"
      },

      ".chkGridprice_th": {
        alignItems: "flex-start",
        color: global.colors.dark.color1,
        display: "flex",
        flex: "0 auto",
        fontFamily: global.fontFamily.secondaryFont,
        fontSize: "11px",
        justifyContent: "flex-start",
        lineHeight: "20px",
        paddingLeft: "0px",
        width: "125px"
      },

      ".chkGridqty_chkUnitprice_th": {
        alignItems: "flex-start",
        color: global.colors.dark.color1,
        display: "flex",
        flex: "1",
        fontFamily: global.fontFamily.secondaryFont,
        fontSize: "11px",
        justifyContent: "center",
        lineHeight: "20px",
        textAlign: "center"
      },

      ".chkGridsubtotal_th": {
        color: global.colors.dark.color1,
        display: "flex",
        flex: "1",
        fontFamily: global.fontFamily.secondaryFont,
        fontSize: "11px",
        justifyContent: "flex-end",
        lineHeight: "20px",
        textAlign: "center"
      },

      ".chkProdgrid": {
        color: global.colors.dark.color1,
        display: "flex",
        fontSize: "14px",
        lineHeight: "20px",
        marginBottom: "20px",
        position: "relative",
      },

      ".chkProdgridimg": {
        border: "0",
        color: global.colors.dark.color1,
        display: "inline-block",
        fontSize: "14px",
        height: "60px",
        width: "60px",
        lineHeight: "20px",
        maxWidth: "100%",
        textAlign: "center",
        verticalAlign: "middle",
        marginBottom: '10px'

      },

      ".chkRemovelink": {
        backgroundColor: "transparent",
        color: "#b4bbc3",
        fontSize: "10px",
        lineHeight: "20px",
        maxWidth: "100%",
        textAlign: "center",
        paddingLeft: '7px'
      },

      ".divBlock_2": {
        color: global.colors.dark.color1,
        display: "flex",
        flexDirection: "column",
        fontSize: "14px",
        lineHeight: "20px",
        width: "225px"
      },

      ".chkGridprice": {
        alignItems: "flex-start",
        color: global.colors.dark.color1,
        display: "flex",
        flex: "0 auto",
        fontSize: "12px",
        justifyContent: "flex-start",
        lineHeight: "20px",
        paddingLeft: "0px",
        width: "225px"
      },
      ".subTitle": {
        fontSize: '10px',
        color: global.colors.dark.color2
      },
      ".chkGridprice_chkGridpricenote": {
        alignItems: "flex-start",
        color: "#118bef",
        display: "flex",
        flex: "0 auto",
        fontSize: "12px",
        justifyContent: "flex-start",
        lineHeight: "16px",
        paddingLeft: "0px",
        width: "auto"
      },

      ".chkGridqty_chkUnitprice_strikethrough": {
        alignItems: "flex-start",
        color: "#667785",
        display: "flex",
        flex: "0 auto",
        fontSize: "12px",
        justifyContent: "flex-start",
        lineHeight: "20px",
        textAlign: "center",
        textDecoration: "line-through"
      },

      ".actualprice_chkGridqty_chkUnitprice": {
        alignItems: "flex-start",
        color: "#118bef",
        display: "flex",
        flex: "1",
        fontSize: "14px",
        justifyContent: "flex-start",
        lineHeight: "20px",
        textAlign: "center"
      },

      ".chkGridqty": {
        alignItems: "flex-start",
        color: "#667785",
        display: "flex",
        flex: "1",
        fontSize: "14px",
        justifyContent: "flex-end",
        lineHeight: "20px",
        textAlign: "center"
      },

      ".counterIndicator": {
        backgroundColor: "transparent",
        color: "#667785",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "4px 9px",
        textAlign: "center",
        textDecoration: "none"
      },

      ".counternumber": {
        border: global.border,
        color: "#667785",
        flex: "0 auto",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "4px 9px 3px",
        textAlign: "center"
      },

      ".chkGridsubtotal": {
        color: global.colors.dark.color2,
        display: "flex",
        flex: "1",
        fontSize: "14px",
        lineHeight: "20px"
      },

      ".progresscontainer": {
        color: global.colors.dark.color1,
        fontSize: "14px",
        lineHeight: "20px",
        paddingTop: "10px",
        position: "relative"
      },

      ".truck": {
        border: "0",
        color: global.colors.dark.color1,
        display: "inline-block",
        fontSize: "14px",
        lineHeight: "20px",
        maxWidth: "55px",
        position: "absolute",
        right: "0px",
        top: "0px",
        verticalAlign: "middle"
      },

      ".progresstext": {
        borderBottomColor: "#000",
        borderBottomWidth: "3px",
        borderStyle: "none",
        color: global.colors.dark.color1,
        fontSize: "14px",
        lineHeight: "20px",
        paddingBottom: "10px",
        paddingRight: "80px"
      },

      ".progressline_titleUnderline": {
        backgroundColor: global.colors.dark.color1,
        borderRadius: "5px",
        color: global.colors.dark.color1,
        fontSize: "14px",
        height: "3px",
        lineHeight: "20px",
        marginBottom: "15px",
        width: "100%"
      },

      ".cartToggleContainer": {
        color: global.colors.dark.color1,
        display: "none",
        fontSize: "14px",
        lineHeight: "20px"
      },
      ".cartTitleSub": {
        color: global.colors.dark.color1,
        fontSize: "32px",
        fontWeight: "bold",
        lineHeight: "36px",
        marginBottom: "10px",
        marginTop: "20px"
      },

      ".toggleLinkblock_wInlineBlock": {
        backgroundColor: "transparent",
        color: global.colors.dark.color1,
        display: "inline-block",
        fontSize: "14px",
        lineHeight: "20px",
        maxWidth: "100%"
      },
      ".toggleCollapsed": {
        border: "0",
        color: global.colors.dark.color1,
        display: "inline-block",
        fontSize: "14px",
        lineHeight: "20px",
        maxWidth: "100%",
        verticalAlign: "middle"
      }
    },
    signInInferno: {
      display: 'inline-block',
      ".headerNavLink": {
        width: "fit-content",
        ...navListItem,
        ".active": {
          borderBottom: "3px solid",
          transition: "100ms ease",
          borderColor: 'inherit'
        },
        ":hover": {
          borderBottom: "3px solid",
          transition: "100ms ease",
          borderColor: 'inherit'
        },
        ...generatePseudoMediaQuery(
          '@media ( max-width:991px )',
          ['largeTablet', 'smallTablet', 'mobile'],
          {
            display: "block",
            color: global.colors.dark.color1,
            margin: '0 20px',
          }, inEditor
        ),
        ...navLinksOverrides
      },
    },
    pullLeft: {
      float: 'left',
      ...generatePseudoMediaQuery(
        '@media ( max-width:991px )',
        ['largeTablet', 'smallTablet', 'mobile'],
        {
          float: 'none',
          display: 'block'
        }, inEditor
      ),
    },
    noBorder: {
      borderBottom: '0px solid transparent',
      ":hover": {
        borderBottom: '3px solid transparent',
      }
    },
    header_top: {
      fontFamily: global.fontFamily.primaryFont,
      fontSize: "16px",
      padding: '6px 20px',
      display: 'block',
      borderBottom: global.border,
      height: '40px'
    },
    header_top_lft: {
      height: '100%',
      display: 'flex',
      paddingTop: "5px",
      alignItems: "center"
    },
    mobileSocialIcons: {
      display:'none',
      ...generatePseudoMediaQuery("@media (max-width: 991px)",
        ['largeTablet', 'smallTablet', 'mobile'], {
          display:'block',
          marginTop:'15px',
          marginLeft:'15px'
        }, inEditor),
    },
    socialIconContainer: {
      height: '20px',
      width: '20px',
      backgroundColor: global.colors.dark.color2,
      fill: global.colors.light.white,
      borderRadius: '50%',
      backgroundSize: '30px',
      ...socialIconBGOverrides
    },
    socialIconWrapper: {
      float: 'left',
      ...generatePseudoMediaQuery("@media (max-width: 991px)",
        ['largeTablet', 'smallTablet', 'mobile'], {
          display:'none'
        }, inEditor),
    },
    connect_us: {
      display: 'inline-flex',
      alignContent: 'center',
      float: 'left',
      color: global.colors.dark.color3,
      fontFamily: global.fontFamily.primaryFont,
      fontSize: "16px",
      lineHeight: "16px",
      margin: "0",
      padding: "0",
      ...generatePseudoMediaQuery("@media (max-width: 991px)",
        ['largeTablet', 'smallTablet', 'mobile'],
        {
          width: '100%',
          justifyContent: 'center',
          fontSize: '12px',
          lineHeight: '12px'
        }, inEditor),
    },
    socialIcons: {
      paddingTop: "5px"
    },
    socialIcon: {
      height: '100%',
      width: '100%',
      padding: '3px',
      fill:'inherit',
      verticalAlign:'sub'
    },
    customCallOut: {
      fontSize: '14px',
      color: global.colors.dark.color1,
      ...callOutTextColorOverrides,
      ...callOutUserOverrides
    },


  });

};

export default HeaderStyles2
