import React from 'react';
import Component from '../../../rootComponent';
import { css } from "@convertly/css";

//Styles
import socialIconStyles from './social-icon-styles';

//Attributes
import Types from '../../../attribute-types';

//SVG
import SVG from '../../../svg/index'

export default class
SocialIcon2 extends Component {

    constructor(props) {

        super(props);

    }

    render() {

        this.styles = socialIconStyles(this.props.global, this.props.data);

        let renderedSocialIcon;

        if ( this.props.data.type === "facebook" ) {
            renderedSocialIcon =
                    <a href={ this.props.data.url }
                       target={ this.props.data.target ? "_blank" : "_self" }
                       className={ css( this.styles.socialIconLink, this.props.socialIconLinkClass ) }
                       data-event="convertly facebook"
                       data-action="click" >
                        <SVG.FacebookNoBackground
                            className={ this.props.styles }
                        />
                    </a>
        }

        if ( this.props.data.type === "twitter" ) {

            renderedSocialIcon =
                    <a href={ this.props.data.url }
                       target={ this.props.data.target }
                       className={ css( this.styles.socialIconLink, this.props.socialIconLinkClass ) }
                       data-event="convertly twitter"
                       data-action="click" >
                        <SVG.TwitterNoBackground
                            className={ this.props.styles }
                        />
                    </a>
        }

        if ( this.props.data.type === "pintrest" ) {
            renderedSocialIcon =
                    <a href={ this.props.data.url }
                       target={ this.props.data.target }
                       className={ css(this.styles.socialIconLink, this.props.socialIconLinkClass ) }
                       data-event="convertly pinterest"
                       data-action="click" >
                        <SVG.PintrestNoBackground
                            className={ this.props.styles }
                        />
                    </a>
        }

        if ( this.props.data.type === "instagram" ) {
            renderedSocialIcon =
                    <a href={ this.props.data.url }
                       target={ this.props.data.target }
                       className={ css( this.styles.socialIconLink, this.props.socialIconLinkClass ) }
                       data-event="convertly instagram"
                       data-action="click" >
                        <SVG.InstagramNoBackground
                            className={ this.props.styles }
                        />
                    </a>

        }

        if ( this.props.data.type === "linkedin" ) {

            renderedSocialIcon =
                    <a href={ this.props.data.url }
                       target={ this.props.data.target }
                       className={ css( this.styles.socialIconLink, this.props.socialIconLinkClass ) }
                       data-event="convertly linkedin"
                       data-action="click" >
                        <SVG.LinkedInNoBackground
                            className={ this.props.styles }
                        />
                    </a>
        }

        if ( this.props.data.type === "youtube" ) {
            renderedSocialIcon =
                    <a href={ this.props.data.url }
                       target={ this.props.data.target }
                       className={ css( this.styles.socialIconLink, this.props.socialIconLinkClass ) }
                       data-event="convertly youtube"
                       data-action="click" >
                        <SVG.YoutubeNoBackground
                            className={ this.props.styles }
                        />
                    </a>
        }

        return (
            <li className={ css(this.styles.socialIconItem) } itemProp="url">
                { renderedSocialIcon }
            </li>
        )
    }
}

