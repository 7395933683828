import { StyleSheet } from "@convertly/css";
import UserOverrides from "../../elements/style-functions/user-overrides";
import styleContainerFn from "../util/styleContainer";

export const LinkWrapperStyles = ({ global, data, inEditor }) => {
  const styleContainer = styleContainerFn({ inEditor });

  const userOverrides = new UserOverrides(global, data, inEditor);

  const enableDefaultStyles = data.enableDefaultStyles === true ? styleContainer : {};

  return StyleSheet.create({
    linkWrapper: {
      ...enableDefaultStyles,
      ...userOverrides
    }
  });
};
