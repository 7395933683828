import React, { Component } from "react";
import styled from "styled-components";
import { EditMenu } from "./EditMenu";

const Wrapper = styled.div`
  display: flex;
  line-height: 2em !important;
`;

const Arrow = styled.i`
  cursor: pointer;
  position: relative;
  top: 3px;
`;

const DropdownArrow = ({ onClick }) => (
  <Arrow className={"material-icons"} onClick={onClick}>
    arrow_drop_down
  </Arrow>
);

/**
 * Renders dropdown widget that toggles the Pages Menu
 */
export class PagePicker extends Component {
  render() {
    const { currentPageName, openMenu, page } = this.props;

    return (
      <Wrapper>
        PAGES: {currentPageName}
        <DropdownArrow onClick={openMenu} />
        {this.props.showNavigationMenu && (
          <EditMenu
            page={page}
            deletePage={this.props.deletePage}
            site={this.props.site}
            showNavigationMenu={this.props.showNavigationMenu}
          />
        )}
      </Wrapper>
    );
  }
}
