import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PagePicker } from "../PagePicker";
import MobileIcon from "components/icons/mobile(dark)";
import TabletPortraitIcon from "components/icons/tablet(portrait)";
import TabletLandscapeIcon from "components/icons/tablet(landscape)";
import DesktopIcon from "components/icons/desktop";
import Confetti from "react-dom-confetti";
import OptionsCog from "containers/Editor/components/editor/optionsCog";
import { css as ConvertlyCSS } from "@convertly/css";
import styles from "components/StoreFront/css-storefront-styles";
import { Button } from "components/Button/Button";
import Toggle from "containers/Editor/components/editor/toggle";
import { is } from "containers/Authentication/PermissionsCheck";

const StyledLink = styled.a`
  background: linear-gradient(90deg, rgb(139, 217, 178) 0%, rgb(69, 170, 145) 100%);
  color: #fff;
  justify-content: center;
  line-height: 24px;
  margin-left: 20px;
`;

const LinkButton = ({ children, ...props }) => (
  <StyledLink {...props} className={ConvertlyCSS(styles.pillButton)}>
    {children}
  </StyledLink>
);

const ViewportSwitcher = ({ css, onClick }) => (
  <div style={{ width: "100%" }}>
    <span
      onClick={e => {
        onClick(e, "");
      }}
    >
      <DesktopIcon style={css.icons} />
    </span>
    <span
      onClick={e => {
        onClick(e, "smallTablet");
      }}
    >
      <TabletPortraitIcon style={css.icons} />
    </span>
    <span
      onClick={e => {
        onClick(e, "largeTablet");
      }}
    >
      <TabletLandscapeIcon style={css.icons} />
    </span>
    <span
      onClick={e => {
        onClick(e, "mobile");
      }}
    >
      <MobileIcon style={css.icons} />
    </span>
  </div>
);

const confettiConfig = {
  angle: 219,
  spread: 80,
  startVelocity: 32,
  elementCount: 130,
  decay: 0.94,
};

export class EditorMenu extends React.Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);

    this.state = {
      fireConfetti: this.props.saving || false,
    };
    this.toggleEditor = this.toggleEditor.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ fireConfetti: nextProps.saving });
  }
  toggleEditor(e) {
    this.props.toggleEditor(!this.props.isUsingNewEditor);
  }
  clearEditorPanel = async e => {
    await this.props.clearEditorPanel();
  };

  /**
   *
   * @returns {*}
   */
  render() {
    const { currentSite, page, saving, preview, savingTheme } = this.props;

    if (!currentSite) {
      return null;
    }

    const currentPageName = page ? page.get("name") : "";

    const css = {
      container: {
        boxShadow: "0 0 4px 0 rgba(0,0,0,0.25)",
        zIndex: 2000,
        width: "100%",
        position: "fixed",
        backgroundColor: "white",
        height: "3em",
        top: "0em",
        display: "flex",
        alignItems: "center",
      },
      menu: {
        verticalAlign: "middle",
        marginRight: "0.5em",
        backgroundColor: "black",
        color: "white",
        position: "relative",
        fontSize: "3em",
        cursor: "pointer",
      },

      chat: {
        marginLeft: "1.2em",
      },
      img: {
        marginLeft: "1em",
        height: "2em",
        position: "relative",
      },
      editMenu: {
        display: "flex",
        alignItems: "center",
        marginLeft: "2em",
        width: "100%",
      },
      publish: {
        display: "inline-block",
        position: "relative",
        height: "24px",
        width: "74px",
        //backgroundColor: 'rgba(78,177,77,0.5)',
        backgroundColor: "#90ee90",
        borderRadius: "5px",
        float: "right",
        outline: "none",
        opacity: this.props.saving ? "0.5" : "1",
      },
      publishIcon: {
        fontSize: "16px",
        marginRight: "3px",
      },
      publishWrapper: {
        alignSelf: "center",
        display: "flex",
        flexDirection: "row-reverse",
        width: "20%",
        marginRight: "10px",
      },
      icons: {
        margin: "5px",
        cursor: "pointer",
      },
    };

    const options = [
      /*{
				optionsItemTitle: "Site Settings",
				optionsCallback: this.props.popSiteSettings,
				optionsIndex: this.props.index
			},*/
      {
        optionsItemTitle: "Edit Design",
        optionsCallback: this.props.editDesign,
        optionsIndex: this.props.index,
      },
      {
        optionsItemTitle: "Save Design",
        optionsCallback: this.props.saveDesign,
        optionsIndex: this.props.index,
      },
    ];

    return (
      <div style={css.editMenu}>
        <div style={{ width: "80%", display: "flex" }}>
          <PagePicker
            {...this.props}
            currentPageName={currentPageName}
            openMenu={this.props.openMenu}
            site={currentSite}
          />

          <div
            style={{
              marginLeft: "20px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
          >
            <OptionsCog spin={savingTheme} options={options} top="0px" left="0px" />
          </div>
          {is("developer") && (
            <div style={{ marginLeft: 20, display: "flex", alignContent: "center" }}>
              Shiny New Editor:
              <Toggle on={this.props.isUsingNewEditor} callback={this.toggleEditor} />
            </div>
          )}
        </div>
        <ViewportSwitcher css={css} onClick={this.props.handleViewport} />

        <div style={css.publishWrapper}>
          {currentSite.get("domain") && (
            <Button
              no-float
              width={"auto"}
              processing={saving}
              type="submit"
              label={`Publish`}
              small
              action={!saving && this.props.savePage.bind(this, true)}
            />
          )}

          <Button
            no-float
            width={"auto"}
            processing={saving}
            type="submit"
            label={`Save`}
            data-action={"save"}
            small
            onMouseDownAction={this.clearEditorPanel}
            action={!saving && this.props.savePage.bind(this, false)}
          />

          <LinkButton target="_blank" href={preview}>
            Preview
          </LinkButton>

          <Confetti active={this.state.fireConfetti} config={confettiConfig} />
        </div>
      </div>
    );
  }
}

EditorMenu.defaultProps = {
  editDesign: () => console.log("default editDesign"),
  popSiteSettings: () => console.log("default popSiteSettings"),
  saveDesign: () => console.log("default saveDesign"),
  handleViewport: () => console.log("default handleViewport"),
  undo: () => console.log("default undo"),
  openMenu: () => console.log("default openMenu"),
  savePage: () => console.log("default savePage"),
  saving: false,
};

EditorMenu.propTypes = {
  editDesign: PropTypes.func,
  popSiteSettings: PropTypes.func,
  saveDesign: PropTypes.func,
  handleViewport: PropTypes.func,
  undo: PropTypes.func,
  openMenu: PropTypes.func,
  savePage: PropTypes.func,
  saving: PropTypes.bool,
};
EditorMenu.contextTypes = {
  store: PropTypes.object,
};
