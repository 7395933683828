export function sortFooterElements( sortId = 6, logo, copyText, navLinks ) {

    let elementsArray = [ logo, copyText, navLinks ];

    switch(sortId) {
        case 1:
            elementsArray = [  copyText, navLinks, logo ];
            break;
        case 2:
            elementsArray = [  copyText, navLinks,  logo  ];
            break;
        case 3:
            elementsArray = [  navLinks, logo, copyText ];
            break;
        case 4:
            elementsArray = [  navLinks, copyText, logo  ];
            break;
        case 5:
            elementsArray = [  logo, navLinks, copyText   ];
            break;
        default:
            elementsArray = [  logo, copyText, navLinks   ];
            break;

    }
    return elementsArray

}