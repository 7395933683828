import {StyleSheet} from "@convertly/css";
import UserOverrides from '../../../elements/style-functions/user-overrides';
import { generatePseudoMediaQuery } from '../../../elements/style-functions/pseudo-media-queries'
import MenuItemModalStyles from '../components/menu-item-with-modal/inferno-menu-item-modal-styles';


const restaurantMenuStyles = (global, data, inEditor) => {

    const userOverrides = UserOverrides(global, data);

    const menuItemModal = MenuItemModalStyles(global, data);

    return StyleSheet.create({

        customRow: {
            display:'flex',
            ...generatePseudoMediaQuery(
                "@media(max-width:991px)",
                ['largeTablet', 'smallTablet', 'mobile'],
                {
                    display:'block'
                },
                inEditor
            ),
            ...generatePseudoMediaQuery(
                "@media (max-width: 768px)",
                ['smallTablet', 'mobile'],
                {
                    textAlign:'center'
                },
                inEditor
            ),

        },
        infernoModal: {
            ...menuItemModal
        }

    });

};

export default restaurantMenuStyles;
