import { registerAlert } from "containers/App/actions";

import { store } from "app/app";

const alert = async (message, args = {}) => {
  return await store.dispatch(
    registerAlert({
      type: "alert",
      message,
      ...args
    })
  );
};

const confirm = async (message, args = {}) => {
  return await store.dispatch(
    registerAlert({
      type: "confirm",
      message,
      ...args
    })
  );
};

const prompt = async (message, args = {}) => {
  return await store.dispatch(
    registerAlert({
      type: "prompt",
      message,
      ...args
    })
  );
};

export const Alerts = {
  alert,
  confirm,
  prompt
};
