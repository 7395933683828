export function generatePseudoMediaQuery(mediaQuery, editorViewPorts, styles, inEditor = false, userOverrides) {

    const styleObject = {};

    if(inEditor !== true && inEditor !== false) {
      try {
        throw new Error('inEditor not defined.');
      }
      catch(err) {
        console.error(err.stack);
      }
    }

    //If in the editor, create the Pseudo media queries
    if (inEditor) {
        if(editorViewPorts) {
            editorViewPorts.map((size) => {
                let prevStyles = {};
                if(userOverrides) {
                    let string = `~#editorPageWrapper[data-pagewidth='${size}']`
                    //console.log("YELLOW", `~#editorPageWrapper[data-pagewidth='${size}']`);
                    //console.log('YELLOW', string, userOverrides);
                    prevStyles = styleObject[`~#editorPageWrapper[data-pagewidth='${size}']`] || {};
                }
                styleObject[`~#editorPageWrapper[data-pagewidth='${size}']`] = {...styles, ...prevStyles}
            });
        }
    }

    styleObject[mediaQuery] = {...styles};

    return styleObject;
}

export function buildEditorMediaQueries(userOverrides) {

    //Vars
    const PseudoMediaQueries = {},
          lgSelector = `~#editorPageWrapper[data-pagewidth='largeTablet']`,
          mdSelector = `~#editorPageWrapper[data-pagewidth='smallTablet']`,
          smSelector = `~#editorPageWrapper[data-pagewidth='mobile']`;

    PseudoMediaQueries[lgSelector] = {};
    PseudoMediaQueries[mdSelector] = {};
    PseudoMediaQueries[smSelector] = {};

    let mediaQueries = [];


    //Gets viewport for editor
    function getVeiwportSize(mediaQuery) {
        let viewPx = mediaQuery.replace(/\D/g, "");
        viewPx = parseFloat(viewPx);


        if (viewPx <= 580) {
            return 'mobile'
        }
        else if (viewPx >= 580 && viewPx <= 768) {
            return 'smallTablet'

        }
        else if (viewPx > 768 && viewPx <= 991) {
            return 'largeTablet'
        }
        else {
            return 'null'
        }

    }

    function addStylesToMediaQueries( size, style ) {


        switch(size) {
            case 'mobile':
                PseudoMediaQueries[smSelector] = {
                    ...PseudoMediaQueries[smSelector],
                    ...style
                };
                break;
            case 'smallTablet':
                PseudoMediaQueries[mdSelector] = {
                    ...PseudoMediaQueries[mdSelector],
                    ...style
                };
                PseudoMediaQueries[smSelector] = {
                    ...style,
                    ...PseudoMediaQueries[smSelector]

                };
                break;
            case 'largeTablet':
                PseudoMediaQueries[lgSelector] = {
                    ...PseudoMediaQueries[lgSelector],
                    ...style
                };
                PseudoMediaQueries[mdSelector] = {
                    ...style,
                    ...PseudoMediaQueries[mdSelector],
                };
                PseudoMediaQueries[smSelector] = {
                    ...style,
                    ...PseudoMediaQueries[smSelector],
                };
        }

    }

    // Ensures there are no empty pseudo media queries so they don't override previous media queries
    function clearEmptyObjects(obj) {
      for (let key in obj) {
        if (!obj[key] || typeof obj[key] !== "object") {
          continue;
        }

        if (Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      }
    }


    //Pull Media Queries out of user Overrides
    Object.keys(userOverrides).map( ( key ) => {
        if (key.indexOf('@media') !== -1) {

            let viewPortSize = getVeiwportSize(key);
            addStylesToMediaQueries( viewPortSize, userOverrides[key] );

        }
    });

    clearEmptyObjects(PseudoMediaQueries);

    let newUserOverrides = {
        ...userOverrides,
        ...PseudoMediaQueries
    };

    return newUserOverrides

}
