/*global Inferno, $, React */
/** Color Picker Product Variant Widget **/
import React from 'react';

const Script = () => {

    return (props) => {
        let currentWidth = 0;
        let maxWidth = (props.maxWidth * 1.7);
        let outOfStockItems = new Set(props.outOfStockVariations);
        let parsedSlug = props.parsedSlug;

        return (
            <div
                id={`${props.rootClass} bubble-wrapper`}
                className={`${props.rootClass} bubble-wrapper${ !props.inPDP ? ' pg-styles' : '' }`}>

                {
                    props.data.values.map((variant, index) => {

                        if (variant.name) {
                            currentWidth = currentWidth + (variant.name.length * 16);
                        }

                        let outOfStock = outOfStockItems.has(variant.name);
                        const isSelectedVariant = props.data.activeValue && props.data.activeValue.name === variant.name;

                        if (props.noMaxWidth || currentWidth <= maxWidth) {
                            let bubbleContainerElement = (
                                <div
                                    key={ `bubble-container-${ index }` } 
                                    className={
                                        `${props.rootClass} bubble-container ${ isSelectedVariant ? 'active' : '' } ${ outOfStock ? 'disabled' : '' }${ !props.inPDP ? ' pg-styles' : '' }`
                                    }
                                    onClick={props.updateVariationTypeMeta}
                                    data-outofstock={outOfStock}
                                    data-id={props.productId}
                                    data-activevalue={JSON.stringify(variant)}
                                    data-type={props.data.name}>
                                    {variant.name}
                                </div>
                            );

                            return bubbleContainerElement;
                        }
                        else {
                            return null
                        }
                    })
                }
                {!props.noMaxWidth && currentWidth >= (maxWidth * 2) &&
                <a href={props.selectedProductVariants.length > 0 ?
                    `${ props.slug }?${ JSON.stringify(props.selectedProductVariants) }` :
                    `${ props.slug }`}
                   className={`${props.rootClass} bubble-container more-button`}>
                    <div className={`${props.rootClass} bubble-value`}>
                        More <span className={`${props.rootClass} more-caret`}>></span>
                    </div>
                </a>
                }
            </div>
        );
    }
};

export default Script;
