
/*global Inferno, $, onYouTubeIframeAPIReady, YT */
/* eslint-disable */

import { include } from '@convertly/thor';

const Scripts = () => {

	const Dispatcher = include( '../../../utils/dispatcher', 'Dispatcher' );
	const isIOSFn = include( '../../../utils/is-ios', 'isIOSFn' );

    return ( id, videoUrl, videoType, events, autoPlay = false ) => {

        const root = document.getElementById ( id );
        const dispatch = Dispatcher( id );
        const onIOS = isIOSFn()();

        dispatch.attach( events );

        const video_id = videoUrl.replace( 'https://www.youtube.com/embed/', '' );

		function registerYT() {


            const $videoDivs = $('[data-youtubeid]');


            window[`player_${video_id}`] = new YT.Player( `${video_id}_video`, {
                height: '100%',
                width: '100%',
                videoId: video_id,
                autoPlay: true,
                events: {
                    'onReady': PlayerOnReady,
                    'onStateChange': PlayerOnStateChange
                }
            });

        }

        function PlayerOnReady( event ) {
            if( ! onIOS ) {
                event.target.playVideo();
            }

        }

        function PlayerOnStateChange( e ) {

            if( e.data === YT.PlayerState.PLAYING ) {
                dispatch.triggerOnce( 'play' );
            }

            if( e.data === YT.PlayerState.ENDED ) {
                dispatch.triggerOnce( 'end' );
            }

        }

        const VideoPlayer = Inferno.createClass ( {

            getInitialState: function () {

				dispatch.on( 'youtube_api_ready', registerYT );

            	return {
                    loadVideo: false,

                };

            },

			registerYT: function() {

				window[`player_${video_id}`] = new YT.Player( `${video_id}_video`, {
					height: '100%',
					width: '100%',
					videoId: video_id,
					autoPlay: true,
					events: {
						'onReady': this.PlayerOnReady,
						'onStateChange': this.PlayerOnStateChange
					}
				});

			},


			injectYoutubeScript: function() {
				let tag = document.createElement('script');
				tag.src = "https://www.youtube.com/iframe_api";
				tag.id = "youtubeApi";

				let firstScriptTag = document.getElementsByTagName('script')[0];
				firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
			},

            render: function () {

                /**
                 * Create Video Player
                 */

                let videoPlayer;

                if( videoType === "youtube" ) {
					if(!document.getElementById('youtubeApi')) {
                        this.injectYoutubeScript();
                    }
                }

                const video_id = videoUrl.replace( 'https://www.youtube.com/embed/', '' );

                return (
                    <div className="video-container">
						<div id={ `${video_id}_video` }
						/>
                    </div>
                )
            }
        } );
        if( onIOS || autoPlay === "true" ) {

			Inferno.render ( <VideoPlayer/>, root );

		} else {

			/**
			 *  Load video on click
			 */

			$( '.placeholder', '#' + id ).on('click', function( e ) {

				Inferno.render ( <VideoPlayer/>, root );
                if( document.getElementById('youtubeApi') ) {
                    registerYT()
                }
				e.stopPropagation();
			});

			$( '#' + id ).on('click', function() {

				Inferno.render ( <VideoPlayer/>, root );
                if( document.getElementById('youtubeApi') ) {
                    registerYT()
                }

			});

		}

    };

};

export default Scripts;
