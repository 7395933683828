import { StyleSheet } from "@convertly/css";
import removeNestedUserOverrides from "../../site-header/site-header/utils/removeNestedUserOverrides";
import UserOverrides from "../../elements/style-functions/user-overrides";

export const FilterDropdownStyles = ({ global, data, inEditor }) => {
  const filterDropdown = removeNestedUserOverrides({ data }),
      { dropdown, list, listItem, itemLink, itemName, dropdownButton, arrowHeadOpenDown } =
    data.userOverrides || {};

  return StyleSheet.create({
    filterDropdown: {
      ...UserOverrides(global, { userOverrides: filterDropdown }, inEditor)
    },

    dropdown: {
      display: "inline-block",
      position: "relative",
      height: "100%",
      ...UserOverrides(global, dropdown, inEditor)
    },

    list: {
      display: "none",
      position: "absolute",
      listStyle: "none",
      border: "1px solid #dbdbdb",
      backgroundColor: "#fff",
      boxShadow: "0 3px 10px 0 rgba(0, 0, 0, .34)",
      zIndex: "1",
      ".active": {
        display: "unset"
      },
      ...UserOverrides(global, list, inEditor)
    },

    listItem: {
      width: "max-content",
      padding: "10px",
      ":hover": {
        color: global.colors.accent.color2
      },
      ...UserOverrides(global, listItem, inEditor)
    },

    itemLink: {
      ...UserOverrides(global, itemLink, inEditor)
    },

    itemName: {
      ...UserOverrides(global, itemName, inEditor)
    },

    dropdownButton: {
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0 10px",
      height: "100%",
      ...UserOverrides(global, dropdownButton, inEditor)
    },

    arrowHeadOpenDown: {
      display: "inline-block",
      height: "10px",
      width: "10px",
      marginLeft: "5px",
      ...UserOverrides(global, arrowHeadOpenDown, inEditor)
    }
  });
};
