import {StyleSheet} from "@convertly/css";
import ModuleGlobal from '../../../elements/style-functions/module-global-styles';
import HeaderGlobal from '../header-style-functions/header-styles-global';
import FixedHeaderFix from '../header-style-functions/fixed-header-fix';

const headerStyles5 = (global, data, headerPosition) => {


    const moduleGlobal = new ModuleGlobal(global, data);
    const headerGlobal = new HeaderGlobal(global, data, headerPosition);
    const fixedHeaderFix = new FixedHeaderFix(data, "0");

    let headerStyles = {};

    if (headerPosition === "overlay") {
        headerStyles = {
            position: 'absolute',
            color: global.textColors.light.white,
            stroke: global.backgroundColors.light.white,
            background: "transparent"
        }
    }
    else if (headerPosition === "overlayFixed") {
        headerStyles = {
            '.absolute': {
                position: 'absolute',
                color: global.textColors.light.white,
                stroke: global.backgroundColors.light.white,
                background: "transparent"
            }
        }
    }
    else if ( headerPosition === "fixed" ) {

        headerStyles = {
            '* body': {
				
            }
        }
    }
    else {
        headerStyles = {}
    }




    return StyleSheet.create({
        headerWrapper: {
            height: 'fit-content'
        },
        header: {
            color: global.textColors.dark.color1,
            fontFamily: global.fontFamily.primaryFont,
            lineHeight: '26px',
			backgroundColor: "rgba(0,0,0,0.9)",
            transition: 'all .25s ease',
            ...headerGlobal,
            ...moduleGlobal,
            ...headerStyles


        },
        fixedHeaderFix: {
            ...fixedHeaderFix
        }


    })

};
export default headerStyles5;
