import {StyleSheet} from '@convertly/css';
import { generatePseudoMediaQuery } from '../../elements/style-functions/pseudo-media-queries';
import  userOverrides  from '../../elements/style-functions/user-overrides';
import _ from 'lodash';

const Styles = (global, data, inEditor) => {

    let headerTopperUserOverrides = _.get(data, 'userOverrides') ?
      userOverrides(global, { userOverrides: data.userOverrides }, inEditor) : {};

    let socialIconUserOverrides = _.get(data, 'userOverrides.socialIcons.userOverrides') ?
      userOverrides(global, { userOverrides: data.userOverrides.socialIcons.userOverrides }, inEditor) : {};

    let callOutUserOverrides = _.get(data, 'customHeaderMessage.userOverrides') ?
      userOverrides(global, data.customHeaderMessage, inEditor) : {};

    let socialIconContainerUserOverrides = _.get(data, 'userOverrides.socialIconContainer.userOverrides') ?
      userOverrides(global, {
        userOverrides: data.userOverrides.socialIconContainer.userOverrides
      }, inEditor): {};

    let headerTopperTopLftUserOverrides = _.get(data, 'userOverrides.headerTopperTopLft.userOverrides') ?
      userOverrides(global, {
        userOverrides: data.userOverrides.headerTopperTopLft.userOverrides
      }, inEditor) : {};



    return StyleSheet.create({
        header_top: {
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            margin: '0px 4%',
            padding: '6px 0px',
            display:'block',
            height:'40px',
            ...generatePseudoMediaQuery("@media (max-width: 768px)", ['smallTablet', 'mobile'], {
                display: "none",
            }, inEditor),
            ...headerTopperUserOverrides
        },
        header_top_lft: {
            height:'100%',
            display:'flex',
            paddingTop: "5px",
            alignItems: "center",
            ...headerTopperTopLftUserOverrides
        },
        socialIconContainer: {
            height: '20px',
            width: '20px',
            backgroundColor: global.colors.dark.color3,
            fill: global.colors.light.white,
            borderRadius: '50%',
            backgroundSize: '30px',
            ...generatePseudoMediaQuery("@media (max-width: 768px)", ['smallTablet', 'mobile'], {
                marginTop: "20px",
                marginLeft: "10px"
            }, inEditor),
            ...socialIconContainerUserOverrides
          },
          socialIconWrapper: {
            float:'left',
            ...socialIconUserOverrides
        },
        connect_us: {
            display:'inline-flex',
            alignContent:'center',
            float:'left',
            color: global.colors.dark.color3,
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            margin: "0",
            padding: "0",
        },
        socialIcons: {
            paddingTop: "5px"
        },
        socialIcon: {
            height: '100%',
            width: '100%',
            padding: '3px',
            fill:'inherit'
        },
        customCallOut: {
            fontSize:'17px',
            color:"green",
            ...callOutUserOverrides
        }
    })

};

export default Styles;
