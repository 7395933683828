import { StyleSheet } from "@convertly/css";

const hoursOfOperationStyles = ( global, data ) => {

    let contrast = ( data.contrast );

    return StyleSheet.create({
        hoursOfOperation: {
            fontFamily:global.fontFamily.secondaryFont,
            width:'fit-content',
            margin:'0 auto',
            textAlign:'left',
            padding:'10px'
        },
        hoursList: {
            listStyleType:'none',
            position:'relative',
            height:'100px',

        },
        dayOfWeek: {
            display:'inline-block',
            fontSize: "10px",
            margin:'50px 5px',
            textTransform:'uppercase',
            cursor:'pointer',
            color: contrast ? 'black' : 'white',
            ':hover': {
                borderBottom: '2px solid',
                color: contrast ? global.colors.dark.color1 : 'white'

            },
            '.active': {
                fontWeight:'700',
                borderBottom: '2px solid',
                color: contrast ? global.colors.dark.color1 : 'white'
            },

        },
        displayedHours: {
            position:'absolute',
            top:0,
            left:'0',
            opacity:'0',
            width:'100%',
            transition:'opacity .2s linear',
            textAlign:'center',
            color: contrast ? global.colors.dark.color1 : 'white',
            '.active': {
                opacity:'1'
            }
        },
        openStatus: {
            fontSize:'17px',
            fontWeight:'bold',
            '@media( max-width:510px )': {
                fontSize:'18px'
            }
        },
        hours: {
            fontSize:'15px',
            textTransform:'lowercase',
            '@media( max-width:510px )': {
                fontSize:'16px'
            }
        }
    })

};

export default hoursOfOperationStyles;