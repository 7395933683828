import { StyleSheet } from '@convertly/css';
import { generatePseudoMediaQuery } from '../../../elements/style-functions/pseudo-media-queries';

const HeaderStylesBottom = (global, data, inEditor) => {
	

  return StyleSheet.create({
    mainHeaderBottom: {
      height: '50%',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)', ['mobile','smallTablet'], {
          height: '0',
          paddingBottom: inEditor ? '0' : '10px',
        },
        inEditor
      ),
    }
  });
}

export default HeaderStylesBottom;
