import {StyleSheet} from "@convertly/css";
import {generatePseudoMediaQuery} from "../../../elements/style-functions/pseudo-media-queries";
import _ from 'lodash';

//browser specific overrides
import {generateTabletPortraitStyleSheet} from '../../../utils/browserSpecificStyles';

//Style objects
import productImagesGalleryStyleObject
    from '../../../ecommerce-common-components/product-image-gallery/product-image-gallery-styles';
import ImageGalleryStyleObject
    from '../../../ecommerce-common-components/product-image-gallery/product-image-gallery-2/product-image-gallery-styles';
import bubbleStyleObject from '../../../ecommerce-common-components/product-variant-widgets/bubble/bubble-style-object';
import progressBarStyleObject
    from '../../../ecommerce-common-components/product-variant-widgets/product-variant-progress-bar/product-grid-progress-bar-styles';
import buyingOptionsStyleObject
    from '../../../ecommerce-common-components/buying-options/buying-options-wrapper/buying-options-style-object';
import addToCartStyleObject
    from '../../../ecommerce-common-components/buying-options/add-to-cart-stepper/add-to-cart-qty-stepper-styles';
import quickBuyStyleObject from '../../../ecommerce-common-components/buying-options/quick-buy/quick-buy-style-object';
import quickViewModalStyleObject from './inferno-components/components/quickViewModalStyleObject';
import productFiltrationStyleObject
    from '../../../ecommerce-common-components/product-filtration/product-filtration-01/product-filtration-style-object';
import paginationStyleObject from "../../../ecommerce-common-components/pagination/paginationStyleObject";
import searchBarStyleObject from '../../../ecommerce-common-components/search-bar/searchBarStyleObject';
import loadingSpinnerStyleObjects
    from '../../../ecommerce-common-components/loading-spinner/loading-spinner-style-object';
import skeletonLoadingLayoutStyleObject from '../../skeleton-loader-layout/skeletonLoadingLayoutStyleObject';
import UserOverrides from "../../../elements/style-functions/user-overrides";

const Styles = (global, data, inEditor) => {

    const {
      productGridContainer,
      container
    } = data.userOverrides || {};

    const productGridContainerUserOverrides = _.get(productGridContainer, 'userOverrides') ? UserOverrides(global, productGridContainer, inEditor) : {};
    const containerUserOverrides = _.get(container, 'userOverrides') ? UserOverrides(global, container, inEditor) : {};

    //Inferno component style sheets

    const productImagesGalleryStyles = productImagesGalleryStyleObject(global, data);

    const bubbleStyles = bubbleStyleObject(global, data, inEditor);
    const progressBarStyles = progressBarStyleObject(global, data);
    const imageGalleryStyleObject = ImageGalleryStyleObject(global, data);

    const buyingOptionsStyles = buyingOptionsStyleObject(global, data, true, inEditor);
    const addToCartStyles = addToCartStyleObject(global, data, inEditor);
    const quickBuyStyles = quickBuyStyleObject(global, data, inEditor);

    const loadingSpinnerStyles = loadingSpinnerStyleObjects(global, data, inEditor);
    const skeletonLoadingLayoutStyles = skeletonLoadingLayoutStyleObject(global, data, inEditor);
    const quickViewModalStyles = quickViewModalStyleObject(global, data, inEditor);
    const productFiltrationStyles = productFiltrationStyleObject(global, data, inEditor);

    const paginationStyles = paginationStyleObject(global, data, inEditor);

    const searchBarStyles = searchBarStyleObject(global, data, inEditor);
    //Browser Specific Overrides
    let hideQuickViewTablePortraitStyles = {
        display: 'none'
    };
    const hideQuickViewTablePortrait = generateTabletPortraitStyleSheet(hideQuickViewTablePortraitStyles);

    const imgOverlay = {
        position: "absolute",
        bottom: '-510px',
        transition: 'all 400ms ease, opacity 0s',
        background: 'linear-gradient(180deg, rgba(0, 0, 0, .5), rgba(0, 0, 0, .6))',
        width: '100%',
        textAlign: 'center',
        padding: '20px',
        cursor: 'default',
        height: '100%',
        zIndex: '1',
        ...generatePseudoMediaQuery(
            '@media(max-width: 767px)',
            ['smallTablet', 'mobile'],
            {
                display: 'none'
            },
            inEditor
        ),
        '.col-3-styles': {
            ...generatePseudoMediaQuery(
                '@media(max-width: 991px)',
                ['largeTablet', 'smallTablet', 'mobile'],
                {
                    padding: '5px'
                },
                inEditor
            ),
            ...generatePseudoMediaQuery(
                '@media(max-width: 767px)',
                ['smallTablet', 'mobile'],
                {
                    display: 'none'
                },
                inEditor
            )
        },
        '.col-4-styles': {
            display: 'none'
        },
        '.col-6-styles': {
            display: 'none'
        }
    };
    const imgOverlayHover = {
        overflow: 'hidden',
        ":hover ": {
            ".imgOverlay": {
                bottom: '0',
            },
            '.productImg-in-svg': {
                display: 'block'
            },
            '.quickViewSVG': {
              display: 'none',
            }
        }
    };

    let baseStyles = {
        ".productBox": {
            height: 'auto',
            marginBottom: '50px',
            paddingLeft: '8px',
            paddingRight: '8px',
            '.col-3-sm-xs-buffer': {
                ...generatePseudoMediaQuery(
                    '@media(max-width: 767px)',
                    ['smallTablet', 'mobile'],
                    {
                        width: '33%',
                        marginBottom: '32px',
                    },
                    inEditor
                ),
                ...generatePseudoMediaQuery(
                    '@media(max-width: 480px)',
                    ['mobile'],
                    {
                        width: '100%'
                    },
                    inEditor
                )
            },
            '.col-4-sm-xs-buffer': {
                width: '25%',
                ...generatePseudoMediaQuery(
                    '@media(max-width: 767px)',
                    ['smallTablet', 'mobile'],
                    {
                        width: '50%'
                    },
                    inEditor
                )
            },
            '.col-6-sm-xs-buffer': {
                ...generatePseudoMediaQuery(
                    '@media(max-width: 767px)',
                    ['smallTablet', 'mobile'],
                    {
                        width: '50%'
                    },
                    inEditor
                )
            }
        },
        ".sectionTitleContainer": {
            margin: '20px 0'
        },
        ".productBoxWrapper": {

            ...imgOverlayHover
        },
        ".sectionTitle": {
            fontWeight: "700",
            fontSize: '30px',
            color: global.colors.light.color3
        },
        ".imageContainer": {
            position: "relative",
            width: '100%',
            height: 'auto',
            margin: '0 auto',
            textAlign: 'center',
            marginBottom: '-5px',
            ".col-3-styles": {
                ...generatePseudoMediaQuery(
                    '@media(max-width: 767px)',
                    ['smallTablet', 'mobile'],
                    {
                        height: '150px'
                    },
                    inEditor
                ),
            },
            ".col-6-styles": {
                marginBottom: '0px'
            },
        },
        ".productImg": {
            width: '100%',
            height: '275px',
            margin: '0 auto',
            'object-fit': data.useDefaultImageSize ? 'unset' : 'cover',
            display: 'block',
            marginBottom: '50px',
            ...generatePseudoMediaQuery(
                '@media(max-width: 767px)',
                ['smallTablet', 'mobile'],
                {
                    height: '175px',
                    marginBottom: 'unset'
                },
                inEditor
            ),
            ...generatePseudoMediaQuery(
                '@media(max-width: 480px)',
                ['mobile'],
                {
                    height: '150px',
                    marginBottom: 'unset',
                },
                inEditor
            ),
            '.col-3-styles': {
                ...generatePseudoMediaQuery(
                    '@media(max-width: 767px)',
                    ['smallTablet', 'mobile'],
                    {
                        height: '150px'
                    },
                    inEditor
                ),
            },
            '.col-4-styles': {
                height: '125px',
                marginBottom: 'unset'
            },
            '.col-6-styles': {
                height: '100px',
                marginBottom: 'unset',
            }
        },

        '.productImg-in-svg': {
            height: '100%',
            width: '100%',
            display: 'none',
        },

        ".imageOverlay": {
            ...imgOverlay
        },
        ".product-variant-spinner": {
          position: 'absolute',
          marginTop: '-20px',
          marginLeft: '-20px',
          display: 'block'
        },
        ".product-variant-spinner-overlay": {
          position: 'absolute',
          marginTop: '-20px',
          marginLeft: '-20px',
          zIndex: '1',
          background: 'rgba(0,0,0,0.3)',
          width: '100%',
          height: '100%',
        },
        ".productButtons": {
            display: 'inline-block',
            margin: '0 10px',
            padding: "10px 15px",
            fontSize: '14px',
            cursor: 'pointer',
            color: global.colors.light.white,
            background: global.colors.accent.color1,
            borderRadius: '20px'
        },
        ".productDescription": {
            display: "block"
        },
        ".subTitle": {
            fontFamily: global.fontFamily.secondaryFont,
            color: global.colors.dark.color3,
            fontSize: '10px',
            lineHeight: '10px',
            fontStyle: 'normal',
            fontWeight: '400',
            textTransform: 'uppercase',
        },
        ".productTitleSection": {
            display: 'block',
        },

        ".productTitle": {
            marginTop: '0px',
            marginBottom: '0px',
            overflow:"visible",
            float: 'left',
            fontSize: "16px",
            maxWidth: '75%',
            color: global.colors.dark.color1,
            fontFamily: global.fontFamily.secondaryFont,
            width: '100%',
            paddingTop: '1px',
            fontWeight: '700',
            overflow: 'hidden',
            display: '-webkit-box',
            '-webkit-line-clamp': '2',
            '-webkit-box-orient': 'vertical',
            '.no-variance': {
                paddingTop: '0px',
                '.col-6-styles': {
                    float: 'none',
                    paddingBottom: '4px',
                }
            },
            ...generatePseudoMediaQuery(
                '@media(max-width: 767px)',
                ['smallTablet', 'mobile'],
                {
                    width: '70%',
                    fontSize: '14px',
                },
                inEditor
            ),
            '.col-3-styles': {
                fontSize: '15px',
                ...generatePseudoMediaQuery(
                    '@media(max-width: 991px)',
                    ['largeTablet', 'smallTablet', 'mobile'],
                    {
                        fontSize: '14px',
                        maxWidth: '70%',
                    },
                    inEditor
                ),
            },
            '.col-4-styles': {
                fontSize: '14px',
                maxWidth: '70%',
                paddingTop: '0px',
                ...generatePseudoMediaQuery(
                    '@media(max-width: 991px)',
                    ['largeTablet', 'smallTablet', 'mobile'],
                    {
                        fontSize: '13px',
                        maxWidth: '60%',
                        '-webkit-line-clamp': '2',
                    },
                    inEditor
                ),
                ...generatePseudoMediaQuery(
                    '@media(max-width: 767px)',
                    ['smallTablet', 'mobile'],
                    {
                        maxWidth: '70%',
                        '.no-variance': {
                            paddingTop: '5px'
                        },
                    },
                    inEditor
                ),
                ...generatePseudoMediaQuery(
                    '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
                        maxWidth: '70%',
                        float: 'none',
                    },
                    inEditor
                ),
            },
            '.col-6-styles': {
                fontSize: '14px',
                maxWidth: '70%',
                ...generatePseudoMediaQuery(
                    '@media(max-width: 991px)',
                    ['largeTablet', 'smallTablet', 'mobile'],
                    {
                        fontSize: '13px',
                    },
                    inEditor
                ),
            }
        },
        ".with-filter .productTitle": {
            maxWidth: '70%',
            overflow:"visible",
            '.col-3-styles': {
                ...generatePseudoMediaQuery(
                    '@media(max-width: 767px)',
                    ['smallTablet', 'mobile'],
                    {
                        paddingBottom: '0px'
                    },
                    inEditor
                ),
            },
            '.col-4-styles': {
                maxWidth: '60%',
                overflow:"visible",
                ...generatePseudoMediaQuery(
                    '@media(max-width: 480px)',
                    ['mobile'],
                    {
                        maxWidth: '70%'
                    },
                    inEditor
                ),
            },
            '.col-6-styles': {
                fontSize: '12px',
                maxWidth: '100%',
            }
        },
        ".quickBuy": {
            display: "block"
        },
        ".product-grid-container": {
            position: 'relative',
            height: 'auto',
            display: "flex",
            flexDirection: 'column',
            width: '100%',
            ".with-filter": {
                display: 'inline-flex',
                width: '79%',
                verticalAlign: 'top',
                paddingLeft: '15px',
                ...generatePseudoMediaQuery(
                    '@media(max-width: 991px)',
                    ['largeTablet', 'smallTablet', 'mobile'],
                    {
                        width: '100%',
                        paddingLeft: '0px'
                    },
                    inEditor
                ),
            },
        },
        ".containerOverride": {
            paddingLeft: "0",
            paddingRight: "0",
            ...generatePseudoMediaQuery(
              '@media(max-width: 767px)',
              ['smallTablet', 'mobile'],
              {
                paddingLeft: "15px",
                paddingRight: "15px",
              },
              inEditor
          ),
          '.section-title': {
            paddingLeft: "15px",
            paddingRight: "15px",
            ...generatePseudoMediaQuery(
              '@media(max-width: 991px)',
              ['smallTablet', 'mobile'],
              {
                paddingLeft: "0px",
                paddingRight: "0px",
              },
              inEditor
            ),
            ...generatePseudoMediaQuery(
              '@media(max-width: 767px)',
              ['smallTablet', 'mobile'],
              {
                paddingLeft: "15px",
                paddingRight: "15px",
              },
              inEditor
            )
          },
          ...containerUserOverrides
        },

        ".page": {
            position: 'relative',
            minWidth: '100%',
            transition: 'all .2s ease',
            height: 'auto',
            ".active": {
                top: 0,
                left: 0,
                right: 0
            },
        },
        ".productDescriptionWrapper": {
            background: global.colors.light.white,
            position: 'relative',
            minHeight: '46px',
            padding: '10px 5px 5px',
            height: '115px',
            '.no-variance': {
                paddingTop: '5px',
            },
            '.no-variance .on-sale-pricing': {
                paddingTop: '25px',
                '.col-2-styles': {
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 479px)',
                        ['mobile'],
                        {
                            marginTop: '61px'
                        },
                        inEditor
                    ),
                },
                '.col-3-styles': {
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 767px)',
                        ['smallTablet', 'mobile'],
                        {
                            marginTop: '61px'
                        },
                        inEditor
                    ),
                },
                '.col-4-styles': {
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 767px)',
                        ['smallTablet', 'mobile'],
                        {
                            paddingTop: '10px'
                        },
                        inEditor
                    ),
                },
                '.col-6-styles': {
                    left: '11px',
                    top: '66px',
                },
                '.with-filter .col-6-styles': {
                    marginTop: '-53px',
                    paddingLeft: '5px',
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 991px)',
                        ['largeTablet', 'smallTablet', 'mobile'],
                        {
                            marginTop: '-50px'
                        },
                        inEditor
                    ),
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 479px)',
                        ['mobile'],
                        {
                            marginTop: '51px'
                        },
                        inEditor
                    ),
                }
            },
            ...generatePseudoMediaQuery(
                '@media(max-width: 479px)',
                ['mobile'],
                {
                    'min-height': '95px',
                    height: '160px'
                },
                inEditor
            ),
            '.col-3-styles': {
                ...generatePseudoMediaQuery(
                    '@media(max-width: 767px)',
                    ['smallTablet', 'mobile'],
                    {
                        height: '155px'
                    },
                    inEditor
                ),
                ...generatePseudoMediaQuery(
                    '@media(max-width: 479px)',
                    ['mobile'],
                    {
                        height: '160px'
                    },
                    inEditor
                ),
            },
            '.col-4-styles': {
                height: '150px'
            },
            '.col-6-styles': {
                height: '155px'
            }

        },
    };

    const dotLoadingBlink = {
      '0%': {
        opacity: '.2',
      },
      '20%': {
        opacity: '1',
      },
      '100%': {
        opacity: '.2',
      },
    };

    return StyleSheet.create({
            productGalleryContainer: {
                fontFamily: global.fontFamily.primaryFont,
                color: global.colors.dark.color3,
                paddingTop: '20px'
            },
            productGridContainer: {
              ...productGridContainerUserOverrides
            },
            sectionTitleContainer: {
                marginBottom: '20px',
                marginTop: '10px',
                paddingLeft: '8px',
                paddingRight: '8px'
            },
            sectionTitle: {
                display: 'inline-block',
                marginTop: '5px',
                color: global.colors.dark.color1,
                fontWeight: '900',
                textTransform: 'uppercase',
                ...generatePseudoMediaQuery(
                    '@media(max-width: 767px)',
                    ['smallTablet', 'mobile'],
                    {
                        backgroundColor: global.colors.light.color2,
                        width: '100%',
                        height: '51px',
                        textAlign: 'center',
                        paddingTop: '10px'
                    },
                    inEditor
                ),
                ...generatePseudoMediaQuery(
                    '@media(max-width: 480px)',
                    ['mobile'], {
                        backgroundColor: global.colors.light.color2,
                        width: '100%',
                        height: '51px',
                        textAlign: 'center',
                        fontSize: '16px',
                        paddingTop: '16px',
                    },
                    inEditor
                ),
            },
            productCount: {
                marginLeft: '15px',
                fontSize: '20px',
                fontWeight: '300',
                ...generatePseudoMediaQuery(
                    '@media(max-width: 480px)',
                    ['mobile'], {
                        fontSize: '16px'
                    },
                    inEditor
                ),
            },
            productGridInferno: {
                ...addToCartStyles,
                ...buyingOptionsStyles,
                ...quickBuyStyles,
                ...productImagesGalleryStyles,
                ...baseStyles,
                ...productFiltrationStyles,
                ...paginationStyles,
                ...searchBarStyles,
                ...loadingSpinnerStyles,
                ...skeletonLoadingLayoutStyles,
                ".applying-filters-loader-container": {
                  position: 'fixed',
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  background: 'rgba(0, 0, 0, .5)',
                  blur: '5px',
                  zIndex: '999999',
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center'
                },
                ".applying-filters-loader-wrapper": {
                  alignSelf: 'center',
                },
                ".applying-filters-text": {
                  display: 'inline-block',
                  fontSize: '30px',
                  fontWeight: 'bold',
                  textTransform: 'uppercase',
                    color:'#bbb'

                },
                ".applying-filters-dot-container": {
                  display: 'inline-block'
                },
                ".dot": {
                  animationName: [dotLoadingBlink],
                  animationDuration: '1.4s',
                  animationIterationCount: 'infinite',
                  'animation-fill-mode': 'both',
                  height: '11px',
                  width: '11px',
                  backgroundColor: '#bbb',
                  borderRadius: '50%',
                  display: 'inline-block',
                  marginLeft: '5px',
                  transform: 'translateY(1px)'
                },
                ".dot2": {
                  animationDelay: '.2s',
                },
                ".dot3": {
                  animationDelay: '.4s',
                },
                ".default-styles": {
                    ...bubbleStyles,
                },

                /** bubble prod variant styles */
                ".product-variant-wrapper": {
                    position: 'absolute',
                    bottom: '0',
                    width: '91%'
                },

                ".quick-view-modal .product-variant-wrapper": {
                    position: 'inherit'
                },

                ".product-variant-row": {
                    height: "86px",
                    textAlign: 'left',
                    overflow: 'hidden'
                },
                ".product-variant-name": {
                    display: 'block',
                    paddingLeft: '7px',
                    color: global.colors.light.white,
                    textTransform: 'uppercase',
                    fontSize: '12px',
                    marginBottom: '5px',
                },
                ".product-variant-container": {
                    height: '100%',
                    display: 'inline-block',
                    verticalAlign: 'top',
                    width: 'calc(100% - 40px)',
                },

                ...progressBarStyles,

                /** Quick View modal widget styles **/
                ...imageGalleryStyleObject,
                ".cart-quick-view-container": {
                    display: 'none',
                    position: 'absolute',
                    bottom: data.columns === 6 ? '-25px' : '-45px',
                    width: '100%',
                    zIndex: '3',
                    backgroundColor: global.colors.dark.color1,
                    ...generatePseudoMediaQuery(
                        '@media(min-width: 768px)',
                        ['largeTablet'],
                        {
                            display: (data.columns === 6 || data.columns === 4) ? 'none' : 'block'
                        },
                        inEditor
                    ),
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 767px)',
                        ['smallTablet', 'mobile'],
                        {
                            display: 'none',
                        },
                        inEditor
                    ),
                    '.out-of-stock': {
                        height: '18%',
                        backgroundColor: global.colors.light.color3,
                    },
                    '.col-4-styles': {
                        '.out-of-stock': {
                            height: '39%',
                            backgroundColor: global.colors.light.color3,
                        },
                    },
                    '.col-6-styles': {
                        '.out-of-stock': {
                            height: '48%',
                            backgroundColor: global.colors.light.color3,
                        },
                    }
                },

                '.out-of-stock-text': {
                    lineHeight: '50px',
                },

                ".buying-option-wrapper-pg-container": {
                    width: '80%',
                    '.col-4-styles': {
                        width: '100%'
                    },
                    '.col-6-styles': {
                        width: '100%'
                    }
                },

                ".modalPricing .price": {
                    display: "inline-block",
                    marginTop: "0px",
                    marginBottom: "0px",
                    fontSize: "17px",
                    fontFamily: global.fontFamily.secondaryFont,
                    paddingRight: '7px',
                    color: global.colors.dark.black,
                    ".on-sale": {
                        fontSize: '12px',
                        textDecoration: "line-through"
                    },
                    '.sale-price': {
                        color: global.colors.light.white,
                    }
                },
                ".gridPricing .price": {
                    display: "flex",
                    justifyContent: 'flex-end',
                    marginTop: "-4px",
                    marginBottom: "0px",
                    fontSize: '14px',
                    lineHeight: '17px',
                    fontFamily: global.fontFamily.secondaryFont,
                    color: global.colors.dark.color3,
                    width: '20%',
                    float: 'right',
                    paddingTop: '5px',
                    '.sale-price': {
                        display: 'flex',
                        'border-bottom-left-radius': '25px',
                        position: 'absolute',
                        right: '0',
                        top: '4px',
                        paddingRight: '5px',
                        backgroundColor: global.colors.accent.color2,
                        height: '46px',
                        color: global.colors.light.white,
                        paddingTop: '15px',
                    },
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 767px)',
                        ['smallTablet', 'mobile'],
                        {
                            width: '30%',
                            fontSize: '13px',
                        },
                        inEditor
                    ),
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 479px)',
                        ['mobile'],
                        {
                            width: '50%',
                            float: 'none',
                            justifyContent: 'inherit',
                            fontSize: '14px',
                            '.sale-price': {
                                justifyContent: 'baseline',
                                marginBottom: '10px',
                                'border-bottom-left-radius': '0',
                                'border-bottom-right-radius': '25px',
                                marginLeft: '0',
                                'align-items': 'baseline',
                                left: '5px',
                                top: '64px',
                                paddingLeft: '5px'
                            }
                        },
                        inEditor
                    ),
                },
                ".on-sale-pricing": {
                    position: 'absolute',
                    right: '0',
                    paddingRight: '5px',
                    fontSize: '10px',
                    paddingTop: '10px',
                    color: global.colors.light.white,
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 479px)',
                        ['mobile'],
                        {
                            left: '10px',
                            paddingTop: '9px',
                        },
                        inEditor
                    ),
                    '.col-2-styles': {
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 479px)',
                            ['mobile'],
                            {
                                marginTop: '61px',
                            },
                            inEditor
                        ),
                    },
                    '.col-3-styles': {
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 991px)',
                            ['largeTablet', 'smallTablet', 'mobile'],
                            {
                                fontSize: '10px',
                            },
                            inEditor
                        ),
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 767px)',
                            ['smallTablet', 'mobile'],
                            {
                                fontSize: '10px',
                                left: '10px',
                                marginTop: '61px'
                            },
                            inEditor
                        ),
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 479px)',
                            ['mobile'],
                            {
                                marginTop: '62px'
                            },
                            inEditor
                        ),
                    },
                    '.col-4-styles': {
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 991px)',
                            ['largeTablet', 'smallTablet', 'mobile'],
                            {
                                fontSize: '10px',
                            },
                            inEditor
                        ),
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 767px)',
                            ['smallTablet', 'mobile'],
                            {
                                fontSize: '10px',
                                top: '20px',
                                paddingTop: '10px'
                            },
                            inEditor
                        ),
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 480px)',
                            ['mobile'],
                            {
                                fontSize: '10px',
                                marginTop: '56px',
                            },
                            inEditor
                        ),
                    },
                    '.col-6-styles': {
                        left: '11px',
                        top: '80px',
                    },
                    '.with-filter .col-6-styles': {
                        position: 'unset',
                        marginTop: '-38px',
                        paddingLeft: '5px',
                    }
                },
                '.gridPricing': {
                    fontSize: '13px',
                    ".col-2-styles .price": {
                        fontSize: '16px',
                        '.on-sale': {
                            position: 'absolute',
                            width: 'fit-content',
                            right: '6px',
                            top: '0',
                            fontSize: '10px',
                            textDecoration: 'line-through',
                            ...generatePseudoMediaQuery(
                                '@media(max-width: 479px)',
                                ['mobile'],
                                {
                                    left: '11px',
                                    top: '58px'
                                },
                                inEditor
                            ),
                        }
                    },
                    ".col-3-styles .price": {
                        fontSize: '16px',
                        '.on-sale': {
                            position: 'absolute',
                            width: 'fit-content',
                            right: '6px',
                            top: '0',
                            fontSize: '10px',
                            textDecoration: 'line-through',
                        },
                        '.sale-price': {
                            fontSize: '16px',
                        },
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 991px)',
                            ['largeTablet', 'smallTablet', 'mobile'],
                            {
                                width: '30%',
                            },
                            inEditor
                        ),
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 767px)',
                            ['smallTablet', 'mobile'],
                            {
                                width: '50%',
                                float: 'none',
                                justifyContent: 'inherit',
                                fontSize: '16px',
                                '.sale-price': {
                                    justifyContent: 'baseline',
                                    marginBottom: '10px',
                                    'border-bottom-left-radius': '0',
                                    'border-bottom-right-radius': '25px',
                                    marginLeft: '0',
                                    'align-items': 'baseline',
                                    left: '5px',
                                    top: '64px',
                                    paddingLeft: '5px',

                                },
                                '.on-sale': {
                                    position: 'absolute',
                                    fontSize: '10px',
                                    top: '58px',
                                    left: '12px',
                                }
                            },
                            inEditor
                        ),
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 480px)',
                            ['smallTablet', 'mobile'],
                            {
                                width: '31%'
                            },
                            inEditor
                        ),
                    },
                    ".col-4-styles .price": {
                        fontSize: '16px',
                        width: '30%',
                        '.on-sale': {
                            position: 'absolute',
                            width: 'fit-content',
                            right: '6px',
                            top: '0',
                            fontSize: '10px',
                            textDecoration: 'line-through',
                        },
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 991px)',
                            ['largeTablet', 'smallTablet', 'mobile'],
                            {
                                width: '40%',
                            },
                            inEditor
                        ),
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 767px)',
                            ['smallTablet', 'mobile'],
                            {
                                width: '30%',
                                float: 'right'
                            },
                            inEditor
                        ),
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 480px)',
                            ['mobile'],
                            {
                                width: '50%',
                                float: 'none',
                                '.sale-price': {
                                    justifyContent: 'baseline',
                                    marginBottom: '10px',
                                    'border-bottom-left-radius': '0',
                                    'border-bottom-right-radius': '25px',
                                    marginLeft: '0',
                                    'align-items': 'baseline',
                                    left: '5px',
                                    top: '59px',
                                    paddingLeft: '5px',
                                    position: 'absolute'
                                },
                                '.on-sale': {
                                    right: 'unset',
                                    left: '11px',
                                    top: '53px',
                                }
                            },
                            inEditor
                        ),
                    },
                    ".col-6-styles .price": {
                        width: '50%',
                        float: 'none',
                        justifyContent: 'inherit',
                        fontSize: '16px',
                        position: 'unset',
                        '.sale-price': {
                            justifyContent: 'baseline',
                            marginBottom: '10px',
                            'border-bottom-left-radius': '0',
                            'border-bottom-right-radius': '25px',
                            marginLeft: '0',
                            'align-items': 'baseline',
                            left: '5px',
                            top: '64px',
                            paddingLeft: '5px',
                            position: 'absolute'
                        },
                        '.on-sale': {
                            position: 'absolute',
                            width: 'fit-content',
                            fontSize: '10px',
                            textDecoration: 'line-through',
                            left: '11px',
                            top: '59px',
                        }

                    },
                },
                ".with-filter .gridPricing": {
                    ".col-3-styles .price": {
                        width: '30%',
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 767px)',
                            ['smallTablet', 'mobile'],
                            {
                                width: '50%',
                                float: 'none',
                                justifyContent: 'inherit',
                                fontSize: '14px',
                                '.sale-price': {
                                    justifyContent: 'baseline',
                                    marginBottom: '10px',
                                    'border-bottom-left-radius': '0',
                                    'border-bottom-right-radius': '25px',
                                    marginLeft: '0',
                                    'align-items': 'baseline',
                                    left: '5px',
                                    top: '64px',
                                    paddingLeft: '5px'
                                },
                            },
                            inEditor
                        ),
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 480px)',
                            ['mobile'],
                            {
                                width: '31%'
                            },
                            inEditor
                        ),
                    },
                    ".col-4-styles .price": {
                        fontSize: '16px',
                        width: '40%',
                        '.on-sale': {
                            position: 'absolute',
                            width: 'fit-content',
                            right: '6px',
                            top: '0',
                            fontSize: '10px',
                            textDecoration: 'line-through',
                        },
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 767px)',
                            ['smallTablet', 'mobile'],
                            {
                                width: '30%',
                                float: 'right'
                            },
                            inEditor
                        ),
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 480px)',
                            ['mobile'],
                            {
                                width: '50%',
                                float: 'none',
                                '.sale-price': {
                                    justifyContent: 'baseline',
                                    marginBottom: '10px',
                                    'border-bottom-left-radius': '0',
                                    'border-bottom-right-radius': '25px',
                                    marginLeft: '0',
                                    'align-items': 'baseline',
                                    left: '5px',
                                    top: '59px',
                                    paddingLeft: '5px',
                                    position: 'absolute'
                                },
                                '.on-sale': {
                                    right: 'unset',
                                    left: '11px',
                                    top: '53px',
                                }
                            },
                            inEditor
                        ),
                    },
                    '.col-6-styles .price': {
                        paddingTop: '0',
                        width: '60%',
                        '.sale-price': {
                            justifyContent: 'baseline',
                            marginBottom: '10px',
                            'border-bottom-left-radius': '0',
                            'border-bottom-right-radius': '25px',
                            marginLeft: '0',
                            'align-items': 'baseline',
                            left: '5px',
                            top: '64px',
                            paddingLeft: '5px',
                            position: 'absolute',
                            paddingTop: '14px',
                        },
                        '.on-sale': {
                            position: 'absolute',
                            width: 'fit-content',
                            fontSize: '10px',
                            textDecoration: 'line-through',
                            left: '11px',
                            top: '64px',
                        },
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 991px)',
                            ['largeTablet', 'smallTablet', 'mobile'],
                            {
                                width: '50%',
                                height: '46px'
                            },
                            inEditor
                        ),
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 767px)',
                            ['smallTablet', 'mobile'],
                            {
                                width: '31%'
                            },
                            inEditor
                        ),
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 480px)',
                            ['mobile'],
                            {
                                width: '50%'
                            },
                            inEditor
                        ),
                    }
                },

                '.out-of-stock-banner': {
                    position: 'absolute',
                    right: '0',
                    top: '0',
                    'border-bottom-left-radius': '25px',
                    height: '46px',
                    width: '20%',
                    textAlign: 'right',
                    paddingRight: '2px',
                    color: global.colors.dark.black,
                    fontSize: '12px',
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 991px)',
                        ['largeTablet', 'smallTablet', 'mobile'], {
                            width: '30%'
                        },
                        inEditor
                    ),
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 767px)', ['mobile'], {
                            width: '50%',
                            right: 'unset',
                            top: '61px',
                            'border-bottom-left-radius': '0px',
                            'border-bottom-right-radius': '25px',
                            textAlign: 'left',
                            paddingLeft: '5px'
                        },
                        inEditor
                    ),
                    '.col-2-styles': {
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 991px)',
                            ['largeTablet', 'smallTablet', 'mobile'], {
                                width: '20%'
                            },
                            inEditor
                        ),
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 767px)',
                            ['smallTablet', 'mobile'],
                            {
                                width: '30%',
                                right: '0',
                                top: '0px',
                                'border-bottom-left-radius': '25px',
                                'border-bottom-right-radius': '0px',
                                textAlign: 'right',
                                paddingLeft: '0px'
                            },
                            inEditor
                        ),
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 480px)', ['mobile'], {
                                width: '50%',
                                right: 'unset',
                                top: '61px',
                                'border-bottom-left-radius': '0px',
                                'border-bottom-right-radius': '25px',
                                textAlign: 'left',
                                paddingLeft: '5px'
                            },
                            inEditor
                        ),
                    },
                    '.col-3-styles': {
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 480px)', ['mobile'], {
                                width: '31%'
                            },
                            inEditor
                        ),
                    },
                    '.col-4-styles': {
                        width: '30%',
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 991px)', ['largeTablet', 'smallTablet', 'mobile'], {
                                width: '40%'
                            },
                            inEditor
                        ),
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
                                width: '30%',
                                right: '0',
                                top: '0',
                                'border-bottom-left-radius': '25px',
                                'border-bottom-right-radius': '0px',
                                textAlign: 'right',
                                paddingLeft: '0'
                            },
                            inEditor
                        ),
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 480px)', ['mobile'], {
                                width: '50%',
                                right: 'unset',
                                top: '55px',
                                'border-bottom-left-radius': '0px',
                                'border-bottom-right-radius': '25px',
                                textAlign: 'left',
                                paddingLeft: '5px'
                            },
                            inEditor
                        ),
                    },
                    '.col-6-styles': {
                        width: '50%',
                        right: 'unset',
                        top: '61px',
                        'border-bottom-left-radius': '0px',
                        'border-bottom-right-radius': '25px',
                        textAlign: 'left',
                        paddingLeft: '5px'
                    },
                },

                '.out-of-stock-pricing': {
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: global.colors.dark.black,
                },

                '.with-filter .out-of-stock-banner': {
                    position: 'absolute',
                    right: '0',
                    top: '0',
                    'border-bottom-left-radius': '25px',
                    height: '46px',
                    width: '20%',
                    textAlign: 'right',
                    paddingRight: '5px',
                    color: global.colors.dark.black,
                    backgroundColor: global.colors.light.color3,
                    fontSize: '12px',
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 767px)',
                        ['smallTablet', 'mobile'],
                        {
                            width: '30%'
                        },
                        inEditor
                    ),
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 480px)',
                        ['mobile'],
                        {
                            width: '50%',
                            right: 'unset',
                            top: '61px',
                            'border-bottom-left-radius': '0px',
                            'border-bottom-right-radius': '25px',
                            textAlign: 'left',
                            paddingLeft: '5px'
                        },
                        inEditor
                    ),
                    '.col-3-styles': {
                        width: '30%',
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
                                width: '50%',
                                right: 'unset',
                                top: '61px',
                                'border-bottom-left-radius': '0px',
                                'border-bottom-right-radius': '25px',
                                textAlign: 'left',
                                paddingLeft: '5px'
                            },
                            inEditor
                        ),
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 480px)', ['mobile'], {
                                width: '31%'
                            },
                            inEditor
                        ),
                    },
                    '.col-4-styles': {
                        width: '40%',
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
                                width: '30%',
                            },
                            inEditor
                        ),
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 480px)', ['mobile'], {
                                top: '55px',
                                width: '50%',
                            },
                            inEditor
                        ),
                    },
                    '.col-6-styles': {
                        width: '60%',
                        right: 'unset',
                        top: '61px',
                        'border-bottom-left-radius': '0px',
                        'border-bottom-right-radius': '25px',
                        textAlign: 'left',
                        paddingLeft: '5px',
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 991px)', ['largeTablet', 'smallTablet', 'mobile'], {
                                width: '50%',
                            },
                            inEditor
                        ),
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
                                width: '31%',
                            },
                            inEditor
                        ),
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 480px)', ['mobile'], {
                                width: '50%',
                            },
                            inEditor
                        ),
                    },
                },

                '.out-of-stock-description-text': {
                    display: 'none',
                    color: 'red',
                    fontSize: '12px',
                    marginLeft: '6px',
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
                            display: 'inline-block'
                        },
                        inEditor
                    ),
                    '.col-4-styles': {
                        display: 'inline-block',
                    },
                    '.col-6-styles': {
                        display: 'inline-block',
                        fontSize: '8px',
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 991px)', ['largeTablet', 'smallTablet', 'mobile'], {
                                fontSize: '12px',
                            },
                            inEditor
                        ),
                    }
                },

                '.with-filter .out-of-stock-pricing': {
                    fontSize: '16px',
                    lineHeight: '20px',
                    color: global.colors.dark.black,
                },

                '.details-button-wrapper': {
                    marginTop: '50px',
                    position: 'absolute',
                    bottom: '10px',
                    background: global.colors.light.white,
                    height: '28px',
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 479px)',
                        ['mobile'],
                        {
                            marginTop: '20px'
                        },
                        inEditor
                    ),
                    '.col-2-styles': {
                        '.on-sale': {
                            ...generatePseudoMediaQuery(
                                '@media(max-width: 479px)',
                                ['mobile'],
                                {
                                    marginTop: '62px'
                                },
                                inEditor
                            ),
                        }
                    },
                    '.col-3-styles': {
                        marginTop: '55px',
                        marginBottom: '0px',
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 767px)',
                            ['smallTablet', 'mobile'],
                            {
                                marginTop: '17px',
                                height: '28px',
                                fontSize: '13px',
                                '.on-sale': {
                                    marginTop: '47px'
                                }
                            },
                            inEditor
                        ),
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 479px)',
                            ['mobile'],
                            {
                                marginTop: '17px',
                                marginBottom: '0px',
                                '.on-sale': {
                                    marginTop: '47px'
                                }
                            },
                            inEditor
                        ),
                    },
                    '.col-4-styles': {
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 767px)',
                            ['smallTablet', 'mobile'],
                            {
                                marginTop: '17px',
                                height: '28px',
                                fontSize: '13px',
                            },
                            inEditor
                        ),
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 479px)',
                            ['mobile'],
                            {
                                marginTop: '17px',
                                marginBottom: '0px',
                                '.on-sale': {
                                    marginTop: '47px'
                                }
                            },
                            inEditor
                        ),
                    },
                    '.col-6-styles': {
                        marginTop: '10px',
                        '.on-sale': {
                            marginTop: '44px'
                        },
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 767px)',
                            ['smallTablet', 'mobile'],
                            {
                                marginTop: '17px',
                                height: '28px',
                                fontSize: '13px',
                            },
                            inEditor
                        ),
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 479px)',
                            ['mobile'],
                            {
                                marginTop: '17px',
                                marginBottom: '0px',
                                '.on-sale': {
                                    marginTop: '47px'
                                }
                            },
                            inEditor
                        ),
                    }
                },
                ".details-button": {
                    fontSize: '11px',
                    lineHeight: '12px',
                    marginTop: '10px',
                    border: `1px solid ${ global.colors.light.color3 }`,
                    textTransform: 'uppercase',
                    padding: '9px 15px',
                    color: global.colors.light.color3,
                    'pointer-events': inEditor ? 'none' : 'unset',
                    ':hover': {
                        transition: 'all 400ms ease',
                        backgroundColor: global.colors.accent.color2,
                        color: global.colors.light.white,
                    }
                },

                ".page-inactive-right": {
                    height: '0',
                    left: '200%'
                },
                ".page-inactive-left": {
                    height: '0',
                    left: '-150%'
                },
                ".paginationIndexContainer": {
                    width: '100%',
                    textAlign: 'center'
                },
                ".paginationIndex": {
                    display: 'inline-block',
                    fontFamily: global.fontFamily.secondaryFont,
                    color: global.colors.dark.color3,
                    fontSize: '16px',
                    width: "15px",
                    height: '20px',
                    cursor: "pointer",
                    ":hover": {
                        fontWeight: '700',
                        color: global.colors.dark.color1,
                    },
                    ".active": {
                        fontWeight: '700',
                        color: global.colors.dark.color1,
                    }
                },
                ".load-more-button-container": {
                    textAlign: 'center',
                    "marginBottom": "30px"
                },
                ".load-more-button": {
                    cursor: 'pointer',
                    margin: '0 auto',
                    ...global.button1
                },
                /** Filtration section **/
                ".filtrationBar": {
                    width: '100%',
                    height: '40px',
                    background: global.colors.dark.color2
                },
                ".categorySortContainer": {
                    width: '200px',
                    height: '100%',
                    position: 'relative'
                },
                ".filterOptions": {
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    background: global.colors.dark.color3
                },

                /** Quick View modal **/
                ".quickViewSVGContainer": {
                    position: 'absolute',
                    zIndex: 4,
                    bottom: '0',
                    right: '0',
                    height: '100%',
                    width: '20%',
                    transition: 'all .5s ease',
                    alignItems: "center",
                    justifyContent: 'center',
                    background: global.colors.dark.color1,
                    cursor: 'pointer',
                    display: 'flex',
                    ":hover ": {
                        background: global.colors.dark.black,
                    },
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 767px)',
                        ['smallTablet', 'mobile'],
                        {
                            display: 'none'
                        },
                        inEditor
                    ),
                    '.col-3-styles': {
                        ...generatePseudoMediaQuery(
                            '@media(max-width: 991px)',
                            ['largeTablet', 'smallTablet', 'mobile'],
                            {
                                width: '30%',
                            },
                            inEditor
                        ),
                    },
                    '.col-4-styles': {
                        display: 'block',
                        width: '100%',
                        background: 'transparent',
                    },
                    '.col-6-styles': {
                        display: 'block',
                        width: '100%',
                        background: 'transparent'
                    }
                },
                ".with-filter .quickViewSVGContainer": {
                    '.col-3-styles': {
                        width: '20%',
                    },
                },
                ".quickViewSVG": {
                    height: "25px",
                    width: "25px",
                    fill: global.colors.light.white,
                    transition: 'all .5s ease',
                    '.col-4-styles': {
                        display: 'none'
                    },
                    '.col-6-styles': {
                        display: 'none'
                    }
                },

                '.closeIcon': {
                    height: '20px',
                    width: '20px'
                },

                ".feature-image-container": {
                    height: '250px'
                },

                ...quickViewModalStyles,


                ".subtitle": {
                    color: global.colors.dark.color3,
                    fontSize: "13px",
                    lineHeight: "20px",
                    marginBottom: "15px",
                    textAlign: "left"
                },
                '.pagination-container': {
                    display: 'block',
                    height: '43px',
                    marginBottom: '20px',
                    width: 'calc(100% + 14px)',
                    transform: 'translateX(-7.5px)',
                    zIndex: '1',
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 991px)',
                        ['largeTablet', 'smallTablet', 'mobile'],
                        {
                            marginBottom: '65px',
                        },
                        inEditor
                    ),
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 767px)',
                        ['smallTablet', 'mobile'],
                        {
                            height: '36px',
                            marginBottom: '15px',
                            '.sort-by-active': {
                              transform: "unset"
                            }
                        },
                        inEditor
                    ),
                },

                '.pagination-container .with-filter': {
                    width: 'calc(100% + 14px)',
                    transform: 'translateX(-7.5px)',
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 767px)',
                        ['smallTablet', 'mobile'],
                        {
                            marginBottom: '65px',
                        },
                        inEditor
                    ),
                },

                '.mobile-refine-sort-by-wrapper': {
                    display: 'none',
                    height: '100%',
                    width: '100%',
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 991px)',
                        ['largeTablet', 'smallTablet', 'mobile'],
                        {
                            paddingBottom: '7px',
                            display: 'block',
                            paddingTop: '3px',
                            marginBottom: '7px',
                        },
                        inEditor
                    ),
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 767px)',
                        ['smallTablet'],
                        {
                            paddingBottom: 'unset',
                            display: 'block',
                            paddingTop: 'unset',
                            margin: 'unset',
                        },
                        inEditor
                    ),
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 375px)',
                        ['mobile'],
                        {
                            paddingBottom: 'unset',
                            display: 'block',
                            paddingTop: 'unset',
                            margin: 'unset',
                        },
                        inEditor
                    )
                },
                '.no-results-page-container': {
                    paddingTop: '35px',
                    paddingBottom: '35px',
                },
                '.get-nooped': {
                    fontSize: '38px',
                    lineHeight: '44px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                },
                '.why-you-got-nooped': {
                    textAlign: 'center',
                },
                '.pagination-wrapper': {
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 767px)',
                        ['smallTablet', 'mobile'],
                        {
                            display: 'none'
                        },
                        inEditor
                    ),
                },

                ".pagination-wrapper-mobile": {
                    display: 'none',
                    width: '100%',
                    height: '50px',
                    ...generatePseudoMediaQuery(
                        '@media(max-width: 767px)',
                        ['smallTablet', 'mobile'],
                        {
                            display: 'block',
                            zIndex: '1'
                        },
                        inEditor
                    ),
                },

                '.col-4-styles .productImg-in-svg': {
                    '.productImg-in-svg': {
                        display: 'none',
                    },
                },

                '.col-6-styles .productImg-in-svg': {
                    '.productImg-in-svg': {
                        display: 'none',
                    },
                },
            },
            containerOverride: {
              paddingLeft: "0",
              paddingRight: "0",
              ...generatePseudoMediaQuery(
                '@media(max-width: 767px)',
                ['smallTablet', 'mobile'],
                {
                  paddingLeft: "15px",
                  paddingRight: "15px",
                },
                inEditor
              ),
              '.section-title': {
                paddingLeft: "15px",
                paddingRight: "15px",
                ...generatePseudoMediaQuery(
                  '@media(max-width: 991px)',
                  ['smallTablet', 'mobile'],
                  {
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  },
                  inEditor
                ),
                ...generatePseudoMediaQuery(
                  '@media(max-width: 767px)',
                  ['smallTablet', 'mobile'],
                  {
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  },
                  inEditor
                )
              },
              ...containerUserOverrides
            },
            ...baseStyles,
            salePrice: {
                fontSize: '16px',
                color: global.colors.accent.color1
            },
            price: {
                display: "block",
                marginTop: "0px",
                marginBottom: "0px",
                fontSize: "16px",
                fontFamily: global.fontFamily.secondaryFont,
                color: global.colors.dark.color2,
                ".onsale": {
                    fontSize: '12px',
                    textDecoration: "line-through"
                }
            },
            paginationIndexContainer: {
                width: '100%',
                textAlign: 'center'
            },
            paginationIndex: {
                display: 'inline-block',
                fontFamily: global.fontFamily.secondaryFont,
                color: global.colors.dark.color3,
                fontSize: '18px',
                width: "15px",
                height: '20px',
                cursor: "pointer",
                ":hover": {
                    fontWeight: '700',
                    color: global.colors.dark.color1,
                },
                ".active": {
                    fontWeight: '700',
                    color: global.colors.dark.color1,
                }
            },
        }
    );

};

export default Styles;
