import { StyleSheet } from '@convertly/css';
import { generatePseudoMediaQuery } from '../../../../elements/style-functions/pseudo-media-queries'
import UserOverrides from '../../../../elements/style-functions/user-overrides';

const Styles = (global, data, inEditor) => {

    const userOverrides = UserOverrides(global, data);

    return StyleSheet.create({

        column: {
            display: 'inline-block',
            width: "25%",
            ...generatePseudoMediaQuery(
                "@media (max-width: 991px)",
                ['largeTablet'],
                {
                    width: "50%"
                },
                inEditor
            ),
            ...generatePseudoMediaQuery(
                "@media (max-width: 768px)",
                ['smallTablet', 'mobile'],
                {
                    width: "100%",
                    maxWidth:'500px',
                    textAlign:'left'
                },
                inEditor
            ),
        },

        menuItemContainer: {
            ...userOverrides
        },

        menuItem: {
            padding: "5px 13px",
            margin: "0px 4px",
            transition: '.15s all ease',
            marginBottom: "10px",
            minHeight:'250px',
            verticalAlign:'middle'
        },
        menuItemTop: {
            height:"160px",
            position:"relative"
        },
        menuItemImage: {
            height:'100%',
            width:'100%'
        },
        menuItemImagePlaceholder: {
            height:'100%',
            width:'100%',
            background:global.colors.dark.color3
        },
        itemTags: {
            padding: "5px 0px 5px",
            position:'absolute',
            top:'5px',
            left:'5px'
        },
        tag: {
            display: 'inline-block',
            padding: "3px 10px",
            backgroundColor: global.colors.light.color3,
            color: global.colors.dark.color1,
            fontSize: "8px",
            marginTop: "3px",
            marginRight: "5px",
            fontFamily:global.fontFamily.secondaryFont
        },
        menuItemBottom: {
            width: "100%",
            backgroundColor:'#fff',
            minHeight:'85px',
            height:'fit-content',
            padding:"10px"
        },

        rightItem: {
            minWidth: "40px"
        },

        headingAndPrice: {
            justifyContent: "space-between",
            width: "100%"
        },

        itemHeading: {
            fontSize: "16px",
            margin: "0 0 5px",
            fontWeight: 'bold',
            fontFamily: global.fontFamily.primaryFont,
            color: global.colors.dark.color1
        },
        left: {
            width:'70%',
            display:'inline-block'
        },
        itemDescription: {
            margin: "0",
            fontFamily: global.fontFamily.secondaryFont,
            color: global.colors.dark.color1,
            fontSize:'12px',
            overflow: "hidden",
            display: "-webkit-box",
            "-webkit-line-clamp": "2",
            "-webkit-box-orient": "vertical",
        },
        right: {
            width:'30%',
            display:'inline-block',
            verticalAlign:'super'
        },
        itemPrice: {
            float:'right',
            fontSize:'12px'
        }

    })

};

export default Styles;
