import { StyleSheet } from "@convertly/css";
import { clientImage } from '../../../utils/generateImage';
import convertlySliderTransitions from '../../convertly-slider-transistions';
import userOverrides from '../../../elements/style-functions/user-overrides';
import { generatePseudoMediaQuery } from "../../../elements/style-functions/pseudo-media-queries";


const slideStyles = ( global, data, sliderSettings, inEditor ) => {

    const transition = convertlySliderTransitions( global, sliderSettings );
    const slideUserOverrides = userOverrides( global, data, inEditor );
    let overlayOverrides = {};
    if(data.overlay) {
        overlayOverrides = userOverrides(global, data.overlay, inEditor);
    }

    return StyleSheet.create({

        slide: {
            listStyle:'none',
            width: '100%',
            height:'100%',
            top:0,
            zIndex: 1,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50%",
            backgroundSize: "cover",
            "@media(hover:hover)": {
                transition:transition.activeTransition,
            },
            ...transition.activeBaseStyles,
            paddingTop:'20px',
            '.active': {
                zIndex:2,
                ...transition.activeActiveStyles
            },
            ...generatePseudoMediaQuery(
              "@media (max-width:767px)",
              ['smallTablet', 'mobile'],
              {
                  textAlign:'center'
              },
              inEditor
            ),
            ...slideUserOverrides
        },
        slideContent: {
            height:'100%',
            width:'100%',
            zIndex:'3'
        },
        textArea: {
            width:'100%'
        },
        overlay: {
            position:'absolute',
            top:0,
            left:0,
            height:'100%',
            width:'100%',
            zIndex:'2',
            ...overlayOverrides
        }
    })
};

export default slideStyles;
