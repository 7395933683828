const memoize = require("memoizee");
import { clientImage } from "containers/Editor/components/pegasus-components/src";

const imageUrlHandler = (url, params = {}, siteId) => clientImage(url, params, siteId);

/**
 *
 * @param url image filename
 * @param params params object for image processor
 * @param siteId
 * @returns {Promise}
 */
export const imageUrl = memoize(imageUrlHandler);
