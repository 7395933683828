import React from 'react';
import PropTypes from 'prop-types';
import Component from '../../rootComponent';
import Types from '../../attribute-types';
import { css } from "@convertly/css";
import defaultGlobal from '../../themes/global.js';
import ReactHTMLParser from "@convertly/react-html-parser";
//utils
import generateId from '../../utils/generate-id';

//Styles
import listStyles from './list-item-styles';

import Elements from '../index';

export default class
ListItem extends Component {

    constructor ( props ) {

        super( props );

    }

    render() {
        /**
         * Calls Stylesheet function
         **/
        this.styles = listStyles( this.props.global, this.props.data, this.props.listStyle );


        let className = '';

        let aphroditeClass = css ( this.styles.listItem );

        if ( this.animatedClassName ) {
            className += " " + this.animatedClassName
        }
        className += aphroditeClass;

        /**
         * Handles custom bullet points
         **/
        const { animated } = this.props.data
        const inlineStyle = this.generateStyleAttribute( )

        let liText;
        let liBulletPoint;

        if(this.props.data.link) {
            liText = <Elements.WebLink { ...this.props } />
        }
        else {
            liText = this.props.data.text
        }

        if(this.props.data.bulletPointImage) {
            liBulletPoint =
            <Elements.Image
                parentFigureClassName={ this.props.bulletPointImage }
                data={ this.props.data.bulletPointImage }
                global={ this.props.global }/>
        }


        return (
            <li key={ this.props.elementDataName }
                { ...this.generateCustomAttributes() }
                {...generateId ( this.props.elementId )}
                onClick={( e ) => {
                    this.launchElementalEditor ( 'list-item', e );
                }}
                onMouseOver={ (e)=> { this.addEditableOutline( e, "child" ) } }
                onMouseOut={ (e)=> { this.removeEditableOutline( e, "child" ) } }
                { ...inlineStyle }
                data-animated={animated}
                className={ className }>
                { [liBulletPoint, liText] }
            </li>
        );
    }
}

/**
 * Default Props for rendering when no data is being passed
 **/
ListItem.defaultProps = {
    data: {
        listItem: {
            text: "Hello from the other side",
            bold: false,
            italic: false,
            underline: false,
            align: 'center',
            fontSize: '',
            color: ''
        }
    },
    global : defaultGlobal
};

/**
 * Connects element to context for cms editor
 **/
ListItem.contextTypes = {
    store: PropTypes.object
};
