export default function generateAccessKey( accessKey, showAccessKey ) {

    let showAccessKeyValue = showAccessKey ? showAccessKey : true;

    const accessKeyObj = {
        accessKey: accessKey
    };

    return showAccessKeyValue ? accessKeyObj : {};

}