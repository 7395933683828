import React from 'react';
import Component from '../../../rootComponent';
import PropTypes from 'prop-types';
import {css} from "@convertly/css";
import {id} from "@convertly/thor";
import defaultGlobal from '../../../themes/global.js';
import ReactHTMLParser from "@convertly/react-html-parser";
import _ from 'lodash';

//Styles
import videoPlayerStyles from './video-player-styles';

//Scripts
import VideoPlayerInferno from 'source/elements/video/scripts/video-player-inferno-app.js'
import Elements from 'source/elements';

//Utils
import {clientImage, globalImage} from 'source/utils/generateImage.js';
import {generateYouTubeEmbed} from 'source/utils/video-src-handler.js';

//Components
import SVG from '../../../svg/index';

/**
 * Class controls imports style functions that allows for user to override the css in the CMS editor for their site.
 **/

class VideoPlayer extends Component {

  constructor(props) {
    super(props);
    const isVideoTypeYoutube = this.props.data.videoType === "youtube";
    let videoUrl = '';
    if (isVideoTypeYoutube) {
      videoUrl = generateYouTubeEmbed(this.props.data.src);
      videoUrl = videoUrl.replace('https://www.youtube.com/embed/', '');
    }
    this.state = {
      disableImageLazyLoad:this.getParentState('disableImageLazyLoad'),
      specialDataAttribute: isVideoTypeYoutube ? {} : {"data-youtubeid": videoUrl},
      videoUrl: videoUrl
    }
  }

  render() {

    const inEditor = this.inEditor();

    /**
     * Calls Stylesheet function
     **/
    this.styles = videoPlayerStyles(this.props.global, this.props.data, inEditor);


    /**
     * Handles the inline hover effect
     **/
    let elementEditableBorder = {};
    if (!this.props.notEditable) {
      elementEditableBorder = {
        outline: this.state.inEditor ? this.state.elementEditableBorder : ''
      };
    }

    /**
     * Generates URL for Video based on video Type
     */
    let videoUrl;
    let video;
    switch (this.props.data.videoType) {
      case "youtube":
        videoUrl = generateYouTubeEmbed(this.props.data.src);

        break;
      case "mediafile":
        videoUrl = `https://s3.amazonaws.com/convertlyimguploadeast/${siteId}/${this.props.data.src}`;
        video =
          <video preload={true} autoPlay={ true } loop={ true } muted={ true }>
            <source src={ `https://s3.amazonaws.com/convertlyimguploadeast/${siteId}/${this.props.data.src}` }/>
          </video>;
        break;
      default:
        videoUrl = generateYouTubeEmbed(this.props.data.src);
    }

    /**
     * Generate Placeholder URL
     */
    let placeholderURLdefault = _.get(this.props, 'data.placeHolderImage.src');

    /**
     * Checks for default class name, if it exists, add to class
     **/

    let className = css(this.props.parentClassName, this.styles.videoPlayer);

    if (this.props.data.defaultClass) {
      className += " " + this.props.data.defaultClass;
    }

    /**
     * Load Play Button if placeholder image
     */
    let playButton;

    if (placeholderURLdefault) {
      playButton =

        <SVG.VideoPlayButton
          playButtonColor={this.props.data.playButtonColor}
          className={this.styles.playButton}
        />
    }

    let events = this.props.data.gaEvent || [];
    events = JSON.stringify(events);

    const inlineStyle = this.generateStyleAttribute();

    const {animated} = this.props.data;


    if (this.props.inEditor) {
      return (
        <div
          {...this.generateCustomAttributes()}
          className={css(this.styles.videoContainer)}
          {...this.generateDataEditorAttribute()}
          {...this.state.specialDataAttribute}
          {...inlineStyle}>
          {placeholderURLdefault ?
            <Elements.Image
              data={{
                src: placeholderURLdefault,
                alt: "Video Place Holder",
              }}
              disableImageLazyLoad={this.state.disableImageLazyLoad}
              parentClass={css(this.styles.videoPlaceHolder) + ' placeholder'}
            /> :
            <img
              classNmae={css(this.styles.videoPlaceHolder) + ' placeholder'}
              src={globalImage("video-placeholder.jpg")}/>
          }
          {playButton}
        </div>
      );
    }

    /**
     * Loads Video element
     **/
    return (
      <div {...inlineStyle}
           {...this.generateDataEditorAttribute()}
           data-animated={animated}
           className={this.animatedClassName + css(this.styles.videoContainer)}
           id={id(VideoPlayerInferno, videoUrl, this.props.data.videoType, events, this.props.data.autoPlay)}

           {...this.state.specialDataAttribute}>
        { placeholderURLdefault ?
          <Elements.Image
            data={{
              src: placeholderURLdefault,
              alt: "Video Place Holder",
            }}
            disableImageLazyLoad={this.state.disableImageLazyLoad}
            parentClass={css(this.styles.videoPlaceHolder) + ' placeholder'}/> :
          <img src={globalImage("video-placeholder.jpg")}/>
        }

        {playButton}
      </div>

    );
  }
}


/**
 * Default Props for rendering when no data is being passed
 **/
VideoPlayer.defaultProps = {
  data: {
    src: 'light-bulb-planter.jpg',
    caption: {
      showCaption: false,
      text: 'hello'
    },
    alt: 'hello',

  },
  global: defaultGlobal
};

/**
 * Connects element to context for cms editor
 **/
VideoPlayer.contextTypes = {
  store: PropTypes.object
};

export default VideoPlayer

/*
 {
 "el": "Elements.Video",
 "data":
 {
 "videoType": "youtube",
 "src": "https://www.youtube.com/embed/Wm7jMoZpOxw",
 "placeHolderImage":
 {
 "src": "video_placeholder.jpg"
 },
 "playButtonColor": "light"

 },

 "children": [],
 "sort": 700
 }
 */










