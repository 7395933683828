/*global Inferno, $ */
/* eslint-disable */
import {include} from '@convertly/thor';

const Script = (id, siteLink) => {

    const Dispatcher = include('../../../utils/dispatcher', 'Dispatcher');

    return (id, siteLink) => {
        const hasLocalStorage = $('html').hasClass('localstorage');

        const dispatch = Dispatcher(id);

        const root = document.getElementById(id);
        /** Init Imports **/

        const ShippingCalculator = Inferno.createClass({

            getInitialState: function () {

                return {
                    itemsInCart: false
                };
            },

            componentDidMount() {
                dispatch.on('cart', (items) => {
                    if (items.length > 0) {
                        this.setState({itemsInCart: true})
                    }
                });
                dispatch.send("request_cart", "");
            },

            render: function () {


                if (this.state.itemsInCart) {
                    return (
                        <div>
                            <a href={ `${siteLink}/checkout` }>Proceed to Checkout</a>
                            <svg version="1.1"
                                 className={ `${root.className} chkGuestarrow` }
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="50"
                                 height="50"
                                 viewBox="0 0 268.832 268.832">
                                <g>
                                    <path
                                        d="M265.171,125.577l-80-80c-4.881-4.881-12.797-4.881-17.678,0c-4.882,4.882-4.882,12.796,0,17.678l58.661,58.661H12.5   c-6.903,0-12.5,5.597-12.5,12.5c0,6.902,5.597,12.5,12.5,12.5h213.654l-58.659,58.661c-4.882,4.882-4.882,12.796,0,17.678   c2.44,2.439,5.64,3.661,8.839,3.661s6.398-1.222,8.839-3.661l79.998-80C270.053,138.373,270.053,130.459,265.171,125.577z"
                                        fill="#253039"/>
                                </g>
                            </svg>
                        </div>
                    )
                }
                else {
                    return (
                        null
                    )
                }
            }

        });

        Inferno.render(<ShippingCalculator />, root);

    };

};

export default Script;