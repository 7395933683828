import { StyleSheet } from "@convertly/css";
import UserOverrides from "../../elements/style-functions/user-overrides";

export const TitleStyles = ({ global, data, inEditor }) => {

  return StyleSheet.create({
    titleStyle: {
      ...global.h2,
      ...UserOverrides(global, data, inEditor)
    }
  });
};
