import React from "react";

import Brightness from "components/icons/imgedit_brightness.svg";
import CropTool from "components/icons/imgedit_crop.svg";
import Filters from "components/icons/imgedit_filter_images.svg";
import Frames from "components/icons/imgedit_frames.svg";
import Palette from "components/icons/imgedit_pallette.svg";
import RotateLeft from "components/icons/imgedit_rotate_left.svg";

import { CropPanel } from "./CropPanel";
import { BrightnessPanel } from "./BrightnessPanel";
import { OrientationPanel } from "./Orientation";
import { SaturationPanel } from "./SaturationPanel";
import { FiltersPanel } from "./FiltersPanel";
import { FramesPanel } from "./FramesPanel";

export const toolsRow = props => {
  return [
    {
      label: "Crop",
      icon: CropTool,
      component: CropPanel
    },
    {
      label: "Orientation",
      icon: RotateLeft,
      component: OrientationPanel,
      disable: true
    },
    {
      label: "Brightness",
      icon: Brightness,
      component: BrightnessPanel
    },
    {
      label: "Saturation",
      icon: Palette,
      component: SaturationPanel
    },
    {
      label: "Filters",
      icon: Filters,
      component: FiltersPanel
    }
    /*{
      label: 'Frames',
      icon: Frames,
      component: FramesPanel,
    },*/
  ];
};
