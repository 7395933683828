import _ from "lodash";
import { graph } from "utils";
import { Alerts } from "services/Alerts";
import { showLoadingIcon } from "containers/App/actions";
import { setElements } from "containers/Editor/actions/editorActions";
import { loadInitialFonts, setBannerTemplates, setSaving } from "../actions";
import { store } from "app/app";

/**
 * Saves a template that is being edited in the editor
 *
 * @returns {Function}
 */
export const saveActiveTemplate = () => async dispatch => {
  await dispatch(setSaving(true));

  const template = store
    .getState()
    .get("marketing")
    .Banners.get("activeBanner");
  const elements = store
    .getState()
    .get("editor")
    .editorReducer.get("pageElements");

  const query = `mutation($id:ID!,$elements:String){updateTemplate(id:$id,elements:$elements){id}}`;
  const variables = {
    id: template.get("id"),
    elements: JSON.stringify(elements)
  };

  await graph({ query, variables }, true);

  await dispatch(setSaving(false));

  await dispatch(
    updateTemplateImage({
      siteId: "convertly/banner-templates",
      bannerId: template.get("id")
    })
  );
};

/**
 *
 * @param siteId - this is used to map in S3 where to store the image
 * @param bannerId
 * @returns {Function}
 */
export const updateTemplateImage = ({ siteId, bannerId }) => async () => {
  const query = `
	mutation($siteId:ID!,$bannerId:ID!) {
		updateBannerImage(siteId:$siteId,bannerId:$bannerId)
	}
	`;

  const variables = {
    siteId,
    bannerId
  };

  await graph({ query, variables });
};

export const saveBannerAsTemplate = banner => async dispatch => {
  if (!(await Alerts.confirm(`Save ${banner.get("title")} as a system template?`))) {
    return;
  }

  await dispatch(showLoadingIcon(true));

  const siteId = store
    .getState()
    .get("editor")
    .editorReducer.getIn(["currentSite", "siteId"]);

  const query = `
	mutation ($bannerId: ID!, $siteId: ID!) {
		createTemplateFromBanner(bannerId: $bannerId, siteId: $siteId)
	}`;

  const variables = {
    bannerId: banner.get("id"),
    siteId
  };

  await graph({ query, variables });

  await dispatch(showLoadingIcon(false));
};

export const useBannerTemplate = template => async dispatch => {
  if (!(await Alerts.confirm("Replace current content with this template?"))) {
    return;
  }

  await dispatch(showLoadingIcon(true));

  const query = `{getTemplate(id:"${template.get("id")}") {id, elements}}`;

  const response = await graph({ query }, true);

  let elements = _.get(response, "data.getTemplate.elements");

  try {
    // @PEDRO Why are you replacing ' with " ????????????
    //elements = elements.replace(/'/g, '\"');
    elements = JSON.parse(elements);
  } catch (e) {
    await dispatch(showLoadingIcon(false));
    await Alerts.alert("There was an error loading that template.");
    return;
  }

  await dispatch(loadInitialFonts(elements));
  await dispatch(setElements(elements));

  await dispatch(showLoadingIcon(false));
};

export const fetchBannerTemplates = async () => {
  const query = `query {getAllTemplates {id, title, image, dimensions}}`;

  const response = await graph({ query }, true);

  return _.get(response, "data.getAllTemplates") || [];
};

export const loadBannerTemplates = () => async dispatch => {
  let templates = await fetchBannerTemplates();

  templates = _.sortBy(templates, t => t.dimensions);

  return await dispatch(setBannerTemplates(templates));
};
