import { fromJS } from "immutable";
import { createReducer } from "redux-act";

import {
  setBanners,
  popBannerEditorOpen,
  setBannerSizes,
  setActiveBanner,
  setBannerTemplates,
  switchToolPanel,
  setActiveBannerId,
  setBannerShapes,
  setSaving,
  setContextMenu,
  setViewFilter,
  setIsTemplate,
  setSetting,
  setEditingTemplate
} from "../actions";

const initialState = fromJS({
  bannerEditorIsOpen: false,
  banners: [],
  saving: false,
  editingTemplate: false,
  activeBanner: fromJS({}),
  bannerSizes: [],
  bannerTemplates: [],
  toolPanel: "",
  activeBannerId: "",
  isTemplate: false,
  bannerShapes: [],
  contextMenu: {
    active: false
  },
  viewFilter: "ACTIVE",
  settings: {}
});

const reducers = {
  [setBannerSizes]: (state, bannerSizes) => state.merge({ bannerSizes }),

  [setEditingTemplate]: (state, editingTemplate) => state.merge({ editingTemplate }),

  [setSetting]: (state, { field, value }) =>
    state.mergeDeep({
      settings: {
        [field]: value
      }
    }),

  [setIsTemplate]: (state, isTemplate) => state.merge({ isTemplate }),

  [setViewFilter]: (state, viewFilter) => state.merge({ viewFilter }),

  [setSaving]: (state, saving) => state.merge({ saving }),

  [setContextMenu]: (state, contextMenu) => state.merge({ contextMenu }),

  [setBanners]: (state, banners) => state.merge({ banners }),

  [setActiveBanner]: (state, activeBanner) => state.merge({ activeBanner }),

  [popBannerEditorOpen]: (state, bannerEditorIsOpen) => state.merge({ bannerEditorIsOpen }),

  [setBanners]: (state, banners) => state.merge({ banners }),

  [setBannerTemplates]: (state, bannerTemplates) => state.merge({ bannerTemplates }),

  [switchToolPanel]: (state, toolPanel) => state.merge({ toolPanel }),

  [setActiveBannerId]: (state, activeBannerId) => state.merge({ activeBannerId }),

  [setBannerShapes]: (state, bannerShapes) => state.merge({ bannerShapes })
};

export const bannersReducer = createReducer(reducers, initialState);
