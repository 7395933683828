/*global Inferno, $ */
import React from 'react';
const Script = (
  PageJump
) => {
  return ({
						rootClass,
						pages,
            currentPage,
            footer,
            toggleDropDown
					}) => {

    return (
          <PageJump rootClass={ rootClass }
                    pages={ pages }
                    currentPage={ currentPage }
                    footer={ footer }
                    toggleDropDown={ toggleDropDown }
          />
    )
  }
};

export default Script;
