/*global Inferno, $ */
/* eslint-disable */
import {include} from '@convertly/thor';

const Script = () => {

        const Dispatcher = include('../../../utils/dispatcher', 'Dispatcher');
        const Graph = include('../../../utils/graph', 'Graph');
        const SpinnerSVGInferno = include('../../../svg/spinner/spinner', 'SpinnerSVGInferno');
        const NotificationBoxInferno = include('../../../ecommerce-common-components/notification-box/notification-box', 'NotificationBoxInferno');

        return (id, domainName, siteId, siteLink) => {

            const root = document.getElementById(id);


            const dispatch = Dispatcher(id);
            const graph = Graph();
            const SpinnerSvg = SpinnerSVGInferno();
            const NotificationBox = NotificationBoxInferno();

            let domain = domainName;

            if (domain.indexOf('www.') !== -1) {
                domain = domain.replace('www.', '');
            }

            const YourComponentName = Inferno.createClass({

                getInitialState: function () {

                    return {
                        state: '',
                    };
                },

                sendForgotPasswordLink: function (e) {

                    e.preventDefault();

                    const mutation = `mutation( $email:String!,$siteId:ID!) {
                        sendResetPasswordLink(email:$email, id:$siteId)
                    }`;

                    let variables = {
                        email: this.state.email,
                        siteId
                    };

                    graph({query: mutation, variables, cb: this.handleCallback})

                },

                onChangeGenericField: function (e, field) {
                    const newState = {};
                    newState[field] = e.target.value;
                    this.setState(newState);
                },

                handleCallback: function (err, response) {

                    if (err) {
                        console.log(err);
                        this.setState({emailSent:"error"})
                    }
                    else {
                        console.log(response);
                        this.setState({emailSent:"success"})
                    }

                },


                componentWillMount() {


                },

                render: function () {

                    const rootClass = root.className;

                    let notification = null;

                    if( this.state.emailSent === "success" ) {
                        notification =
                        <NotificationBox messageType={ 'success' }
                                         animate={ true }
                                         rootDiv={ root }>

                            <p>Please check your email to reset your password.
                                <a href={ `${siteLink}/` }
                                   className={ `${rootClass} home-link` }>
                                    Back Home</a>
                            </p>

                        </NotificationBox>;
                    }
                    else if( this.state.emailSent === "error" ) {
                        notification =
                            <NotificationBox messageType={ 'error' }
                                             animate={ true }
                                             rootDiv={ root }>

                                <p>
                                   Something went wrong
                                </p>

                            </NotificationBox>;
                    }


                    return (
                        <div className={ `${rootClass} forgot-password-container` }>
                            <h2 className={ `${rootClass} title` }>Forgot your password?</h2>
                            <p className={ `${rootClass} text` }>Enter your email address to reset your password. <br/> You
                                may need to check your spam folder or unblock { `noreply@${domain}`}</p>
                            <form className={ `${ rootClass } form_HCPV` }
                                  onSubmit={ (e) => this.sendForgotPasswordLink(e) }
                            >
                                <div className={rootClass + " input-container"}>
                                    <input type="email"
                                           className={ rootClass + " textInput_wInput input" }
                                           name="email"
                                           placeholder="example@example.com"
                                           required={ true }
                                           value={ this.state.email }
                                           onInput={ (e) => this.onChangeGenericField(e, 'email')}
                                    />
                                    <p className={ rootClass + " form-error-message" }>
                                        This field is required</p>
                                </div>
                                <div className={ `${rootClass} button-container` }>

                                    <input type="submit"
                                           className={ `${rootClass} btnContinue_wButton` }
                                    />
                                    <SpinnerSvg className={ `${rootClass} spinnerSVG` }
                                                display={ this.state.displaySpinner }/>
                                </div>
                            </form>
                            { notification }
                        </div>
                    )
                }

            });

            Inferno.render(<YourComponentName />, root);

        };

    }
;

export default Script;
