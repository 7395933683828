import styled from 'styled-components';
import {GLOBALS} from "../../../styles/global-default-styles";

export const ControlsWrapper = styled.div`
  display:flex;
  padding:0 20px;
  justify-content:space-between;
  margin-top:15px;`;

export const CancelButton = styled.div`
    border: 1px solid ${GLOBALS.colorPrimaryDark};
    box-sizing: border-box;
    box-shadow: 0px 0px 2px rgba(65, 65, 65, 0.25);
    border-radius: 3px;
    padding:12px 0;
    flex: 1 1 120px;
    text-align:center;
    margin-right:10px;
    cursor:pointer;`;

export const NextButton = styled(CancelButton)`
    background:${GLOBALS.colorPrimaryDark};
    color:#fff;
    margin-right:0;
    margin-left:${ ({stage}) => stage === 3 ? '0' : '10px' };
    transition:opacity 300ms ease; 
    ${ ({disabled}) => disabled && `cursor:not-allowed;opacity:.7;` }`;



