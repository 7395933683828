import React from 'react';
import Component from '../../rootComponent';
import {css} from "@convertly/css";
import _ from 'lodash';
import ReactHTMLParser from "@convertly/react-html-parser";
import {id} from '@convertly/thor';
//Default Props
import defaultData from './newsletter-signup-default-data';
import defaultGlobal from '../../themes/global';
import generateId from '../../utils/generate-id';


//Styles
import newsletterSignUpStyles from './newsletter-signup-styles-3';

//Attributes
import Types from '../../attribute-types';

//Scripts
import Scripts from './newsletter-signup-2-scripts';

//Components
import Elements from '../../elements/index';

export default class NewsLetterSignUp3 extends Component {

    constructor ( props ) {

        super ( props );


        this.onSubmit = this.onSubmit.bind ( this );
    }

    onSubmit ( e ) {
        e.preventDefault ();
    }

    render () {
        const inEditor = this.inEditor();
        this.styles = newsletterSignUpStyles ( this.props.global, this.props.data, this.props.buttonType, this.props.data.buttonOverrides, inEditor );

        const { site } = this.props;
        const events = this.props.data.gaEvent || [];
        const listId = this.props.data.listId || 0;

        let siteId = '';

        if( site ) {
            siteId = site.siteId;
        }

        let newsletterThankYouText = 'Thanks for signing up!';

        if ( this.props.data.newsletterThankYouText && this.props.data.newsletterThankYouText.text ) {
            newsletterThankYouText = this.props.data.newsletterThankYouText.text;
            newsletterThankYouText = newsletterThankYouText.replace(/"/g, '\\"');
            newsletterThankYouText = newsletterThankYouText.replace(/(['])/g, "<<>>");
        }

        const rootId = id ( Scripts, siteId, JSON.stringify( events ), listId, newsletterThankYouText );
		const inlineStyle = this.generateStyleAttribute( );

		return (
			<div id={ rootId } className={ css( this.styles.newsletterWrapper ) }
                 { ...this.generateCustomAttributes() }
				{ ...this.generateDataEditorAttribute() }
				{ ...generateId ( this.props.elementId ) }
				{ ...inlineStyle }
			>
				<form id={ `${rootId}-form` } onSubmit={ this.onSubmit } className={ css ( this.styles.newsletterSignupForm ) }>
					<input id={ `${rootId}-email` }
						   className={ css ( this.styles.newsletterSignupFormInput ) }
						   type="email" placeholder={ _.get( this.props.data, 'placeholder.text' ) }/>
					<input id={ `${rootId}-submit` }
						   className={ css ( this.styles.newsletterSignupFormSubmit ) }
						   type="submit"
						   value={ _.get( this.props.data, 'submitButton.text' ) }/>
				</form>
			</div>
        )
    }
}
NewsLetterSignUp3.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};


