import React from 'react';
import Component from '../../rootComponent';
import { css } from "@convertly/css";
import { id } from '@convertly/thor';

//Default Props
import defaultGlobal from '../../themes/global';

//Styles
import chatWidgetStyles from './chat-widget-styles-01';

//Scripts
import ConvertlyChatWidget from './scripts/convertlyChatWidget01';

export default class ChatWidget01 extends Component {

	constructor ( props ) {

		super ( props );

	}

	render () {

		this.styles = chatWidgetStyles ( this.props.global, this.props.data );

		if ( this.props.editor ) {
			return this.editor ();
		}

		return (
			<div id={ id ( ConvertlyChatWidget ) } className={ css ( this.styles.convertlyChatWidgetContainer ) }>
				<div data-target="chatWidget"
					 className={ css ( this.styles.convertlyChatWidgetLaunch ) }>
					<h4 className={ css ( this.styles.convertlyChatWidgetLaunchText ) }>LIVE CHAT</h4>
				</div>
				<div data-widget="chatWidget" id="convertlyChatWidget" className={ css ( this.styles.convertlyChatWidget ) }>
					<div className={ css ( this.styles.chatWidgetHeader ) }>
						<div className={ css ( this.styles.chatWidgetHeaderTextContainer ) }>
							<h4 className={ css ( this.styles.chatWidgetHeaderText ) }>Help</h4>
						</div>
						<div className={ css ( this.styles.chatWidgetClose ) }>
							<button data-target="chatWidget"
									className={ css ( this.styles.chatWidgetCloseButton ) }>
								<span className={ css ( this.styles.iconBar ) }/>
								<span className={ css ( this.styles.iconBar ) }/>
							</button>
						</div>
					</div>
					<div id="messageBoardContainer"
						 className={ css ( this.styles.messageBoardContainer ) }>
						<div id="messageBoard"
							 className={ css ( this.styles.messagesBoard ) }>
							<div id="firstSystemMessage" className={ css ( this.styles.systemMessage ) }>TYPICAL
								RESPONSE
								TIME: 5 minutes. If you would like to hear back on your own time, leave you email in
								the
								box and we will contact you via email
							</div>

							<div id="firstClientMessage" className={ css ( this.styles.clientMessage ) }>
								<a id="firstMessageLink" className={ css( this.styles.chatWidgetLink ) } target="#" >Convertly</a>
							</div>

							<div id="firstAgentMessage" className={ css ( this.styles.agentMessage ) }>Hello, I am
								Watson, built by the huge team at <a className={ css( this.styles.chatWidgetLink ) } target="#" >Convertly</a> to help you pick out a tie. Please ask me a
								question and I will assist you in any way I can.
							</div>

							<div className={ css ( this.styles.ellipsisMessage ) } id="ellipsis">

								<svg className={ css ( this.styles.ellipsis ) + ' dot active' } viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'>
									<path d='M1664 896q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z'/>
								</svg>

								<svg className={ css ( this.styles.ellipsis ) + ' dot' } viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'>
									<path d='M1664 896q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z'/>
								</svg>

								<svg className={ css ( this.styles.ellipsis ) + ' dot' } viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg'>
									<path d='M1664 896q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z'/>
								</svg>

							</div>
						</div>
					</div>
					<div className={ css ( this.styles.formContainer ) }>
						<form id="messageForm">

                       <textarea id="customerMessageField"
								 className={ css ( this.styles.messageInput ) }
								 placeholder="Your message here"
					   />
							<button id="submitButton" className={ css ( this.styles.submitButton ) }>Send
							</button>
						</form>
					</div>
				</div>
			</div>
		)
	}
}


ChatWidget01.defaultProps = {
	global: defaultGlobal
};
