/*global Inferno, $ */
/* eslint-disable */

import { include } from "@convertly/thor";

const Script = () => {
  return (id, blogData, maxShown, rootUrl) => {
    const parsedBlogData = JSON.parse(
      blogData
        .replace(/\n/g, "\\\\n")
        .replace(/\r/g, "\\\\r")
        .replace(/\t/g, "\\\\t")
    );

    const blogDataLength = parsedBlogData.length;
    const root = document.getElementById(id);
    const rootClass = root.className;

    const BlogPreviewSlider = Inferno.createClass({
      getInitialState: function() {
        return {
          currentPage: 1,
          currentSelected: `${rootClass}-0`
        };
      },

      getNextList: function(e) {
        e.preventDefault();

        let currentPage = this.state.currentPage;

        this.setState({ currentPage: currentPage + 1 });
      },

      getPrevList: function(e) {
        e.preventDefault();

        let currentPage = this.state.currentPage;

        if (currentPage !== 1) {
          this.setState({ currentPage: currentPage - 1 });
        }
      },

      handleBlogItemClick: function(e) {
        e.preventDefault();

        const blogDataIdentifier = e.currentTarget.getAttribute("data-blog-item");

        this.setState({
          currentSelected: blogDataIdentifier
        });
      },

      render: function() {
        let arrOfBlogs = [];
        (arrOfPreviews = []), (tmpArr = []);

        for (let i = 0; i < blogDataLength; i++) {
          const currentEl = parsedBlogData[i];
          const blogItemDataValue = `${rootClass}-${i}`;

          const {
            postTitle,
            date,
            previewImage,
            featureImage,
            author,
            categories,
            shortDescription,
            slug
          } = currentEl;

          tmpArr.push(
            <div
              className={`${rootClass} blog-list ${
                this.state.currentSelected === blogItemDataValue ? "active" : ""
              }`}
              key={`blog-list-${i}`}
              data-blog-item={blogItemDataValue}
              onClick={this.handleBlogItemClick}
            >
              <div className={`${rootClass} blog-list-title`}>{postTitle}</div>

              <div className={`${rootClass} blog-list-subtitle`}>
                <span className={`${rootClass} blog-list-date`}>{date}</span> |{" "}
                <span className={`${rootClass} blog-list-author`}>{author.name}</span>
              </div>
            </div>
          );

          if ((i + 1) % maxShown === 0 || i === blogDataLength - 1) {
            arrOfBlogs.push(tmpArr);
            tmpArr = [];
          }
          let img = ( (previewImage && previewImage.src) || (featureImage && featureImage.src) || false);

          arrOfPreviews.push(
            <a
              className={`${rootClass} blog-preview-link ${
                this.state.currentSelected === blogItemDataValue ? "active" : ""
              }`}
              key={`blog-preview-${i}`}
              data-blog-item={blogItemDataValue}
              href={slug || "/"}
            >
              <div className={`${rootClass} blog-preview`}>
                { img &&
                  <img
                      className={`${rootClass} blog-preview-media`}
                      src={`${rootUrl}${img}`}
                  />
                }
                <div className={`${rootClass} blog-preview-content`}>
                  <div className={`${rootClass} blog-preview-subtitle`}>
                    <span className={`${rootClass} blog-preview-date`}>{date}</span>
                    {author.name ? "  |  " : ""}
                    <span className={`${rootClass} blog-preview-author`}>{author.name}</span>
                    {categories.length ? "  | in " : ""}
                    {categories.map((category, idx) => {
                      let categoryLength = categories.length;
                      if (categoryLength === 1) {
                        return (
                          <span className={`${rootClass} blog-preview-category`}>
                            {category.name}
                          </span>
                        );
                      } else if (categoryLength === 2 && idx !== categoryLength - 1) {
                        return (
                          <span className={`${rootClass} blog-preview-category`}>
                            {category.name}{" "}
                          </span>
                        );
                      } else {
                        return idx === categoryLength - 1 ? (
                          <span className={`${rootClass} blog-preview-category`}>
                            and {category.name}
                          </span>
                        ) : (
                          <span className={`${rootClass} blog-preview-category`}>
                            {category.name},{" "}
                          </span>
                        );
                      }
                    })}
                  </div>

                  <div className={`${rootClass} blog-preview-title`}>{postTitle}</div>

                  <div className={`${rootClass} blog-preview-short-description`}>
                    {shortDescription}
                  </div>
                </div>
              </div>
            </a>
          );
        }

        return (
          <div className={`${rootClass} blog-preview-slider`}>
            <div className={`${rootClass} blog-list-wrapper`}>
              {this.state.currentPage > 1 && (
                <button
                  className={`${rootClass} blog-list-button blog-list-prev-button`}
                  onClick={this.getPrevList}
                >
                  &#x25B2;
                </button>
              )}

              {arrOfBlogs.map((blogSet, idx) => {
                return (
                  <div
                    className={`${rootClass} blog-list-set ${
                      this.state.currentPage === idx + 1 ? "active" : ""
                    }`}
                    data-blog-list-page={idx + 1}
                  >
                    {blogSet.map(blog => blog)}
                  </div>
                );
              })}

              {this.state.currentPage * maxShown < blogDataLength && (
                <button
                  className={`${rootClass} blog-list-button blog-list-next-button`}
                  onClick={this.getNextList}
                >
                  &#x25BC;
                </button>
              )}
            </div>

            <div className={`${rootClass} blog-preview-wrapper`}>{arrOfPreviews.map(el => el)}</div>
          </div>
        );
      }
    });

    Inferno.render(<BlogPreviewSlider />, root);
  };
};

export default Script;
