import {StyleSheet} from "@convertly/css";
import UserOverrides from '../../style-functions/user-overrides';

const FieldContainer = (global, data) => {

	const userOverrides = UserOverrides(global, data);

	let green = true;

	let containerWidth;

	if( data.containerWidth === "full") {
		containerWidth = {

			width : "100%"
		}
	}
	else if(data.containerWidth === "half"){
		containerWidth = {
			width: "50%"
		}
	}
	else if(data.containerWidth === "quarter"){
		containerWidth = {
			width: "25%"
		}
	}
	else if(data.containerWidth === "third"){
		containerWidth = {
		width: "33.33%"
	}
	}
	else{
		containerWidth = {
			width : "100%"
		}

	}




	return StyleSheet.create({
		
		fieldContainer: {
			height: "64px",
			verticalAlign: "middle",
			padding: "0 5px",
			display:"inline-block",
			...containerWidth
		},
		formErrorMessage: {
			//"display": "none",
			...global.formErrorMessage,
			".form-error-message": {
				display:'none'
			}
		}
	})

};

export default FieldContainer;