/*global Inferno, $ */

export const deferBgImages = () => () => {
    const docClasses = document.documentElement.className;
    const hasWebp = docClasses.indexOf("webp") !== -1;

    /*Assigns img src*/
    const updateImage = (img) => {
        const {lazyImg, lazyWebpImg} = img.dataset;

        //if (lazyWebpImg) img['srcset'] = `${lazyWebpImg}, ${lazyImg}`;
        if (lazyImg) img.src = hasWebp ? lazyWebpImg : lazyImg;
        img.removeAttribute('data-lazy-img');
        img.removeAttribute('data-lazy-webp-img');
    };
    /*assigns class of active on sections with bgimg*/
    const updateBGImg = (section) => {
        section.classList.add('bg-img');
    };

    if (window.IntersectionObserver) {
        const options = {
            rootMargin: '0px',
            threshold: .5
        };
        /* loads images then renders them on the page */
        const preloadImages = (target, bgImg) => {
            const {lazyImg, lazyWebpImg} = target.dataset;
            if (lazyImg) {
                requestIdleCallback(() => {
                    const tempImg = new Image();

                    tempImg.src = hasWebp ? lazyWebpImg : lazyImg;
                    tempImg.onload = () => {
                        if (target.nodeName === "IMG") {
                            updateImage(target);
                            bgIntersect.unobserve(target);
                        }

                    };

                })
            }
        };

        const callback = function (entries, observer) {
            entries.forEach(entry => {

                if (entry.isIntersecting) {

                    if (entry.target.nodeName === "IMG") {
                        updateImage(entry.target)
                    } else {
                        updateBGImg(entry.target)
                    }
                    bgIntersect.unobserve(entry.target)

                }
            })
        };
        const bgIntersect = new IntersectionObserver(callback, options);


        window.onload = () => {
            document.querySelectorAll('[data-lazy-img]').forEach(target => {
                bgIntersect.observe(target);
                //load the images async so they don't crete a jumpy feeling
                preloadImages(target)

            })
        }
    } else {
        window.onload = () => {
            const imgs = document.querySelectorAll('[data-lazy-img]');
            const bgImgs = document.querySelectorAll('[data-bg-img]');

            for (var i = 0; i < imgs.length; i++) {
                updateImage(imgs[i]);
            }
            for (var j = 0; j < bgImgs.length; j++) {
                updateBGImg(bgImgs[j]);
            }
        };

        return false
    }


};
