////import $ from 'jquery';
//toggles nav's drop down icons
const navDropdownToggle = (id) => {

  return (id) => {

    (function () {
      const $el = $('#dropdown' + id);
      const $toggleElements = $(`[data-dropdown="${id}"]`);
      const listOfDecendents = Array.prototype.slice.call($el.find('*'));

      $el.on('click', (e) => toggleOpenOrHoveredClass(e, 'open'));
      $el.on('mouseenter', (e) => toggleOpenOrHoveredClass(e, 'hovered'));
      $el.on('mouseleave', (e) => toggleOpenOrHoveredClass(e, 'hovered'));

      function toggleOpenOrHoveredClass(e, type) {
        //e.preventDefault();

        //if the link is enabled, we don't open the drop down and just go to the link
        if(type !== 'hovered' && e.target.dataset && e.target.dataset.link) {
          return;
        }

        e.preventDefault();
        if(type === 'hovered' && !!~listOfDecendents.indexOf(e)) {
          return;
        }

        let dataName = e.currentTarget.attributes['data-name'].value

        if ($(`[data-dropdown="${id}"]`).hasClass(type)) {
          $(`[data-category="${ dataName }"]`).removeClass(type);
          $(`[data-category="dropdown-parent_${id}"]`).removeClass(type);
          $toggleElements.removeClass(type);
        } else {
          $(`[data-category="${ dataName }"]`).removeClass(type);
          $(`[data-category="dropdown-parent_${id}"]`).removeClass(type);
          $toggleElements.addClass(type);
        }
      }
    })();
  };


};
export default navDropdownToggle;
