import { fromJS } from "immutable";
import { createReducer } from "redux-act";

import {
  setSnapshot,
  setPages,
  setLoaded,
  setEditSelected,
  setPage,
  setSearching,
  setFilteredData,
  setUpdateData,
  setMoreOptionsActivePageId,
  setPageInfoActiveTab,
} from "./actions";

const initialParams = {
  pages: [],
  filteredData: [],
  page: {},
  snapshot: {},
  moreOptionsActivePageId: "",
  searching: "",
  pageInfoActiveTab: "SEO",
};

const initialState = fromJS(initialParams);

const reducers = {
  [setPageInfoActiveTab]: (state, pageInfoActiveTab) => state.merge({ pageInfoActiveTab }),

  [setMoreOptionsActivePageId]: (state, moreOptionsActivePageId) =>
    state.merge({ moreOptionsActivePageId }),

  [setSnapshot]: (state, snapshot) => state.merge({ snapshot }),

  [setLoaded]: (state, pages) => state.merge({ pages }),

  [setPages]: (state, pages) => state.merge({ pages }),

  [setEditSelected]: (state, editSelected) => state.merge({ editSelected }),

  [setPage]: (state, page) => state.merge({ page }),

  [setSearching]: (state, searching) => state.merge({ searching }),

  [setFilteredData]: (state, filteredData) => state.merge({ filteredData }),

  [setUpdateData]: (state, data) => state.merge({ data }),
};

export const PagesPanelReducer = createReducer(reducers, initialState);
