import { StyleSheet } from '@convertly/css';
import UserOverrides from '../elements/style-functions/user-overrides';

const Styles = ( global, data ) => {

    const userOverrides = new UserOverrides( global, data );

    return StyleSheet.create( {
        convertlySlider: {
            textAlign:'center',
            position:'relative',
            background:"#666a",
            ...userOverrides
        }
    })

};

export default Styles;