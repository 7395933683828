export default function StyleObject(global, data) {

    const accentOpacity = `${global.colors.accent.color1}16`;
    const lightGrey = `${global.colors.light.color3}`

    return {
        ".orderStatusWrapper": {
            borderTop:`3px solid ${lightGrey}`,
            margin:"20px 0",
            display: 'flex',
            justifyContent: 'space-evenly'
        },
        ".orderStatus": {
            marginTop: "-3px",
            padding: '20px 0',
            flexGrow: 1,
            textAlign: 'center',
            color:global.colors.accent.color1
        },
        ".orderStatusActive": {
            backgroundColor:accentOpacity,
            borderTop:`3px solid ${global.colors.accent.color1}`,
            "> p": {
                fontWeight:700
            }

        },
        ".completed": {
            color:lightGrey,
            textDecoration:'line-through'

        }
    }

}
