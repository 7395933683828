import React from 'react';
import Component from '../../../../rootComponent';
import {css} from "@convertly/css";
import ReactHTMLParser from "@convertly/react-html-parser"
import {id} from '@convertly/thor';
import {clientImage} from '../../../../utils/generateImage';

//Default Props
import defaultData from './image-gallery-widget-default-data';
import defaultGlobal from '../../../../themes/global';

//Styles
import imageGalleryWidgetStyles from './image-gallery-widget-styles-01';

//Scripts
import ConvertlyImageGalleryWidget01 from '../scripts/image-gallery-widget-script-01';


export default class ImageGalleryWidget01 extends Component {

	constructor ( props ) {
		super ( props );
	}

	render () {

		this.styles = imageGalleryWidgetStyles ( this.props.global, this.props.data );

		if ( this.props.editor ) {
			return this.editor ();
		}

		const { images } = this.props;

		const featuredImage = images[ 0 ];

		const theClassName = css ( this.styles.imageThumbnailItem );
		const imageClass = css ( this.styles.imageThumbnail );

		return (

			<div id={ id ( ConvertlyImageGalleryWidget01 ) } className={ css ( this.styles.imageGallery ) }>

				<div className={ css ( this.styles.featureImgContainer ) }>
					<img id="featuredImage"
						 className={ css ( this.styles.featureImage ) }
						 src={ clientImage ( featuredImage ) }/>
				</div>

				<ul id="imageGalleryList" className={ css ( this.styles.imageThumbnailList ) }>
					{ images.map( ( image, index ) => {

						let itemClassName = 0 === index ? theClassName + " active" : theClassName;
						return (

							<li key={ index } className={ itemClassName } data-imagesrc={ clientImage ( image ) } >
								<img className={ imageClass } src={ clientImage ( image ) } alt=""/>
							</li>

						);
					} ) }
				</ul>
			</div>


		)
	}
}

ImageGalleryWidget01.defaultProps = {
	data: defaultData,
	global: defaultGlobal
};
