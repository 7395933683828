import React from 'react';
import Component from 'source/rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';
import _ from 'lodash';

//Default Props
//import defaultData from '';
const defaultData = {};
import defaultGlobal from 'source/themes/global';

//Styles
import styles from './view-feature-list-styles';

//utils
import {generateLink} from 'source/utils/generateLink';

//Components
import Elements from 'source/elements';


//Scripts
import script from './view-feature-list-script';



export default class PricingInfo extends Component {

    constructor(props) {

        super(props);

    }

    render() {

        let { data } = this.props,
        heading1     = data.list1.heading,
        heading2     = data.list2.heading,
        list1        = data.list1.items,
        list2        = data.list2.items;


        this.styles = styles(this.props.global, this.props.data);

        if (this.props.editor) {
            return this.editor();
        }


        return (
            <div className={ css( this.styles.featureListContainer ) } id={ id( script ) } >

                <div className={ css( this.styles.featureLists ) + ` lists` }> 
                    <div className={ css( this.styles.list ) }>
                        <div className={ css( this.styles.heading )}>{ heading1 }</div>

                        {
                            list1.map( (item, index) => {

                                console.log(item);

                                return (
                                    <div className={ css( this.styles.listItem ) }>
                                        
                                        <Elements.Image 
                                            data={ {
                                                src: item.img,
                                                userOverrides: {
                                                    height: "35px",
                                                    width: "35px",
                                                    display: "inline-block"
                                                }
                                            } }/>

                                        <span>
                                            { item.text }
                                        </span>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div className={ css( this.styles.list ) }>
                        <div className={ css( this.styles.heading )}>{ heading2 }</div>

                        {
                            list2.map( (item, index) => {

                                console.log(item);

                                return (
                                    <div className={ css( this.styles.listItem ) }>
                                        
                                        <Elements.Image 
                                            data={ {
                                                src: item.img,
                                                userOverrides: {
                                                    height: "35px",
                                                    width: "35px",
                                                    display: "inline-block"
                                                }
                                            } }/>

                                        <span>
                                            { item.text }
                                        </span>
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>

                <a className={ css( this.styles.launcher ) + ` launcher active` } >View Full Feature List</a>

            </div>
        )
    }
}


PricingInfo.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
