import {StyleSheet} from '@convertly/css';
import UserOverrides from '../../style-functions/user-overrides';


const VideoPlayerStyles = (global, data) => {

    const userOverrides = new UserOverrides(global, data);

    return StyleSheet.create({

        videoContainer: {
            position: "absolute",
            top: '0',
            left: '0',
            zIndex: '1',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            "> .video-container": {
                position: "absolute",
                top: '0',
                left: '0',
                zIndex: '1',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                margin: 'auto',
                ...userOverrides
            },
            "> video": {
                width: "100%"
            }


        },
        youtubeIframe: {
            width: '100%',
            height: '100%',
            border: '0px'
        },

        videoOverlay: {
            position: "absolute",
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            zIndex:5
        }


    })


};

export default VideoPlayerStyles;
