import React from 'react';
import Component from '../../../rootComponent';
import { css } from "@convertly/css";
import ReactHTMLParser from "@convertly/react-html-parser"
import {id} from '@convertly/thor';
import _ from "lodash";

//Default Props
import defaultGlobal from '../../../themes/global';

//Styles
import LocationListItemStyles from './multiple-locations-map-overlay-styles';

//Components
import Elements from '../../../elements/index'
import Widgets from '../../index';
import HoursOfOperation from './components/hours';

//Scripts
import AsyncMapLoad from '../scripts/map-iframe-async-load';
import mapScript from '../scripts/multiple-locations-map-overlay';

//utils
import generateAddressString from '../../../utils/generateAddressString';
import generateLink from '../../../utils/generateLink';


export default class ProductListing01 extends Component {

    constructor(props) {

        super(props);
    }

    render() {

        const inEditor = this.inEditor()

        this.styles = LocationListItemStyles(this.props.global, this.props.data, inEditor);
        const useBranchName = _.get(this.props, 'data.useBranchName');
        /** Generates border in editor */
        const inlineStyle = this.generateStyleAttribute( );

        let rootId = id( mapScript );

        let locations = _.get(this.props, "site.locations") || [];

        let addressGAEvent = _.get(this.props.data, "link.gaEvents.address") || { "data-event": "view on map", "data-action": "click" };

        if(addressGAEvent.event && addressGAEvent.trigger) {
            const { event, trigger } = addressGAEvent;

            addressGAEvent = {
                "data-event": event,
                "data-action": trigger
            };
        }

        let phoneGAEvent = _.get(this.props.data, "link.gaEvents.phone") || { "data-event": "click to call", "data-action": "click" };

        if(phoneGAEvent.event && phoneGAEvent.trigger) {
            const { event, trigger } = phoneGAEvent;

            phoneGAEvent = {
                "data-event": event,
                "data-action": trigger
            };
        }

        return (

            

            <div className={ css(this.styles.locationsContainer) }
                 { ...this.generateDataEditorAttribute() }
                 { ...this.generateCustomAttributes() }
                 data-module="MultipleLocationsMapOverlay"
                 onClick={( e ) => {
                     this.launchElementalEditor( 'mapsWidget', e );
                 }}
                 onMouseOver={ (e) => {this.addEditableOutline(e)} }
                 onMouseOut={ (e) => {this.removeEditableOutline(e)} }
                 {...inlineStyle}>
                {
                    locations.map((location, index) => {

                        {/* This is a work-around for allowing our new business hours data structure to work with the old script which is expecting the old data  */}
                        let hours = location.businessHours;
                        let hoursOfOperationHack = [];

                        hours.map( (day, index) => {

                            if (day.startHour && day.endHour ) {

                                hoursOfOperationHack.push({
                                    dayOfWeek: day.day,
                                    openingTime: day.startHour.concat(':', day.startMinutes, day.startPeriod),
                                    closingTime: day.endHour.concat(':', day.endMinutes, day.endPeriod),
                                    openStatus: true,
                                    displayText: day.day.slice(0,3)
                                })

                            } else {

                                hoursOfOperationHack.push({
                                    dayOfWeek: day.day,
                                    openingTime: '',
                                    closingTime: '',
                                    openStatus: false,
                                    displayText: day.day.slice(0,3)
                                })

                            }

                        });

                        return (
                            <div className={ css( this.styles.locationDiv ) + ' map-container active' }>
                                {/** Google Map Embed Code, to be changed API **/}
                                {
                                    inEditor ?
                                        <div className={ css(this.styles.googleMap) + ' map' }>
                                            <iframe
                                            className={ css(this.styles.googleMap) + ' map' }
                                            src={  "https://www.google.com/maps/embed/v1/place?q=" + generateAddressString(location) +
                                            "&zoom=16&key=AIzaSyArBjawFp_rUBj6YYFqNHCTmt1LuNOEhjw" } /></div> :
                                    <div
                                        id={ id(AsyncMapLoad, this.props.data.googleMapAPIKey, generateAddressString(location))}
                                        className={ css(this.styles.googleMap) + ' map' }>
                                    </div>
                                }

                                <div className={ css( this.styles.locationInfo ) + " locationOverlay_" + index + rootId }  >

                                    {/** Store Location Info **/}
                                    <div className={ css( this.styles.addressBox ) }>
                                        <h5 className={ css( this.styles.storeName ) + " storeName"} data-target={ index } >{ useBranchName ? location.locationName : location.city }</h5>

                                        {/** Address **/}
                                        <address  className={ css( this.styles.address ) }>

                                            {/* Will come back to fix this, for now this allows the address to be a Google Maps link  */}
                                            <a href={ `http://maps.google.com/?q=${ location.locationName } ${ location.address } ${ location.city }, ${ location.state } ${ location.zip }`} target="_blank" {...addressGAEvent}>
                                                <span className={ css(this.styles.streetAddress) } >{ location.address }</span>
                                                <span className={ css(this.styles.city) } >{ location.city }</span>, { location.state }, { location.zip }
                                            </a>

                                            <div className={ css(this.styles.contactInfo) }>
                                                <div className={ css(this.styles.phoneNumber) }><a href={`tel:${location.phone}`} {...phoneGAEvent}>{ location.phone }</a></div>
                                            </div>

                                        </address>
                                    </div>
                                    <div className={ css( this.styles.callUsBox ) }>

                                        { /** Hours of Operation **/ }
                                        <h4 className={ css( this.styles.hoursTitle ) } >Hours Of Operation:</h4>

                                        <HoursOfOperation
                                            data={ { location, contrast: true } }
                                            styles={ this.styles }
                                            global={ this.props.global }
                                        />
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>  
                            
                        )
                    })
                }
            </div>
        )
    }
}

ProductListing01.defaultProps = {
    global: defaultGlobal,
    data: {
        hoursTitle: {
            text: "Hours of Operation"
        }
    }
};
