export const alterPageElements = async ({ elements, tests, ...args }) => {
  tests = Array.isArray(tests) ? tests : [];

  // only run the algorithm if it is an array
  // if it is anything else we just return it
  if (Array.isArray(elements)) {
    // iterate over the elements
    for (let i = 0; i < elements.length; i++) {
      // iterate over the tests
      for (let j = 0; j < tests.length; j++) {
        // check if there is a test function and run it
        if (
          typeof tests[j].test === "function" &&
          tests[j].test({ element: elements[i], ...args })
        ) {
          // check if there is a mutate function
          if (typeof tests[j].mutate === "function") {
            // run the mutate function
            elements[i] = await tests[j].mutate({
              element: elements[i],
              ...args,
            });
          }
        }
      }

      // run the function on the children
      await alterPageElements({
        elements: elements[i].children,
        tests,
        ...args,
      });
    }
  }

  return elements;
};
