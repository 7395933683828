/* global $ */

/**
 * Script to stop the page from reloading when a link is clicked.
 *
 * */
const preventPageRefresh = () => {

    return ( id ) => {

		const $links = $( 'a[href="#"]' );

		$links.on( 'click', function( e ) {
			e.preventDefault( );
		} );

    }
};
export default preventPageRefresh;
