import * as CommonMethods from "./common-methods.js";
import { findElementObject, findParentElement } from "../functions";
import _ from "lodash";
import { getElementPosition } from "containers/Editor/helpers/getElementPosition";
import { getActiveModule } from "containers/Editor/getActiveModule";
import { findBlankElement } from "containers/Editor/findBlankElement";
import checkAndDeleteParents from "containers/Editor/checkAndDeleteParents";
import { can, is } from "containers/Authentication/PermissionsCheck";
import { Emitter } from "services/Emitter";
import {
  setElements,
  setOverlayStyles,
  setActiveComponent,
  setModules,
  toggleAddModal,
  setEditor,
  clearEditorPanel,
  setDraggingNewElement,
} from "../actions/editorActions";
const GHOST_MAX_DIMENSION = 300;
const GHOST_OPACITY = 0.4;
const DRAGGED_ELEMENT_OPACITY = 0.2;
const emptyColumnBackground = `no-repeat center url(https://convertlyimguploadeast.s3.amazonaws.com/convertly-global-images/empty_column_placeholder.svg)`;
const marsThickness = "5px";
const elementsSideHoveringMargin = 30;
const elementsTopHoveringMargin = 30;
const elementsBottomHoveringMargin = 30;
const sliderOverride = elementName =>
  elementName.includes("convertlySlider") && elementName !== "convertlySlider.convertlySlider";
const wrapperDivObj = {
  el: "Elements.WrapperDiv",
  data: {
    userOverrides: { display: "flex" },
  },
  editable: false,
  children: [],
  sort: 1,
};
const columnObject = {
  el: "Elements.Column",
  data: {
    mdColumns: 3,
    smColumns: 3,
    xsColumns: 12,
    mdOffset: 0,
    userOverrides: {
      //height: "300px",
    },
  },
  editable: false,
  children: [],
  sort: 1,
};
const rowObject = {
  el: "Elements.Row",
  data: {
    userOverrides: {},
  },
  editable: false,
  children: [
    {
      el: "Elements.Column",
      data: {
        mdColumns: 12,
        smColumns: 12,
        xsColumns: 12,
        mdOffset: 0,
        userOverrides: {
          //background: emptyColumnBackground,
          //height: "300px",
          //border: "1px solid black",
        },
      },
      editable: false,
      children: [],
      sort: 1101,
    },
  ],
  sort: 1,
};
const customSection = {
  el: "heartOfGold.heartOfGold",
  sort: 1,
  editable: true,
  data: {},
  children: [
    {
      el: "Elements.Container",
      data: {},
      editable: false,
      children: [
        {
          el: "Elements.Row",
          data: {
            userOverrides: {},
          },
          editable: false,
          children: [
            {
              el: "Elements.Column",
              data: {
                mdColumns: 12,
                smColumns: 12,
                xsColumns: 12,
                mdOffset: 0,
                userOverrides: {
                  //background: emptyColumnBackground,
                  //height: "200px",
                  //border: "1px solid black",
                },
              },
              editable: false,
              children: [],
              sort: 1101,
            },
          ],
          sort: 1100,
        },
      ],
      sort: 1000,
    },
  ],
};
export const draggingHandler = Page => {
  if (!Page.Mars) {
    initMars(Page);
  }
  if (!userIsDraggingAPageElement(Page)) {
    if (Page.Mars.side) {
      hideMars(Page);
    }
    Page.animation = window.requestAnimationFrame(() => draggingHandler(Page));
    return;
  }
  Page.ElementsDisplay && createAndUpdateElementsDisplay(Page);
  checkIfHoveringOverPageElements(Page);

  if (isHoveringOverAPageElement(Page)) {
    handleIsHoveringOverAPageElement(Page);
  } else {
    hideMars(Page);
  }
  //Page.Mars.style.opacity = 0.15;
  Page.animation = window.requestAnimationFrame(() => draggingHandler(Page));
  return;
};
export const onDragNewElement = (payload, Page) => {
  Page.newElementBeingDragged = payload;
  const body = document.getElementsByTagName("BODY")[0];
  const bodyHeight = Number(getComputedStyle(body).height.slice(0, -2));
  let scrollBarPage = document.getElementsByClassName("view")[0].children[0];
  let currentScrollTop = scrollBarPage.scrollTop;
  // create dragging and hovered elements display
  can("view_newEditorNav") && createAndUpdateElementsDisplay(Page);
  //
  if (Page.newElementBeingDragged) {
    if (bodyHeight - Page.newElementBeingDragged.clientY < 60) {
      if (!window.isDraggingNewElement) {
        window.quitScrollingWhileDragging = false;
        window.isDraggingNewElement = true;
        scrollWhileDragging(body, currentScrollTop, "down", Page);
      }
    } else if (Page.newElementBeingDragged.clientY < 80) {
      if (!window.isDraggingNewElement) {
        window.quitScrollingWhileDragging = false;
        window.isDraggingNewElement = true;
        scrollWhileDragging(body, currentScrollTop, "up", Page);
      }
    } else if (isHoveringTopPortionOfDevice(Page)) {
      scrollMobileDeviceWhileDragging("up", 1, Page);
    } else if (isHoveringBottomPortionOfDevice(Page)) {
      scrollMobileDeviceWhileDragging("down", 1, Page);
    } else if (window.isDraggingNewElement) {
      window.isDraggingNewElement = false;
      window.quitScrollingWhileDragging = true;
    }
  }
};
export const addDraggedElement = async ({ element, existingElement }, Page) => {
  // hovered and dragging elements Display is removed from the body

  Page.ElementsDisplay && Page.ElementsDisplay.remove();
  Page.ElementsDisplay = null;
  //
  // existingElement will be null it user is dragging element from Panel
  const { elementsThatCanBeAddedToPage } = Page.props;
  if (!elementBeingHoveredOver(Page)) {
    return;
  }
  // cancel animations
  hideMars(Page);
  window.cancelAnimationFrame(Page.animation);
  //
  let hoveredElement = elementBeingHoveredOver(Page).el;

  Page.wasAnyElementMoved = true;
  const pageElements = Page.props.pageElements.toJS();
  let addedElement,
    hoveredParentElement,
    hoveredGrandParentElement,
    droppingObject,
    hoveredModule,
    existingElementIndex,
    existingElementParentObj,
    currentRow,
    parentSort,
    type;
  // finding hovered element's parent
  pageElements.forEach((obj, index) => {
    if (obj.id == hoveredElement.moduleId) {
      hoveredModule = obj;
      hoveredElement = findElementObject(hoveredModule, hoveredElement.id);
      hoveredParentElement = findElementObject(hoveredModule, hoveredElement.parentId);
      if (hoveredParentElement && hoveredParentElement.parentId) {
        hoveredGrandParentElement = findElementObject(hoveredModule, hoveredParentElement.parentId);
      }
    }
  });
  // finding element that will be added
  if (!existingElement) {
    if (!element.get("editorType")) {
      addedElement = element.toJS();
    } else {
      // came from panel
      if (element.get("element").toJS().element) {
        addedElement = element.get("element").toJS().element;
      } else {
        addedElement = element.get("element").toJS();
      }
    }
  } else {
    addedElement = existingElement;

    pageElements.forEach((obj, index) => {
      if (obj.id == existingElement.moduleId) {
        existingElementParentObj = findElementObject(obj, existingElement.parentId);
      }
    });
  }

  droppingObject = {
    side: Page.Mars.side,
    hoveredParentElement,
    addedElement,
    hoveredElement,
    pageElements,
  };

  if (isAnElement(addedElement.el) || isAWrapperDiv(addedElement.el)) {
    if (isAnElement(hoveredElement.el)) {
      droppingElementOverElement(droppingObject, Page);
    } else if (isAWrapperDiv(hoveredElement.el)) {
      if (isAWrapperDiv(addedElement.el)) {
        droppingWrapperDivOverWrapperDiv(droppingObject, Page);
      } else {
        droppingElementOverWrapperDiv(droppingObject, Page);
      }
    } else if (isASlider(hoveredElement.el)) {
      droppingElementOverSlider(droppingObject, Page);
    } else if (isAColumn(hoveredElement.el)) {
      droppingElementOverColumn(droppingObject, Page);
    } else if (isARow(hoveredElement.el)) {
      droppingElementOverRow(droppingObject, Page);
    } else if (isASection(hoveredElement.el)) {
      //hovering a section
      droppingElementOverSection(droppingObject, Page);
    }
  } else if (isAColumn(addedElement.el)) {
    if (isAnElement(hoveredElement.el) || isAWrapperDiv(hoveredElement.el)) {
      droppingObject.hoveredElement = getElementInHoverArray(isAColumn, Page).el;
      droppingObject.hoveredParentElement = hoveredGrandParentElement;
      droppingColumnOverColumn(droppingObject, Page);
    } else if (isAColumn(hoveredElement.el)) {
      droppingColumnOverColumn(droppingObject, Page);
    } else if (isARow(hoveredElement.el)) {
      droppingColumnOverRow(droppingObject, Page);
    } else if (isASection(hoveredElement.el)) {
      //hovering a section
      droppingColumnOverSection(droppingObject, Page);
    }
  } else if (isARow(addedElement.el)) {
    if (isAnElement(hoveredElement.el) || isAWrapperDiv(hoveredElement.el)) {
      droppingObject.hoveredElement = getElementInHoverArray(isARow, Page).el;
      droppingObject.hoveredParentElement = findElementObject(
        hoveredModule,
        hoveredGrandParentElement.parentId
      );
      droppingRowOverRow(droppingObject, Page);
    } else if (isAColumn(hoveredElement.el)) {
      droppingObject.hoveredElement = getElementInHoverArray(isARow, Page).el;
      droppingObject.hoveredParentElement = hoveredGrandParentElement;
      droppingRowOverRow(droppingObject, Page);
    } else if (isARow(hoveredElement.el)) {
      droppingRowOverRow(droppingObject, Page);
    } else if (isASection(hoveredElement.el)) {
      //hovering a section
      droppingRowOverSection(droppingObject, Page);
    }
  } else if (isASlider(addedElement.el)) {
    if (isAColumn(hoveredElement.el)) {
      droppingSliderOverColumn(droppingObject, Page);
    } else {
      Page.wasAnyElementMoved = false;
    }
  } else if (isASection(addedElement.el)) {
    //dragging a section
    droppingObject.hoveredElement = getActiveModule(hoveredElement.moduleId, pageElements);

    droppingSectionOverSection(droppingObject, Page);
  }
  // deleting existing element
  if (existingElement && Page.wasAnyElementMoved) {
    if (existingElement.parentId) {
      existingElementIndex = existingElementParentObj.children.findIndex(elem => {
        return elem.id === existingElement.id;
      });
      existingElementParentObj.children.splice(existingElementIndex, 1);
      checkAndDeleteParents(pageElements, existingElementParentObj, existingElement.moduleId, Page);
    } else {
      existingElementIndex = pageElements.findIndex(elem => {
        return elem.id === existingElement.id;
      });
      pageElements.splice(existingElementIndex, 1);
    }
  }
  await Page.context.store.dispatch(setElements(pageElements));
  if (window.wasEditorPanelActive) {
    type = CommonMethods.findType(addedElement.el, addedElement, Page);
    parentSort = pageElements.filter(obj => obj.id === addedElement.moduleId);
    parentSort = parentSort[0].sort;
    delete window.wasEditorPanelActive;
    await Page.props.dispatch(
      setEditor({
        editorType: { field: addedElement.id, type: type },
        isActiveComponent: parentSort,
        activeModuleId: addedElement.moduleId,
      })
    );
    Emitter.emit("UPDATE_SATURN_STATE", addedElement.id);
  } else if (!isASection(addedElement.el) && !isARow(addedElement.el)) {
    if (!existingElement) {
      type = CommonMethods.findType(addedElement.el, addedElement, Page);
      parentSort = pageElements.filter(obj => obj.id === addedElement.moduleId);
      await Page.props.dispatch(
        setEditor({
          editorType: { field: addedElement.id, type: type },
          isActiveComponent: parentSort,
          activeModuleId: addedElement.moduleId,
        })
      );
      Emitter.emit("UPDATE_SATURN_STATE", addedElement.id);
    }
  }
  setTimeout(() => {
    Emitter.emit("UPDATE_NEPTUNE_STATE", false);
  }, 50);
};

/**
 * Helper functions to check type of element
 */

export const isASection = elementType => {
  if (!elementType) {
    return;
  }
  return (
    elementType === "background" ||
    elementType === "section" ||
    elementType === "Blog.BlogDetailWrapper" ||
    elementType.toLowerCase().indexOf("heart") !== -1 ||
    elementType.toLowerCase().indexOf("container") !== -1 ||
    elementType.toLowerCase().indexOf("header") !== -1 ||
    elementType.toLowerCase().indexOf("footer") !== -1
  );
};

export const isARow = elementType => {
  if (!elementType) {
    return;
  }
  return elementType === "row" || elementType.toLowerCase().indexOf("row") !== -1;
};

export const isAColumn = elementType => {
  if (!elementType) {
    return;
  }
  return elementType === "column" || elementType.toLowerCase().indexOf("column") !== -1;
};

export const isAWrapperDiv = (elementType, isDraggingASlider, Page) => {
  if (!elementType) {
    return;
  }
  if (isDraggingASlider) {
    if (getElementInHoverArray(isASlider, Page).elementName) {
      return false;
    }
  }
  return (
    elementType === "wrapperDiv" ||
    elementType.toLowerCase().indexOf("wrapperdiv") !== -1 ||
    elementType === "DivWithOverlay" ||
    elementType.toLowerCase().indexOf("divwithoverlay") !== -1
  );
};

export const isASlider = elementType => {
  if (!elementType) {
    return;
  }

  return (
    elementType === "ConvertlySlide" ||
    elementType.toLowerCase().indexOf("convertlyslider") !== -1 ||
    elementType.toLowerCase().includes("slide")
  );
};

export const isAnElement = (elementType, isDraggingASlider, Page) => {
  if (isDraggingASlider) {
    if (getElementInHoverArray(isASlider, Page).elementName) {
      return false;
    }
  }
  return (
    !isASection(elementType) &&
    !isARow(elementType) &&
    !isAColumn(elementType) &&
    !isAWrapperDiv(elementType) &&
    !isASlider(elementType)
  );
};
/**
 * Getters
 */

export const isHoveringOverAPageElement = Page => {
  return !!Page.elementsBeingHovered.length;
};

export const userIsDraggingAPageElement = Page => {
  return !!Page.props.draggingNewElement;
};

export const elementBeingHoveredOver = Page => {
  return Page.elementsBeingHovered[Page.elementsBeingHovered.length - 1];
};
export const createDraggingImageElement = Page => {
  // setting empty dragging ghost element
  // create an empty <span>

  Page.dragImgEl = document.createElement("span");
  // setting its style so it'll be effectively (but not technically) invisible and
  // won't change document flow
  Page.dragImgEl.id = "dragImgEl";
  Page.dragImgEl.innerHTML = "invisible dragging ghost image";
  Page.dragImgEl.style.position = "absolute";
  Page.dragImgEl.style.color = "transparent";
  // add it to the document
  document.body.appendChild(Page.dragImgEl);
};
export const isHoveringBottomPortionOfDevice = Page => {
  if (!document.getElementsByClassName("view2")[0]) {
    return false;
  }
  const draggedElement = Page.newElementBeingDragged;
  const mobileDevice = document.getElementsByClassName("view2")[0];
  const rect = mobileDevice.getBoundingClientRect();
  if (draggedElement.clientX > rect.left && draggedElement.clientX < rect.right) {
    if (draggedElement.clientY > rect.bottom + 20) {
      return true;
    }
  }
  return false;
};
export const repositionMars = (element, Page) => {
  const page = document.getElementById("editorPageWrapper");
  let scrollLeft;
  if (page) {
    scrollLeft = page.getBoundingClientRect().left;
  }
  if (!element.box) {
    return;
  }
  Page.Mars.style.top = `calc( ${element.box.top + Page.scrollOffset}px )`;
  Page.Mars.style.left = `${element.box.left - scrollLeft}px`;
};
export const scrollMobileDeviceWhileDragging = (direction, increment, Page) => {
  const { draggingNewElement } = Page.props;
  if (direction === "up") {
    Page.scrollBar.view.scrollTop -= increment;
    if (isHoveringTopPortionOfDevice(Page) && draggingNewElement) {
      Page.Mars.style.opacity = 0;
      setTimeout(() => {
        scrollMobileDeviceWhileDragging("up", increment, Page);
      }, 10);
    } else {
      Page.Mars.style.opacity = 1;
      Page.componentDidMount();
    }
  } else {
    Page.scrollBar.view.scrollTop += increment;
    if (isHoveringBottomPortionOfDevice(Page) && draggingNewElement) {
      Page.Mars.style.opacity = 0;
      setTimeout(() => {
        scrollMobileDeviceWhileDragging("down", increment, Page);
      }, 10);
    } else {
      Page.Mars.style.opacity = 1;
      Page.componentDidMount();
    }
  }
};
export const scrollWhileDragging = (body, i, direction, Page) => {
  const { editorViewportWidth, draggingNewElement } = Page.props;
  let increment = direction === "down" ? 5 : -5;
  i = i + increment;
  const mainScrollPage = document.getElementsByClassName("view")[0].children[0];
  Emitter.emit("SCROLL_TO_FOCUSED_ELEMENT", i);

  setTimeout(() => {
    // it'll keep scrolling until mouse hovers off the bottom or top part of the body of the page
    if (!window.quitScrollingWhileDragging) {
      Page.Mars.style.opacity = 0;
      if (editorViewportWidth && editorViewportWidth !== "desktop") {
        if (isHoveringBottomPortionOfDevice(Page)) {
          scrollMobileDeviceWhileDragging("down", 5, Page);
          return;
        }
        if (isHoveringTopPortionOfDevice(Page)) {
          scrollMobileDeviceWhileDragging("up", 5, Page);
          return;
        }
      }
      if (!draggingNewElement) {
        Page.elementsBeingHovered.length = 0;
        Page.componentDidMount();
        return;
      }
      scrollWhileDragging(body, i, direction, Page);
    } else {
      Page.componentDidMount();
    }
  }, 2);
};
export const isHoveringTopPortionOfDevice = Page => {
  if (!document.getElementsByClassName("view2")[0]) {
    return false;
  }
  const draggedElement = Page.newElementBeingDragged;
  const mobileDevice = document.getElementsByClassName("view2")[0];
  const rect = mobileDevice.getBoundingClientRect();
  if (draggedElement.clientX > rect.left && draggedElement.clientX < rect.right) {
    if (draggedElement.clientY < rect.top - 20) {
      return true;
    }
  }
  return false;
};
export const scaleGhostGradually = (
  { scaleCoefficient, clone, nodeWidth, nodeHeight, event },
  Page
) => {
  const interval = (1 - scaleCoefficient) / 10;
  let initialCoefficient = 1;
  Page.draggingElementClone.style.margin = `0px`;
  const stopScaling = () => {
    let clientX, clientY;
    if (Page.draggingElementClone) {
      clientX = Page.draggingElementClone.clientX;
      clientY = Page.draggingElementClone.clientY;
      Page.draggingElementClone.style.top = `${clientY - Number(nodeHeight.slice(0, -2)) / 2}px`;
      Page.draggingElementClone.style.left = `${clientX - Number(nodeWidth.slice(0, -2)) / 2}px`;
    }
    clearInterval(f);
  };
  if (scaleCoefficient === 1) {
    Page.draggingElementClone.style.top = `${event.clientY -
      Number(nodeHeight.slice(0, -2)) / 2}px`;
    Page.draggingElementClone.style.left = `${event.clientX -
      Number(nodeWidth.slice(0, -2)) / 2}px`;
    return;
  }
  const startScaling = () => {
    clone.style.transform = `scale(${initialCoefficient})`;
    if (initialCoefficient <= scaleCoefficient) {
      stopScaling();
    }
    if (!Page.draggingElementClone) {
      // if stopped dragging ghost
      stopScaling();
    }
    initialCoefficient -= interval;
  };
  const f = setInterval(startScaling, 5);
};
export const onDrag = (e, Page) => {
  e.stopPropagation();
  if (Page.props.editingBlogPost) {
    return;
  }
  const { clientX, clientY } = e;
  const node = e.target;
  let dx, dy, marginTop, marginLeft;
  Page.draggingElementClone.clientX = clientX;
  Page.draggingElementClone.clientY = clientY;
  // this if statement was added because when you drag a node using a mouse rather than a
  // trackpad, then the event's clientX and clientY gets set to zero at times.
  if (
    Page.draggingElementClone.style.top == "0px" &&
    Page.draggingElementClone.style.left == "0px"
  ) {
    Page.draggingElementClone.style.top = `${node.getBoundingClientRect().top}px`;
    Page.draggingElementClone.style.left = `${node.getBoundingClientRect().left}px`;
    Page.draggingElementClone.style.opacity = GHOST_OPACITY;
  } else if (clientX || clientY) {
    // making sure clientX and clientY are not both equal to zero
    onDragNewElement({ clientX, clientY }, Page);
    dx = e.clientX - Page.draggingElementClone.currentClientX;
    dy = e.clientY - Page.draggingElementClone.currentClientY;
    Page.draggingElementClone.style.top = `${Number(
      Page.draggingElementClone.style.top.slice(0, -2)
    ) + dy}px`;
    Page.draggingElementClone.style.left = `${Number(
      Page.draggingElementClone.style.left.slice(0, -2)
    ) + dx}px`;
    Page.draggingElementClone.currentClientY = e.clientY;
    Page.draggingElementClone.currentClientX = e.clientX;
  }
};
export const onStartDragging = (node, element, e, Page) => {
  e.stopPropagation();
  if (getComputedStyle(node).transition) {
    node.style.transition = "all 0s ease 0s";
  }
  if (sliderOverride(element.el)) {
    element = findParentElement(Page.pageElements, findParentElement(Page.pageElements, element));
    node = document.querySelector(`[data-editor=c${element.id}]`);
  }
  if (Page.props.editingBlogPost) {
    return;
  }
  Page.animation = window.requestAnimationFrame(() => draggingHandler(Page));
  if (Page.props.editorType.field) {
    Page.context.store.dispatch(clearEditorPanel());
    window.wasEditorPanelActive = true;
  }
  Page.props.dispatch(setDraggingNewElement(element));
  // create hovered and dragging element display
  can("view_newEditorNav") && createAndUpdateElementsDisplay(Page);
  // setting empty dragging ghost element

  const dragImgEl = document.getElementById("dragImgEl");

  e.dataTransfer.setDragImage(dragImgEl, 0, 0);

  Page.draggingElementClone = node.cloneNode(true);
  Page.draggingElementClone.style.pointerEvents = "none";
  Page.draggingElementClone.style.transform = "";
  document.body.appendChild(Page.draggingElementClone);
  const nodeWidth = getComputedStyle(node).width;
  const nodeHeight = getComputedStyle(node).height;
  const scaleCoefficient = calculateScaleCoefficient({
    height: Number(nodeHeight.slice(0, -2)),
    width: Number(nodeWidth.slice(0, -2)),
  });

  Page.draggingElementClone.style.width = nodeWidth;
  Page.draggingElementClone.style.outline = "1px dashed gray";
  Page.draggingElementClone.currentClientY = e.clientY;
  Page.draggingElementClone.currentClientX = e.clientX;
  // start scalling gradually
  scaleGhostGradually(
    {
      scaleCoefficient,
      clone: Page.draggingElementClone,
      event: e,
      nodeWidth,
      nodeHeight,
    },
    Page
  );

  Page.draggingElementClone.style.transform = `scale(${scaleCoefficient})`;
  // defining Dragging Element Clone Initial Position
  Page.draggingElementClone.style.top = `0px`;
  Page.draggingElementClone.style.left = `0px`;
  Page.draggingElementClone.style.opacity = 0; // so it's not visible at this coordinate
  Page.draggingElementClone.style.zIndex = 999999; // high zindex to ensure ghost is on top
  Page.draggingElementClone.style.position = "absolute";
  if (getComputedStyle(node).textAlign && getComputedStyle(node).textAlign === "center") {
    Page.draggingElementClone.style.textAlign = "center";
  }
  Page.draggingElementClone.id = "drag-image";
  const { clientX, clientY } = e;
  node.style.opacity = DRAGGED_ELEMENT_OPACITY;
  onDragNewElement({ clientX, clientY }, Page);
  Emitter.emit("UPDATE_SATURN_STATE", false);
  Emitter.emit("UPDATE_NEPTUNE_STATE", false);
};

export const onEndDragging = async (node, element, e, Page) => {
  e.stopPropagation();
  if (sliderOverride(element.el)) {
    element = findParentElement(Page.pageElements, findParentElement(Page.pageElements, element));
    node = document.querySelector(`[data-editor=c${element.id}]`);
  }
  if (Page.props.editingBlogPost) {
    return;
  }
  Page.draggingElementClone.remove();
  Page.draggingElementClone = null;
  node.style.opacity = 1;
  await addDraggedElement({ existingElement: element }, Page);
  Page.props.dispatch(setDraggingNewElement(null));
};
export const calculateScaleCoefficient = ({ height, width }) => {
  if (height > width) {
    if (height > GHOST_MAX_DIMENSION) {
      return GHOST_MAX_DIMENSION / height;
    } else return 1;
  } else {
    if (width > GHOST_MAX_DIMENSION) {
      return GHOST_MAX_DIMENSION / width;
    } else return 1;
  }
};
/**
 * Helper function if two rectangles are overlapping
 * https://stackoverflow.com/questions/2752349/fast-rectangle-to-rectangle-intersection
 * @param r1
 * @param r2
 * @returns {boolean}
 */
export const intersectRect = (r1, r2) => {
  return !(r2.left > r1.right || r2.right < r1.left || r2.top > r1.bottom || r2.bottom < r1.top);
};
export const checkIfHoveringOverPageElements = Page => {
  Page.elementsBeingHovered.length = 0;
  Page.flatElements.forEach(elem => checkIfDraggingOverElement(elem, Page));
};

/**
 * Check what type of element is being dragged and move to handle function
 */
export const handleIsHoveringOverAPageElement = Page => {
  const draggingType =
    Page.props.draggingNewElement.get("editorType") || Page.props.draggingNewElement.get("el");
  const hoveringType = elementBeingHoveredOver(Page).elementName;
  const args = {
    hoveringElement: elementBeingHoveredOver(Page),
    draggingElement: Page.newElementBeingDragged,
    draggingType,
    hoveringType,
  };
  if (window.isDraggingNewElement) {
    // only true if scrolling while dragging
    return;
  }
  isASection(draggingType) && handleDraggingASection(args, Page);

  isARow(draggingType) && handleDraggingARow(args, Page);

  isAColumn(draggingType) && handleDraggingAColumn(args, Page);

  isAWrapperDiv(draggingType) && handleDraggingAWrapperDiv(args, Page);

  isASlider(draggingType) && handleDraggingASlider(args, Page);

  isAnElement(draggingType) && handleDraggingAnElement(args, Page);

  Page.Mars.style.display = "block";
};

/**
 * Start Dragging Section functions
 */

export const handleDraggingASection = ({ hoveringType, ...args }, Page) => {
  isASection(hoveringType) && handleDraggingSectionOverSection(args, Page);

  isARow(hoveringType) && handleDraggingSectionOverRow(args, Page);

  isAColumn(hoveringType) && handleDraggingSectionOverColumn(args, Page);

  isAWrapperDiv(hoveringType) && handleDraggingSectionOverWrapperDiv(args, Page);

  isASlider(hoveringType) && handleDraggingSectionOverElement(args, Page);

  isAnElement(hoveringType) && handleDraggingSectionOverElement(args, Page);
};
export const handleDraggingASlider = ({ hoveringType, ...args }, Page) => {
  //isASection(hoveringType) && handleDraggingSectionOverSection(args, Page);

  //isARow(hoveringType) && handleDraggingSectionOverRow(args, Page);

  isAColumn(hoveringType) && handleDraggingWrapperDivOverColumn(args, Page);

  isAWrapperDiv(hoveringType, true, Page) && handleDraggingWrapperDivOverWrapperDiv(args, Page);

  // isASlider(hoveringType) && handleDraggingWrapperDivOverElement(args, Page);

  isAnElement(hoveringType, true, Page) && handleDraggingWrapperDivOverElement(args, Page);
};
export const droppingElementOverSlider = (
  { side, hoveredParentElement, addedElement, hoveredElement, pageElements },
  Page
) => {
  const column = _.cloneDeep(columnObject);
  if (!hoveredElement.children.length) {
    side = "top";
  }
  switch (side) {
    case "bottom":
      addedElement.sort = 1;
      hoveredElement.children.push(addedElement);
      break;
    case "top":
      addedElement.sort = hoveredElement.children.length * 16 + 16;
      hoveredElement.children.push(addedElement);
      break;
    case "left":
      column.children.push(addedElement);
      column.sort = hoveredElement.sort + 8;
      hoveredParentElement.children.push(column);
      break;
    default:
      // right
      column.children.push(addedElement);
      column.sort = hoveredElement.sort - 8;
      hoveredParentElement.children.push(column);
  }
};
export const droppingElementOverElement = (
  { side, hoveredParentElement, addedElement, hoveredElement, pageElements },
  Page
) => {
  let hoveredElementIndex;
  const wrapperDiv = _.cloneDeep(wrapperDivObj);
  const hoveredGrandParentElement = findParentElement(pageElements, hoveredParentElement);
  switch (side) {
    case "bottom":
      if (
        hoveredParentElement.data.userOverrides &&
        hoveredParentElement.data.userOverrides.display === "flex"
      ) {
        addedElement.sort = hoveredParentElement.sort - 8;
        hoveredGrandParentElement.children.push(addedElement);
        break;
      }
      addedElement.sort = hoveredElement.sort - 8;
      hoveredParentElement.children.push(addedElement);
      break;
    case "top":
      if (
        hoveredParentElement.data.userOverrides &&
        hoveredParentElement.data.userOverrides.display === "flex"
      ) {
        addedElement.sort = hoveredParentElement.sort + 8;
        hoveredGrandParentElement.children.push(addedElement);
        break;
      }
      addedElement.sort = hoveredElement.sort + 8;
      hoveredParentElement.children.push(addedElement);
      break;
    default:
      // right or left
      if (
        hoveredParentElement.data.userOverrides &&
        hoveredParentElement.data.userOverrides.display === "flex"
      ) {
        if (side === "left") {
          addedElement.sort = hoveredElement.sort + 8;
          hoveredParentElement.children.push(addedElement);
        } else {
          // side is right
          addedElement.sort = hoveredElement.sort - 8;
          hoveredParentElement.children.push(addedElement);
        }
        break;
      }
      wrapperDiv.children.push(addedElement);
      wrapperDiv.children.push(hoveredElement);
      hoveredElementIndex = hoveredParentElement.children.findIndex(elem => {
        return elem.id === hoveredElement.id;
      });
      hoveredParentElement.children.splice(hoveredElementIndex, 1);
      wrapperDiv.sort = hoveredElement.sort;
      if (side === "left") {
        addedElement.sort = 32;
        hoveredElement.sort = 16;
      } else {
        addedElement.sort = 16;
        hoveredElement.sort = 32;
      }
      hoveredParentElement.children.splice(hoveredElementIndex, 0, wrapperDiv);
  }
};
export const droppingElementOverWrapperDiv = (
  { side, hoveredParentElement, addedElement, hoveredElement, pageElements },
  Page
) => {
  const hoveredGrandParentElement = findParentElement(pageElements, hoveredParentElement);
  if (!hoveredElement.children.length) {
    hoveredElement.data.userOverrides = hoveredElement.data.userOverrides || {};
    if (hoveredElement.data.userOverrides.height) {
      delete hoveredElement.data.userOverrides.height;
    }
    if (hoveredElement.data.userOverrides.backgroundColor === "#cccccc") {
      delete hoveredElement.data.userOverrides.backgroundColor;
    }
    if (hoveredElement.data.userOverrides && hoveredElement.data.userOverrides.display === "flex") {
      side = "left";
    } else {
      side = "top";
    }
  }
  switch (side) {
    case "bottom":
      if (
        hoveredElement.data.userOverrides &&
        hoveredElement.data.userOverrides.display === "flex"
      ) {
        addedElement.sort = hoveredElement.sort - 8;
        hoveredParentElement.children.push(addedElement);
      } else {
        addedElement.sort = 8;
        hoveredElement.children.push(addedElement);
      }
      break;
    case "top":
      if (
        hoveredElement.data.userOverrides &&
        hoveredElement.data.userOverrides.display === "flex"
      ) {
        addedElement.sort = hoveredElement.sort + 8;
        hoveredParentElement.children.push(addedElement);
      } else {
        addedElement.sort = hoveredElement.children.length * 16 + 8;
        hoveredElement.children.push(addedElement);
      }
      break;
    default:
      // right or left
      if (
        hoveredElement.data.userOverrides &&
        hoveredElement.data.userOverrides.display === "flex"
      ) {
        if (side === "left") {
          addedElement.sort = hoveredElement.children.length * 16 + 8;
          hoveredElement.children.push(addedElement);
        } else {
          // side is right
          addedElement.sort = 8;
          hoveredElement.children.push(addedElement);
        }
      } else {
        return;
      }
  }
};
export const droppingSliderOverColumn = (
  { side, hoveredParentElement, addedElement, hoveredElement, pageElements },
  Page
) => {
  droppingElementOverColumn(
    { side, hoveredParentElement, addedElement, hoveredElement, pageElements },
    Page
  );
};
export const droppingElementOverColumn = (
  { side, hoveredParentElement, addedElement, hoveredElement, pageElements },
  Page
) => {
  const column = _.cloneDeep(columnObject);
  if (!hoveredElement.children.length) {
    hoveredElement.data.userOverrides = hoveredElement.data.userOverrides || {};
    if (hoveredElement.data.userOverrides.background === emptyColumnBackground) {
      delete hoveredElement.data.userOverrides.background;
      if (hoveredElement.data.userOverrides.height) {
        delete hoveredElement.data.userOverrides.height;
      }
      if (hoveredElement.data.userOverrides.border) {
        delete hoveredElement.data.userOverrides.border;
      }
    }
    side = "top";
  }
  switch (side) {
    case "bottom":
      addedElement.sort = 1;
      hoveredElement.children.push(addedElement);
      break;
    case "top":
      addedElement.sort = hoveredElement.children.length * 16 + 16;
      hoveredElement.children.push(addedElement);
      break;
    case "left":
      column.children.push(addedElement);
      column.sort = hoveredElement.sort + 8;
      hoveredParentElement.children.push(column);
      break;
    default:
      // right
      column.children.push(addedElement);
      column.sort = hoveredElement.sort - 8;
      hoveredParentElement.children.push(column);
  }
};
export const droppingElementOverRow = (
  { side, hoveredParentElement, addedElement, hoveredElement, pageElements },
  Page
) => {
  let column = _.cloneDeep(columnObject);
  column.children = [addedElement];
  switch (side) {
    case "left":
      column.sort = hoveredElement.children.length * 16 + 8;
      hoveredElement.children.push(column);
      break;
    case "right":
      column.sort = 8;
      hoveredElement.children.push(column);
    default:
      Page.wasAnyElementMoved = false;
  }
};
export const droppingElementOverSection = (
  { side, hoveredParentElement, addedElement, hoveredElement, pageElements },
  Page
) => {
  const section = _.cloneDeep(customSection);
  section.children[0].children[0].children[0].data.userOverrides = {};
  section.children[0].children[0].children[0].children.push(addedElement);
  switch (side) {
    case "top":
      section.sort = hoveredElement.sort + 8;
      pageElements.push(section);
      break;
    case "bottom":
      section.sort = hoveredElement.sort - 8;
      pageElements.push(section);
  }
};
export const findLargestColumn = (columnArray, Page) => {
  return columnArray.reduce(findLargest);
  function findLargest(largestCol, col) {
    const width1 = findOffsetAndColumnWidth(largestCol, Page).columnWidth;
    const width2 = findOffsetAndColumnWidth(col, Page).columnWidth;
    if (width1 > width2) {
      return largestCol;
    } else {
      return col;
    }
  }
};
export const redistributeColumnWidth = (
  { columnArray, largestColumn, largestColumnWidth, column, columnKey, columnKeyArray },
  Page
) => {
  // this function redistribute columns width if you add a new column and the space is full
  // columnKeyArray has all width column keys lower than the current viewport
  largestColumn.data[columnKey] = largestColumnWidth - 1;
  if (!column.data[columnKey]) {
    column.data[columnKey] = 1;
    assignColumnWidthToLowerViewport({
      columnData: column.data,
      value: 1,
      columnKeyArray,
    });
  } else {
    column.data[columnKey] += 1;
    assignColumnWidthToLowerViewport({
      columnData: column.data,
      value: column.data[columnKey] + 1,
      columnKeyArray,
    });
  }
  largestColumn = findLargestColumn(columnArray, Page);
  largestColumnWidth = findOffsetAndColumnWidth(largestColumn, Page).columnWidth;
  if (largestColumnWidth > 1 && column.data[columnKey] < 2) {
    redistributeColumnWidth(
      { columnArray, largestColumn, largestColumnWidth, column, columnKey, columnKeyArray },
      Page
    );
  }
  return;
};
export const assignWidthToColumns = ({ hoveredParentElement, column, pageElements }, Page) => {
  const { maxWidthValue } = defineMaxValue(Page, hoveredParentElement, column);
  const { columnKey, columnKeyArray } = findOffsetAndColumnWidth(column, Page);
  // columnKeyArray has all width column keys lower than the current viewport
  let columnArray, largestColumn, largestColumnWidth;
  if (maxWidthValue >= 3) {
    column.data[columnKey] = 3;
    assignColumnWidthToLowerViewport({
      columnData: column.data,
      value: 3,
      columnKeyArray,
    });
  } else if (maxWidthValue > 0) {
    column.data[columnKey] = maxWidthValue;
    assignColumnWidthToLowerViewport({
      columnData: column.data,
      value: maxWidthValue,
      columnKeyArray,
    });
  } else {
    columnArray = hoveredParentElement.children;
    largestColumn = findLargestColumn(columnArray, Page);
    largestColumnWidth = findOffsetAndColumnWidth(largestColumn, Page).columnWidth;
    redistributeColumnWidth(
      { columnArray, largestColumn, largestColumnWidth, column, columnKey, columnKeyArray },
      Page
    );
  }
};
// defineMaxValue define the available space for a new column or for offset
export const defineMaxValue = (Page, hoveredParentElement, column) => {
  let row;
  if (!hoveredParentElement) {
    row = findParentElement(Page.props.pageElements, Page.props.objectBeingEdited);
    column = Page.props.objectBeingEdited;
  } else {
    row = hoveredParentElement;
  }
  let { columnWidth, offset } = findOffsetAndColumnWidth(column, Page);
  if (hoveredParentElement) {
    columnWidth = 0;
    offset = 0;
  }
  let columnWidthAvailable = 12;
  let offsetAvailable = 12;
  row.children.forEach(col => {
    offsetAvailable -= findOffsetAndColumnWidth(col, Page).columnWidth;
    offsetAvailable -= findOffsetAndColumnWidth(col, Page).offset;
    if (col.id !== column.id) {
      columnWidthAvailable -= findOffsetAndColumnWidth(col, Page).columnWidth;
    }
    columnWidthAvailable -= findOffsetAndColumnWidth(col, Page).offset;
  });
  let maxWidthValue =
    columnWidth > Math.floor(columnWidthAvailable) ? columnWidth : Math.floor(columnWidthAvailable);
  let maxOffsetValue = offset > Math.floor(offsetAvailable) ? offset : Math.floor(offsetAvailable);
  return { maxWidthValue, maxOffsetValue };
};
export const findOffsetAndColumnWidth = (column, Page) => {
  const columnKeyArray = [];
  let offset, columnWidth, columnKey, offsetKey;
  switch (Page.props.editorViewportWidth) {
    case "mobile":
      offset = column.data.xsOffset || 0;
      columnWidth = column.data.xsColumns || 12;
      columnKey = "xsColumns";
      offsetKey = "xsOffset";
      break;
    case "smallTablet":
      offset = column.smOffset || column.data.xsOffset || 0;
      columnWidth = column.data.smColumns || column.data.xsColumns;
      columnKey = "smColumns";
      offsetKey = "smOffset";
      columnKeyArray.push("xsColumns");
      break;
    case "largeTablet":
      offset = column.data.mdOffset || column.data.smOffset || column.data.xsOffset || 0;
      columnWidth = column.data.mdColumns || column.data.smColumns || column.data.xsColumns;
      columnKey = "mdColumns";
      offsetKey = "mdOffset";
      columnKeyArray.push("smColumns");
      columnKeyArray.push("xsColumns");
      break;
    default:
      offset =
        column.data.lgOffset ||
        column.data.mdOffset ||
        column.data.smOffset ||
        column.data.xsOffset ||
        0;
      columnWidth =
        column.data.lgColumns ||
        column.data.mdColumns ||
        column.data.smColumns ||
        column.data.xsColumns;
      columnKey = "lgColumns";
      offsetKey = "lgOffset";
      columnKeyArray.push("mdColumns");
      columnKeyArray.push("smColumns");
      columnKeyArray.push("xsColumns");
  }
  return { offset, columnWidth, columnKey, offsetKey, columnKeyArray };
};
export const assignColumnWidthToLowerViewport = ({ columnData, columnKeyArray, value }) => {
  columnKeyArray.forEach((colKey, index) => {
    if (!columnData[colKey]) {
      if (colKey === "xsColumns") {
        columnData[colKey] = 12;
      } else {
        columnData[colKey] = value;
      }
    }
  });
};
export const calculateColumnsWidthAndOffset = (
  { hoveredParentElement, column, pageElements, isLastColumn },
  Page
) => {
  let offsetField,
    columnCommonWidth,
    areColumnsEqual,
    newWidth,
    columnParent,
    availableSpace,
    hoveredGrandParentElement,
    offsetSpace;
  const { columnKey, offsetKey, columnKeyArray } = findOffsetAndColumnWidth(
    hoveredParentElement.children[0],
    Page
  );
  const row = _.cloneDeep(rowObject);
  hoveredParentElement.data.userOverrides = hoveredParentElement.data.userOverrides || {};
  columnCommonWidth = findOffsetAndColumnWidth(hoveredParentElement.children[0], Page).columnWidth;
  areColumnsEqual = hoveredParentElement.children.every(el => {
    return columnCommonWidth === findOffsetAndColumnWidth(el, Page).columnWidth;
  });
  if (column) {
    // if it's not only deleting row
    availableSpace = defineMaxValue(Page, hoveredParentElement, column).maxWidthValue;
    offsetSpace = 12 - hoveredParentElement.children.length * columnCommonWidth - availableSpace;
  }
  if (!areColumnsEqual) {
    assignWidthToColumns({ hoveredParentElement, column, pageElements }, Page);
  }
  // added row part
  else if (column && column.parentId !== hoveredParentElement.id) {
    hoveredGrandParentElement = findParentElement(pageElements, hoveredParentElement);
    if (hoveredParentElement.children.length + 1 > 12 - offsetSpace) {
      // push last column into new row
      if (!isLastColumn) {
        row.children = [hoveredParentElement.children[0]];
        assignColumnWidthToLowerViewport({
          columnData: hoveredParentElement.children[0].data,
          value: 12,
          columnKeyArray,
        });
        hoveredParentElement.children[0].data[columnKey] = 12;
        hoveredParentElement.children[0].data[offsetKey] = 0;
        hoveredParentElement.children.splice(0, 1);
        column.data[columnKey] = 1;
        assignColumnWidthToLowerViewport({
          columnData: column.data,
          value: 1,
          columnKeyArray,
        });
      } else {
        row.children = [column];
        column.data[columnKey] = 12;
        assignColumnWidthToLowerViewport({
          columnData: column.data,
          value: 12,
          columnKeyArray,
        });
        column.data.userOverrides.height = "300px";
      }
      row.sort = hoveredParentElement.sort - 8;
      hoveredGrandParentElement.children.push(row);
    } else if ((hoveredParentElement.children.length + 1) * columnCommonWidth + offsetSpace > 12) {
      newWidth = Math.floor(
        (12 - ((12 - availableSpace) % columnCommonWidth)) /
          (hoveredParentElement.children.length + 1)
      );
      column.data[columnKey] = newWidth;
      assignColumnWidthToLowerViewport({
        columnData: column.data,
        value: newWidth,
        columnKeyArray,
      });
      hoveredParentElement.children.forEach(child => {
        child.data[columnKey] = newWidth;
        assignColumnWidthToLowerViewport({
          columnData: child.data,
          value: newWidth,
          columnKeyArray,
        });
      });
    } else {
      column.data[columnKey] = columnCommonWidth;
      assignColumnWidthToLowerViewport({
        columnData: column.data,
        value: columnCommonWidth,
        columnKeyArray,
      });
      hoveredParentElement.children.forEach(child => {
        child.data[columnKey] = columnCommonWidth;
        assignColumnWidthToLowerViewport({
          columnData: child.data,
          value: columnCommonWidth,
          columnKeyArray,
        });
      });
    }
  }
  // deleting row part
  if (!column || (column.parentId && hoveredParentElement.id !== column.parentId)) {
    if (column) {
      columnParent = findParentElement(pageElements, column);
    } else {
      columnParent = hoveredParentElement;
    }
    areColumnsEqual = columnParent.children.every(el => {
      columnCommonWidth = Number(columnParent.children[0].data[columnKey]);
      return el.data[columnKey] === columnParent.children[0].data[columnKey];
    });
    if (!areColumnsEqual) {
      return;
    }
    if (columnParent.children.length * columnCommonWidth === 12) {
      if (columnParent.children.length > 1) {
        columnParent.children.forEach(child => {
          child.data[columnKey] = 12 / (columnParent.children.length - 1);
          assignColumnWidthToLowerViewport({
            columnData: child.data,
            value: 12 / (columnParent.children.length - 1),
            columnKeyArray,
          });
        });
      }
    }
  }
};
export const droppingWrapperDivOverWrapperDiv = (
  { side, hoveredParentElement, addedElement, hoveredElement, pageElements },
  Page
) => {
  if (!hoveredElement.children.length) {
    addedElement.sort = 16;
    hoveredElement.children.push(addedElement);
  } else {
    switch (side) {
      case "top":
        addedElement.sort = hoveredElement.sort + 8;
        hoveredParentElement.children.push(addedElement);
        break;
      case "bottom":
        addedElement.sort = hoveredElement.sort - 8;
        hoveredParentElement.children.push(addedElement);
        break;
      case "left":
        addedElement.sort = hoveredElement.sort + 8;
        hoveredParentElement.children.push(addedElement);
        break;
      default:
        addedElement.sort = hoveredElement.sort - 8;
        hoveredParentElement.children.push(addedElement);
    }
  }
};
export const droppingColumnOverColumn = (
  { side, hoveredParentElement, addedElement, hoveredElement, pageElements },
  Page
) => {
  let rowHeight, rowLengthBefore, isLastColumn;
  const { elementsThatCanBeAddedToPage } = Page.props;
  let column = addedElement;
  //!addedElement.children.length
  //? findBlankElement("Elements.Column", elementsThatCanBeAddedToPage)
  //: addedElement;
  if (!addedElement.children.length) {
    column.data.userOverrides = {
      //background: emptyColumnBackground,
      border: "1px solid black",
    };
  }
  let row = _.cloneDeep(rowObject);
  let hoveredGrandParentElement = findParentElement(pageElements, hoveredParentElement);
  switch (side) {
    case "left":
      if (!addedElement.children.length) {
        //column.data.userOverrides.background = emptyColumnBackground;
        column.data.xsColumns = 12;
      }
      if (hoveredElement.parentId !== addedElement.parentId) {
        // if it's adding a column
        calculateColumnsWidthAndOffset({ hoveredParentElement, column, pageElements }, Page);
      }
      column.sort = hoveredElement.sort + 8;
      hoveredParentElement.children.push(column);
      break;
    case "right":
      const initialNumberOfRows = hoveredGrandParentElement.children.length;
      let finalNumberOfRows;
      if (!addedElement.children.length) {
        //column.data.userOverrides.background = emptyColumnBackground;
        column.data.xsColumns = 12;
      }
      if (hoveredElement.parentId !== addedElement.parentId) {
        // if it's adding a column
        isLastColumn = hoveredParentElement.children[0].id === hoveredElement.id;
        //srowLengthBefore = hoveredParentElement.children.length;
        column.sort = hoveredElement.sort - 8;
        calculateColumnsWidthAndOffset(
          { hoveredParentElement, column, pageElements, isLastColumn },
          Page
        );
      }
      finalNumberOfRows = hoveredGrandParentElement.children.length;
      if (!isLastColumn || finalNumberOfRows === initialNumberOfRows) {
        column.sort = hoveredElement.sort - 8;
        hoveredParentElement.children.push(column);
      }
      break;
    case "top":
      //if (column.children.length) {
      row.children = [column];
      //}
      row.sort = hoveredParentElement.sort + 8;
      hoveredGrandParentElement.children.push(row);
      break;
    default:
      // bottom
      //f (column.children.length) {
      row.children = [column];
      //}
      row.sort = hoveredParentElement.sort - 8;
      hoveredGrandParentElement.children.push(row);
  }
};
export const droppingColumnOverRow = (
  { side, hoveredParentElement, addedElement, hoveredElement, pageElements },
  Page
) => {
  const column = addedElement;
  let rowLengthBefore;
  switch (side) {
    case "left":
      if (!addedElement.children.length) {
        //column.data.userOverrides.background = emptyColumnBackground;
        column.data.xsColumns = 12;
      }
      calculateColumnsWidthAndOffset(
        {
          hoveredParentElement: hoveredElement,
          column,
          pageElements,
        },
        Page
      );
      column.sort = 16 * hoveredElement.children.length + 8;
      hoveredElement.children.push(column);
      break;
    default:
      // right
      if (!addedElement.children.length) {
        //column.data.userOverrides.background = emptyColumnBackground;
        column.data.xsColumns = 12;
      }
      rowLengthBefore = hoveredElement.children.length;
      calculateColumnsWidthAndOffset(
        {
          hoveredParentElement: hoveredElement,
          column,
          pageElements,
          isLastColumn: true,
        },
        Page
      );
      if (hoveredElement.children.length === rowLengthBefore) {
        column.sort = 1;
        hoveredElement.children.push(column);
      }
  }
};
export const droppingColumnOverSection = (
  { side, hoveredParentElement, addedElement, hoveredElement, pageElements },
  Page
) => {
  let column = !addedElement.children.length ? _.cloneDeep(columnObject) : addedElement;
  const section = _.cloneDeep(customSection);
  section.children[0].children[0].children[0].data.userOverrides = {};
  section.children[0].children[0].children = [addedElement];
  hoveredParentElement = findParentElement(pageElements, addedElement);
  switch (side) {
    case "top":
      calculateColumnsWidthAndOffset({ hoveredParentElement, pageElements }, Page);
      section.sort = hoveredElement.sort + 8;
      pageElements.push(section);
      break;
    case "bottom":
      calculateColumnsWidthAndOffset({ hoveredParentElement, pageElements }, Page);
      section.sort = hoveredElement.sort - 8;
      pageElements.push(section);
  }
};
export const droppingRowOverRow = (
  { side, hoveredParentElement, addedElement, hoveredElement, pageElements },
  Page
) => {
  let row = !addedElement.children.length ? _.cloneDeep(rowObject) : addedElement;
  switch (side) {
    case "top":
      row.sort = hoveredElement.sort + 8;
      hoveredParentElement.children.push(row);
      break;
    case "bottom":
      row.sort = hoveredElement.sort - 8;
      hoveredParentElement.children.push(row);
  }
};
export const droppingRowOverSection = (
  { side, hoveredParentElement, addedElement, hoveredElement, pageElements },
  Page
) => {
  let row = !addedElement.children.length ? _.cloneDeep(rowObject) : addedElement;
  switch (side) {
    case "top":
      if (!addedElement.children.length) {
        row.data.userOverrides.height = "300px";
      }
      row.sort = hoveredElement.sort + 8;
      hoveredElement.children.push(row);
      break;
    case "bottom":
      if (!addedElement.children.length) {
        row.data.userOverrides.height = "300px";
      }
      row.sort = 1;
      hoveredElement.children.push(row);
  }
};
export const droppingSectionOverSection = (
  { side, hoveredParentElement, addedElement, hoveredElement, pageElements },
  Page
) => {
  let section = !addedElement.children.length ? _.cloneDeep(customSection) : addedElement;
  switch (side) {
    case "top":
      section.sort = hoveredElement.sort + 8;
      pageElements.push(section);
      break;
    case "bottom":
      section.sort = hoveredElement.sort - 8;
      pageElements.push(section);
  }
};

export const handleDraggingSectionOverSection = (
  { hoveringElement, draggingElement, draggingType, ...args },
  Page
) => {
  handleDraggingElementOverElement(
    { hoveringElement, draggingElement, draggingType, ...args },
    Page
  );
};

export const handleDraggingSectionOverRow = (
  { hoveringElement, draggingElement, ...args },
  Page
) => {
  const section = getElementInHoverArray(isASection, Page);
  handleDraggingSectionOverSection({ hoveringElement: section, draggingElement, ...args }, Page);
};

export const handleDraggingSectionOverColumn = (
  { hoveringElement, draggingElement, ...args },
  Page
) => {
  const section = getElementInHoverArray(isASection, Page);
  handleDraggingSectionOverSection({ hoveringElement: section, draggingElement, ...args }, Page);
};

export const handleDraggingSectionOverWrapperDiv = (
  { hoveringElement, draggingElement, ...args },
  Page
) => {
  const section = getElementInHoverArray(isASection, Page);
  handleDraggingSectionOverSection({ hoveringElement: section, draggingElement, ...args }, Page);
};

export const handleDraggingSectionOverElement = (
  { hoveringElement, draggingElement, ...args },
  Page
) => {
  const section = getElementInHoverArray(isASection, Page);
  handleDraggingSectionOverSection({ hoveringElement: section, draggingElement, ...args }, Page);
};

/**
 * Start Dragging Row functions
 */

export const handleDraggingARow = ({ hoveringType, ...args }, Page) => {
  isASection(hoveringType) && handleDraggingRowOverSection(args, Page);

  isARow(hoveringType) && handleDraggingRowOverRow(args, Page);

  isAColumn(hoveringType) && handleDraggingRowOverColumn(args, Page);

  isAWrapperDiv(hoveringType) && handleDraggingRowOverWrapperDiv(args, Page);

  isASlider(hoveringType) && handleDraggingRowOverColumn(args, Page);

  isAnElement(hoveringType) && handleDraggingRowOverElement(args, Page);
};

export const handleDraggingRowOverSection = (args, Page) => {
  const sides = { hideRight: true, hideLeft: true, hideTop: false, hideBottom: false };
  handleDraggingElementOverElement({ sides, draggingType: args.draggingType, ...args }, Page);
};

export const handleDraggingRowOverRow = (
  { hoveringElement, draggingElement, draggingType },
  Page
) => {
  const sides = { hideRight: true, hideLeft: true, hideTop: false, hideBottom: false };
  handleDraggingElementOverElement({ hoveringElement, draggingElement, draggingType, sides }, Page);
};

export const handleDraggingRowOverColumn = ({ draggingElement }, Page) => {
  const row = getElementInHoverArray(isARow, Page);
  handleDraggingRowOverRow({ hoveringElement: row, draggingElement }, Page);
};

export const handleDraggingRowOverWrapperDiv = ({ draggingElement }, Page) => {
  const row = getElementInHoverArray(isARow, Page);
  handleDraggingRowOverRow({ hoveringElement: row, draggingElement }, Page);
};

export const handleDraggingRowOverElement = ({ draggingElement }, Page) => {
  const row = getElementInHoverArray(isARow, Page);
  handleDraggingRowOverRow({ hoveringElement: row, draggingElement }, Page);
};

/**
 * Start Dragging Column functions
 */

export const handleDraggingAColumn = ({ hoveringType, ...args }, Page) => {
  isASection(hoveringType) && handleDraggingColumnOverSection(args, Page);

  isARow(hoveringType) && handleDraggingColumnOverRow(args, Page);

  isAColumn(hoveringType) && handleDraggingColumnOverColumn(args, Page);

  isAWrapperDiv(hoveringType) && handleDraggingColumnOverWrapperDiv(args, Page);

  isASlider(hoveringType) && handleDraggingColumnOverElement(args, Page);

  isAnElement(hoveringType) && handleDraggingColumnOverElement(args, Page);
};

export const handleDraggingColumnOverSection = (args, Page) => {
  const sides = { hideRight: true, hideLeft: true, hideTop: false, hideBottom: false };
  handleDraggingElementOverElement({ sides, ...args }, Page);
};

export const handleDraggingColumnOverRow = (
  { draggingElement, hoveringElement, draggingType },
  Page
) => {
  const sides = { hideRight: false, hideLeft: false, hideTop: true, hideBottom: true };
  handleDraggingElementOverElement(
    {
      hoveringElement,
      draggingElement,
      draggingType,
      sides,
    },
    Page
  );
};

export const handleDraggingColumnOverColumn = (
  { draggingElement, hoveringElement, draggingType },
  Page
) => {
  handleDraggingElementOverElement(
    {
      hoveringElement,
      draggingElement,
      draggingType,
    },
    Page
  );
};

export const handleDraggingColumnOverWrapperDiv = ({ draggingElement }, Page) => {
  const column = getElementInHoverArray(isAColumn, Page);
  handleDraggingColumnOverColumn({ hoveringElement: column, draggingElement }, Page);
};

export const handleDraggingColumnOverElement = ({ draggingElement }, Page) => {
  const column = getElementInHoverArray(isAColumn, Page);
  handleDraggingColumnOverColumn({ hoveringElement: column, draggingElement }, Page);
};

/**
 * Start Dragging Wrapper Div functions
 */

export const handleDraggingAWrapperDiv = ({ hoveringType, ...args }, Page) => {
  isASection(hoveringType) && handleDraggingWrapperDivOverSection(args, Page);

  isARow(hoveringType) && handleDraggingWrapperDivOverRow(args, Page);

  isAColumn(hoveringType) && handleDraggingWrapperDivOverColumn(args, Page);

  isAWrapperDiv(hoveringType) && handleDraggingWrapperDivOverWrapperDiv(args, Page);

  isASlider(hoveringType) && handleDraggingWrapperDivOverElement(args, Page);

  isAnElement(hoveringType) && handleDraggingWrapperDivOverElement(args, Page);
};

export const handleDraggingWrapperDivOverSection = (args, Page) => {
  const sides = { hideRight: true, hideLeft: true, hideTop: false, hideBottom: false };

  handleDraggingElementOverElement({ ...args, sides }, Page);
};

export const handleDraggingWrapperDivOverRow = ({ draggingElement, hoveringElement }, Page) => {
  const sides = { hideRight: false, hideLeft: false, hideTop: true, hideBottom: true };
  handleDraggingElementOverElement(
    {
      draggingElement,
      hoveringElement,
      draggingType,
      sides,
    },
    Page
  );
};
export const handleDraggingWrapperDivOverColumn = (args, Page) => {
  handleDraggingElementOverElement(args, Page);
};

export const handleDraggingWrapperDivOverWrapperDiv = (
  { hoveringElement, draggingElement, draggingType, ...args },
  Page
) => {
  const sides = { hideRight: true, hideLeft: true, hideTop: false, hideBottom: false };
  handleDraggingElementOverElement({ hoveringElement, draggingElement, draggingType, sides }, Page);
};

export const handleDraggingWrapperDivOverElement = (
  { hoveringElement, draggingElement, ...args },
  Page
) => {
  handleDraggingWrapperDivOverWrapperDiv(
    {
      hoveringElement,
      draggingElement,
      ...args,
    },
    Page
  );
};

/**
 * Start Dragging an Element functions
 */

export const handleDraggingAnElement = ({ hoveringType, ...args }, Page) => {
  isASection(hoveringType) && handleDraggingElementOverSection(args, Page);

  isARow(hoveringType) && handleDraggingElementOverRow(args, Page);

  isAColumn(hoveringType) && handleDraggingElementOverColumn(args, Page);

  isAWrapperDiv(hoveringType) && handleDraggingElementOverWrapperDiv(args, Page);

  isASlider(hoveringType) && handleDraggingElementOverElement(args, Page);

  isAnElement(hoveringType) && handleDraggingElementOverElement(args, Page);
};

export const handleDraggingElementOverSection = (args, Page) => {
  const sides = { hideRight: true, hideLeft: true, hideTop: false, hideBottom: false };
  handleDraggingElementOverElement({ ...args, sides }, Page);
};

export const handleDraggingElementOverRow = (
  { draggingElement, hoveringElement, draggingType },
  Page
) => {
  const sides = { hideRight: false, hideLeft: false, hideTop: true, hideBottom: true };
  handleDraggingElementOverElement(
    {
      draggingElement,
      hoveringElement,
      draggingType,
      sides,
    },
    Page
  );
};

export const handleDraggingElementOverColumn = (args, Page) => {
  handleDraggingElementOverElement(args, Page);
};

export const handleDraggingElementOverWrapperDiv = (
  { hoveringElement, draggingElement, sides, ...args },
  Page
) => {
  const { clientX, clientY } = draggingElement;
  repositionMars(hoveringElement, Page);
  // hovered element box edges
  const { box } = hoveringElement;
  let hideTop, hideBottom, hideRight, hideLeft;
  const options = {
    clientX,
    clientY,
    draggingElement,
    ...box,
  };
  if (!hoveringElement.el.children.length) {
    renderMarsOverEmptyElement(hoveringElement, true, Page);
  } else if (
    hoveringElement.el.data.userOverrides &&
    hoveringElement.el.data.userOverrides.display === "flex"
  ) {
    if (isHoveringTopMargin(options, Page)) {
      renderMarsAboveElement(hoveringElement, Page);
    } else if (isHoveringBottomMargin(options, Page)) {
      renderMarsBelowElement(hoveringElement, Page);
    } else if (isHoveringRightHalf(options, Page)) {
      renderMarsRightSideOfElement(hoveringElement, Page);
    } else if (isHoveringLeftHalf(options, Page)) {
      renderMarsLeftSideOfElement(hoveringElement, Page);
    }
  } else {
    if (isHoveringTopHalf(options, Page)) {
      renderMarsAboveElement(hoveringElement, Page);
    } else if (isHoveringBottomHalf(options, Page)) {
      renderMarsBelowElement(hoveringElement, Page);
    }
  }
};

export const handleDraggingElementOverElement = (
  { hoveringElement, draggingElement, draggingType, sides },
  Page
) => {
  // mouse x, y
  const { clientX, clientY } = draggingElement;
  repositionMars(hoveringElement, Page);
  // hovered element box edges
  const { box } = hoveringElement;
  const { draggingNewElement } = Page.props;
  let hideTop, hideBottom, hideRight, hideLeft;
  if (sides) {
    hideTop = sides.hideTop;
    hideBottom = sides.hideBottom;
    hideRight = sides.hideRight;
    hideLeft = sides.hideLeft;
  }
  const options = {
    clientX,
    clientY,
    draggingElement,
    ...box,
  };
  const wrapperDiv = getElementInHoverArray(isAWrapperDiv, Page);
  if (
    !hoveringElement.el.children.length &&
    !isAnElement(hoveringElement.el.el) &&
    // no dropping elments into header and footers now
    !hoveringElement.el.el.toLowerCase().includes("footer") &&
    !hoveringElement.el.el.toLowerCase().includes("header") &&
    //
    (isAnElement(draggingType) || isAWrapperDiv(draggingType))
  ) {
    renderMarsOverEmptyElement(hoveringElement, true, Page);
  } else if (
    wrapperDiv &&
    Object.keys(wrapperDiv).length &&
    wrapperDiv.el.id === hoveringElement.el.parentId &&
    hoveringElement.el.data.userOverrides &&
    wrapperDiv.el.data.userOverrides.display === "flex"
  ) {
    if (isHoveringTopMargin(options, Page)) {
      renderMarsAboveElement(hoveringElement, Page);
    } else if (isHoveringBottomMargin(options, Page)) {
      renderMarsBelowElement(hoveringElement, Page);
    } else if (isHoveringRightHalf(options, Page)) {
      renderMarsRightSideOfElement(hoveringElement, Page);
    } else if (isHoveringLeftHalf(options, Page)) {
      renderMarsLeftSideOfElement(hoveringElement, Page);
    }
  } else {
    if (isHoveringRightMargin(options, Page)) {
      if (!hideRight) {
        renderMarsRightSideOfElement(hoveringElement, Page);
      } else {
        Page.Mars.style.opacity = 0;
      }
    } else if (isHoveringLeftMargin(options, Page)) {
      if (!hideLeft) {
        renderMarsLeftSideOfElement(hoveringElement, Page);
      } else {
        Page.Mars.style.opacity = 0;
      }
    } else if (isHoveringTopHalf(options, Page)) {
      if (!hideTop) {
        renderMarsAboveElement(hoveringElement, Page);
      } else {
        Page.Mars.style.opacity = 0;
      }
    } else if (isHoveringBottomHalf(options, Page)) {
      if (!hideBottom) {
        renderMarsBelowElement(hoveringElement, Page);
      } else {
        Page.Mars.style.opacity = 0;
      }
    }
  }
};

export const getElementInHoverArray = (filterFunction, Page) => {
  const filtered = Page.elementsBeingHovered.filter(({ elementName }) =>
    filterFunction(elementName)
  );

  if (filtered.length) {
    return filtered[filtered.length - 1];
  }

  return {};
};

/**
 * Handler to check if the new element being dragged is over this dom element
 * @param element
 */
export const checkIfDraggingOverElement = (element, Page) => {
  const elementStyle = getComputedStyle(element.node);
  if (isHovering(element.box, Page.newElementBeingDragged)) {
    // the condition below prevents elements that don't respect the flow from being hovered while dragging
    if (elementStyle.position !== "absolute") {
      Page.elementsBeingHovered.push(element);
    }
  }
};

/**
 *
 * @param top
 * @param right
 * @param bottom
 * @param left
 * @param clientX - mouseX
 * @param clientY - mouseY
 * @returns {boolean}
 */
export const isHovering = ({ top, right, bottom, left }, { clientX, clientY }) => {
  return clientY > top && clientY < bottom && clientX > left && clientX < right;
};

/**
 * clientX - mouse X position
 * clientY - mouse Y position
 * top - top px position of bounding box
 * left - left px position of bounding box
 * right - right px position of bounding box
 * bottom - bottom px position of bounding box
 * @param {*} param0
 */
export const isHoveringTopHalf = ({ clientX, clientY, top, left, right, bottom }, Page) => {
  const height = bottom - top;
  if (clientY - top < height / 2) {
    Page.Mars.side = "top"; // indicates that the element's top side is highlighted
    return true;
  } else {
    Page.Mars.style.transform = "";
    Page.Mars.style.backgroundColor = "transparent";
    return false;
  }
};

export const isHoveringBottomHalf = ({ clientX, clientY, top, left, right, bottom }, Page) => {
  const height = bottom - top;
  if (clientY - top > height / 2) {
    Page.Mars.side = "bottom"; // indicates that the element's bottom side is highlighted
    return true;
  } else {
    Page.Mars.style.transform = "";
    Page.Mars.style.backgroundColor = "transparent";
    return false;
  }
};

export const isHoveringRightMargin = ({ clientX, clientY, top, left, right, bottom }, Page) => {
  const width = right - left;
  if (clientX - left > width - elementsSideHoveringMargin) {
    Page.Mars.side = "right"; // indicates that the element's right side is highlighted
    return true;
  } else {
    Page.Mars.style.transform = "";
    Page.Mars.style.backgroundColor = "transparent";
    return false;
  }
};
export const isHoveringLeftMargin = ({ clientX, clientY, top, left, right, bottom }, Page) => {
  const width = right - left;
  if (clientX - left < elementsSideHoveringMargin) {
    Page.Mars.side = "left"; // indicates that the element's left side is highlighted
    return true;
  } else {
    Page.Mars.style.transform = "";
    Page.Mars.style.backgroundColor = "transparent";
    return false;
  }
};
export const isHoveringRightHalf = ({ clientX, clientY, top, left, right, bottom }, Page) => {
  const width = right - left;
  if (clientX - left > width / 2) {
    Page.Mars.side = "right"; // indicates that the element's bottom side is highlighted
    return true;
  } else {
    Page.Mars.style.transform = "";
    Page.Mars.style.backgroundColor = "transparent";
    return false;
  }
};
export const isHoveringLeftHalf = ({ clientX, clientY, top, left, right, bottom }, Page) => {
  const width = right - left;
  if (clientX - left < width / 2) {
    Page.Mars.side = "left"; // indicates that the element's bottom side is highlighted
    return true;
  } else {
    Page.Mars.style.transform = "";
    Page.Mars.style.backgroundColor = "transparent";
    return false;
  }
};
export const isHoveringBottomMargin = ({ clientX, clientY, top, left, right, bottom }, Page) => {
  const height = bottom - top;
  if (clientY - top > height - elementsBottomHoveringMargin) {
    Page.Mars.side = "bottom"; // indicates that the element's right side is highlighted
    return true;
  } else {
    Page.Mars.style.transform = "";
    Page.Mars.style.backgroundColor = "transparent";
    return false;
  }
};
export const isHoveringTopMargin = ({ clientX, clientY, top, left, right, bottom }, Page) => {
  if (clientY - top < elementsTopHoveringMargin) {
    Page.Mars.side = "top"; // indicates that the element's left side is highlighted
    return true;
  } else {
    Page.Mars.style.transform = "";
    Page.Mars.style.backgroundColor = "transparent";
    return false;
  }
};
/**
 * Mars functionality
 */

/**
 * Creates the Mars element
 */
export const initMars = Page => {
  // Added check to only create the div if it doesn't
  // exist
  const mars = document.getElementById("Mars");

  if (mars) {
    Page.Mars = mars;
    return;
  }

  Page.Mars = document.createElement("div");
  const marsChild = document.createElement("div");
  Page.Mars.appendChild(marsChild);
  Page.Mars.style.display = "none";
  Page.Mars.style.pointerEvents = "none";
  Page.Mars.style.zIndex = "10000";
  Page.Mars.style.position = "fixed";
  Page.Mars.id = "Mars";
  const wrapper = document.getElementById("editorPageWrapper");

  wrapper && wrapper.appendChild(Page.Mars);
};
export const createAndUpdateElementsDisplay = Page => {
  if (!Page.ElementsDisplay) {
    Page.ElementsDisplay = document.createElement("div");
    Page.ElementsDisplay.appendChild(document.createElement("div"));
    Page.ElementsDisplay.appendChild(document.createElement("div"));
    Page.ElementsDisplay.style.backgroundColor = "white";
    Page.ElementsDisplay.style.border = "1px dashed yellow";
    Page.ElementsDisplay.style.zIndex = 9999999;
    Page.ElementsDisplay.style.position = "fixed";
    Page.ElementsDisplay.style.bottom = "10em";
    Page.ElementsDisplay.style.right = "10em";
    document.body.appendChild(Page.ElementsDisplay);
  } else {
    Page.ElementsDisplay.children[0].innerHTML = `Dragging Element: ${
      Page.props.draggingNewElement
        ? Page.props.draggingNewElement.get("el") || Page.props.draggingNewElement.get("editorType")
        : null
    }`;
    Page.ElementsDisplay.children[1].innerHTML = `Hovered Element: ${
      elementBeingHoveredOver(Page) ? elementBeingHoveredOver(Page).el.el : null
    }`;
  }
};
/**
 * Hides the Mars element
 */
export const hideMars = Page => {
  Page.Mars.style.display = "none";
  Page.Mars.style.width = "0px";
  Page.Mars.style.height = "0px";
  // if (Page.Mars.side) {
  //  delete Page.Mars.side;
  // }
};

export const renderMarsAboveElement = (element, Page) => {
  const { box, elementName, height, width, id, node } = element;
  Page.Mars.childNodes[0].style.height = marsThickness;
  Page.Mars.childNodes[0].style.width = `${width}px`;
  Page.Mars.childNodes[0].style.transform = `translate(0px, -${marsThickness})`;
  Page.Mars.childNodes[0].style.backgroundColor = "blue";
  Page.Mars.childNodes[0].style.opacity = 0.5;
  renderMarsOverEmptyElement(element, false, Page);
};

export const renderMarsBelowElement = (element, Page) => {
  const { box, elementName, height, width, id, node } = element;
  const marsThicknessNumber = Number(marsThickness.slice(0, -2));
  Page.Mars.childNodes[0].style.width = `${width}px`;
  Page.Mars.childNodes[0].style.height = marsThickness;
  Page.Mars.childNodes[0].style.transform = `translate(0px, ${height + marsThicknessNumber}px)`;
  Page.Mars.childNodes[0].style.backgroundColor = "blue";
  Page.Mars.childNodes[0].style.opacity = 0.5;
  renderMarsOverEmptyElement(element, false, Page);
};

export const renderMarsRightSideOfElement = (element, Page) => {
  const { box, elementName, height, width, id, node } = element;
  const marsThicknessNumber = Number(marsThickness.slice(0, -2));
  Page.Mars.childNodes[0].style.height = `${height}px`;
  Page.Mars.childNodes[0].style.width = marsThickness;
  Page.Mars.childNodes[0].style.transform = `translate(${width + marsThicknessNumber}px, 0px)`;
  Page.Mars.childNodes[0].style.backgroundColor = "blue";
  Page.Mars.childNodes[0].style.opacity = 0.5;
  renderMarsOverEmptyElement(element, false, Page);
};
export const renderMarsLeftSideOfElement = (element, Page) => {
  const { box, elementName, height, width, id, node } = element;
  const marsThicknessNumber = Number(marsThickness.slice(0, -2));
  Page.Mars.childNodes[0].style.height = `${height}px`;
  Page.Mars.childNodes[0].style.width = marsThickness;
  Page.Mars.childNodes[0].style.transform = `translate(-${marsThicknessNumber}px, 0px)`;
  Page.Mars.childNodes[0].style.backgroundColor = "blue";
  Page.Mars.childNodes[0].style.opacity = 0.5;
  renderMarsOverEmptyElement(element, false, Page);
};
export const renderMarsOverEmptyElement = (element, isEmpty, Page) => {
  const { box, elementName, height, width, id, node } = element;
  Page.Mars.style.height = `${height}px`;
  Page.Mars.style.width = `${width}px`;
  Page.Mars.style.backgroundColor = "rgba(0,0,255,0.25)";
  Page.Mars.style.transform = "";
  Page.Mars.style.opacity = 1;
  if (isEmpty) {
    Page.Mars.childNodes[0].style.opacity = 0;
  }
};
