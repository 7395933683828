import { StyleSheet } from "@convertly/css";
import Typography from '../style-functions/typography';

const heading1Styles = ( global, data ) => {


    const blockquoteStyles = new Typography( global, data.blockquote );
    const authorStyles = new Typography( global, data.quoteAuthor );

    return StyleSheet.create({
        blockquote: {
            ...blockquoteStyles
        },
        author: {
            ...authorStyles
        }

    })

};

export default heading1Styles;