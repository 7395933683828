import React from 'react';
import { css } from "@convertly/css";
import Component from '../../../rootComponent';
import Types from '../../../attribute-types';

import { id } from '@convertly/thor';


//Styles
import hoursOfOperationStyles from './hours-of-operation-styles-02';

//Scripts
import hoursWidget from '../scripts/hours-widget';

//Components
import Elements from '../../../elements/index';

//Default Props
import defaultGlobal from '../../../themes/global.js';


//Component
export default class
HoursOfOperation02 extends Component {
    constructor ( props ) {
        super( props );

    }


    render() {

        /** Calls editor */
        if( this.props.editor ) {
            return this.editor();
        }

        /** Generates border in editor */
        const inlineStyle = this.generateStyleAttribute( );

        /** Inits style sheet **/
        this.styles = hoursOfOperationStyles( this.props.global, this.props.data );

        let { hoursOfOperation } = this.props.data;

        let today = new Date().getDay() - 1;


        return (

            <div
                className={ css(this.styles.hoursOfOperation) }
                { ...this.generateDataEditorAttribute() }
                { ...this.generateCustomAttributes() }
                onClick={( e ) => {
                    this.launchElementalEditor( 'hoursWidget', e );
                }}
                onMouseOver={ (e) => {this.addEditableOutline(e)} }
                onMouseOut={ (e) => {this.removeEditableOutline(e)} }
                {...inlineStyle}
                >

                <div id={ id( hoursWidget ) }>
                    <span className={ css(this.styles.storeHours) }>
                        { hoursOfOperation[today].openingTime } -  { hoursOfOperation[today].closingTime }
                    </span>
                    <div className={ css(this.styles.hoverBox) }>
                        <div className={ css( this.props.parentClassName ) } >Business Hours</div>
                        <div className={ css(this.styles.hoursBox) + ' hoursBox'} >
                            {
                                hoursOfOperation.map( (hours) => {
                                    return (
                                        <div className={ css( this.styles.days ) }>
                                            <span>{hours.dayOfWeek}</span>
                                            <span>{hours.openingTime}-{hours.closingTime}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                </div>

            </div>

        )
    }
}

HoursOfOperation02.defaultProps = {
    data: {
        hoursOfOperation: [
            {
                dayOfWeek: "MONDAY",
                openStatus: true,
                openingTime: "9:00am",
                closingTime: "5:30pm",
                displayText: "mon"
            },
            {
                dayOfWeek: "TUESDAY",
                openStatus: true,
                openingTime: "9:00am",
                closingTime: "12:00pm",
                displayText:'tue'
            },
            {
                dayOfWeek: "WEDNESDAY",
                openStatus: true,
                openingTime: "9:00am",
                closingTime: "5:30pm",
                displayText: "wed"

            },
            {
                dayOfWeek: "THURSDAY",
                openStatus: true,
                openingTime: "9:00am",
                closingTime: "5:30pm",
                displayText: "thur"
            },
            {
                dayOfWeek: "FRIDAY",
                openStatus: true,
                openingTime: "9:00am",
                closingTime: "4:30am",
                displayText: "fri"
            },
            {
                dayOfWeek: "SATURDAY",
                openStatus: false,
                openingTime: "9:00am",
                closingTime: "5:30pm",
                displayText: "sat"
            },
            {
                dayOfWeek: "SUNDAY",
                openStatus: false,
                openingTime: "9:00am",
                closingTime: "5:30pm",
                displayText: "sun"
            }
        ]

    },
    global: defaultGlobal
};