import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { fromJS } from "immutable";
import { setOverlayStyles, closeModal } from "containers/Editor/actions/editorActions";
import { Button as ButtonComponent } from "components/Button/Button";
import { ModalWrapper } from "./ModalWrapper";
import { toolsRow } from "./image-editor-components/toolsRow";
import { keyframes } from "styled-components";
import loadingIcon from "components/icons/loadingIcon.svg";
import { withToolTip } from "components/ToolTip/withToolTip";

const Button = withToolTip(ButtonComponent);

import {
  ImageEditorContainer,
  ToolsContainer,
  ToolContainer,
  ToolLabel,
  ImageEditorFooter,
} from "./image-editor-components/css-image-editor";

const closeAnimation = keyframes`
  from {
    opacity: 1;
    left:50%;
    transform: translate(-50%, 0%)  scale(1);
  }
  to {
    opacity: 0;
    left:50%;
    transform: translate(-50%, 0%) scale(0.95);
  }
`;

const BlockingUnderlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 3900;
`;
const LoadingIconPanel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-color: rgba(255, 255, 255, 1);
  z-index: 9999;
`;

const ButtonSpacer = styled.span`
  display: inline-block;
  float: left;
  margin-left: 20px;
`;

export class ImageEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: 0,
      params: this.props.params,
      transition: null,
      trigger: 0,
      saving: false,
      tempParams: {},
    };

    this.handleToolSelect = this.handleToolSelect.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.undo = this.undo.bind(this);
    this.update = this.update.bind(this);
    this.save = this.save.bind(this);
    this.saveTempParam = this.saveTempParam.bind(this);
    this.resetParams = this.resetParams.bind(this);
  }

  save() {
    this.setState(
      {
        saving: true,
      },
      async () => {
        const { src, params } = await this.props.saveEditedImage({
          params: this.state.params.toJS(),
          src: this.props.image,
        });
        this.closeModal();
        this.props.onSave(params, src);
      }
    );
  }

  undo() {
    const { trigger } = this.state;
    this.setState({
      trigger: trigger + 1,
    });
  }

  handleToolSelect(index) {
    this.setState({ selectedIndex: index });
  }

  closeModal() {
    //this.setState({ transition: { animation: `${closeAnimation} 300ms` } });
    setTimeout(() => {
      this.props.dispatch(closeModal());
      this.props.dispatch(setOverlayStyles(null));
      this.props.onSave(null, null);
    }, 250);
  }

  update(field, value) {
    this.setState({
      params: this.state.params.set(field, value),
    });
  }

  resetParams() {
    const { params, trigger } = this.state;

    const newParams = params
      .set("crop", "")
      .set("brightness", 0)
      .set("saturation", 0)
      .set("filter", 0);

    this.setState({
      params: newParams,
      trigger: trigger + 1,
      tempParams: {},
    });
  }

  saveTempParam(field, value) {
    const { tempParams } = this.state;
    tempParams[field] = value;

    this.setState({
      tempParams,
    });
  }

  render() {
    const { image } = this.props;
    const { selectedIndex, saving } = this.state;
    const tools = toolsRow(this.props, this.state);

    const resetAllTip = {
      toolTip: "Reset all settings to default",
      direction: "top",
    };
    const resetTip = {
      toolTip: "Reset current setting to default",
      direction: "top",
    };

    return (
      <div>
        <ModalWrapper
          width={1130}
          {...this.props}
          transition={this.state.transition}
          title={`Edit Image \u2013 ${image}`}
          noCloseIcon={true}
        >
          <ImageEditorContainer>
            {saving && (
              <LoadingIconPanel>
                <img src={loadingIcon} width="20%" />
              </LoadingIconPanel>
            )}

            <ToolsContainer>
              {tools.map((tool, index) => {
                if (tool.disable) {
                  return null;
                }

                return (
                  <ToolContainer
                    key={index + "tools"}
                    selected={selectedIndex === index}
                    onClick={this.handleToolSelect.bind(this, index)}
                  >
                    <img src={tool.icon} height="24" />
                    <ToolLabel>{tool.label}</ToolLabel>
                  </ToolContainer>
                );
              })}
            </ToolsContainer>

            {React.createElement(tools[selectedIndex].component, {
              image: this.props.image,
              params: this.state.params.set("width", 1000),
              tempParams: this.state.tempParams,
              siteId: this.props.siteId,
              update: this.update,
              trigger: this.state.trigger,
              saveTempParam: this.saveTempParam,
            })}
          </ImageEditorContainer>
          <ImageEditorFooter>
            <Button label="Cancel" action={this.closeModal} />
            <div>
              <ButtonSpacer>
                <Button
                  top="-38px"
                  left="-42px"
                  {...resetAllTip}
                  label="Reset All"
                  action={this.resetParams}
                />
              </ButtonSpacer>
              <ButtonSpacer>
                <Button top="-38px" left="-65px" {...resetTip} label="Undo" action={this.undo} />
              </ButtonSpacer>
              <ButtonSpacer>
                <Button type="submit" label="Save Image" action={this.save} />
              </ButtonSpacer>
            </div>
          </ImageEditorFooter>
        </ModalWrapper>
        <BlockingUnderlay />
      </div>
    );
  }
}

ImageEditor.defaultProps = {
  image: "",
  params: fromJS({}),
  siteId: {},
  onSave: () => {},
};

ImageEditor.propTypes = {
  image: PropTypes.string,
  params: PropTypes.object,
  siteId: PropTypes.string,
  onSave: PropTypes.func,
};
ImageEditor.contextTypes = {
  store: PropTypes.object,
};
