import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { HeaderLink } from "./HeaderLink";
import { ToggleMenuIcon } from "./ToggleMenuIcon";
import { SubMenu } from "./SubMenu";
import { permissionCheck } from "containers/Authentication/PermissionsCheck";
import { setNavigation } from "containers/Editor/actions/editorActions";
import { toggleMainMenu } from "containers/App/actions";
import {GLOBALS} from "../../../styles/global-default-styles";

const HeaderItem = styled.li`
  border-left: 4px solid rgba(0, 0, 0, 0);
  cursor: pointer;
  user-select: none;
  & svg {
   fill: currentColor;
  }
  & a {
  color:${ ({active}) => active ? "#000" : "#aaa" };
  }
  ${props =>
    props.active
      ? `color: #000; border-left-color: ${GLOBALS.colorPrimary};`
      : `color: #AAA; border-left-color: rgba(0,0,0,.2);`}

  &:hover {
   & a {
    color: #000;
    }
    color: #000;
    border-left-color: ${GLOBALS.colorPrimary};
  }
`;

const Icon = styled.i`
  margin: 0 10px;
  font-size: 15px;
`;

export class MenuItem extends React.Component {
  /**
   * Init class and state
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      // handle submenu display state
      open: false,

      // store index of active submenu item
      activeChild: null
    };
    this.handleClick = this.handleClick.bind(this);
    this.setSubItemAsActive = this.setSubItemAsActive.bind(this);
  }

  /**
   * Event handler for HeaderItem click
   * @param e
   */
  handleClick(e) {
    const { item } = this.props;

    if (item.items) {
      // If there is a submenu then stop the event
      // and toggle open state
      const { open } = this.state;
      this.setState({ open: !open });
      e.preventDefault();
      e.stopPropagation();
    } else {
      // sets the active menu item from props callback
      this.props.setActiveMenu(item);

      if (this.windowWidth() < 800) {
        this.context.store.dispatch(setNavigation(true));
        this.context.store.dispatch(toggleMainMenu());
      }
    }
  }

  windowWidth() {
    const width =
      window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width;
  }

  /**
   * Event handler for submenu item click
   *
   * @param index
   * @param e
   */
  setSubItemAsActive(index, e) {
    e.stopPropagation();
    this.props.setActiveMenu(null);
    this.setState({ activeChild: index });
    if (this.windowWidth() < 800) {
      // this.context.store.dispatch ( setNavigation ( true ) );
      this.context.store.dispatch(toggleMainMenu());
    }
  }

  /**
   *
   * @param item
   * @param active
   * @returns {XML}
   */
  renderItem(item, active) {
    let { open, activeChild } = this.state;
    activeChild = active ? activeChild : null;
    return (
      <HeaderItem hasChildren={item.items} onClick={this.handleClick} active={active || open}>
        <HeaderLink to={item.url}>
          <Icon className={"fa " + item.icon} />
          {item.label}
          {item.items && <ToggleMenuIcon active={open} />}
        </HeaderLink>
        {open && (
          <SubMenu
            activeChild={activeChild}
            setActiveMenu={this.setSubItemAsActive}
            items={item.items}
          />
        )}
      </HeaderItem>
    );
  }

  /**
   *
   * @returns {XML}
   */
  render() {
    const { item, active, site, user } = this.props;
    const permission =
      item.permission ||
      function() {
        return true;
      };
    const restraint =
      item.restraint ||
      function() {
        return true;
      };
    return permissionCheck(permission) && restraint(site) ? this.renderItem(item, active) : null;
  }
}

MenuItem.propTypes = {
  setActiveMenu: PropTypes.func,
  item: PropTypes.object
};

MenuItem.contextTypes = {
  store: PropTypes.object
};
