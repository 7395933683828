import React from 'react';
import {StyleSheet, css} from "@convertly/css";
import Component from '../../../rootComponent';
import ReactHTMLParser from "@convertly/react-html-parser";

//Styles
import slideStyles from './contained-slide-absolute-styles';

export default class Slide01 extends Component {

    constructor(props) {

        super(props);

    };

    componentWillMount () {
                /**
                 * Checks to see if we are in the cms and updates inEditor
                 **/
                if ( this.context.hasOwnProperty ( "store" ) ) {
                    if( this.context.store ) {
                        if ( !this.props.notEditable ) {
                            this.setState ( { inEditor: true } );
                        }
                    }
                }
            }

    render() {

        this.styles = slideStyles(this.props.global, this.props.data, this.props.sliderSettings, this.props.inEditor);

        let liClassName;

        if( this.state.inEditor ) {
                    if( this.props.activeSlide === this.props.data.slideNumber ) {
                        liClassName = "active"
                    }
                }
                else {

                    if (this.props.data.slideNumber === this.props.activeSlide) {
                        liClassName = " active";
                    }
                    else {
                        liClassName = " right";
                    }
            }


        function renderChildren(props) {
            return React.Children.map(props.children, child => {
                return React.cloneElement(child, {
                    moduleId: props.id,
                    moduleSort: props.sort
                });
            })
        }

        let overlay = null;

        if( this.props.data.overlay ) {
            overlay = <div className={ css( this.styles.overlay ) }/>
        }


        return (

            <li className={ css(this.styles.slide) + liClassName + ' ' + css(this.styles.slide) }
                {...this.generateCustomAttributes()}
                data-slide={ this.props.data.slideNumber }>
                <div className={ css(this.styles.slideContent) }>

                    {/** Caption **/}
                    <div className={ css(this.styles.textArea) }>

                        { renderChildren(this.props) }

                    </div>
                </div>
                { overlay }
            </li>
        )
    }
}
