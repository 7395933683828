import React from 'react';
import Component from '../../../rootComponent';
import { css } from "@convertly/css";

//defaultProps
import defaultGlobal from '../../../themes/global.js';
import defaultData from '../header-default-data';

//styles
import headerStyles from './header-styles-01.js';

//Components
import HeaderNav1 from './components/header-nav-1';

export class Header1 extends Component {

    constructor( props ) {
        super( props );
    }

    render() {

        if( this.props.editor ) {
            return this.editor();
        }

        this.styles = headerStyles( this.props.global, this.props.data );

        return (
            <div id="header01">
                <header className={ css( this.styles.header ) }
                        itemProp="siteHeader"
                        itemScope
                        itemType="http://schema.org/siteHeader">
                    <div className="container-fluid">
                    <div className="row">
                        <HeaderNav1 global={ this.props.global }
                                    site={ this.props.site }
                                    data={ this.props.data }
                        />
                    </div>
                    </div>
                </header>
                <div className={ css( this.styles.fixedHeaderFix ) }>
                </div>
            </div>
        )
    }
}

Header1.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
