import React from "react";
import { connect } from "react-redux";
import { Notifications as Component } from "./Notifications";
import { clearNotification } from "containers/App/actions";

const mapStateToProps = state => {
  return {
    notifications: state.getIn(["global", "notifications"])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    clear: async id => await dispatch(clearNotification(id))
  };
};

export const NotificationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
