import React from 'react';
import {StyleSheet, css} from "@convertly/css";
import Component from '../../../rootComponent';


//Styles
import sliderStyles from './title-nav-styles';

//utils
import {sortByKey} from '../../../utils/sort-by-key'

//Components

import SVG from '../../../svg/index';


//Component
export default class SliderNav01 extends Component {

    constructor(props) {

        super(props);

    };


    componentDidMount() {

    }

    render() {
        if (this.props.editor) {
            return this.editor();
        }

        this.styles = sliderStyles(this.props.global, this.props.data, this.props.sliderSettings, this.inEditor());

        let navItems = [];



        if (this.props.sliderSettings.titledNav) {
            let titlesArray = sortByKey(this.props.sliderSettings.titledNav, "forSlide");

            navItems = titlesArray.map((item, index) => {
                let liClassName = index === 0 ? " active" : "";
                return (
                    <li className={ css(this.styles.convertlyControlNavItem, this.styles.navItem) + liClassName + ' controlNav'}
                        data-target={ index }>
                        <h3 className={ this.styles.navItemText }>{ item.title }</h3>
                        <div className={ css(this.styles.progressBar, this.styles.progressBar) }>

                        </div>
                        <div className={ css(this.styles.progress, this.styles.progress) + " progress" }/>
                    </li>
                )
            })

        }
        else {
            for (let i = 0; i < parseInt(this.props.sliderSettings.numOfSlides); i++) {
                let liClassName = i === 0 ? " active" : "";

                navItems.push(
                    <li className={ css(this.styles.convertlyControlNavItem) + liClassName + ' controlNav'}
                        data-target={ i }>

                        <div className={ css(this.styles.progressBar) }>

                        </div>
                        <div className={ css(this.styles.progress) + " progress" }/>
                    </li>
                )
            }
        }


        return (


            <div>
                <ol className={ css(this.styles.convertlyControlNav) } id="contorlNav">
                    { navItems }
                </ol>
                <div 
                     className={ css( this.styles.arrowLeftContainer ) + " previous" } >
                    <SVG.ArrowHeadOpenLeft
                        className={ this.styles.arrowLeft }
                    />
                </div>
                <div 
                     className={ css( this.styles.arrowRightContainer ) + " next"} >
                    <SVG.ArrowHeadOpenRight
                        className={ this.styles.arrowRight }
                    />
                </div>

            </div>


        )
    }
}
