import React from "react";
import Styled from "styled-components";

const Wrapper = Styled.div`
	@media print {
		display:none;
	}
`;

export const MenuWrapper = props => <Wrapper {...props}>{props.children}</Wrapper>;
