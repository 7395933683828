import { StyleSheet } from '@convertly/css';
import { generatePseudoMediaQuery } from '../../../elements/style-functions/pseudo-media-queries';
import UserOverrides from '../../../elements/style-functions/user-overrides';
import _ from 'lodash';

const navStylesDropdownItem = (global, data, inEditor) => {
	

  let dropdownItemUserOverrides = {};
  
  if(_.get(data, 'dropdown')) {
    dropdownItemUserOverrides = UserOverrides(global, data.dropdown, inEditor);
  }

  return StyleSheet.create({
    dropdownItem: {
      width: '100%',
      height: '41px',
      cursor: 'pointer',
      borderBottom: '1px solid rgba(197, 197, 197, 0.25)',
      listStyle: 'none',
      paddingTop: '10px',
      background: _.get(data, 'userOverrides.backgroundColor') || global.colors.light.white,
      ':hover': {
        background: 'rgba(197, 197, 197, 0.25)',
        color: '#FF7800'
      },
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
          paddingTop: '0',
          paddingLeft: '20px',
          ':hover': {
            background: global.colors.dark.color3
          }
        },
        inEditor
      ),
    },
    subSelectionItemLinkStyles: {
      display: 'inline-block',
      height: '100%',
      paddingLeft: '10px',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
          width: '95%',
          paddingLeft: '50px'
        },
        inEditor
      ),
      ':hover': {
        color: '#FF7800',
        cursor: 'pointer'
      },
      ...dropdownItemUserOverrides
    }
  })
}

export default navStylesDropdownItem;
