import { Header01 as header01 } from './header-01/header-01';
import { Header02 as header02 } from './header-02/header-02';
import { Header03 as header03 } from './header-03';
import { Header04 as header04 } from './header-04/header-04';
import { Header05 as header05 } from './header-05/header-05';
import { Header06 as header06 } from './header-06/header-06';
import { Header07 as header07 } from './header-07/header-07';
import { Header09 as header09 } from './header-09/header-09';
import { ConvertlyHeader } from './convertly-header/convertly-header';

export const header = {
	header01,
	header02,
	header03,
	header04,
	header05,
	header06,
	header07,
	header09,
	ConvertlyHeader,
};
