import axios from 'axios';
import {deeplog} from "./deeplog";


export const doQuery = (params = {}) => {

    const local = process.env.GRAPH === "local"

    return new Promise((resolve, reject) => {

        const {
            query,
            variables
        } = params;

        let url = 'https://graph.convertly.com';

        if(local) {
            url = local ? 'http://localhost:4000': "https://7y02jybgt0.execute-api.us-east-1.amazonaws.com/development"
        }

        const options = {
            url: url,
            method: 'post',
            data: {
                query: query,
                variables: variables
            },
            headers: {
                Authorization: 'convertly-front-load',
            }
        };

        axios(options)
            .then(res => resolve(local ? res.data : res))
            .catch(err => {
                deeplog(err);
                console.log('doQuery error', err.message);
                resolve({});
            });
    });

};
