import {StyleSheet} from "@convertly/css";
import userOverrides from '../../elements/style-functions/user-overrides';
import _ from 'lodash';
import {generatePseudoMediaQuery} from '../../elements/style-functions/pseudo-media-queries';
import {generateHeaderOffsetMediaQueries} from '../header-style-functions/fixed-header-media-queries'
import {createNewCssRules} from '../../utils/edit-userOverrides';

const headerStyles1 = ({global, data, inEditor}) => {

    data.position = inEditor ? 'static' : data.position;

    let callOutUserOverrides = _.get(data, 'headerTopperSettings.customHeaderMessage.userOverrides') ?
        userOverrides(global, data.headerTopperSettings.customHeaderMessage, inEditor) : {};

    let headerUserOverrides = userOverrides(global, data, inEditor);
    let positionFixed = data.position === "fixed";
    let bodyPaddingMediaQueries = {};
    let displayHeaderTop = _.get(data, 'headerTopperSettings.display');
    let headerHeight = _.get(data, 'userOverrides.height') ||
    displayHeaderTop ? "calc(60px + 40px)" : '60px';

    /** Searches for height in media queries and assigns is
     to a property that can be passed to height */
    if (data.userOverrides && positionFixed) {
        bodyPaddingMediaQueries = generateHeaderOffsetMediaQueries(data);
    }

    /** Pulls the height from userOverrides **/
    let headerHeightObject;
    if (data.userOverrides) {
        headerHeightObject = createNewCssRules(headerUserOverrides, 'height', ['height']);
    }
    /** Pulls the height from userOverrides **/
    let headerBackgroundColorObject;
    if (data.userOverrides) {
        headerBackgroundColorObject = createNewCssRules(headerUserOverrides, 'backgroundColor', ['backgroundColor']);
    }


    return StyleSheet.create({

        header: {
            color: global.colors.light.color3,
            background: global.colors.light.white,
            fontFamily: global.fontFamily.primaryFont,
            lineHeight: '8px',
            borderTop: global.border,


            height: _.get(data, 'headerTopperSettings.display') ? '100px' : '60px',
            width: '100%',
            position: positionFixed ? 'fixed' : 'static',
            top: '0',
            zIndex: '25',
            ...headerUserOverrides,
            "* body": {
                paddingTop: positionFixed ? headerHeight : '0',
                minHeight: positionFixed ? `calc(100vh - ${ headerHeight })` : '100vh',
                ...generatePseudoMediaQuery(
                    "@media(max-width:767px)",
                    ['smallTablet', 'mobile'],
                    {
                        paddingTop: positionFixed ? _.get(data, 'userOverrides.@media(max-width:768px).height') : '0',
                        minHeight: positionFixed ? `calc(100vh - ${ headerHeight })` : '100vh',
                    },
                    inEditor
                ),
                ...bodyPaddingMediaQueries
            }
        },

        headerBottom: {
            height: '60px',
            background: global.colors.light.white,
            ...headerHeightObject,
            ...headerBackgroundColorObject
        },
        header_top: {
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            padding: '6px 20px',
            display: 'block',
            borderBottom: global.border,
            height: '40px',


        },
        header_top_lft: {
            height: '100%',
            display: 'flex',
            paddingTop: "5px",
            alignItems: "center"
        },
        socialIconContainer: {
            height: '20px',
            width: '20px',
            backgroundColor: global.colors.dark.color3,
            fill: global.colors.light.white,
            borderRadius: '50%',
            backgroundSize: '30px',
            ...generatePseudoMediaQuery("@media (max-width: 768px)", ['smallTablet', 'mobile'], {
                display: "none",
            }, inEditor),
        },
        socialIconWrapper: {
            float: 'left'
        },
        connect_us: {
            display: 'inline-flex',
            alignContent: 'center',
            float: 'left',
            color: global.colors.dark.color3,
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "16px",
            lineHeight: "16px",
            margin: "0",
            padding: "0",
            ...generatePseudoMediaQuery("@media (max-width: 768px)", ['smallTablet', 'mobile'], {
                width: '100%',
                justifyContent: 'center',
                fontSize: '12px',
                lineHeight: '12px'
            }, inEditor),
        },
        socialIcons: {
            paddingTop: "5px"
        },
        socialIcon: {
            height: '100%',
            width: '100%',
            fill: global.colors.light.white,
            padding: '3px',
        },
        customCallOut: {
            fontSize: '14px',
            color: global.colors.dark.color3,
            ...callOutUserOverrides
        },

        fixedHeaderFix: {
            display: positionFixed ? 'block' : 'none',
            height: '4em'
        }

    })

};

export default headerStyles1;
