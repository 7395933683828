import React, { useEffect } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import PropTypes from "prop-types";
import "react-dates/initialize";
import HTML5Backend from "react-dnd-html5-backend";
import { DragDropContext } from "react-dnd";
import { StripeProvider } from "react-stripe-elements";
import { STRIPE_API_KEY } from "config";
import { hot } from "react-hot-loader";

import { SubscriptionsProvider } from "contexts";

import Header from "components/Header/container";
import ChatService from "containers/ChatService";
import ModalConductor from "./ModalConductor";
import { AppOverlay } from "./AppOverlay";
import { AlertsContainer } from "containers/Alerts";
import { NotificationsContainer } from "containers/Notifications";
import { LoadingIcon } from "components/icons/loadingIcon";

import withProgressBar from "components/ProgressBar";
import { bootstrap } from "../../app";
import { StripeWrapper } from "./StripeWrapper";
import { tracker } from "utils/snowplow";

import { FeedbackWidget } from "../FeedbackWidget";
import { Footer } from "../Footer";
import { Background } from "./components/Background";

const AppWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: #f5f8fa;
  background-size: 100% 55%;
  padding-bottom: 20px;
  @media (max-width: 700px) {
    padding: 0;
  }
`;

// props for this are set in Authentication/index

export function App(props) {
  const {
    auth,
    displayAppOverlay,
    displayLoadingIcon,
    appOverlayOpacity,
    appOverlayIndex,
    setStripe,
  } = props;
  // only display protected content if user is logged in

  useEffect(() => {
    tracker.init(auth?.user?.id);
  }, []);

  const protectedContent = (
    <div>
      {false && <ChatService />}
      <Header location={props.location} />
    </div>
  );

  const content =
    auth.setupComplete && props.location.pathname !== "/login" ? protectedContent : "";

  let cName = bootstrap;
  if (props.location.pathname === "/editor") {
    cName = "";
  }

  return (
    <AppWrapper id={"app_wrapper"} className={cName}>
      <Background path={props.location.pathname} />
      {/** StripeProvider can only have one child node, so need <div> **/}
      <StripeProvider apiKey={STRIPE_API_KEY}>
        <StripeWrapper setStripe={setStripe}>
          <SubscriptionsProvider>
            <Helmet titleTemplate="%s - Convertly" defaultTitle="Convertly" />

            {content}

            {React.Children.toArray(props.children)}

            <ModalConductor {...props} />

            <AlertsContainer />

            <NotificationsContainer />

            {displayLoadingIcon && <LoadingIcon />}

            {displayAppOverlay && (
              <AppOverlay id={"app_overlay"} zIndex={appOverlayIndex} opacity={appOverlayOpacity} />
            )}

            <FeedbackWidget
              user={{
                firstName: _.get(auth, "user.first_name"),
                email: _.get(auth, "user.username"),
              }}
            />
            <Footer />
          </SubscriptionsProvider>
        </StripeWrapper>
      </StripeProvider>
    </AppWrapper>
  );
}

App.propTypes = {
  children: PropTypes.node,
};

App.defaultProps = {
  setStripe: () => {},
};

const Something = hot(module)(App);

const exportedApp = DragDropContext(HTML5Backend)(withProgressBar(Something));
export default exportedApp;
//export default hot(module)(exportedApp);
//export default withProgressBar ( App );
