import React from 'react'
import {SketchPicker} from 'react-color';
import {connect} from "react-redux";
import _ from 'lodash'
import {lookupByColorValue} from "../../utils/themeHelpers";
import {closeIcon} from "ui-components/Icons"
//TODO, customize the react-color picker
class ColorPickerComp extends React.Component {

  state = {
    colors:[],
    colorLookup:[]
  };
  //adapter for theme data to function to flatten array
  _getColorsArrayFromTheme() {
    const colors = _.get(this.props, 'themeData.colors');
    return [colors.accent, colors.dark, colors.light]
  }
  //returns flat array of colors from theme
  _generateThemeColors() {
    //extract colors in a usable format
    const colorsObjs = this._getColorsArrayFromTheme()
    //init empty array
    const colors = [];
    //iterate over
    colorsObjs.map( c => {
      colors.push(c.color1);
      colors.push(c.color2);
      colors.push(c.color3);
    });

    this.setState({colors:colors})
  }
  //generates lookup map that is used
  //to check if color is a global color
  //and returns appropriate variable for site to use
  _generateColorMap() {
    const colors = _.get(this.props, 'themeData.colors');
    const colorLookup = lookupByColorValue( colors )

    this.setState({colorLookup})
  }

  componentWillMount() {
    this._generateThemeColors();
    this._generateColorMap()
  }
  //returns hex of color or global variable
  _getColorValue(color) {
      const {colorLookup} = this.state;
      //check the lookup to see if its a theme color
      //and return that value or standard color
      return colorLookup[color.hex] || color.hex
  }

  updateColor = (selectedColor) => {
    let color = selectedColor;
    //get theme value if there is one
    this.setState({
      currentColor:color
    }, () => {
      //extract color value
      color = this._getColorValue(color);
      this.props.cb(color)
    } )

    //apply the cb

  }

  render() {
    return (
      <SketchPicker
        color={this.state.currentColor}
        presetColors={this.state.colors}
        onChange={this.updateColor}
      />
    )

  }

}


function mapStateToProps( state ) {

  const Editor = state.get("editor").editorReducer;
  return {
    themeData: Editor.getIn(["currentSite", "themeData"]).toJS(),
  }

}

export const ColorPicker = connect(mapStateToProps)(ColorPickerComp);
