export default function EditNotificationsModal(global, data) {

    const errorColor = "#dc3545";
    const transition = {transition:'all .3s ease'}

    return {
        ".sms-modal-launcher": {
            textAlign:"left",
            cursor:'pointer'
        },
        ".edit-notifications-modal-wrapper": {
            position: 'fixed',
            top: "0",
            left: "0",
            bottom: 0,
            right: 0,
        },
        ".edit-notifications-overlay": {
            position: 'relative',
            height: '100%',
            width: '100%',
            backgroundColor: "rgba(0, 0, 0, 0.5)"
        },
        ".phone-number-form": {
            margin: '30px 0',
            height:60,
            textAlign:"left"
        },
        ".phone-number-input": {
            padding: '12px',
            width:'70%',
            fontSize:"17px",
            borderBottomRightRadius: "0",
            borderTopRightRadius: "0",
            borderColor:global.colors.dark.color1,
            ...transition,
            ":invalid": {
                borderColor: errorColor,
            }
        },
        ".spinner-wrapper": {
            position:'relative',
            display:'inline',

        },
        ".spinner": {
            position:'absolute',
            right: '10px',
            width: '28px',
            height: '28px',
            bottom:'0'
        },

        ".phone-number-submit": {
            display:'inline',
            float:"right",
            marginLeft:"-2px",
            outline:"none",
            width: '30%',
            paddingTop: '12px',
            paddingBottom: '12px',
            backgroundColor: global.colors.dark.color1,
            borderColor:global.colors.dark.color1,
            color:"#fff",
            fontSize: '17px',
            textAlign: 'center',
            ...transition,
            ":disabled": {
                cursor:'not-allowed',
                background:`${global.colors.dark.color1}99`,
                borderColor:`${global.colors.dark.color1}99`
            }
        },
        ".notifications-form-label": {
            display: 'block',
            color: global.colors.dark.color1,
            fontSize: '12px'
        },
        ".error": {
            color: errorColor
        }

    }

}
