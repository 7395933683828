import React from "react";
import Component from "source/rootComponent";
import { css } from "@convertly/css";
import PropTypes from "prop-types";
import _ from "lodash";

// Style
import { BannerStyles } from "./BannerStyles";

// Components
import VideoBG from "source/elements/video/video-bg/video-bg";

// Util
import renderChildren from "../util/renderChildren";
import { clientImage } from "../../utils/generateImage";

const IMAGE = "image";
const VIDEO = "video";
const BACKGROUND = "background";
const FEATURED = "featured";

export class Banner extends Component {
  render() {
    let backgroundType, dataOrigin, imgSrc, imgParams;
    let source = "";

    const { data, inEditor, global } = this.props;

    if(_.isEmpty(this.props.blogData)) {
      return null;
    }

    const enableFeaturedMedia = _.get(data, "enableFeaturedMedia");

    this.styles = BannerStyles({ global, data, inEditor });
    const { banner, bannerOverlay } = this.styles;

    if (enableFeaturedMedia === false) {
      return (
        <section
          {...inlineStyle}
          {...this.generateDataEditorAttribute()}
          {...this.generateCustomAttributes()}
          className={css(banner)}
        >
          {renderChildren(this.props, {
            inEditor,
            blogData: this.props.blogData
          })}
        </section>
      );
    }

    const blogData = _.isArray(this.props.blogData) ? this.props.blogData[0] : this.props.blogData;

    const backgroundVideoSrc = _.get(data, "backgroundVideo.src") || "";
    const backgroundImageSrc = _.get(data, "backgroundImage.src") || "";
    const backgroundImageParams = _.get(data, "backgroundImage.imgParams") || {};

    const featuredVideoSrc = _.get(blogData, "featuredVideo.src") || "";
    const featuredImageSrc = _.get(blogData, "featuredImage.src") || "";
    const featuredImageParams = _.get(blogData, "featuredImage.imgParams") || {};

    const displayOverlay = _.get(data, "displayOverlay") || false;

    const inlineStyle = this.generateStyleAttribute();

    if (_.isString(backgroundVideoSrc) && !_.isEmpty(backgroundVideoSrc)) {
      backgroundType = VIDEO;
      dataOrigin = BACKGROUND;
    } else if (_.isString(backgroundImageSrc) && !_.isEmpty(backgroundImageSrc)) {
      backgroundType = IMAGE;
      dataOrigin = BACKGROUND;
    } else if (_.isString(featuredVideoSrc) && !_.isEmpty(featuredVideoSrc)) {
      backgroundType = VIDEO;
      dataOrigin = FEATURED;
    } else if (_.isString(featuredImageSrc) && !_.isEmpty(featuredImageSrc)) {
      backgroundType = IMAGE;
      dataOrigin = FEATURED;
    }

    if (backgroundType === IMAGE) {
      switch (dataOrigin) {
        case BACKGROUND:
          imgSrc = backgroundImageSrc;
          imgParams = backgroundImageParams;
          break;
        case IMAGE:
          imgSrc = featuredImageSrc;
          imgParams = featuredImageParams;
          break;
        default:
          imgSrc = "";
          imgParams = "";
          break;
      }

      source = clientImage(imgSrc, { ...(_.isObject(imgParams) ? imgParams : {}) });
    }

    let backgroundVideoObject = {};

    if (backgroundType === VIDEO) {
      switch (dataOrigin) {
        case BACKGROUND:
          backgroundVideoObject = _.get(data, "backgroundVideo");
          break;
        case FEATURED:
          backgroundVideoObject = _.get(blogData, "featuredVideo");
          break;
      }
    }

    return (
      <section
        {...inlineStyle}
        {...this.generateDataEditorAttribute()}
        {...this.generateCustomAttributes()}
        className={css(banner)}
        style={backgroundType === IMAGE ? { backgroundImage: `url(${source})` } : {}}
      >
        {backgroundType === VIDEO && (
          <VideoBG
            data={{
              ...backgroundVideoObject,
              userOverrides: _.get(data, "userOverrides.videoContainer.userOverrides") || {}
            }}
            global={global}
            inEditor={inEditor}
          />
        )}

        {renderChildren(this.props, {
          inEditor,
          blogData: this.props.blogData
        })}
      </section>
    );
  }
}

Banner.propTypes = {
  blogData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  data: PropTypes.object,
  inEditor: PropTypes.bool.isRequired,
  global: PropTypes.object.isRequired
};

