/* eslint-disable */
//import $ from 'jquery';
//"use strict"
const LocationSelector = (id, data) => {

    return (id, data) => {

        let storeSelector = $('.storeSelector_' + id),
            locations     = $('.chooseLocation_' + id),
            arrow         = $('[data-target="arrow_' + id + '"]'),
            infoBox       = $('#storeInfo_' + id);

        storeSelector.on('click', function(e) {

            e.stopPropagation();

            locations.toggleClass('open');
            arrow.toggleClass('open');
        });

        locations.children().on('click', function(e) {
            locations.toggleClass('open');
            $('.storeHours_' + id).removeClass('active');
            $('#store_' + e.target.dataset.target[0]).addClass('active');

        });

        $(document).on('click', function() {
            locations.removeClass('open');
            arrow.removeClass('open');
        });

    };

};


export default LocationSelector;
