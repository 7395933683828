import { StyleSheet } from "@convertly/css";
import Typography from '../../../../elements/style-functions/typography';
import ModuleStyles from '../../../../elements/style-functions/module-global-styles';

const HeaderStyles6 = ( global, data ) => {

    const moduleStyles = new ModuleStyles( global, data );
    let modifiedColor;
    let overlayBorderStyle = {};
        if( data.position === "overlayFixed" ) {
            overlayBorderStyle = {
                '.absolute': {
                    borderColor: global.backgroundColors.light.white,
                    color: global.textColors.light.white,
                    stroke: global.textColors.light.white
                }
            };
        }

        if( data.position === "overlay" ) {

            modifiedColor = global.textColors.light.white

        }
        else {
            modifiedColor = global.textColors.dark.color1
        }


        const caret = {
            display: "inline-block",
            height: '17px',
            width: '17px',
            cursor: 'pointer',
            boxSizing: 'content-box',
            marginTop: '-6px',
            marginLeft: '5px',
            stroke: 'inherit',
            fill: global.backgroundColors.light.white,
            position:'absolute',
            top:'27px',
            right:'-13px',
            '@media( max-width:1201px )': {
                top:'34px',
                right: '-17px'
            }

        };

        const navListItem = {
            display:'inline-block',
            cursor:'pointer',
            padding:'24px 10px 0 10px',
            fontSize: '18px',
            stroke: 'inherit',
            position:'relative',

            '@media( max-width:1200px )': {
                padding:'32px 5px 0 5px'
            },
            '@media( max-width:991px )': {
                paddingTop:'10px'
            },

        };
        const dropdown= {
            float:'right',
            listStyle: 'none',
            height: 'auto',
            width:'fit-content',

        };
        const dropdownList = {
            display: 'none',
            position:'absolute',
            top:'140%',
            right:'-34px',
            width:'max-content',
            background:global.backgroundColors.light.white,
            listStyle: 'none',
            padding:'20px 20px 8px',
            zIndex:'1',
            '.open': {
                display:'block'
            },
            '@media( max-width:1064px )': {
                top: '205%',
                right: '3px'
            }

        };




    return StyleSheet.create({
        menuContainer: {
            padding: '50px 20px',
            borderColor: modifiedColor,
            borderRadius:'1px',
            display: 'flex',
            justifyContent: 'space-between',
            ...overlayBorderStyle,
            '@media only screen and (min-width: 768px)': {
                padding: '50px 0px',
            },

        },
        navLinks: {
            display: 'flex',
            flexDirection: 'row',
            fontFamily: global.fontFamily.primaryFont,
        },
        logo: {
            display: 'flex',
            fontSize: '22px',
            fontWeight: '800',
        },
        navLink: {
            padding: '0px 9px',
            opacity: '.6',
            fontSize: '16px',
            ':hover': {
                opacity: '1',
            }
        },
        rightLink: {
            fontSize: '18px',
            display: 'flex',
            flexDirection: 'row',
        },
        rightLinkText: {
            paddingLeft: '8px',
        },
        bookButton: {
            maxWidth: '17px',
            paddingBottom: '5px',
        },
        logoImg: {
            maxWidth: '20px',
        },
        menuLauncherIcon: {
            float:'right',
            marginTop:'20px',
            marginRight:"15px",
            '@media( max-width:1064px )': {
                marginTop: "19px"
            },
            '@media( max-width:991px )': {
                marginTop: "13px"
            }

        },
        navLaunch: {
            zIndex:45,
            display: "none",
            paddingBottom: 0,
            overflow: "visible",
            background:"none",
            border:"none",
            outline: "none",
            '@media( max-width:1064px )': {
                display:'block'
            }
        },
        iconBar: {
            backgroundColor:modifiedColor,
            width: "20px",
            height: "3px",
            marginBottom: "3px",
            position: "relative",
            display: "block",
            zIndex:50,
            transition: 'all 0.3s ease-in-out 0s',
            ':nth-child(3)': {
                width:"16px"
            },
            '.open': {
                ":nth-child(2)":{
                    transform:"rotate(45deg)",
                    top:"7px",
                    background:global.backgroundColors.dark.color1
                },
                ":nth-child(4)": {
                    transform:"rotate(-45deg)",
                    top:"-5px",
                    background:global.backgroundColors.dark.color1
                },
                ":nth-child(3)": {
                    opacity:0
                }
            },
        },
        mainMenu: {
            zIndex: 45,
            alignSelf:'baseline',
            width:'fit-content',
            float:'right',
            '@media (max-width: 1064px)': {

                marginTop:'5px',
                float:'right'
            },
            '@media( max-width:1000px )': {
                marginTop:'-2px'
            },
            '.open': {
                visibility:'visible',
                left: '0',
            },
        },
        headerNav: {
            display:'inline-block'
        },
        navList: {
            float:'right',
            listStyle: 'none',
            paddingLeft:'15px',
            height: 'auto',
            width:'fit-content',
            '@media ( max-width:1064px )': {
                position:'absolute',
                top:'-640%',
                right:'0',
                width:'100%',
                background:global.backgroundColors.light.white,
                transition:'position 1s ease'
            },
            '.open':{
                top:0,
                zIndex:2
            }
        },
        navListItem: {
            ...navListItem,
            '@media ( max-width:1064px )': {
                display:'block',
                color:global.textColors.dark.color1,
                paddingTop:'16px',
                paddingBottom:'16px'
            },
        },
        display: 'flex',
        desktopNav: {
            display: 'none',
            '@media only screen and (min-width: 768px)': {
                display: 'block'
            }
        },
        menuButton: {

        },
        contactButton: {

        },
        mobileNav: {
            display: 'block',
            '@media only screen and (min-width: 768px)': {
                display: 'none',
            }
        },


    });

};

export default HeaderStyles6
