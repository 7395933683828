import { getAsyncInjectors } from "./utils/asyncInjectors";
import { browserHistory } from "react-router";
import { isLoggedIn, refreshLoginStatus } from "containers/Authentication/Auth";
import Logger from "utils/logger";
import { is, can } from "containers/Authentication/PermissionsCheck";
import { tracker } from "utils/snowplow";

const errorLoading = err => {
  Logger.error("Dynamic page loading failed", err); // eslint-disable-line no-console
};

const loadModule = cb => componentModule => {
  cb(null, componentModule.default);
};

const allowIt = () => true;

function createRoutes(store) {
  // create reusable async injectors using getAsyncInjectors factory
  const { injectReducer, injectSagas } = getAsyncInjectors(store);

  async function checkAuth(nextState, replace, callback) {
    const allowed = nextState.routes[nextState.routes.length - 1].permissions || allowIt;

    if (isLoggedIn()) {
      if (!allowed()) {
        return browserHistory.push("/");
      }

      if (nextState.location.pathname === "/" && is("partner")) {
        return browserHistory.push("/partner");
      }
    } else {
      try {
        //await refreshLoginStatus( store );
        await store.dispatch(refreshLoginStatus());

        if (!allowed()) {
          return browserHistory.push("/");
        }

        if (nextState.location.pathname === "/" && is("partner")) {
          return browserHistory.push("/partner");
        }
      } catch (e) {
        return browserHistory.push("/login");
      }
    }

    tracker.trackPageView();

    return callback();
  }

  function injectOnce(path, reducer) {
    if (!store.getState().get(path)) {
      injectReducer(path, reducer.default);
    }
  }

  const MarketingRoutes = [
    {
      path: "/marketing/facebookcampaign/:campaignId(/:stage)",
      name: "marketing/facebookcampaign",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/Marketing/FacebookCampaign/reducer"),
          import("containers/Marketing/FacebookCampaign"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectOnce("FacebookCampaign", reducer);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/marketing/campaignonboarding",
      name: "marketing/campaignonboarding",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/Marketing/CampaignOnboarding/reducer"),
          import("containers/Marketing/CampaignOnboarding"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectOnce("CampaignOnboarding", reducer);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/marketing/splash",
      name: "marketing/splash",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/Marketing/Splash/reducer"),
          import("containers/Marketing/Splash"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectOnce("marketingSplash", reducer);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/marketing/campaigns",
      name: "marketing/campaigns",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/Marketing/Campaigns/reducer"),
          import("containers/Marketing/Campaigns"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("Campaigns", reducer.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/marketing/campaigns/:campaignId(/:stage)",
      name: "marketing/campaigns/detail",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/Marketing/CampaignDetail/reducer"),
          import("containers/Marketing/CampaignDetail"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectOnce("Campaign", reducer);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/marketing/banners",
      name: "marketing/banners",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/Marketing/Banners/reducer"),
          import("containers/Marketing/Banners"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("marketing", reducer.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/marketing/shapes",
      name: "marketing/shapes",
      permissions: () =>
        can("edit_adminAdtech") || is("admin") || is("developer") || can("edit_shapes"),
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/Marketing/Shapes/reducer"),
          import("containers/Marketing/Shapes"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectOnce("Shapes", reducer);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/marketing/templates",
      name: "marketing/templates",
      permissions: () =>
        can("edit_adminAdtech") || is("admin") || is("developer") || can("edit_templates"),
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/Marketing/Templates/reducer"),
          import("containers/Marketing/Templates"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectOnce("Templates", reducer);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/marketing/admin",
      name: "marketing/admin",
      permissions: () => is("developer"),
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/Marketing/Admin/reducer"),
          import("containers/Marketing/Admin"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectOnce("AdTechAdmin", reducer);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
  ];

  const EditorRoutes = [
    {
      path: "/editor",
      name: "editor",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/Editor/reducer"),
          import("containers/Editor/sagas"),
          import("containers/Editor"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer("blogsPanel", reducer.BlogsPanelReducer);
          injectReducer("editor", reducer.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/forms",
      name: "forms",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/Forms/reducer"),
          import("containers/Forms"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("forms", reducer.default);

          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/forms/:formId",
      name: "forms/detail",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/FormResponses/reducer"),
          import("containers/FormResponses"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("formResponses", reducer.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
  ];

  const StorefrontRoutes = [
    {
      path: "/storefront/coupons",
      name: "storefront/coupons",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/StoreFront/Coupons/reducer"),
          import("containers/StoreFront/Coupons"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("coupons", reducer.default);

          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/storefront/coupons/:couponId",
      name: "storefront/coupons/detail",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/StoreFront/CouponDetail/reducer"),
          import("containers/StoreFront/CouponDetail"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("coupon", reducer.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/storefront/products",
      name: "storefront/products",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/StoreFront/Products/reducer"),
          import("containers/StoreFront/Products"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("products", reducer.default);

          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/storefront/products/:productId",
      name: "storefront/products/detail",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/StoreFront/ProductDetail/reducer"),
          import("containers/StoreFront/ProductDetail"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("product", reducer.default);

          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/storefront/orders",
      name: "storefront/orders",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/StoreFront/Orders/reducer"),
          import("containers/StoreFront/Orders"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("Orders", reducer.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/storefront/orders/:orderId",
      name: "storefront/orders/detail",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/StoreFront/OrderDetail/reducer"),
          import("containers/StoreFront/OrderDetail"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("Order", reducer.default);

          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/storefront/customers",
      name: "storefront/customers",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/StoreFront/Customers/reducer"),
          import("containers/StoreFront/Customers"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("Customers", reducer.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/storefront/customers/:customerId",
      name: "storefront/customers/detail",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/StoreFront/CustomerDetail/reducer"),
          import("containers/StoreFront/CustomerDetail"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("Customer", reducer.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/storefront/tags",
      name: "storefront/tags",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/StoreFront/Tags/reducer"),
          import("containers/StoreFront/Tags"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("productTags", reducer.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/storefront/categories",
      name: "storefront/categories",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/StoreFront/Categories/reducer"),
          import("containers/StoreFront/Categories"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("productCategories", reducer.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/storefront/categories/:categoryId",
      name: "storefront/categories/detail",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/StoreFront/CategoryDetail/reducer"),
          import("containers/StoreFront/CategoryDetail"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("productCategory", reducer.default);

          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/storefront/settings",
      name: "storefront/settings",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/StoreFront/Settings/reducer"),
          import("containers/StoreFront/Settings/sagas"),
          import("containers/StoreFront/Settings"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer("storeSettings", reducer.default);
          injectSagas(sagas.default);

          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
  ];

  const ChatRoutes = [
    {
      path: "/chat/inbox",
      name: "chat/inbox",
      permissions: () => is("admin") || can("view", "chat"),
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/Chat/ChatInbox/reducer"),
          import("containers/Chat/ChatInbox/sagas"),
          import("containers/Chat/ChatInbox/"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer("chat", reducer.default);
          injectSagas(sagas.default);

          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/chat/settings",
      name: "chat/settings",
      permissions: () => is("admin") || can("view", "chat"),
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/Chat/ChatSettings/reducer"),
          import("containers/Chat/ChatSettings/"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("chat", reducer.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
  ];

  const UserRoutes = [
    {
      path: "/users/partners",
      name: "partners",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/Users/reducer"),
          import("containers/Users"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectOnce("users", reducer);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/users/partners/:userId",
      name: "partners/detail",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/UserDetail/reducer"),
          import("containers/UserDetail"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectOnce("userdetail", reducer);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/users/clients",
      name: "clients",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/Users/reducer"),
          import("containers/Users"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectOnce("users", reducer);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/users/clients/:userId",
      name: "clients/detail",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/UserDetail/reducer"),
          import("containers/UserDetail"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectOnce("userdetail", reducer);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
  ];

  const OnBoardingRoutes = [
    {
      path: "/start",
      name: "onboarding",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/OnBoarding/reducer"),
          import("containers/OnBoarding"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("OnBoarding", reducer.default);

          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
  ];

  const AuthRoutes = [
    /** Login **/
    {
      path: "/login",
      name: "login",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/Login2/reducer"),
          import("containers/Login2/Login"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectOnce("login", reducer);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    /** Signup - Request Access **/
    {
      path: "/signup",
      name: "signup",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/Login2/reducer"),
          import("containers/Login2/Signup"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectOnce("login", reducer);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    /** Forgot Password - Request Reset Code **/
    {
      path: "/forgot-password",
      name: "forgotpassword",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/Login2/reducer"),
          import("containers/Login2/RequestLostPasswordCode"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectOnce("login", reducer);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    /** Reset Password - Enter Reset Code **/
    {
      path: "/reset-password",
      name: "resetpassword",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/Login2/reducer"),
          import("containers/Login2/EnterPasswordResetCode"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectOnce("login", reducer);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    /** Create Account ( old ) **/
    {
      path: "/create-account",
      name: "createaccount",
      onEnter: () => browserHistory.push("/signup"),
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/CreateAccount/reducer"),
          import("containers/CreateAccount/sagas"),
          import("containers/CreateAccount"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer("createaccount", reducer.default);
          injectSagas(sagas.default);

          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    /** Forgot Password - Enter Code ( old ) **/
    {
      path: "/login/confirm",
      name: "confirmForgotPasswordCode",
      onEnter: () => browserHistory.push("/login"),
      getComponent(nextState, cb) {
        const importModules = Promise.all([import("containers/ConfirmForgotPasswordCode")]);

        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
  ];

  const AppointmentRoutes = [
    {
      path: "/appointments",
      name: "AppointmentCenter",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/AppointmentCenter/reducer"),
          import("containers/AppointmentCenter"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectOnce("AppointmentCenter", reducer);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
  ];

  const DashboardRoutes = [
    {
      path: "/partner-old",
      name: "Partners Dashboard",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/PartnersDashboard/reducer"),
          import("containers/PartnersDashboard"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("PartnerDashboard", reducer.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/partner",
      name: "Partners Dashboard",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/PartnersDashboard2/reducer"),
          import("containers/PartnersDashboard2"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("PartnerDashboard", reducer.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/dashboard",
      name: "New Dashboard",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/SiteDashboard/reducer"),
          import("containers/SiteDashboard"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("SiteDashboard", reducer.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/dashboard",
      name: "dashboard",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/SiteSwitcherDashboard/reducer"),
          import("containers/SiteSwitcherDashboard/sagas"),
          import("containers/SiteSwitcherDashboard"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, sagas, component]) => {
          injectReducer("siteSwitcher", reducer.default);
          injectSagas(sagas.default);

          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/",
      name: "home",
      onEnter: () => browserHistory.push("/dashboard"),
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/Dashboard/reducer"),
          import("containers/Dashboard"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("homeDashboard", reducer.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
  ];

  const ContestRoutes = [
    {
      path: "/contests",
      name: "Contests",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/ContestsAdmin/reducer"),
          import("containers/ContestsAdmin"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("ContestsAdmin", reducer.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
    {
      path: "/contests/:contestId",
      name: "contests/detail",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/ContestsAdmin/reducer"),
          import("containers/ContestsAdmin/component/ContestManager/ContestManager"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("ContestsAdmin", reducer.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
  ]

  const AdminScreenRoutes = [
    {
      path: "/admin/funnels",
      name: "Funnels",
      getComponent(nextState, cb) {
        const importModules = Promise.all([import("containers/Admin/Funnels")]);

        const renderRoute = loadModule(cb);

        importModules.then(([component]) => {
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
  ];

  const protectedRoutes = [
    ...MarketingRoutes,

    ...EditorRoutes,

    ...StorefrontRoutes,

    //...ChatRoutes,

    ...UserRoutes,

    ...OnBoardingRoutes,

    ...AppointmentRoutes,

    ...DashboardRoutes,

    ...AdminScreenRoutes,

    ...ContestRoutes,

    {
      path: "/files",
      name: "Files",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/ManageFiles/reducer"),
          import("containers/ManageFiles"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectOnce("Files", reducer);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/sites",
      name: "Sites",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/Sites/reducer"),
          import("containers/Sites"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("sites", reducer.default);
          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/settings",
      name: "siteSettings",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/SiteSettings/reducer"),
          import("containers/SiteSettings"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("siteSettings", reducer.default);

          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },

    {
      path: "/account",
      name: "profileAccount",
      getComponent(nextState, cb) {
        const importModules = Promise.all([
          import("containers/ProfileAccount/reducer"),
          import("containers/ProfileAccount"),
        ]);

        const renderRoute = loadModule(cb);

        importModules.then(([reducer, component]) => {
          injectReducer("profile", reducer.default);

          renderRoute(component);
        });

        importModules.catch(errorLoading);
      },
    },
  ];

  const routes = [
    ...AuthRoutes,

    {
      path: "*",
      name: "notfound",
      getComponent(nextState, cb) {
        import("containers/NotFoundPage")
          .then(loadModule(cb))
          .catch(errorLoading);
      },
    },
  ];

  return protectedRoutes
    .map(route => {
      return Object.assign({ onEnter: checkAuth }, route);
    })
    .concat(routes);
}

export default createRoutes;
