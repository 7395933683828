import React from 'react';
import PropTypes from 'prop-types';
import Component from '../../../../rootComponent';
import {css} from "@convertly/css";
import Types from '../../../../attribute-types';
import {id} from '@convertly/thor';
import _ from 'lodash';
import {generateLink} from '../../../../utils/generateLink';
import {generateId} from '../../../../utils/generate-attribute';
import {clientImage} from '../../../../utils/generateImage';
import ReactHTMLParser from '@convertly/react-html-parser'

import { generateHeaderOffsetMediaQueries } from '../../header-style-functions/fixed-header-media-queries'


//scripts
import toggleDropDown from '../../scripts/nav-dropdown-toggle';
import isSignedIn from '../../scripts/is-signed-in';
import cartHandler from '../../scripts/cart-script';

//Components
import NavLink05 from './nav-link-05';

export default class NavList05 extends Component {

    constructor(props) {

        super(props);

    }


    render() {

        const {site, data, global, page, styles} = this.props;
        const inEditor = this.inEditor();
        let ecommEnabled = _.get(site, 'ecommerce.enabled');

        /** Cart **/
        let slug = _.get(page, 'slug');
        let cart = null;

        const clientImgSrc = clientImage("", {width: '60', height: '60', crop: 'fit'}, site.siteId);
        const clientLinkUrl = generateLink({linkType: "inferno", url: ""});

        let className = '';
        let text = ReactHTMLParser(data.text);
        let aphroditeClass = css(styles.headerNav);

        if (this.animatedClassName) {
            className += " " + this.animatedClassName
        }
        className += aphroditeClass;

        const {animated} = this.props.data;
        const inlineStyle = this.generateStyleAttribute();

        if (slug !== "/cart" && ecommEnabled) {

            cart =
                <li href={ generateLink({linkType: 'internal', url: '/cart'}) }
                    className={ css(styles.navListItem, styles.noBorder) }>
                    <span id={ id(cartHandler, clientLinkUrl, clientImgSrc, false, slug) }
                          className={ css(styles.desktopCartInfernoComponent) }
                    >Cart</span>
                </li>

        }
        return (

            <nav { ...this.generateDataEditorAttribute() }
                 {...generateId(this.props.elementId)}
                 onClick={(e) => {
                     this.launchElementalEditor('nav', e);
                 }}
                 onMouseOver={ this.addEditableOutline }
                 onMouseOut={ this.removeEditableOutline }
                 { ...inlineStyle }
                 data-animated={ animated }
                 className={ className }>
                <ul className={ css(styles.navList) }
                    id="menu">
                    {this.props.data.navLinks.map((navItem, index) => {
                        return (
                            <NavLink05 key={ index }
                                       data={navItem}
                                       id={ id }
                                       page={ this.props.page }
                                       styles={ styles }
                                       index={ index }/>
                        )
                    })}

                    {
                        ecommEnabled ? inEditor ?
                            <li className={ css(styles.navListItem) + " cart" }>Cart</li>
                            : cart : null
                    }

                    { ecommEnabled ? inEditor ?

                        <li className={ css(styles.navListItem) }>Sign In</li>
                        :
                        <li
                        id={ id(isSignedIn, clientLinkUrl) }
                        href={ generateLink({linkType: 'internal', url: '/account'}) }
                        className={ css(styles.navListItem, styles.noBorder) }>
                        Sign In
                        </li> : null
                    }

                </ul>
                <div className={ css( styles.mobileCart ) }>
                    { cart }
                </div>
            </nav>
        )
    }
}


/**
 * Connects element to context for cms editor
 **/
NavLink05.contextTypes = {
    store: PropTypes.object
};
