import React from "react";
import styled from "styled-components";

export const Underlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2001;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0);
`;
