import PropTypes from 'prop-types';
import React, { Component } from "react";
import { css } from "@convertly/css";
import styled from "styled-components";

const Input = styled.input`
  border: none;
  :focus,
  :active {
    outline: none;
  }
  border-bottom: 1px solid;
  padding: 0 0 1px 0;
  position: relative;
  top: 1px;
`;

export class InputInlineEditing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isInput: false
    };

    this.enableEditing = this.enableEditing.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
  }

  enableEditing(e) {
    this.setState({ isInput: true });
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.setState({ isInput: false });
      this.props.onSave();
    }
  }

  handleOnBlur(e) {
    if (!this.props.onlyInput) {
      this.setState({ isInput: false });
      this.props.onSave();
    }
  }

  render() {
    const { isInput } = this.state;
    const { width, placeholder, styles, onlyInput } = this.props;

    return (
      <div>
        {isInput || onlyInput ? (
          <Input
            autoFocus
            style={{ width: width, outline: "none", ...styles }}
            onChange={e => this.props.onChange(e.target.value)}
            onKeyPress={this.handleKeyPress}
            value={this.props.value}
            onBlur={this.handleOnBlur}
            placeholder={placeholder}
            onFocus={e => {
              let val = e.target.value;
              e.target.value = "";
              e.target.value = val;
            }}
          />
        ) : (
          <div style={{ ...styles, width: width, cursor: "pointer" }} onClick={this.enableEditing}>
            {this.props.label}
          </div>
        )}
      </div>
    );
  }
}

InputInlineEditing.contextTypes = {
  store: PropTypes.object
};
