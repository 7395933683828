import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { fromJS } from "immutable";
import { routerMiddleware } from "react-router-redux";
import createSagaMiddleware from "redux-saga";
import createReducer from "./reducers";
import LogRocket from 'logrocket';

import {
  setEditor,
  setModules,
  setOverlayStyles,
  setActiveComponent,
  toggleClientNav
} from "./containers/Editor/actions/editorActions";

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}, history) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware, routerMiddleware(history), thunk, LogRocket.reduxMiddleware()];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== "production" &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  /* eslint-enable */

  const store = createStore(createReducer(), fromJS(initialState), composeEnhancers(...enhancers));

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.asyncReducers = {}; // Async reducer registry

  store.popEditor = setEditor;
  store.setModules = setModules;
  store.setOverlayStyles = setOverlayStyles;
  store.setActiveComponent = setActiveComponent;
  store.toggleClientNav = toggleClientNav;

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept("./reducers", () => {
      import("./reducers").then(reducerModule => {
        const createReducers = reducerModule.default;
        const nextReducers = createReducers(store.asyncReducers);

        store.replaceReducer(nextReducers);
      });
    });
  }

  return store;
}
