import { takeLatest, call, put, select, takeEvery } from "redux-saga/effects";
import request from "utils/request";
import { purge, cache, purgeSiteCache } from "utils/purge";
import { setSite } from "@convertly/utils";
import { fromJS } from "immutable";
import _ from "lodash";
const generateId = require("uuid/v4");

import { graph } from "utils/graph";
import Logger from "utils/logger";
import LocalStorage from "utils/localStorage";

import {
  setImageUrls,
  setEditorSaveState,
  setPageData,
  loadPageData,
  setSiteData,
  setPageTemplates,
  loadPageTemplates,
  createPage,
  setSitePages,
  updateSiteSettings,
  setSettings,
  setPageSettings,
  setSiteLoadingStatus,
  setPageLoadingStatus
} from "./actions/editorActions";

import { setCurrentSite, setSites } from "../Authentication/actions";
import { showLoadingIcon } from "../App/actions";

/**
 *
 * @param pageId
 */
export function* fetchPageSettings(pageId) {
  yield put(setPageLoadingStatus(true));

  const query = `
	query( $id:ID! ){
  		Page( id: $id ) {
    		id, pageId, description, name, pageTitle, siteId, slug, pageId, options,
    		socialTitle, socialDescription, socialImage,
  		}
	}
	`;

  const variables = {
    id: pageId.payload
  };

  const response = yield call(graph, { query, variables });

  const { Page } = response;

  try {
    Page.options = JSON.parse(Page.options);
    if (!_.isObject(Page.options)) {
      Page.options = {};
    }
  } catch (e) {
    Page.options = {};
  }

  yield put(showLoadingIcon(false));
  yield put(setPageSettings(Page));
  yield put(setPageLoadingStatus(false));
}

/**
 * Fetches URLS for image gallery
 * TODO @pmarashian Convert to Graph
 */
export function* fetchGalleryImages(siteId) {
  try {
    const response = yield call(
      request,
      "https://apiuseast1.sanjayshroff.info/images/" + siteId.payload
    );
    const images = response.list.map(img => {
      const keys = img.Key.split("/");
      return keys[keys.length - 1];
    });

    yield put(setImageUrls(images));
  } catch (e) {
    console.error(e);
  }
}

/**
 * Saves page data
 * Filters out the common elements before saving
 * Filters out global and site from elements
 * @param args
 */
export function* updatePage(args) {
  console.log("updatePage saga");

  const { page, elements, site } = args.payload;
  const { id } = page;
  const domain = site.get("domain");

  // Filter out the unique elements ( header and footer )
  let elementsToSave = elements.filter(el => {
    return !el.unique;
  });

  const rootId = generateId();
  const clean = cleanElements(elementsToSave, null);

  const flat = flatten(clean);
  const random = shuffleArray(flat);

  /*
	// Filter out element data that should not be saved
	elementsToSave = elementsToSave.map ( ( el ) => {

		if ( _.hasIn ( el, 'data.global' ) ) {
			delete el.data.global;
		}

		if ( _.hasIn ( el, 'data.site' ) ) {
			delete el.data.site;
		}

		if ( _.has ( el, 'url' ) ) {
			delete el.url;
		}

		return el;

	} );
	*/

  const query = `mutation($id:ID!,$page:pageInput){editPage(id:$id,page:$page){id}}`;

  const variables = {
    page: {
      elements: JSON.stringify(random)
    },
    id: id
  };

  try {
    yield call(graph, { query, variables });
    yield call(purge, `${domain}${page.slug}`);
    yield call(cache, `${domain}${page.slug}`);
    yield put(setEditorSaveState(false));
  } catch (e) {
    console.error(e.message);
    yield put(setEditorSaveState(false));
  }

  function flatten(array) {
    return flattenFrom(array);
  }

  function flattenFrom(array) {
    return flattenDown(array, []);
  }

  function flattenDown(array, result) {
    for (let i = 0; i < array.length; i++) {
      let value = array[i];

      if (Array.isArray(value.children)) {
        flattenDown(value.children, result);
        delete value.children;
      }

      result.push(value);
    }

    return result;
  }

  function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  function cleanElements(elementsArr, parentId) {
    if (Array.isArray(elementsArr)) {
      elementsArr.sort((a, b) => {
        return a.sort - b.sort;
      });

      let currentSort = 1;

      return elementsArr.map(o => {
        o.a = generateId();
        o.b = parentId;
        o.c = currentSort++;
        if (o.sort) delete o.sort;

        if (o.userOverrides) {
          o.p = o.userOverrides;
          delete o.userOverrides;
        }

        if (o.data) {
          if (o.data.site) delete o.data.site;
          if (o.data.global) delete o.data.global;
          if (o.data.userOverrides) {
            o.data.a = o.data.userOverrides;
            delete o.data.userOverrides;
          }
        }
        o.d = o.data;
        delete o.data;

        if (o.site) delete o.site;

        delete o.parentId;

        if (o.type) delete o.type;
        if (o.id) delete o.id;
        if (o.label) delete o.label;
        if (o.editable) {
          o.z = 1;
        }
        delete o.editable;

        o.e = o.el;
        delete o.el;

        o.children = cleanElements(o.children, o.a, false);
        return o;
      });
    } else {
      return elementsArr;
    }
  }
}

/**
 * Fetches site data, site pages, and common elements
 * Calls setSiteData with returned objects
 * Iterates through site pages and loads homepage ( with slug === '/' )
 *
 * @param args
 */
export function* fetchSite(args) {
  Logger.debug("containers/editor/sagas::fetchsite()", args);

  // Not sure why this is being called undefined payload
  if (!args.payload) {
    Logger.debug("payload is undefined", args);
    return;
  }

  yield put(setSiteLoadingStatus(true));

  try {
    const { site, cb } = args.payload;
    const { id } = site;

    const query = `
		query( $id: ID! ){
		  Site(id: $id) {
			id, siteId, domain, theme, timeZone, ssl, goToTop, googleAnalytics, facebookUrl, 
			twitterUrl, siteName, siteDescription, name, siteCategory, 
			customPixels {
			  pixelTitle, pixelSource, pixelType
			},
			commonPageElements {
			  id, type, element
			},
			pages( id: $id ) {
			  id, name, slug, pageId
			}
		  }
		}
		`;

    const variables = {
      id: id
    };

    const response = yield call(graph, { query, variables });
    const { Site } = response;

    Site.commonElements = Site.commonPageElements.map(object => {
      object.element = JSON.parse(object.element);
      return object;
    });

    delete Site.commonPageElements;

    setSite(Site);

    // Editor reducer
    yield put(setSiteData(Site));

    // Auth reducer
    yield put(setCurrentSite(Site));

    LocalStorage.setItem("active-site", Site.id);

    let homepageId = 0;

    Site.pages.map((page, index) => {
      if (page.slug === "/") {
        homepageId = page.id;
      }
    });

    yield put(loadPageData(homepageId));

    if (cb) {
      Logger.debug("saga cb");
      cb();
    }

    yield put(setSiteLoadingStatus(false));
    Logger.debug("containers/editor/sagas::fetchsite() end");
  } catch (err) {
    Logger.error("fetchSite error: ", err.message);
  }
}

/**
 * Fetch the available page templates from API
 *
 * Calls Create Page API
 * Loads new page
 * Refreshes site pages
 * TODO @pmarashian Convert to API
 *
 * @param args
 */
export function* fetchPageTemplates() {
  const newPageTemplates = [
    {
      optionName: "all",
      templates: ["about01"]
    },
    {
      optionName: "About",
      templates: ["about01"]
    },
    {
      optionName: "Gallery",
      templates: ["gallery01", "gallery02"]
    }
  ];

  yield put(setPageTemplates(newPageTemplates));
}

/**
 * Calls Create Page API
 * Refreshes Site Pages
 * Use returned values from create fn
 *
 * @param args
 */
export function* createNewPage(args) {
  const { siteId, name } = args.payload;

  const query = `
	mutation ($page: pageInput, $siteId:ID!) {
		createNewPageAndReturnUpdatedPages(id: $siteId, page: $page) {
		description, elements, name, id, pageTitle, siteId, slug, pageId, 
		socialTitle, socialDescription, socialImage,
		pages {
		  id, slug, name, pageId
		}
	  }
	}
	`;

  const variables = {
    siteId: siteId,
    page: {
      siteId: siteId,
      name: name,
      elements: "[]"
    }
  };

  const response = yield call(graph, { query, variables });
  const page = response.createNewPageAndReturnUpdatedPages;
  const { pages } = page;

  delete page.pages;
  page.elements = JSON.parse(page.elements);

  yield put(setPageData(page));
  yield put(setSitePages(pages));
}

/**
 * Calls Delete Page API
 * Refreshes Site Pages
 *
 * @param args
 */
export function* updateSiteProperties(args) {
  console.log("updateSiteProperties");

  const { id, object, currentSite, sites } = args.payload;

  const query = `
    mutation( $id:ID!, $site:siteInput ) {
	  editSite( id: $id, site: $site ) {
		id, siteId, domain, theme, timeZone, ssl, goToTop, googleAnalytics, facebookUrl, 
			twitterUrl, siteName, siteDescription, name, siteCategory
	  }
	}
    `;

  const variables = {
    id: id,
    site: object
  };

  const response = yield call(graph, { query, variables });

  const updateSite = response.editSite;
  updateSite.pages = currentSite.pages;
  updateSite.commonElements = currentSite.commonElements;

  const updatedSites = sites.map(site => {
    if (site.get("id") === updateSite.id) {
      return fromJS(updateSite);
    }
    return site;
  });

  yield purgeSiteCache(updateSite.domain);

  // Editor reducer
  yield put(setSiteData(updateSite));

  // Auth reducer
  yield put(setCurrentSite(updateSite));

  // Update Auth reducer sites array
  yield put(setSites(updatedSites));
}

/********************* SAGAS *********************/

/**
 * Load Page Templates Saga
 */
export function* loadPageTemplatesSaga() {
  yield takeLatest(loadPageTemplates.toString(), fetchPageTemplates);
}

/**
 * Update Site Settings Saga
 */
export function* updateSiteSettingsSaga() {
  yield takeLatest(updateSiteSettings.toString(), updateSiteProperties);
}

/**
 * Create Page Saga
 */
export function* createPageSaga() {
  yield takeLatest(createPage.toString(), createNewPage);
}

/**
 * Load Page Settings Saga
 */
export function* loadPageSettingsSaga() {
  yield takeLatest(setSettings.toString(), fetchPageSettings);
}

export default [
  createPageSaga,
  loadPageTemplatesSaga,
  updateSiteSettingsSaga,
  loadPageSettingsSaga
];
