import { StyleSheet } from "@convertly/css";
import globalModuleStyles from '../../../elements/style-functions/module-global-styles';

const productGalleryStyles = ( global, data ) => {

	const productLisitingGlobalStyles = new globalModuleStyles( global, data );

	const productBox = {
		display:'inline-block',
		opacity: '1',
		lineHeight: '8px',
		margin: '0 50px 50px',
	};
	const productBoxMobile = {
		'@media (max-width:741px)':{
			fontSize:'20px',
			margin: '0 0 56px',
			width:'100%',
			height:'450px',
		},
		'@media (max-width:540px)':{
			fontSize:'16px',
			margin: '0 0 56px',
			width:'100%',
			height:'350px',
		}
	};

	return StyleSheet.create({
		productGalleryContainer: {
			marginBottom:'40px',
			paddingTop: '75px',
			width:'100%',
			position:'relative',
			fontFamily:global.fontFamily.primaryFont,
			...productLisitingGlobalStyles,
		},
		productGalleryHeader: {
			textAlign:'center'
		},
		productGalleryHeading: {
			fontSize: '42px',
			textAlign: 'center',
			paddingBottom: '50px'
		},
		productGalleryHeaderList: {
			borderBottom: '2px solid #b7b7b7',
			marginBottom: '60px',
			listStyle: 'none',
			'@media only screen and (max-width: 502px)' : {
				padding: '0',
			}
		},
		productGalleryHeaderListItem: {
			marginLeft: '45px',
			display: 'inline-block',
			fontSize: '20px',
			textAlign: 'center',
			':first-child': {
				marginLeft: '0',
			},
			color: global.textColors.accent.color1,
			padding: '0 .7em',
			paddingBottom: '16px',
			position: 'relative',
			cursor:'pointer',
			top: '2px',
			'.active': {
				borderBottom: '2px solid',
				borderColor: global.textColors.accent.color1
			},
			':hover': {
				borderBottom: '2px solid',
				borderColor: global.textColors.accent.color1
			},
			'@media only screen and (max-width: 767px)' : {
				marginLeft:'35px',
				padding: '0 10px 10px'
			},
			'@media only screen and (max-width: 502px)' : {
				marginLeft:'30px',
				padding: '0 0px 5px',
				fontSize: '15px'
			}

		},
		productGalleryBody: {
			position: 'relative',
			textAlign: 'center',
			display:'none',
			transition:'all .4s',
			'.active': {
				display:'block'
			},
			'@media only screen and (max-width: 502px)' : {
				width:'100%'
			}
		},
		productBox2Col: {
			...productBox,
			height:'450px',
			width:'450px',
			fontSize:'20px',
			...productBoxMobile


		},
		productBox3Col: {
			...productBox,
			height:'300px',
			width:'300px',
			fontSize:'12px',
			margin:'0 25px 25px',
			'@media (max-width:1200px)': {
				height:'250px',
				width:'250px',
			},
			...productBoxMobile
		},
		productBox4Col: {
			...productBox,
			height:'200px',
			width:'200px',
			fontSize:'8px',
			margin:'0 25px 25px',
			...productBoxMobile
		},
		productBoxWrapper: {
			position: 'relative',
			width:'100%',
			height:'100%'
		},
		productDescriptionWrapper: {
      fontFamily:global.fontFamily.secondaryFont,
			position: 'absolute',
			top: 0,
			width: '100%',
			height: '100%',
			textAlign: 'center',
			left: 0,
			opacity:1,
			transition: 'all 0.3s linear 0s',
			background: 'rgba(255, 255, 255, 0.65)',
			color:global.textColors.dark.color1,
			':hover': {
				opacity:1,

			},
			'@media (max-width:768px)': {
				opacity:1,
				background: 'rgba(0, 0, 0, 0.45)',
				color:global.textColors.light.white,
			}
		},
		productImg: {
			height:'100%',
			width:'100%'
		},
		productDescription: {
			width: '100%',
		},
		productDescriptionCallOut: {
			fontFamily:global.fontFamily.primaryFont,
			fontSize: '1.2em',
			color: global.textColors.accent.color2,
			marginTop: '1.4em',
            marginBottom:'2em',
			display: 'block',
			clear:'both'
		},
		productDescriptionSubTitle: {
			fontSize: '1.2em',
			marginBottom:'1.4em',
			display: 'block',
			paddingBottom: '.2em',
			'@media (max-width:620px)': {
				marginBottom:'2em',
				fontSize:'.9em'
			}
		},
		productTitle: {
            fontFamily:global.fontFamily.primaryFont,
			fontSize: '2.5em',
			lineHeight:'1.2em',
			paddingBottom: '.9em',
            '@media (max-width:620px)': {
                paddingBottom: '1.4em',
            }

		},
		fullPrice: {
			opacity:'.5',
			fontSize: '1.4em',
			textDecoration: 'line-through',
			paddingBottom: '2em'
		},
		salePrice: {
			fontSize: '2.2em',
			'@media (max-width:380px)': {
				fontSize:'1.5em'
			}
		},
		quickBuy: {
			fontSize: '1.6em',
			color: global.textColors.light.white,
			textAlign: 'center',
			background: global.backgroundColors.accent.color1,
			padding: '1em 0',
			position: 'absolute',
			bottom: 0,
			left: 0,
			width: '100%',
			transition: 'all 0.3s linear 0s',
			'@media (max-width:786px)': {
				fontSize:'1em'
			},
			':hover': {
				opacity:.7
			}
		},



	})

};
export default productGalleryStyles;
