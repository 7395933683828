import { createAction, batch } from "redux-act";
import _ from "lodash";
import { fromJS } from "immutable";
import { store } from "app/app";
import { graph, delay } from "utils";
import { getActiveSiteId, uploadImage } from "services/sites";

import * as filters from "./filter";
import {
  changePageType,
  loadActivePage,
  setActivePage,
  setSitePages,
  setSiteDataPages,
  openImageEditor,
} from "../../actions/editorActions";
import { changePanel, setProcessing, goToPanel } from "../../actions/navBarActions";

import { Alerts } from "services/Alerts";
import { openImageGallery } from "utils";

import { PANELS } from "../constants";

const p = "PAGES PANEL: ";

export const setPageInfoActiveTab = createAction(`${p}Set active tab in Pages Info panel`);
export const setSnapshot = createAction(`${p}Set pages snapshot`);
export const setPages = createAction(`${p}Set pages data`);
export const setLoaded = createAction(`${p}Set Pages data`);
export const setEditSelected = createAction(`PAGE PANEL: Edit Page`);
export const setResetEditLocationIndex = createAction(`PAGES PANELS: Set reset edit page idx`);
export const setPage = createAction(`${p}Set page being edited object`);
export const setSearching = createAction(`${p}Searching`);
export const setFilteredData = createAction(`${p}Set filtered data`);
export const setUpdateData = createAction(`${p}updateData`);
export const setMoreOptionsActivePageId = createAction(
  `${p}Set page in Panel to display more options`
);

const getStore = () => store.getState().get("editor").Pages;

const getSelectedPageId = () => getStore().get("moreOptionsActivePageId");

export const editSocialImage = () => async dispatch => {
  const page = getStore().get("page");
  await dispatch(
    openImageEditor({
      params: {},
      cb: async function(params, src) {
        await dispatch(updateValue("socialImage", src));
      },
      src: page.get("socialImage"),
    })
  );
};

export const replaceSocialImage = () => async dispatch => {
  await dispatch(
    openImageGallery(async src => {
      await dispatch(updateValue("socialImage", src));
    })
  );
};

export const uploadSocialImage = files => async dispatch => {
  const file = files[0];

  await dispatch(setProcessing(true));

  const filename = await dispatch(uploadImage(file));

  await dispatch(updateValue("socialImage", filename));
  await dispatch(setProcessing(false));
};

export const deletePage = selectedPage => async dispatch => {
  const pageId = selectedPage.id;

  const pages = getStore().get("pages");

  const filtered = pages.filter(p => {
    return p.id === pageId;
  });

  if (!filtered.size) {
    // this should never happen, but just in case.
    return;
  }

  const page = filtered.get(0);

  if (page.slug === "/") {
    return await Alerts.alert(
      "This is your homepage. Please set another page as your homepage first.",
      {
        label: "Close",
      }
    );
  }
  if (!(await Alerts.confirm(`Delete ${page.name}?`))) {
    return;
  }
  await dispatch(setProcessing(true));

  const query = `
    mutation($id:ID!){
      deletePage(id:$id) {
        id
      }
    }
  `;

  const variables = {
    id: page.id,
  };

  await graph({ query, variables });

  await dispatch(updateSitePages());

  await dispatch(setProcessing(false));
};

export const duplicatePage = selectedPage => async dispatch => {
  const pageId = selectedPage.id;
  await dispatch(setProcessing(true));

  const query = `
    mutation($id:ID!){
      duplicatePage(id:$id) {
        id
      }
    }
  `;

  const variables = {
    id: pageId,
  };

  await graph({ query, variables });

  await dispatch(updateSitePages());

  await dispatch(setProcessing(false));
};

/**
 * Validates page object
 * @param page
 * @returns {boolean}
 */
export const isPageValid = page => {
  if (!page.get("name")) return false;

  if (!page.get("id")) return true;

  // if there is no ID this is a new page being created
  // and we are not going to validate or check against the slug

  if (!page.get("slug")) return false;

  if (slugAlreadyExists(page)) {
    return false;
  }

  return true;
};

const slugAlreadyExists = page =>
  !!getStore()
    .get("pages")
    .filter(p => {
      if (p.slug === page.get("slug") && p.id !== page.get("id")) {
        return true;
      }
      return false;
    }).size;

export const resetPageInfo = () => async dispatch => {
  await Promise.all([dispatch(setPageInfoActiveTab("SEO"))]);
};

/**
 * Switch to EDIT PAGE panel with empty page object
 * @returns {Function}
 */
export const addPage = () => async dispatch => {
  await dispatch(
    loadPageInfoPanel({
      siteId: getActiveSiteId(),
    })
  );
};

/**
 * Loads page info and switch to EDIT PAGE panel
 * @returns {Function}
 */
export const editPageSettings = selectedPage => async dispatch => {
  dispatch(changePanel(PANELS.EDIT_PAGE, PANELS.PAGES));
  await dispatch(setProcessing(true) );

  const pageId = selectedPage.id;
  const query = `
	query( $id:ID! ){
  		Page( id: $id ) {
    		id, pageId, description, name, pageTitle, siteId, slug, pageId, options,
    		socialTitle, socialDescription, socialImage, keywords, doNotIndex
  		}
	}
	`;

  const variables = {
    id: pageId,
  };

  const res = await graph({ query, variables });
  await delay(500);

  const page = _.get(res, "Page");

  await Promise.all([dispatch(loadPageInfoPanel(page)), dispatch(setProcessing(false))]);
};

/**
 * Helper function to switch to EDIT PAGE panel and load page and snapshot
 * Created the helper function b/c addPage and editPageSettings were callling
 * the same code
 * @param page
 * @returns {Function}
 */
export const loadPageInfoPanel = page => async dispatch => {
  console.log(page, 'its so easy to walk away')
  await Promise.all([
    dispatch(changePanel(PANELS.EDIT_PAGE, PANELS.PAGES)),
    dispatch(setPage(page)),
    dispatch(setSnapshot(page)),
  ]);
};

const isExistingPage = page => !!page.get("pageId");

export const savePageSettings = page => async dispatch => {
  await dispatch(setProcessing(true));

  if (isExistingPage(page)) {
    await updatePageSettings(page);
  } else {
    await saveNewPage(page).then(updatePageSettings);
  }

  await dispatch(updateSitePages());

  await Promise.all([dispatch(setProcessing(false)), dispatch(goToPanel({ panel: PANELS.PAGES }))]);
};

export const updateSitePages = () => async dispatch => {
  const query = `
    query Pages($siteId: ID!) {
      Site(id: $siteId) {
        pages(id: $siteId) {
          id, slug, name, pageId
        }
      }
    }
  `;

  const variables = {
    siteId: getActiveSiteId(),
  };

  const res = await graph({ query, variables });

  const pages = _.get(res, "Site.pages") || [];

  await Promise.all([dispatch(setSitePages(pages)), dispatch(setSiteDataPages(pages))]);

  await dispatch(loadPages());

  await dispatch(changePanel(PANELS.PAGES, null));
};

const saveNewPage = async page => {
  const query = `mutation($page:pageInput){
    createPage(page:$page){id}
  }`;

  const variables = {
    page: {
      siteId: page.get("siteId"),
      name: page.get("name"),
      elements: "[]",
    },
  };

  const res = await graph({ query, variables });
  const { id } = _.get(res, "createPage");

  return page.set("pageId", id);
};

const updatePageSettings = async page => {
  const query = `mutation($id:ID!,$page:pageInput,$siteId:String){
    editPage(id:$id,page:$page,siteId:$siteId){id}
  }`;

  const variables = {
    id: page.get("pageId"),
    page: {
      description: page.get("description"),
      pageTitle: page.get("pageTitle"),
      keywords: page.get("keywords"),
      socialTitle: page.get("socialTitle"),
      socialDescription: page.get("socialDescription"),
      socialImage: page.get("socialImage"),
      doNotIndex: page.get("doNotIndex"),
      slug: page.get("slug"),
      name: page.get("name"),
    },
    siteId: page.get("siteId"),
  };

  const res = await graph({ query, variables });
  const id = _.get(res, "editPage.id");

  if (id !== page.get("pageId")) {
    await Alerts.alert("Error saving page. Please try again.");
  }

  return page;
};

/**
 * Load page in the Editor
 * @param page
 * @returns {Function}
 */
export const editPage = page => async dispatch => {
  const activePageId = store
    .getState()
    .get("editor")
    .editorReducer.getIn(["page", "id"]);

  if (activePageId === page.id) {
    return;
  }

  await Promise.all([dispatch(changePageType("")), dispatch(setActivePage(page.id))]);

  await dispatch(loadActivePage());
};

/**
 * Initialize data for Pages Panel
 * @returns {Function}
 */
export const init = () => async dispatch => {
  await Promise.all([dispatch(loadPages())]);
};

/**
 * Grabs pages from SiteData reducer
 * Formats data for the PageBeard component
 * Sets them to the Pages reducer
 * @returns {Function}
 */
export const loadPages = () => async dispatch => {
  let pages = store
    .getState()
    .get("editor")
    .SiteData.get("pages");

  pages = pages
    .map(page => {
      return {
        id: page.get("id"),
        name: page.get("name"),
        slug: page.get("slug"),
        children: [],
        type: "standard",
      };
    })
    .sortBy(page => page.name.toLowerCase());

  await dispatch(setPages(pages));
};

/**
 * The more options widget on the Pages panel
 * uses a key to determine if options should be rendered
 * Using the page ID for the unique key
 * @param page
 * @returns {Function}
 */
export const toggleMoreOptions = page => async dispatch => {
  let moreOptionsActivePageId = getStore().get("moreOptionsActivePageId");

  let newActivePageId = "";

  if (page && page.id !== moreOptionsActivePageId) {
    newActivePageId = page.id;
  }

  await dispatch(setMoreOptionsActivePageId(newActivePageId));
};

export const updateValue = (field, value) => async dispatch => {
  let page = getStore().get("page");

  page = page.set(field, value);
  await dispatch(setPage(page));
};

export const updateStore = (field, e, empty, undefined) => async dispatch => {
  let value = _.get(e, "target.value");
  if (value === undefined) {
    value = e;
  }
  await dispatch(updateValue(field, value));
};

export const toggleValue = (field, value) => async dispatch => {
  await dispatch(updateValue(field, !value));
};

function flat(array) {
  let result = [];
  array.forEach(function(a) {
    if (!a.children.length) result.push(a);
    else {
      let copy = Object.assign({}, a);
      copy["children"] = [];
      result = result.concat(copy);
      result = result.concat(flat(a.children));
    }
  });
  return result;
}

/**
 * Updates the search value
 * Filters pages by search value
 * and saves both to reducer
 *
 * @param value
 * @returns {Function}
 */
export const updateSearch = value => async dispatch => {
  const filter = value.trim();

  if (!filter) {
    return await dispatch(setSearching(value.trim()));
  }

  let filteredData = getStore()
    .get("pages")
    .toJS();

  let filteredNodes = flat(filteredData).map(node => filters.filterTree(node, filter));

  // remove roots that don't match
  filteredNodes = filteredNodes.filter(
    node => node.children.length || node.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
  );

  await dispatch(batch([setSearching(filter), setFilteredData(fromJS(filteredNodes))]));
};

export const toggleNode = (node, toggled) => async dispatch => {
  let data = getStore().get("pages");

  data = data.map(currNode => {
    if (currNode.get("id") === node.id) {
      return currNode.set("toggled", toggled);
    }
    return currNode;
  });

  await dispatch(setUpdateData(data));
};
