import React from 'react';

import Component from '../../../../rootComponent';
import {css} from "@convertly/css";
import Types from '../../../../attribute-types';
import {id, getRenderState} from '@convertly/thor';


//scripts
import toggleDropDown from '../../scripts/nav-dropdown-toggle';
import activeNavLink from '../../scripts/active-nav-link';



//Components
import NavLink07 from './nav-link-07';

export default class NavList07 extends Component {

    constructor(props) {

        super(props);

        this.state = {
            ecommSite: false
        }

    }

    checkEcommerce() {
        if (this.props.site) {
            if (this.props.site.ecommerce) {
                    this.setState({
                        cartDiv:null
                    })
                }
            }
    }

    componentWillMount() {
        this.checkEcommerce();
    }
    render() {


        let { site, hideMobile, styles, data  } = this.props;


        let rootId = id(activeNavLink);

        let mobileHideClass = hideMobile ? css( styles.mobileHide ) : '';


        return (
            <nav className={ css(styles.headerNav) + " " + mobileHideClass }>

                <ul className={ css(styles.navList) }
                    id="menu">

                    {data.navLinks.map((navItem, index) => {
                        return (
                            <NavLink07
                                key={ index }
                                data={ navItem }
                                styles={ styles }
                                index={ index }
                                id={ rootId}/>
                        )
                    })}


                </ul>


            </nav>
        )
    }
}

