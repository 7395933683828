import React from 'react';
import Component from '../../../../rootComponent';
import {css} from "@convertly/css";
import {id}  from '@convertly/thor';
import ReactHTMLParser from "@convertly/react-html-parser";
import {generateLink} from '../../../../utils/generateLink';
import {clientImage} from '../../../../utils/generateImage';
import _ from 'lodash';

//Default Props
import defaultData from '../../header-default-data';
import defaultGlobal from '../../../../themes/global';

//Styles
import convertlyNavStyles from './convertly-nav-styles.js';

//Scripts
import mobileMenuHandler from '../../scripts/mobile-menu-handler';

//Components
import Elements from 'source/elements/index';
import NavList05 from './convertly-nav-list';
import ConvertlyLogo from '../svg/convertly-beta-logo';
import ConvertlyLogoMobile from '../svg/convertly-logo-mobile';


//Utils
import MenuLoginContainer from '../inferno-components/menu-login-container-inferno';


export default class ConvertlyNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showHeaderMenu: false,
            toggledClass: ''
        };

        this.toggleHeaderMenu = this.toggleHeaderMenu.bind(this);

    }

    toggleHeaderMenu() {
        this.setState((prevState) => {
            return prevState.showHeaderMenu = !prevState.showHeaderMenu;
        });

        this.setState((prevState) => {
            return this.state.showHeaderMenu ? prevState.toggledClass = ' open' : prevState.toggledClass = '';
        });
    }

    render() {

        let {global, data, headerPosition, page} = this.props;

        this.styles = convertlyNavStyles(global, data, headerPosition);

        let headerTagline;
        let onHomepage = false;
        
        if (page) {
          if (page.slug === '/') {
            onHomepage = true;
          }
          switch (page.slug) {
                case 'tour':
                    headerTagline = (
                        <span>
                        <h2 className={ css(this.styles.tagLineSmall) }>What Can You Do With</h2>
                        <h3 className={ css(this.styles.tagLineLarge) }>Converlty???</h3>
                    </span>
                    )
                    break;
                default:
                    headerTagline = (
                        <span>
                    </span>
                    )
            }
        }


        return (
            <Elements.Container data={ {containerFluid: true} }>
                <Elements.Row>
                    <div className={ css(this.styles.menuContainer) + " headerNav" }>

                        <div className={ `${ css(this.styles.logo) }${ onHomepage ? ' on-homepage' : '' }` } id="logo">
                            <a href={ generateLink('/') }>

                                {/* mobile white */}
                                {/* <img src={ clientImage(this.props.data.mobileLogoLight.src) }
                                 alt={ this.props.data.mobileLogoLight.alt }
                                 id='logo'
                                 title={ this.props.data.mobileLogoLight.title }
                                 data-element="mobileMenu"
                                 className={ css(this.styles.logoImg) + " light-mobile" }/> */}
                                {/* mobile dark */}
                                {/* <img src={ clientImage(this.props.data.mobileLogoDark.src) }
                                 alt={ this.props.data.mobileLogoLight.alt }
                                 id='logo'
                                 title={ this.props.data.mobileLogoLight.title }
                                 data-element="mobileMenu"
                                 className={ css(this.styles.logoImg) + " dark-mobile" }/> */}
                                {/* desktop light */}
                                {/* <img src={ "https://s3.amazonaws.com/convertlyimguploadeast/5cfb42c0-b3c1-11e7-8b08-c3836d7675d8/logo-black.svg" }
                                 alt={ this.props.data.mobileLogoLight.alt }
                                 id='logo'
                                 title={ this.props.data.mobileLogoLight.title }
                                 className={ css(this.styles.logoImg) + " light-desktop" }/> */}
                                {/*<ConvertlyLogoMobile className={ css(this.styles.logoImgMobile) }/>*/}
                                {/*<ConvertlyLogo className={ css(this.styles.logoImg) }/>*/}
                                <img src={ clientImage('logo-convertly-nobeta.png') }
                                     alt={ this.props.site.logo.src }
                                     id='logo'
                                     title={ this.props.site.logo.src }
                                     className={ css(this.styles.logoImg) + " light-desktop light-mobile" }/>

                            </a>
                        </div>
                        
                        <div className={ `${ css(this.styles.mobileLogo) }${ onHomepage ? ' on-homepage' : '' }` } id="mobile-logo">
                            <a href={ generateLink('/') }>
                            <img src={ clientImage('c_symbol.png') }
                                     alt={ this.props.site.logo.src }
                                     id='mobile-logo'
                                     title={ this.props.site.logo.src }
                                     className={ css(this.styles.mobileLogo) + " light-desktop light-mobile" }/>
                            </a>
                        </div>

                        <div className={ css(this.styles.menuLauncherIcon) + this.state.toggledClass }
                             id="menuIcon">
                            <button
                                id={ id(mobileMenuHandler) }
                                //onClick={ this.toggleHeaderMenu }
                                data-target="mobileMenu"
                                type="button"
                                className={ css(this.styles.navLaunch) }>
                                <span className="sr-only">Toggle navigation</span>
                                <span
                                    className={ css(this.styles.iconBar) + this.state.toggledClass + " icon-bar" }
                                    data-target="mobileMenu"
                                    data-element="mobileMenu"
                                >
                                            </span>
                                <span
                                    className={ css(this.styles.iconBar) + this.state.toggledClass + " icon-bar" }
                                    data-target="mobileMenu"
                                    data-element="mobileMenu"
                                >
                                            </span>
                                <span
                                    className={ css(this.styles.iconBar) + this.state.toggledClass + " icon-bar" }
                                    data-target="mobileMenu"
                                    data-element="mobileMenu"

                                >
                                            </span>
                            </button>
                        </div>
                        <div className={ css(this.styles.mainMenu) + this.state.toggledClass }>
                            <NavList05
                                styles={ this.styles }
                                page={ this.props.page }
                                global={ this.props.global }
                                data={ this.props.data.menu }/>


                        </div>
                        <div className={ css(this.styles.tagLineContainer) }
                             data-element="mobileMenu"
                        >
                            {
                                headerTagline
                            }
                        </div>
                        <div className={ css(this.styles.mobileMenu) }
                             data-element="mobileMenu"
                        >
                            <NavList05
                                styles={ this.styles }
                                page={ this.props.page }
                                global={ this.props.global }
                                data={ this.props.data.menu }/>
                            {/* <div className={ css(this.styles.mobileLogin) }>
                                <div id={ id(MenuLoginContainer) }
                                     className={ css(this.styles.loginInfernoComponent) }/>
                            </div> */}
                        </div>

                    </div>
                </Elements.Row>
            </Elements.Container>

        )
    }
}

ConvertlyNav.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
