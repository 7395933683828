import _ from "lodash";
import { graph } from "utils";

/**
 *
 * @param siteId
 * @returns {Promise<*|Array>}
 */
export const fetchCategories = async siteId => {
  const query = `
    query BlogCategories($siteId: ID!) {
      BlogCategories(siteId: $siteId) {
        id, slug, name, url, siteId
      }
    }
  `;

  const variables = {
    siteId,
  };

  const res = await graph({ query, variables });
  return _.get(res, "BlogCategories") || [];
};

/**
 *
 * @param catId
 * @param fields
 * @returns {Promise<*>}
 */
export const fetchCategory = async (catId, fields) => {
  const query = `
  query BlogCategory ($catId:ID!) {
      BlogCategory(id:$catId) {
        ${Array.isArray(fields) ? fields.join(",") : fields}
      }
    }
  `;

  const variables = {
    catId,
  };

  const res = await graph({ query, variables });
  return _.get(res, "BlogCategory");
};

/**
 *
 * @param siteId
 * @param category
 * @returns {Promise<void>}
 */
export const createCategory = async ({ siteId, category }) => {
  const query = `
    mutation($siteId:ID!, $category:BlogCategoryPageInput){
      createBlogCategory(siteId:$siteId,blogCategoryPageInput:$category){
        id, name
      }
    }
  `;

  const variables = {
    siteId,
    category,
  };

  const res = await graph({ query, variables });

  return _.get(res, "createBlogCategory");
};

export const updateCategory = async ({ category, catId }) => {
  const query = `
    mutation($id:ID!, $category: BlogCategoryPageInput ){
      updateBlogCategory(id:$id, blogCategoryPageInput: $category ){
        id
      }
    }
  `;

  const variables = {
    id: catId,
    category,
  };

  await graph({ query, variables });
};

export const fetchCategoryPostCount = async ({ siteId, catId }) => {
  const query = `
    query BlogPostsCount($data:blogQueryInput) {
      BlogPostsCount(blogQueryInput:$data)
    }
  `;

  const variables = {
    data: {
      siteId,
      categories: [catId],
    },
  };

  const res = await graph({ query, variables });

  return _.get(res, "BlogPostsCount");
};

export const deleteBlogCategory = async ({ siteId, catId }) => {
  const query = `
    mutation($data:DeleteArgsInput){
      deleteBlogCategory(deleteBlogCategory:$data) {
        success, message
      }
    }
  `;

  const variables = {
    data: {
      siteId,
      id: catId,
      hardDelete: true,
    },
  };

  return await graph({ query, variables });
};
