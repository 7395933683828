/*global Inferno, $ */
/* eslint-disable */

const Scripts = ( id ) => {

	return ( id ) => {

		const root = document.getElementById( id );
		const defaultValue = $( root ).data('value');
		const dispatch = document.getElementById( 'page-app' );

		const StepperWidget = Inferno.createClass({
			getInitialState: function () {

				dispatch.addEventListener( 'cart', this.handle );

				return {
					value: defaultValue
				};
			},

			componentDidMount: function() {
				this.broadcast();
			},

			broadcast: function() {

				const props = {
					detail: {
						action: 'set_stepper',
						value: this.state.value
					}
				};

				const event = new CustomEvent( 'cart', props );
				dispatch.dispatchEvent( event );

			},
			handle: function( e ) {

				const { action } = e.detail;
				let value = this.state.value;

				switch( action ) {
					case 'increase_stepper':
						this.setState( { value: ++value } );
						this.broadcast();
						break;
					case 'decrease_stepper':
						if( value === 1 ) {
							break;
						}
						this.setState( { value: --value } );
						this.broadcast();
						break;
					default:
						break;
				}

			},
			render: function () {
				return (
					<span>{ this.state.value }</span>
				)
			}
		});

		Inferno.render( <StepperWidget />, root );

	};

};

export default Scripts;
