import React from 'react';
import Component from 'source/rootComponent';
import { css } from '@convertly/css';
import { id } from '@convertly/thor';
import _ from 'lodash';

// Utils
import { generateLink } from '../../../../utils/generateLink';

// Components
import SVG from '../../../../svg';
import Widgets from '../../../../widgets';

// Styles
import navigationStyles from './navigationStyles';

// Scripts
import toggleDropDown from '../../scripts/toggleDropdown';
import toggleBurgerDropdown from '../../scripts/toggleBurgerMenu';

export class Navigation extends Component {

  render() {

    const { data, inEditor, site, global, page } = this.props;
    const { slug } = page || {};

    const { 
      showNavigation,
      menu,
      displaySocialIconInMobileMenu,
    } = data || {};

    if(!showNavigation){
      return null;
    }

    this.styles = navigationStyles({ global, data, inEditor });
    
    const { 
      navigation, 
      dropDownMenu, 
      navArrowOpenDown,
      dropDownItem,
      dropDownItemLink,
      navigationLinkWrapper,
      navigationLinkText,
      navigationWrapper,
      navLaunch,
      iconBar,
      mobileSocialIcons,
      socialIconContainer,
      navigationContainer,
      nestedDropdownWrapper,
      nestedDropdownText,
      nestedDropdownArrowOpenDown,
      nestedDropDownMenu,
      nestedDropDownItem,
      nestedDropDownItemLink
    } = this.styles;

    const inlineStyle = this.generateStyleAttribute();

    return (
      <div 
        className={ css(navigation) }
        { ...this.generateDataEditorAttribute() }
        { ...this.generateCustomAttributes() }
        { ...inlineStyle }>
        
        <button
          id={ id(toggleBurgerDropdown) }
          className={ `${css(navLaunch)}` }>
          <span className={ css(iconBar) }/>
          <span className={ css(iconBar) }/>
          <span className={ css(iconBar) }/>
        </button>

        <ul 
          id='dropdown-nav-menu'
          className={ `${ css(navigationWrapper) }` }>

          <div className={ `${ css(navigationContainer) }` }>
            
            {/* 
              SearchBar component is rendered conditionally here. 
              However, anything can really be rendered here so it's 
              really up to the user of the dynamic header to make the 
              decision.
            */}
            { this._renderChildren(this.props) }

            { 
              (_.get(menu, 'navLinks') || []).map((link, idx1) => {
                let hasDropDownLinks = !!_.get(link, 'dropDownNav') || false;
                let clickThrough = _.get(link, 'clickThrough') || false;
                let generatedLinkObject;
                const toggleDropDownId = id(toggleDropDown);
                let linkUrl;
                
                if(!hasDropDownLinks || clickThrough){
                  linkUrl = _.get(link, 'link.url') ||  '/';
                  generatedLinkObject = {
                    url: linkUrl || '/',
                    linkType: _.get(link, 'link.linkType') || 'internal'
                  };
                }

                return (
                  <li 
                    id={ `dropdown${ toggleDropDownId }` }
                    className={ `${ css(navigationLinkWrapper) } ${ !hasDropDownLinks && (linkUrl === slug) ? 'slug-matched' : '' }` }
                    key={ `nav-link-${ idx1 }` }
                    data-category={ hasDropDownLinks ? `dropdown-parent_${toggleDropDownId}` : '' }
                    data-name={ 'dropdown' }>
                    {
                      !hasDropDownLinks || clickThrough ?
                      <a
                        onClick={ this.cancelClick }
                        data-link={true}
                        className={ css(navigationLinkText) }
                        href={ inEditor ? '#' : generateLink(generatedLinkObject) }>
                        { link.text }
                        {
                          clickThrough &&
                          <SVG.ArrowHeadOpenDown
                            onClick={ this.cancelClick }
                            className={ navArrowOpenDown } />
                        }
                      </a> :
                      <div className={ css(navigationLinkText) }>
                        { link.text }
                        <SVG.ArrowHeadOpenDown 
                          className={ navArrowOpenDown } />
                      </div>
                    }

                    {
                      hasDropDownLinks &&
                      <ul 
                        data-dropdown={ toggleDropDownId }
                        data-category={ `dropdown` }
                        className={ css(dropDownMenu) }>
                        {
                          link.dropDownNav.map((dropDownItemEl, idx2) => {
                            // Second layer of nested dropdowns
                            let hasMoreDropDownLinks = !!_.get(dropDownItemEl, 'dropDownNav') || false;

                            if(hasMoreDropDownLinks) {
                              const toggleNestedDropDownId = id(toggleDropDown);

                              return (
                                <div 
                                  id={ `dropdown${ toggleNestedDropDownId }` }
                                  className={ `${ css(nestedDropdownWrapper) }` }
                                  key={ `dropdown-item-${ idx2 }` }
                                  data-category={ `dropdown-parent_${ toggleNestedDropDownId }` }
                                  data-name={ 'nesteddropdown' }>
                                  <div
                                    className={ css(nestedDropdownText) }>
                                    { _.get(dropDownItemEl, 'text') }
                                  </div>
                                  <SVG.ArrowHeadOpenDown className={ nestedDropdownArrowOpenDown } />
                                  <ul
                                    data-dropdown={ toggleNestedDropDownId }
                                    data-category={ `nesteddropdown` }
                                    className={ css(nestedDropDownMenu) }>
                                  {
                                    (_.get(dropDownItemEl, 'dropDownNav') || []).map((nestedDropDownItemEl, idx3) => {
                                      // Third layer of nested dropdowns
                                      const { linkType, url } = _.get(nestedDropDownItemEl, 'link');
                                      return (
                                        <li 
                                          className={ css(nestedDropDownItem) }
                                          key={ `nestedDropDownitem-${ idx3 }` }>
                                          <a
                                            onClick={ this.cancelClick }
                                            data-link={true}
                                            className={ css(nestedDropDownItemLink) }
                                            href={ inEditor ? '#' : generateLink({ url, linkType }) }>
                                            { _.get(nestedDropDownItemEl, 'text') }
                                          </a> 
                                        </li>
                                      )
                                    })
                                  }
                                  </ul>
                                </div>
                              )
                            }

                            const { linkType, url } = _.get(dropDownItemEl, 'link');
                            return (
                              <li 
                                className={ css(dropDownItem) }
                                key={ `dropDownitem-${ idx2 }` }>
                                <a
                                  onClick={ this.cancelClick }
                                  data-link={true}
                                  className={ css(dropDownItemLink) }
                                  href={ inEditor ? '#' : generateLink({ url, linkType }) }>
                                  { _.get(dropDownItemEl, 'text') }
                                </a> 
                              </li>
                            )
                          })
                        }
                      </ul>
                    }

                  </li>
                )
              }) 
            }

            {/* Social Icons */}
            {
              displaySocialIconInMobileMenu &&
              <li className={ css( mobileSocialIcons ) } >
                  <Widgets.SocialSharing01
                      data={ { socialIcons: site.socialIcons } }
                      global={ global }
                      styles={ this.styles }
                      site={ site }
                      socialIconLinkClass={ socialIconContainer }/>
              </li>
            }

          </div>

        </ul>

      </div>
    )
  }

  _renderChildren(props) {
    return React.Children.map(props.children, child => {
      return React.cloneElement(child, {
        moduleId: props.moduleId,
        moduleSort: props.moduleSort,
        styles: props.styles,
        site: props.site,
        sliderSettings: props.sliderSettings ? props.sliderSettings : null
      });
    })
  }
}

Navigation.defaultProps = {
  page: {},
};
