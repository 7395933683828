import React from 'react';
import Component from '../../rootComponent';
import {css} from "@convertly/css";
import defaultGlobal from '../../themes/global.js';
import ReactHTMLParser from "@convertly/react-html-parser";

// Styles
import customerReviewStyleSheet from './customer-review-styles';

// Utils
import {clientImage} from '../../utils/generateImage';


export default class CustomerReview extends Component {
    constructor(props) {
        super(props);
    }


    render() {

        /** Calls editor */
        if( this.props.editor ) {
            return this.editor();
        }

        this.styles = customerReviewStyleSheet(this.props.global, this.props.data);

        /** Generates border in editor */
        const inlineStyle = this.generateStyleAttribute( );


        /** Generates stars connected to review **/
        let activeStar = <li className={css(this.styles.star)}>
                            <img className={css(this.styles.starImg)}
                                 src={clientImage("star.png")}/>
                        </li>;

        let inactiveStar = <li className={css(this.styles.star)}>
                             <img className={css(this.styles.starImg)}
                                  src={clientImage("icon-star.png")}/>
                           </li>;


        let starsArray = [];

        for (let i = 0; i < 5; i++) {
            if (i + 1 < this.props.data.rating) {
                starsArray.push(activeStar)
            }
            else {
                starsArray.push(inactiveStar)
            }
        }


        return (
            <div className={css(this.styles.customerReview) }
                 { ...this.generateDataEditorAttribute() }
                 onClick={( e ) => {
                     this.launchElementalEditor( 'reviewWidget', e );
                 }}
                 onMouseOver={ (e) => {this.addEditableOutline(e)} }
                 onMouseOut={ (e) => {this.removeEditableOutline(e)} }
                 {...inlineStyle}>
                <div className={css(this.styles.customerRatingDiv)}>

                    <img className={css(this.styles.customerImg)}
                         src={ clientImage(this.props.data.customerImage.src) }/>

                    <h5 className={css(this.styles.customerName)}>
                        {this.props.data.customerName.text}
                    </h5>

                    <div className={css(this.styles.clear)}></div>

                    <ul className={css(this.styles.customerStars)}>
                        { starsArray }
                    </ul>

                    <div className={css(this.styles.clear)}></div>

                </div>


                <div className={css(this.styles.reviewTextDiv)}>
                    <p className={css(this.styles.customerText)}>
                        {this.props.data.reviewText.text}
                    </p>
                </div>

            </div>

        )
    }

};

CustomerReview.defaultProps = {
    global: defaultGlobal,
    data: {}
}
