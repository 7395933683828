import { StyleSheet } from "@convertly/css";
import ModuleGlobal from '../../../elements/style-functions/module-global-styles';
import HeaderGlobal from '../header-style-functions/header-styles-global';

const headerStyles = ( global, data ) => {


    const moduleGlobal = new ModuleGlobal( global, data );
    const headerGlobal = new HeaderGlobal( global, data );

    return StyleSheet.create({

        header: {
            padding:0,
            backgroundColor: global.colors.light.white,
            height: '8em',
            zIndex: 40,
            boxShadow: '-1px 2px 3px #999',
            "@media( max-width: 767px)": {
                height:'6em'
            },
            "@media( max-width: 480px)": {
                height:'5em'
            },
            fontFamily: global.fontFamily.primaryFont,
            ...moduleGlobal,
            ...headerGlobal
        },
        fixedHeaderFix: {
            display: data.position === "fixed" ? 'block' : 'none',
            height: '6em',
        }
    })

};
module.exports = headerStyles;
