import _ from "lodash";

window._LTracker = window._LTracker || [];
window._LTracker.push({
  logglyKey: "68a96cc7-cfd5-4603-9f30-87118343891d",
  sendConsoleErrors: window.location.hostname !== "localhost",
  tag: "convertly-app"
});

export const loggly = (type, log) => {
  if (!window._LTracker) return;

  if (_.isObject(log)) {
    window._LTracker.push({
      host: window.location.hostname,
      log: {
        ...log
      }
    });
  } else {
    window._LTracker.push({
      host: window.location.hostname,
      log
    });
  }
};
