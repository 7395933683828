import React from 'react';
import Component from '../../../rootComponent';
import { css } from "@convertly/css";
import Types from '../../../attribute-types';
import ReactHTMLParser from "@convertly/react-html-parser";

//utils
import generateAccessKey from '../../../utils/generateAccessKey';

//Styles
import footerNavListStyles from './footer-nav-list-styles-1';

//Attributes
import FooterNavItem1 from './footer-nav-item-1';

export default class
FooterNav1 extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        this.styles = footerNavListStyles( this.props.global, this.props.data );

        const indexString = this.props.index.toString();

        return(
            <div key={this.props.key} className={ css( this.props.styles.footerLinksContainer ) }>
                <ul className={ css( this.props.styles.footerNavList ) }>
                    <li className={ css( this.props.styles.footerNavListItem ) } itemProp="headline">
                        <h4 { ...generateAccessKey( "listHeader" + indexString ) }
                            className={ css( this.props.styles.footerNavTitle, this.styles.footerNavTitle ) }>
                            { ReactHTMLParser( this.props.data.listHeader.text ) }
                        </h4>
                    </li>
                    { this.props.data.navLinks.map( ( navLink, index ) => {
                            return (
                                <FooterNavItem1 key={ index }
												index={ index }
                                                global={ this.props.global }
                                                data={ navLink }
                                                styles={ this.props.styles } />
                            )
                        })
                    }
                </ul>
            </div>
        )
    }
    }
