import React from "react";
import { css } from "@convertly/css";

const AddPageThumbnail = props => (
  <svg {...props} width="180px" height="150px" viewBox="0 0 180 150">
    <defs>
      <polygon id="path-1" points="0 150 180 150 180 0 0 0" />
      <polygon
        id="path-2"
        points="17.4534362 16.5697674 0 16.5697674 0 0.472238372 17.4534362 0.472238372 34.9068725 0.472238372 34.9068725 16.5697674 17.4534362 16.5697674"
      />
    </defs>
    <g
      className={css(props.svgClassName)}
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Site-Editor---Add-Page---Step-01" transform="translate(-560.000000, -211.000000)">
        <g id="modal_container" transform="translate(540.000000, 80.000000)">
          <g id="new-page-container">
            <g id="thumbs" transform="translate(0.000000, 131.000000)">
              <g id="row_01" transform="translate(20.000000, 0.000000)">
                <g id="layout_thumb_01-copy">
                  <g id="thumb_about_01">
                    <g id="browser_frame">
                      <g id="Fill-1">
                        <use fill="#FFFFFF" fill-rule="evenodd" />
                        <path
                          stroke="#C6C6C6"
                          stroke-width="2"
                          d="M1,149 L179,149 L179,1 L1,1 L1,149 Z"
                        />
                      </g>
                      <polygon
                        id="Fill-3"
                        fill="#4F4E50"
                        points="1 12.627907 179 12.627907 179 1 1 1"
                      />
                      <g id="Group-13" transform="translate(5.179856, 5.157558)" fill="#FEFEFE">
                        <g id="Group-10">
                          <path
                            d="M5.60913669,2.43825581 C5.60913669,1.14174419 4.43784173,0.0905813953 2.99395683,0.0905813953 C1.55007194,0.0905813953 0.378776978,1.14174419 0.378776978,2.43825581 C0.378776978,3.73476744 1.55007194,4.78651163 2.99395683,4.78651163 C4.43784173,4.78651163 5.60913669,3.73476744 5.60913669,2.43825581"
                            id="Fill-4"
                          />
                          <path
                            d="M13.823482,2.43825581 C13.823482,1.14174419 12.6521871,0.0905813953 11.2083022,0.0905813953 C9.76441727,0.0905813953 8.59376978,1.14174419 8.59376978,2.43825581 C8.59376978,3.73476744 9.76441727,4.78651163 11.2083022,4.78651163 C12.6521871,4.78651163 13.823482,3.73476744 13.823482,2.43825581"
                            id="Fill-6"
                          />
                          <path
                            d="M22.4044964,2.43825581 C22.4044964,1.14174419 21.2332014,0.0905813953 19.7893165,0.0905813953 C18.3454317,0.0905813953 17.1747842,1.14174419 17.1747842,2.43825581 C17.1747842,3.73476744 18.3454317,4.78651163 19.7893165,4.78651163 C21.2332014,4.78651163 22.4044964,3.73476744 22.4044964,2.43825581"
                            id="Fill-8"
                          />
                        </g>
                      </g>
                    </g>
                    <g id="header" transform="translate(10.702703, 15.697674)">
                      <polygon
                        id="Fill-3"
                        fill="#848484"
                        points="111.131757 3.55066445 127.930743 3.55066445 127.930743 1.77533223 111.131757 1.77533223"
                      />
                      <polygon
                        id="Fill-3-Copy"
                        fill="#848484"
                        points="129.869088 3.55066445 146.668074 3.55066445 146.668074 1.77533223 129.869088 1.77533223"
                      />
                      <polygon
                        id="Fill-3-Copy-2"
                        fill="#848484"
                        points="148.606419 3.55066445 165.405405 3.55066445 165.405405 1.77533223 148.606419 1.77533223"
                      />
                      <g id="Group-6">
                        <mask id="mask-3" fill="white">
                          <use />
                        </mask>
                        <g id="Clip-5" />
                        <path
                          d="M4.07104054,2.56417151 C3.08571537,2.56417151 2.28388682,3.29856728 2.28388682,4.20102782 L2.28388682,12.840978 C2.28388682,13.7434385 3.08571537,14.4778343 4.07104054,14.4778343 L30.8357027,14.4778343 C31.8210279,14.4778343 32.6222103,13.7434385 32.6222103,12.840978 L32.6222103,4.20102782 C32.6222103,3.29856728 31.8210279,2.56417151 30.8357027,2.56417151 L4.07104054,2.56417151 Z M30.8357027,16.5697674 L4.07104054,16.5697674 C1.8264375,16.5697674 -0.000129222973,14.8968127 -0.000129222973,12.840978 L-0.000129222973,4.20102782 C-0.000129222973,2.14519311 1.8264375,0.472238372 4.07104054,0.472238372 L30.8357027,0.472238372 C33.0803057,0.472238372 34.9068725,2.14519311 34.9068725,4.20102782 L34.9068725,12.840978 C34.9068725,14.8968127 33.0803057,16.5697674 30.8357027,16.5697674 L30.8357027,16.5697674 Z"
                          id="Fill-4"
                          fill="#545356"
                          mask="url(#mask-3)"
                        />
                      </g>
                    </g>
                    <rect
                      id="Rectangle-3"
                      fill="#414141"
                      x="1"
                      y="40.9883721"
                      width="178"
                      height="58.4302326"
                    />
                    <g id="fi-photo" transform="translate(57.000000, 44.000000)" fill="#FFFFFF">
                      <g id="Group">
                        <path
                          d="M63.8368216,3.53151733 C63.8368216,1.78694777 62.3581323,0.37292823 60.5337753,0.37292823 C60.3653732,0.37292823 60.2036184,0.396942548 60.0411251,0.420250562 L60.0411251,0.353151733 L3.31248756,0.353151733 L3.31248756,0.37292823 C1.48813062,0.37292823 0.0094413121,1.78694777 0.0094413121,3.53151733 L0.0094413121,3.53222363 L0.0094413121,48.7342328 L0.0094413121,48.7349391 L0.0094413121,48.7356454 L0.0094413121,49.0880909 L0.0463716145,49.0880909 C0.232500339,50.6638539 1.61443226,51.8935282 3.31174895,51.8935282 L3.31174895,51.9133047 L60.0403865,51.9133047 L60.0403865,51.8462059 C60.2021412,51.8695139 60.3638959,51.8935282 60.5330367,51.8935282 C62.231092,51.8935282 63.613024,50.6645602 63.7991527,49.0880909 L63.8567639,49.0880909 L63.8567639,3.53151733 L63.8368216,3.53151733 L63.8368216,3.53151733 Z M56.5032021,44.8806411 L48.4812018,44.8806411 C48.4531348,44.7711641 48.4132501,44.6659249 48.3585932,44.568455 L48.3718881,44.5613919 L31.5914973,16.7683506 L31.5848499,16.7718821 C31.2155469,16.0465084 30.4444421,15.5429141 29.5433428,15.5429141 C28.6193466,15.5429141 27.8268223,16.0719354 27.4700756,16.8290927 L16.3030907,35.3243552 L13.5687711,30.7962437 L13.5650781,30.7983626 C13.3700861,30.4155461 12.9638528,30.149976 12.4889291,30.149976 C12.0021877,30.149976 11.5848753,30.4289659 11.3965308,30.8266148 L7.36374174,37.5061266 L7.36374174,7.40559184 L56.5032021,7.40559184 L56.5032021,44.8806411 L56.5032021,44.8806411 Z"
                          id="Shape"
                        />
                        <ellipse
                          id="Oval"
                          cx="45.3184907"
                          cy="18.0700679"
                          rx="7.45992109"
                          ry="7.133665"
                        />
                      </g>
                    </g>
                    <g id="copy" transform="translate(15.567568, 112.500000)" fill="#848484">
                      <polygon
                        id="Fill-1"
                        points="0 2.1877814 72.7995466 2.1877814 72.7995466 0.000118604651 0 0.000118604651"
                      />
                      <polygon
                        id="Fill-2"
                        points="0 8.47916512 72.7995466 8.47916512 72.7995466 6.29209535 0 6.29209535"
                      />
                      <polygon
                        id="Fill-3"
                        points="0 14.7711419 72.7995466 14.7711419 72.7995466 12.5840721 0 12.5840721"
                      />
                      <polygon
                        id="Fill-4"
                        points="83.6083033 2.1877814 156.407203 2.1877814 156.407203 0.000118604651 83.6083033 0.000118604651"
                      />
                      <polygon
                        id="Fill-5"
                        points="83.6083033 8.47916512 156.407203 8.47916512 156.407203 6.29209535 83.6083033 6.29209535"
                      />
                      <polygon
                        id="Fill-6"
                        points="83.6083033 14.7711419 156.407203 14.7711419 156.407203 12.5840721 83.6083033 12.5840721"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default AddPageThumbnail;
