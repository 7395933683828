import { mercury as mercuryAPI } from "@convertly/mercury";


export const mercury = async ({ query, variables, url, service = "widgets", environment = "production" }) => {

    const options = {
        service: service,
        environment,
        headers: {
            mode: 'cors',
            Authorization:'convertly-front-load',
        },
    };

    return await mercuryAPI(query, variables, options);
};
