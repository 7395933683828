import { StyleSheet } from '@convertly/css';
import { generatePseudoMediaQuery } from '../../../elements/style-functions/pseudo-media-queries';
import { createNewCssRules } from '../../../utils/edit-userOverrides';

const navStylesLinkList = (global, data, inEditor) => {
	
  // Pulls background color from userOverrides
  let mobileBackgroundColor = {};
  if (data.userOverrides) {
    mobileBackgroundColor = createNewCssRules(data.userOverrides, 'background', ['background']);
    mobileBackgroundColor = createNewCssRules(data.userOverrides, 'backgroundColor', ['background']);
  }

  return StyleSheet.create({
    menu: {
      display: 'inline-block',
      ...generatePseudoMediaQuery(
        '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
          display: 'none',
          transform: 'translateX(-10px)',
        },
        inEditor
      ),
    },
    dropDownMenu: {
      display: 'none',
      '.open': {
        ...generatePseudoMediaQuery(
          '@media(max-width: 767px)', ['smallTablet', 'mobile'], {
            height: 'auto',
            width: inEditor ? '768px' : '100vw',
            background: global.colors.light.white,
            display: 'block',
            ...mobileBackgroundColor
          },
          inEditor
        ),
        ...generatePseudoMediaQuery(
          '@media(max-width: 375px)', ['mobile'], {
            height: 'auto',
            width: inEditor ? '325px' : '100vw',
            background: global.colors.light.white,
            display: 'block',
            ...mobileBackgroundColor
          },
          inEditor
        ),
      }
    },
    dropDownMenuWrapper: {
      width: '84vw',
      position: 'absolute',
      left: '0',
      zIndex: '22'
    },
    navListWrapper: {
      display: 'inline-block',
      marginRight: '9px',
      ...generatePseudoMediaQuery(
        '@media(max-width: 300px)', ['mobile'], {
          display: 'block'
        },
        inEditor
      ),
    },
    searchBarWrapper: {
      backgroundColor: global.colors.light.white,
      marginRight: '18px',
      '.mobile': {
        marginLeft: '4px',
        display: 'none',
        '.x-icon': {
          fontSize: '26px',
          cursor: 'pointer',
          marginTop: '-8px',
          backgroundColor: 'transparent',
          marginRight: '-10px',
        },
        '.search-bar-container': {
          width: '100%',
          ...generatePseudoMediaQuery(
            '@media(max-width: 991px)', ['largeTablet', 'smallTablet', 'mobile'], {
              display: 'block'
            },
            inEditor
          ),
        },
        '.search-bar-wrapper': {
          '.static': {
            display: 'flex',
            border: 'none',
            border: `2px solid ${ global.colors.light.color3 }`,
            padding: '4px',
            width: '100%',
            'align-items': 'center',
            height: '107%',
            transition: 'all 0.3s ease',
            marginRight: '-2px',
            transform: 'translateY(0px)',
            'box-shadow': `0 0 0 0 ${ global.colors.dark.color3 }`,
            ':focus-within': {
              border: `2px solid ${ global.colors.accent.color2 }`,
              fill: global.colors.accent.color2,
              color: global.colors.accent.color2
            },
          }
        },
        '.search-bar-input': {
          border: 'none',
          padding: '0px 2px',
          height: '100%',
          width: '100%',
          backgroundColor: 'transparent',
          color: global.colors.dark.black,
          lineHeight: '22px',
          fontSize: '16px'
        },
        '.search-bar-product-items-list': {
          transition: 'all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s',
          position: 'absolute',
          width: '100%',
          color: global.colors.dark.black
        },
        '.search-bar-results-wrapper': {
          position: 'relative',
          width: '100%',
          left: '-16px',
          'z-index': '22',
          display: 'none',
          '.active': {
            display: 'block'
          }
        },
        '.search-bar-results': {
          position: 'absolute',
          width: '100%',
        },
        '.product-item': {
          display: 'block',
          padding: '4px 4px',
          border: `2px solid ${ global.colors.light.color3 }`,
          borderTop: 'none',
          listStyle: 'none',
          cursor: 'pointer',
          backgroundColor: global.colors.light.white,
          'text-overflow': 'ellipsis',
          'overflow': 'hidden',
          'white-space': 'nowrap',
          width: '100%',
          height: '30px',
          ':hover': {
            backgroundColor: global.colors.light.color2
          },
          '.no-hover': {
            ':hover': {
              backgroundColor: 'white',
              cursor: 'default'
            },
          }
        },
        '.search-bar': {
          width: '100%',
          '.left': {
            order: '2'
          },
          '.right': {
            order: '1',
            width: '89%',
          },
          '.with-x-icon': {
            width: '95%',
            '.left': {
              width: '83%'
            }
          },
        },
        ...generatePseudoMediaQuery(
          '@media(max-width: 991px)', ['smallTablet', 'mobile'], {
            display: 'block',
          },
          inEditor
        ),
      }
    },
  });
}

export default navStylesLinkList;
