import React from "react";
export default class DesktopIcon extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <svg
        style={this.props.style}
        width="24px"
        height="20px"
        viewBox="0 0 24 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.9032258,0 L3.09677419,0 C1.38658065,0 0,1.119375 0,2.5 L0,11.25 L24,11.25 L24,2.5 C24,1.119375 22.6134194,0 20.9032258,0 L20.9032258,0 Z M0,13.75 C0,15.130625 1.38658065,16.25 3.09677419,16.25 L20.9032258,16.25 C22.6134194,16.25 24,15.130625 24,13.75 L24,12.5 L0,12.5 L0,13.75 L0,13.75 Z M17.0322581,18.75 L15.483871,18.75 L15.483871,17.5 L9.29032258,17.5 L9.29032258,18.75 L7.74193548,18.75 C7.31380645,18.75 6.96774194,19.03 6.96774194,19.375 C6.96774194,19.720625 7.31380645,20 7.74193548,20 L17.0322581,20 C17.4603871,20 17.8064516,19.720625 17.8064516,19.375 C17.8064516,19.03 17.4603871,18.75 17.0322581,18.75 L17.0322581,18.75 Z"
          id="Fill-143"
        />
      </svg>
    );
  }
}
