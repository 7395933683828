import { StyleSheet } from '@convertly/css';

const Styles = ( global, data ) => {

    let signInButton = {
        padding:'15px 25px',
        display:'inline-block',
        borderRadius: "5px",
        color:global.colors.light.white,
        border:'none',
        "@media(max-width:767px)": {
            textAlign:'center',
            width:'100%',
            display:'block',
            margin:'5px auto',
        }

    };

    return StyleSheet.create({

        signUpInferno: {
            ".login-wrapper": {
                width:"100%",
                "@media (max-width:767px)": {
                    margin:'0 auto'
                }
            },
            ".input": {
                ...global.input,
                width:"50%",
                "@media (max-width:767px)": {
                    width:"100%",
                },
                border: 'none',
                borderBottom: '3px solid white',
                height: '40px',
                background: 'transparent',
                color: 'white',
                fontSize: '16px',
                margin: "10px 0px",

            },
            '.input::placeholder': {
                color: 'rgba(255,255,255,0.6)',
                paddingLeft: '5px'
            },
            ".form-error-message": {
                display:'none',
                fontSize: '10px',
                margin:'0'
            },
            ".show-form-error-message": {
                display:'block'
            },
            ".mobile-nav-terms-paragraph": {
                fontSize:'12px',
                color: 'rgba(255,255,255,0.6)',
                "@media(max-width:768px)": {
                    color:'#fff'
                }
            },
            ".sign-up-buttons": {
                marginTop:'35px'
            },
            ".google-button": {
                ...signInButton,
                background:"#4285f4",
            },
            ".facebook-button": {
                ...signInButton,
                background:"red",

            },
            ".convertly-button": {
                ...signInButton,
                background:'#132755',
                color:'#fff'
            },

        }

    })

};

export default Styles;
