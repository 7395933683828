import React from "react";
import Component from "source/rootComponent";
import {css} from "@convertly/css";
import _ from "lodash";
import {parseImages} from "../../blog/util/parse-images";

// Style
import HTMLContentRendererStyles from "./HTMLContentRendererStyles";

class HTMLContentRenderer extends Component {
  render() {
    const {data, inEditor, global} = this.props;

    let {text = ""} = data || {};

    if (!_.isString(text)) {
      return null;
    }

    text = parseImages(text, inEditor);

    this.styles = HTMLContentRendererStyles({global, data, inEditor});

    const {contentWrapper} = this.styles;

    return (
      <div>
        {inEditor ?
          <div className={css(contentWrapper)}
               {...this.generateDataEditorAttribute()}>
            Javascript is disabled in the editor. Please save changes and click to preview button to see this content.
          </div>
          :
          <div
            {...this.generateDataEditorAttribute()}
            {...this.generateCustomAttributes()}
            className={css(contentWrapper)}
            dangerouslySetInnerHTML={{__html: text}}
          />
        }
      </div>
    );
  }
}

export default HTMLContentRenderer;
