import React from 'react';
import {StyleSheet, css} from "@convertly/css";
import Component from '../../../rootComponent';
import {id} from '@convertly/thor';


//Components

import SVG from '../../../svg/index';

//Styles
import sliderStyles from './middle-arrow-navigation-styles';


//Component
export default class middleNavArrows extends Component {

    constructor(props) {

        super(props);

    };


    render() {

        this.styles = sliderStyles(this.props.global, this.props.data, {}, this.inEditor());

        return (



            <div className={ css(this.styles.arrowsHolder) }>
                <div className={ css(this.styles.arrowRelativeContainer) }>

                        <span className={ css(this.styles.arrowLeft) + " previous" } data-target={ "arrowLeft" }>

                            <SVG.ArrowHeadOpenLeft className={this.styles.arrow}/>

                        </span>

                    <span className={ css(this.styles.arrowRight) + " next"  } data-target={ "arrowRight" }>

                            <SVG.ArrowHeadOpenRight className={this.styles.arrow}/>

                        </span>
                </div>

            </div>

        )
    }
}
