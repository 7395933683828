import { createAction } from "redux-act";
import _ from "lodash";
import { fromJS } from "immutable";
import { store } from "app/app";
import Logger from "utils/logger";
import { graph, purgeSiteCache } from "app/utils";

export const setLocations = createAction("Site Settings: Set locations");
export const setLoaded = createAction("Site Settings: Set locations is loaded flag");

export function saveLocations() {
  return async dispatch => {
    const siteId = store.getState().getIn(["auth", "activeSite", "id"]);
    let locations = store
      .getState()
      .get("siteSettings")
      .Locations.get("locations");

    const query = `
      mutation( $id:ID!, $settings:siteStoreSettingsInput ) {
        updateSiteStoreSettings(id:$id, settings: $settings ) {
        id
        }
      }
      `;

    const variables = {
      id: siteId,
      settings: {
        locations: JSON.stringify(locations.toJS())
      }
    };

    const response = await graph({ query, variables });
    console.log(response);
  };
}

export function loadLocations() {
  return async dispatch => {
    const siteId = store.getState().getIn(["auth", "activeSite", "id"]);

    const query = `
      query ($id: ID! ) {
        Site(id: $id) {
        locations
        }
      }
      `;

    const variables = {
      id: siteId
    };

    const response = await graph({ query, variables });
    let locations = _.get(response, "Site.locations") || "[]";

    try {
      locations = JSON.parse(locations);
    } catch (e) {
      locations = [];
    }

    await dispatch(setLocations(fromJS(locations)));
    await dispatch(setLoaded(true));
  };
}
