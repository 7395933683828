//since the props change by state, this will handle that so the logic doesn't get done in ui
export const generateControlsProps = function () {

  const {stage, activeCategoryText, message} = this.state;
  const getHandler = () => {
    if (stage === 1) return this.nextStage;
    if (stage === 2) return this.sendMessage;
    return this.toggleForm;

  };
  const checkContinue = () => {
    if (stage === 1) return !activeCategoryText.length > 0;
    if (stage === 2) return !message.length > 0 || this.state.processing;
  };
  const getText = () => {
    if (this.state.processing) return "Sending...";
    if (stage === 1) return "Next";
    if (stage === 2) return "Send";
    return "Done"
  };
  return {
    stage,
    toggleForm: this.toggleForm,
    handler: getHandler(),
    disableContinue: checkContinue(),
    text: getText()
  };

};

//goes to next stage
export const nextStage = function () {

  this.setState((prevState) => {
    return {stage: prevState.stage + 1}
  })
};

//goes to prev stage
export const prevStage = function () {
  this.setState((prevState) => {
    return {stage: prevState.stage - 1}
  })
};
