/*global Inferno, $ */
import React from 'react';
const Script = (ArrowDownOpen) => {
  return ({ rootClass, changePageNum, currentPage, pages }) => {

    const backArrowActive = currentPage > 1;
    const forwardArrowActive = currentPage < pages;
    
    return (
      <div className={ `${ rootClass } page-jump-single-container` }>
        <div className={ `${ rootClass } page-jump-single-wrapper` }>
          <div className={ `${ rootClass } page-jump-single` }>
            <a className={ `${ rootClass } page-jump-single-arrow left${ !backArrowActive ? ' disabled' : ''}` }
                onClick={ () => {
                  if(backArrowActive) {
                    changePageNum(currentPage - 1)
                  }
                }
              }>
              <ArrowDownOpen nameOfClass={ `${ rootClass } arrow` }/>
            </a>
            <a className={ `${ rootClass } page-jump-single-arrow right${ !forwardArrowActive ? ' disabled' : ''}` }
            onClick={ () => {
                  if(forwardArrowActive) {
                    changePageNum(currentPage + 1)
                  }
                }
              }>
              <ArrowDownOpen nameOfClass={ `${ rootClass } arrow` }/>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default Script;