import { connect } from "react-redux";
import Menu from "../menus/CategoryDetailMenu";
import {toggleDeleteCategoryModal} from "containers/StoreFront/CategoryDetail/actions";

const mapStateToProps = state => {
  console.log('weak step aside', state.toJS().productCategory.Category.toJS() )
  return {
    site: state.get("auth").get("activeSite"),
    category: state.get('productCategory').Category.get('category').category
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleDeleteCategoryModal:() => dispatch(toggleDeleteCategoryModal()),
  };
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu);

export default Container;
