import { StyleSheet } from "@convertly/css";
import ModuleGlobal from '../../../elements/style-functions/module-global-styles';
import HeaderGlobal from '../header-style-functions/header-styles-global';

const headerStyles = ( global, data ) => {


    const moduleGlobal = new ModuleGlobal( global, data );
    const headerGlobal = new HeaderGlobal( global, data );

    return StyleSheet.create({
        header: {
            backgroundColor: global.colors.light.white,
            maxHeight:'4em',
            boxShadow: '2px 2px 2px #999',
            fontFamily: global.fontFamily.primaryFont,
            ...moduleGlobal,
            ...headerGlobal
        },
        fixedHeaderFix: {
            display: data.position === "fixed" ? 'block' : 'none',
            height: '4em',
        }
    });
};
module.exports = headerStyles;
