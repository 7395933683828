import { generatePseudoMediaQuery } from "../../../elements/style-functions/pseudo-media-queries";

export default function buyingOptionsStyles(global, data, inPDP, inEditor) {
	

  let mobileStyles = {};
  let optionsBarMobileStyles = {};

  if (inPDP) {
    mobileStyles = {
      ...generatePseudoMediaQuery(
        "@media(max-width:767px)",
        ['smallTablet', 'mobile'],
        {
          position: 'fixed',
          bottom: '0',
          left: '0',
          width: "100vw",
          'pointer-events': 'all',
					transition:'position .3s ease',
					"* html, body": {
						height:'100%',
						overflowY:'scroll'
					}
        },
        inEditor
      )
    };
    optionsBarMobileStyles = {
      height: '55px',
    }
  }
	
	return {
		".buying-options-wrapper": {
		  width: '100%',
		  background: global.colors.light.color3,
		  ...mobileStyles
    },
		".options-bar": {
			position: 'relative',
			bottom: '0px',
			display: 'flex',
			width: '100%',
			height: data.columns === 6 ? '30px' : '52px',
			marginTop: '0px',
			backgroundColor: 'rgba(0, 0, 0, .5)',
			color: global.colors.light.white,
			alignContent:'center',
      justifyContent:'center',
      ...generatePseudoMediaQuery(
        "@media(max-width:767px)",
        ['smallTablet', 'mobile'],
        {
          ...optionsBarMobileStyles
        },
        inEditor
      ),
		},
		".options-content": {
			alignSelf:'center'
		},
		
		
	}
	
	
}
