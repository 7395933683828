//import $ from 'jquery';

const convertlyImageGalleryWidget = ( id ) => {

    return ( id ) =>  {
        const el = $( '#' + id );
        const imageGalleryThumbNail = $('#imageGalleryList > li');
        const featureImage = $( '#featuredImage' );
        imageGalleryThumbNail.on('click', function(e) {
            imageGalleryThumbNail.removeClass('active');
            $(this).addClass('active');
            let newImageSrc = this.dataset.imagesrc;
            featureImage.animate({opacity: '.3'}, 100,  function(){
            featureImage.attr('src', newImageSrc);
            featureImage.animate({opacity: '1'}, 400)
            })
        })
    }
};




export default convertlyImageGalleryWidget;
