/*global Inferno, $, TodoInput */
/* eslint-disable */
import TodoUiWrapper from "./uis/todo-list-01/todo-ui-wrapper";
import {include} from '@convertly/thor'

const Script = () => {

        const TodoController = include('./controllers/controller', 'TodoController');

        return (id, test, TodoUiWrapper) => {

            const root = document.getElementById(id);
            const rootClass = root.className;

            //Initiate Controllers
            const todoController = TodoController();

            const TodoListWrapper = Inferno.createClass({

                getInitialState: function () {
                    this.TodoController = new todoController(this, id);
                    return {
                        todoInputValue: '',
                        activeTodos: []
                    };
                },

                componentDidUpdate() {
                    //removes and adds event listeners every time component updates
                    this.TodoController.resetDomEventListeners();
                },

                componentDidMount() {
                    //inits event listeners
                    this.TodoController.initDomEventListeners();
                },

                render: function () {

                    return (
                        <TodoUiWrapper rootClass={rootClass}
                                       data={ this.state }/>
                    )
                }

            });

            Inferno.render(<TodoListWrapper/>, root);

        };

    }
;

export default Script;
