import React from 'react';
import Component from '../../../../rootComponent';
import { css } from "@convertly/css";

import BetaText from './convertly-beta-logo-mobile';


export default class
ConvertlyLogoMobile extends Component {
    constructor ( props ) {
        super( props );
    }
    render() {

        let { className } = this.props;

        /*viewBox="0 0 404.308 404.309"*/
        return (
            <div className={ className }>
                <svg width="30px" height="33px" viewBox="0 0 47 53" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    {/* <!-- Generator: Sketch 48.2 (47327) - http://www.bohemiancoding.com/sketch --> */}
                    <title>C_symbol</title>
                    <desc>Created with Sketch.</desc>
                    <defs></defs>
                    <g id="logos" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" transform="translate(-648.000000, -559.000000)">
                        <g className={ className } id="favicon_02" transform="translate(639.000000, 554.000000)" fill="#349886" fillRule="nonzero">
                            <path d="M48.1919719,40.8212137 C45.4636258,45.6180146 40.2195823,48.8635305 34.197677,48.8635305 C25.3641428,48.8635305 18.2034833,41.8786865 18.2034833,33.2656701 C18.1982924,29.4726514 19.6157836,25.8087456 22.1888453,22.9643789 L18.8275495,19.7870871 C21.103611,16.6959131 24.4592657,14.5221398 28.2634964,13.6745365 L29.7759129,18.2742457 C33.2699957,17.2996304 36.9997392,17.5096043 40.355611,18.8698526 L43.0312113,10.5010349 C37.9052345,8.61598993 32.2868695,8.42198411 27.0370176,9.94874494 L25.5184936,5.33333333 C20.3244885,6.65760864 15.7065699,9.57942597 12.3498094,13.6653317 L15.7499705,16.8783599 C11.5567104,21.3551825 9.235643,27.2017413 9.24446953,33.2651286 C9.24446953,46.7052678 20.4165862,57.6 34.197677,57.6 C42.9557015,57.6 50.6582547,53.1990069 55.1111111,46.5422882 L48.1919719,40.8212137 Z" id="C_symbol" transform="translate(32.177778, 31.466667) rotate(-360.000000) translate(-32.177778, -31.466667) "></path>
                        </g>
                    </g>            
                </svg>
                <BetaText />
            </div>
        )
    }
}
