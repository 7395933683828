import { StyleSheet } from "@convertly/css"
import Typography from '../style-functions/typography';
import ButtonGlobalStyles from './button-global-styles';
import userOverrides from '../style-functions/user-overrides';
import {deeplog} from "../../utils/deeplog";

const wideButtonStyles = ( global, data, buttonType, inEditor ) => {

    let buttonOverrides = data.userOverrides ?
        userOverrides(global, data, inEditor) :
        {};
    const buttonGlobalStyles = new ButtonGlobalStyles( global, data, buttonType );

    return StyleSheet.create({

        button : {
            ...buttonGlobalStyles,
            ...buttonOverrides
        },
    })

};

export default wideButtonStyles;