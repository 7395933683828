import  colorAssign  from '../colorAssign';

let defaultGlobal = {
//colors 
    colors: {
        dark: {
            color1:'#090a0a',
            color2:'#30303d',
            color3:'#3e3f42',
            black: "#000"
        },
        accent: {
            color1:'#ff9800',
            color2:'#1a237e',
            color3:'#c5cae9',
        },
        light: {
            color1:'#fafafa',
            color2:'#f5f5f5',
            color3:'#eeeeee',
            white: '#fff'
        }
        
    },
     
    textColors: {
        light:{},
        dark:{},
        accent:{}
    },
    backgroundColors: {
        light:{},
        dark:{},
        accent:{}
    },
         // Module Default wrapper
    moduleDefault: {
        margin:'50px 0',
        padding:'50px 20px',
    },
    /** Misc Styles */
    border: '2px solid',
    figCaption: {
        position:'absolute',
        bottom:'5px',
        background:'rgba(0, 0, 0, .5)',
        width:'100%',
        padding:'30px 20px',
        color:'#fff',
        "@media (max-width: 479px)": {
            padding:'10px'
        }
    },
    // Body background
    bodyBackgroundColor: '', 
    themeBorder: {
        border: '3px solid',
    },
    // typography
    fontFamily: {
        primaryFont: "'Roboto'",
        secondaryFont: " 'Roboto' "
    },
    fonts: [ 'Roboto' ],
    h1: {
       
        fontSize: "40px",
        margin:'20px 0 10px',
        lineHeight: "50px",
        "@media (max-width: 767px)": {
            fontSize: "55px",
            lineHeight: "65px"
        },
        "@media (max-width: 479px)": {
            fontSize: "45px",
            lineHeight: "55px"
        }
    },
    h2: {
     
        margin:'20px 0 10px',
        fontSize: '28px',
        lineHeight: '38px'
    },
    h3: {
      
        fontSize: '16px',
        lineHeigth:"26px"       
    },
    h4: {

        fontSize: '22px',
        lineHeight: '32px'
    },
    h5: {
   
        fontSize: '15px',
        lineHeight:'25px'
    },
    h6: {
   
        fontSize:'20px',
        lineHeight:'30px'
    },
    p: {
    
        margin: '10px 0',
        fontSize: '16px',
        lineHeight: '26px'
    },
    list: {
      
        marginBottom: '20px',
        paddingLeft: '30px'
    },
    listItem: {
        marginBottom:'10px'
    },
    // buttons
    button1: {
        display: 'inline-block',
        height: '50px',
        lineHeight: '50px',
        textAlign: 'center',
        fontSize: '20px',
        padding: '0 58px',
        borderRadius: '30px',
        transition: 'all 0.3s linear 0s',
    },
    button2: {
        height: '50px',
        lineHeight: '45px',
        padding: '0 25px',
        fontSize: '20px',
        transition: 'all 0.3s linear 0s',
        boxShadow: '0px 2px 8px 1px #ccc',
        display: 'inline-block',
        fontWeight: '700',
    },
    button3: {
        padding: '0 70px',
        fontSize: '20px',
        height: '70px',
        lineHeight: '70px',
        display: 'inline-block',
        transition: 'all 0.3s'
    },
    // Form
    form: {
        height: 'auto',
        padding: "30px",
        width: '100%'
    },
    formErrorMessage: {
        color: "red",
        fontSize:'12px'
    },
    input: {
        height: "38px",
        width: '100%',
        
        "::placeholder": {},
        fontSize: '16px',
        lineHeight: '16px',
        border: "1px solid #eeeeee",
        borderRadius: '2px',
        padding: "8px 12px",
        marginBottom: '10px'   
    },
    selectInput: {
        width: '100%',
        height: "40px",
        opactiy: "0",
        appearance: 'none',
        padding: "10px"
    },
    svgDiv: {
        
        height: '40px'
    },
    checkboxInput:{
        marginRight: "5px",
        marginLeft: "5px",
    },
    checkboxLabel:{},
    radioInput:{
       marginRight: "5px",
       marginLeft: "5px",
    },
    radioLabel:{},
    submitButton:{        
        fontWeight: "700",
        border: "none",
        padding: "10px 25px"
    },
    label: {        
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "5px"
    }
};

export default function theme ( global ) {

    global = global ? global : defaultGlobal;

    colorAssign( global );

    // Module Default Wrapper Styles
    global.moduleDefault.fontFamily = global.fontFamily.primaryFont;

    // Theme Border 
    global.themeBorder.borderColor = global.backgroundColors.light.color3


    // Form Styles
    global.form.backgroundColor = global.backgroundColors.accent.color1;

    global.input.backgroundColor = global.backgroundColors.light.white;
    global.input.borderColor = global.backgroundColors.light.color3;
    global.input.color = global.textColors.dark.color1;
    global.input.fontFamily = global.fontFamily.primaryFont;
    global.input["::placeholder"].color = global.textColors.light.color3;
    global.input["::placeholder"].fontFamily = global.fontFamily.primaryFont;

    global.selectInput.backgroundColor = global.backgroundColors.light.white;
    global.selectInput.borderColor = global.backgroundColors.dark.color3;
    global.selectInput.color = global.textColors.dark.color3;
    global.selectInput.fontFamily = global.fontFamily.primaryFont;

    global.svgDiv.backgroundColor = global.backgroundColors.light.white;

    global.checkboxLabel.color = global.textColors.light.white;
    global.checkboxLabel.fontFamily = global.fontFamily.primaryFont;
    
    global.radioLabel.color = global.textColors.light.white;
    global.radioLabel.fontFamily = global.fontFamily.primaryFont;

    global.submitButton.color = global.textColors.light.white;
    global.submitButton.backgroundColor = global.backgroundColors.accent.color2;
    global.submitButton.fontFamily = global.fontFamily.primaryFont;

    global.label.color = global.textColors.light.white;
    global.label.fontFamily = global.fontFamily.primaryFont;

    // typography Styles
    global.h1.color = global.textColors.dark.color1;
    global.h2.color = global.textColors.dark.color1;
    global.h3.color = global.textColors.dark.color1;
    global.h4.color = global.textColors.light.color3;
    global.h5.color = global.textColors.dark.color3;
    global.h6.color = global.textColors.dark.color3;
    global.p.color = global.textColors.light.color3;
    global.list.color = global.textColors.light.color3;

    global.h1.fontFamily = global.fontFamily.primaryFont;
    global.h2.fontFamily = global.fontFamily.primaryFont;
    global.h3.fontFamily = global.fontFamily.primaryFont;
    global.h4.fontFamily = global.fontFamily.primaryFont;
    global.h5.fontFamily = global.fontFamily.primaryFont;
    global.h6.fontFamily = global.fontFamily.primaryFont;
    global.p.fontFamily = global.fontFamily.primaryFont;
    global.list.fontFamily = global.fontFamily.primaryFont;

    //Button Styles

    const defaultButtonStyles = {
        background: global.backgroundColors.accent.color1,
        textColor: global.textColors.light.white,
        borderColor: global.backgroundColors.accent.color1,
        fontFamily: global.fontFamily.secondaryFont
    };
    const alternateButtonStyles = {
        background: global.backgroundColors.dark.color2,
        textColor: global.textColors.light.white,
        fontFamily: global.fontFamily.secondaryFont,
        borderColor: global.backgroundColors.dark.color2
    };

    global.button1 = {
        ...global.button1,
        ...defaultButtonStyles
    };

    global.button1A = {
        ...global.button1,
        ...alternateButtonStyles
    };

    global.button2 = {
        ...global.button2,
        ...defaultButtonStyles
    };

    global.button2A = {
        ...global.button2,
        ...alternateButtonStyles
    };

    global.button3 = {
        ...global.button3,
        ...defaultButtonStyles
    };

    global.button3A = {
        ...global.button3,
        ...alternateButtonStyles
    };

    return global

}
