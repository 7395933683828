/*global Inferno, $ */
/* eslint-disable */
import { include } from '@convertly/thor';

const Script = ( id ) => {

    /** Imports **/
    const Graph = include( '../../../utils/graph', 'Graph' );
    const Dispatcher = include( '../../../utils/dispatcher', 'Dispatcher' );
    const QuickBuyOptions = include( '../../quick-buy-options/quick-buy-options', 'QuickBuyOptions' );



    return( id, clientImageSrc ) => {
        const hasLocalStorage = $( 'html' ).hasClass( 'localstorage' );

        const root = document.getElementById( id );
        /** Init Imports **/
        //utils
        const graph = Graph( );
        const dispatch = Dispatcher( id );

        const QuickBuyOptionsInferno = QuickBuyOptions( dispatch, graph )

        const Cart = Inferno.createClass( {

            getInitialState: function( ) {

                return {
                    items: [ ],
                    subTotal: parseFloat( '0.00' ),
                    cartSubTotal: 0,
                    shippingData: {}
                };
            },

            componentDidMount( ) {

            },


            render: function( ) {



                return(
                    <QuickBuyOptionsInferno rootDiv={ root } />
                )
            }

        } );

        Inferno.render( <Cart />, root );

    };

};

export default Script;