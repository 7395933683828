import React from "react";
import PropTypes from "prop-types";
import Component from "../../rootComponent";
import { css } from "@convertly/css";
import defaultGlobal from "../../themes/global.js";
import ReactHTMLParser from "@convertly/react-html-parser";

//Styles
import dividerStyles from "./divider-styles";

//Utils
import generateId from "../../utils/generate-id";
/**
 * Class controls imports style functions that allows for user to override the css in the CMS editor for their site.
 **/
class Divider extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    /**
     * Calls Stylesheet function
     **/
    this.styles = dividerStyles(this.props.global, this.props.data, this.state.inEditor);

    let className = "";

    let aphroditeClass = css(this.styles.divider);

    if (this.animatedClassName) {
      className += " " + this.animatedClassName;
    }
    className += aphroditeClass;

    const { animated } = this.props.data;
    const inlineStyle = this.generateStyleAttribute();
    /**
     * Loads h1 element
     **/
    return (
      <div
        {...this.generateDataEditorAttribute()}
        {...this.generateCustomAttributes()}
        {...generateId(this.props.elementId)}
        onClick={e => {
          this.launchElementalEditor("divider", e);
        }}
        onMouseOver={e => {
          this.addEditableOutline(e, "child");
        }}
        onMouseOut={e => {
          this.removeEditableOutline(e, "child");
        }}
        {...inlineStyle}
        data-animated={animated}
        className={className}
      />
    );
  }
}

/**
 * Default Props for rendering when no data is being passed
 **/
Divider.defaultProps = {
  data: {
    text: "Hello from the other side",
    bold: false,
    italic: false,
    underline: false,
    align: "center",
    fontSize: "",
    color: "",
  },
  global: defaultGlobal,
};

/**
 * Connects element to context for cms editor
 **/
Divider.contextTypes = {
  store: PropTypes.object,
};

export default Divider;

/**
 Component BoilerPlate

{
    "el": "Elements.Divider",
    "data": {
        "userOverrides": {

        }
    },
    "children": [],
    "sort": 900
}

 **/
