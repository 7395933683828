import { StyleSheet } from "@convertly/css";
import UserOverrides from "../../elements/style-functions/user-overrides";
import removeNestedUserOverrides from "../../site-header/site-header/utils/removeNestedUserOverrides";
import _ from "lodash";
import { generatePseudoMediaQuery } from "../style-functions/pseudo-media-queries";

const LightBoxImageGalleryStyles = ({ global, data, inEditor }) => {
  const lightBoxImageGallery = removeNestedUserOverrides({ data });

  const { userOverrides, numOfColumns = 4 } = data;

  const { lightBoxImage, lightBoxImageWrapper } = userOverrides;

  const lightBoxImageGalleryUserOverrides = !_.isEmpty(lightBoxImageGallery)
    ? UserOverrides(global, { userOverrides: lightBoxImageGallery }, inEditor)
    : {};

  const lightBoxImageUserOverrides = _.get(lightBoxImage, "userOverrides")
    ? UserOverrides(global, lightBoxImage, inEditor)
    : {};

  const lightBoxImageWrapperUserOverrides = _.get(lightBoxImageWrapper, "userOverrides")
    ? UserOverrides(global, lightBoxImageWrapper, inEditor)
    : {};

  return StyleSheet.create({
    lightBoxImageGallery: {
      display: "grid",
      gridTemplateColumns: `repeat(${numOfColumns}, 1fr)`,
      gridAutoRows: "1fr",
      gridGap: "30px",
      ...generatePseudoMediaQuery(
        "@media(max-width: 991px)",
        ["mobile", "smallTablet", "largeTablet"],
        {
          gridTemplateColumns: `repeat(2, 1fr)`,
        },
        inEditor,
      ),
      ...generatePseudoMediaQuery(
        "@media(max-width: 499px)",
        ["mobile"],
        {
          gridTemplateColumns: `repeat(1, 1fr)`,
        },
        inEditor,
      ),
      ...lightBoxImageGalleryUserOverrides,
    },
    lightBoxImageWrapper: {
      position: "relative",
      overflow: "hidden",
      ...lightBoxImageWrapperUserOverrides,
    },
    lightBoxImage: {
      cursor: "pointer",
      objectFit: "cover",
      minHeight: "100%",
      minWidth: "100%",
      //position: "absolute",
      top: "0",
      left: "0",
      ...lightBoxImageUserOverrides,
    },
  });
};

export default LightBoxImageGalleryStyles;
