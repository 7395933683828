import {StyleSheet} from "@convertly/css";
import userOverrides from "../../elements/style-functions/user-overrides";
import removeNestedUserOverrides from "../../site-header/site-header/utils/removeNestedUserOverrides";
import _ from "lodash";

export const PaginationStyles = ({global, data, inEditor}) => {
    const pagination = removeNestedUserOverrides({data}),
        {nextButton, previousButton, buttonText, pageCount} = _.get(data, "userOverrides") || {};

    return StyleSheet.create({
        pagination: {
            ...userOverrides(global, {userOverrides: pagination}, inEditor)
        },

        previousButton: {
            ...global.button1,
            ...userOverrides(global, previousButton, inEditor)
        },

        nextButton: {
            ...global.button1,
            ...userOverrides(global, nextButton, inEditor)
        },

        buttonText: {
            ...userOverrides(global, buttonText, inEditor)
        },

        pageCount: {
            ...userOverrides(global, pageCount, inEditor)
        }
    });
};
