import React from 'react';
import Component from '../../rootComponent';
import {css} from "@convertly/css";
import defaultGlobal from '../../themes/global.js';
import ReactHTMLParser from "@convertly/react-html-parser";

// Styles
import divWithOverlay02StyleSheet from './div-with-overlay-styles-02';

import Elements from '../index.js'


export default class DivWithOverlay02 extends Component { 
    constructor(props){
        super(props);
    }

    render(){
        this.styles = divWithOverlay02StyleSheet(this.props.global, this.props.data);

        let className;
        const aphroditeClass = css(this.styles.divWithOverlay02);

        className +=aphroditeClass; 

        function renderChildren(props){
            return React.Children.map(props.children, child => {
                return React.cloneElement(child, {
                    moduleId: props.moduleId,
                    moduleSort: props.moduleSort,
                    styles: props.styles
                });
            });
        }

    return(

        <div className={css(this.styles.divWithOverlay02)}
             { ...this.generateCustomAttributes() }
        >

            <div className={ css( this.styles.divOverlay02) + " divOverlay02"}>
               
                    {renderChildren(this.props)}
                
            </div>

           
        </div>
        )
    }

};

DivWithOverlay02.defaultProps = {
    global: defaultGlobal,
    data: {}
}
