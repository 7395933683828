import {emitter} from "../../emitter";

/**TODO:
 * move all query functionality here,
 * have a real data store that anything blog related gets stored in
 *
 */


class BlogKraken {

    constructor({STORE_KEY, siteId}) {

        this.STORE_KEY = STORE_KEY;
        this.siteId = siteId;
        this.frontLoadSet = new Set();
        this.postsPerSection = new Map();

        this.initListeners();
    }

    initListeners() {
        //removes item from frontload set
        emitter.addNewListener( `${this.STORE_KEY}-${this.siteId}-removeFrontLoad`, (id) => {
            this.removeFrontLoad(id)
        } )
        //adds to page count
        emitter.addNewListener( `${this.STORE_KEY}-${this.siteId}-registerPostsSectionCount`, ({ id, postCount }) => {
            this.registerPostsSectionCount(id, postCount);
        } )
    }

    //adds frontload to set
    registerFrontLoad( frontLoadId ) {
        this.frontLoadSet.add( frontLoadId );
    }

    //removes frontload from set and will send event if
    // queue is empty
    removeFrontLoad( frontLoadId ) {
        this.frontLoadSet.delete( frontLoadId );
        if( this.frontLoadSet.size === 0 ) {

            this.triggerFrontLoadCompletionEvent()
        }
    }

    //Fires event that all front loads are completed
    triggerFrontLoadCompletionEvent() {
        emitter.emit(`${this.STORE_KEY}-${this.siteId}-frontloadComplete`);
    }

    //Adds section id and post count
    registerPostsSectionCount(id, postCount) {
        if(!this.postsPerSection.has( id )) {
            this.postsPerSection.set( id, postCount )
        }
    }

    getPostsPerPage() {
        const countData = {
            incrementor:0,
            staticCount: 0
        };

        this.postsPerSection.forEach( val => {
            if(val.type === "incrementor") {
                countData.incrementor += val.count
            }
            else {
                countData.staticCount += val.count
            }
        } );
        return countData
    }

    //create function that adds queries into a queue and does one central query

}

export const releaseBlogKraken = (STORE_KEY, siteId) => {

    return new BlogKraken({STORE_KEY, siteId});

};

