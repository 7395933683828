import { registerNotification, clearNotification } from "containers/App/actions";
import { store } from "app/app";

const addSuccessNotification = async args => {
  return await addNotification({
    type: "success",
    icon: "thumbs-up",
    ...args
  });
};

const addInfoNotification = async args => {
  return await addNotification({
    type: "warning",
    ...args
  });
};

const addErrorNotification = async args => {
  return await addNotification({
    type: "danger",
    ...args
  });
};

/**
 *
 * @param type
 * @param sticky
 * @param title
 * @param message
 * @param icon
 * @param spin
 * @param args
 * @returns {Promise<*>}
 */
const addNotification = async ({ type, sticky, title, message, icon, spin, ...args }) => {
  return await store.dispatch(
    registerNotification({
      type,
      sticky,
      title,
      message,
      icon,
      spin,
      ...args
    })
  );
};

/**
 *
 * @param id
 * @param args
 * @returns {Promise<*>}
 */
const cancelNotification = async (id, args = {}) => {
  return await store.dispatch(clearNotification(id, args));
};

export const Notifications = {
  add: addNotification,
  clear: cancelNotification,
  success: addSuccessNotification,
  error: addErrorNotification,
  info: addInfoNotification
};
