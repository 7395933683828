import React from 'react';
import { TextWrapper } from '../root-components';
import { TextArea } from './components';

/** Stage 2 where the message gets entered**/
export const MessageStage = ({activeCategoryText, updateMessage, message}) => {

  return (
    <>
      <TextWrapper>
        <p>{`${activeCategoryText.replace('My', 'Your')}. Excellent. What question or comment do you have for us?`}</p>
      </TextWrapper>
      <p>Question / Comment</p>
      <TextArea onChange={updateMessage} value={message}/>
    </>
  )

};
