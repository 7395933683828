import React from 'react';
import { css } from '@convertly/css';
import Styles from './simple-menu-item-styles';

export const SimpleMenuItem = ({ data, global, inEditor }) => {

    const styles = Styles( global, data, inEditor );

    let price, qty = null;

    if (data.price) {
        price = `$${ data.price }`;
    }

    if (data.quantity) {
        qty = data.quantity.count + ' ' + data.quantity.type;
    }

    return (

        <div className={ css( styles.column ) }>

            <div className={ css(styles.menuItem) + ' menuItem_' } >

                {/* LEFT ITEM - CONTAINS ALL BUT PRICE */}

                <div className={ css(styles.leftItem) }>

                    <div className={ css(styles.itemQuantity) }>
                        { qty }
                    </div>

                    <span className={ css(styles.headingAndPrice) }>

                        <h3 className={ css(styles.itemHeading) }>
                            { data.name.text }
                        </h3>

                        <p className={ css(styles.itemPrice) }>
                            { price }
                        </p>

                    </span>

                    <p className={ css(styles.itemDescription) }>
                        { data.description.text }
                    </p>

                    <div className={ css(styles.itemTags) }>

                        {
                            data.tags.map( (tag) => {
                                return (
                                    <div className={ css(styles.tag) }> { tag } </div>
                                )
                            })
                        }

                    </div>

                </div>

            </div>

        </div>

    )


};