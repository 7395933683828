import React from "react";
import Component from "source/rootComponent";
import { css } from "@convertly/css";

// Style
import { LinkWrapperStyles } from "./LinkWrapperStyles";

// Util
import renderChildren from "../util/renderChildren";

export class LinkWrapper extends Component {
  render() {
    const { blogData, data, inEditor, global } = this.props;

    if(_.isEmpty(blogData)) {
      return null;
    }

    const inlineStyle = this.generateStyleAttribute();

    this.styles = LinkWrapperStyles({ global, data, inEditor });

    const { linkWrapper } = this.styles;


    return (
      <a
        {...inlineStyle}
        {...this.generateDataEditorAttribute()}
        {...this.generateCustomAttributes()}
        className={css(linkWrapper)}
        href={inEditor ? "#" : "/"}
      >
        {renderChildren(this.props, {
          blogData,
          inEditor
        })}
      </a>
    );
  }
}
