/*global Inferno, $, React */
/** Drop down Product Variant Widget **/
import React from 'react';

const Script = () => {
		
		return ({nameOfClass}) => {
			return (
				<svg version="1.1"
						 className={ nameOfClass }
						 xmlns="http://www.w3.org/2000/svg"
						 viewBox="0 0 491.858 491.858">
					<path d="M465.167 211.613H26.69c-8.424 0-26.69 11.439-26.69 34.316s18.267 34.316 26.69 34.316H465.169c8.421 0 26.689-11.439 26.689-34.316s-18.268-34.316-26.691-34.316z"/>
				</svg>
			)
		}
	}
;

export default Script;
