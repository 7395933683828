import React from 'react';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";

//Default Props
import defaultData from '../seciton-title-default-data';
import defaultGlobal from '../../../themes/global';

//Styles
import bodySection from './section-title-styles-01';

//Components
import Elements from '../../../elements/index';

/*
 **Layout

 Full width paragraph with section title
 */
export default class SecitonTitle extends Component {

    constructor(props) {

        super(props);

    }

    render() {
        /**
         * Call Stylesheet function
         **/
        this.styles = bodySection(this.props.global, this.props.data);

        if (this.props.editor) {
            return this.editor();
        }

        return (
            <Elements.ModuleWrapper
                parentClassName={ this.styles.sectionTitleSection }
                data={ this.props.data }
                global={ this.props.global }
                elementDataName="backgroundColor"
                moduleId={ this.props.id }
                moduleSort={ this.props.sort }>
                <Elements.Container>
                    <Elements.Row>
                        <div className="col-sm-12">
                            {/** Section Title **/}
                            <Elements.Heading2
                                parentClassName={ this.styles.sectionTitleStyles }
                                data={ this.props.data.sectionTitle }
                                global={ this.props.global }
                                elementDataName="sectionTitle"
                                moduleId={ this.props.id }
                                moduleSort={ this.props.sort }/>
                        </div>
                    </Elements.Row>
                </Elements.Container>
            </Elements.ModuleWrapper>

        )
    }
}

SecitonTitle.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
