import { store } from "app/app";
import { graph } from "app/utils";
import { setLoadingModules, setCustomModules } from "./editorActions";
import _ from "lodash";

import { getActiveSiteId } from "services/sites";

export const loadCustomModules = () => async dispatch => {
  await dispatch(setLoadingModules(true));

  const modules = await fetchCustomModules();

  await dispatch(setCustomModules(modules));
  await dispatch(setLoadingModules(false));
};

export const fetchCustomModules = async () => {
  const userId = store.getState().getIn(["auth", "user", "sub"]);

  const query = `
			query( $id:ID!, $siteId:String ) {
			  Account( id:$id ) {
				  CustomModules( siteId: $siteId )
			  }
			}
		`;

  const variables = {
    siteId: getActiveSiteId(),
    id: userId,
  };

  const response = await graph({ query, variables });
  const modules = _.get(response, "Account.CustomModules") || "[]";
  return JSON.parse(modules);
};

export const saveModule = ({ module, moduleName }) => async dispatch => {
  const Auth = store.getState().get("auth");
  const siteId = Auth.get("activeSite").get("siteId");
  const userId = Auth.get("user").get("sub");

  const query = `
			mutation( $id:ID!, $module:String, $moduleName:String, $siteId:String ) {
			  Account( id:$id ) {
				  saveModule( module: $module, moduleName: $moduleName, siteId: $siteId )
			  }
			}
		`;

  const clean = cleanElements(module.toJS(), null);

  const variables = {
    module: JSON.stringify(clean),
    moduleName,
    siteId,
    id: userId,
  };

  await graph({ query, variables });
};

function cleanElements(elementsArr, parentId) {
  if (Array.isArray(elementsArr)) {
    elementsArr.sort((a, b) => {
      return a.sort - b.sort;
    });

    let currentSort = 1;

    return elementsArr.map(o => {
      o.a = generateId();
      o.b = parentId;
      o.c = currentSort++;
      if (o.sort) delete o.sort;

      if (o.userOverrides) {
        o.p = o.userOverrides;
        delete o.userOverrides;
      }

      if (o.data) {
        if (o.data.site) delete o.data.site;
        if (o.data.global) delete o.data.global;
        if (o.data.userOverrides) {
          o.data.a = o.data.userOverrides;
          delete o.data.userOverrides;
        }
      }
      o.d = o.data;
      delete o.data;

      if (o.site) delete o.site;

      delete o.parentId;

      if (o.type) delete o.type;
      if (o.id) delete o.id;
      if (o.label) delete o.label;
      if (o.editable) {
        o.z = 1;
      }
      delete o.editable;

      o.e = o.el;
      delete o.el;

      o.children = cleanElements(o.children, o.a, false);
      return o;
    });
  } else {
    return elementsArr;
  }
}
