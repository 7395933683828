import { StyleSheet } from "@convertly/css";
import Typography from "../../../../elements/style-functions/typography";
import headerNavGlobalStyles from '../../common/header-nav-global-styles';

const headerNavStyles = ( global, data ) => {

    const colors = global.colors;
    const transition1 = "all ease-in-out 0.5s";
    const headerNavGlobal = new headerNavGlobalStyles( global, data );


    const alternateNavState = {
        color: global.colors.accent.color2,
        paddingBottom: "2px",
        borderBottom: "2px solid " + global.colors.accent.color2,
        textDecoration: "none"
    };

    const mobileNavContainer = {

    };
    const iconBar = {
        background: global.colors.dark.color1,
        width: "35px",
        height: "5px",
        marginBottom:"5px",
        position: "relative",
        display:"block",
        transition: transition1,
        zIndex:49
    };
    const dropdown = {
        position: "absolute",
        top:'100%',
        left: "-45px",
        width: "198px",
        padding: "13px 0",
        zIndex: 49,
        '@media(max-width:767px)': {
            color: global.colors.light.color1,
            background: global.colors.dark.color1,
        }

    };

    return StyleSheet.create({
        headerBottom: {
            padding: 0,
            zIndex: '40'
        },
        headerMain: {
            padding: 0,
            "@media(max-width: 767px)" : {
                position: "relative"
            }
        },
        logo: {
            padding:"20px 0",
            display:"inline-block",
            float:"left",
            "@media(max-width: 1199px)": {
                textAlign: "center",
                padding: "15px 0"
            },
            "@media(max-width: 767px)": {
                position: "absolute",
                top: "7px",
                padding: 0,
                zIndex: 48,
                width: "auto",
                float: "none",
                left: "50%",
                transform: "translateX(-50%)"
            },
            "@media(max-width: 479px)": {
                top:"0px"
            }
        },
        logoLink: {
            display:"inline-block"
        },
        logoImg: {
            display:"block",
            maxWidth:'100%',
            maxHeight:'5em',
            '@media (max-width: 489px)': {
                maxHeight:'3.8em'
            }
        },
        navBg: {
            display: "inline-block",
            float: "left",
            padding: "0 1em",
            "@media (max-width: 1199px)": {
                padding:"0 1.2em"
            },
            "@media (max-width: 767px)": {
                position: "fixed",
                background: global.colors.dark.color1,
                ...headerNavGlobal,
                padding:"0 1.2em",
                overflow: "hidden",
                left: "-250px",
                width: "250px",
                height: "100%",
                top: "0",
                display: "block",
                zIndex: 48,
                transition: transition1,
            },
            '.open': {
                "@media (max-width: 767px)": {
                    left: "0",
                    padding: 0
                }
            },
        },
        headerBtmRgt: {
            padding:0,
            "@media (max-width: 1199px)": {
                padding: "10px 0 0 0"
            },
            "@media (max-width: 767px)":{
                padding: "12px 0"
            }

        },

        desktopNavbar: {
            background: "none",
            margin: 0,
            float: "left",
            position: "relative",
            minHeight: "5em",
            marginBottom: "20px",
            border: "1px solid transparent",
            "@media (min-width: 768px)": {
                borderRadius: "4px"
            },
            ":before": {
                content: " ",
                display: "table"
            },
            ":after": {
                content: " ",
                display: "table",
                clear: "both"
            },
            display: "none",
            borderColor: global.colors.dark.color1,
            "@media(max-width: 768px)": {
                ...mobileNavContainer,
            },
            '.open': {
                display: "block",
                "@media (max-width: 768px)": {
                    ...mobileNavContainer,
                },
            },
        },
        navList: {
            padding:'10px 0 0 0',
            position: "relative",
            minHeight: "50px",
            border: "1px solid transparent",
            marginTop:50,
            zIndex:40,
            height:'100%',
            "@media (min-width: 768px)": {
                padding:0,
                margin:"0 auto",
                borderRadius: "4px",
            },
            ":before": {
                content: " ",
                display: "table"
            },
            ":after": {
                content: " ",
                display: "table",
                clear: "both"
            }
        },
        mobileNavIconContainer: {
            zIndex:49,
            marginTop: ".8em",
            marginLeft: ".5em",
        },
        mobileNavIcon: {
            width:"1.4em",
            height:"1.4em",
            display:"none",
            "@media (min-width: 989px)": {
                display: "none"
            }
        },
        iconBar: {
            "@media(max-width: 768px)": {
                ...iconBar
            },
        '.open': {
            ":nth-child(2)":{
                ...iconBar,
                transform:"rotate(45deg)",
                top:"6px",
                background:global.colors.light.color3
            },
            ":nth-child(4)": {
                ...iconBar,
                transform:"rotate(-45deg)",
                top:"-14px",
                background:global.colors.light.color3
            },
            ":nth-child(3)": {
                ...iconBar,
                opacity:0
            }
            },
        },
        collapse: {
            display: "block",
            height: "auto",
            paddingBottom: 0,
            overflow: "visible",
            background:"none",
            border:"none",
            outline: "none",
            "@media(min-width: 768px)": {
                display:"none"
            }
        },
        navItemContainer: {
            float:"left",
            "@media (max-width: 767px)": {
                float:"none"
            }
        },
        navItem: {
            display: "inline-block",
            float: "left",
            padding: "0 22px",
            position: "relative",
            height:'8em',
            "@media (max-width: 767px)": {
                height:'initial',
                display: "block",
                float: "none",
                width: "100%",
                padding: 0
            },
        },
        navItemLink: {
            fontSize: "14px",
            lineHeight: "14px",
            color: global.colors.dark.color1,
            fontWeight: 400,
            display: "inline-block",
            padding: "34px 0px",
            "@media(max-width: 1199px)" : {
                padding: "20px 0",
            },
            "@media(max-width: 767px)": {
                display: "block",
                float: "none",
                width: "100%",
                padding: "15px",
                color: global.colors.light.color1
            }
        },
        navItemLinkActive: {
            ...alternateNavState
        },
        dropDownNavList: {
            display: "none",
            zIndex:20,
            //border:'1px solid',
            boxShadow: '-1px 2px 3px #999',
            background: data.backgroundColor || global.colors.light.white,
            borderTop:'none',
            marginTop:'-2px',
            ...dropdown,
            "@media (max-width: 1199px)": {
                marginTop:'-12px'
            },
            "@media (max-width: 767px)": {
                boxShadow:"none",
                position: "static",
                width: "100%",
                border:'none'
            },
            '.open': {
                display: "block",
                ...dropdown,
                "@media (max-width: 767px)": {
                    position: "static",
                    margin:0,
                    padding:0,
                    width: "100%"
                }
            },
        },
        dropDownNavItem: {
            display: "block",
            listStyle: "none",
            "@media (max-width: 767px)": {
                ":hover": {
                    opacity:'.8'
                }
            }
        },
        dropDownNavItemLink: {
            fontSize: "1em",
            padding: "12px 20px",
            display: "block",
        },
        searchBlock: {
            padding: "20px 0 0 0",
            width: "49%",
            "@media(max-width: 1199px)": {
                padding: "5px 0 0 0"
            },
            "@media(max-width: 767px)": {
                padding: 0,
                width: "auto"
            }
        },
        searchLft: {
            padding:0,
            position:"relative",
            float:"left",
            width: "71.4%",
            "   @media(max-width: 1365px)":{
                width: "70%"
            },
            "@media(max-width: 1199px)": {
                width: "64%"
            },
            "@media(max-width: 768px)": {
                display:"none"
            }
        },
        btnSearch: {
            position: "absolute",
            top: 0,
            right: 0,
            border: "none",
            height:"30px",
            width: "30px",
            outline: "none",
            background:"url(./img/search.png) no-repeat center center " + global.colors.dark.color2,
            borderRadius:"0 2px 2px 0"
        },
        searchBt: {
            height:"31px",
            border: "1px solid ",
            borderColor: global.colors.light.color3,
            width: "100%",
            background: "none",
            fontSize:"14px",
            lineHeight:"31px",
            padding:"0 40px 0 12px",
            fontWeight:"400",
            outline: "0 none",
            borderRadius:"3px"
        },

        searchRgt: {
            padding:"8px 9px",
            "@media(max-width: 767px)": {
                padding: "5px 0 0 0"
            }
        },
        searchRgtList: {
            padding:0,
            margin:"0 -18px",
            "@media(max-width: 1365px)": {
                margin: 0
            }
        },
        searchRgtListItem: {
            padding:"0 18px",
            margin:0,
            display:"inline-block",
            width:"25px",
            height:"22px",
            "@media(max-width: 1365px)": {
                padding: "0 13px"
            },
            "@media(max-width: 479px)": {
                padding: "0 6px"
            }
        },
        searchRgtListItemLink: {
            display:"inline-block",
            position: "relative"
        },
        searchRgtListItemImg: {
            display:"block",
            "@media(max-width: 479px)": {
                width:"18px",
                height:"auto"
            }
        },
        searchIcon: {
            "@media(min-width: 479px)": {
                display:"none"
            }
        },
        heartIcon: {
            display:"inline-block",
            "@media(max-width: 479px)": {
                display:"none"
            }
        },
    });
};


export default headerNavStyles;
