export default {
  MODALS: {
    ADD_PAGE: "ADD_PAGE",
    HELP_FORM: "HELP_FORM",
    IMAGE_GALLERY: "IMAGE_GALLERY",
    IMAGE_EDITOR: "IMAGE_EDITOR",
    PAGE_SETTINGS: "PAGE_SETTINGS",
    SITE_SETTINGS: "SITE_SETTINGS",
    SAVE_MODULE: "SAVE_MODULE",
    PRODUCT_IMAGES: "PRODUCT_IMAGES",
    SAVE_BANNER_TITLE: "SAVE_BANNER_TITLE"
  }
};
