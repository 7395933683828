import React from 'react';
import Component from '../../../rootComponent';
import { css } from "@convertly/css";

//Default Data
import defaultData from '../footer-default-data';
import defaultGlobal from '../../../themes/global';

//Styles
import footerStyles from './footer-styles-05';


//Components
import Elements from '../../../elements/index';
import Widgets from '../../../widgets/index';

//Utils 
import {clientImage} from '../../../utils/generateImage';

export default class
Footer05 extends Component {

    constructor ( props ) {

        super( props );

    }
    render() {

        this.styles = footerStyles( this.props.global, this.props.data );

        if( this.props.editor ) {
            return this.editor();
        }
        const inlineStyle = this.generateStyleAttribute();
        return (

            <footer className={css( this.styles.mainFooter ) }
                onClick={(e) => {
                    this.launchElementalEditor('footer', e);
                }}
                onMouseOver={ this.addEditableOutline }
                onMouseOut={ this.removeEditableOutline }
                { ...inlineStyle }
                { ...this.generateDataEditorAttribute() }
            >
                    {/** Footer Logo **/}
                <div className="col-md-offset-3 col-md-6 col-sm-12">

                    <img src={ clientImage(this.props.data.logo.src) }
                            alt={ this.props.data.logo.alt }
                            id='logo'
                            title={ this.props.data.logo.title }
                            className={ css(this.styles.logoImg) + " logo" }
                    />

                    <div className={ css( this.styles.socialIconsContainer ) }>
                        <Widgets.SocialSharing02
                            global={ this.props.global }
                            socialIconLinkClass={ this.styles.socialIconContainer }
                            styles={ this.styles }
                            data={ this.props.data }
                            site={ this.props.site } />
                    </div>
                    <div className={ css( this.styles.footerText ) }>
                        <Elements.Paragraph
                            parentClassName={ this.styles.heroTitle }
                            global={ this.props.global }
                            data={ this.props.data.footerText }
                            elementDataName="heroTitle"
                            moduleId={ this.props.id }
                            moduleSort={ this.props.sort }/>
                    </div>
                <div className={ css( this.styles.footerCopyText ) }>
                {/** Copy Text **/}
                <div className={ css( this.styles.copyRightText ) }>
                    <Elements.Paragraph
                        parentClassName={ this.styles.copyText }
                        data={ this.props.data.paragraph }
                        global={ this.props.global }
                        elementDataName="paragraph"
                        moduleId={ this.props.id }
                        moduleSort={ this.props.sort }
                    />
                </div>
                {/** Privacy Links **/}
                <ul className={ css( this.styles.footerNavLinks ) } >
                    {
                        this.props.data.copyRightLinks.map( ( item, index )  => {
                            return (
                                <li key={ index } className={ css( this.styles.copyRightLinks ) } >
                                    <Elements.WebLink
                                        data={ item }
                                        moduleId={ this.props.id }
                                        moduleSort={ this.props.sort }
                                        global={ this.props.global }
                                    />
                                </li>
                            )
                        })
                    }
                </ul>
                </div>
                </div>
            </footer>

        )
    }

}

Footer05.defaultProps= {
    data: defaultData,
    global: defaultGlobal
};
