import { StyleSheet } from "@convertly/css";
import userOverrides from '../../../elements/style-functions/user-overrides';
import _ from 'lodash';
import { generateHeaderOffsetMediaQueries } from '../header-style-functions/fixed-header-media-queries'

const headerStyles4 = ( { global, data, inEditor } ) => {

  data.position = inEditor ? 'static' : data.position;

  let headerUseroverrides = userOverrides( global, data, inEditor );
  let positionFixed = data.position === "fixed";
  let bodyPaddingMediaQueries = {};
  let headerHeight = _.get( data, 'userOverrides.height' ) || '60px';
  //let headerHeightValue = headerHeight ? parseInt(headerHeight.replace(/px|em|%|vh|vw|vhmax|vhmin|vwMax|vhmin/, '') ) : '';

    /** Searches for height in media queries and assigns is
  to a property that can be passed to height */
  if( data.userOverrides && positionFixed ) {
      bodyPaddingMediaQueries = generateHeaderOffsetMediaQueries( data );
  }


  return StyleSheet.create( {

      header: {
        color: global.colors.light.color3,
        background: global.colors.light.white,
        fontFamily: global.fontFamily.primaryFont,
        lineHeight: '8px',
        borderTop: global.border,
        borderBottom: global.border,
        borderColor: data.moduleTextColor || "",
        height: '60px',
        width: '100%',
        position: positionFixed ? 'fixed' : 'static',
        top: '0',
        zIndex: '40',
        ...headerUseroverrides,
      "* body": {
        marginTop: positionFixed ? headerHeight : '0',
        minHeight: positionFixed ? `calc(100vh - ${ headerHeight })` : '100vh',
        "@media(max-width:767px)": {
          marginTop: positionFixed ? _.get( data, 'userOverrides.@media(max-width:768px).height' ) : '0',
          minHeight: positionFixed ? `calc(100vh - ${ headerHeight })` : '100vh',
        },
        ...bodyPaddingMediaQueries
      }
    },

    fixedHeaderFix: {
      display: positionFixed ? 'block' : 'none',
      height: '4em'
    }

  } )

};
export default headerStyles4;
