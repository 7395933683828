/*global Inferno, $ */
import React from 'react';
const Script = (dispatch) => {
	
	return (props) => {
		const {rootClass, toggleSwitch, sortMeta, toggleSortByMobileModal, showSortByMobileModal} = props;
		
		return (
			<div
				className={ `${ rootClass } sort-by-mobile-modal-container ${ showSortByMobileModal ? 'active': '' }` }
				data-toggle={ 'sort-by-modal' }>
				<div className={ `${ rootClass } sort-by-mobile-modal`}>
					<div className={ `${ rootClass } sort-by-mobile-modal-header`}>
						<div className={ `${ rootClass } sort-by-mobile-modal-title`}>Sort By</div>
						<div
							className={ `${ rootClass } sort-by-mobile-modal-close-button`}
							onClick={ toggleSortByMobileModal }>
							Close
						</div>
					</div>
					<div className={ `${ rootClass } sort-by-mobile-modal-options`}>
						{
							sortMeta.map((option, idx) => {
								return (
									<div key={ `radio-option-mobile-${ idx }` }>
										<div className={ `${ rootClass } sort-by-mobile-modal-single-option-wrapper` }>
											<input id={ `radio-${ idx }` }
														 type='radio'
														 name='radio'
														 onClick={ toggleSwitch }
														 data-index={ idx }
														 className={ `${ rootClass } sort-by-mobile-modal-single-option` }/>
											<label for={ `radio-${ option.mobileText1 }` }
														 className={ `${ rootClass } sort-by-mobile-modal-single-option-label` }>
												{ option.mobileText1 }
											</label>
										</div>
										<div className={ `${ rootClass } sort-by-mobile-modal-single-option-wrapper` }>
											<input id={ `radio-${ idx }` }
														 type='radio'
														 name='radio'
														 onClick={ toggleSwitch }
														 data-index={ idx }
														 className={ `${ rootClass } sort-by-mobile-modal-single-option` }/>
											<label for={ `radio-${ option.mobileText2 }` }
														 className={ `${ rootClass } sort-by-mobile-modal-single-option-label` }>
												{ option.mobileText2 }
											</label>
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>
		)
		
	}
	
}

export default Script;
