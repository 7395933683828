/* global ga, _paq, fbq */

const Script = () => {

	return ( id, conversionPoints, systemFacebookPixelId, customUserFacebookPixelId ) => {

		conversionPoints = JSON.parse( conversionPoints );

		let count = 0;
		const timer = setInterval( runConversions, 100 );

		function runConversions() {
			
			conversionPoints = conversionPoints.map( point => {

				if( ! point ) return;

				if( point.google && typeof ga !== 'undefined') {
					ga( 'send', 'event', 'interaction', point.name );
					console.log( 'Firing GA event', point.name );
					point.google = false;
				}

				if( point.facebook && typeof fbq !== 'undefined') {
					if(systemFacebookPixelId) {
						fbq( 'trackSingleCustom', systemFacebookPixelId, point.name );
						console.log( 'Firing FB event', point.name );	
					}

					if(customUserFacebookPixelId) {
						fbq( 'trackSingleCustom', customUserFacebookPixelId, point.name );
						console.log( 'Firing FB event', point.name );	
					}

					point.facebook = false;
				}

				if( point.facebook || point.google ) {
					return point;
				}

			});

			conversionPoints = conversionPoints.filter( p => typeof p !== 'undefined' );

			if( count++ > 30 ) {
				clearInterval( timer );
			}

			if( ! conversionPoints.length ) {
				clearInterval( timer );
			}

		}

	};

};

export default Script;
