import React from 'react';
import Component from '../../../../rootComponent';
import { css } from "@convertly/css";
import Types from '../../../../attribute-types';
import { id } from '@convertly/thor';
import { generateLink } from '../../../../utils/generateLink';
import _ from 'lodash';

//Styles
import headerNavLinkstyles from './header-nav-link-styles-1'


import DropDownNavLink2 from './dropdown-nav-link/dropdown-nav-item-2';

import caretToggleOpen from '../../scripts/nav-dropdown-toggle';

export default class
HeaderNavLink1 extends Component {

    constructor(props) {
        super(props);

    }

    render() {

        this.styles = headerNavLinkstyles( this.props.global, this.props.data );

        let navItem;

        let activeClass = '';

        const slug = _.get( this.props, 'page.slug' );
        const url = _.get( this.props, 'data.link.url' );

        if( slug === url && slug && url ) {

            activeClass = " active"
        }

        if ( this.props.data.dropDownNav ) {
            navItem =
                <li className={ css( this.props.styles.navListItem ) }
                    id={ id( caretToggleOpen ) }
                    data-target={ "#dropdown" + this.props.id}
                    key={ "navItem" + this.props.id }
                >
                    <svg className={ css( this.props.styles.caret ) + ' caret'}
                         viewBox="0 0 32 18"
                         version="1.1"
                         xmlns="http://www.w3.org/2000/svg">
                        <g id="modals_menus" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
                            <g id="Mobile-Portrait" transform="translate(-254.000000, -929.000000)" stroke="#FFFFFF" strokeWidth="3">
                                <g id="Side-Menu">
                                    <g id="Group" transform="translate(270.000000, 938.000000) scale(-1, 1) rotate(-180.000000) translate(-270.000000, -938.000000) translate(256.000000, 931.000000)">
                                        <path d="M14,0.145833333 L0.286024275,13.7169551" id="Line"></path>
                                        <path d="M27.8526316,0.145833333 L14.1386559,13.7169551" id="Line" transform="translate(20.926316, 7.000000) scale(1, -1) translate(-20.926316, -7.000000) "></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                    <a className={ css( this.props.styles.dropDownLaunch, this.styles.headerNavLink ) }
                       href="#">Products</a>
                    <ul id={ "dropdown" + this.props.id}
                        data-category="dropdown"
                        className={ css( this.props.styles.dropDownNavList ) } >
                        { this.props.data
                            .dropDownNav
                            .map( ( dropDownNavItem, index ) => {
                                return (
                                    <DropDownNavLink2 key={ index }
                                                      global={ this.props.global }
                                                      data={ dropDownNavItem }
                                                      styles={ this.props.styles }/>
                                )
                            })
                        }
                    </ul>
                </li>;
        }
        else {
            navItem =
                <li className={ css( this.props.styles.navListItem ) + activeClass }
                    key={ "navItem" + this.props.id }>
                    <a className={ css( this.props.styles.navListItemLink, this.styles.headerNavLink ) + activeClass }  href={ generateLink(this.props.data.link) }>{ this.props.data.text }</a>
                </li>;
        }
        return (
            <div key={ "div" + this.props.id }
                 className={ "header-nav-link-2 " + css( this.props.styles.navItemContainer ) }>
                { navItem }
            </div>
        )
    }
}
