import {graph} from "app/utils";
import {Router} from "react-router";
import {createAction} from "redux-act";


export const setCurrentCategory = createAction("Set the current product to the store");
export const updateCurrentCategory = createAction( "updates category in store" );
export const saveCategory = createAction( "saves category as exists in state" );
export const toggleDeleteCategoryModal = createAction('toggles delete category modal')

export  function loadCategory(dispatch) {
  return async function () {
    const catId = getCategoryId();

    const query = `query($id:ID) {
      ProductCategory(id:$id) {
        id
        name
        siteId
        description
        numProducts
        slug
        categoryPageId
      }
    }`

    let res = await graph({query, variables: {id: catId}})

    dispatch(setCurrentCategory(res.ProductCategory))
  }
}

export function updateCategory(dispatch) {

  return (key, value) => {
    dispatch(updateCurrentCategory( {key, value} ) )
  }

}




export function getCategoryId() {
  const patharray = window.location.pathname.split('/');
  return patharray[patharray.length - 1];

}

export function createDeleteCategoryFunction(dispatch) {

  return async function deleteCategory(category) {

    const query =  `
      mutation($id:ID! $categoryPageId:ID $hardDelete:Boolean $siteId:ID) {
          DeleteProductCategory(id:$id categoryPageId:$categoryPageId hardDelete:$hardDelete siteId:$siteId)  {
          success
          message
        }
      }
    `

    const variables = {
      id:category.id,
      categoryPageId:category.categoryPageId,
      hardDelete:true,
      siteId:category.siteId
    }

    await graph({query, variables})

  }



}
