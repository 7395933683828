// we don't actually use jquery
// but need a $ library for webpack to compile against
////import $ from 'jquery';

const Scripts = ( id ) => {

	return ( id ) => {


		const el = $(`#${id}`);
		const form = el.find( '#submit-form' );
		const msg = form.find( '#form-message' );
		const inputs = form.find( 'input' );

		const disableForm = () => {
			inputs.each( ( index, item ) => {
				$(item).attr( 'disabled', 'disabled' ).addClass( 'disabled' );
			});
			msg.html('&nbsp;').removeClass('error');
		};

		const enableForm = () => {
			inputs.each( ( index, item ) => {
				$(item).attr('disabled', null ).removeClass( 'disabled' );
			});
		};

		form.on( 'submit', function( e ) {

			e.preventDefault();

			var endpoint = "https://98w5w7kr0d.execute-api.us-east-1.amazonaws.com/dev/subscriptions",

				fields = {
					email:   form.find('[name=form-email]').val(),
					name:    form.find('[name=form-name]').val(),
					website: form.find('[name=form-website]').val(),
					listId:  form.find('[name=form-list]').val(),
				},

				options = {
					data : JSON.stringify( fields ),
					headers: {
						'Content-type' : 'application/json'
					},
					type: "POST",
					url: endpoint,
					beforeSend: function() {
						disableForm();
					},
					success: function( data ){
						inputs.each( ( index, item ) => {

							$( item )
								.removeClass( 'disabled' )
								.addClass( 'success' );

							msg
								.addClass('success')
								.html("Thanks, you're all set!");
						});
					},
					error: function( err ) {
						const error = JSON.parse( err.response );
						msg.html( error.msg ).toggleClass( 'error' );
						enableForm();
					}
				};

			$.ajax( options );

		} );


	};

};

export default Scripts;
