/*global Inferno, $ */
/* eslint-disable */
import {include} from '@convertly/thor';

const Script = () => {

        const Dispatcher = include('../../../utils/dispatcher', 'Dispatcher');
        const Graph = include('../../../utils/graph', 'Graph');
        const SpinnerSVGInferno = include('../../../svg/spinner/spinner', 'SpinnerSVGInferno');
        const NotificationBoxInferno = include('../../../ecommerce-common-components/notification-box/notification-box', 'NotificationBoxInferno');

        return (id, domainName, siteId, siteLink) => {

            const root = document.getElementById(id);


            const dispatch = Dispatcher(id);
            const graph = Graph();
            const SpinnerSvg = SpinnerSVGInferno();
            const NotificationBox = NotificationBoxInferno();

            let domain = domainName;

            if (domain.indexOf('www.') !== -1) {
                domain = domain.replace('www.', '');
            }

            const YourComponentName = Inferno.createClass({

                getInitialState: function () {
                    const token = this.getUrlParameter('token');

                    return {
                        token:token,
                        password:'',
                        passwordConfirm: '',
                        passwordReset:false
                    };
                },

                getUrlParameter: function (name) {
                    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
                    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
                    let results = regex.exec(location.search);
                    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
                },

                /** Password validation **/
                validPassword( e ) {
                    e.preventDefault()
                    let password = this.state.password;
                    let validPassword = /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\1234567890":<>\?]/g.test(password);

                    if (password.length < 7) {
                        $('input[data-input="password"]').next().text('Password required to be 7 characters or more')
                            .addClass('show-form-error-message');
                    }

                    else if (!validPassword) {
                        $('input[data-input="password"]').next().text('Password required to have 1 number or special character')
                            .addClass('show-form-error-message');
                    }

                    else {
                        this.confirmPassword()
                    }

                },
                /** validates password fields match **/
                confirmPassword: function () {

                    if (this.state.password !== this.state.passwordConfirm) {
                        $('input[data-input="confirm-password"]').next().text('This password does not match')
                            .addClass('show-form-error-message');
                        return false
                    }
                    else {

                        this.updatePassword()

                    }
                },

                updatePassword: function () {

                    const query = `mutation( $token:String!, $password:String! ) {
                                    resetPassword(token:$token,password:$password)
                                }
                                    `;

                    const variables = {
                        token: this.state.token,
                        password: this.state.password
                    };

                    let that = this;
                    const callback = function (err, response) {
                        if (response) {
                            console.log(response);
                            if(response.resetPassword === true) {

                                that.setState({
                                    changePassword: false,
                                    password: '',
                                    passwordConfirm: '',
                                    passwordReset:'success'
                                });
                            }
                            else {
                                that.setState({
                                    passwordReset:'error'
                                })
                            }


                        }
                        else {
                            dispatch.send('snack_bar_alert', {
                                alert: "Something went wrong please try again",
                                type: "error",
                                title: '',
                                timeout: '',
                            });
                            console.log(err)
                        }
                    };

                    graph({query: query, variables: variables, cb: callback})
                },

                onChangeGenericField: function (e, field) {
                    const newState = {};
                    newState[field] = e.target.value;
                    this.setState(newState);
                },

                componentWillMount() {


                },

                render: function () {

                    const rootClass = root.className;

                    let notification = null;

                    if (this.state.passwordReset === "success") {
                        notification =
                            <NotificationBox messageType={ 'success' }
                                             animate={ true }
                                             rootDiv={ root }>

                                <p>Your password has been reset.
                                    <a href={ `${siteLink}/account` }
                                       className={ `${rootClass} home-link` }>
                                        Sign In</a>
                                </p>

                            </NotificationBox>;
                    }
                    else if (this.state.passwordReset === "error") {
                        notification =
                            <NotificationBox messageType={ 'error' }
                                             animate={ true }
                                             rootDiv={ root }>

                                <p>
                                    Something went wrong
                                </p>

                            </NotificationBox>;
                    }


                    return (
                        <div className={ `${rootClass} forgot-password-container` }>
                            <h2 className={ `${rootClass} title` }>Reset your password.</h2>
                            <p className={ `${rootClass} text` }>Enter and confirm your new password below.</p>
                            <form className={ `${ rootClass } form_HCPV` }
                                  onSubmit={ (e) => this.validPassword(e) }>
                                {/** Password **/}
                                <div className={rootClass + " input-container"}>
                                    <input type="password"
                                           className={ rootClass + " textInput_wInput input" }
                                           data-name="Address 1"
                                           name="address-1"
                                           data-input="password"
                                           placeholder="Password"
                                           required={ true }
                                           disabled={ this.state.disabled }
                                           value={ this.state.password }
                                           onInput={ (e) => this.onChangeGenericField(e, 'password')}
                                    />
                                    <p className={ rootClass + " form-error-message" }>
                                        This field is required</p>
                                </div>
                                {/** Password Confirm **/}
                                <div className={rootClass + " input-container"}>
                                    <input type="password"
                                           className={ rootClass + " textInput_wInput input" }
                                           data-name="Confirm Password"
                                           name="Confirm Password"
                                           placeholder="Confirm Password"
                                           data-input="confirm-password"
                                           required={ true }
                                           disabled={ this.state.disabled }
                                           value={ this.state.passwordConfirm }
                                           onInput={ (e) => this.onChangeGenericField(e, 'passwordConfirm')}/>
                                    <p className={ rootClass + " form-error-message" }>
                                        This field is required</p>
                                </div>
                                <div className={ `${rootClass} button-container` }>

                                    <input type="submit"
                                           className={ `${rootClass} btnContinue_wButton` }
                                    />
                                    <SpinnerSvg className={ `${rootClass} spinnerSVG` }
                                                display={ this.state.displaySpinner }/>
                                </div>
                            </form>
                            { notification }
                        </div>
                    )
                }

            });

            Inferno.render(<YourComponentName />, root);

        };

    }
;

export default Script;
