import { connect } from "react-redux";
import AddNewPageModal from "../../App/modals/add-new-page-modal";

const mapStateToProps = state => {
  return {
    pageTemplates: state.get("editor").editorReducer.get("pageTemplates")
  };
};

const Container = connect(mapStateToProps)(AddNewPageModal);

export default Container;
