/** Arrows */

/* Open Arrow Heads */
import ArrowHeadOpenRight from './arrows/arrow-head-open-right';
import ArrowHeadOpenDown from './arrows/arrow-head-open-down';
import ArrowHeadOpenLeft from './arrows/arrow-head-open-left';
import ArrowHeadOpenUp from './arrows/arrow-head-open-up';

/* Closed Arrow Heads */
import ArrowHeadClosedDown from './arrows/arrow-head-closed-down';
import ArrowHeadClosedUp from './arrows/arrow-head-closed-up';

/** Social Icons **/

/* No Background Social Icons */
import LinkedInNoBackground from './social-icons-no-background/linkedin';
import YoutubeNoBackground from './social-icons-no-background/you-tube';
import InstagramNoBackground from './social-icons-no-background/instagram';
import TwitterNoBackground from './social-icons-no-background/twitter';
import PintrestNoBackground from './social-icons-no-background/pintrest';
import FacebookNoBackground from './social-icons-no-background/facebook';

/* Square Social Icons */
import LinkedInSquare from './social-icons-square/linkedin';
import YoutubeSquare from './social-icons-square/you-tube';
import InstagramSquare from './social-icons-square/instagram';
import TwitterSquare from './social-icons-square/twitter';
import PintrestSquare from './social-icons-square/pintrest';
import FacebookSquare from './social-icons-square/facebook';


/* Carts */
import Cart from './carts/cart';

/** Quick View Icon **/
import QuickViewIcon from './quick-view/quick-view-svg';

/** Video Player **/
import VideoPlayButton from './video-play-button/video-play-button'

/** X Icon **/
import XIcon from './x-icon/x-icon';

const svg = {
    /** Arrows */

    /* Open Arrow Heads */
    ArrowHeadOpenRight,
    ArrowHeadOpenDown,
    ArrowHeadOpenLeft,
    ArrowHeadOpenUp,

    /* Closed Arrow Heads */
    ArrowHeadClosedDown,
    ArrowHeadClosedUp,
    /** Social Icons **/

    /* No Background Social Icons */
    LinkedInNoBackground,
    YoutubeNoBackground,
    InstagramNoBackground,
    TwitterNoBackground,
    PintrestNoBackground,
    FacebookNoBackground,

    /* Square Social Icons */
    LinkedInSquare,
    YoutubeSquare,
    InstagramSquare,
    TwitterSquare,
    PintrestSquare,
    FacebookSquare,

    /* Video Play Button */
    VideoPlayButton,

    /* Carts */
    Cart,
  
    /* Quick View */
      QuickViewIcon: QuickViewIcon(true),

    /** X Icon **/
    XIcon: XIcon()
};

export default svg;