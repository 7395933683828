import React from 'react';
import PropTypes from 'prop-types';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import {id} from "@convertly/thor";
import defaultGlobal from '../../../themes/global.js';
import ReactHTMLParser from "@convertly/react-html-parser";
import _ from "lodash"

//Styles
import videoPlayerStyles from './video-bg-styles';

//Scripts
import VideoPlayerInferno from '../scripts/video-player-inferno-app-02';


//Utils
import generateId from '../../../utils/generate-id';
import {clientImage, globalImage} from 'source/utils/generateImage.js';
import { generateYouTubeEmbed } from 'source/utils/video-src-handler.js';

//Components
import SVG from '../../../svg/index';

/**
 * Class controls imports style functions that allows for user to override the css in the CMS editor for their site.
 **/

class VideoPlayer extends Component {

    constructor(props) {

        super(props);
        /**
         * inEditor: Determines if we are currently in the CMS editor
         * elementEditableBorder is the styles for
         **/

        this.state = {
            inEditor: false,
            elementEditableBorder: ""
        };


        /**
         * Event binders
         **/
        this.launchElementalEditor = this.launchElementalEditor.bind(this);
        this.removeEditableOutline = this.removeEditableOutline.bind(this);
        this.addEditableOutline = this.addEditableOutline.bind(this);

    }

    componentWillMount() {

        /**
         * Checks to see if we are in the cms and updates inEditor
         **/
        if (this.context.hasOwnProperty("store")) {
            if (!this.props.notEditable) {
                this.setState({inEditor: true});
            }

        }
    }

    /**
     * Removes the style that outlines element
     **/
    removeEditableOutline(e) {
        this.setState({
            elementEditableBorder: ""
        });
        e.hoverOverChild = false;
    }

    /**
     * Adds the style that outlines element
     **/
    addEditableOutline(e) {
        this.setState({
            elementEditableBorder: "2px dashed #F5A623"
        });
        e.hoverOverChild = true;
    }

    /**
     * Launches Editor for particular element
     * @param type [ text, image, button, etc ] defines which editor to launch
     * @param field defines the data attribute to edited
     *
     * must be passed the modules's id ( this.props.moduleId )
     * must be passed modules's sort ( this.props.moduleSort )
     **/
    launchElementalEditor(type, field, e) {
        if (this.state.inEditor) {
            if (!this.props.notEditable) {
                //prevent click from reaching parent bootstrap-containers
                e.stopPropagation();

                //Sends data needed to launch editor
                this.context.store.dispatch(this.context.store.setActiveComponent(this.props.moduleSort));
                this.context.store.dispatch(this.context.store.setModules(this.props.moduleId));
                this.context.store.dispatch(this.context.store.popEditor({type: type, field: field}));

            }
        }
    }

    render() {

        /**
         * Calls Stylesheet function
         **/
        this.styles = videoPlayerStyles(this.props.global, this.props.data);


        /**
         * Handles the inline hover effect
         **/
        let elementEditableBorder = {};
        if (!this.props.notEditable) {
            elementEditableBorder = {
                outline: this.state.inEditor ? this.state.elementEditableBorder : ''
            };
        }

        // Get siteId

        const siteId = _.get( this.props, 'site.siteId' );
        

        /**
         * Generates URL for Video based on video Type
         */
        let videoUrl = '';
        let bgVideo;
        switch (this.props.data.videoType) {
            case "youtube":

                videoUrl = generateYouTubeEmbed(this.props.data.src);

                videoUrl = videoUrl.replace('https://www.youtube.com/embed/', '');
                //bgVideo = <iframe width="560" height="315" src="https://www.youtube.com/embed/luCYT7Qx1oA?rel=0&amp;controls=0&amp;showinfo=0;autoplay=1;loop=1&playlist=luCYT7Qx1oA" frameborder="0" allowfullscreen></iframe>
                //bgVideo = <iframe className={ css(this.styles.youtubeIframe) } src={`https://www.youtube.com/embed/${ videoUrl }?rel=0&amp;controls=0&amp;showinfo=0&amp;autoplay=1&amp;mute=1&amp;loop=1&amp;playlist=${ videoUrl }`} frameborder="0"/>;
                bgVideo = <div className={ css( this.styles.videoContainer ) } id={ id( VideoPlayerInferno, videoUrl, this.props.data.videoType ) } />

                break;
            case "mediafile":
                videoUrl = `https://s3.amazonaws.com/convertlyimguploadeast/${siteId}/${this.props.data.src}`;
                bgVideo =
                    <video preload={true} autoPlay={ true } loop={ true } muted={ true }>
                        <source src={ `https://s3.amazonaws.com/convertlyimguploadeast/${siteId}/${this.props.data.src}` }/>
                    </video>;
                break;
            default:
                videoUrl = generateYouTubeEmbed(this.props.data.src);
        }

        /**
         * Checks for default class name, if it exists, add to class
         **/

        let className = css(this.props.parentClassName, this.styles.videoPlayer);

        if (this.props.data.defaultClass) {
            className += " " + this.props.data.defaultClass;
        }

        /**
         * Loads Video element
         **/
        return (

            <div className={ css(this.styles.videoContainer) + ' video-wrapper' }
                 { ...this.generateDataEditorAttribute() }
            >
                { bgVideo }
            </div>

        );
    }
}


/**
 * Default Props for rendering when no data is being passed
 **/
VideoPlayer.defaultProps = {
    data: {
        src: 'light-bulb-planter.jpg',
        caption: {
            showCaption: false,
            text: 'hello'
        },
        alt: 'hello',

    },
    global: defaultGlobal
};

/**
 * Connects element to context for cms editor
 **/
VideoPlayer.contextTypes = {
    store: PropTypes.object
};

export default VideoPlayer

/*
 {
 "el": "Elements.Video",
 "data":
 {
 "videoType": "youtube",
 "src": "https://www.youtube.com/embed/Wm7jMoZpOxw",
 "placeHolderImage":
 {
 "src": "video_placeholder.jpg"
 },
 "playButtonColor": "light"

 },

 "children": [],
 "sort": 700
 }
 */










