import React from "react";
import PillButton from "./pillButton";

export const SaveButton = ({ loading, action, disabled, label, processing }) => (
  <PillButton
    showLoadIcon={loading}
    backgroundColor={disabled ? "rgba(82,175,82,0.5)" : "#52AF52"}
    textColor="#FFF"
    processing={processing}
    action={action}
    disabled={disabled}
    label={label || "Save"}
  />
);
