import React from 'react';
import Component from 'source/rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';

import { generateLink } from "../../utils/generateLink";

//Default Props
//import defaultData from '';
const defaultData = {};
import defaultGlobal from 'source/themes/global';

//Styles
import Styles from './error-404-styles';

//Components
import Elements from 'source/elements';

export default class Error404 extends Component {

	constructor ( props ) {

		super ( props );

	}

	render () {

		this.styles = Styles ( this.props.global, this.props.data );

		if ( this.props.editor ) {
			return this.editor ();
		}

		return (
			<div className={css ( this.styles._404_lpBody )}>
				<div className={css ( this.styles.contentcontainer )}>
					<div className={css ( this.styles._404Container )}><h1 className={css ( this.styles._404_lg_lpH1 )}>
						404</h1><h1 className={css ( this.styles._404_lpH1 )}>PAGE NOT FOUND</h1>
						<p className={css ( this.styles._404_lpBodytext )}>The content you're looking for no longer exists or has been moved.</p>
						<a href={ generateLink( '/' ) } className={css ( this.styles._404_btn_wButton )}>GO BACK HOME</a></div>
				</div>
			</div>
		)
	}
}


Error404.defaultProps = {
	data: defaultData,
	global: defaultGlobal
};
