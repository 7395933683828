import { StyleSheet } from '@convertly/css';

const productImageGalleryStyles = ( global, data ) => {

    return StyleSheet.create( {
        productgridcontainer: {
            color: global.textColors.dark.black,
            display: "flex",
            flexDirection: "row",
            fontSize: "14px",
            lineHeight: "20px",
            position: "relative",
            width: "100%",
            "@media(max-width:991px)": {
                flexDirection: "column-reverse",
            }
        },
        productboxList: {
            alignItems: "flex-start",
            color: global.textColors.dark.black,
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            lineHeight: "20px",
            listStyle:'none',
            "@media(max-width:991px)": {
                flexDirection: "row",
                justifyContent: "space-between"
            }
        },
        productbox: {
            border: "2px solid transparent",
            color: global.textColors.dark.black,
            fontSize: "14px",
            height: "65px",
            lineHeight: "20px",
            marginBottom: "10px",
            opacity: "1",
            paddingTop: "17px",
            textAlign: "center",
            padding:'0',
            cursor:"pointer",
            width: "65px",
            ".active": {
                borderColor:global.backgroundColors.dark.color1,
                border: "2px solid #253039",
                color: global.textColors.dark.black,
            },
            ':hover': {
                borderColor:global.backgroundColors.dark.color1,
                border: "2px solid #253039",
                color: global.textColors.dark.black,
            },
            ':last-child': {
                marginBottom:'0'
            },
        },
        productThumbnail: {
            height:'100%',
            width:'100%',
            transition: "border 200ms ease",
			"object-fit":"cover"
        },

        productimgcontainer: {
            color: global.textColors.dark.black,
            flex: "0 auto",
            fontSize: "14px",
            lineHeight: "20px",
            paddingLeft: "10px",
            width: "100%"
        },

        large_productbox: {
            overflow:"hidden",
            backgroundColor: "transparent",
            backgroundPosition: "50% 50%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            border: "2px solid #253039",
            color: global.textColors.dark.black,
            fontSize: "14px",
            lineHeight: "20px",
            marginBottom: "10px",
            height:'364px',
            maxWidth: "100%",
            minHeight: "100%",
            opacity: "1",
            //paddingTop: "17px",
            textAlign: "center",
            transition: "border 200ms ease",
            width: "auto"
        },
        featureImage: {
            maxHeight:'100%',
            maxWidth:'100%',
            minHeight:'100%',
            minWidth:'100%',
			"object-fit":"cover"
        }
    } )
};
export default productImageGalleryStyles;
