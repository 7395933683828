/**
 * This component was originally written to work only with the Editor
 * There are references to pageElements and Editor specific DOM fields
 *
 * It has slowly been decoupled from the Editor so that it can be used
 * anywhere in the App
 */

import React, { Component } from "react";
import { css } from "@convertly/css";
import PropTypes from "prop-types";
import _ from "lodash";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import styled from "styled-components";
import TabStyled from "containers/Editor/components/editor/css-Tab-Styles";
import styles from "containers/Editor/components/editor/editor-forms-styles";
import FileUpload from "containers/Editor/components/editor/file-upload";
import ModalWrapper from "./ModalWrapper";
import GalleryImage from "./GalleryImage";
import { keyframes } from "styled-components";
import { setSite } from "@convertly/utils";
import findElementObject from "containers/Editor/findElementObject";
import { setOverlayStyles, closeModal, setElements } from "containers/Editor/actions/editorActions";
import { getSiteImages } from "app/services/sites";
import { assign } from "containers/Editor/assign";
import loadingIcon from "components/icons/loadingIcon.svg";
import { StockPhotosGallery } from "./StockPhotosGallery";
import { noop } from "utils";

const FileUploadWrapper = styled.div`
  display: inline-flex;
  .fa {
    position: relative;
    top: 10px;
  }
`;

const closeAnimation = keyframes`
    from {
      opacity: 1;
      transform: translate(-50%, 0%) scale(1);
    }

    to {
      opacity: 0;
      transform: translate(-50%, 0%) scale(0.95);
    }
  `;

const Underlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2900;
`;

const Button = styled.div`
  cursor: pointer;
  ${({ disabled }) => (disabled ? "opacity: .5" : "")}
`;

let image;
let objectBeingEdited;
let pageElements;

export class GalleryModal extends Component {
  /**
   * Class constructor
   *
   * @param props
   */
  constructor(props) {
    super(props);

    pageElements = this.props.pageElements.toJS().map((element, index) => {
      if (element.id === this.props.editorType.field) {
        objectBeingEdited = findElementObject(element, this.props.editorType.field);
        return element;
      }
      return element;
    });

    this.state = {
      selectedImage: this.props.selectedImage || null,
      hoveredIndex: null,
      transition: {},
      activeTab: 0,
    };

    image = document.querySelector("[data-editor=c" + this.props.editorType.field + "]");

    this.saveImage = this.saveImage.bind(this);
    this.selectImage = this.selectImage.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleWheel = this.handleWheel.bind(this);
  }

  /**
   * Load the Site Images
   * If images are already loaded, calls setCurrentSelectedImage()
   */
  componentWillMount() {
    this.props.dispatch(getSiteImages());
  }

  /**
   * Iterates through pageElements and finds the active Component
   * Sets the field value to the image src
   *
   * Saves pageElements
   * Closes modal
   *
   * @param url
   */
  async saveImage() {
    const selectedImageUrl = this.state.selectedImage;

    if (!selectedImageUrl) {
      return;
    }

    if (this.props.onSave) {
      await this.props.onSave(selectedImageUrl);
      return;
    }

    assign(objectBeingEdited, "data." + this.props.imageFieldSource, selectedImageUrl);

    this.props.dispatch(setElements(pageElements));
    this.props.dispatch(setOverlayStyles(null));
    this.props.dispatch(closeModal());
  }

  /**
   * Event handler for selecting an image
   *
   * @param selectedImage
   */
  selectImage(selectedImage) {
    this.setState({ selectedImage });
  }

  /**
   * Cancel Button event handler. Closes Modal
   *
   * @param url
   */
  closeModal() {
    //this.setState({ transition: { animation: `${closeAnimation} 250ms` } });
    setTimeout(() => {
      this.props.dispatch(setOverlayStyles(null));
      this.props.dispatch(this.props.closeModal());
      this.props.onSave(null);
    }, 250);
  }

  handleWheel(e) {
    if (
      e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + e.deltaY) <
      e.currentTarget.clientHeight
    ) {
      e.currentTarget.scrollTop = e.currentTarget.scrollHeight - e.currentTarget.clientHeight;
      e.preventDefault();
    } else if (e.currentTarget.scrollTop + e.deltaY < 0) {
      e.currentTarget.scrollTop = 0;
      e.preventDefault();
    }
  }

  render() {
    const { site, uploading } = this.props;
    const siteId = site.get("siteId");

    const sortedUrls = this.props.imgUrls.sort(function(a, b) {
      return b.updatedAt - a.updatedAt;
    });

    let title = "Select Image";

    const { selectedImage } = this.state;

    return (
      <div>
        <ModalWrapper
          noCloseIcon={true}
          transition={this.state.transition}
          {...this.props}
          title={title}
          width={810}
        >
          <TabStyled>
            <Tabs>
              <TabList>
                <div onWheel={this.handleWheel}>
                  <Tab>Media Gallery</Tab>
                  <Tab>Stock Photos</Tab>
                </div>
              </TabList>

              <TabPanel>
                <div className={css(styles.modalCollectionContainer)} onWheel={this.handleWheel}>
                  {uploading && (
                    <div className={css(styles.modalLoading)}>
                      <img src={loadingIcon} width="20%" />
                    </div>
                  )}
                  <FileUploadWrapper style={{ marginRight: "20px" }}>
                    <FileUpload
                      key={"upload"}
                      pageElements={this.props.pageElements}
                      siteId={siteId}
                    />
                  </FileUploadWrapper>
                  {sortedUrls.map(image => (
                    <GalleryImage
                      key={`image-${image.filename}`}
                      image={image}
                      selectedImage={this.state.selectedImage}
                      chooseImage={this.selectImage}
                      site={site}
                    />
                  ))}
                </div>
              </TabPanel>
            
              <TabPanel>
                <StockPhotosGallery 
                  search={this.props.search}
                  images={this.props.images}
                  loading={this.props.loading}
                  updatePhotosFromPexelsSearchQuery={this.props.updatePhotosFromPexelsSearchQuery}
                  selectedImage={this.state.selectedImage}
                  selectImage={this.selectImage}
                />
              </TabPanel>
            </Tabs>
          </TabStyled>

          <div>
            <div
              style={{ cursor: "pointer" }}
              className={css(styles.galleryCollectionButton, styles.galleryCollectionButtonWhite)}
              onClick={!uploading && (() => this.closeModal())}
            >
              Cancel
            </div>
            <Button
              disabled={!selectedImage}
              className={css(styles.galleryCollectionButton, styles.galleryCollectionButtonGreen)}
              onClick={!uploading && this.saveImage}
            >
              Save
            </Button>
          </div>
        </ModalWrapper>

        <Underlay />
      </div>
    );
  }
}

GalleryModal.propTypes = {
  uploading: PropTypes.bool,
  onSave: PropTypes.func,
  closeModal: PropTypes.func,
};

GalleryModal.defaultProps = {
  uploading: false,
  onSave: noop,
  closeModal: noop,
};
