import React from "react";
import { css } from "@convertly/css";
import Component from "source/rootComponent";
import _ from "lodash";

// Styles
import { PublishedDateStyles } from "./PublishedDateStyles";

// Components
import Elements from "source/elements";

// Utils
import formatDate from "../util/formatDate";
import { clientImage } from "../../utils/generateImage";
import generateId from "../../utils/generate-id";

export class PublishedDate extends Component {
  render() {
    const {data, inEditor, global, blogData} = this.props;

    if(_.isEmpty(blogData)) {
      return null;
    }

    const formattedDate = formatDate(blogData.date);
    const inlineStyle = this.generateStyleAttribute();
    const {publishedHeadingText, displayPublishedHeadingText} = data;
    const headingTextToUse = _.isString(publishedHeadingText) ? publishedHeadingText : "Posted";

    this.styles = PublishedDateStyles({global, data, inEditor});
    const {publishedDate, publishedDateWrapper} = this.styles;

    return (
        <div
            {...inlineStyle}
            {...this.generateDataEditorAttribute()}
            {...this.generateCustomAttributes()}
            className={css(publishedDateWrapper)}
        >
          {displayPublishedHeadingText !== false && (
              <Elements.Heading4
                  data={{
                    text: headingTextToUse,
                    userOverrides: _.get(data, "userOverrides.publishedHeadingText.userOverrides" || {})
                  }}
              />
          )}

          <div className={css(publishedDate)}>{formattedDate}</div>
        </div>
    );
  }
}
