import React from 'react';
import Component from 'source/rootComponent';
import {css} from "@convertly/css";
import _ from 'lodash';
import {id} from '@convertly/thor';

//Default Props
import defaultGlobal from 'source/themes/global';

//Styles
import Styles from './convertly-sticky-footer-styles';

//Components
import Elements from '../../elements/index';

//Scripts
import ToggleNav from './scripts/open-nav';
import ToggleFooter from './scripts/toggle-sticky-footer';

//Utils
import {clientImage} from '../../utils/generateImage';


export default class ConverltyStickyFooter extends Component {

    constructor(props) {

        super(props);

    }

    render() {

        const slug = _.get(this.props, 'page.slug');

        /** Init style sheet **/
        this.styles = Styles(this.props.global, this.props.data);

        if (this.props.editor) {
            return this.editor();
        }
        return (
            <div id={ id(ToggleFooter) }
                 className={ css(this.styles.stickyFooter) }>
                <Elements.Container>
                    <Elements.Row>
                        <Elements.Column
                            data={ {
                                xsColumns: 12
                            } }>


                            { /** Logo **/ }
                            <div className={ css(this.styles.logoContainer) }>
                                <img src={ clientImage("logo_convertlyBar.png") }/>
                            </div>

                            { /** Navigation **/ }
                            <div className={ css(this.styles.navigationContainer) }>
                                { /** Navigation Launcher **/ }
                                <div className={ css(this.styles.navLauncher) }
                                     data-target="#footerNav"
                                     id={ id(ToggleNav) }>
                                    <div className={ css(this.styles.navLauncherIcon) }/>
                                    <div className={ css(this.styles.navLauncherIcon) }/>
                                    <div className={ css(this.styles.navLauncherIcon) }/>
                                </div>

                                { /** Navigation menu **/ }
                                <div className={ css(this.styles.navigation) }
                                     id="footerNav">
                                    <nav>
                                        <ul className={ css(this.styles.navList) }>
                                            { this.props.data.navLinks.map((navLink) => {

                                                let activeClass = '';

                                                if (slug === navLink.link.url) {
                                                    activeClass = 'active'
                                                }

                                                return (
                                                    <a className={ `${ css(this.styles.navItem) } ${activeClass}` }
                                                       href={ navLink.link.url }>
                                                        <li >
                                                            { navLink.text }
                                                        </li>
                                                    </a>
                                                )
                                            }) }
                                            <a className={ css(this.styles.navItem) }
                                               href="">
                                                <li>
                                                    Sign Up
                                                </li>
                                            </a>
                                        </ul>
                                    </nav>
                                    <div className={ css(this.styles.triangle) }/>
                                </div>

                            </div>
                            {/** Sign Up link **/}

                            <div className={ css( this.styles.signUp ) } >
                                <a href={ '' }>Sign Up for Free</a>
                            </div>


                        </Elements.Column>
                    </Elements.Row>
                </Elements.Container>


            </div>
        )

    }
}


ConverltyStickyFooter.defaultProps = {
    global: defaultGlobal
};
