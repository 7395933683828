/*global React, Inferno, $ */
/* eslint-disable */
import {include} from '@convertly/thor';
import ArrowHeadRightSvg from '../../../../svg/arrows/arrow-head-open-right';

const Script = () => {

        //Utils
        const Graph = include('../../../../utils/graph', 'Graph');
        const Dispatcher = include('../../../../utils/dispatcher', 'Dispatcher');
        const ConvertToCurrency = include('../../../../utils/ecomm-utils/convert-to-currency-ii', 'ConvertToCurrency');
        const InfernoHTMLStripper = include('../../../../utils/inferno-html-stripper', 'InfernoHTMLStripper')
        const BuildPricingObject = include('../../../../utils/ecomm-utils/inferno-utils/build-product-pricing-object-inferno', 'BuildPricingObject')
        //Controllers
        const ProductVariantController = include('../../../../inferno-controllers/product-variant-controller/index', "ProductVariantController");
        const AddToCartButtonController = include('../../../../inferno-controllers/add-to-cart-button-controller', 'AddToCartButtonController');
        const ProductImageGalleryController = include('../../../../inferno-controllers/product-image-gallery-controller', 'ProductImageGalleryController');

        const AddToCartStepperWidget = include('../../../../ecommerce-common-components/buying-options/add-to-cart-stepper/add-to-cart-qty-stepper', "AddToCartStepperWidget");
        const QuickBuyInferno = include('../../../../ecommerce-common-components/buying-options/quick-buy/quick-buy', "QuickBuyInferno");
        //Components
        const ProductImageGalleryInferno = include('../../../../ecommerce-common-components/product-image-gallery/product-image-gallery-2/product-image-gallery', "ProductImageGalleryInferno");
        const ImageThumbNailInferno = include('../../../../ecommerce-common-components/product-image-gallery/product-image-gallery-2/img-thumbnail', 'ImageThumbNailInferno');
        //SVGS
        const ArrowDownOpenInferno = include('../../../../svg/arrows/arrow-down-stateless', 'ArrowDownOpenInferno');
        const PlusIconInferno = include('../../../../svg/plus-icon/plus-icon', 'PlusIconInferno');
        const MinusIconInferno = include('../../../../svg/minus-icon/minus-icon', 'MinusIconInferno');
        const ArrowHeadOpenRightInferno = include('../../../../svg/arrows/arrow-head-open-right-stateless', 'ArrowHeadOpenRightInferno');

        const BubbleVariantContainerInferno = include('../../../../ecommerce-common-components/product-variant-widgets/bubble-variant-container/bubble-variant-container', 'BubbleVariantContainerInferno');
        const BubbleInferno = include('../../../../ecommerce-common-components/product-variant-widgets/bubble/bubble', 'BubbleInferno');
        const VariantProgressBarInferno = include('../../../../ecommerce-common-components/product-variant-widgets/product-variant-progress-bar/product-variant-progress-bar', 'VariantProgressBarInferno');
        const BuyingOptionsWrapperInferno = include('../../../../ecommerce-common-components/buying-options/buying-options-wrapper/buying-options-wrapper', 'BuyingOptionsWrapperInferno');

        const PDPContentInferno = include('./pdp-content-inferno.js', 'PDPContentInferno');

        return (id,  imageUrlsStringified) => {

            const root = document.getElementById(id);
            const metaDiv = document.getElementById('meta');

            let stringifiedData = metaDiv.dataset.product;
            stringifiedData = stringifiedData.replace(/&quot;/g, '"');
            let data = JSON.parse(stringifiedData);
            const dispatch = Dispatcher(id);
            data.productVariants.map((item, index) => {
                item.variations.map( (value, index) => {
                    item.variations[index].name = value.name.replace(/&quot;/g, '"');
                } )
            });

            dispatch.update(`${data.id}-all-variants`, data.productVariants);

            const imageUrls = JSON.parse(imageUrlsStringified);

            const convertToCurrency = ConvertToCurrency();
            const graph = Graph();
            const buildPricingObject = BuildPricingObject();
            //SVG
            const ArrowDownOpen = ArrowDownOpenInferno();
            const PlusIcon = PlusIconInferno();
            const MinusIcon = MinusIconInferno();
            const ArrowHeadOpenRight = ArrowHeadOpenRightInferno();
            //Components
            const AddToCartStepper = AddToCartStepperWidget(dispatch, MinusIcon, PlusIcon);
            const QuickBuy = QuickBuyInferno(dispatch);
            const VariantProgressBar = VariantProgressBarInferno(dispatch);

            const Bubble = BubbleInferno(dispatch);
            const BubbleVariantContainer = BubbleVariantContainerInferno(dispatch, VariantProgressBar, Bubble);
            const stripHtml = InfernoHTMLStripper();

            const BuyingOptionsWrapper = BuyingOptionsWrapperInferno(dispatch, AddToCartStepper, QuickBuy);

            const ImageThumbNail = ImageThumbNailInferno(dispatch, imageUrls);
            const ProductImageGallery = ProductImageGalleryInferno(dispatch, '', ImageThumbNail, imageUrls);

            const PDPContent = PDPContentInferno(
                null,
                dispatch,
                graph,
                convertToCurrency,
                ProductImageGallery,
                AddToCartStepper,
                BubbleInferno,
                BubbleVariantContainer,
                VariantProgressBarInferno,
                BuyingOptionsWrapper,
                stripHtml,
                ArrowHeadOpenRight,
            );

            const productVariantController = ProductVariantController(graph, dispatch, buildPricingObject);
            const addToCartButtonController = AddToCartButtonController(dispatch);
            const productImageGalleryController = ProductImageGalleryController(dispatch);

            const PDPWrapper = Inferno.createClass({

                getInitialState: function () {
                    //Init controllers
                    this.ProductVariantController = new productVariantController(this, true);
                    this.AddToCartButtonController = new addToCartButtonController(this, data.id);
                    this.ProductImageGalleryController = new productImageGalleryController(this);


                    return {
                        //Initial Product Data
                        currentProduct: data,
                        product: data,
                        selectedProductVariants: [],
                        activeProductVariants: null,
                        variationTypeMeta: data.variationTypeMeta,
                        outOfStockVariations: [],
                        //Add to cart settings
                        displayBuyOptions: data.variationTypeMeta.every((variant) => !!variant.activeValue),
                        disabled: false,
                        openMobileOptions: false,
                        currentQty: 1,
                        //Product Image Gallery Settings
                        activeImgIndex: 0,
                        imgArr: data.images,
                        imgArrLength: data.images.length,
                        featureImage: data.images[0],
                        inPDP: true,
                    };
                },

                componentDidMount() {
                    const {id} = data;
                    this.ProductImageGalleryController.initDispatchers(id);
                    this.ProductVariantController.initDispatchers(id, data);
                    this.AddToCartButtonController.initDispatchers(id);
                    this.ProductVariantController.getActiveVariantsFromUrl(id);
                },

              goBackToPreviousPage(e) {
                e.preventDefault();
                if (document.referrer.includes(window.location.host)) {
                  window.history.back();
                } else {
                  window.location.pathname = '/products';
                }
              },

                render: function () {

                    return (
                        <PDPContent data={this.state.product}
                                    rootClass={root.className}
                                    rootDiv={root}
                                    currentQty={this.state.currentQty}
                                    selectedProductVariants={this.state.selectedProductVariants}
                                    activeProductVariants={this.state.activeProductVariants}
                                    variationTypeMeta={this.state.variationTypeMeta}
                                    outOfStockVariations={this.state.outOfStockVariations}
                                    featureImage={this.state.featureImage}
                                    disableAddToCart={this.state.disabled}
                                    goBackToPreviousPage={ this.goBackToPreviousPage }
                                    imgArr={this.state.imgArr}
                                    activeImgIndex={this.state.activeImgIndex}
                                    openMobileOptions={this.state.openMobileOptions}
                                    handleProductVariantArray={this.ProductVariantController.handleProductVariantArray}
                                    updateVariationTypeMeta={this.ProductVariantController.updateVariationTypeMeta}
                                    checkProductOptionSelection={this.ProductVariantController.checkProductOptionSelection}
                                    displayBuyOptions={this.state.displayBuyOptions}
                                    enableAddToCart={this.ProductVariantController.checkProductOptionSelection(this.state.variationTypeMeta, this.state.selectedProductVariants, data.id)}
                                    toggleMobileOptions={this.ProductVariantController.toggleMobileOptions}
                                    renderProductVariantWidget={this.renderProductVariantWidget}
                                    resetButtonState={this.AddToCartButtonController.resetButtonState}
                                    increaseQuantity={this.AddToCartButtonController.increaseQuantity}
                                    decreaseQuantity={this.AddToCartButtonController.decreaseQuantity}
                                    updateFeatureImage={this.ProductImageGalleryController.updateFeatureImage}
                                    addToCart={this.AddToCartButtonController.addToCart}/>
                    )
                }
            });

            Inferno.render(<PDPWrapper/>, root);

        };

    }

export default Script;
