import React from 'react';
import Component from '../../../../../rootComponent';
import {css} from "@convertly/css";
import Types from '../../../../../attribute-types';

//Styles
import headerDropdownNavLinkstyles from './dropdown-nav-item-styles-1'



export default class HeaderDropdownNavLink1 extends Component {

    constructor(props) {

        super(props);
        this.state = {
            dropDown: false
        };
    }

    render() {

        this.styles = headerDropdownNavLinkstyles(this.props.global, this.props.data);

        return (
            <li className={ css(this.props.styles.dropDownNavItem) }
                key={ this.props.index }
            >
                <a href={ this.props.data.url }
                   className={ css(this.props.styles.dropDownNavItemLink, this.styles.headerDropdownNavLink) }>
                    { this.props.data.text }
                </a>
            </li>
        )
    }
}
