import { connect } from "react-redux";
import { GalleryModal as Component } from "containers/App/modals/GalleryModal";
import { closeModal, updatePhotosFromPexelsSearchQuery } from "../actions/editorActions";

const mapStateToProps = state => {
  const Editor = state.get("editor").editorReducer;
  const App = state.get("global");

  return {
    pageElements: Editor.get("pageElements"),
    imgUrls: Editor.get("imgUrls").toJS(),
    addModal: Editor.get("addModal"),
    overlayZIndex: Editor.get("overlayZIndex"),
    options: Editor.get("options"),
    field: Editor.get("field"),
    site: Editor.get("currentSite"),
    editorType: Editor.get("editorType").toJS(),
    imageFieldSource: Editor.get("imageGalleryModalSource"),
    onSave: Editor.get("imageGalleryCallback"),
    uploading: App.get("uploadingImage"),
    search: Editor.get("photosFromPexelsSearchQuery"),
    images: Editor.get("photosFromPexels"),
    loading: Editor.get("photosFromPexelsLoading"),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    closeModal: closeModal,
    updatePhotosFromPexelsSearchQuery: async searchQuery =>
      await dispatch(updatePhotosFromPexelsSearchQuery(searchQuery)),
  };
};

export const GalleryModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
