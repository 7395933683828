import { store } from "app/app";
import { noop } from "./noop";

/**
 * Helper function to cache container actions.
 * Allows for action to be called with up to 2 payload arguments
 * @param action
 * @param dispatch
 * @returns {function(*=)}
 */
export const fireAction = (action, dispatch = store.dispatch) => async (
  payload,
  payload2,
  payload3
) => await dispatch(action(payload, payload2, payload3));

/**
 *
 * @param handler - callback function
 * @param field - field to be updated
 * @param object - data object to be passed
 * @returns {Function}
 *
 * e is either the event object for the input field OR
 * the updated value
 */
export const inputChangeHandler = (handler, field = null, object = null) => e => {
  let value = e;

  if (e.target && e.target.hasOwnProperty("value")) {
    value = e.target.value;
  }

  field ? handler(field, value, object) : handler(value, object);
};

/**
 *
 * @param handler - callback function
 * @param field - field to be updated
 *
 * e is either the event object for the input field OR
 * the updated value
 */
export const switchChangeHandler = (handler, field) => e => {
  if (e.target && e.target.hasOwnProperty("checked")) {
    return handler(field, e.target.checked);
  }
  handler(field, e);
};

/**
 *
 * @param cb
 * @returns {function(*)}
 */
export const tabChangeHandler = (cb = noop) => opt => cb(opt.key);

/**
 *
 * @param options
 * @param activeOption
 * @returns {*}
 */
export const updateTabOptions = (options, activeOption) =>
  options.map(opt => {
    opt.active = activeOption === opt.key;
    return opt;
  });

/**
 * Generic event handler to fire callback with value.
 * Used primarily in mapped components where the onClick handler needs to be mapped to the current object.
 * Added event object as second argument if needed
 *
 * @param callback
 * @param value
 * @returns {function(): *}
 */
export const eventHandler = (callback = noop, value) => e => callback(value, e);

export const checkboxHandler = ( callback = noop ) => e => callback( e.target.checked );
