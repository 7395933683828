import axios from "axios";
import _ from "lodash";

import { store } from "../app";
import { ADTECH_GRAPH, CONVERTLY_GRAPH, COWMAN_PEXEL } from "app/config";
import { loggly } from "./loggly";
import { LogRocket } from "./logRocket";

import { Notifications } from "../services/Notifications";

// Flag to switch endpoint to graph running on localhost
// Moved flag to process so we can't accidentally deploy with set to true
const local = process.env.GRAPH === "local";
import { mercury as mercuryAPI } from "@convertly/mercury";

export const SERVICES = {
  ADTECH: "adtech",
  STOCK_PHOTOS: "stock-photos",
  MASTER_API: "master-api",
  WIDGETS: "widget",
  SUBSCRIPTIONS: "subscriptions",
  USERS: "users",
  SITES: "sites",
  MAILCHIMP: "mailchimp"
};

const updateSetting = async (field, value) => {
  const query = `
	mutation($field:ID,$value:String) {
		updateSetting(field:$field,value:$value) {
			value
		}
	}
	`;

  const variables = {
    field,
    value: JSON.stringify({ value }),
  };

  const res = await graph({ query, variables });

  let value2 = _.get(res, "updateSetting.value");
  value2 = JSON.parse(value2);
  return _.get(value2, "value");
};

const getSetting = async field => {
  const query = `
	query($field:ID) {
		Setting(field:$field) {
			value
		}
	}
	`;

  const variables = {
    field,
  };

  const res = await graph({ query, variables });

  let value = _.get(res, "Setting.value");
  value = JSON.parse(value);
  return _.get(value, "value");
};

const deleteSetting = async field => {
  const query = `
	mutation($field:ID) {
		deleteSetting(field:$field) {
			id
		}
	}
	`;

  const variables = {
    field,
  };

  await graph({ query, variables });
};

export const Settings = {
  get: getSetting,
  set: updateSetting,
  delete: deleteSetting,
};

/**
 * Fetches from MASTER API, vs mercury which fetches from most other APIS.
 * Helper function for API calls to graph endpoints
 * @param query
 * @param variables
 * @param adtech - Flag for using main endpoint or marketing adtech endpoint
 * @returns {Promise.<void>}
 */
export async function graph({ query, variables }, adtech = false, cowmanPexelFlag = false) {
  variables = variables ? variables : {};

  let url = CONVERTLY_GRAPH;

  if (local) {
    url = "http://localhost:4000/api";
  }

  if (adtech) {
    url = ADTECH_GRAPH;
  }

  if (adtech && local) {
    //url = "http://localhost:5000/graphiql";
    //variables.authToken = variables.authToken || "devBypass";
  }

  if (cowmanPexelFlag) {
    url = COWMAN_PEXEL;
  }

  const result = await fetchGraph({ query, variables, url });

  if (adtech) {
    return result;
  }

  return local ? result.data : result;
}

export const adtech = async args => graph(args, true);

LogRocket.init();
const user = store
  .getState()
  .getIn(["auth", "user"])
  .toJS();
LogRocket.setUser(user);

export const mercury = async ({ query, variables, url, service = "widgets", environment = "production" }) => {
  const authenticationToken = store.getState().getIn(["auth", "authToken"]);

  const options = {
    service: service,
    environment,
    headers: {
      mode: 'cors',
      Authorization: authenticationToken,
    },
  };

  return await mercuryAPI(query, variables, options);
};

export const fetchGraph = ({ query, variables, url }) =>
  new Promise((resolve, reject) => {
    const authenticationToken = store.getState().getIn(["auth", "authToken"]);

    const params = {
      url: url,
      method: "post",
      data: {
        query: query,
        variables: variables,
      },
      headers: {
        Authorization: authenticationToken,
      },
    };

    axios(params)
      .then(async response => {
        const object = response.data;

        if (object.errors || object.error) {
          console.error(object.errors);

          loggly("error", {
            query,
            variables,
            error: object.errors || object.error,
          });

          LogRocket.captureMessage(object.errors || object.error);

          // await Notifications.add({
          //   type: "danger",
          //   title: "Error",
          //   message: object.errors || object.error,
          //   icon: "exclamation",
          // });

          if (local) {
            return resolve({
              data: { Error: true, error: true, message: object.errors || object.error },
            });
          }

          return resolve({ Error: true, error: true, message: object.errors || object.error });
        }

        resolve(object);
      })
      .catch(async err => {
        const errors = _.get(err, "response.data.errors") || [];

        console.error(errors);

        LogRocket.captureMessage(errors);

        // await Notifications.add({
        //   type: "danger",
        //   title: "Error",
        //   message: errors,
        //   icon: "exclamation",
        // });

        resolve({ Error: true, error: true, message: errors });
      });
  });
