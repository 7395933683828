/*global Inferno, $, React */
/** Todo-list-02 active todos **/

const ActiveTodos = (React, dispatch) => {
    return ({activeTodos, rootClass}) => {
        return (
            <div>
                { activeTodos.length > 0 ?
                    activeTodos.map((todo, index) => {
                        return (
                            <div>
                                <div>{todo}</div>
                                <span
                                data-function='removeTodo'>delete</span>
                            </div>
                        )
                    }) :
                    <div>All Caught up!</div>

                }

            </div>
        )
    }

};

export default ActiveTodos;