import {deeplog} from "./deeplog";

var path = require('path')

const base64 = require("base-64");
import {getSite} from "@convertly/utils";
import _ from "underscore";

let count = 0;
const globalImageVersion = 9;

const domains = [
    // 'http://localhost:8000/',
    "//images1.convertly.com/",
    "//images2.convertly.com/",
    "//images3.convertly.com/",
    //'//images4.convertly.com/',
    //'//images5.convertly.com/',
    //'//images6.convertly.com/',
    //'http://d3vzm7h5chjzm6.cloudfront.net/convertlyimguploadeast/c1233d96-5a0e-11e7-907b-a6006ad3dba0/'
];

const images = [];

/**
 * Generates dynamic image URL
 *
 * @param url - full url of image or image filename
 * @param params - object of parameters to be passed to image processor
 * @param id
 * @returns {*}
 */

export function contestImage( {url, contestId} ) {

    return clientImage(url, {}, null, contestId)
}

export function clientImage(url, params = {}, id, contestId) {
    const site = getSite();

    let siteId = (!!site && _.isObject(site) && site.siteId) || null;
    let version;

    if (params.format === "webp" && _.isString(url)) {
        params.originalFiletype = getOriginalFileType(url);
        url = adjustFileFormat(url);
    }

    if (id && id === siteId) {
        version = site.imageVersion || globalImageVersion;
    } else if (!id) {
        version = site.imageVersion || globalImageVersion;
    } else if (id && id !== siteId) {
        // if we get to this block there was a problem
        version = globalImageVersion;
        siteId = id;
    } else {
        // should never get to this block, but just in case
        version = globalImageVersion;
        siteId = id;
    }

    url = url || "";
    if (url.indexOf("http://d3vzm7h5chjzm6.cloudfront.net/convertlyimguploadeast/") === 0) {
        return url.replace("http://d3vzm7h5chjzm6.cloudfront.net/convertlyimguploadeast/", domains[0]);
    }

    // strip out URLS for product API returns
    url = url.replace(/https:\/\/s3.amazonaws.com\/convertlyimguploadeast\/[a-zA-Z0-9\-]+\//g, "");

    if (url.indexOf("http") === 0) {
        return url;
    }

    //Checks for stock image
    if (url.indexOf("B317D7A25DF5B-") !== -1) {
        url = url.replace("B317D7A25DF5B-", "");
        siteId = "convertly-global-images";
    }

    if (_.isString(params)) {
        const Newparams = {};
        Newparams[params.split(":")[0]] = params.split(":")[1];
        params = Newparams;
    }

    params.v = params.version || version;

    const {inEditor} = params;
    if (params.inEditor) {
        delete params.inEditor;
    }

    /**
     * Returned save image results
     */
    const key = siteId + "-" + base64.encode(url + JSON.stringify(params));

    if (images[key] && !contestId) {


            if (inEditor && images[key].indexOf("https") !== 0) {
                return `https:${images[key]}`;
            }

            return images[key];
    }

    let variables = "";

    let fullUrl = "";

    fullUrl += siteId + "/";

    //add contest img string

    if(contestId) {

        fullUrl += `contests/${contestId}/`;
    }
    if(!contestId) {
      fullUrl += base64.encode(JSON.stringify(params)) + "/";
    }


    fullUrl += url;

    fullUrl += variables;

    if (count >= domains.length) {
        count = 0;
    }

    fullUrl = domains[count++] + fullUrl;

    images[key] = fullUrl;


    if (inEditor && fullUrl.indexOf("https") !== 0) {
        return `https:${fullUrl}`;
    }
    return fullUrl;
}

export function globalImage(url, params) {
    const site = getSite();

    if (url) {
        if (url.indexOf("http://d3vzm7h5chjzm6.cloudfront.net/convertlyimguploadeast/") === 0) {
            return url.replace(
                "http://d3vzm7h5chjzm6.cloudfront.net/convertlyimguploadeast/",
                domains[0]
            );
        }

        if (url.indexOf("http") === 0) {
            return url;
        }

        params = params ? params : {};

        let variables = "";

        let fullUrl = domains[count++];

        if (site && site.siteId) {
            fullUrl += site.siteId + "/";
        }

        fullUrl += base64.encode(JSON.stringify(params)) + "/";

        fullUrl += url;

        fullUrl += variables;

        if (count === domains.length) {
            count = 0;
        }

        images[url + JSON.stringify(params)] = fullUrl;


        return "https://images1.convertly.com/convertly-global-images/" + url;
    } else return "";
}

function adjustFileFormat(url) {
    return url.replace(/jpg|jpeg|png|gif/g, "webp");
}

function getOriginalFileType(url) {
    return path.extname(url)
}
