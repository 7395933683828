/* eslint-disable */
//import $ from 'jquery';
//"use strict"
const TestScript = (id) => {

    return (id) => {


    }

};


export default TestScript;
