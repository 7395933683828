import React from 'react';
import Component from '../../rootComponent';
import {css} from "@convertly/css";
import global from 'source/themes/global.js';


export default class LinkedIn extends Component {
    constructor(props) {
        super(props);
    }


    render() {


        /**
         * Assign SVG color based on background
         **/
        let svgBackgroundColor,
            playButtonColor;

        switch (this.props.playButtonColor) {
            case "dark":
                svgBackgroundColor = global.colors.dark.color2;
                playButtonColor = global.colors.light.color2;
                break;
            case "light":
                svgBackgroundColor = global.colors.light.color2;
                playButtonColor = global.colors.dark.color2;
                break;
            default:
                svgBackgroundColor = global.colors.dark.color2;
                playButtonColor = global.colors.light.color2;
        }

        return (

            <svg version="1.1"
                 className={ css(this.props.className) }
                 xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 150 150"
                 width="50"
                 height="50">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Artboard" transform="translate(-565.000000, -285.000000)">
                        <g id="Play" transform="translate(565.000000, 285.000000)">
                            <circle id="Oval-2" fill={ svgBackgroundColor } cx="75" cy="75" r="75"></circle>
                            <g transform="translate(58.000000, 75.000000) rotate(-90.000000) translate(-58.000000, -75.000000) translate(23.500000, 28.500000)">
                                <polygon fill={ playButtonColor } transform="translate(34.308758, 58.695199) rotate(135.000000) translate(-34.308758, -58.695199) " points="10.1119763 47.9980382 58.5055397 34.6264425 45.5825645 82.7639547 10.1119763 82.7639547"></polygon>
                                <rect fill={ svgBackgroundColor } transform="translate(34.011368, 24.656837) rotate(180.000000) translate(-34.011368, -24.656837) " x="9" y="0.142354524" width="50.0227353" height="49.0289652"></rect>
                            </g>
                        </g>
                    </g>
                </g>

            </svg>
        )
    }
}
