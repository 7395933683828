export default function StyleObject(global, data) {
	
	return {
		".progress-bar-wrapper": {
			height: '100%',
			display: 'inline-block',
		},
		".progress-bar-container": {
			width: '20px',
			height: '20px',
			borderRadius: '50px',
			border: '1px solid white',
			background: 'transparent',
			borderColor: '#fff',
			transition:'background 500ms ease',
			".active": {
				background: global.colors.accent.color2,
				borderColor: global.colors.accent.color2
			}
		},
		".vertical-line-container": {
			height: '100%',
			width: '10px',
			margin:'0 auto'
		},
		".vertical-line": {
			width: '1px',
			height:'100%',
			background: '#fff',
			margin:'0 auto',
			transition:'background 1s ease',
			".top": {
				height: '40%',
				background:`linear-gradient(to bottom, ${global.colors.accent.color2} 70%, #fff)`
			},
			".bottom": {
				height: '40%',
				background:`linear-gradient(to top, ${global.colors.accent.color2} 50%, #fff )`
			},
			".active": {
				background:global.colors.accent.color2
			}
		}
		
		
	}
	
	
}