import React from 'react';
import styled from 'styled-components';
import {GLOBALS} from '../../styles/global-default-styles';


export const ListOfSteps = styled.ul`
  display: flex;
  list-style: none;
`;

export const Step = styled.li`
  display: flex;
  margin-right: 10px;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }
`;

export const StepText = styled.p`
  margin-right: 10px;
  text-decoration: none;
  font-size: 18px;

  &.step { 
    color: ${GLOBALS.colorSecondaryLight};
  }

  &.step--active {
    color: ${GLOBALS.colorPrimaryDark};
  }

  &.step--completed {
    cursor: pointer;
    color: ${GLOBALS.colorPrimary};
    text-decoration: underline ${GLOBALS.colorPrimary} solid;
  }
`;
