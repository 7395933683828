////import $ from 'jquery';
//"use strict"
const navDropdownToggle = (id) => {


    return (id) => {

        const el = $('#' + id);
        console.log(el)
        let mobileNav = $('.mobileNav_' + id);
        let mobileNav2 = $('.mobileNav2_' + id);

        if(el) {
            $(el).on('click', addToggleClass)
        }

        if(mobileNav2) {
            $(mobileNav2).on('click', addToggleClass)
        }


        function addToggleClass(e) {
          e.preventDefault()
          $('[data-target="#menu"]').toggleClass('open');
          mobileNav.toggleClass('open');
          mobileNav2.toggleClass('open');
        }
    }

};


export default navDropdownToggle;
