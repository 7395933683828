import React from 'react';
import PropTypes from 'prop-types';
import Component from '../../rootComponent';
import { css } from "@convertly/css";

//Styles
import addressStyles from './address-styles';

class AddressLink extends Component {

    render() {

        /**
         * Calls Stylesheet function
         **/
        this.styles = addressStyles( this.props.global, this.props.data )

        let { city, state, streetAddress, zipCode } = this.props.data

        const inlineStyle = this.generateStyleAttribute();

        return (
			      <address
              { ...inlineStyle }
              { ...this.generateDataEditorAttribute() }
              { ...this.generateCustomAttributes() }
              className={ css( this.props.parentClassName, this.styles.address ) }>

              <span className={ css( this.styles.address) }>{ streetAddress }</span> <br/>

              {
                city && state &&  
                <span>{ city }</span>, <span>{ state }</span>
              }

              <span>{ zipCode }</span>

            </address>
        );
    }
}

/**
 * Connects element to context for cms editor
 **/
AddressLink.contextTypes = {
    store: PropTypes.object
};

export default AddressLink

/**
 Component BoilerPlate

{
    "el": "Elements.AddressLink",
    "data": {
        "city": "San Jose",
        "state": "CA",
        "streetAddress": "500 Frazee Rd.",
        "zipCode": "89123",
        "userOverrides": {

        }
    },
    "children": [],
    "sort", 900
}

 **/
