/**
 * All UI components for the Crop Tool are included in this file minus styled components
 * Uses interact library for dragging
 *
 * Crop Tool is fairly robust with only one known issue
 *
 * Issues:
 * Uses clip-path to clip the 'cropped' image.  This does not work on Edge. Solution for IE/Edge
 * is not known yet.  More research is needed on how to create the UI effect in IE/Edge.
 *
 */

import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import interact from "interactjs";
import { findDOMNode } from "react-dom";
import { fromJS } from "immutable";

import { clientImage } from "source/utils/generateImage";

import CropCustom from "components/icons/imgedit_crop_custom.svg";
import Crop32 from "components/icons/imgedit_crop_3_2.svg";
import Crop43 from "components/icons/imgedit_crop_4_3.svg";
import Crop169 from "components/icons/imgedit_crop_16_9.svg";
import CropSquare from "components/icons/imgedit_crop_square.svg";
import cropHandle from "components/icons/icon_crop_handle.svg";

import {
  ImageEditingPanel,
  ToolsContainer,
  ToolContainer,
  ImageContainer,
  ToolLabel
} from "./css-image-editor";

/**
 * Helper function since we use this a few times in our calculations
 * Could have written it as own function but decided to add it to Math for fun.
 * @param r
 */
Math.square = r => r * r;

/**
 * We store the aspect ratios as arrays so we can easily reference them in our calculations
 * Is also easy to check if in custom mode because ratio[0] is falsey
 */
const cropTools = [
  {
    label: "Custom",
    icon: CropCustom,
    ratio: [0, 0]
  },
  {
    label: "Square",
    icon: CropSquare,
    ratio: [1, 1]
  },
  {
    label: "3:2",
    icon: Crop32,
    ratio: [3, 2]
  },
  {
    label: "4:3",
    icon: Crop43,
    ratio: [4, 3]
  },
  {
    label: "16:9",
    icon: Crop169,
    ratio: [16, 9]
  }
];

/**
 * Anchor Point UI component
 * @TODO Update cursor when dragging
 */

class Handle extends React.Component {
  componentDidMount() {
    interact(findDOMNode(this.ref)).draggable({
      onmove: this.props.onMove.bind(this, this.props.pos, this.ref),
      onstart: this.props.onStart.bind(this, this.props.pos),
      onend: this.props.onEnd.bind(this, this.props.pos)
    });
  }

  render() {
    return (
      <img
        ref={el => (this.ref = el)}
        src={cropHandle}
        style={{
          width: "24px",
          height: "24px",
          position: "absolute",
          zIndex: "100",
          transform: `rotate(${this.props.rotate}deg)`,
          top: this.props.offset.top - 12,
          left: this.props.offset.left - 12,
          cursor: "pointer"
        }}
      />
    );
  }
}

Handle.defaultProps = {
  onMove: () => {},
  onStart: () => {},
  onEnd: () => {},
  rotate: "0",
  offset: {
    top: 0,
    left: 0,
    dragging: false
  }
};

/**
 * Full opacity image UI component. Uses clip-path css style. This will not work on IE/Edge
 * After researching the needed effect didn't find an easy way to do it in Edge
 */

class ImgResponsive extends React.Component {
  render() {
    const { offsets } = this.props;
    const { defaults, initialized } = offsets;

    const top = offsets.topLeft.top - defaults.topLeft.top;
    const left = offsets.topLeft.left - defaults.topLeft.left;
    const right = defaults.topRight.left - offsets.topRight.left;
    const bottom = defaults.bottomLeft.top - offsets.bottomLeft.top;

    let clip = `inset( ${top}px ${right}px ${bottom}px ${left}px )`;

    return (
      <img
        ref={el => (this.el = el)}
        style={{
          opacity: initialized ? 1 : 0,
          maxWidth: "100%",
          maxHeight: "100%",
          clipPath: clip
        }}
        src={this.props.src}
      />
    );
  }
}

ImgResponsive.defaultProps = {
  offsets: {},
  src: ""
};

/**
 * Dashed border UI component. Also handles dragging the entire 'cropped' image
 */

class PanHandle extends React.Component {
  componentDidMount() {
    interact(findDOMNode(this.el)).draggable({
      onmove: this.props.onMove,
      onstart: this.props.onStart,
      onend: this.props.onEnd
    });
  }

  render() {
    const { offsets } = this.props;

    const width = offsets.topRight.left - offsets.topLeft.left;
    const height = offsets.bottomLeft.top - offsets.topLeft.top;
    const top = offsets.topLeft.top;
    const left = offsets.topLeft.left;

    const styles = {
      border: "2px dashed green",
      position: "absolute",
      width,
      height,
      top,
      left
    };

    return <div style={styles} ref={el => (this.el = el)} />;
  }
}

PanHandle.defaultProps = {
  onMove: () => {},
  onStart: () => {},
  onEnd: () => {},
  offsets: {}
};

/**
 * This is the main component for the Crop Panel
 */

export class CropPanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
      imageLoaded: false,
      ratio: [0, 0],
      topLeft: {
        top: 0,
        left: 0
      },
      topRight: {
        top: 0,
        left: 0
      },
      bottomRight: {
        top: 0,
        left: 0
      },
      bottomLeft: {
        top: 0,
        left: 0
      },
      defaults: {
        topLeft: {
          top: 0,
          left: 0
        },
        topRight: {
          top: 0,
          left: 0
        },
        bottomRight: {
          top: 0,
          left: 0
        },
        bottomLeft: {
          top: 0,
          left: 0
        }
      },
      image: {
        width: 0,
        height: 0,
        top: 0,
        left: 0
      }
    };

    this.changeAspectRatio = this.changeAspectRatio.bind(this);
    this.onDragHandle = this.onDragHandle.bind(this);
    this.onStartDragHandle = this.onStartDragHandle.bind(this);
    this.onEndDragHandle = this.onEndDragHandle.bind(this);
    this.onDragPanHandle = this.onDragPanHandle.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.onError = this.onError.bind(this);
  }

  /**
   * Preloads the image source. The calculations for anchor points, etc need to be done after
   * the image has been loaded so we can get the dimensions and position of the image element
   */
  componentDidMount() {
    const { image, siteId, params } = this.props;

    const src = clientImage(image, this.getImageParams(params), siteId);

    this.Image = new Image();
    this.Image.onload = this.onLoad;
    this.Image.onerror = this.onError;
    this.Image.src = src;
  }

  /**
   * The trigger prop is incremented when the reset or reset all button is pressed
   *
   * @param nextProps
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.trigger !== this.props.trigger) {
      this.props.update("crop", "");
      this.props.saveTempParam("crop", false);
      this.resetAnchorPoints();
    }
  }

  /**
   * Checks if the image pre load flag has switched. If so, we know that the image has been loaded and rendered.
   * We can now perform the calculations for image position, width, height, etc.
   *
   * @param prevProps
   * @param prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.state.imageLoaded && !prevState.imageLoaded) {
      // If we change the image editing tool and come back to crop
      // we use the savedCrop object to return the UI to the state when
      // the user left
      const savedCrop = _.get(this.props, "tempParams.crop") || false;
      const savedAspectRatio = _.get(savedCrop, "ratio") || false;

      const params = this.generateParams(savedCrop);
      const defaults = this.generateParams(false);

      // Uses JSON parse/stringify to ensure there are no object references
      const stateParams = {
        ...JSON.parse(JSON.stringify(params)),
        defaults: { ...JSON.parse(JSON.stringify(defaults)) },
        image: {
          width: this.imageRef.el.offsetWidth,
          height: this.imageRef.el.offsetHeight,
          top: this.imageRef.el.offsetTop,
          left: this.imageRef.el.offsetLeft,
          naturalWidth: this.imageRef.el.naturalWidth,
          naturalHeight: this.imageRef.el.naturalHeight
        },
        initialized: true
      };

      if (savedAspectRatio && _.isArray(savedAspectRatio)) {
        stateParams.ratio = savedAspectRatio;
      }

      this.setState(stateParams);
    }
  }

  /**
   * Resets the anchor points to full size and ratio to custom
   */
  resetAnchorPoints() {
    const { state } = this;
    const { defaults } = state;

    state.topLeft.top = defaults.topLeft.top;
    state.topLeft.left = defaults.topLeft.left;

    state.topRight.top = defaults.topRight.top;
    state.topRight.left = defaults.topRight.left;

    state.bottomRight.top = defaults.bottomRight.top;
    state.bottomRight.left = defaults.bottomRight.left;

    state.bottomLeft.top = defaults.bottomLeft.top;
    state.bottomLeft.left = defaults.bottomLeft.left;

    state.ratio = [0, 0];

    this.setState(state, this.update);
  }

  /**
   * Helper function to get the image source that we should render
   *
   * @param params
   * @returns {any | *}
   */
  getImageParams(params) {
    return params
      .delete("width")
      .delete("crop")
      .toJS();
  }

  /**
   * Helper function to get a params object since we run this twice
   * Once for the anchor points, and once to get the defaults ( full size )
   *
   * @param savedCrop
   * @returns {{topLeft: {top: (Number|number), left: (Number|number|DOMNodePreview.htmlCloseTag.offsetLeft|{marginLeft}), dragging: boolean}, topRight: {top: (Number|number), left: *, dragging: boolean}, bottomRight: {top: *, left: *, dragging: boolean}, bottomLeft: {top: *, left: (Number|number|DOMNodePreview.htmlCloseTag.offsetLeft|{marginLeft}), dragging: boolean}}}
   */
  generateParams(savedCrop) {
    let savedTop;
    let savedLeft;
    let savedRight;
    let savedBottom;

    if (savedCrop) {
      savedLeft = savedCrop.x;
      savedTop = savedCrop.y;
      savedBottom = savedCrop.y + savedCrop.height;
      savedRight = savedCrop.x + savedCrop.width;
    }

    const imageTop = this.imageRef.el.offsetTop;
    const imageLeft = this.imageRef.el.offsetLeft;
    const imageWidth = this.imageRef.el.offsetWidth;
    const imageHeight = this.imageRef.el.offsetHeight;

    return {
      topLeft: {
        top: savedCrop ? savedTop : imageTop,
        left: savedCrop ? savedLeft : imageLeft,
        dragging: false
      },
      topRight: {
        top: savedCrop ? savedTop : imageTop,
        left: savedCrop ? savedRight : imageLeft + imageWidth,
        dragging: false
      },
      bottomRight: {
        top: savedCrop ? savedBottom : imageTop + imageHeight,
        left: savedCrop ? savedRight : imageLeft + imageWidth,
        dragging: false
      },
      bottomLeft: {
        top: savedCrop ? savedBottom : imageTop + imageHeight,
        left: savedCrop ? savedLeft : imageLeft,
        dragging: false
      }
    };
  }

  /**
   * Callback for pre loading image
   */
  onLoad() {
    this.setState({ imageLoaded: true });
  }

  /**
   * Error callback for pre loading image
   */
  onError() {
    const { image } = this.props;
    alert(`Error loading image ${image}`);
  }

  /**
   * start drag event handler for Handle components
   *
   * @param pos
   */
  onStartDragHandle(pos) {
    const { state } = this;
    state[pos].dragging = true;
    this.setState(state);
  }

  /**
   * end drag event handler for Handle components
   *
   * @param pos
   */
  onEndDragHandle(pos) {
    const { state } = this;
    state[pos].dragging = false;
    this.setState(state);
  }

  /**
   * Calculate new positions of anchor points
   * Keeps static anchor point kiddie corner from dragging anchor point
   *
   * @param pos
   * @param el
   * @param dx
   * @param dy
   * @param clientX
   * @param clientY
   */
  onDragHandle(pos, el, { dx, dy, clientX, clientY }) {
    let width;
    let height;
    let newTop;
    let newLeft;
    let newRight;
    let newBottom;

    const { state } = this;
    const { defaults } = state;
    const xRatio = state.ratio[0];

    // @TODO could use this to not update while mouse is outside bounds of image
    const { left, right } = el.getBoundingClientRect();

    state[pos].top = state[pos].top + dy;
    state[pos].left = state[pos].left + dx;

    // There's a lot going on here.
    //
    // First we check if the new position is outside of the bounds of the defaults
    // which is the full size
    //
    // Next we adjust the corresponding handles to keep the shape

    switch (pos) {
      case "topLeft":
        if (defaults.topLeft.left > state.topLeft.left) {
          state.topLeft.left = defaults.topLeft.left;
        }

        if (state.topLeft.top < defaults.topLeft.top) {
          state.topLeft.top = defaults.topLeft.top;
        }

        if (!xRatio) {
          state.topRight.top = state.topLeft.top;
          state.bottomLeft.left = state.topLeft.left;
        } else {
          width = state.bottomRight.left - state.topLeft.left;
          height = this.getHeight(width);

          newTop = state.bottomRight.top - height;
          newLeft = state.bottomRight.left - width;

          if (newTop < defaults.topLeft.top) {
            newTop = defaults.topLeft.top;
            height = state.bottomRight.top - newTop;
            width = this.getWidth(height);
            newLeft = state.bottomRight.left - width;
          }

          state.topLeft.top = newTop;
          state.topLeft.left = newLeft;

          state.topRight.top = newTop;

          state.bottomLeft.left = newLeft;
        }

        break;

      case "topRight":
        if (state.topRight.left > defaults.topRight.left) {
          state.topRight.left = defaults.topRight.left;
        }

        if (state.topRight.top < defaults.topRight.top) {
          state.topRight.top = defaults.topRight.top;
        }

        if (!xRatio) {
          state.topLeft.top = state.topRight.top;
          state.bottomRight.left = state.topRight.left;
        } else {
          width = state.topRight.left - state.bottomLeft.left;
          height = this.getHeight(width);

          newTop = state.bottomLeft.top - height;
          newRight = state.bottomLeft.left + width;

          if (newTop < defaults.topLeft.top) {
            newTop = defaults.topLeft.top;
            height = state.bottomLeft.top - newTop;
            width = this.getWidth(height);
            newRight = state.bottomLeft.left + width;
          }

          state.topLeft.top = newTop;

          state.topRight.top = newTop;
          state.topRight.left = newRight;

          state.bottomRight.left = newRight;
        }

        break;

      case "bottomRight":
        if (state.bottomRight.left > defaults.bottomRight.left) {
          state.bottomRight.left = defaults.bottomRight.left;
        }

        if (state.bottomRight.top > defaults.bottomRight.top) {
          state.bottomRight.top = defaults.bottomRight.top;
        }

        if (!xRatio) {
          state.bottomLeft.top = state.bottomRight.top;
          state.topRight.left = state.bottomRight.left;
        } else {
          width = state.bottomRight.left - state.topLeft.left;
          height = this.getHeight(width);
          newBottom = state.topLeft.top + height;
          newRight = state.topLeft.left + width;

          if (newBottom > defaults.bottomRight.top) {
            newBottom = defaults.bottomRight.top;
            height = newBottom - state.topLeft.top;
            width = this.getWidth(height);
            newRight = state.topLeft.left + width;
          }

          state.topRight.left = newRight;

          state.bottomRight.left = newRight;
          state.bottomRight.top = newBottom;

          state.bottomLeft.top = newBottom;
        }

        break;

      case "bottomLeft":
        if (state.bottomLeft.top > defaults.bottomLeft.top) {
          state.bottomLeft.top = defaults.bottomLeft.top;
        }

        if (defaults.bottomLeft.left > state.bottomLeft.left) {
          state.bottomLeft.left = defaults.bottomLeft.left;
        }

        if (!xRatio) {
          state.bottomRight.top = state.bottomLeft.top;
          state.topLeft.left = state.bottomLeft.left;
        } else {
          width = state.topRight.left - state.bottomLeft.left;
          height = this.getHeight(width);
          newLeft = state.topRight.left - width;
          newBottom = state.topRight.top + height;

          if (newBottom > defaults.bottomLeft.top) {
            newBottom = defaults.bottomLeft.top;
            height = newBottom - state.topRight.top;
            width = this.getWidth(height);
            newLeft = state.topRight.left - width;
          }

          state.topLeft.left = newLeft;

          state.bottomRight.top = newBottom;

          state.bottomLeft.left = newLeft;
          state.bottomLeft.top = newBottom;
        }

        break;
    }

    this.setState(state, this.update);
  }

  /**
   * Calculates height from width with the given ratio constraint
   * Uses pythagoras theorem
   *
   * @param width
   */
  getHeight(width) {
    const { state } = this;
    const xRatio = state.ratio[0];
    const yRatio = state.ratio[1];
    const ratio = yRatio / xRatio;

    const c = Math.sqrt(Math.square(width) + Math.square(ratio * width));
    return Math.sqrt(Math.square(c) - Math.square(width));
  }

  /**
   * Same as getHeight but we swap the ratio
   *
   * @param height
   */
  getWidth(height) {
    const { state } = this;
    const xRatio = state.ratio[0];
    const yRatio = state.ratio[1];
    const ratio = xRatio / yRatio;

    const c = Math.sqrt(Math.square(height) + Math.square(ratio * height));
    return Math.sqrt(Math.square(c) - Math.square(height));
  }

  /**
   * Event handler for updating aspect ratio from UI buttons.
   *
   * @param index
   */
  changeAspectRatio(index) {
    this.setState(
      {
        ratio: cropTools[index].ratio
      },
      this.updateViewFromNewRatio
    );
  }

  /**
   * Calculates new params when aspect ratio is changed
   */
  updateViewFromNewRatio() {
    const { state } = this;
    const { defaults } = state;

    // If switching to custom we do not need to update
    if (!state.ratio[0]) {
      return;
    }

    // When switching ratios we use topLeft as our anchor point
    let width = state.topRight.left - state.topLeft.left;
    let height = this.getHeight(width);
    const newTop = state.topLeft.top + height;

    // check if the new shape height will be beyond the area of the image
    // if so calculate the new height so it goes just to the border
    if (newTop > defaults.bottomLeft.top) {
      height = defaults.bottomLeft.top - state.topLeft.top;
      width = this.getWidth(height);
    }

    state.topRight.left = state.topLeft.left + width;

    state.bottomRight.top = state.topLeft.top + height;
    state.bottomRight.left = state.topLeft.left + width;

    state.bottomLeft.top = state.topLeft.top + height;

    this.setState(state, this.update);
  }

  /**
   * on drag handler for PanHandle component
   * @TODO Left clientX and clientY as arguments if want to constrain dragging to when mouse
   * is over the UI component
   *
   * @param dx
   * @param dy
   * @param clientX
   * @param clientY
   */
  onDragPanHandle({ dx, dy, clientX, clientY }) {
    const { state } = this;
    const { defaults, topLeft, topRight, bottomRight } = state;

    if (topRight.left <= defaults.topRight.left && topLeft.left >= defaults.topLeft.left) {
      if (topRight.left + dx > defaults.topRight.left) {
        dx = defaults.topRight.left - topRight.left;
      }

      if (topLeft.left + dx < defaults.topLeft.left) {
        dx = defaults.topLeft.left - topLeft.left;
      }

      state.topLeft.left += dx;
      state.bottomLeft.left += dx;
      state.topRight.left += dx;
      state.bottomRight.left += dx;
    }

    if (topRight.top >= defaults.topRight.top && bottomRight.top <= defaults.bottomRight.top) {
      if (bottomRight.top + dy > defaults.bottomRight.top) {
        dy = defaults.bottomRight.top - bottomRight.top;
      }

      if (topLeft.top + dy < defaults.topLeft.top) {
        dy = defaults.topLeft.top - topLeft.top;
      }

      state.topLeft.top += dy;
      state.bottomLeft.top += dy;
      state.topRight.top += dy;
      state.bottomRight.top += dy;
    }

    this.setState(state, this.update);
  }

  /**
   * Save current params for Image Editor
   */
  update() {
    const { state } = this;
    const { defaults, topLeft, bottomRight, image } = state;

    const widthRatio = image.naturalWidth / image.width;
    const heightRatio = image.naturalHeight / image.height;

    const boxWidth = bottomRight.left - topLeft.left;
    const boxHeight = bottomRight.top - topLeft.top;

    let x = (topLeft.left - defaults.topLeft.left) * widthRatio;
    let y = (topLeft.top - defaults.topLeft.top) * heightRatio;
    let width = (bottomRight.left - topLeft.left) * widthRatio;
    let height = (bottomRight.top - topLeft.top) * heightRatio;

    this.props.update(
      "crop",
      `${x.toFixed(0)},${y.toFixed(0)},${width.toFixed(0)},${height.toFixed(0)}`
    );
    this.props.saveTempParam("crop", {
      x: topLeft.left,
      y: topLeft.top,
      width: boxWidth,
      height: boxHeight,
      ratio: state.ratio
    });
  }

  render() {
    const { image, siteId, params } = this.props;
    const { initialized } = this.state;
    const src = clientImage(image, this.getImageParams(params), siteId);

    return (
      <ImageEditingPanel id="image-editing-panel">
        <ImageContainer id="image-container">
          {initialized && (
            <div>
              <Handle
                pos="topLeft"
                onStart={this.onStartDragHandle}
                onMove={this.onDragHandle}
                onEnd={this.onEndDragHandle}
                offset={this.state.topLeft}
              />
              <Handle
                pos="topRight"
                onStart={this.onStartDragHandle}
                onMove={this.onDragHandle}
                onEnd={this.onEndDragHandle}
                rotate="90"
                offset={this.state.topRight}
              />
              <Handle
                pos="bottomRight"
                onStart={this.onStartDragHandle}
                onMove={this.onDragHandle}
                onEnd={this.onEndDragHandle}
                offset={this.state.bottomRight}
              />
              <Handle
                pos="bottomLeft"
                onStart={this.onStartDragHandle}
                onMove={this.onDragHandle}
                onEnd={this.onEndDragHandle}
                rotate="-90"
                offset={this.state.bottomLeft}
              />
            </div>
          )}

          <ImgResponsive ref={ref => (this.imageRef = ref)} src={src} offsets={this.state} />

          {initialized && (
            <img
              style={{
                position: "absolute",
                width: this.state.image.width,
                height: this.state.image.height,
                top: this.state.image.top,
                left: this.state.image.left,
                opacity: ".25",
                userSelect: "none",
                WebkitUserDrag: "none"
              }}
              src={src}
            />
          )}

          {initialized && <PanHandle onMove={this.onDragPanHandle} offsets={this.state} />}
        </ImageContainer>

        <ToolsContainer>
          {cropTools.map((tool, index) => {
            return (
              <ToolContainer
                key={index + "tools"}
                selected={fromJS(cropTools[index].ratio).equals(fromJS(this.state.ratio))}
                onClick={this.changeAspectRatio.bind(this, index)}
              >
                <img src={tool.icon} height="24" />
                <ToolLabel>{tool.label}</ToolLabel>
              </ToolContainer>
            );
          })}
        </ToolsContainer>
      </ImageEditingPanel>
    );
  }
}

CropPanel.defaultProps = {
  image: "",
  params: {},
  siteId: "0",
  trigger: 0
};

CropPanel.propTypes = {
  image: PropTypes.string,
  params: PropTypes.object,
  siteId: PropTypes.string,
  trigger: PropTypes.number
};
