import { StyleSheet } from '@convertly/css';

const footerStyles = ( global, data ) => {

    return StyleSheet.create({
        mainFooter: {
            minHeight:'200px',
            marginTop:'80px',
            textAlign:'center',
            fontFamily:global.fontFamily.primaryFont,
            background:global.bodyBackgroundColor,

        },
        logo: {
            maxWidth:'200px',
            maxHeight:'35px',
            width: "auto",
            display:'inline-block',
            verticalAlign:'sub',
            marginRight:'5px'
        },
        logoImg: {
            maxHeight:'35px',
            width: "auto",
            position: "relative",
            top: "5px",
            "@media (max-width: 401px)": {
                display: 'block',
                margin: "0px auto 25px"
            }
        },
        /** Social Icons **/
        socialIconsContainer: {
            borderLeft:"1px solid",
            padding:'10px',
            borderColor:global.colors.dark.color3,
            marginLeft:'10px',
            display:'inline-block',
            '@media( max-width:401px )': {
                border:'none'
            }
        },
        socialIconContainer: {
            background: global.colors.dark.color3,
            borderRadius: '100%',
            height: '26px',
            padding: '3px',
            width: '26px',
            ":hover": {
                opacity: '.7'
            }
        },
        socialIcon: {
            fill:global.colors.light.white,
            padding:'2px',
            height:'20px',
            width:'20px'
        },

        footerText: {
            margin:'20px',
            color:global.colors.dark.color3
        },
        footerCopyText: {
            marginBottom:'50px'
        },
        copyRightText: {
            display:'inline-block',
            //borderRight:'1px solid',
            borderColor:global.colors.dark.color3,
            color:global.colors.dark.color3,
            paddingRight:'12px',
            fontSize:'12px',
            '@media( max-width: 768px)': {
                border:'none'
            },

        },
        footerNavLinks: {
            display:'inline-block',
            listStyleType:'none'
        },
        copyRightLinks: {
            marginLeft:'5px',
            display:'inline-block',
            color:global.colors.dark.color3,
            fontSize:'12px',
            ":before": {
                content: '" | "'
            },
            ':first-child': {
                ":before": {
                    content: '""'
                },
            },
        }


    })
};


export default footerStyles;
