/*global $, document */
import $ from "jquery";
import { include } from "@convertly/thor";

export const showModalBasedOffCookie = id => {
  const Cookie = include("../../../utils/cookie", "Cookie");

  return (id, data) => {
    const parsedData = JSON.parse(data);
    let cookieName = parsedData.cookieName;

    if (!parsedData.cookieName) {
      cookieName = `convertly_modal_launcher`;
    }

    const USERSUCCESSFULLYCOMPLETED = "user-successfully-completed";
    const USEROPTEDOUT = "user-opted-out";
    const USERHASSEEN = "user-has-seen";

    const cookie = Cookie();

    // Get specific cookie for modal launcher
    const modalLauncher = cookie.read({
      name: cookieName,
    });

    /**
     *  If the cookie does not exist. Add listeners and handle.
     *  Else remove the modal launcher.
     */

    const $modalLauncher = $(`#modal-launcher-02-${id}`);

    if (!modalLauncher) {
      /**
       * Add cookie after buttons are clicked
       * Expiration dependent on data from JSON
       *    {
       *      data: {
       *        daysUntilExpireOnSubmission: Number
       *        daysUntilExpireOnOptOut: Number
       *        daysUntilExpireOnHasSeen: Number
       *      }
       *    }
       *
       * **NOTE**
       * Remember to add these data attributes.
       * This allows the creator to attach it to whatever they want inside of the modal launcher
       * wrapper.
       */
      $modalLauncher
        .find(`[data-cookie-submit]`)
        .click(
          setCookie(
            cookie,
            cookieName,
            USERSUCCESSFULLYCOMPLETED,
            parsedData.daysUntilExpireOnSubmission,
          ),
        );

      $modalLauncher
        .find(`[data-cookie-opt-out]`)
        .click(setCookie(cookie, cookieName, USEROPTEDOUT, parsedData.daysUntilExpireOnOptOut));

      $modalLauncher
        .find(`[data-cookie-close-modal]`)
        .click(setCookie(cookie, cookieName, USERHASSEEN, parsedData.daysUntilExpireOnHasSeen));
    } else {
      const $parentNode = $modalLauncher.parent();
      $parentNode.remove($modalLauncher);
    }

    function setCookie(cookie, cookieName, cookieVal, daysUntilExpiration) {
      return () => {
        const userSetExpiration = daysUntilExpiration !== undefined;

        /**
         * Sets default timer if user did not define its
         *  1. user-has-seen: 1 day
         *  2. user-opted-out: 7 days
         *  3. user-successfully-completed: indefinite
         */

        let defaultDaysUntilExpiration;

        if (!userSetExpiration) {
          switch (cookieVal) {
            case USERHASSEEN:
              defaultDaysUntilExpiration = 1;
              break;
            case USEROPTEDOUT:
              defaultDaysUntilExpiration = 7;
              break;
            case USERSUCCESSFULLYCOMPLETED:
              defaultDaysUntilExpiration = false;
              break;
          }
        }

        cookie.create({
          name: cookieName,
          value: cookieVal,
          expires: userSetExpiration ? daysUntilExpiration : defaultDaysUntilExpiration,
        });
      };
    }
  };
};
