////import $ from 'jquery';
//"use strict"
const activeNavLink = (id) => {

    return (id) => {

        const $root = $(`#${id}`);
        let $headerHeight = $( "header" ).height( );


        $( document ).on( 'scroll', function( e ) {

            let $scroll = $(window).scrollTop( );

            if( $scroll < $headerHeight && !$root.hasClass( 'closed' ) ) {
                $root.addClass( 'closed' )


            } else if( $scroll > $headerHeight && $root.hasClass( 'closed' ) ) {

                $root.removeClass( 'closed' )
            }
        } );

    }

};


export default activeNavLink;
