import {generateBlogLink} from "../util/generate-blog-link";

const regeneratorRuntime = require("regenerator-runtime");
import { frontloadConnect } from "@convertly/react-frontload";
import React from "react";
import Component from "source/rootComponent";
import { css } from "@convertly/css";
import _ from "lodash";

// Components
import Elements from "source/elements";

// Style
import {AuthorListStyles} from "./AuthorListStyles";

// Utils
import { clientImage } from "../../utils/generateImage";
import { doQuery } from "../../utils/doQuery";
import { frontLoadOptions } from "../../utils/frontLoadOptions";

let authorList = [];
let loaded = false;

const fetchAuthorList = (props, component) => {
  return new Promise(async (resolve, reject) => {
    if (loaded && _.get(component, "props.inEditor")) {
      return resolve();
    }


    const siteId = _.get(props, "site.siteId");

    const query = props.inEditor ? `
    query {
      BlogAuthors:DummyBlogAuthors {
          avatar {
            src
            alt
            title
          }
          name
          url
        }
      }
    ` : `
      query getAuthors($siteId:ID!) {
        BlogAuthors(siteId:$siteId) {
          avatar {
            src
            alt
            title
          }
          name
          url
        }
      }`;

    const variables = {
      siteId
    };

    const data = await doQuery({query, variables});
    authorList[props.id] = _.get( data.data, 'BlogAuthors' ) || [];
    loaded = true;
    resolve();
  });
};

const frontload = async props => fetchAuthorList(props);

class AuthorList extends Component {
  componentWillMount() {
    if (!loaded) {
      return fetchAuthorList(this.props, this);
    }
  }

  render() {
    const { data, inEditor, global } = this.props,
          { headingText, displayHeadingText } = data || {},
          inlineStyle = this.generateStyleAttribute();

    this.styles = AuthorListStyles({ global, data, inEditor });
    const { authorListStyle, authorAvatar, authorName, authorWrapper } = this.styles;

    if (!authorList[this.props.id] || authorList[this.props.id].length === 0 || !Array.isArray(authorList[this.props.id])) {
      return null;
    }

    return (
      <section
        {...inlineStyle}
        {...this.generateDataEditorAttribute()}
        {...this.generateCustomAttributes()}
        className={css(authorListStyle)}
      >
        {displayHeadingText !== false && (
          <Elements.Heading4
            data={{
              text: _.isString(headingText)
                ? headingText
                : authorList[this.props.id].length > 1
                ? "Authors"
                : "Author",
              userOverrides: _.get(data, "userOverrides.headingText.userOverrides" || {})
            }}
          />
        )}

        {authorList[this.props.id].map((author, idx) => {
          const { avatar, name, url } = author || {};

          if (!avatar && !name) {
            return null;
          }

          const { src, alt, title, imgParams } = avatar || {};

          return (
            <div key={`author-${idx}`} className={css(authorWrapper)}>
              {src && (
                <a href={ generateBlogLink(this.props.page.blogLink, url, inEditor) }>
                  <Elements.Image
                      data={{
                        src,
                        imgParams,
                        alt,
                        title
                      }}
                      parentClass={css(authorAvatar)}
                  />
                </a>
              )}

              {name && (
                <a className={css(authorName)} href={generateBlogLink(this.props.page.blogLink, url, inEditor) }>
                  {name}
                </a>
              )}
            </div>
          );
        })}
      </section>
    );
  }
}

module.exports = {
  AuthorList: frontloadConnect(frontload, frontLoadOptions)(AuthorList)
};
