import React from 'react';
import PropTypes from 'prop-types';

const TYPES = {
	STRING: 'STRING',
	TEXT: 'TEXT',
	IMAGE: 'IMAGE',
	COLOR: 'COLOR',
	BUTTON: 'BUTTON',
	LINK: 'LINK',
	BOOL: 'BOOL',
};

const Types = {
    String : {
    	type: TYPES.STRING,
		attributes: {
			text: PropTypes.string,
			align: PropTypes.string,
			fontSize: PropTypes.string,
			color: TYPES.COLOR,
			fontFamily: PropTypes.string,
			textEditorSize: PropTypes.number,
		},
		editor: 'TextArea'
	},
	Bool : {
    	type: TYPES.BOOL,
		attributes: {
    		bool: PropTypes.bool
		},
		editor: 'CheckBox'
	},
    Image : {
		type: TYPES.IMAGE,
		attributes: {
			src: PropTypes.string,
			alt: PropTypes.string,
			filter: PropTypes.number
		},
	},
    Color : {
		type: TYPES.COLOR,
		attributes: {
			rgba: PropTypes.string
		},
		editor: 'colorPicker'
	},
	Link : {
    	type: TYPES.STRING,
		attributes: {
			text: TYPES.STRING,
			url: PropTypes.string,
            target: PropTypes.bool
		},
	},
	Module : {
    	type: TYPES.MODULE,
		attributes: {
    		backgroundColor: TYPES.COLOR,
			color: TYPES.COLOR,
		}
	},
	Button : {
    	type: TYPES.BUTTON,
		attributes: {
			link: TYPES.LINK,
            borderColor: TYPES.COLOR,
			backgroundColor: TYPES.COLOR,
		},
		editor: "buttonEditor"
	}

};

export default Types;
