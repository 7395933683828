//import $ from 'jquery';
import {include} from '@convertly/thor';

const sliderScript = (id) => {

    return (id) => {


        (function () {
            /*
             Basic variables and dom selectors.
             */
            let convertlySlider = $(`#${id}`);
            let rootSelector = `#${id}`;
            let sliderRoot = $('.slides', `#${id}`);
            let slides = $('.slides > li', rootSelector);
            let controlNav = $('.controlNav', "#" + id);
            let transitionType = sliderRoot[0].dataset.effect;
            let c = 0;
            let autoScroll = sliderRoot[0].dataset.autoscroll === "true";
            let hoverpause = sliderRoot[0].dataset.hoverpause === "true";
            initProgressBar(controlNav[0]);


            /*
             Sets a timer function based on if auto scroll is set to on, and the timer gets a value
             this will have the slide go to the next slide.
             */
            let autoScrollTimer;


            function autoScrollTimerInit() {

                if (autoScroll) {

                    autoScrollTimer = setTimeout(function () {

                            if (c < slides.length - 1) {
                                ++c;
                                changeSlide(c);
                            }
                            else {
                                c = 0;
                                changeSlide(c);
                            }
                        }, sliderRoot[0].dataset.timer
                    );
                }
            }

            autoScrollTimerInit();


            if (hoverpause) {
                convertlySlider.on('mouseenter', function () {
                    $(".progress", rootSelector).css("-webkit-animation-play-state", "paused");
                    clearInterval(autoScrollTimer);
                }).on('mouseleave', function () {
                    $(".progress", rootSelector).css("-webkit-animation-play-state", "initial");
                    autoScrollTimerInit();
                });
            }

            /*
             This is for the control buttons on the slider
             */


            controlNav.on('click', function (e) {
                e.preventDefault;
                let selector = this.dataset.target;

                c = selector;


                changeSlide(c);
                reset();
            });

            /*
             Event listeners for next and
             */
            $('.next', rootSelector).on('click', function () {
                reset();
                next()
            });
            $('.previous', rootSelector).on('click', function () {
                reset();
                prev()
            });

            /*
             Brings slide to next slide and nav control
             */
            let prevSlide;

            function changeSlide(c, mobileDevice) {

                let currentSlide = slides[c];
                $(slides, rootSelector).removeClass('active left right no-animation');

                let currentNavItem = controlNav[c],
                    right = [],
                    left = [],
                    animation = {
                        fade: {
                            active: {
                                opacity: '1'
                            },
                            right: {
                                opacity: '0'
                            },
                            left: {
                                opacity: '0'
                            }
                        },
                        slide: {
                            active: {
                                left: '0'
                            },
                            left: {
                                left: '-100%'
                            },
                            right: {
                                left: '100%'
                            }
                        }
                    };


                if (c !== slides.length) {

                    $(currentSlide, rootSelector).addClass('active');

                    $(slides, rootSelector).each(function (i) {
                        //if the index of slide is greater than the active slide
                        if (slides[i].dataset.slide > currentSlide.dataset.slide) {
                            $(slides[i], rootSelector).addClass('right');
                        }
                        //if the index of slide is less than the active slide
                        if (slides[i].dataset.slide < currentSlide.dataset.slide) {
                            //if the index of slide is less than the active slide
                            if (currentSlide.dataset.slide !== slides[currentSlide.dataset.slide].dataset.slide - 1 && prevSlide) {
                                $(slides[i], rootSelector).addClass('left').addClass('no-animation');
                                $(prevSlide, rootSelector).removeClass('no-animation');
                            }
                            else if (currentSlide.dataset.slide !== slides[currentSlide.dataset.slide].dataset.slide - 1) {
                                prevSlide = slides[0];
                                $(slides[i], rootSelector).addClass('left').addClass('no-animation');
                                $(prevSlide, rootSelector).removeClass('no-animation');
                            }
                            else {
                                $(slides[i], rootSelector).addClass('left')
                            }
                        }
                    });

                    $(controlNav, rootSelector).removeClass('active');
                    $(currentNavItem, rootSelector).addClass('active');
                    initProgressBar(currentNavItem);
                    prevSlide = currentSlide;
                    if (autoScrollTimer) {
                        reset()
                    }
                }
            }

            /*
             Functions for next and previous slides
             */

            function next(mobileDevice) {
                if (parseInt(c) !== slides.length - 1) {
                    c++;
                    changeSlide(c, mobileDevice);


                    reset()
                }

                else {
                    c = 0;
                    changeSlide(c, mobileDevice);
                    reset()
                }
            }

            function prev(mobileDevice) {
                if (parseInt(c) !== 0) {
                    c--;
                    changeSlide(c, mobileDevice);
                    reset()
                }
                else {
                    c = slides.length - 1;
                    changeSlide(c, mobileDevice);
                    reset()
                }
            }

            /*
             resets autoScroll functions
             */
            function reset() {
                if (autoScrollTimer) {
                    clearTimeout(autoScrollTimer);
                    autoScrollTimerInit();
                }
            }

            /*
             Progress Bar Functions
             */
            function initProgressBar(currentNavItem) {
                if ($('.progress', currentNavItem, rootSelector)) {
                    $('.progress', rootSelector).removeClass('active');
                    $('.progress', currentNavItem, rootSelector).addClass('active');
                }

            }


            /** Touch events **/
            function initTouchEvents() {

                let touchStart;
                let touchCurrentPosition;
                let touchDistance;
                let touchEnd;
                let prevSlideLeft;
                let nextSlideLeft;
                let pageWidth = $(document).width();
                let root = document.getElementById(id);
                let $currentSlide = $(slides[c]);
                let $previousSlide = $(slides[c - 1]);
                let $nextSlide = $(slides[c + 1]);
                let touchingNav = false;

                function getTouchPosition() {

                    return ( parseFloat(touchCurrentPosition) - parseFloat(touchStart) ) / 2;
                }

                /** Adjusts page width when page width has been adjusted **/
                document.addEventListener("resize", function () {
                    pageWidth = $(document).width();

                });

                /** Touch start, assign slides, get touch start position **/
                root.addEventListener('touchstart', function (e) {

                    if ($(e.target) === $('#contorlNav', rootSelector) || $(e.target).hasClass('controlNav')) {
                        touchingNav = true;

                    }
                    else {
                        touchingNav = false;
                    }
                    if (!touchingNav) {
                        $currentSlide = $(slides[c]);

                        touchStart = e.pageX || e.touches[0].pageX;
                        touchCurrentPosition = 0;
                        touchDistance = 0;
                        touchEnd = 0;

                        //If first slide, assigns prev slide to last slide
                        if (c === 0) {
                            $previousSlide = $(slides[slides.length - 1]);
                            $previousSlide.css('left', '-100%');
                            $nextSlide = $(slides[c + 1]);

                        }
                        else if (c === slides.length - 1) {
                            $nextSlide = $(slides[0]);
                            $previousSlide = $(slides[c - 1]);
                            $nextSlide.css('left', '100%');
                        }
                        else {
                            $previousSlide = $(slides[c - 1]);
                            $nextSlide = $(slides[c + 1]);
                        }
                    }

                });


                /** Touch Move, moves correct slides to correct positions **/
                root.addEventListener('touchmove', function (e) {
                    if (!touchingNav) {


                        touchCurrentPosition = e.pageX || e.touches[0].pageX;


                        touchDistance = getTouchPosition();


                        if (c !== 0 || c !== slides.length - 1) {

                            prevSlideLeft = ( parseFloat(pageWidth - ( pageWidth * 2)) ) + parseFloat(touchDistance);
                            nextSlideLeft = pageWidth + touchDistance;

                            $currentSlide.css('left', touchDistance);
                            $nextSlide.css('left', nextSlideLeft);
                            $previousSlide.css('left', prevSlideLeft);

                            $('.slides').css(
                                {
                                    'left': touchDistance,
                                    'overflow': 'initial'
                                });
                        }
                        else if (c === 0 && touchDistance <= 0) {

                            prevSlideLeft = pageWidth - touchDistance;
                            nextSlideLeft = pageWidth + touchDistance;

                            $currentSlide.css('left', touchDistance);
                            $nextSlide.css('left', nextSlideLeft);
                            $previousSlide.css('left', prevSlideLeft);

                            $('.slides').css(
                                {
                                    'left': touchDistance,
                                    'overflow': 'initial'
                                });
                        }

                    }
                });

                /** Touch End, checks to see if they have scrolled far enough, and transitions based on swipe **/
                root.addEventListener('touchend', function (e) {
                    if (!touchingNav) {

                        touchEnd = e.pageX || touchCurrentPosition;

                        $('.slides').css(
                            {
                                'left': 0,
                                'overflow': 'hidden'
                            });


                        nextSlideLeft = nextSlideLeft + touchDistance;
                        prevSlideLeft = prevSlideLeft + touchDistance;
                        touchDistance = touchDistance + touchDistance;

                        $currentSlide.css('left', touchDistance);
                        $nextSlide.css('left', nextSlideLeft);
                        $previousSlide.css('left', prevSlideLeft);


                        if (touchDistance > ( pageWidth * .2)) {
                            prev(true);
                        }
                        else if (touchDistance < (pageWidth * .2 - ( pageWidth * .2 * 2 ))) {
                            next(true)

                        }
                        else {
                            $(slides).removeAttr('style');
                        }

                    }
                });

            }

            if (transitionType === "slide") {
                initTouchEvents();
            }

        }())
    }

}
export default sliderScript;
