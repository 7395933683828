/*global Inferno, $, React */
import TodoInput_I from './components/todo-input';
import ActiveTodos_I from './components/active-todos';
import {importAndExec} from '@convertly/thor';

const TodoUiWrapper = () => {

    importAndExec([TodoInput_I, ActiveTodos_I]);

    return ({data, rootClass}) => {

        return (
            <div className={`${rootClass} todo-app-wrapper`}>
                <h1>Todo List</h1>
                <ActiveTodos
                    rootClass={rootClass}
                    activeTodos={data.activeTodos}/>
                <TodoInput
                    rootClass={rootClass}
                    todoInputValue={data.todoInputValue}/>
            </div>
        );
    }
};

export default TodoUiWrapper;
