import { StyleSheet } from "@convertly/css";
import UserOverrides from "../style-functions/user-overrides";
import { deeplog } from "../../utils/deeplog";

const ButtonGlobal = (global, element, buttonType, inEditor) => {
  const userOverrides = new UserOverrides(global, element, inEditor);

  const GlobalButtonStyles = {
    cursor: "pointer",
    ...buttonType,
    "@media(min-width:991px)": {
      ":hover": {
        transition: element.isOnBanner ? "" : "ease 400ms",
        boxShadow:
          "0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.3)",
      },
    },
    "@media(max-width:991px)": {
      ":focus": {
        boxShadow:
          "0 6px 10px 0 rgba(0,0,0,0.14), 0 1px 18px 0 rgba(0,0,0,0.12), 0 3px 5px -1px rgba(0,0,0,0.3)",
      },
    },

    ...userOverrides,
  };

  return GlobalButtonStyles;
};

export default ButtonGlobal;
