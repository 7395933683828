import ghost1 from './ghost/ghost-global';
import magicdragons from './magic-dragons/magic-dragon-global';
import rogue1 from './rogue/rogue-1-global';
import rogue2 from './rogue/rogue-2-global';
import rogue3 from './rogue/rogue-3-global';
import rogue4 from './rogue/rogue-4-global';
import absolute1 from './absolute/absolute-1-global';
import absolute2 from './absolute/absolute-2-global';
import zeroFill1 from './zero-fill/zero-fill-theme-01';
import bolb from './bolb/bolb';
import lulus from './lulus/lulus';
import chavez from './chavez/chavez';
import electricBlack1 from './electric-black/electric-black-theme';
import frenchPress from './french-press/french-press-theme-01';

const global = lulus(  );


export default global;



