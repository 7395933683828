import React from 'react';
import PropTypes from 'prop-types';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';
import _ from 'lodash';
import {generateLink} from '../../../utils/generateLink';
import {clientImage} from '../../../utils/generateImage';
import ReactHTMLParser from '@convertly/react-html-parser'

//scripts
import isSignedIn from '../../scripts/is-signed-in';
import cartHandler from '../../scripts/cart-script';
import SearchBarWrapper from '../../scripts/inferno-components/search-bar-wrapper/SearchBarWrapper';
import SearchBarWrapperHeader01 from '../../scripts/inferno-components/search-bar-wrapper/SearchBarWrapperHeader01';


//Components
import NavLink02 from './nav-link-02';
import Widgets from '../../../widgets';
import SVG from '../../../svg'

export default class NavList02 extends Component {

    constructor(props) {

        super(props);

    }


    render() {

        const {site, data, global, page, styles, displaySocial} = this.props;
        const inEditor = this.inEditor();

        let ecommEnabled = _.get(site, 'ecommerce.enabled');

        let showMobileMenu = false;

        if (inEditor) {
            showMobileMenu = this.context.store.getState().get('editor').editorReducer.get('showClientNav');
        }
        /** Cart **/
        let slug = _.get(page, 'slug');
        let cart = null;

        const clientImgSrc = clientImage("", {width: '60', height: '60', crop: 'fit'}, site.siteId);
        const clientLinkUrl = generateLink({linkType: "inferno", url: ""});

        let className = '';
        let text = ReactHTMLParser(data.text);
        let aphroditeClass = css(styles.headerNav);

        if (this.animatedClassName) {
            className += " " + this.animatedClassName
        }
        className += aphroditeClass;

        const {animated} = this.props.data;
        const inlineStyle = this.generateStyleAttribute();

        if (slug !== "/cart" && ecommEnabled) {

            cart =

                <li href={ generateLink({linkType: 'internal', url: '/cart'}) }
                    className={ css(styles.navListItem, styles.noBorder) }>
                    <span id={ id(cartHandler, clientLinkUrl, clientImgSrc, true, slug) }
                          className={ css(styles.desktopCartInfernoComponent) }
                    >Cart</span>
                </li>


        }
        return (

            <nav
                className={ className }>
                <ul className={ `${css(styles.navList)} ${showMobileMenu ? 'open' : ''}` }
                    id="menu">
                    <div className={ css(styles.navListItemWrapper) }>
											{ !_.get(this.props.page, 'hideSearchBar') &&
											  _.get(this.props, 'site.ecommerce.enabled') &&
                      <div id={ id(SearchBarWrapper, this.props.site.siteId)}
                           className={ css(styles.searchBarWrapper) }/>
                      }
                      { 
                        !_.get(this.props.page, 'hideSearchBar') &&
                        _.get(this.props, 'site.ecommerce.enabled') &&
                        <div id={ id(SearchBarWrapperHeader01, this.props.site.siteId)}
                            className={ `${ css(styles.searchBarWrapper) } mobile` }/>
                      }
                        {this.props.data.navLinks.map((navItem, index) => {
                            return (
                                <NavLink02 key={ index }
                                           data={navItem}
                                           id={ this.props.id }
                                           site={ site }
                                           page={ this.props.page }
                                           styles={ styles }
                                           index={ index }/>
                            )
                        })}
                        <span className={ css(styles.pullLeft) }>
                        { ecommEnabled ? inEditor ?

                            <li className={ css(styles.navListItem) + " cart" }>Cart</li>

                            : cart : null
                        }
                    </span>
                        <span className={ css(styles.pullLeft) }>
                        { ecommEnabled ? inEditor ?

                            <li className={ css(styles.navListItem) }>Sign In</li>

                            :

                            <li
                                id={ id(isSignedIn, clientLinkUrl) }
                                href={ generateLink({linkType: 'internal', url: '/account'}) }
                                className={ css(styles.signInInferno) }>
                                Sign In
                            </li>
                            : null
                        }
</span>
                        { displaySocial.headerTopper &&
                        displaySocial.socialIcons &&
                        _.get(site, 'social') &&

                        <li className={ css( styles.mobileSocialIcons ) } >
                            <Widgets.SocialSharing01
                                data={ {socialIcons: site.socialIcons} }
                                global={ global }
                                site={ site }
                                styles={ styles }
                                socialIconLinkClass={ styles.socialIconContainer }/>
                        </li>

                        }
                    </div>

                </ul>


            </nav>
        )
    }
}


/**
 * Connects element to context for cms editor
 **/
NavList02.contextTypes = {
    store: PropTypes.object
};
