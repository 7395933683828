import React from 'react';
import Component from '../../../../rootComponent';
import {css} from "@convertly/css";
import Types from '../../../../attribute-types';
import { id } from '@convertly/thor';
import mobileNavToggle from '../../scripts/hidden-nav-toggle';


//Default Props
import defaultData from '../../common/header-nav-default-data';
import defaultGlobal from '../../../../themes/global.js';

//Components
import HeaderNavList1 from '../../common/header-nav-list/header-nav-list-1';

//Styles
import headerNavStyles from './header-nav-styles-2';

export default class HeaderNav2 extends Component {
    constructor(props) {
        super(props);
    }


    render() {

        this.styles = headerNavStyles(this.props.global, this.props.data);

        return (
            <div className={ css( this.styles.headerBottom ) }>
                <div className="container">
                    <div className="row">
                        <div className={ css(this.styles.headerMain) }>
                            <div className={ css(this.styles.logo) + " col-md-3 col-xs-12 col-sm-3" } itemScope
                                 itemType="http://schema.org/astillero">
                                <a className={ css(this.styles.logoImg) } href={ this.props.data.logoLink }>
                                    <img className={ css(this.styles.logoImg) + " img-responsive"}
                                         src={this.props.data.logo.src}
                                         alt={this.props.data.logo.alt}
                                         title={this.props.data.logo.title}
                                         width="277" height="38"/>
                                </a>
                            </div>

                            <div className={ css(this.styles.headerBtmRgt) + " col-md-9 col-xs-12 col-sm-9"}>
                                <div
                                     className={ css(this.styles.mobileNavIconContainerOpen) + " col-xs-2 col-lg-1"}
                                     data-target="#mobileNavContainer">
                                    <button id={ id( mobileNavToggle ) }
                                            type="button"
                                            data-target='#mobileNavContainer'
                                            className={ css(this.styles.collapse) }>
                                        <span className="sr-only">Toggle navigation</span>
                                        <span
                                            className={ css( this.styles.iconBar ) }>
                                        </span>
                                        <span
                                            className={ css( this.styles.iconBar ) }>
                                        </span>
                                        <span
                                            className={ css( this.styles.iconBar ) }>
                                        </span>
                                    </button>

                                </div>
                                <div id="mobileNavContainer"
                                    className={ css(this.styles.navBg ) }>
                                    <HeaderNavList1 data={ this.props.data.menu }
                                                    global={ this.props.global }
                                                    styles={ this.styles }/>

                                    <div className="clearfix"></div>
                                </div>
                                <div className={ css(this.styles.searchBlock) + " pull-right" }>
                                    <div className={ css(this.styles.searchLft) + " hidden-xs" }>
                                        <input name=" " type="text" placeholder="Search"
                                               className={ css(this.styles.searchBt) }/>
                                        <button className={ css(this.styles.btnSearch) }>
                                        </button>
                                    </div>
                                    <div className={ css(this.styles.searchRgt) + " pull-right" }>
                                        <ul className={ css(this.styles.searchRgtList) }>
                                            <li itemProp="url"
                                                className={ css(this.styles.searchRgtListItem, this.styles.heartIcon)
                                                + " hidden-xs" }>
                                                <a className={ css(this.styles.searchRgtListItemLink) } href="#">
                                                    <img className={ css(this.styles.searchRgtListItemImg) }
                                                         src="https://d393hjtdh3b27x.cloudfront.net/magic-dragons/img/heart_icon.png"
                                                         alt="heart"/>
                                                </a>
                                            </li>
                                            <li itemProp="url"
                                                className={ css(this.styles.searchRgtListItem, this.styles.searchIcon) }>
                                                <a className={ css(this.styles.searchRgtListItemLink) + " u-url" }
                                                   href="#">
                                                    <img className={ css(this.styles.searchRgtListItemImg) }
                                                         src="https://d393hjtdh3b27x.cloudfront.net/magic-dragons/img/search_icon.png"
                                                         alt="search"/>
                                                </a>
                                            </li>
                                            <li itemProp="url" className={ css(this.styles.searchRgtListItem) }>
                                                <a className={ css(this.styles.searchRgtListItemLink) + " u-url" }
                                                   href="#">
                                                    <img className={ css(this.styles.searchRgtListItemImg) }
                                                         src="https://d393hjtdh3b27x.cloudfront.net/magic-dragons/img/cart_icon.png"
                                                         alt="cart"/>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

HeaderNav2.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};

