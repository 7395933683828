/* global $, ga, _paq, fbq, window */

import { include } from '@convertly/thor';

const Script = () => {

	const Dispatcher = include( '../utils/dispatcher', 'Dispatcher' );
	const Graph = include( '../utils/graph', 'Graph' );

	return ( id, send, event, interaction, heartbeat, systemFacebookPixelId, customUserFacebookPixelId, siteId ) => {

		const query = `query ($event: String, $siteId: ID) {TrackEvent(siteId: $siteId, event: $event)}`;

		const onloadTimestamp = now();
		const elements        = $( '[data-event]' );
		
		let formattedGAId = window.gaID;
		if( formattedGAId ) {
		  formattedGAId = formattedGAId.replace( /\-/g, '_' );
    }

		Dispatcher().on( 'event', handle );

		elements.forEach( el => {
			const $el = $( el );
			const trigger = `${$el.data( 'action' )}`;
			const eventName = $el.data( 'event' );

			$el.on( trigger, ( e ) => {
				sendEvents( eventName );
			});

		});

		// Register the heartbeat event on page unload
		//window.onbeforeunload = trackPageUnload;

		/**
		 * Manual event triggering handler
		 *
		 * @param action
		 * @param payload
		 */
		function handle( { action, payload } ) {
			sendEvents( action, payload );
		}

		/**
		 * Send Events
		 * Triggers the global Convertly event
		 * @param action
		 * @param payload
		 */
		function sendEvents( action, payload ) {

			Dispatcher().send( action, payload );
			window.log( 'Firing Event', action, payload );

			// log the event to dynamo for conversion tracking
			// this probably will be changed in future with something more elegant
			// but works for now
			const variables = {
				siteId,
				event: action,
			};
			Graph()({query,variables});

			if( typeof ga !== 'undefined' && window.gaID ) {
        /**
         * Because we switched init GA from ga.js to GTM tag we have to namespace the events with
         * the GTM tag ID
         * ga( `gtag_UA_123123123_1.send`, event, interaction, action );
         * GA IDs are of format UA-123123123-1.
         * When sending event the dashes need to be underscores
         */
				ga( `gtag_${ formattedGAId }.${send}`, event, interaction, action );
				window.log( 'Firing GA event', event, interaction, action );
			}

			if( typeof _paq !== 'undefined' ) {
				_paq.push ( [ 'trackEvent', interaction, action, action ] );
				window.log( 'Firing PAQ event', event, interaction, action );
			}

			if( typeof fbq !== 'undefined' ) {
				//fbq( 'trackCustom', action, { myParam: 'abc' } );
				// fbq( 'trackCustom', action );
				// window.log( 'Firing FB event', event, interaction, action );
				if(systemFacebookPixelId) {
					fbq( 'trackSingleCustom', systemFacebookPixelId, action );
					window.log( 'Firing FB event', event, interaction, action );	
				}

				if(customUserFacebookPixelId) {
					fbq( 'trackSingleCustom', customUserFacebookPixelId, action );
					window.log( 'Firing FB event', event, interaction, action );	
				}
			}
			
			if( typeof gtag !== 'undefined' && typeof window.adwordsAccountId !== 'undefined' ) {
				
				try {
					
					const points = Convertly.conversionPoints.filter( point => {
						return point.name === action;
					});
					
					if( points.length !== 1 ) {
						return;
					}
					
					const point = points[ 0 ];
					
					if( ! point.adwordsId ) {
						return;
					}
					
					gtag('event', 'conversion', {
						'send_to': point.adwordsId,
						'value': 1.0,
						'currency': 'USD',
					});
					
					window.log( 'Firing Adwords event', point.adwordsId, action );
					
					if( false && typeof ga !== 'undefined' ) {
            // not sure why we were sending this in
            // leaving it disabled for now
						ga('event', 'conversion', {
							'send_to': point.adwordsId,
							'value': 1.0,
							'currency': 'USD',
						});
						window.log( 'Firing Adwords GA event', event, interaction, action );
					}
					
				} catch( e ) {
					console.error( e );
				}
				
			}
			
		}

		/**
		 * Sends GA and Piwik heartbeat event
		 */
		function trackPageUnload() {

			const timeOnPage = now() - onloadTimestamp;

			if( typeof ga !== 'undefined' ) {
				ga ( send, {
					hitType: event,
					eventCategory: heartbeat,
					eventAction: heartbeat,
					eventLabel: heartbeat,
					eventValue: timeOnPage,
					nonInteraction: true
				} );
			}

			if( typeof _paq !== 'undefined' ) {
				_paq.push ( ['trackEvent', heartbeat, heartbeat, heartbeat, timeOnPage] );
			}

			//return false;

		}

		/**
		 * Returns current timestamp in seconds
		 *
		 * @returns {number}
		 */
		function now() {
			return Math.floor( Date.now() / 1000 );
		}

	};

};

export default Script;
