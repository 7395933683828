const data = {
                "id": "b70f25d0-9844-11e7-abc4-cec278b6b50a",
                "metaTitle": "Silverback® Front ABS Heavy-Duty Hydraulic Brake Rotor",
                "quantity": "500",
                "siteId": "994cb7e4-982e-11e7-abc4-cec278b6b50a",
                "shipping_countries": [
                   "USA"
                ],
                "discountValue": "0",
                "slug": "/silverback-front-abs-heavy-duty-hydraulic-brake-rotor",
                "optionsCollection": [],
                "updatedAt": 1507831727853,
                "product_type": "Physical Good",
                "category": [],
                "images": [
                   "8-compressor.JPG"
                ],
                "description": "<p>Silverback HD has been a name for years to depend on for high-quality truck parts manufactured to withstand the roughest conditions on the road. Their line of OE-quality rotors is built to their high standards and feature specifications like Airstream venting technology, which reduces heat and increases brake life. Their rotors also are covered with a patented GeoMet® coating to resist corrosion from solvents like gasoline, brake fluids, transmission fluids, antifreeze and motor oil. &nbsp;&nbsp;<\/p>",
                "pricing": {
                   "currency": "usd",
                   "price": "145.60"
                },
                "manufacture_details": {
                   "release_date": "2000-01-01T23:28:56.782Z",
                   "model_number": "RW1",
                   "manufacturer": "International, Navistar"
                },
                "discounts": [],
                "sku": "54HR769G",
                "title": "Silverback® Front ABS Heavy-Duty Hydraulic Brake Rotor",
                "type": "Physical Good"
             }

export default data;