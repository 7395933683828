import React from "react";

const renderChildren = (props, propsToBePassed = {}) => {
  if (propsToBePassed === {}) {
    propsToBePassed = props;
  }

  return React.Children.map(props.children, child => {
    return React.cloneElement(child, propsToBePassed);
  });
};

export default renderChildren;
