import { createAction } from "redux-act";
import _ from "lodash";
import { browserHistory } from "react-router";
import { graph } from "utils/graph";
import { store } from "app/app";

export const setProducts = createAction("SET_PRODUCTS");
export const setLoadingState = createAction("SET_PRODUCTS_LOADING_STATE");
export const resetState = createAction("RESET_PRODUCTS_STATE");
export const setNewProductName = createAction("PRODUCTS_SET_NEW_PRODUCT_NAME");
export const setSavingNewProduct = createAction("PRODUCTS_SET_SAVING_NEW_PRODUCT");

export function saveNewProduct() {
  return async dispatch => {
    await dispatch(setSavingNewProduct(true));

    const productName = store
      .getState()
      .get("products")
      .get("newProductName");
    const siteId = store
      .getState()
      .get("auth")
      .get("activeSite")
      .get("id");

    const query = `
		mutation( $product: productInput ) {
		  createProduct(product:$product) {
			id
		  }
		}`;

    const variables = {
      product: {
        title: productName,
        siteId,
        active: true,
        hidden: false,
        categories: [],
      },
    };

    const res = await graph({ query, variables });
    //turn off processing state
    await dispatch(setSavingNewProduct(false));
    const id = _.get(res, "createProduct.id");

    browserHistory.push(`/storefront/products/${id}`);
    await dispatch(setNewProductName(""));
  };
}

export const loadProducts = site => async dispatch => {
  await dispatch(setLoadingState(true));

  const id = site.get("id") || site.get("siteId");

  const query = `query($id:ID!){ Products( siteId: $id ) {
			id, slug, images { src }, pricing { price }, quantity_in_stock, onSale, salePrice, title, active } }`;

  const variables = { id: id };
  const response = await graph({ query, variables });
  const products = response.Products.map(product => {
    product.price = product.pricing.price;
    product.product_image = product.images[0];
    return product;
  });

  await dispatch(setProducts(products));
  await dispatch(setLoadingState(false));
};
