/**
 * Tracking utils for snowplow
 *
 * Exports
 * tracker - tracker object
 * trackEvent - event callback helper
 *
 * Usage:
 * import { tracker } from 'utils/snowplow'
 * tracker.trackEvent("EVENT_CATEGORY","EVENT_ACTION", "EVENT_LABEL");
 *
 * import { trackEvent } from 'utils/snowplow'
 * <Component onClick={trackEvent( handler, "SOME_CATEGORY", "CLICKED_THE_BUTTON" )} />
 *
 * app-analytics funnels work off of EVENT_ACTION
 * EVENT_LABEL and subsequent arguments are optional.
 * See ShowplowTracker code for all arguments
 *
 */
class SnowplowTracker {
  /**
   * For trackEvent method we push the args to an array
   * We then have interval that checks if there are items and processes them
   *
   * Ran into issues where tracker.trackEvent was being called before window.snowplow had been initialized
   * and threw errors
   *
   * The processCache method just pops the array. Could have written code to empty out the array if
   * there are multiple items but it gets called every 50ms so I'm not worried about it
   *
   */
  constructor() {
    this.cache = [];
  }

  init(userId) {
    (function(p, l, o, w, i, n, g) {
      if (!p[i]) {
        p.GlobalSnowplowNamespace = p.GlobalSnowplowNamespace || [];
        p.GlobalSnowplowNamespace.push(i);
        p[i] = function() {
          (p[i].q = p[i].q || []).push(arguments);
        };
        p[i].q = p[i].q || [];
        n = l.createElement(o);
        g = l.getElementsByTagName(o)[0];
        n.async = 1;
        n.src = w;
        g.parentNode.insertBefore(n, g);
      }
    })(window, document, "script", "//d2j4bhw1pf7qsq.cloudfront.net/snow-shove.js", "snowplow");

    window.snowplow("newTracker", "cf", "d22m8znda8zoee.cloudfront.net", {
      appId: "convertly",
      //cookieDomain: "localhost",
      discoverRootDomain: true,
      post: false,
      options: false,
    });

    userId && window.snowplow("setUserId", userId);
    // we don't use page views in funnels yet
    // but still leaving it in for future when we might use it
    window.snowplow("trackPageView");

    this.interval = setInterval(this.processCache.bind(this), 50);
  }

  trackPageView() {
    this.cache.push("trackPageView");
  }

  processCache() {
    if (!this.cache.length) return;
    if (!window.snowplow) return;

    const item = this.cache.pop();

    if (item === "trackPageView") {
      window.snowplow("trackPageView");
    } else {
      window.snowplow("trackStructEvent", ...item);
      // this is the original function
      //window.snowplow("trackStructEvent", category, action, label, property, value);
    }
  }

  setUser(id) {
    !!id && this.set("setUserId", id);
  }

  set(field, value) {
    window.snowplow(field, value);
  }

  /**
   * we don't use these parameters but leaving for reference
   * @param {string} category
   * @param {string} action
   * @param {string} label
   * @param {*} property
   * @param {*} value
   */
  trackEvent(category = null, action = null, label = null, property = null, value = null) {
    this.cache.push([...arguments]);
  }
}

export const tracker = new SnowplowTracker();

/**
 * @param {function} callback
 * @param {string} category
 * @param {string} action
 */
export function trackEvent() {
  const args = [...arguments];
  // args = [ someFunction, "EVENT_CATEGORY", "EVENT_ACTION"]

  // remember slice returns an array
  const callback = args.slice(0, 1)[0];
  const trackingArgs = args.slice(1, args.length);

  return function() {
    try {
      tracker.trackEvent(...trackingArgs);
      callback.apply(null, [...arguments]);
    } catch (e) {
      console.error(e.message);
    }
  };
}
