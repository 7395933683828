import { StyleSheet } from '@convertly/css';

const ScrollButtonStyles = ({ global, data, inEditor }) => {
  return StyleSheet.create({
    scrollButton: {
      'margin-top': 'auto',
      cursor: 'pointer'
    },
    caret: {
      width: '30px',
      margin: 'auto'
    }
  })
};

export default ScrollButtonStyles;