import { Header01 } from './header-01/header-01';
import { Header02 } from './header-02/header-02';
import { Header03 } from './header-03/header-03';
import { Header04 } from './header-04/header-04';
import { Header05 } from './header-05/Header05';
import { Nav04 } from './header-04/components/nav-04';
import { MainSiteHeader } from './site-header/MainSiteHeader';
import { Navigation } from './site-header/components/navigation/Navigation';
import { Ecommerce } from './site-header/components/ecommerce/Ecommerce';
import { SearchBar } from './site-header/components/search-bar/SearchBar';
import { Logo } from './site-header/components/logo/Logo';
import { HeaderTopper } from './components/header-topper';

export const SiteHeader = {
  Header01,
  Header02,
  Header03,
  Header04,
  Header05,
  Nav04,
  MainSiteHeader,
  Navigation,
  Ecommerce,
  SearchBar,
  Logo,
  HeaderTopper
};

export default SiteHeader
