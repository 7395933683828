import Logger from "utils/logger";
import { isProduction } from "./utils";

/**
 * Available Logging Options
 * ALL
 * VERBOSE
 * DEBUG
 * INFO
 * NOTICE
 * WARNING
 * ERROR
 * CRIT
 * ALERT
 * EMERG
 */

export default {
  LOGGING: Logger.DEBUG
};

export const NOTIFICATIONS = {
  TRANSITION: 300,
  TIMEOUT: 5000
};

//export const IMAGE_UPLOAD_SERVER = 'http://localhost:8000';
//export const IMAGE_UPLOAD_SERVER = 'http://104.236.191.39/upload';
export const IMAGE_UPLOAD_SERVER = "https://uploader.convertly.com";
//export const BANNER_RENDERING_SERVER = "http://localhost:8000/phantom";
export const BANNER_RENDERING_SERVER = "https://uploader.convertly.com/phantom";

export const APP_HOST = "app.convertly.com";

const ADTECH_GRAPH_DEV = "https://f5rwlsxgs4.execute-api.us-east-1.amazonaws.com/development";
const ADTECH_GRAPH_PRODUCTION = "https://vebsf5y59g.execute-api.us-east-1.amazonaws.com/production";
export const ADTECH_GRAPH = isProduction() ? ADTECH_GRAPH_PRODUCTION : ADTECH_GRAPH_DEV;

const CONVERTLY_GRAPH_DEV = "https://7y02jybgt0.execute-api.us-east-1.amazonaws.com/development/";
const CONVERTLY_GRAPH_STAGING = "https://m1bfbwrmt8.execute-api.us-east-1.amazonaws.com/staging/";
const CONVERTLY_GRAPH_PRODUCTION = "https://graph.convertly.com/";
export const CONVERTLY_GRAPH = isProduction() ? CONVERTLY_GRAPH_PRODUCTION : CONVERTLY_GRAPH_DEV;

const CONVERTLY_AUTHENTICATION_DEV =
  "https://gtk8c4hmmi.execute-api.us-east-1.amazonaws.com/development";
const CONVERTLY_AUTHENTICATION_PRODUCTION =
  "https://zu9gao1cfl.execute-api.us-east-1.amazonaws.com/production";
export const CONVERTLY_AUTHENTICATION = isProduction()
  ? CONVERTLY_AUTHENTICATION_PRODUCTION
  : CONVERTLY_AUTHENTICATION_DEV;

export const GOOGLE_FONT_API_KEY = "AIzaSyCvfTzvXTkcy7zbBfJREoRazgPFauc-Hmw";

// Stripe API Keys for initializing Stripe SDK
export const STRIPE_API_KEY_LIVE = "pk_live_2l95WxuhDgAeERZop3H2JyyB";
export const STRIPE_API_KEY_TEST = "pk_test_FOWkvSlZdf1FgO1fgWYJk6ao";

export const STRIPE_API_KEY = isProduction() ? STRIPE_API_KEY_LIVE : STRIPE_API_KEY_TEST;

// stripeClientId is Convertly Stripe Connect client ID
// Used for initializing oAuth connection to Stripe on link Stripe button
export const STRIPE_CLIENT_ID_PROD = "ca_BbdJTLNuOmsTUSx7gRfBezW6cqmoNdcY";
export const STRIPE_CLIENT_ID_DEV = "ca_BbdJ6jQNGPjZh6PQyGWCPf3uAC4iHpzP";

export const stripeClientId = isProduction() ? STRIPE_CLIENT_ID_PROD : STRIPE_CLIENT_ID_DEV;

export const IMAGE_SERVER = "http://ec2-54-175-200-35.compute-1.amazonaws.com";
export const S3_IMAGE_DOMAIN = "https://convertlyimguploadeast.s3.amazonaws.com";

const bannerRenderingDomainDevelopment = "https://www.gravitronfluxmagic.com/creative";
const bannerRenderingDomainProduction = "https://www.magicalghostrendering.xyz/creative";
export const BANNER_RENDERING_URL = isProduction()
  ? bannerRenderingDomainProduction
  : bannerRenderingDomainDevelopment;

export const COWMAN_PEXEL = "https://rm5vszgtw1.execute-api.us-east-1.amazonaws.com/development";
