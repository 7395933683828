import React from 'react';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import ReactHTMLParser from "@convertly/react-html-parser"
import {id} from '@convertly/thor';
import _ from 'lodash';

//Default Props
import defaultGlobal from '../../../themes/global';

//Styles
import MapStyles from './map-address-on-side-styles.js';

//Components
import Elements from '../../../elements/index'
import Widgets from '../../index';

//Scripts
import AsyncMapLoad from '../scripts/map-iframe-async-load';

//utils
import generateAddressString from '../../../utils/generateAddressString';
import {getProtocol} from '../../../utils/generateLink';


export default class MapAddressOnSide extends Component {

    constructor(props) {

        super(props);
    }


    render() {

        const { global, data, site } = this.props
        const inEditor = this.inEditor();
        const { 
          showEmail, 
          showHoursOfOperations, 
          showPhone, 
          showViewMapOnGoogleMaps,
          hoursOfOperationContrast
        } = data || {};

        let { locations } = site || {};

        /** Generates border in editor */
        const inlineStyle = this.generateStyleAttribute();

        this.styles = MapStyles(this.props.global, this.props.data, inEditor);

        if (this.props.editor) {
            return this.editor();
        }


        let containerClassName = this.styles.locationContainer;

        if( locations.length === 1 ) {
            containerClassName = this.styles.locationContainerSingle;
        }

        return (

            <div className={ css(this.styles.locationDiv) + ' map-container' }
                 { ...this.generateDataEditorAttribute() }
                 { ...this.generateCustomAttributes() }
                 onClick={(e) => {
                     this.launchElementalEditor('mapsWidget', e);
                 }}
                 onMouseOver={ (e) => {
                     this.addEditableOutline(e)
                 } }
                 onMouseOut={ (e) => {
                     this.removeEditableOutline(e)
                 } }
                 {...inlineStyle}>

                {locations.map((location, index) => {

                    let phoneLink;
                    let emailLink;
                    let viewMapLink;

                    if (location.phone) {
                        phoneLink =
                            <Elements.WebLink
                                data={ { text: location.phone } }
                                parentClassName={ css(this.styles.phoneLink) }
                                global={ this.props.global }
                                elementDataName="emailLink"
                                moduleId={ this.props.id }
                                moduleSort={ this.props.sort }/>
                    }

                    if (location.emailAddress) {
                        emailLink =
                            <Elements.WebLink
                                data={ { text: location.emailAddress } }
                                parentClassName={ css(this.styles.emailLink) }
                                global={ this.props.global }
                                elementDataName="emailLink"
                                moduleId={ this.props.id }
                                moduleSort={ this.props.sort }/>
                    }


                    return (
                        <div key={ `location-${ index }` }
                             className={ css(containerClassName) }>
                            {/** Google Map Embed Code, to be changed API **/}
                            <div className={ css(this.styles.mapContainer) }>
                                { inEditor ?
                                    <div className={ css(this.styles.googleMap) }>
                                    <iframe
                                        className={ css(this.styles.googleMap) + ' map'}
                                        src={  "https://www.google.com/maps/embed/v1/place?q=" + generateAddressString(location) +
                                        "&zoom=16&key=AIzaSyArBjawFp_rUBj6YYFqNHCTmt1LuNOEhjw" } />
                                    </div>
                                        :
                                    <div
                                        id={ id(AsyncMapLoad, this.props.site.googleMapAPIKey, generateAddressString(location))}
                                        className={ css(this.styles.googleMap) }/>
                                }

                            </div>

                            <div className={ css(this.styles.locationInfo) }>

                                {/** Store Location Info **/}
                                <div className={ css(this.styles.addressBox) }>
                                    <h2 className={ css(this.styles.storeName) }>{ location.locationName }</h2>

                                    {/** Address **/}
                                    <address className={ css(this.styles.address) }>
                                        {
                                            ReactHTMLParser(location.address +
                                                '<br/>' +
                                                location.city + " " +
                                                location.state + " " +
                                                location.zip)
                                        }
                                    </address>
                                </div>
                                <div className={ css(this.styles.callUsBox) }>
    
                                    {
                                      showViewMapOnGoogleMaps &&
                                      <Elements.GoogleMapLink data={
                                          {
                                              ...this.props.data,
                                              customLinkMessage: "View on Google maps",
                                              location
                                          }
                                      }
                                      parentClassName={ css(this.styles.phoneLink) }/>

                                    }


                                    <div className="clearfix"/>
                                </div>

                                { /** Phone and Email **/ }

                                {
                                  showPhone &&
                                  phoneLink
                                }

                                {
                                  showEmail &&
                                  emailLink
                                }

                                {
                                  showHoursOfOperations &&
                                  <Widgets.HoursOfOperation1
                                  data={ { location: location, contrast: hoursOfOperationContrast } }
                                  site={ this.props.site }/>
                                }

                                <div className="clearfix"/>
                            </div>
                        </div>
                    )
                }) }
            </div>

        )

    }
}

MapAddressOnSide.defaultProps = {
    global: defaultGlobal,
    data: {}
};
