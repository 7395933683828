import { fromJS } from "immutable";
import { createAction } from "redux-act";

import { store } from "app/app";
import { userClickedSaveButton, toggleEditor, clearEditorPanel } from "./editorActions";
import { PANELS, PanelIconMap } from "../Panels/constants";
import { yellow, red } from "utils";
import { updateUser } from "services/User";
import { getBlogPanelState } from "../Panels/Blogs/actions";

export const setActiveNavState = createAction("NAV BAR: Set the active icon");
export const setPanelTitle = createAction("PANEL: Set panel title");
export const setIsNavBarMoreOptionOpen = createAction("NAV BAR: Set more options open flag");
export const setExitPanel = createAction("PANEL: Exit");
export const setActivePanel = createAction("PANEL: Set Active Panel");
export const setPrevPanel = createAction("PANEL: Set Prev Panel");
export const setProcessing = createAction("PANEL: Set processing flag");
export const setLeavePanelHook = createAction("PANEL: Set the hook for when panel's change");
export const setNavBarLocked = createAction("PANEL: Set nav bar locked flag");
export const setPreviewLink = createAction("NAVBAR: Set Preview Link");

const NavBar = () => store.getState().get("editor").NavBar;

export const toggleTextEditor = () => async dispatch => {
  const currentOption = store
    .getState()
    .get("editor")
    .editorReducer.get("isUsingNewEditor");
  let newOption;
  if (currentOption.includes("text")) {
    newOption = currentOption.replace("text", "");
  } else {
    newOption = `${currentOption}text`;
  }
  await Promise.all([
    dispatch(setIsNavBarMoreOptionOpen(false)),
    dispatch(toggleEditor(newOption)),
  ]);
};
export const toggleColumnEditor = () => async dispatch => {
  const currentOption = store
    .getState()
    .get("editor")
    .editorReducer.get("isUsingNewEditor");
  let newOption;
  if (currentOption.includes("column")) {
    newOption = currentOption.replace("column", "");
  } else {
    newOption = `${currentOption}column`;
  }
  await Promise.all([
    dispatch(setIsNavBarMoreOptionOpen(false)),
    dispatch(toggleEditor(newOption)),
  ]);
};
export const toggleNavBarLocked = () => async dispatch => {
  const currentOption = NavBar().get("navBarLocked");

  const id = store.getState().getIn(["auth", "user", "id"]);
  const user = { lockEditorNavBar: !currentOption };

  await Promise.all([
    dispatch(setIsNavBarMoreOptionOpen(false)),
    dispatch(setNavBarLocked(!currentOption)),
    updateUser({ id, user }),
  ]);
};

export const saveSite = () => async dispatch => {
  await dispatch(clearEditorPanel());
  await dispatch(userClickedSaveButton({}));
};

export const publishSite = () => async dispatch => {
  await dispatch(clearEditorPanel());
  await dispatch(userClickedSaveButton({ publish: true }));
};

export const previewSite = () => async dispatch => {
  const Editor = store.getState().get("editor").editorReducer;
  let PageSlug = Editor.getIn(["page", "slug"]);

  const NavBar = store.getState().get("editor").NavBar;
  const previewLink = NavBar.get("previewLink");

  PageSlug = previewLink ? previewLink : PageSlug;

  const href = `http://${Editor.getIn(["currentSite", "previewDomain"])}.convertly.io${PageSlug}`;

  const element = document.createElement("a");
  element.setAttribute("href", href);
  element.setAttribute("target", `_blank`);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

/**
 *
 * @param item from primaryButtons
 * @returns {Function}
 */
export const handleNavClick = item => async dispatch => {
  if (item.cb) {
    await item.cb();
  } else {
    await dispatch(activateNavState(item));
  }
};

/**
 * Handler for clicking primaryButton icon in nav bar
 * @param key - item key from nav bar that was clicked
 * @param forcePanelChange - added this parameter to update nav state but on close panel if updating same state
 * @returns {Function}
 */
export const activateNavState = ({ key, forcePanelChange }) => async dispatch => {
  yellow(`activateNavState: ${key}`);

  let activeNavState, activePanel, prevPanel;

  const Editor = store.getState().get("editor");
  const Blog = getBlogPanelState(store.getState());

  const currentNav = Editor.NavBar.get("activeNavState");
  const editingBlogLayout = Editor.editorReducer.get("editingBlogLayout");
  const editingBlogPost = Editor.editorReducer.get("editingBlogPost");
  const activeLayoutEditingPanel = Blog.get("activeLayoutEditingPanel");

  if (editingBlogPost) {
    yellow("Editing Blog Post: Cancelling activateNavState navItem");
    return;
  }

  if (currentNav !== key || forcePanelChange) {
    activeNavState = activePanel = key;
  } else {
    activeNavState = activePanel = "";
  }

  yellow(`currentNav: ${currentNav}`);

  if (editingBlogLayout && key === "BLOGS" && currentNav !== "BLOGS") {
    red("Overriding panel to " + activeLayoutEditingPanel);
    activePanel = activeLayoutEditingPanel;
    await dispatch(setPrevPanel(fromJS([PANELS.BLOG])));
    await dispatch(setActiveNavState(PANELS.BLOG));
  }

  await Promise.all([
    dispatch(setActiveNavState(activeNavState)),
    dispatch(pushPanel({ panel: activePanel, fromProcessPanelChange: false })),
  ]);
};

export const goToPanel = ({ panel }) => async dispatch => {
  yellow(`goToPanel("${panel}")`);

  return {
    activePanel: panel,
    prevPanels: fromJS([]),
  };
};

export const pushPanel = ({ panel, fromProcessPanelChange = false }) => async dispatch => {
  yellow(`pushPanel("${panel}")`);

  const activePanel = NavBar().get("activePanel");
  let prevPanels = NavBar().get("prevPanels") || fromJS([]);

  prevPanels = prevPanels.push(activePanel);

  console.log("prevPanels", prevPanels.toJS());

  if (fromProcessPanelChange) {
    return {
      activePanel: panel,
      prevPanels,
    };
  }

  await Promise.all([
    await dispatch(setPrevPanel(prevPanels)),
    await dispatch(setActivePanel(panel)),
  ]);
};

/**
 *
 * @param args
 * @returns {Function}
 */
export const goBackAPanel = (args = { fromProcessPanelChange: false }) => async dispatch => {
  yellow(`goBackAPanel( { fromProcessPanelChange: ${args.fromProcessPanelChange} } )`);

  const { fromProcessPanelChange } = args;

  let prevPanels = NavBar().get("prevPanels") || fromJS([]);
  const hook = NavBar().get("leavePanelHook");

  if (hook && typeof hook === "function") {
    red("call changePanel hook");
    await hook(NavBar().get("activePanel"), prevPanels.last());
  }

  const panel = prevPanels.last();
  prevPanels = prevPanels.pop();

  if (fromProcessPanelChange) {
    return {
      activePanel: panel,
      prevPanels,
    };
  }

  await Promise.all([
    await dispatch(setPrevPanel(prevPanels)),
    await dispatch(setActivePanel(panel)),
  ]);
};

export const processPanelChange = ({ panel, prevPanel, onBack }) => async dispatch => {
  if (onBack) {
    return await dispatch(goBackAPanel({ fromProcessPanelChange: true }));
  }

  if (panel && prevPanel) {
    return await dispatch(pushPanel({ panel, fromProcessPanelChange: true }));
  }

  return await dispatch(goToPanel({ panel }));
};

export const changePanel = (panel, prevPanel, onBack) => async dispatch => {
  yellow(`changePanel("${panel}","${prevPanel}",${onBack})`);

  const { activePanel, prevPanels } = await dispatch(
    processPanelChange({ panel, prevPanel, onBack })
  );

  console.log(`activePanel: ${activePanel}`, "prevPanels", prevPanels.toJS());

  if (!activePanel) await dispatch(setActiveNavState(""));
  await Promise.all([
    // Prevents crashes due to elemental editor staying open
    await dispatch(clearEditorPanel()),
    await dispatch(setPreviewLink()),
    await dispatch(setPrevPanel(prevPanels)),
    await dispatch(setActivePanel(activePanel)),
    await dispatch(setActiveNavState(PanelIconMap[activePanel])),
  ]);
};
