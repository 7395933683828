import {StyleSheet} from '@convertly/css';
import UserOverrides from '../../style-functions/user-overrides';
import _ from "lodash";


const VideoPlayerStyles = (global, data, inEditor) => {

  const userOverrides = new UserOverrides(global, data, inEditor);

  let hasPlaceholder = _.get(data, 'placeHolderImage.src');

  return StyleSheet.create({
    videoContainer: {
      height: '100%',
      width: '100%',
      position: 'relative',
      "> .video-container": {
        height: '100%',
        width: '100%',

      },
      "> img": {
        "max-width": "100%",
        "max-height": "100%"
      },
      ...userOverrides
    },
    videoPlaceHolder: {
      height: '100%',
      width: '100%',
      cursor: 'pointer',
      objectFit: 'cover',
    },
    playButton: {
      position: 'absolute',
      height: '100px',
      width: '100px',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      cursor: "pointer",
      '@media( max-width:480px )': {
        height: '60px',
        width: '60px'
      }
    }

  })


};

export default VideoPlayerStyles;
