import {StyleSheet} from '@convertly/css';

const Styles = (global, data) => {

    let signInButton = {
        padding:"15px 25px",
        display: 'inline-block',
        borderRadius: "5px",
        color: global.colors.light.white,
        border: 'none',
        marginBottom: "15px",
        "@media(max-width:767px)": {
            textAlign:'center',
            width: '100%',
            display: 'block',
            margin: '5px auto',
        }

    };

    return StyleSheet.create({

        freeTrialSection: {
            height: '80vh',
            backgroundImage: `linear-gradient(to left, ${global.colors.accent.color1}, ${global.colors.accent.color2}, ${global.colors.accent.color1} ) }`

        },

        phoneOverlay: {
            height: '346px',
            "@media (max-width: 767px)": {
                height: "fit-content"
            },
            ".login-wrapper": {
                width: "100%",
                "@media (max-width:767px)": {
                    width: "375px",
                    margin: '0 auto'
                }
            },
            ".input": {
                ...global.input,
                width: "50%",
                "@media (max-width:767px)": {
                    width: "100%",

                },
                border: 'none',
                borderBottom: '3px solid white',
                height: '40px',
                background: 'transparent',
                color: 'white',
                fontSize: '16px',
                margin: "10px 0px"
            },
            '.input::placeholder': {
                color: 'rgba(255,255,255,0.6)',
                paddingLeft: '5px'
            },
            ".form-error-message": {
                display: 'none',
                fontSize: '10px',
                margin: '0'
            },
            ".show-form-error-message": {
                display: 'block'
            },
            ".mobile-nav-terms-paragraph": {
                fontSize: '12px',
                color: global.colors.light.white
            },
            ".sign-up-buttons": {
                marginTop: '15px'
            },
            ".google-button": {
                ...signInButton,
                background: "#4285f4",
            },
            ".facebook-button": {
                ...signInButton,
                background: "red",

            },
            ".convertly-button": {
                ...signInButton,
                background: '#132755',
                color: '#fff'
            },


        },

        svgStyles: {
            width: "100%",
            height: "600px",
            margin: "0 auto",
            position: "relative",
            top: "-90px",
            "@media (max-width: 767px)": {
                "display": "none"
            }
        },

        phoneScreen: {
            position: 'absolute',
            width: "520px",
            textAlign: "left",
            zIndex: 10,
            left: "0px",
            right: "0px",
            top: "23px",
            height: "486px",
            margin: "0 auto",
            "@media (max-width: 767px)": {
                position: "static",
                width: "auto",
                textAlign:'center'
            }

        },

        phoneHeading: {
            color: "white",
            fontFamily: global.fontFamily.secondaryFont,
            fontSize: '28px',
            borderBottom: "4px solid white",
            padding: "25px 50px",
            "@media (max-width: 767px)": {
                padding: "25px 25px"
            }
        },

        phoneBody: {
            color: "white",
            fontFamily: global.fontFamily.secondaryFont,
            fontSize: '16px',
            lineHeight: "26px",
            padding: "25px 50px",
            "@media (max-width: 767px)": {
                padding: "25px 25px"
            }
        },

        pageInsights: {
            '> form .url-input': {
                width: "100%",
                border: 'none',
                borderBottom: '3px solid white',
                height: '40px',
                background: 'transparent',
                color: 'white',
                fontSize: '16px',
                margin: "35px 0px 10px",
                ":focus": {
                    transition: 'border-bottom 1s ease',
                    borderBottom: `3px solid ${global.colors.accent.color2}`
                },
                "@media (max-width: 767px)": {
                    textAlign: "center"
                }
            },

            '> form .url-input::placeholder': {
                color: 'rgba(255,255,255,0.6)',
                paddingLeft: '5px'
            },

            '> form .submit-button': {
                backgroundColor: "#4D90F9",
                borderRadius: 0,
                border: 'none',
                display: 'block',
                fontSize: "15px",
                marginTop: "8px",
                padding: "15px 25px",
                "@media (max-width: 767px)": {
                    margin:'0 auto'
                }

            },
            ".insights-modal": {
                position: 'fixed',
                top: '18%',
                left: '10%',
                height: '80%',
                width: '80%',
                "@media( max-width:991px )": {
                    width: '90%',
                    left: '5%',
                }

            },
            ".insights": {
                height: '100%',
                width: '100%',
                background: '#fff',
                border: `2px solid ${global.colors.accent.color1}`
            },
            ".insights-modal-close": {
                position: 'absolute',
                top: '2%',
                left: '95%',
                fontSize: '40px',
                color: global.colors.dark.color1,
                cursor: 'pointer',
                "@media( max-width:991px )": {
                    left: '90%',
                }
            }


        },


    })

};

export default Styles;
