/*global Inferno, $, React */

import React from 'react';

const Script = (dispatch, imageUrls) => {
	return (props) => {
		
		const { rootClass, updateFeatureImage, image, product, index, activeImgIndex } = props;
		
		let isActive = index === activeImgIndex;
		
		return (
			<li className={ `${rootClass} thumbnail-wrapper ${isActive ? 'active' : ''}` }
					key={ index }>
				<img onClick={ () => {
					updateFeatureImage(props)
				} }
						 className={ `${rootClass} thumbnail ` }
						 src={ `${imageUrls.thumbnail}${ image.src }` }
						 alt={ image.alt || product.title }/>
			</li>
		)
	}
};

export default Script;
