import { graph } from "./graph";

export async function purge({ url, domain }) {
  const query = `
		mutation($pattern:String!,$domain:String,$url:String) {
  			purgeCache( pattern: $pattern, domain:$domain, url:$url ) {
    			success
    			msg
  			}
		}`;

  const variables = { url, domain, pattern: "" };
  return await graph({ query, variables });
}

export async function cache(pattern) {
  const query = `
		mutation($pattern:String!) {
  			createCache( pattern: $pattern ) {
    			success
    			msg
  			}
		}`;

  const variables = { pattern };
  return await graph({ query, variables });
}

export async function purgeSiteCache(domain) {
  const query = `
		mutation ( $domain:String!) {
		  purgeSite( domain: $domain ) {
				success
				msg
		  }
		}`;

  const variables = { domain };
  return await graph({ query, variables });
}
