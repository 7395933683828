/*global Inferno, $ */
/* eslint-disable */
import {include} from '@convertly/thor';

const Script = () => {

    const Graph = include('../../../../utils/graph', 'Graph');
    const Dispatcher = include('../../../../utils/dispatcher', 'Dispatcher');
    const ConvertToCurrency = include('../../../../utils/ecomm-utils/convert-to-currency-ii', 'ConvertToCurrency');
    const BuildPricingObject = include('../../../../utils/ecomm-utils/inferno-utils/build-product-pricing-object-inferno', 'BuildPricingObject');
    const InfernoHTMLStripper = include('../../../../utils/inferno-html-stripper', 'InfernoHTMLStripper');
    const ProductGridItemInferno = include('./product-grid-item', 'ProductGridItemInferno');
    const SortCategoryBarInferno = include('./components/sort-category-bar', "SortCategoryBarInferno");
    const HandleQueryParams = include('../../../../utils/handle-query-params', 'HandleQueryParams');


    //Controllers
    const ProductGridControllers = include('../../../../inferno-controllers/controller-bundles/product-grid-bundle', 'ProductGridControllers');
    const ProductVariantController = include('../../../../inferno-controllers/product-variant-controller/index', "ProductVariantController");
    const AddToCartButtonController = include('../../../../inferno-controllers/add-to-cart-button-controller', 'AddToCartButtonController');
    const ProductImageGalleryController = include('../../../../inferno-controllers/product-image-gallery-controller', 'ProductImageGalleryController');
    const ProductGridController = include('../../../../inferno-controllers/product-grid-controller', 'ProductGridController');
    const FiltrationController = include('../../../../inferno-controllers/filtration-controller', 'FiltrationController');
    const ProductSortController = include('../../../../inferno-controllers/product-sort-controller', 'ProductSortController');
    const ProductSearchController = include('../../../../inferno-controllers/product-search-controller', 'ProductSearchController');


    ///Components
    const PriceInferno = include('./components/price', "PriceInferno");
    const QuickBuyButtonInferno = include('./components/quick-buy-button', "QuickBuyButtonInferno");
    const AddToCartButtonInferno = include('./components/add-to-cart', "AddToCartButtonInferno");
    const QuickViewModalInferno = include('./components/quick-view-modal', "QuickViewModalInferno");
    const QuickViewIconInferno = include('../../../../svg/quick-view/quick-view-svg', 'QuickViewIconInferno');
    const ProductImageGalleryInferno = include('../../../../ecommerce-common-components/product-image-gallery/product-image-gallery-2/product-image-gallery', "ProductImageGalleryInferno");
    const ImageThumbNailInferno = include('../../../../ecommerce-common-components/product-image-gallery/product-image-gallery-2/img-thumbnail', 'ImageThumbNailInferno');
    const SpinnerSVGInferno = include('../../../../svg/spinner/spinner', 'SpinnerSVGInferno');
    const XIconSVGInferno = include('../../../../svg/x-icon/x-icon', 'XIconSVGInferno');
    const ArrowDownOpenInferno = include('../../../../svg/arrows/arrow-down-stateless', 'ArrowDownOpenInferno');
    const ProductFiltrationInferno = include('../../../../ecommerce-common-components/product-filtration/product-filtration-01/product-filtration-01', 'ProductFiltrationInferno');
    const PriceSliderInferno = include('../../../../ecommerce-common-components/price-slider/price-slider', 'PriceSliderInferno');
    const PaginationInferno = include('../../../../ecommerce-common-components/pagination/Pagination', 'PaginationInferno');
    const SortByMobileModalInferno = include('../../../../ecommerce-common-components/pagination/components/sort-by/SortByMobileModal', 'SortByMobileModalInferno');
    const SortByDropdownInferno = include('../../../../ecommerce-common-components/pagination/components/sort-by/SortByDropdown', 'SortByDropdownInferno');
    const SortByDropdownItemInferno = include('../../../../ecommerce-common-components/pagination/components/sort-by/SortByDropdownItem', 'SortByDropdownItemInferno');
    const SortByDropdownMenuInferno = include('../../../../ecommerce-common-components/pagination/components/sort-by/SortByDropdownMenu', 'SortByDropdownMenuInferno');
    const ProductsPerPageInferno = include('../../../../ecommerce-common-components/pagination/components/products-per-page/ProductsPerPage', 'ProductsPerPageInferno');
    const PageJumpInferno = include('../../../../ecommerce-common-components/pagination/components/page-jump/PageJump', 'PageJumpInferno');
    const PageJumpBySelectionInferno = include('../../../../ecommerce-common-components/pagination/components/page-jump/PageJumpBySelection', 'PageJumpBySelectionInferno');
    const PageJumpSingleInferno = include('../../../../ecommerce-common-components/pagination/components/page-jump/PageJumpSingle', 'PageJumpSingleInferno');
    const SearchBarInferno = include('../../../../ecommerce-common-components/search-bar/SearchBar', 'SearchBarInferno');
    const LoadingSpinnerInferno = include('../../../../ecommerce-common-components/loading-spinner/loading-spinner', 'LoadingSpinnerInferno');
    const SkeletonLoadingLayoutInferno = include('../../../skeleton-loader-layout/skeletonLoadingLayout', 'SkeletonLoadingLayoutInferno');

    //Product Variant Widgets
    const BoxSelectorInferno = include('../../../../ecommerce-common-components/product-variant-widgets/box-selector/box-selector', 'BoxSelectorInferno');
    const ColorPickerInferno = include('../../../../ecommerce-common-components/product-variant-widgets/color-picker/color-picker', 'ColorPickerInferno');
    const DropDownInferno = include('../../../../ecommerce-common-components/product-variant-widgets/drop-down/drop-down', 'DropDownInferno');
    const BubbleVariantContainerInferno = include('../../../../ecommerce-common-components/product-variant-widgets/bubble-variant-container/bubble-variant-container', 'BubbleVariantContainerInferno');
    const BubbleInferno = include('../../../../ecommerce-common-components/product-variant-widgets/bubble/bubble', 'BubbleInferno');
    const VariantProgressBarInferno = include('../../../../ecommerce-common-components/product-variant-widgets/product-variant-progress-bar/product-variant-progress-bar', 'VariantProgressBarInferno');
    const BuyingOptionsWrapperInferno = include('../../../../ecommerce-common-components/buying-options/buying-options-wrapper/buying-options-wrapper', 'BuyingOptionsWrapperInferno');
    const PlusIconInferno = include('../../../../svg/plus-icon/plus-icon', 'PlusIconInferno');
    const MinusIconInferno = include('../../../../svg/minus-icon/minus-icon', 'MinusIconInferno');
    const AddToCartStepperWidget = include('../../../../ecommerce-common-components/buying-options/add-to-cart-stepper/add-to-cart-qty-stepper', "AddToCartStepperWidget");
    const QuickBuyInferno = include('../../../../ecommerce-common-components/buying-options/quick-buy/quick-buy', "QuickBuyInferno");

    //Initial States
    const GetInitialState = include('./product-grid-initial-state', 'GetInitialState');

    return (id,
            productsArr,
            clientUrl,
            imageUrl,
            numOfColumns,
            numberOfRows,
            EcommerceSettings,
            imageUrlsStringified,
            siteId,
            hasVariants,
            disableFiltration,
            disableSort,
            disablePagination,
            slug) => {

        const imageUrls = JSON.parse(imageUrlsStringified);

        //Utils
        const dispatch = Dispatcher(id);
        const infernoHTMLStripper = InfernoHTMLStripper();
        const convertToCurrency = ConvertToCurrency();
        const graph = Graph();
        const getInitialState = GetInitialState();
        const buildPricingObject = BuildPricingObject();

        const handleQueryParams = HandleQueryParams();


        //inferno components
        const ImageThumbNail = ImageThumbNailInferno(dispatch, imageUrls);
        const ArrowDownOpen = ArrowDownOpenInferno();
        const Spinner = SpinnerSVGInferno();
        const XIcon = XIconSVGInferno()
        const PlusIcon = PlusIconInferno();
        const MinusIcon = MinusIconInferno();
        const AddToCartStepper = AddToCartStepperWidget(dispatch, MinusIcon, PlusIcon);
        const QuickBuy = QuickBuyInferno(dispatch);
        const Price = PriceInferno();
        const VariantProgressBar = VariantProgressBarInferno();
        const Bubble = BubbleInferno();
        const BubbleVariantContainer = BubbleVariantContainerInferno(dispatch, VariantProgressBar, Bubble);
        const QuickViewIcon = QuickViewIconInferno();
        const addToCartButtonController = AddToCartButtonController(dispatch);
        const productImageGalleryController = ProductImageGalleryController(dispatch);
        const filtrationController = FiltrationController(dispatch);
        const productGridController = ProductGridController(dispatch, graph, handleQueryParams);
        const productSortController = ProductSortController(dispatch);
        const productSearchController = ProductSearchController(dispatch, graph);
        const ProductImageGallery = ProductImageGalleryInferno(dispatch, '', ImageThumbNail, imageUrls);
        const BuyingOptionsWrapper = BuyingOptionsWrapperInferno(dispatch, AddToCartStepper, QuickBuy);
        const productVariantController = ProductVariantController(graph, dispatch, buildPricingObject);
        const SortByDropdownItem = SortByDropdownItemInferno();
        const SortByDropdownMenu = SortByDropdownMenuInferno(SortByDropdownItem);
        const SortByMobileModal = SortByMobileModalInferno(dispatch);
        const SortByDropdown = SortByDropdownInferno(SortByDropdownMenu, dispatch, ArrowDownOpen, SortByMobileModal);
        const ProductsPerPage = ProductsPerPageInferno(null);
        const PageJumpBySelection = PageJumpBySelectionInferno(id, dispatch, ArrowDownOpen);
        const PageJumpSingle = PageJumpSingleInferno(ArrowDownOpen);
        const LoadingSpinner = LoadingSpinnerInferno(Spinner);
        const SkeletonLoadingLayout = SkeletonLoadingLayoutInferno(imageUrls);
        const PageJump = PageJumpInferno(id, dispatch, PageJumpBySelection, PageJumpSingle);
        const SearchBar = SearchBarInferno(dispatch, Spinner);
        const PriceSlider = PriceSliderInferno(convertToCurrency);

        const QuickViewModal = QuickViewModalInferno({
            ProductImageGallery,
            Price,
            dispatch,
            clientUrl,
            imageUrl,
            infernoHTMLStripper,
            convertToCurrency,
            ImageThumbNail,
            imageUrls,
            BubbleVariantContainer,
            BuyingOptionsWrapper,
            Spinner
        });

        const ProductGridItem = ProductGridItemInferno({
            dispatch,
            QuickViewModal,
            ProductImageGallery,
            AddToCartStepperWidget,
            Price,
            clientUrl,
            imageUrl,
            infernoHTMLStripper,
            convertToCurrency,
            ImageThumbNail,
            imageUrls,
            BubbleVariantContainer,
            Bubble,
            QuickViewIcon,
            addToCartButtonController,
            productImageGalleryController,
            productVariantController,
            BuyingOptionsWrapper,
            disableFiltration,
            disableSort,
            disablePagination,
            Spinner
        });


        const ProductFiltration = ProductFiltrationInferno(
            dispatch,
            ArrowDownOpen,
            PriceSlider,
            SearchBar,
            XIcon
        );

        const Pagination = PaginationInferno(PageJump);

        const hasLocalStorage = $('html').hasClass('localstorage');

        const root = document.getElementById(id);
        root.innerHTML = '';

        const metaDiv = document.getElementById('productMeta');
        const filtrationMeta = JSON.parse(metaDiv.dataset.filtration);
        const products = JSON.parse(metaDiv.dataset.products);
        const defaultCategories = metaDiv.dataset.categories === undefined ? undefined : JSON.parse(metaDiv.dataset.categories)

        //Build default category ids for query
        const defaultCategoryIds = [];
        defaultCategories.map(cat => {
            defaultCategoryIds.push(cat.defaultCategoryId);
        });


        //metaDiv.remove();

        const ecommerceSettings = JSON.parse(EcommerceSettings);


        const ProductGallery01 = Inferno.createClass({

            getInitialState: function () {
                this.ProductVariantController = new productVariantController(this);
                this.FiltrationController = new filtrationController(this, id);
                this.ProductGridController = new productGridController(this, id, siteId, defaultCategoryIds);
                this.ProductSortController = new productSortController(this, id);
                this.ProductSearchController = new productSearchController(this, id);

                return getInitialState(products, filtrationMeta, numberOfRows, numOfColumns);
            },
            /** Converts products array into a 3d array
             allowing to create arrays and columns
             **/
            splitIntoPaginationArrays: function () {
                let pageArray = [];
                let rowArray = [];
                let productArray = [];

                this.state.products.map((product, index) => {
                    if ((index + 1) % numOfColumns === 0 && index !== this.state.products.length - 1) {
                        productArray.push(product);
                        rowArray.push(productArray);
                        productArray = [];
                        if (parseInt(index + 1) === parseInt(products.length)) {
                            pageArray.push(rowArray);
                            rowArray = [];
                        }
                        else if (parseInt(rowArray.length) === parseInt(numberOfRows)) {
                            pageArray.push(rowArray);
                            rowArray = [];
                        }
                    }
                    else {
                        productArray.push(product);
                        if (parseInt(index + 1) === parseInt(this.state.products.length)) {
                            rowArray.push(productArray);
                            pageArray.push(rowArray);
                            rowArray = [];
                            productArray = [];
                        }
                    }
                });

                this.setState({pageArray: pageArray});
                this.forceUpdate();
            },

            toggleRefineModal: function () {
                let showRefineModal = this.state.showRefineModal;
                this.setState({showRefineModal: !showRefineModal});

                if (!showRefineModal) {
                    dispatch.send('fix-body-position', true);
                } else {
                    dispatch.send('fix-body-position', false);
                }
            },

            toggleSortByMobileModal: function () {
                let showSortByMobileModal = this.state.showSortByMobileModal;
                this.setState({showSortByMobileModal: !showSortByMobileModal})

                // if (!showSortByMobileModal) {
                //     dispatch.send('fix-body-position', true);
                // } else {
                //     dispatch.send('fix-body-position', false);
                // }
            },


            componentDidMount() {
                this.ProductGridController.initDispatchers(this.state.products);
                this.ProductSortController.initDispatchers();
                this.ProductSearchController.initDispatchers();
                this.splitIntoPaginationArrays(this.state.products);
                this.ProductGridController.loadAllProducts();
                this.FiltrationController.handlePriceSlider();
                this.ProductGridController.handleQueryParams();
            },


            calculatePageCount() {
                console.log(numberOfRows * numOfColumns, "ROW/COLUMN", this.state.filtrationMeta.productCount)
                return Math.ceil(this.state.filtrationMeta.productCount / (numOfColumns * numberOfRows))
            },

            render: function () {
                const {products, pageArray} = this.state;
                const rootClass = root.className;
                let renderFilter = hasVariants == "true" && disableFiltration !== "true";

                const renderPagination = disablePagination !== "true" && this.state.filtrationMeta.productCount > this.state.productLimit && disablePagination !== "true"

                if (this.state.queryLoading && slug === "/products") {
                    return (
                        <SkeletonLoadingLayout
                            rootClass={rootClass}
                            numOfColumns={numOfColumns}/>
                    )
                } else {
                    return (
                        <div>

                            <div className={ `${rootClass} containerOverride container` }>
                                {
                                    renderFilter && this.state.filtrationMeta &&

                                    <ProductFiltration
                                        rootId={id}
                                        addFilter={this.FiltrationController.addFilter}
                                        removeFilter={this.FiltrationController.removeFilter}
                                        activeFilters={this.state.activeFilters}
                                        filtrationMeta={this.state.filtrationMeta}
                                        rootClass={rootClass}
                                        startMaxPrice={this.state.startMaxPrice}
                                        startMinPrice={this.state.startMinPrice}
                                        updateSearchQuery={this.ProductSearchController.updateSearchQuery}
                                        getSearchResults={this.ProductSearchController.getSearchResults}
                                        searchQuery={this.state.searchQuery}
                                        updateMaxPrice={this.FiltrationController.updateMaxPrice}
                                        updateMinPrice={this.FiltrationController.updateMinPrice}
                                        addMinPriceFilter={this.FiltrationController.addMinPriceFilter}
                                        addMaxPriceFilter={this.FiltrationController.addMaxPriceFilter}
                                        currentMaxPrice={this.state.currentMaxPrice}
                                        currentMinPrice={this.state.currentMinPrice}
                                        loadingProducts={this.state.loadingProducts}
                                        liveSearchResults={this.state.liveSearchResults}
                                        toggleRefineModal={this.toggleRefineModal}
                                        showRefineModal={this.state.showRefineModal}
                                        handleHeaderSearchQueryChange={this.ProductSearchController.redirectToSearchQuery}
                                        clearMinMaxPrice={this.FiltrationController.clearMinMaxPrice}
                                        toggleDropDown={this.FiltrationController.toggleDropDown}
                                    />
                                }
                                <div
                                    className={`${rootClass} product-grid-container ${renderFilter ? "with-filter" : ""}`}>
                                    {
                                        // Pagination:
                                        //
                                        // Simple logic that will only render pagination if it is enabled
                                        // and there are more than one page.
                                        // The logic is based on the amount of product and the limit of
                                        // products per page. If There is a greater number of products than
                                        // there are limited, then there will be more than one page.
                                        
                                        disablePagination !== 'true' &&
                                        <div className={`${ rootClass } pagination-container ${ this.state.showSortByMobileModal ? 'sort-by-active' : '' }`}>

                                            <div className={`${ rootClass } mobile-refine-sort-by-wrapper`}>
                                                <div
                                                    onClick={this.toggleRefineModal}
                                                    className={`${ rootClass } refine-placeholder-container`}>
                                                    <div className={`${ rootClass } refine-placeholder-button`}>
                                                        <div
                                                            className={`${ rootClass } refine-placeholder-text mobile`}>
                                                            Refine
                                                            <div
                                                                className={`${ rootClass } number-of-user-filters-container`}>
                                                                {
                                                                    this.state.activeFilters.length != 0 &&
                                                                    <div
                                                                        className={`${ rootClass } number-of-user-filters`}>
                                                                        {this.state.activeFilters.length}
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    disableSort !== 'true' &&
                                                    this.state.sortMeta &&
                                                    <SortByDropdown
                                                        viewport='mobile'
                                                        sortMeta={this.state.sortMeta}
                                                        toggleSwitch={this.ProductSortController.toggleSwitch}
                                                        rootClass={rootClass}
                                                        toggleSortByMobileModal={this.toggleSortByMobileModal}
                                                        showSortByMobileModal={this.state.showSortByMobileModal}/>
                                                }
                                            </div>

                                            <div className={`${rootClass} pagination-wrapper`}>
                                                {
                                                    disableSort !== 'true' &&
                                                    this.state.sortMeta &&
                                                    <SortByDropdown
                                                        sortMeta={this.state.sortMeta}
                                                        toggleSwitch={this.ProductSortController.toggleSwitch}
                                                        rootClass={rootClass}/>
                                                }
                                                {
                                                    renderPagination &&
                                                    this.state.filtrationMeta.productCount > this.state.productLimit &&
                                                    <Pagination
                                                        rootClass={rootClass}
                                                        sortMeta={this.state.sortMeta}
                                                        pages={this.calculatePageCount()}
                                                        currentPage={this.state.currentPage}
                                                        footer={false}
                                                        toggleDropDown={this.FiltrationController.toggleDropDown}/>
                                                }
                                            </div>
                                        </div>
                                    }

                                    {(pageArray !== undefined && pageArray.length !== 0) ?
                                        pageArray.map((row, idx) => {
                                            return (

                                                <div key={ `outer-row-${idx}` }>
                                                    {row.map((row, idx) => {
                                                        return (
                                                            <div 
                                                              key={ `middle-row-${idx}` }
                                                              className="row">
                                                                {row.map((product) => {
                                                                    const pricing = product.pricing;
                                                                    const {title} = product;
                                                                    const url = {
                                                                        linkType: 'internal',
                                                                        url: product.slug
                                                                    };

                                                                    return (

                                                                        <ProductGridItem
                                                                            index={idx}
                                                                            product={product}
                                                                            pricing={pricing}
                                                                            title={title}
                                                                            findActiveProductVariants={this.ProductVariantController.findActiveProductVariants}
                                                                            handleProductVariantArray={this.ProductVariantController.handleProductVariantArray}
                                                                            findOutOfStockVariants={this.ProductVariantController.findOutOfStockVariants}
                                                                            updateOutOfStockArray={this.ProductVariantController.updateOutOfStockArray}
                                                                            checkProductOptionSelection={this.ProductVariantController.checkProductOptionSelection}
                                                                            renderProductVariantWidget={this.ProductVariantController.renderProductVariantWidget}
                                                                            initProductVariantDispatchers={this.ProductVariantController.initDispatchers}
                                                                            columns={numOfColumns}
                                                                            url={`${clientUrl}${url.url}`}
                                                                            imgUrl={product.images[0]}
                                                                            imgUrls={imageUrls}
                                                                            rootDiv={root}/>

                                                                    )
                                                                })
                                                                }
                                                            </div>
                                                        )

                                                    })}
                                                </div>

                                            )
                                        }) :
                                        (
                                            <div className={`${ rootClass } no-results-page-container`}>
                                                <div className={`${ rootClass } get-nooped`}>
                                                    Nope.
                                                </div>
                                                <div className={`${ rootClass } why-you-got-nooped`}>
                                                    Your search returned zero results. Please try again.
                                                </div>
                                            </div>
                                        )

                                    }
                                    {
                                        disablePagination !== 'true' &&
                                        this.state.filtrationMeta.productCount > this.state.productLimit &&
                                        <div className={`${ rootClass } pagination-wrapper-mobile`}>
                                            <Pagination
                                                rootClass={rootClass}

                                                sortMeta={this.state.sortMeta}
                                                pages={Math.ceil(this.state.filtrationMeta.productCount / (numOfColumns * numberOfRows))}
                                                currentPage={this.state.currentPage}
                                                ProductSortController={this.ProductSortController}
                                                footer={true}
                                                toggleDropDown={this.FiltrationController.toggleDropDown}/>
                                        </div>
                                    }
                                </div>

                            </div>
                            {slug !== '/products' && this.state.queryLoading &&
                            <div className={`${ rootClass } applying-filters-loader-container`}>
                                <div className={`${ rootClass } applying-filters-loader-wrapper`}>
                                    <div className={`${ rootClass } applying-filters-text`}>
                                        { dispatch.get(`${id}-active-filters`).length === 1
                                            && dispatch.get(`${id}-active-filters`)[0].FilterType === "filter" ?
                                             "Applying Filters" : "Loading Products" }
                                    </div>
                                    <div className={`${ rootClass } applying-filters-dot-container`}>
                                        <div className={`${ rootClass } dot dot1`}/>
                                        <div className={`${ rootClass } dot dot2`}/>
                                        <div className={`${ rootClass } dot dot3`}/>
                                    </div>
                                </div>
                            </div>

                            }
                        </div>
                    )
                }
            }

        });

        Inferno.render(<ProductGallery01/>, root);

    };


};

export default Script;
