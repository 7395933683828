import React from 'react';
import Component from 'source/rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';

//Default Props

//Utils
import getHeaderPosition from '../header-style-functions/get-header-position';

//import defaultData from '';
import defaultData from 'source/modules/header/header-default-data';
import defaultGlobal from 'source/themes/global';

//Styles
import Styles from './header-07-styles';

//Components
import Elements from 'source/elements';
import Nav07 from './components/nav-07';
import ModuleWrapper from "../../../elements/module-wrapper/module-wrapper";

//Scripts
import absoluteToFixed from '../scripts/absolute-to-fixed-header';

export class Header07 extends Component {

    constructor(props) {

        super(props);

    }

    render() {

        let { data, global, editor, id, sort, site, page } = this.props;

        let inEditor = this.inEditor();

        let headerPosition = getHeaderPosition(data.position, page, inEditor);

        this.styles = Styles(global, data, headerPosition, inEditor);

        //console.log('styles in header', this.styles.mainHeader);

        let headerID = data.position === "overlayFixed" ? id(absoluteToFixed) : "header";

        /**
         * Fixed Header Fix
         */
        let fixedHeaderFix;

        if (headerPosition === "fixed") {
            fixedHeaderFix =
                <div id="test" className={css(this.styles.fixedHeaderFix)}/>;
        }
        else {
            fixedHeaderFix = <div/>
        }

        /**
         * Editor
         */
        if (editor) {
            return this.editor();
        }

        if (headerPosition === "noHeader") {
            return null
        }
        else {
            return (
                <Elements.ModuleWrapper
                    parentClassName={ this.styles.heroContainer }
                    data={ data }
                    global={ global }
                    elementDataName="backgroundColor"
                    moduleId={ id }
                    site={ site }
                    moduleSort={ sort }>

                    <div id={ headerID }>

                        <header id="header07"
                                className={css(this.styles.header)}
                                data-elementType="header"
                                itemProp="siteHeader"
                                itemScope
                                itemType="http://schema.org/siteHeader">

                            <Nav07
                                global={ global }
                                data={ data }
                                styles={ this.styles }
                                site={ site }
                                headerPosition={ headerPosition }
                                inEditor={ inEditor }/>

                        </header>

                        { fixedHeaderFix }

                    </div>


                </Elements.ModuleWrapper>
            )
        }
    }
}


Header07.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
