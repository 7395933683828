import React from 'react';
import Component from 'source/rootComponent';
import { css } from '@convertly/css';
import generateId from '../../utils/generate-id';

// Styles
import FooterWrapperStyles from './FooterWrapperStyles';

export class FooterWrapper extends Component {

  render() {
    
    const inEditor = this.inEditor();
    const { global, data } = this.props;
    const { animated } = data || {};

    this.styles = FooterWrapperStyles({ global, data, inEditor });

    const { footer } = this.styles;

    let animatedClassName = '';

    if(this.animatedClassName) {
      animatedClassName = `${ this.animatedClassName } `
    }

    const inlineStyles = this.generateStyleAttribute();

    return (
      <footer
        { ...generateId(this.props.elementId) }
        { ...inlineStyles }
        data-animated={ animated }
        { ...this.generateDataEditorAttribute() }
        { ...this.generateCustomAttributes() }
        className={ `${ animatedClassName } ${ css(footer) }` }>

        { this.renderChildren(this.props) }

      </footer>
    )
  }

  renderChildren = (props) => {
    return React.Children.map(props.children, child => {
      return React.cloneElement(child, {
        moduleId: props.moduleId,
        moduleSort: props.moduleSort,
        global: props.global,
        inEditor: this.inEditor()
      });
    }) 
  }

}