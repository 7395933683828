import generatePhoneNumberLink from './generatePhoneNumberLink';
const { getSite } = require( '@convertly/utils' );



export function generateLink( link ) {
	
	const site = getSite();

	if( ! link ) {
		return '';
	}

	if( link.linkType === 'none' ) {
		return '#';
	}

	// hack to return external links
	if( link.linkType === 'external' ) {
		return link.url;
	}
	// in editor site is still null
	if( link.linkType === 'internal' ) {
		return `${link.url}`;
	}
	if ( link.linkType === 'phone') {
		return generatePhoneNumberLink( `${link.url}` );
	}
    if ( link.linkType === 'email') {
        return 'mailto:' + link.url;
    }
    if( link.linkType === 'section' ) {
		if(link.url[0] === "#") return link.url;
    	return `#${link.url}`;
	}
    //TODO: remove all inferno links
    if ( link.linkType === 'inferno' ) {
    	return ``;
	}
	if ( link.linkType === 'pdf' ) {
		if(link.url[0] === "/") {
			link.url[0] = '';
		}
		return `https://s3.amazonaws.com/convertlyimguploadeast/${site.siteId}/${link.url}`
	}
	if( link.linkType === 'googleMap' ) {
		return `http://maps.google.com/?q=${link.url}`
	}
	else {
		return link.url;
	}
}


export function getProtocol() {

    return protocol
}
