import { StyleSheet } from "@convertly/css";
import UserOverrides from '../style-functions/user-overrides';


const linkStyles = ( global, data, isChild, inEditor ) => {

    let userOverRides;
    if(!isChild) {
        userOverRides = new UserOverrides(global, data, inEditor);
    }
    else {
        userOverRides = {};
    }

    return StyleSheet.create({
        link: {
            //...global.p,
            ...isChild ? {display:'inline-block'} : {},
            fontWeight:'inherit',
            ...userOverRides
        }
    });

};

export default linkStyles;

