const googleStyles = ( global, data ) => {

    return {
        ".abcRioButtonContentWrapper": {
            border: '1px solid #4285f4',
            backgroundColor: '#4285f4',
            color: '#fff',
            cursor: 'pointer',
            margin:'0 auto',
            fontFamily:"'Roboto', sans-serif",
            transition:"all 400ms ease",
            "@media(min-width:991px)": {
                ":hover": {
                    boxShadow:"0 0 3px 3px rgba(66,133,244,.3)"
                }
            }

        },

        ".abcRioButtonIcon": {
            backgroundColor: '#fff',
            borderRadius: '1px',
            padding:'11px',
            width:'fit-content',
            display:'inline-block',
            float:'left'
        },

        ".abcRioButtonContents": {
            fontSize: '12px',
            lineHeight: '28px',
            fontFamily: 'Roboto,arial,sans-serif',
            fontWeight: 500,
            letterSpacing: '.21px',
            marginLeft: '6px',
            marginRight: '6px',
            verticalAlign: 'top'
        },
    }

};

export default googleStyles