//import $ from 'jquery';
//toggles nav's drop down icons
const tabsHandler = ( id ) => {

    return ( id ) =>  {

        //set up click event listener on the id
        const el = '#' + id ;
        const tabLink = $( el +  ' > li' );
        const tabLinkMobile = $( '.tabLinkMobile' );

        tabLink.on('click', function(e) {

            e.preventDefault;
            changeTab(this, tabLink)
        });
        tabLinkMobile.on('click', function(e) {
            e.preventDefault;
            changeTab(this, tabLinkMobile)
        });
        function changeTab(element, list) {
            let target = element.dataset.target;
            list.removeClass('active');
            $(element).addClass('active');
            $('.tab').removeClass('active');
            $(target).addClass('active');
        }
    }
};
export default tabsHandler;
