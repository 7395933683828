import PropTypes from 'prop-types';
import React from "react";
import {saveCategory, toggleDeleteCategoryModal} from "containers/StoreFront/CategoryDetail/actions";
import PillButton from "../../Button/pillButton";
import Options from "../../../containers/Editor/components/editor/options";
import {connect} from 'react-redux'
import {graph} from "app/utils";


class ProductDetailMenuComp extends React.Component {
  /**
   *
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {
      showLoadIcon: false
    };
    this.save = this.save.bind(this);
    this.viewCategory = this.viewCategory.bind(this);
  }

  async save(e) {
    const { site, category } = this.props;

    this.setState({ showLoadIcon: true });

    const mutation = `mutation($id:ID! $productCategory:productCategoryInput) {
          editProductCategory(id:$id, productCategory:$productCategory) {
            id
            name
            description
            image
          }
      }`

    const variables = {
      id: category.id,
      productCategory: {
        siteId: site.get("siteId"),
        name: category.name || "",
        description: category.description || "",
        image: category.image || ""
      }
    }

    this.setState({ showLoadIcon: true });
    let res = await graph( {query:mutation, variables} );
    this.setState({ showLoadIcon: false });

  }

  viewCategory() {

    window.open(generateUrl(this.props.site, this.props.category), '_blank');

    //gets link to category page
    function generateUrl(site, category) {

      return `${getSiteUrl(site)}${getCategorySlug(category)}`
    }
    //gets url for site and formats it
    function getSiteUrl(site) {
      return `http://${site.get('previewDomain')}.convertly.io`
    }
    //gets category url
    function getCategorySlug(category) {
      return category.slug
    }
  }

  /**
   *
   * @returns {*}
   */
  render() {
    const inlineCss = {
      spacing: {
        marginRight: "20px"
      },
      button: {
        cursor: "pointer"
      },
      container: {
        width: "100%"
      }
    };

    const options = [
      {
        optionsItemTitle: "View",
        optionsCallback: this.viewCategory
      },
      {
        optionsItemTitle: "Delete",
        optionsCallback: this.props.toggleDeleteCategoryModal
      }
    ];

    return (
      <div style={inlineCss.container}>
        <span style={inlineCss.spacing}>Storefront</span>
        <PillButton
          label="Save"
          backgroundColor="#52AF52"
          textColor="#FFF"
          action={this.save}
          showLoadIcon={this.state.showLoadIcon}
          width={70}
        />
        <Options options={options} button resetIndex={() => {}} />
      </div>
    );
  }
}

ProductDetailMenuComp.contextTypes = {
  store: PropTypes.object
};

const  mapStateToProps = (state) => {

  return { category:state.get("productCategory").Category.toJS().category }
}
const ProductDetailMenu = connect(mapStateToProps)(ProductDetailMenuComp)
export default ProductDetailMenu;
