import React from "react";
import { createAction } from "redux-act";
import _ from "lodash";
import { fromJS } from "immutable";

import { Emitter } from "services/Emitter";
import { Alerts } from "services/Alerts";
import { Notifications } from "services/Notifications";
import { store } from "app/app";
import { graph } from "utils/graph";
import { uploadImages } from "services/sites";
import { delay } from "utils";
import { clientImage } from "source";
import { copyToClipboard } from "utils/copyToClipboard";
import { activeSite } from "utils";

import { showLoadingIcon } from "containers/App/actions";

import { openImageEditor } from "containers/Editor/actions/editorActions";

export const setFiles = createAction("Files: Set Files array");
export const setSortBy = createAction("Files: Set sort by option");
export const setSortAscending = createAction("Files: Set sort ascending option");
export const setLoading = createAction("Files: Set loading flag");

const getSiteId = () => activeSite().get("id");

const getPdfLink = file =>
  `https://s3.amazonaws.com/convertlyimguploadeast/${getSiteId()}/${file.get("filename")}`;

export const downloadFile = file => async dispatch => {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", getFileLink(file), true);
  xhr.responseType = "blob";
  xhr.onload = function() {
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(this.response);
    const tag = document.createElement("a");
    tag.href = imageUrl;
    tag.download = file.get("filename");
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };
  xhr.send();
};

const getFileLink = file => {
  let url = `https:${clientImage(file.get("filename"), {}, getSiteId())}`;

  if (file.get("isPDF")) {
    url = getPdfLink(file);
  }

  return url;
};

export const copyLink = file => async dispatch => {
  copyToClipboard(getFileLink(file));
};

export const updateSort = field => async dispatch => {
  const currentField = store.getState().getIn(["Files", "sortBy"]);
  const ascending = store.getState().getIn(["Files", "sortAscending"]);

  if (field !== currentField) {
    await dispatch(setSortBy(field));
    await dispatch(setSortAscending(true));
  } else {
    await dispatch(setSortAscending(!ascending));
  }

  Emitter.emit("scroll");
};

export const uploadFiles = files => async dispatch => {
  if (!_.isArray(files)) {
    files = [files];
  }

  const id = await Notifications.info({
    title: "Uploading",
    message: `Uploading ${files.length} files`,
    spin: true,
    icon: "cog",
    sticky: true,
  });

  const uploads = [];

  for (let i = 0; i < files.length; i++) {
    uploads.push(dispatch(uploadImages(files[i], false)));
  }

  await Promise.all(uploads);

  await dispatch(fetchFiles());

  await Notifications.clear(id);

  await Notifications.success({
    title: "Success",
  });
};

export const renameFile = file => async dispatch => {
  const message = (
    <div>
      <div>
        <b>Warning</b>
      </div>
      <span>
        This feature is experimental and does not alter existing uses of your file. You will need to
        edit your site and replace the old references of the file with the new file name.
      </span>
      <div style={{ marginTop: 10 }}>Do you want to continue?</div>
    </div>
  );

  if (!(await Alerts.confirm(message, { label: "Continue" }))) {
    return;
  }

  const files = store.getState().getIn(["Files", "files"]) || fromJS([]);

  const parts = file.get("filename").split(".");

  const ext = parts[1];
  const filename = parts[0];

  let newFileName = await Alerts.prompt("Enter new file name", {
    value: filename,
    placeholder: filename,
    label: "Rename",
    validations: [
      val =>
        !!val &&
        val.toLowerCase().trim() !== filename.toLowerCase().trim() &&
        /^[a-zA-Z0-9\-\_]+$/.test(val.trim()),
      val =>
        !!val &&
        files.filter(file => file.get("filename").trim() === `${val}.${ext}`.trim()).size === 0,
    ],
  });

  if (newFileName === false) {
    return;
  }

  newFileName = (newFileName.trim() + "." + ext).toLowerCase();

  const id = await Notifications.add({
    type: "warning",
    title: "Processing",
    message: `Renaming ${file.get("filename")} to ${newFileName}`,
    icon: "cog",
    spin: true,
  });

  const query = `
		mutation($siteId:ID!,$fileId:ID!,$filename:String!){
			renameFile(siteId:$siteId,fileId:$fileId,filename:$filename){
				success, message
			}
		}
	`;

  const variables = {
    siteId: getSiteId(),
    fileId: file.get("id"),
    filename: newFileName,
  };

  const res = await graph({ query, variables });

  const response = _.get(res, "renameFile") || {};

  await Notifications.clear(id);

  if (!response.success) {
    await Notifications.add({
      type: "danger",
      title: "Error",
      message: response.message,
      icon: "exclamation",
    });

    return;
  }

  await Notifications.add({
    type: "success",
    title: "Success",
    icon: "thumbs-up",
  });

  await dispatch(fetchFiles());
};

export const editImage = file => async dispatch => {
  await dispatch(
    openImageEditor({
      params: {},
      cb: async function(params, src) {
        await dispatch(fetchFiles());
      },
      src: file.get("filename"),
    })
  );
};

const deleteFileRequest = async fileId => {
  const query = `
	mutation($fileId:ID!,$siteId:ID!) {
		deleteFile(fileId:$fileId,siteId:$siteId) {
			success, message
		}
	}
	`;

  const variables = {
    siteId: getSiteId(),
    fileId,
  };

  return graph({ query, variables });
};

export const deleteFiles = files => async dispatch => {
  await Promise.all(files.map(file => deleteFileRequest(file)));
  await dispatch(fetchFiles());
};

export const deleteFile = file => async dispatch => {
  const message = (
    <span>
      Permanently delete <b>{file.get("filename")}</b>
    </span>
  );

  if (!(await Alerts.confirm(message, { label: "Delete" }))) {
    return;
  }

  const id = await Notifications.add({
    type: "warning",
    sticky: false,
    title: "In progress",
    message: `deleting ${file.get("filename")}`,
    icon: "cog",
    spin: true,
  });

  const query = `
	mutation($fileId:ID!,$siteId:ID!) {
		deleteFile(fileId:$fileId,siteId:$siteId) {
			success, message
		}
	}
	`;

  const variables = {
    siteId: getSiteId(),
    fileId: file.get("id"),
  };

  let files = store.getState().getIn(["Files", "files"]) || fromJS([]);

  files = files.map(f => {
    if (f.get("id") === file.get("id")) {
      return f.set("deleting", true);
    }
    return f;
  });

  await dispatch(setFiles(files));

  const res = await graph({ query, variables });

  await delay(2000);

  if (!_.get(res, "deleteFile.success")) {
    await dispatch(showLoadingIcon(false));
    await Alerts.alert(_.get(res, "deleteFile.message"));
    return;
  }

  await Notifications.clear(id);

  await Notifications.add({
    type: "danger",
    sticky: false,
    title: "Success",
    message: `${file.get("filename")} has been deleted`,
    icon: "trash",
    spin: false,
  });

  files = store.getState().getIn(["Files", "files"]) || fromJS([]);

  files = files.filter(f => f.get("id") !== file.get("id"));

  await dispatch(setFiles(files));
};

export const fetchFiles = () => async dispatch => {
  await dispatch(setLoading(true));

  const query = `
	query($siteId:ID!) {
		Site(id:$siteId) {
			images {
				id, filename, updatedAt, size
			}
		}
	}
	`;

  const variables = {
    siteId: getSiteId(),
  };

  const res = await graph({ query, variables });

  let images = _.get(res, "Site.images") || [];

  images = images.map(image => {
    if (image.filename.indexOf(".pdf") !== -1) {
      image.preview = image.filename.replace(".pdf", "-preview.jpg");
      image.isPDF = true;
    } else {
      image.preview = image.filename;
    }
    image.fullUrl = getFileLink(fromJS(image));
    return image;
  });

  await dispatch(setFiles(fromJS(images)));

  await dispatch(setLoading(false));
};

export const loadFiles = () => async dispatch => {
  await dispatch(showLoadingIcon(true));

  await dispatch(fetchFiles());

  await dispatch(showLoadingIcon(false));
};
