/*global Inferno, $, TodoInput */
/* eslint-disable */

import {include} from '@convertly/thor'
import Graph from '../../../utils/graph'

const ContestFormInferno = () => {

    const ContestFormInferno = include('./ContestForm', 'ContestFormInferno');
    const Graph = include('../../../utils/graph', 'Graph')

    return (id, siteId, allowDescription, tabs) => {

        const allowPhotoDescription = allowDescription === "true";
        const root = document.getElementById(id);
        const {contestString} = root.dataset;
        const contest = JSON.parse(contestString);

        const ContestForm = ContestFormInferno();
        const graph = Graph()

        const ContestFormWrapper = Inferno.createClass({

            getInitialState: function () {
                this.submitFormData = this.submitFormData.bind(this)
                this.sendSubmission = this.sendSubmission.bind(this)

                return {}

            },


            updateFileName(e) {
                if(e?.target?.value) {
                    this.setState({filename: e.target.value.replace(`C:\\fakepath\\`, "")});
                }
                else {
                    this.setState({filename:""});
                }

            },

            uploadFile(file) {

                return new Promise((resolve, reject) => {

                    const formData = new FormData();
                    let res;
                    formData.append('file', file)
                    formData.append('siteId', siteId)
                    formData.append('contestId', `${contest.id}`)


                    const params = Object.assign(formData, {sideId: siteId, contestId: contest.id});

                    fetch("https://uploader.convertly.com/contests", {
                        method: "POST",

                        body: formData
                    }).then(response => resolve(resolve(response.json()))
                    ).catch(
                        () => {
                            return false
                        }
                    )

                })


            },

            removeFile() {
                document.getElementById("contestFileUpload").value = null;
                //send fake event to set filename to null
                this.updateFileName({
                    target: {
                        value: null
                    }
                })
            },


            sendSubmission(submission) {
                submission.contestId = contest.id;
                submission.siteId = siteId;

                const query = `mutation($submission:ContestSubmissionInput) {
                                  createContestSubmission(submission:$submission){
                                    success
                                    message
                                  }
                                }`
                const variables = {
                    submission
                }

                const cb = (error, data) => {

                    this.setState({completedSuccess: true})
                }

                graph({query, variables, cb})
            },

            submitFormData(e) {
                e.preventDefault()
                this.setState({pending:true})
                const formElements = e.target.elements
                let submission = {};
                let file;
                const that = this;
                //the "name" attribute is designed to be the key
                //that the api is expecting.
                //in this way we can make a quick object to send to the graph
                for (key of formElements) {

                    //use type of "file to extract the file
                    if (key.type === "file") {
                        file = key.files[0]
                    } else if (key.type === "checkbox") {
                        //value is coming in as a string of true
                        //this check ensures that
                        submission[key.name] = key.value === "true"
                    }
                    //ensure we skip submit buttons
                    else if (key.type === "submit") {

                    } else {
                        submission[key.name] = key.value
                    }
                }

                this.uploadFile(file).then(function handleUploadSuccess(res) {
                    submission.imageUrl = res.filename
                    that.sendSubmission(submission)
                })
            },

            render: function () {

                return (
                    <ContestForm
                        uploadFile={this.uploadFile}
                        updateFileName={this.updateFileName}
                        allowPhotoDescription={allowPhotoDescription}
                        filename={this.state.filename}
                        submitFormData={this.submitFormData}
                        completedSuccess={this.state.completedSuccess}
                        tabSettings={contest.tabSettings}
                        status={contest.status}
                        pending={ this.state.pending }
                        removeFile={this.removeFile}
                    />
                )
            }

        });

        Inferno.render(<ContestFormWrapper/>, root);

    };

};

export default ContestFormInferno
