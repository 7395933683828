import React from "react";
import { connect } from "react-redux";
import { Alerts as Component } from "./Alerts";

import { setSetting } from "../App/actions";

const mapStateToProps = state => {
  return {
    alert: state.getIn(["global", "alert"]),
    settings: state.getIn(["global", "settings"])
  };
};

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
    updateSetting: async (field, value) => await dispatch(setSetting({ field, value }))
  };
};

export const AlertsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);
