import React from 'react';
import Component from '../../../../rootComponent';
import {css} from "@convertly/css";

import Types from '../../../../attribute-types';
import {id} from '@convertly/thor';

//Scripts
import toggleChildrenOpen from '../../scripts/hidden-nav-toggle';

//Styles
import desktopNavStyles from './header-nav-styles-1.js';

//Default Props
import defaultData from '../../common/header-nav-default-data';
import defaultGlobal from '../../../../themes/global.js';

//Components
import HeaderNavList1 from '../../common/header-nav-list/header-nav-list-1';

export default class HeaderNav1 extends Component {

    constructor(props) {

        super(props);

    }

    render() {

        this.styles = desktopNavStyles(this.props.global, this.props.data);

        return (
            <div className={ css(this.styles.desktopNavbar) }>

                <div
                    className={ css(this.styles.mobileNavIconContainer) + " col-xs-2 col-lg-1"}
                >
                    <button id={ id(toggleChildrenOpen) }
                            data-target="#mobileNavContainer"
                            type="button"
                            className={ css(this.styles.navLaunch) }>
                        <span className="sr-only">Toggle navigation</span>
                        <span
                            className={ css(this.styles.iconBar) }
                            data-target="#sideMenu">
                                            </span>
                        <span
                            className={ css(this.styles.iconBar) }
                            data-target="#sideMenu">
                                            </span>
                        <span
                            className={ css(this.styles.iconBar) }
                            data-target="#sideMenu">
                                            </span>
                    </button>
                </div>
                <div className="col-md-3">
                    <div className={ css(this.styles.navLogo) }>
                        <a href={ this.props.data.logoLink }>
                            { this.props.site.logo &&
                            <img src={ this.props.site.logo.src }
                                 alt={ this.props.site.logo.alt }
                                 title={ this.props.site.logo.title }
                                 className={ css(this.styles.logoImg) }

                            />
                            }
                        </a>
                    </div>
                </div>

                <div
                    className={ css(this.styles.mobileNavContainer) }
                    id="mobileNavContainer"
                >
                    <HeaderNavList1 data={ this.props.data.menu }
                                    global={ this.props.global }
                                    styles={ this.styles }/>

                </div>
            </div>
        )
    }
}

HeaderNav1.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
