import React from "react";
import Component from "source/rootComponent";
import { css } from "@convertly/css";
import _ from "lodash";

// Style
import { DivWrapperStyles } from "./DivWrapperStyles";

// Util
import renderChildren from "../util/renderChildren";

export class DivWrapper extends Component {
  render() {
    const { blogData, data, inEditor, global } = this.props;

    const inlineStyle = this.generateStyleAttribute();

    this.styles = DivWrapperStyles({ global, data, inEditor });
    const { divWrapper } = this.styles;

    return (
      <div
        {...inlineStyle}
        {...this.generateDataEditorAttribute()}
        {...this.generateCustomAttributes()}
        className={css(divWrapper)}
      >
        {renderChildren(this.props, {
          blogData,
          inEditor,
        })}
      </div>
    );
  }
}
