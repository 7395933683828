import PropTypes from 'prop-types';
import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { css } from "@convertly/css";
import styles from "components/StoreFront/css-storefront-styles";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import TabStyled from "containers/Editor/components/editor/css-Tab-Styles";
import Dropdown from "react-dropdown";
import styled from "styled-components";
import { keyframes } from "styled-components";
import { fromJS } from "immutable";
import _ from "lodash";
import { CancelSaveButtons } from "components/StoreFront/DetailPage/CancelSaveButtons";
import Dropzone from "react-dropzone";
import { clientImage } from "source/utils/generateImage";

import DropdownWrapper from "./DropdownWrapper";

import {
  createPage,
  loadPageTemplates,
  setOverlayStyles,
  savePageSettings,
  setPageData,
  setSettings
} from "containers/Editor/actions/editorActions";

const closeAnimation = keyframes`
  from {
    opacity: 1;
    transform: translate(-50%, 0%)  scale(1);
  }

  to {
    opacity: 0;
    transform: translate(-50%, 0%) scale(0.95);
  }
`;

const cssStyles = {
  pageName: {
    border: "1px solid #C6C6C6",
    width: "400px",
    borderRadius: "2px",
    padding: "7px 52px 6px 7px",
    outline: "none"
  },
  companyName: {
    border: "1px solid #C6C6C6",
    width: "770px",
    borderRadius: "2px",
    padding: "7px 52px 6px 7px",
    outline: "none",
    marginBottom: "5px"
  },
  label: {
    fontWeight: "normal",
    marginLeft: "8px"
  },
  pageDescription: {
    border: "1px solid #C6C6C6",
    width: "770px",
    borderRadius: "2px",
    padding: "7px 52px 6px 7px",
    outline: "none",
    marginBottom: "5px",
    height: "73px"
  },
  siteName: {
    border: "1px solid #C6C6C6",
    width: "400px",
    borderRadius: "2px",
    height: "34px",
    padding: "7px 52px 6px 7px",
    outline: "none"
  },
  browse: {
    width: "84px",
    height: "34px",
    backgroundColor: "black",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "light",
    fontFamily: "Source Sans Pro",
    cursor: "pointer"
  }
};

const CloseIconWrapper = styled.span`
  font-size: 18px;
  float: right;
  cursor: pointer;
`;

export default class PageSettingsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      settings: {}
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleWheel = this.handleWheel.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleOnUpdate = this.handleOnUpdate.bind(this);
    this.updateSocialImage = this.updateSocialImage.bind(this);
  }

  /**
   * Check for loading flag to flip
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    const { loading } = this.props;

    if (!loading && prevProps.loading) {
      this.initStateFromProps();
    }
  }

  /**
   *
   */

  initStateFromProps() {
    const { pageSettings, site } = this.props;

    const pageName = pageSettings.get("name");
    const pageDescription = pageSettings.get("description");
    const pageTitle = pageSettings.get("pageTitle");
    const pageKeywords = pageSettings.get("pageKeywords");
    const parentPage = pageSettings.get("parent");
    const socialTitle = pageSettings.get("socialTitle");
    const socialDescription = pageSettings.get("socialDescription");
    const socialImage = pageSettings.get("socialImage");

    const pageOptions = pageSettings.get("options").toJS();
    const headerDisplay = _.get(pageOptions, "header.headerPositionOverwrite");

    const homepageInArray = site.get("pages").filter(page => {
      return page.get("slug") === "/";
    });

    let homePageName = "";

    if (homepageInArray.length) {
      homePageName = homepageInArray[0].get("name");
    }

    const settings = {
      headerDisplay,
      parentPage,
      pageName,
      pageDescription,
      pageTitle,
      pageKeywords,
      socialTitle,
      socialDescription,
      socialImage
    };

    this.setState({
      settings,
      loaded: true,
      homePageName
    });
  }

  /**
   *
   * @param field
   * @param valueObject
   */
  handleOnUpdate(field, valueObject) {
    // valueObject is either event object
    // or dropdown object

    let value = '';

    if( valueObject.target ) {
      value = valueObject.target.value;
    } else {
      value = valueObject.value;
    }

    console.log( field, value );

    const { settings } = this.state;
    settings[field] = value;

    console.log( settings );

    this.setState({ settings });
  }

  async handleSave(e) {
    const { settings } = this.state;
    const { page, currentSite, pageSettings, closeModal } = this.props;

    const {
      parentPage,
      headerDisplay,
      pageName,
      pageDescription,
      pageKeywords,
      pageTitle,
      socialTitle,
      socialDescription,
      socialImage
    } = settings;

    const updatedSettings = {
      name: pageName,
      description: pageDescription,
      pageTitle,
      keywords: pageKeywords,
      parent: parentPage,
      options: {
        header: {
          headerPositionOverwrite: headerDisplay
        }
      },
      socialTitle: socialTitle,
      socialDescription: socialDescription,
      socialImage: socialImage
    };

    const payload = {
      updatedPageSettings: updatedSettings,
      currentPage: page,
      currentSite: fromJS(currentSite),
      pageBeingEdited: pageSettings
    };

    await this.context.store.dispatch(savePageSettings(payload));
    await this.context.store.dispatch(closeModal());
    await this.context.store.dispatch(setOverlayStyles(null));
    //this.context.store.dispatch( setSettings(this.props.pageSettings.toJS().pageId ) );
  }

  handleWheel(e) {
    if (
      e.currentTarget.scrollHeight - (e.currentTarget.scrollTop + e.deltaY) <
      e.currentTarget.clientHeight
    ) {
      e.currentTarget.scrollTop = e.currentTarget.scrollHeight - e.currentTarget.clientHeight;
      e.preventDefault();
    } else if (e.currentTarget.scrollTop + e.deltaY < 0) {
      e.currentTarget.scrollTop = 0;
      e.preventDefault();
    }
  }

  closeModal() {
    setTimeout(() => {
      this.context.store.dispatch(this.props.closeModal());
      this.context.store.dispatch(setOverlayStyles(null));
    }, 150);
  }

  async updateSocialImage(files) {
    console.log( 'updateSocialImage', files[0] );
    const filename = await this.props.uploadSocialImage(files[0]);
    const { settings } = this.state;
    settings.socialImage = filename;

    this.setState({
      settings
    });
  }

  render() {
    if (!this.state.loaded) {
      return null;
    }

    const { site, uploadSocialImage } = this.props;
    const { settings, homePageName } = this.state;
    const {
      headerDisplay,
      parentPage,
      pageName,
      pageTitle,
      pageDescription,
      pageKeywords,
      socialTitle,
      socialDescription,
      socialImage
    } = settings;
    console.log("settings", settings);
    const pageNames = site.get("pages").map(page => page.get("name"));

    const parentPageOptions = pageNames
      .filter(option => {
        return option !== pageName && option !== homePageName;
      })
      .toJS();

    const headerDisplayOptions = ["fixed", "overlayFixed"];

    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="fade150"
        transitionEnterTimeout={150}
        transitionLeaveTimeout={150}
      >
        <div className={css(styles.modalUnderlay)} onClick={this.closeModal} />

        <div className={css(styles.pageSettingsModalWrapper)}>
          <div>
            <div className={css(styles.modalHeader)}>
              Page Settings
              <CloseIconWrapper>
                <i
                  onClick={this.closeModal}
                  className={css(styles.modalCloseIcon) + " material-icons"}
                >
                  close
                </i>
              </CloseIconWrapper>
            </div>
          </div>

          <TabStyled>
            <Tabs>
              <TabList>
                <Tab>Page Name</Tab>
                <Tab>SEO</Tab>
                <Tab>Social</Tab>
                <Tab>Options</Tab>
              </TabList>

              <TabPanel>
                <p
                  style={{
                    marginLeft: "15px",
                    marginTop: "20px",
                    fontSize: "12px"
                  }}
                >
                  {
                    "The name for this page, as it appears in the navigation. Renaming this page does not change the URL. To change it, go to SEO Settings"
                  }
                </p>

                <div style={{ marginLeft: "15px", marginBottom: "20px" }}>
                  <div>Page Name</div>
                  <input
                    onChange={this.handleOnUpdate.bind(this, "pageName")}
                    style={cssStyles.pageName}
                    value={pageName}
                    type="text"
                    placeholder="Placeholder"
                  />
                </div>

                <div style={{ marginBottom: "25px", marginLeft: "15px" }}>
                  Parent Page
                  <DropdownWrapper>
                    <Dropdown
                      options={parentPageOptions}
                      onChange={this.handleOnUpdate.bind(this, "parentPage")}
                      value={parentPage}
                      placeholder="Select Page"
                    />
                  </DropdownWrapper>
                </div>
              </TabPanel>

              <TabPanel>
                <div
                  onWheel={this.handleWheel}
                  style={{
                    padding: "16px",
                    overflowY: "auto",
                    maxHeight: "484px"
                  }}
                >
                  Page Title
                  <p
                    style={{
                      fontSize: "12px",
                      marginTop: "5px"
                    }}
                  >
                    {
                      "A page’s titles are displayed by search engines and displayed at the top of the page when the site is loaded. It is important for search engines and sharing on social media sites. Keep it between 55-70 characters."
                    }
                  </p>
                  <div>
                    <input
                      style={cssStyles.companyName}
                      onChange={this.handleOnUpdate.bind(this, "pageTitle")}
                      value={pageTitle}
                      type="text"
                      placeholder="COMPANY NAME | Brief Tagline"
                    />
                  </div>
                  <br />
                  Page Description
                  <p
                    style={{
                      fontSize: "12px",
                      marginTop: "5px"
                    }}
                  >
                    {
                      "Describe this page in 155 characters or less. It helps people know what this page is about as well as being displayed by search engines."
                    }
                  </p>
                  <div>
                    <textarea
                      onChange={this.handleOnUpdate.bind(this, "pageDescription")}
                      value={pageDescription}
                      style={cssStyles.pageDescription}
                      type="text"
                      placeholder="COMPANY NAME | Brief Tagline"
                    />
                  </div>
                  <br />
                  Keywords
                  <p
                    style={{
                      fontSize: "12px",
                      marginTop: "5px"
                    }}
                  >
                    {
                      "These are words or terms used to describe this page. Separate each keywords or phrases with a comma."
                    }
                  </p>
                  <div>
                    <input
                      style={cssStyles.companyName}
                      onChange={this.handleOnUpdate.bind(this, "pageKeywords")}
                      value={pageKeywords}
                      type="text"
                      placeholder="COMPANY NAME | Brief Tagline"
                    />
                  </div>
                  <br />
                  Page URL
                  <p
                    style={{
                      fontSize: "12px",
                      marginTop: "5px"
                    }}
                  >
                    {
                      "Customize the URL of this page on the live site as it appears in the browser address bar. We recommend setting this once and not changing it."
                    }
                  </p>
                  <div>
                    <input style={cssStyles.companyName} type="text" placeholder=" /" />
                  </div>
                  <br />
                  <input type="checkbox" />
                  <label style={cssStyles.label}>Hide page from Search Engines</label>
                  <br />
                  <br />
                </div>
              </TabPanel>

              <TabPanel>
                {" "}
                {/*  SOCIAL  */}
                <div
                  onWheel={this.handleWheel}
                  style={{
                    padding: "16px",
                    overflowY: "auto",
                    maxHeight: "480px"
                  }}
                >
                  <p>
                    {
                      "Prepare your page to be shared on Facebook and Twitter. To do so we will require the below options."
                    }
                  </p>
                  <br />
                  Page Title
                  <p
                    style={{
                      fontSize: "12px",
                      marginTop: "5px"
                    }}
                  >
                    {
                      "You may specify a page title different than the one you provide for SEO purposes. If the below field is left blank, the default page title will be used."
                    }
                  </p>
                  <div>
                    <input
                      style={cssStyles.companyName}
                      onChange={this.handleOnUpdate.bind(this, "socialTitle")}
                      value={socialTitle}
                      type="text"
                      placeholder="COMPANY NAME | Brief Tagline"
                    />
                  </div>
                  <br />
                  Page Description
                  <p
                    style={{
                      fontSize: "12px",
                      marginTop: "5px"
                    }}
                  >
                    {
                      "You may specify a page description different than the one you provide for SEO purposes. If the below field is left blank, the default page description "
                    }
                    <br />
                    {"will be used."}
                  </p>
                  <div>
                    <textarea
                      onChange={this.handleOnUpdate.bind(this, "socialDescription")}
                      value={socialDescription}
                      style={cssStyles.pageDescription}
                      type="text"
                      placeholder=""
                    />
                  </div>
                  <br />
                  Page Image
                  <p
                    style={{
                      fontSize: "12px",
                      marginTop: "5px"
                    }}
                  >
                    {
                      "An image that will represent your page when it is shared on Facebook and Twitter."
                    }
                  </p>
                  <div style={{ display: "flex" }}>
                    <input
                      disabled={true}
                      placeholder="Upload your page image"
                      style={cssStyles.siteName}
                      value={socialImage}
                      type="text"
                    />
                    <div>
                      <Dropzone
                        style={{ margin: 0, border: "none" }}
                        ref={node => {
                          this.dropzone = node;
                        }}
                        onDrop={this.updateSocialImage}
                        multiple={false}
                      >
                        <div style={cssStyles.browse}>Browse</div>
                      </Dropzone>
                    </div>
                  </div>
                  {socialImage && (
                    <div
                      style={{
                        marginTop: "12px"
                      }}
                    >
                      <img src={clientImage(socialImage, { height: 150, fit: "crop" })} />
                    </div>
                  )}
                </div>
              </TabPanel>

              <TabPanel>
                <div
                  style={{
                    margin: "20px"
                  }}
                >
                  Override Header Display
                  <DropdownWrapper>
                    <Dropdown
                      options={headerDisplayOptions}
                      onChange={this.handleOnUpdate.bind(this, "headerDisplay")}
                      value={headerDisplay}
                      placeholder="Select Display Option"
                    />
                  </DropdownWrapper>
                </div>
              </TabPanel>
            </Tabs>
          </TabStyled>

          {/*

				<div>
					<div className={css ( styles.galleryCollectionButton )}
						 onClick={() => {
							 this.closeModal ()
						 }}>
						Cancel
					</div>
					<div className={css ( styles.galleryCollectionButton, 
					styles.galleryCollectionButtonGreen )} onClick={this.handleSave}>
						Save
					</div>
				</div>

*/}
          <CancelSaveButtons
            processing={false}
            saveAction={this.handleSave}
            cancelAction={this.closeModal}
          />
        </div>
      </ReactCSSTransitionGroup>
    );
  }
}

PageSettingsModal.contextTypes = {
  store: PropTypes.object
};
