import { generateMSStyleSheet } from "../../../utils/browserSpecificStyles";

export default function productImagesGalleryStyleObject(global, data) {
	
	return {
		
		".product-image-container": {
			color: global.colors.light.white,
			fontSize: "14px",
			lineHeight: "20px",
			position: "relative",
      'min-width': "50%",
      padding: '0px 0px 10px',
		},
		".productboxList": {
			listStyle: 'none'
		},
		".thumbnails-container": {
			width: '100%',
			border:'1px solid #cacaca',
			overflow: 'scroll',
      paddingTop: '10px',
      paddingLeft: '10px',
      height: '70px',
      'overflow-x': 'scroll',
      'overflow-y': 'hidden',
      'white-space': 'nowrap',
      ...generateMSStyleSheet({
        paddingTop: '0px',
      }),
      '@-moz-document url-prefix()': {
        paddingTop: 'unset',
      },
      '::-webkit-scrollbar': {
        height: '5px',
        width: '5px',
        borderRadius: '25px'
      },
      '::-webkit-scrollbar-track': {
        background: 'transparent',
        borderRadius: '25px'
      },

      '::-webkit-scrollbar-thumb': {
        background: global.colors.light.color3,
        borderRadius: '25px'
      },

      '::-webkit-scrollbar-thumb:hover': {
        background: global.colors.dark.color3
      },
    },
    ".thumbnails-container::-webkit-scrollbar-thumb": {
      width: '5px',
      color: 'black'
    },
		".thumbnail-wrapper": {
			border: "3px solid transparent",
			color: global.colors.dark.black,
			display: 'inline-block',
			fontSize: "14px",
			height: "50px",
			width: "50px",
			lineHeight: "20px",
			marginBottom: "10px",
			opacity: "1",
			paddingTop: "17px",
			textAlign: "center",
			cursor: "pointer",
			marginRight:'20px',
      padding:'0',
      verticalAlign: 'top',
			".active": {
				borderColor: global.colors.dark.color1,
				border: "3px solid #253039",
				color: global.colors.dark.black,
			},
			':hover': {
        border: "3px solid #253039",
        borderColor: global.colors.dark.color2,
        transition: 'all 400ms ease',
			},
			':last-child': {
				marginBottom: '0'
			},
		},
		
		".thumbnail": {
			height: '100%',
			width: '100%',
			transition: "border 200ms ease",
			objectFit: "cover"
		},
		
		".productimgcontainer": {
			color: global.colors.dark.black,
			fontSize: "14px",
			lineHeight: "20px",
			paddingLeft: "10px",
			width: "100%"
		},
		
		".feature-image-container": {
			fontSize: "14px",
			lineHeight: "20px",
			height: '250px',
			maxWidth: "100%",
			minHeight: "100%",
			opacity: "1",
			textAlign: "center",
			transition: "border 200ms ease",
			width: "auto",
		},
		".featureImage": {
			maxHeight: '100%',
			maxWidth: '100%',
			minHeight: '100%',
			minWidth: '100%',
			"object-fit": "cover"
		}
		
		
	}
	
	
}
