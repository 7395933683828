import React from 'react';
import Component from 'source/rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';
import _  from 'lodash';
//Default Props

//Utils
import getHeaderPosition from '../header-style-functions/get-header-position';

//Styles
import Styles from './header-03-styles';

//Components
import Elements from 'source/elements';
import Nav03 from './components/nav-03';

//Scripts
import absoluteToFixed from '../scripts/absolute-to-fixed-header';

export class Header03 extends Component {

    constructor(props) {

        super(props);

    }

    render() {

        let { data, global, editor, sort, site, page } = this.props;

        let inEditor = this.inEditor();

        const inlineStyle = this.generateStyleAttribute();

        let headerPosition = getHeaderPosition(data.position, page, inEditor);

 
        
        this.styles = Styles(global, data, headerPosition, inEditor);


        let headerID = data.position === "overlayFixed" ? id(absoluteToFixed) : "header";
        const displayHeaderTop = _.get(data, "headerTopperSettings.display");
        const headerHeight =  _.get(data, 'userOverrides.height') ?
        _.get(data, 'userOverrides.height') :
        displayHeaderTop ? '140px' : '100px';

        /**
         * Editor
         */
        if (editor) {
            return this.editor();
        }

        if (headerPosition === "noHeader") {
            return null
        }
        else {
            return (

                    <div id={ headerID }>

                        <header id="header03"
                                className={css(this.styles.header)}
                                data-elementType="header"
                                itemProp="siteHeader"
                                itemScope
                                itemType="http://schema.org/siteHeader"
                                onClick={(e) => {
                                    this.launchElementalEditor('header', e);
                                }}
                                onMouseOver={ this.addEditableOutline }
                                onMouseOut={ this.removeEditableOutline }
                                { ...inlineStyle }
                                { ...this.generateDataEditorAttribute() }>

                            <Nav03
                                global={ global }
                                data={ data }
                                headerHeight={headerHeight}
                                id={ this.props.id }
                                styles={ this.styles }
                                site={ site }
                                page={ page }
                                sort={ sort }
                                headerPosition={ headerPosition }
                                inEditor={this.inEditor()} />

                        </header>

                    </div>
            )
        }
    }
}

