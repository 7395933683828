import styled from "styled-components";
import { Link } from "react-router";

const A = styled.a`
  color: #fff !important;
  padding: 0 !important;
  font-weight: 300;
  font-size: 14px;
  text-decoration: none !important;
`;

export const HeaderLink = styled(Link)`
  position: relative;
  display: block;
  width: 100%;
  padding: 12px;
  font-size: 14px;
  text-decoration: none !important;
  border-bottom: 1px solid #eee;
`;
