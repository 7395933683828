import React from 'react';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';
import _ from 'lodash';
import {clientImage} from '../../../utils/generateImage';
import {generateLink} from '../../../utils/generateLink';

//Scripts
import cartScript from '../../scripts/cart-script';
import isSignedIn from '../../scripts/is-signed-in';
import SearchBarWrapper from '../../scripts/inferno-components/search-bar-wrapper/SearchBarWrapper';
import SearchBarWrapperHeader01 from '../../scripts/inferno-components/search-bar-wrapper/SearchBarWrapperHeader01';

//Components
import { NavLink04 } from './nav-link-04';
import Widgets from '../../../widgets';

export class NavList04 extends Component {

    constructor(props) {

        super(props);
    }


    render() {

        const {page, site, data, global, styles, displaySocial} = this.props;

        const slug = _.get(this.props, 'page.slug');

        let inEditor = this.inEditor();

        const enabled = _.get(this.props, 'site.ecommerce.enabled');

        let cartDiv = null;
        let signIn = null;

        const clientImgSrc = clientImage("", {width: '60', height: '60', crop: 'fit'});
        const clientLinkUrl = generateLink({linkType: "inferno", url: ""});

        let className = '';
        let aphroditeClass = css(styles.headerNav);

        if (this.animatedClassName) {
            className += " " + this.animatedClassName
        }

        className += aphroditeClass;

        const {animated} = this.props.data;
        const inlineStyle = this.generateStyleAttribute();

        if (enabled) {
            if (slug !== "/cart") {
                cartDiv =
                    !inEditor ?
                        <div>
                            <div className={ css(styles.desktopCartInfernoComponent) }
                                 id={ id(cartScript, clientLinkUrl, clientImgSrc, false, slug) }/>
                        </div> :
                        <li className={ `${css(styles.navListItem)} ${ slug === "/account" && 'active' }`}>
                            <span className={ css(styles.navListItemText) }>
                                <span className={ css(styles.navLink) }>Cart</span>
                            </span>
                        </li>
            }
            signIn =
                !inEditor ?
                    <div>
                        <div className={ css(styles.desktopCartInfernoComponent) }
                             id={ id(isSignedIn, clientLinkUrl) }/>
                    </div> :
                    <li className={ `${css(styles.navListItem)} ${ slug === "/cart" && 'active' }`}>
                            <span className={ css(styles.navListItemText) }>
                                <span className={ css(styles.navLink) }>Sign In</span>
                            </span>
                    </li>
        }

        let showMobileMenu = false;

        if ( inEditor ) {

            showMobileMenu = this.context.store.getState().get('editor').editorReducer.get('showClientNav');

        }

        return (
            <nav className={ css(styles.headerNav) }
                 data-animated={ animated }>

                <ul className={ `${ css(styles.navList ) }` }
                    id="menu">
									{ !_.get(this.props.page, 'hideSearchBar') &&
									   _.get(this.props, 'site.ecommerce.enabled') &&
                  <div id={ id(SearchBarWrapper, this.props.site.siteId)} className={ css(styles.searchBarWrapper) }/>
                  }
                  { 
                    !_.get(this.props.page, 'hideSearchBar') &&
                    _.get(this.props, 'site.ecommerce.enabled') &&
                    <div id={ id(SearchBarWrapperHeader01, this.props.site.siteId)}
                        className={ `${ css(styles.searchBarWrapper) } mobile` }/>
                  }
                    {data.navLinks.map((navItem, index) => {
                        return (
                            <NavLink04 key={ index }
                                       data={navItem}
                                       site={ site }
                                       page={ page }
                                       styles={ styles }
                                       index={ index }
                                       isChild={ false }/>
                        )
                    })}

                    { /** Cart **/ }
                    <span className={ css(styles.navItemContainer) }>
                        { cartDiv }
                    </span>

                    { /** Sign In **/ }
                    <span className={ css(styles.navItemContainer) }>
                        { signIn }
                    </span>
                    { /** Social Icons **/ }
                    <span className={ css(styles.socialIconSection) }>
                        { displaySocial.headerTopper &&
                        displaySocial.socialIcons &&
                        _.get(site, 'social') &&
                        <Widgets.SocialSharing01
                            data={ {
                                socialIcons: site.socialIcons,
                                userOverrides: {
                                    background:'red'
                                }
                            } }
                            global={ global }
                            site={ site }
                            styles={ styles }
                            socialIconLinkClass={ this.props.socialIconLinkClass }/>
                        }
                    </span>
                </ul>
            </nav>
        )
    }
}

