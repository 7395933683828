import React from 'react';
import Component from 'source/rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';
import _ from 'lodash';

//Default Props
//import defaultData from '';
const defaultData = {};
import defaultGlobal from 'source/themes/global';

//Styles
import styles from './phone-overlay-styles';

//utils
import {generateLink} from 'source/utils/generateLink';

//Components


//inferno components
import pageInsights from './page-insights-script'



export default class PhoneOverlay extends Component {

    constructor(props) {

        super(props);

    }

    render() {

        this.styles = styles(this.props.global, this.props.data);

        if (this.props.editor) {
            return this.editor();
        }


        return (
            <div className={ css( this.styles.phoneOverlay ) } >
                <svg width="571px" height="610px" viewBox="0 0 572 610" version="1.1" xmlns="http://www.w3.org/2000/svg" className={ css( this.styles.svgStyles ) } >
                    <g id="ALL-PAGES" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="Tech" transform="translate(-434.000000, -1130.000000)">
                            <g id="Group-3" transform="translate(0.000000, 1130.000000)">
                                <g id="CONTENT" transform="translate(433.000000, 0.000000)">
                                    <g id="PHONE">
                                        <g id="Group-8" fill="#349886">
                                            <path d="M106,0 L468.831701,0 L468.831701,-1.42108547e-14 C524.060176,2.46965787e-14 568.831701,44.771525 568.831701,100 L568.831701,609.750306 L6,609.750306 L6,100 L6,100 C6,44.771525 50.771525,6.69887251e-14 106,5.68434189e-14 Z" id="Rectangle-12"></path>
                                            <rect id="Rectangle-13-Copy" transform="translate(570.198575, 289.448441) rotate(-270.000000) translate(-570.198575, -289.448441) " x="531.997329" y="286.645683" width="76.4024933" height="5.60551559" rx="2.80275779"></rect>
                                            <rect id="Rectangle-13-Copy-2" transform="translate(3.801425, 228.297362) rotate(-270.000000) translate(-3.801425, -228.297362) " x="-34.3998219" y="225.494604" width="76.4024933" height="5.60551559" rx="2.80275779"></rect>
                                            <rect id="Rectangle-13-Copy-3" transform="translate(3.801425, 320.023981) rotate(-270.000000) translate(-3.801425, -320.023981) " x="-34.3998219" y="317.221223" width="76.4024933" height="5.60551559" rx="2.80275779"></rect>
                                        </g>
                                        <rect id="Rectangle-13" fill="#5BAFA0" x="227" y="61" width="122" height="11" rx="5.5"></rect>
                                        <rect id="Rectangle-11" fill="#5BAFA0" x="22" y="114" width="530" height="495" rx="3"></rect>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>

                <div className={ css( this.styles.phoneScreen ) } >
                    <div className={ css( this.styles.phoneHeading ) }>
                        Test your mobile page speed with Google
                    </div>
                    <div className={ css( this.styles.phoneBody ) }>
                        Most people lose half their visitors while their sites are loading. Test your page speed on Google to see if your site is fast enough for today's consumers.

                        <div id={ id( pageInsights ) } className={ css( this.styles.pageInsights ) } >
                            {/* INFERNO */}
                        </div>

                    </div>

                </div>
            </div>
        )
    }
}


PhoneOverlay.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
