import React from 'react';
import Component from '../../../../rootComponent';
import {css} from "@convertly/css";
import {generateLink} from '../../../../utils/generateLink';
import _ from 'lodash';

//Default Props
import defaultData from '../../header-default-data';
import defaultGlobal from '../../../../themes/global';

//Styles
import navStyles06 from './nav-styles-06.js';

//Scripts


//Components
import Elements from '../../../../elements/index';
import SVG from '../../../../svg/index';
import GoogleMapsLink from '../../../../elements/links/google-map-link';
import EmailLink from '../../../../elements/links/email-link';
import PhoneNumberLink from "../../../../elements/links/phone-number-link";

export default class HeaderNav06 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showHeaderMenu: false,
            toggledClass: ''
        };

    }

    render() {

        this.styles = navStyles06(this.props.global, this.props.data);

        let displayNavLinks = this.props.data.menu.navLinks.map( (link) => {
            return (
                    <Elements.WebLink
                    data={ link }
                    parentClassName={ this.styles.navLink }
                    global={ this.props.global }
                    elementDataName="link"
                    moduleId={ this.props.id }
                    moduleSort={ this.props.sort }/>
            )
        });

        return (
            <Elements.Container>
                <Elements.Row>

                {/* DESKTOP */}
                <div className={ css(this.styles.desktopNav) }>

                    <div className={ css(this.styles.menuContainer ) + " headerNav" }>

                        {/*LOGO*/}
                        <div className={ css(this.styles.logo) } id="logo">
                            <span>ROGUE</span>

                            {/*<a href={ generateLink('/') }>*/}
                                {/*<Elements.Image*/}
                                    {/*parentFigureClassName={ this.styles.leftFigure }*/}
                                    {/*parentImgClassName={ this.styles.logoImg }*/}
                                    {/*data={ this.props.data.logo }*/}
                                    {/*global={ this.props.global }*/}
                                    {/*elementDataName="img"*/}
                                    {/*moduleId={ this.props.id }*/}
                                    {/*moduleSort={ this.props.sort }/>*/}
                            {/*</a>*/}
                        </div>

                        {/*CENTER NAV ITEMS*/}

                        <div className={ css(this.styles.navLinks) }>

                            { displayNavLinks }

                        </div>

                        {/*BOOK A SESSION*/}

                        <div className={ css(this.styles.rightLink) } >

                            <a href={ generateLink('/') }>
                                <Elements.Image
                                    parentFigureClassName={ this.styles.bookButton }
                                    parentImgClassName={ this.styles.logoImg }
                                    data={ _.get(this.props, 'data.menu.img.calendar') }
                                    global={ this.props.global }
                                    elementDataName="img"
                                    moduleId={ this.props.id }
                                    moduleSort={ this.props.sort }/>
                            </a>

                            <div className={ css(this.styles.rightLinkText)}>
                                Book A Session
                            </div>

                        </div>

                    </div>

                </div>

                {/* MOBILE */}
                <div className={ css(this.styles.mobileNav) }>

                    <div className={ css(this.styles.menuContainer ) + " headerNav" }>

                        {/*MENU BUTTON*/}

                        <div className={ css( this.styles.menuButton ) }>

                            <a href={ generateLink('/') }>
                                <Elements.Image
                                    parentFigureClassName={ this.styles.leftFigure }
                                    parentImgClassName={ this.styles.logoImg }
                                    data={ _.get(this.props, 'data.menu.img.menu' )  }
                                    global={ this.props.global }
                                    elementDataName="img"
                                    moduleId={ this.props.id }
                                    moduleSort={ this.props.sort }/>
                            </a>

                        </div>

                        {/*LOGO*/}

                        <div className={ css(this.styles.logo) } id="logo">
                            <span>ROGUE</span>

                            {/*<a href={ generateLink('/') }>*/}
                            {/*<Elements.Image*/}
                            {/*parentFigureClassName={ this.styles.leftFigure }*/}
                            {/*parentImgClassName={ this.styles.logoImg }*/}
                            {/*data={ this.props.data.logo }*/}
                            {/*global={ this.props.global }*/}
                            {/*elementDataName="img"*/}
                            {/*moduleId={ this.props.id }*/}
                            {/*moduleSort={ this.props.sort }/>*/}
                            {/*</a>*/}
                        </div>

                        {/*CONTACT BUTTON*/}

                        <div className={ css(this.styles.contactButton ) }>

                            <a href={ generateLink('/') }>
                                <Elements.Image
                                    parentFigureClassName={ this.styles.leftFigure }
                                    parentImgClassName={ this.styles.logoImg }
                                    data={ _.get(this.props, 'data.menu.img.phone') }
                                    global={ this.props.global }
                                    elementDataName="img"
                                    moduleId={ this.props.id }
                                    moduleSort={ this.props.sort }/>
                            </a>

                        </div>

                    </div>

                </div>

                </Elements.Row>
            </Elements.Container>

        )
    }
}

HeaderNav06.defaultProps = {
    data: defaultData,
    global: defaultGlobal
};
