const TABS = ['123', '345', '567']
const CONTEST_ID = "123456789"
const PUBLISHED_STATUS = "published"
const IMAGE_DESCRIPTION_FIELD = "imageDescription"
const IMAGE_DESCRIPTION = `“A beach house isn't just real estate. It's a state of mind.”
“A beach house,” he said, “doesn’t even have to be on the beach. Though the best ones are. We all like to congregate,” he went on, “at boundary conditions.” “Really?” said Arthur. “Where land meets water. Where earth meets air. Where body meets mind. Where space meets time. We like to be on one side, and look at the other.
― Douglas Adams, Mostly Harmless
”
`
let id = () => Math.round( Math.random(  ) * 100000 )
export const DUMMY_SUBMISSIONS = [
    {
        id:id(),
        contestId: CONTEST_ID,
        tabId: TABS[1],
        dateSubmitted: "",
        imageName: "Flam Boiant",
        imageUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSACtyHhpRItZ-sP7VA4FFP0NREz4NIVoTFcMOkoCDRasPOVww46wIaWI9TEw&usqp=CAc",
        [IMAGE_DESCRIPTION_FIELD]: IMAGE_DESCRIPTION,
        email: "a@a.com",
        status: PUBLISHED_STATUS
    },
    {
        id:id(),
        contestId: CONTEST_ID,
        tabId: TABS[1],
        dateSubmitted: "",
        imageName: "Tricky Puppy",
        imageUrl: "https://i.pinimg.com/originals/d4/12/52/d41252113b6822d1910de8140ecc686a.jpg",
        [IMAGE_DESCRIPTION_FIELD]: IMAGE_DESCRIPTION,
        email: "b@b.com",
        status: PUBLISHED_STATUS
    },
    {
        id:id(),
        contestId: CONTEST_ID,
        tabId: TABS[1],
        dateSubmitted: "",
        imageName: "Senior Goldie",
        imageUrl: "https://pbs.twimg.com/profile_images/1088174531599106049/wpgUrQfo.jpg",
        [IMAGE_DESCRIPTION_FIELD]: IMAGE_DESCRIPTION,
        email: "b@b.com",
        status: PUBLISHED_STATUS
    },
    {
        id:id(),
        contestId: CONTEST_ID,
        tabId: TABS[1],
        dateSubmitted: "",
        imageName: "Wyatt Erp",
        imageUrl: "https://lh3.googleusercontent.com/proxy/wNf1vb-mI-j1JViuEvAiVrXgJeLKUdTdgwbxzrf_K3y6AApyNqin3Z-r8Xh0kZYV2EeQbfSQdtF6bwNcdYA7Fux2KGTRN0jm5kAaj2W_kc1FoPuuxvDJj9OD",
        [IMAGE_DESCRIPTION_FIELD]: IMAGE_DESCRIPTION,
        email: "c@c.com",
        status: PUBLISHED_STATUS
    },
    {
        id:id(),
        contestId: CONTEST_ID,
        tabId: TABS[1],
        dateSubmitted: "",
        imageName: "Chad",
        imageUrl: "https://pbs.twimg.com/media/BR_mXMBCUAEErWV.jpg",
        [IMAGE_DESCRIPTION_FIELD]: IMAGE_DESCRIPTION,
        email: "c@c.com",
        status: PUBLISHED_STATUS
    },
    {
        id:id(),
        contestId: CONTEST_ID,
        tabId: TABS[1],
        dateSubmitted: "",
        imageName: "Chef boy RD",
        imageUrl: "https://i.imgur.com/O2vBNJv.jpg",
        [IMAGE_DESCRIPTION_FIELD]: IMAGE_DESCRIPTION,
        email: "c@c.com",
        status: PUBLISHED_STATUS
    },
    {
        id:id(),
        contestId: CONTEST_ID,
        tabId: TABS[1],
        dateSubmitted: "",
        imageName: "Fron Teer",
        imageUrl: "https://dogs.thefuntimesguide.com/files/dog-hats.jpg",
        [IMAGE_DESCRIPTION_FIELD]: IMAGE_DESCRIPTION,
        email: "c@c.com",
        status: PUBLISHED_STATUS
    },
    {
        id:id(),
        contestId: CONTEST_ID,
        tabId: TABS[1],
        dateSubmitted: "",
        imageName: "Katy Jurado",
        imageUrl: "https://i.pinimg.com/originals/cd/a2/0e/cda20efbc77cba2fadd87f32ae5bd9ee.jpg",
        [IMAGE_DESCRIPTION_FIELD]: IMAGE_DESCRIPTION,
        email: "c@c.com",
        status: PUBLISHED_STATUS
    },
    {
        id:id(),
        contestId: CONTEST_ID,
        tabId: TABS[1],
        dateSubmitted: "",
        imageName: "Katy Jurado",
        imageUrl: "https://images.baxterboo.com/global/images/products/large/cowboy-hat-dog-costume-sparkle-blue-1.jpg",
        [IMAGE_DESCRIPTION_FIELD]: IMAGE_DESCRIPTION,
        email: "c@c.com",
        status: PUBLISHED_STATUS
    }
]
