import React from 'react';
import Component from '../../rootComponent';
import {css} from "@convertly/css";
import defaultGlobal from '../../themes/global.js';
import ReactHTMLParser from "@convertly/react-html-parser";

//Styles
//import paragraphStyles from './paragraph-styles';

//Utils
import generateId from '../../utils/generate-id';
import lodash from 'lodash';

/**
 * Class controls imports style functions that allows for user to override the css in the CMS editor for their site.
 **/
export default class Pre extends Component {

    constructor(props) {

        super(props);

    }

    render() {

        const inEditor = this.inEditor();
        /**
         * Calls Stylesheet function
         **/
        //this.styles = paragraphStyles(this.props.global, this.props.data, inEditor);



        return (


            <div { ...this.generateDataEditorAttribute() }
                 { ...this.generateCustomAttributes() }
                 {...generateId(this.props.elementId)}
                 onClick={(e) => {
                     this.launchElementalEditor('text', e);
                 }}
                 onMouseOver={ (e) => {
                     this.addEditableOutline(e)
                 } }
                 onMouseOut={ (e) => {
                     this.removeEditableOutline(e)
                 } }>{ ReactHTMLParser(this.props.data.code) }</div>

        );
    }
}
