let $;
//toggles nav's drop down icons
const navDropdownToggle = (id) => {

    return (id) => {


        (function () {
            /**
             * Select root id
             */
            const $el = $('#dropdown' + id);

            const $toggleElements = $(`[data-dropdown="${id}"]`);

            /**
             * Handles opening and closing of drop down
             */
            $el.on('click', function (e) {

                e.preventDefault();

                if ($(`ul[data-dropdown="${id}"]`).hasClass('open')) {
                    $('[data-category="dropdown"]').removeClass('open');
                    $toggleElements.removeClass('open');
                }
                else {
                    $('[data-category="dropdown"]').removeClass('open');
                    $toggleElements.addClass('open');
                }

            })
        })();
    };


};
export default navDropdownToggle;
