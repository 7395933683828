/* global window, $ */

// This is a copy and paste of register-animated-elements script
// Next step is to refactor and make it a reusable function
// that accepts callback functions to do on the elements
// Then can use the one function and the register-animated-elements and this script
// can call it.

/**
 *
 * @returns {function(*)}
 */
const Script = () => {

	return () => {
   
	  // https://medium.com/@amdgls/lazyloading-css-background-images-458a85da5550
    function rebounce(f){ var scheduled, context, args, len, i; return function(){ context = this; args = []; for(var i = 0; i < arguments.length; ++i){ args[i] = arguments[i]; } window.cancelAnimationFrame(scheduled); scheduled = window.requestAnimationFrame(function(){ f.apply(context, args); scheduled = null; }); } }

		/**
		 * Initialize variables
		 */
		const $window    = $ ( window );

		// queue holds $el references to elements
		// that have an data image source but have not been scrolled into view
		const queue      = [];

		let checking     = false;
		let dirty        = false;
		
		const sourceTag  = 'data-image-source';

		updateQueue();

		// Initialize event listeners
    window.addEventListener( 'load', check, false );
    window.addEventListener( 'scroll', rebounce( check ), false );
    window.addEventListener( 'resize', rebounce( check ), false );

    /**
		 * Check if elements are in view
		 * If so, update the image source
		 * Repopulate queue array with updated elements
		 * not yet in view
		 * Remove event listener if all elements have been processed
		 *
		 */
		function check() {

			// Only process one check() at a time
      // Don't know if this validation is actually needed
      // Leaving in just in case, can't hurt
			if( checking ) {
				return;
			}

			dirty    = false;
			checking = true;

			queue.forEach( $el => {

				if( isScrolledIntoView( $el ) ) {

					const el  = $el[ 0 ];
          
          el.src = $el.attr( sourceTag );
					el.removeAttribute( sourceTag );

					dirty = true;
					
					// console.log( 'lazy loading image', el.src );

				}

			});
			
			if( dirty ) {
        
        updateQueue();

				if( ! queue.length ) {
					window.removeEventListener( 'scroll', check );
				}

			}

			checking = false;

		}

		/**
		 * Populate the Queue array with elements that have
		 * data-animated attribute
		 */
		function updateQueue() {

			queue.length = 0;

			$ ( `[${sourceTag}]` ).forEach( el => queue.push( $( el ) ) );
			
		}

		/**
		 * Check if element is within current view
		 * https://stackoverflow.com/a/488073
		 *
		 * @param $el
		 * @returns {boolean}
		 */
		function isScrolledIntoView ( $el ) {

			const docViewTop = $window.scrollTop();
			const docViewBottom = docViewTop + $window.height();

			const elemTop = $el.offset().top;
			const elemBottom = elemTop + $el.height();

			return ( ( elemBottom <= docViewBottom ) && ( elemTop >= docViewTop ) );
		}

	};


};

export default Script;
