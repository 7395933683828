import Footer01 from './footer-01/footer-01';
import Footer02 from './footer-02/footer-02';
import Footer03 from './footer-03/footer-03';
import Footer04 from './footer-04/footer-04';
import Footer05 from './footer-05/footer-05';
import Footer06 from './footer-06/footer-06';
import Footer08 from './footer-08/footer-08';
import ConvertlyFooter from './convertly-footer/footer';

const footer = {
	footer01: Footer01,
	footer02: Footer02,
	footer03: Footer03,
    footer04: Footer04,
    footer05: Footer05,
	footer06: Footer06,
	footer08: Footer08,
	ConvertlyFooter: ConvertlyFooter
};

export default footer;

