import React, {useState, useRef} from 'react';
import styled from 'styled-components';
import {GLOBALS} from "../../styles/global-default-styles";


export const DropdownWrapper = styled.div`
    appearance: none;
    border-radius: 3px;
    position:relative;
    ${({dropdownWidth}) => dropdownWidth ? `width: ${dropdownWidth};` : "width: 100%;"}
    ${({dropdownBorder}) => dropdownBorder ? `border: 1px solid ${dropdownBorder};` : `border: 1px solid ${GLOBALS.colorPrimary};`}
`;



export const Option = styled.div`
    padding: 7px;
    padding-right:20px;
    width:100%;
`;

export const OptionsMenu = styled.div`
  max-height:${({active}) => active ? "unset" : "0px"};
  bottom:0;
  overflow:hidden;
  width:100%;
  background:#fff;
  transition:height .3s ease;
  height:fit-content;
  width:${({selectorRef}) => getElementWidth(selectorRef.current)}px;
  position:fixed;
  z-index:99;
  border: ${({active}) => active ? `1px solid ${GLOBALS.colorPrimary}` : "none"};
  ${( {active} ) => active ? `max-height:250px;` : ""}
  overflow:auto;
  top: ${({cords, elHeight}) => cords.y ? cords.y + elHeight : 0}px;
  left: ${({cords}) => cords.left - 1}px;
  & ${Option}:hover {
    background-color:${GLOBALS.colorSecondaryLightest}!important;
  }

`;

function getElementWidth(el) {
  //let elStyles = getComputedStyle(el);
  console.log(el?.getBoundingClientRect()?.width, 'width:::::')
  return el?.getBoundingClientRect()?.width//elStyles.getPropertyValue('width')

}

const Caret = styled.div`
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid ${GLOBALS.colorPrimary};
  position:absolute;
  right:10px;
  vertical-align:middle;
  top:18px;
  cursor: pointer;
`;

export const DropDown = ({options, selected, cb, ...props}) => {

  const [openMenu, toggleOpenMenu] = useState(false);
  const [cords, updateCords] = useState({x: null, y: null});
  const [elHeight, updateElHeight] = useState(0)

  const selectorRef = useRef(null);
  const optionsMenuRef = useRef(null)

  const openDropDown = (e) => {
    e.stopPropagation();

    updateCords(e.target.getBoundingClientRect())
    updateElHeight(e.target.offsetHeight)

    toggleOpenMenu(!openMenu)
  };

  const selectOption = (e) => {

    const {value} = e.target.dataset;

    toggleOpenMenu(!openMenu);
    cb(value)

  };

  return (
    <DropdownWrapper
      dropdownWidth={props.dropdownWidth}
      ref={selectorRef}
      optionsMenuRef={optionsMenuRef}
      selectorRef={selectorRef}
      dropdownBorder={props.dropdownBorder}>
      <Option onClick={openDropDown}>{options.filter(v => v.value == selected)[0]?.text || "Select Option"}</Option>
      <Caret/>

      <OptionsMenu
        cords={cords}
        dropdownWidth={props.dropdownWidth}
        elHeight={elHeight}
        ref={optionsMenuRef}
        optionsMenuRef={optionsMenuRef}
        selectorRef={selectorRef}
        active={openMenu}>
        {
          options.map(option => {
            return <Option onClick={selectOption} key={option.value} data-value={option.value}>{option.text}</Option>
          })
        }
      </OptionsMenu>

    </DropdownWrapper>
  )

}


export function adaptObjectToDropdown(options, filterKey, textKey) {
  let newOptions = []
  options.forEach(function changeOptionValues(option) {
    let newObject = {};
    newObject.filter = option[filterKey];
    newObject.text = option[textKey];
  })
  return options

}
