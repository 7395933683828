import React from "react";
import Component from "source/rootComponent";
import { css } from "@convertly/css";

// Style
import { VerticalDividerStyles } from "./VerticalDividerStyles";

// Utils
export class VerticalDivider extends Component {
  render() {
    const { data, inEditor, global } = this.props;

    this.styles = VerticalDividerStyles({ global, data, inEditor });
    const { verticalDivider } = this.styles;
    const inlineStyle = this.generateStyleAttribute();

    return (
      <div
        {...inlineStyle}
        {...this.generateDataEditorAttribute()}
        {...this.generateCustomAttributes()}
        className={css(verticalDivider)}
      />
    );
  }
}