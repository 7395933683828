const data = {
   "logo": {
      "src": "cc_logo.png",
      "alt": "ghost logo",
      "title": "ghost logo"
   },
   "footerNavLinks": [
      {
         "listHeader": {
            "text": "About Magic Dragon",
            "bold": false,
            "italic": false,
            "underline": false
         },
         "navLinks": [
            {
               "url": "#",
               "target": false,
               "text": "About Us",
               "textEditorSize": 5
            },
            {
               "url": "http://google.com",
               "target": true,
               "text": "Our Policy",
               "bold": false,
               "italic": false,
               "underline": false
            }
         ]
      },
      {
         "listHeader": {
            "text": "Help & Support",
            "bold": false,
            "italic": false,
            "underline": false
         },
         "navLinks": [
            {
               "url": "#",
               "target": false,
               "text": "Delivery & Returns",
               "bold": false,
               "italic": false,
               "underline": false
            },
            {
               "url": "#",
               "target": false,
               "text": "Terms & Conditions",
               "bold": false,
               "italic": false,
               "underline": false
            },
            {
               "url": "#",
               "target": false,
               "text": "Contact us",
               "bold": false,
               "italic": false,
               "underline": false
            }
         ]
      },
      {
         "listHeader": {
            "text": "Threads",
            "bold": false,
            "italic": false,
            "underline": false
         },
         "navLinks": [
            {
               "url": "#",
               "target": false,
               "text": "Cashmere",
               "bold": false,
               "italic": false,
               "underline": false
            },
            {
               "url": "#",
               "target": false,
               "text": "Silk",
               "bold": false,
               "italic": false,
               "underline": false
            },
            {
               "url": "#",
               "target": false,
               "text": "Wool",
               "bold": false,
               "italic": false,
               "underline": false
            }
         ]
      }
   ],
   "paragraph": {
      "text": "© 2017 MagicDragon. All Rights Reserved",
      "bold": false,
      "italic": false,
      "underline": false
   },
   "copyRightLinks": [
      {
         "text": "Privacy Policy",
         "link": {
            "linkType": "internal",
            "url": "#"
         },
         "target": false
      },
      {
         "text": "Terms and Conditions",
         "link": {
            "linkType": "internal",
            "url": "#"
         },
         "target": false
      }
   ],
   "socialIcons": [
      {
         "active": true,
         "color": false,
         "type": "facebook",
         "url": "https://www.facebook.com/zerowasteguru/?ref=aymt_homepage_panel",
         "target": true,
         "socialIcon": "fa-facebook-official",
         "sort": 0
      },
      {
         "active": true,
         "type": "twitter",
         "url": "https://twitter.com/ZeroWasteGuru",
         "target": true,
         "socialIcon": "fa-twitter-square",
         "sort": 1
      },
      {
         "active": false,
         "type": "pintrest",
         "target": true,
         "socialIcon": "fa-pinterest-p",
         "sort": 0
      },
      {
         "active": true,
         "type": "instagram",
         "url": "https://www.instagram.com/zerowasteguru/",
         "target": true,
         "sort": 4
      },
      {
         "active": true,
         "type": "youtube",
         "url": "https://www.youtube.com/channel/UC__4Xy8rggA4TRnQygehykw",
         "target": true,
         "sort": 3
      },
      {
         "active": true,
         "type": "linkedin",
         "url": "https://www.linkedin.com/in/teslamotors/",
         "target": true,
         "sort": 5
      }
   ],
   "newsletterTitle": {
      "text": "Sign Up for our Newsletter",
      "fontSize": "1.4em"
   },
   "newsletterCta": {
      "text": "To receive exclusive news and special offers!",
      "fontSize": "1em"
   },
   "button": {
      "text": "Button2",
      "align": "center",
      "url": "https://google.com"
   },
   "footerText": {
      "text": "ZeroFill Solutions exist to help any Business, School, or Community to achieve their zero waste to landfill goals"
   }
};
export default data;