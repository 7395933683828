import React from 'react';
import Component from 'source/rootComponent';
import {css} from "@convertly/css";
import {id} from '@convertly/thor';
import ReactHTMLParser from '@convertly/react-html-parser';
import _ from 'lodash';

//Default Props
//import defaultData from '';
const defaultData = {};
import defaultGlobal from 'source/themes/global';

//Styles
import Styles from './product-detail-page-styles';

//Components
import Elements from 'source/elements';
import ProductImageGallery from 'source/modules/product-listing/product-image-gallery/product-image-gallery-02/product-image-gallery';
import ConvertToCurrency from 'source/utils/ecomm-utils/convert-to-currency-ii';
import AddToCart from './components/add-to-cart';
import StepperWidget from './components/stepper-widget';


export default class ProductDetailPage extends Component {
	
	constructor(props) {
		super(props);
	}
	
	render() {
		
		if (this.inEditor()) {
			return (<div style={{textAlign: 'center', padding: 50, border: '1px solid #aeaeae'}}>Product Module</div>);
		}
		
		this.styles = Styles(this.props.global, this.props.data, this.inEditor());
		
		if (this.props.editor) {
			return this.editor();
		}
		const convertToCurrency = ConvertToCurrency();
		
		
		let actualPrice;
		if (this.props.data.salePrice) {
			actualPrice = this.props.data.salePrice
		} else {
			actualPrice = this.props.data.price
		}
		
		let product = {};
		let _this = this;
		
		delete this.props.data.global;
		
		Object.keys(_this.props.data).map(function (key, index) {
			
			if (key !== "global") {
				product[key] = _this.props.data[key]
			}
			
		});
		
		const {pricing, salePrice, weight, sku, title, featuredImage, onSale} = this.props.data;
		const productId = _.get(this.props, 'data.id');
		const price = _.get(pricing, 'price');
		
		const productToPassToInferno = {
			id: productId,
			price,
			salePrice,
			weight,
			sku,
			title,
			featuredImage,
			onSale
		};
		
		let stringifiedProduct = JSON.stringify(productToPassToInferno);
		
		let productOptions;
		
		if (this.props.data.productOptions) {
			productOptions = <div className={ css(this.styles.optionscontainer) }>
				<div className={ css(this.styles.divBlock_section1) }>
					<div className={ css(this.styles.label_subtitle) }>Choose Your Size</div>
					<a className={ css(this.styles.optionbtn) } href="#">XS</a><a className={ css(this.styles.optionbtn) }
																																				href="#">S</a><a
					className={ css(this.styles.optionbtn) } href="#">M</a><a className={ css(this.styles.optionbtn_selected) }
																																		href="#">L</a><a
					className={ css(this.styles.optionbtn) } href="#">XL</a><a className={ css(this.styles.optionbtn) } href="#">XXL</a>
				</div>
				<div className={ css(this.styles.divBlock_2) }>
					<div className={ css(this.styles.divBlock_var2) }>
						<div className={ css(this.styles.label_subtitle) }>Choose your color</div>
						<div className={ css(this.styles.variant1container) }>
							<div className={ css(this.styles.selected_selector) }/>
							<div className={ css(this.styles.selector) }/>
							<div className={ css(this.styles.selector) }/>
						</div>
					</div>
				</div>
			</div>
			
		}
		let subscriptionSection;
		let displaySubscriptionSection = false;
		if (displaySubscriptionSection) {
			subscriptionSection = <div>
				<div className={ css(this.styles.subscriptioncontainer) }>
					
					<div className={ css(this.styles.divBlock_2) }><a className={ css(this.styles.freqselector_wButton) }
																														href="#">One-Time Purchase</a><a
						className={ css(this.styles.freqselector_selected_wButton) } href="#">Subscribe and Save 10%</a></div>
					<div className={ css(this.styles.frequencycontainer) }>
						<div className={ css(this.styles.first_toggletext) }>Deliver</div>
						<div className={ css(this.styles.wDropdown) } data-delay="0" data-hover="1">
							<div className={ css(this.styles.toggle_wDropdownToggle) }>
								<div className={ css(this.styles.icon_wIconDropdownToggle) }/>
								<div className={ css(this.styles.drop_toggletext) }>1</div>
							</div>
							<nav className={ css(this.styles.dropdownList_wDropdownList) }>
								<a className={ css(this.styles.dropdownlink_wDropdownLink) } href="#">2</a>
								<a className={ css(this.styles.dropdownlink_wDropdownLink) } href="#">3</a>
								<a className={ css(this.styles.dropdownlink_wDropdownLink) } href="#">4</a></nav>
						</div>
						<div className={ css(this.styles.first_toggletext) }>every</div>
						<div className={ css(this.styles.dropdown_wDropdown) } data-delay="0" data-hover="1">
							<div className={ css(this.styles.toggle_wDropdownToggle) }>
								<div className={ css(this.styles.icon_wIconDropdownToggle) }/>
								<div className={ css(this.styles.drop_toggletext) }>Month</div>
							</div>
							<nav className={ css(this.styles.dropdownList_wDropdownList) }>
								<a className={ css(this.styles.dropdownlink_wDropdownLink) } href="#">Week</a>
								<a className={ css(this.styles.dropdownlink_wDropdownLink) } href="#">Quarter</a>
								<a className={ css(this.styles.dropdownlink_wDropdownLink) } href="#">Year</a></nav>
						</div>
					</div>
				</div>
			
			
			</div>
		}
		
		let subTitle = null;
		if (this.props.data.subtitle) {
			subTitle =
				<div className={ css(this.styles.subtitle) }>{this.props.data.subTitle}</div>
		}
		
		//pricing section
		let priceSection =
			<div className={ css(this.styles.priceContainer) }>
				<strong
					className={ css(this.styles.price_producttitle) }>{ convertToCurrency(this.props.data.pricing.price) }</strong>
			</div>;
		
		if (this.props.data.onSale) {
			priceSection =
				<div className={ css(this.styles.priceContainer) }>
					<strong
						className={ `${css(this.styles.price_producttitle)} on-sale` }>{ convertToCurrency(this.props.data.pricing.price) }</strong>
					<strong
						className={ css(this.styles.price_saleprice) }>{ convertToCurrency(this.props.data.pricing.salePrice) }</strong>
				</div>;
			
		}
		
		
		//Cleans description of html.
		let long_description
		if (this.props.data.long_description) {
			long_description = this.props.data.long_description.replace(/(?:\r\n|\r|\n)/g, '<br/>');
		}
		
		return (
			<div className={ css(this.styles.productDetailPage) }>
				<Elements.Container>
					
					<Elements.Row className={ css(this.styles.productrow_wRow) }>
						<Elements.Column data={ {mdColumns: 6, smColumns: 12} }>
							<ProductImageGallery
								global={ this.props.global }
								images={ this.props.data.images }/>
						</Elements.Column>
						{/** Product Description **/}
						<Elements.Column data={ {mdColumns: 6, smColumns: 12} }>
							<div className={ css(this.styles.textcontainer) }>
								{/** Title **/}
								<div className={ css(this.styles.titleholder) }>
									<div className={ css(this.styles.titlecontainer) }>
										<h3 className={ css(this.styles.producttitle) }>{this.props.data.title}</h3>
										{ priceSection }
									</div>
									{ subTitle }
								</div>
								{/** Description **/}
								<div className={ css(this.styles.titleholder) }>
									<p className={ css(this.styles.description) }>{ ReactHTMLParser(this.props.data.description) }</p>
								</div>
								{ /** Product options section **/ }
								
								{ productOptions }
								
								<div className={ css(this.styles.divider) }/>
								{ /** Add to cart/ subcription Options **/ }
								<div className={ css(this.styles.actioncontainer) }>
									<div id={ id(AddToCart, stringifiedProduct) }
											 className={ css(this.styles.addToCartInfernoComponent) }/>
								
								</div>
								{ /** Where quick buy options go **/ }
								<div className={ css(this.styles.variant1container) }>
								</div>
							</div>
						</Elements.Column>
					</Elements.Row>
					<div className={ css(this.styles.descontainer) }>
						<p className={ css(this.styles.bodytext) }>{ ReactHTMLParser(long_description) }</p>
					</div>
				</Elements.Container>
			</div>
		)
	}
}


ProductDetailPage.defaultProps = {
	data: defaultData,
	global: defaultGlobal
};
