import React from 'react';
import styled from 'styled-components';
import {GLOBALS} from "../../styles/global-default-styles";

const FooterContainer = styled.div`
  background:${GLOBALS.colorPrimaryDark};
  color:${GLOBALS.colorPrimaryLight};
  padding:40px;
  text-align:center;
`;

export const Footer = (props) => {


  return (
    <FooterContainer>
      <p>Terms and Conditions | Privacy Policy</p>
    </FooterContainer>)


}
