import { StyleSheet } from "@convertly/css";
import { clientImage } from '../../../utils/generateImage';
import convertlySliderTransitions from '../../convertly-slider-transistions';
import userOverrides from '../../../elements/style-functions/user-overrides';
import { generatePseudoMediaQuery } from '../../../elements/style-functions/pseudo-media-queries'


const slideStyles = ( global, data, sliderSettings, inEditor ) => {

    const transition = convertlySliderTransitions( global, sliderSettings );
    const slideUserOverrides = userOverrides( global, data, inEditor );
    let overlayOverrides = {};
    if(data.overlay) {
        overlayOverrides = userOverrides(global, data.overlay, inEditor);
    }

    return StyleSheet.create({

        slide: {
            width: '100%',
            height:'100%',
            top:0,
            display:'inline-block',
            zIndex: 10,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50%",
            backgroundSize: "cover",
            "@media(hover:hover)": {
                transition:transition.activeTransition,
            },
            ...transition.activeBaseStyles,
            '.active': {
                zIndex:20,
                ...transition.activeActiveStyles,
            },
            ...slideUserOverrides
        },
        slideContent: {
            height:'100%',
            width:'100%',
            zIndex:'20',
            display:'block',
            paddingTop:"200px",
            position:'absolute',
            overflow: 'hidden',
            ...generatePseudoMediaQuery(
                "@media(max-width:991px)",
                ['mobile', 'smallTablet', 'largeTablet'],
                {
                    paddingTop:'100px'
                },
                inEditor
            )
        },
        textArea: {
            width:'100%',
            height: 'auto'
        },
        overlay: {
            position:'absolute',
            top:0,
            left:0,
            height:'100%',
            width:'100%',
            zIndex:'2',
            ...overlayOverrides
        }
    })
};

export default slideStyles;
