import { createAction } from "redux-act";
import _ from "lodash";
import { fromJS } from "immutable";
import { store } from "app/app";
import Logger from "utils/logger";
import { graph, purgeSiteCache } from "app/utils";

import { changePanel } from "../../actions/navBarActions";

export const setLocations = createAction("Site Settings: Set locations");
export const setLoaded = createAction("Site Settings: Set locations is loaded flag");

export const setEditNewLocation = createAction("Site Settings: Edit New Location");

export const setNewLocation = createAction("Site Settings: Set New Location");
export const setResetNewLocation = createAction("Site Settings: Reset New Location");

export const setSavingState = createAction("Site Settings: Set saving flag");

export const setEditLocationIndex = createAction("Site Settings: Set edit location");
export const setResetEditLocationIndex = createAction("Site Settings: Set reset edit location");

export const setLaunchRunTimeModal = createAction("Site Settings: Launch RunTimeModal");

export const setCloseRunTimeModal = createAction("Site Settings: Launch RunTimeModal");

export const setSchedules = createAction("Site Settings: Set Schedules");
export const setResetSchedules = createAction("Site Settings: Reset Schedules");

export const setEditLocation = createAction("Site Settings: Set Edit Location");
export const setResetEditLocation = createAction("Site Settings: Set Reset Location");

// export const setEditLocationIndex

///////////////////////////
export function saveLocations() {
  return async dispatch => {
    await dispatch(setSavingState(true));
    let locations = store
      .getState()
      .get("editor")
      .Locations.get("locations");

    let editLocationIndex = store
      .getState()
      .get("editor")
      .Locations.get("editLocationIndex");

    let editLocation = store
      .getState()
      .get("editor")
      .Locations.get("editLocation");

    if (editLocationIndex) {
      // if editLocationIndex === -1 a new location was created
      locations =
        editLocationIndex === -1
          ? // ? locations.setIn([locations.size], editLocation)
            locations.push(editLocation)
          : locations.setIn([editLocationIndex], editLocation);
      await dispatch(setLocations(locations));
    }

    const siteId = store.getState().getIn(["auth", "activeSite", "id"]);

    const query = `
      mutation( $id:ID!, $settings:siteStoreSettingsInput ) {
        updateSiteStoreSettings(id:$id, settings: $settings ) {
        id
        }
      }
      `;

    const variables = {
      id: siteId,
      settings: {
        locations: JSON.stringify(locations.toJS()),
      },
    };

    const response = await graph({ query, variables });
    await dispatch(setEditLocationIndex(0));
    await dispatch(setSavingState(false));
    await dispatch(changePanel("SETTINGS"));
  };
}

export function loadLocations() {
  return async dispatch => {
    const siteId = store.getState().getIn(["auth", "activeSite", "id"]);

    const query = `
      query ($id: ID! ) {
        Site(id: $id) {
        locations
        }
      }
      `;

    const variables = {
      id: siteId,
    };

    const response = await graph({ query, variables });
    let locations = _.get(response, "Site.locations") || "[]";

    try {
      locations = JSON.parse(locations);
    } catch (e) {
      locations = [];
    }
    const businessSettings = await fetchBusinessSettings(siteId);

    // there will always be 1 location (at the very least main business address is a location)
    if (!locations.length) {
      locations.push(createLocationFromBusinessSettings(businessSettings));
      await dispatch(saveLocations());
    }
    await dispatch(setLocations(fromJS(locations)));
    await dispatch(setLoaded(true));
  };
}

export const fetchLocations = async siteId => {
  const query = `
      query ($id: ID! ) {
        Site(id: $id) {
        locations
        }
      }
      `;

  const variables = {
    id: siteId,
  };

  const response = await graph({ query, variables });
  let locations = _.get(response, "Site.locations") || "[]";

  try {
    locations = JSON.parse(locations);
  } catch (e) {
    locations = [];
  }
  return locations;
};

/////////////////////// business settings to locations //////////////////////////

export const createLocationFromBusinessSettings = businessSettings => {
  const { businessName, address, city, state, zip, country, email, phone, fax } = businessSettings;
  const location = {
    primaryLocation: true,
    locationName: businessName,
    address,
    city,
    state,
    zip,
    country: "United States",
    email,
    phone,
    fax,
    businessHours: [],
  };

  // do something with businessSettings data
  return location;
};

export const hasBusinessSettings = businessSettings => {
  // delete businessSettings.businessName;
  let count = 0;
  Object.keys(businessSettings).forEach(k => {
    if (businessSettings[k]) {
      count++;
    }
  });
  return count > 0;
};

export const fetchBusinessSettings = async siteId => {
  const query = `
      query ($id: ID! ) {
        Site(id: $id) {
        generalSettings {
          businessName
          address
          city
          state
          zip
          country
          phone
          fax
          email
        }
        }
      }`;

  const variables = {
    id: siteId,
  };

  const response = await graph({ query, variables });
  const businessInfo = _.get(response, "Site.generalSettings") || {};
  return businessInfo;
};

/////////////////////// add/edit location //////////////////////////

export const fillEditLocation = () => async dispatch => {
  const locations = store
    .getState()
    .get("editor")
    .Locations.get("locations");

  const editLocationIndex = store
    .getState()
    .get("editor")
    .Locations.get("editLocationIndex");

  const location =
    // adding new location
    editLocationIndex === -1
      ? fromJS({
          businessHours: [],
          // primaryLocation currently not used
          // primaryLocation: false,
          country: "United States",
        })
      : locations.get(editLocationIndex);
  await dispatch(setEditLocation(location));
};

export const updateEditLocation = (field, value) => async dispatch => {
  let editLocation = store
    .getState()
    .get("editor")
    .Locations.get("editLocation");

  editLocation = editLocation.merge({
    [field]: value,
  });
  await dispatch(setEditLocation(editLocation));
};

/////////////////////// edit branch Options //////////////////////////

export const showEditOptions = idx => async dispatch => {
  let editLocationIndex = store
    .getState()
    .get("editor")
    .Locations.get("editLocationIndex");

  if (idx === editLocationIndex) {
    await dispatch(setEditLocationIndex(null));
  } else {
    await dispatch(setEditLocationIndex(idx));
  }
};

export const duplicateBranch = () => async dispatch => {
  let locations = store
    .getState()
    .get("editor")
    .Locations.get("locations");

  let editLocationIndex = store
    .getState()
    .get("editor")
    .Locations.get("editLocationIndex");

  // handle edge case of duplicating primary location
  let location = locations.get(editLocationIndex);
  location = location.setIn(["primaryLocation"], false);

  locations = locations.setIn([locations.size], location);
  await dispatch(setLocations(locations));
};

export const deleteBranch = () => async dispatch => {
  let locations = store
    .getState()
    .get("editor")
    .Locations.get("locations");

  let editLocationIndex = store
    .getState()
    .get("editor")
    .Locations.get("editLocationIndex");

  // cannont delete main location/business
  if (editLocationIndex === 0) return;

  locations = locations.delete(editLocationIndex);
  await dispatch(setResetEditLocationIndex());
  await dispatch(setLocations(locations));
};

/**
 *
 *
 *  setPrimaryBranch was used in old settings, not used for now
 *
 *
 */

// export const setPrimaryBranch = () => async dispatch => {
//   let locations = store
//     .getState()
//     .get("editor")
//     .Locations.get("locations");

//   let editLocationIndex = store
//     .getState()
//     .get("editor")
//     .Locations.get("editLocationIndex");

//   // loop through locations and set all primary to false

//   locations = locations.map((location, idx) => {
//     console.log("Here is the location ", location);
//     console.log("Here is the editLocationIndex ", editLocationIndex);
//     return editLocationIndex === idx
//       ? location.setIn(["primaryLocation"], true)
//       : location.setIn(["primaryLocation"], false);
//   });
//   locations = locations.sortBy(location => location.get("primaryLocation") === false);
//   await dispatch(setEditLocationIndex(0));
//   await dispatch(setLocations(locations));
// };

/////////////////////// modal actions //////////////////////////

export const convertBackToLocationBusinessHours = () => async dispatch => {
  let editLocation = store
    .getState()
    .get("editor")
    .Locations.get("editLocation");

  const schedules = store
    .getState()
    .get("editor")
    .Locations.get("schedules")
    .toJS();

  const dayMap = {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  };
  const businessHours = [];

  schedules.forEach(schedule => {
    let day = "";
    let startHour = "";
    let startMinutes = "";
    let startPeriod = "";
    let endHour = "";
    let endMinutes = "";
    let endPeriod = "";

    let hourStart = schedule.time.start ? schedule.time.start.split(":")[0] : "12";
    let hourEnd = schedule.time.end ? schedule.time.end.split(":")[0] : "12";

    if (Number(hourStart) > 12) {
      startHour += Number(hourStart) - 12;
    } else if (hourStart === "00") startHour += "12";
    else startHour = hourStart;

    if (Number(hourEnd) > 12) {
      endHour += Number(hourEnd) - 12;
    } else if (hourEnd === "00") endHour += "12";
    else endHour = hourEnd;

    startMinutes = schedule.time.start ? schedule.time.start.split(":")[1] : "00";
    endMinutes = schedule.time.end ? schedule.time.end.split(":")[1] : "00";
    startPeriod = Number(hourStart) >= 12 ? "PM" : "AM";
    endPeriod = Number(hourEnd) >= 12 ? "PM" : "AM";

    schedule.days.forEach(dayNum => {
      day = dayMap[dayNum];
      businessHours.push({
        day,
        startHour,
        startMinutes,
        startPeriod,
        endHour,
        endMinutes,
        endPeriod,
      });
    });
  });

  editLocation = editLocation.setIn(["businessHours"], fromJS(businessHours));
  await dispatch(setEditLocation(editLocation));
};

export const locationBusinessHoursToModalTime = () => async dispatch => {
  let editLocation = store
    .getState()
    .get("editor")
    .Locations.get("editLocation");

  const editLocationIndex = store
    .getState()
    .get("editor")
    .Locations.get("editLocationIndex");

  let businessHours = editLocation.get("businessHours");

  businessHours = businessHours.toJS();

  const days = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };
  const schedules = [];

  // used for days that have the same hours
  const dayMap = {};

  businessHours.forEach(time => {
    let start = "";
    let end = "";
    let day = "";
    day = days[time.day];

    if (time.startPeriod === "PM" && time.startHour !== "12") start += Number(time.startHour) + 12;
    else if (time.startPeriod === "AM" && time.startHour === "12") start += "00";
    else start += time.startHour;
    start += ":" + time.startMinutes;

    if (time.endPeriod === "PM" && time.endHour !== "12") end += Number(time.endHour) + 12;
    else if (time.endPeriod === "AM" && time.endHour === "12") end += "00";
    else end += time.endHour;
    end += ":" + time.endMinutes;

    if (start in dayMap) {
      if (dayMap[start]) dayMap[start].push(day);
    } else dayMap[start] = [end, day];
  });

  for (let k in dayMap) {
    schedules.push({
      days: dayMap[k].slice(1),
      time: { start: k, end: dayMap[k][0] },
    });
  }
  await dispatch(setSchedules(fromJS(schedules)));
};

export const addSchedule = () => async dispatch => {
  let schedules = store
    .getState()
    .get("editor")
    .Locations.get("schedules");
  schedules = schedules.push(
    fromJS({
      days: [],
      time: {},
    })
  );
  await dispatch(setSchedules(schedules));
};

export const deleteSchedule = scheduleIndex => async dispatch => {
  let schedules = store
    .getState()
    .get("editor")
    .Locations.get("schedules");
  schedules = schedules.delete(scheduleIndex);
  await dispatch(setSchedules(schedules));
};

export const toggleDay = ({ scheduleIndex, value }) => async dispatch => {
  let schedules = store
    .getState()
    .get("editor")
    .Locations.get("schedules");
  let days = schedules.getIn([scheduleIndex, "days"]) || fromJS([]);
  const indexOf = days.indexOf(value);
  if (indexOf === -1) {
    days = days.push(value);
  } else {
    days = days.delete(indexOf);
  }
  schedules = schedules.setIn([scheduleIndex, "days"], days);
  await dispatch(setSchedules(schedules));
};

export const updateTime = ({ updatedTime, scheduleIndex, field }) => async dispatch => {
  let schedules = store
    .getState()
    .get("editor")
    .Locations.get("schedules");

  schedules = schedules.setIn([scheduleIndex, "time", field], updatedTime);

  await dispatch(setSchedules(schedules));
};
