/*global Inferno, $, React */
import React from "react";

const ContestSubmissionsModalGenerator = () => {


    const ContestSubmissionsModal = ({activeSubmission, closeModal, imgPath}) => {


        return (
            <div className={"contest-submission-modal-wrapper"}>
                <div onClick={closeModal} className={"contest-submission-modal-overlay"}/>
                <div className={"contest-submission-modal"}>
                    <div>
                    <img className={"contest-submission-modal-image"}
                         src={imgPath + activeSubmission.imageUrl}
                         alt={activeSubmission.imageName}/>
                    </div>
                    <div className={"contest-submission-modal-content"}>
                        <h2>{activeSubmission.imageName}</h2>
                        {
                            activeSubmission.imageDescription &&
                            <p>{activeSubmission.imageDescription}</p>
                        }
                    </div>
                </div>

            </div>
        )

    }

    return ContestSubmissionsModal


}

export default ContestSubmissionsModalGenerator



