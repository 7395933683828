import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

/**
 * styles of the enclosing wrapper of the input
 */
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({width}) => width && `width: ${width};`}
  ${({height}) => height && `height: ${height};`}
`;

/**
 * Styles  of an input of type upload
 */
const UploadInput =  styled.input`
  width: 100%;
  height: 100%;
`;

export const UploadInputWrapper = ({ height, width, children, ...props }) => {
  return (
    <Wrapper width={width} height={height} {...props}>
      <UploadInput as="input" type="upload" />
      {!!children && children}
    </Wrapper>
  );
};

UploadInputWrapper.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node]),
};

UploadInputWrapper.defaultProps = {
  height: "",
  width: "",
  children: <div />,
};
