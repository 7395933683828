import { StyleSheet } from '@convertly/css';
import { generatePseudoMediaQuery } from '../../../../elements/style-functions/pseudo-media-queries'
import UserOverrides from '../../../../elements/style-functions/user-overrides';

const Styles = (global, data, inEditor) => {

    const userOverrides = UserOverrides(global, data, inEditor);

    return StyleSheet.create({

        column: {
            display: 'inline-block',
            width: "25%",
            ...generatePseudoMediaQuery(
                "@media (max-width: 991px)",
                ['largeTablet'],
                {
                    width: "50%"
                },
                inEditor
            ),
            ...generatePseudoMediaQuery(
                "@media (max-width: 768px)",
                ['smallTablet', 'mobile'],
                {
                    width: "100%",
                    textAlign:'left'
                },
                inEditor
            ),
        },

        menuItemContainer: {
            ...userOverrides
        },

        menuItem: {
            padding: "5px 13px",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            margin: "0px 4px",
            transition: '.15s all ease',
            marginBottom: "10px"
        },

        leftItem: {
            width: "100%"
        },

        rightItem: {
            minWidth: "40px"
        },

        headingAndPrice: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%"
        },

        itemHeading: {
            fontSize: "14px",
            margin: "0 0 5px",
            fontWeight: 'bold',
            fontFamily: global.fontFamily.primaryFont,
            color: global.colors.dark.color1
        },

        itemQuantity: {
            lineHeight: "22px",
            minHeight: "22px",
            fontSize: "12px"

        },

        itemDescription: {
            margin: "0",
            fontFamily: global.fontFamily.secondaryFont,
            color: global.colors.dark.color1
        },

        itemTags: {
            padding: "5px 0px 5px"
        },

        tag: {
            display: 'inline-block',
            padding: "5px 10px",
            backgroundColor: global.colors.accent.color2,
            color: "white",
            fontSize: "10px",
            marginTop: "3px",
            marginRight: "5px"

        },

        itemPrice: {}

    })

};

export default Styles;
