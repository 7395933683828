/*global Inferno, $ */
/* eslint-disable */
import {include} from '@convertly/thor';

const Script = () => {

    const Graph = include('../../../../utils/graph', 'Graph');
    const Dispatcher = include('../../../../utils/dispatcher', 'Dispatcher');
    const ConvertToCurrency = include('../../../../utils/ecomm-utils/convert-to-currency-ii', 'ConvertToCurrency');
    const InfernoHTMLStripper = include('../../../../utils/inferno-html-stripper', 'InfernoHTMLStripper');
    const ProductGridItemInferno = include('./product-grid-item', 'ProductGridItemInferno');
    const SortCategoryBarInferno = include('./components/sort-category-bar', "SortCategoryBarInferno");

    const AddToCartStepperWidget = include('../../../../ecommerce-common-components/add-to-cart-stepper/add-to-cart-qty-stepper', "AddToCartStepperWidget");

    const PriceInferno = include('./components/price', "PriceInferno");
    const QuickBuyButtonInferno = include('./components/quick-buy-button', "QuickBuyButtonInferno");
    const AddToCartButtonInferno = include('./components/add-to-cart', "AddToCartButtonInferno");
    const QuickViewModalInferno = include('./components/quick-view-modal', "QuickViewModalInferno");
    const ProductImageGalleryInferno = include('./components/product-image-gallery', "ProductImageGalleryInferno");
    const SpinnerSVGInferno = include('../../../../svg/spinner/spinner', 'SpinnerSVGInferno');

    //Product Variant Widgets
    const TestInferno = include('./components/product-variant-widgets/stateless-test', 'TestInferno');
    const ColorPickerInferno = include('./components/product-variant-widgets/color-picker', 'ColorPickerInferno');


    const ImageThumbNailInferno = include('./components/img-thumbnail', 'ImageThumbNailInferno');


    return (id, productsArr, clientUrl, imageUrl, numOfColumns, numberOfRows, EcommerceSettings, imageUrlsStringified, siteId) => {



        const imageUrls = JSON.parse(imageUrlsStringified);

        //Utils
        const dispatch = Dispatcher();
        const infernoHTMLStripper = InfernoHTMLStripper();
        const convertToCurrency = ConvertToCurrency();
        const graph = Graph();

        //inferno components
        const ImageThumbNail = ImageThumbNailInferno( dispatch, imageUrls );
        const Spinner = SpinnerSVGInferno();
        const Price = PriceInferno();
        const ColorPicker = ColorPickerInferno();
        const ProductGridItem = ProductGridItemInferno(dispatch, QuickBuyButtonInferno, AddToCartButtonInferno, QuickViewModalInferno, ProductImageGalleryInferno, AddToCartStepperWidget, Price, clientUrl, imageUrl, infernoHTMLStripper, convertToCurrency, ImageThumbNail, imageUrls, ColorPicker);
        const SortCategoryBar = SortCategoryBarInferno(dispatch, QuickBuyButtonInferno, AddToCartButtonInferno, clientUrl);

        //Product Variant Widgets
        const Test = TestInferno();

        const hasLocalStorage = $('html').hasClass('localstorage');

        const root = document.getElementById(id);
        root.innerHTML = '';

        let products;

        try {
          products = JSON.parse(productsArr);
        } catch( e ) {
          console.warn( 'Error parsing products' );
        }

        const ecommerceSettings = JSON.parse(EcommerceSettings);


        const ProductGallery02 = Inferno.createClass({

            getInitialState: function () {
                return {
                    products: products,
                    renderStage: 1,
                    productsRenderArray: [],
                    paginationMeta: [],
                    pagesLoaded: [],
                    loadMoreIndex: 0,
                    pages: []
                };
            },

            /** Sorts products - TODO: make able to handle more options */
            sortProducts: function (key, direction) {
                const compare = function (a, b) {
                    if (a[key] < b[key]) {
                        if (direction === "decending") {
                            return -1;
                        }
                        else {
                            return 1
                        }
                    }
                    if (a[key] > b[key]) {
                        if (direction === "decending") {
                            return 1;
                        }
                        else {
                            return -1
                        }
                    }
                    return 0;
                };

                let products =
                    this.state.products.sort(compare);
                this.setState({products: products});
                this.splitIntoPaginationArrays();

            },
            /** Converts products array into a 3d array
             * allowing to create arrays and columns
             **/
            splitIntoPaginationArrays: function () {
                let pageArray = [];
                let rowArray = [];
                let productArray = [];


                this.state.products.map((product, index) => {
                    product.description = product.description = product.description.replace(/<<>>/g, "'");

                    if (( index + 1 ) % numOfColumns === 0) {
                        productArray.push(product);
                        rowArray.push(productArray);
                        productArray = [];
                        if (parseInt(index + 1) === parseInt(products.length)) {
                            pageArray.push(rowArray);
                            rowArray = [];
                        }
                        else if (parseInt(rowArray.length) === parseInt(numberOfRows)) {
                            pageArray.push(rowArray);
                            rowArray = [];
                        }
                    }
                    else {
                        productArray.push(product);
                        if (parseInt(index + 1) === parseInt(products.length)) {
                            rowArray.push(productArray);
                            pageArray.push(rowArray);
                            rowArray = [];
                            productArray = [];
                        }
                    }

                });

                this.addPaginationMetaData(pageArray);
            },
            /** Creates class names for each page in pagination **/
            addPaginationMetaData: function (productsRenderArray) {
                paginationMeta = this.state.paginationMeta;
                productsRenderArray.map((page, index) => {

                    if (index === 0) {
                        paginationMeta.push({
                            className: "active",
                            launcherClassName: "active"
                        })
                    }
                    else {
                        paginationMeta.push({
                            className: "page-inactive-right",
                            launcherClassName: ""
                        })
                    }
                });
                this.setState({
                    paginationMeta: paginationMeta,
                    productsRenderArray: productsRenderArray
                });

                this.loadSetOfProducts(0)


            },

            /** Loads set of products for Load More button - TODO: pagination function**/
            loadSetOfProducts: function (index) {
                let pages = this.state.pages;
                pages.push(
                    <div className={ `${root.className} page active` }>
                        {  /** Creates a new row based on number of columns in row **/
                            this.state.productsRenderArray[index].map((row) => {
                                return (
                                    <div className="row">
                                        {  /** Adds products to row **/
                                            row.map((product) => {
                                                const pricing = product.pricing;
                                                const brand = product.manufacture_details.manufacturer;
                                                const {title} = product;
                                                const url = {
                                                    linkType: 'internal',
                                                    url: product.slug
                                                };
                                                    return (
                                                        <ProductGridItem
                                                            product={ product }
                                                            pricing={ pricing }
                                                            loadProductVariants={ this.loadProductVariants }
                                                            brand={ brand }
                                                            title={ title }
                                                            columns={ numOfColumns }
                                                            url={ `${clientUrl}${url.url}` }
                                                            imgUrl={ product.images[0] }
                                                            imgUrls={ imageUrls }
                                                            rootDiv={root}/>
                                                    )
                                            }) }
                                    </div>
                                )
                            }) }
                    </div>
                );

                this.setState({
                    pages: pages,
                    loadMoreIndex: this.state.loadMoreIndex + 1
                });


            },

            /** Changes pages on click **/
            changePage: function (i) {


                paginationMeta = this.state.paginationMeta;
                paginationMeta.map((metaItem, index) => {

                    if (index == i) {
                        metaItem.className = "active";
                        metaItem.launcherClassName = "active"
                    }
                    else if (index > i) {
                        metaItem.className = "page-inactive-right";
                        metaItem.launcherClassName = "";
                    }
                    else {
                        metaItem.className = "page-inactive-left";
                        metaItem.launcherClassName = "";
                    }
                });

                this.setState({paginationMeta: paginationMeta})
            },

            /** Loads Product Variants **/
            loadProductVariants: function(id) {

                return false // remove once on branch

                const query = `query( $id: ID! ) { Product( id: $id ) {id,
                productVariants {variations { variationType, value }, price, onSale, qtyInStock } } }`;

                let currentIndex;
                let products = this.state.products;
                let currentProduct = products.filter(  ( product, index ) => {

                        if( product.id === id ) {
                            currentIndex = index;
                        }

                        return product.id === id
                    }
                );
              

                const variables = {
                    id
                };

                const cb = (err, success) => {
                    if(success) {
                        console.log('sent success data data', success);
                        products[currentIndex].productVariants = Product.productVariants;
                        this.setState( { products } );
                    }
                    else {
                        console.log('sent error data data', err);
                    }
                    console.log(this.state.products);
                };

                graph( {query, variables, cb} );

            },

            componentWillMount() {
                this.splitIntoPaginationArrays();
            },

            render: function () {

                /** Sort/filtration bar **/
                let filtrationBar = null;
                let showFiltrationBar = false;
                if (showFiltrationBar) {
                    filtrationBar =
                        <SortCategoryBar rootDiv={root} products={ products }/>
                }

                let pages = this.state.pages;

                let ecommSettings = true;

                let displayMoreProductsMethod;

                /** pagination **/
                if (ecommerceSettings.displayMoreProductsMethod === "pagination") {
                    displayMoreProductsMethod =
                        <div className={ `${root.className} paginationIndexContainer` }>
                            {  /**creates pagination tabs **/
                                this.state.productsRenderArray.map((page, index) => {
                                    if (this.state.productsRenderArray.length <= 1) {
                                        return null
                                    }
                                    else {
                                        return (
                                            <p className={ `${root.className} paginationIndex ${this.state.paginationMeta[index].launcherClassName}` }
                                               onClick={ () => this.loadSetOfProducts(index) }>{ (index + 1) }</p>)
                                    }
                                })
                            }
                        </div>
                }

                /** load more button **/

                if (ecommerceSettings.displayMoreProductsMethod === "loadMoreButton") {

                    if (this.state.loadMoreIndex < this.state.productsRenderArray.length) {
                        displayMoreProductsMethod =
                            <div className={ `${root.className} load-more-button-container` }>
                                <div className={ `${root.className} load-more-button` }
                                     onClick={ () => this.loadSetOfProducts(this.state.loadMoreIndex) }>See More
                                    Products
                                </div>
                            </div>
                    }
                }

                return (
                    <div>
                        <div>
                            { filtrationBar }
                        </div>

                        <div className={ `${root.className} product-grid-container` }>
                            <Test src={"https://seeklogo.com/images/I/inferno-logo-FACC443C0B-seeklogo.com.png"}
                                  rootClass={ root.className }
                                  reactImg={ 'img-2' }
                            />
                            <Test src={"https://vignette.wikia.nocookie.net/prehistrico/images/9/9d/T-rex.png/revision/latest/scale-to-width-down/454?cb=20150511220722&path-prefix=es"}
                                  rootClass={ root.className }
                                  reactImg={ 'img-3' }
                                  queMusic={ false }
                            />
                            { pages }
                        </div>
                        { displayMoreProductsMethod }
                    </div>
                )
            }

        });

        Inferno.render(<ProductGallery02 />, root);

    };

};

export default Script;
