import {StyleSheet} from "@convertly/css";
import addToCartQtyStepperStyleObject from '../../../ecommerce-common-components/add-to-cart-stepper/add-to-cart-qty-stepper-styles';


const ProductDetailPageStyles = (global, data, inEditor) => {

    const addToCartQtyStepperStyles = addToCartQtyStepperStyleObject(global, data, inEditor);

    return StyleSheet.create({

        productDetailPage: {
            marginTop: '50px',
            color: global.textColors.dark.black,
            fontFamily: global.fontFamily.primaryFont,
            fontSize: "14px",
            lineHeight: "20px",
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: "1140px",
            position: "relative",
            width: "95%",
            zIndex: "2"
        },

        breadcrumbContainer_wClearfix: {
            borderBottom: "1px solid #667785",
            borderTop: "1px solid #667785",
            color: global.textColors.dark.black,
            fontSize: "14px",
            height: "55px",
            lineHeight: "20px",
            paddingBottom: "10px",
            paddingTop: "10px",
            width: "100%"
        },

        breadcrumbLinkContainer_wClearfix: {
            color: global.textColors.dark.black,
            display: "inline",
            float: "left",
            fontSize: "14px",
            lineHeight: "20px",
            paddingTop: "10px",
            width: "50%"
        },

        breadcrumbLink: {
            color: global.textColors.dark.color2,
            float: "left",
            fontSize: "15px",
            lineHeight: "15px",
            textDecoration: "none"
        },

        breadcrumbLink_separator: {
            background: global.backgroundColors.dark.color2,
            color: global.textColors.dark.color2,
            float: "left",
            fontSize: "15px",
            lineHeight: "15px",
            paddingLeft: "10px",
            paddingRight: "10px",
            textDecoration: "none"
        },

        sharebtn_wButton: {
            backgroundColor: global.backgroundColors.light.color2,
            backgroundImage: "url('../images/chevright2.png')",
            backgroundPosition: "100% 50%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "auto",
            border: "1px solid #667785",
            borderRadius: "50px",
            color: global.textColors.dark.color2,
            cursor: "pointer",
            display: "inline-block",
            float: "right",
            fontSize: "13px",
            lineHeight: "inherit",
            padding: "5px 25px 5px 20px",
            textDecoration: "none"
        },

        productrow_wRow: {
            backgroundColor: "transparent",
            color: global.textColors.dark.black,
            fontSize: "14px",
            lineHeight: "20px",
            marginLeft: "0",
            marginRight: "0",
            marginTop: "0px"
        },

        column_2_wCol_wCol_6: {
            color: global.textColors.dark.black,
            float: "left",
            fontSize: "14px",
            left: "auto",
            lineHeight: "20px",
            minHeight: "1px",
            paddingLeft: "0px",
            paddingRight: "10px",
            position: "relative",
            right: "auto",
            width: "100%"
        },


        column_wCol_wCol_6: {
            color: global.textColors.dark.black,
            float: "left",
            fontSize: "14px",
            left: "auto",
            lineHeight: "20px",
            minHeight: "1px",
            paddingLeft: "10px",
            paddingRight: "0px",
            position: "relative",
            right: "auto",
            width: "100%"
        },

        textcontainer: {
            color: global.textColors.dark.black,
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            justifyContent: "center",
            lineHeight: "20px",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop:'30px',
            maxWidth: "95%",
            position: "relative",
            textAlign: "left",
            width: "700px"
        },

        titleholder: {
            color: global.textColors.light.color3,
            fontSize: "14px",
            lineHeight: "24px",
            textAlign: "left",
        },

        titlecontainer: {
            color: global.textColors.dark.black,
            display: "flex",
            fontSize: "14px",
            justifyContent: "space-between",
            lineHeight: "20px",
            position: "relative",
            textAlign: "left"
        },

        producttitle: {
            color: global.textColors.dark.black,
            fontWeight: "bold",
            fontSize: "20px",
            lineHeight: "30px",
            margin: "0.67em 0",
            marginBottom: "5px",
            marginTop: "0px",
            textAlign: "left",
        },

        priceContainer: {
            textAlign:'right'
        },
        price_producttitle: {
            color: global.textColors.dark.color2,
            fontSize: "20px",
            lineHeight: "30px",
            margin: "0.67em 0",
            marginBottom: "5px",
            marginTop: "0px",
            textAlign: "left",
            ".on-sale": {
                fontSize:'12px',
                textDecoration:"line-through"
            }
        },
        price_saleprice: {
            fontSize: "20px",
            display:"block",
            color:global.textColors.accent.color1
        },

        subtitle: {
            color: global.textColors.dark.color3,
            fontSize: "13px",
            lineHeight: "20px",
            marginBottom: "15px",
            textAlign: "left"
        },

        description: {
            color: global.textColors.dark.color1,
            fontSize: "12px",
            lineHeight: "20px",
            marginBottom: "10px",
            marginTop: "0",
            textAlign: "left"
        },
        divider: {
            borderBottom: "1px solid",
            borderColor: global.backgroundColors.dark.color1,
            width: '100%',
            margin: '30px 0 10px'
        },
        optionscontainer: {
            borderBottom: "1px solid",
            borderColor: global.backgroundColors.dark.color1,
            color: global.textColors.dark.black,
            display: "flex",
            fontSize: "14px",
            lineHeight: "20px",
            paddingBottom: "8px",
            textAlign: "left"
        },

        divBlock_section1: {
            color: global.textColors.dark.black,
            flex: "1",
            fontSize: "14px",
            lineHeight: "20px",
            marginBottom: "15px",
            maxWidth: "auto",
            minWidth: "30%",
            textAlign: "left"
        },

        label_subtitle: {
            color: global.textColors.dark.color3,

            fontSize: "11px",
            lineHeight: "20px",
            marginBottom: "7px",
            textAlign: "left"
        },

        optionbtn: {
            backgroundColor: global.backgroundColors.light.color2,
            border: "2px solid #667785",
            color: global.textColors.dark.black,
            display: "inline-block",
            fontSize: "11px",
            height: "30px",
            lineHeight: "13px",
            marginRight: "5px",
            padding: "6px 8px 0px",
            textAlign: "center",
            textDecoration: "none",
            textTransform: "uppercase"
        },

        optionbtn_selected: {
            backgroundColor: "#b4bbc3",
            border: "2px solid #667785",
            color: global.textColors.light.white,
            display: "inline-block",
            fontSize: "11px",
            height: "30px",
            lineHeight: "13px",
            marginRight: "5px",
            padding: "6px 8px 0px",
            paddingBottom: "0px",
            textAlign: "center",
            textDecoration: "none",
            textTransform: "uppercase"
        },

        divBlock_2: {
            color: global.textColors.dark.black,
            display: "flex",
            flex: "1",
            fontSize: "14px",
            lineHeight: "20px",
            textAlign: "left"
        },

        divBlock_var2: {
            color: global.textColors.dark.black,
            flex: "1",
            fontSize: "14px",
            lineHeight: "20px",
            marginBottom: "15px",
            maxWidth: "auto",
            textAlign: "left"
        },

        variant1container: {
            color: global.textColors.dark.black,
            display: "flex",
            fontSize: "14px",
            lineHeight: "20px",
            textAlign: "left"
        },

        selected_selector: {
            backgroundColor: "#0098ff",
            border: "4px solid #f5f5f5",
            borderRadius: "50%",
            boxShadow: "0 0 0 3px #667785",
            color: global.textColors.dark.black,
            fontSize: "14px",
            height: "29px",
            lineHeight: "20px",
            marginLeft: "3px",
            marginRight: "3px",
            textAlign: "left",
            width: "29px"
        },

        selector: {
            backgroundColor: "#0098ff",
            border: "4px solid #f5f5f5",
            borderRadius: "50%",
            boxShadow: "0 0 0 3px #f5f5f5",
            color: global.textColors.dark.black,
            fontSize: "14px",
            height: "29px",
            lineHeight: "20px",
            marginLeft: "3px",
            marginRight: "3px",
            textAlign: "left",
            width: "29px"
        },

        centerize_label_subtitle: {
            color: global.textColors.dark.color3,
            fontSize: "11px",
            lineHeight: "20px",
            marginBottom: "7px",
            textAlign: "center"
        },

        subscriptioncontainer: {
            alignItems: "center",
            color: global.textColors.dark.black,
            display: "flex",
            fontSize: "14px",
            justifyContent: "center",
            lineHeight: "20px",
            marginTop: "22px",
            textAlign: "left"
        },

        freqselector_wButton: {
            backgroundColor: "transparent",
            border: "2px solid #828588",
            borderRadius: "0",
            color: global.textColors.dark.color3,
            cursor: "pointer",
            display: "inline-block",
            fontFamily: "'Libre Baskerville', sans-serif",
            fontSize: "10px",
            lineHeight: "14px",
            marginLeft: "0px",
            marginRight: "5px",
            padding: "9px 15px",
            textAlign: "center",
            textDecoration: "none"
        },

        freqselector_selected_wButton: {
            backgroundColor: global.backgroundColors.light.color2,
            border: "2px solid #828588",
            borderColor: global.backgroundColors.dark.color1,
            borderRadius: "0",
            color: global.textColors.dark.color1,
            cursor: "pointer",
            display: "inline-block",
            fontFamily: "'Libre Baskerville', sans-serif",
            fontSize: "10px",
            lineHeight: "14px",
            marginLeft: "0px",
            marginRight: "5px",
            padding: "9px 15px",
            textAlign: "center",
            textDecoration: "none"
        },

        frequencycontainer: {
            alignItems: "center",
            color: global.textColors.dark.black,
            display: "flex",
            flex: "0 auto",
            fontSize: "14px",
            justifyContent: "flex-end",
            lineHeight: "20px",
            position: "relative",
            textAlign: "left"
        },

        first_toggletext: {
            color: global.textColors.dark.color1,
            flex: "0 auto",

            fontSize: "14px",
            lineHeight: "20px",
            paddingLeft: "10px",
            textAlign: "right"
        },

        wDropdown: {
            color: global.textColors.dark.black,
            display: "inline-block",
            fontSize: "14px",
            lineHeight: "20px",
            marginLeft: "auto",
            marginRight: "auto",
            position: "relative",
            textAlign: "left",
            zIndex: "900"
        },

        toggle_wDropdownToggle: {
            color: "#222222",
            cursor: "pointer",
            display: "inline-block",
            float: "none",
            fontSize: "14px",
            lineHeight: "20px",
            marginLeft: "auto",
            marginRight: "auto",
            padding: "10px 17px 10px 0px",
            position: "relative",
            textAlign: "left",
            textDecoration: "none",
            userSelect: "none",
            verticalAlign: "top",
            whiteSpace: "nowrap"
        },

        icon_wIconDropdownToggle: {
            bottom: "0",
            color: global.textColors.dark.color1,
            cursor: "pointer",
            fontFamily: "'webflow-icons'",
            fontSize: "14px",
            fontVariant: "normal",
            height: "1em",
            lineHeight: "1",
            margin: "auto",
            marginRight: "0px",
            position: "absolute",
            right: "0",
            speak: "none",
            textAlign: "left",
            textTransform: "none",
            top: "0",
            whiteSpace: "nowrap",
            width: "1em"
        },

        drop_toggletext: {
            color: global.textColors.dark.color1,
            cursor: "pointer",
            flex: "0 auto",

            fontSize: "14px",
            lineHeight: "20px",
            paddingLeft: "10px",
            textAlign: "right",
            whiteSpace: "nowrap"
        },

        dropdownList_wDropdownList: {
            background: "#dddddd",
            color: global.textColors.dark.black,
            display: "none",
            fontSize: "14px",
            lineHeight: "20px",
            minWidth: "100%",
            position: "absolute",
            textAlign: "left"
        },

        dropdownlink_wDropdownLink: {
            backgroundColor: global.backgroundColors.light.white,
            color: global.textColors.dark.color2,
            display: "block",
            fontSize: "12px",
            lineHeight: "20px",
            marginLeft: "auto",
            marginRight: "auto",
            minWidth: "60px",
            padding: "10px 20px",
            paddingLeft: "10px",
            paddingRight: "10px",
            position: "relative",
            textAlign: "center",
            textDecoration: "none",
            verticalAlign: "top",
            whiteSpace: "nowrap"
        },

        dropdown_wDropdown: {
            color: global.textColors.dark.black,
            display: "inline-block",
            flex: "0 auto",
            fontSize: "14px",
            lineHeight: "20px",
            marginLeft: "auto",
            marginRight: "0px",
            position: "relative",
            textAlign: "left",
            zIndex: "900"
        },

        actioncontainer: {
            alignItems: "center",
            color: global.textColors.dark.black,
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            justifyContent: "space-between",
            lineHeight: "20px",
            marginTop: "25px",
            textAlign: "left"
        },
        addToCartInfernoComponent: {
            ...addToCartQtyStepperStyles
        },

        actionlabel: {
            color: global.textColors.dark.color3,
            fontSize: "11px",
            lineHeight: "20px",
            marginBottom: "5px",
            textAlign: "left"
        },

        paymentlink_wInlineBlock: {
            backgroundColor: "transparent",
            color: global.textColors.dark.black,
            display: "inline-block",
            fontSize: "14px",
            lineHeight: "20px",
            marginLeft: "5px",
            marginRight: "5px",
            maxWidth: "100%",
            opacity: "0.3",
            textAlign: "left",
            transition: "opacity 400ms ease"
        },

        paymenticon: {
            border: "0",
            color: global.textColors.dark.black,
            display: "inline-block",
            fontSize: "14px",
            height: "36px",
            lineHeight: "20px",
            maxWidth: "100%",
            textAlign: "left",
            verticalAlign: "middle",
            width: "36px"
        },

        descontainer: {
            margin: '70px auto',
            textAlign: 'left',
            color: global.textColors.light.color3,
            fontSize: "14px",
            lineHeight: "24px",
            paddingBottom: "75px",
            width: "700px",
            maxWidth: '95%',
            background: "transparent",
            padding: "0",
            border: "none"
        },

        destitle: {
            color: global.textColors.dark.black,
            fontFamily: global.fontFamily.secondaryFont,
            fontSize: "18px",
            lineHeight: "28px",
            margin: "0.67em 0",
            marginBottom: "10px",
            marginTop: "20px",
            textAlign: "center"
        },

        bodytext: {
            color: global.textColors.dark.color2,
            fontSize: "14px",
            lineHeight: "24px",
            marginBottom: "10px",
            marginTop: "0",
            textAlign: "center"
        }


    });

};

export default ProductDetailPageStyles;