import findElementObject from "./findElementObject";
export function findParentElement(pageElements, childElement) {
  let localParentElements = pageElements;
  let parentObject;
  if (pageElements.size) {
    localParentElements = pageElements.toJS();
  }
  if (!childElement.parentId) {
    return;
  }
  localParentElements.forEach(element => {
    if (element.id === childElement.moduleId) {
      parentObject = findElementObject(element, childElement.parentId);
    }
  });
  return parentObject;
}

export default findParentElement;
