/*global Inferno, $ */
/* eslint-disable */
import {include} from '@convertly/thor';

const Script = () => {
		
		const DomController = include( './controller', 'DomController' );
		const ToggleStateInferno = include( './toggle-state', 'ToggleStateInferno' );
	
		return (id) => {
			
			const root = document.getElementById(id);
			
			const domController = DomController();
			const ToggleState = ToggleStateInferno();
			
			const MVCTester = Inferno.createClass({
				
				getInitialState: function () {
					this.domController = new domController( this );
					return {
						toggle: false
					};
				},
				
				componentDidMount() {
					this.domController.initDomEventListeners()
				},
				
				render: function () {
					
					
					return (
						<ToggleState
							toggle={ this.state.toggle }
						/>
					)
				}
				
			});
			
			Inferno.render(<MVCTester />, root);
			
		};
		
	}
;

export default Script;
