import { generatePseudoMediaQuery } from '../../elements/style-functions/pseudo-media-queries';


/**
 * @param global
 * @param data
 * @returns style object
 */
export default function CartScriptStyleObject(global, data, inEditor) {

  return {
    desktopCartInfernoComponent: {
      ".casper": {
        position: 'absolute',
        top: '0',
        right: '0',
        height: '60px',
        width: '425px',
        background: 'transparent',
        display: 'none',
        cursor: 'default'
      },

      ".cartLink": {
        color: global.colors.accent.color1
      },
      ".cartDropDown": {
        display: 'block',
        cursor: 'default',
        position: 'absolute',
        top: '100%',
        right: '-1px',
        width: '425px',
        height: "0px",
        boxSizing: 'border-box',
        overflowY: 'hidden',
        transition: 'height .3s ease',
        background: 'white',
        padding: '0px',
        zIndex: '50',
        paddingRight: "0",
        border: global.border,
        visibility: 'hidden',
      },
      ".cartContainer": {
        height: '350px',
        overflowY: 'auto',
        overflowX: 'hidden',
        width: '100%',
        borderBottom: global.border,

      },
      ".cartContainerFooter": {
        marginTop: '20px',
        textAlign: 'center'
      },
      ".checkoutLink": {
        display: 'block',
        ...global.button1
      },
      ".viewCartLink": {
        display: 'block',
        marginTop: "10px",
        color: global.colors.dark.color1
      },
      ".checkRightPanel": {
        padding: '30px',
        alignItems: "stretch",
        backgroundColor: "transparent",
        color: global.colors.dark.color1,
        display: "flex",
        flex: "1",
        flexDirection: "row",
        fontSize: "14px",
        justifyContent: "center",
        lineHeight: "20px",
        paddingTop: "30px"
      },

      ".chkRightPanelContainer": {
        color: global.colors.dark.color1,
        fontSize: "14px",
        lineHeight: "20px",
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "100%",
        width: "100%"
      },

      ".chkTitle1_mobileTitle1": {
        borderBottom: "2px none #667785",
        color: global.colors.dark.color1,
        display: "none",
        flex: "0 auto",
        flexDirection: "row",
        fontSize: "32px",
        fontWeight: "400",
        lineHeight: "36px",
        marginBottom: "10px",
        marginTop: "0px",
        paddingBottom: "20px",
        width: "100%"
      },

      ".mobileUnderline_titleUnderline": {
        backgroundColor: global.colors.dark.color1,
        borderRadius: "5px",
        color: global.colors.dark.color1,
        display: "none",
        fontSize: "14px",
        height: "3px",
        lineHeight: "20px",
        marginBottom: "20px",
        width: "45%"
      },

      ".chkGridheadercontainer": {
        color: global.colors.dark.color1,
        display: "flex",
        fontSize: "14px",
        justifyContent: "flex-end",
        lineHeight: "20px",
        marginBottom: "15px"
      },

      ".chkProdgridimgcontainer": {
        alignItems: "flex-start",
        color: global.colors.dark.color1,
        display: "flex",
        flexDirection: "column",
        fontSize: "14px",
        justifyContent: "center",
        lineHeight: "20px",
        maxWidth: "18%",
        textAlign: "center",
        width: "20%"
      },

      ".chkGridprice_th": {
        alignItems: "flex-start",
        color: global.colors.dark.color1,
        display: "flex",
        flex: "0 auto",
        fontFamily: global.fontFamily.secondaryFont,
        fontSize: "11px",
        justifyContent: "flex-start",
        lineHeight: "20px",
        paddingLeft: "0px",
        width: "125px"
      },

      ".chkGridqty_chkUnitprice_th": {
        alignItems: "flex-start",
        color: global.colors.dark.color1,
        display: "flex",
        flex: "1",
        fontFamily: global.fontFamily.secondaryFont,
        fontSize: "11px",
        justifyContent: "center",
        lineHeight: "20px",
        textAlign: "center"
      },

      ".chkGridsubtotal_th": {
        color: global.colors.dark.color1,
        display: "flex",
        flex: "1",
        fontFamily: global.fontFamily.secondaryFont,
        fontSize: "11px",
        justifyContent: "flex-end",
        lineHeight: "20px",
        textAlign: "center"
      },

      ".chkProdgrid": {
        color: global.colors.dark.color1,
        display: "flex",
        fontSize: "14px",
        lineHeight: "20px",
        marginBottom: "5px",
        position: "relative",
      },

      ".chkProdgridimg": {
        border: "0",
        color: global.colors.dark.color1,
        display: "inline-block",
        fontSize: "14px",
        height: "60px",
        width: "60px",
        lineHeight: "20px",
        maxWidth: "100%",
        textAlign: "center",
        verticalAlign: "middle",
        marginBottom: '10px'

      },

      ".chkRemovelink": {
        backgroundColor: "transparent",
        color: "#b4bbc3",
        fontSize: "10px",
        lineHeight: "20px",
        maxWidth: "100%",
        textAlign: "center",
        paddingLeft: '7px'
      },

      ".divBlock_2": {
        color: global.colors.dark.color1,
        display: "flex",
        flexDirection: "column",
        fontSize: "14px",
        lineHeight: "20px",
        width: "225px"
      },

      ".chkGridprice": {
        alignItems: "flex-start",
        color: global.colors.dark.color1,
        display: "flex",
        flex: "0 auto",
        fontSize: "12px",
        justifyContent: "flex-start",
        lineHeight: "20px",
        paddingLeft: "0px",
        width: "225px"
      },
      ".subTitle": {
        fontSize: '10px',
        color: global.colors.dark.color2
      },
      ".chkGridprice_chkGridpricenote": {
        alignItems: "flex-start",
        color: "#118bef",
        display: "flex",
        flex: "0 auto",
        fontSize: "12px",
        justifyContent: "flex-start",
        lineHeight: "16px",
        paddingLeft: "0px",
        width: "auto"
      },

      ".chkGridqty_chkUnitprice_strikethrough": {
        alignItems: "flex-start",
        color: "#667785",
        display: "flex",
        flex: "0 auto",
        fontSize: "14px",
        justifyContent: "flex-start",
        lineHeight: "20px",
        textAlign: "center",
        textDecoration: "line-through"
      },

      ".actualprice_chkGridqty_chkUnitprice": {
        alignItems: "flex-start",
        color: "#118bef",
        display: "flex",
        flex: "1",
        fontSize: "14px",
        justifyContent: "flex-start",
        lineHeight: "20px",
        textAlign: "center"
      },

      ".chkGridqty": {
        alignItems: "flex-start",
        color: "#667785",
        display: "flex",
        flex: "1",
        fontSize: "14px",
        justifyContent: "flex-end",
        lineHeight: "20px",
        textAlign: "center"
      },

      ".counterIndicator": {
        backgroundColor: "transparent",
        color: "#667785",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "4px 9px",
        textAlign: "center",
        textDecoration: "none"
      },

      ".counternumber": {
        border: global.border,
        color: "#667785",
        flex: "0 auto",
        fontSize: "14px",
        lineHeight: "20px",
        padding: "4px 9px 3px",
        textAlign: "center"
      },

      ".chkGridsubtotal": {
        color: global.colors.dark.color2,
        display: "flex",
        flex: "1",
        fontSize: "14px",
        lineHeight: "20px"
      },

      ".progresscontainer": {
        color: global.colors.dark.color1,
        fontSize: "14px",
        lineHeight: "20px",
        paddingTop: "10px",
        position: "relative"
      },

      ".truck": {
        border: "0",
        color: global.colors.dark.color1,
        display: "inline-block",
        fontSize: "14px",
        lineHeight: "20px",
        maxWidth: "55px",
        position: "absolute",
        right: "0px",
        top: "0px",
        verticalAlign: "middle"
      },

      ".progresstext": {
        borderBottomColor: "#000",
        borderBottomWidth: "3px",
        borderStyle: "none",
        color: global.colors.dark.color1,
        fontSize: "14px",
        lineHeight: "20px",
        paddingBottom: "10px",
        paddingRight: "80px"
      },

      ".progressline_titleUnderline": {
        backgroundColor: global.colors.dark.color1,
        borderRadius: "5px",
        color: global.colors.dark.color1,
        fontSize: "14px",
        height: "3px",
        lineHeight: "20px",
        marginBottom: "15px",
        width: "100%"
      },

      ".cartToggleContainer": {
        color: global.colors.dark.color1,
        display: "none",
        fontSize: "14px",
        lineHeight: "20px"
      },
      ".cartTitleSub": {
        color: global.colors.dark.color1,
        fontSize: "32px",
        fontWeight: "bold",
        lineHeight: "36px",
        marginBottom: "10px",
        marginTop: "20px"
      },

      ".toggleLinkblock_wInlineBlock": {
        backgroundColor: "transparent",
        color: global.colors.dark.color1,
        display: "inline-block",
        fontSize: "14px",
        lineHeight: "20px",
        maxWidth: "100%"
      },
      ".toggleCollapsed": {
        border: "0",
        color: global.colors.dark.color1,
        display: "inline-block",
        fontSize: "14px",
        lineHeight: "20px",
        maxWidth: "100%",
        verticalAlign: "middle"
      },
      /** Change in cart item **/
      ".cart-item-container": {
        padding: '15px'
      },
      ".change-message-bg": {
        backgroundColor: '#e3f2fd'
      },
      ".change-message-container": {
        borderTop: `1px solid ${global.colors.light.color2}`,
        paddingTop: '10px',
        fontFamily: global.fontFamily.secondaryFont
      },
      ".change-message-text": {
        textTransform: 'none'
      },
      ".change-message-action": {
        display: 'block'
      },
      ".change-message-link": {
        fontSize: '16px',
        display: 'inline-block',
        margin: "5px 10px 0 0",
        cursor: 'pointer'
      }
    },
  }
}
