import { fromJS } from "immutable";
import _ from "lodash";
import { createReducer } from "redux-act";
import assignUniqueId from "../assignUniqueId";
import { store } from "app/app";
import { assignZIndex } from "containers/Marketing/Banners/helpers/assignZIndex";
import {
  setElementsToAdd,
  setLoadingTheme,
  setSavingTheme,
  activateModule,
  setPageElements,
  setImageUrls,
  setSiteImages,
  toggleModal,
  toggleAddNewPageModal,
  setOptions,
  setIsActiveComponent,
  setOverlay,
  setNavigationMenu,
  setHeaders,
  setEditorSaveState,
  setPageData,
  setSiteData,
  setPageTemplates,
  setSiteTheme,
  setSitePages,
  setCurrentModal,
  setPageSettings,
  setEditorPanel,
  setSiteLoadingStatus,
  setPageLoadingStatus,
  setImageModalSource,
  setLoadingModules,
  setCustomModules,
  resetState,
  setEditorViewport,
  // setActiveEditorTab,
  setPageLoaded,
  setActivePage,
  setPageRendered,
  setPageMetaData,
  setPageSnapshot,
  setUnsavedPrompt,
  setStateWasCancelledFrom,
  setOverrideLeaveRoute,
  toggleClientNavigation,
  setImageGalleryCallback,
  // setSocialImage,
  setSocialImageFilename,
  setCommonElements,
  setSiteThemeData,
  setGoogleFonts,
  resetUndoRedo,
  toggleEditor,
  setEditingBlogPost,
  setEditingBlogLayout,
  setDraggingNewElement,
  setPublishedPageElements,
  clearEditorPanelAction,
  setBlogContent,
  setBlogTitle,
  setPhotosFromPexelsSearchQuery,
  setPhotosFromPexels,
  setPhotosFromPexelsLoading,
  setUserEditingSite,
  // setSocialImagePreview
} from "../actions/editorActions";

const blogParams = {
  editingBlogLayout: false,
  editingBlogPost: false,
  blogPostTitle: "",
  blogPostContent: "",
};

const blogReducer = {
  [setEditingBlogPost]: (state, editingBlogPost) => state.merge({ editingBlogPost }),

  [setEditingBlogLayout]: (state, editingBlogLayout) => state.merge({ editingBlogLayout }),

  [setBlogTitle]: (state, blogPostTitle) => state.merge({ blogPostTitle }),

  [setBlogContent]: (state, blogPostContent) => state.merge({ blogPostContent }),
};

const initialState = fromJS({
  loadingTheme: false,
  savingTheme: false,
  activeModuleId: "",
  pageElements: [],
  publishedPageElements: [],
  elementsThatCanBeAddedToPage: [],
  imgUrls: [],
  addModal: null,
  addNewPageModal: false,
  options: [],
  headers: [],
  isActiveComponent: null,
  overlayStyles: null,
  showNavigationMenu: false,
  saving: false,
  page: null,
  currentSite: null,
  pageTemplates: [],
  currentModal: "",
  pageSettings: {},
  editorType: {},
  siteIsLoading: false,
  pageSettingsIsLoading: false,
  imageGalleryModalSource: "",
  photosFromPexelsSearchQuery: "",
  photosFromPexels: [],
  photosFromPexelsLoading: false,
  imageGalleryCallback: null,
  loadingCustomModules: false,
  customModules: [],
  editorViewportWidth: "",
  // activeEditorTab: 1,
  pageLoaded: false,
  pageRendered: false,
  activePage: null,
  snapshot: {},
  showUnsavedPrompt: false,
  // when route transition is cancelled from unsaved changes
  // this is the route that user was going to
  stateToGoToAfterSavePrompt: {},
  overrideLeaveRoute: false,
  showClientNav: false,
  socialImage: "",
  googleFonts: [],
  pageElementsUndo: [],
  pageElementsRedo: [],
  isUndoDisabled: true,
  isRedoDisabled: true,
  // even though this variable looks
  // like it should be boolean
  // it needs to be a string
  // it is checked in Editor.js around line 409
  // and uses isUsingNewEditor.includes()
  isUsingNewEditor: "",
  draggingNewElement: null,
  undoAndRedoToggle: false, // needed by Page.js to indicate whether undo or redo has been clicked
  siteBeingEditedBy: {},
  ...blogParams,
});

const editorReducer = createReducer(
  {
    [setUserEditingSite]: (state, siteBeingEditedBy) => state.merge({ siteBeingEditedBy }),

    [setDraggingNewElement]: (state, draggingNewElement) => state.merge({ draggingNewElement }),

    [setElementsToAdd]: (state, e) => state.merge({ elementsThatCanBeAddedToPage: e }),

    [setLoadingTheme]: (state, loadingTheme) => state.merge({ loadingTheme }),

    [setSavingTheme]: (state, savingTheme) => state.merge({ savingTheme }),

    [setGoogleFonts]: (state, googleFonts) => state.merge({ googleFonts }),

    [setPublishedPageElements]: (state, e) => state.merge({ publishedPageElements: e }),

    [setSiteThemeData]: (state, themeData) => state.mergeDeep({ currentSite: { themeData } }),

    [setCommonElements]: (state, e) => state.mergeDeep({ currentSite: { commonElements: e } }),

    [setImageGalleryCallback]: (state, cb) => state.merge({ imageGalleryCallback: cb }),

    [setPageLoaded]: (state, pageLoaded) => state.merge({ pageLoaded }),

    [setStateWasCancelledFrom]: (state, t) => state.merge({ stateToGoToAfterSavePrompt: t }),

    [setOverrideLeaveRoute]: (state, overrideLeaveRoute) => state.merge({ overrideLeaveRoute }),

    [setUnsavedPrompt]: (state, showUnsavedPrompt) => state.merge({ showUnsavedPrompt }),

    [setPageSnapshot]: (state, snapshot) => state.merge({ snapshot }),

    [setActivePage]: (state, activePage) => state.merge({ activePage }),

    [setPageRendered]: (state, pageRendered) => state.merge({ pageRendered }),

    [setPhotosFromPexelsSearchQuery]: (state, photosFromPexelsSearchQuery) =>
      state.merge({ photosFromPexelsSearchQuery }),

    [setPhotosFromPexels]: (state, photosFromPexels) => state.merge({ photosFromPexels }),

    [setPhotosFromPexelsLoading]: (state, photosFromPexelsLoading) =>
      state.merge({ photosFromPexelsLoading }),

    [resetState]: state => {
      return state.merge({
        activeModuleId: "",
        pageElements: [],
        imgUrls: [],
        addModal: null,
        addNewPageModal: false,
        options: [],
        headers: [],
        isActiveComponent: null,
        overlayStyles: null,
        showNavigationMenu: false,
        saving: false,
        page: null,
        pageTemplates: [],
        currentModal: "",
        pageSettings: {},
        editorType: {},
        siteIsLoading: false,
        pageSettingsIsLoading: false,
        imageGalleryModalSource: "",
        loadingCustomModules: false,
        customModules: [],
        editorViewportWidth: "",
        // activeEditorTab: 1,
        pageLoaded: false,
        pageRendered: false,
        showUnsavedPrompt: false,
        snapshot: {},
        editingBlogLayout: false,
        editingBlogPost: false,
        overrideLeaveRoute: false,
        stateToGoToAfterSavePrompt: {},
        socialImage: "",
        pageElementsUndo: [],
        pageElementsRedo: [],
        isUndoDisabled: true,
        isRedoDisabled: true,
        siteBeingEditedBy: {},
      });
    },

    [clearEditorPanelAction]: state => {
      return state.merge({
        activeModuleId: "",
        editorType: {},
        overlayStyles: null,
        isActiveComponent: null,
      });
    },
    [setEditorViewport]: (state, editorViewportWidth) => state.merge({ editorViewportWidth }),

    [setCustomModules]: (state, customModules) => state.merge({ customModules }),

    [setLoadingModules]: (state, loadingCustomModules) => state.merge({ loadingCustomModules }),

    [setImageModalSource]: (state, s) => state.merge({ imageGalleryModalSource: s }),

    [setPageLoadingStatus]: (state, s) => state.merge({ pageSettingsIsLoading: s }),

    [setSiteLoadingStatus]: (state, siteIsLoading) => state.merge({ siteIsLoading }),

    [setCurrentModal]: (state, currentModal) => state.merge({ currentModal }),

    [toggleEditor]: (state, param) => state.merge({ isUsingNewEditor: param }),

    [setPageTemplates]: (state, pageTemplates) => state.merge({ pageTemplates }),

    [setEditorPanel]: (state, { editorType, isActiveComponent, activeModuleId }) => {
      editorType = window.dontRunIt ? {} : editorType;
      isActiveComponent =
        isActiveComponent === undefined ? state.get("isActiveComponent") : isActiveComponent;
      activeModuleId = activeModuleId === undefined ? state.get("activeModuleId") : activeModuleId;
      return state.merge({ editorType: editorType, isActiveComponent, activeModuleId });
    },

    [setPageSettings]: (state, pageSettings) => state.merge({ pageSettings }),

    [setSitePages]: (state, pages) => {
      let newCurrentSite = state.get("currentSite").toJS();
      newCurrentSite.pages = pages;
      return state.merge({
        currentSite: newCurrentSite,
      });
    },

    [setSiteTheme]: (state, theme) => {
      return state.mergeDeep({
        currentSite: {
          theme: theme,
        },
      });
    },

    [setSiteData]: (state, currentSite) => state.merge({ currentSite }),

    [setPageData]: (state, page) => {
      // TODO @BUG This reducer is being called when load site on editor route
      // and you are logged in as a partner
      const currentSite = state.get("currentSite");
      if (!currentSite) {
        return state;
      }

      // it's redundant to set pageElements and page
      // since elements is a part of page
      // pageElements is used by itself throughout the app
      // that it would require massive refactor to
      // separate the two
      // needs to be done down the road though

      // First get the common elements from the state
      const commonElements = state.getIn(["currentSite", "commonElements"]);

      // Loop through common elements
      // build an editor compatible version
      // push it into the elements array
      //
      // set the unique flag - this is used when saving to filter out
      // these elements
      let pageElements;
      const pageElementsUndo = [];
      commonElements.forEach(el => {
        // The save API needs modId for common elements
        const element = el.get("element").merge({
          type: el.get("type"),
          unique: true,
          modId: el.get("modId"),
        });

        page.elements.push(element.toJS());
      });

      pageElements = page.elements;

      assignUniqueId(pageElements, null, true, null, null, true);
      pageElementsUndo.push(pageElements);
      return state.merge({
        pageElements: pageElements,
        pageElementsUndo: pageElementsUndo,
        page: page,
      });
    },

    [setEditorSaveState]: (state, saving) => state.merge({ saving }),

    [activateModule]: (state, params) => {
      const activeModuleId = window.dontRunIt ? "" : params;
      return state.merge({ activeModuleId: activeModuleId });
    },

    [setPageElements]: (state, { elements, forceUpdate, editorType, action }) => {

      const type = state.getIn(["editorType", "type"]);
      const id = state.get("activeModuleId");
      let pageElements = elements;
      let pageElementsRedo, isUndoDisabled, isRedoDisabled;
      let pageElementsUndo = state.get("pageElementsUndo");
      let undoAndRedoToggle;
      const Marketing = store.getState().get("marketing");
      let bannerEditorIsOpen = false;
      if (!action) {
        if (pageElementsUndo.size < 10) {
          if (pageElementsUndo.size > 0) {
            if (!_.isEqual(pageElementsUndo.get(pageElementsUndo.size - 1), pageElements)) {
              pageElementsUndo = pageElementsUndo.push(pageElements);
            }
          } else {
            if (pageElements.length) {
              pageElementsUndo = pageElementsUndo.push(pageElements);
            }
          }
        } else if (pageElementsUndo.size === 10) {
          if (!_.isEqual(pageElementsUndo.get(pageElementsUndo.size - 1), pageElements)) {
            pageElementsUndo = pageElementsUndo.push(pageElements);
            pageElementsUndo = pageElementsUndo.delete(0);
          }
        }
        if (pageElementsUndo.size > 1) {
          isUndoDisabled = false;
        } else {
          isUndoDisabled = true;
        }
        isRedoDisabled = true;
        pageElementsRedo = [];
      } else if (action === "undo") {
        undoAndRedoToggle = !state.get("undoAndRedoToggle");
        pageElementsRedo = state.get("pageElementsRedo");
        pageElementsRedo = pageElementsRedo.push(state.get("pageElements").toJS());
        pageElementsUndo = pageElementsUndo.pop();
        pageElements = pageElementsUndo.get(pageElementsUndo.size - 1);
        if (pageElementsUndo.size === 1) {
          isUndoDisabled = true;
        }
        isRedoDisabled = false;
      } else if (action === "redo") {
        undoAndRedoToggle = !state.get("undoAndRedoToggle");
        pageElementsRedo = state.get("pageElementsRedo");
        pageElements = pageElementsRedo.get(pageElementsRedo.size - 1);
        pageElementsRedo = pageElementsRedo.pop();
        pageElementsUndo = pageElementsUndo.push(pageElements);
        if (pageElementsRedo.size < 1) {
          isRedoDisabled = true;
        }
        isUndoDisabled = false;
      }
      // We have to check if Marketing Reducer exists
      // If we have not loaded the marketing route then then the reducer has not been initialized
      if (Marketing) {
        bannerEditorIsOpen = store
          .getState()
          .get("marketing")
          .Banners.get("bannerEditorIsOpen");
      }

      if (
        id === "" ||
        type === "column" ||
        type === "row" ||
        type === "background" ||
        type === "section" ||
        type === "wrapperDiv" ||
        type === "ConvertlySlide" ||
        type === "slide" ||
        bannerEditorIsOpen
      ) {
        assignUniqueId(pageElements, null, true, null, null);
      }
      if (bannerEditorIsOpen) {
        assignZIndex(pageElements); //if bannerEditorIsOpen this is assign elements a zIndex depending on the order
      }
      if (type !== undefined) {
        if (editorType) {

          return state.merge({
            pageElements: pageElements,
            pageElementsUndo: pageElementsUndo,
            pageElementsRedo: pageElementsRedo,
            isRedoDisabled: isRedoDisabled,
            isUndoDisabled: isUndoDisabled,
            undoAndRedoToggle: undoAndRedoToggle,
            editorType: editorType,
            forceUpdate,
          });
        }

        return state.merge({
          pageElements: pageElements,
          pageElementsUndo: pageElementsUndo,
          pageElementsRedo: pageElementsRedo,
          undoAndRedoToggle: undoAndRedoToggle,
          isRedoDisabled: isRedoDisabled,
          isUndoDisabled: isUndoDisabled,
          forceUpdate,
        });
      }

      return state.merge({
        pageElements: pageElements,
        pageElementsUndo: pageElementsUndo,
        pageElementsRedo: pageElementsRedo,
        undoAndRedoToggle: undoAndRedoToggle,
        isUndoDisabled: isUndoDisabled,
        isRedoDisabled: isRedoDisabled,
        forceUpdate,
      });
    },

    [resetUndoRedo]: state => state.merge({ pageElementsUndo: [], pageElementsRedo: [] }),

    [setSiteImages]: (state, imgUrls) => state.merge({ imgUrls }),

    [setImageUrls]: (state, imgUrls) => state.merge({ imgUrls }),

    [toggleModal]: (state, addModal) => state.merge({ addModal }),

    [toggleAddNewPageModal]: state =>
      state.merge({ addNewPageModal: !state.get("addNewPageModal") }),

    [setIsActiveComponent]: (state, params) => {
      const isActiveComponent = window.dontRunIt ? null : params;
      delete window.dontRunIt;
      return state.merge({ isActiveComponent: isActiveComponent });
    },

    [setOptions]: (state, options) => state.merge({ options }),

    [setHeaders]: (state, headers) => state.merge({ headers }),

    [setOverlay]: (state, overlayStyles) => state.merge({ overlayStyles }),

    [setNavigationMenu]: (state, showNavigationMenu) => state.merge({ showNavigationMenu }),

    [setPageMetaData]: (state, pageMetaData) => {
      return state.mergeDeep({
        page: {
          ...pageMetaData,
        },
      });
    },

    [toggleClientNavigation]: (state, params) => state.merge({ showClientNav: params }),

    [setSocialImageFilename]: (state, socialImage) => state.merge({ socialImage }),

    ...blogReducer,
  },
  initialState
);

export default editorReducer;
