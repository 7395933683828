import React from "react";
import Component from "source/rootComponent";
import {css} from "@convertly/css";
import _ from "lodash";
import ReactHtmlParser from "@convertly/react-html-parser"

// Style
import {ParagraphStyles} from "./ParagraphStyles";

// Utils
export class Paragraph extends Component {
    render() {
        const {data, inEditor, global, blogData} = this.props;

        if (_.isEmpty(data) && _.isEmpty(blogData)) {
            return null;
        }

        let {shortDescription, text} = blogData || data || {};

        const {characterCap} = data || {};

        this.styles = ParagraphStyles({global, data, inEditor});

        const {paragraph, capCharacters} = this.styles;

        if (
            _.isString(text) &&
            _.isInteger(+characterCap) &&
            +characterCap > 0 &&
            text.length > characterCap
        ) {
            text = `${text.substring(0, characterCap)}...`;
        } else if (
            _.isString(shortDescription) &&
            _.isInteger(+characterCap) &&
            +characterCap > 0 &&
            shortDescription.length > characterCap
        ) {
            if(shortDescription.indexOf( 'href' ) === -1) {
                shortDescription = `${shortDescription.substring(0, characterCap)}...`;
            }

        }

        const inlineStyle = this.generateStyleAttribute();

        return (
            <div
                {...inlineStyle}
                {...this.generateDataEditorAttribute()}
                {...this.generateCustomAttributes()}
                className={css(paragraph)}
                //dangerouslySetInnerHTML={{ __html: shortDescription ? shortDescription : text }}
            >
                <p className={ capCharacters }>{ReactHtmlParser(shortDescription ? shortDescription : text)}</p>
            </div>
        );

    }
}
