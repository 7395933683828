export default function TodoListStyles01(global, data, inEditor) {

    return {
        ".todo-app-wrapper": {
            textAlign:'center'
        },
        /** Form Styles **/
        ".todo-form": {
            width:'100%'
        },
        ".todo-input": {
            border:'2px solid black',
            display:'inline-block',
            width:'200px'
        },
        ".submit-button": {
            background:global.colors.accent.color1,
            color:"#fff"
        },
        /** Todos **/
        ".todo": {
            float:'left',
            fontSize:'20px'
        },
        ".remove-todo": {
            float:'right',
            transition:'all 1s ease',
            ":hover": {
                color:'red'
            }
        }
    }
}