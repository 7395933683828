import React from 'react';
import { css } from "@convertly/css";
import Component from '../../rootComponent';
import Types from '../../attribute-types';


//utils
import generateAccessKey from '../../utils/generateAccessKey';

//Default Props
import defaultGlobal from '../../themes/global.js';


//Styles
import wideButtonStyles from './buttonStyles01.js';

//Components
import PhoneLink from '../links/phone-number-link';

//Component
export default class
ButtonPhoneLink extends Component {
    constructor ( props ) {
        super( props );
    }
    render() {

        this.styles = wideButtonStyles( this.props.global, this.props.data, this.props.buttonType );


        return ( <PhoneLink { ...this.props }
                       className={ css( this.styles.button) }
                       { ...generateAccessKey( "button" ) }/> );

    }
}

ButtonPhoneLink.defaultProps = {
    data: {
        text: "Hello from the other side",
        bold: false,
        italic: false,
        underline: false,
        link: {
            linkType: 'external',
            url: "https://google.com"
        },
        align: 'center',
        fontSize: '',
        color: ''
    },
    global: defaultGlobal
};

