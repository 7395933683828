import React from 'react';
import Component from '../../rootComponent';
import {css} from "@convertly/css";
import defaultGlobal from '../../themes/global.js';
import ReactHTMLParser from "@convertly/react-html-parser";

// Styles
import divWithOverlayStyleSheet from './div-with-overlay-styles';


export default class DivWithOverlay extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {

    console.log(this.context.store.getState().toJS().editor.editorReducer.toJS(), "NEED A WEAPON" )

  }

  render() {
    this.styles = divWithOverlayStyleSheet(this.props.global, this.props.data, this.inEditor());

    let className;
    const aphroditeClass = css(this.styles.divWithOverlay);

    className += aphroditeClass;

    function renderChildren(props) {
      return React.Children.map(props.children, child => {
        return React.cloneElement(child, {
          moduleId: props.moduleId,
          moduleSort: props.moduleSort,
          styles: props.styles
        });
      });
    }

    /** Generates border in editor */
    const inlineStyle = this.generateStyleAttribute();

    return (

      <div className={css(this.styles.divWithOverlay)}
           {...this.generateDataEditorAttribute()}
           {...this.generateCustomAttributes()}
           onClick={(e) => {
             this.launchElementalEditor('divWithOverlay', e);
           }}
           onMouseOver={(e) => {
             this.addEditableOutline(e)
           }}
           onMouseOut={(e) => {
             this.removeEditableOutline(e)
           }}
           {...inlineStyle} >

        <div className={`${css(this.styles.divOverlay)} divOverlay ${this.props.inEditor && _.get(this.props, 'data.showOverlay') && 'showOverlay'}`}>
          {renderChildren(this.props)}
        </div>
      </div>
    )
  }

};

DivWithOverlay.defaultProps = {
  global: defaultGlobal,
  data: {}
}
