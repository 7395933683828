import React from "react";
import { graph } from "utils/graph";
import { delay } from "utils";
import { getUserByEmail, addSiteToAccount } from "requests";

import { setStatusMessage, setSaving, setModal } from "./actions";

import { store } from "app/app";
import { Notifications } from "services/Notifications";

export const linkClient = ({ email }, site) => async dispatch => {
  await dispatch(setSaving(true));
  await dispatch(setStatusMessage(`Searching for ${email}`));

  const user = await getUserByEmail({ email });

  if (!user.id) {
    await dispatch(setStatusMessage(`No user with ${email} found.`));
    await dispatch(setSaving(false));
    return null;
  }

    /**
   * Added a false here to allow partners to be set as a primary account holder.
   * Remove the false to check for the roles again.
   */
  if (false && user.roles.indexOf("partner") !== -1) {
    await dispatch(
      setStatusMessage(
        `${email} is a partner account. Partners can not be set as the primary account holder.`
      )
    );
    await dispatch(setSaving(false));
    return null;
  }

  const partner = store.getState().getIn(["auth", "user"]);

  /**
   * Added a false here to allow partners to set other partners as a primary account holder. 
   * Remove the false to disallow.
   */
  if (false && user.partner !== partner.get("partner")) {
    await dispatch(
      setStatusMessage(`You do not have access to link ${email} with ${site.siteName}.`)
    );
    await dispatch(setSaving(false));
    return null;
  }

  await dispatch(setStatusMessage(`Linking ${email}`));

  await Promise.all([
    await dispatch(editSitePrimaryZorkUser({ user, site })),
    await addSiteToAccount({ accountId: user.id, siteId: site.id })
  ]);

  await delay(250);

  await Notifications.success({
    title: "Success",
    message: `${email} has been set as the primary account holder for ${site.siteName}`
  });

  await dispatch(setSaving(false));
  await dispatch(setModal(null));
  return user.id;
};

export const editSitePrimaryZorkUser = ({ user, site }) => async dispatch => {
  const query = `
	mutation( $id:ID!, $site: siteInput ) {
		editSite(id:$id,site:$site) {
			id, primaryZorkUserId
		}
	}`;

  const variables = {
    id: site.id,
    site: {
      primaryZorkUserId: user.id
    }
  };

  return await graph({ query, variables });
};
