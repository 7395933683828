import {deeplog} from "../utils/deeplog";

const getEcommerceSettings = (site) => {

    let ecommerceSettings = {};

    if (site) {

        if (site.ecommerce) {

            if (site.ecommerce) {
                /** Coupon Code **/
                ecommerceSettings.displayCouponCodeField =
                    site.ecommerce.displayCouponCodeField ?
                        site.ecommerce.displayCouponCodeField : false;
                /** Zip Code shipping estimator **/
                ecommerceSettings.allowZipCodeShippingEstimate =
                    site.ecommerce.allowZipCodeShippingEstimate ?
                        site.ecommerce.allowZipCodeShippingEstimate : false;
                /** Product Gallery Settings **/
                ecommerceSettings.displayMoreProductsMethod = site.ecommerce.displayMoreProductsMethod;

                ecommerceSettings.quickBuyOptions =
                    site.ecommerce.quickBuyOptions ?
                        site.ecommerce.quickBuyOptions : false;

                /** curbside settings **/
                if(site.curbsideEnabled) ecommerceSettings.curbsideEnabled = site.curbsideEnabled
                if(site.curbsideCallLink) ecommerceSettings.curbsideCallLink = site.curbsideCallLink
                if(site.curbsidePickupInstructions) ecommerceSettings.curbsidePickupInstructions = site.curbsidePickupInstructions


            }
        }
    }

    return ecommerceSettings;
};

export default getEcommerceSettings;
