import React from 'react';
import Component from 'source/rootComponent';
import { css } from '@convertly/css';
import { id } from '@convertly/thor';
import _ from 'lodash';

// Utils
import { generateLink } from '../../../../utils/generateLink';
import { clientImage } from '../../../../utils/generateImage';

// Components
import cartIconSVG from '../../../../svg/carts/cart';

// Styles
import ecommerceStyles from './ecommerceStyles';

// Scripts
import cartScript from '../../../scripts/cart-script';
import isSignedIn from '../../../scripts/is-signed-in';

export class Ecommerce extends Component {

  render() {

    const CartIconSVG = cartIconSVG(React);
    const { global, data, inEditor, page, site } = this.props;
    const displayEcommerce = _.get(data, 'displayEcommerce') === false || true;

    if(displayEcommerce !== true) {
      return null;
    }

    const order = _.get(data, 'order') || [0, 1];
    const slug = _.get(page, 'slug') || '/';
    const siteId = _.get(site, 'siteId') || null;

    const clientImgSrc = clientImage("", {
      width: '60',
      height: '60',
      crop: 'fit'
    }, siteId);

    const clientLinkUrl = generateLink({
      linkType: "inferno",
      url: ""
    });


    this.styles = ecommerceStyles({ global, data, inEditor });
    
    const {
      ecommerce,
      cart,
      signIn,
      cartWrapper,
      mobileCart,
      signInWrapper,
    } = this.styles;

    let cartComponent;
    let signInComponent;

    // Renders the cart, mobileCart, and signIn components differently 
    // based off of whether Ecommerce is in the editor or not. This 
    // eliminates the functionality in the editor that would otherwise 
    // be in a live site.

    if(inEditor) {

      cartComponent = (
        <div className={ css(cartWrapper) }>
          <div 
            className={ css(cart) }>
            Cart
          </div>
          <div className={ css(mobileCart) }>
            <CartIconSVG nameOfClass={ 'mobileCartContainer' }/>
          </div>
        </div>
      );

      signInComponent = (
        <div className={ css(signIn) }>
          Sign In
        </div>
      );

    } else {

      cartComponent = (
        <div className={ css(cartWrapper) }>
          <div 
            onClick={ this.cancelClick }
            id={ id(cartScript, clientLinkUrl, clientImgSrc, "true") }
            className={ css(cart) }
            href={ generateLink({ linkType: 'internal', url: '/cart' }) }>
            Cart
          </div>
          <div className={ css(mobileCart) }>
            <CartIconSVG nameOfClass={ 'mobileCart' }/>
          </div>
        </div>
      );

      signInComponent = (
        <div className={ css(signInWrapper) }>
          <div
            onClick={ this.cancelClick }
            id={ id(isSignedIn, clientLinkUrl) }
            className={ css(signIn) }
            href={ generateLink({ linkType: 'internal', url: '/account' }) }>
            Sign In
          </div>
        </div>
      );
    }

    // Add additional components here that will be rendered based off of
    // the order property provided by the user.
    const componentPointers = [
      cartComponent,
      signInComponent,
    ];

    const lengthOfComponentPointers = Object.keys(componentPointers).length;
    this._validateOrderValue(order, lengthOfComponentPointers);
    let sortedArrayOfComponents = new Array(lengthOfComponentPointers);

    for(let idx = 0; idx < order.length; idx++) {
      const currentSortValue = order[idx];
      const component = componentPointers[currentSortValue];
      sortedArrayOfComponents[idx] = component;
    }

    const inlineStyle = this.generateStyleAttribute();
    
    return (
      <div 
        className={ css(ecommerce) }
        { ...inlineStyle }
        { ...this.generateDataEditorAttribute() }
        { ...this.generateCustomAttributes() }>
        { sortedArrayOfComponents.map(comp => comp) }
      </div>
    )

  }

  // Ensures the values in 'order' are sequential, unique, and integer 
  // types. 
  /* 
      @TODO: Make validations more general to be used for other 
              properties (i.e., modules)
  */  
  _validateOrderValue(order, pointerLength) {
    let memoizedOrderValues = {};
    for(let idx = 0; idx < order.length; idx++) {
      const value = order[idx];
      if (
        memoizedOrderValues[value] || 
        value >= pointerLength ||
        !_.isInteger(value)
        ) {
        throwInvalidOrderError();
      }
      memoizedOrderValues[value] = true;
    }

    function throwInvalidOrderError() {
      try {
        throw new Error(
          'Please provide only unique, sequential integers starting from zero.'
        );
      } catch (err) {
        console.error(err.stack);
      }
    }
  }
}