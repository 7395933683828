export default function OrderNotificationStyles(global, data) {

    const lightGrey = `${global.colors.light.color3}`

    return {
        ".notifications-section": {
            textAlign:'left'
        },
        ".notifications-heading": {
            color:lightGrey,
            fontWeight:'bold',
            margin:'10px 0',

        },
        ".notification-wrapper": {
            margin:`10px 0`,
            padding:'20px 0',
            borderTop:`1px solid ${lightGrey}`,
        }
    }

}
