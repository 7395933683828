import { StyleSheet } from "@convertly/css";
import Typography from '../style-functions/typography';

const addressStyles = ( global, data ) => {

    const addressStyles = new Typography( global, data );

    return StyleSheet.create({
        address: {
            ...addressStyles,
        },
    })

};

export default addressStyles;