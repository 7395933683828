import PropTypes from "prop-types";
import React, { Component } from "react";
import { css } from "@convertly/css";
import ModalStyles from "./modal-styles";
import { setOverlayStyles, closeModal } from "../../Editor/actions/editorActions";
import { keyframes, css as styledCSS } from "styled-components";

const openAnimation = keyframes`
  from {
    opacity: 0;
    left:50%;
    transform: translate(-50%, 0%) scale(0.95);
  }
  to {
    opacity: 1;
    left: 50%;
    transform: translate(-50%, 0%)  scale(1);
  }
`;

const closeAnimation = keyframes`
  from {
    opacity: 1;
    left:50%;
    transform: translate(-50%, 0%)  scale(1);
  }
  to {
    opacity: 0;
    left:50%;
    transform: translate(-50%, 0%) scale(0.95);
  }
`;

export class ModalWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: { opacity: 0.7 },
      transition: this.props.transition,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount() {
    this.setState({
      transition: {
        //animation: styledCSS`${openAnimation} 300ms`,
        left: "50%",
        transform: "translate(-50%, 0%)",
      },
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.transition && Object.keys(nextProps.transition).length !== 0) {
      this.setState({ transition: nextProps.transition });
    }
  }

  /**
   * Close button X event handler
   */
  closeModal() {
    //this.setState({ transition: { animation: styledCSS`${closeAnimation} 300ms` } });
    setTimeout(() => {
      this.context.store.dispatch(closeModal());
      this.context.store.dispatch(setOverlayStyles(null));
    }, 250);
  }

  /**
   *
   * @returns {XML}
   */
  render() {
    const { width, height, left, top, underlay } = this.props;
    const styles = ModalStyles({ width: width, height: height });

    return (
      <div>
        <div style={this.state.transition} className={css(styles.modalWrapper)}>
          <div>
            <div className={css(styles.modalHeader)}>
              {this.props.title}
              <span style={{ float: "right" }}>
                {!this.props.noCloseIcon && (
                  <i
                    onClick={this.closeModal}
                    className={css(styles.modalCloseIcon) + " material-icons"}
                  >
                    close
                  </i>
                )}
              </span>
            </div>
          </div>
          <div>{this.props.children}</div>
        </div>

        {underlay && <div className={css(styles.modalUnderlay)} onClick={this.closeModal} />}
      </div>
    );
  }
}

ModalWrapper.contextTypes = {
  store: PropTypes.object,
};

export default ModalWrapper;
