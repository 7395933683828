import React from 'react';
import Component from '../../../rootComponent';
import {css} from "@convertly/css";
import {id, getRenderState} from '@convertly/thor';
import _ from 'lodash';
import {clientImage} from '../../../utils/generateImage';
import {generateLink} from '../../../utils/generateLink';


//scripts
import activeNavLink from '../../scripts/active-nav-link';
import cartScript from '../../scripts/cart-script';
import isSignedIn from '../../scripts/is-signed-in';


//Components
import NavLink07 from './nav-link-03';

export default class NavList03 extends Component {

    constructor(props) {

        super(props);


        this.state = {
            ecommSite: false
        }

    }

    checkEcommerce() {
        if (this.props.site) {
            if (this.props.site.ecommerce) {
                this.setState({
                    cartDiv: null
                })
            }
        }
    }

    componentWillMount() {
        this.checkEcommerce();
    }

    render() {

        let {site, hideMobile, styles, data, page} = this.props;

        let rootId = id(activeNavLink);

        let mobileHideClass = hideMobile ? css(styles.mobileHide) : '';

        const slug = _.get(this.props, 'page.slug');

        const enabled = _.get(this.props, 'site.ecommerce.enabled');

        let inEditor = this.inEditor();
        let showMobileMenu = false;


        if (inEditor) {
            showMobileMenu = this.context.store.getState().get('editor').editorReducer.get('showClientNav');
        }

        let cartDiv = null;
        let signIn = null;

        const clientImgSrc = clientImage("", {width: '60', height: '60', crop: 'fit'});
        const clientLinkUrl = generateLink({linkType: "inferno", url: ""});

        let className = '';
        let aphroditeClass = css(styles.headerNav);

        if (this.animatedClassName) {
            className += " " + this.animatedClassName
        }
        className += aphroditeClass;

        const {animated} = this.props.data;
        const inlineStyle = this.generateStyleAttribute();

        if (enabled) {
            if (slug !== "/cart") {
                cartDiv =
                    <div className={ css(styles.navItemContainer) }>
                        <div className={ css(styles.desktopCartInfernoComponent) }
                             id={ id(cartScript, clientLinkUrl, clientImgSrc, true, slug) }/>
                    </div>;
            }
            signIn =
                <div className={ css(styles.navItemContainer, styles.mobileHide) }>
                    <div className={ css(styles.desktopSignInInfernoComponent) }
                         id={ id(isSignedIn, clientLinkUrl) }/>
                </div>;
        }


        return (
            <nav className={ css(styles.headerNav) + " " + mobileHideClass }>

                <ul className={ `${css(styles.navList)} ${ showMobileMenu && 'open'}` } id="menu">

                    {data.navLinks.map((navItem, index) => {
                        return (
                            <NavLink07
                                key={ index }
                                data={ navItem }
                                styles={ styles }
                                site={ site }
                                page={ page }
                                index={ index }
                                id={ rootId}/>
                        )
                    })}

                    { signIn }
                    { cartDiv }


                </ul>

            </nav>
        )
    }
}

