import React from 'react';
import Component from 'source/rootComponent';
import {css} from "@convertly/css";
import _ from 'lodash';
import ReactHTMLParser from "@convertly/react-html-parser";

import Styles from './header-topper-styles';
import Widgets from '../../widgets';

export class HeaderTopper extends Component {

    constructor(props) {

        super(props);

    }

    render() {
        const {data, global, site} = this.props;

        const inEditor = this.inEditor();

        this.styles = Styles(this.props.global, this.props.data, inEditor);

        let styles = this.props.styles ? this.props.styles : this.styles;

        if (this.props.editor) {
            return this.editor();
        }

        return (
            <div className={`${css(styles.header_top)} header-top`}>
                {/** SOCIAL / CONNECT WITH US CONTAINER */}
                <div className={css(styles.header_top_lft)}>
                    <div className={css(styles.socialIconWrapper)}>
                        {/** SOCIAL ICONS **/}
                        { _.get(data, 'displaySocialIcons') && _.get(site, 'social') &&
                        <Widgets.SocialSharing01
                            data={ {
                                socialIcons: site.socialIcons
                            } }
                            global={ global }
                            site={ site }
                            styles={ styles }
                            socialIconLinkClass={ styles.socialIconContainer }/>
                        }
                    </div>
                    {/** Custom message */}
                    <div className={css(styles.connect_us)}>
                        {_.get(data, 'customHeaderMessage.text') &&
                        <h2 className={ css(styles.customCallOut) }>{ ReactHTMLParser(data.customHeaderMessage.text) }</h2>
                        }
                    </div>

                </div>
            </div>
        )
    }
}
