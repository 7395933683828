import React from "react";
import styled from "styled-components";

export const MenuWrapper = styled.div`
  width: 100%;
  margin: 0 10px 0 0;
  display: grid;
  grid-template-columns: 1fr auto;
`;

export const CenterWrapper = styled.div`
  margin-left: calc(100vw / 2 - 298px);
  justify-self: start;
  align-self: center;
`;

export const ButtonsWrapper = styled.div`
  justify-self: end;
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
`;

export const UndoButtons = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 20px;
  border-right: 1px solid #ddd;
  padding: 0 20px 0 0;
`;

export const ArrowButton = styled.div`
  align-self: end;
  cursor: pointer;
  opacity: 0.5;
  i {
    position: relative;
    top: 2px;
  }
`;

export const UndoButton = styled(ArrowButton)`
  :hover {
    opacity: ${props => (props.isUndoDisabled ? 0.5 : 1)};
  }
  i {
    transform: rotate(45deg);
  }
`;

export const RedoButton = styled(ArrowButton)`
  :hover {
    opacity: ${props => (props.isRedoDisabled ? 0.5 : 1)};
  }
  i {
    transform: rotate(-45deg) scaleX(-1);
  }
`;
