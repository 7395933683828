import { StyleSheet } from '@convertly/css';
import UserOverrides from '../../elements/style-functions/user-overrides';

const HTMLContentRendererStyles = ({ global, data, inEditor }) => {
  
  const userOverrides = new UserOverrides(global, data, inEditor);

  return StyleSheet.create({
    contentWrapper: {
      ...inEditor && {
        display:'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:`#F6FDFA`,
        height:400
      },
      ...userOverrides
    }
  })
};

export default HTMLContentRendererStyles;
