import { StyleSheet } from '@convertly/css';
import UserOverrides from '../style-functions/user-overrides';
import _ from 'lodash';

const FullPageScrollingBannerStyles = ({ global, data, inEditor }) => {

  const bannerUserOverrides = UserOverrides(global, data, inEditor);
  const bannerLocationUserOverrides = _.get(data, 'location.userOverrides') ? UserOverrides(global, data.location, inEditor) : {};
  const bannerHeadingUserOverrides = _.get(data, 'heading.userOverrides') ? UserOverrides(global, data.heading, inEditor) : {};
  const backgroundImageStyles = _.get(data, 'backgroundImage.userOverrides') ? UserOverrides(global, data.backgroundImage, inEditor) : {};

  return StyleSheet.create({
    
    banner: {
      height: '100vh',
      width: '100vw',
      display: 'flex',
      'justify-content': 'center',
      'align-items': 'center',
      'flex-direction': 'column',
      background: 'transparent',
      position: 'relative',
      'font-family': global.fontFamily.primaryFont,
      ...bannerUserOverrides
    },

    content: {
      'text-align': 'center',
      'margin-top': 'auto',
    },

    locationStyles: {
      ...bannerLocationUserOverrides
    },

    headingStyles: {
      'max-width': '80%',
      'text-align': 'center',
      'margin': 'auto',
      'margin-top': '20px',
      ...bannerHeadingUserOverrides
    },

    backgroundImageStyles: {
      'position': 'absolute',
      'top': '0',
      'left': '0',
      'height': '100%',
      'width': '100%',
      'z-index': '-1',
      'object-fit': 'cover',
      ...backgroundImageStyles
    }

  })
};

export default FullPageScrollingBannerStyles;